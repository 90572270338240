import React, { useState } from "react";
import { GiCancel } from "react-icons/gi";
import { IoMdUnlock } from "react-icons/io";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import "./Resetpswd.scss"
import { KeyboardBackspace } from "@material-ui/icons";

const Resetpswd = ({ rowData, closeModal , closeOn}) => {

  console.log("opened");
  console.log("rowData-->",rowData);


  const [show2, setShow2] = useState(false);

  const submitHandler = async () => {
    // const resp = await trackPromise(
    //     API.post(`admin-module/api/v1/users/resetPassword`, { userID: rowData.userID })
    // );
    // { resp.success ? successNotification(resp.message) : errorNotification(resp.message) }
}
console.log("hi")
  const mainBlockStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
    backdropFilter: "blur(5px)", // background blur effect
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const box1Style = {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    width: "600px",
    textAlign: "center",
    zIndex: 1001, // Ensure it is above the blurred background
  };

  const mainBlock2Style = {
    ...mainBlockStyle,
    zIndex: 1000, // Keep it the same or adjust as needed
  };

  const box2Style = {
    ...box1Style,
    width: "350px",
    padding: "30px",
  };


  const closeHandler = ()=>{
    closeModal(false)
    closeOn(false)
  }

  return (
    <div>
      <div style={mainBlockStyle} className="mainblock">
        <div style={box1Style} className="box1">
          <div className="header">
            <h3>Reset Password</h3>
          </div>
          <div className="box1body">
            <button onClick={() => setShow2(!show2)}>
              Click here to Reset password <IoMdUnlock id="lockicon" />
            </button>
          </div>
          <div className="footer">
            <button onClick={closeHandler}>
              {" "}
              <GiCancel id="cnclicon" /> Cancel{" "}
            </button>
          </div>
        </div>
      </div>

      {show2 && (
        <div style={mainBlock2Style} className="mainblock2">
          <div style={box2Style} className="box2">
            <div className="body2">
              <p>
                Do you really want to reset the
                <br />
                password of selected user account?
              </p>
            </div>
            <div className="footer2">
              <button onClick={() => closeModal(false)}><KeyboardBackspace/>Back</button>
              <button id="confirm" onClick={submitHandler}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Resetpswd;



















// import React, { useState } from "react";
// import { GiCancel } from "react-icons/gi"
// import { IoMdUnlock } from "react-icons/io"
// import "./Resetpswd.scss"
// import { errorNotification, successNotification } from "../../utils/notifications";
// import { trackPromise } from "react-promise-tracker";
// import API from "../../utils/api";

// const Resetpswd = ({ rowData, closeModal }) => {
//     console.log("opened");
//     const [show2, setShow2] = useState(false);

//     const submitHandler = async () => {
//         const resp = await trackPromise(
//             API.post(`admin-module/api/v1/users/resetPassword`, { userID: rowData.userID })
//         );
//         { resp.success ? successNotification(resp.message) : errorNotification(resp.message) }
//     }
//     return (
//         <div>
//             {/* <div>
//         <button onClick={()=> setShow(!show)} >show</button>
//     </div>
//     {show &&  */}
//             <div className="mainblock">

//                 <div className="box1">
//                     <div className="header">
//                         <h3>Reset Password</h3>
//                     </div>
//                     <div className="box1body">
//                         {/* <h1>Animation</h1> */}
//                         <button onClick={() => setShow2(!show2)}>Click here to Reset password <IoMdUnlock id="lockicon" /></button>
//                     </div>
//                     <div className="footer">
//                         <button onClick={() => closeModal(false)}> <GiCancel id="cnclicon" /> Cancel </button>
//                     </div>
//                 </div>
//             </div>
//             {/* } */}

//             {show2 && <div className="mainblock2">
//                 <div className="box2">

//                     <div className="body2">
//                         <p>
//                             Do you really want to reset the<br />
//                             password of selected user account?
//                         </p>
//                     </div>

//                     <div className="footer2">
//                         <button onClick={() => setShow2(false)}>Back</button>
//                         <button id='confirm' onClick={submitHandler}>Confirm</button>
//                     </div>

//                 </div>
//             </div>
//             }

//         </div>

//     )

// }

// export default Resetpswd;