import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import RequestedBatteryQty from "./RequestedBatteryQty";
import moment from "moment";
import PurchaseOrderTemplate from "../../../orders/PurchaseOrderTemplate";
import DealerVehiclePO from "../../../RequisitionOrders/DealerVehiclePO";
import auth from "../../../../utils/auth";

const PODetails = ({ rowData, closeModal }) => {
  console.log("rowData-->",rowData)

  const [open, setOpen] = useState(false);
  const [batteryDetails, setBatteryDetails] = useState({});
  const [openPO, setPO] = useState(false);

  const SubTotal = (item) => {
    if (item.vehicleCategory == "Standard") {
      return (item.unitPrice * item.requiredQty)
    } else {
      return (item.unitPrice * item.requiredQty)
    }
  };

  const openBatteryDetails = (item) => {
    setOpen(true);
    setBatteryDetails(item)
  }



  return (
    <div className="container1">
      {
        openPO ? <DealerVehiclePO rowData={rowData} closeModal={setPO} type="vehicleData" /> : ""
      }
      <div
        className="modalContainer"
        style={{ width: "1280px", height: "500px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>PO Details</h3>
        </div>
       
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              // borderBottom: "0.1px solid grey",
              // marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
                 fontWeight:"100"
              }}
            >
              <div>PO To : {auth.getUserInfo().businessName}</div>
              <div>{rowData.poTo}</div>
              <div>{rowData?.poAddress}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
                fontWeight:"100"
              }}
            >
              <div>
                <span>
                  PO No. :
                </span>
                <span style={{ color: "blue", cursor: "pointer" }} onClick={() => setPO(true)}>
                  {rowData.poNo}
                </span>
              </div>
              <div>PO Date : {moment(rowData.poDate).format("DD-MMM-YYYY/HH:mm")}</div>
              <div>PO By : {rowData.poBy}</div>
              <div>{`PO Value (${auth.getUserInfo().currencySymbol})`} :{" "} {rowData.poValue}</div>
            </section>
          </section>
          <span style={{width:"100%" , height:"1px", margin:"7px 0px 7px 0px" , backgroundColor:"grey"}}></span>
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              // borderBottom: "0.1px solid grey",
              // marginBottom: "2%",
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
                fontWeight:"100"
              }}
            >
              <div style={{ fontWeight: "600" }}>Bill To : </div>
              <div>{rowData.businessName}</div>
              <div>{rowData.billingAddress.DealerAddress}</div>
              <div>Contact Person: {rowData.billingAddress.contactPersonName}</div>
              <div>Contact Number: {rowData.billingAddress.DealerAddress}</div>
              <div>PAN: {rowData.billingAddress.panNo}</div>
              <div>GSTIN: {rowData.billingAddress.gstin}</div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
                fontWeight:"100"
              }}
            >
              <div style={{ fontWeight: "600" }}>Ship To : </div>
              <div>{rowData.showroomName}</div>
              <div>{rowData.showroomLocation}</div>
              <div>Contact Person: {rowData.shippingContactPerson}</div>
              <div>Contact Number: {rowData.shippingContactNo}</div>
            </div>
          </section>

          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "fit-content",
                fontSize: "13px",
                height:"50px",
              }}
            >
              <div style={{ width: "50px", padding: "10px" }}>S.No.</div>
             <div style={{ width: "100px", padding: "10px" }}>Vehicle SKU</div>
              <div style={{ width: "150px", padding: "10px" }}>Vehicle Segment</div>
              <div style={{ width: "150px", padding: "10px" }}>Vehicle Category</div>
              {/* <div style={{ width: "100px", padding: "10px" }}>
                Vehicle Make
              </div> */}
              <div style={{ width: "150px", padding: "10px" }}>
                Vehicle Model
              </div>
              <div style={{ width: "150px", padding: "10px" }}>
                Vehicle Variant
              </div>
              <div style={{ width: "150px", padding: "10px" }}>
                Vehicle Type
              </div>
              <div style={{ width: "150px", padding: "10px" }}>
                Vehicle Color
              </div>
              <div style={{ width: "150px", padding: "10px" }}>
                Req. Vehicle Qty.
              </div>
              <div
                style={{ width: "150px", padding: "10px" }}
              >
               {`Vehicle Price/Unit (${auth.getUserInfo().currencySymbol})`}
              </div>
              <div
                style={{ width: "150px", padding: "10px" }}
              >
                Req. Battery Qty.
              </div>
              <div
                style={{ width: "150px", padding: "10px" }}
              >
              {`Battery Price/Unit (${auth.getUserInfo().currencySymbol})`}
              </div>
              <div
                style={{ width: "100px", padding: "10px" }}
              >
                {`Sub Total (${auth.getUserInfo().currencySymbol})`}
              </div>
            </div>
            <section style={{ width: "fit-content", height: "auto", }}>
              {rowData.vehicleData.map((item, index) => (
                <div
                  style={{
                    padding: "0px",
                    border: "1px solid #cdcdcd",
                    width: "",
                    fontSize: "13px",
                    display: "flex",
                    height:"40px"
                  }}
                >
                  <div style={{ width: "50px", padding: "10px" }}>{index + 1}</div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                     
                    }}
                  >
                    {item.vehicleSkuCode}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                     
                    }}
                  >
                    {item.vehicleSegment}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                     
                    }}
                  >
                    {item.vehicleCategory}
                  </div>
                  
                  {/* <div
                    style={{
                      width: "100px",
                      padding: "10px",
                     
                    }}
                  >
                    {item.vehicleMake}
                  </div> */}
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                     
                    }}
                  >
                    {item.vehicleModel}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                     
                    }}
                  >
                    {item.vehicleVariant}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                     
                    }}
                  >
                    {item.vehicleType}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                     
                    }}
                  >
                    {item.vehicleColor}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                     
                    }}
                  >
                    {item.requiredQty}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                     
                    }}
                  >
                    {item.unitPrice}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                     
                    }}
                  >
                    <p
                      style={{
                        fontSize: "14px",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => openBatteryDetails(item)}
                    >
                      {item.vehicleCategory == "Standard" ? "-" : item.batteryQty}
                    </p>

                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                     
                    }}
                  >
                    {item.vehicleCategory == "Standard" ? "-" : item?.batteryDetails?.batteryUnitPrice}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                     
                    }}
                  >
                    {SubTotal(item)}
                  </div>
                </div>
              ))}
            </section>
          </section>
          {open && <RequestedBatteryQty rowData={batteryDetails} closeModal={setOpen} />}
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default PODetails;
