import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import { errorNotification, successNotification } from "../../../../utils/notifications";
import DeliveryDetails from "../DeliveryDetails";

const AddVendorModal = ({ updatedData, rowData, closeModal }) => {
  const [delivery, setDelivery] = useState([]);
  const [vendorsData, setVendorsData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [openLocation, setOpenLocation] = useState(false);
  const [formValue, setFormValue] = useState(
    {
      vendorName: "",
      vendorCity: "",
      vendorPartName: "",
      vendorPartCode: "",
      deliveryDetails: []
    },
  );

  const [formDelivery, setFormDelivery] = useState(
    [{
      deliveryLocation: "",
      unitPrice: "",
      estdTime: "",
      duration: "",
    }]
  );

  const fetchReport = async () => {
    const resp = await trackPromise(API.get(`procurement-module/api/v1/mappings/getAllVendors`));
    console.log(resp);
    resp.success == false ? errorNotification(resp.message, { autoClose: 3000 }) : setVendorsData(resp);

    const response = await trackPromise(API.get(`procurement-module/api/v1/mappings/getStorageLocations`));
    console.log(response);
    response.success == false ? errorNotification(response.message, { autoClose: 3000 }) : setLocationData(response);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const submitHandler = async () => {
    let arrayLoc = [];
    delivery.map((item)=>{
      arrayLoc.push({
        "deliveryLocation":item.deliveryLocation,
        "duration":item.duration,
        "estdTime":item.estdTime,
        "unitPrice":item.unitPrice
      })
    });
    let vendorObj = { ...formValue, deliveryDetails:arrayLoc };
    let addVendorBody =
    {
      "componentID": rowData.componentID,
      "vendorDetails": vendorObj
    };
    const resp = await trackPromise(API.post(`procurement-module/api/v1/mappings/addVendor`,addVendorBody));
    closeModal(false);
    updatedData();
  };

  const handleChange = (e) => {
    setFormValue({ ...formValue, deliveryDetails: delivery });
    setDelivery([...delivery, { ...formDelivery, [e.target.name]: e.target.value }]);
  };
  const removeFields = (index) => {
    const field = [...delivery];
    field.splice(index, 1);
    setDelivery(field);
  };

  const deliveryLocation = (e) => {
    setOpenLocation(true);
    setFormDelivery({ ...formDelivery, [e.target.name]: e.target.value })
  }



  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: '830px' }}>
        <div className="header">
          <h3>Add Vendor</h3>
        </div>

        <section
          style={{
            width: '100%',
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
            margin: "10px 0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
            <span>Component Name : {rowData.componentName}</span>
            <span>Category : {rowData.category}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
            <span>Component SKU Code : {rowData.skuCode}</span>
            <span>Sub Category : {rowData.subCategory}</span>
          </div>

        </section>

        <div className="body" style={{ overflow: 'scroll' }}>
          <label>
            <select
              style={{ width: '180px' }}
              name="vendorName"
              defaultValue={formValue.vendorName}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option selected hidden>
                Vendor Name
              </option>
              {vendorsData.map((item) => (
                <option value={item.vendorName}>{item.vendorName}</option>
              ))}
            </select>
          </label>


          <label>
            <select
              style={{ width: '180px' }}
              name="vendorCity"
              defaultValue={formValue.vendorCity}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option selected hidden>
                Vendor Location
              </option>
              {vendorsData.map((item) => (
                <option>{item.city}</option>
              ))}
            </select>
          </label>

          <label>
            <input
              type="text"
              style={{ width: '180px' }}
              name="vendorPartName"
              defaultValue={formValue.vendorPartName}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Vendor Part Name"
              autoComplete="off"
            />
          </label>

          <label>
            <input
              type="text"
              style={{ width: '180px' }}
              name="vendorPartCode"
              defaultValue={formValue.vendorPartCode}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Vendor Part No."
              autoComplete="off"
            />
          </label>

          <div style={{ display: 'flex', width: '100%' }}>
            <select
              style={{ width: '48%' }}
              name="deliveryLocation"
              defaultValue={formDelivery.deliveryLocation}
              onChange={deliveryLocation}
            >
              <option value="" disabled selected>
                Delivery Location
              </option>
              {locationData.map((item) => (
                <option>{`${item.address1}, ${item.address2}, ${item.city}, ${item.zipcode}`}</option>
              ))}
            </select>

            <input
              type="text"
              style={{ width: '20%' }}
              name="unitPrice"
              defaultValue={formDelivery.unitPrice}
              placeholder="Unit Price"
              onChange={(e) => setFormDelivery({ ...formDelivery, [e.target.name]: e.target.value })}
            />
            <input
              type="text"
              style={{ width: "12.5%" }}
              name="estdTime"
              defaultValue={formDelivery.estdTime}
              placeholder="Estd. Time"
              onChange={(e) => setFormDelivery({ ...formDelivery, [e.target.name]: e.target.value })}
            />
            <select
              style={{ width: "12%" }}
              name="duration"
              defaultValue={formDelivery.duration}
              onChange={(e) => handleChange(e)}
            >
              <option>Duration</option>
              <option value="Day">Day</option>
              <option value="Week">Week</option>
              <option value="Month">Month</option>
            </select>
          </div>

          <div style={{ width: '96.5%', borderRadius: '5px', backgroundColor: '#ebebeb' }}>
            {delivery.map((item, index) => (
              <div style={{ display: 'flex' }}>
                <div style={{
                  width: '93%',
                  margin: '5px',
                  padding: '5px',
                  borderRadius: '5px',
                  backgroundColor: "white",
                  display: 'flex',
                  justifyContent: 'flex-start'
                }}>
                  <span style={{ display: 'inline-block', width: '57%', textAlign: 'left', overflow: "auto" }}>{item.deliveryLocation}</span>
                  <span style={{ display: 'inline-block', width: '25%', textAlign: 'left' }}>{item.unitPrice}</span>
                  <span>{`${item.estdTime} ${item.duration}`}</span>
                </div>
                <div>
                  <button
                    type="button"
                    style={{ borderRadius: "100%", border: "1px solid red", marginTop: '10px', width: "30px", height: "23px" }}
                    onClick={() => removeFields(index)}
                  >
                    <div style={{ color: "red" }}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  </button>
                </div>
              </div>
            ))}
          </div>

        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}><KeyboardBackspace />Back</button>
          <button id="submit-button" onClick={submitHandler}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddVendorModal;