import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import moment from "moment";
import ViewSpecs from "../../../bom/bom-viewspecs";
import DealerVehiclePO from "../../../RequisitionOrders/DealerVehiclePO";

const PODetails = ({ rowData, closeModal }) => {
  const [open, setOpen] = useState(false);
  const [specsData, setData] = useState({});
  const [openPO, setPO] = useState(false);

  const openSpecs = (item) => {
    setData(item);
    setOpen(true);
  };

  let sum = rowData?.requisitionDetails?.reduce((acc, curr) => {
    return acc + parseInt(curr.requireQty);
  }, 0);

  return (
    <div className="container1">
      {openPO ? <DealerVehiclePO rowData={rowData} closeModal={setPO} /> : ""}
      <div
        className="modalContainer"
        style={{ width: "1280px", height: "500px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>PO Details</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderBottom: "0.1px solid grey",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div style={{ fontWeight: "600" }}>Po To : </div>
              <div style={{ fontWeight: "600" }}>{rowData?.poTo}</div>
              <div style={{ fontWeight: "600" }}>{rowData?.poAddress}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div>
                <span>PO No. :</span>
                <span
                  style={{
                    fontWeight: "600",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() => setPO(true)}
                >
                  {rowData.poNo}
                </span>
              </div>
              <div style={{ fontWeight: "600" }}>
                PO Date : {moment(rowData?.poDate).format("DD-MMM-YYYY/HH:mm")}
              </div>
              <div style={{ fontWeight: "600" }}>PO By : {rowData?.poBy}</div>
              <div>
                Total Spares Requested: {rowData?.requisitionDetails?.length}
              </div>
              <div>Total Units Requested: {sum}</div>
              <div style={{ fontWeight: "600" }}>
                PO Value : {rowData?.poValue}
              </div>
            </section>
          </section>
          <br />

          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderBottom: "0.1px solid grey",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div style={{ fontWeight: "600" }}>Bill To : </div>
              <div style={{ fontWeight: "600" }}>{rowData?.billTo}</div>
              <div>{rowData?.billingAddress}</div>
              <div>Contact Person: {rowData?.billingContactPerson}</div>
              <div>Contact Number: {rowData?.billingContactNo}</div>
              <div>GSTIN: {rowData?.dealerGSTIN}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div style={{ fontWeight: "600" }}>Ship To : </div>
              <div style={{ fontWeight: "600" }}>{rowData?.shipmentTo}</div>
              <div>{rowData?.shipTo}</div>
              <div>Contact Person: {rowData?.shipmentContactPerson}</div>
              <div>Contact Number: {rowData?.shipmentContactNo}</div>
            </section>
          </section>

          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "fit-content",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "50px", padding: "10px" }}>S.No.</div>
              <div style={{ width: "200px", padding: "10px" }}>SKU Code</div>
              <div style={{ width: "200px", padding: "10px" }}>
                Spare Part Name
              </div>
              <div style={{ width: "200px", padding: "10px" }}>Category</div>
              <div style={{ width: "200px", padding: "10px" }}>
                Sub-Category
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Specifications
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Requested Qty.
              </div>
              <div style={{ width: "200px", padding: "10px" }}>Unit Price</div>
              <div style={{ width: "200px", padding: "10px" }}>Sub Total</div>
            </div>
            <section style={{ width: "fit-content", height: "auto" }}>
              {rowData?.requisitionDetails?.map((item, index) => (
                <div
                  style={{
                    padding: "0px",
                    border: "1px solid #cdcdcd",
                    width: "",
                    fontSize: "13px",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "50px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {index + 1}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.skuCode}
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    {item?.sparePartName}
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    {item?.category}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.subCategory}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "13px",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => openSpecs(item)}
                    >
                      View Specifications
                    </p>
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.requireQty}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.priceToserviceCenter}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.requireQty * item?.priceToserviceCenter}
                  </div>
                </div>
              ))}
            </section>
          </section>
          {open && <ViewSpecs rowData={specsData} close={setOpen} />}
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default PODetails;
