import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const PutOnHalt = ({ updatedData, rowData, closeModal, setIsOpen }) => {
  const [formValue, setFormValue] = useState({
    reason: "",
    description: "",
    haltDate: "",
  });


  console.log("formValue",formValue)



  const editDetails = {
    jobID: rowData.jobID,

    status: "Job On-Halt",

    reason: formValue.reason,

    description: formValue.description,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    console.log(formValue);
    const resp = await trackPromise(
      API.post(
        `serviceAdvisor-module/api/v1/serviceJobs/updateStatus`,
        editDetails
      )
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      setIsOpen(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const closeHandler = () => {
    setIsOpen(false);
    closeModal(false);
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Put On halt</h3>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h7>Job Card ID : {rowData.jobID}</h7>
            <h7 style={{ textAlign: "start" }}>
              Vehicle Reg. No. {rowData.vehicleRegNo}
            </h7>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h7>
              Job Card Date :{" "}
              {moment(rowData.jobDate).format("DD-MMM-YYYY/HH:mm")}
            </h7>
          </div>
        </div>

        <div
          className="body"
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            marginTop: "6px",
          }}
        >
          <label
            style={{ display: "flex", flexDirection: "column", width: "80%" }}
          >
            <label
              style={{ fontSize: "13px", fontWeight: "500", width: "83px" }}
            >
              Put On Halt<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="reason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: "100%", marginTop: "-5px" }}
            >
              <option disabled selected hidden>
                Select Reason
              </option>
              <option value="Remaining Spare not received">
                Remaining Spare not received
              </option>
              <option value="Electricity Issue">Electricity Issue</option>
              <option value="Short of Manpower">Short of Manpower</option>
              <option value="Warranty Void">Warranty Void</option>
              <option value="Others">Others</option>
            </select>
          </label>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <label
              style={{ display: "flex", flexDirection: "column", width: "80%" }}
            >
              <label
                style={{
                  float: "left",
                  fontSize: "13px",
                  fontWeight: "500",
                  textAlign: "start",
                }}
              >
                Halt Date<span style={{ color: "red" }}>*</span>
              </label>

              <input
                className="input-config"
                id="chargesPerUser"
                type="date"
                name="haltDate"
                placeholder="Select"
                autoComplete="off"
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                style={{
                  marginTop: "-8px",
                  width: "100%",
                }}
              />
            </label>
          </div>

          {formValue.reason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Description"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={submitHandler}
            disabled={formValue.reason == ""  || formValue.haltDate == "" ? true : false}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PutOnHalt;
