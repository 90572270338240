import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelOutlined, CheckCircle } from "@material-ui/icons";
import {
  faFileUpload,
  faPlus,
  faTrashAlt,
  faTimes,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import { useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import {
  errorNotification,
  infoNotification,
  successNotification,
} from "../../utils/notifications";
import API from "../../utils/api";
import QueryTable from "./QueryTable";



const ViewQuery = () => {
  const { register, handleSubmit, errors } = useForm();
  const [data, setData] = useState([]);
  const [serialData, setSerialData] = useState([]);
  const [blankForm, setBlankForm] = useState([
    {
      priority: "",
      category: "",
      subcategory: "",
      description: "",
    },
  ]);

  console.log("blankForm-->", blankForm);

  const InputValidate = () => {
    const numericRegex = /^\d+$/;

    const isValid = blankForm.every((formEntry) => {
      if (!formEntry.description || !formEntry.yearlyCharges) {
        errorNotification("All fields are required.");
        return false;
      }

      if (!numericRegex.test(formEntry.description)) {
        errorNotification("Monthly charges must be a numeric value.");
        return false;
      }

      if (!numericRegex.test(formEntry.yearlyCharges)) {
        errorNotification("Yearly charges must be a numeric value.");
        return false;
      }

      return true;
    });

    return isValid;
  };

  const submitHandler = async () => {
    if (!InputValidate()) {
      return;
    }

    const finalObj = blankForm;

    const result = await trackPromise(
      API.post(`systemadmin/api/v1/addUserCharges`, finalObj)
    );
    if (result.success) {
      successNotification(result.message, { autoClose: 3000 });
    } else {
      errorNotification(result.message, { autoClose: 3000 });
    }

    setBlankForm([
      {
        priority: "",
      category: "",
      subcategory: "",
      description: "",
      },
    ]);
    document.getElementById("priority").value = "";
    document.getElementById("category").value = "";
    document.getElementById("subcategory").value = "";
    document.getElementById("description").value = "";
  };

  let addFormFields = () => {
    setBlankForm([
      ...blankForm,
      {
        priority: "",
      category: "",
      subcategory: "",
      description: "",
      },
    ]);
  };

  const removeFields = (index) => {
    const field = [...blankForm];
    field.splice(index, 1);
    setBlankForm(field);
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const field = [...blankForm];
    field[index][name] = value;
    setBlankForm(field);
  };

  // const apiHandler = async (event, index) => {
  //   const response = await trackPromise(
  //     API.get(
  //       `systemadmin/api/v1/addUserCharges?componentName=${event.target.value}`
  //     )
  //   );
  //   console.log(response);
  //   setSerialData(response);

  //   const final = [...blankForm];
  //   final[index]["skuCode"] =
  //     response && response[0] ? response[0].skuCode : "";
  //   final[index]["componentID"] = response[0].componentID;
  //   setBlankForm(final);
  // };

  console.log(serialData);

  const create = async (loginDetails) => {
    setBlankForm([
      {
        priority: "",
        category: "",
        subcategory: "",
        description: "",
      },
    ]);
    document.getElementById("priority").value = "";
    document.getElementById("category").value = "";
    document.getElementById("subcategory").value = "";
    document.getElementById("description").value = "";
  };
  const cancel = (e) => {
    setBlankForm([
      {
        priority: "",
      category: "",
      subcategory: "",
      description: "",
      },
    ]);
    document.getElementById("priority").value = "";
    document.getElementById("category").value = "";
    document.getElementById("subcategory").value = "";
    document.getElementById("description").value = "";
  };

  return (
    <>
      <div className="bussiness">
        <h4 className="heading">View Query</h4>
      </div>
      <form
        id="add-user-from"
        className="setting-form"
        onSubmit={handleSubmit(create)}
        style={{marginBottom:"60px"}}
      >
        {blankForm.map((item, index) => {
          return (
            <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
              <div
                style={{
                  margin: "0px 5px 0px 0px",
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    marginLeft: "6px",
                  }}
                >
                  Priority<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  required={true}
                  name="priority"
                  id="priority"
                  className="input-config"
                  defaultValue={item.priority}
                  // onChange={handleAssemblyTypeChange}
                  style={{ marginTop: "-8px", width: "100%" }}
                  onChange={(event) => {
                    handleInputChange(event, index);
                  }}
                >
                  <option value="" disabled selected>
                    Priority
                  </option>
                  <option value="option1">Option1</option>
                  <option value="option2">Option2</option>
                </select>
              </div>

              <div
                style={{
                  margin: "0px 5px 0px 0px",
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    marginLeft: "6px",
                  }}
                >
                  Select Category<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  required={true}
                  name="category"
                  id="category"
                  className="input-config"
                  defaultValue={item.category}
                  // onChange={handleAssemblyTypeChange}
                  style={{ marginTop: "-8px", width: "100%" }}
                  onChange={(event) => {
                    handleInputChange(event, index);
                  }}
                >
                  <option value="" disabled selected>
                    Select Category
                  </option>
                  <option value="option1">Option1</option>
                  <option value="option2">Option2</option>
                </select>
              </div>

              <div
                style={{
                  margin: "0px 5px 0px 0px",
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    marginLeft: "6px",
                  }}
                >
                  Select Sub Category<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  required={true}
                  name="subcategory"
                  id="subcategory"
                  className="input-config"
                  defaultValue={item.subcategory}
                  // onChange={handleAssemblyTypeChange}
                  style={{ marginTop: "-8px", width: "100%" }}
                  onChange={(event) => {
                    handleInputChange(event, index);
                  }}
                >
                  <option value="" disabled selected>
                    Select Sub Category
                  </option>
                  <option value="option1">Option1</option>
                  <option value="option2">Option2</option>
                </select>
              </div>

              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    marginLeft: "6px",
                  }}
                >
                  Description <span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  // disabled={blankForm.assemblyType ? false : true}
                  className="input-config"
                  required
                  id="description"
                  type="text"
                  name="description"
                  defaultValue={item.description}
                  // placeholder="description"
                  autoComplete="off"
                  style={{ marginTop: "-8px", width: "100%" }}
                  onChange={(event) => {
                    handleInputChange(event, index);
                  }}
                  
                />
              </div>
              
            </div>
          );
        })}
        <br />
        <div className="save-cancel">
          <div className="cust-reset  cancel-button">
            <button type="button" className="btn btn-icon-label">
              <div
                style={{
                  fontSize: "14px",
                  color: "#0A18A3",
                  fontWeight: "500",
                }}
                onClick={(e) => {
                  cancel(e);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
                Cancel
              </div>
            </button>
          </div>
          <div className=" cancel-button">
            <button className="btn btn-icon-label" type="submit">
              <div
                style={{
                  fontSize: "14px",
                  color: "#0A18A3",
                  fontWeight: "500",
                }}
                onClick={() => {
                  submitHandler();
                }}
              >
                <CheckCircle style={{ fontSize: "17px" }} />
                Submit
              </div>
            </button>
          </div>
        </div>
      </form>

      <QueryTable/>
    </>
  );
};
export default ViewQuery;
