import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../components/modal/modal.scss";
import moment from "moment";
import SearchBox from "../../components/search/SearchBox";
import handleExport from "../../utils/exportExcel";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AppConstants from "../../utils/app-constants";
import { errorNotification } from "../../utils/notifications";

const { ExportCSVButton } = CSVExport;

function ProdManageVehicles({ inDashboard }) {
  const { SearchBar } = Search;
  const [UsersData, setUsersData] = useState([]);
  

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `production-module/api/v1/vehicles?vehicleSegment=${formValue.vehicleSegment}&vehicleType=${formValue.vehicleType}&vehicleCategory=${formValue.vehicleCategory}&vehicleVariant=${formValue.vehicleVariant}&vehicleModel=${formValue.vehicleModel}`
      )
    );
    console.log(resp);
    {
      resp.success == false
        ? errorNotification(resp.message, { autoClose: 3000 })
        : setUsersData(resp);
    }
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const statusFormatter = (cell) => {
    return cell == AppConstants.ACTIVE ? (
      <button className="active-btn">
        <span className="active-btn-font">Active</span>
      </button>
    ) : cell == AppConstants.DEACTIVATED ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">Deactivated</span>
      </button>
    ) : cell == AppConstants.REACTIVATED ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">Reactivated</span>
      </button>
    ) : cell == AppConstants.DELETED ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Deleted</span>
      </button>
    ) : (
      cell
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return (
      `${moment(date).format("DD-MMM-YYYY")}` 
    );
  }

  const reasonFormatter = (cell, row) => {
    return row.status == AppConstants.ACTIVE ? (
      <span className="active-btn-font">-</span>
    ) : row.status == AppConstants.DEACTIVATED ? (
      <span cla>{row.deactivationReason}</span>
    ) : row.status == AppConstants.REACTIVATED ? (
      <span>{row.reactivationReason}</span>
    ) : row.status == AppConstants.DELETED ? (
      <span>{row.deletedReason}</span>
    ) : (
      cell
    );
  };

  const uniqueVehicleModelArray = [
    ...new Set(UsersData.map((item) => item.vehicleModel)),
  ];
  const uniqueVehicleCategoryArray = [
    ...new Set(UsersData.map((item) => item.vehicleCategory)),
  ];
  const uniqueVehicleVariantArray = [
    ...new Set(UsersData.map((item) => item.vehicleVariant)),
  ];
  const uniqueVehicleColorArray = [
    ...new Set(UsersData.map((item) => item.vehicleColor)),
  ];
  const uniqueVehicleSegmentArray = [
    ...new Set(UsersData.map((item) => item.vehicleSegment)),
  ];
  const uniqueVehicleTypeArray = [
    ...new Set(UsersData.map((item) => item.vehicleType)),
  ];

  const validateField = (field, defaultValue = "") => {
    return field !== undefined && field !== null ? field : defaultValue;
  };

  const exportData = UsersData?.map((item, index) => {
    let reason = "N/A"; // Default value for Reason
    
    // Directly implement the logic for 'Reason' field based on the status
    if (item?.status === AppConstants.ACTIVE) {
      reason = "-";
    } else if (item?.status === AppConstants.DEACTIVATED) {
      reason = item?.deactivationReason || "N/A";
    } else if (item?.status === AppConstants.REACTIVATED) {
      reason = item?.reactivationReason || "N/A";
    } else if (item?.status === AppConstants.DELETED) {
      reason = item?.deletedReason || "N/A";
    }
    
    return {
      "S.No.": index + 1, // Serial number based on the array index
      Segment: item?.vehicleSegment || "N/A", // Segment value or fallback
      Category: item?.vehicleCategory || "N/A", // Category value or fallback
      Color: item?.vehicleColor || "N/A", // Color value or fallback
      Type: item?.vehicleType || "N/A", // Type value or fallback
      Model: item?.vehicleModel || "N/A", // Model value or fallback
      Variant: item?.vehicleVariant || "N/A", // Variant value or fallback
      "SKU Code": item?.vehicleSkuCode || "N/A", // SKU Code value or fallback
      "Added On": item?.createdDate
        ? moment(item.createdDate).format("YYYY-MM-DD HH:mm:ss")
        : "Invalid Date", // Formatted date or fallback
      Status: item?.status || "Unknown", // Status value or fallback
      Reason: reason, // Apply the reason based on the logic above
    };
  });
  
  
  
  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const index = UsersData.findIndex((value) => value == row);
        return index !== -1 ? index + 1 : "N/A"; // Validating index
      },
      sort: true,
    },
    {
      text: "Segment",
      dataField: "vehicleSegment",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "120px" },
      classes: "alignment",
      formatter: (cell, row) => validateField(row.vehicleSegment, "N/A"),
    },
    {
      text: "Category",
      dataField: "vehicleCategory",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "120px" },
      classes: "alignment",
      formatter: (cell, row) => validateField(row.vehicleCategory, "N/A"),
    },
    {
      text: "Color",
      dataField: "vehicleColor",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "120px" },
      classes: "alignment",
      formatter: (cell, row) => validateField(row.vehicleColor, "N/A"),
    },
    {
      text: "Type",
      dataField: "vehicleType",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "120px" },
      classes: "alignment",
      formatter: (cell, row) => validateField(row.vehicleType, "N/A"),
    },
    {
      text: "Model",
      dataField: "vehicleModel",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "120px" },
      classes: "alignment",
      formatter: (cell, row) => validateField(row.vehicleModel, "N/A"),
    },
    {
      text: "Variant",
      dataField: "vehicleVariant",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "120px" },
      classes: "alignment",
      formatter: (cell, row) => validateField(row.vehicleVariant, "N/A"),
    },
    {
      text: "SKU Code",
      dataField: "vehicleSkuCode",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "120px" },
      classes: "alignment",
      formatter: (cell, row) => validateField(row.vehicleSkuCode, "N/A"),
    },
    {
      text: "Added On",
      dataField: "createdDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "120px" },
      classes: "alignment",
      formatter: (cell, row) => {
        const date = validateField(row.createdDate);
        return date ? dateFormatter(date) : "Invalid Date";
      },
    },
    {
      text: "Status",
      dataField: "status",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "140px" },
      classes: "alignment",
      formatter: (cell, row) => {
        const status = validateField(row.status, "Unknown");
        return statusFormatter(status);
      },
    },
    {
      text: "Reason",
      classes: "cellsOverflow",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "350px" },
      classes: "alignment",
      formatter: reasonFormatter,
    },
  ];
  

  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    vehicleSegment: "",
    vehicleModel: "",
    vehicleVariant: "",
    vehicleType: "",
    vehicleCategory: "",
  });
  //  const [icon,setIcon]=useState(<FaFilter/>)

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      vehicleSegment: "",
      vehicleModel: "",
      vehicleVariant: "",
      vehicleType: "",
      vehicleCategory: "",
    });

    const resp = await trackPromise(
      API.get(`production-module/api/v1/vehicles`)
    );
    {
      resp.success == false
        ? errorNotification(resp.message, { autoClose: 3000 })
        : setUsersData(resp);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };
  const handleClick = (e) => {
    setShow(!show);
  };

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Vehicles</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(exportData, "Vehicles")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <>
                    <form
                      onSubmit={submitHandler}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: "135px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                      }}
                      className="UHinputfields VMform"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          Segment
                        </label>
                        <select
                          name="vehicleSegment"
                          value={formValue.vehicleSegment}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Segment
                          </option>
                          {uniqueVehicleSegmentArray.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Model
                        </label>
                        <select
                          name="vehicleModel"
                          value={formValue.vehicleModel}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Model
                          </option>
                          {uniqueVehicleModelArray.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Variant
                        </label>
                        <select
                          name="vehicleVariant"
                          value={formValue.vehicleVariant}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Variant
                          </option>
                          {uniqueVehicleVariantArray.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Type
                        </label>
                        <select
                          name="vehicleType"
                          value={formValue.vehicleType}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Type
                          </option>
                          {uniqueVehicleTypeArray.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Category
                        </label>
                        <select
                          name="vehicleCategory"
                          value={formValue.vehicleCategory}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Category
                          </option>
                          {uniqueVehicleCategoryArray.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>

                      <div
                        style={{
                          width: "75%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          onClick={fetchReport}
                          style={{
                            width: "100px",
                            marginTop: "24px",
                            float: "left",
                          }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{
                            width: "100px",
                            marginTop: "24px",
                            float: "left",
                          }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </form>
                  </>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

export default ProdManageVehicles;
