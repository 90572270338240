import React, { useEffect, useState } from 'react';
import Group23 from '../QcDashboard/assets/Group23.svg';
import Group2 from '../QcDashboard/assets/Group2.png';
import Group24 from '../QcDashboard/assets/Group24.svg';
import Group26 from '../QcDashboard/assets/Group26.svg';
import Group28 from '../QcDashboard/assets/Group28.svg';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';

function NationalSpareReturnCard({ prop }) {

    const [data, setData] = useState({});
    const [spareData, setSpareData] = useState({});

    const fetchData = async () => {
        const res = await trackPromise(
            API.get(`nationalSales-module/api/v1/dashboard/vehicleReturns`)
        );
        setData(res);
    };
    const fetchSpareData = async () => {
        const res = await trackPromise(
            API.get(`nationalSales-module/api/v1/dashboard/spareReturns`)
        );
        setSpareData(res);
    };
    useEffect(() => {
        { prop == "vehicle" ? fetchData() : fetchSpareData() }
    }, []);

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430", marginTop: "30px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <img style={{ marginTop: "-10px", height: "40px" }} src={Group23} />&nbsp;&nbsp;
                    <span>{prop == "vehicle" ? "Total Vehicle Returns" : "Total Spare Returns"}</span>
                </div>
                <div style={{ fontSize: "18px" }}>
                    {prop == "vehicle" ? data.totalVehicles : spareData.totalSpare}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <img style={{ marginTop: "-10px", height: "40px" }} src={Group24} />&nbsp;&nbsp;
                    <span>New Requests</span>
                </div>
                <div>
                    {prop == "vehicle" ? data.newRequest : spareData.newRequest}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <img style={{ marginTop: "-10px", height: "40px" }} src={Group26} />&nbsp;&nbsp;
                    <span>In-Progress at Inventory</span>
                </div>
                <div>
                    {prop == "vehicle" ? data.inProgressAtInventory : spareData.inProgressAtInventory}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <img style={{ marginTop: "-10px", height: "40px" }} src={Group2} />&nbsp;&nbsp;
                    <span>Verification Completed</span>
                </div>
                <div>
                    {prop == "vehicle" ? data.verificationCompleted : spareData.verificationCompleted}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <img style={{ marginTop: "-10px", height: "40px" }} src={Group28} />&nbsp;&nbsp;
                    <span>Pending at Finance</span>
                </div>
                <div>
                    {prop == "vehicle" ? data.pendingAtInventory : spareData.pendingAtInventory}
                </div>
            </div>
        </>
    )
}

export default NationalSpareReturnCard