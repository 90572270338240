import React, { useState } from "react";
import "../../work-order/workorder.scss";
import { KeyboardBackspace } from '@material-ui/icons';
import auth from "../../../utils/auth";

const ViewAssemblyBom = ({ close, assemblydata }) => {

  const assemblyServices = (item) => {
    return item.assemblyServices.map((list) => {
      return (
        <>
          <ul>
            <li style={{ listStyle: "none" }}>{list.serviceName}</li>
          </ul>
        </>
      );
    })
  }

  const requiredDuration = (item) => {
    return item.assemblyServices.map((list) => {
      return (
        <>
          <ul>
            <li style={{ listStyle: "none" }}>{list.timeDuration} {list.time}</li>
          </ul>
        </>
      );
    })
  }

  
  const cost = (item) => {
    return item.assemblyServices.map((list) => {
      return (
        <>
          <ul>
            <li style={{ listStyle: "none" }}>{list.serviceCost} Per Day</li>
          </ul>
        </>
      );
    })
  }

  const sum = (item) => {
    
    return item.assemblyServices.map((list) => {
      return (
        <>
          <ul>
            <li style={{ listStyle: "none" }}>{calculateDailyCost(list.serviceCost,list?.time,list?.timeDuration)}</li>
          </ul>
        </>
      );
    })
  }

 


  const calculateDailyCost = (serviceCost, time, timeDuration) => {
    console.log("check ther data",serviceCost, time, timeDuration,"-------------")
    switch (time) {
      case "Week":
        return serviceCost * 7 * Number(timeDuration)   // Convert weekly cost to daily cost
      case "Month":
        return serviceCost * 30 *  Number(timeDuration)  // Convert monthly cost to daily cost
      case "Year":
        return serviceCost * 365 * Number(timeDuration) ; // Convert yearly cost to daily cost
      default:
        return serviceCost * Number(timeDuration)  // If "Day", return the cost as is
    }
  };
  console.log("testing console")

  const totalPrice = (item) => {
    let total = 0;
    item.assemblyServices.map((list) => {
      total+= calculateDailyCost(Number(list?.serviceCost), list?.time, list?.timeDuration )
  })
    return total
  }

  const calculateTotalPrice = ()=>{
    let total = 0
    assemblydata?.map((item)=>{
      total+= calculateDailyCost((Number(item?.serviceCost), item?.time, item?.timeDuration) )
      
    })
    return total
  }



  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "1000px", height: "450px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Assembly Servicesssss</h3>
            {/* <div>
              <button className="action-btn" style={{ color: "blue" }}>
                Print
              </button>
              <button className="action-btn" style={{ color: "blue" }}>
                Export
              </button>
            </div> */}
          </div>
          {assemblydata.map((item) => {
            return (
              <>
                <div className="shopfloortbl" style={{ width: "100%" }}>
                  <table className="sfaction_tbl" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{fontSize:"14px"}}>Assembly Type</th>
                        <th style={{fontSize:"14px"}}>Assembly Name</th>
                        <th style={{fontSize:"14px"}}>Required Services</th>
                        <th style={{fontSize:"14px"}}>Required Time</th>
                        <th style={{fontSize:"14px"}}>{`Cost(${auth.getUserInfo().currencySymbol})`}</th>
                        <th style={{fontSize:"14px"}}>{`Sub Total (${auth.getUserInfo().currencySymbol})`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{fontSize:"13px"}}>
                          {item.assemblyType}
                        </td>
                        <td style={{fontSize:"13px"}}>{item.assemblyName}</td>
                        <td style={{fontSize:"13px"}}>{assemblyServices(item)}</td>
                        <td style={{fontSize:"13px"}}>{requiredDuration(item)}</td>
                        <td style={{fontSize:"13px"}}>{cost(item)}</td>
                        <td style={{fontSize:"13px"}}>{sum(item)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <section
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    borderRadius: "5px",
                    margin: "5px 0",
                    height: "35px",
                  }}
                >
                  <div
                    style={{
                      width: "200px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {`Total Price (${auth.getUserInfo().currencySymbol}) : `}
                    <span
                      style={{
                        width: "100px",
                        background: "#ebebeb",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {totalPrice(item)}
                    </span>
                  </div>
                </section>
              </>
            );
          })}

          <br />
          <div className="footer">
            <button onClick={() => close(false)}><KeyboardBackspace />&nbsp;Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAssemblyBom;
