import React, { useState } from "react";
import "../../../components/modal/modal.scss";
import InvoiceHistory from "./InvoiceHistory";


const DealerActions = ({rowData,extraData, status}) => {
  const [showModal, setShowModal] = useState(false);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      approve: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };


  return (
    <div>

{showModal && activeModal.approve && <InvoiceHistory rowData={rowData} closeModal={setShowModal}/>}

      <div style={{ position: "relative" }}>
        <div className="action vendoraction">
                     <div>
                     <span id="approve" onClick={(e) => clickHandler(e)}>
                       View Invoice History
                     </span>
                   </div>
          
        </div>
      </div>
    </div>
  );
};

export default DealerActions;
