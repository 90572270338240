import React, { useState } from 'react';
import { Backspace, CancelOutlined, KeyboardBackspace } from '@material-ui/icons';
import moment from 'moment';
import ConfirmRelease from './ConfirmRelease';


const ViewData = ({ rowData, closeModal, extraData }) => {
    const [openConfirm, setOpenConfirm] = useState(false)
    console.log(rowData)
    let total = rowData.requisitionDetails.reduce(
        (accumulator, currentValue) => accumulator + parseInt(currentValue.requiredQty),
        0
    );

    return (
        <div className="container1">
            {openConfirm && <ConfirmRelease closeModal={setOpenConfirm} />}
            <div
                className="modalContainer"
                style={{ width: "600px", height: "430px", padding: '15px' }}
            >
                <div className="header" style={{ width: "100%" }}>
                    <h3 style={{ fontSize: "18px", marginRight: "68%" }}>View Details</h3>
                </div>

                <section
                    style={{
                        width: '100%',
                        display: "flex",
                        padding: "10px",
                        flexDirection: "column",
                        fontSize: "14px",
                        fontWeight: "500",
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: 'flex-start' }}>
                            <span>Requisition ID : {rowData.requisitionID}</span>
                            <span>Requisition Date : {moment(rowData.requisitionDate).format("DD-MMM-YYYY/HH:mm")}</span>
                            <span>Requested To : {rowData?.requestedTo}</span>
                        </div>
                        {!extraData && (
                            <span style={{ borderRadius: "8px", height: "32px", width: "82px", background: "#FFDDB6", color: "#E37D04", alignItems: "center", justifyContent: "center", display: "flex" }}>On Hold</span>

                        )}

                        {extraData == "rejected" && (
                            <span style={{ borderRadius: "8px", height: "40px", width: "158px", background: "#FFE1E1", color: "#D0021B", alignItems: "center", justifyContent: "center", display: "flex" }}>Requisition Rejected</span>

                        )}

                        {
                            extraData == "completed" && (
                                <span style={{ borderRadius: "8px", height: "35px", width: "150px", background: "rgba(17, 247, 40, 0.26)", color: "#11781b", alignItems: "center", justifyContent: "center", display: "flex" }}>PO Generated</span>

                            )
                        }

                    </div>
                </section>
                <br />
                {!extraData && (
                    <label style={{ justifyContent: "flex-start", alignItems: 'flex-start', display: 'flex', paddingLeft: "10px" }}>On Hold Details</label>

                )}
                {extraData == "rejected" && (
                    <label style={{ justifyContent: "flex-start", alignItems: 'flex-start', display: 'flex', paddingLeft: "10px" }}>Rejection Details</label>

                )}
                {extraData == "completed" && (
                    <label style={{ justifyContent: "flex-start", alignItems: 'flex-start', display: 'flex', paddingLeft: "10px" }}>PO Details</label>

                )}
                <div className="body" style={{ padding: "10px" }}>
                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0",
                            padding: "5px",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
                        }}
                    >

                        <div style={{ lineHeight: "1.6" }}>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Total Spare Requested : {rowData.requisitionDetails.length}</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Total Units Requested : {total}</span>
                            </div>

                            {!extraData && (
                                <>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>On Hold Date : {moment(rowData?.onHoldDate).format("DD-MMM-YYYY/HH:mm")}</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>On Hold Reason : {rowData.onHoldReason}</span>
                                    </div>
                                </>
                            )}
                            {extraData == "rejected" && (
                                <>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Rejection Date : {moment(rowData?.rejectionDate).format("DD-MMM-YYYY/HH:mm")}</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Rejection Reason : {rowData.rejectionReason}</span>
                                    </div>
                                </>
                            )}

                            {
                                extraData == "completed" && (
                                    <>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Sales Manager : { }</span>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>PO No. : {rowData.poNo}</span>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>PO Date : {moment(rowData?.poDate).format("DD-MMM-YYYY/HH:mm")}</span>
                                        </div>
                                    </>

                                )
                            }

                        </div>
                    </section>


                </div>

                <div className="footer" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "7%", marginLeft: "67%" }}>
                    <div style={{display:"flex"}}>
                        <button
                            style={{
                                border: "1px solid #0A18A3",
                                borderRadius: "3px",
                                padding: "5px 15px",
                                marginRight: "10px",
                                color: "#0A18A3",
                                width: '152px'
                            }}
                            onClick={() => closeModal(false)}
                        >
                            <KeyboardBackspace style={{ color: "#0A18A3", fontSize: "28px" }} />
                            Back
                        </button>
                        {!extraData && (
                            <>
                                <button
                                    style={{
                                        borderRadius: "3px",
                                        padding: "5px 15px",
                                        marginRight: "10px",
                                        color: "#fff",
                                        width: '185px',
                                        background: "#0A18A3"
                                    }}
                                    onClick={() => setOpenConfirm(true)}
                                >
                                    Request For Release
                                </button>
                            </>)}
                    </div>

                </div>
            </div>
        </div>
    )
};

export default ViewData;