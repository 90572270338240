import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import moment from "moment";
import TaxInvoice from "../ServiceAdvisorServiceJobs/Components/TaxInvoice";

const ViewDetails = ({ closeModal, rowData }) => {

    console.log("rowData",rowData)

    const [ invoiceNo, setInvoiceNo]= useState(false)

    return (
        <div className="container1">
        {
            invoiceNo ? <TaxInvoice rowData={rowData} closeTaxInvoice={setInvoiceNo}/>:""
        }
            <div id="overlay" onClick={(e) => off(e)}></div>
            <div
                className="modalContainer"
                style={{ width: "737px", height: "330px", padding: '15px' }}
            >
            
                <div className="header">
                    <h3>Service Jobs</h3>
                </div>


                <section
                    style={{
                        width: '100%',
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "14px",
                        fontWeight: "500",
                        // margin: "10px 0",
                        fontFamily: "sans-serif",
                        fontWeight: "420",
                        padding: '5px'
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Job ID : {rowData.jobID}</span>
                        <span>Customer Name : {rowData.customerDetails.customerName}</span>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Job Type : {rowData.serviceDetails.serviceType}</span>
                        <span>Customer Phone : {rowData.customerDetails.customerPhone}</span>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Job Date : {moment(rowData.jobDate).format("DD-MMM-YYYY,HH:mm")}</span>
                        <span>Customer Email : {rowData.customerDetails.customerEmail}</span>
                    </div>

                    <div style={{ display: "flex", justifyContent: "initial" }}>
                    <span>Invoice No. : </span>
                        <span 
                         style={{
                            fontSize: "14px",
                            color: "blue",
                            cursor: "pointer",
                            }}
                            onClick={()=>setInvoiceNo(true)}
                        >{rowData?.invoiceNo} </span>
                    </div>
                </section>

                <hr />

                <section
                    style={{
                        width: '100%',
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "14px",
                        fontWeight: "500",
                        // margin: "10px 0",
                        fontFamily: "sans-serif",
                        fontWeight: "420",
                        padding: '5px'
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Vehicle Reg. No. : {rowData.vehicleRegNo}</span>
                        <span>Vehicle Model : {rowData.vehicleModel}</span>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Vehicle Variant : {rowData.vehicleVariant}</span>
                        <span>Service Advisory : {rowData.serviceAdvisorName}</span>
                    </div>

                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Spare Parts : {parseInt(rowData.serviceDetails.mandatoryInspectionCheck.length) + parseInt(rowData.serviceDetails.additionalChecks.length)}</span>
                    </div> */}
                </section>

                <div className="footer">
                    <button
                        style={{
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                            padding: "5px 15px",
                            marginRight: "10px",
                            color: "#0A18A3",
                        }}
                        onClick={() => closeModal(false)}
                    >
                        <KeyboardBackspace style={{ fontSize: "17px" }} />
                        Back
                    </button>
                </div>
            </div>
        </div>
           
    );
};

export default ViewDetails;
