import React, { useState } from "react";
import "../../work-order/workorder.scss";

const GINdetails = ({ close, rowdata }) => {
    console.log(rowdata.requestType);
 
  return (
    <>
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "775px", height:"365px", padding: "15px" }}
      >
        <div className="header">
          <h3>GIN Details</h3>
        </div>

    <div className="body" style={{height:"315px"}}> 

    <div
        style={{
            width:"100%",
          display: "flex",
          flexDirection: "column",
          fontSize: "16px",
          fontWeight: "500",
          marginTop:"10px",
          padding:"12px",
          border:"2px solid lavender",
          borderRadius:"5px"
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>GIN No: -</span>
          {rowdata.requestType=="New Goods Verification" ?
          <span>Received By:someone</span>
          :
          <span>Shipped From: somewhere</span>
         }
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>GIN Date:</span>
          {rowdata.requestType=="New Goods Verification" ?
          <span>Received Date:7789</span>
          :
          <span>Shipment Date:-</span>
          }
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>GIN Created By: {"someone"}</span>
          {rowdata.requestType=="New Goods Verification" ?
          null
          :
          <span>Receipt Location: Somewhere</span>
          }
        </div>
        {rowdata.requestType=="New Goods Verification" ?
        <div style={{ display: "flex",flexDirection:"column",textAlign:"left",marginTop:"20px"}}>
        <span>Delivery Location:somewhere</span>
        <span>Received Qty:-</span> 
       </div>
         :
         <>
        <div style={{ display: "flex",flexDirection:"column",textAlign:"left",marginTop:"20px"}}>
         <span>Received Qty:-</span> 
         <span>Received Date</span>
        </div>
        <div style={{ display: "flex",flexDirection:"column",textAlign:"left",marginTop:"20px"}}>
         <span>Returned Qty: -</span> 
         <span>Returned Date: -</span>
        </div>
        </>  }
        
      </div>
    </div>

        <br />
        <div className="footer">
          <button onClick={() => close(false)}>Back</button>
        </div>
      </div>
    </div>
    </>
  );
};

export default GINdetails;