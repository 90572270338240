import React, { useEffect, useState } from 'react'
import "../subscriptionTabs/subscription.scss";
import { IoEyeSharp } from "react-icons/io5";
import { GrCycle } from "react-icons/gr";
import { BiTransfer } from "react-icons/bi";
import ViewDetails from './ViewDetails';
import { Link } from 'react-router-dom';
import SubscribePlann from '../subscriptionTabs/SubscribePlann';
import { TabLabels } from '../../../utils/app-constants';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from './ConfirmationModal';



const ActionsModal = ({ setIsOpen, Data, setPlanShow, setActiveTab }) => {

  const [showData, setShowData] = useState(false);
  const [renew, setRenew] = useState(false);
  const [openManageUserTable, setOpenManageUserTable] = useState(false);

  let history = useHistory();


  const routeComp = () => {
    setOpenManageUserTable(true)
    //setIsOpen(false);
  }




  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!document.getElementById('modal-container').contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Attach the event listener when the modal is open
    document.addEventListener('click', handleClickOutside);

    // Detach the event listener when the component is unmounted or the modal is closed
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [setIsOpen]);


  const handler = () => {
    setPlanShow(1)
    setActiveTab("Upgrade Plan")
  }

  return (
    <>
      {openManageUserTable == true ? <SubscribePlann setPlanShow={setPlanShow} /> :
        <div className="body">
          <div id="modal-container">
            {/* Transparent overlay that covers the entire screen */}
            <div className="overlay" onClick={() => setIsOpen(false)}></div>

            <div className="action" style={{ position: 'relative' }}>
              {/* Modal content */}
              <div className="action vendoraction" style={{ marginLeft: '859px', marginTop: '140px' }}>
                <div onClick={() => setShowData(true)} style={{ alignItems: "center", padding:"1px", cursor: "pointer", justifyContent: "space-evenly" }}><IoEyeSharp /> View Details </div>
                <div onClick={() => setRenew(true)} style={{ alignItems: "center", padding:"1px", cursor: "pointer", justifyContent: "space-evenly" }}><GrCycle /> Renew Plan </div>
                <div onClick={handler} style={{ alignItems: "center", padding:"1px", cursor: "pointer", justifyContent: "space-evenly" }}><BiTransfer /> Change Plan </div>

              </div>
            </div>
            {
              showData && <ViewDetails Data={Data} closeModal={setShowData} />
            }
            {
              renew && <ConfirmationModal closeModal={setRenew}/>
            }

          </div>
        </div>
      }
    </>
  );
}

export default ActionsModal