import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
    KeyboardBackspace,
    CheckCircle,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ViewSpecs from "./ViewSpecs";
import moment from "moment";
import { errorNotification, successNotification } from "../../../utils/notifications";
import EditPartSno from "../EditPartSno";
import "../../inward/Inward.scss";

const ManageGRNDetails = ({ closeModal, rowData }) => {
    console.log(rowData)
    const [receivedBy, setReceivedBy] = useState(rowData.receivedBy);
    const [receivedDate, setReceivedDate] = useState(new Date(rowData.receivedDate));
    const [viewSpecs, setViewSpecs] = useState();
    const [receivedQuantity, setReceivedQty] = useState();
    const [openAddPartSno, setAddPartSno] = useState();
    const [finalReceivedQty, setFinalReceivedQty] = useState([]);

    const receivedQtyChange = (e, id) => {
        rowData.components.forEach(item => {
            if (item.componentID == id) {
                item.receivedQuantity = e.target.value
            }
        })
        setReceivedQty(rowData.components);
        const field = [...finalReceivedQty]
        field[index][e.target.name] = e.target.value
        setFinalReceivedQty(field);
    };

    useEffect(() => {
        const array = []
        for (let i = 0; i < rowData.components.length; i++) {
            const obj = {
                value: ""
            }
            array.push(obj)
        }
        setFinalReceivedQty(array)
    }, [])

    const editDetails = {
        GRNID: rowData.GRNID,
        receivedBy: receivedBy,
        receivedDate: receivedDate,
        components: rowData.components
    };

    const submitHandler = async () => {
        console.log(editDetails)
        const resp = await trackPromise(API.put(`inventory-module/api/v1/grn`, editDetails));

        const { success, message } = resp;

        if (success) {
            closeModal(false);
            successNotification(message, { autoClose: 3000 });
        } else {
            errorNotification(message, { autoclose: 2000 });
        }
    };

    const compSno = (data, index) => {
        console.log(data)
        rowData.components[index].componentSno = data
        rowData.components[index].receivedQuantity = data.length.toString()
    };

    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "1100px", height: "425px", padding: '15px' }}
            >
                <div className="header">
                    <h3>Goods Received Note</h3>
                </div>

                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "500" }}>
                            <span>GRN No. : {rowData.GRNID}</span>
                            <span>PO No. : {rowData.POID}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>GRN Generation Date: {moment(rowData.GRNCreatedDate).format("DD-MM-YYYY/HH:mm")}</span>
                            <span>PO Generation Date: {moment(rowData.PODate).format("DD-MM-YYYY/HH:mm")}</span>
                        </div>

                    </section>

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "500", }}>
                            <span>Vendor Name: {rowData.vendorName}</span>
                            <span>Delivery Location : {rowData.deliveryLocation}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>{rowData.vendorLocation}</span>
                        </div>

                    </section>

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            fontSize: "14px",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", width: "23%", textAlign: "start" }}>
                            <label style={{ textAlign: "start" }}>Received By</label>
                            <input
                                className="input-config"
                                style={{ outline: "none", marginTop: "-2%" }}
                                type="text"
                                name="receivedBy"
                                value={receivedBy}
                                onChange={(e) => setReceivedBy(e.target.value)}
                                placeholder="Received By"
                                autoComplete="off"
                            />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", width: "23%", textAlign: "start" }}>
                            <label style={{ textAlign: "start" }}>Received Date</label>
                            <DatePicker
                                className="input-config create-grn-date receivedDate grndatepicker"
                                selected={receivedDate}
                                onChange={(date) => setReceivedDate(date)}
                                dateFormat="dd-MM-yyyy/HH:mm"
                                placeholderText="Received Date"
                            />
                        </div>


                    </section>


                    <section style={{ width: '100%', }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '13%' }}>Component Name</div>
                            <div style={{ width: '9%' }}>SKU Code</div>
                            <div style={{ width: '13%' }}>Vendor Part Name</div>
                            <div style={{ width: '13%' }}>Vendor Part Code</div>
                            <div style={{ width: '8%' }}>Category</div>
                            <div style={{ width: '10%' }}>Sub Category</div>
                            <div style={{ width: '9%' }}>Specifications</div>
                            <div style={{ width: '10%' }}>Ordered Qty.</div>
                            <div style={{ width: '10%' }}>Received Qty.</div>
                            <div style={{ width: '10%' }}>Components Units</div>
                        </div>
                        <div>
                            {
                                rowData.components.map((item, index) => (<div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }} key={index}>
                                    <div style={{ width: '13%' }}>{item.componentName}</div>
                                    <div style={{ width: '9%' }}>{item.skuCode}</div>
                                    <div style={{ width: '13%' }}>{item.vendorPartName}</div>
                                    <div style={{ width: '13%' }}>{item.vendorPartCode}</div>
                                    <div style={{ width: '8%' }}>{item.category}</div>
                                    <div style={{ width: '10%' }}>{item.subCategory}</div>
                                    <div style={{ width: '9%' }}>
                                        <span onClick={() => setViewSpecs(index)} style={{ color: "#0a18a3", fontWeight: "500", cursor: 'pointer' }}> View Specs.</span>
                                        {viewSpecs === index && <ViewSpecs specs={item} closeModal={setViewSpecs} />}
                                    </div>
                                    <div style={{ width: '10%' }}>{item.requiredQuantity}</div>
                                    <div style={{ width: '10%' }}>
                                        <input
                                            type="text"
                                            disabled
                                            placeholder="Enter Qty."
                                            style={{ width: '80px', margin: '0 -7px 0 0', textAlign: 'center', height: '20px', background: '#ebebeb', border: 'none' }}
                                            defaultValue={item.receivedQuantity}
                                            onChange={(e) => receivedQtyChange(e, item.componentID)}
                                        />
                                    </div>
                                    <div style={{ width: '10%' }}>
                                        <span onClick={() => setAddPartSno(index)} style={{ color: "#0a18a3", fontWeight: "500", cursor: 'pointer' }}>View & Edit</span>
                                        {openAddPartSno === index && <EditPartSno index={index} receivedQuantity={item.receivedQuantity} requiredQuantity={item.requiredQuantity}  poData={rowData} comp={item} qty={finalReceivedQty[index].value == "" ? item.receivedQuantity : finalReceivedQty[index].value} closeModal={setAddPartSno} compSno={compSno} />}
                                    </div>
                                </div>))
                            }
                        </div>
                    </section>

                </div>

                <div className="footer">
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                    <button onClick={submitHandler} style={{ marginRight: '0', width: '120px', height: '42px' }} id="submit-button"><CheckCircle style={{ fontSize: "17px" }} />Update</button>
                </div>
            </div>
        </div>
    );
};

export default ManageGRNDetails;