import { KeyboardBackspace } from "@material-ui/icons";
import React from "react";
import auth from "../../../utils/auth";

const ViewAssemblyCost = ({ closeModal, row }) => {
  const UsersData = row.bomAssembly;
  console.log(UsersData);

  // Function to calculate the total service cost based on time, timeDuration, and serviceCost
  const calculateTotalCost = (time, timeDuration, serviceCost) => {
    let totalDays = 0;

    switch (time) {
      case "Week":
        totalDays = Number(timeDuration) * 7; // 1 week = 7 days
        break;
      case "Month":
        totalDays = Number(timeDuration) * 30; // 1 month = 30 days
        break;
      case "Year":
        totalDays = Number(timeDuration) * 365; // 1 year = 365 days
        break;
      default:
        totalDays = Number(timeDuration); // If timeDuration is "Day", we just multiply by time
        break;
    }

    return totalDays * serviceCost; // Total cost = (total days) * serviceCost per day
  };




  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "80%", height: "414px", padding: "15px" }}
      >
        <div className="header">
          <h3>BOM Assembly Details</h3>
        </div>

        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div id="assemblyTableHeading" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>Assembly Name</div>
              <div>Required Services</div>
              <div>Required Unit</div>
              <div>{`Cost(${auth.getUserInfo().currencySymbol})`}</div>
              <div>{`Total Cost (${auth.getUserInfo().currencySymbol})`}</div>
            </div>
            <div style={{ height: '200px', overflowY: "scroll" }}>
              {UsersData.map((item) => (
                <div id="assemblyTableContent" style={{ display: 'flex',justifyContent:'space-between' }} key={item.assemblyName}>
                  <div style={{ width: '20%', margin: '10px 0' }}>{item.assemblyName}</div>
                  <div style={{ width: '80%' }}>
                    {item.assemblyServices.map(service => {
                      const totalServiceCost = calculateTotalCost(service?.time, service?.timeDuration, service?.serviceCost);

                      return (
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }} key={service?.serviceName}>
                          <div style={{ width: '209px', textAlign: "center", overflow: "scroll" }}>{service?.serviceName}</div>
                          <div style={{ width: '154px', textAlign: "left", overflow: "scroll" }}>
                            {`${service?.timeDuration}  ${service?.time}`}
                          </div>
                          <div style={{ width: '154px', textAlign: "left", overflow: "scroll" }}>
                            {`${service?.serviceCost} Per Day`}
                          </div>
                          <div style={{ width: '154px', textAlign: "left", overflow: "scroll" }}>
                            {totalServiceCost}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "8px" }}>{`Total Cost (${auth.getUserInfo().currencySymbol})`}</span>
                <span
                  style={{
                    padding: "7px",
                    width: "141px",
                    backgroundColor: "#e5e5e5",
                  }}
                >
                  {row.totalAssemblyCost}
                </span>
              </div>
            </div>
          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}><KeyboardBackspace /> Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewAssemblyCost;
