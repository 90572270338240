import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import AddBusinessUser from "./addbusinessUser";
// import ManageBusinessUser from "./manageBusinessUser";

const AddBusinessUser = React.lazy(()=>import("./addbusinessUser"))
const ManageBusinessUser = React.lazy(()=>import("./manageBusinessUser"))


const tabs = [
  {
    id:"DEM1T1",
    label: TabLabels.ADD_BUSINESS_USER,
    Component: AddBusinessUser
  },
  {
    id:"DEM1T2",
    label: TabLabels.MANAGE_BUSINESS_USER,
    Component: ManageBusinessUser
  }
];

const DealerBusinessUserTabs = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default DealerBusinessUserTabs;
