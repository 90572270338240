import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import "../../../../components/modal/modal.scss";

const EditComponents = ({ closeModal, editData, updatedData , setOpen }) => {
  const [openSpecs, setOpenSpecs] = useState(false);
  const [formValue, setFormValue] = useState({
    pricingId: editData.pricingId,
    vehicleSegment: editData.vehicleSegment,
    vehicleCategory: editData.vehicleCategory,
    vehicleModel: editData.vehicleModel,
    vehicleVariant: editData.vehicleVariant,
    vehicleType: editData.vehicleType,
    vehicleColor: editData.vehicleColor,
    HSNSACcode: editData.HSNSACcode,
    taxPercentage: editData.taxPercentage,
  });

  const refresh = () => {
    updatedData();
  };

  const colorData = ["Black", "white", "red"];

  const specsStyle = {
    border: "none",
    margin: "10px 10px 10px 0px",
    width: "240px",
    height: "50px",
    background: "#ebebeb",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  };

  const handleChange = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  const validateInputs = () =>{
  if (!formValue.HSNSACcode.trim()) {
    errorNotification("HSN/SAC Code is required.");
    return;
  }
  if (!formValue.taxPercentage) {
    errorNotification("GST is required.");
    return;
  }
  if (formValue.taxPercentage < 0 || formValue.taxPercentage > 100) {
    errorNotification("GST must be between 0 and 100.");
    return;
  }
  return true;
  }

  const submitHandler = async () => {
    if (!validateInputs()) {
      return;
    }
    const resp = await trackPromise(
      API.post(`finance/api/v1/editHSNSAC`, formValue)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      setOpen(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  // let uniqueCategoryArray = [...new Map(UsersData.map((item) => [item["category"], item])).values()];


  const closeHandler = ()=>{
    closeModal(false)
    setOpen(false)
  }

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "800px", height: "400px" }}
      >
        <div className="header">
          <h3>Edit HSN/SAC Details</h3>
        </div>

        <div className="body">
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-120px",
                }}
              >
                Vehicle Segment<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="input-config"
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                type="text"
                name="vehicleSegment"
                value={formValue.vehicleSegment}
                placeholder="Vehicle Segment"
                disabled
              />
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-120px",
                }}
              >
                Vehicle Category<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="input-config"
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                type="text"
                name="vehicleCategory"
                value={formValue.vehicleCategory}
                placeholder="Vehicle Segment"
                disabled
              />
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-172px",
                }}
              >
                Order ID<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="input-config"
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                type="text"
                name="vehicleModel"
                value={formValue.vehicleModel}
                placeholder="Vehicle Category"
                disabled
              />
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-172px",
                }}
              >
                Order ID<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="input-config"
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                type="text"
                name="vehicleVariant"
                value={formValue.vehicleVariant}
                placeholder="Vehicle Model"
                disabled
              />
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-144px",
                }}
              >
                Vehicle Type<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="input-config"
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                type="text"
                name="vehicleType"
                value={formValue.vehicleType}
                placeholder="Vehicle Variant"
                disabled
              />
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-142px",
                }}
              >
                Vehicle Color<span style={{ color: "red" }}>*</span>
              </label>
              <select
                required={true}
                name="vehicleColor"
                className="input-config"
                style={{ width: "100%", outline: "none", marginTop: "-8px", cursor: 'pointer' }}
                onChange={(event) => handleChange(event)}
                defaultValue={formValue.vehicleColor}
              >
                <option value="" selected disabled>
                  Vehicle Color
                </option>
                {colorData.map((item, i) => (
                  <option key={i} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-120px",
                }}
              >
                HSN/SAC Code<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="input-config"
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                type="text"
                name="HSNSACcode"
                placeholder="HSN/SAC*"
                defaultValue={formValue.HSNSACcode}
                onChange={(event) => handleChange(event)}
              />
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-177px",
                }}
              >
                GST<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="input-config"
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                type="number"
                name="taxPercentage"
                placeholder="Tax%*"
                defaultValue={formValue.taxPercentage}
                onChange={(event) => handleChange(event)}
              />
            </div>
            <br />
          </div>
        </div>
        <div className="footer">
          <button onClick={closeHandler}>Cancel</button>
          <button id="submit-button" onClick={() => submitHandler()}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditComponents;
