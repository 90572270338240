import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckCircle, KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { errorNotification } from "../../utils/notifications";

const AddPartSno = ({ closeModal, comp, qty, selectedPO, compSno, index }) => {
  const [formValue, setFormValue] = useState([]);

  console.log("comp-->", comp);
  console.log("selectedPO-->", selectedPO);

  useEffect(() => {
    const array = [];
    for (let i = 0; i < parseInt(qty); i++) {
      const obj = {
        partSno: "",
      };
      array.push(obj);
    }
    setFormValue(array);
  }, []);

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    let field = [...formValue];
    field[index][name] = value;
    setFormValue(field);
  };

  let addFormFields = () => {
    setFormValue([...formValue, { partSno: "" }]);
  };

  const removeFields = (index) => {
    const field = [...formValue];
    field.splice(index, 1);
    setFormValue(field);
  };

  const submitHandler = () => {
    const finalBody = formValue.map((item) => ({
      ...item,
      vendorCity: selectedPO.vendorCity,
      vendorName: selectedPO.vendorName,
      vendorID: selectedPO.vendorID,
      deliveryLocation: selectedPO.deliveryLocation,
      unitPrice: comp.unitPrice,
    }));
    console.log(finalBody);
    const hasPartSno = finalBody.map((item) => item.partSno != "");
    if (hasPartSno) {
      compSno(finalBody, index);
      closeModal(false);
    } else {
      errorNotification("Please Input the Part Serial Numbers to save Data");
    }
  };






  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          height: "500px",
          width: "80%",
          padding: "15px",
          justifyContent: "flex-start",
        }}
      >
        <div
          className="header"
          style={{ width: "100%", justifyContent: "flex-start" }}
        >
          <h3>Add Part Serial No.</h3>
        </div>

        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
            margin: "10px 0",
            borderBottom: "2px solid #F2F2F2",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
            }}
          >
            <span>SKU Code: {comp.skuCode}</span>
            <span>PO No.: {selectedPO.POID} </span>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
            }}
          >
            <span>Component Name: {comp.componentName}</span>
          </div>
        </section>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            flexWrap: "wrap",
            height: "65%",
            overflow: "scroll",
            textAlign: "left",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          {formValue.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "19%",
                alignItems: "flex-start",
              }}
            >
              <label style={{ fontWeight: "500", marginBottom: "0" }}>
                Serial Number {index + 1}
              </label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  padding: "0",
                  margin: "0",
                }}
              >
                <input
                  style={{
                    flex: 1,
                    padding: "10px",
                    border: "none",
                    borderRadius: "4px",
                    width: "calc(100% - 40px)", // Adjust width dynamically
                    height: "40px",
                    fontSize: "14px",
                    backgroundColor: "#ebebeb",
                  }}
                  type="text"
                  name="partSno"
                  placeholder="Enter Part S.No"
                  value={item.partSno}
                  onChange={(event) => handleInputChange(event, index)}
                />
                <button
                  onClick={() => removeFields(index)}
                  style={{
                    background: "none",
                    border: "none",
                    color: "red",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          ))}
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          {/* <button
            style={{
              display: "flex",
              alignItems: "center",
              width: "104px",
              height: "42px",
              background: "#fff",
              color: "green",
              border: "1px solid green",
            }}
            onClick={() => addFormFields()}
          >
            <FontAwesomeIcon icon={faPlus} style={{ fontSize: "25px", marginRight:"5px"}} />
            Add More
          </button> */}
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px", marginRight:"5px" }} />
            Back
          </button>
          <button
            style={{ width: "104px", height: "42px" }}
            onClick={submitHandler}
          >
            <CheckCircle  style={{marginRight:"5px"}}/>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPartSno;
