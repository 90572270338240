import { KeyboardBackspace } from "@material-ui/icons";
import React from "react";
const ViewAssembly = ({ close, rowdata }) => {
  console.log(rowdata.bomAssembly)

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "982px", height: "426px", padding: "15px" }}
      >
        <div className="header">
          <h3>Assembly Details</h3>
        </div>

        <br />
        <div className="body">
          <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
            <h6>WO ID:{rowdata.orderID}</h6>
            <h6>WO Type:{rowdata.workType}</h6>
          </div>
          <div style={{ overflow: "auto", height: "252px" }}>
            <table className="viewcomp_tbl" style={{ width: "100%" }} >
              <thead>
                <tr>
                  <th>Assembly Name</th>
                  <th>Required Services</th>
                  <th>Required Time</th>
                </tr>
              </thead>
              <tbody>
                {rowdata.hasOwnProperty('bomAssembly') ?

                  rowdata.bomAssembly.map((item) => {
                    return (
                      <tr>
                        <td className="leftcell">{item?.assemblyName}</td>
                        <td>
                          {/* <ul
                            // style={{ width: "230px", height: "40px", margin: "0px" }}
                            // > */}
                            {item?.assemblyServices?.map((item) => {
                              return (
                                <ul>{item?.serviceName}</ul>
                              )
                            })}
                          {/* </ul> */}
                        </td>
                        <td className="rightcell">
                        {/* <ul
                            // style={{ width: "230px", height: "40px", margin: "0px" }}
                            > */}
                            {item?.assemblyServices.map((item) => {
                              return (
                                <ul>{`${item?.timeDuration} ${item?.time}`}
                              </ul>
                              )
                            })}
                          {/* </ul> */}
                         </td>
                      </tr>
                    )
                  }) :
                  rowdata.requiredAssembly.map((item) => {
                    return (
                      <tr>
                        <td className="leftcell">{item?.assemblyName}</td>
                        <td>
                          <ul
                            style={{ width: "230px", height: "40px", margin: "0px" }}>
                            {item?.assemblyServices.map((item) => {
                              return (
                                <span>{item?.service || item?.serviceName}</span>
                              )
                            })}
                          </ul>
                        </td>
                        <td className="rightcell">
                          {
                            item?.assemblyServices?.map((item) => {
                              return `${item?.timeDuration} ${item?.time}`
                            })
                          }
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>

          {/* <div style={{ width: "100%", display: "flex", flexDirection: "column", textAlign: "left" }}>
            <h6>Request Reason</h6>
            <div className="reqreason">Components Exhaust Manifold  10 qty are damanged</div>
          </div> */}
        </div>

        <br />
        <div className="footer">
          <button onClick={() => close(false)}><KeyboardBackspace /> Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewAssembly;