import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { trackPromise } from "react-promise-tracker";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import API from "../../../../utils/api";
import auth from "../../../../utils/auth";
import "./header.scss";
import PersonalProfile from "../../../../components/modal/PersonalProfile";
import "../../../../components/modal/modal.scss";
import { errorNotification } from "../../../../utils/notifications";

const DashboardHeader = () => {
  let history = useHistory();
  const [notificationData, setNotificationData] = useState([]);
  const [notifyDot, setNotifyDot] = useState();

  const fetchReport = async () => {
    // const resp = await trackPromise(
    //   // API.get(`message-module/api/v1/notification`)
    // );
    // console.log(resp.data);
    // setNotificationData(resp.data == undefined ? [] : resp.data);

    // ***************below code is temporary to avoid error as notification functionality has to be fixed
    const resp = [];
    setNotificationData(resp);
    let test = resp?.every((item) => item.status == "Read");
    setNotifyDot(test);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const logout = async (e) => {
    let body = { userID: auth.getUserInfo().userID };
    const resp = await trackPromise(
      API.post(`admin-module/api/v1/logout`, body)
    );
    if (resp.success == true) {
      auth.clearAll();
      history.push("/");
    } else {
      errorNotification(resp.message);
    }
  };

  const [uploadedUserImage, setUserImage] = useState(null);

  const [showProfile, setShowProfile] = useState(false);
  const actionClick = () => {
    setShowProfile(true);
  };

  const [showNotification, setShowNotification] = useState(false);

  const hideIfSuperAdmin = (component) =>
    auth.isSuperAdmin() ? null : component;

  const userImage = (image) => {
    console.log(image);
    setUserImage(image);
  };

  function on() {
    document.getElementById("overlayNotification").style.display = "block";
  }

  function off() {
    document.getElementById("overlayNotification").style.display = "none";
    setShowNotification(false);
  }

  const actionHandler = () => {
    setShowNotification(true);
    on();
  };

  const onViewAllCLick = async () => {
    history.push("/app/notification/viewAll");
    const resp = await trackPromise(
      API.post(`message-module/api/v1/notification`, {
        userId: auth.getUserInfo().userID,
      })
    );
    fetchReport();
    off();
  };

  const onSingleNotifyClick = async (data) => {
    let body = {
      userId: auth.getUserInfo().userID,
      notificationID: data.notificationID,
    };
    console.log(body);
    const resp = await trackPromise(
      API.post(`message-module/api/v1/singleNotification`, body)
    );
    fetchReport();
  };

  return (
    <>
      {showProfile ? (
        <PersonalProfile closeModal={setShowProfile} getUserImage={userImage} />
      ) : null}
      <div id="overlayNotification" onClick={(e) => off(e)}></div>
      <header className="dashboard-header">
        <nav className="navbar navbar-light p-0 nav-head">
          <UncontrolledDropdown>
            <div className="notification-bell" onClick={() => actionHandler()}>
              <FontAwesomeIcon icon={faBell} />
              {notifyDot == true ? (
                <span></span>
              ) : (
                <span className="notification-dot"></span>
              )}
            </div>
            {showNotification && (
              <div className="block" id="list">
                <div className="header">
                  <span id="s1">Notifications </span>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    onClick={(e) => off(e)}
                    style={{
                      color: "#0A18A3",
                      marginTop: "5px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className="listitem">
                  {notificationData.length == 0 ? (
                    <ul
                      style={{ fontSize: "14px", margin: "10px 35px 0px 5px" }}
                    >
                      <li>No New Notification</li>
                    </ul>
                  ) : (
                    notificationData.map((item) => {
                      return (
                        <ul
                          style={{
                            background:
                              item.status == "UnRead" ? "#f0f8ff" : "",
                            margin: "0px",
                            height: "35px",
                            padding: "5px 0px 0px 15px",
                            cursor: "pointer",
                          }}
                          onClick={() => onSingleNotifyClick(item)}
                        >
                          <li
                            style={{
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faBell}
                              style={{
                                color:
                                  item.status == "UnRead"
                                    ? "#0a18a3"
                                    : "#808080",
                                fontSize: "16px",
                              }}
                            />{" "}
                            <span
                              style={{
                                display: "block",
                                textOverflow: "ellipsis",
                                width: "330px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.message.notification.body}
                            </span>
                          </li>
                        </ul>
                      );
                    })
                  )}
                </div>
                <div id="viewall" onClick={() => onViewAllCLick()}>
                  <a>View All</a>
                </div>
              </div>
            )}
          </UncontrolledDropdown>

          <UncontrolledDropdown>
            <DropdownToggle nav caret className="d-flex align-items-center">
              {uploadedUserImage == null ? (
                <AiOutlineUser className="user-icon" />
              ) : (
                <img
                  src={uploadedUserImage}
                  className="uploadedPicStyle"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "20px",
                    marginRight: "14px",
                  }}
                />
              )}
              {auth.getUserInfo().fName}
            </DropdownToggle>

            <DropdownMenu end className="header-menus">
              <DropdownItem onClick={actionClick}>
                Personal Profile
              </DropdownItem>
              <DropdownItem divider />
              {hideIfSuperAdmin(<DropdownItem>Change Password</DropdownItem>)}
              {hideIfSuperAdmin(<DropdownItem divider />)}
              {hideIfSuperAdmin(
                <DropdownItem>Notification Preference</DropdownItem>
              )}
              {hideIfSuperAdmin(<DropdownItem divider />)}
              <DropdownItem onClick={logout}>Sign Out</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </nav>
      </header>
      <DropdownItem divider />
    </>
  );
};

export default DashboardHeader;