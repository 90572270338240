import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../../utils/notifications";
import data from "../Data";

const Edit = ({ editData, closeModal, updated, setIsOpen }) => {
  const [formValue, setFormValue] = useState({
    laborCategory: editData.laborCategory,
    laborSubCategory: editData.laborSubCategory,
    laborLevel: editData.laborLevel,
    avgLaborCost: editData.avgLaborCost,
    time: "Day",
  });

  const validateInputs = () => {
   
      if (
        !formValue.laborCategory.trim() ||
        !formValue.laborSubCategory.trim() ||
        !formValue.laborLevel.trim() ||
        !formValue.avgLaborCost.trim() ||
        !formValue.time.trim() 
      ) {
        errorNotification("All fields are required.");
        return false;
      }
    
    return true;
  };

  const editDetails = {
    laborID: editData.laborID,
    // "laborCategory": formValue.laborCategory,
    // "laborSubCategory": formValue.laborSubCategory,
    // "laborLevel": formValue.laborLevel,
    avgLaborCost: formValue.avgLaborCost,
    time: formValue.time,
  };

  const refresh = () => {
    updated();
  };

  const submitHandler = async () => {
    if (!validateInputs()) {
      return;
    }

    console.log("edit detaisl chek",editDetails)
    const resp = await trackPromise(
      API.post(
        `procurement-module/api/v1/costings/editLaborCosting`,
        editDetails
      )
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
      setIsOpen(false)
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "810px" }}>
        <div className="header">
          <h3>Edit Labor Costing</h3>
        </div>

        <div className="body">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              width:'100%'
            }}
          >
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-123px",
                }}
              >
                Labor Category<span style={{ color: "red" }}>*</span>
              </label>
              <select
              disabled
              name="laborCategory"
              defaultValue={editData.laborCategory}
              style={{marginTop:'-5px', width:'auto'}}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option value={editData.laborCategory} disabled selected>
                {editData.laborCategory}
              </option>

              {/* {data.map((item, id) => (
                <option key={id}>{item.category}</option>
              ))} */}
            </select>
            </div>
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-99px",
                }}
              >
                Labor Sub Category<span style={{ color: "red" }}>*</span>
              </label>
              <select
              name="laborSubCategory"
              disabled
              defaultValue={editData.laborSubCategory}
              style={{marginTop:'-5px', width:'auto'}}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option value={editData.laborSubCategory} disabled selected>
                {editData.laborSubCategory}
              </option>

              {/* {
                data.find((item) => item.category === formValue.laborCategory)?.subCategory.map((item, id) => (
                  <option key={id}>{item}</option>
                ))
              } */}
            </select>
            </div>
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-154px",
                }}
              >
                Labor Level<span style={{ color: "red" }}>*</span>
              </label>
              <select
              disabled
              name="laborLevel"
              defaultValue={editData.laborLevel}
              style={{marginTop:'-5px', width:'auto'}}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option value="" disabled selected>
                Labor Level
              </option>
              <option>Fresher</option>
              <option>Intermediate</option>
              <option>Experienced</option>
            </select>
            </div>
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-105px",
                }}
              >
                Average Labor Cost<span style={{ color: "red" }}>*</span>
              </label>
              <input
              type="text"
              pattern="[0-9]*"
              style={{marginTop:'-5px', width:'auto'}}
              name="avgLaborCost"
              defaultValue={editData.avgLaborCost}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Avg Labor Cost"
              autoComplete="off"
            />
            </div>
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-190px",
                }}
              >
                Time<span style={{ color: "red" }}>*</span>
              </label>

              {/* <select
              name="time"
              style={{marginTop:'-5px', width:'auto'}}
              defaultValue={editData.time}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option value="" disabled selected>
                Time
              </option>
              <option>Per Second</option>
              <option>Per Minute</option>
              <option>Per Hour</option>
              <option>Per Day</option>
              <option>Per Week</option>
              <option>Per Month</option>
            </select> */}
            <input
              name="time"
              style={{marginTop:'-5px', width:'auto'}}
              disabled
              // defaultValue={editData.time}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Per Day"
            />
            </div>
          </div>
          
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default Edit;
