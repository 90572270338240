import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import Vehicles from "./VehicleReturns/Component/VehicleReturns";
// import Spares from "./SpareReturns/component/SpareReturns";

const Vehicles = React.lazy(()=>import("./VehicleReturns/Component/VehicleReturns"))
const Spares = React.lazy(()=>import("./SpareReturns/component/SpareReturns"))


const ZonalSalesReturns = () => {
  const tabs = [
    {
      id: "ZSMM4T1",
      label: TabLabels.VEHICLE_RETURN,
      Component: Vehicles,
    },
    {
      id: "ZSMM4T2",
      label: TabLabels.SPARES_RETURN,
      Component: Spares,
    }
  ];

  return (
    <div className="app-layout__card">
      <Tabs type="pills" tabs={tabs} />
    </div>
  );
};

export default ZonalSalesReturns;
