import React, { useState } from "react";
import ReceivedQuantity from "./inv-sf-actions/receivedquantity";
// import "../../work-order/workorder.scss";

const PartialQtyDetails = ({ close, rowdata }) => {

    const [showQty,setShowQty]=useState();

  return (
    <>
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "900px", height: "546px", padding: "15px" }}
      >
           {showQty && <ReceivedQuantity close={setShowQty}/>}
        <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>View Details</h3>
          <div>
          </div>
        </div>

    <div className="body" style={{height:"430px",overflow:"auto"}}> 
    <div
        style={{
          width:"100%",
          display: "flex",
          flexDirection: "column",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request ID:{}</span>
          <span>WO ID:{}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request Date:{}</span>
          <span>WO Type: {}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request Type:{}</span>
          <span>Total Requested Component:{}</span>
        </div>
        <div style={{ display: "flex", justifyContent:"flex-end" }}>
          <span>Total Received Component:{}</span>
        </div>
      </div>
        <div style={{width:"100%"}}>
          <div style={{width:"18%",height:"30px",padding:"3px",backgroundColor:"#EBEBEB",borderRadius:"3px"}}>
             Date: 15-April-2022
          </div>
          <table className="viewcomp_tbl"> 
          <thead>

            <tr>
                <th style={{position:"static"}}>S.No.</th>
                <th style={{position:"static"}}>Component Name</th>
                <th style={{position:"static"}}>SKU Code</th> 
                <th style={{position:"static"}}>Requested Qty.</th>
                <th style={{position:"static"}}>Request Reason</th>
                <th style={{position:"static"}}>Received Qty.</th>
                <th style={{position:"static"}}>Pending Qty.</th>
            </tr>
            </thead>
            <tr>
                <td className="leftcell">1.</td>
                <td>Exhaust Manifold</td>
                <td>EL76575</td>
                <td>10</td>
                <td>Not working</td>
                <td>
                <button 
                    onClick={()=> setShowQty(true)}
                    className="action-btn" 
                    style={{color:"blue"}}>
                    10
                </button>
                </td>
                <td className="rightcell">-</td>
            </tr>
            </table>  
            </div>   
                
        </div>

        <br />
        <div className="footer">
          <button onClick={() => close(false)}>Back</button>
        </div>
      </div>
    </div>
    </>
  );
};

export default PartialQtyDetails;