import { ContactsOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import auth from "../../utils/auth";
import ViewTermsDetails from "../OperationsWarrantyServices/Component/ViewTermsDetails";
// import "../../work-order/workorder.scss";

const ViewWarranty = ({ close, rowData }) => {

  const [openTerm, setOpenTerm] = useState()
  const [openDetails, setOpenDetails] = useState()

  return (
    <>
      <div className="container1">
      {
          openTerm && <ViewTermsDetails closeModal={setOpenTerm} data={"Vehicle Warranty Terms"} />
        }
        {
          openDetails && <ViewTermsDetails closeModal={setOpenDetails} data={"Vehicle Warranty Details"} />
        }
        <div
          className="modalContainer"
          style={{ width: "700px", height: "550px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>View Warranty Details</h3>
            <div>
            <button
              style={{
                color: "#0A18A3",
                margin: "1px",
                marginLeft:"10px",
                height: "27px",
                width: "125px",
                border: "none",
                marginRight:'8px'
              }}
              onClick={() => setOpenDetails(true)}
            >
              Warranty Details
            </button>
           <button
              style={{
                color: "#0A18A3",
                margin: "1px",
                height: "27px",
                width: "90px",
                border: "none",
              }}
              onClick={() => setOpenTerm(true)}
            >
              View Terms
            </button>
           </div>
          </div>
          <br />
          <section
            style={{ display: "flex", flexDirection: "column", width: "100" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span>SKU Code: {rowData.vehicleSkuCode}</span>
              <span>Vehicle Model: {rowData.vehicleModel}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span>Segment: {rowData.vehicleSegment}</span>
              <span>Vehicle Variant: {rowData.vehicleVariant}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span>Category: {rowData.vehicleCategory}</span>
              <span>Vehicle Type: {rowData.vehicleType}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span>Color: {rowData.vehicleColor}</span>
            </div>
          </section>
          <br />
          <div className="body" style={{ height: "340px" }}>
            <div style={{ width: "100%", overflow: "auto" }}>
              <span style={{ color: "#0A18A3", float: "left" }}>
                Standard Warranty
              </span>

              <table style={{ width: "100%" }}>
                <tr>
                  <td
                    style={{
                      background: "#efefefe6",
                      width: "40%",
                      textAlign: "left",
                    }}
                  >
                    Distance
                  </td>
                  <td>{rowData.standardWarranty.warrantyDistance}/{rowData?.standardWarranty?.warrantyDistanceUnit}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      background: "#efefefe6",
                      width: "40%",
                      textAlign: "left",
                    }}
                  >
                    Duration
                  </td>
                  <td>
                    {rowData.standardWarranty.warrantyDuration +
                      "/" +
                      rowData.standardWarranty.warrantyDurationUnit}
                  </td>
                </tr>
              </table>
            </div>
            <span
              style={{ color: "#0A18A3", float: "left", marginTop: "10px" }}
            >
              Extended Warranty
            </span>

            {rowData.extendedWarranty.map((item) => {
              return (
                <>
                  <table style={{ width: "100%", marginTop: "10px" }}>
                    <tr>
                      <td
                        style={{
                          background: "#efefefe6",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        Distance
                      </td>
                      <td>{item.warrantyDistance}/{item?.warrantyDistanceUnit}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          background: "#efefefe6",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        Duration
                      </td>
                      <td>
                        {item.warrantyDuration +
                          "/" +
                          item.warrantyDurationUnit}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          background: "#efefefe6",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        Cost ({auth.getUserInfo().currencySymbol})
                      </td>
                      <td>{item.warrantyCharges}</td>
                    </tr>
                  </table>
                  <hr />
                </>
              );
            })}
          </div>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}>
              <KeyboardBackspace /> Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewWarranty;
