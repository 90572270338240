import React, { useEffect, useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import "../finishGoods.scss";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";

const StandardVehicleDetails = ({ rowData, closeModal }) => {
    const [standardVehicleDetailsData, setStandardVehicleDetailsData] = useState([]);

    const getStandardVehicleViewDetails = async () => {
        const resp = await trackPromise(API.get(`inventory-module/api/v1/finishedGoods/getStandardVehicles?vehicleSegment=${rowData.vehicleSegment}&vehicleColor=${rowData.vehicleColor}&vehicleModel=${rowData.vehicleModel}&vehicleVariant=${rowData.vehicleVariant}&vehicleType=${rowData.vehicleType}`));
        setStandardVehicleDetailsData(resp)
    };
    useEffect(() => {
        getStandardVehicleViewDetails();
    }, []);

    console.log(rowData)
    const blankForm = [rowData];
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "80%", height: "425px", padding: '15px' }}
            >
                <div className="header">
                    <h3>View Details</h3>
                </div>

                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Vehicle Category : Standard</span>
                            <span>Battery Type : {""}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>BOM Segment : {""}</span>
                            <span>Battery Make : {""}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Vehicle Model : {rowData.vehicleModel}</span>
                            <span>Battery Capacity : {""}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Vehicle Variant : {rowData.vehicleVariant}</span>
                            <span>Nominal Battery volts : {""}</span>
                        </div>

                    </section>
                    <div className="availVehicle">Total Qty : {standardVehicleDetailsData.length}</div>
                    <section style={{ width: '100%', }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '8%' }}>Color</div>
                            <div style={{ width: '10%' }}>VIN</div>
                            <div style={{ width: '10%' }}>S.No.</div>
                            <div style={{ width: '10%' }}>BIN</div>
                            <div style={{ width: '10%' }}>Socket Type</div>
                            <div style={{ width: '12%' }}>Battery Model No.</div>
                            <div style={{ width: '13%' }}>Avg. Full Charge(hrs)</div>
                            <div style={{ width: '15%',overflow:"auto" }}>Power Comsumption/Charging</div>
                            <div style={{ width: '12%',overflow:"auto" }}>Distance to Full Charge </div>
                        </div>
                        <div>
                            {
                                standardVehicleDetailsData?.map((item, index) => {
                                    return (
                                        <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', }} key={""}>
                                            <div style={{ width: '8%', overflowY: "auto" }}>{item.vehicleColor}</div>
                                            <div style={{ width: '10%', overflowY: "auto" }}>{item.VIN}</div>
                                            <div style={{ width: '10%', overflowY: "auto" }}>{item.SNO}</div>
                                            <div style={{ width: '10%', overflowY: "auto" }}>{item.BIN}</div>
                                            <div style={{ width: '10%', overflowY: "auto" }}>{""}</div>
                                            <div style={{ width: '12%', overflowY: "auto" }}></div>
                                            <div style={{ width: '13%', overflowY: "auto" }}>{""}</div>
                                            <div style={{ width: '15%', overFlow: "auto" }}>{""}</div>
                                            <div style={{ width: '12%', overflowY: "auto" }}>₹{""}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </section>

                </div>

                <div className="footer">
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
    );
};

export default StandardVehicleDetails;