import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import Vector from "../QcDashboard/assets/Vector.svg";
import Vector3 from "../QcDashboard/assets/Vector3.svg";
import Group7 from "../QcDashboard/assets/Group7.svg";
import Group8 from "../QcDashboard/assets/Group8.svg";
import Group9 from "../QcDashboard/assets/Group9.svg";
import Group10 from "../QcDashboard/assets/Group10.svg";
import Group11 from "../QcDashboard/assets/Group11.svg";
import Group12 from "../QcDashboard/assets/Group12.svg";
import Group13 from "../QcDashboard/assets/Group13.svg";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);
ChartJS.defaults.plugins.legend.display = false;
import ProgressBar from "react-bootstrap/esm/ProgressBar";
import moment from "moment";
import auth from "../../utils/auth";

const VehicleCard = ({ prop }) => {
  const [data, setData] = useState([]);
  const [spareData, setSalesData] = useState({});
  const [weeklyShowroomData, setWeeklyShowroomSalesData] = useState([]);
  const [monthlyShowroomData, setMonthlyShowroomSalesData] = useState([]);
  const [yearlyShowroomData, setYearlyShowroomSalesData] = useState([]);

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(`dealer-module/api/v1/showrooms/inventory/showroomWise`)
    );
    setData(res.data);
  };
  const fetchSpareData = async () => {
    const res = await trackPromise(
      API.get(`dealer-module/api/v1/dashBoard/vehicleSales`)
    );
    setSalesData(res);
  };

  const fetchSalesData = async () => {
    const now = new Date();

    // Format date to YYYY-MM-DD
    const formatDate = (date) => {
      return date.toISOString().split('T')[0];
    };

    // Calculate date ranges for weekly, monthly, and yearly data
    const getDateRange = (type) => {
      const startDate = new Date(now);
      switch (type) {
        case 'weekly':
          startDate.setDate(now.getDate() - 7);
          break;
        case 'monthly':
          startDate.setMonth(now.getMonth() - 1);
          break;
        case 'yearly':
          startDate.setFullYear(now.getFullYear() - 1);
          break;
        default:
          return { from: formatDate(now), to: formatDate(now) };
      }
      return { from: formatDate(startDate), to: formatDate(now) };
    };

    const fetchAndFilterData = async (type) => {
      const { from, to } = getDateRange(type);
      const response = await trackPromise(
        API.get(`dealer-module/api/v1/showrooms/sales/showroomWise?from=${from}&to=${to}`)
      );
      return response.data.filter((item) => item.invoiceAmount !== undefined && item.invoiceAmount !== null);
    };

    // Fetch and filter data for weekly, monthly, and yearly periods
    const [weeklyData, monthlyData, yearlyData] = await Promise.all([
      fetchAndFilterData('weekly'),
      fetchAndFilterData('monthly'),
      fetchAndFilterData('yearly'),
    ]);
    console.log('Weekly Data:', weeklyData);
    console.log('Monthly Data:', monthlyData);
    console.log('Yearly Data:', yearlyData);

    setWeeklyShowroomSalesData(weeklyData)
    setMonthlyShowroomSalesData(monthlyData)
    setYearlyShowroomSalesData(yearlyData)
  };
  useEffect(() => {
    fetchData();
    fetchSpareData();
    fetchSalesData();
  }, []);

  const { totalCount, totalPrice } = data.reduce(
    (acc, curr) => {
      const count = parseInt(curr.count) || 0;
      const price = parseInt(curr.pricetoDealer) || 0;
      acc.totalCount += count;
      acc.totalPrice += count * price;
      return acc;
    },
    { totalCount: 0, totalPrice: 0 }
  );

  const { weekelyTotalCount, weeklyTotalPrice } = weeklyShowroomData.reduce(
    (acc, curr) => {
      const count = parseInt(curr.count);
      const price = parseInt(curr.invoiceAmount == "" ? 0 : curr.invoiceAmount);
      acc.weekelyTotalCount += count;
      acc.weeklyTotalPrice += count * price;
      return acc;
    },
    { weekelyTotalCount: 0, weeklyTotalPrice: 0 }
  );

  const { monthlyTotalCount, monthlyTotalPrice } = monthlyShowroomData.reduce(
    (acc, curr) => {
      const count = parseInt(curr.count);
      const price = parseInt(curr.invoiceAmount == "" ? 0 : curr.invoiceAmount);
      acc.monthlyTotalCount += count;
      acc.monthlyTotalPrice += count * price;
      return acc;
    },
    { monthlyTotalCount: 0, monthlyTotalPrice: 0 }
  );

  const { yearlyTotalCount, yearlyTotalPrice } = yearlyShowroomData.reduce(
    (acc, curr) => {
      const count = parseInt(curr.count);
      const price = parseInt(curr.invoiceAmount == "" ? 0 : curr.invoiceAmount);
      acc.yearlyTotalCount += count;
      acc.yearlyTotalPrice += count * price;
      return acc;
    },
    { yearlyTotalCount: 0, yearlyTotalPrice: 0 }
  );



  return (
    <div className="requestBody">
      {prop == "showroom" && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <span style={{ fontSize: "11px", fontWeight: "600", marginLeft: "1%" }}>This Week</span>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <>
                <div
                  className="shopFloorRequest"
                  style={{ height: "auto", width: "115px", margin: "8px 5px" }}
                >
                  <img
                    src={Group9}
                    style={{
                      marginTop: "7px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    alt="group"
                  />
                  <div
                    style={{
                      color: "#808080",
                      fontSize: "11px",
                      textAlign: "center",
                      margin: "2px 6px 0px 6px",
                      borderBottom: "2px solid #F2F2F2",
                    }}
                  >
                    Total Sold Units
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      marginLeft: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "450",
                        textAlign: "center",
                      }}
                    >
                      {weekelyTotalCount}
                    </div>
                  </div>
                </div>
              </>
              <>
                <div
                  className="shopFloorRequest"
                  style={{ height: "auto", width: "115px", margin: "8px 5px" }}
                >
                  <img
                    src={Group12}
                    style={{
                      marginTop: "7px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    alt="group"
                  />
                  <div
                    style={{
                      color: "#808080",
                      fontSize: "11px",
                      textAlign: "center",
                      margin: "2px 6px 0px 6px",
                      borderBottom: "2px solid #F2F2F2",
                    }}
                  >
                    {`Total Sales Value (${auth.getUserInfo().currencySymbol})`}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      marginLeft: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "450",
                        textAlign: "center",
                      }}
                    >
                      {weeklyTotalPrice.toLocaleString("en-IN")}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <span style={{ fontSize: "11px", fontWeight: "600", marginLeft: "1%" }}>This Month</span>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <>
                <div
                  className="shopFloorRequest"
                  style={{ height: "auto", width: "115px", margin: "8px 5px" }}
                >
                  <img
                    src={Group9}
                    style={{
                      marginTop: "7px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    alt="group"
                  />
                  <div
                    style={{
                      color: "#808080",
                      fontSize: "11px",
                      textAlign: "center",
                      margin: "2px 6px 0px 6px",
                      borderBottom: "2px solid #F2F2F2",
                    }}
                  >
                    Total Sold Units
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      marginLeft: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "450",
                        textAlign: "center",
                      }}
                    >
                      {monthlyTotalCount}
                    </div>
                  </div>
                </div>
              </>
              <>
                <div
                  className="shopFloorRequest"
                  style={{ height: "auto", width: "115px", margin: "8px 5px" }}
                >
                  <img
                    src={Group12}
                    style={{
                      marginTop: "7px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    alt="group"
                  />
                  <div
                    style={{
                      color: "#808080",
                      fontSize: "11px",
                      textAlign: "center",
                      margin: "2px 6px 0px 6px",
                      borderBottom: "2px solid #F2F2F2",
                    }}
                  >
                    {`Total Sales Value (${auth.getUserInfo().currencySymbol})`}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      marginLeft: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "450",
                        textAlign: "center",
                      }}
                    >
                      {monthlyTotalPrice.toLocaleString("en-IN")}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>


        </div>
      )}
      {prop == "inventory" ? (
        <>
          <>
            <div
              className="shopFloorRequest"
              style={{ height: "auto", width: "239px", padding: "5%" }}
            >
              <img
                src={Group9}
                style={{
                  marginTop: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
                alt="group"
              />
              <div
                style={{
                  color: "#808080",
                  fontSize: "14px",
                  textAlign: "center",
                  margin: "10px 6px 0px 6px",
                  borderBottom: "2px solid #F2F2F2",
                }}
              >
                Total Available Units
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  marginLeft: "15px",
                }}
              >
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "450",
                    textAlign: "center",
                  }}
                >
                  {totalCount}
                </div>
              </div>
            </div>
          </>
          <>
            <div
              className="shopFloorRequest"
              style={{ height: "auto", width: "239px", padding: "5%" }}
            >
              <img
                src={Group12}
                style={{
                  marginTop: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
                alt="group"
              />
              <div
                style={{
                  color: "#808080",
                  fontSize: "14px",
                  textAlign: "center",
                  margin: "10px 6px 0px 6px",
                  borderBottom: "2px solid #F2F2F2",
                }}
              >
                Total Inventory Value
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  marginLeft: "15px",
                }}
              >
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "450",
                    textAlign: "center",
                  }}
                >
                  {totalPrice.toLocaleString("en-IN")}
                </div>
              </div>
            </div>
          </>
        </>

      ) : (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }} >
          <span style={{ fontSize: "11px", fontWeight: "600", marginLeft: "1%" }}>This Year</span>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="shopFloorRequest"
              style={{ height: "auto", width: "239px" }}
            >
              <img
                src={Group9}
                style={{
                  marginTop: "7px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
                alt="group"
              />
              <div
                style={{
                  color: "#808080",
                  fontSize: "11px",
                  textAlign: "center",
                  margin: "2px 6px 0px 6px",
                  borderBottom: "2px solid #F2F2F2",
                }}
              >
                Total Sold Units
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  marginLeft: "15px",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "450",
                    textAlign: "center",
                  }}
                >
                  {yearlyTotalCount}
                </div>
              </div>
            </div>
            <div
              className="shopFloorRequest"
              style={{ height: "auto", width: "239px" }}
            >
              <img
                src={Group12}
                style={{
                  marginTop: "7px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
                alt="group"
              />
              <div
                style={{
                  color: "#808080",
                  fontSize: "11px",
                  textAlign: "center",
                  margin: "2px 6px 0px 6px",
                  borderBottom: "2px solid #F2F2F2",
                }}
              >
                {`Total Sales Value (${auth.getUserInfo().currencySymbol})`}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  marginLeft: "15px",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "450",
                    textAlign: "center",
                  }}
                >
                  {yearlyTotalPrice.toLocaleString("en-IN")}
                </div>
              </div>
            </div>
          </div>
        </div>

      )}


    </div>
  );
};

export default VehicleCard;
