import React, { useState, useEffect } from "react";
import "../../outward.scss";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { CheckCircle } from "@material-ui/icons";
import PartiallyClosure from "../../../orders/action/PartiallyClosure";
import ViewSpecs from "../../../bom/bom-viewspecs";
import AddComponents from "./addComponents";
import Reason from "./Reason";
import CompFullRelease from "./compFullRelease";
import DealerSparePO from "../../../RequisitionOrders/DealerSparePO";
import DealerVehiclePO from "../../../RequisitionOrders/DealerVehiclePO";

const ReleaseCompInventory = ({ display, close, rowData: initialRowData, fetchReport }) => {
  const [specsShow, setSpecsShow] = useState();
  const [actionShow, setActionShow] = useState();
  const [RNoteData, setRNoteData] = useState();
  const [openAdd, setOpenAdd] = useState();
  const [vehicleData, setVehicleData] = useState([]);
  const [updatedVehicleDataObject, setUpdatedVehicleDataObject] = useState({});
  const [fullRelease, setFullRelease] = useState(false);
  const [partiallyRelease, setPartiallyRelease] = useState(false);
  const [openPO, setPO] = useState(false);
  const [remainingQtyCheck, setremainingQtyCheck] = useState();
  const [rowData, setRowData] = useState(initialRowData); // New state to manage updated rowData

  useEffect(() => {
    setRowData(initialRowData); // Sync rowData with initialRowData when it changes
  }, [initialRowData]);


  console.log(rowData);

  const FullReleaseHandler = () => {
    setPartiallyRelease(false);
    setFullRelease(true);
  };

  const PartiallyReleaseHandler = () => {
    setFullRelease(false);
    setPartiallyRelease(true);
  };

  const handlePage = () => {
    display(true);
    close(false);
  };

  let qtyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.color + "-" + row.requestedQuantity;
  };

  const vehicleUpdatedData = (data) => {
    console.log(data);
    setUpdatedVehicleDataObject(data);
    setVehicleData([data]);
  };

  const handleRowDataUpdate = (updatedRow, index) => {
    const updatedRowData = { ...rowData };
    updatedRowData.requisitionDetails[index] = updatedRow;
    setRowData(updatedRowData);
  };

  let addVehicleFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row);
    // if (Object.keys(updatedVehicleDataObject).length != 0) {
    if (row?.releasedQty) {
      console.log(row.added);
      return (
        <div>
          {
            <button className="added-btn" onClick={() => setOpenAdd(rowIndex)}>
              <CheckCircle />
              Add
            </button>
          }
          {openAdd === rowIndex && (
            <AddComponents
            vehicleUpdatedData={vehicleUpdatedData}
            row={row}
            rowData={rowData}
            updatedData={fetchReport}
            closeModal={setOpenAdd}
            updateRowData={(updatedRow) => handleRowDataUpdate(updatedRow, rowIndex)}
            />
          )}
        </div>
      );
    } else {
      return (
        <div>
          <button className="add-btn" onClick={() => setOpenAdd(rowIndex)}>
            Add
          </button>
          {openAdd === rowIndex && (
            <AddComponents
            vehicleUpdatedData={vehicleUpdatedData}
            row={row}
            rowData={rowData}
            updatedData={fetchReport}
            closeModal={setOpenAdd}
            updateRowData={(updatedRow) => handleRowDataUpdate(updatedRow, rowIndex)}         
              />
          )}
        </div>
      );
    }
  };
  // else {
  //   return (
  //     <div>
  //       <button className="add-btn" onClick={() => setOpenAdd(rowIndex)}>
  //         Add
  //       </button>
  //       {openAdd === rowIndex && (
  //         <AddComponents
  //           vehicleUpdatedData={vehicleUpdatedData}
  //           row={row}
  //           rowData={rowData}
  //           updatedData={fetchReport}
  //           closeModal={setOpenAdd}
  //         />
  //       )}
  //     </div>
  //   );
  // }
  // };

  const actionHandler = (rowIndex) => {
    setSpecsShow(true);
    setActionShow(rowIndex);
  };

  const specsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => actionHandler(rowIndex)}
          style={{ color: "blue", fontSize: "14px" }}
        >
          View Specification
        </button>
        {actionShow === rowIndex && specsShow ? (
          <ViewSpecs rowData={row} close={setSpecsShow} />
        ) : null}
      </div>
    );
  };

  let CNO_Formatter = (cell, row, rowIndex, formatExtraData) => {
    return row?.releasedQty ? (
      <ul className="VIN_NO" style={{ padding: "0px" }}>
        {row?.releasedQty?.map((item) => {
          return <li>{item.partSno}</li>;
        })}
      </ul>
    ) : (
      "-"
    );
  };

  let addedQtyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <span>{row?.releasedQty?.length}</span>;
  };

  let remainingQtyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <span>{row?.requireQty - row?.releasedQty?.length}</span>;
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Component Name",
      dataField: "sparePartname",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "SKU Code",
      dataField: "skuCode",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Category",
      dataField: "category",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "180px" },
    },
    {
      text: "Sub Category",
      dataField: "subCategory",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Specifications",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatter: specsFormatter,
      formatExtraData: { specsShow, actionShow },
    },
    {
      text: "Ordered Qty.",
      dataField: "requiredQty",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Added Qty.",
      // dataField: "addedQty",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "130px" },
      formatter: addedQtyFormatter,
    },
    {
      text: "Remaining Qty.",
      dataField: "remainingQty",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "130px" },
      // formatter:remainingQtyFormatter
    },
    {
      text: "Component S No.",
      dataField: "releasedQty",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "180px" },
      formatter: CNO_Formatter,
    },
    {
      text: "Add Components",
      // dataField: "color",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "180px" },
      formatExtraData: { openAdd, actionShow },
      formatter: addVehicleFormatter,
    }
  ];



  
  useEffect(() => {
    const remainingTotal = rowData?.requisitionDetails
      ?.map((item) => parseInt(item?.remainingQty) || 0)
      .filter((qty) => !isNaN(qty) && qty > 0);
  
    if (remainingTotal && remainingTotal.length > 0) {
      const sum = remainingTotal.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      setremainingQtyCheck(sum);
    } 
  }, [rowData]);
  
  console.log(remainingQtyCheck);
  

  return (
    <>
      <div className="bussiness">
        {
          openPO ? <DealerSparePO closeModal={setPO} rowData={rowData} type="requisitionDetails" /> : ""
        }

        <h4 className="heading">Release Inventory</h4>
        <button
          type="button"
          className="btn btn-md"
          style={{ float: "right", marginTop: "-49px" }}
        >
          <div
            style={{ display: "flex", marginRight: "-18px", height: "26px" }}
          >
            <div className="filter-refresh" onClick={handlePage}>
              <FontAwesomeIcon icon={faRedoAlt} />
              Back
            </div>
          </div>
        </button>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Requested ID:{rowData?.requisitionID}</span>
          <span>Requested By:{rowData?.requestBy}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>
            <span>PO No.: </span>
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => setPO(true)}
            >
              {rowData?.poNo}
            </span>
          </span>
          <span>
            Requested Date:{" "}
            {moment(rowData?.requestedDate).format("DD-MMM-YYYY/HH:mm")}
          </span>
        </div>
      </div>
      <br />
      <div className="bootstraptbl">
        <BootstrapTable
          wrapperClasses="table-responsive"
          rowClasses="text-nowrap"
          keyField="id"
          rowStyle={{ border: "1px solid #CDCDCD" }}
          data={rowData?.requisitionDetails}
          columns={columns}
          pagination={paginationFactory({
            hidePageListOnlyOnePage: true,
            showTotal: true,
            hideSizePerPage: true,
            paginationSize: 2,
            sizePerPage: "10",
          })}
        />
      </div>
      <div
        className="footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
          float: "right",
        }}
      >
        <div>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              marginRight: "10px",
              color: "#0A18A3",
              height: "40px",
            }}
            onClick={PartiallyReleaseHandler}
          >
            Partial Release
          </button>
          {partiallyRelease && (
            <Reason
              editData={rowData}
              finalData={rowData}
              releaseType="Partial Release"
              closeModal={setPartiallyRelease}
              updatedData={fetchReport}
            />
          )}
          <button
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              color: "white",
              width: "140px",
              height: "40px",
            }}
            disabled={remainingQtyCheck > 0 ? true:false}
            onClick={FullReleaseHandler}
          >
            Full Release
          </button>
          {fullRelease && (
            <CompFullRelease
              editData={rowData}
              finalData={rowData}
              releaseType="Full Release"
              closeModal={setFullRelease}
              updatedData={fetchReport}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ReleaseCompInventory;
