import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';

const NationalVehicleOrderCard = ({ prop }) => {

    const [data, setData] = useState({});
    const [spareData, setSpareData] = useState({});

    const fetchData = async () => {
        const res = await trackPromise(
            API.get(`/nationalSales-module/api/v1/dashboard/vehicleOrders`)
        );
        setData(res);
    };
    const fetchSpareData = async () => {
        const res = await trackPromise(
            API.get(`/nationalSales-module/api/v1/dashboard/spareOrders`)
        );
        setSpareData(res);
    };
    useEffect(() => {
        { prop == "vehicle" ? fetchData() : fetchSpareData() }
    }, []);

    return (
        <div style={{ width: "95%", marginLeft: "10px" }}>
            <ProgressBar>
                <ProgressBar now={prop == "vehicle" ? data.totalVehicles : spareData.totalVehicles * 100} style={{ background: "#0A18A3" }} />
                <ProgressBar now={prop == "vehicle" ? data.newRequest : spareData.newRequest * 100} style={{ background: "#AE0218" }} />
                <ProgressBar now={prop == "vehicle" ? data.pendingAtFinance : spareData.pendingAtFinance * 100} style={{ background: "rgba(255, 215, 74, 1)" }} />
                <ProgressBar now={prop == "vehicle" ? data.approvedByFinanceVehicle : spareData.approvedByFinance * 100} style={{ background: "rgba(33, 168, 46 , 1)" }} />
                <ProgressBar now={prop == "vehicle" ? data.PartialShipment : spareData.PartialShipment * 100} style={{ background: "#682FE2" }} />
                <ProgressBar now={prop == "vehicle" ? data.FullShipment : spareData.FullShipment * 100} style={{ background: "#03B78C" }} />
            </ProgressBar>
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430", marginTop: "20px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#0A18A3", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>{`Total ${prop == "vehicle" ? "Vehicle" : "Spare"} PO`}</span>
                </div>
                <div>
                    {prop == "vehicle" ? data.totalVehicles : spareData.totalVehicles}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#AE0218", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>New PO Requests</span>
                </div>
                <div>
                    {prop == "vehicle" ? data.newRequest : spareData.newRequest}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(255, 215, 74, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Pending at Finance</span>
                </div>
                <div>
                    {prop == "vehicle" ? data.pendingAtFinance : spareData.pendingAtFinance}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(33, 168, 46 , 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Approved By Finance</span>
                </div>
                <div>
                    {prop == "vehicle" ? data.approvedByFinanceVehicle : spareData.approvedByFinance}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#682FE2", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Partial Shipment</span>
                </div>
                <div>
                    {prop == "vehicle" ? data.PartialShipment : spareData.PartialShipment}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#03B78C", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Full Shipment</span>
                </div>
                <div>
                    {prop == "vehicle" ? data.FullShipment : spareData.FullShipment}
                </div>
            </div>
        </div>
    )
}

export default NationalVehicleOrderCard