import React from "react";
import { Tabs } from "react-simple-tabs-component";
import "../settings-tabs/tabs.scss";
import { TabLabels } from "../../utils/app-constants";
// import ShowroomVehicleInventory from "./showroomVehicleInventory";
// import ShowroomBatteryInventory from "./showroomBatteryInventory";

const InventoryVehicle = React.lazy(()=>import("./InventoryVehicle"))
const ShowroomBatteryInventory = React.lazy(()=>import("./showroomBatteryInventory"))


const tabs = [
    {
      id:"SHOM3T1",
      label: TabLabels.VEHICLE_INVENTORY,
      Component: InventoryVehicle
    },
    {
      id:"SHOM3T2",
      label: TabLabels.BATTERY_INVENTORY,
      Component: ShowroomBatteryInventory
    }
  ];

function ShowroomInventoryTabs() {
    return (
        <div className="app-layout__card">
            <Tabs tabs={tabs} type="pills" />
        </div>
    );
}

export default ShowroomInventoryTabs