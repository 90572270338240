import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { NavItem } from "reactstrap";
import "../../components/modal/modal.scss";
import AppConstants from "../../utils/app-constants";
import SearchBox from "../../components/search/SearchBox";
import { errorNotification } from "../../utils/notifications";
import Actions from "./actions/Actions";
import ToolkitProvider, { CSVExport , Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import handleExport from "../../utils/exportExcel";

const Standard = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [open, setOpen] = useState();
  const [actionData, setActionData] = useState([]);
  const [count, setCount] = useState([]);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `management/api/v1/bom/standardvehicle?vehicleSegment&vehicleModel&vehicleVariant=&vehicleType=`
      )
    );
    console.log(resp);

    // _ID
    const data = resp.filter((item, index) => {
      return item._id.vehicleCategory === "Standard";
    });
    setUsersData(data.map((item, index) => item._id));

    //  Action Data
    const action = await resp.map((item, index) => {
      return [...item.data];
    });
    setActionData(action);

    // Count
    const stdCount = await data.map((item, index) => item.count);
    setCount(stdCount);
  };

  useEffect(() => {
    fetchReport();
  }, []);

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && open ? (
          <Actions rowData={actionData[rowIndex]} status={true} rowIndex={rowIndex} />
        ) : null}
      </div>
    );
  };

  const [formValue, setFormValue] = useState({
    category: "",
    subCategory: "",
    skuCode: "",
    componentName: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      category: "",
      subCategory: "",
      skuCode: "",
      componentName: "",
    });
    const resp = await trackPromise(
      API.get(
        `management/api/v1/bom/standardvehicle?vehicleSegment&vehicleModel&vehicleVariant=&vehicleType=`
      )
    );
    // _ID
    const data = resp.filter((item, index) => {
      return item._id.vehicleCategory === "Standard";
    });
    setUsersData(data.map((item, index) => item._id));

    //  Action Data
    const action = await resp.map((item, index) => {
      return [...item.data];
    });
    setActionData(action);

    // Count
    const stdCount = await data.map((item, index) => item.count);
    setCount(stdCount);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const qtyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <span>{count[rowIndex]}</span>
      </>
    );

  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const exportData = UsersData.map((row, index) => ({
    "S.No.": index + 1,
    "Segment": row?.vehicleSegment ?? "",
    "Vehicle Model": row?.vehicleModel ?? "",
    "Vehicle Variant": row?.vehicleVariant ?? "",
    "Vehicle Type": row?.vehicleType ?? "",
    "Vehicle Color": row?.vehicleColor ?? "",
    "Available Qty.": qtyFormatter(null, row, index, { count }),
  }));
  

  const columns = [
    {
      text: "S.No.",
      // dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Segment",
      dataField: "vehicleSegment",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      // classes: "alignment"
    },
    {
      text: "Vehicle Model",
      dataField: "vehicleModel",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vehicle Variant",
      dataField: "vehicleVariant",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      // classes: "alignment",
      // formatter: noCloudId
    },

    {
      text: "Vehicle Type",
      dataField: "vehicleType",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      // classes: "alignment",
      // formatter: noCloudId
    },
    {
      text: "Vehicle Color",
      dataField: "vehicleColor",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      // classes: "alignment",
      // formatter: noCloudId
    },
    {
      text: "Available Qty.",
      dataField: "",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { count },
      formatter: qtyFormatter,
    },
    {
      text: "Action",
      // dataField: "title",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open, actionData },
      formatter: actionFormatter,
    },
  ];

  let uniqueCategoryArray = [
    ...new Map(UsersData.map((item) => [item["category"], item])).values(),
  ];

  return (

    <>
 <div >
       <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{marginBottom:"-5px"}}>
          <span>Standard Vehicle</span>
        </div>

         <ToolkitProvider keyField="id" data={UsersData} columns={columns} search >
         
          {(props) => (
            <>
            <div>
              <div style={{ float: "right", marginTop: "-42px"}}>
                <div style={{ display: "flex", marginRight: "-15px", height: "26px", marginTop:"10px", }}>
                  <div style={{ width: "220px" }}>
                    
                     <SearchBar
                      {...props.searchProps}
                      placeholder='Search'
                      style={{
                        height: "25px",
                       // border: "1px solid #C6C6C6",
                        border:"none",
                        background: "white",
                        borderRadius: "3px",
                        marginRight: "-50px",
                      }}
                    />
                    
                  </div>
                  <div onClick={clearFilter} className="filter-refresh">
                    <FontAwesomeIcon icon={faRedoAlt} />
                    &nbsp; Refresh
                  </div>
                  <div className="filter-refresh" onClick={handleClick}>
                    {" "}
                    <FontAwesomeIcon icon={faFilter} />
                    &nbsp; Filters
                  </div>
                  <div
                    className="filter-refresh"
                    onClick={() => handleExport(exportData, "Standard vehicle")}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                    &nbsp; Export
                  </div>
                  {inDashboard ? (
              <div
                className="filter-refresh"
                onClick={() => inDashboard(false)}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            ) : (
              ""
            )}
                </div>
               </div>
              </div>
               
     <div>

   

      {show && (
        <>
          <form
            onSubmit={submitHandler}
            style={{
              display: "flex",
              flexWrap: "wrap",
              height: "85px",
              backgroundColor: "#F7F7F7",
              padding: "5px",
            }}
            className="UHinputfields VMform"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "18%",
              }}
            >
              <label style={{ fontSize: "13px", fontWeight: "500" }}>
                Category
              </label>
              <select
                name="category"
                value={formValue.category}
                onChange={handleChange}
                style={{ marginTop: "-5px", width: "100%" }}
              >
                <option value="" disabled selected>
                  Category
                </option>
                {uniqueCategoryArray.map((item) => {
                  return <option>{item.category}</option>;
                })}
              </select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "18%",
              }}
            >
              <label style={{ fontSize: "13px", fontWeight: "500" }}>
                Sub Category
              </label>
              <input
                type="text"
                name="subCategory"
                value={formValue.subCategory}
                onChange={handleChange}
                placeholder="Sub Category"
                autoComplete="off"
                style={{ marginTop: "-5px", width: "100%" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "18%",
              }}
            >
              <label style={{ fontSize: "13px", fontWeight: "500" }}>
                SKU Code
              </label>
              <input
                type="text"
                name="skuCode"
                value={formValue.skuCode}
                onChange={handleChange}
                placeholder="SKU Code"
                autoComplete="off"
                style={{ marginTop: "-5px", width: "100%" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "18%",
              }}
            >
              <label style={{ fontSize: "13px", fontWeight: "500" }}>
                Component Name
              </label>
              <input
                type="text"
                name="componentName"
                value={formValue.componentName}
                onChange={handleChange}
                placeholder="Component Name"
                autoComplete="off"
                style={{ marginTop: "-5px", width: "100%" }}
              />
            </div>
            <div
              style={{
                width: "22%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <button
                onClick={fetchReport}
                style={{ width: "100px", marginTop: "22px" }}
                id="apply"
              >
                Apply
              </button>
              <button
                style={{ width: "100px", marginTop: "22px" }}
                onClick={clearFilter}
                id="clear"
              >
                Clear Filter
              </button>
            </div>
          </form>
        </>
      )}
      <br></br>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "80%",
        }}
      >
        <span
          style={{
            display: "flex",
            background: "#DDDFF2",
            alignItems: "center",
            height: "30px",
            justifyContent: "center",
            borderRadius: "5px",
            color: "#1D56A4",
            width: "230px",
            fontSize: "14px",
            fontWeight: "800",
          }}
        >
          Total Vehicles Added - {count.reduce((a, b) => a + b, 0)}
        </span>
        <span
          style={{
            display: "flex",
            background: "#FBE8EA",
            alignItems: "center",
            height: "30px",
            justifyContent: "center",
            borderRadius: "5px",
            color: "#DB434F",
            width: "230px",
            fontSize: "14px",
            fontWeight: "800",
          }}
        >
          Total Vehicles Release - 0{/*Pending*/}
        </span>
        <span
          style={{
            display: "flex",
            background: "#DBEBDD",
            alignItems: "center",
            height: "30px",
            justifyContent: "center",
            borderRadius: "5px",
            color: "#2C8633",
            width: "230px",
            fontSize: "14px",
            fontWeight: "800",
          }}
        >
          Available Vehicles - {count.reduce((a, b) => a + b, 0)}
        </span>
      </div>
      <br />


     </div>

      
              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
        </div>
    </>
  );
};

export default Standard;
