import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import AssignedWorkorder from "./assigned-workorder";
// import ManagedWorkorder from "./managed-workorder";
// import FinishedGoodsVerification from "./finishedgoodsverification";

const AssignedWorkorder = React.lazy(()=>import("./assigned-workorder"))
const ManagedWorkorder = React.lazy(()=>import("./managed-workorder"))
const FinishedGoodsVerification = React.lazy(()=>import("./finishedgoodsverification"))



const WoShopfloor = () => {
  const tabs = [
    {
      id: "SFM1T1",
      label: TabLabels.ASSIGNED_WORKORDER,
      Component: AssignedWorkorder
    },
    {
      id: "SFM1T2",
      label: TabLabels.MANAGED_WORKORDER,
      Component: ManagedWorkorder
    },
    {
      id: "SFM1T3",
      label: TabLabels.FINISHED_GOODS_VERIFICATION,
      Component: FinishedGoodsVerification
    },
  ];

  const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
    return tabs.filter(tab => tabIds.includes(tab.id));
  };
  let tabsToShow = getTabs(tabs);

  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>

  )
}
export default WoShopfloor