import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { KeyboardBackspace } from "@material-ui/icons";
import "../../work-order/workorder.scss";
import ViewSpecs from "../../wo-shopfloor/wo-sf-actions/wo-sf-viewspecs";
import "../BomCosting.scss";
import auth from "../../../utils/auth";

const ViewDetailsComponents = ({ close, rowdata }) => {
  const [specsShow, setSpecsShow] = useState(false);
  const [UsersData, setUsersData] = useState(undefined);

  // Fetch report data for components
  const fetchReport = async () => {
    try {
      const resp = await trackPromise(
        API.get(
          `procurement-module/api/v1/boms/viewComponents?BOMID=${rowdata.BOMID}`
        )
      );
      setUsersData(resp);
    } catch (error) {
      console.error("Error fetching components data: ", error);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <>
      <div className="container1" style={{ padding: "0" }}>
        <div
          className="modalContainer"
          style={{
            width: "1100px",
            maxHeight: "465px",
            height: "auto",
            padding: "10px",
            boxSizing: "border-box",
          }}
        >
          <div
            className="header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "8px",
            }}
          >
            <h3 style={{ margin: "0" }}>Required Components</h3>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "15px",
              fontWeight: "500",
              marginBottom: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              <span>Total Components: {UsersData?.totalComponents}</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              <span>Total Cost ({auth.getUserInfo().currencySymbol}): {UsersData?.totalComponentsCost}</span>
              <span>Delivery Location: {UsersData?.bomLocation}</span>
            </div>
          </div>

          {/* Scrollable Table Body */}
          <div className="body" style={{ maxHeight: "300px", overflowY: "auto" }}>
            <table className="viewcomp_tbl" style={{ width: "100%", tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>S.No.</th>
                  <th style={{ width: "15%" }}>Component Name</th>
                  <th style={{ width: "8%" }}>SKU Code</th>
                  <th style={{ width: "14%" }}>Category</th>
                  <th style={{ width: "10%" }}>Sub Category</th>
                  <th style={{ width: "10%" }}>Specification</th>
                  <th style={{ width: "7%" }}>Req Qty.</th>
                  <th style={{ width: "15%" }}>Vendor Name</th>
                  <th style={{ width: "10%" }}>Unit Price ({auth.getUserInfo().currencySymbol})</th>
                  <th style={{ width: "9%" }}>Sub Total ({auth.getUserInfo().currencySymbol})</th>
                </tr>
              </thead>
              <tbody>
                {rowdata?.bomComponents?.length > 0 ? (
                  rowdata?.bomComponents?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ fontSize:"13px", width: "5%" }}>{index + 1}</td>
                      <td style={{ fontSize:"13px", width: "15%" }}>{item?.componentName}</td>
                      <td style={{ fontSize:"13px", width: "8%" }}>{item?.skuCode}</td>
                      <td style={{ fontSize:"13px", width: "14%" }}>{item?.category}</td>
                      <td style={{ fontSize:"13px", width: "10%" }}>{item?.subCategory}</td>
                      <td style={{ fontSize:"13px", width: "10%" }}>
                        <button
                          onClick={() => setSpecsShow(true)}
                          className="action-btn"
                          style={{ color: "blue", padding: "2px 5px" }}
                          aria-label={`View specifications of ${item?.componentName}`}
                        >
                          View Specs
                        </button>
                        {specsShow && (
                          <ViewSpecs rowData={item} close={setSpecsShow} />
                        )}
                      </td>
                      <td style={{ fontSize:"13px", width: "7%" }}>{item?.componentQuantity}</td>
                      <td style={{ fontSize:"13px", width: "15%" }}>{item?.vendorDetails[0]?.vendorName}</td>
                      <td style={{ fontSize:"13px", width: "10%" }}>{item?.vendorDetails[0]?.deliveryDetails[0]?.unitPrice}</td>
                      <td style={{ fontSize:"13px", width: "9%" }}>{(item?.vendorDetails[0]?.deliveryDetails[0]?.unitPrice)*item?.componentQuantity}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" style={{ textAlign: "center" }}>
                      No components available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="footer" style={{ marginTop: "8px" }}>
            <button className="btn-footer" onClick={() => close(false)}>
              <KeyboardBackspace style={{ fontSize: "25px" }} /> Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDetailsComponents;
