import React, { useState } from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RRViewDetails from "./rrViewDetails";
import MoveToHistory from "./MoveToHistory";

const ReturnRequestAction = ({ rowData, setIsOpen , rowIndex}) => {
    const [showModal, setShowModal] = useState(true);
    const [activeModal, setActiveModal] = useState({});

    const clickHandler = (e) => {
        setActiveModal({
            viewDetails: false,
            moveToHistory: false,
            [e.target.id]: true,
        });
        setShowModal(true);
    };

    return (
        <div>
            {activeModal.viewDetails && showModal && (
                <RRViewDetails rowData={rowData} closeModal={setShowModal} />
            )}
            {activeModal.moveToHistory && showModal && (
                <MoveToHistory closeModal={setShowModal} rowData={rowData} />
            )}


            <div className="action" style={{ position: "relative", marginTop: rowIndex <= 4 ? "0px" : "-20px", }} onBlur={() => setIsOpen(false)}>
                <div className="action vendoraction" style={{ marginLeft: "-30px", marginTop: "0px", padding: "0px" }}>
                    <div>
                        <span id="viewDetails" onClick={(e) => clickHandler(e)}>
                            <FontAwesomeIcon icon={faEdit} />  View Details
                        </span>
                    </div>
                    {
                        rowData.status === "Returns Settled" ?
                            <div>
                                <span id="moveToHistory" onClick={(e) => clickHandler(e)}>
                                    <FontAwesomeIcon icon={faEdit} />  Move To History
                                </span>
                            </div>
                            :
                            ""
                    }
                </div>
            </div>
        </div>
    );
};

export default ReturnRequestAction;
