import React, { useEffect, useState } from "react";
import auth from "../../../utils/auth";
import ReactToPrint from "react-to-print";
import { BorderColor, Close } from "@material-ui/icons";
import Table from "react-bootstrap/esm/Table";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";



const SalesInvoice = ({ closeSalesInvoice, rowData }) => {

  console.log("rowData-->",rowData)


  const [data, setData] = useState([]);

  const fetchReport =  async () => {
      const resp = await trackPromise(
        API.get(`/showroom-module/api/v1/customerAndSales/getAllVehicleSales`)
      );
      console.log(resp);
      setData(resp);
    };

    useEffect(()=>{
      fetchReport()
    },[])

    console.log("data---->", data)


   
  let componentRef = null;



  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          width: "570px",
          height: "625px",
          padding: "15px",
          borderRadius: "0px",
          borderTop: "none",
        }}
      >
         <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Tax Invoice</h3>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <button
                    style={{
                      color: "#0A18A3",
                      margin: "1px",
                      height: "27px",
                      width: "78px",
                      border: "none",
                    }}
                  >
                    Download
                  </button>
                );
              }}
              content={() => componentRef}
              documentTitle="Sales Invoice"
              pageStyle="Print"
            />
          </div>
        </div>


        <div className="body">
          <div 
             style={{
            width: " 100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            pageBreakBefore:"always",
          }}
          ref={(el) => (componentRef = el)}
          >
         

        <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{
                backgroundColor:"#0A18A3",
                color:"white",
                width:"350px",
                height:"10px",
                paddingRight:"12px",
                display:"flex",
                margin:"20px 20px 2px 20px",
                marginTop:"10px",
                flexGrow:"1",
                }}>

                </div>

              <div style={{display:"flex",flexDirection:"column"}}>
              <div>
                <h5>Sales Invoice</h5>
              </div>
              </div>

              <div style={{
                backgroundColor:"#0A18A3",
                color:"white",
                width:"20px",
                height:"10px",
                paddingRight:"12px",
                display:"flex",
                marginLeft:"10px",
                marginTop:"10px",
                }}>

                </div>
            </div>     

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{
                width:"200px",
                fontSize:"18px",
                marginTop:"5px",
              }}>Jacob Honda :</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>Address</span>
              <span>GSTIN No. : {}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>Phone :</span>
              <span>Email : {}</span>
            </div>
            
          </section>


          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{
                backgroundColor:"#0A18A3",
                color:"white",
                width:"2100%",
                height:"2px",
                paddingLeft:"12px",
                }}></span>
            </div>



            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
                fontSize:"12px",
                fontWeight:"bold"
              }}
            >
              <span>To : {}</span>
              <span>Invoice No : {}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>Address : </span>
              <span>HSN Code : </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection:"column",
                justifyContent: "initial",
                fontWeight: "400",
              }}
            >
              <span>Email : </span>
              <span>Phone No : </span>
            </div>

          </section>

          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              fontWeight: "500",
              margin: "5px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{
                backgroundColor:"#0A18A3",
                color:"white",
                width:"2100%",
                height:"2px",
                paddingLeft:"12px",
                }}></span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "initial",
                fontWeight: "400",
                fontWeight:"100",
              }}
            >
              <span>Payment in favor of : </span>
            </div>

          </section>

          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              fontWeight: "500",
              margin: "5px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{
                backgroundColor:"#0A18A3",
                color:"white",
                width:"2100%",
                height:"2px",
                paddingLeft:"12px",
                }}></span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "initial",
                fontWeight: "400",
                fontWeight:"100",
              }}
            >
              <span>Model : {data.vehicleModel}</span>
            </div>

          </section>

          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              fontWeight: "500",
              margin: "5px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{
                backgroundColor:"#0A18A3",
                color:"white",
                width:"2100%",
                height:"2px",
                paddingLeft:"12px",
                }}></span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "initial",
                fontWeight: "400",
                fontWeight:"100",
              }}
            >
              <span>Color : {data.vehicleColor} </span>
            </div>

          </section>


          <section style={{ width: "100%",flexGrow: 1, }}>

          <div style={{marginBottom:"15px"}}>
                <div style={{backgroundColor:"#0A18A3", display:"flex", flexDirection:"row", justifyContent:"space-between", padding:"0px 30px 0px 30px"}}>
                    <div style={{color:"white"}}><b>Particulars</b></div>
                    <div style={{color:"white"}}><b>Amount</b></div>
                </div>
      
              <h6>(a) Ex-Showroom Value</h6>
              <div style={{ border: "1px solid grey", display:"flex", padding:"3px", justifyContent:"space-between"}}>
                <div style={{ display:"flex", flexDirection:"column", justifyContent:"start"}}>
                  <span>Net Value</span>
                  <span>CGST {data.cgst}</span>
                  <span> SGST {data.sgst}</span>
                </div>
                <div style={{ borderRight: "1px solid grey"}}>
                </div>
                <div style={{ display:"flex", flexDirection:"column", justifyContent:"start"}}>
                <span>2100</span>
                  <span>200</span>
                  <span> 200</span>
                </div>
              </div>
              <div style={{ border: "1px solid grey",borderTop:"none", display:"flex", padding:"3px", justifyContent:"space-between"}}>
              <span style={{ display:"flex", flexDirection:"column", justifyContent:"start"}}>
              Ex-Showroom Value
              </span>
              <span style={{ display:"flex", flexDirection:"column", justifyContent:"start"}}>
              2500
              </span>
              </div>
          </div>

      
          <h6>(b) Post Sale Values</h6>
              <div style={{ border: "1px solid grey", display:"flex", padding:"3px", justifyContent:"space-between"}}>
                <div style={{ display:"flex", flexDirection:"column", justifyContent:"start"}}>
                  <span> Hypothication charged</span>
                  <span> 14% life Tax</span>
                  <span> Insurance Promotional</span>
                </div>
                <div style={{ borderRight: "1px solid grey"}}>
                </div>
                <div style={{ display:"flex", flexDirection:"column", justifyContent:"start"}}>
                <span>2100</span>
                  <span>200</span>
                  <span> 200</span>
                </div>
              </div>
              <div style={{ border: "1px solid grey", borderTop:"none", display:"flex", padding:"3px", justifyContent:"space-between"}}>
              <span style={{ display:"flex", flexDirection:"column", justifyContent:"start"}}>
              Total on road price (A+B)
              </span>
              <span style={{ display:"flex", flexDirection:"column", justifyContent:"start"}}>
              5000
              </span>
              </div>

          <section className="POterms" style={{backgroundColor:"#0A18A3",display:"flex",flexDirection:"row", justifyContent:"center", marginTop:"15px" }}>
                <span style={{fontSize:'12px',color:"white"}}>In words Rupee : </span>
                <span style={{fontSize:'12px',color:"white"}}>Ten thousand five hundred fifty only</span>
          </section>
          
          </section>


          <section className="POterms" style={{padding:"3px 5px",marginBottom:"20px",backgroundColor:"white",display:"flex",flexDirection:"column"}}>
              <div style={{ display:"flex",flexDirection:"row", justifyContent:"space-between",marginTop:"12px"}}>
              <div  style={{ display:"flex",flexDirection:"column", justifyContent:"initial",marginTop:"12px", marginRight:"30px"}}>
               <span style={{fontWeight: "500", }}>Terms & Conditions :-</span>
               <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
              </div>

              <div
              style={{
                marginTop: "15px",
                height:"110px",
                width:"370px",
                backgroundColor:"white",
                // borderStyle:"ridge",
              }}
              >
              </div>
              </div>
              <div style={{ display:"flex",flexDirection:"row", justifyContent:"end",marginTop:"8px"}}>
              <span>Authorised Signature</span>
              </div>
              
          </section>
          </div>
        </div>


        <div className="footer">
          <button
            style={{ width: "104px", height: "35px", background: "#fff" }}
            onClick={()=>closeSalesInvoice(false)}
          >
            <Close style={{ fontSize: "25px" }} />
            Close
          </button>
        </div>

      </div>
    </div>
  );
};

export default SalesInvoice;

