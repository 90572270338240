import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import ProgressBar from "react-bootstrap/ProgressBar";
import API from "../../utils/api";

const ApprovalProgressBar = ({ props }) => {
  const [orderData, setOrderData] = useState({});

  const fetchRequest = async () => {
    const result = await trackPromise(API.get(`serviceCenter-module/api/v1/dashboard/getOrders`));
    setOrderData(result);
  };
  useEffect(() => {
    fetchRequest();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "95%",
        marginLeft: "10px",
        fontWeight: "430",
      }}
    >
      <ProgressBar>
        <ProgressBar style={{ backgroundColor: "#3B61CF" }} now={(orderData.totalOrder) * 100} />
        <ProgressBar style={{ backgroundColor: "#FDAA48" }} now={(orderData.totalPending) * 100} />
        <ProgressBar style={{ backgroundColor: "#FFD74A" }} now={(orderData.totalOnHold) * 100} />
        <ProgressBar style={{ backgroundColor: "#43A830" }} now={(orderData.totalCompleted) * 100} />
        <ProgressBar style={{ backgroundColor: "#EF4D62" }} now={(orderData.totalRejected) * 100} />
      </ProgressBar>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#3B61CF",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            Total Requisitions
          </span>
        </div>
        <div>
          {orderData.totalOrder}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#FDAA48",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            Pending Requisitions
          </span>
        </div>
        <div>{orderData.totalPending}</div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#FFD74A",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            Requisition On Hold
          </span>
        </div>
        <div>
          {orderData.totalOnHold}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#43A830",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            Requisition Completed
          </span>
        </div>
        <div>
          {orderData.totalCompleted}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#EF4D62",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            Requisition Rejected
          </span>
        </div>
        <div>
          {orderData.totalRejected}
        </div>
      </div>
    </div>
  );
}

export default ApprovalProgressBar;
