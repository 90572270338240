import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  KeyboardBackspace,
  CheckCircle
} from "@material-ui/icons";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import OpenConfirmation from "./OpenConfirmation";

const Edit = ({ closeModal, editData, userType, updatedData }) => {

  let fName = editData.asmFname;
  let lName = editData.asmLname;
  let email = editData.asmEmail;

  const [formValue, setFormValue] = useState(
    {
      fName: fName,
      lName: lName,
      email: email,
    },
  );

  const [getUnmappedAreas, setUnMappedAreas] = useState([]);
  const fetchUnmappedAreas = async () => {
    const resp = await trackPromise(API.get(`operations-module/api/v1/salesUser/getAllUnmappedAreas?zoneId=${editData.zoneId}`));
    console.log(resp);
    setUnMappedAreas(resp);
  };
  useEffect(() => {
    fetchUnmappedAreas();
  }, []);

  const [areasArray, setAreasArray] = useState(editData.areas);
  const [openConfirmation, setOpenConfirmartion] = useState(false);
  const [confirmFlag, setConfirmFlag] = useState(false);

  const handleInputChange = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  const handleConfirm = () => {
    setOpenConfirmartion(true);
  }

  const submitHandler = async () => {
    let areaBodyData = {
      countryName: editData.countryName,
      zone: editData.zone,
      zoneId: editData.zoneId,
      asmFname: formValue.fName,
      asmLname: formValue.lName,
      asmEmail: formValue.email,
      asmUserID: editData.asmUserID,
      areas: areasArray,
      asmUserType: "Area Sales"
    }
    console.log(areaBodyData);
    const resp = await trackPromise(API.post(`operations-module/api/v1/salesUser/editAreaUser`, areaBodyData));
    if (resp.success) {
      updatedData("", "Area Sales")
      closeModal(false)
      return successNotification(resp.message, { autoClose: 10000 });
    } else {
      return errorNotification(resp.message, { autoClose: 10000 });
    }
  };

  const onCheckboxChange = (e, item) => {
    console.log(areasArray)
    let tempInfo = [...areasArray];
    if (e.target.checked == true) {
      if (e.target.checked == false) {
        tempInfo = tempInfo.filter((info) => info.areaId != item.areaId);
        e.target.checked == false
      } else {
        tempInfo.push(item);
      }

    } else {
      if (e.target.checked == false) {
        tempInfo = tempInfo.filter((info) => info.areaId != item.areaId);
      } else {
        tempInfo.push(item);
      }
    }
    setAreasArray(tempInfo);
  };

  var expanded = false;
  const showCheckboxes = () => {
    var checkboxes = document.getElementById("checkboxes")
    if (!expanded && userType != "National Sales") {
      checkboxes.style.display = "block";
      expanded = true;
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  }

  const closePrevState = ()=>{
    const checkboxes = document.querySelectorAll("#checkboxes");
    checkboxes.forEach(checkbox => {
        checkbox.style.display = "none";
    });
    expanded = false;
 }

  return (
    <div className="container1">
      {openConfirmation && !confirmFlag && <OpenConfirmation closeModal={setOpenConfirmartion} getConfirmFlag={setConfirmFlag} />}
      <div
        className="modalContainer"
        style={{ width: "835px", height: "400px", padding: "15px" }}
      >
        <div className="header">
          <h3>Edit User Details </h3>
        </div>
        <section>
          <form id="add-user-from" className="setting-form">
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px"
                  }}
                >
                  User Type
                </label>
                <input
                  type="text"
                  placeholder="User Type"
                  name="fName"
                  disabled
                  value={userType}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px"
                  }}
                >
                  Country
                </label>
                <input
                  type="text"
                  disabled
                  placeholder="Country"
                  name="fName"
                  value={editData.countryName}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px"
                  }}
                >
                  Zone
                </label>
                <input
                  type="text"
                  placeholder="Country"
                  name="zone"
                  value={editData.zone}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                  disabled
                />
              </div>
              <div
                style={{
                  margin: "0px 10px 0px 0px",
                  // width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div class="multiselect">
                  <div class="selectBox" onClick={() => showCheckboxes()}>
                    <label style={{ display: 'flex', flexDirection: 'column' }}>
                      <label style={{ marginLeft: '-107px', fontSize: '13px', fontWeight: '500' }}>Select Areas</label>
                      <select
                        name="area"
                        className="input-config"
                        id="areaId"
                        style={{ marginTop: "-8px" }}
                      >
                        <option value=''>Select Areas</option>
                      </select>
                    </label>
                    <div class="overSelect"></div>
                  </div>
                  <div id="checkboxes" style={{ padding: "5px", textAlign: "left", position: "absolute", width: "15%" }}>
                    {[...getUnmappedAreas, ...editData?.areas]?.map((item, index) => {
                      return (
                        <label>
                          <input
                            className="zoneCheckbox"
                            type="checkbox"
                            id={item.areaId}
                            onChange={(e) => { onCheckboxChange(e, item) }}
                            checked={areasArray.some((el) => el.areaId == item.areaId)}
                          />
                          <span style={{ marginLeft: "5px" }}>{item.area}</span>
                        </label>
                      )
                    })
                    }
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px"
                  }}
                >
                  Mapped States
                </label>
                <select
                  name="states"
                  id="mappedStates"
                  className="input-config"
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  onFocus={closePrevState}
                >
                  <option style={{ color: "gray" }} value="" disabled selected>
                    Mapped States
                  </option>
                  {editData?.areas?.map((item) => {
                    return item?.states?.map((item) => {
                      return (
                        <option value="" disabled>{item?.stateName}</option>
                      );
                    })
                  })
                  }
                </select>
              </div>

              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px"
                  }}
                >
                  Mapped Cities
                </label>
                <select
                  name="states"
                  id="mappedStates"
                  className="input-config"
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                >
                  <option style={{ color: "gray" }} value="" disabled selected>
                    Mapped Cities
                  </option>
                  {editData?.areas?.map((item) => {
                    return item?.cities?.map((item) => {
                      return (
                        <option value="" disabled>{item?.City}</option>
                      );
                    })
                  })
                  }
                </select>
              </div>

              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                  }}
                >
                  First Name
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="fName"
                  defaultValue={fName}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start"
                  }}
                >
                  Last Name
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lName"
                  defaultValue={lName}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start"
                  }}
                >
                  Email ID
                </label>
                <input
                  // disabled={confirmFlag == true ? false : true}
                  type="text"
                  placeholder="Email ID"
                  name="email"
                  defaultValue={email}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                  onClick={(e) => handleConfirm(e)}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          </form>
        </section>
        <div
          className="footer"
          style={{ width: "100%", display: "flex", flexDirection: "row" }}
        >
          <button
            style={{ width: "100px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            &nbsp; Back
          </button>
          <button
            onClick={submitHandler}
            style={{
              marginRight: "0",
              width: "120px",
              height: "42px",
              backgroundColor: "#0A18A3",
            }}
            id="submit-button"
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            &nbsp; Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default Edit;
