import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';
import moment from 'moment';


function InvoiceNDPayment({InvoiceData}) {
    return (
        <div style={{ width: "53%", marginLeft: "10px" }}>
            <Pie
                data={{
                    datasets: [{
                        // data: [InvoiceData?.totalReqs, InvoiceData?.managedReqs],
                        data:[43,34],
                        backgroundColor: [
                            'rgb(70,168,47)',
                            'rgb(240,170,71)'
                        ],
                        options: {
                            cutoutPercentage: 70
                        },
                        hoverOffset: 4,
                    }
                    ],
                    labels: ["Received Payments", "Pending Payments"]
                }} />
            <div style={{ margin: "-70% 0 0 100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgb(70,168,47)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "160px", fontSize: "14px", fontWeight: "500", marginTop: "3px" }}>Received Payments</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "700", marginTop: "3px" }}>
                        {/* {InvoiceData?.totalReqs} */}43
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgb(240,170,71)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "160px", fontSize: "14px", fontWeight: "500", marginTop: "3px" }}>Pending Payments</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "700", marginTop: "3px" }}>
                        {/* {InvoiceData?.managedReqs} */}34
                    </div>
                </div>

            </div>
        </div>
    )
}

export default InvoiceNDPayment