import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification,errorNotification } from "../../../utils/notifications";


const DeleteAssm = ({closeModal,updatedData,editData , showModal}) => {
  const [formValue, setFormValue] = useState({
    DeleteReason: "",
    description: "",
  });

  const editDetails  = {
    "id": editData.id,
    "reason": formValue.DeleteReason,
    "reasonComment": formValue.description
   };

    const refresh = () => {
      updatedData()
  };

  const submitHandler = async () => {
    const resp = await trackPromise(API.post(`admin-module/api/v1/assembly/delete`, editDetails));
    
    const { success,message} = resp;
    
    if (success) {
        closeModal(false);
        showModal(false)
        successNotification(message, { autoClose: 3000 });
        refresh()
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
  };

  const closeHandler = ()=>{
    showModal(false)
    closeModal(false)
  }

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Delete Assembly</h3>
         
        </div>

        <div className="body">
          <label style={{display:'flex', flexDirection:'column', width:'50%'}}>
          <label style={{ fontSize: "13px", fontWeight: "500", width: "fit-content" }}>Delete Reason<span style={{color:'red'}}>*</span></label>
            <select
              name="DeleteReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{marginTop:"-5px", width:'100%'}}
            >
              <option disabled selected hidden>
                Delete Reason
              </option>
              <option value="Misconduct">
              Misconduct
              </option>
              <option value="Not an Active User">
              Not an Active User
              </option>
              <option value="Change of Role">
              Change of Role
              </option>
              <option value="Others">Others</option>
            </select>
          </label>

          {formValue.DeleteReason === "Others" ?
          <label className="lgInput">
            Description
            <input
              type="text"
              style= {{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Delete Reason"
              autoComplete="off"
            />
          </label> : ""}

        </div>
        <div className="footer">
          <button onClick={closeHandler}>Cancel</button>
          <button id="submit-button" onClick={formValue.DeleteReason == "" ?  () => errorNotification("Please select Delete Reason",{autoclose:2000}) : submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAssm;
