import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import NewReturn from "./NewReturn";
import ManageReturn from "./ManageReturn";

const tabs = [
  {
    label: TabLabels.NEW_RETURN,
    Component: NewReturn
  },
  {
    label: TabLabels.MANAGE_RETURN,
    Component: ManageReturn
  },
];

const ComponentsReturns = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default ComponentsReturns; 