import React from "react";
import {Tabs} from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import QualityControl from "./qualitycontrol";
import WorkOrders from "./workorders";
import auth from '../../utils/auth'

const WorkOrderQC=()=>{

  const tabs = [
    {
      id:"MGM2T1",
      label: TabLabels.WORKORDERS,
      Component: WorkOrders
    },
    {
      id:"MGM2T2",
      label: TabLabels.QUALITY_CONTROL,
      Component: QualityControl
    },
  ];

  const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
    return tabs.filter(tab => tabIds.includes(tab.id));
  };

  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
}
export default WorkOrderQC