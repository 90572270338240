import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import VehicleOrder from "./VehicleOrders/VehicleOrder";
// import SpareOrder from "./SparesOrders/SpareOrder";

const VehicleOrder = React.lazy(()=>import("./VehicleOrders/VehicleOrder"))
const SpareOrder = React.lazy(()=>import("./SparesOrders/SpareOrder"))


const AreaSalesOrders = () => {
  const tabs = [
    {
      id: "ASM2T1",
      label: TabLabels.VEHICLE_ORDERS,
      Component: VehicleOrder,
    },
    {
      id: "ASM2T2",
      label: TabLabels.SPARE_ORDERS,
      Component: SpareOrder,
    }
  ];

  const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
    return tabs.filter((tab) => tabIds.includes(tab.id));
  };

  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default AreaSalesOrders;
