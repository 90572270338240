import React, { useEffect, useState } from 'react';
import { BsArrowUpRight } from 'react-icons/bs';
import StoreOrdersCard from './StoreOrdersCard';
import StoreSpareRequestsPieChart from './StoreSpareRequestsPieChart';
import StoreSpareReturnCard from './StoreSpareReturnCard';
import StoreSpareInventoryCard from './StoreSpareInventoryCard';
import RequisitionProgress from '../storeManagerOrders/RequisitionProgress/RequisitionProgress';
import ManageRequest from '../StoreManagerSpareRequest/component/ManageRequest/ManageRequest';
import { useHistory } from "react-router-dom";
import ReturnProgress from '../storeManagerReturns/component/ManageReturns/component/ReturnProgress';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';

function StoreManagerDashboard() {
  const [openOrdersTable, setOpenOrdersTable] = useState(false);
  const [openSpareReqsTable, setOpenSpareReqsTable] = useState(false);
  const [openSpareReturnsTable, setOpenSpareReturns] = useState(false);

  const [spareReqsData, setSpareReqsData] = useState({})

  const fetchReport = async () => {
    const result = await trackPromise(API.get(`/storeManager-module/api/v1/dashboard/getSpareReqsCount`));
    console.log(result);
    setSpareReqsData(result);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  let history = useHistory();

  return (
    <>
      {openOrdersTable == true ? <RequisitionProgress inDashboard={setOpenOrdersTable} /> :
        openSpareReqsTable == true ? <ManageRequest inDashboard={setOpenSpareReqsTable} /> :
          openSpareReturnsTable == true ? <ReturnProgress inDashboard={setOpenSpareReturns} /> :
            <div className="body">
              <div id="overlay" onClick={(e) => off(e)}></div>
              <div className="firstRow">
                <div className="container-box"
                  style={{ width: "50%", height: "430px", padding: "10px" }}
                >
                  <div
                    className="header"
                  >
                    <div>
                      <h5 style={{ fontSize: "18px" }}>Orders</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "60%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer"
                        }}
                      >
                        <BsArrowUpRight onClick={() => setOpenOrdersTable(true)} />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div style={{ overflow: "auto" }}>
                    <StoreOrdersCard
                    />
                  </div>
                </div>


                <div className="container-box"
                  style={{ width: "50%", height: "430px", padding: "10px", marginLeft: "15px" }}
                >
                  <div className="header">
                    <div>
                      <h5 style={{ fontSize: "18px" }}>Spare Requests</h5>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer"
                        }}
                      >
                        <BsArrowUpRight onClick={() => history.push("/app/storeManager/spareRequest")} />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                      padding: "10px"
                    }}
                  >
                    <span className="grey-heading">Total Requests</span>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      {spareReqsData.totalReqs}
                    </div>
                  </div>
                  <div>
                    <StoreSpareRequestsPieChart spareReqsData={spareReqsData} />
                  </div>
                </div>


              </div>

              <div className="secondRow">
                <div className="container-box"
                  style={{ width: "50%", height: "310px", padding: "10px" }}
                >
                  <div
                    className="header"
                  >
                    <div>
                      <h5 style={{ fontSize: "18px" }}>Spare Returns</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "40%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight onClick={() => setOpenSpareReturns(true)} />
                      </div>
                    </div>
                  </div>
                  <StoreSpareReturnCard />
                </div>
                <div
                  className="container-box"
                  style={{ width: "50%", height: "310px", padding: "10px", marginLeft: "10px" }}
                >
                  <div
                    className="header"
                  >
                    <div>
                      <h5 style={{ fontSize: "18px" }}>Spares Inventory</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "40%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => history.push("/app/storeManager/inventory")}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <StoreSpareInventoryCard />
                  </div>
                </div>
              </div>
            </div>
      }
    </>
  )
}

export default StoreManagerDashboard;