import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import auth from "../../../utils/auth";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { successNotification, errorNotification } from "../../../utils/notifications";


const RequestForProcessing = ({ closeModal, rowData }) => {
  // const UsersData = row.requisitionDetails;


  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(``)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };



  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "45%", height: "300px", padding: "15px" }}
      >
      
       
        <div className="body">
          <div style={{ width: "100%" }}>
            <div>
             <h4>Are you sure to send Returns</h4>
             <h4>Processing Request is store for the job</h4>
             <h4>Card ID "SF-4663"</h4>
            </div>



          
          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={submitHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestForProcessing;
