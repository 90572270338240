import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import Table from "react-bootstrap/Table";
import {
  faCheckCircle,
  faPlus,
  faTimesCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import auth from "../../../utils/auth";

const JobCardPreview = ({
  UsersData, closeModal, serviceHistory 
}) => {
  let componentRef = null;
  const subTotalSparesRepl = serviceHistory?.customerComplaints?.reduce(
    (sum, item) => {
      const cost = parseFloat(item?.spareCost) || 0;
      return sum + cost;
    },
    0
  );
  const subTotalcomplaints = serviceHistory?.customerComplaints?.reduce(
    (sum, item) => {
      const cost = parseFloat(item?.cost) || 0;
      return sum + cost;
    },
    0
  );

  const sumMandatoryInspectionCosts =
    serviceHistory?.inspectionsChecks?.mandatoryInspectionChecks?.reduce(
      (total, check) => {
        const checkTotal = check?.inspectionCheck?.reduce((sum, item) => {
          return sum + (parseFloat(item?.cost) || 0);
        }, 0);
        return total + checkTotal;
      },
      0
    );

  const optionalInspectionChecks =
    serviceHistory?.inspectionsChecks?.optionalInspectionChecks?.reduce(
      (total, check) => {
        const checkTotal = check?.inspectionCheck?.reduce((sum, item) => {
          return sum + (parseFloat(item?.cost) || 0);
        }, 0);
        return total + checkTotal;
      },
      0
    );

  const sumMandatorySpareCosts =
    serviceHistory?.inspectionsChecks?.spares?.reduce((total, spare) => {
      if (spare?.spareType == "Mandatory") {
        return total + (parseFloat(spare?.sparePartMrp) || 0);
      }
      return total;
    }, 0);

  const sumOptionalSpareCosts =
    serviceHistory?.inspectionsChecks?.spares?.reduce((total, spare) => {
      if (spare?.spareType == "Optional") {
        return total + (parseFloat(spare?.sparePartMrp) || 0);
      }
      return total;
    }, 0);

  const estimatedTotal =
    (subTotalSparesRepl +
    subTotalcomplaints +
    sumMandatoryInspectionCosts +
    optionalInspectionChecks +
    sumMandatorySpareCosts +
    sumOptionalSpareCosts)?.toFixed(2)

  const cancel = () => {
    closeModal(false);
  };


  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          width: "75%",
          height: "600px",
          padding: "15px",
          borderRadius: "0px",
          borderTop: "none",
          justifyContent: "flex-start",
        }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <span style={{ fontWeight: "500", fontSize: "25px" }}>
            Job Card
          </span>
          {/* <div>
              <ReactToPrint
                trigger={() => {
                  return (
                    <button
                      style={{
                        color: "#0A18A3",
                        margin: "1px",
                        height: "27px",
                        width: "90px",
                        border: "none",
                      }}
                    >
                      Download
                    </button>
                  );
                }}
                content={() => componentRef}
                documentTitle="Purchase Order Invoice"
                pageStyle="Print"
              />
            </div> */}
        </div>

        <div style={{ overflow: "scroll" }}>
          <div
            style={{
              width: " 99%",
              height: "97%",
              display: "flex",
              margin: "5px",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            // ref={(el) => (componentRef = el)}
          >
            {/* Section 1  */}
            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "45%",
                  borderRight: "1px solid grey",
                  borderBottom: "1px solid grey",
                  display: "flex",
                  flexDirection: "column",
                  textWrap: "wrap",
                }}
              >
                <span style={{ fontSize: "14px", fontWeight: "500", textAlign:"left" }}>
                  {auth.getUserInfo()?.businessName ? auth.getUserInfo().businessName :"N/A"}
                </span>
                <span style={{ fontSize: "12px", textWrap: "wrap", textAlign:"left" }}>
                  {`${auth.getUserInfo()?.location?.address1}, ${auth.getUserInfo()?.location?.city},
                ${auth.getUserInfo()?.location?.state},${auth.getUserInfo()?.location?.zipCode}`}
                </span>
                <span style={{ fontSize: "12px", textAlign:"left" }}>
                  Ph. {auth.getUserInfo()?.contactPersonNumber ? auth.getUserInfo()?.contactPersonNumber:"N/A"}
                </span>
                <br />
                <span style={{ fontSize: "14px", fontWeight: "500", textAlign:"left" }}>
                  Customer Name & Address:
                </span>
                <span style={{ fontSize: "12px", textAlign:"left" }}>
                  {UsersData?.customerName ? UsersData?.customerName:"N/A"}
                </span>
                <span style={{ fontSize: "12px", textAlign:"left" }}>
                  {UsersData?.customerAddress ? UsersData?.customerAddress :"N/A"}
                </span>
                <span style={{ fontSize: "12px", textAlign:"left" }}>
                  Ph. {UsersData?.customerPhone ? UsersData?.customerPhone :"N/A"}
                </span>
              </div>
              <div
                style={{
                  width: "55%",
                  borderBottom: "1px solid grey",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    margin: "0",
                    padding: "0",
                    borderBottom: "1px solid grey",
                    justifyContent: "start",
                  }}
                >
                  <span
                    style={{
                      width: "65%",
                      display: "flex",
                      paddingLeft: "4%",
                      flexDirection: "column",
                      // justifyContent: "space-between",
                      textAlign: "start",
                      paddingRight: "2%",
                      fontSize: "small",
                    }}
                  >
                    <span>
                      Job Card No. : {" "}
                      <span style={{ fontWeight: "bold" }}>
                        {serviceHistory?.jobID ? serviceHistory?.jobID:"N/A"}
                      </span>
                    </span>
                    <span>
                      Job Date:{" "}
                      {serviceHistory?.serviceDetails?.jobDate ? moment(serviceHistory?.serviceDetails?.jobDate).format("DD-MM-YYYY") : "N/A"}
                    </span>
                    
                  </span>
                  <span style={{ textAlign: "end", width:"40%" }}>
                    <img
                      src="https://i.pinimg.com/originals/5b/ac/94/5bac942d02e70ce67498bf2ff04efe97.png"
                      style={{ height: "50px", width: "60px" }}
                    ></img>
                  </span>
                  {/* <span style={{ width: "30%", textAlign: "center" }}>
                    Logo
                  </span> */}
                </div>
                <div style={{ textAlign: "center", fontWeight: "500" }}>
                  Vehicle Details
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "1px solid grey",
                    width: "100%",
                    margin: "0",
                    padding: "2% 5%",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "14px", fontWeight: "500" , textAlign:"left"}}>
                      Reg.No. :{" "}
                      <span style={{ fontWeight: "400" }}>
                        {UsersData?.vehicleRegNo ? UsersData?.vehicleRegNo:"N/A"}
                      </span>
                    </span>
                    <span style={{ fontSize: "14px", fontWeight: "500" , textAlign:"left"}}>
                      Model :{" "}
                      <span style={{ fontWeight: "400" }}>
                        {UsersData?.vehicleModel ? UsersData?.vehicleModel:"N/A"}
                      </span>
                    </span>
                    <span style={{ fontSize: "14px", fontWeight: "500", textAlign:"left" }}>
                      Type :{" "}
                      <span style={{ fontWeight: "400" }}>
                        {UsersData?.vehicleType ? UsersData?.vehicleType:"N/A"}
                      </span>
                    </span>
                    <span style={{ fontSize: "14px", fontWeight: "500" , textAlign:"left"}}>
                      Date of Sales :{" "}
                      <span style={{ fontWeight: "400" }}>
                        {UsersData?.invoiceDate ? moment(UsersData?.invoiceDate).format("DD-MM-YYYY"): "N/A"}
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "end",
                    }}
                  >
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      Engine No. :{" "}
                      <span style={{ fontWeight: "400" }}>
                        {UsersData?.VIN ? UsersData?.VIN:"N/A"}
                      </span>
                    </span>
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      Variant :{" "}
                      <span style={{ fontWeight: "400" }}>
                        {UsersData?.vehicleVariant ? UsersData?.vehicleVariant:"N/A"}
                      </span>
                    </span>
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      Color :{" "}
                      <span style={{ fontWeight: "400" }}>
                        {UsersData?.vehicleColor ? UsersData?.vehicleColor:"N/A"}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Section 2  */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <div
                  style={{
                    width: "48%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    textAlign: "center",
                    fontWeight: "500",
                  }}
                >
                  Last Service Details
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontWeight: "500",
                    paddingLeft: "19%",
                  }}
                >
                  Warranty Details
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  borderTop: "1px solid grey",
                  borderBottom: "1px solid grey",
                }}
              >
                <div
                  style={{
                    width: "48%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "500" , textAlign:"left"}}>
                    Service Type :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {serviceHistory?.serviceDetails?.serviceType ? serviceHistory?.serviceDetails?.serviceType:"N/A"}
                    </span>
                  </span>
                  <span style={{ fontSize: "14px", fontWeight: "500", textAlign:"left" }}>
                    Service Category :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {serviceHistory?.serviceDetails?.serviceCategory ? serviceHistory?.serviceDetails?.serviceCategory:"N/A"}
                    </span>
                  </span>
                  <span style={{ fontSize: "14px", fontWeight: "500", textAlign:"left" }}>
                    Service Date :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {serviceHistory?.serviceDetails?.jobDate
                        ? moment(serviceHistory.serviceDetails.jobDate).format(
                            "DD-MM-YYYY"
                          )
                        : "N/A"}
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "14px", fontWeight: "500" , textAlign:"left"}}>
                      Standard Warranty
                    </span>
                    <span style={{ fontSize: "14px", textAlign:"left", fontWeight:"100" }}>
                      Distance : {UsersData?.warrantyDistance ? UsersData?.warrantyDistance:"N/A"}{" "}
                      {UsersData?.warrantyDistanceUnit ? UsersData?.warrantyDistanceUnit:"N/A"}
                    </span>
                    <span style={{ fontSize: "14px" , textAlign:"left", fontWeight:"100"}}>
                      Duration : {UsersData?.warrantyDuration ? UsersData?.warrantyDuration:"N/A"}{" "}
                      {UsersData?.warrantyDurationUnit ? UsersData?.warrantyDurationUnit:"N/A"}
                    </span>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      textAlign: "end",
                    }}
                  >
                    <span style={{ fontSize: "14px", fontWeight: "500" , textAlign:"right" }}>
                      Extended Warranty
                    </span>
                    <span style={{ fontSize: "14px", textAlign:"right", fontWeight:"100" }}>
                      Distance : {UsersData?.addOnWarrantyDistance ? UsersData?.addOnWarrantyDistance:"N/A"}{" "}
                      {UsersData?.addOnWarrantyDistanceUnit ? UsersData?.addOnWarrantyDistanceUnit:"N/A"}
                    </span>
                    <span style={{ fontSize: "14px" , textAlign:"right", fontWeight:"100"}}>
                      Duration : {UsersData?.addOnWarrantyDuration ? UsersData?.addOnWarrantyDuration:"N/A"}{" "}
                      {UsersData?.addOnWarrantyDurationUnit ? UsersData?.addOnWarrantyDurationUnit:"N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Section 3  */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Job Details
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  textAlign: "center",
                  border: "1px solid grey",
                  fontSize: "12px",
                }}
              >
                <span
                  style={{
                    width: "25%",
                    textAlign: "center",
                    borderRight: "1px solid grey",
                  }}
                >
                  Service Type - {serviceHistory?.serviceDetails?.serviceType ? serviceHistory?.serviceDetails?.serviceType:"N/A"}
                </span>
                <span
                  style={{
                    width: "25%",
                    textAlign: "center",
                    borderRight: "1px solid grey",
                  }}
                >
                  Service Category - {serviceHistory?.serviceDetails?.serviceCategory ? serviceHistory?.serviceDetails?.serviceCategory:"N/A"}
                </span>
                <span
                  style={{
                    width: "25%",
                    textAlign: "center",
                    borderRight: "1px solid grey",
                  }}
                >
                  Job Date -{" "}
                  {serviceHistory?.jobDate
                    ? moment(serviceHistory?.jobDate).format("DD-MM-YYYY, HH:mm")
                    : "N/A"}
                </span>
                <span style={{ width: "25%", textAlign: "center" }}>
                  Estd. Delivery Date - {" "}
                  {serviceHistory?.serviceDetails?.deliveryDateAndTime
                    ? moment(serviceHistory?.serviceDetails?.deliveryDateAndTime).format(
                        "DD-MM-YYYY, HH:mm"
                      )
                    : "N/A"}
                </span>
              </div>
            </div>
            {/* Section 4  */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Checks and Spares
              </div>

              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    >
                      Mandatory Checks
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      Labor Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    >
                      Optional Checks
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      Labor Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    >
                      Mandatory Spares
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    >
                      Optional Spares
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* Data Rows */}
                  <tr>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "18%",
                        verticalAlign: "top",
                      }}
                    >
                      {serviceHistory?.inspectionsChecks?.mandatoryInspectionChecks?.map(
                        (item, index) =>
                          item.inspectionCheck?.map((check, subIndex) => (
                            <div key={`mandatory-${index}-${subIndex}`}>
                              {check?.inspectionCheck ? check?.inspectionCheck:"N/A"}
                              <br />
                            </div>
                          ))
                      )}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "7%",
                        verticalAlign: "top",
                      }}
                    >
                      {serviceHistory?.inspectionsChecks?.mandatoryInspectionChecks?.map(
                        (item, index) =>
                          item.inspectionCheck?.map((check, subIndex) => (
                            <div key={`mandatory-${index}-${subIndex}`}>
                              {check?.cost ? check?.cost?.toFixed(2) : "N/A"}
                              <br />
                            </div>
                          ))
                      )}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "18%",
                        verticalAlign: "top",
                      }}
                    >
                      {serviceHistory?.inspectionsChecks?.optionalInspectionChecks?.map(
                        (item, index) =>
                          item.inspectionCheck?.map((check, subIndex) => (
                            <div key={`optional-${index}-${subIndex}`}>
                              {check?.inspectionCheck ? check?.inspectionCheck:"N/A"}
                              <br />
                            </div>
                          ))
                      )}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "7%",
                        verticalAlign: "top",
                      }}
                    >
                      {serviceHistory?.inspectionsChecks?.optionalInspectionChecks?.map(
                        (item, index) =>
                          item.inspectionCheck?.map((check, subIndex) => (
                            <div key={`optional-${index}-${subIndex}`}>
                              {check?.cost ? check?.cost?.toFixed(2):"N/A"}
                              <br />
                            </div>
                          ))
                      )}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "18%",
                        verticalAlign: "top",
                      }}
                    >
                      {serviceHistory?.inspectionsChecks?.spares
                        ?.filter((spare) => spare?.spareType === "Mandatory")
                        .map((spare, index) => (
                          <div key={`mandatory-spare-${index}`}>
                            {spare?.componentName ? spare?.componentName:"N/A"}
                            <br />
                          </div>
                        ))}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "7%",
                        verticalAlign: "top",
                      }}
                    >
                      {serviceHistory?.inspectionsChecks?.spares
                        ?.filter((spare) => spare?.spareType === "Mandatory")
                        .map((spare, index) => (
                          <div key={`mandatory-spare-${index}`}>
                            {spare?.sparePartMrp ? spare?.sparePartMrp?.toFixed(2):"N/A"}
                            <br />
                          </div>
                        ))}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "18%",
                        verticalAlign: "top",
                      }}
                    >
                      {serviceHistory?.inspectionsChecks?.spares
                        ?.filter((spare) => spare?.spareType === "Optional")
                        .map((spare, index) => (
                          <div key={`optional-spare-${index}`}>
                            {spare?.componentName ? spare?.componentName:"N/A"}
                            <br />
                          </div>
                        ))}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "7%",
                        verticalAlign: "top",
                      }}
                    >
                      {serviceHistory?.inspectionsChecks?.spares
                        ?.filter((spare) => spare?.spareType === "Optional")
                        .map((spare, index) => (
                          <div key={`optional-spare-${index}`}>
                            {spare?.sparePartMrp ? spare?.sparePartMrp?.toFixed(2):"N/A"}
                            <br />
                          </div>
                        ))}
                    </td>
                  </tr>
                  {/* Subtotals Row */}
                  <tr>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    >
                      Subtotal ({auth.getUserInfo().currencySymbol})
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      {sumMandatoryInspectionCosts ? sumMandatoryInspectionCosts?.toFixed(2):"N/A"}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      {optionalInspectionChecks ? optionalInspectionChecks?.toFixed(2):"N/A"}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      {sumMandatorySpareCosts ? sumMandatorySpareCosts?.toFixed(2):"N/A"}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      {sumOptionalSpareCosts ? sumOptionalSpareCosts?.toFixed(2):"N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>{" "}
            <br />
            {/* Section 5  */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "40%",
                      }}
                    >
                      Customer Complaints / Remarks
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "10%",
                      }}
                    >
                      Labor Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    >
                      Spares Replacement
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "25%",
                      }}
                    >
                      Observation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {serviceHistory?.customerComplaints?.map((item, index) => {
                    return (
                      <tr>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "left",
                            borderRight: "1px solid grey",
                            borderLeft: "1px solid grey",
                            fontSize: "10px",
                            fontWeight: "500",
                            width: "40%",
                          }}
                        >
                          {item?.customerComplaint ? item?.customerComplaint:"N/A"}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "right",
                            borderRight: "1px solid grey",
                            borderLeft: "1px solid grey",
                            fontSize: "10px",
                            fontWeight: "500",
                            width: "10%",
                          }}
                        >
                          {typeof item?.cost
                            ? Number(item.cost).toFixed(2)
                            : "N/A"}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "left",
                            borderRight: "1px solid grey",
                            borderLeft: "1px solid grey",
                            fontSize: "10px",
                            fontWeight: "500",
                            width: "18%",
                          }}
                        >
                          {item?.spare ? item?.spare:"N/A"}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "right",
                            borderRight: "1px solid grey",
                            borderLeft: "1px solid grey",
                            fontSize: "10px",
                            fontWeight: "500",
                            width: "7%",
                          }}
                        >
                          {typeof item?.spareCost 
                            ? Number(item.spareCost).toFixed(2)
                            : "N/A"}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "left",
                            borderRight: "1px solid grey",
                            borderLeft: "1px solid grey",
                            fontSize: "10px",
                            fontWeight: "500",
                            width: "25%",
                          }}
                        ></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "40%",
                      }}
                    >
                      Sub Total ({auth.getUserInfo().currencySymbol})
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "10%",
                      }}
                    >
                      {subTotalcomplaints ? subTotalcomplaints?.toFixed(2):"N/A"}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      {subTotalSparesRepl ? subTotalSparesRepl?.toFixed(2):"N/A"}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "25%",
                      }}
                    ></td>
                  </tr>
                </tbody>
              </table>
              <br />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    border: "1px solid grey",
                    width: "40%",
                    textAlign: "end",
                    justifyContent: "end",
                    fontSize: "14px",
                    fontWeight: "500",
                    paddingRight: "1%",
                  }}
                >
                  Total Estimate ({auth.getUserInfo().currencySymbol})
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    border: "1px solid grey",
                    width: "10%",
                    textAlign: "end",
                    fontSize: "14px",
                    fontWeight: "500",
                    justifyContent: "end",
                  }}
                >
                  { estimatedTotal
                    ? Number(estimatedTotal).toFixed(2)
                    : estimatedTotal === "NaN" ? "N/A" : "N/A"}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "25%",
                    textAlign: "start",
                    paddingLeft: "1%",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Technician Name: {serviceHistory?.serviceDetails?.technicianName ? serviceHistory?.serviceDetails?.technicianName:"N/A"}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "25%",
                    textAlign: "start",
                    paddingLeft: "1%",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Approver Name:
                </div>
              </div>
            </div>
            {/* Section 6  */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "10%",
              }}
            >
              <span style={{ fontSize: "14px", fontWeight: "500" }}>
                Customer Signature
              </span>
              <div style={{ textAlign: "right" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    textDecoration: "underline",
                  }}
                >
                  For {auth.getUserInfo()?.businessName ? auth.getUserInfo()?.businessName :"N/A"}:
                </span>
                <br />
                <span style={{ fontSize: "14px", fontWeight: "500" }}>
                  Authorised Signature
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="save-cancel" style={{ justifyContent: "flex-end" }}>
          <div className="cust-reset  cancel-button">
            <button
              type="button"
              className="btn btn-icon-label"
              onClick={cancel}
              style={{ height: "40px" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  color: "#0A18A3",
                  fontWeight: "500",
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ fontSize: "16px" }}
                />{" "}
                Cancel
              </div>
            </button>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default JobCardPreview;
