import React, { useState } from "react";
import {
  CheckCircle,
  CancelOutlined,
} from "@material-ui/icons";
import moment from "moment";
import { errorNotification, successNotification } from "../../../../utils/notifications";
import API from "../../../../utils/api";
import { trackPromise } from "react-promise-tracker";

const ProceedToShipment = ({ rowData, closeModal, tableRefresh }) => {
  const [formValue, setFormValue] = useState({
    shippingBy: "",
    consignmentNo: "",
    deliveryPersonName: "",
    deliveryPersonContact: "",
    shipmentDate: ""
  });
  const handleInputs = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value })
  }

  const submitHandler = async () => {
    const obj = {
      "RPI": rowData.RPI,
      "shippingBy": formValue.shippingBy,
      "consignmentNo": formValue.consignmentNo,
      "deliveryPersonName": formValue.deliveryPersonName,
      "deliveryPersonContactNo": formValue.deliveryPersonContact,
      "shippingDate": formValue.shipmentDate
    }
    const resp = await trackPromise(API.post(`inventory-module/api/v1/spareOutward/proceedToShipment`, obj));
    resp.success ? successNotification(resp.message) : errorNotification(resp.message);
    closeModal(false);
    tableRefresh();
    closeModal(false);
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "785px", height: "435px", padding: '15px' }}>
        <div className="header" style={{ width: '100%', display: "flex", justifyContent: 'flex-start' }}>
          <h3>Proceed To Shipment</h3>

        </div>

        <section
          style={{
            width: '100%',
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
            <span>RP Invoice No: {rowData.RPI}</span>
            <span>Vendor Name: {rowData.vendorName}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
            <span>RP Invoice Date: {moment(rowData.RPIDate).format("DD-MMM-YYYY/HH:mm")}</span>
            <span>Vendor Location: {rowData.deliveryLocation}</span>
          </div>
        </section>

        <div className="body" style={{ display: "flex", justifyContent: 'space-between' }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <label style={{display:"flex", flexDirection:"column", textAlign:"start"}}>
            <label>Shipping By<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              name="shippingBy"
              value={formValue.shippingBy}
              onChange={(e) => handleInputs(e)}
              placeholder="Shipping By"
              autoComplete="off"
              style={{marginTop:"-5px"}}
            />
            </label>

            <label style={{display:"flex", flexDirection:"column", textAlign:"start"}}>
            <label>Consignment No<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              name="consignmentNo"
              value={formValue.consignmentNo}
              onChange={(e) => handleInputs(e)}
              placeholder="Consignment No"
              autoComplete="off"
              style={{marginTop:"-5px"}}
            />
             </label>

            <label style={{display:"flex", flexDirection:"column", textAlign:"start"}}>
            <label>Delivery Person Name<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              name="deliveryPersonName"
              value={formValue.deliveryPersonName}
              onChange={(e) => handleInputs(e)}
              placeholder="Delivery Person Name"
              autoComplete="off"
              style={{marginTop:"-5px"}}
            />
             </label>

            <label style={{display:"flex", flexDirection:"column", textAlign:"start"}}>
            <label>Delivery Person Contact<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              name="deliveryPersonContact"
              value={formValue.deliveryPersonContact}
              onChange={(e) => handleInputs(e)}
              placeholder="Delivery Person Contact No."
              autoComplete="off"
              style={{marginTop:"-5px"}}
            />
             </label>

            <label style={{display:"flex", flexDirection:"column", textAlign:"start"}}>
            <label>Shipment Date<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              onFocus={(e) => (e.currentTarget.type = "date")}
              onBlur={(e) => (e.currentTarget.type = "text")}
              name="shipmentDate"
              value={formValue.shipmentDate}
              onChange={(e) => handleInputs(e)}
              placeholder="Shipment Date"
              autoComplete="off"
              style={{marginTop:"-5px"}}
            />
             </label>
          </div>
        </div>
        <div className="footer" style={{ width: '100%', display: "flex", justifyContent: 'flex-end' }}>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 15px",
              marginRight: "10px",
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 15px",
              color: "white",
            }}
            onClick={(e) => submitHandler(e)}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProceedToShipment; 