import React from 'react';
import { CancelOutlined } from '@material-ui/icons';
import moment from 'moment';

const GINDetails = ({ rowData, closeModal, extraData }) => {
    console.log(rowData)
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "600px", height: "510px", padding: '15px' }}
            >
                <div className="header" style={{ width: "100%" }}>
                    <h3 style={{ fontSize: "18px", marginRight: "68%" }}>GIN Details</h3>
                </div>

                <section
                    style={{
                        width: '100%',
                        display: "flex",
                        padding: "10px",
                        flexDirection: "column",
                        fontSize: "14px",
                        fontWeight: "500",
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: 'flex-start' }}>
                            <span>GIN No. : {}</span>
                            <span>GIN Date : {}</span>
                            <span>GIN Issued By : {}</span>
                        </div>
                    </div>
                </section>
                <br />
                <label style={{ justifyContent: "flex-start", alignItems: 'flex-start', display: 'flex', paddingLeft: "10px" }}>Shipment Details</label>
                <div className="body" style={{ padding: "10px",height:"170px",marginTop:"-20px" }}>
                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0",
                            padding: "5px",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
                        }}
                    >

                        <div style={{ lineHeight: "1.6" }}>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Shipped From : {}</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Shipment Qty. : {}</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Shipment Date : {}</span>
                            </div>
                        </div>
                    </section>
                </div>

                <br />
                <label style={{ justifyContent: "flex-start", alignItems: 'flex-start', display: 'flex', paddingLeft: "10px" }}>Delivery Details</label>
                <div className="body" style={{ padding: "10px",height:"155px",marginTop:"-20px" }}>
                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0",
                            padding: "5px",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
                        }}
                    >

                        <div style={{ lineHeight: "1.6" }}>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Delivery Vehicle Details : {}</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Delivery Person  : {}</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Delivery Person Contact : {}</span>
                            </div>
                        </div>
                    </section>


                </div>

                <div className="footer" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "7%", marginLeft: "67%" }}>
                    <div>
                        <button
                            style={{
                                border: "1px solid #0A18A3",
                                borderRadius: "3px",
                                padding: "5px 15px",
                                marginRight: "10px",
                                color: "#0A18A3",
                                width: '152px'
                            }}
                            onClick={() => closeModal(false)}
                        >
                            <CancelOutlined style={{ color: "#0A18A3", fontSize: "28px" }} />
                            Back
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default GINDetails;