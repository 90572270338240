import React from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import moment from "moment";

const CompletedViewDetails = ({ closeModal, rowData, category }) => {
  console.log(rowData)
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          width: "1150px",
          padding: "15px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 4px 16px rgba(0,0,0,0.1)",
          height:"72vh"
        }}
      >
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px"
          }}
        >
          <h3 style={{ margin: 0 }}>View Details</h3>
        </div>
        <section
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
              fontWeight: "500",
              width:"100%"
            }}
          >
            <div>
              Component Name: {rowData?.componentName || rowData?.sparePartname}
            </div>
            <div>SKU Code: {rowData?.skuCode}</div>
          </section>
        <div className="body" style={{ marginTop: "15px", display:"flex", flexDirection:"column", width:"100%" }}>
        

          <section style={{width:"100%"}}>
            <div
              id="POVendorHeading"
              style={{
                display: "flex",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
                fontWeight: "600",
                color: "#555",
                width:"100%"
              }}
            >
              <div style={{ width: "10%" }}>S.No.</div>
              <div style={{ width: "10%" }}>Part S.No</div>
              <div style={{ width: "20%" }}>Date of Verification</div>
              <div style={{ width: "20%" }}>Verification Status</div>
              <div style={{ width: "20%" }}>Verification Reason</div>
              <div style={{ width: "10%" }}>Last Action</div>
              <div style={{ width: "10%" }}>Action Date</div>
            </div>

            <div style={{ maxHeight: "200px", overflowY: "auto", width:"100%" }}>
            {category == "PO Components" ? rowData?.map((item, index) => (
                <div
                  key={index}
                  id="POVendorContent"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px solid #ddd",
                    padding: "10px 0",
                    width:"100%"
                  }}
                >
                  <div style={{ width: "10%" }}>{index + 1}</div>
                  <div
                    style={{
                      width: "10%",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    {item?.partSno}
                  </div>
                  <div style={{ width: "20%" }}>
                    {moment(item?.addedDate).format("DD-MM-YYYY")}
                  </div>
                  <div style={{ width: "20%" }}>
                    {item?.status === "Passed" ? (
                      <span
                        style={{
                          padding: "4px 8px",
                          borderRadius: "4px",
                          backgroundColor: "#4caf50",
                          color: "white",
                          fontWeight: "bold"
                        }}
                      >
                        Passed
                      </span>
                    ) : (
                      <span
                        style={{
                          padding: "4px 8px",
                          borderRadius: "4px",
                          backgroundColor: "#f44336",
                          color: "white",
                          fontWeight: "bold"
                        }}
                      >
                        Failed
                      </span>
                    )}
                  </div>
                  <div style={{ width: "20%" }}>{item?.issue}</div>
                  <div style={{ width: "10%" }}>{item?.lastAction}</div>
                  <div style={{ width: "10%" }}>
                    {moment(item?.actionDate).format("DD-MM-YYYY")}
                  </div>
                </div>
              )):rowData?.returnQty?.map((item, index) => (
                <div
                  key={index}
                  id="POVendorContent"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px solid #ddd",
                    padding: "10px 0",
                    width:"100%"
                  }}
                >
                  <div style={{ width: "10%" }}>{index + 1}</div>
                  <div
                    style={{
                      width: "10%",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    {item?.partSno}
                  </div>
                  <div style={{ width: "20%" }}>
                    {moment(item?.addedDate).format("DD-MM-YYYY")}
                  </div>
                  <div style={{ width: "20%" }}>
                    {item?.status === "Passed" ? (
                      <span
                        style={{
                          padding: "4px 8px",
                          borderRadius: "4px",
                          backgroundColor: "#4caf50",
                          color: "white",
                          fontWeight: "bold"
                        }}
                      >
                        Passed
                      </span>
                    ) : (
                      <span
                        style={{
                          padding: "4px 8px",
                          borderRadius: "4px",
                          backgroundColor: "#f44336",
                          color: "white",
                          fontWeight: "bold"
                        }}
                      >
                        Failed
                      </span>
                    )}
                  </div>
                  <div style={{ width: "20%" }}>{item?.issue}</div>
                  <div style={{ width: "10%" }}>{item?.lastAction}</div>
                  <div style={{ width: "10%" }}>
                    {moment(item?.actionDate).format("DD-MM-YYYY")}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>

        <div
          className="footer"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
            width:"100%"
          }}
        >
          <button
            style={{
              backgroundColor: "#0a18a3",
              color: "white",
              border: "none",
              padding: "10px 20px",
              cursor: "pointer",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              borderRadius: "4px",
              width:"99px"
            }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "20px", marginRight: "5px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompletedViewDetails;
