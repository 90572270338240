import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification,errorNotification } from "../../../utils/notifications"


const VehicleReturnReject = ({closeModal, rowData, updatedData,setOpen}) => {

  const [formValue, setFormValue] = useState({
    RejectReason: "",
    description: "",
  });

  

  const editDetails  = {
    requestID:rowData.requestID,
    status:"Rejected",
    reason: formValue.RejectReason,
    ...(formValue.RejectReason == "Other" ? {reasonDescription:formValue.reasonDescription}:{reasonDescription:""})
   };

    const refresh = () => {
      updatedData()
  };

  console.log(formValue,editDetails)

  const submitHandler = async () => {
    const resp = await trackPromise(API.post(`qc-module/api/v1/vehicle/newrequest/status`, editDetails));
    
    const { success,message} = resp;
    
    if (success) {
        closeModal(false);
        setOpen(false)
        successNotification(message, { autoClose: 3000 });
        refresh()
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Reject</h3>
         
        </div>

        <div className="body">
          <label>
            <select
              name="RejectReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                Reject Reason*
              </option>
              <option value="No Manpower">
              No Manpower
              </option>
              <option value="In-Correct GRN">
              In-Correct GRN
              </option>
              <option value="Other">Other</option>
            </select><br />
          </label>

          {formValue.RejectReason === "Other" ?
          <label className="lgInput">
            Other Reason
            <input
              type="text"
              style= {{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Reject Reason*"
              autoComplete="off"
            />
          </label> : ""}

        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={()=>{submitHandler()}}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default VehicleReturnReject;
