import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import moment from "moment";
import DealerVehiclePO from "../../../../RequisitionOrders/DealerVehiclePO";

const ViewUpdates = ({ rowData, closeModal }) => {

  const [openPO,setPO] = useState(false);

  return (
    <div className="container1">
    {
      openPO ? <DealerVehiclePO rowData={rowData}   closeModal={setPO} type="vehicleData"/>:""
    }
      <div
        className="modalContainer"
        style={{ width: "1280px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>View Updates</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <span>Request ID: {rowData.requisitionID}</span>
              <span>
              <span>PO No.: </span>
              <span style={{color:"blue",cursor:"pointer"}} onClick={()=>setPO(true)}>{rowData.poNo}</span>
              </span>
              <span>PO Date: {moment(rowData.poDate).format("DD-MM-YYYY")}</span>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div>PO By : {rowData.poBy}</div>
              <div>PO Value : {rowData.poValue}</div>
              <div><button className="reactive-btn" style={{ color: "blue", width: "115px" }}>WO In-Progress<span className="reactive-btn-font"></span></button></div>

            </section>
          </section>
          <br />
          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "50px", padding: "10px" }}>S.No.</div>
              <div style={{ width: "150px", padding: "10px" }}>Vehicle Segment</div>
              <div style={{ width: "150px", padding: "10px" }}>Vehicle Category</div>
              <div style={{ width: "100px", padding: "10px" }}>Vehicle SKU</div>
              <div style={{ width: "100px", padding: "10px" }}>
                Vehicle Make
              </div>
              <div style={{ width: "100px", padding: "10px" }}>
                Vehicle Model
              </div>
              <div style={{ width: "100px", padding: "10px" }}>
                Vehicle Variant
              </div>
              <div style={{ width: "100px", padding: "10px" }}>
                Vehicle Type
              </div>
              <div style={{ width: "100px", padding: "10px" }}>
                Vehicle Color
              </div>
              <div style={{ width: "150px", padding: "10px" }}>
                Requested Vehicle Qty.
              </div>
              <div
                style={{ width: "150px", padding: "10px", overflowX: "scroll" }}
              >
                Status
              </div>
            </div>
            <section style={{ height: "auto" }}>
              {rowData.vehicleData.map((item, index) => (
                <div
                  style={{
                    padding: "0px",
                    border: "1px solid #cdcdcd",
                    width: "",
                    fontSize: "13px",
                    display: "flex",
                  }}
                >
                  <div style={{ width: "50px", padding: "10px" }}>{index + 1}</div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.vehicleSegment}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.vehicleCategory}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.vehicleSkuCode}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.vehicleMake}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.vehicleModel}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.vehicleVariant}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.vehicleType}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.vehicleColor}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.requiredQty}
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    <button className="reactive-btn" style={{ color: "blue", width: "115px" }}>WO In-Progress<span className="reactive-btn-font"></span></button>
                  </div>
                </div>
              ))}
            </section>
          </section>
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewUpdates;
