import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
  ConfirmationNumber,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { errorNotification, successNotification } from "../../../../utils/notifications";
import {
  faEdit,
  faBan,
  faShare,
  faPauseCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import auth from "../../../../utils/auth";

const ViewDetails = ({ closeModal, rowData, updatedData }) => {
  const [receivedBy, setReceivedBy] = useState();
  const [receivedDate, setReceivedDate] = useState(new Date(parseInt("5467")));
  const [viewSpecs, setViewSpecs] = useState();
  const [showReject, setShowReject] = useState(false);
  const [showHold, setShowHold] = useState(false);
  const [receivedQuantity, setReceivedQty] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [margin, setMargin] = useState(rowData.manufactureMargin);
  const [otherCharges, setOtherCharges] = useState(rowData.otherCharges);
  const [unitPrice, setUnitPrice] = useState(rowData.pricetoDealer);
  const [showFinalMargin, setFinalMargin] = useState();
  const [dealerMargin, setDealerMargin] = useState(rowData.dealerMargin);
  const [showroomPrice, setShowroomPrice] = useState(
    rowData.exShowroomPrice
  );

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.put(`finance/api/v1/vehiclemanagerequest`, {
        requestID: rowData.requestID,
        manufactureMargin: margin,
        status: "Approved By Management",
        dealerMargin: dealerMargin,
        pricetoDealer: unitPrice.toString(),
        otherCharges: otherCharges,
        exShowroomPrice: showroomPrice,
      })
    );

    const { success, message } = resp;

    if (success) {
      successNotification(message, { autoClose: 3000 });
      refresh();
      closeModal(false);
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const marginHandler = (e) => {
    setMargin(e.target.value);
    setUnitPrice(
      parseInt(rowData.totalBomCost) +
        parseInt((rowData.totalBomCost * parseInt(e.target.value)) / 100) +
        parseInt(otherCharges)
    );
    setShowroomPrice(parseInt(unitPrice) + parseInt(Math.round(unitPrice * (dealerMargin / 100))));
  };

  const priceDealerChange = (e) => {
    console.log(e.target.value);
    debugger;
    setShowroomPrice(parseInt(e.target.value) + parseInt(Math.round(e.target.value * (dealerMargin / 100))));
  };

  const otherChargesHandler = (e) => {
    setOtherCharges(e.target.value);
    const cost = rowData.totalBomCost;
    const finalMargin = Math.round(cost * (margin / 100));
    setFinalMargin(finalMargin);
    let finalDealerPrice = 0
    if (e.target.value === 0) {
      setUnitPrice(finalMargin + parseInt(cost));
    }else{
     finalDealerPrice =
      parseInt(cost) +
      parseInt(Math.round(cost * (margin / 100))) +
      parseInt(e.target.value);
      setUnitPrice(finalDealerPrice);
    }
    setShowroomPrice(parseInt(finalDealerPrice) + parseInt(Math.round(finalDealerPrice * (dealerMargin / 100))));
    console.log(parseInt(Math.round(finalDealerPrice * (dealerMargin / 100))))
  };

  const finalMarginHandler = (e) => {
    setDealerMargin(e.target.value);
    const finalDealerMargin = Math.round(unitPrice * (e.target.value / 100));
    const totalPrice = parseInt(unitPrice) + parseInt(finalDealerMargin);
    setShowroomPrice(totalPrice);
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "800px", height: "600px", padding: "15px" }}
      >
        <div className="header">
          <h3>Vehicle Pricing</h3>
        </div>
        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>BOM ID : {rowData.BOMID}</span>
            <span>Request Type : {rowData.requestType}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>BOM Location : {rowData.bomLocation}</span>
            <span>Request Category : {rowData.requestCategory}</span>
          </div>
        </section>
        <div
          className="body"
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "unset",
            height: "380px",
            width: "100%",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <br />
          <h6
            style={{
              justifyContent: "flex-start",
              display: "flex",
              marginLeft: "5px",
            }}
          >
            Vehicles Details
          </h6>
          <div className="shopfloortbl">
            <table
              className="sfaction_tbl"
              style={{ width: "100%", overflow: "scroll" }}
            >
              <thead>
                <tr>
                  <th>Segment</th>
                  <th>Category</th>
                  <th>Model</th>
                  <th>Variant</th>
                  <th>Type</th>
                  <th>Color</th>
                </tr>
              </thead>
              {/* <hr className='line'/> */}
              <tbody>
                <tr>
                  <td>{rowData.vehicleSegment}</td>
                  <td>{rowData.vehicleCategory}</td>
                  <td>{rowData.vehicleModel}</td>
                  <td>{rowData.vehicleVariant}</td>
                  <td>{rowData.vehicleType}</td>
                  <td>{rowData.vehicleColor}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <h6
            style={{
              justifyContent: "flex-start",
              display: "flex",
              marginLeft: "5px",
            }}
          >
            Bom Details
          </h6>
          <div className="shopfloortbl" style={{ overflow: "scroll" }}>
            <table className="sfaction_tbl" style={{ width: "100%" }}>
              <thead>
                <tr>
                    <th style={{ width: "150px" }}>{`BOM Cost (${auth.getUserInfo().currencySymbol})`}</th>
                    <th style={{ width: "200px" }}>Manufacturer Margin(%)</th>
                    <th style={{ width: "200px" }}>{`Other Charges (${auth.getUserInfo().currencySymbol})`}</th>
                    <th style={{ width: "200px" }}>{`Price to Dealer (${auth.getUserInfo().currencySymbol})`}</th>
                    <th style={{ width: "200px" }}>Dealer Margin(%)</th>
                    <th style={{ width: "200px" }}>{`Ex-showroom Price (${auth.getUserInfo().currencySymbol})`}</th>
                  </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: "150px" }}>
                    <input
                      style={{ width: "85%" }}
                      className="input-config"
                      defaultValue={rowData.totalBomCost}
                      readOnly
                    />
                  </td>
                  <td style={{ width: "200px" }}>
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      className="input-config"
                      value={margin}
                      onChange={marginHandler}
                    />
                  </td>
                  <td style={{ width: "200px" }}>
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      className="input-config"
                      value={otherCharges}
                      onChange={otherChargesHandler}
                    />
                  </td>
                  <td style={{ width: "200px" }}>
                    <input
                      style={{ width: "100%" }}
                      className="input-config"
                      value={unitPrice}
                      onChange={priceDealerChange}
                      readOnly
                    />
                  </td>
                  <td style={{ width: "200px" }}>
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      className="input-config"
                      value={dealerMargin}
                      onChange={finalMarginHandler}
                    />
                  </td>
                  <td style={{ width: "200px" }}>
                    <input
                      style={{ width: "100%" }}
                      className="input-config"
                      value={showroomPrice}
                      readOnly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "15px",
            }}
          ></div>
        </div>

        <div className="footer">
          <button
            onClick={() => closeModal(false)}
            className="approvalfooter_btn"
          >
            Cancel
          </button>
          <button
            className="approvalfooter_btn"
            style={{ color: "white", backgroundColor: "#0a18a3" }}
            onClick={() => submitHandler()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
