import React from "react";
import moment from "moment";
import { errorNotification } from "../../../utils/notifications";
import { KeyboardBackspace } from "@material-ui/icons";
// import "./modal.scss";

const ViewDetails = ({ updatedData, editData, closeModal }) => {
  // let actionArray = userDetails.action.reverse()
  console.log(editData)


  return (
    <div className="container1">

      <div className="modalContainer" style={
        { width: "60vw", height: "502px" }}>
        <div className="header">
          <h3>Update History</h3>
        </div>
        <br />
        <div style={{textAlign:"start"}}>{editData.user} User Charges</div>
        <br />
        {/* <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "17.5%",
              marginRight: "2%",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Start Date
            </label>
            <input
              type="date"
              onFocus={(e) => (e.currentTarget.type = "date")}
              onBlur={(e) => (e.currentTarget.type = "text")}
              name="startDate"
              // value={formValue.startDate}
              // onChange={handleChange}
              placeholder="Start Date"
              autoComplete="off"
              style={{ marginTop: "-5px", width: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "17.5%",
              marginRight: "2%",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              End Date
            </label>
            <input
              type="date"
              onFocus={(e) => (e.currentTarget.type = "date")}
              onBlur={(e) => (e.currentTarget.type = "text")}
              name="endDate"
              // value={formValue.startDate}
              // onChange={handleChange}
              placeholder="End Date"
              autoComplete="off"
              style={{ marginTop: "-5px", width: "100%" }}
            />
          </div>
        </div> */}
        <br /> <br />

        <ol class="progress">
          <>
            {editData === undefined ?
              errorNotification("user Not Performed any action ", { autoClose: 3000 }) :
              editData.actionHistory.reverse().map((item) => {
                if (item.action === "Deactivated") {
                  return (
                    <li className="progress-delete" style={{width:"100%", display:"flex", display:"row", textAlign:"start", justifyContent:"space-between", paddingTop:"8px"}}>
                      <button style={{width:"20%"}}>
                        <span className="btn-delete">Deactivation</span>
                      </button>
                      <span style={{width:"35%", textAlign:"start"}}>Date:&nbsp;{moment(item.date).format("DD-MMM-YYYY, HH:mm")},</span>
                      <span style={{width:"35%", textAlign:"start"}}>Reason: {item.reason}</span>
                    </li>
                  )
                }
                if (item.action === "Reactivated") {
                  return (
                    <li className="progress-reactivate" style={{width:"100%" , paddingTop:"8px"}}>
                      <button style={{width:"20%"}}>
                        <span className="btn-reactivate">Reactivation</span>
                      </button>
                      <span style={{width:"35%", textAlign:"start"}}>Date:&nbsp;{moment(item.date).format("DD-MMM-YYYY, HH:mm")},</span>
                      <span style={{width:"35%", textAlign:"start"}}>Reason: {item.reason}</span>
                    </li>
                  )
                }
                if (item.action === "Added") {
                  return (
                    <li className="progress-created" style={{width:"100%", paddingTop:"8px"}}>
                      <button style={{width:"20%"}}>
                        <span className="btn-created">Addition</span>
                      </button>
                      <span style={{width:"35%", textAlign:"start"}}>Date:&nbsp;{moment(item.date).format("DD-MMM-YYYY, HH:mm")}</span>
                      <span style={{width:"35%", textAlign:"start"}}></span>
                    </li>
                  )
                }
              })}
          </>
        </ol>
        <div className="footer">
          <button onClick={() => closeModal(false)}> <KeyboardBackspace /> Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
