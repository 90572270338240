import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import { BorderColor } from "@material-ui/icons";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";

const PurchaseSummary = ({ closeModal, Data , selectedOption}) => {

  const planID = {
    planID: Data?.item?.planId || Data?.planId,
    planName: Data?.item?.planName ||Data?.planName ,
    description: Data?.item?.description ||Data?.description ,
    planStatus: Data?.item?.status || Data?.status,
    subscriptionType:selectedOption
  }


  console.log("monthlyCharges-->",Data)

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(`admin-module/api/v1/subscription/upgradePlan`, planID)
    );
    const { success, message } = resp;
    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="mainCont" style={{height:"337px"}}>
        <div className="bg-grdnt">
          <p className="ph">Purchase Summary</p>
        </div>

        <div className="body" style={{ padding: "6px" }}>
          <section style={{ padding: "6px" }}>
            <span className="planSec">
              <span>Plan Name:</span>
              <span>{Data?.item?.planName || Data?.planName}</span>
            </span>
            {/* <span className='planSec'>
                <span>Plan ID:</span>
                <span>{Data?.item?.planId || Data?.planId}</span>
            </span> */}
            <span className="planSec">
              <span>Plan ID:</span>
              <span>{Data?.item?.planId || Data?.planId}</span>
            </span>
          </section>

          {/* <section style={{ padding: "6px" }}>
            <span style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ color: "black", fontWeight: "bold" }}>
                Plan Discription:
              </span>
              <p style={{ fontWeight: "lighter" , overflow:"auto", height:"100px"}}>
                {Data?.descriptionData?.item?.description}
              </p>
            </span>
          </section> */}

          <section>
            <span className="planSect">
              <span>Plan Amount:</span>
              <span>{selectedOption==="Monthly" ? ( Data?.item?.monthlyCharges || Data?.monthlyCharges) :( Data?.item?.yearlyCharges || Data?.yearlyCharges)}</span>
            </span>
            <span className="planSect">
              <span>GST:</span>
              <span>18 %</span>
            </span>
            <span className="planSect">
              <span style={{ fontWeight: "bold" }}>Total Payment Amount:</span>
              <span>{parseInt(selectedOption==="Monthly" ? ( Data?.item?.monthlyCharges || Data?.monthlyCharges) :( Data?.item?.yearlyCharges || Data?.yearlyCharges)) + ((parseInt(selectedOption==="Monthly" ? ( Data?.item?.monthlyCharges || Data?.monthlyCharges) :( Data?.item?.yearlyCharges || Data?.yearlyCharges)) * 18) / 100)}</span>
            </span>
          </section>
        </div>

        <br />
        <div
          style={{
            position: "sticky",
            bottom: "0px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop:"7px"
          }}
        >
          <button
            className="footerPurchase-btn"
            onClick={() => closeModal(false)}
          >
            <MdOutlineCancel /> Cancel
          </button>

          <button
            className="footerPurchase-btn"
            onClick={submitHandler}
            style={{ backgroundColor: "#0a18a3", color: "white" }}
          >
            <FaArrowRight /> Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseSummary;
