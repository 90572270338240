import {
 HOMEDASHBOARD_ROUTE_URL,
 MAIN_APP_ROUTE_URL,
 SUBSCRIPTION_ROUTE_URL,
 SUPER_ADMIN_DASHBOARD_ROUTE_URL,
 OPERATIONS_DASHBOARD_ROUTE_URL,
 SUB_ADMIN_DASHBOARD_ROUTE_URL,
 PROCUREMENT_DASHBOARD_ROUTE_URL,
 USER_ROUTE_URL,
 ROLES_MANAGEMENT_ROUTE_URL,
 MASTER_CONFIGURATION_ROUTE_URL,
 ORGANIZATION_ROUTE_URL,
 SUPPORT_ROUTE_URL,
 LOGIN_ROUTE_URL,
 VEHICLES_ROUTE_URL,
 VENDORS_ROUTE_URL,
 COMPONENTS_ROUTE_URL,
 MAPPING_ROUTE_URL,
 ASSEMBLY_ROUTE_URL,
 BOM_ROUTE_URL,
 BOM_COSTING_ROUTE_URL,
 ORDERS_ROUTE_URL,
 WORKORDER_ROUTE_URL,
 QC_DASHBOARD,
 INVENTORY_ROUTE_URL,
 LABOUR_ROUTE_URL,
 INVENTORY_DASHBOARD_ROUTE_URL,
 INWARD_ROUTE_URL,
 INVENTORY_INV_ROUTE_URL,
 SHOPFLOOR_DASHBOARD_ROUTE_URL,
 WO_SHOPFLOOR_ROUTE_URL,
 INVENTORY_SHOPFLOOR_ROUTE_URL,
 MANAGEMENT_DASHBOARD_ROUTE_URL,
 APPROVAL_ROUTE_URL,
 STOCK_MANAGEMENT_ROUTE_URL,
 OUTWARD_ROUTE_URL,
 QUALITY_ROUTE_URL,
 WORKORDER_QC_ROUTE_URL,
 PRICING_ROUTE_URL,
 INVENTORY_MANAGEMENT_ROUTE_URL,
 PO_REQUEST_ROUTE_URL,
 SALE_REQUEST_ROUTE_URL,
 SALES_ROUTE_URL,
 BUSINESS_INSIGHTS_ROUTE_URL,
 FINANCE_INVENTORY_ROUTE_URL,
 FINANCE_RETURNS_ROUTE_URL,
 FINANCE_DASHBOARD_ROUTE_URL,
 PRODUCTION_DASHBOARD_ROUTE_URL,
 BUSINESS_USER_ROUTE_URL,
 VEHICLES_SPARES_ROUTE_URL,
 REQUISITION_ROUTE_URL,
 SHOWROOMS_ROUTE_URL,
 INVOICE_PAYMEMT_ROUTE_URL,
 DEALER_DASHBOARD_USER_ROUTE_URL,
 SERVICE_CENTER_ROUTE_URL,
 SERVICE_CENTER_DASHBOARD_ROUTE_URL,
 SERVICE_CENTER_SPARES_ROUTE_URL,
 SERVICE_CENTER_USER_ROUTE_URL,
 SERVICE_CENTER_ORDERS_ROUTE_URL,
 SERVICE_CENTER_INVENTORY_ROUTE_URL,
 SERVICE_CENTER_JOBS_ROUTE_URL,
 SERVICE_CENTER_RETURNS_ROUTE_URL,
 SHOWROOM_ROUTE_URL,
 SHOWROOM_VEHICLE_BATTERIES_ROUTE_URL,
 SHOWROOM_ORDERS_ROUTE_URL,
 SHOWROOM_RETURNS_ROUTE_URL,
 SHOWROOM_INVENTORY_ROUTE_URL,
 SHOWROOM_CUSTOMER_SALES_ROUTE_URL,
 SHOWROOM_VEHICLE_WARRANTY,
 STORE_MANAGER_SPARES_ROUTE_URL,
 STORE_MANAGER_HOME_ROUTE_URL,
 STORE_MANAGER_ORDER_ROUTE_URL,
 STORE_MANAGER_INVENTORY_ROUTE_URL,
 STORE_MANAGER_SPARE_REQUEST_ROUTE_URL,
 STORE_MANAGER_RETURNS_ROUTE_URL,
 SYSTEM_ADMIN_HOME_ROUTE_URL,
 SYSTEM_ADMIN_SUBSCRIPTIONS_ROUTE_URL,
 SYSTEM_ADMIN_BUSINESS_ENTITY_ROUTE_URL,
 SYSTEM_ADMIN_INVOICE_AND_PAYMENT_ROUTE_URL,
 SYSTEM_ADMIN_CONFIGURATIONS,
 OPERATIONS_VEHICLE_AND_SPARES_URL,
 OPERATIONS_ZONE_MANAGEMENT_ROUTE_URL,
 NOTIFICATION_VIEW_ROUTE_URL,
 OPERATIONS_SETTING_ROUTE_URL,
 OPERATIONS_SALES_USER,
 OPERATION_BUSINESS_ENTITIES,
 OERATION_WARRANTY_AND_SERVICES,
 OPERATIONS_TEMPLATES,
 SERVICE_ADVISOR_HOME_ROUTE_URL,
 SERVICE_ADVISOR_SPARE_PARTS_URL,
 SERVICE_ADVISOR_SERVICE_JOBS,
 DEALER_ONBOARD_FORM_ROUTE_URL,
 DEALER_VEHICLE_WARRANTY,
 OPERATIONS_BUSINESS_INVENTORY_AND_SALES_URL,
 SERVICE_ADVISOR_SPARE_RETURNS,
 AREA_VEHICLE_SPARES_URL,
 AREA_SALES_HOME,
 AREA_SALES_OR_URL,
 // AREA_SALES_RETURNS_URL
 // AREA_SALES_ORDER_URL,
 AREA_SALES_RETURNS_URL,
 AREA_SALES_DASHBOARD_ROUTE_URL,
 AREA_SALES_ORDERS_ROUTE_URL,
 ZONAL_SALES_HOME_ROUTE_URL,
 ZONAL_SALES_USER_ROUTE_URL,
 ZONAL_SALES_VEHICLE_AND_SPARES_ROUTE_URL,
 ZONAL_SALES_ORDERS_ROUTE_URL,
 ZONAL_SALES_RETURNS_ROUTE_URL,
 ZONAL_SALES_BUSINESS_INVENTOR_ROUTE_URL,
 ZONAL_SALES_BUSINESS_SALES_ROUTE_URL,
 NATIONAL_SALES_HOME_ROUTE_URL,
 NATIONAL_SALES_USER_ROUTE_URL,
 NATIONAL_SALES_VEHICLE_AND_SPARES_ROUTE_URL,
 NATIONAL_SALES_ORDERS_ROUTE_URL,
 NATIONAL_SALES_RETURNS_ROUTE_URL,
 NATIONAL_SALES_BUSINESS_INVENTOR_ROUTE_URL,
 NATIONAL_SALES_BUSINESS_SALES_ROUTE_URL,
 AREA_SALES_BUSINESS_INVENTOR_ROUTE_URL,
 AREA_SALES_BUSINESS_SALES_ROUTE_URL,
 RESET_PASSWORD_ROUTE_URL,

} from "./route-urls";

import {
 HomeDashboard,
 SuperAdminDashboard,
 ProcurementDashboard,
 Subscription,
 RolesManagement,
 MasterConfiguration,
 SettingsTabs,
 UsersTab,
 Login,
 Vendors,
 Components,
 Mapping,
 Vehicles,
 Assembly,
 Bom,
 BomCosting,
 Orders,
 Workorder,
 Inventory,
 Labour,
 Inward,
 Inventory_INV,
 WoShopfloor,
 InventoryShopfloor,
 Approval,
 StockManagement,
 Outward,
 Quality,
 WorkOrderQC,
 Pricing,
 InventoryManagement,
 PORequest,
 Salerequest,
 Sales,
 BusinessInsights,
 FinanceInventory,
 FinanceReturns,
 QcDashboard,
 FinanceDashboard,
 ProductionDashboard,
 VehicleSpares,
 RequisitionOrders,
 DealerServiceCenter,
 ServiceCenterUser,
 ServiceCenterOrders,
 ServiceCenterInventory,
 ShowroomDashboard,
 ShowroomVehicleBatteries,
 ShowroomOrders,
 Returns,
 StoreManagerSpares,
 StoreManagerDashboard,
} from "../../pages";

import MainApp from "../../layouts/app-layout/mainApp";
import DealerBusinessUserTabs from "../../pages/dealerBusinessUser/dealerBusinessUserTab";
import DealerShowroomTabs from "../../pages/DealerShowroom/DealerShowroom";
import DealerInvoicePaymentTabs from "../../pages/Invoice-Payments/invoicePayments";
import DealerDashboard from "../../pages/DealerDashboard/dealerDashboard";
import DealerVehicleWarranty from "../../pages/DealerVehicleWarranty/DealerVehicleWarranty"
import ServiceCenterDashborad from "../../pages/ServiceCenterDashboard/serviceCenterDashborad";
import ServiceCenterUserTabs from "../../pages/ServiceCenter/serviceCenterUser/serviceCenterUserTabs";
import ShowroomInventoryTabs from "../../pages/showroomInvetory/showroomInventoryTabs";
import ShowroomCustomerSalesTabs from "../../pages/ShowroomCustomer&sales/ShowroomCustomerSalesTabs";
import StoreManagerOrders from "../../pages/storeManagerOrders/StoreManagerOrders";
import StoreManagerInventory from "../../pages/storeManagerInventory/storeManagerInventory";
import StoreManagerSpareRequest from "../../pages/StoreManagerSpareRequest/StoreManagerSpareRequest";
import StoreManagerReturns from "../../pages/storeManagerReturns/StoreManagerReturns";
import InventoryDashboard from "../../pages/InventoryDashboard/InventoryDashboard";
import ShopFloorDashboard from "../../pages/ShopFloorDashboard/ShopFloorDashboard";
import ManagementDash from "../../pages/ManagementDashboard/managementDash";
import { NotFoundPage } from "../../pages";
import SystemAdminDashboard from "../../pages/SystemAdminDashboard/SysAdminDash";
import SystemAdminSubscription from "../../pages/SystemAdminSubscription/SystemAdminSubscription";
import SystemAdminBusinessEntity from "../../pages/SystemAdminBusinessEntity/SystemAdminBusiness";
import SystemAdminInvoiceandPayment from "../../pages/SystemAdminInvoiceandPayment/SystemAdminInvoiceandPayment";
import SystemAdminConfigurations from "../../pages/SystemAdminConfiguration/SystemAdminConfiguration";
import ViewAllNotification from "../../layouts/app-layout/components/header/viewAllNotification";
import OperationsDashboard from "../../pages/OperationsDashboard/OperationsDashboard";
import VehicleAndSpares from "../../pages/VehicleAndSpares/VehicleAndSpares";
import OpSettingsTabs from "../../pages/OperationSettingTab/OpSetting";
import OperationSalesUser from "../../pages/SalesUser/SalesUser";
import ZoneManagement from "../../pages/zoneManagement/zoneManagement";
import OperationBusinessEntities from "../../pages/OperationBusinessEntities/OperationBusinessEntities";
import DealerOnboardForm from "../../pages/dealerOnboardForm/dealerOnboardForm";
import ServiceCenterSpares from "../../pages/ServiceCenterSpares/ServiceCenterSpares";
import OperationBIS from "../../pages/OperationBusinessInventoryAndSales/OperationBIS";
import OperatinoWS from "../../pages/OperationsWarrantyServices/OperationsWarrantyServices";
import ServiceCenterReturns from "../../pages/serviceCenterReturns/serviceCenterReturns";
import ServiceAdvisorDashboard from "../../pages/ServiceAdvisorDashboard/ServiceAdvisorDashboard";
import ServiceAdvisorSpareParts from "../../pages/ServiceAdvisorSpareParts/ServiceAdvisorSpareParts";
import Jobs from "../../pages/serviceCenterJobs/Jobs";
import ServiceAdvisorServiceJobs from "../../pages/ServiceAdvisorServiceJobs/ServiceAdvisorServiceJobs";
import SpareRequestAndReturns from "../../pages/ServiceAdvisorRequestAndReturn/SpareRequestAndReturns";
import AreaVehicleSpares from "../../pages/AreaSales/AreaSalesVehicle&Spares/AreaSales";
import AreaSalesOrders from "../../pages/AreaSalesOrder/AreaSalesOrder";
import AreaSalesReturn from "../../pages/AreaSalesReturn/AreaSalesReturn";
import AreaSalesDashboard from "../../pages/areaSalesDashboard/AreaSalesDashboard";
import ZonalSalesHome from "../../pages/ZonalSalesDashboard/ZonalSalesDashboard";
import ZonalSalesUser from "../../pages/ZonalSalesUsers/ZonalSalesUsers";
import ZonalSalesVehicleAndSpares from "../../pages/ZonalSalesVehicleAndSpares/ZonalSalesVehicleAndSpares";
import ZonalSalesOrders from "../../pages/ZonalSalesOrders/ZonalSalesOrders";
import ZonalSalesReturns from "../../pages/ZonalSalesReturns/ZonalSalesReturns";
import ZonalSalesBusinessInventory from "../../pages/ZonalSalesBusinessInventory/ZonalSalesBusinessInventory";
import ZonalSalesBusinessSales from "../../pages/ZonalSalesBusinessSales/ZonalSalesBusinessSales";
import NationalSalesDashboard from "../../pages/NationalSalesDashboard/NationalSalesDashboard";
import NationalSalesUsers from "../../pages/NationalSalesUsers/NationalSalesUsers";
import NationalSalesVehicleAndSpares from "../../pages/NationalSalesVehicleAndSpares/NationalSalesVehicleAndSpares";
import NationalSalesOrders from "../../pages/NationalSalesOrders/NationalSalesOrders";
import NationalSalesReturns from "../../pages/NationalSalesReturns/NationalSalesReturns";
import NationalSalesBusinessInventory from "../../pages/NationalSalesBusinessInventory/NationalSalesBusinessInventory";
import NationalSalesBusinessSales from "../../pages/NationalSalesBusinessSales/NationalSalesBusinessSales";
import AreaSalesBusinessInventory from "../../pages/AreaSalesBusinessInventory/AreaSalesBusinessInventory";
import AreaSalesBusinessSales from "../../pages/AreaSalesBusinessSales/AreaSalesBusinessSales";
import ShowroomVehicleWarranty from "../../pages/ShowroomVehicleWarranty/ShowroomVehicleWarranty";
import Templates from "../../pages/Templates/Templates.jsx";
import ResetPassword from "../modal/firstLoginModal";
import SupportTabs from "../../pages/SupportTabs/SupportTabs";

const routes = [
 {
   path: LOGIN_ROUTE_URL,
   component: Login,
 },
 {
   path: DEALER_ONBOARD_FORM_ROUTE_URL,
   exact: true,
   component: DealerOnboardForm,
 },
 {
   path: RESET_PASSWORD_ROUTE_URL,
   exact: true,
   component: ResetPassword,
 },
 {
   path: MAIN_APP_ROUTE_URL,
   component: MainApp,
   routes: [
     {
       path: HOMEDASHBOARD_ROUTE_URL,
       exact: true,
       component: HomeDashboard,
     },
     {
       path: SUPER_ADMIN_DASHBOARD_ROUTE_URL,
       exact: true,
       component: SuperAdminDashboard,
     },
     {
       path: SUB_ADMIN_DASHBOARD_ROUTE_URL,
       exact: true,
       component: SuperAdminDashboard,
     },
     {
       path: PROCUREMENT_DASHBOARD_ROUTE_URL,
       exact: true,
       component: ProcurementDashboard,
     },
     {
       path: SUBSCRIPTION_ROUTE_URL,
       exact: true,
       component: Subscription,
     },
     {
       path: USER_ROUTE_URL,
       exact: true,
       component: UsersTab,
     },
     {
       path: ROLES_MANAGEMENT_ROUTE_URL,
       exact: true,
       component: RolesManagement,
     },
     {
       path: MASTER_CONFIGURATION_ROUTE_URL,
       exact: true,
       component: MasterConfiguration,
     },
     {
       path: ORGANIZATION_ROUTE_URL,
       exact: true,
       component: SettingsTabs,
     },
     {
       path: SUPPORT_ROUTE_URL,
       exact: true,
       component: SupportTabs,
     },
     {
       path: VEHICLES_ROUTE_URL,
       exact: true,
       component: Vehicles,
     },
     {
       path: LABOUR_ROUTE_URL,
       exact: true,
       component: Labour,
     },
     {
       path: ASSEMBLY_ROUTE_URL,
       exact: true,
       component: Assembly,
     },
     {
       path: BOM_ROUTE_URL,
       exact: true,
       component: Bom,
     },
     {
       path: WORKORDER_ROUTE_URL,
       exact: true,
       component: Workorder,
     },
     {
       path: INVENTORY_ROUTE_URL,
       exact: true,
       component: Inventory,
     },
     {
       path: VENDORS_ROUTE_URL,
       exact: true,
       component: Vendors,
     },
     {
       path: COMPONENTS_ROUTE_URL,
       exact: true,
       component: Components, 
     },
     {
       path: MAPPING_ROUTE_URL,
       exact: true,
       component: Mapping,
     },
     {
       path: BOM_COSTING_ROUTE_URL,
       exact: true,
       component: BomCosting,
     },
     {
       path: ORDERS_ROUTE_URL,
       exact: true,
       component: Orders,
     },
     {
       path: INWARD_ROUTE_URL,
       exact: true,
       component: Inward,
     },
     {
       path: INVENTORY_INV_ROUTE_URL,
       exact: true,
       component: Inventory_INV,
     },
     {
       path: SHOPFLOOR_DASHBOARD_ROUTE_URL,
       exact: true,
       component: ShopFloorDashboard,
     },
     {
       path: WO_SHOPFLOOR_ROUTE_URL,
       exact: true,
       component: WoShopfloor,
     },
     {
       path: INVENTORY_SHOPFLOOR_ROUTE_URL,
       exact: true,
       component: InventoryShopfloor,
     },
     {
       path: INVENTORY_DASHBOARD_ROUTE_URL,
       exact: true,
       component: InventoryDashboard,
     },
     {
       path: APPROVAL_ROUTE_URL,
       exact: true,
       component: Approval,
     },
     {
       path: WORKORDER_QC_ROUTE_URL,
       exact: true,
       component: WorkOrderQC,
     },
     {
       path: STOCK_MANAGEMENT_ROUTE_URL,
       exact: true,
       component: StockManagement,
     },
     {
       path: OUTWARD_ROUTE_URL,
       exact: true,
       component: Outward,
     },
     {
       path: QUALITY_ROUTE_URL,
       exact: true,
       component: Quality,
     },
     {
       path: PRICING_ROUTE_URL,
       exact: true,
       component: Pricing,
     },
     {
       path: INVENTORY_MANAGEMENT_ROUTE_URL,
       exact: true,
       component: InventoryManagement,
     },
     {
       path: PO_REQUEST_ROUTE_URL,
       exact: true,
       component: PORequest,
     },
     {
       path: SALE_REQUEST_ROUTE_URL,
       exact: true,
       component: Salerequest,
     },
     {
       path: SALES_ROUTE_URL,
       exact: true,
       component: Sales,
     },
     {
       path: BUSINESS_INSIGHTS_ROUTE_URL,
       exact: true,
       component: BusinessInsights,
     },
     {
       path: FINANCE_INVENTORY_ROUTE_URL,
       exact: true,
       component: FinanceInventory,
     },
     {
       path: FINANCE_RETURNS_ROUTE_URL,
       exact: true,
       component: FinanceReturns,
     },
     {
       path: QC_DASHBOARD,
       exact: true,
       component: QcDashboard,
     },
     {
       path: FINANCE_DASHBOARD_ROUTE_URL,
       exact: true,
       component: FinanceDashboard,
     },
     {
       path: PRODUCTION_DASHBOARD_ROUTE_URL,
       exact: true,
       component: ProductionDashboard,
     },
     {
       path: DEALER_DASHBOARD_USER_ROUTE_URL,
       exact: true,
       component: DealerDashboard,
     },
     {
       path: BUSINESS_USER_ROUTE_URL,
       exact: true,
       component: DealerBusinessUserTabs,
     },
     {
      path: DEALER_VEHICLE_WARRANTY,
      exact: true,
      component: DealerVehicleWarranty,
    },
     {
       path: VEHICLES_SPARES_ROUTE_URL,
       exact: true,
       component: VehicleSpares,
     },
     {
       path: REQUISITION_ROUTE_URL,
       exact: true,
       component: RequisitionOrders,
     },
     {
       path: SHOWROOMS_ROUTE_URL,
       exact: true,
       component: DealerShowroomTabs,
     },
     {
       path: INVOICE_PAYMEMT_ROUTE_URL,
       exact: true,
       component: DealerInvoicePaymentTabs,
     },
     {
       path: SERVICE_CENTER_ROUTE_URL,
       exact: true,
       component: DealerServiceCenter,
     },
     {
       path: SERVICE_CENTER_DASHBOARD_ROUTE_URL,
       exact: true,
       component: ServiceCenterDashborad,
     },
     {
       path: SERVICE_CENTER_USER_ROUTE_URL,
       exact: true,
       component: ServiceCenterUserTabs,
     },
     {
       path: SERVICE_CENTER_SPARES_ROUTE_URL,
       exact: true,
       component: ServiceCenterSpares,
     },
     {
       path: SERVICE_CENTER_ORDERS_ROUTE_URL,
       exact: true,
       component: ServiceCenterOrders,
     },
     {
       path: SERVICE_CENTER_INVENTORY_ROUTE_URL,
       exact: true,
       component: ServiceCenterInventory,
     },
     {
       path: SERVICE_CENTER_JOBS_ROUTE_URL,
       exact: true,
       component: Jobs,
     },
     {
       path: SERVICE_CENTER_RETURNS_ROUTE_URL,
       exact: true,
       component: ServiceCenterReturns,
     },
     {
       path: SHOWROOM_ROUTE_URL,
       exact: true,
       component: ShowroomDashboard,
     },
     {
       path: SHOWROOM_VEHICLE_BATTERIES_ROUTE_URL,
       exact: true,
       component: ShowroomVehicleBatteries,
     },
     {
       path: SHOWROOM_ORDERS_ROUTE_URL,
       exact: true,
       component: ShowroomOrders,
     },
     {
       path: SHOWROOM_RETURNS_ROUTE_URL,
       exact: true,
       component: Returns,
     },
     {
       path: SHOWROOM_INVENTORY_ROUTE_URL,
       exact: true,
       component: ShowroomInventoryTabs,
     },
     {
       path: SHOWROOM_CUSTOMER_SALES_ROUTE_URL,
       exact: true,
       component: ShowroomCustomerSalesTabs,
     },
     {
      path: SHOWROOM_VEHICLE_WARRANTY,
      exact: true,
      component: ShowroomVehicleWarranty,
    },
     {
       path: STORE_MANAGER_HOME_ROUTE_URL,
       exact: true,
       component: StoreManagerDashboard,
     },
     {
       path: STORE_MANAGER_SPARES_ROUTE_URL,
       exact: true,
       component: StoreManagerSpares,
     },
     {
       path: STORE_MANAGER_ORDER_ROUTE_URL,
       exact: true,
       component: StoreManagerOrders,
     },
     {
       path: STORE_MANAGER_INVENTORY_ROUTE_URL,
       exact: true,
       component: StoreManagerInventory,
     },
     {
       path: STORE_MANAGER_SPARE_REQUEST_ROUTE_URL,
       exact: true,
       component: StoreManagerSpareRequest,
     },
     {
       path: STORE_MANAGER_RETURNS_ROUTE_URL,
       exact: true,
       component: StoreManagerReturns,
     },
     {
       path: MANAGEMENT_DASHBOARD_ROUTE_URL,
       exact: true,
       component: ManagementDash,
     },
     {
       path: SYSTEM_ADMIN_HOME_ROUTE_URL,
       exact: true,
       component: SystemAdminDashboard,
     },
     {
       path: SYSTEM_ADMIN_SUBSCRIPTIONS_ROUTE_URL,
       exact: true,
       component: SystemAdminSubscription,
     },
     {
       path: SYSTEM_ADMIN_BUSINESS_ENTITY_ROUTE_URL,
       exact: true,
       component: SystemAdminBusinessEntity,
     },
     {
       path: SYSTEM_ADMIN_INVOICE_AND_PAYMENT_ROUTE_URL,
       exact: true,
       component: SystemAdminInvoiceandPayment,
     },
     {
       path: SYSTEM_ADMIN_CONFIGURATIONS,
       exact: true,
       component: SystemAdminConfigurations,
     },
     {
       path: NOTIFICATION_VIEW_ROUTE_URL,
       exact: true,
       component: ViewAllNotification,
     },
     {
       path: OPERATIONS_DASHBOARD_ROUTE_URL,
       exact: true,
       component: OperationsDashboard,
     },
     {
       path: OPERATIONS_VEHICLE_AND_SPARES_URL,
       exact: true,
       component: VehicleAndSpares,
     },
     {
       path: OPERATIONS_ZONE_MANAGEMENT_ROUTE_URL,
       exact: true,
       component: ZoneManagement,
     },
     {
       path: OPERATIONS_SETTING_ROUTE_URL,
       exact: true,
       component: OpSettingsTabs,
     },
     {
       path: OPERATIONS_SALES_USER,
       exact: true,
       component: OperationSalesUser,
     },
     {
       path: OPERATION_BUSINESS_ENTITIES,
       exact: true,
       component: OperationBusinessEntities,
     },
     {
       path: OPERATIONS_BUSINESS_INVENTORY_AND_SALES_URL,
       exact: true,
       component: OperationBIS,
     },
     {
       path: OERATION_WARRANTY_AND_SERVICES,
       exact: true,
       component: OperatinoWS,
     },
     {
       path: OPERATIONS_TEMPLATES,
       exact: true,
       component: Templates,
     },
     {
       path: SERVICE_ADVISOR_HOME_ROUTE_URL,
       exact: true,
       component: ServiceAdvisorDashboard,
     },
     {
       path: SERVICE_ADVISOR_SPARE_PARTS_URL,
       exact: true,
       component: ServiceAdvisorSpareParts,
     },
     {
       path: SERVICE_ADVISOR_SERVICE_JOBS,
       exact: true,
       component: ServiceAdvisorServiceJobs,
     },
     {
       path: SERVICE_ADVISOR_SPARE_RETURNS,
       exact: true,
       component: SpareRequestAndReturns,
     },
     {
       path: SERVICE_ADVISOR_SERVICE_JOBS,
       exact: true,
       component: OperatinoWS,
     },
     {
       path: AREA_SALES_DASHBOARD_ROUTE_URL,
       exact: true,
       component: AreaSalesDashboard,
     },
     {
       path: AREA_VEHICLE_SPARES_URL,
       exact: true,
       component: AreaVehicleSpares,
     },
     {
       path: AREA_SALES_ORDERS_ROUTE_URL,
       exact: true,
       component: AreaSalesOrders,
     },
     {
       path: AREA_SALES_RETURNS_URL,
       exact: true,
       component: AreaSalesReturn,
     },
     {
      path: AREA_SALES_BUSINESS_INVENTOR_ROUTE_URL,
      exact: true,
      component: AreaSalesBusinessInventory,
    },
    {
      path: AREA_SALES_BUSINESS_SALES_ROUTE_URL,
      exact: true,
      component: AreaSalesBusinessSales,
    },
     {
       path: ZONAL_SALES_HOME_ROUTE_URL,
       exact: true,
       component: ZonalSalesHome,
     },
     {
       path: ZONAL_SALES_USER_ROUTE_URL,
       exact: true,
       component: ZonalSalesUser,
     },
     {
       path: ZONAL_SALES_VEHICLE_AND_SPARES_ROUTE_URL,
       exact: true,
       component: ZonalSalesVehicleAndSpares,
     },
     {
       path: ZONAL_SALES_ORDERS_ROUTE_URL,
       exact: true,
       component: ZonalSalesOrders,
     },
     {
       path: ZONAL_SALES_RETURNS_ROUTE_URL,
       exact: true,
       component: ZonalSalesReturns,
     },
     {
       path: ZONAL_SALES_BUSINESS_INVENTOR_ROUTE_URL,
       exact: true,
       component: ZonalSalesBusinessInventory,
     },
     {
       path: ZONAL_SALES_BUSINESS_SALES_ROUTE_URL,
       exact: true,
       component: ZonalSalesBusinessSales,
     },
     {
       path: NATIONAL_SALES_HOME_ROUTE_URL,
       exact: true,
       component: NationalSalesDashboard,
     },
     {
       path: NATIONAL_SALES_USER_ROUTE_URL,
       exact: true,
       component: NationalSalesUsers,
     },
     {
       path: NATIONAL_SALES_VEHICLE_AND_SPARES_ROUTE_URL,
       exact: true,
       component: NationalSalesVehicleAndSpares,
     },
     {
       path: NATIONAL_SALES_ORDERS_ROUTE_URL,
       exact: true,
       component: NationalSalesOrders,
     },
     {
       path: NATIONAL_SALES_RETURNS_ROUTE_URL,
       exact: true,
       component: NationalSalesReturns,
     },
     {
       path: NATIONAL_SALES_BUSINESS_INVENTOR_ROUTE_URL,
       exact: true,
       component: NationalSalesBusinessInventory,
     },
     {
       path: NATIONAL_SALES_BUSINESS_SALES_ROUTE_URL,
       exact: true,
       component: NationalSalesBusinessSales,
     },
     {
       path: "*",
       exact: true,
       component: NotFoundPage,
     },
   ],
 },
];

export default routes;
