import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faRedoAlt,
  faEllipsisH,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import SearchBox from "../../../components/search/SearchBox";
import StandardavailableQuantity from "../action/standard_Avail_Qty";
import "./finishGoods.scss";
import FinishedGoodsAction from "./actions/StandardVehicleaction";
import AppConstants from "../../../utils/app-constants";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import handleExport from "../../../utils/exportExcel";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";


const OnDemandstandardVehicle = () => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [openQty, setOpenQty] = useState();
  const [isOpen, setIsOpen] = useState(true);
  const [standardVehicleData, setStandardVehicleData] = useState([]);
  const [totalAddedVehicles, setTotalAddedVehicles] = useState();
  const [totalReleasedVehicles, setTotalReleasedVehicles] = useState();
  const [totalAvailableVehicles, setTotalAvailableVehicles] = useState();

  const standardVehicle = async () => {
    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/finishedGoods/standard?vehicleSegment=${formValue.segment}&vehicleVariant=${formValue.vehicleVariant}&vehicleModel=${formValue.vehicleModel}`
      )
    );
    let standardTempArray = [];
    resp.data.map((item) => {
      standardTempArray.push({ ...item._id, count: item.count });
    });
    console.log(standardTempArray);
    setStandardVehicleData(standardTempArray);
    setTotalAddedVehicles(resp.totalAddedVehicles);
    setTotalReleasedVehicles(resp.totalReleasedVehicles);
    setTotalAvailableVehicles(resp.totalAvailableVehicles);

    if (resp.success) {
      successNotification(resp.message, { autoClose: 10000 });
    } else {
      errorNotification(resp.message, { autoClose: 10000 });
    }
  };
  useEffect(() => {
    standardVehicle();
  }, []);


  const uniqueVehicleSegment = [...new Set(standardVehicleData?.map((item) => item.vehicleSegment))];
  const uniqueVehicleModel = [...new Set(standardVehicleData?.map((item) => item.vehicleModel))];
  const uniqueVehicleVariant = [...new Set(standardVehicleData?.map((item) => item.vehicleVariant))];


  function on() {
    console.log("on");
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    console.log("off");
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }


  const handleClick = (e) => {
    setShow(!show);
  };

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.status === AppConstants.DELETED ? (
      ""
    ) : (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          <FinishedGoodsAction
            updatedData={""}
            rowData={row}
            setIsOpen={setIsOpen}
            rowIndex={rowIndex}
          />
        ) : null}
      </div>
    );
  };

  const [formValue, setFormValue] = useState({
    segment: "",
    vehicleModel: "",
    vehicleVariant: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };


  const clearFilter = async(e) => {
    setFormValue({
      segment: "",
      vehicleModel: "",
      vehicleVariant: "",
    });


    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/finishedGoods/standard`
      )
    );
    let standardTempArray = [];
    resp.data.map((item) => {
      standardTempArray.push({ ...item._id, count: item.count });
    });
    setStandardVehicleData(standardTempArray);
    setTotalAddedVehicles(resp.totalAddedVehicles);
    setTotalReleasedVehicles(resp.totalReleasedVehicles);
    setTotalAvailableVehicles(resp.totalAvailableVehicles);

    if (resp.success) {
      successNotification(resp.message, { autoClose: 10000 });
    } else {
      errorNotification(resp.message, { autoClose: 10000 });
    }
  };


  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      classes: "cellsOverflow",
      headerAttrs: { width: "50px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return standardVehicleData.findIndex(function (value, idx, arr) { return value == row }) + 1;
      },
      sort: true,
    },
    {
      text: "Segment",
      dataField: "vehicleSegment",
      classes: "cellsOverflow",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vehicle Model",
      dataField: "vehicleModel",
      classes: "cellsOverflow",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vehicle Variant",
      dataField: "vehicleVariant",
      classes: "cellsOverflow",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vehicle Type",
      dataField: "vehicleType",
      classes: "cellsOverflow",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vehicle Color",
      dataField: "vehicleColor",
      classes: "cellsOverflow",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Available Qty.",
      dataField: "count",
      classes: "cellsOverflow",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { openQty },
      // formatter: qtyFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      classes: "cellsOverflow",
      headerAttrs: { width: "50px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  return (
          <>
          <div >
                <div id="overlay" onClick={(e) => off(e)}></div>
                 <div className="heading bussiness" style={{marginBottom:"-5px"}}>
                   <span>Standard Vehicle Inventory</span>
                 </div>
         
                  <ToolkitProvider keyField="id" data={standardVehicleData} columns={columns} search >
                  
                   {(props) => (
                     <>
                     <div>
                       <div style={{ float: "right", marginTop: "-42px"}}>
                         <div style={{ display: "flex", marginRight: "-15px", height: "26px", marginTop:"10px", }}>
                           <div style={{ width: "220px" }}>
                             
                              <SearchBar
                               {...props.searchProps}
                               placeholder='Search'
                               style={{
                                 height: "25px",
                                // border: "1px solid #C6C6C6",
                                 border:"none",
                                 background: "white",
                                 borderRadius: "3px",
                                 marginRight: "-50px",
                               }}
                             />
                             
                           </div>
                           <div onClick={clearFilter} className="filter-refresh">
                             <FontAwesomeIcon icon={faRedoAlt} />
                             &nbsp; Refresh
                           </div>
                           <div className="filter-refresh" onClick={handleClick}>
                             {" "}
                             <FontAwesomeIcon icon={faFilter} />
                             &nbsp; Filters
                           </div>
                           <div
                             className="filter-refresh"
                             onClick={() => handleExport(standardVehicleData, "manageUsers")}
                           >
                             <FontAwesomeIcon icon={faDownload} />
                             &nbsp; Export
                           </div>
                         </div>
                        </div>
                       </div>
                        
              <div>
              

      {show && (
        <form
          onSubmit={submitHandler}
          style={{
            display: "flex",
            flexWrap: "wrap",
            height: "85px",
            backgroundColor: "#F7F7F7",
            padding: "5px",
          }}
          className="UHinputfields VMform"
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "20%" }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Segment
            </label>
            <select
              style={{ marginTop: "-5px", width: "100%" }}
              name="segment"
              value={formValue.segment}
              onChange={handleChange}
            >
              <option value="" disabled selected hidden>
                Segment
              </option>
              {uniqueVehicleSegment.map((item) => (
                <option>{item}</option>
              ))}
            </select>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "20%" }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Vehicle Model
            </label>
            <select
              style={{ marginTop: "-5px", width: "100%" }}
              name="vehicleModel"
              value={formValue.vehicleModel}
              onChange={handleChange}
            >
              <option value="" disabled selected hidden>
                Vehicle Model
              </option>
              {uniqueVehicleModel.map((item) => (
                <option>{item}</option>
              ))}
            </select>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "20%" }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Vehicle Variant
            </label>
            <select
              style={{ marginTop: "-5px", width: "100%" }}
              name="vehicleVariant"
              value={formValue.vehicleVariant}
              onChange={handleChange}
            >
              <option value="" disabled selected hidden>
                Vehicle Variant
              </option>
              {uniqueVehicleVariant.map((item) => (
                <option>{item}</option>
              ))}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "22px",
            }}
          >
            <button type="submit" id="apply" onClick={standardVehicle}>
              Apply
            </button>
            <button style={{ width: "100px" }} onClick={clearFilter} id="clear">
              Clear Filter
            </button>
          </div>
        </form>
      )}

      <div style={{ display: "flex", margin:"8px" }}>
        <div className="vehicleAdded">
          Total Vehicle Added : {totalAddedVehicles}
        </div>
        <div className="vehicleRelease">
          Total Vehicle Release : {totalReleasedVehicles}
        </div>
        <div className="availVehicle">
          Available Vehicle : {totalAvailableVehicles}
        </div>
      </div>

      </div>

      
              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
        </div>
    </>
  );
};

export default OnDemandstandardVehicle;
