import React from 'react';
import { Tabs } from 'react-simple-tabs-component';
import { TabLabels } from '../../utils/app-constants';
import "../settings-tabs/tabs.scss";
// import NewRequest from './component/NewRequest/NewRequest';
// import ManageRequest from './component/ManageRequest/ManageRequest';

const NewRequest = React.lazy(()=>import('./component/NewRequest/NewRequest'))
const ManageRequest = React.lazy(()=>import('./component/ManageRequest/ManageRequest'))


const StoreManagerSpareRequest = () =>{

    const tabs = [
        {
        id:"STMM4T1",
        label:TabLabels.NEW_REQUEST,
        Component:NewRequest
        },
        {
        id:"STMM4T2",
        label:TabLabels.MANAGE_REQUEST,
        Component:ManageRequest
        }
    ]

    return(
        <div className='app-layout__card'>
            <Tabs type="pills" tabs={tabs} />
        </div>
    )
}

export default StoreManagerSpareRequest