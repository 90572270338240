import React, { useState, useEffect } from "react";
import "../../../../components/modal/modal.scss";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFileUpload,
  faPlus,
  faTrashAlt,
  faTimes,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { errorNotification, successNotification } from "../../../../utils/notifications";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";

const EditFeatures = ({ updatedData, editData, closeModal , setShowModal}) => {



  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [selectedOption, setSelectedOption] = useState(editData?.time || "Monthly");  // Default to the current time if available
  const [blankForm, setBlankForm] = useState({
    name: editData?.name || "",  // Default to the current feature name if available
    time: selectedOption,
    _id:editData?._id
  });


  console.log("editData-->",editData)
  console.log("blankForm-->",blankForm)


  // Handle radio button change
  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setBlankForm((prevState) => ({
      ...prevState,
      time: selectedValue,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBlankForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateInputs = () => {
    if (!blankForm?.name) {
      errorNotification("Feature name is required.");
      return false;
    }
    return true;
  };

  const submitHandler = async () => {
    if (!validateInputs()) return;

    const result = await trackPromise(API.put(`systemadmin/api/v1/features`, blankForm));
    if (result?.success) {
      successNotification(result?.message, { autoClose: 3000 });
      closeModal(false);  // Close the modal after successful update
      updatedData();      // Refresh the data
    } else {
      errorNotification(result?.message, { autoClose: 3000 });
    }
  };

  const cancel = () => {
    closeModal(false);
    setShowModal(false)
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{ height: "auto", width: "auto" }}>
        <div className="header">
          <h3>Edit Subscription Feature</h3>
        </div>
        <div className="body">
          <form
            className="setting-form"
            onSubmit={handleSubmit(submitHandler)}
            style={{ marginTop: "10px",padding:"0px 20px" }}
          >
            <div style={{ display: "flex", flexDirection: "row", marginTop: "15px" , gap:"20px"}}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  value="Monthly"
                  checked={selectedOption === "Monthly"}
                  onChange={handleOptionChange}
                  style={{width:"20px", margin:"0px"}}
                />
                <span style={{ width: "60px", padding: "6px" }}>Monthly</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  value="Yearly"
                  checked={selectedOption === "Yearly"}
                  onChange={handleOptionChange}
                  style={{width:"20px", margin:"0px"}}
                />
                <span style={{ width: "60px", padding: "6px" }}>Yearly</span>
              </div>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
              <div
                style={{
                  margin: "0 5px",
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label style={{ fontSize: "13px", fontWeight: "500", marginLeft: "6px" , width:"92px"}}>
                  Feature Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="input-config"
                  type="text"
                  name="name"
                  value={blankForm?.name || ""}
                  onChange={handleInputChange}
                  placeholder={`Enter ${selectedOption} Feature`}
                  style={{ marginTop: "-8px"}}
                />
              </div>
            </div>

            <br />

            <div className="save-cancel">
              <div className="cust-reset cancel-button">
                <button type="button" className="btn btn-icon-label" onClick={cancel}>
                  <div style={{ fontSize: "14px", color: "#0A18A3", fontWeight: "500" }}>
                    <FontAwesomeIcon icon={faTimes} />
                    Cancel
                  </div>
                </button>
              </div>
              <div className="cancel-button">
                <button className="btn btn-icon-label" type="submit">
                  <div style={{ fontSize: "14px", color: "#0A18A3", fontWeight: "500" }}>
                    <FontAwesomeIcon icon={faEdit} /> Update
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditFeatures;
