import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";

const TermsView = ({data , color}) => {
  console.log("data-->", data);

  const [viewData, setViewData] = useState([]);

  console.log("viewData-->", viewData);

  const getViewDetails = async () => {
    const resp = await trackPromise(
      API.get(
        `/operations-module/api/v1/warrantyAndService/serviceMaster/serviceManual/getServiceManualForPopup?serviceManualType=${data}`
      )
    );
    console.log("getViewDetails-->", resp);
    setViewData(resp);
  };

  useEffect(() => {
    getViewDetails();
  }, []);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: viewData.serviceManualTerms }}
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize:"12px",
          fontWeight:"100",
          alignItems: "start",
          padding: "10px",
          textAlign:"start",
        //   width: "29%",
        //   border: "1px solid grey",
          overflow: "scroll",
          background: color == "#ebebeb"? "#ebebeb" :color =="#0A18A3"? "#0A18A3" : "white",
        }}
      />
    </>
  );
};

export default TermsView;
