import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";

const AddBattery = React.lazy(() => import("./add-battery"));
const ManageBattery = React.lazy(() => import("./manage-battery"));

const tabs = [
  {
    id: "PRM3T1",
    label: TabLabels.ADD_BATTERY,
    Component: AddBattery,
  },
  {
    id: "PRM3T2",
    label: TabLabels.MANAGE_BATTERY,
    Component: ManageBattery,
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

const ProcurementBattery = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default ProcurementBattery;
