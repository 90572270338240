import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import "../QcDashboard/styles/main.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { BsArrowUpRight, BsTable } from "react-icons/bs";
import { FaExpandAlt } from "react-icons/fa";
import { FaRedoAlt } from "react-icons/fa";
import { DatePicker, Space } from "antd";
import { useHistory } from "react-router-dom";
// Routing Components
import BusinessUser from "../dealerBusinessUser/showrooms";
import VehicleOrder from "../RequisitionOrders/components/NewRequisition";
import SparesOrder from "../RequisitionOrders/components/SpareNewRequisition";
import ShowroomInventory from "../DealerShowroom/DealerInventory/InventoryShowroomWise";
import ServiceCenterInventory from "../DealerServiceCenter/InventoryComponent/ServiceCenterWise";
import ShowroomSales from "../DealerShowroom/DealerSales/SalesShowroomWise";
import ServiceCenterSales from "../DealerServiceCenter/Sales/ServiceCenterWise";
// Dashboard Components
import UserCountBarChart from "./userCountBarChart";
import VehicleCard from "./VehicleCards";
import SpareInventory from "./SpareInventory";
import BusinessUserCard from "./BusinessUser";
import DealerSalesReport from "../DealerShowroom/dealerSalesReport";

function DealerDashboard() {
  const [openUserTable, setOpenUserTable] = useState(false);
  const [openVehicleOrderTable, setOpenVehicleOrderTable] = useState(false);
  const [openSparesOrderTable, setOpenSparesOrderTable] = useState(false);
  const [openShowroomInventoryTable, setOpenShowroomInventoryTable] = useState(false);
  const [openServiceCenterInventoryTable, setOpenServiceCenterInventoryTable] = useState(false);
  const [openShowroomSalesTable, setOpenShowroomSalesTable] = useState(false);
  const [openServiceCenterSalesTable, setOpenServiceCenterSalesTable] = useState(false);

  const { RangePicker } = DatePicker;
  const [calenderOpen, setCalenderOpen] = useState(false);

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    setCalenderOpen(false);
    document.getElementById("overlay").style.display = "none";
    // refresh();
    // vpaRefresh();
    // spareRefresh();
    // vehicleRefresh();
    // verification();
    // reVerificationRefresh();
  }

  const history = useHistory();

  return (
    <>
      {openUserTable == true ? (
        <BusinessUser inDashboard={setOpenUserTable} />
      ) : openVehicleOrderTable == true ? (
        <VehicleOrder inDashboard={setOpenVehicleOrderTable} />
      ) : openSparesOrderTable == true ? (
        <SparesOrder inDashboard={setOpenSparesOrderTable} />
      ) : openShowroomInventoryTable == true ? (
        <ShowroomInventory inDashboard={setOpenShowroomInventoryTable} />
      ) : openServiceCenterInventoryTable == true ? (
        <ServiceCenterInventory
          inDashboard={setOpenServiceCenterInventoryTable}
        />
      ) : openShowroomSalesTable == true ? (
        <DealerSalesReport inDashboard={setOpenShowroomSalesTable} />
      ) : openServiceCenterSalesTable == true ? (
        <ServiceCenterSales inDashboard={setOpenServiceCenterSalesTable} />
      ) : (
        <>
          <div id="overlay" onClick={(e) => off(e)}></div>
          <div
            className="thirdBox"
            style={{
              height: "auto",
              marginTop: "10px",
              padding: "23px 0px",
            }}
          >
            {/* First Row */}
            <>
              <div
                className="container-new"
                style={{ width: "98%", height: "auto", marginBottom: "5%", marginLeft: '10px' }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "98%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Business User</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "35%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => { setOpenUserTable(true) }}
                      />
                    </div>
                  </div>
                </div>
                <BusinessUserCard />
              </div>
            </>
            {/* Second Row */}
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "inherit",
                }}
              >
                <div
                  className="pricing"
                  style={{ height: "265px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Vehicle Orders</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenVehicleOrderTable(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <UserCountBarChart prop="vehicle" />
                  </div>
                </div>
                <div
                  className="pricing"
                  style={{ height: "265px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Spare Orders</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenSparesOrderTable(true)}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <UserCountBarChart prop="spare" />
                  </div>
                </div>
              </div>
            </>
            {/* Third Row */}
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "inherit",
                }}
              >
                <div
                  className="pricing"
                  style={{ height: "324px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Showroom Inventory</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenShowroomInventoryTable(true)}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <VehicleCard prop="inventory" />
                  </div>
                </div>
                <div
                  className="pricing"
                  style={{ height: "324px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "35%" }}>
                      <h5 style={{ fontSize: "14px" }}>Showroom Sales</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() =>
                            setOpenShowroomSalesTable(true)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <VehicleCard prop="showroom" />
                  </div>
                </div>
              </div>
            </>
            {/* Fourth Row */}
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "inherit",
                }}
              >
                <div
                  className="pricing"
                  style={{ height: "auto", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Spares Inventory</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenInventoryTable(true)}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <SpareInventory prop="inventory" />
                  </div>
                </div>
                <div
                  className="pricing"
                  style={{ height: "auto", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Service Jobs</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenJobsTable(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <SpareInventory prop="sales" />
                  </div>
                </div>
              </div>
            </>
          </div>
        </>
      )}
    </>
  );
}

export default DealerDashboard;
