import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import auth from "../../../utils/auth";

const EditShowroomLocationModal = ({
  updatedData,
  editData,
  closeModal,
  tabName,
  location,
  formValue,
}) => {
  console.log(editData);
  const [countryData, setCountryData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [selectedCity, setSelectedCity] = useState([]);
  const [locationValues, setLocationValues] = useState({
    address1: editData.location.address1,
    address2: editData.location.address2,
    country: editData.location.country,
    state: editData.location.state,
    city: editData.location.city,
    zipCode: editData.location.zipCode
  });

  console.log(locationValues);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/users/getAllCountries`)
    );
    console.log(resp);
    setCountryData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const handleInputChange = async (event) => {
    setLocationValues({ ...locationValues, [event.target.name]: event.target.value });
  };

  const handleCountryChange = async (event) => {
    setLocationValues({ ...locationValues, [event.target.name]: event.target.value });
    let countryName = event.target.value;
    const resp = await trackPromise(
      API.get(
        `admin-module/api/v1/users/getCountryDataByID?countryName=${countryName}`
      )
    );
    setSelectedCountry(resp[0]);
  };

  const handleZipcodeChange = async (event) => {
    // setLocationValues({ ...locationValues, [event.target.name]: event.target.value });
    let zipCode = event.target.value;
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/users/getDataByZipCode?zipcode=${zipCode}`)
    );
    setSelectedState(resp.state[0]);
    setSelectedCity(resp.city);
    setLocationValues({ ...locationValues, state: resp.state[0].stateName, zipCode: zipCode });
  };

  const submitHandler = () => {
    formValue.address1 = locationValues.address1
    formValue.address2 = locationValues.address2
    formValue.zipCode = locationValues.zipCode
    formValue.city = locationValues.city
    formValue.country = locationValues.country
    formValue.state = locationValues.state
    console.log(formValue)
    closeModal(false)
  };


  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "804px", height: "300px" }}
      >
        <div className="header">
          <h3>Edit Showroom Location</h3>
        </div>

        <div className="body">
          <label style={{ fontSize: "13px", fontWeight: "500" }}>Address 1</label>
          <br />

          <input
            name="address1"
            placeholder="Address 1"
            style={{ margin: "17px 0px 0px -56px" }}
            className="input-config"
            defaultValue={locationValues.address1}
            onChange={(event) => handleInputChange(event)}
          />

          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginLeft: "3px",
              width: "17%",
            }}
          >
            Address 2
          </label>
          <br />
          <input
            name="address2"
            placeholder="Address 2"
            style={{ margin: "17px 0px 0px -128px" }}
            className="input-config"
            defaultValue={locationValues.address2}
            onChange={(event) => handleInputChange(event)}
          />

          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginLeft: "6px"
            }}
          >
            Country
          </label>
          <br />
          <select
            disabled
            name="country"
            style={{ margin: "17px 0px 0px -45px" }}
            defaultValue={locationValues.country}
            className="input-config"
            onChange={(event) => handleCountryChange(event)}
          >
            <option value="" disabled selected>
              Country
            </option>
            {/* {countryData.map((item) => (
              <option value={item.countryName}>{item.countryName}</option>
            ))} */}
            <option value="India">India</option>
          </select>

          <label
            style={{ fontSize: "13px", fontWeight: "500", marginTop: "3px" }}
          >
            Zipcode/Postal
          </label>
          <br />
          <input
            className="input-config"
            type="text"
            style={{ margin: "19px 0px 5px -92px" }}
            name="zipCode"
            defaultValue={locationValues.zipCode}
            placeholder="ZipCode"
            onBlur={(event) => handleZipcodeChange(event)}
          />

          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginLeft: "15px",
              marginTop: "3px",
            }}
          >
            State/Province
          </label>
          <br />
          <input
            name="state"
            value={locationValues.state}
            disabled
            className="input-config"
            style={{ margin: "19px 0px 5px -96px" }}
            placeholder="State"
          />
          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginLeft: "16px",
              marginTop: "3px",
            }}
          >
            City
          </label>
          <br />
          <select
            name="city"
            defaultValue={locationValues.city}
            style={{ margin: "19px 0px 5px -32px" }}
            className="input-config"
            onChange={(event) => handleInputChange(event)}
          >
            <option disabled selected hidden>
              {selectedCity.length != 0
                ? selectedCity.PostOfficeName
                : editData.location.city}
            </option>
            {selectedCity.map((item) => (
              <option>{item.PostOfficeName}</option>
            ))}
          </select>
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditShowroomLocationModal;
