import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import GINno from "./GINno";
import CR_POno from "./CR_POno";
import CR_MR_ReturnQty from "./CR_MR_ReturnQty";
import moment from "moment";
import ViewSpecs from "../../inventory-inv/action/ViewSpecs";
import FullAssignment from "../../inventory-inv/action/FullAssignment";
import SpareReturnQty from "./spareReturnQty";
import RequestUpdate from "./requestUpdate";
import CompletedViewDetails from "./completedViewDetails";

const   VerificationHistoryVehicle = ({ closeModal, rowData }) => {
    const [showReturnItem, setItem] = useState();
    const [showIndex, setIndex] = useState();
    const [openFA, setOpenFA] = useState(false);
    const [openReqUpdate, setOpenReqUpdate] = useState(false);
    const [openSpecs, setOpenSpecs] = useState();
    const [viewDetails, setViewDetails] = useState();

    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "1150px", height: "425px", padding: '15px' }}
            >
                <div className="header" style={{marginBottom:"34px"}}>
                    <h3>View Details</h3>
                </div>

                <div
                    className="body"
                    style={{ width: '100%', display: "flex", fontSize: "14px", marginTop: "-36px" }}
                >

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Request ID: {rowData?.requestID}<span style={{ color: "#0a18a3" }}></span></span>
                        </div>


                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Work Order ID.: {rowData?.orderID}<span style={{ color: "#0a18a3" }}></span></span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested Category: {rowData?.requestCategory}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Request Type: {rowData?.requestType}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested By: {rowData?.requestedBy}</span>
                        </div>

                    </section>

                    <section style={{ width: '100%', overflow: "scroll" }}>
                        <div style={{ width: '100%' }} id='POVendorHeading'>
                            <div style={{ width: '4%' }}>S.No.</div>
                            <div style={{ width: '8%' }}>Category</div>
                            <div style={{ width: '8%' }}>Model</div>
                            <div style={{ width: '8%' }}>Variant</div>
                            <div style={{ width: '8%' }}>Type</div>
                            <div style={{ width: '8%' }}>Color</div>
                            <div style={{ width: '8%' }}>VIN</div>
                            <div style={{ width: '8%' }}>BIN</div>
                            <div style={{ width: '8%' }}>Status</div>
                            <div style={{ width: '8%' }}>Failed Reason</div>
                            <div style={{ width: '12%' }}>Last Action</div>
                            <div style={{ width: '12%' }}>Action Date</div>
                        </div>
                        <div>
                            {rowData?.vehicles.map((item, index) => (
                                <div id='POVendorContent' style={{ width: "100%", borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }}>
                                    <div style={{ width: '4%', overflowX:"scroll" }}>{index + 1}</div>
                                    <div style={{ width: '8%', overflowX:"scroll" }}>{item?.vehicleCategory}</div>
                                    <div style={{ width: '8%', overflowX:"scroll" }}>{item?.vehicleModel}</div>
                                    <div style={{ width: '8%', overflowX:"scroll" }}>{item?.vehicleVariant}</div>
                                    <div style={{ width: '8%', overflowX:"scroll" }}>{item?.vehicleType}</div>
                                    <div style={{ width: '8%', overflowX:"scroll" }}>{item?.vehicleColor}</div>
                                    <div style={{ width: '8%', overflowX:"scroll" }}>{item?.VIN}</div>
                                    <div style={{ width: '8%', overflowX:"scroll" }}>{item?.BIN}</div>
                                    <div style={{ width: '8%', overflowX:"scroll" }}>{item?.status}</div>
                                    <div style={{ width: '8%', overflowX:"scroll" }}>{item?.requiredQuantity}</div>
                                    <div style={{ width: '12%', overflowX:"scroll" }}>{item?.lastAction}</div>
                                    <div style={{ width: '12%', overflowX:"scroll" }}>{moment(item?.lastActionDate).format("DD-MMM-YYYY/HH:mm")}</div>
                                </div>
                            ))}
                        </div>
                    </section>

                </div>

                <div className="footer">
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
    );
};

export default VerificationHistoryVehicle;   