import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisH
} from "@fortawesome/free-solid-svg-icons";
import '../../../quality/style/Details.scss'
import moment from "moment";

const ViewRejectedDetails = ({closeModal,rowData}) => {
    console.log(rowData)
    const [status, setStatus] = useState("");

    function on() {
        document.getElementById("overlay").style.display = "block";
      };
    
      function off() {
        document.getElementById("overlay").style.display = "none";
        setOpen(false);
      }
    
      const actionHandler = () => {
        setOpen(true);
        on();
      };

    return (
        <div className="container1">
        <div id="overlay" onClick={(e) => off(e)}></div>
            <div
                className="modalContainer"
                style={{ width: "600px", height: "300px", padding: '15px' }}
            >
                <div className="header">
                    <h3>Request Details</h3>
                </div>

                <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Request ID : {rowData.requestID}</span>
                            <span>Request Category : {rowData.requestCategory}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Request By : {rowData.requestedBy}</span>
                            <span>Request Type : {rowData.requestType}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Request Date : {moment(rowData.requestDate).format("DD-MM-YYYY, HH:mm")}</span>
                        </div>
                    </section>

                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >
            
                    <section id="rejectedDiv" style={{ width: '100%' }}>
                       <div>
                       <p className="rejectDetails" ><label>Rejected Date : </label>&nbsp;{moment(rowData.actionDate).format("DD-MM-YYYY, HH:mm")}</p>
                       <p className="rejectDetails" ><label>Rejected Reason : </label>&nbsp;{rowData.rejectreason}</p>
                       </div>
                    </section>
                </div>
                
               

                <div className="footer">
                    <button
                        style={{
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                            padding: "5px 15px",
                            marginRight: "10px",
                            color: "#0A18A3",
                        }}
                        onClick={() => closeModal(false)}
                    >
                        <KeyboardBackspace style={{ fontSize: "17px" }} />
                        Back
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ViewRejectedDetails;