import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../work-order/workorder.scss";
import { isArray } from "lodash";
import { KeyboardBackspace } from "@material-ui/icons";
import ServiceView from "./ServicesView";

const WoInProgressViewAssembly = ({ close, assemblydata }) => {
  const [viewServices, setViewServices] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [propData, setPropData] = useState({});

  const fetchReport = async () => {
    let body = {
      orderID: assemblydata?.orderID,
      status: assemblydata?.status,
    };
    const resp = await trackPromise(
      API.post(
        `shop-floor-module/api/v1/shopFloor/getRequirementItemType`,
        body
      )
    );
    console.log(resp);
    setUsersData(resp.data);
    999;
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const clickHandler = (item) => {
    setViewServices(true);
    setPropData(item);
  };

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "636px", height: "350px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Required Assembly</h3>
            {/* <div>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Print 
          </button>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Export
          </button>
          </div> */}
          </div>

          {/* <div className="body" style={{height:"340px"}}>  */}
          {/* <div style={{width:"100%",overflow:"auto",height:"325px"}}> */}

          {viewServices && (
            <ServiceView rowData={propData} close={setViewServices} />
          )}
          <div className="shopfloortbl" style={{ width: "100%" }}>
            <table className="sfaction_tbl" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      width: "8%",
                      fontSize: "small",
                      fontWeight: "bold",
                    }}
                  >
                    S.No.
                  </th>
                  <th style={{ fontSize: "small", fontWeight: "bold" }}>
                    Assembly Type
                  </th>
                  <th style={{ fontSize: "small", fontWeight: "bold" }}>
                    Assembly Name
                  </th>
                  <th style={{ fontSize: "small", fontWeight: "bold" }}>
                    Service Name
                  </th>
                </tr>
              </thead>
              {Array?.isArray(UsersData) &&
                UsersData?.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td
                          style={{ width: "8%", fontSize: "small" }}
                          className="leftcell"
                        >
                          {index + 1}
                        </td>
                        <td style={{ fontSize: "small" }}>
                          {item?.assemblyType}
                        </td>
                        <td style={{ fontSize: "small" }}>
                          {item?.assemblyName}
                        </td>
                        <td style={{ fontSize: "small" }} className="rightcell">
                          <button
                            onClick={() => clickHandler(item)}
                            className="action-btn"
                            style={{ color: "blue" }}
                          >
                            View Services
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </table>
          </div>

          {/* </div> */}
          {/* </div> */}

          <br />
          <div className="footer">
            <button onClick={() => close(false)}>
              <KeyboardBackspace />
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WoInProgressViewAssembly;
