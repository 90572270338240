import React, { useState } from 'react';
import ManageZones from './manageZones';
import ManageAreas from './mangeAreas';
import './zones.scss';

function ManageZonesTab({inDashboard}) {
  const [openManageAreas, setManageAreas] = useState(false);

  return (
    <>
      <section style={{ display: "flex" }}>
        <span>
          <input onChange={() => setManageAreas(false)} value="add Zones" id="addZones" checked={openManageAreas === false ? true : false} className="zoneCheckbox" type="radio" name="zones" />
          <label style={{ marginLeft: "5px" }}>Manage Zones/Regions</label>
        </span>
        <span style={{ marginLeft: "9%" }}>
          <input onChange={() => setManageAreas(true)} value="add Areas" id="addAreas" className="zoneCheckbox" type="radio" name="zones" />
          <label style={{ marginLeft: "5px" }}>Manage Areas</label>
        </span>
      </section>
      <div id="add-user-from" className="setting-form" >
        {openManageAreas ? <ManageAreas /> : <ManageZones inDashboard={inDashboard}/>}
      </div>
    </>
  )
}

export default ManageZonesTab