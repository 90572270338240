import React from "react";
import"../../work-order/workorder.scss"
const ViewComponentSNo = ({ close, rowdata }) => {
  console.log(rowdata)
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "577px", height: "500px", padding: "15px",position:"relative"}}
      >
        <div className="header">
          <h3>Components S No.</h3>
        </div>

        <div className="body" style={{height:"300px"}}>
        <div
        style={{
            width:"100%",
          display: "flex",
          flexDirection: "column",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Component Name: {rowdata.componentName}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Component SKU Code : {rowdata.skuCode}</span>
          <span>Returned Qty :{rowdata.returnedQuantity} nos.</span>
        </div>
      </div>

      <div style={{overflow:"auto",height:"290px",marginTop:"20px"}}>
          <table style={{width:"100%"}} > 
          <thead>
            <tr>
                <th style={{width:"15%",position:"sticky",top:"0"}}>S.No.</th>
                <th style={{position:"sticky",top:"0"}}>Component S.No.</th>
            </tr>
            </thead>
            {rowdata.componentSno.map((item,index) => {
              return(
            <tr>
                <td>{index+1}</td>
                <td>{item}</td>
            </tr>
              )
            })}
            </table>        
            </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => close(false)}>Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewComponentSNo;