import React from 'react';
import moment from "moment";
import { CheckCircle, LocalShipping } from "@material-ui/icons";
import "../../outward.scss";

const TrackOrder = ({ closeModal, rowData }) => {
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "615px", height: "505px", padding: "15px" }}
      >
        <div className="header">
          <h3>Track Order</h3>
        </div>

        <section
          style={{
            width: '100%',
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
            margin: "10px 0"
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
            <span>RP Invoice No: {rowData.RPI}</span>
            <span>Vendor Name: {rowData.vendorName}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
            <span>RP Invoice Date: {moment(rowData.RPIDate).format("DD-MMM-YYYY/HH:mm")}</span>
            <span>Vendor Location: {rowData.vendorLocation}</span>
          </div>
        </section>

        <div style={{ height: '400px', overflow: 'scroll', display: "flex", flexDirection: "column", marginTop: "20px" }}>
          {rowData.history.map((item) => {
            return (
              <>
                <div style={{ display: "flex" }}>
                  <div className='trackorderlist'> <LocalShipping style={{ color: "#0a18a3", marginTop: "7px" }} /></div>
                  <div className='trackordertext'>
                    <span>{item.date}</span>
                    <span>{item.deliveryPersonName}</span>
                    <span>{item.deliveryPersonContactNo}</span>
                  </div>
                </div><div className='trackordergap'></div>
              </>
            )
          })}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Back</button>
        </div>
      </div>
    </div>
  )
}

export default TrackOrder