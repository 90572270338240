import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEdit } from "@fortawesome/free-solid-svg-icons";
import "../bom.scss";
import "../../../components/modal/modal.scss";
import EditBomComponents from "./edit-bomcomponent";
import EditBomLabour from "./edit-bomlabour";
import EditBomAssembly from "./edit-bomassembly";
import EditBomBattery from "./edit-bomBattery";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";

const EditBom = ({ updatedData, editData, closeModal, setActionShow }) => {
  console.log(editData);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [UsersData, setUsersData] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [blankForm, setBlankForm] = useState({
    BOMID: editData.BOMID,
    bomName: editData.bomName,
    bomLocation: editData.bomLocation,
    bomTypes: editData.bomTypes,
    bomVersion: editData.bomVersion,
    bomLevel: editData.bomLevel,
    vehicleSkuCode: editData.vehicleSkuCode,
    vehicleColor: editData.vehicleColor,
    vehicleSegment: editData.vehicleSegment,
    vehicleCategory: editData.vehicleCategory,
    vehicleType: editData.vehicleType,
    vehicleModel: editData.vehicleModel,
    vehicleVariant: editData.vehicleVariant,
    drawingUplaod: editData.drawingUplaod,
    bomComponents: editData.bomComponents,
    bomLabor: editData.bomLabor,
    bomAssembly: editData.bomAssembly,
    bomBattery: editData.bomBattery,
  });
  const validateInputs = () => {
    if (
      !blankForm.bomName ||
      !blankForm.bomLocation ||
      !blankForm.bomTypes ||
      !blankForm.bomVersion ||
      !blankForm.bomLevel ||
      !blankForm.vehicleSkuCode ||
      blankForm.bomComponents.length == 0 ||
      blankForm.bomLabor.length == 0 ||
      blankForm.bomAssembly.length == 0
    ) {
      errorNotification("All fields are required.");
      return false;
    }

    if (
      /^[0-9]+$/.test(
        blankForm.bomName ||
          blankForm.bomLocation ||
          blankForm.bomTypes ||
          blankForm.bomVersion ||
          blankForm.bomLevel ||
          blankForm.vehicleSkuCode
      )
    ) {
      errorNotification("Only numeric values are not allowed.");
      return false;
    }
    return true;
  };
  const componentsData = (data) => {
    blankForm.bomComponents = data;
  };
  const componentsDataBattery = (data) => {
    blankForm.bomBattery = data;
  };
  const labourData = (data) => {
    blankForm.bomLabor = data;
  };
  const assemblyData = (data) => {
    console.log(data);
    blankForm.bomAssembly = data;
  };

  const handleInputChange = (e) => {
    if (e.target.name == "vehicleSkuCode") {
      const vehicle = vehiclesData.filter(
        (item) => item.vehicleSkuCode == e.target.value
      );
      setSelectedVehicle(vehicle[0]);
    }
    setBlankForm({ ...blankForm, [e.target.name]: e.target.value });
  };

  const editDetails = blankForm;

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    if (!validateInputs()) {
      return;
    }
    console.log(blankForm);
    const resp = await trackPromise(
      API.put(`production-module/api/v1/bom`, editDetails)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      setActionShow(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const fetchReport = async () => {
    const resp = await trackPromise(API.get(`production-module/api/v1/bom`));
    console.log(resp);
    setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const fetchVehicleData = async () => {
    const resp = await trackPromise(
      API.get(`production-module/api/v1/vehicles`)
    );
    {
      resp.success == false
        ? errorNotification(resp.message, { autoClose: 3000 })
        : setVehiclesData(resp);
    }
  };
  useEffect(() => {
    fetchVehicleData();
  }, []);

  const uniqueBomLocation = [
    ...new Set(UsersData.map((item) => item.bomLocation)),
  ];
  const uniqueBomLevel = [...new Set(UsersData.map((item) => item.bomLevel))];

  const closeHandler = () => {
    setActionShow(false);
    closeModal(false);
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1150px", height: "500px" }}
      >
        <div className="header">
          <h3>Edit BOM details</h3>
        </div>

        <div className="body" style={{ height: "420px", overflow: "auto" }}>
          <div id="add-user-from" className="setting-form">
            <div
              className="bomedit"
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign: "start",
                  }}
                >
                  BOM Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="assemblyname"
                  type="text"
                  name="bomName"
                  value={blankForm.bomName}
                  style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                  placeholder="BOM Name"
                  autoComplete="off"
                  onChange={(event) => handleInputChange(event)}
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign: "start",
                  }}
                >
                  BOM Location<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  name="bomLocation"
                  value={blankForm.bomLocation}
                  onChange={(event) => handleInputChange(event)}
                  style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                >
                  <option disabled selected>
                    BOM Location
                  </option>
                  {uniqueBomLocation.map((item) => (
                    <option>{item}</option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign: "start",
                  }}
                >
                  BOM Types<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  name="bomTypes"
                  value={blankForm.bomTypes}
                  onChange={(event) => handleInputChange(event)}
                  style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                >
                  <option disabled selected>
                    BOM Types
                  </option>
                  {UsersData.map((item) => (
                    <option>{item.bomTypes}</option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign: "start",
                  }}
                >
                  BOM Version<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="drpdwnbox"
                  type="text"
                  name="bomVersion"
                  defaultValue={blankForm.bomVersion}
                  style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                  placeholder="BOM Version"
                  autoComplete="off"
                  onChange={(event) => handleInputChange(event)}
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign: "start",
                  }}
                >
                  BOM Level<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  name="bomLevel"
                  className="drpdwnbox"
                  value={blankForm.bomLevel}
                  onChange={(event) => handleInputChange(event)}
                  style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                >
                  <option disabled selected>
                    BOM Level
                  </option>
                  {uniqueBomLevel.map((item) => (
                    <option>{item}</option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign: "start",
                  }}
                >
                  Vehicle SKU Code<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  disabled
                  name="vehicleSkuCode"
                  className="drpdwnbox"
                  value={blankForm.vehicleSkuCode}
                  onChange={(event) => handleInputChange(event)}
                  style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                >
                  {vehiclesData.map((item) => (
                    <option>{item.vehicleSkuCode}</option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign: "start",
                  }}
                >
                  Vehicle Color
                </label>
                <input
                  disabled
                  className="drpdwnbox"
                  type="text"
                  name="vehicleColor"
                  value={blankForm.vehicleColor}
                  style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                  placeholder="Vehicle Color"
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign: "start",
                  }}
                >
                  Vehicle Segment
                </label>
                <input
                  disabled
                  className="drpdwnbox"
                  type="text"
                  name="vehicleSegment"
                  value={blankForm.vehicleSegment}
                  style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                  placeholder="Vehicle Segment"
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign: "start",
                  }}
                >
                  Vehicle Category
                </label>
                <input
                  disabled
                  className="drpdwnbox"
                  type="text"
                  name="vehicleCategory"
                  value={blankForm.vehicleCategory}
                  style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                  placeholder="vehicle Category"
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign: "start",
                  }}
                >
                  Vehicle Type
                </label>
                <input
                  disabled
                  className="drpdwnbox"
                  type="text"
                  name="vehicleType"
                  value={blankForm.vehicleType}
                  style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                  placeholder="vehicle Type"
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign: "start",
                  }}
                >
                  Vehicle Model<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  disabled
                  name="vehicleModel"
                  className="drpdwnbox"
                  value={blankForm.vehicleModel}
                  onChange={(event) => handleInputChange(event)}
                  style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                >
                  <option disabled selected>
                    Vehicle Model
                  </option>
                  {UsersData.map((item) => (
                    <option>{item.vehicleModel}</option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign: "start",
                  }}
                >
                  Vehicle Variant
                </label>
                <select
                  disabled
                  name="vehicleVariant"
                  className="drpdwnbox"
                  value={blankForm.vehicleVariant}
                  onChange={(event) => handleInputChange(event)}
                  style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                >
                  <option disabled selected>
                    Vehicle Variant
                  </option>
                  {UsersData.map((item) => (
                    <option>{item.vehicleVariant}</option>
                  ))}
                </select>
              </div>

              <div className="bomaccordian">
                <EditBomComponents
                  componentsData={componentsData}
                  bomComdata={editData.bomComponents}
                />
                <br />
                <EditBomLabour
                  labourData={labourData}
                  bomLabData={editData.bomLabor}
                />
                <br />
                <EditBomAssembly
                  assemblyData={assemblyData}
                  bomAssData={editData.bomAssembly}
                />
                <br />
                {!!(blankForm.vehicleCategory == "Hybrid") && (
                  <EditBomBattery
                    componentsData={componentsDataBattery}
                    bomComdata={editData.bomBattery}
                  />
                )}
              </div>
            </div>
            <br />
            <div className="save-cancel">
              <div className="cust-reset  cancel-button" onClick={closeHandler}>
                <button type="button" className="btn btn-icon-label">
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#0A18A3",
                      fontWeight: "500",
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} /> Cancel
                  </div>
                </button>
              </div>
              <div className=" cancel-button">
                <button
                  className="btn btn-icon-label"
                  type="submit"
                  onClick={submitHandler}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#0A18A3",
                      fontWeight: "500",
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Update
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBom;
