import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";

const ApproveSPP = ({closeModal,selectedrows,updatedData,close,requestCategory, requestID}) => {
console.log(selectedrows);

const arr = selectedrows.map((value)=>{
  return {
    skuCode: value.skuCode,
  }
})
console.log(arr);
console.log(requestID)
const editDetails = {
  status: "Approve",
  requestID: requestID,
  spareDetails: arr,
};

console.log(requestCategory)

   const refresh = () => {
    updatedData();
   }

   const modificationData = {
         requestID:requestID,
         skuCode:arr[0].skuCode
   }

   console.log(modificationData)

  const submitHandler = async () => {

    if(requestCategory=="New Request"){
      
    const resp = await trackPromise(API.put(`management/api/v1/approved/status`, editDetails));
    const { success,message} = resp;
    
    if (success) {
        closeModal(false);
        refresh()
        close(false)
        successNotification(message, { autoClose: 3000 });
     
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
    }else{
      
    const resp = await trackPromise(API.put(`management/api/v1/modificationApprove`, modificationData));
    const { success,message} = resp;
    
    if (success) {
        closeModal(false);
        refresh()
        close(false)
        successNotification(message, { autoClose: 3000 });
     
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
    } 
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{width:"410px",height:"210px"}}>
        <div className="body" style={{flexDirection:"column",marginTop:"58px",fontSize:"18px",textAlign:"center"}}>
          <span>Are you sure to approve this ?</span>
        </div>
        <div className="footer" style={{justifyContent:"center",marginBottom:"16px"}}>
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={()=>submitHandler()}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApproveSPP;
