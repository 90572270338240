import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import AddSalesUser from "./Component/AddSalesUser";
// import ManageSalesUser from "./Component/ManageSalesUser";
// import RequestUpdated from "./Component/RequestUpdated";
// import UserHistorySales from "./Component/UserHistorySales";

const AddSalesUser = React.lazy(()=>import("./Component/AddSalesUser"))
const ManageSalesUser = React.lazy(()=>import("./Component/ManageSalesUser"))
const RequestUpdated = React.lazy(()=>import("./Component/RequestUpdated"))
const UserHistorySales = React.lazy(()=>import("./Component/UserHistorySales"))


const tabs = [
  {
    id: "OPM2T1",
    label: TabLabels.ADD_SALES_USER,
    Component: AddSalesUser,
  },
  {
    id: "OPM2T2",
    label: TabLabels.MANAGE_SALES_USERS,
    Component: ManageSalesUser,
  },
  {
    id: "OPM2T1",
    label: TabLabels.REQUESTED_UPDATES,
    Component: RequestUpdated,
  },
  {
    id: "OPM2T2",
    label: TabLabels.USER_HISTORY_SALES,
    Component: UserHistorySales,
  }
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

const SalesUser = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default SalesUser;
