import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../components/modal/modal.scss";
import SearchBox from "../../components/search/SearchBox";
import ManageOrdersAction from "./action/ManageOrdersAction";
import OrderDetails from "./action/OrderDetails";
import moment from "moment";
import handleExport from "../../utils/exportExcel";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import PurchaseOrderTemplateInvoice from "./PurchaseOrderTemplateInvoice";
import POViewDetails from "../po-request/components/actions/POViewDetails";
import AppConstants from "../../utils/app-constants";

const ManageOrders = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState();
  const [isOpen, setIsOpen] = useState(true);
  const [poOpen, setPoOpen] = useState(false);
  const [indexTracker, setIndexTracker] = useState();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [poActionShow, setPoActionShow] = useState(false);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `procurement-module/api/v1/orders?BOMID=${formValue.BOMID
        }&bomLocation=${formValue.bomLocation}&vendorName=${formValue.vendorName
        }&POID=${formValue.POID.substring(1)}&status=${formValue.status
        }&poDate=${formValue.orderDate}`
      )
    );
    console.log("resp", resp);

    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setOrderData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const statusFormatter = (cell, row, rowIndex) => {
    return cell === "Approved By Finance" ? (
      <button className="active-btn">
        <span className="active-btn-font">Approved By Finance</span>
      </button>
    ) : cell === "On Hold By Finance" ? (
      <button className="deactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="deactive-btn-font">On Hold By Finance</span>
      </button>
    ) : cell === "Partially Closed" ? (
      <button className="deactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="deactive-btn-font">Partially Closed</span>
      </button>
    ) : cell === "Pending At Finance" ? (
      <button className="pendingByProc-btn" style={{ width: "100%" }}>
        <span className="pendingByProc-btn-font">Pending At Finance</span>
      </button>
    ) : cell === "Placed To Vendor" ? (
      <button className="reactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="reactive-btn-font">Placed To Vendor</span>
      </button>
    ) : cell === "GRN Created" ? (
      <button
        className="active-btn"
        style={{ width: "100%", backgroundColor: "#d6f5f5", color: "#2eb8b8" }}
      >
        {" "}
        <span className="active-btn-font">GRN Created</span>
      </button>
    ) : cell === "Fully Closed" ? (
      <button className="active-btn" style={{ width: "100%" }}>
        {" "}
        <span className="active-btn-font">Fully Closed</span>
      </button>
    ) : cell === "Rejected By Finance" ? (
      <button className="delete-btn" style={{ width: "100%" }}>
        {" "}
        <span className="delete-btn-font">Rejected By Finance</span>
      </button>
    ) : (
      cell
    );
  };

  const detailsHandler = (rowIndex) => {
    setIndexTracker(rowIndex);
    setIsDetailsOpen(true);
  };

  const orderFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "14px",
            color: "blue",
            cursor: "pointer",
            marginBottom:"0"
          }}
          onClick={() => detailsHandler(rowIndex)}
        >
          View Details
        </p>
        {indexTracker === rowIndex && isDetailsOpen ? (
          <OrderDetails row={row} closeModal={setIsDetailsOpen} />
        ) : null}
      </div>
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon id="outside" icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && open ? (
          <ManageOrdersAction rowData={row} rowIndex={rowIndex} setOpen={setOpen}/>
        ) : null}
      </div>
    );
  };

  const poHandler = (rowIndex) => {
    setPoActionShow(rowIndex);
    setPoOpen(true);
  };

  const poFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{ color: "blue", cursor: "pointer",marginBottom:"0" }}
          onClick={() => poHandler(rowIndex)}
        >
          {row.POID}
        </p>
        {poActionShow === rowIndex && poOpen ? (
          <POViewDetails data={row} closeModal={setPoOpen} />
        ) : null}
      </div>
    );
  };

  const [formValue, setFormValue] = useState({
    BOMID: "",
    bomLocation: "",
    POID: "",
    vendorName: "",
    status: "",
    orderDate: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      BOMID: "",
      bomLocation: "",
      POID: "",
      vendorName: "",
      status: "",
      orderDate: "",
    });
    const resp = await trackPromise(
      API.get(`procurement-module/api/v1/orders`)
    );
    setOrderData(resp);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  function orderDateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY | HH:mm")}`;
  }

  const bomID = [...new Set(orderData.map((item) => item.BOMID))];
  const bLocation = [...new Set(orderData.map((item) => item.bomLocation))];
  const poID = [...new Set(orderData.map((item) => item.POID))];
  const vName = [...new Set(orderData.map((item) => item.vendorName))];

  console.log("f", bomID);

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          orderData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "BOM ID",
      dataField: "BOMID",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Location",
      dataField: "bomLocation",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "PO Number",
      dataField: "POID",
      headerAttrs: { width: "130px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { poOpen, poActionShow },
      formatter: poFormatter,
    },
    {
      text: "Vendor Name",
      dataField: "vendorName",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    // {
    //   text: "Order Details",
    //   dataField: "components",
    //   headerAttrs: { width: "150px" },
    //   headerStyle: {
    //     fontWeight: "500",
    //     borderTop: "1px solid rgba(0,0,0,0.2)",
    //   },
    //   formatExtraData: { isDetailsOpen, indexTracker },
    //   formatter: orderFormatter,
    // },
    {
      text: "Order By",
      dataField: "requestedBy",
      headerAttrs: { width: "230px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "PO Generated ON",
      dataField: "PODate",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: orderDateFormatter,
    },
    {
      text: "PO Approved/Rejected ON",
      dataField: "approveDate",
      headerAttrs: { width: "230px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex) => {
        return row.status == "Rejected By Finance"
          ? moment(row?.rejectDate).format("DD-MMM-YYYY | HH:mm")
          : row.status == "Approved By Finance"
            ? moment(row?.approveDate).format("DD-MMM-YYYY | HH:mm")
            : row.status == "On Hold By Finance"
              ? moment(row?.onholdDate).format("DD-MMM-YYYY | HH:mm")
              : "-";
      },
    },
    {
      text: "Reason",
      dataField: "reason",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex) => {
        return row.status == "Rejected By Finance"
          ? row?.rejectReason
          : row.status == "Approved By Finance"
            ? row?.approveRemarks
            : row.status == "On Hold By Finance"
              ? row?.onholdReason
              : "-";
      },
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    // {
    //   text: "Pending Qty.",
    //   dataField: "pendingQuantity",
    //   headerAttrs: { width: "130px" },
    //   headerStyle: {
    //     fontWeight: "500",
    //     borderTop: "1px solid rgba(0,0,0,0.2)",
    //   },
    // },
    {
      text: "Action Date & Time",
      dataField: "actionDate",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: orderDateFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "100px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  // let uniqueComponentNameArray;
  // orderData.map((item) => {
  //   uniqueComponentNameArray = [
  //     ...new Map(
  //       item.components.map((item) => [item["componentName"], item])
  //     ).values(),
  //   ];
  // });
  // let uniqueSkuCodeArray;
  // orderData.map((item) => {
  //   uniqueSkuCodeArray = [
  //     ...new Map(
  //       item.components.map((item) => [item["skuCode"], item])
  //     ).values(),
  //   ];
  // });
  // let uniquePartNameArray;
  // orderData.map((item) => {
  //   uniquePartNameArray = [
  //     ...new Map(
  //       item.components.map((item) => [item["vendorPartName"], item])
  //     ).values(),
  //   ];
  // });
  // let uniquePartCodeArray;
  // orderData.map((item) => {
  //   uniquePartCodeArray = [
  //     ...new Map(
  //       item.components.map((item) => [item["vendorPartCode"], item])
  //     ).values(),
  //   ];
  // });
  let uniqueStatusArray = [
    ...new Map(orderData.map((item) => [item["status"], item])).values(),
  ];

  const refresh = () => {
    clearFilter();
    setShowFilter(!showFilter);
    fetchReport();
  };

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Orders</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={orderData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(orderData, "Manage Orders")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    onSubmit={submitHandler}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      height: "145px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                    }}
                    className="UHinputfields VMform"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        BOMID
                      </label>
                      <select
                        name="BOMID"
                        value={formValue.BOMID}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          BOMID
                        </option>
                        {bomID.map((item) => {
                          return <option>{item}</option>;
                        })}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        BOM Location
                      </label>
                      <select
                        name="bomLocation"
                        value={formValue.bomLocation}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          BOM Location
                        </option>
                        {bLocation.map((item) => {
                          return <option>{item}</option>;
                        })}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        PO ID
                      </label>
                      <select
                        name="POID"
                        value={formValue.POID}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          PO ID
                        </option>
                        {poID.map((item) => {
                          return <option>{item}</option>;
                        })}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Vendor Name
                      </label>
                      <select
                        name="vendorName"
                        value={formValue.vendorName}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          Vendor Name
                        </option>
                        {vName.map((item) => {
                          return <option>{item}</option>;
                        })}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        {" "}
                        Status
                      </label>
                      <select
                        name="status"
                        value={formValue.status}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          Status
                        </option>
                        {uniqueStatusArray.map((item) => {
                          return <option>{item.status}</option>;
                        })}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Order Date
                      </label>
                      <input
                        type="text"
                        onFocus={(e) => (e.currentTarget.type = "date")}
                        onBlur={(e) => (e.currentTarget.type = "text")}
                        placeholder="Order Date"
                        name="orderDate"
                        value={formValue.orderDate}
                        onChange={handleChange}
                        autoComplete="off"
                        style={{ marginTop: "-5px", width: "100%" }}
                      />
                    </div>
                    <div
                      style={{
                        width: "46%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        type="submit"
                        id="apply"
                        onClick={fetchReport}
                        style={{ marginTop: "23px" }}
                      >
                        Apply
                      </button>
                      <button
                        onClick={clearFilter}
                        id="clear"
                        style={{ marginTop: "23px", width: "100px" }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </form>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManageOrders;
