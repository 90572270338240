import React from "react";
import moment from "moment";
import { errorNotification } from "../../../utils/notifications";
import { KeyboardBackspace } from "@material-ui/icons";
// import "./modal.scss";

const ViewHistory = ({ updatedData, editData, closeModal, tabName }) => {
  // let actionArray = userDetails.action.reverse()
  console.log(editData.action.reverse().map((item)=>item.status))


  return (  
    <div className="container1">

      <div className="modalContainer" style={
        { width: "615px", height: "502px" }}>
        <div className="header">
          <h3>{tabName} User History</h3>
        </div>
        <ol className="progress">
          <>
            {editData === undefined ?
              errorNotification("user Not Performed any action ", { autoClose: 3000 }) :
              editData?.action.reverse().map((item) => {
                if (item?.status === "Delete") {
                  return (
                    <li className="progress-delete" style={{width:"100%", display:"flex", display:"row", textAlign:"start", justifyContent:"space-between"}}>
                      <button style={{width:"20%"}}>
                        <span className="btn-delete">Deletion</span>
                      </button>
                      <span style={{width:"35%", textAlign:"start"}}>Date:&nbsp;{moment(item.actionDate).format("DD-MMM-YYYY, HH:mm")},</span>
                      <span style={{width:"35%", textAlign:"start"}}>Reason: {item.reason}</span>
                    </li>
                  )
                }
                if (item.status === "Reactivate") {
                  return (
                    <li className="progress-reactivate" style={{width:"100%"}}>
                      <button style={{width:"20%", textAlign:"start"}}>
                        <span className="btn-reactivate">Reactivation</span>
                      </button>
                      <span style={{width:"35%", textAlign:"start"}}>Date:&nbsp;{moment(item.actionDate).format("DD-MMM-YYYY, HH:mm")}</span>
                      <span style={{width:"35%", textAlign:"start"}}>Reason: {item.reason}</span>
                    </li>
                  )
                }
                if (item.status === "Deactivate") {
                  return (
                    <li className="progress-deactivate" style={{width:"100%"}}>
                      <button style={{width:"20%", textAlign:"start"}}>
                        <span className="btn-deactivate">Deactivation</span>
                      </button>
                      <span style={{width:"35%", textAlign:"start"}}>Date:&nbsp;{moment(item.actionDate).format("DD-MMM-YYYY, HH:mm")}</span>
                      <span style={{width:"35%", textAlign:"start"}}>Reason: {item.reason}</span>
                    </li>
                  )
                }
                if (editData.status === "Active") {
                  return (
                    <li className="progress-created" style={{width:"100%"}}>
                      <button style={{width:"20%", textAlign:"start"}}>
                        <span className="btn-created">On-Boarding Date</span>
                      </button>
                      <span style={{width:"35%", textAlign:"start"}}>Date:&nbsp;{moment(editData.createdDate).format("DD-MMM-YYYY, HH:mm")}</span>
                      <span style={{width:"35%", textAlign:"start"}}>Reason: {item.reason}</span>
                    </li>
                  )
                }
              })}
          </>
        </ol>
        <div className="footer">
          <button onClick={() => closeModal(false)}> <KeyboardBackspace /> Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewHistory;
