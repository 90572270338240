import { KeyboardBackspace } from "@material-ui/icons";
import React from "react";
import auth from "../../../../utils/auth";

const ViewLabourBom = ({ close, labourdata }) => {
  console.log("labourdata-->", labourdata);

  const totalPrice = () => {
    return labourdata?.reduce((total, item) => {
      return total + (parseInt(item?.avgLaborCost) * parseInt(item?.requiredDuration));
    }, 0) || 0; // Fallback to 0 if labourdata is undefined
  };

  const totalRequiredWorkers = labourdata?.reduce((accumulator, item) => {
    return accumulator + (Number(item?.requiredWorkers) || 0);
  }, 0);

  const totalDuration = labourdata?.reduce((accumulator, item) => {
    return {
      requiredDuration: accumulator.requiredDuration + (Number(item?.requiredDuration) || 0),
    };
  }, { requiredDuration: 0,});



  return (
    <>
      <div className="container1">
        <div className="modalContainer" style={{ width: "770px", height: "540px", padding: "15px" }}>
          <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Bom Labours Details</h3>
            <div>
              {/* Optional buttons can go here */}
            </div>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "column", fontSize: "12px", fontWeight: "450" }}>
            <span>Total Labour Count: {totalRequiredWorkers}</span>
            <span>Total Duration: {totalDuration.requiredDuration} {labourdata[0]?.laborDuration}</span>
            <span>{`Total Cost(${auth.getUserInfo().currencySymbol}): ${totalPrice()}`}</span>
          </div>

          <div className="body" style={{ height: "340px" }}>
            <div style={{ width: "100%", overflow: "auto", height: "325px" }}>
              <table style={{ width: "130%" }} className="viewcomp_tbl">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Labour Category</th>
                    <th>Labour Sub Category</th>
                    <th>Labour Level</th>
                    <th>Req. Labour</th>
                    <th>{`Labour Cost(${auth.getUserInfo().currencySymbol})`}</th>
                    <th>Labour Time</th>
                    <th>{`Sub Total(${auth.getUserInfo().currencySymbol})`}</th>
                  </tr>
                </thead>
                <tbody>
                  {labourdata.map((item, index) => (
                    <tr key={index}>
                      <td className="leftcell">{index + 1}</td>
                      <td>{item?.laborCategory}</td>
                      <td>{item?.laborSubCategory}</td>
                      <td>{item?.laborLevel}</td>
                      <td>{item?.requiredWorkers}</td>
                      <td>{`${item?.avgLaborCost} per ${item?.laborDuration}`}</td>
                      <td>{item?.requiredDuration} {item?.laborDuration}</td>
                      <td className="rightcell">{parseInt(item?.avgLaborCost) * parseInt(item?.requiredDuration)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <section style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', borderRadius: '5px', margin: '5px 0', height: "35px" }}>
            <div style={{ width: '200px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', fontSize: '14px', fontWeight: '500' }}>
              {`Total Price(${auth.getUserInfo().currencySymbol}):`}
              <span style={{ width: '100px', background: '#ebebeb', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {totalPrice()}
              </span>
            </div>
          </section>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}><KeyboardBackspace/> Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewLabourBom;
