import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import '../../../../components/modal/modal.scss';
import { successNotification, errorNotification } from "../../../../utils/notifications";
import auth from "../../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";

const NewRequestOnHold = ({ updatedData, editData, closeModal }) => {
  const [formValue, setFormValue] = useState({
    reason: "",
    description: "",
  });

  const editDetails = {
    "requestID": editData.requisitionID,
    "status": "On Hold",
    "reason": formValue.reason,
    "description": formValue.description
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    console.log(formValue);
    if(formValue.reason){
      const resp = await trackPromise(API.put(`inventory-module/api/v1/inventoryOutward/component/newRequest/onHold`, editDetails));

      const { success, message } = resp;
  
      if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        refresh()
      } else {
        errorNotification(message, { autoclose: 2000 });
      }
    }else{
      errorNotification("Please select reason", { autoclose: 2000 });
    }
   
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Put On Hold</h3>
        </div>

        <div className="body">
          <label>
            <select
              name="reason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                Reason
              </option>
              <option value="Internal Audit">
                Internal Audit
              </option>
              <option value="On-going Maintenance ">
                On-going Maintenance
              </option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.reason === "Others" ?
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({ ...formValue, [e.target.name]: e.target.value })
                }
                placeholder="Reason"
                autoComplete="off"
              />
            </label> : ""}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewRequestOnHold;
