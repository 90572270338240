import { ContactsOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import auth from "../../utils/auth";
// import "../../work-order/workorder.scss";

const BatterySpecification = ({ close, rowData }) => {
  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "700px", height: "550px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Battery Specification</h3>
          </div>
          <br />
          <section
            style={{ display: "flex", flexDirection: "column", width: "100" }}
          >
          </section>
          <br />
          <div className="body" style={{ height: "340px" }}>
            <div style={{ width: "100%", overflow: "auto" }}>
              {/* <span style={{ color: "#0A18A3", float: "left" }}>
                Standard Warranty
              </span> */}

              <table style={{ width: "100%" }}>
                {/* <tr>
                  <td
                    style={{
                      background: "#efefefe6",
                      width: "40%",
                      textAlign: "left",
                    }}
                  >
                    Distance
                  </td>
                  <td>
                    {rowData.standardWarranty.warrantyDistance}/
                    {rowData?.standardWarranty?.warrantyDistanceUnit}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      background: "#efefefe6",
                      width: "40%",
                      textAlign: "left",
                    }}
                  >
                    Duration
                  </td>
                  <td>
                    {rowData.standardWarranty.warrantyDuration +
                      "/" +
                      rowData.standardWarranty.warrantyDurationUnit}
                  </td>
                </tr> */}
              </table>
            </div>
            {/* <span
              style={{ color: "#0A18A3", float: "left", marginTop: "10px" }}
            >
              Extended Warranty
            </span> */}

            {/* {rowData.extendedWarranty.map((item) => {
              return (
                <>
                  <table style={{ width: "100%", marginTop: "10px" }}>
                    <tr>
                      <td
                        style={{
                          background: "#efefefe6",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        Distance
                      </td>
                      <td>
                        {item.warrantyDistance}/{item?.warrantyDistanceUnit}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          background: "#efefefe6",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        Duration
                      </td>
                      <td>
                        {item.warrantyDuration +
                          "/" +
                          item.warrantyDurationUnit}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          background: "#efefefe6",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        Cost ({auth.getUserInfo().currencySymbol})
                      </td>
                      <td>{item.warrantyCharges}</td>
                    </tr>
                  </table>
                  <hr />
                </>
              );
            })} */}
          </div>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}>
              <KeyboardBackspace /> Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BatterySpecification;
