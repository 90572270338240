import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";

const OnHoldSPP = ({
  closeModal,
  selectedrows,
  updatedData,
  close,
  requestCategory,
  requestID
}) => {
  const [formValue, setFormValue] = useState({
    onholdReason: "",
    description: "",
  });
  console.log(requestID)
  const arr = selectedrows.map((value) => {
    return {
      skuCode: selectedrows[0].skuCode,
      reason: formValue.onholdReason,
      ...(formValue.onholdReason === "Other" && {
        reasonDescription: formValue.description,
      }),
    };
  });
  console.log(arr);
  const editDetails = {
    status: "On Hold By Management",
    requestID: selectedrows[0].requestID,
    spareDetails: arr,
  };

  const refresh = () => {
    updatedData();
  };

  const modificationData = {
    requestID: requestID,
    skuCode: arr[0].skuCode,
    reason: formValue.onholdReason,
    ...(formValue.onholdReason === "Other" && {
      reasonDescription: formValue.description,
    }),
  };

  console.log(modificationData);

  const submitHandler = async () => {
    if (requestCategory == "New Request") {
      const resp = await trackPromise(
        API.put(`management/api/v1/onHold/status`, editDetails)
      );
      const { success, message } = resp;

      if (success) {
        closeModal(false);
        refresh();
        close(false);
        successNotification(message, { autoClose: 3000 });
      } else {
        errorNotification(message, { autoclose: 2000 });
      }
    } else {
      const resp = await trackPromise(
        API.put(`management/api/v1/modifiedOnHold`, modificationData)
      );
      const { success, message } = resp;

      if (success) {
        closeModal(false);
        refresh();
        close(false);
        successNotification(message, { autoClose: 3000 });
      } else {
        errorNotification(message, { autoclose: 2000 });
      }
    }
  };
  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Put On Hold</h3>
        </div>
        <div className="body">
          <label>
            <select
              name="onholdReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                On Hold Reason*
              </option>
              <option value="Misconduct">Misconduct</option>
              <option value="Not an Active User">Not an Active User</option>
              <option value="Change of Role">Change of Role</option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.onholdReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="On Hold Reason*"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnHoldSPP;
