import React, { useEffect, useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import auth from "../../utils/auth";
// import "../../work-order/workorder.scss";

const ViewWarrantyShowroom = ({ close, vehicleSkuCode }) => {

    const [rowData, setRowData] = useState({});

    const fetchReport = async () => {
        const resp = await trackPromise(
            API.get(
                `showroom-module/api/v1/inventory/vehicleInvenrtory/warranty?vehicleSkuCode=${vehicleSkuCode}`
            )
        );
        console.log(resp);
        setRowData({ data: resp });
    };
    useEffect(() => {
        fetchReport();
    }, []);

    return (
        <>
            {console.log(rowData)}
            <div className="container1">
                <div
                    className="modalContainer"
                    style={{ width: "700px", height: "550px", padding: "15px" }}
                >
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>View Warranty Details</h3>
                        {/* <div>
                            <button
                                className="action-btn"
                                style={{ color: "blue" }}>
                                Print
                            </button>
                            <button
                                className="action-btn"
                                style={{ color: "blue" }}>
                                Export
                            </button>
                        </div> */}
                    </div>
                    <br />
                    <section style={{ display: "flex", flexDirection: "column", width: "100" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <span>SKU Code: {rowData?.data?.vehicleSkuCode}</span>
                            <span>Vehicle Model: {rowData?.data?.vehicleModel}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <span>Segment: {rowData?.data?.vehicleSegment}</span>
                            <span>Vehicle Variant: {rowData?.data?.vehicleVariant}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <span>Category: {rowData?.data?.vehicleCategory}</span>
                            <span>Vehicle Type: {rowData?.data?.vehicleType}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <span>Color: {rowData?.data?.vehicleColor}</span>
                        </div>

                    </section>
                    <br />
                    <div className="body" style={{ height: "340px" }}>
                        <div style={{ width: "100%", overflow: "auto" }}>
                            <span style={{ color: "#0A18A3", float: "left" }}>Standard Warranty</span>

                            <table style={{ width: "100%" }}>
                                <tr>
                                    <td style={{ background: "#efefefe6", width: "40%", textAlign: "left" }}>Distance</td>
                                    <td>{rowData?.data?.standardWarranty.warrantyDistance}/{rowData?.standardWarranty?.warrantyDistanceUnit}</td>
                                </tr>
                                <tr>
                                    <td style={{ background: "#efefefe6", width: "40%", textAlign: "left" }}>Duration</td>
                                    <td>{rowData?.data?.standardWarranty.warrantyDuration + "/" + rowData?.data?.standardWarranty.warrantyDurationUnit}</td>
                                </tr>
                                <tr>
                                    <td style={{ background: "#efefefe6", width: "40%", textAlign: "left" }}>Warranty Terms</td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                        <span style={{ color: "#0A18A3", float: "left", marginTop: "10px" }}>Extended Warranty</span>

                        {
                            rowData?.data?.extendedWarranty.map((item) => {
                                return (
                                    <>
                                        <table style={{ width: "100%", marginTop: "10px" }}>
                                            <tr>
                                                <td style={{ background: "#efefefe6", width: "40%", textAlign: "left" }}>Distance</td>
                                                <td>{item.warrantyDistance}/{item?.warrantyDistanceUnit}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "#efefefe6", width: "40%", textAlign: "left" }}>Duration</td>
                                                <td>{item.warrantyDuration + "/" + item.warrantyDurationUnit}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "#efefefe6", width: "40%", textAlign: "left" }}>Cost ({auth.getUserInfo().currencySymbol})</td>
                                                <td>{item.warrantyCharges}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ background: "#efefefe6", width: "40%", textAlign: "left" }}>Warranty Terms</td>
                                                <td></td>
                                            </tr>
                                        </table>
                                        <hr />
                                    </>
                                )
                            })
                        }
                    </div>

                    <br />
                    <div className="footer">
                        <button onClick={() => close(false)}><KeyboardBackspace /> Back</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewWarrantyShowroom;