import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { errorNotification, successNotification } from "../../../utils/notifications";
import {
  CancelOutlined,
  CheckCircle,
} from "@material-ui/icons";

const VR_MovedToFinishedGoods = ({ closeModal, requestID, vehicleID,vehicleSkuCode, goodsData , SNO, vehicleTableRefresh, tabName }) => {

  // const SNO = goodsData?.map((item)=>item?.SNO)

  console.log("requestID-->",requestID)
  console.log("vehicleSkuCode-->",vehicleSkuCode)
  console.log("SNO-->",SNO)

  const goodsSubmitHandler = async () => {
    const rejectionData = {
      "requestID": requestID,
      "vehicleSkuCode": vehicleSkuCode,
      "SNO": SNO
    }
    const resp = await trackPromise(API.post(`inventory-module/api/v1/inventoryGoodsVerification/moveToFinishedGoodsInvVehicles`, rejectionData));
    console.log(resp);
    resp.success == true ? successNotification(resp.message) : errorNotification(resp.message);
    closeModal(false);
    vehicleTableRefresh();
  };

  const vehicleSubmitHandler = async () => {
    const rejectionData = {
      "requestID": requestID,
      "vehicleID": vehicleID
    }
    const resp = await trackPromise(API.post(`inventory-module/api/v1/inventoryGoodsVerification/moveToFinishedGoodsInventory`, rejectionData));
    console.log(resp);
    resp.success == true ? successNotification(resp.message) : errorNotification(resp.message);
    closeModal(false);
    vehicleTableRefresh();
  };

  return (
    <div className='container1'>
      <div className="modalContainer" style={
        { width: '400px', height: '200px' }
      }>

        <div className="body" style={{ justifyContent: 'center', fontSize: '16px' }}>
          Are you sure to move this <br /> vehicle in finished goods?

        </div>
        <div className="footer">
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: 'white',
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            onClick={tabName == "vehicles" ? vehicleSubmitHandler : goodsSubmitHandler}
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>

        </div>
      </div>
    </div>
  )
}

export default VR_MovedToFinishedGoods