import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import Table from "react-bootstrap/Table";
import {
  faCheckCircle,
  faPlus,
  faTimesCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import auth from "../../../utils/auth";
import { width } from "@mui/system";

const ViewJobCard = ({
  UsersData,
  serviceDetails,
  blankForm,
  vehicleSkuCode,
  closeModal,
  additionalChecks,
  additionalSpares,
  blankData,
  checksData,
  serviceHistory,
  rowData,
  setIsOpen
}) => {
  console.log("blankData", blankData);
  console.log("blankForm", blankForm);
  console.log(blankForm);

  const [showData, setShowData] = useState({});

  console.log("showData -->", showData);
  console.log("rowData -->", rowData);

  const subTotalSparesRepl = showData?.data?.customerComplaints?.reduce(
    (sum, item) => {
      const cost = parseFloat(item?.spareCost) || 0;
      return sum + cost;
    },
    0
  );
  const subTotalcomplaints = showData?.data?.customerComplaints?.reduce(
    (sum, item) => {
      const cost = parseFloat(item?.cost) || 0;
      return sum + cost;
    },
    0
  );

  const sumMandatoryInspectionCosts =
    showData?.data?.inspectionsChecks?.mandatoryInspectionChecks?.reduce(
      (total, check) => {
        const checkTotal = check?.inspectionCheck?.reduce((sum, item) => {
          return sum + (parseFloat(item?.cost) || 0);
        }, 0);
        return total + checkTotal;
      },
      0
    );

  const optionalInspectionChecks =
    showData?.data?.inspectionsChecks?.optionalInspectionChecks?.reduce(
      (total, check) => {
        const checkTotal = check?.inspectionCheck?.reduce((sum, item) => {
          return sum + (parseFloat(item?.cost) || 0);
        }, 0);
        return total + checkTotal;
      },
      0
    );

  const sumMandatorySpareCosts =
    showData?.data?.inspectionsChecks?.spares?.reduce((total, spare) => {
      if (spare?.spareType == "Mandatory") {
        return total + (parseFloat(spare?.sparePartMrp) || 0);
      }
      return total;
    }, 0);

  const sumOptionalSpareCosts =
    showData?.data?.inspectionsChecks?.spares?.reduce((total, spare) => {
      if (spare?.spareType == "Optional") {
        return total + (parseFloat(spare?.sparePartMrp) || 0);
      }
      return total;
    }, 0);

  const estimatedTotal =
    subTotalSparesRepl +
    subTotalcomplaints +
    sumMandatoryInspectionCosts +
    optionalInspectionChecks +
    sumMandatorySpareCosts +
    sumOptionalSpareCosts;

  console.log("estimatedTotal-->", estimatedTotal);



  const fetchData = async () => {
    try {
      // Making API request
      const resp = await trackPromise(
        API.get(
          `serviceAdvisor-module/api/v1/serviceJobs/getJobCardDetails?jobID=${rowData?.jobID}`
        )
      );
      setShowData(resp);
      const { success, message } = resp;
      if (success) {
        closeModal(false);
        setIsOpen(false);
        successNotification(message, { autoClose: 3000 });
        refresh();
      } else {
        errorNotification(message, { autoClose: 2000 });
      }
    } catch (error) {
      errorNotification("An error occurred while fetching job details.", {
        autoClose: 2000,
      });
      console.error("Error fetching job details:", error);
    }
  };

  useEffect(() => {
    if (rowData?.jobID) {
      // Check if rowData and jobID are available
      fetchData();
    }
  }, [rowData]); // Include rowData in dependency array if it affects the fetch



  const closeHandler = ()=>{
    setIsOpen(false)
    closeModal(false)
  }





  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          width: "75%",
          height: "600px",
          padding: "15px",
          borderRadius: "0px",
          borderTop: "none",
          justifyContent: "flex-start",
        }}
      >
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "500",
            fontSize: "25px",
          }}
        >
          <h3>Job Card</h3>
          {/* <div>
              <ReactToPrint
                trigger={() => {
                  return (
                    <button
                      style={{
                        color: "#0A18A3",
                        margin: "1px",
                        height: "27px",
                        width: "90px",
                        border: "none",
                      }}
                    >
                      Download
                    </button>
                  );
                }}
                content={() => componentRef}
                documentTitle="Purchase Order Invoice"
                pageStyle="Print"
              />
            </div> */}
        </div>

        <div style={{ overflow: "scroll" }}>
          <div
            style={{
              width: " 99%",
              height: "97%",
              display: "flex",
              margin: "5px",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            // ref={(el) => (componentRef = el)}
          >
            {/* Section 1  */}
            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "45%",
                  borderRight: "1px solid grey",
                  borderBottom: "1px solid grey",
                  display: "flex",
                  flexDirection: "column",
                  textWrap: "wrap",
                  alignItems: "start",
                }}
              >
                <span style={{ fontSize: "14px", fontWeight: "500" }}>
                  { rowData?.serviceCenterName ? (rowData?.serviceCenterName) : "N/A"}
                </span>
                <span style={{ fontSize: "12px", textWrap: "wrap" }}>
                  {rowData?.serviceCenterLocation ? (rowData?.serviceCenterLocation) : "N/A"}
                </span>
                <span style={{ fontSize: "12px" }}>
                  Ph. {rowData?.serviceCenterPhone ? (rowData?.serviceCenterPhone) : "N/A"}
                </span>
                <br />
                <span style={{ fontSize: "14px", fontWeight: "500" }}>
                  Customer Name & Address:
                </span>
                <span style={{ fontSize: "12px" }}>
                  {showData?.data?.customerDetails?.customerName ? (showData?.data?.customerDetails?.customerName): "N/A"}
                </span>
                <span style={{ fontSize: "12px" }}>
                  {showData?.data?.customerDetails?.customerAddress ? (showData?.data?.customerDetails?.customerAddress) : "N/A"}
                </span>
                <span style={{ fontSize: "12px" }}>
                  Ph. {showData?.data?.customerDetails?.customerPhone ? (showData?.data?.customerDetails?.customerPhone) : "N/A"}
                </span>
              </div>
              <div
                style={{
                  width: "55%",
                  borderBottom: "1px solid grey",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    margin: "0",
                    padding: "0",
                    borderBottom: "1px solid grey",
                    justifyContent: "start",
                  }}
                >
                  <span
                    style={{
                      width: "65%",
                      display: "flex",
                      paddingLeft: "4%",
                      flexDirection: "column",
                      // justifyContent: "space-between",
                      textAlign: "start",
                      paddingRight: "2%",
                      fontSize: "small",
                    }}
                  >
                    <span>
                      Job Card No. : {" "}
                      <span style={{ fontWeight: "bold" }}>
                        {showData?.data?.jobID ? (showData?.data?.jobID) : "N/A"}
                      </span>
                    </span>
                    <span>
                      Job Date:{" "}
                      { showData?.data?.jobDate ? (moment(showData?.data?.jobDate).format("DD-MM-YYYY")) : "N/A"}
                    </span>
                  </span>

                  <span style={{ textAlign: "end", width:"40%" }}>
                    <img
                      src="https://i.pinimg.com/originals/5b/ac/94/5bac942d02e70ce67498bf2ff04efe97.png"
                      style={{ height: "50px", width: "60px" }}
                    ></img>
                  </span>
                </div>
                <div style={{ textAlign: "center", fontWeight: "500" }}>
                  Vehicle Details
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "1px solid grey",
                    width: "100%",
                    margin: "0",
                    padding: "2% 5%",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        display: "flex",
                      }}
                    >
                      Reg.No. : {" "}
                      <span style={{ fontWeight: "400" }}>
                        {showData?.data?.vehicleRegNo ? (showData?.data?.vehicleRegNo) : "N/A"}
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        display: "flex",
                      }}
                    >
                      Model : {" "}
                      <span style={{ fontWeight: "400" }}>
                        {showData?.data?.vehicleModel ? (showData?.data?.vehicleModel) :"N/A"}
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        display: "flex",
                      }}
                    >
                      Type : {" "}
                      <span style={{ fontWeight: "400" }}>
                        {showData?.data?.vehicleType ? (showData?.data?.vehicleType) : "N/A"}
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        display: "flex",
                      }}
                    >
                      Date of Sales : {" "}
                      <span style={{ fontWeight: "400" }}>
                        {showData?.data?.invoiceDate
                          ? (moment(showData?.data?.invoiceDate).format(
                              "DD-MM-YYYY"
                            ))
                          : "N/A"}
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        // display: "flex",
                        textAlign: "end",
                      }}
                    >
                      Engine No. : {" "}
                      <span style={{ fontWeight: "400" }}>
                        {showData?.data?.engineNo ? (showData?.data?.engineNo) : "N/A"}
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        // display: "flex",
                        textAlign: "end",
                      }}
                    >
                      Variant : {" "}
                      <span style={{ fontWeight: "400" }}>
                        {showData?.data?.vehicleVariant ? (showData?.data?.vehicleVariant) : "N/A"}
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                       // display: "flex",
                       textAlign: "end",
                      }}
                    >
                      Color : {" "}
                      <span style={{ fontWeight: "400" }}>
                        {showData?.data?.vehicleColor ? (showData?.data?.vehicleColor): "N/A"}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Section 2  */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <div
                  style={{
                    width: "48%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    textAlign: "center",
                    fontWeight: "500",
                  }}
                >
                  Last Service Details
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontWeight: "500",
                    paddingLeft: "19%",
                  }}
                >
                  Warranty Details
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  borderTop: "1px solid grey",
                  borderBottom: "1px solid grey",
                }}
              >
                <div
                  style={{
                    width: "48%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      display: "flex",
                    }}
                  >
                    Service Type : {" "}
                    <span style={{ fontWeight: "400" }}>
                      {showData?.serviceHistory?.serviceDetails?.serviceType ? (showData?.serviceHistory?.serviceDetails?.serviceType) : "N/A"}
                    </span>
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      display: "flex",
                    }}
                  >
                    Service Category : {" "}
                    <span style={{ fontWeight: "400" }}>
                      {
                        showData?.serviceHistory?.serviceDetails?.serviceCategory ? (showData?.serviceHistory?.serviceDetails?.serviceCategory) : "N/A"
                      }
                    </span>
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      display: "flex",
                    }}
                  >
                    Service Date : {" "}
                    <span style={{ fontWeight: "400" }}>
                      {showData?.serviceHistory?.serviceDetails?.jobDate
                        ? (moment(
                            showData?.serviceHistory?.serviceDetails?.jobDate
                          ).format("DD-MM-YYYY"))
                        : "N/A"}
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        display: "flex",
                      }}
                    >
                      Standard Warranty
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        fontWeight: "100",
                      }}
                    >
                      Distance :{" "}
                      {
                        showData?.warrantyDetails?.standardWarranty?.warrantyDistance ? (showData?.warrantyDetails?.standardWarranty?.warrantyDistance) : "N/A"
                      }{" "}
                      {
                        showData?.warrantyDetails?.standardWarranty?.warrantyDistanceUnit ? (showData?.warrantyDetails?.standardWarranty?.warrantyDistanceUnit) : "N/A"
                      }
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        fontWeight: "100",
                      }}
                    >
                      Duration :{" "}
                      {
                        showData?.warrantyDetails?.standardWarranty?.warrantyDuration ? (showData?.warrantyDetails?.standardWarranty?.warrantyDuration) : "N/A"
                      }{" "}
                      {
                        showData?.warrantyDetails?.standardWarranty?.warrantyDurationUnit ? (showData?.warrantyDetails?.standardWarranty?.warrantyDurationUnit) : "N/A"
                      }
                    </span>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      textAlign: "end",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Extended Warranty
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "100",
                      }}
                    >
                      Distance :{" "}
                      {
                        showData?.warrantyDetails?.extendedWarranty?.addOnWarrantyDistance ? (showData?.warrantyDetails?.extendedWarranty?.addOnWarrantyDistance) : "N/A"
                      }{" "}
                      {
                        showData?.warrantyDetails?.extendedWarranty?.addOnWarrantyDistanceUnit ? (showData?.warrantyDetails?.extendedWarranty?.addOnWarrantyDistanceUnit) : 'N/A'
                      }
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "100",
                      }}
                    >
                      Duration :{" "}
                      {
                        showData?.warrantyDetails?.extendedWarranty?.addOnWarrantyDuration ? (showData?.warrantyDetails?.extendedWarranty?.addOnWarrantyDuration) : "N/A"
                      }{" "}
                      {
                        showData?.warrantyDetails?.extendedWarranty?.addOnWarrantyDurationUnit ? (showData?.warrantyDetails?.extendedWarranty?.addOnWarrantyDurationUnit) : "N/A"
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Section 3  */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Job Details
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  textAlign: "center",
                  border: "1px solid grey",
                  fontSize: "12px",
                }}
              >
                <span
                  style={{
                    width: "25%",
                    textAlign: "center",
                    borderRight: "1px solid grey",
                  }}
                >
                  Service Type - {showData?.data?.serviceDetails?.serviceType ? (showData?.data?.serviceDetails?.serviceType) : "N/A"}
                </span>
                <span
                  style={{
                    width: "25%",
                    textAlign: "center",
                    borderRight: "1px solid grey",
                  }}
                >
                  Service Category -{" "}
                  {showData?.data?.serviceDetails?.serviceCategory ? (showData?.data?.serviceDetails?.serviceCategory) : "N/A"}
                </span>
                <span
                  style={{
                    width: "25%",
                    textAlign: "center",
                    borderRight: "1px solid grey",
                  }}
                >
                  Job Date - 
                  {showData?.data?.serviceDetails?.jobDate
                    ? (moment(showData?.data?.serviceDetails?.jobDate).format(
                        "DD-MM-YYYY"
                      ))
                    : "N/A"}
                </span>
                <span style={{ width: "25%", textAlign: "center" }}>
                  Estd. Delivery Date -{" "}
                  {showData?.data?.serviceDetails?.deliveryDateAndTime
                    ? (moment(
                        showData?.data?.serviceDetails?.deliveryDateAndTime
                      ).format("DD-MM-YYYY"))
                    : "N/A"}
                </span>
              </div>
            </div>
            {/* Section 4  */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Checks and Spares
              </div>

              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    >
                      Mandatory Checks
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      Labor Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    >
                      Optional Checks
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      Labor Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    >
                      Mandatory Spares
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    >
                      Optional Spares
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* Data Rows */}
                  <tr>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "18%",
                        verticalAlign: "top",
                      }}
                    >
                      {showData?.data?.inspectionsChecks?.mandatoryInspectionChecks?.map(
                        (item, index) =>
                          item.inspectionCheck.map((check, subIndex) => (
                            <div key={`mandatory-${index}-${subIndex}`}>
                              {check?.inspectionCheck ? (check?.inspectionCheck) : "N/A"}
                              <br />
                            </div>
                          ))
                      )}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "7%",
                        verticalAlign: "top",
                      }}
                    >
                      {showData?.data?.inspectionsChecks?.mandatoryInspectionChecks?.map(
                        (item, index) =>
                          item.inspectionCheck.map((check, subIndex) => (
                            <div
                              key={`mandatory-${index}-${subIndex}`}
                              style={{ display: "flex", justifyContent: "end" }}
                            >
                              {check?.cost ? (check?.cost?.toFixed(2)) : "N/A"}
                              <br />
                            </div>
                          ))
                      )}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "18%",
                        verticalAlign: "top",
                      }}
                    >
                      {showData?.data?.inspectionsChecks?.optionalInspectionChecks?.map(
                        (item, index) =>
                          item?.inspectionCheck?.map((check, subIndex) => (
                            <div key={`optional-${index}-${subIndex}`}>
                              {check?.inspectionCheck ? (check?.inspectionCheck) : "N/A"}
                              <br />
                            </div>
                          ))
                      )}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "7%",
                        verticalAlign: "top",
                      }}
                    >
                      {showData?.data?.inspectionsChecks?.optionalInspectionChecks?.map(
                        (item, index) =>
                          item?.inspectionCheck?.map((check, subIndex) => (
                            <div
                              key={`optional-${index}-${subIndex}`}
                              style={{ display: "flex", justifyContent: "end" }}
                            >
                              { check?.cost ? (check?.cost?.toFixed(2)) : "N/A"}
                              <br />
                            </div>
                          ))
                      )}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "18%",
                        verticalAlign: "top",
                      }}
                    >
                      {showData?.data?.inspectionsChecks?.spares
                        ?.filter((spare) => spare?.spareType === "Mandatory")
                        .map((spare, index) => (
                          <div key={`mandatory-spare-${index}`}>
                            {spare?.componentName ? (spare?.componentName) : "N/A"}
                            <br />
                          </div>
                        ))}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "7%",
                        verticalAlign: "top",
                      }}
                    >
                      {showData?.data?.inspectionsChecks?.spares
                        ?.filter((spare) => spare?.spareType === "Mandatory")
                        .map((spare, index) => (
                          <div
                            key={`mandatory-spare-${index}`}
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            {spare?.sparePartMrp ? (spare?.sparePartMrp?.toFixed(2)) : "N/A"}
                            <br />
                          </div>
                        ))}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "18%",
                        verticalAlign: "top",
                      }}
                    >
                      {showData?.data?.inspectionsChecks?.spares
                        ?.filter((spare) => spare?.spareType === "Optional")
                        .map((spare, index) => (
                          <div key={`optional-spare-${index}`}>
                            {spare?.componentName ? (spare?.componentName) : "N/A"}
                            <br />
                          </div>
                        ))}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "10px",
                        fontWeight: "500",
                        width: "7%",
                        verticalAlign: "top",
                      }}
                    >
                      {/* Calculated total cost for optional spares */}

                      {showData?.data?.inspectionsChecks?.spares
                        ?.filter((spare) => spare?.spareType === "Optional")
                        .map((spare, index) => (
                          <div
                            key={`optional-spare-${index}`}
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            {spare?.sparePartMrp ? (spare?.sparePartMrp?.toFixed(2)) : "N/A"}
                            <br />
                          </div>
                        ))}
                    </td>
                  </tr>
                  {/* Subtotals Row */}
                  <tr>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    >
                      Subtotal ({auth.getUserInfo().currencySymbol})
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                        // display:"flex",
                        // justifyContent:"end"
                      }}
                    >
                      {sumMandatoryInspectionCosts ? sumMandatoryInspectionCosts?.toFixed(2) : "N/A"}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                        // display:"flex",
                        // justifyContent:"end"
                      }}
                    >
                      {optionalInspectionChecks ? optionalInspectionChecks?.toFixed(2) : "N/A"}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                        // display:"flex",
                        justifyContent: "end",
                      }}
                    >
                      {sumMandatorySpareCosts ? sumMandatorySpareCosts?.toFixed(2) : "N/A"}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                        // display:"flex",
                        justifyContent: "end",
                      }}
                    >
                      {sumOptionalSpareCosts ? sumOptionalSpareCosts?.toFixed(2) : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>{" "}
            <br />
            {/* Section 5  */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "40%",
                      }}
                    >
                      Customer Complaints / Remarks
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "10%",
                      }}
                    >
                      Labor Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    >
                      Spares Replacement
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                      }}
                    >
                      Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "25%",
                      }}
                    >
                      Observation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {showData?.data?.customerComplaints?.map((item, index) => {
                    return (
                      <tr>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "left",
                            borderRight: "1px solid grey",
                            borderLeft: "1px solid grey",
                            fontSize: "10px",
                            fontWeight: "500",
                            width: "40%",
                          }}
                        >
                          {item?.customerComplaint ? (item?.customerComplaint) : "N/A"}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "right",
                            borderRight: "1px solid grey",
                            borderLeft: "1px solid grey",
                            fontSize: "10px",
                            fontWeight: "500",
                            width: "10%",
                            // display:"flex",
                            justifyContent: "end",
                          }}
                        >
                          {item?.cost
                            ? parseFloat(item.cost)?.toFixed(2)
                            : "N/A"}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "left",
                            borderRight: "1px solid grey",
                            borderLeft: "1px solid grey",
                            fontSize: "10px",
                            fontWeight: "500",
                            width: "18%",
                          }}
                        >
                          {item?.spare ? (item?.spare) : "N/A"}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "right",
                            borderRight: "1px solid grey",
                            borderLeft: "1px solid grey",
                            fontSize: "10px",
                            fontWeight: "500",
                            width: "7%",
                            // display:"flex",
                            justifyContent: "end",
                          }}
                        >
                          {item?.spareCost
                            ? parseFloat(item.spareCost)?.toFixed(2)
                            : "N/A"}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "left",
                            borderRight: "1px solid grey",
                            borderLeft: "1px solid grey",
                            fontSize: "10px",
                            fontWeight: "500",
                            width: "25%",
                          }}
                        ></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "40%",
                      }}
                    >
                      Sub Total ({auth.getUserInfo().currencySymbol})
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "10%",
                        // display:"flex",
                        justifyContent: "end",
                      }}
                    >
                      {subTotalcomplaints ? subTotalcomplaints?.toFixed(2) : "N/A"}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "18%",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "7%",
                        // display:"flex",
                        justifyContent: "end",
                      }}
                    >
                      {subTotalSparesRepl ? subTotalSparesRepl?.toFixed(2) : "N/A"}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        border: "1px solid grey",
                        fontSize: "12px",
                        fontWeight: "500",
                        width: "25%",
                      }}
                    ></td>
                  </tr>
                </tbody>
              </table>
              <br />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    border: "1px solid grey",
                    width: "40%",
                    textAlign: "right",
                    justifyContent: "end",
                    fontSize: "14px",
                    fontWeight: "500",
                    paddingRight: "1%",
                  }}
                >
                  Total Estimate ({auth.getUserInfo().currencySymbol})
                </div>
                <div
                  style={{
                    // display: "flex",
                    // flexDirection: "row",
                    border: "1px solid grey",
                    width: "10%",
                    textAlign: "end",
                    paddingRight: "3px",
                    fontSize: "14px",
                    fontWeight: "500",
                    // justifyContent: "end",
                  }}
                >
                  {typeof estimatedTotal === "number"
                    ? estimatedTotal?.toFixed(2)
                    : "N/A"}
                  {/* { estimatedTotal?.toFixed(2)} */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "25%",
                    textAlign: "start",
                    paddingLeft: "1%",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Technician Name :{" "}
                  {showData?.data?.serviceDetails?.technicianName ? (showData?.data?.serviceDetails?.technicianName) : "N/A"}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "25%",
                    textAlign: "start",
                    paddingLeft: "1%",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Approver Name :
                </div>
              </div>
            </div>
            {/* Section 6  */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "10%",
              }}
            >
              <span style={{ fontSize: "14px", fontWeight: "500" }}>
                Customer Signature
              </span>
              <div style={{ textAlign: "right" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    textDecoration: "underline",
                  }}
                >
                  For {rowData?.serviceCenterName ? (rowData?.serviceCenterName) : "N/A"}
                </span>
                <br />
                <span style={{ fontSize: "14px", fontWeight: "500" }}>
                  Authorised Signature
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="save-cancel" style={{ justifyContent: "flex-end" }}>
          <div className="cust-reset  cancel-button">
            <button
              type="button"
              className="btn btn-icon-label"
              onClick={closeHandler}
              style={{ height: "40px" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  color: "#0A18A3",
                  fontWeight: "500",
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ fontSize: "16px" }}
                />{" "}
                Cancel
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewJobCard;
