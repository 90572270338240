import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';
import moment from 'moment';


const UserCountBarChart = ({ startDate, endDate }) => {
    const [userCountData, setUserCountData] = useState({});

    const fetchUserCountData = async () => {
        const resp = await trackPromise(API.get(startDate == null && endDate == null ? `admin-module/api/v1/dashboard/getUserCounts` : `admin-module/api/v1/dashboard/getUserCounts?startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}`));
        console.log(resp);
        setUserCountData(resp)
    };
    useEffect(() => {
        fetchUserCountData();
    }, [endDate]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        // Modify the axis by adding scales
        scales: {
            // to remove the y-axis labels
            y: {
                ticks: {
                    display: false,
                    beginAtZero: true,
                },
                // to remove the y-axis grid
                grid: {
                    drawBorder: false,
                    display: false,
                },
            },
        },
    };
    return <Bar
        height={125}
        data={{
            datasets: [{
                type: 'bar',
                backgroundColor: '#2D3DD9',
                barThickness: 25,
                data: [userCountData.subAdminUsers,
                userCountData.productionUsers,
                userCountData.procurementUsers,
                userCountData.inventoryUsers,
                userCountData.shopfloorUsers,
                userCountData.qcUsers,
                userCountData.financeUsers,
                userCountData.managementUsers,
                userCountData.operationUsers,
                userCountData.salesUsers,
                userCountData.dealerUsers,
                userCountData.showroomUsers,
                userCountData.serviceCenterUsers]
            }
            ],
            labels: ["Sub Admin", "Production", "Procurement", "Inventory", "Shop Floor", "Quality Control",
                "Finance", "Management", "Operations", "Sales", "Dealer", "Showroom", "Service Center"]
        }} options={options}
    />
}

export default UserCountBarChart;
