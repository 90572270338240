import React, { useState } from "react";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
} from "@material-ui/icons";
import moment from "moment";
import AppConstants from "../../../utils/app-constants";
import auth from "../../../utils/auth";
import CreditNotePreview from "../../ReturnTemplates/CreditNote Preview";
import OnHoldReturnNote from "./OnHoldReturnNote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHolding, faPause } from "@fortawesome/free-solid-svg-icons";


const VehicleViewAct = ({ closeModal, rowData, updatedData }) => {
  const [openQty, setOpenQty] = useState();
  const [viewSpecs, setViewSpecs] = useState(false);
  const [showGRN, setShowGRN] = useState();
  const [showGIN, setShowGIN] = useState();

  const [showNote, setShowNote] = useState();
  const [showModal, setShowModal] = useState();

  let value = rowData?.returnedData?.reduce((accumulator, item) => {
    return accumulator + (parseInt(item.unitPrice) || 0);
  }, 0);

  return (
    <div className="container1">
      {showNote && (
        <CreditNotePreview
          closeModal={setShowNote}
          closeOne={closeModal}
          rowData={rowData}
          updatedData={updatedData}
        />
      )}

      {showModal && (
        <OnHoldReturnNote
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      <div
        className="modalContainer"
        style={{ width: "1185px", height: "500px", padding: "15px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>View and Update</h3>
        </div>

        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
            margin: "10px 0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Requested ID: {rowData?.requestID}</span>
            <span>RPI No.: {rowData?.rpiNo}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Requested By: {rowData?.requestedBy}</span>
            <span>
              RPI Date: {moment(rowData?.rpiDate).format("DD-MM-YYYY")}
            </span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              Requested Date :{" "}
              {moment(rowData?.requestDate).format("DD-MM-YYYY")}
            </span>
            <span>RPI Raise By : {rowData?.showroomName}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Segment : {rowData?.returnedData[0]?.vehicleSegment}</span>
          </div>
        </section>

        <section>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "80%",
            }}
          >
            <span
              style={{
                display: "flex",
                background: "#0a18a3",
                alignItems: "center",
                height: "30px",
                justifyContent: "center",
                borderRadius: "5px",
                color: "white",
                width: "200px",
                fontSize: "14px",
                fontWeight: "800",
              }}
            >
              Total Returned Qty. : {rowData?.returnedData?.length}
            </span>
            <span
              style={{
                display: "flex",
                background: "#0a18a3",
                alignItems: "center",
                height: "30px",
                justifyContent: "center",
                borderRadius: "5px",
                color: "white",
                width: "200px",
                fontSize: "14px",
                fontWeight: "800",
                marginLeft: "10px",
              }}
            >
              {`Total Value(${auth.getUserInfo().currencySymbol}) : ${value}`}
            </span>
          </div>
        </section>

        <div
          className="body"
          style={{
            display: "flex",
            height: "300px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div id="POVendorHeading">
              <div style={{ width: "5%" }}>S.No.</div>
              <div style={{ width: "10%" }}>Category</div>
              <div style={{ width: "10%" }}>Model</div>
              <div style={{ width: "10%" }}>Variant</div>
              <div style={{ width: "10%" }}>Type</div>
              <div style={{ width: "10%" }}>Color</div>
              <div style={{ width: "15%" }}>VIN</div>
              <div style={{ width: "15%" }}>BIN</div>
              <div style={{ width: "10%" }}>Return Reason</div>
              <div style={{ width: "12%" }}>{`Vehicle Price(${
                auth.getUserInfo().currencySymbol
              })`}</div>
              <div style={{ width: "12%" }}>{`Sub Total(${
                auth.getUserInfo().currencySymbol
              })`}</div>
              <div style={{ width: "12%" }}>Status</div>
            </div>

            <div>
              {rowData?.returnedData?.map((item, index) => {
                return (
                  <div
                    id="POVendorContent"
                    style={{
                      borderBottom: "1px solid #999999",
                      boxShadow: "none",
                      padding: "0px",
                    }}
                  >
                    <div style={{ width: "5%", overflowY: "auto" }}>
                      {index + 1}
                    </div>
                    <div style={{ width: "10%", overflowY: "auto" }}>
                      {item?.vehicleCategory}
                    </div>
                    <div style={{ width: "10%", overflowY: "auto" }}>
                      {item?.vehicleModel}
                    </div>
                    <div style={{ width: "10%", overflowY: "auto" }}>
                      {item?.vehicleVariant}
                    </div>
                    <div style={{ width: "10%", overflowY: "auto" }}>
                      {item?.vehicleType}
                    </div>
                    <div style={{ width: "10%", overflowY: "auto" }}>
                      {item?.vehicleColor}
                    </div>
                    <div style={{ width: "15%", overflowY: "auto" }}>
                      {item?.VIN}
                    </div>
                    <div style={{ width: "15%", overflowY: "auto" }}>
                      {item?.BIN}
                    </div>
                    <div style={{ width: "10%", overflowY: "auto" }}>
                      {item?.returnReason}
                    </div>

                    <div style={{ width: "12%", overflowY: "auto" }}>
                      {item?.unitPrice}
                    </div>
                    <div style={{ width: "12%", overflowY: "auto" }}>
                      {item?.unitPrice}
                    </div>
                    <div style={{ width: "12%", overflowY: "auto" }}>
                      {item?.status}
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>

        <div className="footer" style={{ display: "flex", flexDirection:"row" ,justifyContent: "space-between" }}>
          
            <div>
              <button
                style={{ width: "104px", height: "42px", background: "#fff" }}
                onClick={() => closeModal(false)}
              >
                <KeyboardBackspace style={{ fontSize: "25px" }} />
                Back
              </button>
            </div>

            <div>
              <button
                style={{
                  width: "135px",
                  height: "42px",
                }}
                onClick={() => setShowModal(true)}
              >
                <FontAwesomeIcon icon={faPause}/> Put On Hold
              </button>

              <button
                style={{
                  width: "104px",
                  height: "42px",
                  background: "#0a18a3",
                  color: "white",
                }}
                onClick={() => setShowNote(true)}
              >
                Proceed
              </button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleViewAct;
