import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import moment from "moment";
import { errorNotification, successNotification } from "../../../../../utils/notifications";
import SubmitPop from "./SubmitPop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const ViewDetails = ({ rowData, closeModal }) => {

  const [openSpare, setOpenSpare] = useState();
  const [spareData, setSpareData] = useState();

  const submitHandler = (rowData) => {
    setOpenSpare(true);
    setSpareData(rowData);
  };



  return (
    <div className="container1">
     {openSpare ? (
        <SubmitPop rowData={spareData} closeModal={setOpenSpare} />
      ) : (
        ""
      )}
      <div
        className="modalContainer"
        style={{ width: "1280px", height: "500px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Vehicle Return</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div>Request ID: {}</div>
              <div>Request Date: {moment().format("DD-MM-YYYY")}</div>
              <div>Request By.: {}</div>
              <div>GIN No: {}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div>RPI No: {}</div>
              <div>RPI Date: {}</div>
              <div>RPI Raised By: {}</div>
               
            </section>
          </section>
          <br />
          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "fit-content",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "10%", padding: "10px" }}>S.No</div>
              <div style={{ width: "200px", padding: "10px" }}>Segment</div>
              <div style={{ width: "200px", padding: "10px"}}>Category</div>
              <div style={{ width: "200px", padding: "10px"}}>Vehicle SKU</div>
              <div style={{ width: "200px", padding: "10px"}}>
                Vehicle S.No
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
                Vehicle Make
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
                Vehicle Model
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
                Vehicle Variant
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
                Vehicle Type
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
                Vehicle Color
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
               VIN
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
               BIN
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
               Return Reason
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
                Status
              </div>
            </div>
            <section
              style={{ width: "fit-content", height: "auto" }}
            >
              {/* {row.availableQty.map((item, index) => ( */}
              <div
               style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "",
                fontSize: "13px",
                display:"flex"
              }}
            >
              <div style={{ width: "10%", padding: "10px" }}>Index</div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>Segment</div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>Category</div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>Vehicle SKU</div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>Vehicle S.No</div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                Vehicle Make
              </div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                Vehicle Model
              </div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                Vehicle Variant
              </div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                Vehicle Type
              </div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                Vehicle Color
              </div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                VIN
              </div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
              BIN
              </div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                Return reason
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
              <button className="reactive-btn" style={{color:"blue", width:"115px"}}>WO In-Progress<span className="reactive-btn-font"></span></button>
              </div>
            </div>
            {/* //   ))} */}
            </section>
          </section>
        </div>

        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={submitHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
