import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  faFilter,
  faRedoAlt,
  faTimesCircle,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import SearchBox from "../../../components/search/SearchBox";
import "../dealerShowroom.scss";
import auth from "../../../utils/auth";
import ViewSalesUnits from "./ViewSalesUnits";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import handleExport from "../../../utils/exportExcel"

function SalesShowroomWise({ inDashboard }) {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [openQty, setOpenQty] = useState();
  const [UsersData, setUsersData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showroomDataWithId, setShowroomData] = useState([]);
  const [filtersDropdown, setFiltersDropdown] = useState([]);
  const [selectedShowroomValue, setSelectedShowroomValue] = useState("All");
  const [dropdownData, setDropdownData] = useState([]);
  const [formValue, setFormValue] = useState({
    vehicleSkuCode: "",
    vehicleModel: "",
    vehicleVariant: "",
    vehicleType: "",
    vehicleColor: "",
    showroomLocation: "",
    showroomID: "",
    from: "",
    to: "",
  });

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `dealer-module/api/v1/showrooms/sales/showroomWise?vehicleSkuCode=${
          formValue.vehicleSkuCode
        }&&vehicleModel=${formValue.vehicleModel}&&vehicleVariant=${
          formValue.vehicleVariant
        }&&vehicleType=${formValue.vehicleType}&&vehicleColor=${
          formValue.vehicleColor
        }&&from=${formValue.from}&&to=${formValue.to}&&showroomLocation=${
          formValue.showroomLocation
        }&&showroomID=${
          formValue.showroomID == "All" ? "" : formValue.showroomID
        }`
      )
    );
    const filteredData = resp.data.filter((item)=>{
      return item.invoiceAmount != undefined || item.invoiceAmount != null
    })
    console.log(resp.success);
    setTableData(filteredData);
    setUsersData(filteredData);
    setShowroomData(filteredData);
    const respo = await trackPromise(
      API.get(`dealer-module/api/v1/showrooms/sales/showroomWise`)
    );
    const filteredDataDropdown = respo.data.filter((item)=>{
      return item.invoiceAmount != undefined || item.invoiceAmount != null
    })
    setDropdownData(filteredDataDropdown);
    setFiltersDropdown(filteredDataDropdown)
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const uniqueshowroomID = [
    ...new Set(dropdownData.map((item) => item.showroomID)),
  ];
  const uniqueshowroomLocation = [
    ...new Set(filtersDropdown.map((item) => item.showroomLocation.address1)),
  ];
  const uniquevehicleSkuCode = [
    ...new Set(filtersDropdown.map((item) => item.vehicleSkuCode)),
  ];
  const uniquevehicleModel = [
    ...new Set(filtersDropdown.map((item) => item.vehicleModel)),
  ];
  const uniquevehicleVariant = [
    ...new Set(filtersDropdown.map((item) => item.vehicleVariant)),
  ];
  const uniquevehicleType = [
    ...new Set(filtersDropdown.map((item) => item.vehicleType)),
  ];
  const uniquevehicleColor = [
    ...new Set(filtersDropdown.map((item) => item.vehicleColor)),
  ];

  const { totalCount, totalPrice } = tableData.reduce(
    (acc, curr) => {
      const count = parseInt(curr.count);
      const price = parseInt(curr.invoiceAmount == "" ? 0 : curr.invoiceAmount);
      acc.totalCount += count;
      acc.totalPrice += count * price;
      return acc;
    },
    { totalCount: 0, totalPrice: 0 }
  );

  // const handleInputChange = (event) => {
  //   let filteredData;
  //   let name = event.target.name;
  //   if (name == "showroomID") {
  //     formValue.showroomID = event.target.value;
  //     filteredData = tableData.filter(
  //       (item) => item.showroomID == event.target.value
  //     );
  //   } else {
  //     formValue.showroomLocation = event.target.value;
  //     filteredData = tableData.filter(
  //       (item) => item.showroomLocation == event.target.value
  //     );
  //   }
  //   setShowroomData(filteredData);
  //   fetchReport();
  // };

  const handleInputChange = (event) => {
    formValue.showroomID = event.target.value;
    setSelectedShowroomValue(event.target.value);
    let filteredData = tableData.filter(
      (item) => item.showroomID == event.target.value
    );
    if (event.target.value == "All") {
      fetchReport();
      setFiltersDropdown(dropdownData);
    } else {
      setShowroomData(filteredData);
      setFiltersDropdown(filteredData);
      fetchReport();
    }
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const handleChange = (e) => {
    console.log(e.target.name);
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  // const qtyFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   return (
  //     <div>
  //       <p
  //         style={{
  //           fontSize: "14px",
  //           color: "blue",
  //           cursor: "pointer",
  //         }}
  //         onClick={() => setOpenQty(rowIndex)}
  //       >
  //         {cell.length}
  //       </p>
  //       {openQty === rowIndex && (
  //         <ViewSalesUnits
  //           row={row}
  //           closeModal={setOpenQty}
  //           tabName={"availableQty"}
  //         />
  //       )}
  //     </div>
  //   );
  // };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          tableData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "SKU Code",
      dataField: "vehicleSkuCode",
      classes: "cellsOvereflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Segment",
      dataField: "vehicleSegment",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Category",
      dataField: "vehicleCategory",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Model",
      dataField: "vehicleModel",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Variant",
      dataField: "vehicleVariant",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Type",
      dataField: "vehicleType",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Color",
      dataField: "vehicleColor",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Sold Units",
      dataField: "count",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      // formatExtraData: { openQty },
      // formatter: qtyFormatter,
    },
    // {
    //   text: `Ex-Showroom Price (${auth.getUserInfo().currencySymbol})`,
    //   dataField: "exShowroomPrice",
    //   classes: "cellsOverflow",
    //   headerAttrs: { width: "180px" },
    //   headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    //   formatter:(cell,row)=>{
    //     const price = Number(row.exShowroomPrice);
    //     return price.toLocaleString("en-IN", {
    //       maximumFractionDigits: 0,
    //       style: "decimal",
    //     });
    //   },
    // },
    {
      text: `Sale Value (${auth.getUserInfo().currencySymbol})`,
      dataField: "invoiceAmount",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row) => {
        const price = Number(row.invoiceAmount);
        return price.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "decimal",
        });
      },
    },
  ];

  const clearFilter = async (e) => {
    setFormValue({
      vehicleSkuCode: "",
      vehicleModel: "",
      vehicleVariant: "",
      vehicleType: "",
      vehicleColor: "",
      showroomLocation: "",
      showroomID: "",
      from: "",
      to: "",
    });
    setSelectedShowroomValue("All");
    const resp = await trackPromise(
      API.get(`dealer-module/api/v1/showrooms/sales/showroomWise`
      )
    );
    const filteredData = resp.data.filter((item)=>{
      return item.invoiceAmount != undefined || item.invoiceAmount != null
    })
    console.log(resp);
    setFiltersDropdown(filteredData);
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setTableData(filteredData);
  };

  const refresh = async (e) => {
    setFormValue({
      vehicleSkuCode: "",
      vehicleModel: "",
      vehicleVariant: "",
      vehicleType: "",
      vehicleColor: "",
      showroomLocation: "",
      showroomID: "",
      from: "",
      to: "",
    });
    setShowroomData(tableData);
    document.getElementById("id").value = "";
    document.getElementById("location").value = "";
    const resp = await trackPromise(
      API.get(
        `dealer-module/api/v1/showrooms/sales/showroomWise?vehicleSkuCode=${""}&&vehicleModel=${""}&&vehicleVariant=${""}&&vehicleType=${""}&&vehicleColor=${""}&&from=${""}&&to=${""}&&showroomLocation=${""}&&showroomID=${""}`
      )
    );
    console.log(resp);
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setTableData(resp.data);
  };

  console.log(formValue);
  let dataToExport = tableData.map((item) => {
    return {
        "SKU Code": item.vehicleSkuCode,
        "Segment": item.vehicleSegment,
        "Category": item.vehicleCategory,
        "Model": item.vehicleModel,
        "Variant": item.vehicleVariant,
        "Type": item.vehicleType,
        "Color": item.vehicleColor,
        "Sold Units": item.count || "-",
        "Sales Value(₹)": item.totalPrice||"-",
    }
})



  return (
    <>
      <ToolkitProvider keyField="id" data={tableData} columns={columns} search>
        {(props) => (
          <>
            <div className="bussiness">
              <div className="heading">
                <span>Showroom Wise</span>
              </div>
              <div
                type="button"
                className="btn btn-md"
                style={{ float: "right", marginTop: "-42px" }}
              >
                <div
                  style={{
                    display: "flex",
                    marginRight: "-18px",
                    height: "26px",
                  }}
                >
                  <div style={{ width: "199px" }}>
                    <SearchBar
                      {...props.searchProps}
                      placeholder="Search"
                      style={{
                        height: "25px",
                        // border: "1px solid #C6C6C6",
                        border: "none",
                        background: "white",
                        borderRadius: "3px",
                        marginRight: "-50px",
                      }}
                    />
                  </div>
                  <div onClick={clearFilter} className="filter-refresh">
                    <FontAwesomeIcon icon={faRedoAlt} />
                    &nbsp; Refresh
                  </div>
                  <div className="filter-refresh" onClick={handleClick}>
                    {" "}
                    <FontAwesomeIcon icon={faFilter} />
                    &nbsp; Filters
                  </div>
                  <div
                      className="filter-refresh"
                      onClick={() => handleExport(dataToExport, "Showroom Wise")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                  {inDashboard ? (
                    <div
                      className="filter-refresh"
                      onClick={() => inDashboard(false)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="mainShowroomInvDiv">
              {/* <div className="leftDetailsBox">
                <select
                  name="showroomID"
                  id="id"
                  className="input-config"
                  defaultValue=""
                  style={{
                    width: "96%",
                    height: "35px",
                    background: "#FFFFFF",
                    border: "1px solid #CDCDCD",
                  }}
                  onChange={(event) => handleInputChange(event)}
                >
                  <option value="" selected>
                    Showroom ID
                  </option>
                  {uniqueshowroomID.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                <select
                  name="showroomLocation"
                  id="location"
                  className="input-config"
                  defaultValue=""
                  style={{
                    width: "96%",
                    height: "35px",
                    background: "#FFFFFF",
                    border: "1px solid #CDCDCD",
                  }}
                  onChange={(event) => handleInputChange(event)}
                >
                  <option value="" selected>
                    Showroom Location
                  </option>
                  {uniqueshowroomLocation.map((item) => (
                    <option value={item}>
                      {item}
                    </option>
                  ))}
                </select>

                {showroomDataWithId.map((item, index) => {
                  return (
                    <div className="showroomsLeftBoxDetails">
                      <h6 style={{ color: "rgba(10, 24, 163, 1)" }}>
                        {item.showroomID}
                      </h6>
                      <h6 style={{ color: "rgba(55, 55, 55, 1)" }}>
                        {item.showroomName}
                      </h6>
                      <small style={{ color: "rgba(55, 55, 55, 1)" }}>
                        {item?.showroomLocation?.address1}
                      </small>
                    </div>
                  );
                })}
              </div> */}

              {/* RIGHT SIDE BOX */}

              <div
                className="rightDetailsBox"
                style={{ width: "100%", marginLeft: "0px" }}
              >
                {show && (
                  <div
                    id="abc"
                    className="RA_block"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      height: "125px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                      marginBottom: "1%",
                    }}
                  >
                    <div
                      className="bomfilter_fields"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          SKU Code
                        </label>
                        <select
                          name="vehicleSkuCode"
                          value={formValue.vehicleSkuCode}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            SKU Code
                          </option>
                          {uniquevehicleSkuCode.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Model
                        </label>
                        <select
                          name="vehicleModel"
                          value={formValue.vehicleModel}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Model
                          </option>
                          {uniquevehicleModel.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Variant
                        </label>
                        <select
                          name="vehicleVariant"
                          value={formValue.vehicleVariant}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Variant
                          </option>
                          {uniquevehicleVariant.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Type
                        </label>
                        <select
                          name="vehicleType"
                          value={formValue.vehicleType}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Type
                          </option>
                          {uniquevehicleType.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Color
                        </label>
                        <select
                          name="vehicleColor"
                          value={formValue.vehicleColor}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Color
                          </option>
                          {uniquevehicleColor.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          From
                        </label>
                        <input
                          type="date"
                          name="from"
                          defaultValue={formValue.from}
                          style={{ marginTop: "-5px", width: "100%" }}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          To
                        </label>
                        <input
                          type="date"
                          name="to"
                          defaultValue={formValue.to}
                          style={{ marginTop: "-5px", width: "100%" }}
                          onChange={handleChange}
                        />
                      </div> */}
                      <div
                        style={{
                          width: "100%",
                          textAlign: "end",
                          marginTop: "2%",
                        }}
                      >
                        <button
                          type="submit"
                          id="apply"
                          style={{ width: "96px", height: "35px" }}
                          onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ width: "96px", height: "35px" }}
                        >
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#F3F3F3",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "0",
                      padding: "0",
                      width: "23%",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        marginLeft: "2.5%",
                      }}
                      htmlFor="showroomID"
                    >
                      Select Showroom ID
                    </label>
                    <select
                      name="showroomID"
                      id="showroomID"
                      className="input-config"
                      value={formValue.showroomID}
                      style={{
                        width: "100%",
                        height: "25px",
                        background: "#FFFFFF",
                        border: "1px solid #CDCDCD",
                        marginTop: "-2%",
                      }}
                      onChange={(event) => handleInputChange(event)}
                    >
                      <option value="All" selected>
                        All
                      </option>
                      {uniqueshowroomID?.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                  <span
                    style={{
                      marginTop: "2%",
                      color: "rgb(54, 54, 54)",
                      fontWeight: "500",
                    }}
                  >
                    Showroom Name:{" "}
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      {selectedShowroomValue == "All"
                        ? "All"
                        : showroomDataWithId[0]?.showroomName}
                    </span>
                  </span>
                  <h6
                    style={{
                      margin: "5px",
                      color: "rgb(54, 54, 54)",
                      marginTop: "2%",
                    }}
                  >
                    Total Vehicles Sold :{" "}
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      {totalCount}
                    </span>
                  </h6>
                  <h6
                    style={{
                      margin: "5px",
                      color: "rgb(54, 54, 54)",
                      marginTop: "2%",
                    }}
                  >
                    {` Total Sales value (${auth.getUserInfo().currencySymbol})`} :{" "}
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      {totalPrice.toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                        style: "decimal",
                      })}
                    </span>
                  </h6>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <BootstrapTable
                    wrapperClasses="table-responsive mis-table"
                    rowClasses="text-nowrap"
                    keyField="id"
                    data={tableData}
                    noDataIndication="No Data Found"
                    rowStyle={{ border: "1px solid #CDCDCD" }}
                    columns={columns}
                    pagination={paginationFactory({
                      hidePageListOnlyOnePage: true,
                      showTotal: true,
                      hideSizePerPage: true,
                      paginationSize: 2,
                      sizePerPage: "10",
                    })}
                    {...props.baseProps}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </>
  );
}

export default SalesShowroomWise;
