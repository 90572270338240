import React, { useState } from "react";
import "../../work-order/workorder.scss";
import { KeyboardBackspace } from '@material-ui/icons';
import auth from "../../../utils/auth";

const ViewLabourBom = ({ close, labourdata }) => {
  const costPerTabs = labourdata[0].duration;
  const totalPrice = () => {
    let total = 0;
    labourdata.map(
      (item) =>
        (total +=
          item.requiredWorkers * item.requiredDuration * item.avgLaborCost)
    );
    return total.toLocaleString('en-IN');
  };


  const calculateDailyCost = (serviceCost, timeUnit, requiredLabors, requiredWorkers) => {
    switch (timeUnit) {
      case "Week":
        return serviceCost * 7 * Number(requiredLabors)  *requiredWorkers;  // Convert weekly cost to daily cost
      case "Month":
        return serviceCost * 30 *  Number(requiredLabors) *requiredWorkers; // Convert monthly cost to daily cost
      case "Year":
        return serviceCost * 365 * Number(requiredLabors) *requiredWorkers ; // Convert yearly cost to daily cost
      default:
        return serviceCost * Number(requiredLabors) *requiredWorkers; // If "Day", return the cost as is
    }
  };

  const calculateTotalPrice = ()=>{
    let total = 0
     labourdata?.map((item)=>{
      console.log(item.avgLaborCost), item?.laborDuration,item.requiredDuration,Number(item?.requiredWorkers)
      total+= calculateDailyCost((item.avgLaborCost), item?.laborDuration,item.requiredDuration,Number(item?.requiredWorkers) )
      
    })
    return total
  }

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "82%", height: "465px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Required Labours</h3>
            {/* <div>
              <button className="action-btn" style={{ color: "blue" }}>
                Print
              </button>
              <button className="action-btn" style={{ color: "blue" }}>
                Export
              </button>
            </div> */}
          </div>

          <div className="body" style={{ height: "340px" }}>
            <div style={{ width: "100%", overflow: "auto", height: "325px" }}>
              <table className="viewcomp_tbl">
                <thead>
                  <tr>
                    <th style={{fontSize:"14px"}}>S.No.</th>
                    <th style={{fontSize:"14px"}}>Labour Type</th>
                    <th style={{fontSize:"14px"}}>Labour Level</th>
                    <th style={{fontSize:"14px"}}>Required Count</th>
                    <th style={{fontSize:"14px"}}>Required Time</th>
                    <th style={{fontSize:"14px"}}>{`Cost (${auth.getUserInfo().currencySymbol})`}</th>
                    <th style={{fontSize:"14px"}}>{`Sub Total (${auth.getUserInfo().currencySymbol})`}</th>
                  </tr>
                </thead>
                {labourdata.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td className="leftcell" style={{fontSize:"13px"}}>{index + 1}</td>
                        <td style={{fontSize:"13px"}}>{item.laborCategory}</td>
                        <td style={{fontSize:"13px"}}>{item.laborLevel} </td>
                        <td style={{fontSize:"13px"}}>{`${item.requiredWorkers}`}</td>
                        <td style={{fontSize:"13px"}}>
                        {` ${item.requiredDuration} ${item?.laborDuration}`}
                        </td>
                        <td style={{fontSize:"13px"}}>{item.avgLaborCost.toLocaleString('en-IN')} Per Day</td>

                        <td className="rightcell" style={{fontSize:"13px"}}>
                        {/* {(item.requiredWorkers *
                            item.requiredDuration *
                            item.avgLaborCost).toLocaleString('en-IN')} */}

                            {
                              calculateDailyCost((item.avgLaborCost), item?.laborDuration,item.requiredDuration,Number(item?.requiredWorkers) )
                            }
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>

              <section
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  borderRadius: "5px",
                  margin: "5px 0",
                  height: "35px",
                }}
              >
                <div
                  style={{
                    width: "200px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {`Total Price (${auth.getUserInfo().currencySymbol})`}
                  <span
                    style={{
                      width: "100px",
                      background: "#ebebeb",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {calculateTotalPrice()}
                  </span>
                </div>
              </section>
            </div>
          </div>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}><KeyboardBackspace/>&nbsp;Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewLabourBom;
