import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import OperationVehicle from "./Component/OperationsVehicles";
// import OperationSpares from "./Component/OperationsSpares";

const OperationVehicle = React.lazy(() =>
  import("./Component/OperationsVehicles")
);
const OperationSpares = React.lazy(() =>
  import("./Component/OperationsSpares")
);
const OperationsBattery = React.lazy(() =>
  import("./Component/OperationsBattery")
);

const tabs = [
  {
    id: "OPM4T1",
    label: TabLabels.VEHICLE,
    Component: OperationVehicle,
  },
  {
    id: "OPM4T2",
    label: TabLabels.SPARES,
    Component: OperationSpares,
  },
  {
    id: "OPM4T3",
    label: TabLabels.BATTERY_OP,
    Component: OperationsBattery,
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

const VehiclesandSpares = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default VehiclesandSpares;
