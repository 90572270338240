import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import RequestedBatteryQty from "../Component/RequestedBatteryQty";
import moment from "moment";
import auth from "../../../../utils/auth";

const RPIDetails = ({ rowData, closeModal, manipulateData, prop }) => {
  console.log(rowData);
  const [open, setOpen] = useState(false);
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1280px", height: "500px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>RPI Details</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderBottom: "0.1px solid grey",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div>RPI No:{rowData?.rpiNo}</div>
              <div>
                RPI Date: {moment(rowData?.rpiDate).format("DD-MM-YYYY")}
              </div>
              <div>RPI Raised By: {rowData?.rpiRaisedBy}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div>PO Value: {}</div>
              <div>Total Qty: {rowData?.returnedData?.length}</div>
              <div>
                Total Amount({`${auth.getUserInfo().currencySymbol}`}):{" "}
                {rowData?.totalAmount}
              </div>
            </section>
          </section>

          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "fit-content",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "50px", padding: "10px" }}>S.No</div>
              <div style={{ width: "200px", padding: "10px" }}>Invoice No</div>
              <div style={{ width: "200px", padding: "10px" }}>
                Invoice Date
              </div>
              <div style={{ width: "200px", padding: "10px" }}>Vehicle SKU</div>
              <div style={{ width: "200px", padding: "10px" }}>Category</div>
              {manipulateData == "rpiSpareManageReq" ? (
                <>
                  <div style={{ width: "200px", padding: "10px" }}>
                    Sub Category
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    Spare Part Name
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    Specifications
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    Returned Quantity
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    Unit Price ({`${auth.getUserInfo().currencySymbol}`})
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    Sub Totals ({`${auth.getUserInfo().currencySymbol}`})
                  </div>
                </>
              ) : (
                <>
                  <div style={{ width: "200px", padding: "10px" }}>Segment</div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    Vehicle Model
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    Vehicle Variant
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    Vehicle Type
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>Color</div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    Vehicle S.No
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    BIN
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    VIN
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    Return Reason
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    Unit Value ({`${auth.getUserInfo().currencySymbol}`})
                  </div>
                </>
              )}
            </div>
            <section style={{ width: "fit-content", height: "auto" }}>
              {rowData?.returnedData?.map((item, index) => (
                <div
                  style={{
                    padding: "0px",
                    border: "1px solid #cdcdcd",
                    width: "",
                    fontSize: "13px",
                    display: "flex",
                  }}
                >
                  <div style={{ width: "50px", padding: "10px" }}>
                    {index + 1}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "14px",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      // onClick={() => setOpen(true)}
                    >
                      {item?.invoiceNo}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {moment(item?.invoiceDate).format("DD-MM-YYYY")}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleSkuCode}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleCategory}
                  </div>
                  {manipulateData == "rpiSpareManageReq" ? (
                    <>
                      <div style={{ width: "200px", padding: "10px" }}>
                        Sub Category
                      </div>
                      <div style={{ width: "200px", padding: "10px" }}>
                        Spare Part Name
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                          color: "blue",
                        }}
                      >
                        View Details
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                          color: "blue",
                        }}
                        onClick={() => setOpen(true)}
                      >
                        7
                      </div>
                      <div style={{ width: "200px", padding: "10px" }}>
                        Unit Price
                      </div>
                      <div style={{ width: "200px", padding: "10px" }}>
                        Sub Totals
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        {item?.vehicleSegment}
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        {item?.vehicleModel}
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        {item?.vehicleVariant}
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        {item?.vehicleType}
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        {item?.vehicleColor}
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        {item?.SNO}
                      </div>

                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        {item?.BIN}
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        {item?.VIN}
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        {item?.returnReason}
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        {item?.unitPrice}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </section>
          </section>
          {open && (
            <RequestedBatteryQty rowData={rowData} closeModal={setOpen} />
          )}
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default RPIDetails;
