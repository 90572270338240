import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";
import { KeyboardBackspace, CheckCircle } from "@material-ui/icons";
import auth from "../../utils/auth";
import PurchaseOrderTemplateInvoice from "./PurchaseOrderTemplateInvoice";
import POTemplateInvoice from "./POTemplateInvoice";
import TermsView from "../TermsView/TermsView";
import moment from "moment";


const PurchaseOrder = ({
  poData,
  productionQty,
  bom,
  closeModal,
  purchaseData,
  bomComponents,
  bomLocation
}) => {
  const [inVoice, setInVoice] = useState(false);
  const [myPOdata,setMyPOdata] = useState({})
  const userName = JSON?.parse(localStorage.getItem('USER_INFO'))?.fullName
  console.log("purchaseData-->", purchaseData)


  let estdTime = [];
  let duration = [];
  console.log(estdTime);
  purchaseData.map((purchaseItem) => {
    purchaseItem?.vendorDetails?.map((data) => {
      data.deliveryDetails.map((item) => {
        if (
          bom.bomLocation === item.deliveryLocation &&
          data.vendorID === purchaseItem.vendorID
        ) {
          estdTime.push(item.estdTime);
          duration.push(item.duration);
        }
      });
    });
  });

  var componentsArray = purchaseData.map((data) => ({
    vendorPartName: data.vendorPartName,
    vendorPartCode: data.vendorPartCode,
    vendorID: data.vendorID,
    vendorName: data.vendorName,
    vendorAddress1: data.address1,
    vendorAddress2: data.address2,
    vendorBusinessContactNo: data.businessContactNo,
    gstin: data.gstin,
    vendorCity: data.vendorCity,
    componentID: data.componentID,
    componentName: data.componentName,
    skuCode: data.skuCode,
    category: data.category,
    subCategory: data.subCategory,
    componentQuantity: data.componentQuantity,
    specifications: data.specifications,
    hsnSacCode: data.hsnSacCode,
    taxRate: data.taxRate,
    estdTime: estdTime[0],
    tenantID: data.tenantID,
    duration: duration[0],
    unitPrice: data.unitPrice,
    requiredQuantity: data.reqComponentQty,
    totalPriceWOTaxed: data.unitPrice * data.reqComponentQty,
    taxAmount: (data.unitPrice * data.reqComponentQty * data.taxRate) / 100,
    totalPriceTaxed:
      data.unitPrice * data.reqComponentQty +
      (data.unitPrice * data.reqComponentQty * data.taxRate) / 100,
  }));
  console.log(componentsArray);

  console.log({ ...bom, components: componentsArray });
  let Obj = { ...bom, components: componentsArray };
  console.log(Obj);

  const totalPrice = () => {
    let total = 0;
    purchaseData?.map(
      (item) =>
      (total +=
        item.unitPrice * item.reqComponentQty +
        (item.unitPrice * item.reqComponentQty * item.taxRate) / 100)
    );
    return total;
  };

  let PoObj = {
    BOMID: Obj.BOMID,
    bomLocation: Obj.bomLocation,
    bomLevel: Obj.bomLevel,
    bomName: Obj.bomName,
    vehicleModel: Obj.vehicleModel,
    vehicleVariant: Obj.vehicleVariant,
    createdBy: Obj.createdBy,
    createdDate: Obj.createdDate,
    approvedBy: Obj.approvedBy,
    mgmtApprovalDate: Obj.mgmtApprovalDate,
    productionQuantity: productionQty,
    vendorID: Obj.components[0].vendorID,
    vendorName: Obj.components[0].vendorName,
    vendorAddress1: Obj.components[0].vendorAddress1,
    vendorAddress2: Obj.components[0].vendorAddress2,
    vendorBusinessContactNo: Obj.components[0].vendorBusinessContactNo,
    gstin: Obj.components[0].gstin,
    vendorCity: Obj.components[0].vendorCity,
    deliveryLocation: Obj.bomLocation,
    components: Obj.components,
    totalPrice: totalPrice(),
    tenantID: Obj.tenantID
  };




  poData(PoObj);
  console.log("PoObj-->", PoObj);

  const generatePO = async () => {
    let poData = PoObj;
    console.log("poData-->", poData)
    
    const resp = await trackPromise(
      API.post(`procurement-module/api/v1/orders`, poData)
    );
    console.log(resp);
    if (resp.success) {
     setMyPOdata(resp?.data)
     setInVoice(true);
      // closeModal(false);
      successNotification(resp.message, { autoClose: 10000 });
    } else {
      errorNotification(resp.message, { autoClose: 10000 });
    }
  };

  console.log(bomComponents)

  const specData = bomComponents?.map((item) => item.specifications?.map((spec) => (spec.specsType + " " + spec.specsValue + " " + spec.specsUnit)))

  console.log(specData)


  const [tentData, setTentData] = useState([])


  const getCompanyDetails = async () => {
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/users/getDataWithTenantID?tenantID=${PoObj.tenantID}`)
    );
    setTentData(resp)
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);



  const [orderData, setOrderData] = useState({});

  console.log("orderData-->", orderData);

  const fetchReport = async (POID) => {
    const resp = await trackPromise(
      API.get(`procurement-module/api/v1/orders?POID=${POID}`)
    );
    setOrderData(resp[resp.length - 1]);
  };
  // useEffect(() => {
  //   fetchReport();
  // }, []);




  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          width: "70%",
          height: "600px",
          padding: "15px",
          marginTop: "95px",
          marginBottom: "100px",
          borderRadius: "0px",
          borderTop: "none",
        }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Preview Purchase Order</h3>

        </div>

        <div style={{ overflow: "scroll" }}>
          <div
            // className="body"
            style={{
              width: " 99%",
              height: "97%",
              display: "flex",
              margin: "5px",
              flexDirection: "column",
              justifyContent: "space-between",
              // pageBreakBefore: "always",
            }}
          >
            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Tata_logo.svg/1200px-Tata_logo.svg.png"
                      style={{
                        width: "60px",
                        height: "60px",
                        marginBottom: "30px",
                      }}
                      alt="Car"
                    />
                  </span>
                  <span>{tentData.businessName}</span>
                  <span>
                    {" "}
                    {tentData?.locations?.map(
                      (item) =>
                        item.locationType === "Business" &&
                        item.businessContactNo
                    )}
                  </span>
                  <span>
                    {" "}
                    {tentData?.locations?.map(
                      (item) =>
                        item.locationType === "Business" && (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <span>{item.address1 + " , " + item.address2}</span>
                            <span>
                              {item.city +
                                " , " +
                                item.state +
                                " , " +
                                item.country}
                            </span>
                          </span>
                        )
                    )}
                  </span>
                  <span>PAN : {tentData?.pan}</span>
                  <span>GSTIN : {tentData?.gstin}</span>
                  {/* <span>
                    Website :
                    <span
                      style={{ color: "#0A18A3", textDecoration: "underline" }}
                    >
                      {"www.tatamotors.com"}
                    </span>
                  </span> */}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      marginTop: "40px",
                      fontSize: "25px",
                      marginBottom: "12px",
                    }}
                  >
                    PURCHASE ORDER
                  </span>
                  {/* <span>
                    PO No. :{" "}
                    <span style={{ color: "#0A18A3" }}>{orderData?.POID}</span>
                  </span> */}
                  <span>
                    PO Date : {moment(orderData?.PODate).format("MMM Do YY")}{" "}
                  </span>
                  <span>Requested by : {userName}</span>
                  <span>{orderData?.requestedBy}</span>
                </div>
              </div>

            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#0A18A3",
                  padding: "2px",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      marginLeft: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Vendor :
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    Bill To :
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      marginRight: "185px",
                      fontSize: "15px",
                    }}
                  >
                    Ship To :
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    paddingLeft: "9px",
                  }}
                >
                  {bomLocation === PoObj?.bomLocation && (
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontWeight: "400" }}>
                        {PoObj?.vendorName}
                      </span>
                      <span style={{ fontWeight: "400" }}>
                        Phone: <span>{PoObj?.vendorBusinessContactNo}</span>
                      </span>
                      <span style={{ fontWeight: "400" }}>
                        {PoObj?.vendorAddress1 + ", " + PoObj?.vendorAddress2}
                      </span>
                      <span style={{ fontWeight: "400" }}>
                        {PoObj.vendorCity}
                      </span>
                      {/* <span style={{ fontWeight: "400" }}>
                        {`PAN : ${PoObj?.panNo}`}
                      </span> */}
                      <span style={{ fontWeight: "400" }}>
                        {`GSTIN : ${PoObj?.gstin}`}
                      </span>
                    </span>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span style={{ fontWeight: "400" }}>
                    {tentData.businessName}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    Phone:{" "}
                    <span>
                      {" "}
                      {tentData?.locations?.map(
                        (item) =>
                          item.locationType === "Business" &&
                          item.businessContactNo
                      )}
                    </span>
                  </span>
                  <span style={{ fontWeight: "100" }}>
                    {" "}
                    {tentData?.locations?.map(
                      (item) =>
                        item.locationType === "Business" && (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <span>{item.address1 + " , " + item.address2}</span>
                            <span>
                              {item.city +
                                " , " +
                                item.state +
                                " , " +
                                item.country}
                            </span>
                          </span>
                        )
                    )}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    {`PAN : ${tentData?.pan}`}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    {`GSTIN : ${tentData?.gstin}`}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginRight: "100px",
                  }}
                >
                  <span style={{ fontWeight: "100" }}>{tentData.businessName}</span>
                  <span style={{ fontWeight: "100" }}>
                    {" "}
                    {tentData?.locations?.map(
                      (item) =>
                        item.locationType === "Storage" && (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <span>{`Phone : ${item.businessContactNo}`}</span>
                            <span>{item.address1 + " , " + item.address2}</span>
                            <span>
                              {item.city +
                                " , " +
                                item.state +
                                " , " +
                                item.country}
                            </span>
                          </span>
                        )
                    )}
                  </span>
                </div>
              </div>
            </section>

            <section style={{ width: "100%", flexGrow: 1 }}>
              <span style={{ display: "flex", alignItems: "start" }}>
                Order Details :{" "}
              </span>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#0A18A3",
                }}
              >
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "50px",
                      marginLeft: "5px",
                      padding: "2px",
                    }}
                  >
                    S.No
                  </span>
                </div>

                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "100px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    Vendor Part No.
                  </span>
                </div>

                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "250px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    Item Description
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "100px",
                      marginLeft: "10px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    Qty.
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "100px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    {`Unit Price(${auth.getUserInfo().currencySymbol})`}
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "100px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    HSN/SAC
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "100px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    {`Tax(${auth.getUserInfo().currencySymbol})`}
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {`Total (${auth.getUserInfo().currencySymbol})`}
                  </span>
                </div>
              </div>

              <div>
                {purchaseData.map((item, index) => (
                  <div
                    // id="POVendorContent"
                    style={{
                      display: "flex",
                      // justifyContent: "space-between",
                    }}
                    key={index}
                  >
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "50px",
                          marginLeft: "5px",
                        }}
                      >
                        {index + 1}
                      </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "100px",
                          marginLeft: "5px",
                        }}
                      >
                        {item.vendorPartCode}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "250px",
                          marginLeft: "2px",
                          marginRight: "2px",
                        }}
                      >
                        {item.componentName}
                      </span>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "250px",
                          marginLeft: "5px",
                        }}
                      >
                        {/* {specData[0]} */}
                        {bomComponents
  ?.map((elem) => 
    elem.specifications
      ?.map((spec) => {
        if (item.componentID === elem.componentID) {
          return `${spec.specsType} ${spec.specsValue} ${Array.isArray(spec?.specsUnit) ? "" : spec?.specsUnit}`;
        }
        return null; // Return null if the condition isn't met
      })
      .filter((spec) => spec !== null) // Filter out the null values
      .join(", ") // Join the filtered specifications with a comma
  )
}

                      </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "100px",
                          marginLeft: "10px",
                          marginRight: "2px",
                        }}
                      >
                        {item.reqComponentQty}
                      </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "100px",
                          marginLeft: "2px",
                          marginRight: "2px",
                        }}
                      >
                        {item.unitPrice}
                      </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "100px",
                          marginLeft: "2px",
                          marginRight: "2px",
                        }}
                      >
                        {item.hsnSacCode}
                      </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "100px",
                          marginLeft: "2px",
                          marginRight: "2px",
                        }}
                      >
                        {`${(item.unitPrice *
                            item.reqComponentQty *
                            item.taxRate) /
                          100
                          } (${item.taxRate}%)`}
                      </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "100px",
                          marginLeft: "2px",
                          marginRight: "2px",
                        }}
                      >
                        {item.unitPrice * item.reqComponentQty +
                          (item.unitPrice *
                            item.reqComponentQty *
                            item.taxRate) /
                          100}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </section>



            <section
              className="POterms"
              style={{
                justifyContent: "space-between",
                backgroundColor: "rgb(10, 24, 163)",
                margin: "12px 0px 12px 0px",
              }}
            ></section>



            <section
              className="POterms"
              style={{
                backgroundColor: "white",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div
                  style={{
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  Terms & Conditions :-
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    textAlign: "left",
                    display: "flex",
                    flex: "wrap",
                  }}
                >
                  <TermsView data="PROC. PO Terms" />
                </div>
              </div>

            </section>

            <section
              className="POterms"
              style={{
                justifyContent: "end",
                backgroundColor: "rgb(10, 24, 163)",
                margin: "12px 0px 0px 0px",
                color: "white",
              }}
            >
              {`Grand Total(${auth.getUserInfo().currencySymbol})`} :{" "}
              <span style={{ marginLeft: "3px" }}>
                {purchaseData.map((item, index) => {
                  return (
                    item.unitPrice * item.reqComponentQty +
                    (item.unitPrice * item.reqComponentQty * item.taxRate) /
                    100
                  );
                })}
              </span>
            </section>


            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span></span>
                <span style={{ marginTop: "70px", paddingRight: "23px" }}>
                  {" "}
                  Authorized Signatory
                </span>
              </div>
            </section>




          </div>
        </div>

        <div className="footer">
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
          <button
            style={{ marginRight: "0", width: "120px", height: "42px" }}
            onClick={generatePO}
            id="submit-button"
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Proceed
          </button>
          {inVoice ? (
            <POTemplateInvoice
              poData={myPOdata}
              productionQty={productionQty}
              bom={bom}
              PoObj={PoObj}
              purchaseData={purchaseData}
              closeModal={setInVoice}
              totalPrice={totalPrice}
              tentData={tentData}
              specData={specData}
              bomLocation={bomLocation}
            />
          ) : (
            " "
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrder;
