import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";

const Edit = ({ updatedData, editData, closeModal, setIsOpen}) => {
  const [formValue, setFormValue] = useState({
    componentName: editData.componentName,
    componentSKUCode: editData.skuCode,
    componentCategory: editData.category,
    componentSubCategory: editData.subCategory,
    hsnSacCode: editData.hsnSacCode,
    taxRate: editData.taxRate,
  });

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    console.log(formValue);
    let orders = {
      componentID: editData.componentID,
      hsnSacCode: formValue.hsnSacCode,
      taxRate: formValue.taxRate,
    };

    const resp = await trackPromise(
      API.put(
        `${process.env.REACT_APP_BASE_URL}/procurement-module/api/v1/hsnsac`,
        orders
      )
    );
    console.log(resp);
    if (resp.success) {
      closeModal(false);
      successNotification(resp.message, { autoClose: 3000 });
      refresh();
      setIsOpen(false);
    } else {
      errorNotification(resp.message, { autoClose: 3000 });
    }
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "810px", height: "349px" }}
      >
        <div className="header">
          <h3>Edit Details</h3>
        </div>

        <div className="body">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-115px",
                }}
              >
                Component Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="componentName"
                value={formValue.componentName}
                style={{ marginTop: "-5px", width: "auto" }}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Component Name"
                autoComplete="off"
                disabled
              />
            </div>

            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-162px",
                }}
              >
                SKU Code<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="componentSKUCode"
                value={formValue.componentSKUCode}
                style={{ marginTop: "-5px", width: "auto" }}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Component Sku Code"
                autoComplete="off"
                disabled
              />
            </div>

            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-96px",
                }}
              >
                Component Category<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="componentCategory"
                style={{ marginTop: "-5px", width: "auto" }}
                value={formValue.componentCategory}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Category"
                autoComplete="off"
                disabled
              />
            </div>

            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-72px",
                }}
              >
                Component Sub Category<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="componentSubCategory"
                value={formValue.componentSubCategory}
                style={{ marginTop: "-5px", width: "auto" }}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Sub Category"
                autoComplete="off"
                disabled
              />
            </div>

            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-130px",
                }}
              >
                HSN/SAC Code<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="hsnSacCode"
                defaultValue={formValue.hsnSacCode}
                style={{ marginTop: "-5px", width: "auto" }}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="HSN/SAC Code"
                autoComplete="off"
              />
            </div>

            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-169px",
                }}
              >
                Tax Rate<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                name="taxRate"
                defaultValue={formValue.taxRate}
                style={{ marginTop: "-5px", width: "auto" }}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Tax Rate (%)"
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default Edit;
