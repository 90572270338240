import React from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const RequisitionDetails = ({ closeModal, rowData }) => {
    const requisitionDetails = rowData?.requisitionDetails || [];
    const total = requisitionDetails.reduce(
        (accumulator, currentValue) => accumulator + parseInt(currentValue.requestedQuantity || 0, 10),
        0
    );

    return (
        <div className="container1">
            <div className="modalContainer" style={{ width: "1000px", height: "420px", padding: '15px' }}>
                <div className="header">
                    <h3>Spare Parts Requisition</h3>
                </div>
                <br />
                <div className="body" style={{ display: "flex", height: "450px", fontSize: "14px", overflow: 'scroll' }}>
                    <section style={{ width: '100%' }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requisition ID: {rowData?.requisitionID || 'N/A'}</span>
                            <span>Total Spare Requested: {requisitionDetails.length}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requisition Date: {moment(rowData?.requisitionDate).format("DD-MMM-YYYY/HH:mm")}</span>
                            <span>Total Units Requested: {total}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested To: {rowData?.requestedTo || 'N/A'}</span>
                        </div>
                        <br />
                        <div style={{ overflow: "scroll" }}>
                            <div id='POVendorHeading'>
                                <div style={{ width: '8%' }}>S. No.</div>
                                <div style={{ width: '13%' }}>Category</div>
                                <div style={{ width: '13%' }}>Sub Category</div>
                                <div style={{ width: '13%' }}>Spare Part Name</div>
                                <div style={{ width: '13%' }}>SKU Code</div>
                                <div style={{ width: '13%' }}>Specifications</div>
                                <div style={{ width: '13%' }}>Dealer Price</div>
                                <div style={{ width: '13%' }}>Requested Qty.</div>
                            </div>
                            <div>
                                {requisitionDetails.map((item, index) => (
                                    <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }} key={index}>
                                        <div style={{ width: '6%' }}>{index + 1}</div>
                                        <div style={{ width: '14%' }}>{item.category || 'N/A'}</div>
                                        <div style={{ width: '14%' }}>{item.subCategory || 'N/A'}</div>
                                        <div style={{ width: '14%' }}>{item.sparePartName || 'N/A'}</div>
                                        <div style={{ width: '14%' }}>{item.SKUCode || 'N/A'}</div>
                                        <div style={{ width: '15%' }}>
                                            {item.specifications 
                                                ? `${item.specifications.specsType || ''} ${item.specifications.specsValue || ''} ${item.specifications.specsUnit || ''}` 
                                                : 'N/A'}
                                        </div>
                                        <div style={{ width: '14%' }}>{item.dealerPrice || 'N/A'}</div>
                                        <div style={{ width: '13%' }}>{item.requestedQuantity || 0}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
                <div className="footer">
                    <div>
                        <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}>
                            <KeyboardBackspace style={{ fontSize: "25px" }} />Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequisitionDetails;
