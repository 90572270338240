import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification,errorNotification } from "../../../utils/notifications";

const ReassignWO = ({rowData,closeModal, updatedData}) => {
  console.log(rowData.orderID)

  const editDetails  = {orderID:rowData.orderID};

  const refresh = () => {
    updatedData()
};

  const submitHandler = async() => {
    const resp = await trackPromise(API.post(`production-module/api/v1/workOrder/reAssigned`, editDetails));
    
    const { success,message} = resp;
    
    if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        refresh()
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
   
  };
  return (
    <div className="container1">
      <div className="modalContainer" style={{width:"477px",height:"237px"}}>
        <div className="body" style={{flexDirection:"column",marginTop:"58px",fontSize:"18px"}}>
          <span>Are you sure to re-assign task </span>
          <span>to same shop floor manager?</span>
        </div>
        <div className="footer" style={{justifyContent:"center",marginBottom:"16px"}}>
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReassignWO;
