import React, { useState } from "react";
import Edit from "../../../components/modal/Edit";
import Reactivate from "../../../components/modal/Reactivate";
import Deactivate from "../../../components/modal/Deactivate";
import Delete from "../../../components/modal/Delete";
import Resetpswd from "../../../components/modal/Resetpswd";
import {
  faEdit,
  faBan,
  faTrash,
  faDotCircle,
  faAlignRight,
  faTicketAlt,
  faEye,
  faCross,
  faClosedCaptioning,
  faCheckCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../components/modal/modal.scss";
import ViewAndUpdate from "../Modals/ViewAndUpdate";
import PutOnHalt from "../Modals/PutOnHalt";
import RequestForSpares from "../Modals/RequestForSpares";
import ResumeJobcard from "../Modals/ResumeJobcard";
import { Cancel, Done } from "@material-ui/icons";
import ViewDetails from "../Modals/ViewDetails";
import JobClosed from "../Modals/JobClosed";
import ReOpenJob from "../Modals/ReOpenJob";
import ViewUpdateSpare from "../Modals/ViewUpdateSpare";
import ViewHistoryDetails from "../Modals/ViewHistoryDetails";
import ViewUpdateReOpen from "../Modals/ViewUpdateReOpen";
import ServiceInvoiceView from "../../Invoice/ServiceInvoiceView";
import EditJobCard from "../Modals/EditJobCard";
import PreviousJobCard from "./previousJobCard";
import ViewJobCard from "./ViewJobCard";
import ViewSparesRequest from "./ViewSparesRequest";

const Action = ({ updatedData, rowData, setIsOpen, rowIndex }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  console.log(rowData);

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      viewUpdate: false,
      viewSpareRequest: false,
      viewJobCard: false,
      jobClose: false,
      jobReopen: false,
      viewReopen: false,
      viewDetails: false,
      viewHistoryDetails: false,
      requestSpare: false,
      resetpswd: false,
      reactivate: false,
      deactivate: false,
      jobonHalt: false,
      delete: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  console.log("rowIndex", rowIndex);

  return (
    <div>
      {/* {activeModal.edit && showModal && (
        <ViewAndUpdate
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )} */}

      {rowData.status == "Job On-Halt"
        ? activeModal.jobonHalt &&
          showModal && (
            <ResumeJobcard
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )
        : ""}

      {rowData.status == "New Job Card"
        ? activeModal.edit &&
          showModal && (
            <EditJobCard
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )
        : ""}
      {rowData.status == "New Job Card"
        ? activeModal.requestSpare &&
          showModal && (
            <RequestForSpares
              closeModal={setShowModal}
              rowData={rowData}
              updatedData={updatedData}
              setIsOpen={setIsOpen}
            />
          )
        : ""}

      {rowData.status == "New Job Card" ||
      rowData.status == "Partial Spares Assigned" ||
      rowData.status == "Job In Progress" ||
      rowData.status == "Job Re-Opened"
        ? activeModal.jobonHalt &&
          showModal && (
            <PutOnHalt
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )
        : ""}

      {rowData.status == "Spares Requested"
        ? activeModal.viewDetails &&
          showModal && (
            <ViewDetails
              rowData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )
        : ""}

      {/* {rowData.status == "Partial Spares Assigned"
        ? activeModal.viewUpdate &&
        showModal && (
          <ViewUpdateSpare
            updatedData={updatedData}
            rowData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""} */}
      {rowData.status == "Partial Spares Assigned"
        ? activeModal.viewUpdate &&
          showModal && (
            <EditJobCard
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )
        : ""}


      { rowData.status == "Partial Spares Assigned" ||
        rowData.status == "Full Spares Assigned" ? 
        activeModal.viewSpareRequest &&
          showModal && (
            <ViewSparesRequest
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )
        : ""}

      {rowData.status == "Job In Progress" ||
      rowData.status == "Job Resumed" ||
      rowData.status == "Partial Spares Assigned" ||
      rowData.status == "New Job Card" ||
      rowData.status == "Partial Spares Assigned" ||
      rowData.status == "Job In Progress" ||
      rowData.status == "Job Closed" ||
      rowData.status == "Job Re-Opened" ||
      rowData.status == "Job On-Halt"
        ? activeModal.jobClose &&
          showModal && (
            <JobClosed
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )
        : ""}

      {/* {rowData.status == "Job Closed"
        ? activeModal.viewHistoryDetails &&
        showModal && (
          <ViewHistoryDetails
            updatedData={updatedData}
            rowData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""} */}
      {rowData.status == "Job Closed"
        ? activeModal.viewHistoryDetails &&
          showModal && (
            <ServiceInvoiceView
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )
        : ""}

      {rowData.status == "Job Closed"
        ? activeModal.jobReopen &&
          showModal && (
            <ReOpenJob
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )
        : ""}

      {rowData.status == "Job Re-Opened"
        ? activeModal.viewReopen &&
          showModal && (
            <ViewUpdateReOpen
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )
        : ""}

      {rowData.status == "New Job Card" ||
      rowData.status == "Partial Spares Assigned" ||
      rowData.status == "Full Spares Assigned" ||
      rowData.status == "Job In Progress" ||
      rowData.status == "Job Resumed" ||
      rowData.status == "Job Re-Opened"
        ? activeModal.viewJobCard &&
          showModal && (
            <ViewJobCard
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )
        : ""}

      <div
        className="action"
        style={{ position: "relative" }}
        onBlur={() => setIsOpen(false)}
      >
        <div
          className="action vendoraction"
          style={{
            marginLeft: "-50px",
            marginTop: rowIndex <= 4 ? "0px" : "-145px",
            width: "200px",
          }}
        >
          {rowData.status == "Job On-Halt" ? (
            <div>
              <span id="jobonHalt" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faTicketAlt} /> Resume Job Card
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status == "New Job Card" ||
          rowData.status == "Partial Spares Assigned" ||
          rowData.status == "Full Spares Assigned" ||
          rowData.status == "Job In Progress" ||
          rowData.status == "Job Resumed" ||
          rowData.status == "Job Re-Opened" ? (
            <div>
              <span id="viewJobCard" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEye} /> View Job Card
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status == "Partial Spares Assigned" ||
          rowData.status == "Full Spares Assigned" ? (
            <div>
              <span id="viewUpdate" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEye} /> View & Update
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status == "Partial Spares Assigned" ||
          rowData.status == "Full Spares Assigned" ? (
            <div>
              <span id="viewSpareRequest" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEye} /> View Spare Request
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status == "New Job Card" ||
          rowData.status == "Full Spares Assigned" ||
          rowData.status == "Job In Progress" ? (
            <div>
              <span id="edit" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Edit job Card
              </span>
            </div>
          ) : (
            ""
          )}
          {/* {rowData.status == "New Job Card" || rowData.status == "Job In Progress" ? (
            <div>
              <span id="edit" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEye} /> View Job Card
              </span>
            </div>
          ) 
          : (
            ""
          )} */}
          {/* {rowData.status == "New Job Card" || rowData.status == "Job In Progress" ? (
            <div>
              <span id="edit" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Edit Job Card
              </span>
            </div>
          ) 
          : (
            ""
          )} */}

          {rowData.status == "New Job Card" ? (
            <div>
              <span id="requestSpare" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faAlignRight} /> Request for Spares
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status == "Job Re-Opened" ? (
            <div>
              <span id="viewReopen" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEye} /> View Details
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status == "New Job Card" ||
          rowData.status == "Partial Spares Assigned" ||
          rowData.status == "Full Spares Assigned" ||
          rowData.status == "Job In Progress" ||
          rowData.status == "Job Resumed" ||
          rowData.status == "Job Re-Opened" ? (
            <div>
              <span id="jobonHalt" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faBan} /> Put On Halt
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status == "Spares Requested" ? (
            <div>
              <span id="viewDetails" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faBan} /> View Details
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status == "New Job Card" ||
          rowData.status == "Partial Spares Assigned" ||
          rowData.status == "Job In Progress" ||
          rowData.status == "Job Closed" ||
          rowData.status == "Job Re-Opened" ||
          rowData.status == "Job On-Halt" ||
          rowData.status == "Job In Progress" ||
          rowData.status == "Job Resumed" ? (
            <div>
              <span id="jobClose" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faBan} /> Close Job Card
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status == "Job Closed" ? (
            <div>
              <span id="viewHistoryDetails" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEye} />
                View Invoice
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status == "Job Closed" ? (
            <div>
              <span id="jobReopen" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faBan} /> Re-Open Job Card
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Action;
