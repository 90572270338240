import React, { useState } from "react";
import Edit from './Edit'
import Reactivate from "./Reactivate";
import Deactivate from "./Deactivate";
import Delete from "./Delete";
import Resetpswd from "./Resetpswd";
import { faEdit, faBan, faTrash, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./modal.scss";

const Action = ({ updatedData, rowData, setIsOpen,rowIndex }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      resetpswd: false,
      reactivate: false,
      deactivate: false,
      delete: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && (
        <Edit updatedData={updatedData} editData={rowData} closeModal={setShowModal} closeOn={setIsOpen} />
      )}
      {activeModal.resetpswd && showModal && (
        <Resetpswd rowData={rowData} closeModal={setShowModal} closeOn={setIsOpen}/>
      )}
      {
        rowData.status === "Active" || rowData.status === "Reactivated" ?
          activeModal.deactivate && showModal && (
            <Deactivate updatedData={updatedData} editData={rowData} closeModal={setShowModal} closeOn={setIsOpen} />
          )
          :
          activeModal.reactivate && showModal && (
            <Reactivate updatedData={updatedData} editData={rowData} closeModal={setShowModal} closeOn={setIsOpen}/>
          )
      }

      {activeModal.delete && showModal && <Delete updatedData={updatedData} editData={rowData} closeModal={setShowModal} closeOn={setIsOpen}/>}

      <div style={
        rowData.status === "Deactivated"?
        (rowIndex === 0 || rowIndex === 1 ? { position: "relative" } : { position: "relative" , marginTop:"-50px"}) 
        :(rowIndex === 0 || rowIndex === 1 ? { position: "relative" } : { position: "relative" , marginTop:"-148px"})}>
    <div className="vehicleaction" onBlur={() => setIsOpen(false)}>
        {
          rowData.status === "Deactivated" ?
            <div className="action vendoraction" style={{ marginLeft: "-30px", marginTop: "0px" }}>
              <div>
                <span id="reactivate" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faBan} /> Reactivate
                </span>
              </div>
              <div>
                <span id="delete" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faTrash} />  Delete
                </span>
              </div>
            </div>
            :
            <>
            <div>
                <span id="edit" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faEdit} />  Edit
                </span>
              </div>
              <div>
                <span id="resetpswd" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faEdit} />  Reset Password
                </span>
              </div>
              <div>
                {
                  rowData.status === "Active" || rowData.status === "Reactivated" ?
                    <span id="deactivate" onClick={(e) => clickHandler(e)}>
                      <FontAwesomeIcon icon={faBan} /> Deactivate
                    </span>
                    :
                    <span id="reactivate" onClick={(e) => clickHandler(e)}>
                      <FontAwesomeIcon icon={faDotCircle} /> Reactivate
                    </span>
                }
              </div>
              <div>
                <span id="delete" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faTrash} />  Delete
                </span>
              </div>
            </>
        }
      </div>
    </div>

    </div>
  );
};

export default Action;
