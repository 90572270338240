import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../../utils/api";
import "../../../../../components/modal/modal.scss";
import { successNotification, errorNotification } from "../../../../../utils/notifications";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const SubmitPop = ({ updatedData, rowData, closeModal, parentModal }) => {

console.log(rowData)

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    
    const resp = await trackPromise(
      API.post(`/salesUser-module/api/v1/spareReturns/placeToInventory`,{"requestID":rowData?.requestID})
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      parentModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "400px", height: "200px" }}>

        <div className="body">

          <h5>Are you sure to place return request to Inventory ? </h5>


        </div>
        <div className="footer" style={{ justifyContent: "center" }}>
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={submitHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubmitPop;
