import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
  ConfirmationNumber,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// import { notifyError, notifySuccess } from "../../../utils/notify";
import {
  faEdit,
  faBan,
  faShare,
  faPauseCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../components/modal/modal.scss";

const AvailabilityQty = ({ rowData, closeModal, extraData }) => {
  const [actionShow, setActionShow] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState();


  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Part S.No.",
      dataField: "partSno",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "PO No.",
      dataField: "poNo",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
  ];

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "500px", height: "350px", padding: "15px" }}
      >
        <div className="header" style={{ marginTop: "1px" }}>
          <h3>Added Qty.</h3>
        </div>
        <br />
        <div
          className="body"
          style={{
            display: "flex",
            height: "450px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          {/* <section style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Assigned Part S.No.: { }</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Spare Part Name: { }</span>
            </div>
            <br />
          </section> */}
          <br />
          <section style={{ width: "100%" }}>
            <div style={{ overflow: "scroll" }}>
              <div>
                <ToolkitProvider
                  keyField="id"
                  data={rowData.poDetails} // change this to rowData for final
                  columns={columns}
                  exportCSV
                >
                  {(props) => (
                    <div className="bootstraptbl">
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        rowClasses="text-nowrap"
                        rowStyle={{ border: "1px solid #CDCDCD" }}
                        keyField="id"
                        data={rowData.poDetails} // change this to rowData for final
                        columns={columns}
                        {...props.baseProps}
                        condensed
                        filterPosition="top"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </section>
        </div>
        <div className="footer">
          <div>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "25px" }} />
              &nbsp; Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailabilityQty;
