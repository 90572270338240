import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../../components/modal/modal.scss";
import moment from "moment";
import "../../../../pages/vendors/Vendors.scss";
import AppConstants from "../../../../utils/app-constants";
import SearchBox from "../../../../components/search/SearchBox";
import Action from "../Component/Action";
import RPIDetails from "./RPIDetails";
import GINDetails from "./GINDetails";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import auth from "../../../../utils/auth";

const NewRequest = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [rpiOpen, setRPIOpen] = useState(false);
  const [ginOpen, setGINOpen] = useState(false);
  const [open, setOpen] = useState();
  const [formValue, setFormValue] = useState({
    requestID: "",
    poNo: "",
    poBy: "",
    status: "",
    from: "",
    to: "",
  });

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`salesUser-module/api/v1/returns/getVehicles`)
    );
    console.log(resp);
    let filterData = resp.filter((item) => {
      return (
        item?.status == "Verification Completed" ||
        item?.status == "On Hold" ||
        item?.status == "Pending at Inventory" ||
        item?.status == "In-Progress at Inventory" ||
        item?.status == "Return Request Placed"
      );
    });
    setUsersData(filterData);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const uniquerequestID = [
    ...new Set(UsersData?.map((item) => item?.requisitionID)),
  ];
  const uniquepoNo = [...new Set(UsersData?.map((item) => item?.poNo))];
  const uniquepoBy = [...new Set(UsersData?.map((item) => item?.poBy))];
  const uniquestatus = [...new Set(UsersData?.map((item) => item?.status))];

  const statusFormatter = (cell, row, rowIndex) => {
    return cell === AppConstants.VERIFICATION_COMPLETED ? (
      <button className="active-btn" style={{ width: "100%" }}>
        <span className="active-btn-font">Verification Completed</span>
      </button>
    ) : cell === AppConstants.ON_HOLD ? (
      <button className="deactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="deactive-btn-font">On Hold</span>
      </button>
    ) : cell === AppConstants.PENDING_AT_INVENTORY ? (
      <button
        className="deactive-btn"
        style={{ backgroundColor: "#FFDBC7", width: "100%" }}
      >
        {" "}
        <span className="deactive-btn-font" style={{ color: "#A43E23" }}>
          Pending at Inventory
        </span>
      </button>
    ) : cell === AppConstants.IN_PROGRESS_AT_INVENTORY ? (
      <button
        className="deactive-btn"
        style={{ backgroundColor: "#D8F8FD", width: "100%" }}
      >
        {" "}
        <span className="deactive-btn-font" style={{ color: "#0A63A3" }}>
          In-Progress at Inventory
        </span>
      </button>
    ) : cell === AppConstants.RETURN_REQUEST_PLACED ? (
      <button className="reactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="reactive-btn-font">New Request</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const detailHandler = (row, rowIndex) => {
    setOpen(rowIndex);
    setRPIOpen(true);
  };

  const rpiDetailFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "14px",
            color: "blue",
            cursor: "pointer",
          }}
          onClick={() => detailHandler(row, rowIndex)}
        >
          {cell}
        </p>
        {open === rowIndex && rpiOpen ? (
          <RPIDetails rowData={row} closeModal={setRPIOpen} />
        ) : null}
      </div>
    );
  };

  const ginDetailHandler = (row, rowIndex) => {
    setOpen(rowIndex);
    setGINOpen(true);
  };

  const ginDetailFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "14px",
            color: "blue",
            cursor: "pointer",
          }}
          onClick={() => ginDetailHandler(row, rowIndex)}
        >
          {cell}
        </p>
        {open === rowIndex && ginOpen ? (
          <GINDetails rowData={row} closeModal={setGINOpen} />
        ) : null}
      </div>
    );
  };

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          <Action
            updatedData={fetchReport}
            rowData={row}
            setIsOpen={setIsOpen}
            rowIndex={rowIndex}
          />
        ) : null}
      </div>
    );
  };

  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = (e) => {
    setFormValue({
      requestID: "",
      poNo: "",
      poBy: "",
      status: "",
      from: "",
      to: "",
    });
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  function lastDateFormatter(cell) {
    let date = cell[0].date;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const formatToIndianCurrency = (cell, row, rowIndex, formatExtraData) => {
    return cell.toLocaleString("en-IN", { maximumFractionDigits: 0 });
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requestID",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Return By",
      dataField: "showroomName",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "RPI No.",
      dataField: "rpiNo",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { isOpen, rpiOpen },
      formatter: rpiDetailFormatter,
    },
    {
      text: "GIN No.",
      dataField: "ginNo",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { isOpen, ginOpen },
      formatter: ginDetailFormatter,
    },
    {
      text: "Return Qty",
      dataField: "returnQty",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: `Return Value (${auth.getUserInfo().currencySymbol})`,
      dataField: "totalAmount",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: formatToIndianCurrency,
    },
    {
      text: "Status",
      dataField: "status",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "Last Updated",
      dataField: "action",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: lastDateFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "100px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>New Request</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div
                    className="UHinputfields"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "135px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request ID
                        </label>
                        <select
                          name="requestID"
                          value={formValue.requestID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Request ID
                          </option>
                          {uniquerequestID.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          PO No.
                        </label>
                        <select
                          name="poNo"
                          value={formValue.poNo}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            PO No.
                          </option>
                          {uniquepoNo.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          PO By
                        </label>
                        <select
                          name="poBy"
                          value={formValue.poBy}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            PO By
                          </option>
                          {uniquepoBy.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {uniquestatus.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          From
                        </label>
                        <input
                          type={"date"}
                          name="from"
                          className="create-grn-date"
                          selected={formValue.from}
                          onChange={handleChange}
                          placeholderText="From"
                          dateFormat="DD-MM-YYYY/HH:mm"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          To
                        </label>
                        <input
                          type={"date"}
                          name="from"
                          className="create-grn-date"
                          selected={formValue.to}
                          onChange={handleChange}
                          placeholderText="To"
                          dateFormat="DD-MM-YYYY/HH:mm"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "end",
                          width: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <button
                          id="apply"
                          style={{ marginTop: "22px", marginLeft: "10px" }}
                          onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ marginTop: "22px", width: "auto" }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default NewRequest;
