import React, { useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import moment from "moment";
import ViewSpecs from "./ViewSpecs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Approve from "./Approve";

const ViewDetails = ({ closeModal, rowdata }) => {
  const [viewSpecs, setViewSpecs] = useState(false);
  const [viewApprove, setViewApprove] = useState(false);

  const value = () => {
    let count = rowdata.components.reduce(
      (n, { spareSubTotal }) => n + parseInt(spareSubTotal),
      0
    );
    return count;
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "850px", height: "550px", padding: "15px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>View Details</h3>
          <div>
            <button className="action-btn" style={{ color: "blue" }}>
              Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              Export
            </button>
          </div>
        </div>

        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
            margin: "10px 0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>RPI No: {rowdata.RPIno}</span>
            <span>Vendor Name: {rowdata.vendorName}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              RPI Date:{" "}
              {moment(rowdata.RPIDate).format("DD-MMM-YYYY/HH:mm")}
            </span>
            <span>Vendor Address: {rowdata.vendorLocation}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>RPI Raise By: {rowdata.RPIRaiseBy}</span>
          </div>

          {rowdata.status == "On Hold_On Hold" ? (
            <div className="onhold_div">
              <span className="holdDate">
                Hold Date:{" "}
                {moment(rowdata.holdDate).format("DD-MMM-YYYY/HH:mm")}
              </span>
              <span
                style={{ fontSize: "25px", color: "#CDCDCD", marginTop: "9px" }}
              >
                |
              </span>
              <span className="holdReason" style={{ right: "150px" }}>
                Hold By: {rowdata.holdBy}
              </span>
              <span
                style={{ fontSize: "25px", color: "#CDCDCD", marginTop: "9px" }}
              >
                |
              </span>
              <span className="holdReason" style={{ right: "150px" }}>
                Hold Reason: {rowdata.holdReason}
              </span>
            </div>
          ) : rowdata.status == "Completed_Completed" ? (
            <div className="onhold_div" style={{ backgroundColor: "#C0FCC6" }}>
              <span className="holdDate" style={{ color: "#11781B" }}>
                Approval Date:{" "}
                {moment(rowdata.approvalDate).format("DD-MMM-YYYY/HH:mm")}{" "}
              </span>
              <span
                style={{ fontSize: "25px", color: "gray", marginTop: "9px" }}
              >
                |
              </span>
              <span
                className="holdReason"
                style={{ right: "150px", color: "#11781B" }}
              >
                Approved By:{rowdata.approvalBy}
              </span>
            </div>
          ) : rowdata.status == "Rejected_Rejected" ? (
            <div className="onhold_div" style={{ backgroundColor: "#FFE1E1" }}>
              <span className="holdDate" style={{ color: "#E51111" }}>
                Rejection Date:{" "}
                {moment(rowdata.rejectedDate).format("DD-MMM-YYYY/HH:mm")}{" "}
              </span>
              <span
                style={{ fontSize: "25px", color: "#CDCDCD", marginTop: "9px" }}
              >
                |
              </span>
              <span
                className="holdReason"
                style={{ right: "150px", color: "red" }}
              >
                Rejection Reason:{rowdata.rejectedBy}
              </span>
            </div>
          ) : null}
        </section>

        <section>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "80%",
            }}
          >
            <span
              style={{
                display: "flex",
                background: "#DFE1F3",
                alignItems: "center",
                height: "30px",
                justifyContent: "center",
                borderRadius: "5px",
                color: "#0A18A3",
                width: "200px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Total Components : {rowdata.components.length}
            </span>
            <span
              style={{
                display: "flex",
                background: "#DFE1F3",
                alignItems: "center",
                height: "30px",
                justifyContent: "center",
                borderRadius: "5px",
                color: "#0A18A3",
                width: "200px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Total Returned Units :{" "}
            </span>
            <span
              style={{
                display: "flex",
                background: "#DFE1F3",
                alignItems: "center",
                height: "30px",
                justifyContent: "center",
                borderRadius: "5px",
                color: "#0A18A3",
                width: "200px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Total Value : {value(rowdata.components)}
            </span>
          </div>
        </section>

        <div
          className="body"
          style={{
            display: "flex",
            height: "300px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div id="POVendorHeading">
              <div style={{ width: "6%" }}>S.No.</div>
              <div style={{ width: "15%" }}>Component Name</div>
              <div style={{ width: "15%" }}>SKU Code</div>
              <div style={{ width: "15%" }}>Category</div>
              <div style={{ width: "15%" }}>Sub Category</div>
              <div style={{ width: "15%" }}>Vendor Part Code</div>
              <div style={{ width: "10%" }}>Returned Qty.</div>
              <div style={{ width: "9%" }}>Sub Total</div>
            </div>

            <div>
              {rowdata.components.map((item, index) => {
                console.log(item)
                return (
                  <>
                    <div
                      id="POVendorContent"
                      style={{
                        borderBottom: "1px solid #999999",
                        boxShadow: "none",
                        padding: "0px",
                      }}
                    >
                      <div style={{ width: "6%", overflowY: "auto" }}>
                        {index + 1}
                      </div>
                      <div style={{ width: "15%", overflowY: "auto" }}>
                        {item.componentName}
                      </div>
                      <div style={{ width: "15%", overflowY: "auto" }}>
                        {item.skuCode}
                      </div>
                      <div style={{ width: "15%", overflowY: "auto" }}>
                        {item.category}
                      </div>
                      <div style={{ width: "15%", overflowY: "auto" }}>
                        {item.subCategory}
                      </div>
                      <div style={{ width: "15%", overflowY: "auto" }}>
                        {rowdata.vendorPartCode}
                      </div>
                      <div
                        onClick={() => setViewSpecs(true)}
                        style={{
                          width: "10%",
                          overflowY: "scroll",
                          color: "blue",
                          cursor: "pointer",
                        }}
                      >{rowdata.returnedQuantity}</div>
                      <div style={{ width: "9%", overflowY: "auto" }}>
                        {item.spareSubTotal}
                      </div>
                    </div>
                  </>
                );
              })}

              {viewSpecs && (
                <ViewSpecs rowData={rowdata} closeModal={setViewSpecs} />
              )}
            </div>
          </section>
        </div>

        <div className="footer">
          <div>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "25px" }} />
              Back
            </button>
            <button
              style={{
                width: "104px",
                height: "42px",
                color: "#fff",
                background: "#0A18A3",
              }}
              onClick={() => setViewApprove(true)}
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "16px" }}
              />{" "}
              Approve
            </button>
            {viewApprove && (
              <Approve rowData={rowdata} closeModal={setViewApprove} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
