import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import Group14 from "../QcDashboard/assets/Group14.svg";
import Group15 from "../QcDashboard/assets/Group15.svg";
import Vector from "../QcDashboard/assets/Vector.svg";
import Vector3 from "../QcDashboard/assets/Vector3.svg";
import moment from "moment";
// import Vector3 from '../QcDashboard/assets/Vector3';

function ShopFloorInventoryDash({ startDate, endDate }) {
  const [data, setData] = useState({});
  const [wipData, setwipData] = useState({});
  const fetchData = async () => {
    const res = await trackPromise(
      API.get(
        startDate == null && endDate == null
          ? `shop-floor-module/api/v1/dashBoard/finishedGoods`
          : `shop-floor-module/api/v1/dashBoard/finishedGoods?startDate=${moment(
            startDate
          ).format("DD-MMM-YYYY")}& endDate=${moment(endDate).format(
            "DD-MMM-YYYY"
          )}`
      )
    );
    setData(res);
  };

  useEffect(() => {
    fetchData();
  }, [endDate]);

  const fetchReport = async () => {
    const res = await trackPromise(
      API.get(
        startDate == null && endDate == null
          ? `shop-floor-module/api/v1/dashBoard/wip`
          : `shop-floor-module/api/v1/dashBoard/wip?startDate=${moment(
            startDate
          ).format("DD-MMM-YYYY")}& endDate=${moment(endDate).format(
            "DD-MMM-YYYY"
          )}`
      )
    );
    console.log(res);
    setwipData(res);
  };

  useEffect(() => {
    fetchReport();
  }, [endDate]);
  return (
    <div className="requestBody">
      <div className="request" style={{ width: "320px", height: "90px", justifyContent: "center" }}>
        <img src={Group15} style={{ marginLeft: "8px" }} alt="group" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "430",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span
              style={{
                fontSize: "17px",
                fontWeight: "500",
                margin: "8px 0px 0px 10px",
              }}
            >
              WIP Inventory
            </span>
          </div>
          <div
            style={{ fontSize: "26px", fontWeight: "500", marginLeft: "37px" }}
          >
            {wipData.totalWIPInventory}&nbsp;
            {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {wipData.totalWIPInventoryChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${wipData.totalWIPInventoryChange}`}</span>
                </>
              ) : wipData.totalWIPInventoryChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${wipData.totalWIPInventoryChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${wipData.totalWIPInventoryChange}`}</span>
                </>
              )}
            </span> */}
          </div>
        </div>
      </div>
      <div className="request" style={{ width: "320px", height: "90px", justifyContent: "center" }}>
        <img src={Group14} style={{ marginLeft: "8px" }} alt="group" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "430",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span
              style={{
                fontSize: "17px",
                fontWeight: "500",
                margin: "8px 0px 0px 10px",
              }}
            >
              Finished Goods
            </span>
          </div>
          <div
            style={{ fontSize: "26px", fontWeight: "500", marginLeft: "50px" }}
          >
            {data.totalFinishedGoods}&nbsp;
            {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {data.totalFinishedGoodsChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.totalFinishedGoodsChange}`}</span>
                </>
              ) : data.totalFinishedGoodsChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.totalFinishedGoodsChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${data.totalFinishedGoodsChange}`}</span>
                </>
              )}
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopFloorInventoryDash;
