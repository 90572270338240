import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AppConstants from "../../utils/app-constants";
import BomViewandUpdate from "./approval-actions/bom-viewandupdate";
import SearchBox from "../../components/search/SearchBox";
import RecommendIcon from "@mui/icons-material/Recommend";
import handleExport from "../../utils/exportExcel";

// const { ExportCSVButton } = CSVExport;

const BomNewRequests = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [openPending, setOpenPending] = useState(false);
  const [openOnhold, setOpenOnhold] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    requestID: "",
    requestType: "",
    requestedBy: "",
    requestCategory: "",
    requestDate: "",
    status: "",
  });

  const [row, setRow] = useState();

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(
        `management/api/v1/bom/newrequest?tabName=${"newRequest"}&BOMID=${formValue.requestID}&bomTypes=${formValue.requestType}&requestedBy=${formValue.requestedBy}&createdDate=${formValue.requestDate}&status=${formValue.status}`
      )
    );
    console.log(res);

    const tempArr = res.filter((item, index) => {
      return (
        item.status == AppConstants.PENDING_AT_MANAGEMENT ||
        item.status == "Pending At Management" ||
        item.status == AppConstants.ON_HOLD_BY_MANAGEMENT
      );
    });
    console.log(tempArr);

    setData(tempArr);
  };

  useEffect(() => {
    fetchData();
  }, []);



  const uniquerequestID = [...new Set(data.map(item => item.BOMID))];
  const uniquerequestType = [...new Set(data.map(item => item.bomTypes))];
  const uniquerequestedBy = [...new Set(data.map(item => item.bomApprovalReqBy))];
  const uniquestatus = [...new Set(data.map(item => item.status))];




  console.log(data);
  const statusFormatter = (cell) => {
    return cell === AppConstants.PENDING_AT_MANAGEMENT ||
      "Pending At Management" ? (
      <button className="delete-btn">
        <span className="delete-btn-font">Pending</span>
      </button>
    ) : cell === AppConstants.ON_HOLD_BY_MANAGEMENT ? (
      <button className="deactive-btn">
        <span className="deactive-btn-font">On Hold</span>
      </button>
    ) : (
      cell
    );
  };

  const actionHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    if (row.status == AppConstants.PENDING_AT_MANAGEMENT) {
      setOpenPending(true);
    } else {
      setOpenOnhold(true);
    }
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          onClick={() => actionHandler(row, rowIndex)}
          style={{
            color: "#0A18A3",
            backgroundColor: "white",
            border: "1px solid #0A18A3",
            borderRadius: "3px",
          }}
        >
          View and Update
        </button>
        {/* {actionShow === rowIndex && open ? (
          <ViewDetailsManagedWO rowData={row} status={true} close={setOpen} />
        ):null} */}
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const reqByFormatter = (cell, row, rowIndex) => {
    return (
      <>
        <span>{row.bomApprovalReqBy}</span>
      </>
    );
  };

  const emptyDataMessage = () => {
    return (
      <>
        <span style={{ fontSize: "15px" }}>
          All Requests are Processed! <br /> <RecommendIcon />
        </span>
      </>
    );
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          data.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "BOMID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Request Type",
      dataField: "bomTypes",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    // {
    //   text: "Request Category",
    //   dataField: "bomRequestCategory",
    //   headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    //   headerAttrs: { width: "200px" },
    // },
    {
      text: "Requested By",
      dataField: "requestedBy",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: reqByFormatter,
    },
    {
      text: "Request Date",
      dataField: "createdDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: dateFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatter: statusFormatter,
    },
    // {
    //   text: "Reason",
    //   dataField: "reason",
    //   headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    //   headerAttrs: { width: "200px" },
    // },
    // {
    //   text: "Action Date",
    //   dataField: "actiondate",
    //   headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    //   headerAttrs: { width: "150px" },
    //   formatter: dateFormatter,
    // },
    {
      text: "Action",
      // dataField: "title",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, openPending, openOnhold },
      formatter: actionFormatter,
    },
  ];

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      requestID: "",
      requestType: "",
      requestedBy: "",
      requestCategory: "",
      requestDate: "",
      status: "",
    });
    const res = await trackPromise(API.get(`management/api/v1/bom/newrequest`));
    const tempArr = res.filter((item, index) => {
      return (
        item.status == AppConstants.PENDING_AT_MANAGEMENT ||
        item.status == "Pending At Management" ||
        item.status == AppConstants.ON_HOLD_BY_MANAGEMENT
      );
    });
    setData(tempArr);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  if (openPending == true) {
    return (
      <BomViewandUpdate
        updatedData={fetchData}
        rowdata={row}
        close={setOpenPending}
      />
    );
  } else if (openOnhold == true) {
    return (
      <BomViewandUpdate
        updatedData={fetchData}
        rowdata={row}
        close={setOpenOnhold}
      />
    );
  } else {
    return (
      <>
        <div>
          <div id="overlay" onClick={(e) => off(e)}></div>
          <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
            <span>New Requests</span>
          </div>

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div>
                  <div style={{ float: "right", marginTop: "-39px" }}>
                    <div
                      style={{
                        display: "flex",
                        marginRight: "-15px",
                        height: "26px",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "220px" }}>
                        <SearchBar
                          {...props.searchProps}
                          placeholder="Search"
                          style={{
                            height: "25px",
                            // border: "1px solid #C6C6C6",
                            border: "none",
                            background: "white",
                            borderRadius: "3px",
                            marginRight: "-50px",
                          }}
                        />
                      </div>
                      <div onClick={clearFilter} className="filter-refresh">
                        <FontAwesomeIcon icon={faRedoAlt} />
                        &nbsp; Refresh
                      </div>
                      <div className="filter-refresh" onClick={handleClick}>
                        {" "}
                        <FontAwesomeIcon icon={faFilter} />
                        &nbsp; Filters
                      </div>
                      <div
                        className="filter-refresh"
                        onClick={() => handleExport(data, "new request")}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp; Export
                      </div>
                      {inDashboard ? (
                        <div
                          className="filter-refresh"
                          onClick={() => inDashboard(false)}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  {show && (
                    <>
                      <form
                        onSubmit={submitHandler}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          height: "135px",
                          backgroundColor: "#F7F7F7",
                          padding: "5px",
                        }}
                        className="UHinputfields VMform"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Request ID
                          </label>
                          <select
                            name="requestID"
                            value={formValue.requestID}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Request ID
                            </option>
                            {
                              uniquerequestID.map((item)=>(
                                <option>{item}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Request Type
                          </label>
                          <select
                            name="requestType"
                            value={formValue.requestType}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Request Type
                            </option>
                            {
                              uniquerequestType.map((item)=>(
                                <option>{item}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Requested By
                          </label>
                          <select
                            name="requestedBy"
                            value={formValue.requestedBy}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Requested By
                            </option>
                            {
                              uniquerequestedBy.map((item)=>(
                                <option>{item}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Request Date
                          </label>
                          <input
                            type="date"
                            onFocus={(e) => (e.currentTarget.type = "date")}
                            onBlur={(e) => (e.currentTarget.type = "text")}
                            name="requestDate"
                            value={formValue.requestDate}
                            onChange={handleChange}
                            placeholder="Request Date"
                            autoComplete="off"
                            style={{ marginTop: "-5px", width: "100%" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Status
                          </label>
                          <select
                            name="status"
                            value={formValue.status}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Status
                            </option>
                            {
                              uniquestatus.map((item)=>(
                                <option>{item}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div
                          style={{
                            width: "22%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <button
                            onClick={fetchData}
                            style={{ width: "100px", marginTop: "24px" }}
                            id="apply"
                          >
                            Apply
                          </button>
                          <button
                            style={{ width: "100px", marginTop: "24px" }}
                            onClick={clearFilter}
                            id="clear"
                          >
                            Clear Filter
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                  <br></br>
                </div>

                <BootstrapTable
                  wrapperClasses="table-responsive"
                  // rowClasses="text-nowrap"
                  rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                  pagination={paginationFactory({
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    hideSizePerPage: true,
                    paginationSize: 2,
                    sizePerPage: "10",
                  })}
                  noDataIndication="No Data Found"
                  {...props.baseProps}
                />
              </>
            )}
          </ToolkitProvider>
        </div>
      </>
    );
  }
};
export default BomNewRequests;
