import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import "../../../../components/modal/modal.scss";
import {
  successNotification,
  errorNotification,
} from "../../../../utils/notifications";
import auth from "../../../../utils/auth";

const EditServiceCenterUsers = ({ updatedData, editData, closeModal, setIsOpen }) => {
  const [formValue, setFormValue] = useState({
    role: editData.role,
    fullName: editData.fullName,
    email: editData.email,
  });

  const editDetails = {
    userID: editData.userID,
    fullName: formValue.fullName,
    email: formValue.email,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(`serviceCenter-module/api/v1/user/editUser`, editDetails)
    );
    const { success, message } = resp;
    if (success) {
      closeModal(false);
      setIsOpen(false);
      refresh();
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Edit User Details</h3>
        </div>

        <div className="body">
          <label style={{display:"flex", flexDirection:"column"}}>
          <label style={{textAlign:"start"}}>Role</label>
            <select
              disabled
              name="role"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              value={editData.role}
              style={{marginTop:"-5px"}}
            >
              <option>Service Advisor</option>
              <option>Store Manager</option>
            </select>
          </label>

          <label style={{display:"flex", flexDirection:"column"}}>
           <label style={{textAlign:"start"}}>Full Name</label>
            <input
              type="text"
              name="fullName"
              defaultValue={editData.fullName}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Full Name"
              autoComplete="off"
              style={{marginTop:"-5px"}}
            />
          </label>
          <label style={{display:"flex", flexDirection:"column"}}>
          <label style={{textAlign:"start"}}>Email</label>
            <input
              type="text"
              name="email"
              defaultValue={editData.email}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Email"
              autoComplete="off"
              style={{marginTop:"-5px"}}
            />
          </label>
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditServiceCenterUsers;
