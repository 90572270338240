import { KeyboardBackspace } from '@material-ui/icons';
import React from 'react'

const ViewSpecs = ({ closeModal, specs }) => {
    console.log(specs.specifications)
    return (
        <div className="container1">
            <div className="modalContainer" style={{ height: '325px',padding:'15px',justifyContent:'flex-start'}}>
                <div className="header" style={{ width: '100%',justifyContent:'flex-start' }}>
                    <h3>Component Specifications</h3>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start',width: '100%', }}>
                    <h6>Component Name: {specs.componentName}</h6>
                    <h6>Component SKU Code: {specs.skuCode}</h6>
                </div>

                <div className="body" style={{ display: 'flex',justifyContent:'space-between', fontSize: "14px",overflow:'scroll', width:"100%" }}>
                    {
                        specs.specifications.map((item,id) => (
                            <div key={id} style={{padding:'0px 30px 0px 0px',margin:'5px 0' }}>{`Component ${item.specsType} : ${item.specsValue}${item.specsUnit}`}</div>
                        ))
                    }
                </div>
                <div className="footer" style={{ width: '100%',justifyContent:'flex-end' }}>
                    <button onClick={() => closeModal(false)}><KeyboardBackspace/> Back</button>
                </div>
            </div>
        </div>
    );
};

export default ViewSpecs