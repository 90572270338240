import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import auth from "../../utils/auth";
import { KeyboardBackspace } from "@material-ui/icons";

const EditLocation = ({ closeModal }) => {
    const [showVerify, setVerify] = useState(false);
  
    return (
        <>
            <div className="container1">
                <div
                    className="modalContainer"
                    style={{ width: "465px", height: "400px", padding: "15px" }}
                >
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>View Operations Location</h3>
                    </div>

                    <div className="body" style={{ height: "340px", marginTop: "25px", boxShadow:"0px 0px 5px grey", display:"flex", flexDirection:"column", justifyContent:"start", padding:"3%", fontSize:"14px", fontWeight:"500"}}>
                     <span>Location ID: 2323234</span>
                     <span>Location Name: 2323234</span>
                     <span>Address 1: 2323234</span>
                     <span>Address(Optional): 2323234</span>
                     <span>Country: 2323234</span>
                     <span>State/Province: 2323234</span>
                     <span>City: 2323234</span>
                     <span>Contact Number: 2323234</span>
                     <span>Time Zone: 2323234</span>
                    </div>

                    <br />
                    <div className="footer">
                    <button onClick={() => closeModal(false)}> <KeyboardBackspace /> Back</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditLocation;