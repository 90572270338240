import React from 'react';
import { Tabs } from 'react-simple-tabs-component';
import { TabLabels } from '../../utils/app-constants';
import "../settings-tabs/tabs.scss";
// import SpareParts from './components/SpareParts';

const SpareParts = React.lazy(()=>import('./components/SpareParts'))


const StoreManagerSpares = () =>{

    const tabs = [
        {
        id:"STMM1T1",
        label:TabLabels.SPARE_PARTS,
        Component:SpareParts
        }
    ]

    return(
        <div className='app-layout__card'>
            <Tabs type="pills" tabs={tabs} />
        </div>
    )
}

export default StoreManagerSpares