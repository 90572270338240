import { CancelOutlined, CheckCircle, KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import moment from "moment";
import CNShare from "./CNShare";

const CNGeneratedDetails = ({ rowData, closeModal }) => {

    const [cnShare,setCNShare]=useState()
    


  return (
    <div className="container1">
    {
      cnShare ? <CNShare closeModal={closeModal}/> :""
    }
      <div
        className="modalContainer"
        style={{ width: "600px", height: "450px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>CN Generated</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div>Request ID: {rowData.requestID}</div>
              <div>Request Date: {moment(rowData.requestDate).format("DD-MM-YYYY")}</div>
              <div>Request By: {rowData.rpiRaisedBy}</div>
              <div>Request ID: {rowData.requestID}</div>
              <div>Request Date: {moment(rowData.requestDate).format("DD-MM-YYYY")}</div>
              <div>Request By: {rowData.rpiRaisedBy}</div>
            </section>
          </section>
          <br/>
         
         <section style={{width:"99%",display:"flex", flexDirection:"column", border:"1px solid #EEEEEE", textAlign:"start", justifyContent:"start", marginTop:"2%", padding:"2%"}}>
              <div>RPI No: {rowData?.rpiNo}</div>
              <div>RPI Raised By: {rowData?.rpiRaisedBy}</div>
              <div>GIN No: {rowData?.ginNo}</div>
              <div>CN No: {rowData?.cnNo}</div>
              <div>CN Doc: {rowData?.cnDoc}</div>
              <div>CN Date: {rowData?.cnDate}</div>
         </section>
        </div>

        <div className="footer" style={{ justifyContent: "end" }}>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: "white",
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
            onClick={()=>setCNShare(true)}
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

export default CNGeneratedDetails;
