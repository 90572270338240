import React, { useState } from "react";
import { Switch } from "react-router-dom";
import DashboardHeader from "./components/header";
import Sidebar from "../../components/shared/sidebar";
import { RouteWithSubRoutes } from "../../components/routes";
import "../../components/modal/modal.scss";
import "./main-app.scss";
import auth from "../../utils/auth";
import ResetPassword from "../../components/modal/firstLoginModal";

const MainApp = ({ routes }) => {
  const [showModal, setShowModal] = useState(true);

  return (
    <div className="app-layout d-flex min-vh-100 mw-100">
      {auth.getUserInfo().hashotp === null ? showModal && <ResetPassword closeModal={setShowModal} /> :
        <>
          <Sidebar /><section className="flex-grow-1 flex-column app-layout__main main-content-area app-layout__card">
            <DashboardHeader />
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </section>
        </>
      }
    </div>
  );
};

export default MainApp;