import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { ArrowRightAlt } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import "../settings-tabs/homesettings.scss";
import "./Inward.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddReceivedQty from "./AddReceivedQty";
import GenerateGRN from "./action/GenerateGRN";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";
import moment from "moment";
import { setDate } from "date-fns";

const CreateGRN = () => {
  const [nextPage, setNextPage] = useState(true);
  const [openGRN, setOpenGRN] = useState(false);
  const [selectedPO, setSelectedPO] = useState({
    receivedDate: new Date(),
  });
  const [receivedBy, setReceivedBy] = useState();
  const [receivedDate, setReceivedDate] = useState();
  const [purchaseOrder, setPurchaseOrder] = useState([]);
  const [receivedQuantity, setReceivedQty] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [remarks, setRemarks] = useState("");
  const [render, setRender] = useState(false)

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`inventory-module/api/v1/grn/getAllPO`)
    );
    console.log(resp)
    const filteredData = resp.filter((item) => {
      return item?.status !== "Rejected By Finance" && item?.status !== "On Hold By Finance";
    });


    console.log(filteredData)
    resp.message == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setPurchaseOrder(filteredData);
  };
  useEffect(() => {
    fetchReport();
  }, [render]);

  const handleInputChange = (event) => {
    setNextPage(true);
    const PO = purchaseOrder.filter((item) => item.POID == event.target.value);
    setSelectedPO(PO[0]);
    setReceivedBy("");
    setReceivedDate("");
    document.getElementById("receivedDate").value = "";
    setRemarks("");
  };

  const receivedQty = (Qty) => {
    console.log(Qty);
    setSelectedPO((prevPO) => ({
      ...prevPO,
      components: Qty,
      receivedBy: receivedBy,
      receivedDate: startDate, // Use startDate directly
    }));
  };

  console.log(purchaseOrder)

  const filteredPOID = purchaseOrder.filter((item) => {
    return (
      item.totalReceivedQuantity == null ||  
      item.totalRequiredQuantity == null ||  
      item.totalReceivedQuantity < item.totalRequiredQuantity  
    );
  });
  
  
  
console.log(filteredPOID)
  const [receivedDateChanged, setReceivedDateChanged] = useState(false);
  const [remarksAdded, setRemarksAdded] = useState(false);
  const [remarksEnabled, setRemarksEnabled] = useState(false);

  const onReceivedDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    setStartDate(selectedDate);
    // Set receivedDateChanged to true when received date is changed
    setReceivedDateChanged(true);
    setRemarksEnabled(true);
  };

  const handleReceivedByChange = (e) => {
    const receivedByValue = e.target.value;
    setReceivedBy(receivedByValue);
    setSelectedPO((prevPO) => ({
      ...prevPO,
      receivedBy: receivedByValue,
    }));
  };

  const handleRemarksChange = (e) => {
    setSelectedPO({
      ...selectedPO,
      remarks: e.target.value,
    });
    // Set remarksAdded to true when remarks are added
    setRemarksAdded(e.target.value.trim() !== "");
  };

  const refresh = () => {
    setSelectedPO({});
    setReceivedBy("");
    setReceivedDate("");
    document.getElementById("poId").value = "";
    document.getElementById("date").value = "";
    document.getElementById("receivedBy").value = "";
    document.getElementById("remarks").value = "";
    fetchReport()


    setRender(prev => !prev)
  };

  const onGenerateGrnClick = () => {
    const hasComponentSno = selectedPO.components.every(
      (item) => Array.isArray(item.componentSno) && item.componentSno.length > 0
    );
    if (hasComponentSno) {
      setOpenGRN(true);
    } else {
      errorNotification("Please Add the Part Serial Numbers");
    }
  };

  return (
    <>
      <div className="bussiness">
        <h4 className="heading">Create GRN</h4>
      </div>

      <div>
        <small>Add GRN Details</small>
        <hr />

        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <div
            style={{
              margin: "0px 5px 0px 5px",
              width: "30%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "7px",
              }}
            >
              PO ID<span style={{ color: "red" }}>*</span>
            </label>
            <select
              required={true}
              name="POID"
              id="poId"
              className="input-config"
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              defaultValue={selectedPO.POID}
              onChange={(event) => handleInputChange(event)}
            >
              <option selected disabled value={""}>
                PO ID
              </option>
              {filteredPOID.map((item) => (
                <option>{item.POID}</option>
              ))}
            </select>
          </div>
          <div
            style={{
              margin: "0px 5px 0px 5px",
              width: "30%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "7px",
              }}
            >
              PO Date<span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="input-config"
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              type="text"
              name="PODate"
              defaultValue={
                selectedPO.PODate
                  ? moment(selectedPO.PODate).format("DD-MM-YYYY")
                  : ""
              }
              // defaultValue={selectedPO.PODate}
              placeholder="PO Date"
              disabled
            />
          </div>
          <div
            style={{
              margin: "0px 5px 0px 5px",
              width: "30%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "5px",
              }}
            >
              Vendor Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="input-config"
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              type="text"
              name="vendorName"
              defaultValue={selectedPO.vendorName}
              placeholder="Vendor Name"
              disabled
            />
          </div>
          <div
            style={{
              margin: "0px 5px 0px 5px",
              width: "30%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "4px",
              }}
            >
              Vendor City<span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="input-config"
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              type="text"
              name="vendorCity"
              defaultValue={selectedPO.vendorCity}
              placeholder="Vendor Location"
              disabled
            />
          </div>
        </div>

        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          {/* <div
            style={{
              margin: "0px 5px 0px 5px",
              width: "30%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "7px",
              }}
            >
              Vendor Contact<span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="input-config"
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              type="text"
              name="vendorContact"
              defaultValue={selectedPO.vendorContact}
              placeholder="Vendor Contact Number"
              disabled
            />

          </div> */}
          <div
            style={{
              margin: "0px 5px 0px 5px",
              width: "24%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "7px",
              }}
            >
              Delivery Location<span style={{ color: "red" }}>*</span>
            </label>
            <input
              required={true}
              name="deliveryLocation"
              className="input-config"
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              defaultValue={selectedPO.deliveryLocation}
              placeholder="Delivery Location"
              // onChange={(event) => handleChange(event)}
              disabled
            />
            {/* <option selected disabled hidden>
            Delivery Location
          </option>
          
        </select> */}
          </div>
          <div
            style={{
              margin: "0px 5px 0px 5px",
              width: "24%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "5px",
              }}
            >
              Received By<span style={{ color: "red" }}>*</span>
            </label>

            <input
              required
              id="receivedBy"
              disabled={Object.keys(selectedPO).length !== 0 ? false : true}
              className="input-config"
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              type="text"
              name="receivedBy"
              value={receivedBy}
              placeholder="Received By"
              autoComplete="off"
              onChange={(e) => handleReceivedByChange(e)}
            />
          </div>
          <div
            style={{
              margin: "0px 5px 0px 5px",
              width: "24%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "4px",
              }}
            >
              Goods Received Date<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="date"
              id="date"
              disabled={Object.keys(selectedPO).length !== 0 ? false : true}
              className="input-config create-grn-date"
              name="receivedDate"
              onChange={(e) => onReceivedDateChange(e)}
              value={startDate.toISOString().substr(0, 10)} // Display startDate in input
              max={new Date().toISOString().substr(0, 10)}
              style={{ marginTop: "-8px", width: "100%" }}
            />
          </div>
          <div
            style={{
              margin: "0px 5px 0px 5px",
              width: "24%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "4px",
              }}
            >
              Remarks<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              id="remarks"
              disabled={
                !(
                  Object.keys(selectedPO).length !== 0 &&
                  receivedDateChanged &&
                  remarksEnabled
                )
              }
              className="input-config create-grn-date"
              name="remarks"
              onChange={handleRemarksChange}
              defaultValue={remarks}
              placeholder="Remarks"
              style={{ marginTop: "-8px", width: "100%" }}
            />
          </div>
        </div>
        <br />

        {nextPage ? (
          startDate && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "180px 10px 0",
              }}
            >
              <button
                style={{
                  background: "#0A18A3",
                  border: "1px solid #0A18A3",
                  borderRadius: "3px",
                  padding: "6px 25px",
                  color: "white",
                }}
                onClick={() => {
                  if (receivedDateChanged && !remarksAdded) {
                    // If received date is changed and remarks are empty, show error message
                    errorNotification("Please fill remarks to proceed.");
                  } else {
                    // Otherwise, proceed to next page
                    setNextPage(false);
                  }
                }}
              >
                <ArrowRightAlt />
                Next
              </button>
            </div>
          )
        ) : (
          <div>
            <AddReceivedQty selectedPO={selectedPO} receivedQty={receivedQty} />
            <div
              className="footer"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                style={{
                  background: "#0A18A3",
                  border: "1px solid #0A18A3",
                  borderRadius: "3px",
                  padding: "6px",
                  marginRight: "0px",
                  width: "135px",
                  color: "white",
                  fontSize: "14px",
                }}
                onClick={() => onGenerateGrnClick()}
              >
                <FontAwesomeIcon
                  style={{ fontSize: "14px", margin: "0px 2px" }}
                  icon={faEdit}
                />
                Generate GRN
              </button>
              {openGRN && (
                <GenerateGRN
                  selectedPO={selectedPO}
                  closeModal={setOpenGRN}
                  nextPage={setNextPage}
                  receivedQuantity={receivedQuantity}
                  refresh={refresh}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateGRN;
