import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import "../../work-order/workorder.scss";

const WoViewLabours = ({ close, labordata }) => {

  const [viewSpecs, setViewSpecs] = useState();
  const [display, setDisplay] = useState(false);

  console.log(labordata)
  const convertToDays = (duration, unit) => {
    console.log(duration, unit);
    switch (unit.toLowerCase()) {
      case "day":
      case "days":
        return duration;
      case "week":
      case "weeks":
        return duration * 7;
      case "month":
      case "months":
        return duration * 30; // Approximate
      case "year":
      case "years":
        return duration * 365; // Approximate
      case "minute":
      case "minutes":
        return duration / 1440; // Convert minutes to days
      case "hour":
      case "hours":
        return duration / 24; // Convert hours to days
      default:
        return 0; // Handle unknown unit
    }
  };
  const calculateAvgProductionPerDay = (productionQuantity, requiredDuration, laborDuration) => {
    console.log(productionQuantity, requiredDuration, laborDuration);
    const totalDays = convertToDays(requiredDuration, laborDuration);
    console.log("Total Days:", totalDays);
    return totalDays > 0 ? productionQuantity / totalDays : 0;
  };

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "1170px", height: "521px", padding: "15px" }}
        >

          <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Required Labours</h3>
            {/* <div>
              <button
                className="action-btn"
                style={{ color: "blue" }}>
                Print
              </button>
              <button
                className="action-btn"
                style={{ color: "blue" }}>
                Export
              </button>
            </div> */}
          </div>

          <div className="body" style={{ height: "340px", flexWrap: "nowrap" }}>


            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                  {/* <div style={{ display: "flex" }}>
                    <label htmlFor="lbrcount" className="wo_labels" style={{ marginRight: "20px" }}>
                      <input type="radio"
                        name="labor"
                        id="lbrcount"
                        checked={display == false}
                        onClick={e => setDisplay(false)}
                        style={{ width: "15px" }}
                      />
                      View By Labour Count</label>

                    <label htmlFor="lbrtime" className="wo_labels" >
                      <input type="radio"
                        name="labor"
                        id="lbrtime"
                        // checked={display == true}
                        onClick={e => setDisplay(true)}
                        style={{ width: "15px" }}
                      />
                      View By Labour Time</label>
                  </div> */}
              </div>

          
  <table className="viewcomp_tbl" style={{ width: "100%", fontSize: "15px"}}>
    <thead>
      <tr>
        <th>S.No.</th>
        <th>Labour Type</th>
        <th>Labour Level</th>
        <th>BOM Labour Count</th>
        <th>Required Labour Count</th>
        <th>Required Labour Time</th>
        <th>Avg Labour/day</th>
        <th>Avg Labour Time/day</th>
        <th>Avg Production/day</th>
      </tr>
    </thead>
    <tbody>
      {labordata?.bomLabor?.map((item, index) => {
        const avgProductionPerDay = calculateAvgProductionPerDay(
          labordata?.productionQuantity,
          item?.requiredDuration,
          item?.laborDuration
        );
        return (
          <tr key={index}>
            <td className="leftcell">{index + 1}</td>
            <td>{item?.laborCategory}</td>
            <td>{item?.laborLevel}</td>
            <td>{item?.requiredWorkers}</td>
            <td>{item?.requiredWorkers * labordata.productionQuantity}</td>
            <td>{item?.requiredDuration + " " + item?.laborDuration}</td>
            <td>{((item?.requiredWorkers * labordata.productionQuantity) * item?.avgLaborCost)}</td>
            <td>{((item?.requiredWorkers * labordata.productionQuantity)* 8) + " Hrs."}</td>
            <td className="rightcell">{avgProductionPerDay.toFixed(2)}</td>
          </tr>
        );
      })}
    </tbody>
  </table>


            </div>

          </div>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}><KeyboardBackspace /> Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WoViewLabours;