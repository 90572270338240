import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";

const Spares = React.lazy(()=>import("./spares"))


const tabs = [
  {
    id:"SVCM2T1",
    label: TabLabels.SPARES,
    Component: Spares
  }
];

const ServiceCenterSpares = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default ServiceCenterSpares;
