import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";

import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
  ConfirmationNumber,
} from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import API from "../../../utils/api";
import ViewSparesRequestUnit from "./ViewSparesRequestUnit";
import { errorNotification, successNotification } from "../../../utils/notifications";

const ViewSparesRequest = ({ closeModal, count, rowData, updatedData }) => {
  const [open, setOpen] = useState(false);
  const [sparesData, setSparesData] = useState([]);

  let mergedSpareParts = {};
  let combinedParts = (rowData?.inspectionsChecks?.spares || [])
                    .concat(rowData?.additionalSpares || []);


  combinedParts.forEach(part => {
    if (mergedSpareParts[part.skuCode]) {
      mergedSpareParts[part.skuCode].requiredUnits += parseInt(part.requiredUnits);
    } else {
      mergedSpareParts[part.skuCode] = { ...part, requiredUnits: parseInt(part.requiredUnits) };
    }
  });

  let newArray = Object.values(mergedSpareParts);
  console.log(newArray);
  const [array, setArray] = useState(newArray);
  const [selectedArray, setSelectedArray] = useState([]);
  const [rowIndex, setIndex] = useState();
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [sku, setSku] = useState("");
  const [skuRevert, setSkuRevert] = useState("");

  useEffect(() => {
    setSelectedUnit(array.length);
    let tempArray = [];
    array.map((el) => {
      tempArray.push({
        partSno: [],
      });
    });
    setSelectedArray(tempArray);
  }, []);

  let bodyData = {
    jobID: "",
    status: "",
    assignedSpareParts: [],
  };

  console.log("selectedArray",selectedArray, "apiData", apiData)
  let data = [];

  const submitHandler = async (status) => {
    // Initialize bodyData
    let bodyData = {
      jobID: rowData?.jobID,
      status: status,
      assignedSpareParts: [],
    };
  
    // Loop through the array (which contains assigned spare parts details)
    array.forEach((item, index) => {
      const selectedPartSno = selectedArray[index]?.partSno; // Get the selected part S.No array from selectedArray
  
      // Initialize the spares array
      const spares = [];
  
      // If there are selected spare parts (non-empty array), iterate and push the details
      if (selectedPartSno && selectedPartSno.length > 0) {
        selectedPartSno.forEach(part => {
          spares.push({
            partSno: part?.partSno,  // Access the partSno directly
            poNo: part?.poNo,        // Get poNo from the selected part
            invoiceNo: part?.invoiceNo // Get invoiceNo from the selected part
          });
        });
      }
  
      // Push the spare part details into assignedSpareParts
      bodyData?.assignedSpareParts?.push({
        skuCode: item?.skuCode,
        sparePartname: item?.sparePartname,
        category: item?.category,
        subCategory: item?.subCategory,
        requiredUnits: item?.requiredUnits,
        spares:spares // Spares array added to partSno key
      });
    });
  
    console.log(bodyData); // Output the constructed bodyData object
  
    // API call to update assigned spare units
    const resp = await trackPromise(
      API.put(`storeManager-module/api/v1/spareRequests/assignedSpareUnit`, bodyData)
    );
  
    const { success, message } = resp;
  
    // Handle response from the API
    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      updatedData(); // Update the UI data
    } else {
      errorNotification(message, { autoClose: 2000 });
    }
  };
  
  console.log(selectedArray);

  let totalPendingValue = 0;
  array?.map((item, index) => {
    totalPendingValue += item?.requiredUnits - selectedArray[index]?.partSno?.length;
  })

  console.log(totalPendingValue);
  console.log(sparesData);




  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1200px", height: "420px", padding: "15px" }}
      >
        <div className="header" style={{ marginTop: "-10px" }}>
          <h3>View & Assign Spares</h3>
        </div>
        <br />
        <div
          className="body"
          style={{
            display: "flex",
            height: "450px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span
                style={{ fontSize: "13px", fontWeight: "500" }}
              >
                Job Card ID: {rowData?.jobID}
              </span>
              <span style={{ fontSize: "13px", fontWeight: "500" }}>
                Service Advisor: {rowData?.serviceAdvisorName}
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontSize: "13px", fontWeight: "500" }}>
                Job Card Date:{" "}
                {moment(rowData?.serviceDetails?.jobDate).format("DD-MM-YYYY")}
              </span>
              <span style={{ fontSize: "13px", fontWeight: "500" }}>
                Technician Name: {rowData?.serviceDetails?.technicianName}
              </span>
            </div>
            
            <br />
            <div style={{ overflow: "scroll" }}>
              <div id="POVendorHeading">
                <div style={{ width: "10%" }}>S. No.</div>
                <div style={{ width: "20%" }}>SKU Code</div>
                <div style={{ width: "20%" }}>Spare Part Name</div>
                <div style={{ width: "20%" }}>Category</div>
                <div style={{ width: "20%" }}>Sub-Category</div>
                <div style={{ width: "20%" }}>Required Qty.</div>
                <div style={{ width: "20%" }}>Spare Unit</div>
                <div style={{ width: "20%" }}>Assigned Spare Unit</div>
                <div style={{ width: "20%" }}>Pending Unit</div>
              </div>
              <div>
                {array?.map((item, index) => {
                  return (
                    <div
                      id="POVendorContent"
                      style={{
                        borderBottom: "1px solid #999999",
                        boxShadow: "none",
                        padding: "0px",
                      }}
                    >
                      <div style={{ width: "10%", overflow: "scroll" }}>
                        {index + 1}
                      </div>
                      <div style={{ width: "20%", overflow: "scroll" }}>
                        {item?.skuCode}
                      </div>
                      <div style={{ width: "20%", overflow: "scroll" }}>
                        {item?.componentName}
                      </div>
                      <div style={{ width: "20%", overflow: "scroll" }}>
                        {item?.category}
                      </div>
                      <div style={{ width: "20%", overflow: "scroll" }}>
                        {item?.subCategory}
                      </div>
                      <div style={{ width: "20%", overflow: "scroll" }}>
                        {item?.requiredQty}
                      </div>
                      <div
                        style={{
                          width: "20%",
                          overflow: "scroll",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpen(true);
                          setIndex(index);
                          setSku(item?.skuCode);
                        }}
                      >
                        View
                      </div>
                      <div style={{ width: "20%", overflow: "scroll" }}>
                        {<span>{selectedArray[index]?.partSno?.length}</span>}
                      </div>
                      <div style={{ width: "20%", overflow: "scroll" }}>
                        {<span>{item?.requiredQty  - selectedArray[index]?.partSno?.length}</span>}
                      </div>
                    </div>
                  );
                })}
                {open && (
                  <ViewSparesRequestUnit
                    // setSelectedArray={setSelectedArray}
                    selectedArray={selectedArray}
                    // rowData={rowData}
                    index={rowIndex}
                    // array={array}
                    closeModal={setOpen}
                    setSparesData={setSparesData}
                    apiData={setApiData}
                    sku={sku}
                  // skuRevert={setSkuRevert}
                  />
                )}
              </div>
            </div>
          </section>
        </div>

        <div
          className="footer"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "50%", textAlign: "start" }}>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "20px" }} />
              Back
            </button>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default ViewSparesRequest;