import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";

const StandardavailableQuantity = ({ row, closeModal }) => {
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "580px", height: "480px", padding: '15px' }}
            >
                 <div className="header" style={{ width: '100%', display: "flex", justifyContent: "flex-start" }}>
                    <h3>Vehicle Quantity</h3>
                </div>

                <div
                    className="body"
                    style={{ width: '100%', display: "flex", fontSize: "14px" }}
                >


                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between",padding:'0px' }}>
                        <span>Segment : {row.segment}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between",padding:'0px' }}>
                            <span>Vehicle Model & Variant : {`${row.vehicleModel} & ${row.vehicleVariant}`}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between",padding:'0px' }}>
                            <span>Total Qty : {""}</span>
                        </div>

                    </section>

                    <section
                        id="nr_POno"
                        style={{
                            width: '100%',
                            fontSize: "14px",
                            margin: "10px 0",
                            border: 'none'
                        }}
                    >
                        <div style={{ padding: '0px',border: '1px solid #cdcdcd' }}>
                            <span style={{ width: '20%', padding: '10px',borderRight: '1px solid #cdcdcd' }}>Color</span>
                            <span style={{ width: '80%', padding: '10px' }}>Available Qty.</span>
                        </div>
                        <section style={{width:"100%", height: '180px', overflow: 'scroll' }}>
                        {row.availableQuantity.map((item, index) => (
                            <div style={{display:'flex', padding: '0px',border: '1px solid #cdcdcd',borderTop:'none' }}>
                                <span style={{ width: '20%', padding: '10px',borderRight: '1px solid #cdcdcd'}}>{item.color}</span>
                                <span style={{ width: '80%', padding: '10px'}}>{item.qty}</span>
                            </div>
                        ))}
                        </section>

                    </section>

                </div>

                <div className="footer" style={{ width: '100%', display: "flex", justifyContent: "flex-end" }}>
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
    );
};

export default StandardavailableQuantity; 