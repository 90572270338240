import React from "react";
import { Link } from "react-router-dom";
import { HOME_ROUTE_URL } from "../../components/routes/route-urls"
import images from "../../utils/get-images";

const NotFoundPage = () => {
  return (
    <section className="error-page p-4" >
      <div className="container">
        <div className="error-content-wrapper text-center">
          <div className="error-content">
            <img src={images["dialog/warning.svg"]} alt="error" />
            <h2 className="error-title">Page Not Found</h2>
            <p>We re unable to find the page you re looking for.</p>
            <Link className="pix-btn" to={HOME_ROUTE_URL}>
              Go Home
            </Link>
          </div>
          {/* /.error-content */}
        </div>
        {/* /.error-content-wrapper */}
      </div>
      {/* /.container */}
    </section>
  );
};

export default NotFoundPage;
