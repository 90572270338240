import React, { useState } from "react";
import {
    KeyboardBackspace,
    CheckCircle,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import VR_NR_Action from "./VR_NR_Action";
import GINno from "./GINno";
import VR_POno from "./VR_POno";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { errorNotification, successNotification } from "../../../utils/notifications";
import moment from 'moment';

const VR_NR_Details = ({ closeModal, rowData, tableRefresh }) => {
    const [actionShow, setActionShow] = useState();
    const [showGIN, setShowGIN] = useState(false);
    const [showPO, setShowPO] = useState(false);

    const sendToVerification = async () => {
        const resp = await trackPromise(API.post(`inventory-module/api/v1/vehicleReturns`, { "requestID": rowData.requestID }));
        resp.success == true ? successNotification(resp.message) : errorNotification(resp.message);
        closeModal(-1);
        tableRefresh();
    };

    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "1180px", height: "425px", padding: '15px' }}
            >
                <div className="header">
                    <h3>View and Update</h3>
                </div>

                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested ID: {rowData.requestID}</span>
                            <span>RPI No. : {rowData.rpiNo}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested By: {rowData.showroomEmail}</span>
                            <span>RPI Date: {moment(rowData.rpiDate).format("DD-MMM-YYYY/HH:mm")}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested Date: {moment(rowData.requestDate).format("DD-MMM-YYYY/HH:mm")}</span>
                            <span>RPI Raise By : {rowData.showroomName}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>GIN No. : <span>{rowData.ginNo}</span>
                                {/* {showGIN && <GINno closeModal={setShowGIN} />} */}
                            </div>
                            {/* <span>{rowData.RPIAddress}</span> */}
                        </div>
                        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>PO No. : <span
                                onClick={() => setShowPO(true)} 
                                style={{ color: '#0A18A3', cursor: 'pointer' }}>{rowData.poNo}</span>
                                {showPO && <VR_POno closeModal={setShowPO} />}
                            </div>
                        </div> */}

                    </section>

                    <section style={{ width: '100%' }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '50px' }}>S.No.</div>
                            <div style={{ width: '100px' }}>Segment</div>
                            <div style={{ width: '80px' }}>Category</div>
                            <div style={{ width: '70px' }}>Model</div>
                            <div style={{ width: '70px' }}>Variant</div>
                            <div style={{ width: '70px' }}>Type</div>
                            <div style={{ width: '70px' }}>Color</div>
                            <div style={{ width: '160px' }}>VIN</div>
                            <div style={{ width: '150px' }}>BIN</div>
                            <div style={{ width: '150px' }}>Return Reason</div>
                            <div style={{ width: '160px' }}>Status</div>
                        </div>
                        <div>
                            {
                                rowData.returnedData.map((item, index) => (<div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }} key={index}>
                                    <div style={{ width: '50px' }}>{index + 1}</div>
                                    <div style={{ width: '100px' }}>{item.vehicleSegment}</div>
                                    <div style={{ width: '80px' }}>{item.vehicleCategory}</div>
                                    <div style={{ width: '70px' }}>{item.vehicleModel}</div>
                                    <div style={{ width: '70px' }}>{item.vehicleVariant}</div>
                                    <div style={{ width: '70px' }}>{item.vehicleType}</div>
                                    <div style={{ width: '70px' }}>{item.vehicleColor}</div>
                                    <div style={{ width: '160px' }}>{item.VIN}</div>
                                    <div style={{ width: '150px' }}>{item.BIN}</div>
                                    <div style={{ width: '150px' }}>{item.returnReason}</div>
                                    <div style={{ width: '160px', padding: "4px" }}>{
                                        item.status == "Pending Verification" ?
                                            <button className="deactive-btn" style={{ height: "22px" }}> <span className="deactive-btn-font" style={{ fontSize: "13px" }}>Verification In-Progress </span></button> :
                                            item.status == "New" || item.status == null || item.status == undefined ?
                                                <button className="reactive-btn" style={{ height: "22px" }}> <span className="reactive-btn-font" style={{ fontSize: "13px" }}>New</span></button> :
                                                item.status == "Passed" ?
                                                    <button className="active-btn" style={{ height: "22px" }}> <span className="active-btn-font" style={{ fontSize: "13px" }}>Verification Passed</span></button> :
                                                    item.status == "Failed" ?
                                                        <button className="delete-btn" style={{ height: "22px" }}> <span className="delete-btn-font" style={{ fontSize: "13px" }}>Verification Failed</span></button> :
                                                        item.status}
                                    </div>
                                </div>))
                            }
                        </div>
                    </section>

                </div>

                <div className="footer">
                    {rowData.status == "New Request" || rowData.status == "Pending at Inventory" ? <button style={{ marginRight: '0', width: '170px', height: '42px' }} id="submit-button" onClick={() => sendToVerification()}><CheckCircle style={{ fontSize: "17px" }} /> Send For Verification</button> : ""}
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(-1)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
    );
};

export default VR_NR_Details;