import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";
import moment from "moment";

const GINDetails = ({ closeModal, rowData }) => {
  // const UsersData = row.requisitionDetails
  console.log(rowData)
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "50%", height: "450px", padding: "10px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>GIN Details</h3>
          <div>
            <button className="action-btn" style={{ color: "blue" }}>
              <PrintIcon />
              &nbsp;Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              <IosShareIcon />
              &nbsp;Export
            </button>
          </div>
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding:7,
          }}
        >
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            GIN No: {rowData?.ginNo}
          </span>
          <span
            style={{ width: "50%", textAlign: "start", justifyContent: "end" }}
          >
            GIN Date: {moment(rowData?.ginDate).format("DD-MM-YYYY")}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            GIN Raised By: {rowData?.ginRaisedBy}
          </span>
         
        </section>
        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ color: "blue" }}>Shipment Details</h6>
            </div>
            <div style={{display:"flex",flexDirection:"column",borderWidth:"3px",alignItems:"start",margin:"3px", border:"1px solid #E6E6E6", borderRadius:"5px", padding:"2%"}}>
               <h7>Shipped From: {rowData?.shipmentFrom}</h7>
               <h7>Shipment Spares: {rowData?.shipmentSpares}</h7>
               <h7>Shipment Units: {rowData?.shipmentUnit}</h7>
               <h7>Shipment Date: {moment(rowData?.shipmentDate).format("DD-MM-YYYY")}</h7>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ color: "blue" }}>Delivery Details</h6>
            </div>
            <div style={{display:"flex",flexDirection:"column",borderWidth:"3px",alignItems:"start",margin:"3px", border:"1px solid #E6E6E6", borderRadius:"5px", padding:"2%"}}>
               <h7>Delivery Vehicle Number:{rowData?.deliveryVehicleNo}</h7>
               <h7>Delivery Person:{rowData?.deliveryPersonName}</h7>
               <h7>Delivery Person Contact No:{rowData?.personName}</h7>
            </div>
          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <KeyboardBackspace /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default GINDetails;
