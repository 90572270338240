import React from "react";
import { useState, useEffect } from "react";
import VehicleBarChart from "../QcDashboard/components/VehicleBarChart";
import VehicleReBarChart from "../QcDashboard/components/VehicleReBarChart";
import TableView from "../QcDashboard/components/TableView";
import Inventory from "./components/Inventory";
import VehiclesView from "./components/VehiclesView";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { DatePicker } from "antd";
import { BsArrowUpRight } from "react-icons/bs";
import ManageWorkorder from "../work-order/manage-workorder";
import ManageBom from "../bom/manage-bom";
import { useHistory } from "react-router-dom";
import ProdManageVehicles from "../vehicles/ProcManageVehicles";
import ProdManageAssembly from "../assembly/ProdManageAssembly";
import Group4 from '../QcDashboard/assets/Group4.png'
import Group5 from '../QcDashboard/assets/Group5.png'
import Group6 from '../QcDashboard/assets/Group6.png'
import Group from '../QcDashboard/assets/Group.png'
import Group2 from '../QcDashboard/assets/Group2.png'

function ProductionDashboard() {
  const [assemblyData, setAssemblyData] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const { RangePicker } = DatePicker;
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [openWOTable, setOpenWOTable] = useState(false);
  const [openBOMTable, setOpenBOMTable] = useState(false);
  const [openInvTable, setOpenInvTable] = useState(false);
  const [opensfTable, setOpensfTable] = useState(false);
  const [openVehicleTable, setOpenVehicleTable] = useState(false);
  const [openAssemblyTable, setOpenAssemblyTable] = useState(false);

  let history = useHistory();

  function on() {
    document.getElementById("overlay").style.display = "block";
  };

  function off() {
    setCalenderOpen(false);
    document.getElementById("overlay").style.display = "none";
    refresh();
    blank();
    empty();
    update();
  }
  // BOM
  const onChange = (dates) => {
    if (dates != null) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };

  const refresh = () => {
    setStartDate(null);
    setEndDate(null);
    document.getElementById("bomcalender").style.display = "none";
    document.getElementById("bomcalendericon").style.display = "block";
    document.getElementById("bomcalendericon").style.justifyContent = "center";
    document.getElementById("bomcalendericon").style.textAlign = "center";
  }
  const show = () => {
    document.getElementById("bomcalender").style.display = "block";
    document.getElementById("bomcalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }
  // Inventory
  const [invStartDate, setInvStartDate] = useState(null);
  const [invEndDate, setInvEndDate] = useState(null);

  const onInvChange = (dates) => {
    const [start, end] = dates;
    setInvStartDate(start);
    setInvEndDate(end);
  };
  const blank = () => {
    setInvStartDate(null);
    setInvEndDate(null);
    document.getElementById("inventorycalender").style.display = "none";
    document.getElementById("inventorycalendericon").style.display = "block";
    document.getElementById("inventorycalendericon").style.justifyContent = "center";
    document.getElementById("inventorycalendericon").style.textAlign = "center";
  }
  const shows = () => {
    document.getElementById("inventorycalender").style.display = "block";
    document.getElementById("inventorycalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  // ShopFloor
  const [sfStartDate, setSfStartDate] = useState(null);
  const [sfEndDate, setSfEndDate] = useState(null);

  const onSfChange = (dates) => {
    const [start, end] = dates;
    setSfStartDate(start);
    setSfEndDate(end);
  };
  const empty = () => {
    setSfStartDate(null);
    setSfEndDate(null);
    document.getElementById("shopfloorcalender").style.display = "none";
    document.getElementById("shopfloorcalendericon").style.display = "block";
    document.getElementById("shopfloorcalendericon").style.justifyContent = "center";
    document.getElementById("shopfloorcalendericon").style.textAlign = "center";
  }
  const visible = () => {
    document.getElementById("shopfloorcalender").style.display = "block";
    document.getElementById("shopfloorcalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  // Vehicles
  const [vehicleStartDate, setVehicleStartDate] = useState(null);
  const [vehicleEndDate, setVehicleEndDate] = useState(null);

  const onVehicleChange = (dates) => {
    const [start, end] = dates;
    setVehicleStartDate(start);
    setVehicleEndDate(end);
  };
  const update = () => {
    setVehicleStartDate(null);
    setVehicleEndDate(null);
    document.getElementById("vehiclecalender").style.display = "none";
    document.getElementById("vehiclecalendericon").style.display = "block";
    document.getElementById("vehiclecalendericon").style.justifyContent = "center";
    document.getElementById("vehiclecalendericon").style.textAlign = "center";
  }
  const display = () => {
    document.getElementById("vehiclecalender").style.display = "block";
    document.getElementById("vehiclecalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  const fetchAssemblyData = async () => {
    const resp = await trackPromise(
      API.get(`production-module/api/v1/dashboard/getAllAssemblies`)
    );
    setAssemblyData(resp);
  };
  useEffect(() => {
    fetchAssemblyData();
  }, []);

  const [showModal, setShowModal] = useState(false);

  const [data, setData] = useState({})
  // const [openWOTable, setOpenWOTable] = useState(false);

  const fetchData = async () => {
    const resp = await trackPromise(API.get(`production-module/api/v1/dashboard/getAllWorkOrders`))
    setData(resp)
    console.log(resp)
  }
  useEffect(() => {
    fetchData();
  }, [])


  return (
    <>
      {openWOTable == true ? <ManageWorkorder inDashboard={setOpenWOTable} /> :
        openBOMTable == true ? <ManageBom inDashboard={setOpenBOMTable} /> :
          openInvTable == true ? <ManageBom inDashboard={setOpenInvTable} /> :
            opensfTable == true ? <ManageBom inDashboard={setOpensfTable} /> :
              openVehicleTable == true ? <ProdManageVehicles inDashboard={setOpenVehicleTable} /> :
                openAssemblyTable == true ? <ProdManageAssembly inDashboard={setOpenAssemblyTable} /> :
                  <div className="dashboardBody">
                    <div id="overlay" onClick={(e) => off(e)}></div>
                    {showModal && <TableView closeModal={setShowModal} />}
                    <div className="firstRow" style={{ height: "85%" }}>
                      <div className="container-new">
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "15px", marginLeft: "15px", height: "18px" }}>
                            <div><h5 style={{ fontSize: "18px", width: "150px" }}>Work Order</h5></div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", marginRight: "20px" }}>
                              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "25px", height: "25px", border: "1px Solid #808080", color: "#808080", borderRadius: "5px", cursor: "pointer" }}>
                                <BsArrowUpRight onClick={() => setOpenWOTable(true)} />
                              </div>
                            </div>

                          </div>
                          <hr />
                          <div style={{ display: "flex", width: "95%", marginLeft: "10px", flexDirection: "column", marginTop: "10px" }}>

                            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <img style={{ marginTop: "-10px", height: "40px" }} src={Group} />&nbsp;&nbsp;
                                <span >Assigned Work Order</span>
                              </div>
                              <div style={{ fontSize: "18px" }}>
                                {data?.assignedWO}
                                {/* &nbsp;{data?.assignedWOChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.assignedWOChange}`}</span></> : data?.assignedWOChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.assignedWOChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data?.assignedWOChange}`}</span></>} */}
                              </div>
                            </div>
                            <hr />
                            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <img style={{ marginTop: "-10px", height: "40px" }} src={Group2} />&nbsp;&nbsp;
                                <span>Accepted Work Order</span>
                              </div>
                              <div>
                                {data?.acceptedWO}
                                {/* &nbsp;{data?.acceptedWOChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.acceptedWOChange}`}</span></> : data?.acceptedWOChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.acceptedWOChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data?.acceptedWOChange}`}</span></>} */}
                              </div>
                            </div>
                            <hr />
                            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <img style={{ marginTop: "-10px", height: "40px" }} src={Group4} />&nbsp;&nbsp;
                                <span>In-Progress Work Order</span>
                              </div>
                              <div>
                                {data?.inProgressWO}
                                {/* &nbsp;{data?.inProgressWOChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.inProgressWOChange}`}</span></> : data?.inProgressWOChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.inProgressWOChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data?.inProgressWOChange}`}</span></>} */}
                              </div>
                            </div>
                            <hr />
                            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <img style={{ marginTop: "-10px", height: "40px" }} src={Group5} />&nbsp;&nbsp;
                                <span>Completed Work Order</span>
                              </div>
                              <div>
                                {data?.completedWO}
                                {/* &nbsp;{data?.completedWOChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.completedWOChange}`}</span></> : data?.completedWOChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.completedWOChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data?.completedWOChange}`}</span></>} */}
                              </div>
                            </div>
                            <hr />
                            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <img style={{ marginTop: "-10px", height: "40px" }} src={Group6} />&nbsp;&nbsp;
                                <span>Rejected Work Order</span>
                              </div>
                              <div>
                                {data?.rejectedWO}
                                {/* &nbsp;{data?.rejectedWOChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.rejectedWOChange}`}</span></> : data?.rejectedWOChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.rejectedWOChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data?.rejectedWOChange}`}</span></>} */}
                              </div>
                            </div>
                            <hr />
                          </div>
                        </div>
                      </div>
                      <div className="container-new" style={{ marginLeft: "20px" }}>
                        <div
                          className="header"
                          style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                        >
                          <div>
                            <h5 style={{ fontSize: "18px" }}>BOM</h5>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            {/* <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                marginRight: "5px"
                              }}
                              onClick={refresh}
                            >
                              <FaRedoAlt />
                            </div>
                            <span id="bomcalendericon" style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }} onClick={show}>
                              <FontAwesomeIcon
                                icon={faCalendar}
                                style={{ color: "#808080" }}
                              />
                            </span>
                            <label id="bomcalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                              <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                <RangePicker
                                  startDate={startDate}
                                  endDate={endDate}
                                  value={[startDate, endDate]}
                                  onChange={onChange}
                                  format="DD-MM-YYYY"
                                  size="small"
                                />
                              </Space>
                            </label> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                cursor: "pointer"
                              }}
                            >
                              <BsArrowUpRight onClick={() => setOpenBOMTable(true)} />
                            </div>
                          </div>
                        </div>
                        <div>
                          <VehicleBarChart
                            props="production"
                            bomStartDate={startDate}
                            bomEndDate={endDate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="secondRow">
                      <div className="container-new" style={{ height: "95%" }}>
                        <div
                          className="header"
                          style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                        >
                          <div>
                            <h5 style={{ fontSize: "18px" }}>Inventory</h5>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            {/* <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                marginRight: "5px"
                              }}
                              onClick={blank}
                            >
                              <FaRedoAlt />
                            </div>
                            <span id="inventorycalendericon" style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }} onClick={shows}>
                              <FontAwesomeIcon
                                icon={faCalendar}
                                style={{ color: "#808080" }}
                              />
                            </span>
                            <label id="inventorycalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                              <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                <RangePicker
                                  startDate={invStartDate}
                                  endDate={invEndDate}
                                  value={[invStartDate, invEndDate]}
                                  onChange={onInvChange}
                                  format="DD-MM-YYYY"
                                  size="small"
                                />
                              </Space>
                            </label> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                cursor: "pointer"
                              }}
                            >
                              <BsArrowUpRight onClick={() => history.push("/app/prodInventory")} />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div style={{ overflow: "auto" }}>
                          <Inventory invStartDate={invStartDate} invEndDate={invEndDate} />
                        </div>
                      </div>
                      <div
                        className="container-new"
                        style={{ marginLeft: "20px", height: "95%" }}
                      >
                        <div
                          className="header"
                          style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                        >
                          <div>
                            <h5 style={{ fontSize: "18px" }}>Shop Floor</h5>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            {/* <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                marginRight: "5px"
                              }}
                              onClick={empty}
                            >
                              <FaRedoAlt />
                            </div>
                            <span id="shopfloorcalendericon" style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }} onClick={visible}>
                              <FontAwesomeIcon
                                icon={faCalendar}
                                style={{ color: "#808080" }}
                              />
                            </span>
                            <label id="shopfloorcalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                              <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                <RangePicker
                                  startDate={sfStartDate}
                                  endDate={sfEndDate}
                                  value={[sfStartDate, sfEndDate]}
                                  onChange={onSfChange}
                                  format="DD-MM-YYYY"
                                  size="small"
                                />
                              </Space>
                            </label> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                cursor: "pointer"
                              }}
                            >
                              <BsArrowUpRight onClick={() => history.push("/app/prodInventory")} />
                            </div>
                          </div>
                        </div>
                        <div>
                          <VehicleReBarChart
                            props="production"
                            sfStartDate={sfStartDate}
                            sfEndDate={sfEndDate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="secondRow">
                      <div className="container-new" style={{ height: "380px" }}>
                        <div
                          className="header"
                          style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                        >
                          <div>
                            <h5 style={{ fontSize: "18px" }}>Vehicles</h5>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            {/* <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                marginRight: "5px"
                              }}
                              onClick={update}
                            >
                              <FaRedoAlt />
                            </div>
                            <span id="vehiclecalendericon" style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }} onClick={display}>
                              <FontAwesomeIcon
                                icon={faCalendar}
                                style={{ color: "#808080" }}
                              />
                            </span>
                            <label id="vehiclecalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                              <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                <RangePicker
                                  startDate={vehicleStartDate}
                                  endDate={vehicleEndDate}
                                  value={[vehicleStartDate, vehicleEndDate]}
                                  onChange={onVehicleChange}
                                  format="DD-MM-YYYY"
                                  size="small"
                                />
                              </Space>
                            </label> */}

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                cursor: "pointer"
                              }}
                            >
                              <BsArrowUpRight onClick={() => setOpenVehicleTable(true)} />
                            </div>
                          </div>
                        </div>
                        <br />
                        <VehiclesView
                          vehicleStartDate={vehicleStartDate}
                          vehicleEndDate={vehicleEndDate}
                        />
                      </div>
                      <div className="container-new" style={{ marginLeft: "20px" }}>
                        <div
                          className="header"
                          style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                        >
                          <div>
                            <h5 style={{ fontSize: "18px" }}>Assembly</h5>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                cursor: "pointer"
                              }}
                            >
                              <BsArrowUpRight onClick={() => setOpenAssemblyTable(true)} />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "10px",
                          }}
                        >
                          <div className="assemblyCard innerDiv">
                            <span style={{ fontWeight: "420" }}>Total Assemblies</span>
                            <span style={{ fontWeight: "420" }}>
                              {assemblyData.allAssemblies}
                            </span>
                          </div>
                          <div className="innerDiv">
                            <span style={{ fontWeight: "420" }}>Total Assembly Type</span>
                            <span style={{ fontWeight: "420" }}>
                              {assemblyData.assemblyTypes}
                            </span>
                          </div>
                          <div className="assemblyCard innerDiv">
                            <span style={{ fontWeight: "420" }}>
                              Total Assemblies Services
                            </span>
                            <span style={{ fontWeight: "420" }}>
                              {assemblyData.allAssembliesServices}
                            </span>
                          </div>
                          <div className="innerDiv">
                            <span style={{ fontWeight: "420" }}>Active Services</span>
                            <span style={{ fontWeight: "420" }}>
                              {assemblyData.activeServices}
                            </span>
                          </div>
                          <div className="assemblyCard innerDiv">
                            <span style={{ fontWeight: "420" }}>De-activated Services</span>
                            <span style={{ fontWeight: "420" }}>
                              {assemblyData.deactivatedServices}
                            </span>
                          </div>
                          <div className="innerDiv">
                            <span style={{ fontWeight: "420" }}>Re-activated Services</span>
                            <span style={{ fontWeight: "420" }}>
                              {assemblyData.reactivatedServices}
                            </span>
                          </div>
                          <div className="assemblyCard innerDiv">
                            <span style={{ fontWeight: "420" }}>Deleted Services</span>
                            <span style={{ fontWeight: "420" }}>
                              {assemblyData.deletedServices}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
      }
    </>
  );
};

export default ProductionDashboard;
