import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { successNotification, errorNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
    faFileUpload,
    faPlus,
    faTrashAlt,
    faTimes,
    faEdit,
  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";

const ChargesEdit = ({ updatedData, editData, closeModal }) => {
    const { register, handleSubmit, errors } = useForm();
    const [blankForm, setBlankForm] = useState(
        {
          user: editData.user,
          monthlyCharges: editData.monthlyCharges ||"", 
          yearlyCharges: editData.yearlyCharges || ""
        },
      );
      console.log(blankForm)

  const refresh = () => {
    updatedData();
  };

  console.log("editData-->",editData)


  const InputValidate = () => {
    const numericRegex = /^\d+$/;
  
    if (!blankForm.monthlyCharges || !blankForm.yearlyCharges) {
      errorNotification("All fields are required.");
      return false;
    }
  
    if (!numericRegex.test(blankForm.monthlyCharges)) {
      errorNotification("Monthly charges must be a numeric value.");
      return false;
    }
  
    if (!numericRegex.test(blankForm.yearlyCharges)) {
      errorNotification("Yearly charges must be a numeric value.");
      return false;
    }
  
    return true;
  };
  


  const submitHandler = async () => {

    if(!InputValidate()){
      return
    }

    const resp = await trackPromise(
      API.put(`systemadmin/api/v1/editUserCharges`, blankForm)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const handleInputChange = (event) => {
    setBlankForm({ ...blankForm, [event.target.name]: event.target.value });
};

  const create = async (loginDetails) => {
    setBlankForm(
      {
        user: editData.user,
        monthlyCharges:"", 
        status: "Active",
        yearlyCharges: ""

      },
    );
    document.getElementById('userType').value="";
    document.getElementById('chargesM').value=editData.monthlyCharges || "";
    document.getElementById('chargesY').value= editData.yearlyCharges || "";

  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{height:"auto", with:"auto"}}>
        <div className="header">
          <h3>Edit User Charges</h3>
        </div>
        <br />

        <div className="body">
        <form
        id="add-user-from"
        className="setting-form"
        onSubmit={handleSubmit(create)}
      >
        {/* {blankForm.map((item, index) => { */}
          {/* return ( */}
            <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
              <div
                style={{
                  margin: "0px 5px 0px 0px",
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    marginLeft: "6px",
                    justifyContent:"flex-start",
                  textAlign:"left"
                  }}
                >
                  User Type
                </label>
                {/* <select
              required={true}
              name="user"
              id="type"
              className="input-config"
              defaultValue={item.user}
              // onChange={handleAssemblyTypeChange}
              style={{ marginTop: '-8px', width: '100%' }}
              onChange={(event) => {handleInputChange(event, index);}}
            >
              <option value='' disabled selected>
             Select User Type
              </option>
              <option value="Sub_Admin">Sub Admin</option>
              <option value="Procurement">Procurement</option>
              <option value="Production">Production</option>
              <option value="Inventory">Inventory</option>
              <option value="Quality Control">Quality Control</option>
              <option value="Finance">Finance</option>
              <option value="Shop Floor">Shop Floor</option>
              <option value="Management">Management</option>
              <option value="Operations">Operations</option>
              <option value="National Sales">National Sales</option>
              <option value="Zonal Sales">Zonal Sales</option>
              <option value="Area Sales">Area Sales</option>
              <option value="Dealer">Dealer</option>
              <option value="Showroom ">Showroom </option>
              <option value="Service Center ">Service Center </option>
              <option value="Service Advisor ">Service Advisor </option>
              <option value="Store Manager ">Store Manager </option>
            </select> */}
                   <input
              // disabled={blankForm.assemblyType ? false : true}
              className="input-config"
              id="userType"
              type="text"
              name="user"
              // value={blankForm.user}
              value={editData?.user}
              placeholder="Enter Charges/Month"
              autoComplete="off"
              style={{ marginTop: '-8px', width: '100%' }}
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    justifyContent:"flex-start",
                  textAlign:"left"
                  }}
                >
                  Charges/Month (₹)
                </label>
                <input
              // disabled={blankForm.assemblyType ? false : true}
              className="input-config"
              id="chargesM"
              type="text"
              name="monthlyCharges"
              defaultValue={editData?.monthlyCharges}
              placeholder="Enter Charges/Month"
              autoComplete="off"
              style={{ marginTop: '-8px', width: '100%' }}
              onChange={(event) => {
                handleInputChange(event);
              }}
              onKeyPress={(e) => {
                const isNumeric = /^[0-9]*$/.test(e.key);
                if (!isNumeric) {
                  e.preventDefault();
                }
              }}
            />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    justifyContent:"flex-start",
                  textAlign:"left"
                  }}
                >
                  Charges/Year (₹)
                </label>
               <input
              className="input-config"
              id="chargesY"
              type="text"
              name="yearlyCharges"
              defaultValue={editData?.yearlyCharges}
              placeholder="Enter Charges/Year"
              autoComplete="off"
              style={{ marginTop: '-8px', width: '100%' }}
              onChange={(event) => {
                handleInputChange(event);
              }}
              onKeyPress={(e) => {
                const isNumeric = /^[0-9]*$/.test(e.key);
                if (!isNumeric) {
                  e.preventDefault();
                }
              }}
            />
              </div>
            </div>
          {/* ); */}
        {/* })} */}
        <br />
        <div className="save-cancel">
          <div className="cust-reset  cancel-button">
            <button type="button" className="btn btn-icon-label">
              <div
                style={{
                  fontSize: "14px",
                  color: "#0A18A3",
                  fontWeight: "500",
                }}
                onClick={() => closeModal(false)}
              >
                <FontAwesomeIcon icon={faTimes} />
                Cancel
              </div>
            </button>
          </div>
          <div className=" cancel-button">
            <button className="btn btn-icon-label" type="submit">
              <div
                style={{
                  fontSize: "14px",
                  color: "#0A18A3",
                  fontWeight: "500",
                }}
                onClick={() => {
                  submitHandler();
                }}
              >
                <FontAwesomeIcon icon={faEdit} /> Update
              </div>
            </button>
          </div>
        </div>
      </form>
        </div>
        
      </div>
    </div>
  );
};

export default ChargesEdit;
