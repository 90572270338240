import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification,errorNotification } from "../../../utils/notifications";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
  ConfirmationNumber,
} from "@material-ui/icons";


const ComponentViewReject = ({closeModal,updatedData,rowData,setOpen}) => {

  const [formValue, setFormValue] = useState({
    RejectReason: "",
    description: "",
  });

  const editDetails  = {
    requestID:rowData.requestID,
    status:"Rejected",
    reason: formValue.RejectReason,
    ...(formValue.RejectReason == "Other" ? {reasonDescription:formValue.description}:{reasonDescription:""})
   };

    const refresh = () => {
      updatedData()
  };

  console.log(formValue,editDetails)


  const submitHandler = async () => {
    if(formValue?.RejectReason){
      const resp = await trackPromise(API.post(`qc-module/api/v1/vehicle/newrequest/status`, editDetails));
    
      const { success,message} = resp;
      
      if (success) {
          closeModal(false);
          setOpen(false)
          successNotification(message, { autoClose: 3000 });
          refresh()
      } else {
          errorNotification(message, { autoclose: 2000 });
      }
    }
    else{
      errorNotification("Please select reason first", { autoclose: 2000 }); 
    }
    
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Reject</h3>
         
        </div>

        <div className="body">
        <label style={{display:'flex', flexDirection:'column', width:'50%'}}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "fit-content" }}>Reject Reason<span style={{color:'red'}}>*</span></label>
          
            <select
            style={{marginTop:"-7px"}}
              name="RejectReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                Reject Reason
              </option>
              <option value="No Manpower">
              No Manpower
              </option>
              <option value="In-Correct GRN">
              In-Correct GRN
              </option>
              <option value="Other">Other</option>
            </select><br />
          </label>

          {formValue.RejectReason === "Other" ?
          <label className="lgInput">
            Description
            <input
              type="text"
              style= {{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Description"
              autoComplete="off"
            />
          </label> : ""}

        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}><KeyboardBackspace
                style={{ fontSize: "25px", marginRight: "5px" }}
              />Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
          <CheckCircle style={{ fontSize: "17px" }} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComponentViewReject;
