import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import GINno from "./GINno";
import CR_POno from "./CR_POno";
import CR_MR_ReturnQty from "./CR_MR_ReturnQty";
import moment from "moment";

const CR_MR_Details = ({ closeModal, rowData }) => {
    const [showGIN, setShowGIN] = useState(false);
    const [showPO, setShowPO] = useState(false);
    const [viewQty, setViewQty] = useState();
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "980px", height: "425px", padding: '15px' }}
            >
                <div className="header">
                    <h3>View and Update</h3>
                </div>

                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested ID: {rowData.requestID}</span>
                            <span>RPI No. : {rowData.rpiNo}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested By: {rowData.storeManagerEmail}</span>
                            <span>RPI Date: {moment(rowData.rpiDate).format("DD-MMM-YYYY")}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested Date: {moment(rowData.requestDate).format("DD-MMM-YYYY")}</span>
                            <span>RPI Raise By : {rowData.rpiRaisedBy}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>GIN No. : <span>{rowData.ginNo}</span>
                                {/* {showGIN && <GINno closeModal={setShowGIN} />} */}
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>PO No. : <span>{rowData.poNo}</span>
                                {/* {showPO && <CR_POno closeModal={setShowPO} />} */}
                            </div>
                        </div>

                    </section>

                    <section style={{ width: '100%' }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '10%' }}>S.No.</div>
                            <div style={{ width: '18%' }}>Component Name</div>
                            <div style={{ width: '18%' }}>SKU Code</div>
                            <div style={{ width: '18%' }}>Category</div>
                            <div style={{ width: '18%' }}>Sub Category</div>
                            <div style={{ width: '18%' }}>Return Qty.</div>
                        </div>
                        <div>
                            {
                                rowData.returnedData.map((item, index) => (
                                    <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }} key={index}>
                                        <div style={{ width: '10%' }}>{index}</div>
                                        <div style={{ width: '18%' }}>{item.sparePartname}</div>
                                        <div style={{ width: '18%' }}>{item.skuCode}</div>
                                        <div style={{ width: '18%' }}>{item.category}</div>
                                        <div style={{ width: '18%' }}>{item.subCategory}</div>

                                        <div style={{ width: '18%' }}>
                                            <span onClick={() => setViewQty(index)} style={{ color: "#0a18a3", fontWeight: "500", cursor: 'pointer' }}> {item.returnQty?.length}</span>
                                            {viewQty === index && <CR_MR_ReturnQty rowData={item} closeModal={setViewQty} />}
                                        </div>
                                    </div>))
                            }
                        </div>
                    </section>

                </div>

                <div className="footer">
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
    );
};

export default CR_MR_Details;