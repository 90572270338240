import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import Bom from './components/Bom'
// import PONewRequest from "./components/PONewRequest";
// import RequestHistory from "./components/RequestHistory";

const Bom = React.lazy(()=>import('./components/Bom'))
const PONewRequest = React.lazy(()=>import("./components/PONewRequest"))
const RequestHistory = React.lazy(()=>import("./components/RequestHistory"))


const tabs = [
  {
    id: "FIM2T1",
    label: TabLabels.BOM,
    Component: Bom
  },
  {
    id: "FIM2T1",
    label: TabLabels.NEW_REQUEST,
    Component: PONewRequest
  },
  {
    id: "FIM2T1",
    label: TabLabels.REQUEST_HISTORY,
    Component: RequestHistory
  }
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};


const PORequest = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  )
};

export default PORequest;
