import React, { useState, useEffect } from 'react'
import {
  CancelOutlined,
  CheckCircle,
} from "@material-ui/icons";
import { trackPromise } from 'react-promise-tracker';
import API from '../../../utils/api';
import { errorNotification, successNotification } from '../../../utils/notifications';

const FullAssignment = ({ closeModal, finalBody, updatedData, close }) => {
  // const [arr, setArr] = useState([]);
  console.log(finalBody)


  // useEffect(()=>{
  //   finalBody?.bomComponents?.map((item) => {
  //     item?.partsSno?.map((value) => {
  //             arr.push(value?.partSno);
  //     });
  // });
  // })

  let arr = []
  finalBody?.bomComponents?.map((item) => {
    item?.partsSno?.map((value) => {
      arr.push(value?.partSno);
    });
  });

  console.log(arr)
  const finalPartialBody = { ...finalBody, addedPart: arr };
  console.log(finalPartialBody)
  const sumOfRemaining = finalBody?.bomComponents?.reduce((acc, item) => {
    return acc + parseInt(item?.remainingRequiredQty);
  })

  const sumOfAssigned = finalBody?.bomComponents?.reduce((acc, item) => {
    return acc + parseInt(item?.assignedQty);
  })

  console.log(sumOfRemaining, sumOfAssigned)

  const submitHandler = async () => {
    // if(sumOfRemaining-sumOfAssigned == 0){
    const resp = await trackPromise(API.post(`inventory-module/api/v1/wipInventory/`, finalPartialBody));
    const { success, message } = resp;
    if (success) {
      await trackPromise(API.post(`inventory-module/api/v1/grn/notification`));
      closeModal(false);
      updatedData();
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 3000 });
    }
    // }
    // else {
    //   errorNotification("Remaining Required Quantity is not 0", { autoclose: 3000 });
    // }


  };




  return (
    <div className='container1'>
      <div className="modalContainer" style={
        { width: '400px', height: '200px' }
      }>

        <div className="body" style={{ justifyContent: 'center', fontSize: '16px' }}>
          Are you sure to make <br /> full assignment?

        </div>
        <div className="footer" style={{ justifyContent: 'center' }}>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: 'white',
              color: "#0A18A3",
            }}
            onClick={() => close(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            onClick={submitHandler}
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>

        </div>
      </div>
    </div>
  )
}

export default FullAssignment