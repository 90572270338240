import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../settings-tabs/homesettings.scss";
// import "../../../pages/orders/Orders.scss";
import { CheckCircle, CancelOutlined } from "@material-ui/icons";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import ManageSalesUserTable from "./ManageSalesUserTable";


const ManageSalesUser = ({ inDashboard }) => {
  const [userType, setUserType] = useState();
  const [actionData, setActionData] = useState();
  const [UsersData, setUsersData] = useState([]);

  const handleInputChange = async (event, data) => {
    // console.log(data)
    setActionData(data)
    let typeUser = event?.target?.value;
    setUserType(typeUser);
    const resp = await trackPromise(
      API.get(
        `operations-module/api/v1/salesUser/manageSales?userType=${data == "" ? typeUser : data}`
      )
    );
    // var output = [];
    // resp?.forEach(function (item) {
    //   var existing = output?.filter(function (v, i) {
    //     return v.countryName == item.countryName;
    //   });
    //   console.log(existing)
    //   if (existing.length) {
    //     var existingIndex = output.indexOf(existing[0]);
    //     // output[existingIndex].value = output[existingIndex].value.concat(item.value);
    //   } else {
    //     if (typeof item.value == "string") item.value = [item.value];
    //     output.push(item);
    //   }
    // });

    // Use Set to store unique nsmUserId values
    // const output = Array.from(new Set(resp.map(item => item.nsmUserId))).map(id => {
    //   return resp.find(item => item.nsmUserId === id);
    // });

    // console.log(output);
    let filterId = resp.filter((item) => { return item.nsmUserId != null || item.zsmUserId != null || item.asmUserID != null })
    setUsersData(filterId)
  };

  // console.log(UsersData);

  return (
    <>
      <div>
        <div className="bussiness">
          <h4 className="heading">Manage Sales User</h4>
        </div>
        <br />
        <div>
          <div
            style={{
              width: "23%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "6px",
              }}
            >
              User Type<span style={{ color: "red" }}>*</span>
            </label>
            <select
              required={true}
              name="userType"
              className="input-config"
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              onChange={(event) => handleInputChange(event, "")}
            >
              <option selected disabled>
                Select User Type
              </option>
              <option value="National Sales">National Sales</option>
              <option value="Zonal Sales">Zonal Sales</option>
              <option value="Area Sales">Area Sales</option>
            </select>
          </div>
        </div>
        <br />
        {userType ? (
          <ManageSalesUserTable userType={userType} usersData={UsersData} updatedData={handleInputChange} inDashboard={inDashboard} />
        ) : actionData ? (
          <ManageSalesUserTable userType={actionData} usersData={UsersData} updatedData={handleInputChange} inDashboard={inDashboard} />
        ) : ""}
      </div>
    </>
  );
};

export default ManageSalesUser;
