import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import auth from "../../../utils/auth";
import ViewBatterySpec from "./ViewBatterySpec";


const ViewBattery = ({ closeModal, row }) => {
  console.log("row-->", row);
  console.log("row?.bomBattery-->", row?.bomBattery.map((item)=>item?.batteryName));

  const [viewSpecs, setViewSpecs] = useState(false);
  const [specsData, setSpecsData] = useState({});

  const handleViewSpecs = (item, index) => {
    setViewSpecs(true);
    setSpecsData(item);
  };

  return (
    <div className="container1">
      {viewSpecs && <ViewBatterySpec specs={specsData} closeModal={setViewSpecs} />}
      <div
        className="modalContainer"
        style={{ width: "90%", height: "400px", padding: "15px" }}
      >
        <div className="header">
          <h3>Battery Details</h3>
        </div>

        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div
              id="costingTableHeading"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{
                  width: "12%",
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                }}
              >
                Component Name
              </div>
              <div style={{ width: "12%" }}>SKU Code</div>
              <div style={{ width: "12%" }}>Category</div>
              <div style={{ width: "12%" }}>Sub Category</div>
              <div style={{ width: "12%" }}>Specification</div>
              <div style={{ width: "12%" }}>Quantity</div>
              <div style={{ width: "12%" }}>{`Unit Price (${
                auth.getUserInfo().currencySymbol
              })`}</div>
              <div style={{ width: "12%" }}>{`Total Price (${
                auth.getUserInfo().currencySymbol
              })`}</div>
            </div>
            <div style={{ height: "230px", overflowY: "scroll" }}>
              {row?.bomBattery?.map((item, index) =>
                // Check if item.primary exists before rendering the entire div
                item?.primary ? (
                  <div
                    id="costingTableContent"
                    style={{ display: "flex", justifyContent: "space-between" }}
                    key={index}
                  >
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {item?.batteryName}
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {item?.batterySkuCode}
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {item?.batteryCategory}
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {item?.batterySubCategory}
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      <span
                        onClick={() => handleViewSpecs(item, index)}
                        style={{
                          color: "#0a18a3",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        View Specs
                      </span>
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {item?.batteryQuantity}
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {Number(item?.primary?.cost)}
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {item?.batteryQuantity * Number(item?.primary?.cost)}
                    </div>
                  </div>
                ) : (
                  <div
                    id="costingTableContent"
                    style={{ display: "flex", justifyContent: "space-between" }}
                    key={index}
                  >
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {item?.batteryName}
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {item?.batterySkuCode}
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {item?.batteryCategory}
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {item?.batterySubCategory}
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      <span
                        onClick={() => handleViewSpecs(item, index)}
                        style={{
                          color: "#0a18a3",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        View Specs
                      </span>
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {item?.batteryQuantity}
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {Number(item?.cost)}
                    </div>
                    <div style={{ overflowY: "auto", width: "12%" }}>
                      {item?.batteryQuantity * Number(item?.primary?.cost)}
                    </div>
                  </div>
                )
              )}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "8px" }}>{`Total Cost (${
                  auth.getUserInfo().currencySymbol
                })`}</span>
                <span
                  style={{
                    padding: "7px",
                    width: "141px",
                    backgroundColor: "#e5e5e5",
                  }}
                >
                  {row.totalComponentsCost}
                </span>
              </div>
            </div>
          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <KeyboardBackspace /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewBattery;
