import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import AddVendors from "./add-vendor";
// import ManageVendors from "./manage-vendor";

const AddVendors = React.lazy(()=>import("./add-vendor"))
const ManageVendors = React.lazy(()=>import("./manage-vendor"))


const tabs = [
  {
    id:"PRM1T1",
    label: TabLabels.ADD_VENDOR,
    Component: AddVendors
  },
  {
    id:"PRM1T2",
    label: TabLabels.MANAGE_VENDOR,
    Component: ManageVendors
  }
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};

const Vendor = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default Vendor;
