import React, { useState, useEffect } from "react";
import {
  KeyboardBackspace,
} from "@material-ui/icons";
import moment from "moment";
import VR_MovedToFinishedGoods from "./VR_MovedToFinishedGoods";
import VR_SendToProduction from "./VR_SendToProduction";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { errorNotification } from "../../../utils/notifications";

const FinishedGoodsUpdates = ({ closeModal, rowData }) => {
  const [goodsShow, setFinishedGoodShow] = useState();
  const [prodShow, setProductionShow] = useState();
  const [goodsData, setGoodsData] = useState([]);

  const fetchGoodsDetails = async () => {
    const resp = await trackPromise(API.get(`inventory-module/api/v1/inventorygoodsverification/getFinishedGoods?requestID=${rowData.requestID}`));
    console.log(resp);
    resp.success == false ? errorNotification(resp.message) : setGoodsData(resp);
  };
  useEffect(() => {
    fetchGoodsDetails()
  }, []);

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1100px", height: "470px", padding: '15px' }}
      >
        <div className="header">
          <h3>View Details</h3>
        </div>

        <div
          className="body"
          style={{ width: '100%', display: "flex", fontSize: "14px" }}
        >

          <section
            style={{
              width: '100%',
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0"
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested ID: {rowData.requestID}</span>
              <span>WO ID: {rowData.orderID}<span style={{ color: "#0a18a3" }}></span></span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested Category: {rowData.requestCategory}</span>
              <span>Added Date : {moment(rowData.requestDate).format("DD-MMM-YYYY/HH:mm")}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Request Type: {rowData.requestType}</span>
              <span>Added Qty : {goodsData.length}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested By: {rowData.requestedBy}<span style={{ color: "#0a18a3" }}></span></span>
              <span>Segment: {goodsData[0]?.vehicleSegment}</span>
            </div>



          </section>
          <div style={{ background: "lightgrey", width: "15%", marginBottom: "5px", borderRadius: "5px" }}>Category: {goodsData[0]?.vehicleCategory}</div>
          <section style={{ width: '100%' }}>
            <div id='POVendorHeading'>
              <div style={{ width: '4%' }}>SNo.</div>
              <div style={{ width: '10%' }}>Model</div>
              <div style={{ width: '10%' }}>Variant</div>
              <div style={{ width: '10%' }}>Type</div>
              <div style={{ width: '10%' }}>Color</div>
              <div style={{ width: '8%' }}>SNO</div>
              <div style={{ width: '8%' }}>VIN</div>
              <div style={{ width: '8%' }}>BIN</div>
              <div style={{ width: '8%' }}>Status</div>
              <div style={{ width: '8%' }}>Verified On</div>
              <div style={{ width: '8%' }}>Failure Reason</div>
              {rowData.status == 'Completed' ? <div style={{ width: '8%' }}>Action</div> : ""}
            </div>
            <div>
              {goodsData.map((item, index) => (
                <div key={index} id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }}>
                  <div style={{ width: '4%' }}>{index + 1}</div>
                  <div style={{ width: '10%' }}>{item.vehicleModel}</div>
                  <div style={{ width: '10%' }}>{item.vehicleVariant}</div>
                  <div style={{ width: '10%' }}>{item.vehicleType}</div>
                  <div style={{ width: '10%' }}>{item.vehicleColor}</div>
                  <div style={{ width: '8%' }}>{item.SNO}</div>
                  <div style={{ width: '8%' }}>{item.VIN}</div>
                  <div style={{ width: '8%' }}>{item.BIN}</div>

                  {item.status == "Passed" ?
                    <div className="active-btn" style={{ width: '8%', marginTop: "25px" }}><span className="active-btn-font">{item.status}</span></div>
                    :
                    <div className="delete-btn" style={{ width: '8%', marginTop: "25px" }}><span className="delete-btn-font">{item.status}</span></div>
                  }
                  <div style={{ width: '8%' }}>{moment(item.verifiedOn).format("DD-MMM-YYYY/HH:mm")}</div>
                  <div style={{ width: '8%' }}>{item.reason}</div>
                  {rowData.status == "Completed" ?
                    <div style={{ width: '8%', padding: '5px', borderRight: '1px solid #cdcdcd' }}>
                      {item.status === 'Passed' ?
                        <button
                          style={{
                            width: "100%",
                            fontSize: '12px',
                            color: item.lastAdded ? 'green' : "blue",
                            cursor: 'pointer',
                            padding: '3px 9px',
                            border: `1px solid ${item.lastAdded ? 'green' : "blue"}`,
                            borderRadius: '4px',
                            background: 'white'
                          }}
                          disabled={item.lastAdded ? true : false}
                          onClick={() => setFinishedGoodShow(index)}
                        >
                          {item.lastAdded ? item.lastAction : "Move To Finished Goods"}
                        </button>
                        :
                        <button
                          style={{
                            width: "100%",
                            fontSize: '12px',
                            color: item.lastAdded ? 'green' : "blue",
                            cursor: 'pointer',
                            padding: '3px 9px',
                            border: `1px solid ${item.lastAdded ? 'green' : "blue"}`,
                            borderRadius: '4px',
                            background: 'white'
                          }}
                          disabled={item.lastAdded ? true : false}
                          onClick={() => setProductionShow(index)}
                        >
                          {item.lastAdded ? item.lastAction : "Move To Production"}
                        </button>}
                      {(goodsShow === index) && <VR_MovedToFinishedGoods rowData={item} vehicleSkuCode={item.vehicleSkuCode} SNO={item.SNO} requestID={rowData.requestID} vehicleID={item.vehicleID} tabName="vehicles" closeModal={setFinishedGoodShow} vehicleTableRefresh={fetchGoodsDetails} />}
                      {(prodShow === index) && <VR_SendToProduction rowData={item} requestID={rowData.requestID} vehicleID={item.vehicleID} tabName="vehicles" closeModal={setProductionShow} vehicleTableRefresh={fetchGoodsDetails} />}
                    </div> : ""}
                </div>
              ))}
            </div>
          </section>

        </div>

        <div className="footer">
          <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
        </div>
      </div>
    </div>
  );
};

export default FinishedGoodsUpdates;