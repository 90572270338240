import React from "react";
import { Tabs } from "react-simple-tabs-component";
import "./tabs.scss";
import OperationLocation from "./OperationLocation";
import OperationChangePassword from "./OperationChangePassword";
import OperationNotificationPreference from "./OperationNotificationPreference";
import auth from "../../utils/auth";


const operationTabs = [
  {
    label: "Operation Locations",
    Component: OperationLocation
  },
  {
    label: "Change Password",
    Component: OperationChangePassword
  },
  {
    label: "Notification Preference",
    Component: OperationNotificationPreference
  }
];

const OpSettingsTabs = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={operationTabs} type="pills" className="tabs-line"/> 
    </div>
  );
};

export default OpSettingsTabs;
