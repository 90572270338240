import React, { useState, useEffect } from 'react'
import Group from '../QcDashboard/assets/Group.png'
import Group2 from '../QcDashboard/assets/Group2.png'
import auth from '../../utils/auth'
import { trackPromise } from 'react-promise-tracker'
import API from '../../utils/api'

function StoreSpareInventoryCard({ }) {

    const costingData = {
        "totalReqs": 5,
        "approvedReqs": 250000
    }

    const [data, setData] = useState([]);


    const fetchData = async () => {
        const resp = await trackPromise(API.get(`storeManager-module/api/v1/dashboard/getSpareInventoryCount`))
        setData(resp);
    };
    useEffect(() => {
        fetchData();
    }, []);

    let totalValue = data.reduce((a, b) => {
        return a + b.subTotal;
    },0)

    return (
        <div className='requestBody' style={{ justifyContent: "space-around" }}>
            <div className='request'>
                <img src={Group} style={{ marginLeft: "8px" }} alt="group" />
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
                    <div style={{ fontSize: "24px", fontWeight: "450" }}>{data?.length}</div>
                    <span style={{ color: "#808080", fontSize: "14px" }}>Total Available Units</span>
                </div>
            </div>

            <div className='request'>
                <img src={Group2} style={{ marginLeft: "8px" }} alt="group" />
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
                    <div style={{ fontSize: "24px", fontWeight: "450" }}>{auth.getUserInfo().currencySymbol}{totalValue}</div>
                    <span style={{ color: "#808080", fontSize: "14px" }}>Total Inventory Value</span>
                </div>
            </div>
        </div>
    )
}

export default StoreSpareInventoryCard