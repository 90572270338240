import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import ReturnUnit from "../ReturnUnit";
import SubmitPop from "./SubmitPop";
import moment from "moment";



const ViewAndPlaceToInventory = ({ rowData, closeModal, updatedData }) => {
  const [open, setOpen] = useState(false);
  const [openSpare, setOpenSpare] = useState();
  const [spareData, setSpareData] = useState();

  const submitHandler = (rowData) => {
    setOpenSpare(true);
    setSpareData(rowData);
  };

  console.log(rowData);


  return (
    <div className="container1">
     {openSpare ? (
        <SubmitPop rowData={rowData} closeModal={closeModal} updatedData={updatedData}/>
      ) : (
        ""
      )}
      <div
        className="modalContainer"
        style={{ width: "1280px", height: "500px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Spare Returns</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
         
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderBottom: "0.1px solid grey",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div>Request ID: {rowData?.requestID}</div>
              <div>Request Date: {moment(rowData?.requestDate).format("DD-MM-YYYY")}</div>
              <div>Requested By: {rowData?.requestBy}</div>
              <div>GIN No.: {rowData?.ginNo}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
                <div>RPI No:{rowData?.rpiNo}</div>
              <div>RPI Date: {moment(rowData?.rpiDate).format("DD-MM-YYYY")}</div>
              <div>RPI Raised By: {rowData?.rpiRaisedBy}</div>
            </section>
          </section>

          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "fit-content",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "50px", padding: "10px" }}>S.No</div>
              <div style={{ width: "200px", padding: "10px" }}>Invoice No</div>
              <div style={{ width: "200px", padding: "10px" }}>Invoice Date</div>
              <div style={{ width: "200px", padding: "10px" }}>SKU Code</div>
              <div style={{ width: "200px", padding: "10px" }}>Spare Part Name</div>
              <div style={{ width: "200px", padding: "10px" }}>Category</div>
              <div style={{ width: "200px", padding: "10px" }}>Sub Category</div>
              <div style={{ width: "200px", padding: "10px" }}>Specification</div>
              <div style={{ width: "200px", padding: "10px" }}>Return Units</div>
              <div style={{ width: "200px", padding: "10px" }}>Unit Price</div>
              <div style={{ width: "200px", padding: "10px" }}>Amount</div>
            </div>
            <section style={{ width: "fit-content", height: "auto" }}>
              {rowData?.returnedData?.map((item, index) => (
              <div
                style={{
                  padding: "0px",
                  border: "1px solid #cdcdcd",
                  width: "",
                  fontSize: "13px",
                  display: "flex",
                }}
              >
                <div style={{ width: "50px", padding: "10px" }}>{index+1}</div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    {item?.invoiceNo}
                  </p>
                  
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                 {moment(item?.invoiceDate).format("DD-MM-YYYY")}
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  {item?.skuCode}
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  {item?.sparePartName}
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                 {item?.category}
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                 {item?.subCategory}
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    View Details
                  </p>
                  
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={()=>setOpen(true)}
                  >
                    {item?.returnQty?.length}
                  </p>
                  
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  {item?.unitPrice}
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  {(item?.unitPrice)*(item?.returnQty?.length)}
                </div>
                {open && <ReturnUnit rowData={item?.returnQty} closeModal={setOpen} />}
              </div>
              
               ))}
            </section>
          </section>
          
        </div>

        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={submitHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewAndPlaceToInventory;
