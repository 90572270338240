import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { trackPromise } from "react-promise-tracker";
import API from '../../utils/api'
import { useState, useEffect } from 'react'
import moment from 'moment';

function VendorsGraph({ startDate, endDate }) {

    const [vendorData, setVendorData] = useState({})
    const fetchData = async () => {
        const resp = await trackPromise(API.get(startDate == null && endDate == null ? `procurement-module/api/v1/dashboard/getAllVendors` : `procurement-module/api/v1/dashboard/getAllVendors?startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}`))
        setVendorData(resp);
    }
    useEffect(() => {
        fetchData();
    }, [endDate])

    return (
        <div style={{ width: "53%", marginLeft: "10px" }}>
            <Doughnut
                height={53}
                data={{
                    datasets: [{
                        data: [vendorData?.activeVendors, vendorData?.deactivatedVendors, vendorData?.reactivatedVendors, vendorData?.blacklistedVendors, vendorData?.deletedVendors],
                        backgroundColor: [
                            'rgba(33, 168, 46, 1)',
                            'rgba(253, 170, 72, 1)',
                            'rgba(255, 215, 74, 1)',
                            'black',
                            'rgba(237, 52, 75, 1)'
                        ],
                        options: {
                            radius: "20%",
                        },
                        hoverOffset: 4,
                    }
                    ],
                    labels: ["Active Users", "De-Activated Users", "Re-Activated Users", "Deleted Users"]
                }} />
            <div style={{ margin: "-70% 0 0 100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(33, 168, 46, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "150px", fontSize: "14px", fontWeight: "500", marginTop: "3px" }}>Active Vendors</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "700", marginTop: "3px" }}>
                        {vendorData?.activeVendors}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(253, 170, 72, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "150px", fontSize: "14px", fontWeight: "500", marginTop: "3px" }}>Deactivated Vendors</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "700", marginTop: "3px" }}>
                        {vendorData?.deactivatedVendors}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(255, 215, 74, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "150px", fontSize: "14px", fontWeight: "500", marginTop: "3px" }}>Reactivated Vendors</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "700", marginTop: "3px" }}>
                        {vendorData?.reactivatedVendors}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "black", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "150px", fontSize: "14px", fontWeight: "500", marginTop: "3px" }}>Blacklisted Vendors</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "700", marginTop: "3px" }}>
                        {vendorData?.blacklistedVendors}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(237, 52, 75, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "150px", fontSize: "14px", fontWeight: "500", marginTop: "3px" }}>Deleted Vendors</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "700", marginTop: "3px" }}>
                        {vendorData?.deletedVendors}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default VendorsGraph