import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import VehicleWarranty from "./Component/VehicleWarranty";
// import SpareWarranty from "./Component/SpareWarranty";
// import Services from "./Component/Services";
// import Insurance from "./Component/insurance/Insurance";
// import ServiceMaster from "./Component/serviceMaster/serviceMaster";

const VehicleWarranty = React.lazy(()=>import("./Component/VehicleWarranty"))
const SpareWarranty = React.lazy(()=>import("./Component/SpareWarranty"))
const Services = React.lazy(()=>import("./Component/Services"))
const Insurance = React.lazy(()=>import("./Component/insurance/Insurance"))
const ServiceMaster = React.lazy(()=>import("./Component/serviceMaster/serviceMaster"))


const tabs = [
  {
    id: "OPM5T1",
    label: TabLabels.VEHICLE_WARRANTY,
    Component: VehicleWarranty,
  },
  {
    id: "OPM5T2",
    label: TabLabels.SPARE_WARRANTY,
    Component: SpareWarranty,
  },
  {
    id: "OPM5T3",
    label: TabLabels.SERVICES,
    Component: Services,
  },
  {
    id: "OPM5T4",
    label: TabLabels.INSURANCE,
    Component: Insurance,
  },
  {
    id: "OPM5T5",
    label: TabLabels.SERVICE_MASTER,
    Component: ServiceMaster,
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

const OperationsWarrantyServices = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default OperationsWarrantyServices;
