import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import { errorNotification, successNotification } from "../../../../utils/notifications";
import { KeyboardBackspace } from "@material-ui/icons";
// import DocSharing from "./docSharing";
import moment from "moment";
import auth from "../../../../utils/auth";

const PrepareForShipment = ({ closeModal, rowData ,tableRefresh}) => {
    console.log(rowData);

    const submitChallan = async () => {
        const obj = {
            "requestID": rowData.requestID,
            "requestCategory": rowData.requestCategory,
            "orderID": rowData.orderID,
            "vendorID": rowData.vendorID,
            "vendorName": rowData.vendorName,
            "vendorContact": rowData.vendorContact,
            "vendorLocation": rowData.vendorLocation,
            "components": rowData.components,
            "RPI": rowData.RPI,
            "RPIDate": rowData.RPIDate,
            "RPICreatedBy": rowData.RPICreatedBy
        }
        const resp = await trackPromise(API.post(`inventory-module/api/v1/spareOutward/prepareForShipment`, obj));
        resp.success ? successNotification(resp.message) : errorNotification(resp.message);
        closeModal(false);
        tableRefresh();
    };


    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "1100px", height: "450px", padding: '15px' }}
            >
                <div className="header" style={{ display: "flex", justifyContent: "space-between", paddingRight: "0px" }}>
                    <h3>Prepare For Shipment</h3>
                </div>

                <section
                    style={{
                        width: '100%',
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "14px",
                        fontWeight: "500",
                        margin: "10px 0"
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>RPI No.: {rowData.RPI}</span>
                        <span>Vendor ID: {rowData.vendorID}</span>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>RPI Date: {moment(rowData.RPIDate).format("DD-MMM-YYYY/HH:mm")}</span>
                        <span>Vendor Name: {rowData.vendorName}</span>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Created By : {rowData.RPICreatedBy}</span>
                        <span>Vendor Location : {rowData.vendorLocation}</span>
                    </div>
                </section>

                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >
                    <section style={{ width: '100%', overflow: "scroll" }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '5%' }}>S.No.</div>
                            <div style={{ width: '10%' }}>Component Name</div>
                            <div style={{ width: '10%' }}>SKU Code</div>
                            <div style={{ width: '15%' }}>Category</div>
                            <div style={{ width: '10%' }}>Sub Category</div>
                            <div style={{ width: '15%' }}>Vendor Part Name</div>
                            <div style={{ width: '10%' }}>Vendor Part Code</div>
                            <div style={{ width: '8%' }}>Qty.</div>
                            <div style={{ width: '8%' }}>{`Unit Price (${auth.getUserInfo().currencySymbol})`}</div>
                            <div style={{ width: '9%' }}>{`Sub Total (${auth.getUserInfo().currencySymbol})`}</div>
                        </div>
                        <div>
                            {rowData.components.map((item, index) => {
                                return (
                                    <div>
                                        <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }} key={""}>
                                            <>
                                                <div style={{ width: '5%' }}>{index + 1}</div>
                                                <div style={{ width: '10%' }}>{item.componentName}</div>
                                                <div style={{ width: '10%' }}>{item.skuCode}</div>
                                                <div style={{ width: '15%', overflow: "auto" }}>{item.category}</div>
                                                <div style={{ width: '10%' }}>{item.subCategory}</div>
                                                <div style={{ width: '15%', overflow: "auto" }} >{item.vendorPartName}</div>
                                                <div style={{ width: '10%' }}>{item.vendorPartCode}</div>
                                                <div style={{ width: '8%' }}>{rowData.components.length}</div>
                                                <div style={{ width: '8%' }}>{"-"}</div>
                                                <div style={{ width: '9%' }}>{"-"}</div>
                                            </>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </section>

                </div>

                <div className="footer" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div>
                        <button
                            style={{
                                border: "1px solid #0A18A3",
                                borderRadius: "3px",
                                padding: "5px 15px",
                                marginRight: "10px",
                                color: "#0A18A3",
                                width: '120px'
                            }}
                            onClick={() => closeModal(false)}
                        >
                            <KeyboardBackspace style={{ fontSize: "25px" }} />
                            Back
                        </button>
                        <button type="submit" id="apply" style={{ width: "120px" }} onClick={() => submitChallan()}>
                            Generate Challan
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PrepareForShipment; 