import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { successNotification, errorNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const MarkInProgress = ({ updatedData, rowData, closeModal }) => {

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(`serviceAdvisor-module/api/v1/serviceJobs/updateStatus`, { jobID: rowData.jobID, status: "Job In Progress" })
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "400px", height: "200px" }}>

        <div className="body">
          <h5>Are you sure to Mark this Job card ? </h5>
        </div>
        <div className="footer" style={{ justifyContent: "center" }}>
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={submitHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarkInProgress;
