import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import { errorNotification, successNotification } from "../../../../utils/notifications";
import {
  PauseCircleOutline,
  CancelOutlined,
  Vignette,
} from "@material-ui/icons";
import moment from "moment";
import DocSharing from "../../vehicleOutward/actions/docSharing";

const CompPartialRelease = ({
  closeModal,
  editData,
  releaseType,
  finalData,
  formValue,
  updatedData
}) => {
  const [releaseNoteData, setReleaseNoteData] = useState([]);
  const [open, setOpen] = useState(false);

  const cnoFormatter = (item) => {
    if (item.releasedQty) {
      return (
        <ul className="VIN_NO" style={{ padding: "0px" }}>
          {item?.releasedQty?.map((item) => {
            return <li>{item?.partSno}</li>;
          })}
        </ul>
      );
    }
  };
  let reason = formValue.partialReason == "Others" ? formValue.description : formValue.partialReason

  console.log(formValue)
  const submitHandler = async () => {
    console.log(reason)
    let bodyData = {
      requestID: finalData?.requisitionID,
      status: "Partial Release",
      reason: reason
    }
    const resp = await trackPromise(API.put(`inventory-module/api/v1/inventoryOutward/component/newRequest/releaseInventory`, bodyData));
    console.log(resp);
    if (resp.success == true) {
      successNotification(resp.message);
      updatedData()
    } else {
      errorNotification(resp.message);
    }
    closeModal(false)
  }

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1180px", height: "425px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "0px",
          }}
        >
          <h3>Generate Release Note</h3>
          {/* <button
                        onClick={() => closeModal(false)}
                        style={{ backgroundColor: "white", border: "1px solid white" }}

                    >
                        <CancelOutlined style={{ color: "#0A18A3", fontSize: "28px" }} />
                    </button> */}
        </div>

        <div
          className="body"
          style={{
            display: "flex",
            height: "300px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested ID : {finalData?.requisitionID}</span>
              <span>Requested By : {finalData?.requestBy}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>PO No. : {finalData?.poNo}</span>
              <span>
                Requested Date :{" "}
                {moment(finalData?.requisitionDate).format("DD-MMM-YYYY/HH:mm")}
              </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span></span>
              <button className="deactivate-btn">
                {" "}
                <span className="deactive-btn-font">Partial Release</span>
              </button>
            </div>
          </section>

          <section style={{ width: "100%" }}>
            <div id="POVendorHeading">
              <div style={{ width: "10%" }}>S.No.</div>
              <div style={{ width: "15%" }}>Component Name</div>
              <div style={{ width: "15%" }}>SKU Code</div>
              <div style={{ width: "15%" }}>Category</div>
              <div style={{ width: "15%" }}>Sub Category</div>
              <div style={{ width: "10%" }}>Added Qty.</div>
              <div style={{ width: "10%" }}>Remaining Qty.</div>
              <div style={{ width: "10%" }}>Component S.No.</div>
            </div>
            <div>
              {finalData?.requisitionDetails?.map((item, index) => {
                return (
                  <div
                    id="POVendorContent"
                    style={{
                      borderBottom: "1px solid #999999",
                      boxShadow: "none",
                      padding: "0px",
                    }}
                    key={""}
                  >
                    <>
                      <div style={{ width: "10%" }}>{index + 1}</div>
                      <div style={{ width: "15%" }}>{item?.sparePartname}</div>
                      <div style={{ width: "15%" }}>{item?.skuCode}</div>
                      <div style={{ width: "15%" }}>{item?.category}</div>
                      <div style={{ width: "15%" }}>{item?.subCategory}</div>
                      <div style={{ width: "10%" }}>{item?.releasedQty?.length}</div>
                      <div style={{ width: "10%" }}>
                        {item?.remainingQty}
                      </div>
                      <div style={{ width: "10%" }}>{cnoFormatter(item)}</div>
                    </>
                  </div>
                );
              })}
            </div>
          </section>
        </div>

        <div
          className="footer"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <button
              style={{
                border: "1px solid #0A18A3",
                borderRadius: "3px",
                padding: "5px 15px",
                marginRight: "10px",
                color: "#0A18A3",
                width: "152px",
              }}
              onClick={() => closeModal(false)}
            >
              Cancel
            </button>
            <button
              style={{
                background: "#0A18A3",
                border: "1px solid #0A18A3",
                borderRadius: "3px",
                padding: "5px 15px",
                color: "white",
                width: "140px",
              }}
              //   onClick={() => setOpen(true)}
              onClick={submitHandler}
            >
              Submit
            </button>
            {/* {open && <DocSharing closeModal={setOpen} />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompPartialRelease;
