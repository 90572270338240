import React, { useEffect, useState } from 'react';
import Group from '../QcDashboard/assets/Group.png';
import Group22 from '../QcDashboard/assets/Group22.png';
import Group2 from '../QcDashboard/assets/Group2.png';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';

function ZonesAreasDash() {
    const [zoneData, setData] = useState({});

    const fetchData = async () => {
        const resp = await trackPromise(
            API.get(`operations-module/api/v1/dashboard/zoneAndAreas`)
        );
        setData(resp);
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className='grid-container'>
                <div className='shopFloorRequest' style={{ width: "230px", height: "70px", padding: "10px", display: "flex", justifyContent: "space-around" }}>
                    <img src={Group} style={{ marginLeft: "8px" }} alt="group" />
                    <div>
                        <span style={{ fontSize: "16px", fontWeight: "500", color: "#3442CF" }}>Total Zones</span>
                        <div style={{ fontSize: "26px", fontWeight: "500" }}>{zoneData.totalZones}</div>
                    </div>
                </div>

                <div className='shopFloorRequest' style={{ width: "230px", height: "70px", padding: "10px", display: "flex", justifyContent: "space-around" }}>
                    <img src={Group22} style={{ marginLeft: "8px" }} alt="group" />
                    <div>
                        <span style={{ fontSize: "16px", fontWeight: "500", color: "#FFD74A" }}>Total Areas</span>
                        <div style={{ fontSize: "26px", fontWeight: "500" }}>{zoneData.totalAreas}</div>
                    </div>
                </div>

                <div className='shopFloorRequest' style={{ width: "230px", height: "70px", padding: "10px", display: "flex", justifyContent: "space-around" }}>
                    <img src={Group2} style={{ marginLeft: "8px" }} alt="group" />
                    <div>
                        <span style={{ fontSize: "16px", fontWeight: "500", color: "#FDAA48" }}>Active Zones</span>
                        <div style={{ fontSize: "26px", fontWeight: "500" }}>{zoneData.totalActiveZone}</div>
                    </div>
                </div>
                <div className='shopFloorRequest' style={{ width: "230px", height: "70px", padding: "10px", display: "flex", justifyContent: "space-around" }}>
                    <img src={Group2} style={{ marginLeft: "8px" }} alt="group" />
                    <div>
                        <span style={{ fontSize: "16px", fontWeight: "500", color: "#FFD74A" }}>Active Areas</span>
                        <div style={{ fontSize: "26px", fontWeight: "500" }}>{zoneData.totalActiveAreas}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ZonesAreasDash;