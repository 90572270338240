import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "../../../components/modal/modal.scss";
import moment from "moment";
import AppConstants from "../../../utils/app-constants";
import Action from "./ManageUserChargesAction";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ManageFeatures = () => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Monthly");

  const fetchReport = async () => {
    const resp = await trackPromise(API.get(`your-api-endpoint`));
    setUsersData(resp);
  };

  useEffect(() => {
    fetchReport();
  }, []);

  const actionFormatter = (cell, row, rowIndex) => {
    return (
      <div>
        <button className="action-btn" onClick={() => setActionShow(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          <Action updatedData={fetchReport} rowData={row} setIsOpen={setIsOpen} rowIndex={rowIndex} />
        ) : null}
      </div>
    );
  };

  const filteredUsersData = UsersData.filter(item => {
    if (selectedOption === "Monthly") {
      return item.monthlyCharges; // Adjust this condition based on your data structure
    }
    return item.yearlyCharges; // Adjust this condition based on your data structure
  });

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      sort: true,
    },
    {
      text: "Features",
      dataField: "features",
    },
    {
      text: "Action",
      dataField: "action",
      formatter: actionFormatter,
    },
  ];

  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <div className="bussiness">
        <h4 className="heading">Manage Features</h4>
      </div>

      <div style={{ display: "flex", flexDirection: "row", marginBottom: "30px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input type="radio" value="Monthly" checked={selectedOption === "Monthly"} onChange={() => setSelectedOption("Monthly")} />
          <span style={{ width: "90px", padding: "6px" }}>Monthly</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input type="radio" value="Yearly" checked={selectedOption === "Yearly"} onChange={() => setSelectedOption("Yearly")} />
          <span style={{ width: "90px", padding: "6px" }}>Yearly</span>
        </div>
      </div>


      <div className="heading bussiness" style={{marginBottom:"15px"}}>
        <span>Features</span>
      </div>


      <ToolkitProvider keyField="id" data={filteredUsersData} columns={columns} search>
        {(props) => (
          <>
            <BootstrapTable
              wrapperClasses="table-responsive"
              rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
              pagination={paginationFactory({
                hidePageListOnlyOnePage: true,
                showTotal: true,
                hideSizePerPage: true,
                paginationSize: 2,
                sizePerPage: "10",
              })}
              noDataIndication="No Data Found"
              {...props.baseProps}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ManageFeatures;
