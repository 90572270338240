import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import ComponentSpares from "./components/ComponentSpares";
// import Vehicle from "./components/Vehicles";

const ComponentSpares = React.lazy(()=>import("./components/ComponentSpares"))
const Vehicle = React.lazy(()=>import("./components/Vehicles"))
const Battery = React.lazy(()=>import("./components/Battery"))


const Quality = () => {
  const tabs = [
    {
      id: "QCM1T1",
      label: TabLabels.COMPONENTS_SPARES,
      Component: ComponentSpares
    },
    {
      id: "QCM1T2",
      label: TabLabels.VEHICLE,
      Component: Vehicle
    },
    {
      id: "QCM1T3",
      label: TabLabels.BATTERY_QC,
      Component: Battery
    },
  ];

  const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
    return tabs.filter(tab => tabIds.includes(tab.id));
  };
  let tabsToShow = getTabs(tabs);

  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>

  )
}
export default Quality