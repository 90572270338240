import React from 'react';
import { Tabs } from 'react-simple-tabs-component';
import { TabLabels } from '../../utils/app-constants';
import "../settings-tabs/tabs.scss";
// import Requisitions from './Requisitions';
// import POHistory from "./PoHistory/POHistory"
// import GoodsArrival from './GoodsArrival/GoodsArrival'
// import GoodsVerification from './GoodsVerification/GoodsVerification';

const Requisitions = React.lazy(()=>import('./Requisitions'))
const GoodsArrival = React.lazy(()=>import('./GoodsArrival/GoodsArrival'))
const GoodsVerification = React.lazy(()=>import('./GoodsVerification/GoodsVerification'))
const POHistory = React.lazy(()=>import("./PoHistory/POHistory"))


const ShowroomOrders = () =>{

    const tabs = [
        {
        id:"SHOM2T1",
        label:TabLabels.REQUISITION,
        Component:Requisitions
        },
        {
        id:"SHOM2T2",
        label:TabLabels.GOODS_ARRIVAL,
        Component:GoodsArrival
        },
        {
        id:"SHOM2T3",
        label:TabLabels.GOODS_VERIFICATION,
        Component:GoodsVerification
        },
        {
        id:"SHOM2T4",
        label:TabLabels.PO_HISTORY,
        Component:POHistory
        }
    ]

    return(
        <div className='app-layout__card'>
            <Tabs type="pills" tabs={tabs} />
        </div>
    )
}

export default ShowroomOrders