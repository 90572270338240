import React, { useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";

const ZonesViewMappedStates = ({ rowData, close }) => {
    console.log(rowData)
    return (
        <div>
            <div className="container1">
                <div className="modalContainer" style={{ width: "400px", height: "450px" }}>
                    <div className="header">
                        <h3>Mapped States</h3>
                    </div>
                    <label style={{ textAlign: "left" }}>{`Zone Name :`} <b style={{ color: "#0A18A3" }}>{rowData.zone}</b></label>
                    <div className="body">
                        <table style={{ width: "100%" }}>
                            <tr>
                                <th style={{ width: "16%" }}>S.No</th>
                                <th>Mapped States</th>
                            </tr>
                            {
                                rowData.states.map((item, index) => (
                                    <tr>
                                        <td>{index + 1}.</td>
                                        <td>{item.stateName}</td>
                                    </tr>
                                ))
                            }
                        </table>
                    </div>
                    <div className="footer">
                        <button onClick={() => close(false)}><KeyboardBackspace /> Back</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ZonesViewMappedStates;
