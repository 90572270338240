import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../../utils/app-constants";
import "../../settings-tabs/tabs.scss";
// import ServiceCenterAddUsers from "./ServiceCenterAddUsers";
// import ServiceCenterManageUsers from "./ServiceCenterManageUsers";
// import ServiceCenterUserHistory from "./ServiceCenterUserHistory";

const ServiceCenterAddUsers = React.lazy(()=>import("./ServiceCenterAddUsers"))
const ServiceCenterManageUsers = React.lazy(()=>import("./ServiceCenterManageUsers"))
const ServiceCenterUserHistory = React.lazy(()=>import("./ServiceCenterUserHistory"))



const tabs = [
  {
    id:"SVCM1T1",
    label: TabLabels.ADD_USER,
    Component: ServiceCenterAddUsers
  },
  {
    id:"SVCM1T2",
    label: TabLabels.MANAGE_USER,
    Component: ServiceCenterManageUsers
  },
  {
    id:"SVCM1T3",
    label: TabLabels.USER_HISTORY,
    Component: ServiceCenterUserHistory
  }
];

const ServiceCenterUserTabs = () => {
  // let tabsToShow=getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default ServiceCenterUserTabs;
