import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';

function UserPieChart({ userTypeData }) {

    const [data, setData] = useState({});

    const fetchData = async () => {
        const res = await trackPromise(
            API.get(`serviceCenter-module/api/v1/dashboard/getServiceJobs`)
        );
        setData(res);
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div style={{ width: "40%", height: "211px", marginLeft: "10px" }}>
            <Doughnut
                data={{
                    datasets: [{
                        data: [data.totalJobs, data.totalCompletedJob, data.totalInProgressJob, data.totalOnHaltJob],
                        backgroundColor: [
                            'rgb(0 47 120)',
                            'rgba(33, 168, 46, 1)',
                            'rgba(253, 170, 72, 1)',
                            'rgba(237, 52, 75, 1)',
                        ],
                        options: {
                            radius: "5%",
                            height: "10px"
                        },
                        hoverOffset: 4,
                    }
                    ],
                    labels: ["Total Jobs", "Jobs Completed", "Jobs In-Progress", "Jobs On Halt"]
                }} />
            <div style={{ margin: "-70% 0 0 100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430", width: "286px" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgb(0 47 120)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "114px", fontSize: "13px", fontWeight: "500", marginTop: "3px" }}>Total Jobs</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "13px", fontWeight: "700", marginTop: "3px" }}>
                        {data.totalJobs}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430", width: "286px" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(33, 168, 46, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "114px", fontSize: "13px", fontWeight: "500", marginTop: "3px" }}>Jobs Completed</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "13px", fontWeight: "700", marginTop: "3px" }}>
                        {data.totalCompletedJob}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430", width: "286px" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(253, 170, 72, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "114px", fontSize: "13px", fontWeight: "500", marginTop: "3px" }}>Jobs In-Progress</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "13px", fontWeight: "700", marginTop: "3px" }}>
                        {data.totalInProgressJob}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430", width: "286px" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(237, 52, 75, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "114px", fontSize: "13px", fontWeight: "500", marginTop: "3px" }}>Jobs On Halt</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "13px", fontWeight: "700", marginTop: "3px" }}>
                        {data.totalOnHaltJob}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default UserPieChart