import { KeyboardBackspace } from "@material-ui/icons";
import React from "react";
import auth from "../../../utils/auth";

const ViewAssemblyCost = ({ closeModal, row }) => {

  const UsersData = row.bomAssembly;
  console.log(UsersData);
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "80%", height: "414px", padding: "15px" }}
      >
        <div className="header">
          <h3>BOM Assembly Details</h3>
        </div>

        <br />
        <div className="body">

          <div style={{ width: "100%", }}>
            <div id="assemblyTableHeading" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>Assembly Name</div>
              <div>Required Services</div>
              <div>Required Unit</div>
              <div>{`Cost(${auth.getUserInfo().currencySymbol})`}</div>
              <div>{`Total Cost (${auth.getUserInfo().currencySymbol})`}</div>
            </div>
            <div style={{ height: '200px', overflowY: "scroll" }}>
              {UsersData.map((item) => (
                <div id="assemblyTableContent" style={{ display: 'flex', }}>
                  <div style={{ width: '20%', margin: '10px 0' }}>{item.assemblyName}</div>
                  <div style={{ width: '80%' }}>
                    {item.assemblyServices.map(service => (
                      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                        <div style={{ width: '209px', textAlign: "center", overflow: "scroll" }}>{service?.serviceName}</div>
                        <div style={{ width: '154px', textAlign: "left", overflow: "scroll" }}>{`${service?.timeDuration}  ${service?.unit}`}</div>
                        <div style={{ width: '154px', textAlign: "left", overflow: "scroll" }}>{`${service?.serviceCost} Per ${service?.time}`}</div>
                        <div style={{ width: '154px', textAlign: "left", overflow: "scroll" }}>{parseInt(service?.timeDuration) * parseInt(service?.serviceCost)}</div>
                      </div>
                    ))}
                  </div>

                </div>
              ))}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "8px" }}>{`Total Cost (${auth.getUserInfo().currencySymbol})`}</span>
                <span
                  style={{
                    padding: "7px",
                    width: "141px",
                    backgroundColor: "#e5e5e5",
                  }}
                >
                  {row.totalAssemblyCost}
                </span>
              </div>
            </div>
          </div>


        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}><KeyboardBackspace /> Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewAssemblyCost;
