import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import ManageUser from "./Tabs/ManageUsers";
// import RequestedUpdates from "./Tabs/RequestedUpdates";
// import UsersHistory from "./Tabs/UsersHistory";

const ManageUser = React.lazy(()=>import("./Tabs/ManageUsers"))
const RequestedUpdates = React.lazy(()=>import("./Tabs/RequestedUpdates"))
const UsersHistory = React.lazy(()=>import("./Tabs/UsersHistory"))


const NationalSalesUsers = () => {
  const tabs = [
    {
      id: "NSMM1T1",
      label: TabLabels.MANAGE_USER,
      Component: ManageUser,
    },
    {
      id: "NSMM1T2",
      label: TabLabels.REQUESTED_UPDATES,
      Component: RequestedUpdates,
    },
    // {
    //   id: "NSMM1T3",
    //   label: TabLabels.USER_HISTORY,
    //   Component: UsersHistory,
    // },
  ];

  return (
    <div className="app-layout__card">
      <Tabs type="pills" tabs={tabs} />
    </div>
  );
};

export default NationalSalesUsers;
