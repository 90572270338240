import React, { useState } from "react";
import "../../../../components/modal/modal.scss";
import StandardVehicleDetails from "./StandardVehicleDetails";
import FinishGoodsHistory from "./inv-history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const StandardVehicleAction = ({ rowData  , rowIndex}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      viewDetails: false,
      history: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.viewDetails && showModal && <StandardVehicleDetails rowData={rowData} closeModal={setShowModal} />}
      {/* {activeModal.history && showModal && <FinishGoodsHistory historyData={rowData} closeModal={setShowModal} />} */}

       <div style={ rowIndex === 0 || rowIndex === 1 ? { position: "relative" } : {position:"relative", marginTop:"-20px"}}>
       <div style={{ marginLeft: "-130px" }} className="action">
          <div>
            <span id="viewDetails" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faEye} /> View Details
            </span>
          </div>
          {/* <div>
            <span id="history" onClick={(e) => clickHandler(e)}>
              Inventory History
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default StandardVehicleAction;