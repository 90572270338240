import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";
import images from "../../utils/get-images";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faTimes,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import auth from "../../utils/auth";
const eye = <FontAwesomeIcon icon={faEye} />;
const eyeslash = <FontAwesomeIcon icon={faEyeSlash} />;

const OperationChangePassword = () => {
  const [formValue, setFormValue] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const editDetails = {
    userID: auth.getUserInfo().userID,
    oldPassword: formValue.oldPassword,
    newPassword: formValue.newPassword,
    confirmNewPassword: formValue.confirmNewPassword,
  };

  const submitHandler = async () => {
    console.log(formValue);
    const resp = await trackPromise(
      API.post("admin-module/api/v1/users/changePassword", editDetails)
    );
    const { success, message } = resp;

    if (success) {
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 2000 });
    }

    document.getElementById("current").value = "";
    document.getElementById("new").value = "";
    document.getElementById("confirm").value = "";
  };

  const cancel = (e) => {
    document.getElementById("current").value = "";
    document.getElementById("new").value = "";
    document.getElementById("confirm").value = "";
  };

  const [passwordShown, setPasswordShown] = useState(true);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [passwordNewShown, setPasswordNewShown] = useState(true);
  const togglePasswordNewVisiblity = () => {
    setPasswordNewShown(passwordNewShown ? false : true);
  };
  const [passwordConfirmShown, setPasswordConfirmNewShown] = useState(true);
  const togglePasswordConfirmVisiblity = () => {
    setPasswordConfirmNewShown(passwordConfirmShown ? false : true);
  };

  return (
    <section className=" banner-two get-report">
      <br />
      <div className="container">
        <div className="banner-content-wrap">
          <div
            className="row align-items-center"
            style={{ justifyContent: "space-around" }}
          >
            {/* left side */}
            <div className="col-lg-6" style={{ marginTop: "50px" }}>
              <img
                src={images["changePassword.png"]}
                alt="logo"
                className="mb-40"
              />
            </div>
            {/* //right side */}
            <div
              className="col-lg-6 password-box" style={{borderRadius:"5px"}}
            >
              <div>Change Password</div>
              <hr></hr>
              <div style={{ width: "98%" }} className="banner-content">
                <div className="position-relative">
                  <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                    }}
                  >
                    Current Password<span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control-custom"
                    id="current"
                    placeholder="Current Password"
                    name="oldPassword"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        [e.target.name]: e.target.value,
                      })
                    }
                  //   ref={register({
                  //     required: "You must specify your old password"
                  //   })}
                  />
                  <i
                    style={{ marginTop: "20px" }}
                    onClick={togglePasswordVisiblity}
                  >
                    {passwordShown ? eye : eyeslash}
                  </i>
                  {/* {errors.oldPassword && (
                      <p className="text-danger small">
                        {errors.oldPassword.message}
                      </p>
                    )} */}
                </div>

                <div className="position-relative mt-4 pass-wrapper">
                  <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                    }}
                  >
                    New Password<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={passwordNewShown ? "text" : "password"}
                    className="form-control-custom"
                    id="new"
                    placeholder="New Password"
                    name="newPassword"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        [e.target.name]: e.target.value,
                      })
                    }
                  //   ref={register({
                  //     required: "You must specify new password"
                  //   })}
                  //   onChange={() =>
                  //     watch("confirmPassword") &&
                  //     triggerValidation("confirmPassword")
                  //   }
                  />
                  <i
                    style={{ marginTop: "20px" }}
                    onClick={togglePasswordNewVisiblity}
                  >
                    {passwordNewShown ? eye : eyeslash}
                  </i>
                  {/* {errors.newPassword && (
                      <p className="text-danger small">
                        {errors.newPassword.message}
                      </p>
                    )} */}
                </div>
                <div className="position-relative mt-4 pass-wrapper">
                  <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                    }}
                  >
                    Confirm Password<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={passwordConfirmShown ? "text" : "password"}
                    className="form-control-custom"
                    id="confirm"
                    placeholder="Confirm Password"
                    name="confirmNewPassword"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        [e.target.name]: e.target.value,
                      })
                    }
                  //   ref={register({
                  //     validate: value =>
                  //       value === newPassword.current ||
                  //       "The passwords do not match"
                  //   })}
                  />
                  <i
                    style={{ marginTop: "20px" }}
                    onClick={togglePasswordConfirmVisiblity}
                  >
                    {passwordConfirmShown ? eye : eyeslash}
                  </i>
                  {/* {errors.confirmPassword && (
                      <p className="text-danger small">
                        {errors.confirmPassword.message}
                      </p>
                    )} */}
                </div>

                <br />
                <div className="save-cancel">
                  <div className="cust-reset  cancel-button">
                    <button
                      type="button"
                      className="btn btn-icon-label"
                      onClick={(e) => cancel(e)}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#0A18A3",
                          fontWeight: "500",
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} /> Cancel
                      </div>
                    </button>
                  </div>
                  <div className=" cancel-button">
                    <button
                      className="btn btn-icon-label"
                      onClick={submitHandler}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#0A18A3",
                          fontWeight: "500",
                        }}
                      >
                        <FontAwesomeIcon icon={faSave} />
                        Update
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OperationChangePassword;
