import React from "react";
import "./subscription.scss";
import { ImCancelCircle } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { GoDash } from "react-icons/go";

const ComparisonPlan = ({ closeModal, selectedPlan, setPurchaseSummary, markHandleChange, selectedOption , checkedPlans, isChecked, handleCheckboxChange ,}) => {


  return (
    <>
      <div id="addUser" className="bussiness">
        <h4 className="heading">Compare Subscription Plans</h4>
      </div>

      <div
        style={{ display: "flex", flexDirection: "row", overflow: "scroll" }}
      >
        <span className="feat">Plan Features</span>

        {
          selectedOption==="Monthly" ? (
            selectedPlan?.map((item,index) => (
          <div className="cmpPlan shadow" key={index}>
            <span  onClick={() => closeModal([])} className="corner">
            <input
              type="checkbox"
              checked={(selectedPlan?.map((ele)=> ele.planId == item.planId))? true : false}
              onChange={()=>markHandleChange(item)}
            />
            </span>

            <span>{item.planName}</span>
            <span>{item.planValidity}</span>

            <span
              style={{
                color: "#0a18a3",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ fontWeight: "bold" }}>₹ {item.monthlyCharges}</span>
              <span>Incl. GST</span>
            </span>

            <button
              className="buyButtonPlan"
              style={{
                width: "100px",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setPurchaseSummary(item)}
            >
              Buy Now
            </button>
          </div>
        ))
          ) : (
            selectedPlan?.map((item,index) => (
          <div className="cmpPlan shadow" key={index}>
            <span  onClick={() => closeModal([])} className="corner">
            <input
              type="checkbox"
              checked={(selectedPlan?.map((ele)=> ele.planId == item.planId))? true : false}
              onChange={()=>markHandleChange(item)}
            />
            </span>

            <span>{item.planName}</span>
            <span>{item.planValidity}</span>

            <span
              style={{
                color: "#0a18a3",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ fontWeight: "bold" }}>₹ {item.yearlyCharges}</span>
              <span>Excl. GST</span>
            </span>

            <button
              className="buyButtonPlan"
              style={{
                width: "100px",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setPurchaseSummary(item)}
            >
              Buy Now
            </button>
          </div>
        ))
          )
        }
      </div>

      <div
        style={{ display: "flex", flexDirection: "row", overflow: "scroll" }}
      >
        <span style={{ display: "flex", flexDirection: "column", width:"45%" }}>
          <ul>
            <li style={{paddingTop:"1px", borderBottom: "1px solid black"}}>Basic Modules</li>
            <li style={{paddingTop:"8px", borderBottom: "1px solid black"}}>Dashboards</li>
            <li style={{paddingTop:"8px", borderBottom: "1px solid black"}}>User Management</li>
            <li style={{paddingTop:"8px", borderBottom: "1px solid black"}}>Role Management</li>
            <li style={{paddingTop:"8px", borderBottom: "1px solid black"}}>Role Delegation</li>
            <li style={{paddingTop:"8px", borderBottom: "1px solid black"}}>Master Data Addition</li>
            <li style={{paddingTop:"8px", borderBottom: "1px solid black"}}>Notifications</li>
            <li style={{paddingTop:"8px", borderBottom: "1px solid black"}}>Sales Modules</li>
            <li style={{paddingTop:"8px", borderBottom: "1px solid black"}}>Limited Downloads</li>
            <li style={{paddingTop:"8px", borderBottom: "1px solid black"}}>Unlimited Downloads</li>
            <li style={{paddingTop:"8px", borderBottom: "1px solid black"}}>Plan History</li>
          </ul>
        </span>

        <span
          style={{ display: "flex", flexDirection: "row", overflow: "scroll" }}
        >
          {selectedPlan?.map((el, index) => (
            <span key={index} style={{marginTop:"9px"}}>
              {el?.features?.map((data, index) => (
                <ul key={index}  style={{borderBottom: "1px solid black" }}>
                  <li style={{ listStyle: "none"}}>{data.checked ? <FaCheck style={{color:"#2eb82e",width:"170px"}}/> : <GoDash style={{width:"170px"}}/>}</li>
                </ul>
              ))}
            </span>
          ))}
        </span>
      </div>
    </>
  );
};

export default ComparisonPlan;
