import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faUpload } from "@fortawesome/free-solid-svg-icons";

const EditArea = ({ updatedData, editData, closeModal }) => {
  console.log("editData-->", editData);

  const [statesArray, setStatesArray] = useState(editData.states);
  const [zoneName, setZoneName] = useState(editData.zone);
  const [areaData, setAreaData] = useState([]);
  const [stateName, setStateName] = useState([]);

  console.log("zoneName-->", zoneName);
  console.log("zone-->", editData.zone);

  console.log(
    "editData-->",
    editData.cities.map((item) => item.City)
  );

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `operations-module/api/v1/zoneManagement/getStatesByArea?zoneId=${editData.zoneId}`
      )
    );
    console.log(resp);
    setAreaData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  let stateArray = [];

  const [zoneData, setZoneData] = useState([]);

  const fetchData = async () => {
    const resp = await trackPromise(
      API.get(`operations-module/api/v1/zoneManagement/getZones`)
    );
    console.log(resp);
    setZoneData(resp);
  };
  useEffect(() => {
    fetchData();
  }, []);

  console.log("zoneData-->", zoneData);
  const dt = zoneData
    .filter((item) => item.zone == zoneName)
    .map((el) => el.states);
  stateArray.push(dt[0]);
  console.log("zoneData state-->", dt[0]);
  dt[0]?.map((item) => {
    console.log(item.stateName);
  });

  // const zoneHandler = (e) => {
  //   setZoneName(e.target.value);
  //   console.log(zoneData, e.target.value);
  //   const data = zoneData.filter((item) => item.zone == e.target.value);
  //   console.log(data[0].states);
  //   setStateName([...data[0].states]);

  //   //    data.map((item)=>{
  //   //     finalStateArray.push(item.states)
  //   //    })
  //   //    finalStateArray.push(data.map((item)=>item.states))
  //   //    console.log("data-->",data.map((item)=>item.states.map((el)=>el.stateName)))
  // };


  const zoneHandler = (e) => {
    const selectedZone = e.target.value;
    setZoneName(selectedZone);
    // Filter states based on the selected zone
    const selectedZoneData = zoneData.filter((item) => item.zone === selectedZone);
    if (selectedZoneData) {
      setStateName([selectedZoneData.states]);
      setCheckedStates([]); // Reset selected states
    } else {
      setStateName([]);
      setCheckedStates([]);
    }
    setCheckedCities([]); // Reset selected cities
  };

  let finalStateArray = [];

  editData.states.map((el) => {
    finalStateArray.push({ ...el });
  });

  areaData.filter((item) => {
    finalStateArray.push(item);
  });

 // State to track checked states
 const [checkedStates, setCheckedStates] = useState(editData.states);

 // Function to handle checkbox change for states
const onCheckboxChange = (e, item) => {
  const isChecked = e.target.checked;
  if (isChecked) {
    // Add the state to the list of checked states
    setCheckedStates((prevCheckedStates) => [...prevCheckedStates, item]);
  } else {
    // Remove the state from the list of checked states
    setCheckedStates((prevCheckedStates) =>
      prevCheckedStates.filter((state) => state.stateId !== item.stateId)
    );

    // Uncheck associated cities
    setCheckedCities((prevCheckedCities) =>
      prevCheckedCities.filter((city) => city.stateId !== item.stateId)
    );
  }
};


  // // Function to handle checkbox change for states
  // const onCheckboxChange = (e, item) => {
  //   const isChecked = e.target.checked;
  //   if (isChecked) {
  //     // Add the state to the list of checked states
  //     setCheckedStates([...checkedStates, item]);
  //   } else {
  //     // Remove the state from the list of checked states
  //     setCheckedStates(
  //       checkedStates.filter((state) => state.stateId !== item.stateId)
  //     );
  //   }
  // };

//   const onCheckboxChange = (e, item) => {
//     console.log("item-->", item);
//     let tempInfo = [...statesArray];
//     console.log("tempInfo-->", tempInfo);
//     setCheckedState(tempInfo);
//     if (e.target.checked == true) {
//       if (e.target.checked == false) {
//         tempInfo = tempInfo.filter((info) => info.stateId != item.stateId);
//         e.target.checked == false;
//       } else {
//         tempInfo.push(item);
//       }
//     } else {
//       if (e.target.checked == false) {
//         tempInfo = tempInfo.filter((info) => info.stateId != item.stateId);
//       } else {
//         tempInfo.push(item);
//       }
//     }
//     setStatesArray(tempInfo);
//   };

  var expanded = false;
  const showCheckboxes = () => {
    var checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
      checkboxes.style.display = "block";
      expanded = true;
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  };

  document.body.addEventListener("click", (event) => {
    const multiselect = document.querySelector(".multiselect");
    if (!multiselect.contains(event.target)) {
      // Click occurred outside the multiselect component
      if (expanded) {
        // Close the checkboxes if they are currently expanded
        var checkboxes = document.getElementById("checkboxes");
        checkboxes.style.display = "none";
        expanded = false;
      }
    }
  });

  console.log("checkedState-->", checkedStates);

  // for cities

  // Cities

  const [checkedCities, setCheckedCities] = useState([]);

  useEffect(() => {
    // Initialize checkedCities with editData.cities when editData.cities changes
    setCheckedCities(editData.cities);
  }, [editData.cities]);

  const [citiesData, setCitiesData] = useState([]);

  console.log("checkedCities-->", checkedCities);
  console.log("citiesData-->", citiesData);
  console.log(
    "cities-->",
    citiesData?.map((item) => item.City)
  );

//   const getCitiesByStates = async () => {
//     const citiesBody = checkedState?.map((stateId) => ({
//       stateId: stateId.stateId,
//     }));
//     const finalBody = {
//       cityIds: citiesBody,
//     };
//     const resp = await trackPromise(
//       API.post(`operations-module/api/v1/zoneManagement/cities`, finalBody)
//     );
//     console.log(resp);
//     setCitiesData(resp);
//   };

//   useEffect(() => {
//     getCitiesByStates();
//   }, []);

// Function to fetch cities by checked states
const getCitiesByStates = async () => {
    if (checkedStates.length === 0) {
      // No states checked, clear cities
      setCitiesData([]);
      return;
    }

    const citiesBody = checkedStates.map((state) => ({
      stateId: state.stateId,
    }));
    const finalBody = {
      cityIds: citiesBody,
    };
    const resp = await trackPromise(
      API.post(`operations-module/api/v1/zoneManagement/cities`, finalBody)
    );
    setCitiesData(resp);
  };

  useEffect(() => {
    getCitiesByStates();
  }, [checkedStates]);


//   useEffect(() => {
//     getCitiesByStates();
//   }, [checkedState]);

  //   const onCheckboxChangeCity = (e, item) => {
  //       console.log("item-->",item)
  //       let tempInfo = [...citiesData];
  //       console.log("tempInfo-->",tempInfo)
  //       setCheckedCities([...tempInfo])
  //       if (e.target.checked == true) {
  //           if (e.target.checked == false) {
  //               tempInfo = tempInfo.filter((info) => info.cityId != item.cityId);
  //               e.target.checked == false
  //           } else {
  //               tempInfo.push(item);
  //           }

  //       } else {
  //           if (e.target.checked == false) {
  //               tempInfo = tempInfo.filter((info) => info.cityId != item.cityId);
  //           } else {
  //               tempInfo.push(item);
  //           }
  //       }
  //       setCitiesData(tempInfo);
  //   };

  // Function to handle checkbox change for cities
  const onCheckboxChangeCity = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Add the city to the list of checked cities
      setCheckedCities([...checkedCities, item]);
    } else {
      // Remove the city from the list of checked cities
      setCheckedCities(
        checkedCities.filter((city) => city.cityId !== item.cityId)
      );
    }
  };

  var expand = false;
  const showCheckboxesCity = (index) => {
    var checkboxes = document.getElementById("citiesCheckboxes");
    if (!expand) {
      checkboxes.style.display = "block";
      expand = true;
    } else {
      checkboxes.style.display = "none";
      expand = false;
    }
  };

  document.body.addEventListener("click", (event) => {
    const multiselect = document.querySelector(".multiselect");
    if (!multiselect.contains(event.target)) {
      // Click occurred outside the multiselect component
      if (expand) {
        // Close the checkboxes if they are currently expand
        var checkboxes = document.getElementById("citiesCheckboxes");
        checkboxes.style.display = "none";
        expand = false;
      }
    }
  });

  const refresh = () => {
    updatedData();
  };

  const [areaName,setAreaName] = useState(editData.area)
  const handleInputChange = (event, index) => {
    setAreaName(event.target.value);
  };

  console.log("areaName-->",areaName)


  const submitHandler = async () => {
    let editAreaBody = {
      zoneId: editData.zoneId,
      zone: zoneName,
      areaId: editData.areaId,
      states: checkedStates,
      cities: checkedCities,
      area:areaName
    };
    console.log(editAreaBody);

    const resp = await trackPromise(
      API.put(`operations-module/api/v1/zoneManagement/editAreas`, editAreaBody)
    );
    if (resp.success == true) {
      successNotification(resp.message);
      refresh();
    } else {
      errorNotification(resp.message);
    }
    closeModal(false);
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "800px", height: "300px" }}
      >
        <div className="header">
          <h3>Edit Area Details</h3>
        </div>

        <div className="body" style={{height:"450px"}}>
          <label style={{ display: "flex", flexDirection: "column",height:"95px" }}>
            <label
              style={{
                float: "left",
                marginLeft: "-200px",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              Country
            </label>
            <input
              disabled
              name="countryName"
              value={editData.countryName}
              className="input-config"
              style={{marginTop:"-5px"}}
            />
          </label>

          {/* Select Zone */}
          <label style={{ display: "flex", flexDirection: "column" ,height:"95px" }}>
            <label
              style={{
                float: "left",
                marginLeft: "-210px",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              Zone
            </label>
            <input
              disabled
              name="zone"
                className="input-config"
                id="stateId"
                defaultValue={editData?.zone}
                style={{marginTop:"-5px"}}
            />
          </label>


          <label
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              height:"95px" 
            }}
          >
            <label
              style={{
                float: "left",
                marginLeft: "-160px",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              Area Name
            </label>
            <input
              type="text"
              name="area"
              value={areaName}
              className="input-config"
              style={{width:"220px",marginTop:"-5px"}}
              onChange={(event) => handleInputChange(event)}
            />
          </label>

         
          {/* Select States */}

          <div class="multiselect" style={{ width: "33%", marginTop:"-19px", marginLeft:"-9px" }}>
            <div class="selectBox" onClick={() => showCheckboxes()}>
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "9px",
                  width: "100%",
                  height:"95px" 
                }}
              >
                <label
                  style={{
                    float: "left",
                    marginLeft: "-210px",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  States
                </label>
                <select name="editStates" className="input-config" id="stateId"  style={{marginTop:"-5px"}}>
                  <option value="">Select/Unselect States</option>
                </select>
              </label>
              <div class="overSelect"></div>
            </div>
            {zoneName ? (
              <div
                id="checkboxes"
                style={{
                  padding: "5px",
                  textAlign: "left",
                  margin: "-24px 0px 0px 14px",
                  position: "absolute",
                  width: "15%",
                }}
              >
                <div style={{ overflow: "scroll", height: "130px" }}>
                  {dt[0]?.map((item, index) => {
                    return (
                      <label>
                        <input
                          className="zoneCheckbox"
                          type="checkbox"
                          id={item.stateId}
                          onChange={(e) => {
                            onCheckboxChange(e, item);
                          }}
                          // checked={statesArray?.some(
                          //   (el) => el?.stateId == item?.stateId
                          // )}
                          checked={checkedStates.some((state) => state.stateId === item.stateId)}
                        />
                        <span style={{ marginLeft: "5px" }}>
                          {item.stateName}
                        </span>
                      </label>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div
                id="checkboxes"
                style={{
                  padding: "5px",
                  textAlign: "left",
                  margin: "-24px 0px 0px 14px",
                  position: "absolute",
                  width: "16%",
                }}
              >
                <div style={{ overflow: "scroll", height: "130px" }}>
                  {finalStateArray?.map((item, index) => {
                    return (
                      <label>
                        <input
                          className="zoneCheckbox"
                          type="checkbox"
                          id={item.stateId}
                          onChange={(e) => {
                            onCheckboxChange(e, item);
                          }}
                        //   checked={statesArray.some(
                        //     (el) => el.stateId == item.stateId
                        //   )}
                        checked={checkedStates.some((state) => state.stateId === item.stateId)}
                        />
                        <span style={{ marginLeft: "5px" }}>
                          {item.stateName}
                        </span>
                      </label>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          {/* cities multiselect */}

          <div class="multiselect" style={{ width: "33%", marginTop:"-19px"  }}>
            <div class="selectBox" onClick={() => showCheckboxesCity()}>
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "9px",
                  width: "100%",
                }}
              >
                <label
                  style={{
                    float: "left",
                    marginLeft: "-210px",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Cities
                </label>
                <select name="editStates" className="input-config" id="stateId"  style={{marginTop:"-5px"}}>
                  <option value="">Select/Unselect Cities</option>
                </select>
              </label>
              <div class="overSelect"></div>
            </div>
            {
              <div
                id="citiesCheckboxes"
                style={{
                  padding: "5px",
                  textAlign: "left",
                  margin: "-12px 0px 0px 14px",
                  position: "absolute",
                  width: "16%",
                }}
              >
                <div style={{ overflow: "scroll", height: "130px" }}>
                  {citiesData?.map((item, index) => {
                    return (
                      <label key={item.cityId}>
                        <input
                          className="zoneCheckbox"
                          type="checkbox"
                          id={item.cityId}
                          onChange={(e) => onCheckboxChangeCity(e, item)}
                          checked={checkedCities.some(
                            (city) => city.cityId === item.cityId
                          )}
                        />
                        <span style={{ marginLeft: "5px" }}>{item.City}</span>
                      </label>
                    );
                  })}
                </div>
              </div>
            }
          </div>

        
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button id="submit-button" onClick={submitHandler}>
            <FontAwesomeIcon icon={faUpload} /> Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditArea;
