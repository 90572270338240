import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";

const DealerInventoryTabs = React.lazy(()=>import("./DealerInventory/DealerInventoryTabs"))
const DealerSalesTabs = React.lazy(()=>import("./DealerSales/DealerSalesTabs"))
const DealerReturnsTabs = React.lazy(()=>import("./DealerReturns/DealerReturnsTabs"))
const DealerCustomerTabs = React.lazy(()=>import("./DealerCustomer/DealerCustomerTabs"))
const DealerSalesReport = React.lazy(()=>import("./dealerSalesReport"))

const tabs = [
  {
    id:"DEM4T1",
    label: TabLabels.INVENTORY,
    Component: DealerInventoryTabs
  },
  {
    id:"DEM4T2",
    label: TabLabels.SALES,
    Component: DealerSalesTabs
  },
  {
    id:"DEM4T3",
    label: TabLabels.RETURNS,
    Component: DealerReturnsTabs
  },
  {
    id:"DEM4T4",
    label: TabLabels.CUSTOMERS,
    Component: DealerCustomerTabs
  },
  {
    id:"DEM4T5",
    label: TabLabels.SALES_REPORT,
    Component: DealerSalesReport
  }
];

const DealerShowroomTabs = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default DealerShowroomTabs;
