import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import ReturnedQtyModal from "./ReturnedQtyModal";
import moment from "moment";

const RRViewDetails = ({ closeModal, rowData }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <div className="container1">
            {
                openModal && <ReturnedQtyModal rowData={rowData} closeModal={setOpenModal} />
            }
            <div
                className="modalContainer"
                style={{ width: "1000px", height: "500px", padding: '15px' }}
            >
                <div className="header">
                    <h3>Spare Return Details</h3>
                </div>
                <br />
                <div
                    className="body"
                    style={{ display: "flex", height: "450px", fontSize: "14px", overflow: 'scroll' }}
                >

                    <section style={{ width: '100%', }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Total Returned Spares : {rowData.returnedData.length}</span>
                            <span>Total Returned Units : {rowData.returnedUnits}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>RPI No. : {rowData.rpiNo}</span>
                            <span>RPI Date : {rowData.rpiNo}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Returned To : {rowData?.requestedBy}</span>
                        </div>
                        <hr />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>RPI Details</span>
                            {
                                rowData.status == "In-Progress" ?
                                    <button className="reactive-btn" style={{ width: "15%" }}>
                                        <span className="reactive-btn-font">In-Progress</span>
                                    </button> :
                                    rowData.status == "On-Hold" ?
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "50%" }}>
                                            <button className="delete-btn">
                                                <span className="delete-btn-font">On-Hold</span>
                                            </button>
                                        </div>
                                        :
                                        rowData.status == "Return Note Generated" ?
                                            <div style={{ display: "flex", justifyContent: "space-between", width: "50%" }}>
                                                <button className="active-btn">
                                                    <span className="active-btn-font">CN Generated</span>
                                                </button>
                                            </div>
                                            :
                                            <button className="deactive-btn" style={{ width: "15%" }}>
                                                <span className="deactive-btn-font">Pending At Sales</span>
                                            </button>
                            }
                        </div>
                        <div style={{ overflow: "scroll" }}>
                            <div id='POVendorHeading'>
                                <div style={{ width: '8%' }}>S. No.</div>
                                <div style={{ width: '13%' }}>Spare Part Name</div>
                                <div style={{ width: '13%' }}>SKU Code</div>
                                <div style={{ width: '13%' }}>Category</div>
                                <div style={{ width: '13%' }}>Sub Category</div>
                                <div style={{ width: '13%' }}>Returned Units Qty.</div>
                                <div style={{ width: '13%' }}>Unit Price</div>
                                <div style={{ width: '13%' }}>Sub Total</div>

                            </div>
                            <div>

                                {rowData.returnedData.map((item, index) => {
                                    return (
                                        <>
                                            <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }} key={1}>
                                                <div style={{ width: '8%' }}>{index + 1}</div>
                                                <div style={{ width: '13%' }}>{item.sparePartname}</div>
                                                <div style={{ width: '13%' }}>{item.skuCode}</div>
                                                <div style={{ width: '13%' }}>{item.category}</div>
                                                <div style={{ width: '13%' }}>{item.subCategory}</div>

                                                <div style={{ width: '13%', color: "#0A18A3", cursor: "pointer" }} onClick={() => setOpenModal(true)}>{item.returnQty.length}</div>
                                                <div style={{ width: '13%' }}>{item.priceToserviceCenter}</div>
                                                <div style={{ width: '13%', }}>{item.returnQty.length * item.priceToserviceCenter}</div>
                                            </div>
                                        </>
                                    )
                                })
                                }


                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <span>GIN Details</span>
                        </div>
                        <div className="GINDetailsDiv">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>GIN No. : {rowData.ginNo}</span>
                                <span>Shipped By : {rowData.shipmentBy}</span>
                                <span>Delivery Vehicle Details : {rowData.deliveryVehicleNo}</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>GIN Issued By : Store Manager</span>
                                <span>Shipped Qty. : {rowData.returnedUnits}</span>
                                <span>Delivery Person : {rowData.deliveryPersonName}</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>GIN Date : {moment(rowData.ginDate).format("DD-MM-YYYY")}</span>
                                <span>Shipment Date : {moment(rowData.shipmentDate).format("DD-MM-YYYY")}</span>
                                <span>Delivery Person Contact : {rowData.deliveryPersonPhone}</span>
                            </div>
                        </div>
                    </section>

                </div>

                <div className="footer">
                    <div><button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button></div>

                </div>

            </div>
        </div>
    );
};

export default RRViewDetails