import React, { useState } from "react";
import { CancelOutlined, CheckCircle } from "@material-ui/icons";
// import DealerVehiclePO from "./DealerVehiclePO";
// import DealerSparePO from "./DealerSparePO";
import API from "../../utils/api";
import { trackPromise } from "react-promise-tracker";
import { errorNotification, successNotification } from "../../utils/notifications";


const ConfirmationBox = ({ closeModal, rowData, verify, selectedShippingAddress, selectedBillingAddress, updatedData, OpenOne, OpenTwo, OpenThree , actionShow }) => {
  console.log(selectedShippingAddress);
  console.log(selectedBillingAddress);
  const [openInvoice, setInVoice] = useState(false)


  const refresh = () => {
    updatedData();
  }

  const submitHandler = async () => {
    const body = {
      requisitionID: rowData.requisitionID,
      shipmentAddress: selectedShippingAddress?.showroomAddress,
      shippingContactNo: selectedShippingAddress?.shippingContactNo,
      shippingContactPerson: selectedShippingAddress?.shippingContactPerson,
      billingAddress: selectedBillingAddress
    }
    console.log(body)
    const resp = await trackPromise(API.put(`dealer-module/api/v1/requisitionAndOrder/generatePO`, body));
    if (resp.success) {
      successNotification(resp.message);
      closeModal(false);
      OpenOne(false);
      OpenTwo(false);
      actionShow(false);
      OpenThree(false);
      setInVoice(true);
      refresh();
    } else {
      errorNotification(resp.message)
    }
  };

  const submitSparePOHandler = async () => {
    console.log("hi")
    const body = {
      requisitionID: rowData.requisitionID,
      shipmentAddress: selectedShippingAddress,
      billingAddress: selectedBillingAddress
    }
    console.log(body)
    const resp = await trackPromise(API.put(`dealer-module/api/v1/requisitionAndOrder/generateSparePO`, body));
    if (resp.success) {
      successNotification(resp.message);
      closeModal(false);
      actionShow(false)
      setInVoice(true);
      refresh();
    } else {
      errorNotification(resp.message)
    }
  };

  return (
    <div className='container1'>
      <div className="modalContainer" style={
        { width: '450px', height: '200px' }
      }>

        <div className="body" style={{ justifyContent: 'center', fontSize: '16px', padding: "0px" }}>
          Are you sure you want to Proceed?

        </div>
        <div className="footer" style={{ justifyContent: "center" }}>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: 'white',
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
            onClick={verify == "SpareGeneratePO" ? submitSparePOHandler : submitHandler}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>

        </div>
      </div>
      {/* {
        openInvoice && verify == "GeneratePO" ? <DealerVehiclePO closeModal={setInVoice} rowData={rowData} />
          : openInvoice && verify == "SpareGeneratePO" ? <DealerSparePO setInVoice={setInVoice} rowData={rowData} /> : ""
      } */}
    </div>
  )
}

export default ConfirmationBox;
