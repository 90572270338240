import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { KeyboardBackspace, CheckCircle } from "@material-ui/icons";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import OpenConfirmation from "./OpenConfirmation";

const EditZonalSales = ({ closeModal, editData, userType, updatedData }) => {

  let fName = editData.zsmFname;
  let lName = editData.zsmLname;
  let email = editData.zsmEmail;

  const [formValue, setFormValue] = useState({
    fName: fName,
    lName: lName,
    email: email,
  });

  const [getUnmappedZones, setUnMappedZones] = useState([]);
  const fetchUnmappedZones = async () => {
    const resp = await trackPromise(
      API.get(`operations-module/api/v1/salesUser/getAllUnmappedZones`)
    );
    console.log(resp);
    setUnMappedZones(resp);
  };
  useEffect(() => {
    fetchUnmappedZones();
  }, []);

  const [zonesArray, setZonesArray] = useState(editData.zones);
  const [openConfirmation, setOpenConfirmartion] = useState(false);
  const [confirmFlag, setConfirmFlag] = useState(false);

  const handleInputChange = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  const handleConfirm = () => {
    setOpenConfirmartion(true);
  };


  const submitHandler = async () => {
    let zonalBodyData = {
      countryName: editData.countryName,
      zones: zonesArray,
      zsmFname: formValue.fName,
      zsmLname: formValue.lName,
      zsmEmail: formValue.email,
      zsmUserId: editData?.zsmUserId,
    };
    console.log(zonalBodyData);
    const resp = await trackPromise(
      API.post(
        `operations-module/api/v1/salesUser/editZoneUser`,
        zonalBodyData
      )
    );
    if (resp.success) {
      updatedData("", "Zonal Sales");
      closeModal(false);
      return successNotification(resp.message, { autoClose: 10000 });
    } else {
      return errorNotification(resp.message, { autoClose: 10000 });
    }
  };

  const onCheckboxChange = (e, item) => {
    console.log(zonesArray);
    let tempInfo = [...zonesArray];
    if (e.target.checked == true) {
      if (e.target.checked == false) {
        tempInfo = tempInfo.filter((info) => info.zoneId != item.zoneId);
        e.target.checked == false;
      } else {
        tempInfo.push(item);
      }
    } else {
      if (e.target.checked == false) {
        tempInfo = tempInfo.filter((info) => info.zoneId != item.zoneId);
      } else {
        tempInfo.push(item);
      }
    }
    setZonesArray(tempInfo);
  };

  var expanded = false;
  const showCheckboxes = () => {
    var checkboxes = document.getElementById("checkboxes");
    if (!expanded && userType != "National Sales") {
      checkboxes.style.display = "block";
      expanded = true;
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  };

  return (
    <div className="container1">
      {openConfirmation && !confirmFlag && (
        <OpenConfirmation
          closeModal={setOpenConfirmartion}
          getConfirmFlag={setConfirmFlag}
        />
      )}
      <div
        className="modalContainer"
        style={{ width: "835px", padding: "15px" }}
      >
        <div className="header">
          <h3>Edit User Details </h3>
        </div>
        <section>
          <form id="add-user-from" className="setting-form">
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px",
                  }}
                >
                  User Type
                </label>
                <input
                  type="text"
                  placeholder="User Type"
                  name="fName"
                  value={userType}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px",
                  }}
                >
                  Country
                </label>
                <input
                  type="text"
                  placeholder="Country"
                  name="fName"
                  value={editData.countryName}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                />
              </div>
              <div
                style={{
                  margin: "0px 10px 0px 0px",
                  // width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div class="multiselect">
                  <div class="selectBox" onClick={() => showCheckboxes()}>
                    <label style={{ display: "flex", flexDirection: "column" }}>
                      <label
                        style={{
                          marginLeft: "-107px",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        Select Zones
                      </label>
                      <select
                        name="zones"
                        className="input-config"
                        id="zoneId"
                        style={{ marginTop: "-8px" }}
                      >
                        <option value="">Select Zones</option>
                      </select>
                    </label>
                    <div class="overSelect"></div>
                  </div>
                  <div
                    id="checkboxes"
                    style={{
                      padding: "5px",
                      textAlign: "left",
                      position: "absolute",
                      width: "15%",
                      height: "25%",
                      overflow: "scroll",
                    }}
                  >
                    {[...editData?.zones, ...getUnmappedZones]?.map(
                      (item, index) => {
                        return (
                          <label>
                            <input
                              className="zoneCheckbox"
                              type="checkbox"
                              id={item.zoneId}
                              onChange={(e) => {
                                onCheckboxChange(e, item);
                              }}
                              checked={zonesArray.some(
                                (el) => el.zoneId == item.zoneId
                              )}
                            />
                            <span style={{ marginLeft: "5px" }}>
                              {item.zone}
                            </span>
                          </label>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px",
                  }}
                >
                  Mapped States
                </label>
                <select
                  disabled={userType == "National Sales" ? true : false}
                  name="states"
                  id="mappedStates"
                  className="input-config"
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                >
                  <option style={{ color: "gray" }} value="" disabled selected>
                    Mapped States
                  </option>
                  {editData.zones.map((item) => {
                    return item.states?.map((item) => {
                      return (
                        <option value="" disabled>
                          {item.stateName}
                        </option>
                      );
                    });
                  })}
                </select>
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                  }}
                >
                  First Name
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="fName"
                  defaultValue={fName}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                  }}
                >
                  Last Name
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lName"
                  defaultValue={lName}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                  }}
                >
                  Email ID
                </label>
                <input
                  // disabled={confirmFlag == true ? false : true}
                  type="text"
                  placeholder="Email ID"
                  name="email"
                  defaultValue={email}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                  onClick={(e) => handleConfirm(e)}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          </form>
        </section>
        <div
          className="footer"
          style={{ width: "100%", display: "flex", flexDirection: "row" }}
        >
          <button
            style={{ width: "100px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            &nbsp; Back
          </button>
          <button
            onClick={submitHandler}
            style={{
              marginRight: "0",
              width: "120px",
              height: "42px",
              backgroundColor: "#0A18A3",
            }}
            id="submit-button"
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            &nbsp; Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditZonalSales;
