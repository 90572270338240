import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import { successNotification } from "../../../../utils/notifications";
import { CheckCircle, KeyboardBackspace } from "@material-ui/icons";
import "../../outward.scss";
import SearchBox from "../../../../components/search/SearchBox";


const AddComponents = ({ vehicleUpdatedData, row, rowData, closeModal, updatedData, updateRowData  }) => {
  const [formValue, setFormValue] = useState([]);
  const [addVehicleData, setAddVehicleData] = useState([]);
  const [unitPrice, setUnitPrice] = useState("")

  console.log(rowData);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/inventoryOutward/component/newRequest/getAvailableSpareDetails?skuCode=${row?.skuCode}`
      )
    );
    console.log(resp);
    setAddVehicleData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  console.log(addVehicleData);

  let CNO = [];
  const submitHandler = async () => {
    formValue.map((item) => {
      // setUnitPrice(item?.unitPrice)
      return CNO.push({
        unitPrice: item?.unitPrice,
        partSno: item?.partSno,
        componentID: item?.componentID,
        componentName: item?.componentName,
      });
    });

    row.added = true;
    closeModal(false);

    let bodyData = {
      requestID: rowData?.requisitionID,
      invoiceSpareData: {
        sparePartMrp: row?.sparePartMrp,
        skuCode: row?.skuCode,
        sparePartName: row?.sparePartname,
        hsnSacCode: addVehicleData?.hsnSacCode,
        taxRate: addVehicleData?.taxRate,
        unitPrice: row?.priceToserviceCenter,
        category: row?.category,
        subCategory: row?.subCategory,
        specifications: row?.specifications,
        invoiceQty: CNO?.length.toString(),
        orderedQty: row?.requiredQty,
        requiredQty: row?.requiredQty,
        warrantyID: row?.warrantyID,
        warrantyDuration: row?.warrantyDuration,
        warrantyDurationUnit: row?.warrantyDurationUnit,
        warrantyAddDate: row?.warrantyAddDate,
        operationID: row?.operationID,
        remainingQty: (row?.requiredQty - CNO?.length).toString(),
        releasedQty: CNO,
        unitReceived: 0,
        verifiedUnits: 0
      },
    };
    console.log(bodyData);

    const resp = await trackPromise(API.put(`inventory-module/api/v1/inventoryOutward/component/newRequest/addingInventory`, bodyData));
    console.log(resp);
    if (resp.success == true) {
      updatedData();
      vehicleUpdatedData();
      successNotification(resp.message);
      updateRowData({ ...row, releasedQty: CNO });
    } else {
      errorNotification(resp.message);
    }
    updatedData();
    closeModal(false)
  };

  vehicleUpdatedData(row);

  const onCheckboxChange = (item) => {
    console.log(item)
    let tempInfo = formValue;
    if (tempInfo.some((info) => info?.partSno === item?.partSno)) {
      tempInfo = tempInfo.filter((info) => info.partSno !== item.partSno);
    } else {
      tempInfo.push(item);
    }
    setFormValue(tempInfo);
  };

  console.log(formValue);

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "695px", height: "500px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Add Component</h3>
        </div>

        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px",
              }}
            >
              <span>Component Name : {row?.sparePartname}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px",
              }}
            >
              <span>Component SKU Code : {row?.skuCode}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px",
              }}
            >
              <span>Required Qty. : {row?.requiredQty}</span>
            </div>
          </section>

          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
            }}
          >
            <div style={{ padding: "0px", border: "1px solid #cdcdcd" }}>
              <span
                style={{
                  width: "20%",
                  padding: "10px",
                  borderRight: "1px solid #cdcdcd",
                }}
              >
                Select All
              </span>
              <span
                style={{
                  width: "80%",
                  padding: "10px",
                  borderRight: "1px solid #cdcdcd",
                }}
              >
                Component S.No.
              </span>
            </div>
            <section
              style={{ width: "100%", height: "180px", overflow: "scroll" }}
            >
              {addVehicleData?.data?.map((item) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      padding: "0px",
                      border: "1px solid #cdcdcd",
                      borderTop: "none",
                    }}
                  >
                    <span
                      style={{
                        width: "20%",
                        height: "50px",
                        padding: "10px",
                        borderRight: "1px solid #cdcdcd",
                      }}
                    >
                      <input
                        className="vehicleSelect"
                        type="checkbox"
                        name="partSno"
                        value={item.partSno}
                        onChange={() => {
                          onCheckboxChange(item);
                        }}
                      />
                    </span>
                    <span
                      style={{
                        width: "80%",
                        padding: "10px",
                        borderRight: "1px solid #cdcdcd",
                      }}
                    >
                      {item?.partSno}
                    </span>
                  </div>
                );
              })}
            </section>
          </section>
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px" }}
            onClick={submitHandler}
          >
            <CheckCircle />
            Submit
          </button>
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddComponents;
