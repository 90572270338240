import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import AddUser from "../user/add-user";
// import ManageUsers from "./manage-users";
// import RequestApproval from "./requestApproval";
// import UsersHistory from "./users-history";

const AddUser = React.lazy(()=>import("../user/add-user"))
const ManageUsers = React.lazy(()=>import("./manage-users"))
const RequestApproval = React.lazy(()=>import("./requestApproval"))
const UsersHistory = React.lazy(()=>import("./users-history"))


const tabs = [
  {
    id: "SAM2T1",
    label: TabLabels.ADD_USER,
    Component: AddUser
  },
  {
    id: "SAM2T2",
    label: TabLabels.MANAGE_USER,
    Component: ManageUsers
  },
  {
    id: "SAM2T3",
    label: TabLabels.USER_HISTORY,
    Component: UsersHistory
  },
  {
    id: "SAM2T4",
    label: TabLabels.REQUEST_APPROVAL,
    Component: RequestApproval
  }
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};

const UsersTabs = () => {
  let tabsToShow = getTabs(tabs);

  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};



export default UsersTabs;
