import { CancelOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import ViewSpecs from "../../../bom/bom-viewspecs";
import DealerSparePO from "../../../RequisitionOrders/DealerSparePO";
import DealerVehiclePO from "../../../RequisitionOrders/DealerVehiclePO";

const PoNoModal = ({ rowData, close }) => {
  console.log(rowData);
  const [openSpecs, setOpenSpecs] = useState();
  const [openPO, setPO] = useState(false);

  return (
    <div className="container1">
    {
      rowData?.vehicleData && openPO ? <DealerSparePO closeModal={setPO} rowData={rowData} type="vehicleData"/> :  
      rowData?.requisitionDetails && openPO ? <DealerVehiclePO closeModal={setPO} rowData={rowData} type="requisitionDetails"/> : null
    }
      <div
        className="modalContainer"
        style={{ width: "800px", height: "450px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "0px",
          }}
        >
          <h3>PO Details</h3>
        </div>

        <div
          className="body"
          style={{
            display: "flex",
            height: "300px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                <span>PO No.: </span>
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => setPO(true)}
                >
                  {rowData.poNo}
                </span>
              </span>
              <span>
                Buyer Name : {<span style={{ color: "#0A18A3" }}>{"-"}</span>}
              </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>PO Date : {rowData.PODate}</span>
              <span>Buyer Address : {"-"}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Order Qty. : {rowData.totalRequestedQuantity}</span>
            </div>
          </section>

          <section style={{ width: "100%" }}>
            <div id="POVendorHeading">
              <div style={{ width: "8%" }}>S.No.</div>
              <div style={{ width: "15%" }}>Component Name</div>
              <div style={{ width: "15%" }}>Sku Code</div>
              <div style={{ width: "20%" }}>Category</div>
              <div style={{ width: "15%" }}>Sub Category</div>
              <div style={{ width: "19%" }}>Specifications</div>
              <div style={{ width: "8%" }}>Qty</div>
            </div>
            <div>
              {rowData?.components?.map((item, index) => {
                return (
                  <div>
                    <div
                      id="POVendorContent"
                      style={{
                        borderBottom: "1px solid #999999",
                        boxShadow: "none",
                        padding: "0px",
                      }}
                      key={""}
                    >
                      <>
                        <div style={{ width: "8%" }}>{index + 1}</div>
                        <div style={{ width: "15%" }}>{item.componentName}</div>
                        <div style={{ width: "15%" }}>{item.skuCode}</div>
                        <div style={{ width: "20%" }}>{item.category}</div>
                        <div style={{ width: "15%" }}>{item.subCategory}</div>
                        <div style={{ width: "19%" }}>
                          <span
                            style={{
                              color: "#0a18a3",
                              fontWeight: "500",
                              cursor: "pointer",
                            }}
                            onClick={(e) => setOpenSpecs(index)}
                          >
                            view Specification
                          </span>
                        </div>
                        <div style={{ width: "8%" }}>
                          {item.requestedQuantity}
                        </div>
                      </>
                    </div>
                    {openSpecs === index && (
                      <ViewSpecs rowData={item} close={setOpenSpecs} />
                    )}
                  </div>
                );
              })}
            </div>
          </section>
        </div>

        <div
          className="footer"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <button
              style={{
                border: "1px solid #0A18A3",
                borderRadius: "3px",
                padding: "5px 15px",
                marginRight: "10px",
                color: "#0A18A3",
                width: "152px",
              }}
              onClick={() => close(false)}
            >
              <CancelOutlined style={{ color: "#0A18A3", fontSize: "28px" }} />
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoNoModal;
