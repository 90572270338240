import React from 'react';
import moment from "moment";
import { KeyboardBackspace } from "@material-ui/icons";


const ViewInvHistory = ({data ,closeModal}) => {

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "765px", heightz: "450px", padding: "15px" }}
      >
        <div className="header" style={{marginTop:"5px"}}>
          <h3>Inventory History</h3>
        </div>

        <br />
        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
            margin: "10px 0",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Vehicle Category : {data[0].vehicleCategory}</span>
              <span>Vehicle Model : {data[0].vehicleModel}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Vehicle Segment : {data[0].vehicleSegment}</span>
              <span>Vehicle Variant : {data[0].vehicleVariant}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Vehicle Type : {data[0].vehicleType}</span>
            </div>
          </div>
        </section>
        <br />
        <div style={{height:'400px'}}>
          <ul style={{width:"150%"}} className='OH_Ul'>
            {data.map((item,index)=>{
              return(
                <li className='grnReceived_list' style={{margin:"0px 0px 30px 5px", width:"729px"}}>
                <div style={{width:"100%"}} className='OH_list_div'>
                  <span style={{overflow:"scroll"}}>WO ID : {item.orderID}</span>
                  <span style={{color:"gray", overflow:"scroll"}}> | </span>
                  <span style={{overflow:"scroll"}}>Date: {moment(item.requestDate).format("DD-MMM-YYYY , HH:mm")}</span>
                  <span style={{color:"gray", overflow:"scroll"}}> | </span>
                  <span style={{overflow:"scroll"}}>Added By : {item.requestedBy}</span>
                  <span style={{color:"gray", overflow:"scroll"}}> | </span>
                  <span style={{overflow:"scroll"}}>Added Quantity : {parseInt(item.productionQuantity)}</span>
                </div>
              </li>
              )
            })}    
          
          </ul>
        </div>

        <div className="footer">
          <button onClick={() => closeModal(false)}><KeyboardBackspace/>&nbsp;Back</button>
        </div>
      </div>
    </div>
  )
}

export default ViewInvHistory