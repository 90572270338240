import React, { useState } from "react";
import "../../work-order/workorder.scss";

const ReceivedQuantity = ({ close, rowdata }) => {

  console.log(rowdata)
 
  return (
    <>
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "655px", height:"506px", padding: "15px" }}
      >
        <div className="header">
          <h3>Received Qty.</h3>
        </div>

    <div className="body" style={{height:"315px"}}> 

    <div
        style={{
            width:"100%",
          display: "flex",
          flexDirection: "column",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Component Name: {rowdata.componentName}</span>
          <span>Category: {rowdata.category}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Component SKU Code:{rowdata.SKUCode}</span>
          <span>Sub Category:{rowdata.subCategory}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Received Qty: {rowdata.recivedQty}.</span>
        </div>
      </div>

      
         <div style={{width:"100%",overflow:"auto",height:"265px"}}>     
          <table className="simple_tbl" width="100%"> 
          <thead>
            <tr>
                <th style={{width:"20%"}}>S.No.</th>
                <th>Part Serial No.</th>
            </tr>
            </thead>
      {rowdata.componentSno.map((item, index)=> {
         return(
            <tr>
                <td>{index+1}</td>
                <td>{item.partSno}</td>
            </tr>
         )})}
            </table>        
            </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => close(false)}>Back</button>
        </div>
      </div>
    </div>
    </>
  );
};

export default ReceivedQuantity;