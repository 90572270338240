import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";

const CaptchaCompnent = ({ captchaImg }) => {
    return (
        <div>
            {captchaImg?.captchaSvg ? (
                <img src={`data:image/svg+xml;base64,${btoa(captchaImg?.captchaSvg)}`} alt="Captcha" />
            ) : (
                // <FontAwesomeIcon icon={faRedoAlt} spin />
                ""
            )}
        </div>
    );
};
export default CaptchaCompnent;