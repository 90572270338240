import { KeyboardBackspace } from "@material-ui/icons";
import React from "react";
import moment from "moment";

const RequestedBatteryQty = ({ rowData, closeModal }) => {
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "725px", height: "310px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Requested Battery Qty.</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginBottom: "2%",
            }}
          >
            Total Requested Batteries : {rowData?.batteryQty}
          </section>
          <br />
          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "20%", padding: "10px" }}>Battery Make</div>
              <div style={{ width: "20%", padding: "10px" }}>Battery Model</div>
              <div style={{ width: "20%", padding: "10px" }}>Battery Type</div>
              <div style={{ width: "20%", padding: "10px" }}>
                Battery Capacity
              </div>
              <div style={{ width: "20%", padding: "10px" }}>
                Battery Volts
              </div>
              {/* <div style={{ width: "120px", padding: "10px"}}>
                Requested Qty.
              </div> */}
            </div>
            <section>
              {/* {row.availableQty.map((item, index) => ( */}
              <div
                style={{
                  padding: "0px",
                  border: "1px solid #cdcdcd",
                  fontSize: "13px",
                  display: "flex"
                }}
              >
                <div style={{ width: "20%", padding: "10px", overflowX: "scroll" }}>{rowData?.batteryDetails?.batteryMake}</div>
                <div style={{ width: "20%", padding: "10px", overflowX: "scroll" }}>{rowData?.batteryDetails?.batteryModel}</div>
                <div style={{ width: "20%", padding: "10px", overflowX: "scroll" }}>{rowData?.batteryDetails?.batteryType}</div>
                <div style={{ width: "20%", padding: "10px", overflowX: "scroll" }}>
                  {rowData?.batteryDetails?.batteryCapacity}
                </div>
                <div style={{ width: "20%", padding: "10px", overflowX: "scroll" }}>
                  {rowData?.batteryDetails?.batteryVolts}
                </div>
                {/* <div style={{ width: "120px", padding: "10px", overflowX:"scroll" }}>
              Requested Qty.
              </div> */}
              </div>
              {/* //   ))} */}
            </section>
          </section>
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestedBatteryQty;
