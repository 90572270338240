import React, { useState } from "react";
import EditBom from "./bom-edit";
import ShareBom from "./bom-share";
import DiscontinuedBom from "./bom-discontinued";
import DeleteBom from "./bom-delete";
import ReactivateBom from "./bom-reactivate";
import "../../../components/modal/modal.scss";
import {
  faEdit,
  faBan,
  faTrash,
  faDotCircle,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppConstants from "../../../utils/app-constants";

const ActionBom = ({
  updatedData,
  rowData,
  status,
  rowIndex,
  setActionShow,
}) => {
  console.log(rowData.status);
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      share: false,
      reactivate: false,
      discontinued: false,
      delete: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && (
        <EditBom
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
          setActionShow={setActionShow}
        />
      )}
      {activeModal.share && showModal && (
        <ShareBom
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
          setActionShow={setActionShow}
        />
      )}
      {activeModal.delete && showModal && (
        <DeleteBom
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
          setActionShow={setActionShow}
        />
      )}
      {activeModal.discontinued && showModal && (
        <DiscontinuedBom
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
          setActionShow={setActionShow}
        />
      )}
      {activeModal.reactivate && showModal && (
        <ReactivateBom
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
          setActionShow={setActionShow}
        />
      )}

      <div
        style={{  
          position: "relative",
          marginTop: rowIndex <= 4 ? "0px" : "-145px",
        }}
      >
        
        <div className="vehicleaction" style={{ width: "150px" }}>
        {
          rowData.status == "Pending At Procurement" || rowData.status == "Rejected By Management" ? 
          <div>
          <span id="edit" onClick={(e) => clickHandler(e)}>
            <FontAwesomeIcon icon={faEdit} /> Edit
          </span>
        </div>:""
        
        }
          
          {/* <div>
            <span id="share" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faShare} /> Share
            </span>
          </div> */}
          <div>
            <span id="delete" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faTrash} /> Delete
            </span>
          </div>

          {rowData.status == "Deactivated" && (
              <div>
                <span id="reactivate" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faBan} /> Reactivate
                </span>
              </div>
            )}

          {rowData.status != "Deactivated" && (
            <div>
              <span id="discontinued" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faBan} /> Deactivate
              </span>
            </div>
          )}

          <div></div>
        </div>
      </div>
    </div>
  );
};

export default ActionBom;
