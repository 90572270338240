import React, { useState } from "react";
import moment from "moment";
import {
  KeyboardBackspace,
} from "@material-ui/icons";
// import moment from "moment";

const ViewUpdates = ({ closeModal, rowData }) => {

  const [viewSpecs, setViewSpecs] = useState();

  function dateFormatter(cell) {
    let date = cell
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "600px", height: "550px", padding: '15px' }}
      >
        <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>View Details</h3>
        </div>
        <div style={{ fontSize: "14px", fontFamily: "sans-serif", fontWeight: "420", padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>RPI No: {rowData?.rpiNo}</span>
            <span>Return Qty. : {rowData?.returnedUnits}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>RPI Date : {moment(rowData?.rpiDate).format("DD-MMM-YYYY/HH:mm")}</span>
            <span>Return Value : {rowData?.returnValue?.toLocaleString("en-IN")}</span>
          </div>
        </div>


        <div style={{ height: '400px', overflow: 'scroll' }}>

          <ul className='OH_Ul'>

            <li className='OH_list poGenerated_list'>
              <div className='OH_list_div'>
                <span style={{ width: "50%" }} className='OH_list_btn poGenerated'>Replacement Shipped/CN Generated</span>
                <span>Date:  { }</span>
              </div>
            </li>

            <li className='OH_list poGenerated_list'>
              <div className='OH_list_div'>
                <span style={{ width: "35%" }} className='OH_list_btn poGenerated'>Approved by Finance</span>
                <span>Date:  { }</span>
              </div>
            </li>
            <li className='OH_list fullyClosure_list'>
              <div className='OH_list_div'>
                <span style={{ width: "35%" }} className='OH_list_btn fullyClosure'>Pending at Inventory</span>
                <span>Date: { } </span>
              </div>
            </li>

            <li className='OH_list partiallyClosure_list'>
              <div className='OH_list_div'>
                <span style={{ width: "50%" }} className='OH_list_btn partiallyClosure'>Replacement/CN Requested to Finance</span>
                <span>Date: { }</span>
              </div>
            </li>

            <li className='OH_list poGenerated_list'>
              <div className='OH_list_div'>
                <span style={{ width: "35%" }} className='OH_list_btn poGenerated'>Verification Completed</span>
                <span>Date: { } </span>
              </div>
            </li>

            <li className='OH_list grnReceived_list'>
              <div className='OH_list_div'>
                <span style={{ width: "40%" }} className='OH_list_btn grnReceived'>QC Verification</span>
                <span>Date:  { }</span>
              </div>
            </li>

            <li className='OH_list grnReceived_list'>
              <div className='OH_list_div'>
                <span style={{ width: "40%" }} className='OH_list_btn grnReceived'>Raised to Inventory</span>
                <span>Date:  { }</span>
              </div>
            </li>
            <li className='OH_list poGenerated_list'>
              <div className='OH_list_div'>
                <span style={{ width: "35%" }} className='OH_list_btn poGenerated'>Returns Placed</span>
                <span>Date: { } </span>
                {/* <span style={{ color: "blue" }}>View Details</span> */}
              </div>
            </li>



          </ul>
        </div>





        <div className="footer">
          <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewUpdates; 