import React, { useState } from "react";
import {
  faEdit,
  faBan,
  faTrash,
  faDotCircle,
  faRecycle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../components/modal/modal.scss";
import EditFeatures from "./EditFeatures";
import Deactivate from "./Deactivate";
import Delete from "./Delete";
import Reactivate from "./Reactivate";

const ManageFeaturesAction = ({
  updatedData,
  setIsOpen,
  rowData,
  rowIndex,
}) => {
  console.log("rowData-->", rowData);

  const [showModal, setShowModal] = useState(false);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      deactivate: false,
      reactivate: false,
      delete: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {rowData.status === "Active" || rowData.status === "Reactivated"
        ? activeModal.edit &&
          showModal && (
            <EditFeatures
              updatedData={updatedData}
              editData={rowData}
              closeModal={setIsOpen}
              setShowModal={setShowModal}
            />
          )
        : ""}


        {rowData.status === "Active" || rowData.status === "Reactivated" 
        ? activeModal.deactivate &&
          showModal && (
            <Deactivate
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setIsOpen}
              setShowModal={setShowModal}
            />
          )
        : ""}



        {rowData.status === "Deactivated" 
        ? activeModal.reactivate &&
          showModal && (
            <Reactivate
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setIsOpen}
              setShowModal={setShowModal}
            />
          )
        : ""}



        {rowData.status === "Active" || rowData.status === "Reactivated" || rowData.status === "Deactivated"
        ? activeModal.delete &&
          showModal && (
            <Delete
              updatedData={updatedData}
              rowData={rowData}
              closeModal={setIsOpen}
              setShowModal={setShowModal}
            />
          )
        : ""}



      <div
        className="action"
        style={{
          position: "relative",
          marginTop: rowIndex <= 4 ? "0px" : "-145px",
        }}
        onBlur={() => setIsOpen(false)}
      >
        <div
          className="action vendoraction"
          style={{ marginLeft: "65px", marginTop: "0px", width: "170px" }}
        >
          {rowData.status === "Active" || rowData.status === "Reactivated" ? (
            <div>
              {" "}
              <span id="edit" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status === "Active" || rowData.status === "Reactivated" ? (
            <div>
              {" "}
              <span id="deactivate" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faBan} /> Deactivate
              </span>
            </div>
          ) : (
            ""
          )}


          {rowData.status === "Deactivated" ? (
            <div>
              {" "}
              <span id="reactivate" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faRecycle} /> Reactivated
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status === "Active" || rowData.status === "Reactivated" || rowData.status === "Delete" ? (
            <div>
              {" "}
              <span id="delete" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faTrash} /> Delete
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageFeaturesAction;
