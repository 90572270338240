import React, { useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import GINno from "./GINno";
import CR_POno from "./CR_POno";
import CR_MR_ReturnQty from "./CR_MR_ReturnQty";
import moment from "moment";
import ViewSpecs from "../../inventory-inv/action/ViewSpecs";
import FullAssignment from "../../inventory-inv/action/FullAssignment";
import SpareReturnQty from "./spareReturnQty";
import RequestUpdate from "./requestUpdate";
import CompletedViewDetails from "./completedViewDetails";

const HistoryCompInProgress = ({ closeModal, rowData }) => {
  console.log(rowData);
  const [showReturnItem, setItem] = useState();
  const [showIndex, setIndex] = useState();
  const [openFA, setOpenFA] = useState(false);
  const [openReqUpdate, setOpenReqUpdate] = useState(false);
  const [openSpecs, setOpenSpecs] = useState();
  const [viewDetails, setViewDetails] = useState();
  const [view, setView] = useState(false);

  const returnQty = (componentSno) => {
    console.log(componentSno);
    let qty = componentSno.length;
    return qty;
  };

  const returnQuantity = (item, index) => {
    setViewQty(true);
    setItem(item);
  };
console.log(rowData?.components[0]?.componentSno)
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1150px", height: "425px", padding: "15px" }}
      >
        <div className="header">
          <h3>View Details</h3>
        </div>

        <div
          className="body"
          style={{
            width: "100%",
            display: "flex",
            fontSize: "14px",
            marginTop: "-36px",
          }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                GRN NO.: {rowData?.GRNID}
                <span style={{ color: "#0a18a3" }}></span>
              </span>
              <span>
                Requested By: {rowData?.requestedBy}
                <span style={{ color: "#0a18a3" }}></span>
              </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                PO No.: {rowData?.POID}
                <span style={{ color: "#0a18a3" }}></span>
              </span>
              <span>Request Date: {rowData?.requestedDate}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested Category: {rowData?.requestCategory}</span>
              <span>Received Components: {}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Request Type: {rowData?.requestType}</span>
              <span>Received Units :{rowData?.components?.length} </span>
            </div>
          </section>

          <section style={{ width: "100%" }}>
            <div id="POVendorHeading">
              <div style={{ width: "50px" }}>S.No.</div>
              <div style={{ width: "120px" }}>Component Name</div>
              <div style={{ width: "100px" }}>SKU Code</div>
              <div style={{ width: "100px" }}>Category</div>
              <div style={{ width: "100px" }}>Sub Category</div>
              <div style={{ width: "100px" }}>Specifications</div>
              <div style={{ width: "160px" }}>Vendor Part Name</div>
              <div style={{ width: "120px" }}>Vendor Part Code</div>
              <div style={{ width: "100px" }}>Received Qty.</div>
              <div style={{ width: "100px" }}>Total Qty.</div>
              <div style={{ width: "100px" }}>Remaining Qty.</div>
              <div style={{ width: "115px" }}>Action</div>
            </div>
            <div>
              {rowData?.components?.map((item, index) => (
                <>
                 <div
                  id="POVendorContent"
                  style={{
                    borderBottom: "1px solid #999999",
                    boxShadow: "none",
                    padding: "0px",
                  }}
                >
                  <div style={{ width: "50px" }}>{index + 1}</div>
                  <div style={{ width: "120px", overflow: "scroll" }}>
                    {item?.componentName}
                  </div>
                  <div style={{ width: "100px", overflow: "scroll" }}>
                    {item?.skuCode}
                  </div>
                  <div style={{ width: "100px", overflow: "scroll" }}>
                    {item?.category}
                  </div>
                  <div style={{ width: "100px", overflow: "scroll" }}>
                    {item?.subCategory}
                  </div>
                  <div style={{ width: "100px", overflow: "scroll" }}>
                    <span
                      onClick={() => handleOpenSpecs(index)}
                      style={{
                        color: "#0a18a3",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      View Specs
                    </span>

                    {openSpecs === index && view && (
                      <ViewSpecs
                        specs={item?.specifications}
                        closeModal={setView}
                      />
                    )}
                  </div>
                  <div style={{ width: "160px", overflow: "scroll" }}>
                    {item?.vendorPartName}
                  </div>
                  <div style={{ width: "120px", overflow: "scroll" }}>
                    {item?.vendorPartCode}
                  </div>
                  <div style={{ width: "100px", overflow: "scroll" }}>
                    {item?.receivedQuantity}
                  </div>
                  <div style={{ width: "100px", overflow: "scroll" }}>
                    {item?.requiredQuantity}
                  </div>
                  <div style={{ width: "100px", overflow: "scroll" }}>
                    {item?.requiredQuantity - item?.requiredQuantity}
                  </div>
                  <div style={{ width: "115px" }}>
                    <button
                      style={{
                        width: "80px",
                        fontSize: "12px",
                        color: "#0a18a3",
                        cursor: "pointer",
                        padding: "3px 9px",
                        border: "1px solid #0a18a3",
                        borderRadius: "4px",
                        background: "white",
                      }}
                      onClick={() => setViewDetails(true)}
                    >
                      View Details
                    </button>
                  </div>
                </div>
                 {viewDetails && (
                  <CompletedViewDetails
                    rowData={item?.componentSno}
                    closeModal={setViewDetails}
                    category={"PO Components"}
                  />
                )}
                </>
               
              ))}
             
            </div>
          </section>
        </div>

        <div className="footer">
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default HistoryCompInProgress;
