import React, { useState } from "react";
import moment from "moment";
import { KeyboardBackspace } from "@material-ui/icons";

const ViewDetails = ({ closeModal, rowData }) => {
    console.log(rowData.actionData)
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "550px", height: "450px", padding: "15px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>View Details</h3>
          <div>
            <button className="action-btn" style={{ color: "blue" }}>
              Export
            </button>
          </div>
        </div>
        <div style={{ height: "400px", overflow: "scroll" }}>
          <ul className="OH_Ul">
            {rowData.status == "Active" ? (
              <>
                {rowData?.actionData.map((item) => {
                  return (
                    <>
                      {item.status == "Onboard" ? (
                        <>
                          <li className="OH_list poGenerated_list">
                            <div className="OH_list_div">
                              <span
                                style={{ width: "35%" }}
                                className="OH_list_btn poGenerated"
                              >
                                Onboard
                              </span>
                              <span>
                                Date:{" "}
                                {moment(item.actionDate).format("DD-MM-YYYY")}
                              </span>
                              <span>Reason:{item.reason}</span>
                            </div>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </>
            ) : rowData.status == "Deactivated" ? (
              <>
                {rowData?.actionData.map((item) => {
                    return(
                        <>
                        {
                            item.status == "Deactivated" ? (
                                <>
                                  <li className="OH_list partiallyClosure_list">
                                    <div className="OH_list_div">
                                      <span
                                        style={{ width: "35%" }}
                                        className="OH_list_btn partiallyClosure"
                                      >
                                        Deactivated
                                      </span>
                                      <span>
                                        Date: {moment(item.actionDate).format("DD-MM-YYYY")}
                                      </span>
                                      <span>Reason: {item.reason}</span>
                                    </div>
                                  </li>
                                </>
                              ) : item.status == "Active" ? (
                                <>
                                  <li className="OH_list grnReceived_list">
                                    <div className="OH_list_div">
                                      <span
                                        style={{ width: "35%" }}
                                        className="OH_list_btn grnReceived"
                                      >
                                        Onboard
                                      </span>
                                      <span>
                                        Date: {moment(item.actionDate).format("DD-MM-YYYY")}
                                      </span>
                                      <span>Reason: {item.reason}</span>
                                    </div>
                                  </li>
                                </>
                              ) : (
                                ""
                              )  
                        }
                        </>
                    );
                
                })}
              </>
            ) : rowData.status == "Reactivated" ? (
              <>
                {rowData.actionData.map((item) => {
                    return(
                        <>
                        {
                             item.status == "Reactivated" ? (
                                <>
                                  <li className="OH_list grnReceived_list">
                                    <div className="OH_list_div">
                                      <span
                                        style={{ width: "35%" }}
                                        className="OH_list_btn grnReceived"
                                      >
                                        Reactivated
                                      </span>
                                      <span>
                                        Date: {moment(item.actionDate).format("DD-MM-YYYY")}
                                      </span>
                                      <span>Reason: {item.reason}</span>
                                    </div>
                                  </li>
                                </>
                              ) : item.status == "Deactivated" ? (
                                <>
                                  <li className="OH_list partiallyClosure_list">
                                    <div className="OH_list_div">
                                      <span
                                        style={{ width: "35%" }}
                                        className="OH_list_btn partiallyClosure"
                                      >
                                        Deactivated
                                      </span>
                                      <span>
                                        Date: {moment(item.actionDate).format("DD-MM-YYYY")}
                                      </span>
                                      <span>Reason: {item.reason}</span>
                                    </div>
                                  </li>
                                </>
                              ) : item.status == "Active" ? (
                                <>
                                  <li className="OH_list grnReceived_list">
                                    <div className="OH_list_div">
                                      <span
                                        style={{ width: "35%" }}
                                        className="OH_list_btn grnReceived"
                                      >
                                        Active
                                      </span>
                                      <span>
                                        Date: {moment(item.actionDate).format("DD-MM-YYYY")}
                                      </span>
                                      <span>Reason: {item.reason}</span>
                                    </div>
                                  </li>
                                </>
                              ) : (
                                ""
                              )
                        }
                        </>
                    )
                 
                })}
              </>
            ) : ""}
          </ul>
        </div>

        <div className="footer">
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
