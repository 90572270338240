import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { errorNotification, successNotification } from "../../utils/notifications";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import auth from "../../utils/auth";
import { exp_email } from "../../utils/validations";
import "./login.scss";
import images from "../../utils/get-images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faEnvelope, faArrowRight, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import CaptchaCompnent from "./CaptchaCompnent";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeslash = <FontAwesomeIcon icon={faEyeSlash} />;
const envelope = <FontAwesomeIcon icon={faEnvelope} />;
const arrow = <FontAwesomeIcon icon={faArrowRight} />;
const refresh = <FontAwesomeIcon icon={faRedoAlt} />;

const Login = () => {
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [passwordShown, setPasswordShown] = useState(true);
    const [captchaText, setCaptchaText] = useState('');
    const [captchaSvg, setCaptchaSvg] = useState({});
    const [token, setToken] = useState('');

    const { register, handleSubmit, errors } = useForm();
    const [loginForm, setLoginForm] = useState({ email: "", password: "" });
    const [resetEmail, setResetEmail] = useState('');
    let history = useHistory();


    const getCaptcha = async () => {
        const data = await trackPromise(API.post(`/admin-module/api/v1/login/captcha`));
        setCaptchaSvg(data);
    }
    useEffect(() => {
        getCaptcha()
    }, []);


    // useEffect(() => {
    //     const getCaptcha = async () => {
    //         const data = await trackPromise(API.post(`/admin-module/api/v1/login/captcha`));
    //         setCaptchaSvg(data);
    //     };

    //     getCaptcha();
    // }, []);

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const handleInputChange = (e) => {
        if (isResetPassword) {
            setResetEmail(e.target.value);
        } else {
            setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
        }
    };

    const handleCaptchaRefresh = async () => {
        const data = await trackPromise(API.post(`/admin-module/api/v1/login/captcha`));
        setCaptchaSvg(data);
    };

    const onLoginSubmit = async (loginDetails) => {
        const captchaBody = {
            captcha: captchaText,
            captchaId: captchaSvg.captchaId,
        };
        
        try {
            const captchaResp = await trackPromise(API.post(`/admin-module/api/v1/login/verifyCaptcha`, captchaBody));

            if (captchaResp.success) {
                const resp = await trackPromise(API.post(`/admin-module/api/v1/login`, loginDetails));
                const { success, accessToken, refreshToken, message } = resp;

                if (success) {
                    auth.setToken(accessToken);
                    auth.setRefreshToken(refreshToken);
                    const respWithDetails = await trackPromise(API.get(`/admin-module/api/v1/login/user`));
                    auth.setUserInfo(respWithDetails.data);
                    history.push(`/app/${respWithDetails.data.role}`);
                    successNotification(message, { autoClose: 1000 });

                    if (token) {
                        console.log('Send FCM token');
                    }
                } else {
                    errorNotification(message);
                }
            } else {
                errorNotification("Please enter the correct captcha");
                handleCaptchaRefresh();
            }
        } catch (error) {
            errorNotification("An error occurred during login.");
        }
    };

    const onResetSubmit = async () => {
        try {
            const resp = await trackPromise(API.post(`/admin-module/api/v1/login/sendForgetPasswordLink`, { email: resetEmail }));
            const { success, message } = resp;

            if (success) {
                successNotification(message, { autoClose: 2000 });
                setIsResetPassword(false);
            } else {
                errorNotification(message);
            }
        } catch (error) {
            errorNotification("An error occurred while sending the reset password link.");
        }
    };

    return (
        <>
            <img style={{ width: "100%", height: "100vh" }} src={images["bgNewEdited.png"]} alt="logo" />
            {!isResetPassword ? (
                <div style={{ display: "flex" }}>
                    <div className="hello">
                        <img src={images["ElektriseLogoNew.svg"]} alt="logo" style={{ width: "40%" }} />
                        <br />
                        <h2 style={{ fontSize: "16px", color: "black", marginTop: "9%" }}>
                            Complete <span>Data Management System</span> that's all you need
                        </h2>
                        <br />
                        <div style={{ borderLeft: "2px solid #D0021B", padding: "8px" }}>
                            <h2 style={{ fontSize: "16px", color: "black" }}>Your data is 100% safe with us</h2>
                            <span style={{ fontSize: "16px", color: "black" }}>Being a responsible company, our topmost priority is the security of your data</span>
                        </div>
                        <br />
                        <div style={{ borderLeft: "2px solid #D0021B", padding: "8px" }}>
                            <h2 style={{ fontSize: "16px", color: "black" }}>Complete solution for Pre/Post Production Data Management Needs</h2>
                            <span style={{ fontSize: "16px", color: "black" }}>ElektRise is a complete solution for the data management such as Production planning and execution</span>
                        </div>
                        <br />
                        <div style={{ borderLeft: "2px solid #D0021B", padding: "8px" }}>
                            <h2 style={{ fontSize: "16px", color: "black" }}>Automated Solution for Stock Inward and Outward</h2>
                            <span style={{ fontSize: "16px", color: "black" }}>ElektRise automates the hectic and tedious stock management tasks</span>
                        </div>
                    </div>
                    <div className="login-container" style={{ height: "80%" }}>
                        <span className="signin-heading">Hello User! Login to your Account</span>
                        <small className="signin-text">Please enter your registered email and password in order to login to your account.</small>
                        <form className="signin-form" onSubmit={handleSubmit(onLoginSubmit)}>
                            <div className="search-bar" id="searchBar" style={{ display: "flex", flexDirection: "column", width: "100%", height: "70px" }}>
                                <label style={{ fontSize: "13px", fontWeight: "500", color: "black" }}>
                                    Email Address <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type="email"
                                    autoComplete="new-password"
                                    placeholder="Email Address"
                                    id="searchBarInput"
                                    name="email"
                                    ref={register({
                                        required: "Email is required",
                                        pattern: {
                                            value: exp_email,
                                            message: "Invalid Email Address",
                                        },
                                    })}
                                    style={{ color: "black", marginTop: "-5px", filter: "none" }}
                                    onChange={handleInputChange}
                                />
                                <i className="position" style={{ color: "black" }}>{envelope}</i>
                                {errors.email && (
                                    <span className="d-block" style={{ marginTop: "-1.5rem", color: "red", fontSize: "13px", fontWeight: "500" }}>
                                        {errors.email.message}
                                    </span>
                                )}
                            </div>
                            <div className="search-bar" id="searchBar" style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <label style={{ fontSize: "13px", fontWeight: "500", color: "black" }}>
                                    Password<span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type={passwordShown ? "password" : "text"}
                                    autoComplete="new-password"
                                    id="searchBarInput"
                                    placeholder="Password"
                                    name="password"
                                    style={{ color: "black", marginTop: "-5px", filter: "none" }}
                                    ref={register({ required: "Password is required" })}
                                    onChange={handleInputChange}
                                />
                                <i onClick={togglePasswordVisibility} className="position" style={{ color: "black" }}>
                                    {passwordShown ? eyeslash : eye}
                                </i>
                                {errors.password && (
                                    <span className="d-block" style={{ marginTop: "-1.5rem", color: "red", fontSize: "13px", fontWeight: "500" }}>
                                        {errors.password.message}
                                    </span>
                                )}
                            </div>


                            <div className="captchaMain">
                                <h5 className="captchaHeading">Enter Captcha</h5>
                                <div style={{ display: "flex" }}>
                                    <CaptchaCompnent captchaImg={captchaSvg} />
                                    <FontAwesomeIcon icon={faRedoAlt} onClick={() => getCaptcha()} className="captchaRefresh" />
                                    <div>
                                        <input
                                            type="text"
                                            id="searchBarInput"
                                            placeholder="Enter Captcha"
                                            style={{ color: "black" }}
                                            value={captchaText}
                                            onChange={(e) => setCaptchaText(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <br />
                            <div className="search-bar signin-btn" id="searchBar" style={{ margin: "3% 0px 0px 4%", width: "92%" }}>
                                <button style={{ background: "#0A18A3", filter: "none" }} type="submit" id="searchBarInput">
                                    <div style={{ fontSize: "17px", color: "#fff", fontWeight: "500", textAlign: "left" }}>
                                        Login <i style={{ position: "relative", left: "20rem", color: "white", fontSize: "19px", top: "0rem" }}>{arrow}</i>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <div className="forgotText">
                            Forgot Password? <span onClick={() => setIsResetPassword(true)} style={{ textDecoration: "underline", cursor: "pointer" }}>Reset Now</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{ width: "100%", height: "100vh", backgroundColor: "#393a44", display: "flex" }}>
                    <div className="hello">
                        <img src={images["ElektriseLogoNew.svg"]} alt="logo" style={{ width: "40%" }} />
                        <br />
                        <h2 style={{ fontSize: "16px", color: "black", marginTop: "9%" }}>
                            Complete <span>Data Management System</span> that's all you need
                        </h2>
                        <br />
                        <div style={{ borderLeft: "2px solid #D0021B", padding: "8px" }}>
                            <h2 style={{ fontSize: "16px", color: "black" }}>Your data is 100% safe with us</h2>
                            <span style={{ fontSize: "16px", color: "black" }}>Being a responsible company, our topmost priority is the security of your data</span>
                        </div>
                        <br />
                        <div style={{ borderLeft: "2px solid #D0021B", padding: "8px" }}>
                            <h2 style={{ fontSize: "16px", color: "black" }}>Complete solution for Pre/Post Production Data Management Needs</h2>
                            <span style={{ fontSize: "16px", color: "black" }}>ElektRise is a complete solution for the data management such as Production planning and execution</span>
                        </div>
                        <br />
                        <div style={{ borderLeft: "2px solid #D0021B", padding: "8px" }}>
                            <h2 style={{ fontSize: "16px", color: "black" }}>Automated Solution for Stock Inward and Outward</h2>
                            <span style={{ fontSize: "16px", color: "black" }}>ElektRise automates the hectic and tedious stock management tasks</span>
                        </div>
                    </div>
                    <div className="login-container" style={{ height: "65%" }}>
                        <span className="signin-heading">Reset Your Password</span>
                        <small className="signin-text">Please enter your registered email and we will send you a link to reset your password.</small>
                        <form className="signin-form" onSubmit={handleSubmit(onResetSubmit)}>
                            <div className="search-bar" id="searchBar" style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <label style={{ fontSize: "13px", fontWeight: "500", color: "black" }}>
                                    Email Address <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type="email"
                                    autoComplete="new-password"
                                    placeholder="Email Address"
                                    id="searchBarInput"
                                    name="email"
                                    ref={register({
                                        required: "Email is required",
                                        pattern: {
                                            value: exp_email,
                                            message: "Invalid Email Address",
                                        },
                                    })}
                                    style={{ color: "black", marginTop: "-5px", filter: "none" }}
                                    onChange={(e) => setResetEmail(e.target.value)}
                                />
                                <i className="position" style={{ color: "black" }}>{envelope}</i>
                                {errors.email && (
                                    <span className="d-block" style={{ marginTop: "-1.5rem", color: "red", fontSize: "13px", fontWeight: "500" }}>
                                        {errors.email.message}
                                    </span>
                                )}
                            </div>
                            <br />
                            <div className="search-bar signin-btn" id="searchBar" style={{ margin: "3% 0px 0px 4%", width: "92%" }}>
                                <button style={{ background: "#0A18A3", filter: "none" }} type="submit" id="searchBarInput">
                                    <div style={{ fontSize: "17px", color: "#fff", fontWeight: "500", textAlign: "left" }}>
                                        Submit <i style={{ position: "relative", left: "20rem", color: "white", fontSize: "19px", top: "0rem" }}>{arrow}</i>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <div className="forgotText">
                            <span onClick={() => setIsResetPassword(false)} style={{ textDecoration: "underline", cursor: "pointer" }}>Back to Login</span>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Login;
