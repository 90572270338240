import React from "react";
import ServiceManual from "../../OperationsWarrantyServices/Component/serviceMaster/ServiceManual";

const Template = () => {
  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px", marginBottom:"10px" }}>
          <span>Add Templates</span>
        </div>
      </div>
          <ServiceManual/>
    </>
  );
};

export default Template;
