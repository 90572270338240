import moment from "moment";
import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../settings-tabs/homesettings.scss";
import "../../../pages/wo-shopfloor/shopfloor.scss";
import { KeyboardBackspace } from "@material-ui/icons";
import ViewDetailsComponents from "./viewDetailsComponents";
import ViewDetailsLabour from "./viewDetailsLabor";
import ViewDetailsAssembly from "./viewDetailsAssembly";
import auth from "../../../utils/auth";
import ViewEditBattery from "./ViewEditBattery";

const ViewDetailsBomCost = ({ rowData, close }) => {
    const [viewComp, setViewComp] = useState();
    const [viewLbr, setViewLbr] = useState();
    const [viewAssm, setViewAssm] = useState();
    const [viewBattery, setViewBattery] = useState();

    return (
        <>
            {viewComp && <ViewDetailsComponents rowdata={rowData} close={setViewComp} />}
            {viewLbr && <ViewDetailsLabour rowdata={rowData} close={setViewLbr} />}
            {viewAssm && <ViewDetailsAssembly rowdata={rowData} close={setViewAssm} />}
            {viewBattery && <ViewEditBattery rowdata={rowData} close={setViewBattery} />}


            <div className="bussiness">
                <h4 className="heading">View Details</h4>
            </div>

            {/* BOM Details */}
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "16px",
                    fontWeight: "500",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>BOM Details</span>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {/* <div
                            style={{
                                width: "auto",
                                height: "30px",
                                padding: "5px",
                                color: "green",
                                backgroundColor: "rgba(17, 120, 27, 0.15)",
                                borderRadius: "3px",
                                marginRight: "10px",
                            }}
                        >
                            Approved Date : {moment(rowData.approveDate).format("DD-MMM-YYYY/HH:mm")}
                        </div> */}
                        <div
                            style={{
                                width: "auto",
                                height: "30px",
                                padding: "5px",
                                color: "blue",
                                backgroundColor: "rgba(17, 120, 27, 0.15)",
                                borderRadius: "3px",
                                marginRight: "10px",
                            }}
                        >
                            {`Total BOM Cost (${auth.getUserInfo().currencySymbol})`} :{" "}
                            {rowData.totalBomCost}
                        </div>
                    </div>
                </div>
            </div>

            {/* BOM Info */}
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "16px",
                    fontWeight: "500",
                    border: "2px solid #EBEBEB",
                    borderRadius: "5px",
                    padding: "5px",
                    marginTop: "6px",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>BOM ID: {rowData.BOMID}</span>
                    <span>BOM Version: {rowData.bomVersion}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>BOM Name: {rowData.bomName}</span>
                    <span>BOM Level: {rowData.bomLevel}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>BOM Location: {rowData.bomLocation}</span>
                    <span>
                        Created By :{" "}
                        <button className="action-btn" style={{ color: "blue" }}>
                            <span style={{ color: "#0A18A3" }}>{rowData.createdBy}</span>
                        </button>
                    </span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>BOM Types: {rowData.bomTypes}</span>
                    <span>Created Date: {moment(rowData.createdDate).format("DD-MMM-YYYY/HH:mm")}</span>
                </div>
            </div>

            <br />

            {/* Vehicle Details */}
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "16px",
                    fontWeight: "500",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Vehicle Details</span>
                </div>
            </div>

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "16px",
                    fontWeight: "500",
                    border: "2px solid #EBEBEB",
                    borderRadius: "5px",
                    padding: "5px",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Vehicle Category: {rowData.vehicleCategory}</span>
                    <span>Vehicle Variant: {rowData.vehicleVariant}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Vehicle Segment: {rowData.vehicleSegment}</span>
                    <span>Vehicle Type: {rowData.vehicleType}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Vehicle Model: {rowData.vehicleModel}</span>
                </div>
            </div>

            <br />

            <h6>Edit BOM Details</h6>

            {/* Table with Scrollable Feature */}
            <div className="shopfloortbl" style={{ maxHeight: "200px", overflowY: "auto" }}>
                <table className="sfaction_tbl" style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th>BOM Components</th>
                            <th>BOM Labours</th>
                            <th>BOM Assembly</th>
                            <th>BOM Battery</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <button
                                    onClick={() => setViewComp(true)}
                                    className="action-btn"
                                    style={{ color: "blue" }}
                                >
                                    View Components
                                </button>
                            </td>
                            <td>
                                <button
                                    onClick={() => setViewLbr(true)}
                                    className="action-btn"
                                    style={{ color: "blue" }}
                                >
                                    View Labours
                                </button>
                            </td>
                            <td>
                                <button
                                    onClick={() => setViewAssm(true)}
                                    className="action-btn"
                                    style={{ color: "blue" }}
                                >
                                    View Assembly
                                </button>
                            </td>
                            <td>
                                <button
                                    onClick={() => setViewBattery(true)}
                                    className="action-btn"
                                    style={{ color: "blue" }}
                                >
                                    View Battery
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style={{ float: "right", marginTop: "10px", width: "auto" }}>
                <div className="cust-reset  cancel-button">
                    <button
                        type="button"
                        className="btn btn-icon-label"
                        onClick={() => close(false)}
                    >
                        <div style={{ fontSize: "14px", color: "#0A18A3", fontWeight: "500" }}>
                            <KeyboardBackspace />
                            Back
                        </div>
                    </button>
                </div>
            </div>
        </>
    );
};

export default ViewDetailsBomCost;
