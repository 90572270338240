import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import OnboardBusinessEntities from "./Components/OnboardBusinessEntities";
// import VerifyBusinessDetails from "./Components/VerifyBusinessDetails";
// import ManageBusinessEntities from "./Components/ManageBusinessEntities";

const OnboardBusinessEntities = React.lazy(()=>import("./Components/OnboardBusinessEntities"))
const VerifyBusinessDetails = React.lazy(()=>import("./Components/VerifyBusinessDetails"))
const ManageBusinessEntities = React.lazy(()=>import("./Components/ManageBusinessEntities"))


const tabs = [
  {
    id: "SYM2T1",
    label: TabLabels.ONBOARD_BUSINESS_ENTITIES,
    Component: OnboardBusinessEntities,
  },
  {
    id: "SYM2T2",
    label: TabLabels.VERIFY_BUSINESS_DETAILS,
    Component: VerifyBusinessDetails,
  },
  {
    id: "SYM2T3",
    label: TabLabels.MANAGE_BUSINESS_ENTITIES,
    Component: ManageBusinessEntities,
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

const SystemAdminBusiness = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default SystemAdminBusiness;
