import React from "react";
import {Tabs} from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from '../../utils/auth'
// import SparePartsSales from "./sparepartsSales";
// import VehicleSales from "./vehicleSales";

const SparePartsSales = React.lazy(()=>import("./sparepartsSales"))
const VehicleSales = React.lazy(()=>import("./vehicleSales"))


const Sales=()=>{
    const tabs = [
        {
          id:"MGM4T1",
          label: TabLabels.VEHICLE_SALES,
          Component: VehicleSales
        },
        {
          id:"MGM4T2",
          label: TabLabels.SPAREPARTS_SALES,
          Component: SparePartsSales
        },
    ];

    const getTabs = (tabs) => {
      let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
      return tabs.filter(tab => tabIds.includes(tab.id));
    };

    let tabsToShow = getTabs(tabs);
    return (
      <div className="app-layout__card">
        <Tabs tabs={tabsToShow} type="pills" />
      </div>
    );
}
export default Sales