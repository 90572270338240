import React, { useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import ShowMappedCities from "./ShowMappedCities";

const ViewMappedStates = ({ rowData, close }) => {
    console.log(rowData);

    const [showCities, setShowCities] = useState(false)
    const [citiesData, setCitiesData] = useState([])

    let showMappedCities = (item, index) => {
        setShowCities(true);
        setCitiesData(item)
    };

    return (
        <div className="container1">
            {showCities && <ShowMappedCities citiesData={citiesData} rowData={rowData} closeModal={setShowCities} />}
            <div className="modalContainer" style={{ width: "500px", height: "450px" }}>
                <div className="header">
                    <h3>Mapped Areas</h3>
                </div>
                <label style={{ textAlign: "left" }}>{`Area Name :`} <b style={{ color: "#0A18A3" }}>{rowData.area}</b></label>
                <div className="body">
                    <table style={{ width: "100%" }}>
                        <tr>
                            <th style={{ width: "16%" }}>S.No</th>
                            <th>States</th>
                            <th>Cities</th>
                        </tr>
                        {
                            rowData.states.map((item, index) => (
                                <tr>
                                    <td>{index + 1}.</td>
                                    <td>{item.stateName}</td>
                                    <td onClick={() => showMappedCities(item, index)} style={{ color: "#0A18A3", cursor: "pointer" }}>{rowData?.cities?.filter((item1) => {
                                        return item1.stateId == item.stateId
                                    }).length}</td>
                                </tr>
                            ))
                        }
                    </table>
                </div>
                <div className="footer">
                    <button onClick={() => close(false)}><KeyboardBackspace /> Back</button>
                </div>
            </div>
        </div>
    );
};

export default ViewMappedStates;
