import React from 'react';
import { Doughnut } from 'react-chartjs-2';

function StoreSpareRequestsPieChart({ spareReqsData }) {
    return (
        <div style={{ width: "50%", marginLeft: "10px" }}>
            <Doughnut
                data={{
                    datasets: [{
                        data: [spareReqsData.partialReqsCount, spareReqsData.fullReqsCount],
                        backgroundColor: [
                            '#105cad',
                            'rgba(33, 168, 46, 1)',
                        ],
                        options: {
                            radius: "10%"
                        },
                        hoverOffset: 4,
                    }
                    ],
                    labels: ["Request Partially Processed", "Request Fully Processed"]
                }} />
            <div style={{ margin: "-70% 0 0 100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430", width: "184px" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "#105cad", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "114px", fontSize: "13px", fontWeight: "500", marginTop: "3px" }}>Request Partially Processed</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "13px", fontWeight: "700", marginTop: "3px" }}>
                        {spareReqsData.partialReqsCount}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430", width: "184px" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(33, 168, 46, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "114px", fontSize: "13px", fontWeight: "500", marginTop: "3px" }}>Request Fully Processed</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "13px", fontWeight: "700", marginTop: "3px" }}>
                        {spareReqsData.fullReqsCount}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StoreSpareRequestsPieChart