import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { errorNotification } from "../../../utils/notifications";

const EditDeliveryDetails = ({ deliveryDetailsData, index, deliveryHandler }) => {
  const [delivery, setDelivery] = useState(deliveryDetailsData);
  const [vendorData, setVendorData] = useState([]);
  
  const [businessUserInfo, setBusinessUserInfo] = useState([]);

  const deliveryData = deliveryDetailsData === undefined ? [] : deliveryDetailsData;

  const [formValue, setFormValue] = useState(deliveryData);

  var data = {};
  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    var testDelivery = [...formValue];
    testDelivery.push(data);
    setDelivery(testDelivery);
    setFormValue(testDelivery);
    deliveryHandler(testDelivery);
  };
  
  const removeFields = (index) => {
    const field = [...formValue];
    field.splice(index, 1);
    setFormValue(field);
    deliveryHandler(field);
  };

  const handleFormValueInput = (e) => {
    data[e.target.name] = e.target.value;
  };


  const fetchReport = async () => {
    const response = await trackPromise(
      API.get(`procurement-module/api/v1/mappings/getStorageLocations`)
    );
    setBusinessUserInfo(response);
  };

  const getVendor = async () => {
    const resp = await trackPromise(
      API.get(`procurement-module/api/v1/mappings/getAllVendors`)
    );
    console.log(resp);
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setVendorData(resp);
  };
  useEffect(() => {
    getVendor();
    fetchReport()
  }, []);

  
  let uniquedeliveryLocation = [...new Set(vendorData.map((item) => item.city))];



  return (
    <form>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "24%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              width: "auto",
              marginLeft: "-102px",
            }}
          >
            Delivery Location<span style={{ color: "red" }}>*</span>
          </label>
          <select
            style={{ marginTop: '-5px', width: 'auto' }}
            name="deliveryLocation"
            defaultValue={formValue.deliveryLocation}
            onChange={handleFormValueInput}
          >
            <option value="" disabled selected>
              Delivery Location
            </option>
            {businessUserInfo.map((item) => (
              <option>{item.city}</option>
            ))}
          </select>
        </div>
        <div
          style={{
            width: "22%",
            display: "flex",
            flexDirection: "column",
            marginLeft: '9px'
          }}
        >
          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              width: "auto",
              marginLeft: "-128px",
            }}
          >
            Unit Price<span style={{ color: "red" }}>*</span>
          </label>

          <input
            type="text"
            style={{ marginTop: '-5px', width: 'auto' }}
            name="unitPrice"
            defaultValue={formValue.unitPrice}
            placeholder="Unit Price"
            onChange={handleFormValueInput}
          />
        </div>
        <div
          style={{
            width: "22%",
            display: "flex",
            flexDirection: "column",
            marginLeft: '9px'
          }}
        >
          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              width: "auto",
              marginLeft: "-128px",
            }}
          >
            Estd Time<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            style={{ marginTop: '-5px', width: 'auto' }}
            name="estdTime"
            defaultValue={formValue.estdTime}
            placeholder="Estd. Time"
            onChange={handleFormValueInput}
          />
        </div>
        <div
          style={{
            width: "22%",
            display: "flex",
            flexDirection: "column",
            marginLeft: '9px'
          }}
        >
          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              width: "auto",
              marginLeft: "-135px",
            }}
          >
            Duration<span style={{ color: "red" }}>*</span>
          </label>
          <select
            style={{ marginTop: '-5px', width: 'auto' }}
            name="duration"
            defaultValue={formValue.duration}
            onChange={handleChange}
          >
            <option>Duration</option>
            <option value="Day">Day</option>
            <option value="Week">Week</option>
            <option value="Month">Month</option>
          </select>
        </div>




      </div>

      <div
        style={{
          width: "96.5%",
          borderRadius: "5px",
          backgroundColor: "#ebebeb",
        }}
      >
        {formValue.map((item, index) => (
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "93%",
                margin: "5px",
                padding: "5px",
                borderRadius: "5px",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "56%",
                  textAlign: "left",
                }}
              >
                {item.deliveryLocation}
              </span>
              <span
                style={{
                  display: "inline-block",
                  width: "25%",
                  textAlign: "left",
                }}
              >
                {item.unitPrice}
              </span>
              <span>{`${item.estdTime} ${item.duration}`}</span>
            </div>
            <div>
              <button
                type="button"
                style={{
                  borderRadius: "100%",
                  border: "1px solid red",
                  marginTop: "10px",
                }}
                onClick={() => removeFields(index)}
              >
                <div style={{ color: "red" }}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
              </button>
            </div>
          </div>
        ))}
      </div>
    </form>
  );
};

export default EditDeliveryDetails;
