import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  faFilter,
  faRedoAlt,
  faTimesCircle,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import SearchBox from "../../../components/search/SearchBox";
import DealerShowroomAvailableQty from "../../DealerShowroom/DealerInventory/DealerShowroomAvailableQty";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import auth from "../../../utils/auth";
import handleExport from "../../../utils/exportExcel"

function ServiceCenterWise({ inDashboard }) {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [openQty, setOpenQty] = useState();
  const [tableData, setTableData] = useState([]);
  const [serviceCenterdataWithID, setServiceCenterData] = useState([]);
  const [selectedShowroomValue, setSelectedShowroomValue] = useState("All");
  const [dropdownData, setDropdownData] = useState([]);
  const [filtersDropdown, setFiltersDropdown] = useState([]);
  const [formValue, setFormValue] = useState({
    sparePartname: "",
    skuCode: "",
    category: "",
    subCategory: "",
    serviceCenterUserID: "",
  });

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `dealer-module/api/v1/serviceCenters/inventory/serviceCenterWise?sparePartname=${
          formValue.sparePartname
        }&&skuCode=${formValue.skuCode}&&category=${
          formValue.category
        }&&subCategory=${formValue.subCategory}&&serviceCenterUserID=${
          formValue.serviceCenterUserID == "All"
            ? ""
            : formValue.serviceCenterUserID
        }`
      )
    );
    console.log(resp);
    setTableData(resp.data);
    // setUsersData(resp);
    setServiceCenterData(resp.data);
    const respo = await trackPromise(
      API.get(`dealer-module/api/v1/serviceCenters/inventory/serviceCenterWise`)
    );
    setDropdownData(respo.data);
    setFiltersDropdown(respo.data);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const uniqueserviceCenterId = [
    ...new Set(dropdownData?.map((item) => item.serviceCenterUserID)),
  ];

  const uniquesparePartname = [
    ...new Set(filtersDropdown?.map((item) => item.sparePartname)),
  ];

  const uniqueSKUCode = [...new Set(filtersDropdown?.map((item) => item.skuCode))];

  const uniqueCategory = [...new Set(filtersDropdown?.map((item) => item.category))];
  const uniqueSubCategory = [
    ...new Set(filtersDropdown?.map((item) => item.subCategory)),
  ];

  // const handleInputChange = (event) => {
  //   let filteredData = tableData?.filter(
  //     (item) => item?.serviceCenterUserID == event.target.value
  //   );
  //   setServiceCenterData(filteredData);
  //   formValue.serviceCenterUserID=event.target.value
  // };

  const handleInputChange = (event) => {
    formValue.serviceCenterUserID = event.target.value;
    setSelectedShowroomValue(event.target.value);
    let filteredData = tableData.filter(
      (item) => item.serviceCenterUserID == event.target.value
    );
    if (event.target.value == "All") {
      fetchReport();
      setFiltersDropdown(dropdownData)
    } else {
      setServiceCenterData(filteredData);
      setFiltersDropdown(filteredData);
      fetchReport();
    }
  };

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const { totalInventory, totalInventoryPrice } = tableData.reduce(
    (acc, curr) => {
      const count = parseInt(curr.count);
      const price = parseInt(curr.priceToserviceCenter);
      acc.totalInventory += count;
      acc.totalInventoryPrice += count * price;
      return acc;
    },
    { totalInventory: 0, totalInventoryPrice: 0 }
  );

  const totalFormatter = (cell, row, rowIndex, formatExtraData) => {
    const totalAmount = row.count * parseInt(row.priceToserviceCenter);
    const formattedAmount = totalAmount.toLocaleString("en-IN", {
      maximumFractionDigits: 0,
      style: "decimal",
    });
    return formattedAmount;
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const qtyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "14px",
            // color: "blue",
            // cursor: "pointer",
          }}
          // onClick={() => setOpenQty(rowIndex)}
        >
          {cell}
        </p>
        {openQty === rowIndex && (
          <DealerShowroomAvailableQty row={row} closeModal={setOpenQty} />
        )}
      </div>
    );
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return tableData?.findIndex(function (value, idx, arr) {
          return value == row;
        }) + 1
      },
      sort: true,
    },
    {
      text: "Spare Part Name",
      classes: "cellsOverflow",
      dataField: "sparePartname",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Category",
      classes: "cellsOverflow",
      dataField: "category",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Sub Category",
      classes: "cellsOverflow",
      dataField: "subCategory",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Available Qty.",
      dataField: "count",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { openQty },
      // formatter: qtyFormatter,
    },
    {
      text: `Unit Price (${auth.getUserInfo().currencySymbol})`,
      dataField: "priceToserviceCenter",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ fontSize: "14px" }}>
            {parseInt(row?.priceToserviceCenter).toLocaleString("en-IN", {
              maximumFractionDigits: 0,
              style: "decimal",
            })}
          </p>
        );
      },
    },
    {
      text: `Inventory Value (${auth.getUserInfo().currencySymbol})`,
      dataField: "subTotal",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: totalFormatter,
    },
  ];

  const clearFilter = async (e) => {
    setFormValue({
      sparePartname: "",
      skuCode: "",
      category: "",
      subCategory: "",
      serviceCenterUserID: "",
    });
    setSelectedShowroomValue("All");
    setFiltersDropdown(dropdownData);
    const resp = await trackPromise(
      API.get(`dealer-module/api/v1/serviceCenters/inventory/serviceCenterWise`)
    );
    console.log(resp);
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setTableData(resp.data);
  };

  // const refresh = async (e) => {
  //   setFormValue({
  //     sparePartname: "",
  //     skuCode: "",
  //     category: "",
  //     subCategory: "",
  //     serviceCenterUserID: "",
  //   });
  //   const resp = await trackPromise(
  //     API.get(
  //       `dealer-module/api/v1/serviceCenters/inventory/serviceCenterWise?sparePartname=${""}&&skuCode=${""}&&category=${""}&&subCategory=${""}`
  //     )
  //   );
  //   console.log(resp);
  //   resp.success == false
  //     ? errorNotification(resp.message, { autoClose: 3000 })
  //     : setTableData(resp.data);
  // };

  let dataToExport = tableData.map((item) => {
    return {
        "Spare Part Name": item.sparePartname,
        "Category": item.category,
        "Sub Category": item.subCategory,
        "Available Qty.": item.count || "-",
        "Unit Price (₹)": item.priceToserviceCenter||"-",
        "Inventory Value (₹)": parseInt(item.count)*parseInt(item.priceToserviceCenter)||"-",
    }
})

  return (
    <>
      <ToolkitProvider keyField="id" data={tableData} columns={columns} search>
        {(props) => (
          <>
            <div className="bussiness">
              <div className="heading">
                <span>Service Center Wise</span>
              </div>
              <div
                type="button"
                className="btn btn-md"
                style={{ float: "right", marginTop: "-42px" }}
              >
                <div
                  style={{
                    display: "flex",
                    marginRight: "-18px",
                    height: "26px",
                  }}
                >
                  <div style={{ width: "199px" }}>
                    <SearchBar
                      {...props.searchProps}
                      placeholder="Search"
                      style={{
                        height: "25px",
                        // border: "1px solid #C6C6C6",
                        border: "none",
                        background: "white",
                        borderRadius: "3px",
                        marginRight: "-50px",
                      }}
                    />
                  </div>
                  <div onClick={clearFilter} className="filter-refresh">
                    <FontAwesomeIcon icon={faRedoAlt} />
                    &nbsp; Refresh
                  </div>
                  <div className="filter-refresh" onClick={handleClick}>
                    {" "}
                    <FontAwesomeIcon icon={faFilter} />
                    &nbsp; Filters
                  </div>
                  <div
                      className="filter-refresh"
                      onClick={() => handleExport(dataToExport, "Service Center Wise")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                  {inDashboard ? (
                    <div
                      className="filter-refresh"
                      onClick={() => inDashboard(false)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="mainShowroomInvDiv">
              {/* RIGHT SIDE BOX */}

              <div
                className="rightDetailsBox"
                style={{ width: "100%", marginLeft: "0px" }}
              >
                {show && (
                  <div
                    className="RA_block"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      height: "125px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                      marginBottom: "1%",
                    }}
                  >
                    <div
                      className="bomfilter_fields"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Spare Part Name
                        </label>
                        <select
                          name="sparePartname"
                          value={formValue.sparePartname}
                          onChange={(e) => handleChange(e)}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Spare Part Name
                          </option>
                          {uniquesparePartname?.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          SKU Code
                        </label>
                        <select
                          name="skuCode"
                          value={formValue.skuCode}
                          onChange={(e) => handleChange(e)}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            SKU Code
                          </option>
                          {uniqueSKUCode?.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Category
                        </label>
                        <select
                          name="category"
                          value={formValue.category}
                          onChange={(e) => handleChange(e)}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Category
                          </option>
                          {uniqueCategory.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Sub Category
                        </label>
                        <select
                          name="subCategory"
                          value={formValue.subCategory}
                          onChange={(e) => handleChange(e)}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Sub Category
                          </option>
                          {uniqueSubCategory.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "end",
                          marginTop: "15px",
                        }}
                      >
                        <button
                          onClick={fetchReport}
                          id="apply"
                          style={{ width: "96px", height: "35px" }}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ width: "96px", height: "35px" }}
                        >
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#F3F3F3",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "0",
                      padding: "0",
                      width: "23%",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        marginLeft: "2.5%",
                      }}
                      htmlFor="serviceCenterUserID"
                    >
                      Select Service Center ID
                    </label>
                    <select
                      name="serviceCenterUserID"
                      id="serviceCenterUserID"
                      className="input-config"
                      value={formValue.serviceCenterUserID}
                      style={{
                        width: "100%",
                        height: "25px",
                        background: "#FFFFFF",
                        border: "1px solid #CDCDCD",
                        marginTop: "-2%",
                      }}
                      onChange={(event) => handleInputChange(event)}
                    >
                      <option value="All" selected>
                        All
                      </option>
                      {uniqueserviceCenterId?.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                  <span
                    style={{
                      marginTop: "2%",
                      color: "rgb(54, 54, 54)",
                      fontWeight: "500",
                      marginLeft: "-200px",
                    }}
                  >
                    Service Center Name:{" "}
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      {selectedShowroomValue == "All"
                        ? "All"
                        : serviceCenterdataWithID[0]?.serviceCenterName}
                    </span>
                  </span>

                  <h6
                    style={{
                      margin: "5px",
                      color: "rgb(54, 54, 54)",
                      marginTop: "2%",
                    }}
                  >
                     {` Total Inventory value (${auth.getUserInfo().currencySymbol})`} :{" "}
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      {totalInventoryPrice.toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                        style: "decimal",
                      })}
                    </span>
                  </h6>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <BootstrapTable
                    wrapperClasses="table-responsive mis-table"
                    rowClasses="text-nowrap"
                    keyField="id"
                    data={tableData}
                    noDataIndication="No Data Found"
                    rowStyle={{ border: "1px solid #CDCDCD" }}
                    columns={columns}
                    pagination={paginationFactory({
                      hidePageListOnlyOnePage: true,
                      showTotal: true,
                      hideSizePerPage: true,
                      paginationSize: 2,
                      sizePerPage: "10",
                    })}
                    {...props.baseProps}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </>
  );
}

export default ServiceCenterWise;
