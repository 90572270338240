import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import AddComponents from "./add-components";
// import ManageComponents from "./manage-components";

// const AddBattery = React.lazy(()=>import("./add-battery"))
// const ManageBattery = React.lazy(()=>import("./manage-battery"))

const AddHsnSac = React.lazy(()=>import("./add-hsn-sac.jsx"))
const PlaceOrders = React.lazy(()=>import("./place-orders.jsx"))
const ManageOrders = React.lazy(()=>import("./manage-orders.jsx"))
const GoodReceivedNote = React.lazy(()=>import("./good-received-note.jsx"))
const OrderHistory = React.lazy(()=>import("./order-history.jsx"))
const GoodReturnManagement = React.lazy(()=>import("./good-return-management.jsx"))


const tabs = [
  {
    id: "PRM6T1",
    label: TabLabels.ADD_HSN_SAC,
    Component: AddHsnSac
  },
  {
    id: "PRM6T2",
    label: TabLabels.PLACE_ORDERS,
    Component: PlaceOrders
  },
  {
    id: "PRM6T3",
    label: TabLabels.MANAGE_ORDERS,
    Component: ManageOrders
  },
  {
    id: "PRM6T4",
    label: TabLabels.GOOD_RECEIVED_NOTE,
    Component: GoodReceivedNote
  },
  {
    id: "PRM6T5",
    label: TabLabels.ORDER_HISTORY,
    Component: OrderHistory
  },
  {
    id: "PRM6T6",
    label: TabLabels.Good_RETURN_MANAGEMENT,
    Component: GoodReturnManagement
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};

const BatteryOrders = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default BatteryOrders;
