import React, { useState } from "react";
import EditAssm from "./assembly-edit";
import DiscontinuedAssm from "./assembly-discontinued";
import DeleteAssm from "./assembly-delete";
import ReactivateAssm from "./assembly-reactivate";
 import "../../../components/modal/modal.scss";
import { faEdit,faBan,faTrash,faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppConstants from "../../../utils/app-constants";


const ActionAssembly = ({updatedData,rowData,status, closeModal, rowIndex}) => {


  const [showModal, setShowModal] = useState();
  const [activeModal, setActiveModal] = useState({});


  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      reactivate: false,
      discontinued:false,
      delete: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && 
        <EditAssm updatedData={updatedData} editData={rowData} closeModal={setShowModal} showModal={closeModal}/>
      }
       {
       rowData.status === AppConstants.ACTIVE || rowData.status === AppConstants.REACTIVATED ?
          activeModal.discontinued && showModal && (
          <DiscontinuedAssm updatedData={updatedData} editData={rowData} closeModal={setShowModal} showModal={closeModal} type={"assembly"} />
          ) 

         :  activeModal.reactivate && showModal && (
            <ReactivateAssm updatedData={updatedData} editData={rowData} closeModal={setShowModal} showModal={closeModal} />
          )
          
      }
       
      {activeModal.delete && showModal && 
      <DeleteAssm updatedData={updatedData} editData={rowData} closeModal={setShowModal} showModal={closeModal}/>
      }
       <div style={{ position: "relative", marginTop: rowIndex <= 4 ? "0px" : "-145px"}}>
      <div className="vehicleaction">
        <div>
          <span id="edit" onClick={(e) => clickHandler(e)}>
          <FontAwesomeIcon icon={faEdit}/>  Edit
          </span>
        </div>
        <div>
        {
           rowData.status === AppConstants.ACTIVE || rowData.status === AppConstants.REACTIVATED ?
             <span id="discontinued" onClick={(e) => clickHandler(e)}>
             <FontAwesomeIcon icon={faBan}/>  Deactivate
              </span>
             : <span id="reactivate" onClick={(e) => clickHandler(e)}>
               <FontAwesomeIcon icon={faDotCircle}/>  Reactivate
              </span>
                
          }
      
        </div>

        <div>
          <span id="delete" onClick={(e) => clickHandler(e)}>
          <FontAwesomeIcon icon={faTrash}/>  Delete
          </span>
        </div>
      </div>
      </div>
    </div>
  );
};

export default ActionAssembly;
