import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import moment from "moment";
import { errorNotification, successNotification } from "../../../../../utils/notifications";
import SubmitPop from "./SubmitPop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import auth from "../../../../../utils/auth";

const RequestCompletedByInventory = ({ rowData, closeModal }) => {

  const [openSpare, setOpenSpare] = useState();
  const [spareData, setSpareData] = useState();

  const submitHandler = (rowData) => {
    setOpenSpare(true);
    setSpareData(rowData);
  };

  console.log(rowData)



  return (
    <div className="container1">
      {openSpare ? (
        <SubmitPop rowData={spareData} closeModal={setOpenSpare} />
      ) : (
        ""
      )}
      <div
        className="modalContainer"
        style={{ width: "1280px", height: "500px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Request Completed</h3>
        </div>
        <br />
        <di
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div>Request ID: {rowData?.requestID}</div>
              <div>Request Date: {moment(rowData?.requestDate).format("DD-MM-YYYY")}</div>
              <div>Request By.: {rowData?.requestedBy}</div>
              <div>GIN No: {rowData?.ginNo}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div>RPI No: {rowData?.rpiNo}</div>
              <div>RPI Date: {moment(rowData?.rpiDate).format("DD-MM-YYYY")}</div>
              <div>RPI Raised By: {rowData?.showroomEmail}</div>

            </section>
          </section>
          <br />
          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "fit-content",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "50px", padding: "10px" }}>S.No</div>
              <div style={{ width: "200px", padding: "10px" }}>Segment</div>
              <div style={{ width: "200px", padding: "10px" }}>Category</div>
              <div style={{ width: "200px", padding: "10px" }}>Vehicle SKU</div>
              <div style={{ width: "200px", padding: "10px" }}>
                Vehicle S.No
              </div>
              {/* <div style={{ width: "200px", padding: "10px"}}>
                Vehicle Make
              </div> */}
              <div style={{ width: "200px", padding: "10px" }}>
                Vehicle Model
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Vehicle Variant
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Vehicle Type
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Vehicle Color
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                VIN
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                BIN
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Status
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Failed Reason
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Status
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Unit Value {`(${auth.getUserInfo().currencySymbol})`}
              </div>
            </div>
            <section
              style={{ width: "fit-content", height: "auto" }}
            >
              {rowData.returnedData.map((item, index) => (
                <div
                  style={{
                    padding: "0px",
                    border: "1px solid #cdcdcd",
                    width: "",
                    fontSize: "13px",
                    display: "flex"
                  }}
                >
                  <div style={{ width: "50px", padding: "10px" }}>{index + 1}</div>
                  <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>{item?.vehicleSegment}</div>
                  <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>{item?.vehicleCategory}</div>
                  <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>{item?.vehicleSkuCode}</div>
                  <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>
                    {item?.SNO}
                  </div>
                  <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>
                    {item?.vehicleModel}
                  </div>
                  <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>
                    {item?.vehicleVariant}
                  </div>
                  <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>
                    {item?.vehicleType}
                  </div>
                  <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>
                    {item?.vehicleColor}
                  </div>
                  <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>
                    {item?.VIN}
                  </div>
                  <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>
                    {item?.BIN}
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    {
                      item?.verificationStatus == "Verification Passed" ? <button className="active-btn" style={{ color: "green", width: "150px" }}>Verification Passed<span className="active-btn-font"></span></button> : <button className="deactive-btn" style={{ color: "red", width: "150px" }}>Verification Failed<span className="deactive-btn-font"></span></button>
                    }

                  </div>
                  <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>
                    Failed reason
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>
                    {item.status == "Send to Production" ? <button className="active-btn" style={{ color: "green", width: "150px" }}><span className="active-btn-font">Send to Production</span></button> : <button className="active-btn" style={{ color: "green", width: "150px" }}><span className="active-btn-font">Moves to finish Goods</span></button>}

                  </div>
                  <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>
                    {item?.unitPrice}
                  </div>
                </div>
              ))}
            </section>
          </section>
        </di>

        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Back
          </button>
          {/* <button
            id="submit-button"
            onClick={submitHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default RequestCompletedByInventory;
