import React, { useEffect, useState } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import ServiceManualEdit from "./ServiceManualEdit";
import ViewTerms from "./ViewTerms";

const ManageServiceManuals = ({ tableData, fetchData }) => {
  console.log("tableData-->", tableData);

  const [showData, setShowData] = useState(tableData);
  const [actionShow, setActionShow] = useState();
  const [open, setOpen] = useState(false);
  const [actionDisplay, setActionDisplay] = useState();
  const [openTerms, setOpenTerms] = useState(false);

  const actionHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setOpen(true);
  };

  const actionHandle = (row, rowIndex) => {
    setActionDisplay(rowIndex);
    setOpenTerms(true);
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          onClick={() => actionHandler(row, rowIndex)}
          style={{
            color: "#0A18A3",
            backgroundColor: "white",
            border: "1px solid #0A18A3",
            borderRadius: "3px",
          }}
        >
          Edit
        </button>
        {actionShow === rowIndex && open ? (
          <ServiceManualEdit rowdata={row} closeModal={setOpen} fetchData={fetchData} />
        ) : null}
      </div>
    );
  };

  const actionFormatterTerms = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          onClick={() => actionHandle(row, rowIndex)}
          style={{
            color: "#0A18A3",
            backgroundColor: "white",
            border: "1px solid #0A18A3",
            borderRadius: "3px",
          }}
        >
          View Text
        </button>
        {actionDisplay === rowIndex && openTerms ? (
          <ViewTerms rowdata={row} closeModal={setOpenTerms} />
        ) : null}
      </div>
    );
  };

//   console.log("Data-->", showData);
//   console.log(
//     "Data-->",
//     showData?.map((item) => item?.serviceManualType)
//   );

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          tableData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      }
    },
    {
      text: "Types",
      dataField: "serviceManualType",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Service Terms",
      dataField: "serviceManualTerms",
      headerAttrs: { width: "100px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionDisplay, openTerms },
      formatter: actionFormatterTerms,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "100px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  return (
    <div>
      <BootstrapTable
        wrapperClasses="table-responsive"
        // rowClasses="text-nowrap"
        keyField="id"
        data={tableData}
        columns={columns}
        rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
        pagination={paginationFactory({
          hidePageListOnlyOnePage: true,
          showTotal: true,
          hideSizePerPage: true,
          paginationSize: 2,
          sizePerPage: "10",
        })}
        noDataIndication="No Data Found"
      />
    </div>
  );
};

export default ManageServiceManuals;
