import React from "react";
import { Tabs } from "react-simple-tabs-component";
import "./tabs.scss";
import BussinessProfile from "./bussinessProfile";
import ChangePassword from "./changePassword";
import Notifications from "./notifications";
import auth from "../../utils/auth";

const superAdminTabs = [
  {
    label: "Bussiness Profile",
    Component: BussinessProfile
  },
  {
    label: "Change Password",
    Component: ChangePassword
  },
  {
    label: "Notification Prefrence",
    Component: Notifications
  }
];

const subAdminTabs = [
  {
    label: "Change Password",
    Component: ChangePassword
  },
  {
    label: "Notification Prefrence",
    Component: Notifications
  }
];

const SettingsTabs = () => {
  return (
    <div className="app-layout__card">
      {auth.isSubAdmin() ? <Tabs tabs={subAdminTabs} type="pills" className="tabs-line"/> : <Tabs tabs={superAdminTabs} type="pills" className="tabs-line"/>}
    </div>
  );
};

export default SettingsTabs;
