import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import { AppRoutes } from "./components/routes";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <Router>
      <ToastContainer theme="colored" />
      <AppRoutes />
    </Router>
  );
};

export default App;
