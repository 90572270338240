import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import AddAssembly from "./add-assembly";
// import ConfigureAssembly from "./configure-assembly";
// import ManageAssembly from "./manage-assembly";
// import ProdManageAssembly from "./ProdManageAssembly";

const AddAssembly = React.lazy(()=>import("./add-assembly"))
const ConfigureAssembly = React.lazy(()=>import("./configure-assembly"))
const ManageAssembly = React.lazy(()=>import("./manage-assembly"))
const ProdManageAssembly = React.lazy(()=>import("./ProdManageAssembly"))



const Assembly = () => {

  const tab1 = [
    {
      label: TabLabels.ASSEMBLY,
      Component: ProdManageAssembly
    },
  ];

  const tab2 = [
    {
      label: TabLabels.CONFIGURE_ASSEMBLY,
      Component: ConfigureAssembly
    },
    {
      label: TabLabels.ADD_ASSEMBLY,
      Component: AddAssembly
    },
    {
      label: TabLabels.MANAGE_ASSEMBLY,
      Component: ManageAssembly
    },
  ];

  return (
    <div className="app-layout__card">
      {auth.isProduction() ? <Tabs tabs={tab1} type="pills" /> : <Tabs tabs={tab2} type="pills" />}
    </div>

  )
}
export default Assembly