import React, { useState } from "react";
import Edit from "./Edit";
import History from "./History"
import Delete from "./Delete"
import "../../../components/modal/modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faHistory, faTrash } from "@fortawesome/free-solid-svg-icons";

const Action = ({ updatedData, rowData , setIsOpen, rowIndex}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      history: false,
      delete: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && <Edit updatedData={updatedData} editData={rowData} closeModal={setShowModal} setIsOpen={setIsOpen}/>}
      {activeModal.history && showModal && <History editData={rowData} closeModal={setShowModal} setIsOpen={setIsOpen}/>}
      {activeModal.delete && showModal && <Delete updatedData={updatedData} editData={rowData} closeModal={setShowModal} />}

      <div style={{ position: "relative", marginTop: rowIndex <= 4 ? "0px" : "-145px" }}>
        <div style={{ marginLeft: "-130px" }} className="action">
          <div>
            <span id="edit" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </span>
          </div>
          <div>
            <span id="history" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faHistory} /> History
            </span>
          </div>
          <div>
            <span id="delete" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faTrash} />  Delete
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Action;
