import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../../utils/api";
import { errorNotification, successNotification } from "../../../../../utils/notifications";
import moment from "moment";
import {
  CheckCircle
} from "@material-ui/icons";
import DealerVehiclePO from "../../../../RequisitionOrders/DealerVehiclePO";

const ViewDetails = ({ rowData, closeModal }) => {

  const [openPO,setPO] = useState(false);

  return (
    <div className="container1">
    {
      openPO ? <DealerVehiclePO rowData={rowData}  closeModal={setPO} type="vehicleData"/>:""
    }
      <div
        className="modalContainer"
        style={{ width: "500px", height: "355px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Held by Finance</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "13px" }}
        >
          <section style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "2%" }}>
            <span>Request ID: {rowData.requisitionID}</span>
            <div style={{ width: "130px" }}><button className="delete-btn" style={{ color: "red" }}>Pending Payment<span className="delete-btn-font"></span></button></div>
          </section>
          <br />
          <section style={{ width: "99%", border: "1px solid #D8D8D8", borderRadius: "5px", display: "flex", flexDirection: "column", padding: "2%", textAlign: "start", justifyContent: "start" }}>
            <div>Hold Date: {moment(rowData.onHoldDate).format("DD-MMM-YYYY/HH:mm")}</div>
            <span>
              <span>PO No.: </span>
              <span style={{color:"blue",cursor:"pointer"}} onClick={()=>setPO(true)}>{rowData.poNo}</span>
            </span>
            <span>PO Date: {moment(rowData.poDate).format("DD-MM-YYYY")}</span>
            <div>PO By: {rowData.poBy}</div>
            <div>PO Value: {rowData.poValue}</div>
          </section>
        </div>
        <br />

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>

      </div>
    </div>
  );
};

export default ViewDetails;
