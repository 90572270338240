import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { KeyboardBackspace } from "@material-ui/icons";
import moment from "moment";
import AppConstants from "../../../utils/app-constants";
import "../../settings-tabs/homesettings.scss";
import "../../wo-shopfloor/shopfloor.scss";
import WoInProgressRejectedComp from "./wo-inprogress-rejectedcomp";
import WoInProgressViewAssembly from "./wo-inprogress-viewassembly";
import WoInProgressViewComp from "./wo-inprogress-viewcomp";
import WoInProgressViewLabours from "./wo-inprogress-viewlabours";
import WoProductionQty from "./wo-productionqty";
import WoViewAssembly from "./wo-viewassembly";
import WoViewLabours from "./wo-viewlabours";

const WoInProgressViewDetails = ({ closeModal, rowdata }) => {
  console.log(rowdata);
  const [viewComp, setViewComp] = useState();
  const [viewLbr, setViewLbr] = useState();
  const [viewAssm, setViewAssm] = useState();
  const [viewProdQty, setViewProdQty] = useState(false);
  const [viewRejected, setViewRejected] = useState();
  const [UsersData, setUsersData] = useState({});
  const [todayProd, setTodayProd] = useState(0);
  const [proData, setProData] = useState("");

  const getData = async () => {
    const getBody = {
      orderID: rowdata?.orderID,
    };
    const proData = await trackPromise(
      API.post(`shop-floor-module/api/v1/shopFloor/productionQty`, {
        orderID: rowdata?.orderID,
      })
    );

    setProData(proData);
  };

  const fetchReport = async () => {
    let body = {
      orderID: rowdata?.orderID,
      status: rowdata?.status,
    };
    const resp = await trackPromise(
      API.post(
        `shop-floor-module/api/v1/shopFloor/getAvgRequirementsPerDay`,
        body
      )
    );
    setUsersData(resp);
    const production = await trackPromise(
      API.get(
        `shop-floor-module/api/v1/shopFloor/getFinishedVehicleByAdded?orderID=${
          rowdata?.orderID
        }&type=${"addedToday"}`
      )
    );
    console.log(production);
    if (production.status) {
      setTodayProd(production);
    } else {
      setTodayProd(0);
    }
  };
  useEffect(() => {
    fetchReport();
  }, []);

  console.log(UsersData);
  console.log(todayProd);

  // set up
  let start = moment(rowdata?.haltedOn); 
  let end = moment(rowdata?.resumedOn); 
  let diff = end.diff(start);

  // execution
  let duration = moment(diff).format("HH:mm:ss.SSS");

  console.log(duration)

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "933px", height: "545px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>View Details</h3>
          {/* <div>
            <button className="action-btn" style={{ color: "blue" }}>
              Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              Export
            </button>
          </div> */}
        </div>

        <div className="" style={{ height: "402px", overflow: "auto" }}>
          {viewComp && (
            <WoInProgressViewComp compdata={rowdata} close={setViewComp} />
          )}
          {viewLbr && (
            <WoInProgressViewLabours labourdata={rowdata} close={setViewLbr} />
          )}
          {viewAssm && (
            <WoInProgressViewAssembly
              assemblydata={rowdata}
              close={setViewAssm}
            />
          )}
          {viewProdQty && (
            <WoProductionQty rowdata={rowdata} closeModal={setViewProdQty} />
          )}
          {viewRejected && (
            <WoInProgressRejectedComp
              compdata={rowdata}
              close={setViewRejected}
            />
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "100",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>WO ID : {rowdata?.orderID}</span>
              <span>BOM ID : {rowdata?.BOMID}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>WO Type : {rowdata?.workType}</span>
              <span>BOM Name : {rowdata?.bomName}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                WO Timeline :{" "}
                {moment(rowdata?.startDate).format("DD-MMM-YYYY/HH:mm")} to{" "}
                {moment(rowdata?.endDate).format("DD-MMM-YYYY/HH:mm")}
              </span>
              <span>BOM Location : {rowdata?.bomLocation}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                Production Qty :{" "}
                <span
                  // style={{ color: "blue", cursor: "pointer" }}
                  // onClick={() => setViewProdQty(true)}
                >
                  {rowdata?.productionQuantity}
                </span>
              </span>
              <span>BOM Created By : {rowdata?.createdBy}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span></span>
              <span>
                BOM Creation Date :{" "}
                {moment(rowdata?.createdDate).format("DD-MMM-YYYY/HH:mm")}
              </span>
            </div>
          </div>

          <br />
          <div>
            {rowdata?.status != "Accepted_WO in Progress" ? (
              <div>
                <h6 style={{ float: "left" }}>WO Progress Details</h6>
                <div className="shopfloortbl" style={{ border: "none" }}>
                  <table className="sfaction_tbl" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{fontSize:"small", fontWeight:"bold"}}>WO Start Date</th>
                        <th style={{fontSize:"small", fontWeight:"bold"}}>WO Halted Date</th>
                        <th style={{fontSize:"small", fontWeight:"bold"}}>WO Halt Reason</th>
                        {rowdata?.status == "Accepted_Work Resumed" ||
                        rowdata?.status == "Accepted_WO Completed" ? (
                          <>
                            <th style={{fontSize:"small", fontWeight:"bold"}}>WO Resumed On</th>
                            <th style={{fontSize:"small", fontWeight:"bold"}}>Halt Duration</th>
                          </>
                        ) : null}
                        {rowdata?.status == "Accepted_WO Completed" ? (
                          <th style={{fontSize:"small", fontWeight:"bold"}}>WO Completion Date</th>
                        ) : null}
                      </tr>
                    </thead>
                    {/* <hr className='line'/> */}
                    <tbody>
                      <tr>
                        <td style={{fontSize:"small"}}>
                          {rowdata?.startDate ? moment(rowdata?.startDate).format(
                            "DD-MMM-YYYY/HH:mm"
                          ):"N/A"}
                        </td>
                        <td style={{fontSize:"small"}}>
                          {rowdata?.haltedOn ? moment(rowdata?.haltedOn).format(
                            "DD-MMM-YYYY/HH:mm"
                          ):"N/A"}
                        </td>
                        <td style={{fontSize:"small"}}>{rowdata?.reason}</td>
                        {rowdata?.status == "Accepted_Work Resumed" ||
                        rowdata?.status == "Accepted_WO Completed" ? (
                          <>
                            <td style={{fontSize:"small"}}>
                              {rowdata?.resumedOn ? moment(rowdata?.resumedOn).format(
                                "DD-MMM-YYYY/HH:mm"
                              ):"N/A"}
                            </td>
                            <td style={{fontSize:"small"}}>{rowdata?.duration ? moment(rowdata?.duration).format("DD-MM-YYYY/HH:mm"):"N/A"}</td>
                          </>
                        ) : null}
                        {rowdata?.status == "Accepted_WO Completed" ? (
                          <td style={{fontSize:"small"}}>{rowdata?.completedDate ? moment(rowdata?.completedDate).format(
                            "DD-MMM-YYYY/HH:mm"
                          ):"N/A"}</td>
                        ) : null}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
              </div>
            ) : null}

            {rowdata?.status === "Accepted_Work Halted" ||
            rowdata?.status === "Accepted_WO in Progress" ||
            rowdata?.status === "Accepted_Work Resumed" ||
            rowdata?.status === "Accepted_Accepted" ? (
              <>
                <h6 style={{ float: "left" }}>Production Details</h6>
                <div className="shopfloortbl" style={{ border: "none" }}>
                  <table className="sfaction_tbl" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{fontSize:"small", fontWeight:"bold"}}>WO Start Date</th>
                        <th style={{fontSize:"small", fontWeight:"bold"}}>Avg Production/day</th>
                        <th style={{fontSize:"small", fontWeight:"bold"}}>Today's Production</th>
                        <th style={{fontSize:"small", fontWeight:"bold"}}>Total Production</th>
                        <th style={{fontSize:"small", fontWeight:"bold"}}>Remaining Production</th>
                      </tr>
                    </thead>
                    {/* <hr className='line'/> */}
                    <tbody>
                      <tr>
                        <td style={{fontSize:"small"}}>
                          {moment(rowdata?.createdDate).format(
                            "DD-MMM-YYYY/HH:mm"
                          )}
                        </td>
                        <td style={{fontSize:"small", fontWeight:"bold"}}>{UsersData?.avg_production_per_day}</td>
                        <td style={{fontSize:"small", fontWeight:"bold"}}>{todayProd}</td>
                        <td style={{fontSize:"small", fontWeight:"bold"}}>{proData}</td>
                        <td style={{fontSize:"small", fontWeight:"bold"}}>{UsersData?.remaining_production}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            ) : null}
            <br />

            <h6 style={{ float: "left" }}>Production Requirement</h6>
            <div className="shopfloortbl" style={{ border: "none" }}>
              <table className="sfaction_tbl" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{fontSize:"small", fontWeight:"bold"}}>Required Components</th>
                    <th style={{fontSize:"small", fontWeight:"bold"}}>Required Labours</th>
                    <th style={{fontSize:"small", fontWeight:"bold"}}>Required Assembly</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{fontSize:"small"}}>
                      <button
                        onClick={() => setViewComp(true)}
                        className="action-btn"
                        style={{ color: "blue" }}
                      >
                        View Components
                      </button>
                    </td>
                    <td style={{fontSize:"small"}}>
                      <button
                        onClick={() => setViewLbr(true)}
                        className="action-btn"
                        style={{ color: "blue" }}
                      >
                        View Labours
                      </button>
                    </td>
                    <td style={{fontSize:"small"}}>
                      <button
                        onClick={() => setViewAssm(true)}
                        className="action-btn"
                        style={{ color: "blue" }}
                      >
                        View Assembly
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h6 style={{ float: "left", marginTop: "15px" }}>
              Production Rejected Components
            </h6>
            <div className="shopfloortbl" style={{ border: "none" }}>
              <table className="sfaction_tbl" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{fontSize:"small", fontWeight:"bold"}}>Rejected Components</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{fontSize:"small"}}>
                      <button
                        onClick={() => setViewRejected(true)}
                        className="action-btn"
                        style={{ color: "blue" }}
                      >
                        View Rejected Components
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <KeyboardBackspace /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default WoInProgressViewDetails;
