import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import auth from "../../utils/auth";
import { errorNotification, successNotification } from "../../utils/notifications";
import './modal.scss';
import { useHistory, useLocation } from "react-router-dom";
 
const ResetPassword = ({ closeModal }) => {
    const [formValue, setFormValue] = useState({
        newPassword: "",
        confirmPassword: ""
    });
 
    const location = useLocation();
    const history = useHistory();
 
    // Extract token and id from the URL query parameters
    const query = new URLSearchParams(location.search);
    // const token = query.get("token");
    // const userID = query.get("id");
    const token = localStorage?.getItem('AUTH_TOKEN') || query?.get("token");
    const userID = JSON?.parse(localStorage.getItem('USER_INFO'))?.userID ||  query?.get("id");
 
    const logout = () => {
      localStorage.removeItem('AUTH_TOKEN');
      localStorage.removeItem('USER_INFO');
      localStorage.removeItem('REFRESH_TOKEN');
      history.push('/');
    };
 
    const validatePassword = () => {
      // Password regex pattern: at least 8 characters, at least one uppercase letter, one lowercase letter, and one digit
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!passwordRegex.test(formValue.newPassword)) {
        errorNotification("Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, and one digit.");
        return false;
      }
      if (formValue.newPassword !== formValue.confirmPassword) {
        errorNotification("Passwords do not match.");
        return false;
      }
      return true;
    };
 
    const submitHandler = async () => {
      if (!validatePassword()) {
        return;
      }
 
      let users = {
        newPassword: formValue.newPassword,
        token, // Add the token to the request payload
        userID // Add userID to the request payload
      };
 
      try {
        const resp = await trackPromise(API.post(`admin-module/api/v1/login/resetPassword`, users));
        console.log("resp-->",resp)
        if (resp.success) {
          successNotification(resp.message, { autoclose: 3000 });
          // closeModal(false);
          logout();
        } else {
          errorNotification(resp.message, { autoclose: 3000 });
        }
      } catch (error) {
        console.log("error",error)
        errorNotification("An error occurred while resetting the password.", { autoclose: 3000 });
      }
    };
 
    return (
        <div className="container1">
            <div className='modalContainer'>
                <div className="header">
                    <h3>Change Password</h3>
                </div>
 
                <div className="body">
                    <label className="lgInput">
                        New Password
                        <input
                            type="password"
                            style={{ width: "508px" }}
                            name="newPassword"
                            value={formValue.newPassword}
                            onChange={(e) => setFormValue({ ...formValue, [e.target.name]: e.target.value })}
                            placeholder="Password"
                            autoComplete="off"
                        />
                    </label>
 
                    <label className="lgInput">
                        Confirm Password
                        <input
                            type="password"
                            style={{ width: "508px" }}
                            name="confirmPassword"
                            value={formValue.confirmPassword}
                            onChange={(e) => setFormValue({ ...formValue, [e.target.name]: e.target.value })}
                            placeholder="Confirm Password"
                            autoComplete="off"
                        />
                    </label>
                </div>
 
                <div className="footer">
                    <button onClick={() => closeModal(false)}>Cancel</button>
                    <button id='submit-button' onClick={submitHandler}>Submit</button>
                </div>
            </div>
        </div>
    );
};
 
export default ResetPassword;