import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AppConstants from "../../../utils/app-constants";
import NewRequestCompAction from "./actions/NewRequestCompAction";
import PoNoModal from "./actions/poNoModal";
import ReleaseCompInventory from "./actions/releaseCompInventory";
import DealerSparePO from "../../RequisitionOrders/DealerSparePO";

const CompNewRequest = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [open, setOpen] = useState();
  const [requestsData, setRequestData] = useState([]);
  const [viewQty, setViewQty] = useState(false);
  const [componentShow, setComponentShow] = useState();
  const [display, setDisplay] = useState(true);
  const [openSpecs, setOpenSpecs] = useState();
  const [row, setRow] = useState();

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`inventory-module/api/v1/inventoryOutward/component/newRequest`)
    );
    let filteredData = resp?.filter((item) => {
      return (
        item.status == AppConstants.ONHOLD ||
        item.status == AppConstants.PARTIALASSIGNMENT ||
        item.status == "Pending At Inventory" ||
        item.status == AppConstants.PARTIALRELEASE ||
        item.status == "Partial In Progress" ||
        item.status == "Partial Order Completed" ||
        item.status == "Partial Invoice Generated" ||
        item.status == "Ready To Ship"
      );
    });
    console.log(filteredData);
    setRequestData(filteredData);
  };
  useEffect(() => {
    fetchReport();
  }, []);


  const uniqueOrderID = [...new Set(requestsData?.map((item) => item.orderID))];
  const uniqueWorkType = [...new Set(requestsData?.map((item) => item.workType))];
  const uniqueBOMID = [...new Set(requestsData?.map((item) => item.BOMID))];
  const uniqueBomLocation = [...new Set(requestsData?.map((item) => item.bomLocation))];




  const statusFormatter = (cell) => {
    return cell == AppConstants.ONHOLD ? (
      <button className="pendingByProc-btn" style={{ width: "100%", fontSize: "15px" }}>
        <span className="pendingByProc-btn-font">On Hold</span>
      </button>
    ) : cell == AppConstants.PARTIALASSIGNMENT || cell == "Partial Release" || cell == "Partial In Progress" ||
      cell == "Partial Order Completed" || cell == "Partial Invoice Generated" || cell == "Ready To Ship" ? (
      <button className="deactive-btn" style={{ width: "100%", fontSize: "15px" }}>
        <span className="deactive-btn-font">Partial Assignment</span>
      </button>
    )
      : cell == AppConstants.PENDING_AT_INVENTORY || cell == "Pending At Inventory" ? (
        <button className="delete-btn" style={{ width: "100%", fontSize: "15px" }}>
          <span className="delete-btn-font">Pending</span>
        </button>
      ) : (
        cell
      );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const handleClick = (e) => {
    setShow(!show);
  };

  const actionHandler = (row, rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    setRow(row);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => actionHandler(row, rowIndex)}
          style={{marginBottom:"0px"}}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && open ? (
          <NewRequestCompAction
            rowData={row}
            status={true}
            display={setDisplay}
            updatedData={fetchReport}
            rowIndex={rowIndex}
          />
        ) : null}
      </div>
    );
  };

  const poNoFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "14px",
            color: "blue",
            cursor: "pointer",
            marginBottom:"0px"
          }}
          onClick={() => setOpenSpecs(rowIndex)}
        >
          {cell}
        </p>
        {/* {openSpecs === rowIndex && (
          <PoNoModal rowData={row} close={setOpenSpecs} />
        )} */}
        {openSpecs === rowIndex && (
          <DealerSparePO closeModal={setOpenSpecs} rowData={row} type="requisitionDetails" />
        )}
      </div>
    );
  };

  const QuantityHandler = (rowIndex) => {
    console.log("hii");
    setComponentShow(rowIndex);
    setViewQty(true);
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      classes: "cellsOverflow",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requisitionID",
      classes: "cellsOverflow",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "PO No.",
      dataField: "poNo",
      formatter: poNoFormatter,
      classes: "cellsOverflow",
      formatExtraData: { openSpecs },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "170px" },
    },
    {
      text: "Requested By",
      dataField: "requestBy",
      classes: "cellsOverflow",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "230px" },
    },
    {
      text: "Requested Date",
      dataField: "requestDate",
      classes: "cellsOverflow",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "160px" },
      formatExtraData: { viewQty, componentShow },
      formatter: dateFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      classes: "cellsOverflow",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "160px" },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      classes: "cellsOverflow",
      headerAttrs: { width: "60px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  const [formValue, setFormValue] = useState({
    orderID: "",
    workType: "",
    BOMID: "",
    bomLocation: "",
    productionQuantity: "",
    creationDate: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = (e) => {
    setFormValue({
      orderID: "",
      workType: "",
      BOMID: "",
      bomLocation: "",
      productionQuantity: "",
      creationDate: "",
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  if (display == true) {
    return (
      <>
        <div>
          <div id="overlay" onClick={(e) => off(e)}></div>
          <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
            <span>New Requests</span>
          </div>

          <ToolkitProvider
            keyField="id"
            data={requestsData}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div>
                  <div style={{ float: "right", marginTop: "-39px" }}>
                    <div
                      style={{
                        display: "flex",
                        marginRight: "-15px",
                        height: "26px",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "220px" }}>
                        <SearchBar
                          {...props.searchProps}
                          placeholder="Search"
                          style={{
                            height: "25px",
                            // border: "1px solid #C6C6C6",
                            border: "none",
                            background: "white",
                            borderRadius: "3px",
                            marginRight: "-50px",
                          }}
                        />
                      </div>
                      <div onClick={clearFilter} className="filter-refresh">
                        <FontAwesomeIcon icon={faRedoAlt} />
                        &nbsp; Refresh
                      </div>
                      <div className="filter-refresh" onClick={handleClick}>
                        {" "}
                        <FontAwesomeIcon icon={faFilter} />
                        &nbsp; Filters
                      </div>
                      <div
                        className="filter-refresh"
                        onClick={() =>
                          handleExport(requestsData, "New Requests")
                        }
                      >
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp; Export
                      </div>
                      {inDashboard ? (
                        <div
                          className="filter-refresh"
                          onClick={() => inDashboard(false)}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  {show && (
                    <div
                      id="abc"
                      className="RA_block"
                      style={{ height: "120px", justifyContent: "flex-start" }}
                    >
                      <div className="bomfilter_fields">
                        <select
                          // required={true}
                          name="orderID"
                          value={formValue.orderID}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            WO ID*
                          </option>
                          {
                            uniqueOrderID.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                        <select
                          // required={true}
                          name="workType"
                          value={formValue.workType}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            WO Type*
                          </option>
                          {
                            uniqueWorkType.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>

                        <select
                          name="BOMID"
                          value={formValue.BOMID}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            BOM ID*
                          </option>
                          {
                            uniqueBOMID.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                        <select
                          name="bomLocation"
                          value={formValue.bomLocation}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            BOM Location*
                          </option>
                          {
                            uniqueBomLocation.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>

                        <input
                          type="text"
                          name="productionQuantity"
                          value={formValue.productionQuantity}
                          onChange={handleChange}
                          placeholder="Production Quantity"
                          autoComplete="off"
                        />

                        <input
                          type="text"
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "text")}
                          name="creationDate"
                          value={formValue.creationDate}
                          onChange={handleChange}
                          placeholder="Creation Date"
                          autoComplete="off"
                        />

                        <div style={{ margin: "10px 0 0 570px" }}>
                          <button
                            type="submit"
                            id="apply"
                            style={{ width: "96px", height: "35px" }}
                          >
                            Apply
                          </button>
                          <button
                            onClick={clearFilter}
                            id="clear"
                            style={{ width: "96px", height: "35px" }}
                          >
                            Clear Filters
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <br></br>
                </div>

                <BootstrapTable
                  wrapperClasses="table-responsive"
                  // rowClasses="text-nowrap"
                  rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                  pagination={paginationFactory({
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    hideSizePerPage: true,
                    paginationSize: 2,
                    sizePerPage: "10",
                  })}
                  noDataIndication="No Data Found"
                  {...props.baseProps}
                />
              </>
            )}
          </ToolkitProvider>
        </div>
      </>
    );
  } else if (
    (display == false && row.status == AppConstants.ONHOLD) ||
    row.status == AppConstants.PENDING_AT_INVENTORY ||
    row.status == AppConstants.PARTIALASSIGNMENT || row.status == "Partial Release" ||
    row.status == "Pending At Inventory" ||
    row.status == "Partial In Progress" ||
    row.status == "Partial Order Completed" ||
    row.status == "Partial Invoice Generated" ||
    row.status == "Ready To Ship"
  ) {
    return (
      <ReleaseCompInventory
        rowData={row}
        fetchReport={fetchReport}
        display={setDisplay}
        close={setOpen}
      />
    );
  }
};

export default CompNewRequest;
