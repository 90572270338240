import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import SearchBox from "../../../components/search/SearchBox";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppConstants from "../../../utils/app-constants";
import RequestForApproval from "./actions/RequestForApproval";
import ViewAndRequest from "./actions/ViewAndRequest";
import SpareInProgress from "./SpareInProgress";
import ViewRejectedDetails from "./actions/ViewRejectedDetails";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import handleExport from "../../../utils/exportExcel";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import auth from "../../../utils/auth";


const SpareManageRequest = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [openRPI, setOpenRPI] = useState();
  const [openGIN, setOpenGIN] = useState();
  const [show, setShow] = useState(false);
  const [filterData, setFilterData] = useState();
  const [data, setData] = useState([]);

  const fetchBom = async () => {
    const resp = await trackPromise(
      API.get(
        `finance/api/v1/sparepricing/managerequest?requestID=${formValue.requestID}&requestType=${formValue.requestType}&requestCategory=${formValue.requestCategory}&actionDate=${formValue.requestDate}&status=${formValue.status}`
      )
    );

    console.log(resp);
    setData(resp);
  };

  useEffect(() => {
    fetchBom();
  }, []);


  const uniquerequestID  = [...new Set(data.map((item)=>item.requestID))]
  const uniquestatus = [...new Set(data.map((item)=>item.status))]



  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY / HH:mm")}`;
  }

  const filterGRNData = ["213245", "324451266", "635536363", "536477"];
  const filterGINData = ["12345", "2345432", "786477362", "7635442"];

  const statusFormatter = (cell) => {
    return cell === AppConstants.PENDING_MANAGEMENT ? (
      <button className="deactive-btn">
        <span className="deactive-btn-font">Pending at Management</span>
      </button>
    ) : cell === AppConstants.HOLD_MANAGEMENT ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">Hold by Management</span>
      </button>
    ) : cell === AppConstants.IN__PROGRESS ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">In-Progress</span>
      </button>
    ) : cell === AppConstants.COMPLETED ? (
      <button className="active-btn">
        {" "}
        <span className="active-btn-font">Completed</span>
      </button>
    ) : cell === AppConstants.REJECTED ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Rejected</span>
      </button>
    ) : (
      cell
    );
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.status === AppConstants.PENDING_MANAGEMENT)
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              // padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
            }}
            onClick={() => setActionShow(rowIndex)}
          >
            Request for Approval
          </button>
          {actionShow === rowIndex && (
            <RequestForApproval rowData={row} closeModal={setActionShow} />
          )}
        </div>
      );
    else if (row.status === AppConstants.HOLD_MANAGEMENT) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              // padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
              width: "130px",
            }}
            onClick={() => setActionShow(rowIndex)}
          >
            View & Release
          </button>
          {actionShow === rowIndex && (
            <ViewAndRequest rowData={row} closeModal={setActionShow} />
          )}
        </div>
      );
    } else if (row.status === AppConstants.IN__PROGRESS) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              // padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
              width: "130px",
            }}
            onClick={() => setActionShow(rowIndex)}
          >
            View Details
          </button>
          {actionShow === rowIndex && (
            <SpareInProgress rowData={row} closeModal={setActionShow} />
          )}
        </div>
      );
    } else if (row.status === AppConstants.REJECTED) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              // padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
              width: "130px",
            }}
            onClick={() => setActionShow(rowIndex)}
          >
            View Details
          </button>
          {actionShow === rowIndex && (
            <ViewRejectedDetails rowData={row} closeModal={setActionShow} />
          )}
        </div>
      );
    } else if (row.status === AppConstants.COMPLETED) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              // padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
              width: "130px",
            }}
            onClick={() => setActionShow(rowIndex)}
          >
            View Details
          </button>
          {actionShow === rowIndex && (
            <SpareInProgress rowData={row} closeModal={setActionShow} />
          )}
        </div>
      );
    }
  };

  const [formValue, setFormValue] = useState({
    requestID: "",
    requestedBy: "",
    requestDate: "",
    requestCategory: "",
    requestType: "",
    status: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      requestID: "",
      requestBy: "",
      requestDate: "",
      requestCategory: "",
      requestType: "",
      status: "",
    });
    const resp = await trackPromise(
      API.get(
        `finance/api/v1/sparepricing/managerequest`
      )
    );
    setData(resp);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requestID",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: "Request Category",
      dataField: "requestCategory",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: "Request Type",
      dataField: "requestType",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: "Added By",
      // dataField: "addedBy",
      headerAttrs: { width: "170px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow",
      formatter:()=>{
        return auth.getUserInfo().email
      }
    },
    {
      text: "Addition Date",
      dataField: "requestDate",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "230px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
 <div >
       <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{marginBottom:"-5px"}}>
          <span>Manage Requests</span>
        </div>

         <ToolkitProvider keyField="id" data={data} columns={columns} search >
         
          {(props) => (
            <>
            <div>
              <div style={{ float: "right", marginTop: "-42px"}}>
                <div style={{ display: "flex", marginRight: "-15px", height: "26px", marginTop:"10px", }}>
                  <div style={{ width: "220px" }}>
                    
                     <SearchBar
                      {...props.searchProps}
                      placeholder='Search'
                      style={{
                        height: "25px",
                       // border: "1px solid #C6C6C6",
                        border:"none",
                        background: "white",
                        borderRadius: "3px",
                        marginRight: "-50px",
                      }}
                    />
                    
                  </div>
                  <div onClick={clearFilter} className="filter-refresh">
                    <FontAwesomeIcon icon={faRedoAlt} />
                    &nbsp; Refresh
                  </div>
                  <div className="filter-refresh" onClick={handleClick}>
                    {" "}
                    <FontAwesomeIcon icon={faFilter} />
                    &nbsp; Filters
                  </div>
                  <div
                    className="filter-refresh"
                    onClick={() => handleExport(data, "manage request")}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                    &nbsp; Export
                  </div>
                   {inDashboard ?
              <div className="filter-refresh" onClick={() => inDashboard(false)}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </div> : ""}
                </div>
               </div>
              </div>
               
     <div>

   
      {show && (
        <>
          <form
            onSubmit={submitHandler}
            style={{
              display: "flex",
              flexWrap: "wrap",
              height: "135px",
              backgroundColor: "#F7F7F7",
              padding: "5px",
            }}
            className="UHinputfields VMform"
          >
            <div
              style={{ display: "flex", flexDirection: "column", width: "24%" }}
            >
              <label style={{ fontSize: "13px", fontWeight: "500" }}>
                {" "}
                Request ID
              </label>
              <select
                style={{ marginTop: "-5px", width: "100%" }}
                name="requestId"
                value={formValue.requestID}
                onChange={handleChange}
              >
                <option value="" disabled selected hidden>
                  Request ID
                </option>
                {uniquerequestID.map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "24%" }}
            >
              <label style={{ fontSize: "13px", fontWeight: "500" }}>
                {" "}
                Request Category
              </label>
              <input
                type="text"
                style={{ marginTop: "-5px", width: "100%" }}
                name="requestCategory"
                value={formValue.requestCategory}
                onChange={handleChange}
                placeholder="Request Category"
                autoComplete="off"
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "24%" }}
            >
              <label style={{ fontSize: "13px", fontWeight: "500" }}>
                Request Type
              </label>
              <input
                type="text"
                style={{ marginTop: "-5px", width: "100%" }}
                name="requestType"
                value={formValue.requestType}
                onChange={handleChange}
                placeholder="Request Type"
                autoComplete="off"
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "24%" }}
            >
              <label style={{ fontSize: "13px", fontWeight: "500" }}>
                Addition Date
              </label>
              <input
                type={"date"}
                name="actionDate"
                className="requestdate"
                selected={formValue.requestDate}
                onChange={(date) =>
                  setFormValue({ ...formValue, actionDate: date })
                }
                isClearable
                placeholderText="Addition Date"
                dateFormat="DD-MM-YYYY/HH:mm"
                autoComplete="off"
                style={{ marginTop: "-5px", width: "100%" }}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "24%" }}
            >
              <label style={{ fontSize: "13px", fontWeight: "500" }}>
                Status
              </label>
              <select
                style={{ marginTop: "-5px", width: "100%" }}
                name="status"
                value={formValue.status}
                onChange={handleChange}
              >
                <option value="" disabled selected hidden>
                  Status
                </option>
                {uniquestatus.map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div
              style={{
                width: "46%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <button
                onClick={fetchBom()}
                style={{ width: "100px", marginTop: "24px" }}
                id="apply"
              >
                Apply
              </button>
              <button
                style={{ width: "100px", marginTop: "24px" }}
                onClick={clearFilter}
                id="clear"
              >
                Clear Filter
              </button>
            </div>
          </form>
        </>
      )}
      <br></br>

      </div>

      
              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
        </div>
    </>
  );
};

export default SpareManageRequest;
