import React, { useState, useEffect } from "react";
import Group from "../QcDashboard/assets/Group.png";
import Group2 from "../QcDashboard/assets/Group2.png";
import Vector from "../QcDashboard/assets/Vector.svg";
import Vector3 from "../QcDashboard/assets/Vector3.svg";
import Group3 from "../QcDashboard/assets/Group3.png";
import Group4 from "../QcDashboard/assets/Group4.png";
import Group5 from "../QcDashboard/assets/Group5.png";
import Group6 from "../QcDashboard/assets/Group6.png";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";

function BomCosting({ costingData }) {
  console.log(costingData);
  return (
    <div className="requestBody">
      <div className="request">
        <img src={Group} style={{ marginLeft: "8px" }} alt="group" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginLeft: "15px",
          }}
        >
          <div style={{ fontSize: "24px", fontWeight: "450" }}>
            {costingData?.totalReqs}&nbsp;
            {/* {costingData?.totalReqsChange > 0 ? (
              <>
                <img src={Vector} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "green" }}
                >{`+${costingData?.totalReqsChange}`}</span>
              </>
            ) : costingData?.totalReqsChange === 0 ? (
              <>
                <img src={Vector} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "green" }}
                >{`+${costingData?.totalReqsChange}`}</span>
              </>
            ) : (
              <>
                <img src={Vector3} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "red" }}
                >{`${costingData?.totalReqsChange}`}</span>
              </>
            )} */}
          </div>
          <span style={{ color: "#808080", fontSize: "14px" }}>
            Total Requests
          </span>
        </div>
      </div>

      <div className="request">
        <img src={Group2} style={{ marginLeft: "8px" }} alt="group" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginLeft: "15px",
          }}
        >
          <div style={{ fontSize: "24px", fontWeight: "450" }}>
            {costingData?.approvedReqs}&nbsp;
            {/* {costingData?.approvedReqsChange > 0 ? (
              <>
                <img src={Vector} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "green" }}
                >{`+${costingData?.approvedReqsChange}`}</span>
              </>
            ) : costingData?.approvedReqsChange === 0 ? (
              <>
                <img src={Vector} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "green" }}
                >{`+${costingData?.approvedReqsChange}`}</span>
              </>
            ) : (
              <>
                <img src={Vector3} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "red" }}
                >{`${costingData?.approvedReqsChange}`}</span>
              </>
            )} */}
          </div>
          <span style={{ color: "#808080", fontSize: "14px" }}>
            Approved Requests
          </span>
        </div>
      </div>

      <div className="request">
        <img src={Group4} style={{ marginLeft: "8px" }} alt="group" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginLeft: "15px",
          }}
        >
          <div style={{ fontSize: "24px", fontWeight: "450" }}>
            {costingData?.pendingReqs}&nbsp;
            {/* {costingData?.pendingReqsChange > 0 ? (
              <>
                <img src={Vector} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "green" }}
                >{`+${costingData?.pendingReqsChange}`}</span>
              </>
            ) : costingData?.pendingReqsChange === 0 ? (
              <>
                <img src={Vector} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "green" }}
                >{`+${costingData?.pendingReqsChange}`}</span>
              </>
            ) : (
              <>
                <img src={Vector3} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "red" }}
                >{`${costingData?.pendingReqsChange}`}</span>
              </>
            )} */}
          </div>
          <span style={{ color: "#808080", fontSize: "14px" }}>
            Pending Requests
          </span>
        </div>
      </div>

      <div className="request">
        <img src={Group5} style={{ marginLeft: "8px" }} alt="group" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginLeft: "15px",
          }}
        >
          <div style={{ fontSize: "24px", fontWeight: "450" }}>
            {costingData?.onHoldReqs}&nbsp;
            {/* {costingData?.onHoldReqsChange > 0 ? (
              <>
                <img src={Vector} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "green" }}
                >{`+${costingData?.onHoldReqsChange}`}</span>
              </>
            ) : costingData?.onHoldReqsChange === 0 ? (
              <>
                <img src={Vector} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "green" }}
                >{`+${costingData?.onHoldReqsChange}`}</span>
              </>
            ) : (
              <>
                <img src={Vector3} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "red" }}
                >{`${costingData?.onHoldReqsChange}`}</span>
              </>
            )} */}
          </div>
          <span style={{ color: "#808080", fontSize: "14px" }}>
            Hold Requests
          </span>
        </div>
      </div>

      <div className="request" style={{ marginRight: "222px" }}>
        <img src={Group6} style={{ marginLeft: "8px" }} alt="group" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginLeft: "15px",
          }}
        >
          <div style={{ fontSize: "24px", fontWeight: "450" }}>
            {costingData?.rejectedReqs}&nbsp;
            {/* {costingData?.rejectedReqsChange > 0 ? (
              <>
                <img src={Vector} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "green" }}
                >{`+${costingData?.rejectedReqsChange}`}</span>
              </>
            ) : costingData?.rejectedReqsChange === 0 ? (
              <>
                <img src={Vector} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "green" }}
                >{`+${costingData?.rejectedReqsChange}`}</span>
              </>
            ) : (
              <>
                <img src={Vector3} alt="vector" />
                <span
                  style={{ fontSize: "14px", color: "red" }}
                >{`${costingData?.rejectedReqsChange}`}</span>
              </>
            )} */}
          </div>
          <span style={{ color: "#808080", fontSize: "14px" }}>
            Rejected Requests
          </span>
        </div>
      </div>
    </div>
  );
}

export default BomCosting;
