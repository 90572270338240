import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import auth from "../../utils/auth";
import ReactToPrint from "react-to-print";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import moment from "moment";
import TermsView from "../TermsView/TermsView";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";

const CreditNotePreview = ({ rowData, closeModal }) => {
  console.log("rowData vehicle", rowData);

  const [showNote, setShowNote] = useState();
  const [UserData, setUserData] = useState([]);

  const getCompanyDetails = async () => {
    const resp = await trackPromise(
      API.get(
        `admin-module/api/v1/users/getDataWithTenantID?tenantID=${
          auth.getUserInfo().tenantID
        }`
      )
    );
    setUserData(resp);
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  console.log("rowData.dealerID-->", rowData?.dealerID);

  const [dealerData, setdealerData] = useState();

  const getDealerDetails = async () => {
    const resp = await trackPromise(
      API.get(`/finance/api/v1/userDetails?userID=${rowData.dealerID}`)
    );
    setdealerData(resp);
  };

  useEffect(() => {
    getDealerDetails();
  }, []);

  console.log("dealerData-->", dealerData);

  let componentRef = null;


  const total1 = rowData?.returnedData?.reduce((accumulator, item) => {
    const itemTotal = item.unitPrice * rowData.returnQty;
    return accumulator + itemTotal;
  }, 0);

  let min = 100000;
  let max = 999999;
  let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

  let dueDate = new Date();
  dueDate.setDate(dueDate.getDate() + 90);

  return (
    <div className="container1">
      {/* {
        showNote && <CreditNote closeModal={setShowNote} closeOne={closeOne} closeTwo={closeModal} rowData={rowData} />
      } */}
      <div
        className="modalContainer"
        style={{
          width: "75%",
          height: "600px",
          padding: "15px",
          marginTop: "95px",
          marginBottom: "100px",
          borderRadius: "0px",
          borderTop: "none",
        }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Credit Note</h3>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <button
                    style={{
                      color: "#0A18A3",
                      margin: "1px",
                      height: "27px",
                      width: "90px",
                      border: "none",
                    }}
                  >
                    Download
                  </button>
                );
              }}
              content={() => componentRef}
              documentTitle="Purchase Order Invoice"
              pageStyle="Print"
            />
          </div>
        </div>

        <div style={{ overflow: "scroll" }}>
          <div
            // className="body"
            style={{
              width: " 99%",
              height: "97%",
              display: "flex",
              margin: "5px",
              flexDirection: "column",
              justifyContent: "space-between",
              // pageBreakBefore: "always",
            }}
            ref={(el) => (componentRef = el)}
          >
            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      marginTop: "40px",
                      fontSize: "25px",
                      marginBottom: "14px",
                    }}
                  >
                    CREDIT NOTE
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span style={{ fontWeight: "100" }}>
                    Credit Note No. : <span>{randomNumber}</span>
                  </span>
                  <span style={{ fontWeight: "100" }}>
                    Credit Note Date :{" "}
                    {moment(rowData?.creditNoteDate).format("DD-MMM-YYYY")}{" "}
                  </span>
                  {/* <span style={{ fontWeight: "100" }}>
                    PO No. :{" "}
                    <span>
                      {rowData?.returnedData.map((item) => item.poNo)}
                    </span>
                  </span> */}
                  <span style={{ fontWeight: "100" }}>
                    Due Date : {moment(dueDate).format("DD-MMM-YYYY")}{" "}
                  </span>
                </div>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "2px",
                  height: "25px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      paddingLeft: "3px",
                      fontSize: "15px",
                    }}
                  >
                    From :
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      paddingLeft: "3px",
                      fontSize: "15px",
                    }}
                  >
                    Customer :
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      alignItems: "end",
                      fontSize: "15px",
                    }}
                  >
                    Ship To :
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {UserData?.businessName}
                  </span>
                  <span>
                    {" "}
                    {UserData?.locations?.map(
                      (item) =>
                        item.locationType === "Business" && (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <span style={{ fontWeight: "100" }}>
                              Phone: {item.businessContactNo}
                            </span>
                            <span style={{ fontWeight: "100" }}>
                              {item.address1 + " , " + item.address2}
                            </span>
                            <span style={{ fontWeight: "100" }}>
                              {item.city +
                                " , " +
                                item.state +
                                " , " +
                                item.country}
                            </span>
                          </span>
                        )
                    )}
                  </span>
                  <span style={{ fontWeight: "100" }}>
                    PAN : {UserData?.pan}
                  </span>
                  <span style={{ fontWeight: "100" }}>
                    GSTIN : {UserData?.gstin}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginLeft: "-35px",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {dealerData?.businessName}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    Phone: <span>{dealerData?.contactPersonNo}</span>
                  </span>
                  <span style={{ fontWeight: "400" }}>{`${dealerData?.address}, ${dealerData?.address1}`}</span>
                  <span style={{ fontWeight: "400" }}>
                    {`PAN : ${dealerData?.panNo}`}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    {`GSTIN : ${dealerData?.gstin}`}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {dealerData?.businessName}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    Phone: <span>{dealerData?.contactPersonNo}</span>
                  </span>
                  <span style={{ fontWeight: "400" }}>{`${dealerData?.address}, ${dealerData?.address1}`}</span>
                  <span style={{ fontWeight: "400" }}>
                    {`PAN : ${dealerData?.panNo}`}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    {`GSTIN : ${dealerData?.gstin}`}
                  </span>
                </div>

              </div>
            </section>

            <section style={{ width: "100%", flexGrow: 1 }}>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#0A18A3",
                  marginBottom: "2px",
                }}
              >
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "50px",
                      marginLeft: "5px",
                      padding: "2px",
                    }}
                  >
                    S.No
                  </span>
                </div>

                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "500px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    Item Description
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "100px",
                      marginLeft: "10px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    Qty.
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "100px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    {`Unit Price(${auth.getUserInfo().currencySymbol})`}
                  </span>
                </div>

                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      padding: "2px",
                      marginLeft:"50px"
                    }}
                  >
                    {`Total (${auth.getUserInfo().currencySymbol})`}
                  </span>
                </div>
              </div>

              <div style={{ fontWeight: "100" }}>
                {rowData?.returnedData?.map((item, index) => (
                  <div
                    // id="POVendorContent"
                    style={{
                      display: "flex",
                      // justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "50px",
                          marginLeft: "5px",
                        }}
                      >
                        {index + 1}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "500px",
                          marginLeft: "2px",
                          marginRight: "2px",
                        }}
                      >
                        {`${item?.vehicleSkuCode} - ${item?.vehicleModel} - ${item?.vehicleVariant} - ${item?.vehicleColor}`}
                      </span>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "500px",
                          marginLeft: "5px",
                        }}
                      >
                        {}
                      </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "100px",
                          marginLeft: "10px",
                          marginRight: "2px",
                        }}
                      >
                        {rowData?.returnQty}
                      </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "100px",
                          marginLeft: "2px",
                          marginRight: "2px",
                        }}
                      >
                        {item?.unitPrice?.toLocaleString("en-IN")}
                      </span>
                    </div>

                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          color: "black",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "start",
                          width: "100px",
                          marginLeft: "50px",
                          marginRight: "2px",
                        }}
                      >
                        {(item.unitPrice * rowData.returnQty).toLocaleString("en-IN")}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            <section
              className="POterms"
              style={{
                justifyContent: "space-between",
                backgroundColor: "rgb(10, 24, 163)",
                margin: "12px 0px 12px 0px",
              }}
            ></section>

            <section
              className="POterms"
              style={{
                justifyContent: "space-between",
                backgroundColor: "white",
              }}
            >
              <div style={{ width: "75%" }}>
                <div
                  style={{
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  Terms & Conditions :-
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    textAlign: "left",
                    display: "flex",
                    flex: "wrap",
                    fontWeight: "100",
                  }}
                >
                  <TermsView data={"Credit Note Terms"} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  width: "25%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    width: "130px",
                  }}
                >
                  <span>
                    {`Sub Total (${auth.getUserInfo().currencySymbol})`} :{" "}
                  </span>
                  {/* <span>
                    {`Shipping Charges(${auth.getUserInfo().currencySymbol})`} :{" "}
                  </span> */}
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <span>{total1.toLocaleString("en-IN")}</span>
                  {/* <span>{`500`}</span> */}
                </div>
              </div>
            </section>

            <section
              className="POterms"
              style={{
                justifyContent: "end",
                backgroundColor: "rgb(10, 24, 163)",
                margin: "12px 0px 0px 0px",
                color: "white",
                border: "1px solid black",
              }}
            >
              <span>
                {" "}
                {`Credit Note Total : (${auth.getUserInfo().currencySymbol})`}
                {total1.toLocaleString("en-IN")}{" "}
              </span>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span></span>
                <span style={{ marginTop: "70px", paddingRight: "23px" }}>
                  {" "}
                  Authorized Signatory
                </span>
              </div>
            </section>
          </div>
        </div>

        <div className="footer">
          <button
            style={{
              width: "104px",
              height: "35px",
              background: "#fff",
              cursor: "pointer",
            }}
            onClick={() => closeModal(false)}
          >
            <Close style={{ fontSize: "25px" }} />
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreditNotePreview;
