import { BorderBottom, KeyboardBackspace } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Specification from "../../../AreaSalesOrder/SparesOrders/Component/Specification";
import ReturnUnit from "./ReturnUnit";
import auth from "../../../../utils/auth";

const RPIDetailsSpareReturns = ({ rowData, closeModal, manipulateData }) => {
  const [open, setOpen] = useState(false);
  const [openSpec, setOpenSpec] = useState(false);
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "80%", height: "90%", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            marginTop:"-30px",
          }}
        >
          <h3>RPI Details</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px", marginTop:"-100px" }}
        >

          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div>RPI No:{rowData?.rpiNo}</div>
              <div>RPI Date: {moment(rowData?.rpiDate).format("DD-MM-YYYY")}</div>
              <div>RPI Raised By: {rowData?.serviceCenterName}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div>Total Units: {rowData?.returnedUnits}</div>
              <div>Total Amount {`(${auth.getUserInfo().currencySymbol})`}: {rowData?.returnValue}</div>
            </section>
          </section>

          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
              height:"250px"
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "fit-content",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "50px", padding: "10px" }}>S.No</div>
              {/* <div style={{ width: "200px", padding: "10px" }}>Invoice No</div> */}
              <div style={{ width: "200px", padding: "10px" }}>Invoice Date</div>
              <div style={{ width: "200px", padding: "10px" }}>SKU Code</div>
              <div style={{ width: "400px", padding: "10px" }}>Spare Part Name</div>
              <div style={{ width: "200px", padding: "10px" }}>Category</div>
              <div style={{ width: "400px", padding: "10px" }}>Sub Category</div>
              {/* <div style={{ width: "200px", padding: "10px" }}>
                Specifications
              </div> */}
              <div style={{ width: "200px", padding: "10px" }}>
                Returned Quantity
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Unit Price{`(${auth.getUserInfo().currencySymbol})`}
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Sub Total {`(${auth.getUserInfo().currencySymbol})`}
              </div>
            </div>
            <section style={{ width: "fit-content", height: "auto" }}>
              {rowData.returnedData.map((item, index) => (
                <div
                  style={{
                    padding: "0px",
                    border: "1px solid #cdcdcd",
                    width: "",
                    fontSize: "13px",
                    display: "flex",
                  }}
                >
                  <div style={{ width: "50px", padding: "10px" }}>{index + 1}</div>
                  {/* <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "14px",
                        color: "blue",
                        cursor: "pointer",
                      }}
                    >
                      { rowData?.invoiceNo || item?.invoiceNo}
                    </p>

                  </div> */}
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {moment(item?.invoiceDate).format("DD-MM-YYYY")}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.skuCode}
                  </div>
                  <div style={{ width: "400px", padding: "10px" }}>{item?.sparePartname}</div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.category}
                  </div>
                  <div style={{ width: "400px", padding: "10px" }}>{item?.subCategory}</div>
                  {/* <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                      color: "blue"
                    }}
                    onClick={() => setOpenSpec(true)}
                  >
                    View Details
                  </div> */}
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                      color: "blue"
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {item?.returnQty?.length}
                  </div>
                  <div style={{ width: "200px", padding: "10px" }}>{item?.priceToserviceCenter}</div>
                  <div style={{ width: "200px", padding: "10px" }}>{(item?.returnQty?.length) * (item?.priceToserviceCenter)}</div>
                  {open && <ReturnUnit  rowData={item} closeModal={setOpen} />}
                  {openSpec && <Specification specs={item} closeModal={setOpenSpec} />}
                </div>
              ))}
            </section>
          </section>

        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default RPIDetailsSpareReturns;

