import React from "react";
import {
  KeyboardBackspace,
} from "@material-ui/icons";
import moment from "moment";
import auth from "../../utils/auth";

const RequisitionDetails = ({ closeModal, rowData }) => {

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "90%", height: "420px", padding: "15px" }}
      >
        <div className="header" tyle={{ marginTop: "-30px" }}>
          <h3>Vehicle Requisition</h3>
        </div>
        <br />
        <div
          className="body"
          style={{
            display: "flex",
            height: "450px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requisition ID : {rowData.requisitionID}</span>
              <span>Total Vehicle Requested : {rowData.vehicleData.length}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requisition Date : {moment(rowData.requisitionDate).format("DD-MMM-YYYY/HH:mm")}</span>
              <span>Total Units Requested : {rowData.vehicleData.reduce((acc, item) => acc + Number(item.requiredQty), 0)}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested To : {rowData.dealerEmail}</span>
            </div>

            <br />
            <div style={{ overflow: "scroll" }}>
              <div id="POVendorHeading">
                <div style={{ width: "8%" }}>S. No.</div>
                <div style={{ width: "13%" }}>SKU Code</div>
                <div style={{ width: "13%" }}>Category</div>
                <div style={{ width: "13%" }}>Model</div>
                <div style={{ width: "13%" }}>Variant</div>
                <div style={{ width: "13%" }}>Type</div>
                <div style={{ width: "13%" }}>Color</div>
                <div style={{ width: "15%" }}>Requested Units</div>
                <div style={{ width: "13%" }}>Battery Qty.</div>
                <div style={{ width: "19%" }}>{`Vehicle Unit Price (${auth.getUserInfo().currencySymbol})`}</div>
                <div style={{ width: "19%" }}>{`Battery Unit Price (${auth.getUserInfo().currencySymbol})`}</div>
                <div style={{ width: "13%" }}>{`Sub Total (${auth.getUserInfo().currencySymbol})`}</div>
              </div>
              <div>
                {
                  rowData.vehicleData.map((item, index) => {
                    return (
                      <div
                        id="POVendorContent"
                        style={{
                          borderBottom: "1px solid #999999",
                          boxShadow: "none",
                          padding: "0px",
                        }}
                        key={1}
                      >
                        <div style={{ width: "8%" }}>{index + 1}</div>
                        <div style={{ width: "13%" }}>{item.vehicleSkuCode}</div>
                        <div style={{ width: "13%" }}>{item.vehicleCategory}</div>
                        <div style={{ width: "13%" }}>{item.vehicleModel}</div>
                        <div style={{ width: "13%" }}>{item.vehicleVariant}</div>
                        <div style={{ width: "13%" }}>{item.vehicleType}</div>
                        <div style={{ width: "13%" }}>{item.vehicleColor}</div>
                        <div style={{ width: "15%" }}>{item.requiredQty}</div>
                        <div style={{ width: "13%" }}>{item.batteryQty}</div>
                        <div style={{ width: "19%" }}>{item.unitPrice}</div>
                        <div style={{ width: "19%" }}>-</div>
                        <div style={{ width: "13%" }}>{item.unitPrice * item.requiredQty}</div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </section>
        </div>

        <div className="footer">
          <div>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "25px" }} />
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequisitionDetails;
