import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { KeyboardBackspace } from "@material-ui/icons";
import "../../work-order/workorder.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import "../BomCosting.scss";
import auth from "../../../utils/auth";

const ViewDetailsLabour = ({ close, rowdata }) => {
  const [UsersData, setUsersData] = useState([]);

  // const fetchReport = async () => {
  //     let body = {
  //         "orderID": rowdata.orderID,
  //         "status": rowdata.status,
  //         "requiredItemsType": "components"
  //     }
  //     const resp = await trackPromise(API.post(`shop-floor-module/api/v1/shopFloor/getRequirementItemType`, body));
  //     console.log(resp);
  //     setUsersData(resp);
  // };
  // useEffect(() => {
  //     fetchReport();
  // }, []);

  let compData = [
    {
      _id: { $oid: "6229b056664f049747d14b7d" },
      laborCategory: "Unskilled labor",
      laborSubCategory: "Loading",
      laborLevel: "Fresher",
      laborCost: "200",
      laborTime: "5 hrs",
      reqLabor: "2",
    },
    {
      _id: { $oid: "6229b056664f049797d14b7d" },
      laborCategory: "skilled labor",
      laborSubCategory: "Unloading",
      laborLevel: "Experienced",
      laborCost: "100",
      laborTime: "9 hrs",
      reqLabor: "5",
    },
  ];

  const [blankForm, setBlankForm] = useState(rowdata?.bomLabor);
  const totalLaborCost = blankForm?.reduce((acc, item) => {
    return acc + parseFloat(item?.avgLaborCost);
  }, 0);
  const totalTime = blankForm?.reduce((acc, item) => {
    return acc + parseFloat(item?.requiredDuration);
  }, 0);
  const totalLabor = blankForm?.reduce((acc, item) => {
    return acc + parseFloat(item?.requiredWorkers);
  }, 0);

  const convertToDays = (requiredDuration, laborDuration) => {
    const durationInDays = {
      day: 1,
      week: 7,
      month: 30,
      days:1,
      weeks:7,
      months:30
    };
    return requiredDuration * (durationInDays[laborDuration.toLowerCase()] || 1);
  };

  const countPrice = (props) => {
    let total = 0;
    props.forEach((item) => {
      const days = convertToDays(parseInt(item?.requiredDuration), item?.laborDuration);
      total += parseInt(item?.requiredWorkers) * days * parseInt(item?.avgLaborCost);
    });
    return total;
  };

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{
            width: "1104px",
            height: "auto",
            maxHeight: "485px",
            padding: "15px",
          }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Required Labour</h3>
            {/* <div>
                            <button
                                className="action-btn"
                                style={{ color: "blue" }}>
                                Print
                            </button>
                            <button
                                className="action-btn"
                                style={{ color: "blue" }}>
                                Export
                            </button>
                        </div> */}
          </div>
          <br />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontWeight: "500",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontSize: "14px" }}>Total Labour Count: {totalLabor}</span>
            </div>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontSize: "14px" }}>Total Time: {totalTime} {blankForm[0]?.laborDuration}</span>
            </div> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontSize: "14px" }}>Total Cost({auth.getUserInfo().currencySymbol}): {countPrice(blankForm)}</span>
            </div>
          </div>
          <div className="body" style={{ height: "300px" }}>
            <div style={{ width: "100%", overflow: "scroll", height: "285px" }}>
              <table className="viewcomp_tbl" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "6%" }}>S.No.</th>
                    <th style={{ width: "13%" }}>Labor Category</th>
                    <th style={{ width: "15%" }}>Labor Sub Category</th>
                    <th style={{ width: "15%" }}>Labor Level</th>
                    <th style={{ width: "10%" }}>Req. Count</th>
                    <th style={{ width: "15%" }}>Labor Time</th>
                    <th style={{ width: "15%" }}>
                      Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                    <th style={{ width: "15%" }}>
                      Sub Total ({auth.getUserInfo().currencySymbol})
                    </th>
                  </tr>
                </thead>

                {blankForm?.map((item, index) => {
                  return (
                    <tr>
                      <td
                        style={{ fontSize: "13px", width: "6%" }}
                        className="leftcell"
                      >
                        {index + 1}
                      </td>
                      <td style={{ fontSize: "13px", width: "13%" }}>
                        {item?.laborCategory}
                      </td>
                      <td style={{ fontSize: "13px", width: "15%" }}>
                        {item?.laborSubCategory}
                      </td>
                      <td style={{ fontSize: "13px", width: "15%" }}>
                        {item?.laborLevel}
                      </td>
                      <td style={{ fontSize: "13px", width: "10%" }}>
                        {item?.requiredWorkers}
                      </td>
                      <td style={{ fontSize: "13px", width: "8%" }}>
                        {item?.requiredDuration}{" "}{item?.laborDuration}
                      </td>
                      <td style={{ fontSize: "13px", width: "8%" }}>
                        {item?.avgLaborCost}{" Per Day"}
                      </td>
                      <td
                        style={{ fontSize: "13px", width: "15%" }}
                        className="rightcell"
                      >
                        {/* {(item?.requiredWorkers || 0) *
                          (item?.avgLaborCost || 0)} */}
                            {parseInt(item?.requiredWorkers) *
                    convertToDays(parseInt(item?.requiredDuration), item?.laborDuration) *
                    parseInt(item?.avgLaborCost)}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>

          <br />
          <div className="footer">
            <button className="btn-footer" onClick={() => close(false)}>
              <KeyboardBackspace style={{ fontSize: "25px" }} /> Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDetailsLabour;
