import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker';
import API from '../../../../utils/api';

const ViewAllNotification = () => {

  const [notificationData, setNotificationData] = useState([]);

  const fetchReport = async () => {
    const resp = await trackPromise(API.get(`message-module/api/v1/notification`));
    setNotificationData(resp.data == undefined ? [] : resp.data);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  console.log(notificationData)

  return (
    <>
      <div style={{ fontSize: "35px", fontWeight: "400", textAlign: "center" }}>Notifications</div>
      <div class="notificationList">
        {notificationData.length == 0 ? <ul><li>No New Notification</li></ul> :
          notificationData.map((item) => {
            return (
              <ul className="notificationUl">
                <li style={{ fontSize: "14px" }}>
                  <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <FontAwesomeIcon icon={faBell} style={{ color: "#0a18a3", fontSize: "16px" }} />
                    <span style={{ margin: "0px 0px 0px 10px" }}>{item.message.notification.body}</span>
                  </div>
                </li>
              </ul>
            )
          })}
      </div>
    </>
  )
}

export default ViewAllNotification;