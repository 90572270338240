import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';



const UserCountBarChart = () => {
    const [userCountData, setUserCountData] = useState({});

    const fetchUserCountData = async () => {
        const resp = await trackPromise(API.get(`serviceCenter-module/api/v1/dashboard/getUsers`));
        console.log(resp);
        setUserCountData(resp)
    };
    useEffect(() => {
        fetchUserCountData();
    }, []);

    return <Bar
        height={120}
        data={{
            datasets: [{
                type: 'bar',
                backgroundColor: '#2D3DD9',
                barThickness: 25,
                data: [userCountData.totalUser, userCountData.totalActiveUser, userCountData.totalDeactivateUser, userCountData.totalReactivateUser, userCountData.totalDeleteUser],
                options: {
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    responsive: true
                }
            }
            ],
            labels: ["Total Users", "Active Users", "Deactivated Users", "Reactivated Users", "Deleted Users"]
        }}
    />
}

export default UserCountBarChart