import React, { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, registerables } from 'chart.js'
import moment from 'moment';
ChartJS.register(...registerables)
ChartJS.defaults.plugins.legend.display = false;


function ShopFloorAddOnBarChart({ props, startDate, endDate }) {
  const [data, setData] = useState({});
  const fetchData = async () => {
    const res = await trackPromise(
      API.get(startDate == null && endDate == null
        ? `shop-floor-module/api/v1/dashBoard/addOn`
        : `shop-floor-module/api/v1/dashBoard/addOn?startDate=${moment(
          startDate
        ).format("DD-MMM-YYYY")}& endDate=${moment(endDate).format(
          "DD-MMM-YYYY"
        )}`)
    );
    console.log(res)
    setData(res);
  };

  useEffect(() => {
    fetchData();
  }, [endDate]);

  return <Bar
    height={100}
    data={{
      datasets: [{
        type: 'bar',
        backgroundColor: '#2D3DD9',
        barThickness: 25,
        data: [data.totalRequest, data.approvedRequest, data.onHoldRequest, data.rejectedRequest],
        indexAxis: 'y',
        options: {

          responsive: true,

        }
      }
      ],
      labels: ["Total Requests", "Approved Requests", "On-Hold Requests", "Rejected Requests"]
    }}
  />
}

export default ShopFloorAddOnBarChart