import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import Vehicles from "./Tabs/Vehicles";
// import Spares from "./Tabs/Spares";

const Vehicles = React.lazy(()=>import("./Tabs/Vehicles"))
const Spares = React.lazy(()=>import("./Tabs/Spares"))


const ZonalSalesVehicleAndSpares = () => {
  const tabs = [
    {
      id: "ZSMM2T1",
      label: TabLabels.VEHICLE,
      Component: Vehicles,
    },
    {
      id: "ZSMM2T2",
      label: TabLabels.SPARES,
      Component: Spares,
    }
  ];

  return (
    <div className="app-layout__card">
      <Tabs type="pills" tabs={tabs} />
    </div>
  );
};

export default ZonalSalesVehicleAndSpares;
