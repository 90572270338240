import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "../../../../../utils/notifications";
import SubmitPop from "./SubmitPop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import ReturnUnit from "../ReturnUnit";
import ReturnUnits from "./ReturnUnits";
import Specification from "../../../../AreaSalesOrder/SparesOrders/Component/Specification";


const RequestCompletedByInventory = ({ rowData, closeModal, tabName }) => {
  const [open, setOpen] = useState(false);
  const [openSpec, setOpenSpec] = useState(false);
  
  return (
    <div className="container1">
     
     {openSpec && <Specification rowData={rowData} closeModal={setOpenSpec} />}

    
      <div
        className="modalContainer"
        style={{ width: "1280px", height: "500px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Request Completed By Inventory</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div>Request ID: {rowData.requestID}</div>
              <div>Request Date: {moment().format("DD-MM-YYYY")}</div>
              <div>Request By.: {rowData.rpiRaisedBy}</div>
              <div>GIN No: {rowData.ginNo}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div>RPI No: {rowData.rpiNo}</div>
              <div>RPI Date: {rowData.rpiDate}</div>
              <div>RPI Raised By: {}</div>
            </section>
          </section>
          <br />
          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "auto",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "10%", padding: "10px" }}>S.No</div>
              <div style={{ width: "200px", padding: "10px" }}>SKU Code</div>
              <div style={{ width: "200px", padding: "10px" }}>Category</div>
              {
                (tabName == "spareManageReq" ? (
                  <>
                  <div style={{ width: "200px", padding: "10px" }}>Sub Category</div>
                  <div style={{ width: "200px", padding: "10px" }}>Spare Part Name</div>
                    <div style={{ width: "200px", padding: "10px" }}>
                      Specifications
                    </div>
                    <div style={{ width: "200px", padding: "10px" }}>
                      Returned Quantity
                    </div>
                    <div style={{ width: "200px", padding: "10px" }}>
                      Unit Price
                    </div>
                    <div style={{ width: "200px", padding: "10px" }}>
                      Sub Totals
                    </div>
                  </>
                ) : (
                  <> 
                  <div style={{ width: "200px", padding: "10px" }}>Segment</div>
                  <div style={{ width: "200px", padding: "10px" }}>
                Vehicle S.No
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Vehicle Make
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Vehicle Model
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Vehicle Variant
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Vehicle Type
              </div>
              <div style={{ width: "200px", padding: "10px" }}>
                Vehicle Color
              </div>
                    <div style={{ width: "200px", padding: "10px" }}>VIN</div>
                    <div style={{ width: "200px", padding: "10px" }}>BIN</div>
                    <div style={{ width: "200px", padding: "10px" }}>
                      Verification Status
                    </div>
                    <div style={{ width: "200px", padding: "10px" }}>
                      Failed Reason
                    </div>
                    <div style={{ width: "200px", padding: "10px" }}>
                      Status
                    </div>
                  </>
                ))
              }
            </div>
            <section style={{ width: "auto", height: "auto" }}>
              {rowData?.returnedData?.map((item, index) => (
                
              <div
                style={{
                  padding: "0px",
                  border: "1px solid #cdcdcd",
                  width: "auto",
                  fontSize: "13px",
                  display: "flex",
                }}
              >
                <div style={{ width: "10%", padding: "10px" }}>{index+1}</div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  {item?.skuCode}
                </div>
               
                  <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  {item?.category}
                </div>
               
                {
                  (tabName = "spareManageReq" ? (
                    <>
                    <div style={{ width: "200px", padding: "10px" }}>{item?.subCategory}</div>
                  <div style={{ width: "200px", padding: "10px" }}>{item?.sparePartName}</div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                          color:"blue"
                        }}
                        onClick={()=>setOpenSpec(true)}
                      >
                        View Details
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                          color:"blue"
                        }}
                        onClick={()=>setOpen(true)}
                      >
                        {item?.returnQty?.length}
                      </div>
                      {open && <ReturnUnits rowData={item?.returnQty} closeModal={setOpen} />}
                      <div style={{ width: "200px", padding: "10px" }}>{item?.unitPrice}</div>
                  <div style={{ width: "200px", padding: "10px" }}>{item?.returnQty?.length * item?.unitPrice}</div>
                    </>
                  ) : (
                    <>
                    <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  Segment
                </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        VIN
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        BIN
                      </div>
                      <div style={{ width: "200px", padding: "10px" }}>
                        <button
                          className="active-btn"
                          style={{ color: "green", width: "150px" }}
                        >
                          Verification Passed
                          <span className="active-btn-font"></span>
                        </button>
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          overflowX: "scroll",
                        }}
                      >
                        Failed reason
                      </div>
                      <div style={{ width: "200px", padding: "10px" }}>
                        <button
                          className="reactive-btn"
                          style={{ color: "blue", width: "150px" }}
                        >
                          Moves to finish Goods
                          <span className="reactive-btn-font"></span>
                        </button>
                      </div>
                    </>
                  ))
                }
              </div>
                ))}
            </section>
          </section>
        </div>

        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <KeyboardBackspace/> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestCompletedByInventory;
