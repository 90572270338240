import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AppConstants from "../../utils/app-constants";
import NG_ViewDetails from "./actions/ng-viewDetails";
import RG_ViewDetails from "./actions/rg-viewdetails";
import NG_CompViewDetails from "./actions/ng-compViewDetails";
import RG_CompViewDetails from "./actions/rg-compViewDetails";
import handleExport from "../../utils/exportExcel";

const { ExportCSVButton } = CSVExport;

const ComponentQC = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [open, setOpen] = useState();
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState();
  const [actionShow, setActionShow] = useState();
  const [tempData, setTempData] = useState();
  const [date, setDate] = useState("");
  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    requestID: "",
    requestType: "",
    requestedBy: "",
    requestDate: "",
    GRNno: "",
    GINno: "",
    status: "",
  });

  const [row, setRow] = useState();

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(
        `qc-module/api/v1/componentsspares/newrequest?requestID=${formValue.requestID}&requestType=${formValue.requestType}&requestedDate=${formValue.requestDate}&requestedBy=${formValue.requestedBy}&GIN=${formValue.GINno}&status=${formValue.status}`
      )
    );
    console.log(res);
    const finalData = res.filter((item, i) => {
      return (
        item.status == AppConstants.NEW_REQUEST ||
        item.status == AppConstants.NEW_REQUEST_NEW ||
        item.status == AppConstants.ON_HOLD ||
        item.status == AppConstants.IN_PROGRESS
      );
    });
    let filteredData = finalData;

    const compareDates = (a, b) => {
      const dateA = new Date(a.requestDate);
      const dateB = new Date(b.requestDate);
      return dateB - dateA; // Sort in descending order (latest first)
    };

    // Sort the array based on the latest requestedDate
    filteredData.sort(compareDates);
    console.log(filteredData);
    setData(filteredData);
    setTempData(filteredData);
  };

  console.log(data);
  useEffect(() => {
    fetchData();
  }, []);




  const uniquerequestID = [...new Set(data.map(item => item.requestID))];
  const uniquerequestType = [...new Set(data.map(item => item.requestType))];
  const uniquerequestedBy = [...new Set(data.map(item => item.requestedBy))];
  const uniqueGRNno = [...new Set(data.map(item => item.GRNno))];
  const uniqueGIN = [...new Set(data.map(item => item.GIN))];
  const uniquestatus = [...new Set(data.map(item => item.status))];






  const statusFormatter = (cell) => {
    return cell == AppConstants.NEW_REQUEST ||
      cell == AppConstants.NEW_REQUEST_NEW ? (
      <button className="active-btn">
        <span className="active-btn-font">New Request</span>
      </button>
    ) : cell == AppConstants.ON_HOLD ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">On Hold</span>
      </button>
    ) : cell == AppConstants.IN_PROGRESS ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">In-Progress</span>
      </button>
    ) : (
      cell
    );
  };

  const actionHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setOpen(true);
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          onClick={() => actionHandler(row, rowIndex)}
          style={{
            color: "#0A18A3",
            backgroundColor: "white",
            border: "1px solid #0A18A3",
            borderRadius: "3px",
          }}
        >
          View Details
        </button>
        {actionShow === rowIndex &&
          open &&
          row.requestType == "Verification" ? (
          <NG_CompViewDetails
            rowdata={row}
            status={true}
            closeModal={setOpen}
          />
        ) : actionShow === rowIndex &&
          open &&
          row.requestType == "Re-Verification" ? (
          <RG_CompViewDetails
            rowdata={row}
            status={true}
            closeModal={setOpen}
          />
        ) : null}
      </div>
    );
  };

  const goodsdateFormatter = (cell, row, rowIndex) => {
    return (
      <div>
        {row.GINDate ? <span>{row.GINDate}</span> : <span>{row.GRNDate}</span>}
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const GRNFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.requestCategory === "PO Components") {
      return (
        <div>
          <p>{row.GRNID}</p>
        </div>
      );
    } else {
      return (
        <div>
          <p>-</p>
        </div>
      );
    }
  };

  const WOFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.requestCategory === "WO Rejections") {
      return (
        <div>
          <p>{row.orderID}</p>
        </div>
      );
    } else {
      return (
        <div>
          <p>-</p>
        </div>
      );
    }
  };

  const GINFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.requestCategory === "Spare Returns") {
      return (
        <div>
          <p>{row.GIN}</p>
        </div>
      );
    } else {
      return (
        <div>
          <p>-</p>
        </div>
      );
    }
  };

  const exportData = data.map((row, index) => ({
    "S.No.": index + 1,
    "Request ID": row?.requestID ?? "",
    "Request Category": row?.requestCategory ?? "",
    "Request Type": row?.requestType ?? "",
    "Requested By": row?.requestedBy ?? "",
    "Requested Date": row?.requestedDate ? moment(row.requestedDate).format("DD-MM-YYYY") : "",
    "GRN No.": row?.GRN ?? "", // Assuming the raw value without formatter
    "WO No.": row?.orderID ?? "", // Assuming the raw value without formatter
    "GIN No.": row?.GIN ?? "", // Assuming the raw value without formatter
    "Status": row?.status ?? "", // Assuming the raw value without formatter
  }));
  

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          data.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requestID",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request Category",
      dataField: "requestCategory",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request Type",
      dataField: "requestType",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Requested By",
      dataField: "requestedBy",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Requested Date",
      dataField: "requestedDate",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "GRN No.",
      dataField: "GRN",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: GRNFormatter,
    },
    {
      text: "WO No.",
      dataField: "orderID",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: WOFormatter,
    },
    {
      text: "GIN No.",
      dataField: "GIN",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: GINFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      // dataField: "action",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      requestID: "",
      requestType: "",
      requestedBy: "",
      requestDate: "",
      GRNno: "",
      GINno: "",
      status: "",
    });
    const res = await trackPromise(
      API.get(`qc-module/api/v1/componentsspares/newrequest`)
    );
    const finalData = res.filter((item, i) => {
      return (
        item.status == AppConstants.NEW_REQUEST ||
        item.status == AppConstants.NEW_REQUEST_NEW ||
        item.status == AppConstants.ON_HOLD ||
        item.status == AppConstants.IN_PROGRESS
      );
    });
    setData(finalData);
    setTempData(finalData);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Component QC</span>
        </div>

        <ToolkitProvider keyField="id" data={data} columns={columns} search>
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(exportData, "Component QC")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <>
                    <form
                      onSubmit={submitHandler}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: "135px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                      }}
                      className="UHinputfields VMform"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request ID
                        </label>
                        <select
                          name="requestID"
                          value={formValue.requestID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Request ID
                          </option>
                          {
                            uniquerequestID.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Type
                        </label>
                        <select
                          name="requestType"
                          value={formValue.requestType}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Request Type
                          </option>
                          {
                            uniquerequestType.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Requested By
                        </label>
                        <select
                          name="requestedBy"
                          value={formValue.requestedBy}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Requested By
                          </option>
                          {
                            uniquerequestedBy.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Date
                        </label>
                        <input
                          type="date"
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "text")}
                          name="requestDate"
                          value={formValue.requestDate}
                          onChange={handleChange}
                          placeholder="Request Date"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          GRN No.
                        </label>
                        <select
                          name="GRNno"
                          value={formValue.GRNno}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            GRN No.
                          </option>
                          {
                            uniqueGRNno.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          GIN No.
                        </label>
                        <select
                          name="GINno"
                          value={formValue.GINno}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            GIN No.
                          </option>
                          {
                            uniqueGIN.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {
                            uniquestatus.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          width: "22%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <button
                          onClick={fetchData}
                          style={{ width: "100px", marginTop: "24px" }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{ width: "100px", marginTop: "24px" }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </form>
                  </>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ComponentQC;
