import React, { useState } from "react";
import AppConstants from "../../../utils/app-constants";
import "../../settings-tabs/homesettings.scss";
import "../approval.scss";
import ApproveVP from "./approveVP";
import OnHoldVP from "./onholdVP";
import RejectVP from "./rejectVP";
import moment from "moment";
import { KeyboardBackspace } from '@material-ui/icons';
import PrintIcon from '@mui/icons-material/Print';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import auth from "../../../utils/auth";

const VpViewandUpdate = ({ close, rowdata, updatedData }) => {
  console.log("rowdata-->",rowdata);
  const [approvePopup, setApprovePopup] = useState();
  const [rejectPopup, setRejectPopup] = useState();
  const [onholdPopup, setOnholdPopup] = useState();

  return (
    <>
      {approvePopup && <ApproveVP close={close} updatedData={updatedData} data={rowdata} closeModal={setApprovePopup} />}
      {rejectPopup && <RejectVP close={close} updatedData={updatedData} data={rowdata} closeModal={setRejectPopup} />}
      {onholdPopup && <OnHoldVP close={close} updatedData={updatedData} data={rowdata} closeModal={setOnholdPopup} />}
      <div className="bussiness">
        <h4 className="heading">New Requests</h4>
        <button
          type="button"
          className="btn btn-md"
          style={{ float: "right", marginTop: "-52px" }}
        >
          <div
            style={{ display: "flex", marginRight: "-18px", height: "31px" }}
          >
            <div className="filter-refresh" onClick={() => close(false)}>
            <KeyboardBackspace/>&nbsp;
              Back
            </div>
          </div>
        </button>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request ID:&nbsp;{rowdata.requestID}</span>
          <span>Requested By:&nbsp;{rowdata.requestedBy}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request Type:&nbsp;{rowdata.requestType}</span>
          <span>Request Date:&nbsp; {moment(rowdata.requestDate).format("DD-MMM-YYYY, HH:mm")}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request Category:{rowdata.requestCategory}</span>
        </div>
      </div>
      {rowdata.status == AppConstants.ON_HOLD_BY_MANAGEMENT ? (
        <div className="onhold_div">
          <span className="holdDate">Hold Date: &nbsp; {moment(rowdata.actionDate).format("DD-MMM-YYYY, HH:mm")}</span>
          <span style={{ fontSize: "25px", color: "#CDCDCD" }}>|</span>
          <span className="holdReason" style={{ right: "150px" }}>
            Hold Reason: &nbsp;{ rowdata?.holdreason || rowdata?.onHoldreason}
          </span>
        </div>
      ) : null} 
      <br />
      <div>
        <h6>Vehicles Details</h6>
        <div className="shopfloortbl">
          <table className="sfaction_tbl" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Segment</th>
                <th>Category</th>
                <th>Model</th>
                <th>Variant</th>
                <th>Type</th>
                <th>Color</th>
              </tr>
            </thead>
            {/* <hr className='line'/> */}
            <tbody>
              <tr>
                <td>{rowdata.vehicleSegment}</td>
                <td>{rowdata.vehicleCategory}</td>
                <td>{rowdata.vehicleModel}</td>
                <td>{rowdata.vehicleVariant}</td>
                <td>{rowdata.vehicleType}</td>
                <td>{rowdata.vehicleColor}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />

        <h6>Vehicle MRP Details</h6>
        <div className="shopfloortbl">
          <table className="sfaction_tbl" style={{ width: "100%" }}>
            <thead>
              <tr>
                      
                <th>{`BOM/Production Cost (${auth.getUserInfo().currencySymbol})`}</th>
                <th>Manufacturing Margin(%)</th>
                <th>{`Other Costs (${auth.getUserInfo().currencySymbol})`}</th>
                <th>{`Price to Dealer (${auth.getUserInfo().currencySymbol})`}</th>
                <th>Dealer Margin(%)</th>
                <th>{`Ex-Showroom Price (${auth.getUserInfo().currencySymbol})`}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{rowdata.totalBomCost?.toLocaleString('en-IN')}</td>
                <td>{rowdata.manufactureMargin} %</td>
                <td>{rowdata.otherCharges?.toLocaleString('en-IN')}</td>
                <td>{rowdata.pricetoDealer?.toLocaleString('en-IN')}</td>
                <td>{rowdata.dealerMargin} %</td>
                <td>{rowdata.exShowroomPrice?.toLocaleString('en-IN')}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <div>
            <button className="approvalfooter_btn"><PrintIcon/> &nbsp;Print</button>
            {rowdata.status == AppConstants.PENDING_AT_MANAGEMENT ? (
              <button
                className="approvalfooter_btn"
                onClick={() => setOnholdPopup(true)}
              ><PauseCircleOutlineIcon/>&nbsp;
                On Hold
              </button>
            ) : null}
          </div>
          <div>
            <button
              className="approvalfooter_btn"
              onClick={() => setRejectPopup(true)}
            ><CloseIcon/>&nbsp;
              Reject
            </button>
            <button
              className="approvalfooter_btn"
              style={{ color: "white", backgroundColor: "#0a18a3" }}
              onClick={() => setApprovePopup(true)}
            ><DoneIcon/>&nbsp;
              Approve
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VpViewandUpdate;
