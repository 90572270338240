import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification,errorNotification } from "../../../utils/notifications"

const OnHoldBom = ({closeModal, data,  updatedData,close}) => {
  const [formValue, setFormValue] = useState({
    onholdReason: "",
    description: "",
  });

  const editDetails  = {
    reason:formValue.onholdReason,
    ...(formValue.onholdReason === "Other" && {
      reasonDescription: formValue.description
    }) ,
    status:"On Hold By Management",
    BOMID:data.BOMID
   };

   const refresh = () => {
    updatedData();
   }


   const submitHandler = async () => {
    if(!formValue.onholdReason){
      return;
    }
    const resp = await trackPromise(API.put(`management/api/v1/bom/action`, editDetails));
    const {success,message} = resp;
    
    if (success) {
      closeModal(false);
      refresh()
      close(false)
      successNotification(message, { autoClose: 3000 });
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
 
  };
  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Hold BOM Request</h3> 
        </div>
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "13px" }}
        >
          <section
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "100%",
                textAlign: "left",
                width: "100%",
                textAlign: "left",
              }}
            >
            On Hold Reason<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="onholdReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: "100%", marginTop: "-5px" }}
            >
             <option disabled selected hidden>
                On Hold Reason
              </option>
              <option value="Misconduct">
              Misconduct
              </option>
              <option value="Not an Active User">
              Not an Active User
              </option>
              <option value="Change of Role">
              Change of Role
              </option>
              <option value="Others">Others</option>
            </select>

         
            {formValue.onholdReason === "Others" ?   <label className="lgInput">
            Description
            <input
              type="text"
              style= {{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="On Hold Reason*"
              autoComplete="off"
            />
          </label>:""}
          </section>
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnHoldBom;
