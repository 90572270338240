import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { successNotification, errorNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const RequiredSpareAndUnit = ({ updatedData, rowData, closeModal }) => {



  const submitHandler = async () => {
    console.log(formValue);
    const resp = await trackPromise(
      API.post(``)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Required Spare & Units</h3>
          <div>
            <button className="action-btn" style={{ color: "blue" }}>
              <PrintIcon />
              &nbsp;Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              <IosShareIcon />
              &nbsp;Export
            </button>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <label
            style={{ display: "flex", flexDirection: "column", width: "24%" }}
          >
            <label
              style={{
                float: "left",
                marginLeft: "-45px",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              Spare Parts<span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="input-config"
              id="chargesPerUser"
              type="text"
              backgroundColor="blue"
              placeholder="Select"
              autoComplete="off"
              style={{ marginTop: "-8px", width: "100px" }}
            />
          </label>
        </div>


        <div className="body">
          <div style={{ width: "100%" }}>
            <div>
              <Table>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>S.No</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>Spare Part Name</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>Qty</td>
                  </tr>
                  <tr>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{ }</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>



        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={submitHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequiredSpareAndUnit;
