import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
import RejectedVehicle from "./RejectedVehicle";
// import WipInventory from "./wip-inventory";
// import FinishedGoodsStatus from "./finishedgoods-status";
// import FinishedGoodsInventory from "./finishedgoods-inventory";
// import ReturnInventory from "./return-inventory";

const WipInventory = React.lazy(()=>import("./wip-inventory"))
const FinishedGoodsStatus = React.lazy(()=>import("./finishedgoods-status"))
const FinishedGoodsInventory = React.lazy(()=>import("./finishedgoods-inventory"))
const ReturnInventory = React.lazy(()=>import("./return-inventory"))


const Inventory = () => {
  const tabs = [
    {
      id: "PDM5T1",
      label: TabLabels.WIP_INVENTORY,
      Component: WipInventory
    },
    {
      id: "PDM5T2",
      label: TabLabels.FINISHED_GOODS_STATUS,
      Component: FinishedGoodsStatus
    },
    {
      id: "PDM5T3",
      label: TabLabels.FINISHED_GOODS_INVENTORY,
      Component: FinishedGoodsInventory
    },
    {
      id: "PDM5T4",
      label: TabLabels.RETURN_INVENTORY,
      Component: ReturnInventory
    },
    {
      id: "PDM5T5",
      label: TabLabels.REJECTED_VEHICLE,
      Component: RejectedVehicle
    },
  ];

  const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
    return tabs.filter(tab => tabIds.includes(tab.id));
  };

  let tabsToShow = getTabs(tabs);

  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>

  )
}
export default Inventory