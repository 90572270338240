import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisH
} from "@fortawesome/free-solid-svg-icons";
import {
    faEllipsisV,
    faTable,
  } from "@fortawesome/free-solid-svg-icons";
  import { BsTable } from 'react-icons/bs';
  import { FaExpandAlt } from 'react-icons/fa';
  import Vector2 from '../../QcDashboard/assets/Vector2.svg'

const InventoryView = ({ closeModal }) => {
    const [openSpecs, setOpenSpecs] = useState();
    const [status, setStatus] = useState("");
    const [actionShow, setActionShow] = useState();
    const [open, setOpen] = useState();


    function on() {
        document.getElementById("overlay").style.display = "block";
      };
    
      function off() {
        document.getElementById("overlay").style.display = "none";
        setOpen(false);
      }
    
      const actionHandler = (rowIndex) => {
        setOpen(true);
        setActionShow(rowIndex);
        on();
      };

      const handleStatusChange = (e,id) => {
          rowData.components.map((item) => {
            if(item.componentID == id){
                setStatus(e.target.value)
            }
          }) 
      };

    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "500px", height: "400px", padding: '15px' }}
            >
                  <div className='header' style={{display:"flex", justifyContent:"space-between"}}>
           <div ><h5 style={{fontSize:"18px"}}>Inventory</h5></div>
            <div style={{display:"flex",justifyContent:"center",width:"40%"}}>
              <select style={{height:"25px",borderRadius:"5px",background:"#fff",outline:"none",margin:" 0px 10px"}}>
                <option selected value="Month">Month</option>
                <option value="week">Week</option>
                <option value="Day">Day</option>
              </select>
              <div onClick={()=>{closeModal(false)}} style={{display:"flex",justifyContent:"center",alignItems:"center",color:"#808080",cursor:"pointer", width:"25px",height:"25px", border:"1px Solid #808080", borderRadius:"5px",margin:"0px 2px"}}>
              <img src={Vector2} alt="table"/>
              </div>
              <button className="action-btn" style={{height:"25px", color:"#808080",margin:" 0px 0px 0px 10px"}}>
              <FontAwesomeIcon className='fa-lg' icon={faEllipsisV} />
              </button>
            </div>
       
         </div>

                <div
                    className="body"
                    style={{ display: "flex", height: '500px', fontSize: "14px", overflow: 'scroll',marginTop:'20px' }}
                >
                    <section>
                 
                        <div id='POVendorHeading'>
                            <div style={{ width: '10%',height:'40px'}}>S.No.</div>
                            <div style={{ width: '30%',height:'40px'}}>Inventory Category</div>
                            <div style={{ width: '30%',height:'40px'}}>Total Numbers</div>
                            <div style={{ width: '30%',height:'40px'}}>Total Value</div>
                          
                        </div>
                           <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px',overflow:"auto" }}>
                                    <div style={{ width: '10%', overflowY: "auto",height:'30px' }}>{}</div>
                                    <div style={{ width: '30%', overflowY: "auto",height:'30px'   }}>{}</div>
                                    <div style={{ width: '30%', overflowY: "auto",height:'30px'   }}>{}</div>
                                    <div style={{ width: '30%', overflowY: "auto",height:'30px'   }}>{}</div>
                                   
                    </div>
                                
                        
                    </section>

                </div>

               
            </div>
        </div>
    );
};

export default InventoryView;