import React, { useState, useEffect } from "react";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CompPartialRelease from "./compPartialRelease";

const Reason = ({ finalData, releaseType, editData, closeModal, updatedData }) => {
  const [open, setOpen] = useState(false)
  const [formValue, setFormValue] = useState({
    partialReason: "",
    description: "",
  });

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Partial Reason</h3>
        </div>

        <div className="body">
          <label style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "150px" }}>Partial Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="partialReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', marginTop: '-5px' }}
            >
              <option disabled selected hidden>
                Partial Reason
              </option>
              <option value="One Component Quantity is short">One Component Quantity is short</option>
              <option value="Two or more Component Quantity is short">Two or more Component Quantity is short</option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.deleteReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Partial Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={() => setOpen(true)}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
          {open && <CompPartialRelease updatedData={updatedData} finalData={finalData} formValue={formValue} releaseType={releaseType} editData={editData} closeModal={closeModal} />}
        </div>
      </div>
    </div>
  );
};

export default Reason;



