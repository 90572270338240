import React, { useState, useEffect } from "react";
// import { trackPromise } from "react-promise-tracker";
// import API from "../../utils/api";
import '../../../components/modal/modal.scss';
// import { successNotification,errorNotification } from "../../utils/notifications";
// import { IoMdRefresh } from "react-icons/io";
// import auth from "../../utils/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const EditColorQty = ({ updatedData, editData, closeModal }) => {
    const [blankForm, setBlankForm] = useState([
        {
            "color": "red",
            "qty": "2"
        },
        {
            "color": "white",
            "qty": "27"
        },
        {
            "color": "yellow",
            "qty": "4"
        },
        {
            "color": "black",
            "qty": "2"
        },
        {
            "color": "pink",
            "qty": "20"
        }
    ]);

    let obj = { "color": "", "qty": "" };
    var BlankForm = [...blankForm, obj];

    const [updatedBlankForm, setupdatedBlankForm] = useState(BlankForm);

    let addFormFields = () => {
        setupdatedBlankForm([
            ...updatedBlankForm,
            {
                "color": "",
                "qty": ""
            }
        ]);
    };

    const removeFields = index => {
        const field = [...updatedBlankForm];
        field.splice(index, 1);
        setupdatedBlankForm(field);
    };

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        const field = [...updatedBlankForm];
        field[index][name] = value;
        setupdatedBlankForm(field);
    };


    const refresh = () => {
        updatedData();
    };

    const submitHandler = async () => {
        console.log(updatedBlankForm);
        // const resp = await trackPromise(API.put(`admin-module/api/v1/users`, editDetails));

        // const { success,message} = resp;

        // if (success) {
        //     closeModal(false);
        //     successNotification(message, { autoClose: 3000 });
        //     refresh();
        // } else {
        //     errorNotification(message, { autoclose: 2000 });
        // }
    };

    let uniqueColorArray = [...new Map(blankForm.map((item) => [item["color"], item])).values()];

    return (
        <div className="container1">
            <div className="modalContainer" style={{ width: '600px' }}>
                <div className="header">
                    <h3>Enter Color & Qty</h3>
                </div>

                <div className="body">
                    {updatedBlankForm.map((item, index) => (
                        <>
                            <select
                                name="color"
                                onChange={(event) => handleInputChange(event, index)}
                                defaultValue={item.color}
                            >
                                {uniqueColorArray.map((item) => (
                                    <option>{item.color}</option>
                                ))}
                            </select>
                            <input
                                type="text"
                                placeholder="Enter Qty."
                                name="qty"
                                defaultValue={item.qty}
                                onChange={(event) => handleInputChange(event, index)}
                            />
                            {index === updatedBlankForm.length - 1 ?
                                <button
                                    type="button"
                                    className="btn btn-md"
                                    onClick={() => addFormFields()}
                                >
                                    <div style={{ color: "green" }}><FontAwesomeIcon icon={faPlus} /></div>
                                </button>
                                :
                                <button
                                    type="button"
                                    className="btn btn-md"
                                    onClick={() => removeFields(index)}
                                >
                                    <div style={{ color: "red" }}><FontAwesomeIcon icon={faTrashAlt} /></div>
                                </button>
                            }
                        </>

                    ))}

                </div>
                <div className="footer">
                    <button onClick={() => closeModal(false)}>Cancel</button>
                    <button id="submit-button" onClick={submitHandler}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditColorQty;
