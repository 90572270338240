import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { errorNotification, successNotification } from "../../../utils/notifications";
import "../../settings-tabs/homesettings.scss";
import "../../user/users.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckCircle } from "@material-ui/icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ManageFeaturesTable from "./ManageFeaturesTable";
import { useForm } from "react-hook-form";

const AddFeatures = () => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [selectedOption, setSelectedOption] = useState("Monthly");
  const [blankForm, setBlankForm] = useState({
    name: "",
    time: selectedOption,
  });

  console.log("blankForm-->", blankForm);

   // Corrected handleOptionChange to ensure it works properly
   const handleOptionChange = (event) => {
    const selectedValue = event.target.value;  // Extract the value from event
    console.log("Selected Option:", selectedValue);
    setSelectedOption(selectedValue); // Update selected option
    setBlankForm((prevState) => ({
      ...prevState,
      time: selectedValue, // Update the time in form state
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBlankForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateInputs = () => {
    if (!blankForm?.name) {
      errorNotification("Feature name is required.");
      return false;
    }
    return true;
  };

  const submitHandler = async () => {
    if (!validateInputs()) return;

    const result = await trackPromise(API.post(`systemadmin/api/v1/features`, blankForm));
    if (result?.success) {
      successNotification(result?.message, { autoClose: 3000 });
      setBlankForm({ name: "", time: selectedOption }); // Reset form
    } else {
      errorNotification(result?.message, { autoClose: 3000 });
    }
  };

  const cancel = () => {
    setBlankForm({ name: "", time: selectedOption });
  };

  return (
    <>
      <div className="bussiness">
        <h4 className="heading">Add Subscription Features</h4>
      </div>

      <div style={{ display: "flex", flexDirection: "row", marginTop: "15px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="radio"
            value="Monthly"
            checked={selectedOption === "Monthly"}
            onChange={handleOptionChange} 
          />
          <span style={{ width: "90px", padding: "6px" }}>Monthly</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="radio"
            value="Yearly"
            checked={selectedOption === "Yearly"}
            onChange={handleOptionChange}
          />
          <span style={{ width: "90px", padding: "6px" }}>Yearly</span>
        </div>
      </div>

      <form className="setting-form" onSubmit={handleSubmit(submitHandler)} style={{ marginTop: "15px", marginBottom: "60px" }}>
        <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          <div style={{ margin: "0 5px", width: "30%", display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "13px", fontWeight: "500", marginLeft: "6px" }}>
              Add Features <span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="input-config"
              type="text"
              name="name"
              value={blankForm?.name || ""}  // Optional chaining for safe access
              onChange={handleInputChange}
              placeholder={`Enter ${selectedOption} Features`}
              style={{ marginTop: "-8px", width: "100%" }}
            />
          </div>
        </div>
        <br />
        <div className="save-cancel">
          <div className="cust-reset cancel-button">
            <button type="button" className="btn btn-icon-label" onClick={cancel}>
              <div style={{ fontSize: "14px", color: "#0A18A3", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faTimes} />
                Cancel
              </div>
            </button>
          </div>
          <div className="cancel-button">
            <button className="btn btn-icon-label" type="submit">
              <div style={{ fontSize: "14px", color: "#0A18A3", fontWeight: "500" }}>
                <CheckCircle style={{ fontSize: "17px" }} />
                Submit
              </div>
            </button>
          </div>
        </div>
      </form>

      {/* <ManageFeaturesTable /> */}
    </>
  );
};

export default AddFeatures;
