import React, { useState } from "react";
import auth from "../../../utils/auth";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification,errorNotification } from "../../../utils/notifications";

const DiscontinueComponent = ({ updatedData,editData,closeModal , setIsOpen }) => {
  console.log(editData.componentID);
  const [formValue, setFormValue] = useState({
    discontinueReason: "",
    description: "",
  });

  const editDetails  = {
    "componentID": editData.componentID,
    "reason": formValue.discontinueReason,
    "reasonComment": formValue.description
    };

    const refresh = () => {
      updatedData()
  };

  const submitHandler = async () => {
    const resp = await trackPromise(API.post(`procurement-module/api/v1/components/discontinue`, editDetails));
    
    const { success,message} = resp;
    
    if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        refresh()
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
  };


  const closeHandler = ()=>{
    setIsOpen(false)
    closeModal(false)
  }



  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Deactivate Component</h3>
        </div>

        <div className="body">
        <label style={{display:'flex', flexDirection:'column'}}>
          <label style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign:"start"
                  }}>Deactivate Reason<span style={{color:"red"}}>*</span></label>
            <select
              name="discontinueReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', outline: 'none', marginTop:'-8px'  }}
            >
              <option disabled selected hidden>
              Deactivate Reason
              </option>
              <option value="No Longer needed">
              No Longer needed
              </option>
              <option value="In-compatiable specifications">
              In-compatiable specifications
              </option>
              <option value="Technical issues">
                Technical issues
              </option>
              <option value="Others">Others</option>
            </select>
          </label>

          {formValue.discontinueReason === "Others" ? 
          <label className="lgInput">
            Description
            <input
              type="text"
              style={{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Deactivate Reason"
              autoComplete="off"
            />
          </label> : ""}

        </div>
        <div className="footer">
          <button onClick={closeHandler}>Cancel</button>
          <button id="submit-button" onClick={formValue.discontinueReason==""?"" : submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DiscontinueComponent;
