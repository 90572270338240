import { KeyboardBackspace } from "@material-ui/icons";
import React from "react";
import auth from "../../../utils/auth";

const ViewLaborCost = ({ closeModal, row }) => {


  const calculateDailyCost = (serviceCost, timeUnit, requiredLabors, requiredWorkers) => {
    switch (timeUnit) {
      case "Week":
        return serviceCost * 7 * Number(requiredLabors)  *requiredWorkers;  // Convert weekly cost to daily cost
      case "Month":
        return serviceCost * 30 *  Number(requiredLabors) *requiredWorkers; // Convert monthly cost to daily cost
      case "Year":
        return serviceCost * 365 * Number(requiredLabors) *requiredWorkers ; // Convert yearly cost to daily cost
      default:
        return serviceCost * Number(requiredLabors) *requiredWorkers; // If "Day", return the cost as is
    }
  };

  const calculateTotalPrice = ()=>{
    let total = 0
    UsersData?.map((item)=>{
      console.log(item.avgLaborCost), item?.laborDuration,item.requiredDuration,Number(item?.requiredWorkers)
      total+= calculateDailyCost((item.avgLaborCost), item?.laborDuration,item.requiredDuration,Number(item?.requiredWorkers) )
      
    })
    return total
  }
  
  const UsersData = row.bomLabor;

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "80%", height: "442px", padding: "15px" }}
      >
        <div className="header">
          <h3>BOM Labor Details</h3>
        </div>

        <br />
        <div className="body">

          <div style={{width: "100%",}}>
            <div  id="costingTableHeading" style={{display:'flex', justifyContent:'space-between'}}>
              <div>Labor</div>
              <div>Labor Level</div>
              <div>Required Unit</div>
              <div>Required Count</div>
              <div>{`Cost(${auth.getUserInfo().currencySymbol})`}</div>
              <div>{`Total Cost (${auth.getUserInfo().currencySymbol})`}</div>
            </div>
            <div style={{height:'230px', overflowY: "scroll" }}>
              {UsersData.map((item) => (
                <div id="costingTableContent" style={{display:'flex', justifyContent:'space-between'}}>
                  <div>{item.laborCategory}</div>
                  <div>{item.laborLevel}</div>
                  <div>{`${item.requiredDuration} ${item.laborDuration}`}</div>
                  <div>{item.requiredWorkers}</div>
                  <div>{`${item.avgLaborCost} per Day`}</div>
                  <div> {
                              calculateDailyCost((item.avgLaborCost), item?.laborDuration,item.requiredDuration,Number(item?.requiredWorkers) )
                            }</div>
                </div>
              ))}
              <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "8px" }}>{`Total Cost (${auth.getUserInfo().currencySymbol})`}</span>
            <span
              style={{
                padding: "7px",
                width: "141px",
                backgroundColor: "#e5e5e5",
              }}
            >
              {calculateTotalPrice()}
              {/* {row.totalLaborCost} */}
            </span>
          </div>
            </div>
          </div>

  
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}><KeyboardBackspace /> Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewLaborCost;
