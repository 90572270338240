import React, { useState } from "react";
import ShowMappedCities from "../../zoneManagement/ShowMappedCities";
import { KeyboardBackspace } from "@material-ui/icons";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import { Table } from "@mui/material";

const SalesUserViewMappings = ({ blankForm, close }) => {
  // let showMappedCities = (item, index, el) => {
  //   setShowCities(true);
  //   setCitiesData(el);
  //   setRowData(item);
  // };

  // let Data = countryData?.filter((item) => item.countryName == blankForm.countryName && item.zone == blankForm.zone)?.map((item) => item.areas?.filter((area) => area.area === blankForm.area)).flat();
  // console.log(Data);

  // let zoneData = countryData
  //     ?.filter(item => item.countryName === blankForm.countryName)
  //     ?.filter(obj1 =>
  //         blankForm.zones?.some(obj2 => obj2.zoneId === obj1.zoneId)
  //     );

  // console.log(zoneData);

  console.log(blankForm.cities)

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "500px", height: "450px", justifyContent: "space-between" }}
      >
        <div className="header">
          <h3>Mappings</h3>
        </div>

        <div className="body">
          <table style={{ width: "100%", border: "1px solid grey" }}>
            <tr style={{ backgroundColor: "#E5E5E5" }}>
              <th style={{ width: "16%" }}>S.No</th>
              <th>Mapped Cities</th>
              <th> Mapped Sales Manager</th>
            </tr>
            {blankForm?.cities.map((el, index) => {
              console.log(el)
              return (
                <tr style={{ border: "1px solid grey" }}>
                  <td style={{ paddingLeft: "27px" }}>{index + 1}.</td>
                  <td
                    style={{
                      paddingLeft: "12px",
                    }}
                  >
                    {el?.City}
                  </td>
                  <td style={{ color: "#0A18A3" }}>
                    {/* {el.asmUserID == null ? el.zsmFullName : el.zsmUserId == null ? el.nsmFullName : el.asmFullName} */}
                    {el.asmFullName || "-"}
                  </td>
                </tr>
              );
            })}
          </table>

        </div>
        <div className="footer">
          <button onClick={() => close(false)}>
            <KeyboardBackspace /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default SalesUserViewMappings;
