import React, { useState, useEffect, useRef } from "react";
// import DatePicker from "react-datepicker";
import "../QcDashboard/styles/main.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faTable,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { FaRedoAlt } from "react-icons/fa";
import { FaExpandAlt } from "react-icons/fa";
import WorkOrderDashboard from "./WorkOrderDashboard";
import ShopFloorInventoryDash from "./ShopFloorInventoryDash";
import ShopFloorAddOnBarChart from "./ShopFloorAddOnBarChart";
import { useHistory } from "react-router-dom";
import { DatePicker, Space } from "antd";
import { BsArrowUpRight } from "react-icons/bs";
import ManageAddonSF from "../inventory-shopfloor/manageaddon-sf";

const ShopFloorDashboard = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [addOnStartDate, setAddOnStartDate] = useState(null);
  const [addOnEndDate, setAddOnEndDate] = useState(null);
  const { RangePicker } = DatePicker;
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [openAddonTable, setOpenAddonTable] = useState(false);
  const history = useHistory();

  function on() {
    document.getElementById("overlay").style.display = "block";
  };

  function off() {
    setCalenderOpen(false);
    document.getElementById("overlay").style.display = "none";
    refresh();
  }

  const onAddOnChange = (dates) => {
    const [start, end] = dates;
    setAddOnStartDate(start);
    setAddOnEndDate(end);
  };
  const refresh = () => {
    setAddOnStartDate(null);
    setAddOnEndDate(null);
    setStartDate(null);
    setEndDate(null);
    document.getElementById("calender").style.display = "none";
    document.getElementById("calendericon").style.display = "block";
    document.getElementById("calendericon").style.justifyContent = "center";
    document.getElementById("calendericon").style.textAlign = "center";
  }

  const show = () => {
    document.getElementById("calender").style.display = "block";
    document.getElementById("calendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }
  const routeLink = () => {
    <a href="/app/wo-shopfloor"></a>
  };

  return (
    <>
      {openAddonTable == true ? <ManageAddonSF inDashboard={setOpenAddonTable} /> :
        <>
          <div id="overlay" onClick={(e) => off(e)}></div><div className="dashboardBody">
            <div className="firstRow">
              <div
                className="container-new"
                style={{ width: "100%", height: "235px" }}
              >
                <div
                  className="header"
                  style={{ marginTop: "10px", marginLeft: "10px", width: "98%" }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Work Orders</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "35%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight onClick={() => history.push("/app/wo-shopfloor")} />
                    </div>
                  </div>
                </div>
                <div>
                  <WorkOrderDashboard soStartDate={startDate} soEndDate={endDate} />
                </div>
              </div>
            </div>

            <div className="secondRow">
              <div
                className="container-new"
                style={{ width: "35%", height: "293px" }}
              >
                <div
                  className="header"
                  style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Inventory</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "55%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight onClick={() => history.push("/app/inventory-shopfloor")} />
                    </div>
                  </div>
                </div>
                <div>
                  <ShopFloorInventoryDash />
                </div>
              </div>
              <div
                className="container-new"
                style={{
                  marginLeft: "20px",
                  height: "95%",
                  width: "65%",
                  height: "293px",
                }}
              >
                <div
                  className="header"
                  style={{ marginTop: "10px", marginLeft: "10px", width: "97%" }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Add On Requests</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "60%",
                    }}
                  >
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        marginRight: "5px"
                      }}
                      onClick={refresh}
                    >
                      <FaRedoAlt />
                    </div>

                    <span id="calendericon" style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }} onClick={show}>
                      <FontAwesomeIcon
                        icon={faCalendar}
                        style={{ color: "#808080" }} />
                    </span>
                    <label id="calender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                      <Space style={{ width: "235px" }} direction="vertical" size={10}>
                        <RangePicker
                          startDate={addOnStartDate}
                          endDate={addOnEndDate}
                          value={[addOnStartDate,addOnEndDate]}
                          onChange={onAddOnChange}
                          format="DD-MM-YYYY"
                          size="small" />
                      </Space>
                    </label> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight onClick={() => setOpenAddonTable(true)} />
                    </div>
                  </div>
                </div>
                <div>
                  <ShopFloorAddOnBarChart props="shopfloor" startDate={addOnStartDate} endDate={addOnEndDate} />
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default ShopFloorDashboard;
