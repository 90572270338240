import React, { useState } from "react";
import {
    KeyboardBackspace,
    CheckCircle,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import VR_MR_Action from "./VR_MR_Action";
import GINno from "./GINno";
import RPIno from "./RPIno";
import moment from "moment";

const VR_MR_Details = ({ closeModal, rowData }) => {
    const [actionShow, setActionShow] = useState();
    const [showGIN, setShowGIN] = useState(false);
    const [showRPI, setShowRPI] = useState(false);
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "1180px", height: "425px", padding: '15px' }}
            >
                <div className="header">
                    <h3>View and Update</h3>
                </div>

                <div
                    className="body"
                    style={{ display: "flex", fontSize: "14px", overflow: 'scroll' }}
                >

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested ID: {rowData.requestID}</span>
                            <div>RPI No. : <span>{rowData.rpiNo}</span>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested By: {rowData.showroomEmail}</span>
                            <div>GIN No. : <span>{rowData.ginNo}</span>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested Date: {moment(rowData.requestDate).format("DD-MMM-YYYY/HH:mm")}</span>
                            <span>Return Qty. : {rowData.returnQty}</span>
                        </div>
                    </section>

                    <section style={{ width: '100%' }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '50px' }}>S.No.</div>
                            <div style={{ width: '90px' }}>Segment</div>
                            <div style={{ width: '80px' }}>Category</div>
                            <div style={{ width: '70px' }}>Model</div>
                            <div style={{ width: '70px' }}>Variant</div>
                            <div style={{ width: '70px' }}>Type</div>
                            <div style={{ width: '70px' }}>Color</div>
                            <div style={{ width: '150px' }}>VIN</div>
                            <div style={{ width: '100px' }}>BIN</div>
                            {/* <div style={{ width: '120px' }}>Verification Status</div> */}
                            <div style={{ width: '200px' }}>Failed Reason</div>
                            <div style={{ width: '200px' }}>Status</div>
                            {/* <div style={{ width: '50px' }}>Action</div> */}
                        </div>
                        <div>
                            {
                                rowData.returnedData.map((item, index) => (<div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }} key={index}>
                                    <div style={{ width: '50px' }}>{index + 1}</div>
                                    <div style={{ width: '90px' }}>{item.vehicleSegment}</div>
                                    <div style={{ width: '80px' }}>{item.vehicleCategory}</div>
                                    <div style={{ width: '70px' }}>{item.vehicleModel}</div>
                                    <div style={{ width: '70px' }}>{item.vehicleVariant}</div>
                                    <div style={{ width: '70px' }}>{item.vehicleType}</div>
                                    <div style={{ width: '70px' }}>{item.vehicleColor}</div>
                                    <div style={{ width: '150px' }}>{item.VIN}</div>
                                    <div style={{ width: '100px' }}>{item.BIN}</div>
                                    {/* <div style={{ width: '120px' }}>{item.verificationStatus}</div> */}
                                    <div style={{ width: '200px' }}>{item.returnReason}</div>
                                    <div style={{ width: '200px', padding: "4px" }}>
                                        {item.lastAction == "Added to Finished Goods" ?
                                            <button className="active-btn" style={{ height: "22px" }}> <span className="active-btn-font" style={{ fontSize: "13px" }}>Added to Finished Goods</span></button> :
                                            item.lastAction == "Send to Production" ?
                                                <button className="delete-btn" style={{ height: "22px" }}> <span className="delete-btn-font" style={{ fontSize: "13px" }}>Send to Production</span></button> :
                                                item.lastAction}
                                    </div>
                                    {/* <div style={{ width: '50px' }}>
                                        <button className="action-btn" onClick={() => setActionShow(index)}>
                                            <FontAwesomeIcon id="outside" icon={faEllipsisH} />
                                        </button>
                                        {(actionShow === index) && <VR_MR_Action row={rowData} />}
                                    </div> */}
                                </div>))
                            }
                        </div>
                    </section>

                </div>

                <div className="footer">
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                    {/* <button style={{ marginRight: '0', width: '120px', height: '42px' }} id="submit-button"><CheckCircle style={{ fontSize: "17px" }} />Proceed</button> */}
                </div>
            </div>
        </div>
    );
};

export default VR_MR_Details;