import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import SearchBox from "../../../components/search/SearchBox";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppConstants from "../../../utils/app-constants";
import ReturnActions from "./ReturnActions";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ReturnNote = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState();
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState();
  const [actionShow, setActionShow] = useState();
  const [UsersData, setUsersData] = useState([]);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`finance/api/v1/returns/returnnotes`)
    );
    const filteredData = resp.filter((item) => {
      return (
        item.status == "New Request" ||
        item.status == "Pending at Finance" ||
        item.status == "On Hold" ||
        item.status == "Return Note Generated"
      );
    });
    setUsersData(filteredData);
  };
  useEffect(() => {
    fetchReport();
  }, []);



  // useEffect(() => {
  //   setUsersData([
  //     {
  //       status: "Return Note Generated",
  //       requestType: "Vehicle Return",
  //       requestCategory: "Vehicle Returns",
  //       returnedData: [{ vehicleSegment: "standard" }],
  //       dealerID: 23443,
  //     },
  //     {
  //       status: "New Request",
  //       requestType: "Spare Return",
  //       requestCategory: "Spare Returns",
  //       returnedData: [{ vehicleSegment: "standard" }],
  //       dealerID: 78685,
  //     },
  //     {
  //       status: "New Request",
  //       requestType: "Vehicle Return",
  //       requestCategory: "Vehicle Returns",
  //       returnedData: [{ vehicleSegment: "standard" }],
  //       dealerID: 23443,
  //     },
  //     {
  //       status: "Return Note Generated",
  //       requestType: "Spare Return",
  //       requestCategory: "Spare Returns",
  //       returnedData: [{ vehicleSegment: "standard" }],
  //       dealerID: 78685,
  //     },
  //   ]);
  // }, []);




  const uniquerequestID = [...new Set(UsersData.map((item) => item.requestID))];
  const uniquerequestCategory = [
    ...new Set(UsersData.map((item) => item.requestCategory)),
  ];
  const uniquerequestedBy = [
    ...new Set(UsersData.map((item) => item.requestedBy)),
  ];
  const uniquerequestType = [
    ...new Set(UsersData.map((item) => item.requestType)),
  ];
  const uniquestatus = [...new Set(UsersData.map((item) => item.status))];

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY / HH:mm")}`;
  }

  const statusFormatter = (cell) => {
    return cell === AppConstants.NEWREQUEST || cell == "Pending at Finance" ? (
      <button className="reactive-btn">
        <span className="reactive-btn-font">New Request</span>
      </button>
    ) : cell === AppConstants.ONHOLD ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">On Hold</span>
      </button>
    ) : cell === AppConstants.RETURN_NOTE_GENERATED ? (
      <button className="active-btn">
        {" "}
        <span className="active-btn-font">Return Note Generated</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && open ? (
          <ReturnActions rowData={row} updatedData={fetchReport} rowIndex={rowIndex}/>
        ) : null}
      </div>
    );
  };

  const [formValue, setFormValue] = useState({
    requestID: "",
    requestedBy: "",
    requestDate: "",
    requestCategory: "",
    requestType: "",
    GRNNo: "",
    GINNo: "",
    Status: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = (e) => {
    setFormValue({
      requestID: "",
      requestBy: "",
      requestDate: "",
      requestCategory: "",
      requestType: "",
      GRNno: "",
      GINNo: "",
      Status: "",
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Request Type",
      dataField: "requestCategory",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request ID",
      dataField: "requestID",
      classes: "cellsOverflow",
      headerAttrs: { width: "160px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request By",
      dataField: "requestedBy",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request Date",
      dataField: "requestDate",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Create Return Note</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "manageUsers")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    onSubmit={submitHandler}
                    className="VMform"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      height: "135px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                    }}
                  >
                    <div className="UHinputfields" style={{ padding: "0px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Category
                        </label>
                        <select
                          name="requestCategory"
                          value={formValue.requestCategory}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Request Category
                          </option>
                          {uniquerequestCategory.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request ID
                        </label>
                        <select
                          name="requestID"
                          value={formValue.requestID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Request ID
                          </option>
                          {uniquerequestID.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Requested By
                        </label>
                        <select
                          name="requestedBy"
                          value={formValue.requestedBy}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Requested By
                          </option>
                          {uniquerequestedBy.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Date
                        </label>
                        <input
                          type="date"
                          name="requestDate"
                          className="create-grn-date"
                          selected={formValue.requestDate}
                          onChange={(date) =>
                            setFormValue({ ...formValue, requestDate: date })
                          }
                          isClearable
                          placeholderText="Request Date"
                          dateFormat="DD-MM-YYYY/HH:mm"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Type
                        </label>
                        <select
                          name="requestType"
                          value={formValue.requestType}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Request Type
                          </option>
                          {uniquerequestType.map((item) => (
                            <option>{item}</option>
                          ))}
                          {/* {formValue.requestCategory === "PO Components" && (
                            <option> Verification </option>
                          )}
                          {formValue.requestCategory === "Spare Returns" && (
                            <option> Re-Verification </option>
                          )} */}
                        </select>
                      </div>
                    </div>
                    <div className="UHinputfields" style={{ padding: "0px" }}>
                      {formValue.requestCategory === "PO Components" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            GRN No.
                          </label>
                          <select
                            name="GRNNo"
                            value={formValue.GRNNo}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected hidden>
                              GRN No.
                            </option>
                            {filterGRNData.map((item, i) => {
                              return (
                                <option key={i} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}

                      {formValue.requestCategory === "Spare Returns" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            GIN No.
                          </label>
                          <select
                            name="GINNo"
                            value={formValue.GINNo}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected hidden>
                              GIN No.
                            </option>
                            {filterGINData.map((item, i) => {
                              return (
                                <option key={i} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Status
                          </option>
                          {uniquestatus.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          width: "21%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <button
                          // onClick={fetchReport}
                          style={{ width: "100px", marginTop: "21px" }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{ width: "100px", marginTop: "21px" }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ReturnNote;
