import React, { useState } from "react";
import auth from "../../../utils/auth";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";

const ReactivateComponent = ({ updatedData, editData, closeModal , setIsOpen}) => {
  const [formValue, setFormValue] = useState({
    reactivationReason: "",
    description: "",
  });

  const editDetails = {
    componentID: editData.componentID,
    reason: formValue.reactivationReason,
    reasonComment: formValue.description,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(`procurement-module/api/v1/components/reactivate`, editDetails)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };



  
const closeHandler = ()=>{
  setIsOpen(false)
  closeModal(false)
}





  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Reactivate Component</h3>
        </div>

        <div className="body">
          <label style={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                textAlign: "start",
              }}
            >
              Reactivation Reason <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="reactivationReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
            >
              <option disabled selected hidden>
                Reactivation Reason
              </option>
              <option value="Required for production">Required for production</option>
              <option value="Specifications improved">
                Specifications improved
              </option>
              <option value="Technically Modified">
                Technically Modified
              </option>
              <option value="Others">Others</option>
            </select>
          </label>

          {formValue.reactivationReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Reactivation Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={closeHandler}>Cancel</button>
          <button id="submit-button" 
          onClick={  formValue.reactivationReason==""?"":submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReactivateComponent;
