import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";

const EditVendor = ({ updatedData, editData, closeModal, setIsOpen}) => {
  const [formValue, setFormValue] = useState({
    vendorId: editData.vendorID,
    vendorName: editData.vendorName,
    vendorEmail: editData.vendorEmail,
    businessContactNo: editData.businessContactNo,
    gstInNo: editData.gstin,
    gstInDoc: "",
  });

  const editDetails = {
    vendorID: editData.vendorID,
    vendorName: formValue.vendorName,
    vendorEmail: formValue.vendorEmail,
    businessContactNo: formValue.businessContactNo,
    gstin: formValue.gstInNo,
  };


  const validateEmail = (emailId) => {
    const mailformat =
      /^([A-Za-z0-9_\-\.]+)@([A-Za-z0-9_\-\.]+\.[A-Za-z]{2,4})$/;
    return emailId && typeof emailId === 'string' ? mailformat.test(emailId) : false;
  };

  const validateGSTIN = (gstin) => {
    // Example GSTIN validation pattern (adjust according to specific format requirements)
    const gstinPattern = /^[0-9A-Z]{15}$/;
    return gstin && typeof gstin === 'string' ? gstinPattern.test(gstin) : false;
  };

  const validateContactNumber = (contactNo) => {
    // Example phone number validation pattern (adjust according to specific format requirements)
    const contactPattern = /^[0-9]{10}$/;
    return contactNo && typeof contactNo === 'string' ? contactPattern.test(contactNo) : false;
  };




  const validateInputs = () => {
    const isEmailValid = validateEmail(formValue.vendorEmail);
    //const isGSTINValid = validateGSTIN(formValue.gstInNo);
    const isContactNumberValid = validateContactNumber(formValue.businessContactNo);

    if (!isEmailValid) {
      errorNotification("Invalid email address.");
      return false;
    }

    if (/^[0-9]+$/.test(formValue.vendorName)) {
      errorNotification("Vendor Name cannot be numeric only.");
      return false;
    }

    // if (!isGSTINValid) {
    //   errorNotification("Invalid GSTIN format.");
    //   return false;
    // }

    if (!isContactNumberValid) {
      errorNotification("Invalid contact number format.");
      return false;
    }

    return true;
  };


  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    if (!validateInputs()) {
      return;
    }
    const resp = await trackPromise(
      API.put(`procurement-module/api/v1/vendors/`, editDetails)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
      setIsOpen(false)
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "810px" }}>
        <div className="header">
          <h3>Edit Vendors Details</h3>
        </div>

        <div className="body">
          <div style={{ display: "flex", flexWrap: "wrap", margin: "12px", justifyContent:'start' }}>
            <div
              style={{
                width: "20%",
                display: "flex",
                flexDirection: "column",
                
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-83px",
                }}
              >
                Vendor ID<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="vendorId"
                defaultValue={editData.vendorID}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Vendor ID"
                autoComplete="off"
                disabled
                style={{marginTop:'-5px', width:'auto'}}
              />
            </div>

            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "35%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-172px",
                }}
              >
                Vendor Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="vendorName"
                defaultValue={editData.vendorName}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Vendor Business Name"
                style={{marginTop:'-5px', width:'auto'}}
              />
            </div>

            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "35%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-172px",
                }}
              >
                Vendor Email<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="vendorEmail"
                defaultValue={editData.vendorEmail}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Vendor Business Email"
                autoComplete="off"
                style={{marginTop:'-5px', width:'auto'}}
              />
            </div>

            <div
              style={{
               
                width: "20%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-105px",

                }}
              >
                GSTIN<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="gstInDoc"
                defaultValue={editData.gstDoc}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="GSTIN / Tax Document"
                autoComplete="off"
                style={{marginTop:'-5px', width:'auto'}}
              />
            </div>
            
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "35%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-160px",
                }}
              >
                Contact Number<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="businessContactNo"
                defaultValue={editData.businessContactNo}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Business Contact No."
                autoComplete="off"
                style={{marginTop:'-5px', width:'auto'}}
              />
            </div>

            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "35%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-190px",
                }}
              >
                GSTIN No.<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="gstInNo"
                defaultValue={editData.gstin}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="GSTIN / Tax Identification no."
                autoComplete="off"
                style={{marginTop:'-5px', width:'auto'}}
              />
            </div>

          
          </div>
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditVendor;
