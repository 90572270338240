import { ContactsOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
// import "../../work-order/workorder.scss";

const ViewDetails = ({ close, rowData }) => {

    return (
        <>
            <div className="container1">
                <div
                    className="modalContainer"
                    style={{ width: "492px", height: "480px", padding: "15px" }}
                >
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>Spare Part Inventory Details</h3>
                    </div>
                    <br />
                    <section style={{ display: "flex", flexDirection: "column", width: "100" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <span> Spare Part Name: {rowData.sparePartname}</span>
                            <span>Category: {rowData.category}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <span> SKU Code: {rowData.skuCode}</span>
                            <span>Sub-Category: {rowData.subCategory}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <span></span>
                            <span>Available Qty.: {rowData.availableQty.length}</span>
                        </div>
                    </section>
                    <br />
                    <div className="body" style={{ height: "340px" }}>
                        <div style={{ width: "100%", overflow: "auto", height: "340px" }}>
                            <table className="viewcomp_tbl">
                                <thead>
                                    <tr>
                                        <th style={{ width: "20%" }}>S.No.</th>
                                        <th style={{ width: "90%" }}>Serial No.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rowData.availableQty.map((item, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td style={{ width: "20%" }}>{index + 1}</td>
                                                    <td style={{ width: "90%" }}>{item.partSno}</td>
                                                </tr>
                                            </>
                                        );
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <br />
                    <div className="footer">
                        <button onClick={() => close(false)}><KeyboardBackspace /> Back</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewDetails;