import { KeyboardBackspace } from '@material-ui/icons';
import { set } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react'
import AppConstants from '../../../utils/app-constants';
import "../../settings-tabs/homesettings.scss";
import "../../wo-shopfloor/shopfloor.scss";
import WoProductionQty from './wo-productionqty';
import WoViewAssembly from './wo-viewassembly';
import WoViewComponents from './wo-viewcomponents';
import WoViewLabours from './wo-viewlabours';
// import "../approval.scss"
// import ViewAssemblyBom from './viewassembly-bom';
// import ViewComponentsBom from './viewcomponents-bom';
// import ViewLabourBom from './viewlabour-bom';
// import "./newtable.scss"

const WoViewDetails = ({ closeModal, rowdata, productionQuantity }) => {

  console.log(rowdata)
  const [viewComp, setViewComp] = useState();
  const [viewLbr, setViewLbr] = useState();
  const [viewAssm, setViewAssm] = useState();
  const [viewProdQty, setViewProdQty] = useState(false);

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "1000px", height: "545px" }}>
        <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>View Details</h3>
          {/* <div>
            <button
              className="action-btn"
              style={{ color: "blue" }}>
              Print
            </button>
            <button
              className="action-btn"
              style={{ color: "blue" }}>
              Export
            </button>
          </div> */}
        </div>

        <div className="" style={{ height: "402px", overflow: "auto" }}>
          {viewComp && <WoViewComponents productionQuantity={productionQuantity} compdata={rowdata} close={setViewComp} />}
          {viewLbr && <WoViewLabours labordata={rowdata} close={setViewLbr} />}
          {viewAssm && <WoViewAssembly assemblydata={rowdata} close={setViewAssm} />}
          {viewProdQty && <WoProductionQty rowdata={rowdata} closeModal={setViewProdQty} />}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>WO ID  : {rowdata?.orderID}</span>
              <span>BOM ID : {rowdata?.BOMID}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>WO Type : {rowdata?.workType}</span>
              <span>BOM Name : {rowdata?.bomName}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>WO Timeline : {`${moment(rowdata?.startDate).format("DD-MM-YYYY, HH:mm")} to ${moment(rowdata?.endDate).format("DD-MM-YYYY, HH:mm")}`}</span>
              <span>BOM Location : {rowdata?.bomLocation}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Production Qty : <span 
              style={{ 
                // color: "blue", 
                // cursor: "pointer"
                 }} 
                //onClick={() => setViewProdQty(true)}
              >
              {parseInt(rowdata?.productionQuantity)}</span></span>
              <span>BOM Created By : {rowdata?.createdBy}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span></span>
              <span>BOM Creation Date : {`${moment(rowdata?.createdDate).format("DD-MM-YYYY, HH:mm")}`}</span>
            </div>
          </div>

          <br />
          <div>
            <h6>WO Vehicles Details</h6>
            <div className="shopfloortbl">
              <table className="sfaction_tbl" style={{ width: "100%" }} >
                <thead>
                  <tr>
                    <th>Segment</th>
                    <th>Category</th>
                    <th>Model</th>
                    <th>Variant</th>
                    <th>Type</th>
                  </tr>
                </thead>
                {/* <hr className='line'/> */}
                <tbody>
                  <tr>
                    <td>{rowdata?.vehicleSegment}</td>
                    <td>{rowdata?.vehicleCategory}</td>
                    <td>{rowdata?.vehicleModel}</td>
                    <td>{rowdata?.vehicleVariant}</td>
                    <td>{rowdata?.vehicleType}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />

            <h6>WO Required Items</h6>
            <div className="shopfloortbl">

              <table className="sfaction_tbl" style={{ width: "100%" }} >
                <thead>
                  <tr>
                    <th>Required Components</th>
                    <th>Required Labours</th>
                    <th>Required Assembly</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <button
                        onClick={() => setViewComp(true)}
                        className="action-btn"
                        style={{ color: "blue" }}>
                        View Components
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => setViewLbr(true)}
                        className="action-btn"
                        style={{ color: "blue" }}>
                        View Labours
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => setViewAssm(true)}
                        className="action-btn"
                        style={{ color: "blue" }}>
                        View Assembly
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}><KeyboardBackspace /> Back</button>
        </div>
      </div>
    </div>
  );
};

export default WoViewDetails;
