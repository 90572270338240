import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Action from "../../components/modal/Action";
import "../../components/modal/modal.scss";
import auth from "../../utils/auth";
import moment from "moment";
import "../../pages/vendors/Vendors.scss";
import AppConstants from "../../utils/app-constants";
import SearchBox from "../../components/search/SearchBox";
import ShowroomAction from "./actions/showroomAction";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const Showrooms = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState();
  const [formValue, setFormValue] = useState({
    showroomID: "",
    showroomName: "",
    email: "",
    contactPersonName: "",
    location: "",
    gstNo: "",
    status: "",
  });
  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `dealer-module/api/v1/businessusers/servicecenters?businessUserType=${"Showroom"}&businessID=${formValue.showroomID
        }&businessEmail=${formValue.email}&businessName=${formValue.showroomName
        }&contactPersonName=${formValue.contactPersonName}&gstin=${formValue.gstNo
        }&status=${formValue.status}&location=${formValue.location}`
      )
    );
    console.log(resp);
    setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);



  const uniquebusinessID = [...new Set(UsersData.map((item) => item.businessID))]
  const uniquebusinessEmail = [...new Set(UsersData.map((item) => item.businessEmail))]
  const uniquebusinessName = [...new Set(UsersData.map((item) => item.businessName))]
  const uniquecontactPersonName = [...new Set(UsersData.map((item) => item.contactPersonName))]
  const uniquelocation = [...new Set(UsersData.map((item) => item.location))]
  const uniquegstin = [...new Set(UsersData.map((item) => item.gstin))]
  const uniquestatus = [...new Set(UsersData.map((item) => item.status))]




  console.log(formValue);

  const statusFormatter = (cell, row, rowIndex) => {
    return cell === AppConstants.ACTIVE ? (
      <button className="active-btn">
        <span className="active-btn-font">Active</span>
      </button>
    ) : cell === AppConstants.DEACTIVATE || cell === "Deactivate" ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">Deactivated</span>
      </button>
    ) : cell === AppConstants.REACTIVATE || cell === "Reactivate" ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">Reactivated</span>
      </button>
    ) : cell === AppConstants.DELETE ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Deleted</span>
      </button>
    ) : (
      cell
    );
  };

  const locationFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>{`${row.location.address1} ${row.location.city} ${row.location.state}`}</span>
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.status === AppConstants.DELETED ? (
      ""
    ) : (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          <ShowroomAction
            updatedData={fetchReport}
            rowData={row}
            setIsOpen={setIsOpen}
            tabName={row.businessUserType}
            rowIndex={rowIndex}
          />
        ) : null}
      </div>
    );
  };

  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = (e) => {
    setFormValue({
      showroomID: "",
      showroomName: "",
      email: "",
      contactPersonName: "",
      location: "",
      gstNo: "",
      status: "",
    });
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const columns = [
    // {
    //   text: "S.No.",
    //   dataField: "id",
    //   headerAttrs: { width: "60px" },
    //   headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     return rowIndex + 1 + ".";
    //   },
    //   sort: true,
    // },
    {
      text: "Showroom ID",
      dataField: "businessID",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Showroom Name",
      dataField: "businessName",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Contact Person Name",
      dataField: "contactPersonName",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Email ID",
      dataField: "businessEmail",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Showroom Location",
      dataField: "showroomLocation",
      headerAttrs: { width: "300px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: locationFormatter,
    },
    {
      text: "Contact Number",
      dataField: "businessContactNo",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Mobile Number",
      dataField: "businessMobileNo",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Sales Manager",
      dataField: "salesManager",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "On-Boarding Date",
      dataField: "createdDate",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "GSTIN",
      dataField: "gstin",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Showroom Auth. No.",
      dataField: "authNo",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "130px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "Last Updated",
      dataField: "createdDate",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Reason",
      dataField: "reason",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "60px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Showrooms</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "manageUsers")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div
                    className="UHinputfields"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "135px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Showroom ID
                        </label>
                        <select
                          name="showroomID"
                          value={formValue.showroomID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Showroom ID
                          </option>
                          {uniquebusinessID.map((item) => {
                            return (
                              <option value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Email
                        </label>
                        <select
                          name="email"
                          value={formValue.email}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Email
                          </option>
                          {uniquebusinessEmail.map((item) => {
                            return (
                              <option value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Showroom Name
                        </label>
                        <select
                          name="showroomName"
                          value={formValue.showroomName}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Showroom Name
                          </option>
                          {uniquebusinessName.map((item) => {
                            return (
                              <option value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Contact Person Name
                        </label>
                        <select
                          name="contactPersonName"
                          value={formValue.contactPersonName}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Contact Person Name
                          </option>
                          {uniquecontactPersonName.map((item) => {
                            return (
                              <option value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Showroom Location
                        </label>
                        <select
                          name="showroomLocation"
                          value={formValue.showroomLocation}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Showroom Location
                          </option>
                          {uniquelocation.map((item) => {
                            return (
                              <option value={item}>{item.city}</option>);
                          })}
                        </select>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          GSTIN
                        </label>
                        <select
                          name="gstNo"
                          value={formValue.gstNo}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            GSTIN
                          </option>
                          {uniquegstin.map((item) => {
                            return (
                              <option value={item}>{item}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {
                            uniquestatus.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          justifyContent: "end",
                          width: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <button
                          id="apply"
                          style={{ marginTop: "22px", marginLeft: "10px" }}
                          onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ marginTop: "22px", width: "auto" }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default Showrooms;
