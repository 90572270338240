import React, { useState } from "react";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
} from "@material-ui/icons";
import moment from "moment";
import ViewSpecs from "./ViewSpecs";
import AppConstants from "../../../utils/app-constants";
import auth from "../../../utils/auth";

const SpareViewDetails = ({ closeModal, rowData }) => {
  console.log(rowData);
  const [openQty, setOpenQty] = useState();
  const [viewSpecs, setViewSpecs] = useState(false);
  const [showGRN, setShowGRN] = useState();
  const [showGIN, setShowGIN] = useState();
  const [rowIndex, setRowIndex] = useState()

  const tempArr = [];
  rowData?.returnedData?.map((item) => {
    tempArr.push(item.returnQty.length);
  });

  const finalReturnedUnits = tempArr.reduce((acc, item) => {
    return acc + item;
  }, 0);

  const finaltempValue = [];

  rowData?.returnedData?.map((item) => {
    const value = [];
    item?.returnedData?.map((data) => {
      value.push(data.priceToserviceCenter);
    });
    finaltempValue.push(
      value.reduce((acc, data) => {
        return acc + parseInt(data);
      }, 0)
    );
  });

  const totalValue = finaltempValue.reduce((acc, item) => {
    return acc + parseInt(item);
  }, 0);

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "850px", height: "550px", padding: "15px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>View Details</h3>
        </div>

        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
            margin: "10px 0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Request ID: {rowData?.requestID}</span>
            <span>RPI Raise By: {rowData?.rpiRaisedBy}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Request By: {rowData?.requestedBy}</span>
            <span>RPI No: {rowData?.rpiNo}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              Request Date:{" "}
              {moment(rowData?.requestedDate).format("DD-MMM-YYYY")}
            </span>
            <span>RPI Date:  {moment(rowData?.rpiDate).format("DD-MMM-YYYY")}</span>
          </div>

         
        </section>

        <section>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "80%",
            }}
          >
            <span
              style={{
                display: "flex",
                background: "blue",
                alignItems: "center",
                height: "25px",
                justifyContent: "center",
                borderRadius: "5px",
                color: "white",
                width: "180px",
                fontSize: "12px",
                fontWeight: "600",
              }}
            >
              Total Returned Qty. : {rowData?.returnedData?.map((item)=>item.returnQty.length)}
            </span>
            <span
              style={{
                display: "flex",
                background: "blue",
                alignItems: "center",
                height: "25px",
                justifyContent: "center",
                borderRadius: "5px",
                color: "white",
                width: "180px",
                fontSize: "12px",
                fontWeight: "600",
              }}
            >
              Total Returned Units : {finalReturnedUnits}
            </span>
            <span
              style={{
                display: "flex",
                background: "blue",
                alignItems: "center",
                height: "25px",
                justifyContent: "center",
                borderRadius: "5px",
                color: "white",
                width: "180px",
                fontSize: "12px",
                fontWeight: "600",
              }}
            >
              {`Total Value(${auth.getUserInfo().currencySymbol})`} {totalValue}
            </span>
          </div>
        </section>

        <div
          className="body"
          style={{
            display: "flex",
            height: "300px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div id="POVendorHeading">
              <div style={{ width: "6%" }}>S.No.</div>
              <div style={{ width: "15%" }}>Spare Name</div>
              <div style={{ width: "15%" }}>SKU Code</div>
              <div style={{ width: "15%" }}>Category</div>
              <div style={{ width: "15%" }}>Sub Category</div>
              <div style={{ width: "10%" }}>Returned Qty.</div>
              <div style={{ width: "15%" }}>{`UnitPrice(${auth.getUserInfo().currencySymbol})`}</div>
              <div style={{ width: "9%" }}>{`Sub Total(${auth.getUserInfo().currencySymbol})`}</div>
            </div>

            <div>
              {rowData?.returnedData?.map((item, index) => {
                return (
                  <>
                    <div
                      id="POVendorContent"
                      style={{
                        borderBottom: "1px solid #999999",
                        boxShadow: "none",
                        padding: "0px",
                      }}
                    >
                      <div style={{ width: "6%", overflowY: "auto" }}>
                        {index + 1}
                      </div>
                      <div style={{ width: "15%", overflowY: "auto" }}>
                        {item.sparePartname}
                      </div>
                      <div style={{ width: "15%", overflowY: "auto" }}>
                        {item.skuCode}
                      </div>
                      <div style={{ width: "15%", overflowY: "auto" }}>
                        {item.category}
                      </div>
                      <div style={{ width: "15%", overflowY: "auto" }}>
                        {item.subCategory}
                      </div>
                      <div 
                      style={{ width: "10%", overflowY: "auto", color: "blue", 
                      // onClick={() => setRowIndex(index)} ,
                      // cursor: "pointer"
                       }}>
                        {item.returnQty.length}
                      </div>
                      <div style={{ width: "15%", overflowY: "auto" }}>
                        {item.priceToserviceCenter}
                      </div>
                      <div style={{ width: "9%", overflowY: "auto" }}>
                        {item.returnQty.length *
                          item.priceToserviceCenter}
                      </div>
                    </div>

                    {rowIndex == index && (
                      <ViewSpecs rowData={item} closeModal={setRowIndex} />
                    )}
                  </>
                );
              })}
            </div>
          </section>
        </div>

        <div className="footer">
          <div>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "25px" }} />
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpareViewDetails;
