import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import {
  KeyboardBackspace,
  CheckCircle,
  CancelOutlined,
} from "@material-ui/icons";
import PersonIcon from "@mui/icons-material/Person";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "../../../../utils/notifications";
import { reject } from "lodash";
import { width } from "@mui/system";

const ViewDetails = ({ closeModal, editData }) => {
  const sumTotal = editData.requisitionDetails.reduce(
    (accumulator, currentValue) => accumulator + parseInt(currentValue.requiredQty),
    0
  );
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "600px", height: "377px", padding: "15px" }}
      >
        <div className="header">
          <h3>Requisition Details</h3>
        </div>
        <br />
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <section
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: "50%",
              textAlign: "flex-start",
            }}
          >
            <span
              style={{
                width: "100%",
                textAlign: "start",
                fontSize: "15px",
                fontWeight: "500",
                color: "blue"
              }}
            >
              Requisition ID:{editData.requisitionID}
            </span>
            <span
              style={{
                width: "100%",
                textAlign: "start",
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
              Requisition Date:{moment(editData.requisitionDate).format("DD-MM-YYYY")}
            </span>
            <span
              style={{
                width: "100%",
                textAlign: "start",
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
              Requested To:{editData.requestedTo}
            </span>
          </section>
          <sections
            style={{
              display: "flex",
              width: "50%",
              justifyContent: "end",
              textAlign: "end",
            }}
          >
            <div
              style={{
                backgroundColor: "#FFE1E1",
                height: "fit-content",
                width: "fit-content",
                borderRadius: "5px",
                padding: "3%",
              }}
            >
              <span
                style={{
                  color: "#E51111",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Requisition Rejected
              </span>
            </div>
          </sections>
        </section>
        <br />
        <div className="body" style={{ width: "100%" }}>
          <div
            style={{
              fontWeight: "500",
              color: "#0A18A3",
              width: "100%",
              textAlign: "start",
            }}
          >
            Rejection Details
          </div>
          <br />
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "5px",
              boxShadow: "0px 0px 5px grey",
              width: "100%",
              textAlign: "start",
              padding: "2%",
              margin: "2%",
            }}
          >
            <span>Total Spares Requested:&nbsp; {editData.requisitionDetails.length}</span>
            <span>Total Units Requested:&nbsp; {sumTotal}</span>
            <span>Rejection Date:&nbsp; {moment(editData.rejectionDate).format("DD-MM-YYYY")}</span>
            <span>Rejection Reason:&nbsp; {editData.reason}</span>
          </section>
        </div>
        <div
          className="footer"
          style={{ width: "100%", display: "flex", flexDirection: "row" }}
        >
          <button
            style={{ width: "100px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            &nbsp; Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
