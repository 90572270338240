import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification, errorNotification } from "../../../utils/notifications";

const DeleteComponent = ({ updatedData, editData, closeModal , setIsOpen}) => {
  const [formValue, setFormValue] = useState({
    deleteReason: "",
    description: "",
  });

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    if(formValue.deleteReason){
      const editDetails = {
        componentID: editData.componentID,
        reason: formValue.deleteReason,
        reasonComment: formValue.description
      };
  
      const resp = await trackPromise(API.post(`procurement-module/api/v1/components/delete`, editDetails));
  
      const { success, message } = resp;
  
      if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        refresh();
      } else {
        errorNotification(message, { autoClose: 2000 });
      }
    }else {
      errorNotification("Please select Delete Reason", { autoClose: 2000 });
    }
 
  };


  const closeHandler = ()=>{
    setIsOpen(false)
    closeModal(false)
  }



  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Delete Component</h3>
        </div>

        <div className="body">
          <label style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{
              fontSize: "13px",
              fontWeight: "500",
              width: "auto",
              textAlign: "start"
            }}>Delete Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="deleteReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', outline: 'none', marginTop: '-8px' }}
            >
              <option disabled selected hidden>
                Delete Reason
              </option>
              <option value="Misconduct">
                Misconduct
              </option>
              <option value="Violation of Terms">
                Violation of Terms
              </option>
              <option value="Others">Others</option>
            </select>
          </label>

          {formValue.deleteReason === "Others" ?
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({ ...formValue, [e.target.name]: e.target.value })
                }
                placeholder="Delete Reason"
                autoComplete="off"
              />
            </label> : ""}

        </div>
        <div className="footer">
          <button onClick={closeHandler}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteComponent;
