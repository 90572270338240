import React, { useState } from "react";
import { faEdit,faBan, faTrash,faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../components/modal/modal.scss";
import BusinessEntitiesReactivate from "./BusinessEntitiesReactivate";
import BusinessEntitiesDelete from "./BusinessEntitiesDelete";
import BusinessEntitiesDeactivate from "./BusinessEntitiesDeactivate";
import BusinessEntitiesEdit from "./BusinessEntitiesEdit";

const BusinessEntitiesAction = ({updatedData,rowData,setIsOpen,rowIndex,entityID}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});



  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      reactivate: false,
      deactivate: false,
      delete: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && (
        <BusinessEntitiesEdit updatedData={updatedData} editData={rowData} rowIndex={rowIndex} closeModal={setShowModal} />
      )}
      { activeModal.deactivate && showModal &&(
        <BusinessEntitiesDeactivate updatedData={updatedData} editData={rowData} closeModal={setShowModal} entityID={entityID}/>
      )}
      {  
           activeModal.reactivate && showModal && (
            <BusinessEntitiesReactivate updatedData={updatedData} editData={rowData} closeModal={setShowModal} entityID={entityID}/>
          )
      }
      
      {activeModal.delete && showModal && 
      <BusinessEntitiesDelete updatedData={updatedData} editData={rowData} closeModal={setShowModal} entityID={entityID} />}
      
      <div className="action" style={{position: "relative" ,  marginTop: rowIndex <= 4 ? "0px" : "-100px",}} onBlur={() => setIsOpen(false)}>
        <div className="action vendoraction" style={{marginLeft:"-30px", marginTop:"0px"}}>
        <div> 
          <span id="edit" onClick={(e) => clickHandler(e)}>
          <FontAwesomeIcon icon={faEdit}/>  Edit
          </span>
        </div>
        <div>

        
          {
            rowData.status === "Verified" || rowData.status === "Reactivated" ? 
              <span id="deactivate" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faBan}/> Deactivate
              </span>
              :
               <span id="reactivate" onClick={(e) => clickHandler(e)}>
               <FontAwesomeIcon icon={faDotCircle}/> Reactivate
              </span>
          }
        </div>
        <div>
          <span id="delete" onClick={(e) => clickHandler(e)}>
          <FontAwesomeIcon icon={faTrash}/>  Delete
          </span>
        </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessEntitiesAction;
