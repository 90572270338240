import React, { useState } from "react";
import Edit from "./Edit";
import Delete from "./Delete";
import "../../../../components/modal/modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const Action = ({ updatedData, componentID, rowData }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      delete: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && <Edit updatedData={updatedData} componentID={componentID} editData={rowData} closeModal={setShowModal} />}

      {activeModal.delete && showModal && <Delete updatedData={updatedData} componentID={componentID} editData={rowData} closeModal={setShowModal} />}

      <div style={{ position: "relative" }}>
        <div style={{ marginLeft: "-145px", position: "fixed" }} className="action">
          <div>
            <span id="edit" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </span>
          </div>
          <div>
            <span id="delete" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faTrash} />  Delete
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Action;
