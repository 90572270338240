import React, { useState, useEffect, useRef } from "react";
import "../QcDashboard/styles/main.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faTable,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { FaRedoAlt } from "react-icons/fa";
import BomCosting from "./BomCosting";
import VehicleBarChart from "../QcDashboard/components/VehicleBarChart";
import VehicleReBarChart from "../QcDashboard/components/VehicleReBarChart";
import VendorsGraph from "./VendorsGraph";
import LinearProgressCard from "./LinearProgressCard";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { DatePicker, Space } from "antd";
import { BsArrowUpRight } from "react-icons/bs";
import ManageBomCosting from "../bom-costing/manage-bom-costing";
import ManageOrders from "../orders/manage-orders";
import ManageVendors from "../vendors/manage-vendor";
import ManageComponents from "../components/manage-components";
import MappedComponentVendors from "../mapping/cv-mapping/mapped-component-vendors";
import ManageLaborCosting from "../mapping/labor-costing/manage-labor-costing";
import ManageAssemblyCosting from "../mapping/assembly-costing/manage-assembly-costing";

const ProcurementDashboard = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateBOM, setStartDateBOM] = useState(null);
  const [endDateBOM, setEndDateBOM] = useState(null);
  const [vendorStartDate, setVendorStartDate] = useState(null);
  const [vendorEndDate, setVendorEndDate] = useState(null);
  const [compStartDate, setCompStartDate] = useState(null);
  const [compEndDate, setCompEndDate] = useState(null);
  const { RangePicker } = DatePicker;
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [costingData, setCostingData] = useState({});
  const [openBomCostTable, setOpenBomCostTable] = useState(false);
  const [openOrdersTable, setOpenOrdersTable] = useState(false);
  const [openVendorsTable, setOpenVendorsTable] = useState(false);
  const [openComponentsTable, setOpenComponentsTable] = useState(false);
  const [openCompMappingTable, setOpenCompMappingTable] = useState(false);
  const [openLaborMappingTable, setOpenLaborMappingTable] = useState(false);
  const [openAssemblyMappingTable, setOpenAssemblyMappingTable] = useState(false);



  const fetchData = async () => {
    const resp = await trackPromise(
      API.get(`procurement-module/api/v1/dashboard/getAllReqsBomCosting`)
    );
    setCostingData(resp);
  };
  useEffect(() => {
    fetchData();
  }, []);

  function on() {
    document.getElementById("overlay").style.display = "block";
  };

  function off() {
    setCalenderOpen(false);
    document.getElementById("overlay").style.display = "none";
    refresh();
    blank();
    empty();
  }

  // ORDERS
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const refresh = () => {
    setStartDate(null);
    setEndDate(null);
    document.getElementById("calender").style.display = "none";
    document.getElementById("calendericon").style.display = "block";
    document.getElementById("calendericon").style.justifyContent = "center";
    document.getElementById("calendericon").style.textAlign = "center";
    
  }

  const show = () => {
    document.getElementById("calender").style.display = "block";
    document.getElementById("calendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }


  // BOM COSTING
  const onChangeBOM = (dates) => {
    const [start, end] = dates;
    setStartDateBOM(start);
    setEndDateBOM(end);
  };

  const refreshBOM = () => {
    setStartDateBOM(null);
    setEndDateBOM(null);
    document.getElementById("calenderOrder").style.display = "none";
    document.getElementById("calendericonOrder").style.display = "block";
    document.getElementById("calendericonOrder").style.justifyContent = "center";
    document.getElementById("calendericonOrder").style.textAlign = "center";
    
  }

  const showBOM = () => {
    document.getElementById("calenderOrder").style.display = "block";
    document.getElementById("calendericonOrder").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  // VENDORS
  const onVendorChange = (dates) => {
    const [start, end] = dates;
    setVendorStartDate(start);
    setVendorEndDate(end);
  };
  const blank = () => {
    setVendorStartDate(null);
    setVendorEndDate(null);
    document.getElementById("vendorcalender").style.display = "none";
    document.getElementById("vendorcalendericon").style.display = "block";
    document.getElementById("vendorcalendericon").style.justifyContent = "center";
    document.getElementById("vendorcalendericon").style.textAlign = "center";
  }

  const shows = () => {
    document.getElementById("vendorcalender").style.display = "block";
    document.getElementById("vendorcalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  // COMPONENTS
  const onCompChange = (dates) => {
    const [start, end] = dates;
    setCompStartDate(start);
    setCompEndDate(end);
  };
  const empty = () => {
    setCompStartDate(null);
    setCompEndDate(null);
    document.getElementById("componentcalender").style.display = "none";
    document.getElementById("componentcalendericon").style.display = "block";
    document.getElementById("componentcalendericon").style.justifyContent = "center";
    document.getElementById("componentcalendericon").style.textAlign = "center";
  };
  const display = () => {
    document.getElementById("componentcalender").style.display = "block";
    document.getElementById("componentcalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }


  return (
    <>
      {openBomCostTable == true ? <ManageBomCosting inDashboard={setOpenBomCostTable} /> :
        openOrdersTable == true ? <ManageOrders inDashboard={setOpenOrdersTable} /> :
          openVendorsTable == true ? <ManageVendors inDashboard={setOpenVendorsTable} /> :
            openComponentsTable == true ? <ManageComponents inDashboard={setOpenComponentsTable} /> :
              openCompMappingTable == true ? <MappedComponentVendors inDashboard={setOpenCompMappingTable} /> :
                openLaborMappingTable == true ? <ManageLaborCosting inDashboard={setOpenLaborMappingTable} /> :
                  openAssemblyMappingTable == true ? <ManageAssemblyCosting inDashboard={setOpenAssemblyMappingTable} /> :
                    <div className="dashboardBody">
                      <div id="overlay" onClick={(e) => off(e)}></div>
                      <div className="firstRow">
                        <div className="container-new" style={{ height: "400px" }}>
                          <div
                            className="header"
                            style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                          >
                            <div>
                              <h5 style={{ fontSize: "18px" }}>BOM Costing</h5>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                }}
                                onClick={refreshBOM}
                              >
                                <FaRedoAlt />
                              </div>
                              <span id="calendericonOrder" style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                marginRight: "5px",
                              }} onClick={showBOM}>
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  style={{ color: "#808080" }}
                                />
                              </span>
                              <label id="calenderOrder" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                                <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                  <RangePicker
                                    startDate={startDateBOM}
                                    endDate={endDateBOM}
                                    value={[startDateBOM, endDateBOM]}
                                    onChange={onChangeBOM}
                                    format="DD-MM-YYYY"
                                    size="small"
                                  />
                                </Space>
                              </label> */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                              >
                                <BsArrowUpRight onClick={() => setOpenBomCostTable(true)} />
                              </div>
                            </div>
                          </div>
                          <div>
                            <BomCosting costingData={costingData} />
                          </div>
                        </div>
                        <div
                          className="container-new"
                          style={{ marginLeft: "20px", height: "400px" }}
                        >
                          <div
                            className="header"
                            style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                          >
                            <div>
                              <h5 style={{ fontSize: "18px" }}>Orders</h5>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                              {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                }}
                                onClick={refresh}
                              >
                                <FaRedoAlt />
                              </div>
                              <span id="calendericon" style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                marginRight: "5px",
                              }} onClick={show}>
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  style={{ color: "#808080" }}
                                />
                              </span>
                              <label id="calender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                                <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                  <RangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    value={[startDate,endDate]}
                                    onChange={onChange}
                                    format="DD-MM-YYYY"
                                    size="small"
                                  />
                                </Space>
                              </label> */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                              >
                                <BsArrowUpRight onClick={() => setOpenOrdersTable(true)} />
                              </div>
                            </div>
                          </div>
                          <div>
                            <VehicleBarChart
                              props="procurement"
                              bomStartDate={startDate}
                              bomEndDate={endDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="secondRow" style={{ height: "400px" }}>
                        <div className="container-new" style={{ height: "95%" }}>
                          <div
                            className="header"
                            style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                          >
                            <div>
                              <h5 style={{ fontSize: "18px" }}>Vendors</h5>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                              {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  marginRight: "5px"
                                }}
                                onClick={blank}
                              >
                                <FaRedoAlt />
                              </div>
                              <span id="vendorcalendericon" style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                marginRight: "5px",
                              }} onClick={shows}>
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  style={{ color: "#808080" }}
                                />
                              </span>
                              <label id="vendorcalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                                <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                  <RangePicker
                                    startDate={vendorStartDate}
                                    endDate={vendorEndDate}
                                    value={[vendorStartDate,vendorEndDate]}
                                    onChange={onVendorChange}
                                    format="DD-MM-YYYY"
                                    size="small"
                                  />
                                </Space>
                              </label> */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                              >
                                <BsArrowUpRight onClick={() => setOpenVendorsTable(true)} />
                              </div>
                            </div>
                          </div>
                          <br />
                          <div style={{ overflow: "auto", height: "100%" }}>
                            <VendorsGraph startDate={vendorStartDate} endDate={vendorEndDate} />
                          </div>
                        </div>
                        <div
                          className="container-new"
                          style={{ marginLeft: "20px", height: "95%" }}
                        >
                          <div
                            className="header"
                            style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                          >
                            <div>
                              <h5 style={{ fontSize: "18px" }}>Components</h5>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                              {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  marginRight: "5px"
                                }}
                                onClick={empty}
                              >
                                <FaRedoAlt />
                              </div>
                              <span id="componentcalendericon" style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "23.4px",
                                border: "1px Solid #808080",
                                color: "#808080",
                                borderRadius: "5px",
                                marginRight: "5px",
                              }} onClick={display}>
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  style={{ color: "#808080" }}
                                />
                              </span>
                              <label id="componentcalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                                <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                  <RangePicker
                                    startDate={compStartDate}
                                    endDate={compEndDate}
                                    value={[compStartDate,compEndDate]}
                                    onChange={onCompChange}
                                    format="DD-MM-YYYY"
                                    size="small"
                                  />
                                </Space>
                              </label> */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                              >
                                <BsArrowUpRight onClick={() => setOpenComponentsTable(true)} />
                              </div>
                            </div>
                          </div>
                          <div>
                            <VehicleReBarChart
                              props="procurement"
                              startDate={compStartDate}
                              endDate={compEndDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="thirdBox" style={{ height: "400px" }}>
                        <div
                          className="header"
                          style={{
                            width: "95%",
                            marginTop: "10px",
                            marginLeft: "20px",
                            marginBottom: "40px",
                          }}
                        >
                          <h5 style={{ fontSize: "18px" }}>Mapping</h5>
                        </div>
                        <div style={{ display: "flex", justifyContent: "row" }}>
                          <div className="pricing" style={{ height: "260px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                marginLeft: "10px",
                                marginRight: "10px",
                                marginTop: "-100px",
                              }}
                            >
                              <div>
                                <h5 style={{ fontSize: "14px", width: "150px" }}>
                                  Components Mapping
                                </h5>
                              </div>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "25px",
                                    height: "23.4px",
                                    border: "1px Solid #808080",
                                    color: "#808080",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <BsArrowUpRight onClick={() => setOpenCompMappingTable(true)} />
                                </div>
                              </div>
                            </div>
                            <br />
                            <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                              <LinearProgressCard props="component" />
                            </div>
                          </div>
                          <div className="pricing" style={{ height: "260px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                marginLeft: "10px",
                                marginRight: "10px",
                                marginTop: "-100px",
                              }}
                            >
                              <div>
                                <h5 style={{ fontSize: "14px", width: "150px" }}>
                                  Labours Mapping
                                </h5>
                              </div>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "25px",
                                    height: "23.4px",
                                    border: "1px Solid #808080",
                                    color: "#808080",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <BsArrowUpRight onClick={() => setOpenLaborMappingTable(true)} />
                                </div>
                              </div>
                            </div>
                            <br />
                            <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                              <LinearProgressCard props="labour" />
                            </div>
                          </div>
                          <div className="pricing" style={{ height: "260px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                marginLeft: "10px",
                                marginRight: "10px",
                                marginTop: "-100px",
                              }}
                            >
                              <div>
                                <h5 style={{ fontSize: "14px", width: "150px" }}>
                                  Assembly Mapping
                                </h5>
                              </div>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "25px",
                                    height: "23.4px",
                                    border: "1px Solid #808080",
                                    color: "#808080",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <BsArrowUpRight onClick={() => setOpenAssemblyMappingTable(true)} />
                                </div>
                              </div>
                            </div>
                            <br />
                            <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                              <LinearProgressCard props="assembly" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
      }
    </>
  );
};

export default ProcurementDashboard;
