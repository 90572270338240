import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification, errorNotification } from "../../../utils/notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ChangeAssign = ({ rowData, closeModal, updatedData }) => {
  const [blankForm, setBlankForm] = useState({
    BOMID: "BOM ID 1",
    bomLocation: "Haryana",
    bomName: "New Bom",
    vehicleModel: "Pulsar",
    vehicleVariant: "DTSI",
    shopFloorManager: "",
    shopFloorEmailID: "",
    workType: "Production",
    startDate: "1581292800000",
    endDate: "1646468862712",
    productionQuantity: "234",

  })
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const shopManagers = ["Jatin", "Sandeep", "Ayush", "Jay"];
  const shopId = ["abc@gmail.com", "xyz@gmail.com"];

  const editDetails = {

    orderID: rowData.orderID,

    shopFloorManager: blankForm.shopFloorManager,

    shopFloorEmailID: blankForm.shopFloorEmailID

  };

  const refresh = () => {
    updatedData()
  };

  const submitHandler = async () => {
    const resp = await trackPromise(API.post(`production-module/api/v1/workOrder/changeAssignment`, editDetails));

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh()
    } else {
      errorNotification(message, { autoclose: 2000 });
    }

  };

  const handleChange = (e) => {
    setBlankForm({ ...blankForm, [e.target.name]: e.target.value });
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "730px", height: "435px" }}>
        <div className="header">
          <h3>Change Assignment</h3>
        </div>

        <div className="body">
          <input
            className="input-config"
            style={{ width: "30%", outline: "none" }}
            type="text"
            name="BOMID"
            value={blankForm.BOMID}
            placeholder="BOM"
            disabled
          />
          <input
            className="input-config"
            style={{ width: "30%", outline: "none" }}
            type="text"
            name="location"
            value={blankForm.bomLocation}
            placeholder="Location"
            disabled
          />
          <input
            className="input-config"
            style={{ width: "30%", outline: "none" }}
            type="text"
            name="bomName"
            value={blankForm.bomName}
            placeholder="BOM Name"
            disabled
          />

          <input
            className="input-config"
            style={{ width: "30%", outline: "none" }}
            type="text"
            name="vehicleModel"
            value={blankForm.vehicleModel}
            placeholder="Vehicle Model"
            disabled
          />
          <input
            className="input-config"
            style={{ width: "30%", outline: "none" }}
            type="text"
            name="vehicleVariant"
            value={blankForm.vehicleVariant}
            placeholder="Vehicle Variant"
            disabled
          />
          <input
            className="input-config"
            style={{ width: "30%", outline: "none" }}
            type="text"
            name="productionQuantity"
            value={blankForm.productionQuantity}
            placeholder="Production Quantity"
            disabled
          />
          <input
            className="input-config"
            style={{ width: "30%", outline: "none" }}
            type="text"
            name="workType"
            value={blankForm.workType}
            placeholder="Work Type"
            disabled
          />
          <DatePicker
            style={{ width: "100%", outline: "none", cursor: 'pointer' }}
            className="daterange"
            placeholderText="Work Timeline"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            // onChange={handleDates}
            isClearable={true}
          />
          <select
            required={true}
            name="shopFloorManager"
            className="input-config"
            style={{ width: "30%", marginLeft: "49px", outline: "none", cursor: 'pointer' }}
            defaultValue={blankForm.shopFloorManager}
            onChange={(e) => handleChange(e)}
          >
            <option selected disabled hidden>
              Shop Floor Manager
            </option>
            {shopManagers.map(item => <option>{item}</option>)}
          </select>
          <select
            required={true}
            name="shopFloorEmailID"
            className="input-config"
            style={{ width: "30%", outline: "none", cursor: 'pointer' }}
            defaultValue={blankForm.shopFloorEmailID}
            onChange={(e) => handleChange(e)}
          >
            <option selected disabled hidden>
              Shop Floor Email ID
            </option>
            {shopId.map(item => <option>{item}</option>)}
          </select>
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeAssign;
