import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";

const WarrantyDetails = ({ closeModal, rowData }) => {
  // const UsersData = row.requisitionDetails;
  console.log("warranty-->",rowData)

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "50%", height: "500px", padding: "10px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Warranty Details</h3>
          <div>
            {/* <button className="action-btn" style={{ color: "blue" }}>
              <PrintIcon />
              &nbsp;Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              <IosShareIcon />
              &nbsp;Export
            </button> */}
          </div>
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding:7,
          }}
        >
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            SKU Code: {rowData?.vehicleSkuCode}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Vehicle Model: {rowData?.vehicleModel}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            Segment: {rowData?.vehicleSegment}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Vehicle Variant: {rowData?.vehicleVariant}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            Category: {rowData?.vehicleCategory}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Vehicle Type: {rowData?.vehicleType}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            Color: {rowData?.vehicleColor}
          </span>
        </section>
        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ color: "blue" }}>Standard Warranty</h6>
            </div>

            <div>
                <Table>
                <tbody> 
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Warranty Type</td>
                    <td style={{textAlign:"start"}}>{rowData?.standardWarranty?.warrantyType}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Distance</td>
                    <td style={{textAlign:"start"}}>{rowData?.standardWarranty?.warrantyDistance}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Duration & Units</td>
                    <td style={{textAlign:"start"}}>{rowData?.standardWarranty?.warrantyDuration + rowData?.standardWarranty?.warrantyDurationUnit}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Warranty Inclusions & Terms</td>
                    <td style={{color:"blue", textAlign:"start"}}>tata altroz.pdf</td>
                  </tr>
                </tbody>
              </Table>
        
            </div>

          </div>
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ color: "blue" }}>Extended Warranty</h6>
            </div>

            <div>
             {
              rowData?.extendedWarranty?.map((item)=>{
                return(
                  <>
                  <Table>
                <tbody>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Warranty Type</td>
                    <td>{item?.warrantyType}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Distance</td>
                    <td>{item?.warrantyDuration}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Distance & Units</td>
                    <td>{item?.warrantyDuration + item?.warrantyDurationUnit}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Charges</td>
                    <td>{item?.warrantyCharges}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Warranty Inclusions & Terms</td>
                    <td style={{color:"blue"}}>tata altroz.pdf</td>
                  </tr>
                </tbody>
              </Table>
                  </>
                )
               
              })
             }
            </div>

          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <KeyboardBackspace /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default WarrantyDetails;
