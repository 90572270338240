import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../../../utils/notifications";
import EditDeliveryDetails from "../EditDeliveryDetails";

const Edit = ({ updatedData, editData, closeModal, componentID }) => {
  const [vendorData, setVendorData] = useState([]);
  const [deliveryData, setDeliveryData] = useState({});
console.log(editData)
  const [formValue, setFormValue] = useState({
    vendorName: editData.vendorName,
    vendorPartName: editData.vendorPartName,
    vendorPartCode: editData.vendorPartCode,
    vendorCity: editData.vendorCity,
    deliveryDetails: editData.deliveryDetails,
    vendorID: editData.vendorID,
  });

  const editDetails = {
    componentID: componentID,
    vendorDetails: {
      vendorID: editData.vendorID,
      vendorName: formValue.vendorName,
      vendorPartName: formValue.vendorPartName,
      vendorPartCode: formValue.vendorPartCode,
      vendorCity: formValue.vendorCity,
      deliveryDetails: deliveryData,
    },
  };
  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    var finalEditBody = { ...editDetails, vendorDetails: Object.keys(deliveryData).length == 0 ? formValue : deliveryData }
    console.log(finalEditBody)
    const resp = await trackPromise(
      API.put(`procurement-module/api/v1/mappings/`, finalEditBody)
    );
    const { success, message } = resp;
    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const getVendor = async () => {
    const resp = await trackPromise(
      API.get(`procurement-module/api/v1/vendors`)
    );
    console.log(resp);
    setVendorData(resp);
  };
  useEffect(() => {
    getVendor();
  }, []);

  const submitDeliveryData = (data) => {
    let final = { ...formValue, deliveryDetails: data };
    console.log(final)
    setDeliveryData(final);
  };

  let uniqueVendorNameArray = [...new Set(vendorData.map((item) => item.vendorName))];
  let uniqueVendorLocationArray = [...new Set(vendorData.map((item) => item.city))];
  

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "950px" }}>
        <div className="header">
          <h3>Edit Vendor Details</h3>
        </div>
        <div className="body" style={{ overflow: "scroll" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              margin: "12px",
              justifyContent: "start",
            }}
          >
            <div
              style={{
                width: "25%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-132px",
                }}
              >
                Vendor Name<span style={{ color: "red" }}>*</span>
              </label>
              <select
                style={{ width: "200px", marginTop: '-5px', }}
                name="vendorName"
                defaultValue={editData.vendorName}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
              >
                <option selected>{editData.vendorName}</option>
                {uniqueVendorNameArray.map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div
              style={{
                width: "23%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-87px",
                }}
              >
                Vendor Part Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                style={{ width: "180px", marginTop: '-5px' }}
                name="vendorPartName"
                defaultValue={editData.vendorPartName}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Vendor Part Name"
                autoComplete="off"
              />
            </div>
            <div
              style={{
                width: "23%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-94px",
                }}
              >
                Vendor Part Code<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                style={{ width: "180px", marginTop: '-5px' }}
                name="vendorPartCode"
                defaultValue={editData.vendorPartCode}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Vendor Part No."
                autoComplete="off"
              />
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-102px",
                }}
              >
                Vendor City<span style={{ color: "red" }}>*</span>
              </label>
              <select
                style={{ width: "180px", marginTop: '-5px' }}
                name="vendorCity"
                defaultValue={editData.vendorCity}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
              >
                <option selected>{editData.vendorCity}</option>
                {uniqueVendorLocationArray.map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <label style={{ width: "100%" }}>
              <EditDeliveryDetails
                deliveryHandler={submitDeliveryData}
                deliveryDetailsData={editData.deliveryDetails}
              />
            </label>
          </div>
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default Edit;
