import React from "react";
import {Tabs} from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from '../../utils/auth'
// import FinishedGoods from "./finishedgoods";
// import GRN from "./grn";
// import MROInventory from "./mroinventory";
// import PurchaseOrder from "./purchaseorder";
// import RawInventory from "./rawinventory";

const FinishedGoods = React.lazy(()=>import("./finishedgoods"))
const GRN = React.lazy(()=>import("./grn"))
const MROInventory = React.lazy(()=>import("./mroinventory"))
const PurchaseOrder = React.lazy(()=>import("./purchaseorder"))
const RawInventory = React.lazy(()=>import("./rawinventory"))



const InventoryManagement=()=>{
    const tabs = [
        {
          id:"MGM3T1",
          label: TabLabels.PURCHASEORDER,
          Component:PurchaseOrder
        },
        {
          id:"MGM3T2",
          label: TabLabels.GRN,
          Component:GRN
        },
        {
          id:"MGM3T3",
          label: TabLabels.RAW_INVENTORY_INV,
          Component:RawInventory
        },
        {
          id:"MGM3T4",
          label: TabLabels.MRO_INVENTORY_INV,
          Component:MROInventory
        },
        {
          id:"MGM3T5",
          label: TabLabels.FINISHEDGOODS,
          Component:FinishedGoods
        },
    ];

    const getTabs = (tabs) => {
      let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
      return tabs.filter(tab => tabIds.includes(tab.id));
    };

   let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
}
export default InventoryManagement