import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
  ConfirmationNumber,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import {
  faEdit,
  faBan,
  faShare,
  faPauseCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentViewReject from "./ComponentViewReject";
import ComponentHold from "./ComponentHold";
import Confirmation from "./Confirmation";

const VehicleViewAct = ({ closeModal, rowData, updatedData }) => {
  console.log(rowData);
  const [receivedBy, setReceivedBy] = useState();
  const [receivedDate, setReceivedDate] = useState(new Date(parseInt("5467")));
  const [viewSpecs, setViewSpecs] = useState();
  const [showReject, setShowReject] = useState(false);
  const [showHold, setShowHold] = useState(false);
  const [actionData, setActionData] = useState([]);
  const [receivedQuantity, setReceivedQty] = useState();
  const [openModal, setOpenModal] = useState(false);

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(`qc-module/api/v1/vehicle/newrequest/status`, {
        requestID: rowData.requestID,
        status: "In-Progress",
      })
    );
    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const refresh = () => {
    updatedData();
  };
  const fetchBom = async () => {
    const resp = await trackPromise(
      API.get(
        `qc-module/api/v1/vehicle/finishedgoods/action?requestID=${rowData.requestID}`
      )
    );
    const dataArray = resp.map((item, i) =>
      item.data.map((obj, i) => {
        return obj;
      })
    );
    const manageData = [];
    dataArray.map((item, i) => {
      return item.map((object, i) => manageData.push(object));
    });
    setActionData(manageData);
  };

  console.log(actionData);
  useEffect(() => {
    fetchBom();
  }, []);

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "980px", height: "450px", padding: "15px" }}
      >
        <div className="header">
          <h3>Request Details</h3>
        </div>

        <div
          className="body"
          style={{
            display: "flex",
            height: "300px",
            fontSize: "14px",
            overflow: "scroll",
            flexDirection: "column",
            flexWrap: "revert",
          }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Request ID : {rowData.requestID}</span>
              <span>WO No. : {rowData.orderID}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>RequestCategory : {rowData.requestCategory}</span>
              <span>
                Added Date : {moment(rowData.actionDate).format("DD-MMM-YYYY")}{" "}
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Request Type : {rowData.requestType}</span>
              <span>Added Qty. : {actionData.length}</span>
            </div>
          </section>
          <section style={{ width: "100%", height: "400px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
              }}
            >
              <span>Segment: {actionData[0]?.vehicleSegment}</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
              }}
            >
              <span>Category: {actionData[0]?.vehicleCategory}</span>
            </div>
            <div id="POVendorHeading">
              <div style={{ width: "8%" }}>S.No.</div>
              <div style={{ width: "15%" }}>Vehicle Model</div>
              <div style={{ width: "15%" }}>Vehicle Variant</div>
              <div style={{ width: "10%" }}>Vehicle Type</div>
              <div style={{ width: "8%" }}>Color</div>
              <div style={{ width: "8%" }}>SNO</div>
              <div style={{ width: "8%" }}>VIN</div>
              <div style={{ width: "8%" }}>BIN</div>
              <div style={{ width: "20%" }}>Status</div>
            </div>
            <div>
              {actionData.map((item, index) => {
                return (
                  <div
                    id="POVendorContent"
                    style={{
                      borderBottom: "1px solid #999999",
                      boxShadow: "none",
                      padding: "0px",
                    }}
                    key={1}
                  >
                    <div style={{ width: "8%" }}>{++index}</div>
                    <div style={{ width: "15%" }}>{item.vehicleModel}</div>
                    <div style={{ width: "15%" }}>{item.vehicleVariant}</div>
                    <div style={{ width: "10%" }}>{item.vehicleType}</div>
                    <div style={{ width: "8%" }}>{item.vehicleColor}</div>
                    <div style={{ width: "8%" }}>{item.SNO}</div>
                    <div style={{ width: "8%" }}>{item.VIN}</div>
                    <div style={{ width: "8%" }}>{item.BIN}</div>
                    <div style={{ width: "20%" }}>
                      {item.status == "Failed" ? (
                        <button className="delete-btn">
                          <span className="delete-btn-font">{item.status}</span>
                        </button>
                      ) : item.status == "Pending Verification" ? (
                        <button className="deactive-btn">
                          <span className="deactive-btn-font">
                            {item.status}
                          </span>
                        </button>
                      ) : (
                        <button className="active-btn">
                          <span className="active-btn-font">{item.status}</span>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>

        <div className="footer">
          <div>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace
                style={{ fontSize: "25px", marginRight: "5px" }}
              />
              Back
            </button>
          </div>
          <div>
            <button
              style={{
                width: "104px",
                height: "42px",
                background: "#fff",
                marginRight: "380px",
              }}
              onClick={() => setShowReject(true)}
            >
              <HighlightOffOutlined
                style={{ fontSize: "25px", marginRight: "5px" }}
              />
              Reject
            </button>
            {showReject && (
              <ComponentViewReject
                setOpen={closeModal}
                rowData={rowData}
                updatedData={updatedData}
                closeModal={setShowReject}
              />
            )}
          </div>
          <div>
            <button
              style={{
                width: "125px",
                height: "42px",
                background: "#fff",
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setShowHold(true)}
            >
              <FontAwesomeIcon icon={faPauseCircle}></FontAwesomeIcon>Put on
              Hold
            </button>
            {showHold && (
              <ComponentHold
                setOpen={closeModal}
                rowData={rowData}
                updatedData={updatedData}
                closeModal={setShowHold}
              />
            )}
          </div>

          <div>
            <button
              onClick={() => setOpenModal(true)}
              style={{
                marginRight: "0",
                width: "170px",
                height: "42px",
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
              id="submit-button"
            >
              <CheckCircle style={{ fontSize: "17px" }} /> Start Verification{" "}
            </button>
            {openModal && (
              <Confirmation
                submitHandler={submitHandler}
                closeModal={setOpenModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleViewAct;
