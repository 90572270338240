import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import "../QcDashboard/styles/main.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { BsArrowUpRight, BsTable } from "react-icons/bs";
import { FaExpandAlt } from "react-icons/fa";
import { FaRedoAlt } from "react-icons/fa";
// import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import ApprovalProgressBar from "./ApprovalProgressBar";
import VehicleCard from "./VehicleCards"
// import WorkOrderDashboard from "../ShopFloorDashboard/WorkOrderDashboard";
// import Group18 from "../QcDashboard/assets/Group18.svg";
// import Group9 from "../QcDashboard/assets/Group9.svg";
// import Group from "../QcDashboard/assets/Group.png";
// import Vector from "../QcDashboard/assets/Vector.svg";
// import ProgressBar from "react-bootstrap/esm/ProgressBar";
// import ManagementRawBox from "./ManagementRawBox";
// import FinishedGoodsBox from "./finishedGoodsBox";
// import Vector3 from "../QcDashboard/assets/Vector3.svg";
// import ComponentVerification from "../QcDashboard/components/ComponentVerification";
// import SpareReVerification from "../QcDashboard/components/SpareReVerification";
// import VehicleBarChart from "../QcDashboard/components/VehicleBarChart";
// import VehicleReBarChart from "../QcDashboard/components/VehicleReBarChart";
import { DatePicker, Space } from "antd";
import { useHistory } from "react-router-dom";
import Orders from "../ShowroomOrders/RequisitionProgress";
import VehicleReturns from "../ShowroomReturns/ReturnProgress";
import VehicleInventory from "../ShowroomVehicleBatteries/Vehicle";
import VehicleSales from "../ShowroomCustomer&sales/ShowroomVehicleSales/ManageVehicleSales";
import ShowroomSalesReport from "../ShowroomCustomer&sales/showroomSalesReport/showroomSalesReport";
import ReturnProgress from "../ShowroomReturns/ReturnProgress";
import SKUWiseInventory from "../showroomInvetory/SKUWiseInventory";

function ShowroomDashboard() {
  const [openOrdersTable, setOpenOrdersTable] = useState(false);
  const [openInventoryTable, setOpenInventoryTable] = useState(false);
  const [openSalesTable, setOpenSalesTable] = useState(false);
  const [openReturnTable, setOpenReturnTable] = useState(false);

  const { RangePicker } = DatePicker;
  const [calenderOpen, setCalenderOpen] = useState(false);

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    setCalenderOpen(false);
    document.getElementById("overlay").style.display = "none";
    // refresh();
    // vpaRefresh();
    // spareRefresh();
    // vehicleRefresh();
    // verification();
    // reVerificationRefresh();
  }

  // const fetchData = async () => {
  //   const result = await trackPromise(API.get(``));
  //   setGrnData(result);
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  const history = useHistory();

  return (
    <>
      {openOrdersTable == true ? (
        <Orders inDashboard={setOpenOrdersTable} />
      ) : openInventoryTable == true ? (
        <SKUWiseInventory inDashboard={setOpenInventoryTable} />
      ) : openSalesTable == true ? (
        <ShowroomSalesReport inDashboard={setOpenSalesTable} />
      ) : openReturnTable == true ? (
        <ReturnProgress inDashboard={setOpenReturnTable} />
      ) : (
        <>
          <>
            <div id="overlay" onClick={(e) => off(e)}></div>
            <div
              className="thirdBox"
              style={{
                height: "105%",
                marginTop: "10px",
                padding: "23px 0px",
              }}
            >
              {/* First Row */}
              <div style={{ display: "flex", flexDirection: "row", padding: "inherit" }}>
                <div
                  className="pricing"
                  style={{ height: "265px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Orders</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenOrdersTable(true)}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <ApprovalProgressBar />
                  </div>
                </div>
                <div
                  className="pricing"
                  style={{ height: "265px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Vehicle Returns</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenReturnTable(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <ApprovalProgressBar />
                  </div>
                </div>
              </div>

              {/* Second Row */}
              <div style={{ display: "flex", flexDirection: "row", padding: "inherit" }}>
                <div
                  className="pricing"
                  style={{ height: "300px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Vehicle Inventory</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenInventoryTable(true)}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <VehicleCard prop="inventory" />
                  </div>
                </div>
                <div
                  className="pricing"
                  style={{ height: "300px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Vehicle Sales</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenSalesTable(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <VehicleCard prop="showroom" />
                  </div>
                </div>
              </div>
            </div>
          </>
        </>
      )}
    </>
  );
}

export default ShowroomDashboard;
