import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ViewSpecs from "../../inward/action/ViewSpecs";
import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import "../style/ReleaseUpdate.scss";
import ComponentUpdateReject from "./ComponentUpdateReject";
import UpdateConfirmation from "./UpdateConfirmation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPauseCircle } from "@fortawesome/free-solid-svg-icons";

const VehicleReleaseUpdate = ({ closeModal, rowData, updatedData }) => {
  console.log(rowData);
  const [receivedBy, setReceivedBy] = useState();
  const [receivedDate, setReceivedDate] = useState(new Date(parseInt("5467")));
  const [viewSpecs, setViewSpecs] = useState();
  const [receivedQuantity, setReceivedQty] = useState();
  const [actionData, setActionData] = useState([]);
  const [showHold, setShowHold] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const fetchBom = async () => {
    const resp = await trackPromise(
      API.get(
        `qc-module/api/v1/vehicle/finishedgoods/action?requestID=${rowData.requestID}`
      )
    );
    const dataArray = resp.map((item, i) =>
      item.data.map((obj, i) => {
        return obj;
      })
    );
    const manageData = [];
    dataArray.map((item, i) => {
      return item.map((object, i) => manageData.push(object));
    });
    setActionData(manageData);
  };

  console.log(actionData);

  useEffect(() => {
    fetchBom();
  }, []);

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(`qc-module/api/v1/vehicle/newrequest/status`, {
        requestID: rowData.requestID,
        status: "In-Progress",
      })
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "980px", height: "550px", padding: "15px" }}
      >
        <div className="header">
          <h3>Request Details</h3>
        </div>

        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Request ID : {rowData.requestID}</span>
            <span>WO No.: {rowData.orderID}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Request Category : {rowData.requestCategory}</span>
            <span>
              Added Date :{" "}
              {moment(rowData.createdDate).format(
                "DD-MMM-YYYY"
              )}
            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Request Type : {rowData.requestType}</span>
          </div>
        </section>

        <div
          id="hold"
          style={{
            display: "flex",
            width: "100%",
            height: "60px",
            marginBottom: "30px",
            marginTop: "20px",
          }}
        >
          <span id="holdDate">
            Hold Date: {moment(rowData.actionDate).format("DD-MMM-YYYY/HH:mm")}
          </span>
          <view id="line"></view>
          <span style={{ margin: "10px", color: "#E37D04" }}>
            Hold Reason:{" "}
            {rowData.reason === "Other"
              ? `Other - ${rowData.reasonDescription}`
              : rowData.reason}
          </span>
        </div>

        <div
          className="body"
          style={{
            display: "flex",
            height: "400px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
              }}
            >
              <span>Segment: {actionData[0]?.vehicleSegment}</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
              }}
            >
              <span>Category: {actionData[0]?.vehicleCategory}</span>
            </div>
            <div id="POVendorHeading">
              <div style={{ width: "8%" }}>S.No.</div>
              <div style={{ width: "15%" }}>Vehicle Model</div>
              <div style={{ width: "15%" }}>Vehicle Variant</div>
              <div style={{ width: "10%" }}>Vehicle Type</div>
              <div style={{ width: "8%" }}>Color</div>
              <div style={{ width: "8%" }}>SNO</div>
              <div style={{ width: "8%" }}>VIN</div>
              <div style={{ width: "8%" }}>BIN</div>
              <div style={{ width: "20%" }}>Status</div>
            </div>
            <div>
              {actionData.map((item, index) => {
                return (
                  <div
                    id="POVendorContent"
                    style={{
                      borderBottom: "1px solid #999999",
                      boxShadow: "none",
                      padding: "0px",
                    }}
                    key={1}
                  >
                    <div style={{ width: "8%" }}>{++index}</div>
                    <div style={{ width: "15%" }}>{item.vehicleModel}</div>
                    <div style={{ width: "15%" }}>{item.vehicleVariant}</div>
                    <div style={{ width: "10%" }}>{item.vehicleType}</div>
                    <div style={{ width: "8%" }}>{item.vehicleColor}</div>
                    <div style={{ width: "8%" }}>{item.SNO}</div>
                    <div style={{ width: "8%" }}>{item.VIN}</div>
                    <div style={{ width: "8%" }}>{item.BIN}</div>
                    <div style={{ width: "20%" }}>
                      {item.status == "Failed" ? (
                        <button className="delete-btn">
                          <span className="delete-btn-font">{item.status}</span>
                        </button>
                      ) : item.status == "Pending Verification" ? (
                        <button className="deactive-btn">
                          <span className="deactive-btn-font">
                            {item.status}
                          </span>
                        </button>
                      ) : (
                        <button className="active-btn">
                          <span className="active-btn-font">{item.status}</span>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>

        <div className="footer">
          <button
            style={{
              width: "104px",
              height: "42px",
              background: "#fff",
              marginRight: "480px",
            }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
          <div>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => setShowHold(true)}
            >
              <HighlightOffOutlined style={{ fontSize: "25px" }} />
              Reject
            </button>
            {showHold && (
              <ComponentUpdateReject
                rowData={rowData}
                setOpen={closeModal}
                updatedData={updatedData}
                closeModal={setShowHold}
              />
            )}
          </div>
          <div>
            <button
              onClick={() => setOpenModal(true)}
              style={{ marginRight: "0", width: "200px", height: "42px" }}
              id="submit-button"
            >
              <CheckCircle style={{ fontSize: "17px" }} /> Start Verification{" "}
            </button>
            {openModal && (
              <UpdateConfirmation
                submitHandler={submitHandler}
                closeModal={setOpenModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleReleaseUpdate;
