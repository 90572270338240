import React, { useState } from "react";
import { faEdit,faBan, faTrash,faDotCircle, faAlignRight, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../components/modal/modal.scss";
import RequestForProcessing from "./RequestForProcessing";

const Action = ({updatedData,rowData,setIsOpen}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && (
        <RequestForProcessing updatedData={updatedData} rowData={rowData} closeModal={setShowModal} />
      )}
      
    
      
      <div className="action" style={{position: "relative"}} onBlur={() => setIsOpen(false)}>
        <div className="action vendoraction" style={{marginLeft:"-30px", marginTop:"0px",width:"250px"}}>
        <div> 
          <span id="edit" onClick={(e) => clickHandler(e)}>
          <FontAwesomeIcon icon={faEdit}/>  Request For Processing
          </span>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Action;
