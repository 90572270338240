import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../work-order/workorder.scss";
import ViewSpecs from "../../bom/bom-viewspecs";
import { KeyboardBackspace } from "@material-ui/icons";

const WoInProgressRejectedComp = ({ close,compdata }) => {

    const [viewSpecs,setViewSpecs]=useState();
    const [data, setData] = useState();

  //   const totalPrice = () => {
  //     let total = 0;
  //     data.map(item => (
  //         total += ((item.unitPrice * item.releasedQty + item.taxAmount))
  //     ))
  //     return total;
  // }

  console.log(compdata)

  const fetchReport = async () => {
    const resp = await trackPromise(API.get(`management/api/v1/rejectedData?orderID=${compdata.orderID}`));
    setData(resp);
  };

  useEffect(() => {
    fetchReport();
  }, []);

  console.log(data)

  return (
    <>
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "850px", height: "500px", padding: "15px" }}
      >

        <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>Required Components</h3>
          {/* <div>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Print 
          </button>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Export
          </button>
          </div> */}
        </div>

    <div className="body" style={{height:"340px",flexWrap:"nowrap"}}> 
         <div style={{width:"100%",overflow:"auto",height:"325px"}}>
          <table className="viewcomp_tbl"> 
          <thead>
            <tr>
                <th style={{fontSize:"small", fontWeight:"bold"}}>S.No.</th>
                <th style={{fontSize:"small", fontWeight:"bold"}}>Component Name</th>
                <th style={{fontSize:"small", fontWeight:"bold"}}>SKU Code</th>
                <th style={{fontSize:"small", fontWeight:"bold"}}>Part SNo.</th>
                <th style={{fontSize:"small", fontWeight:"bold"}}>Rejection Reason</th>
            </tr>
            </thead>
            {data?.components?.map((item, index)=>{
              return(
                <>
                <tr>
                <td style={{fontSize:"small"}} className="leftcell">{index+1}</td>
                <td style={{fontSize:"small"}}>{item?.componentName}</td>
                <td style={{fontSize:"small"}}>{item?.skuCode} </td>
                <td style={{fontSize:"small"}}>{item?.partsSno[0]?.partSno}</td>
                <td style={{fontSize:"small"}} className="rightcell">{item?.reason}</td>    
                </tr> 
                </>
              );
            })}
           </table>   
       </div>
   </div>

        <br />
        <div className="footer">
          <button onClick={() => close(false)}><KeyboardBackspace/>Back</button>
        </div>
      </div>
    </div>
    </>
  );
};

export default WoInProgressRejectedComp;