import React from 'react'
import {
    CancelOutlined,
    CheckCircle,
} from "@material-ui/icons";
import { trackPromise } from 'react-promise-tracker';
import API from '../../../utils/api';
import { errorNotification, successNotification } from '../../../utils/notifications';
import auth from '../../../utils/auth';

const RequestToStartVerification = ({ closeModal, rowData, tabName }) => {
    const submitHandler = async () => {
        let finalBody = {
            "role": ["Quality Control"],
            "title": "vehicle verification request",
            "body": `Vehicles Re-verification request ${rowData.requestID} placed by ${auth.getUserInfo().fullName} is pending  for action.`,
            "requestID": rowData.requestID
        }
        const resp = await trackPromise(API.post(`inventory-module/api/v1/grn/notification`, finalBody));
        const { success, message } = resp;
        if (success) {
            closeModal(false);
            successNotification(message, { autoClose: 3000 });
        } else {
            errorNotification(message, { autoclose: 3000 });
        }
    };

    const finsishedGoodsHandler = async () => {
        let finalBody = {
            "role": ["Quality Control"],
            "title": "vehicle verification request",
            "body": `${auth.getUserInfo().fullName} have requested for action on Finished Goods  Verification request ${rowData.requestID}`,
            "requestID": rowData.requestID
        }
        const resp = await trackPromise(API.post(`inventory-module/api/v1/grn/notification`, finalBody));
        const { success, message } = resp;
        if (success) {
            closeModal(false);
            successNotification(message, { autoClose: 3000 });
        } else {
            errorNotification(message, { autoclose: 3000 });
        }
    };

    const pendingSubmitHandler = async () => {
        let finalBody = {
            "role": ["Quality Control"],
            "title": "component pending verification request",
            "body": tabName == 'components' ? `Components Verification Request ${rowData.requestID} ${auth.getUserInfo().fullName} is pending  for action.` : `Spares Re-Verification Request ${rowData.requestID} placed by ${auth.getUserInfo().fullName} is pending for action`,
            "requestID": rowData.requestID
        }
        const resp = await trackPromise(API.post(`inventory-module/api/v1/grn/notification`, finalBody));
        const { success, message } = resp;
        if (success) {
            closeModal(false);
            successNotification(message, { autoClose: 3000 });
        } else {
            errorNotification(message, { autoclose: 3000 });
        }
    };

    const onHoldSubmitHandler = async () => {
        let finalBody = {
            "role": ["Quality Control"],
            "title": "component onHold verification request",
            "body": tabName == 'components' ? `${auth.getUserInfo().fullName} have requested to relase and update the Components Verification Request ${rowData.requestID}` : `${auth.getUserInfo().fullName} have requested for release and update of Spares Re-Verification request ${rowData.requestID}`,
            "requestID": rowData.requestID
        }
        const resp = await trackPromise(API.post(`inventory-module/api/v1/grn/notification`, finalBody));
        const { success, message } = resp;
        if (success) {
            closeModal(false);
            successNotification(message, { autoClose: 3000 });
        } else {
            errorNotification(message, { autoclose: 3000 });
        }
    };


    return (
        <div className='container1'>
            <div className="modalContainer" style={
                { width: '400px', height: '200px' }
            }>

                <div className="body" style={{ justifyContent: 'center', fontSize: '16px' }}>
                    Are you sure to sent Request <br /> for verification ?
                </div>
                <div className="footer" style={{ justifyContent: 'center' }}>
                    <button
                        style={{
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                            padding: "5px 10px",
                            marginRight: "10px",
                            background: 'white',
                            color: "#0A18A3",
                        }}
                        onClick={closeModal(false)}
                    >
                        <CancelOutlined style={{ fontSize: "17px" }} />
                        Cancel
                    </button>
                    <button
                        onClick={rowData.status == "Pending At QC_New Request" ? pendingSubmitHandler : rowData.status == "On Hold_On Hold" ? onHoldSubmitHandler : tabName == 'finishedGoods' ? finsishedGoodsHandler : submitHandler}
                        style={{
                            background: "#0A18A3",
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                            padding: "5px 10px",
                            color: "white",
                        }}
                    >
                        <CheckCircle style={{ fontSize: "17px" }} />
                        Confirm
                    </button>

                </div>
            </div>
        </div>
    )
}

export default RequestToStartVerification