import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import "./modal.scss";
import { successNotification, errorNotification } from "../../utils/notifications";
import { IoMdRefresh } from "react-icons/io";
import auth from "../../utils/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faUpload } from "@fortawesome/free-solid-svg-icons";

const Edit = ({ updatedData, editData, closeModal,closeOn }) => {
  console.log(editData.city);
  const [formValue, setFormValue] = useState({
    role: editData.role,
    fName: editData.fName,
    lName: editData.lName,
    locationID: editData.locationID,
  });

  const [locationData, setLocationData] = useState([]);
  const editDetails = {
    userID: editData.userID,
    role: formValue.role,
    fName: formValue.fName,
    lName: formValue.lName,
    locationID: formValue.locationID,
  };

  const refresh = () => {
    updatedData();
  };

  const fetchLocation = async () => {
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/users/getBusinessUserInfo`)
    );
    console.log(resp);
    setLocationData(resp.locations);
  };
  useEffect(() => {
    fetchLocation();
  }, []);


  const validateInputs = () => {
   
  
    // Validate first name and last name
    if (
      !/^[a-zA-Z\s]+$/.test(formValue.fName) ||
      !/^[a-zA-Z\s]+$/.test(formValue.lName)
    ) {
      errorNotification(
        "First name and last name are required and should only contain alphabets and spaces."
      );
      return false;
    }

  
    return true;
  };

  

  const submitHandler = async () => {

    // if (!validateInputs()) {
    //   return;
    // }


    console.log(formValue);
    const resp = await trackPromise(
      API.put(`admin-module/api/v1/users`, editDetails)
    );
    const { success, message } = resp;
    if (success) {
      closeModal(false);
      closeOn(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };


  const closeHandler = ()=>{
    closeModal(false)
    closeOn(false)
  }

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Edit User Details</h3>
        </div>

        <div className="body">
          <label style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ float: 'left', marginLeft: '-180px', fontSize: '13px', fontWeight: '500' }}>Sub Admin</label>
            <select
              disabled
              name="role"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              value={editData.role}
              style={{ marginTop: '-8px' }}
            >
              {auth.isSubAdmin() ? "" : <option>Sub Admin</option>}
              <option>Procurement</option>
              <option>Production</option>
              <option>Inventory</option>
              <option>Shop floor</option>
              <option>Management</option>
              <option>Quality Control</option>
            </select>
          </label>

          <label style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ float: 'left', marginLeft: '-180px', fontSize: '13px', fontWeight: '500' }}>First Name</label>
            <input
              type="text"
              name="fName"
              defaultValue={editData.fName}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="First Name"
              autoComplete="off"
              style={{ marginTop: '-8px' }}
            />
          </label>
          <label style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ float: 'left', marginLeft: '-180px', fontSize: '13px', fontWeight: '500' }}>Last Name</label>
            <input
              type="text"
              name="lName"
              defaultValue={editData.lName}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Last Name"
              autoComplete="off"
              style={{ marginTop: '-8px' }}
            />
          </label>
          <label style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ float: 'left', marginLeft: '-180px', fontSize: '13px', fontWeight: '500' }}>Location</label>
            <select
              name="locationID"
              className="input-config"
              defaultValue={editData.city}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ marginTop: '-8px' }}
            >
              {locationData.map((item) => (
                <option value={item.locationID}>{item.city}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="footer">
          <button onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button id="submit-button" onClick={submitHandler}>
            <FontAwesomeIcon icon={faUpload} /> Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default Edit;
