import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification, errorNotification } from "../../../utils/notifications"

const OpenConfirmation = ({ closeModal, getConfirmFlag }) => {
    const [confirmationSubmitted, setConfirmationSubmitted] = useState(false);

    const handleConfirm = () => {
        setConfirmationSubmitted(true);
        getConfirmFlag(true);
        closeModal(false);
    }

    return (
        <div className="container1">
            <div className="modalContainer" style={{ width: "410px", height: "210px" }}>
                <div className="body" style={{ flexDirection: "column", marginTop: "58px", fontSize: "18px", textAlign: "center" }}>
                    <span>Are you sure you want to edit the email?</span>
                    <span>This will reset the password.</span>
                </div>
                <div className="footer" style={{ justifyContent: "center", marginBottom: "16px" }}>
                    <button onClick={() => closeModal(false)}>Cancel</button>
                    <button id="submit-button" onClick={handleConfirm}>Proceed</button>
                </div>
            </div>
        </div>
    );
};

export default OpenConfirmation;
