import React from "react";
import {Tabs} from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import Vehicle from "./Vehicle";
// import Batteries from './Batteries'

const Vehicle = React.lazy(()=>import("./Vehicle"))
const Batteries = React.lazy(()=>import('./Batteries'))


const ShowroomVehicleBatteries =()=>{
    const tabs = [
        {
          id:"SHOM1T1", 
          label: TabLabels.VEHICLES,
          Component: Vehicle
        },
        {
          id:"SHOM1T2",
          label: TabLabels.BATTERIES,
          Component: Batteries
        },
    ];

    return(
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
    
    )
}

export default ShowroomVehicleBatteries