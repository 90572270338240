import React from "react";
import { Tabs } from "react-simple-tabs-component";
import "../settings-tabs/tabs.scss";
import { TabLabels } from "../../utils/app-constants";

const ShowroomCustomerEnquiryTabs = React.lazy(() => import("./ShowroomCustomerEnquiry/ShowroomCustomerEnquiryTabs"))
const ShowroomVehicleBookingTabs = React.lazy(() => import("./ShowroomVehicleBooking/ShowroomVehicleBookingTabs"))
const ShowroomVehicleSalesTabs = React.lazy(() => import("./ShowroomVehicleSales/ShowroomVehicleSalesTabs"))
const ShowroomSalesHistory = React.lazy(() => import("./ShowroomSalesHistory/showroomSalesHistory"))
const ShowroomSalesReport = React.lazy(() => import("./showroomSalesReport/showroomSalesReport"))

const tabs = [
  {
    id: "SHOM4T1",
    label: TabLabels.CUSTOMER_ENQUIRY,
    Component: ShowroomCustomerEnquiryTabs
  },
  {
    id: "SHOM4T2",
    label: TabLabels.VEHICLE_BOOKING,
    Component: ShowroomVehicleBookingTabs
  },
  {
    id: "SHOM4T3",
    label: TabLabels.VEHICLE_SALES,
    Component: ShowroomVehicleSalesTabs
  },
  {
    id: "SHOM4T4",
    label: TabLabels.SALES_HISTORY,
    Component: ShowroomSalesHistory
  },
  {
    id: "SHOM4T5",
    label: TabLabels.SALES_REPORT,
    Component: ShowroomSalesReport
  }
];
function ShowroomCustomerSalesTabs() {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
}

export default ShowroomCustomerSalesTabs