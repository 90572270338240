import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "../../../components/modal/modal.scss";
import moment from "moment";
import Action from "./ManageUserChargesAction";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ManageFeaturesAction from "./FeaturesAction/ManageFeaturesAction";
import AppConstants from "../../../utils/app-constants";

const ManageFeatures = () => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState(null); // To track which action button is clicked
  const [UsersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Monthly");

  // Fetch the report data
  const fetchReport = async () => {
    const resp = await trackPromise(API.get(`systemadmin/api/v1/features`));
    setUsersData(resp?.data || []); // Ensure empty array if no data is fetched
  };

  useEffect(() => {
    fetchReport();
  }, []); // Fetch data on component mount

  // Action button formatter
  const actionFormatter = (cell, row, rowIndex) => {
    return (
      <div>
        <button className="action-btn" onClick={() => toggleAction(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          // Pass selectedOption to the Action component
          <ManageFeaturesAction
            updatedData={fetchReport}
            rowData={row}
            setIsOpen={setIsOpen}
            rowIndex={rowIndex}
            selectedOption={selectedOption} // New prop passed here
          />
        ) : null}
      </div>
    );
  };

  // Toggle action button visibility
  const toggleAction = (rowIndex) => {
    if (actionShow === rowIndex) {
      setActionShow(!actionShow);
      // on();
    } else {
      setActionShow(rowIndex); 
      on();
    }
  };

  // Filter UsersData based on the selected option (Monthly or Yearly)
  const filteredUsersData = UsersData.filter((item) => {
    if (selectedOption === "Monthly") {
      return item.time === "Monthly"; 
    }
    return item.time === "Yearly"; 
  });

  // Reset actionShow when selectedOption changes
  useEffect(() => {
    setActionShow(null); // Close any open action when option changes
  }, [selectedOption]);




  const statusFormatter = (cell, row, rowIndex) => {
    return cell === AppConstants.ACTIVE ? (
      <button className="active-btn">
        <span className="active-btn-font">Active</span>
      </button>
    ) : cell === AppConstants.REACTIVATED ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">Reactivated</span>
      </button>
    ) : cell === AppConstants.DEACTIVATED ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-font">Deactivated</span>
      </button>
    ) : cell === AppConstants.DELETE ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Delete</span>
      </button>
    ) : (
      cell
    );
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      sort: true,
    },
    {
      text: "Features",
      dataField: "name",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Created Date",
      dataField: "createdDate",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row) => moment(row.createdDate).format("DD-MMM-YYYY/HH:mm"),
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter:statusFormatter
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];


  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }


  return (
    <div style={{ position: "relative", zIndex: "1" }}>

     <div id="overlay" onClick={(e) => off(e)}></div>
      <div className="heading bussiness" style={{marginBottom:"-5px"}}>
        <span>Manage Features</span>
      </div>

      {/* Monthly / Yearly Radio Buttons */}
      <div style={{ display: "flex", flexDirection: "row", margin: "20px 0px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="radio"
            value="Monthly"
            checked={selectedOption === "Monthly"}
            onChange={() => setSelectedOption("Monthly")}
          />
          <span style={{ width: "90px", padding: "6px" }}>Monthly</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="radio"
            value="Yearly"
            checked={selectedOption === "Yearly"}
            onChange={() => setSelectedOption("Yearly")}
          />
          <span style={{ width: "90px", padding: "6px" }}>Yearly</span>
        </div>
      </div>

      <div className="heading bussiness" style={{ marginBottom: "15px" }}>
        <span>Features</span>
      </div>

      {/* Table with filtered data */}
      <ToolkitProvider keyField="id" data={filteredUsersData} columns={columns} search>
        {(props) => (
          <>
            <BootstrapTable
              wrapperClasses="table-responsive"
              rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
              pagination={paginationFactory({
                hidePageListOnlyOnePage: true,
                showTotal: true,
                hideSizePerPage: true,
                paginationSize: 2,
                sizePerPage: "10",
              })}
              noDataIndication="No Data Found"
              {...props.baseProps}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ManageFeatures;
