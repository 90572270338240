import React, { useState } from 'react'
import { CancelOutlined, CheckCircle } from "@material-ui/icons";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../../../../utils/notifications";

const RequestForReleaseAndProcess = ({
  closeModal,
  formData,
  rowData,
}) => {
  
  const submitHandler = async () => {
    const resp = await trackPromise(API.post(``,editDetails ));
    if (resp.success) {
      return successNotification(resp.message, { autoClose: 10000 });
    } else {
      return errorNotification(resp.message, { autoClose: 10000 });
    }
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "400px", height: "200px" }}
      >
          <div
            className="body"
            style={{
              justifyContent: "center",
              fontSize: "16px",
              padding: "0px",
              marginTop: "40px",
            }}
          >
            Are you sure to Place release request
            <br />
            to Finance ?
          </div>
        <div className="footer" style={{ justifyContent: "center" }}>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: "white",
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
            onClick={submitHandler}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestForReleaseAndProcess;
