import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import auth from "../../../utils/auth";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPlus,
  faTimes,
  faTimesCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import JobCardDetails from "./JobCardDetails";
import moment from "moment";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { textAlign } from "@mui/system";

const RequestForSpares = ({ closeModal, rowData, updatedData , setIsOpen}) => {
  console.log("rowData-->", rowData);

  const Data = [
    ...(rowData?.serviceDetails?.additionalSpares || []),
    ...(rowData?.serviceDetails?.mandatoryReplacements || []),
  ];
  console.log(Data);
  const [openSpare, setOpenSpare] = useState();
  const [spareData, setSpareData] = useState();
  const [additional, setAddtional] = useState(false);
  const [showAdditional, setShowAddtional] = useState(false);
  const [blankData, setBlankData] = useState([
    {
      skuCode: "",
      componentID: "",
      componentName: "",
      category: "",
      subCategory: "",
      requiredQty: "",
      sparePartMrp: "",
      assignedQty:0,
    },
  ]);

  console.log("blankData-->", blankData);

  const [sparesData, setSparesData] = useState();

  const fetchSpareData = async () => {
    const res = await trackPromise(
      API.get(`serviceAdvisor-module/api/v1/serviceJobs/getAllSpares`)
    );
    console.log(
      "res-->",
      res.flatMap((item) => item?.spareDetails)
    );
    setSparesData(res.flatMap((item) => item?.spareDetails));
  };

  useEffect(() => {
    fetchSpareData();
  }, []);

  console.log("sparesData-->",sparesData);

  // const handleInputChanger = async (event, index) => {
  //   const { name, value } = event.target;
  //   console.log("value-->",value)
  //   const field = [...blankData];
  //   field[index][name] = value;
  //   const data = sparesData?.filter((item)=>item?.sparePartname == value)
  //   field[index][name] = data;
  //   console.log("data-->",data)
  //   setBlankData(field);
  // };


  let selectedData;

  const handleInputChanger = async (event, index) => {
    const { name, value } = event.target;
    const updatedBlankData = [...blankData];
    updatedBlankData[index][name] = value;
    
    selectedData = sparesData.filter(item => item?.skuCode == value);

    console.log("yoo-->",selectedData)

    if (selectedData) {
      updatedBlankData[index] = {
        ...updatedBlankData[index],
        componentID: selectedData[0]?.componentID,
        skuCode: selectedData[0]?.skuCode,
        sparePartMrp: selectedData[0]?.sparePartMrp,
        componentName: selectedData[0]?.sparePartname,
        category: selectedData[0]?.category,
        subCategory: selectedData[0]?.subCategory,
      };
    }

    setBlankData(updatedBlankData);
  };


  const handleChanger = async (event, index) => {
    const { name, value } = event.target;
    console.log("value-->",value)
    const field = [...blankData];
    field[index][name] = value;
    setBlankData(field);
  };




  const addFormFields = () => {
    setBlankData([
      ...blankData,
      {
        skuCode: "",
        componentID: "",
        componentName: "",
        category: "",
        subCategory: "",
        requiredQty: "",
        sparePartMrp: "",
        assignedQty:0,
      },
    ]);
  };

  const removeFields = (index) => {
    const field = [...blankData];
    field.splice(index, 1);
    setBlankData(field);
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(`serviceAdvisor-module/api/v1/serviceJobs/updateStatus`, {
        jobID: rowData?.jobID,
        status: "Spares Requested",
        additionalSpares: additional ? blankData :[]
      })
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      setIsOpen(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const jobCardFormatter = (rowData) => {
    setOpenSpare(true);
    setSpareData(rowData);
  };

  function handleAdditional() {
    setAddtional(false);
    setShowAddtional(false);
  }

  function handlerAdditional() {
    setAddtional(true);
    setShowAddtional(true);
  }

  console.log("additional", additional);
  console.log("showAdditional", showAdditional);



  const closeHandler = ()=>{
    setIsOpen(false)
    closeModal(false)
  }

  console.log(blankData)

  return (
    <div className="container1">
      {openSpare ? (
        <JobCardDetails rowData={rowData} closeModal={setOpenSpare} />
      ) : (
        ""
      )}
      <div
        className="modalContainer"
        style={{
          width: "80%",
          height: "fit-content",
          padding: "15px",
          overflow: "scroll",
        }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Request for Spares</h3>
          {/* <div>
            <button className="action-btn" style={{ color: "blue" }}>
              <PrintIcon />
              &nbsp;Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              <IosShareIcon />
              &nbsp;Export
            </button>
          </div> */}
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding: 7,
          }}
        >
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            Vehicle Reg. No: {rowData?.vehicleRegNo}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Job Card Date: {moment(rowData?.jobDate).format("DD-MMM-YYYY/HH:mm")}
          </span>
          <span
            onClick={() => jobCardFormatter(rowData)}
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
              color: "blue",
              cursor:"pointer"
            }}
          >
            Job Card ID: {rowData?.jobID}
          </span>
        </section>
        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div>
              <Table>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>S.No</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>SKU Code</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Spare Part Name
                    </td>
                    <td style={{ backgroundColor: "#ebebeb" }}>Category</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>Sub-Category</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Required Qty.
                    </td>
                  </tr>
                  {rowData?.inspectionsChecks?.spares?.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item?.skuCode}</td>
                        <td>{item?.componentName}</td>
                        <td>{item?.category}</td>
                        <td>{item?.subCategory}</td>
                        <td>{item?.requiredQty}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            {additional && (
              <div>
                <div
                  style={{
                    background: "#0a18a3",
                    color: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "6px",
                  }}
                  onClick={handleAdditional}
                >
                  <span>Additional Spares</span>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                      color: "white",
                      border: "2px solid",
                      height: "25px",
                      width: "25px",
                      borderRadius: "100%",
                    }}
                  />
                </div>
                <div style={{ marginTop: "15px" }}>
                  {Array.isArray(blankData) &&
                    blankData?.map((item, index) => (
                      <div
                        style={{ display: "flex", width: "100%" }}
                        key={index}
                      >
                        <div
                          style={{ margin: "0px 5px 0px 5px", width: "22%" }}
                        >
                          <label
                            style={{
                              fontSize: "13px",
                              fontWeight: "500",
                              width: "auto",
                              marginLeft: "-100px",
                            }}
                          >
                            Select SKU Code
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <select
                            className="input-config"
                            id="skuCode"
                            name="skuCode"
                            value={blankData.filter(item => item?.skuCode == selectedData) ? item?.skuCode :""}
                            onChange={(event) =>
                              handleInputChanger(event, index)
                            }
                            style={{ width: "100%", marginTop: "-7px" }}
                          >
                            <option value="" disabled>
                              Select SKU Code
                            </option>
                            {sparesData?.map((el, index) => (
                              <option key={index}>
                                {el.skuCode}
                              </option>
                            ))}
                            {/* Add more options as needed */}
                          </select>
                        </div>

                        <div
                          style={{ margin: "0px 5px 0px 5px", width: "22%" }}
                        >
                          <label
                            style={{
                              fontSize: "13px",
                              fontWeight: "500",
                              width: "auto",
                              marginLeft: "-80px",
                            }}
                          >
                            Spare Part Name
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <input
                            className="input-config"
                            type="text"
                            id="sparePartName"
                            name="sparePartName"
                            value={blankData.filter(item => item?.skuCode == selectedData) ? item?.componentName :""}
                            placeholder="Spare Part Name"
                            style={{ width: "100%", marginTop: "-7px" }}
                          />
                        </div>

                        <div
                          style={{ margin: "0px 5px 0px 5px", width: "22%" }}
                        >
                          <label
                            style={{
                              fontSize: "13px",
                              fontWeight: "500",
                              width: "auto",
                              marginLeft: "-85px",
                            }}
                          >
                            Spare Category
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <input
                            className="input-config"
                            type="text"
                            id="category"
                            name="category"
                            value={blankData.filter(item => item?.skuCode == selectedData) ? item?.category :""}
                            placeholder="spare Category"
                            style={{ width: "100%", marginTop: "-7px" }}
                          />
                        </div>

                        <div
                          style={{ margin: "0px 5px 0px 5px", width: "22%" }}
                        >
                          <label
                            style={{
                              fontSize: "13px",
                              fontWeight: "500",
                              width: "auto",
                              marginLeft: "-63px",
                            }}
                          >
                            Spare Sub Category
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <input
                            className="input-config"
                            type="text"
                            id="subCategory"
                            name="subCategory"
                            value={blankData.filter(item => item?.skuCode == selectedData) ? item?.subCategory :""}
                            placeholder="spare Sub Category"
                            style={{ width: "100%", marginTop: "-7px" }}
                          />
                        </div>

                        <div
                          style={{ margin: "0px 5px 0px 5px", width: "22%" }}
                        >
                          <label
                            style={{
                              fontSize: "13px",
                              fontWeight: "500",
                              width: "auto",
                              marginLeft: "-100px",
                            }}
                          >
                            Required Qty.
                          </label>
                          <br />
                          <input
                            className="input-config"
                            type="text"
                            id="requiredQty"
                            name="requiredQty"
                            value={blankData.filter(item => item?.skuCode == selectedData) ? item?.requiredQty :""}
                            placeholder="RequiredQty"
                            style={{ width: "100%", marginTop: "-7px" }}
                            onChange={(event) =>
                              handleChanger(event, index)
                            }
                          />
                        </div>

                        {index == 0 ? (
                          <button
                            type="button"
                            className="btn btn-md"
                            onClick={() => addFormFields()}
                            style={{ margin: "25px" }}
                          >
                            <div style={{ color: "green" }}>
                              <FontAwesomeIcon icon={faPlus} />
                            </div>
                          </button>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-md"
                              onClick={() => removeFields(index)}
                              style={{ margin: "2px" }}
                            >
                              <div style={{ color: "red" }}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </div>
                            </button>
                            <button
                              type="button"
                              className="btn btn-md"
                              onClick={() => addFormFields()}
                              style={{ margin: "2px" }}
                            >
                              <div style={{ color: "green" }}>
                                <FontAwesomeIcon icon={faPlus} />
                              </div>
                            </button>
                          </>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            )}

            {/* <div style={{ height: "230px", overflowY: "scroll" }}>
               {UsersData?.map((item, index) => (
                <div
                  id="costingTableContent"
                  style={{ display: "flex",justifyContent:"space-between"  }}
                >
                  <div style={{ overflowY: "auto", width:"10%" }}>
                    {}
                  </div>
                  <div style={{ overflowY: "auto", width:"32%" }}>
                    {}
                  </div>
                  <div style={{ overflowY: "auto", width:"54%" }}>
                    {}
                  </div>
                </div>
              ))} 
            </div> */}
          </div>
        </div>

        <br />
        <div
          className="footer"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            {!additional && (
              <button
                id="submit-button"
                style={{ width: "fit-content" }}
                onClick={handlerAdditional}
              >
                <FontAwesomeIcon icon={faCheckCircle} /> Request Additional
                Spare
              </button>
            )}
          </div>

          <div>
            <button onClick={closeHandler}>
              <FontAwesomeIcon icon={faTimesCircle} /> Cancel
            </button>
            <button id="submit-button" onClick={submitHandler}>
              <FontAwesomeIcon icon={faCheckCircle} /> Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestForSpares;
