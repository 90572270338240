import React, { useState, useEffect }from 'react'
import Group from '../QcDashboard/assets/Group.png'
import Group2 from '../QcDashboard/assets/Group2.png'
import Vector from '../QcDashboard/assets/Vector.svg'
import Vector3 from '../QcDashboard/assets/Vector3.svg'
import Group3 from '../QcDashboard/assets/Group3.png'
import Group4 from '../QcDashboard/assets/Group4.png'
import Group5 from '../QcDashboard/assets/Group5.png'
import Group6 from '../QcDashboard/assets/Group6.png'
import { trackPromise } from "react-promise-tracker";
import API from '../../utils/api'

const Business = ({businessData}) =>{
   console.log(businessData)
  return (
    <div className='requestBody'>
      <div className='request' style={{width:"32%"}}>
        <img src={Group} style={{ marginLeft: "8px" }} alt="group" />
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
          <div style={{ fontSize: "24px", fontWeight: "450" }}>{businessData?.totalReqs}&nbsp;{businessData?.totalReqsChange > 0 ? <><img src={Vector} alt="vector" /><span style={{fontSize:"14px",color:"green"}}>{`+${businessData?.totalReqsChange}`}</span></>:businessData?.totalReqsChange === 0 ? <><img src={Vector} alt="vector" /><span style={{fontSize:"14px",color:"green"}}>{`+${businessData?.totalReqsChange}`}</span></>:<><img src={Vector3} alt="vector" /><span style={{fontSize:"14px",color:"red"}}>{`${businessData?.totalReqsChange}`}</span></>}</div>
          <span style={{ color: "#808080", fontSize: "14px" }}>Total Registered</span>
        </div>
      </div>

      <div className='request' style={{width:"32%"}}>
        <img src={Group2} style={{ marginLeft: "8px" }} alt="group" />
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
          <div style={{ fontSize: "24px", fontWeight: "450" }}>{businessData?.approvedReqs}&nbsp;{businessData?.approvedReqsChange > 0 ? <><img src={Vector} alt="vector" /><span style={{fontSize:"14px",color:"green"}}>{`+${businessData?.approvedReqsChange}`}</span></>:businessData?.approvedReqsChange === 0 ? <><img src={Vector} alt="vector" /><span style={{fontSize:"14px",color:"green"}}>{`+${businessData?.approvedReqsChange}`}</span></>:<><img src={Vector3} alt="vector" /><span style={{fontSize:"14px",color:"red"}}>{`${businessData?.approvedReqsChange}`}</span></>}</div>
          <span style={{ color: "#808080", fontSize: "14px" }}>Subscribed</span>
        </div>
      </div>

      <div className='request' style={{width:"32%"}}>
        <img src={Group6} style={{ marginLeft: "8px" }} alt="group" />
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
          <div style={{ fontSize: "24px", fontWeight: "450" }}>{businessData?.rejectedReqs}&nbsp;{businessData?.rejectedReqsChange > 0 ? <><img src={Vector} alt="vector" /><span style={{fontSize:"14px",color:"green"}}>{`+${businessData?.rejectedReqsChange}`}</span></>:businessData?.rejectedReqsChange === 0 ? <><img src={Vector} alt="vector" /><span style={{fontSize:"14px",color:"green"}}>{`+${businessData?.rejectedReqsChange}`}</span></>:<><img src={Vector3} alt="vector" /><span style={{fontSize:"14px",color:"red"}}>{`${businessData?.rejectedReqsChange}`}</span></>}</div>
          <span style={{ color: "#808080", fontSize: "14px" }}>Unsubscribed</span>
        </div>
      </div>
    </div>
  )
}

export default Business