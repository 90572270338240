import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import NewRequests from "./NewRequests";
import ManageRequests from "./ManageRequests";

const tabs = [
  {
    label: TabLabels.NEW_REQUESTS,
    Component: NewRequests
  },
  {
    label: TabLabels.MANAGE_REQUESTS,
    Component: ManageRequests
  },
];

const VehiclesReturns = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default VehiclesReturns;