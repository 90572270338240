import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import auth from "../../../../utils/auth";
import ViewSpecs from "../../../bom/bom-viewspecs";

const ViewComponentsBom = ({ close, compdata, location }) => {

  console.log("compdata-->",compdata, "cehk loca",location)

  const [viewSpecs, setViewSpecs] = useState(false);

  const filterCompData = compdata.filter((item)=>{
    return item?.primary
  })

  console.log("filter data------",filterCompData)

  //   const totalPrice = () => {
  //     let total = 0;
  //     data.map(item => (
  //         total += ((item?.unitPrice * item?.releasedQty + item?.taxAmount))
  //     ))
  //     return total;
  // }

  const vendorDetails = [];
  compdata?.map((item, index) => {
    item?.vendorDetails?.map((data, i) => {
      if (data?.selected == true) {
        vendorDetails?.push(data)
      }
    })
  })


  const unitPrice = []

  compdata?.map((item, index) => {
    item?.vendorDetails?.map((value, i) => {
      if (value?.selected == true) {
        value?.deliveryDetails?.map((data, ind) => {
          if (data?.deliveryLocation == location) {
            unitPrice?.push(data?.unitPrice)
          }
        })
      }
    })
  })

  const calculateTotalPrice = filterCompData?.map((item) => {
    // Find the delivery detail where deliveryLocation matches the location
    const matchingDeliveryDetail = item?.vendorDetails[0]?.deliveryDetails?.find(
      (deliveryDetail) => deliveryDetail.deliveryLocation === location
    );
  
    // If a match is found, perform the multiplication
    if (matchingDeliveryDetail) {
      const unitPrice = parseInt(matchingDeliveryDetail?.unitPrice);
      const componentQuantity = parseInt(item?.componentQuantity);
      
      // Return the calculated total price
      if (!isNaN(unitPrice) && !isNaN(componentQuantity)) {
        return unitPrice * componentQuantity;
      }
    }
  
    // Return NaN if no match is found or invalid values
    return NaN;
  });
  

  // const totalPrice = calculateTotalPrice?.reduce((acc, value) => {
  //   return acc + value
  // }, 0)


  const totalValue = compdata?.reduce((accumulator, item, index) => {
    const unitPriceValue = item?.vendorDetails[0]?.deliveryDetails?.some((deliveryDetail) => {
      if (deliveryDetail.deliveryLocation === item?.location) {
        return parseInt(deliveryDetail.unitPrice) * parseInt(item?.componentQuantity);
      }
      return false; 
    }) || NaN 
    const quantity = parseInt(item?.componentQuantity) || 0; // Ensure quantity is a number
    return accumulator + (unitPriceValue * quantity);
  }, 0);
  



  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "1170px", height: "521px", padding: "15px" }}
        >

          <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Bom Components Details</h3>
            <div>
              {/* <button
                className="action-btn"
                style={{ color: "blue" }}>
                Print
              </button>
              <button
                className="action-btn"
                style={{ color: "blue" }}>
                Export
              </button> */}
            </div>
          </div>

          <div className="body" style={{ height: "340px" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column", fontSize: "12px", fontWeight: "450" }}>
                <span>Total Component : {filterCompData?.length}</span>
                <span>{`Total Cost(${auth?.getUserInfo()?.currencySymbol}) :`}  {calculateTotalPrice}</span>
              </div>
            </div>

            <div style={{ width: "100%", overflow: "auto", height: "325px" }}>
              <table style={{ width: "130%" }} className="viewcomp_tbl">
                <thead >
                  <tr >
                    <th>S.No.</th>
                    <th>Component Name</th>
                    <th>SKU Code</th>
                    <th>Category</th>
                    <th>Sub Category</th>
                    <th>Specification</th>
                    <th>Vendor Part Name</th>
                    <th>Vendor Part Code</th>
                    <th>{`Unit Price(${auth.getUserInfo().currencySymbol})`}</th>
                    <th>Qty.</th>
                    <th>{`Sub Total(${auth.getUserInfo().currencySymbol})`}</th>
                  </tr>
                </thead>
                <>
                  <tbody >
                    {filterCompData?.map((item, index) => {
                      if(item?.primary){
                        return (
                          <>
                            {viewSpecs === index && <ViewSpecs rowData={item} close={setViewSpecs} />}
                            <tr >
                              <td className="leftcell">{index + 1}</td>
                              <td>{item?.componentName}</td>
                              <td>{item?.skuCode} </td>
                              <td>{item?.category}</td>
                              <td>{item?.subCategory}</td>
                              <td>{<span onClick={() => setViewSpecs(index)}
                                style={{ color: "#0a18a3", fontWeight: "500", cursor: 'pointer' }}>
                                View Specs</span>}
                              </td>
                              <td>{item?.vendorDetails[0]?.vendorPartName}</td>
                              <td>{item?.vendorDetails[0]?.vendorPartCode}</td>
                              <td>{item?.vendorDetails[0]?.deliveryDetails[0]?.unitPrice}</td>
                              <td>{item?.componentQuantity}</td>
                              <td className="rightcell">
  {(() => {
    const matchingDelivery = item?.vendorDetails[0]?.deliveryDetails?.find(
      (deliveryDetail) => deliveryDetail.deliveryLocation === location
    );
    
    if (matchingDelivery) {
      // If matching delivery is found, perform the calculation
      const unitPrice = parseInt(matchingDelivery?.unitPrice);
      const componentQuantity = parseInt(item?.componentQuantity);
      
      if (!isNaN(unitPrice) && !isNaN(componentQuantity)) {
        return unitPrice * componentQuantity; // Perform and return the multiplication
      }
    }
    
    return NaN; // If no match or invalid numbers, return NaN
  })()}
</td>


                            </tr>
                          </>
                        )
                      }else{
                        return null
                      }
                      

                    })}

                  </tbody>

                </>

              </table>


            </div>
          </div>
         

          <br />
          <div className="footer">
            <button onClick={() => close(false)}><KeyboardBackspace/> Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewComponentsBom;