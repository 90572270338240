import React, { useState } from "react";
import {
    KeyboardBackspace,
    CheckCircle,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisH
} from "@fortawesome/free-solid-svg-icons";
import AppConstants from "../../../utils/app-constants";
import CompleteConfirm from "./actions/CompleteConfirm";
import auth from "../../../utils/auth";

const SpareInProgress = ({closeModal , rowData}) => {
    console.log(rowData)
    const [openQuant, setOpenQuant] = useState(false);
    const [status, setStatus] = useState("");
    const [open, setOpen] = useState(false);


    function on() {
        document.getElementById("overlay").style.display = "block";
      };
    
      function off() {
        document.getElementById("overlay").style.display = "none";
        setOpen(false);
      }
    
      const actionHandler = () => {
        setOpen(true);
        on();
      };

      const handleStatusChange = (e,id) => {
         
      };

    return (
        <div className="container1">
        <div id="overlay" onClick={(e) => off(e)}></div>
            <div
                className="modalContainer"
                style={{ width: "900px", height: "500px", padding: '15px' }}
            >
                <div className="header">
                    <h3>View & Updates</h3>
                </div>


                <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Request ID : {rowData.requestID}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Request Type : {rowData.requestType}</span>
                        </div>
                    </section>

                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >


                    <div className="shopfloortbl"  style={{width:"100%",overflow:"scroll"}}>
          <table className="sfaction_tbl" style={{width:"220%"}} > 
            <thead>
            <tr>
                
                <th>S.NO</th>
                <th>Spare Part Name</th>
                <th>SKU Code</th>
                <th>{`Unit Price(${auth.getUserInfo().currencySymbol})`}</th>
                <th>Manuf. Margin(%)</th>
                <th>{`Other Charges(${auth.getUserInfo().currencySymbol})`}</th>
                <th>{`Ser Cent. Price(${auth.getUserInfo().currencySymbol})`}</th>
                <th>Ser Cent. Margin</th>
                <th>{`Spare part MRP(${auth.getUserInfo().currencySymbol})`}</th>
                <th>Status</th>
                <th>Reason</th>
              
            </tr>
            </thead>
            {/* <hr className='line'/> */}
            <tbody>

            
            {rowData.spareDetails.map((item,index)=>{
                            return(
                                 <tr>
                <td>{++index}</td>
                <td>{item.sparePartname}</td>
                <td>{item.skuCode}</td>
                <td>{item.unitPrice}</td>
                <td>{item.manufactureMargin}</td>
                <td>{item.otherCharges}</td>
                <td>{item.priceToserviceCenter}</td>
                <td>{item.serviceCenterMargin}</td>
                <td>{item.sparePartMrp}</td>
                <td>{item.status}</td>
                <td>{item.status == "Hold" ? item.reason : ""}</td>
               
               </tr>
                            
                            )
                        })}
       
                 
             
            
            </tbody>
            </table>
          </div>


                </div>

                <div className="footer">
                    <button
                        style={{
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                            padding: "5px 15px",
                            marginRight: "10px",
                            color: "#0A18A3",
                        }}
                        onClick={() => closeModal(false)}
                    >
                        <KeyboardBackspace style={{ fontSize: "17px" }} />
                        Back
                    </button>
                    {rowData.status === AppConstants.INPROGRESS && (<button onClick={()=>{setOpen(true)}}  style={{ marginRight: '0', width: '200px', height: '42px' }} id="submit-button"><CheckCircle style={{ fontSize: "17px" }}/> Complete </button>
)}
{open && (<CompleteConfirm closeModal={setOpen}/>)}
                </div>
            </div>
        </div>
    );
};

export default SpareInProgress