import moment from 'moment';
import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { trackPromise } from 'react-promise-tracker';
import API from '../../../utils/api';

const Inventory = ({ invStartDate, invEndDate }) => {
    const [inventoryData, setInventoryData] = useState({});

    const fetchInventoryData = async () => {
        const resp = await trackPromise(API.get(invStartDate == null && invEndDate == null ? `production-module/api/v1/dashboard/getAllInventory` : `production-module/api/v1/dashboard/getAllInventory?startDate=${moment(invStartDate).format("DD-MMM-YYYY")}&endDate=${moment(invEndDate).format("DD-MMM-YYYY")}`))
        setInventoryData(resp);
    };
    useEffect(() => {
        fetchInventoryData();
    }, [invEndDate]);

    return (
        <div style={{ width: "95%", marginLeft: "10px" }}>
            <ProgressBar>
                <ProgressBar now={inventoryData.rawInventory * 100} />
                <ProgressBar variant="newRequest" now={inventoryData.mroInventory * 100} />
                <ProgressBar variant="inProgressRequest" now={inventoryData.wipInventory * 100} />
                <ProgressBar variant="success" now={inventoryData.finishedGoods * 100} />
                <ProgressBar variant="danger" now={inventoryData.rejectionInventory * 100} />
            </ProgressBar>
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430", marginTop: "20px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#1e90ff", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>RAW Inventory</span>
                </div>
                <div>
                    {inventoryData.rawInventory}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#267799", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>MRO Inventory</span>
                </div>
                <div>
                    {inventoryData.mroInventory}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#FDAA48", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>WIP Inventory</span>
                </div>
                <div>
                    {inventoryData.wipInventory}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#21A82E", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Finished Goods Inventory</span>
                </div>
                <div>
                    {inventoryData.finishedGoods}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#EF4D62", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Rejection Inventory</span>
                </div>
                <div>
                    {inventoryData.rejectionInventory}
                </div>
            </div>
            <hr />
        </div>
    )
}

export default Inventory