import React, { useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import GenerateGNI from "./GenerateGNI";
import auth from "../../../../../utils/auth";

const GenerateShip = ({ closeModal, rowData }) => {
  console.log("rowData", rowData);

  const [openQuant, setOpenQuant] = useState(false);
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState();
  const [actionShow, setActionShow] = useState();
  const [confirmationPopup, setConfirmationPopUp] = useState(false);

  const [formValue, setFormValue] = useState(
    {
      shipmentBy: "",
      shipmentNo: "",
      shipmentDate: "",
      deliveryVehicleNo: "",
      deliveryPersonName: "",
      deliveryPersonPhone: "",
    }
  );
console.log(formValue)
  return (
    <div className="container1">
      <div id="overlay" onClick={(e) => off(e)}></div>
      <div
        className="modalContainer"
        style={{ width: "737px", height: "450px", padding: "15px" }}
      >
        <div className="header">
          <h3>Return Shipment</h3>
        </div>

        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
            margin: "10px 0",
            fontFamily: "sans-serif",
            fontWeight: "420",
            padding: "5px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>RPI No. : {rowData?.rpiNo}</span>
            <span>Return Qty. : {rowData?.returnedUnits}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>RPI Date : {moment(rowData?.rpiDate).format("DD-MMM-YYYY/HH:mm")}</span>
            <span>Return Value {`(${auth.getUserInfo().currencySymbol})`}: {rowData?.returnValue?.toLocaleString("en-IN")}</span>
          </div>

          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Sales Manager : { }</span>
          </div> */}
        </section>
        <div
          className="body"
          style={{
            display: "flex",
            height: "300px",
            fontSize: "14px",
            overflow: "scroll",
            padding: "5px",
            marginTop: "-21px",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap", margin: "21px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <label>
                <span>Shipment By</span>
              </label>
              <input
                name="shipmentBy"
                style={{ width: "95%", marginTop: "-5px" }}
                placeholder="Shipment By"
                className="input-config"
                onChange={(e) => setFormValue({ ...formValue, [e.target.name]: e.target.value })}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <label>
                <span>Shipment No.</span>
              </label>
              <input
                name="shipmentNo"
                style={{ width: "95%", marginTop: "-5px" }}
                placeholder="Shipment No"
                className="input-config"
                onChange={(e) => setFormValue({ ...formValue, [e.target.name]: e.target.value })}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <label>
                <span>Shipment Date</span>
              </label>
              <input
                type="date"
                name="shipmentDate"
                style={{ width: "128%", marginTop: "-5px" }}
                className="input-config"
                onChange={(e) => setFormValue({ ...formValue, [e.target.name]: e.target.value })}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <label>
                <span>Delivery Vehicle No.</span>
              </label>
              <input
                name="deliveryVehicleNo"
                style={{ width: "95%", marginTop: "-5px" }}
                placeholder="Vehicle No."
                className="input-config"
                onChange={(e) => setFormValue({ ...formValue, [e.target.name]: e.target.value })}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <label>
                <span>Delivery Person Name</span>
              </label>
              <input
                name="deliveryPersonName"
                style={{ width: "95%", marginTop: "-5px" }}
                className="input-config"
                placeholder="Delivery Person Name"
                onChange={(e) => setFormValue({ ...formValue, [e.target.name]: e.target.value })}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <label>
                <span>Delivery Person Phone</span>
              </label>
              <input
                name="deliveryPersonPhone"
                placeholder="Phone No."
                style={{ width: "95%", marginTop: "-5px" }}
                className="input-config"
                onChange={(e) => setFormValue({ ...formValue, [e.target.name]: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 15px",
              marginRight: "10px",
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "17px" }} />
            Back
          </button>
          <button
            className="approvalfooter_btn"
            style={{ color: "white", backgroundColor: "blue", width: "auto" }}
            onClick={() => {
              setConfirmationPopUp(true);
            }}
          >
            Generate GIN
          </button>
          {confirmationPopup && (
            <GenerateGNI
              data={formValue}
              rowData={rowData}
              closeMain={closeModal}
              closeModal={setConfirmationPopUp}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerateShip;
