import { KeyboardBackspace } from "@material-ui/icons";
import React from "react";
import auth from "../../../../utils/auth";

const ViewAssemblyBom = ({ close, assemblydata }) => {
  console.log("assemblydata-->", assemblydata);

  const totalServices = assemblydata?.reduce((count, item) => {
    return count + (item?.assemblyServices?.length || 0);
  }, 0);

  const duration = assemblydata?.[0]?.Duration; // Assuming duration is the same for all items



  const calculateDailyCost = (serviceCost, time, timeDuration) => {
    console.log("check ther data",serviceCost, time, timeDuration,"-------------")
    switch (time) {
      case "Week":
        return serviceCost * 7 * Number(timeDuration)   // Convert weekly cost to daily cost
      case "Month":
        return serviceCost * 30 *  Number(timeDuration)  // Convert monthly cost to daily cost
      case "Year":
        return serviceCost * 365 * Number(timeDuration) ; // Convert yearly cost to daily cost
      default:
        return serviceCost * Number(timeDuration)  // If "Day", return the cost as is
    }
  };
  

  const totalPrice = (elem) => {
    console.log("testing console",elem)
    let total = 0;
    elem.map((item)=>{
      item?.assemblyServices?.map((list) => {
        total+= calculateDailyCost(Number(list?.serviceCost), list?.time, list?.timeDuration )
    })
    })
    
    return total
  }


  // const calculateTotalPrice = () => {
  //   return assemblydata?.reduce((total, item) => {
  //     const itemTotal = item?.assemblyServices?.reduce((sum, service) => {
  //       return (
  //         sum +
  //         parseInt(item?.timeDuration) * parseInt(service?.serviceCost || 0)
  //       );
  //     }, 0);
  //     return total + (itemTotal || 0);
  //   }, 0);
  // };

  // const totalPrice = calculateTotalPrice();

  // const totalServiceCost = assemblydata.reduce((total, item) => {
  //   return (
  //     total +
  //     item.assemblyServices.reduce((serviceTotal, service) => {
  //       return serviceTotal + parseInt(service.serviceCost || 0);
  //     }, 0)
  //   );
  // }, 0);

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "770px", height: "465px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Bom Assembly Details</h3>
            <div>
              {/* <button className="action-btn" style={{ color: "blue" }}>
                Print
              </button>
              <button className="action-btn" style={{ color: "blue" }}>
                Export
              </button> */}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              fontWeight: "450",
            }}
          >
            <span>Total Service: {totalServices}</span>
          </div>

          <div className="body" style={{ height: "340px" }}>
            <div style={{ width: "100%", overflow: "auto", height: "325px" }}>
              <table style={{ width: "130%" }} className="viewcomp_tbl">
                <thead>
                  <tr>
                    <th>Assembly Type</th>
                    <th>Assembly Name</th>
                    <th>Required Services</th>
                    <th>
                      Required Duration
                    </th>
                    <th>{`Cost (${
                      auth.getUserInfo().currencySymbol
                    })`}</th>
                    <th>{`Sub Total (${
                      auth.getUserInfo().currencySymbol
                    })`}</th>
                  </tr>
                </thead>
                <tbody>
                  {assemblydata?.map((item) => (
                    <tr key={item?.assemblyName}>
                      <td>{item?.assemblyType}</td>
                      <td>{item?.assemblyName}</td>
                      <td>
                        <ul
                          style={{
                            listStyleType: "none",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {item?.assemblyServices?.map((data) => (
                            <li
                              key={data?.serviceName}
                              style={{
                                textAlign: "start",
                                paddingLeft: "20px",
                              }}
                            >
                              {data?.serviceName}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <ul
                          style={{
                            listStyleType: "none",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {item?.assemblyServices?.map((el) => (
                            <li>{`${el?.timeDuration} ${el?.time}`}</li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <ul
                          style={{
                            listStyleType: "none",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {item?.assemblyServices?.map((data) => (
                            <li key={data?.serviceCost}>{`${data?.serviceCost} per Day`}</li>
                          ))}
                        </ul>
                      </td>
                      <td>
                      {item?.assemblyServices?.map((list) => (
                            <ul style={{ padding: "0", margin: "0" }}>
                              <li>
                                {/* {parseInt(item?.serviceCost) *
                                  parseInt(item?.timeDuration)} */}
                                  {calculateDailyCost(list.serviceCost,list?.time,list?.timeDuration)}
                              </li>
                            </ul>
                          ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <section
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              borderRadius: "5px",
              margin: "5px 0",
              height: "35px",
            }}
          >
            <div
              style={{
                width: "200px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {`Total Price (${auth.getUserInfo().currencySymbol}) : `}
              <span
                style={{
                  width: "100px",
                  background: "#ebebeb",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {totalPrice(assemblydata)}
              </span>
            </div>
          </section>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}>
              {" "}
              <KeyboardBackspace /> Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAssemblyBom;
