import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AppConstants from "../../utils/app-constants";
import ViewQuantity from "../wo-shopfloor/wo-sf-actions/wo-sf-viewquantity";
import RequestApproval from "./inv-sf-actions/requestapproval";
import PendingInvDetails from "./inv-sf-actions/pendinginvdetails";
import PartialQtyDetails from "./partialqtydetails";
import FullQtyDetails from "./fullqtydetails";
import RejectionDetails from "./inv-sf-actions/rejectiondetails";
import ViewComponentsFG from "./inv-sf-actions/viewcomponents";
import handleExport from "../../utils/exportExcel";

const { ExportCSVButton } = CSVExport;

const ManageAddonSF = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [open, setOpen] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [viewComponents, setViewComponents] = useState(false);
  const [componentShow, setComponentShow] = useState();
  const [requestApproval, setRequestApproval] = useState();
  const [pendingInv, setPendingInv] = useState();
  const [partialQty, setPartialQty] = useState();
  const [fullQty, setFullQty] = useState();
  const [rejectedbyprod, setRejectedbyprod] = useState();
  const [rejectedbyinv, setRejectedbyinv] = useState();
  const [row, setRow] = useState();

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `shop-floor-module/api/v1/shopFloor/getAddOn?orderID=${formValue.orderID}&workType=${formValue.workType}&BOMID=${formValue.BOMID}&bomLocation=${formValue.bomLocation}&bomName=${formValue.bomName}&createdDate=${formValue.addedDate}&requestType=${formValue.requestType}&requestDate=${formValue.requestDate}`
      )
    );
    console.log(resp);
    setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);



  const uniqueorderID = [...new Set(UsersData.map((item) => item.orderID))];
  const uniqueworkType = [...new Set(UsersData.map((item) => item.workType))];
  const uniqueBOMID = [...new Set(UsersData.map((item) => item.BOMID))];
  const uniquebomName = [...new Set(UsersData.map((item) => item.BOMName))];
  const uniquebomLocation = [...new Set(UsersData.map((item) => item.BOMLocation))];
  const uniquerequestType = [...new Set(UsersData.map((item) => item.requestType))];



  const statusFormatter = (cell) => {
    return cell === AppConstants.PRODUCTIONAPPROVALPENDING ? (
      <button className="deactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="deactive-btn-font">Production Approval Pending</span>
      </button>
    ) : cell === AppConstants.PENDINGATINVENTORY || cell == "Approved" ? (
      <button className="deactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="deactive-btn-font">Pending at Inventory</span>
      </button>
    ) : cell === AppConstants.PARTIALQTYRELEASED ? (
      <button className="pendingByProc-btn" style={{ width: "100%" }}>
        {" "}
        <span className="pendingByProc-btn-font">Partial Qty Released</span>
      </button>
    ) : cell === AppConstants.FULLQTYRELEASED ? (
      <button className="active-btn" style={{ width: "100%" }}>
        {" "}
        <span className="active-btn-font">Full Qty Released</span>
      </button>
    ) : cell === AppConstants.REJECTEDBYPRODUCTION || cell == "Rejected" ? (
      <button className="delete-btn" style={{ width: "100%" }}>
        {" "}
        <span className="delete-btn-font">Rejected By Production</span>
      </button>
    ) : cell == "On Hold" ? (
      <button className="deactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="deactive-btn-font">Hold By Production</span>
      </button>
    ) : cell === AppConstants.REJECTEDBYINVENTORY ? (
      <button className="delete-btn" style={{ width: "100%" }}>
        {" "}
        <span className="delete-btn-font">Rejected By Inventory</span>
      </button>
    ) : (
      cell
    );
  };

  // const reasonFormatter = (cell,row) => {
  //   return (
  //     row.status === AppConstants.ACCEPTED ?
  //     <span className="active-btn-font">-</span> :
  //     row.status === AppConstants.ASSIGNED?
  //     <span cla>-</span> :
  //     row.status ===  AppConstants.REJECTED ?
  //     <span>{row.notAcceptedReason}</span>: cell
  //   );
  // };

  const actionHandler = (row, rowIndex) => {
    console.log("hello");
    setOpen(true);
    setActionShow(rowIndex);
    setRow(row);
    setPendingInv(true);
  };
  const requestApprovalHandler = (row, rowIndex) => {
    console.log("hello");
    setOpen(true);
    setActionShow(rowIndex);
    setRow(row);
    setRequestApproval(true);
  };
  const rejectedbyprodHandler = (row, rowIndex) => {
    console.log("hello");
    setOpen(true);
    setActionShow(rowIndex);
    setRow(row);
    setRejectedbyprod(true);
  };

  const rejectedbyinvHandler = (row, rowIndex) => {
    console.log("hello");
    setOpen(true);
    setActionShow(rowIndex);
    setRow(row);
    setRejectedbyinv(true);
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {row.status == AppConstants.PRODUCTIONAPPROVALPENDING ? (
          <button
            onClick={() => requestApprovalHandler(row, rowIndex)}
            style={{
              color: "#0A18A3",
              backgroundColor: "white",
              fontSize: "14px",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              width: "180px",
            }}
          >
            Request for Approval
          </button>
        ) : row.status == AppConstants.PENDINGATINVENTORY || row.status == "Approved" || row.status == "On Hold" ? (
          <button
            onClick={() => actionHandler(row, rowIndex)}
            style={{
              color: "#0A18A3",
              backgroundColor: "white",
              fontSize: "14px",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              width: "180px",
            }}
          >
            View Details
          </button>
        ) : row.status == AppConstants.PARTIALQTYRELEASED ? (
          <button
            onClick={() => setPartialQty(true)}
            style={{
              color: "#0A18A3",
              backgroundColor: "white",
              fontSize: "14px",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              width: "180px",
            }}
          >
            View Details
          </button>
        ) : row.status == AppConstants.FULLQTYRELEASED ? (
          <button
            onClick={() => setFullQty(true)}
            style={{
              color: "#0A18A3",
              backgroundColor: "white",
              fontSize: "14px",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              width: "180px",
            }}
          >
            View Details
          </button>
        ) : row.status == AppConstants.REJECTEDBYPRODUCTION || row.status == "Rejected" ? (
          <button
            onClick={() => rejectedbyprodHandler(row, rowIndex)}
            style={{
              color: "#0A18A3",
              backgroundColor: "white",
              fontSize: "14px",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              width: "180px",
            }}
          >
            View Details
          </button>
        ) : row.status == AppConstants.REJECTEDBYINVENTORY ? (
          <button
            onClick={() => rejectedbyinvHandler(row, rowIndex)}
            style={{
              color: "#0A18A3",
              backgroundColor: "white",
              fontSize: "14px",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              width: "180px",
            }}
          >
            View Details
          </button>
        ) : null}
      </div>
    );
  };

  const ComponentHandler = (rowIndex) => {
    setComponentShow(rowIndex);
    setViewComponents(true);
  };
  const componentFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => ComponentHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          View Components
        </button>
        {componentShow === rowIndex && viewComponents ? (
          <ViewComponentsFG rowdata={row} close={setViewComponents} />
        ) : null}
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const handleClick = (e) => {
    setShow(!show);
  };

  const exportData = UsersData?.map((item, index) => {
    return {
        "S.No.": index + 1, // S.No.
        "WO ID": item?.orderID, // WO ID
        "WO Type": item?.workType, // WO Type
        "BOM ID": item?.BOMID, // BOM ID
        "BOM Location": item?.BOMLocation, // BOM Location
        "BOM Name": item?.BOMName, // BOM Name
        "Request Type": item?.requestType, // Request Type
        "Request Date": moment(item?.requestDate).format("DD-MMM-YYYY"), // Request Date formatted with Moment.js
        "Required Components": item?.requiredComponents, // Required Components formatted using componentFormatter
        "Status": statusFormatter(item?.status), // Status formatted using statusFormatter
        "Reason": item?.reason, // Reason
    };
});



  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "WO ID",
      dataField: "orderID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "WO Type",
      dataField: "workType",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "BOM ID",
      dataField: "BOMID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: " BOM Location",
      dataField: "BOMLocation",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "BOM Name",
      dataField: "BOMName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Request Type",
      dataField: "requestType",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Request Date",
      dataField: "requestDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatter: dateFormatter,
    },
    {
      text: "Required Components",
      dataField: "requiredComponents",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "180px" },
      formatExtraData: { viewComponents, componentShow },
      formatter: componentFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "250px" },
      formatter: statusFormatter,
    },
    {
      text: "Reason",
     dataField: "reason",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "250px" },
      // formatter: (row)=>{
      //   return row.requiredComponents

      // },
    },
    {
      text: "Action",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  const [formValue, setFormValue] = useState({
    orderID: "",
    workType: "",
    BOMID: "",
    bomLocation: "",
    bomName: "",
    addedDate: "",
    requestType: "",
    requestDate: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      orderID: "",
      workType: "",
      BOMID: "",
      bomLocation: "",
      bomName: "",
      addedDate: "",
      requestType: "",
      requestDate: "",
    });
    const resp = await trackPromise(
      API.get(`shop-floor-module/api/v1/shopFloor/getAddOn`)
    );
    setUsersData(resp);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  //  if(open===true && (row.status==AppConstants.PARTIALINVENTORYRECEIVED || row.status==AppConstants.FULLINVENTORYRECEIVED)){
  //     return(
  //       <ViewReleaseComponents rowdata={row} close={setOpen} />
  //     )
  //    }
  return (
    <>
      <div>
        {requestApproval && (
          <RequestApproval rowdata={row} closeModal={setRequestApproval} />
        )}
        {pendingInv && (
          <PendingInvDetails rowdata={row} close={setPendingInv} />
        )}
        {partialQty && (
          <PartialQtyDetails rowdata={row} close={setPartialQty} />
        )}
        {fullQty && <FullQtyDetails rowdata={row} close={setFullQty} />}
        {rejectedbyprod && (
          <RejectionDetails rowdata={row} close={setRejectedbyprod} />
        )}
        {rejectedbyinv && (
          <RejectionDetails rowdata={row} close={setRejectedbyinv} />
        )}

        <div>
          <div id="overlay" onClick={(e) => off(e)}></div>
          <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
            <span>Manage WO Requests</span>
          </div>

          <ToolkitProvider
            keyField="id"
            data={UsersData}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div>
                  <div style={{ float: "right", marginTop: "-39px" }}>
                    <div
                      style={{
                        display: "flex",
                        marginRight: "-15px",
                        height: "26px",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "220px" }}>
                        <SearchBar
                          {...props.searchProps}
                          placeholder="Search"
                          style={{
                            height: "25px",
                            // border: "1px solid #C6C6C6",
                            border: "none",
                            background: "white",
                            borderRadius: "3px",
                            marginRight: "-50px",
                          }}
                        />
                      </div>
                      <div onClick={clearFilter} className="filter-refresh">
                        <FontAwesomeIcon icon={faRedoAlt} />
                        &nbsp; Refresh
                      </div>
                      <div className="filter-refresh" onClick={handleClick}>
                        {" "}
                        <FontAwesomeIcon icon={faFilter} />
                        &nbsp; Filters
                      </div>
                      <div
                        className="filter-refresh"
                        onClick={() => handleExport(exportData, "Manage Add On")}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp; Export
                      </div>
                      {inDashboard ? (
                        <div
                          className="filter-refresh"
                          onClick={() => inDashboard(false)}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  {show && (
                    <>
                      <form
                        id="abc"
                        onSubmit={submitHandler}
                        className="RA_block"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          height: "185px",
                          backgroundColor: "#F7F7F7",
                          padding: "5px",
                        }}
                      >
                        <div
                          className="bomfilter_fields"
                          style={{
                            padding: "0px",
                            height: "auto",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "24%",
                              marginRight: "10px",
                            }}
                          >
                            <label
                              style={{ fontSize: "13px", fontWeight: "500" }}
                            >
                              {" "}
                              WO ID
                            </label>
                            <select
                              // required={true}
                              name="orderID"
                              value={formValue.orderID}
                              onChange={handleChange}
                              style={{ marginTop: "-5px", width: "100%" }}
                            >
                              <option value="" disabled selected>
                                WO ID
                              </option>
                              {
                                uniqueorderID.map((item) => (
                                  <option>{item}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "24%",
                              marginRight: "10px",
                            }}
                          >
                            <label
                              style={{ fontSize: "13px", fontWeight: "500" }}
                            >
                              {" "}
                              WO Type
                            </label>
                            <select
                              // required={true}
                              name="orderID"
                              value={formValue.workType}
                              onChange={handleChange}
                              style={{ marginTop: "-5px", width: "100%" }}
                            >
                              <option value="" disabled selected>
                                WO Type
                              </option>
                              {
                                uniqueworkType.map((item) => (
                                  <option>{item}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "24%",
                              marginRight: "10px",
                            }}
                          >
                            <label
                              style={{ fontSize: "13px", fontWeight: "500" }}
                            >
                              BOM ID
                            </label>
                            <select
                              name="BOMID"
                              value={formValue.BOMID}
                              onChange={handleChange}
                              style={{ marginTop: "-5px", width: "100%" }}
                            >
                              <option value="" disabled selected>
                                BOM ID
                              </option>
                              {
                                uniqueBOMID.map((item) => (
                                  <option>{item}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "24%",
                              marginRight: "10px",
                            }}
                          >
                            <label
                              style={{ fontSize: "13px", fontWeight: "500" }}
                            >
                              BOM Location
                            </label>
                            <select
                              name="bomLocation"
                              value={formValue.bomLocation}
                              onChange={handleChange}
                              style={{ marginTop: "-5px", width: "100%" }}
                            >
                              <option value="" disabled selected>
                                BOM Location
                              </option>
                              {
                                uniquebomLocation.map((item) => (
                                  <option>{item}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "24%",
                              marginRight: "10px",
                            }}
                          >
                            <label
                              style={{ fontSize: "13px", fontWeight: "500" }}
                            >
                              {" "}
                              BOM Name
                            </label>
                            <select
                              name="bomName"
                              value={formValue.bomName}
                              onChange={handleChange}
                              style={{ marginTop: "-5px", width: "100%" }}
                            >
                              <option value="" disabled selected>
                                BOM Name
                              </option>
                              {
                                uniquebomName.map((item) => (
                                  <option>{item}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "24%",
                              marginRight: "10px",
                            }}
                          >
                            <label
                              style={{ fontSize: "13px", fontWeight: "500" }}
                            >
                              {" "}
                              Added Date
                            </label>
                            <input
                              type="date"
                              onFocus={(e) => (e.currentTarget.type = "date")}
                              onBlur={(e) => (e.currentTarget.type = "text")}
                              name="addedDate"
                              value={formValue.addedDate}
                              onChange={handleChange}
                              placeholder="Added Date"
                              autoComplete="off"
                              style={{ marginTop: "-5px", width: "100%" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "24%",
                              marginRight: "10px",
                            }}
                          >
                            <label
                              style={{ fontSize: "13px", fontWeight: "500" }}
                            >
                              Request Type
                            </label>
                            <select
                              name="requestType"
                              value={formValue.requestType}
                              onChange={handleChange}
                              style={{ marginTop: "-5px", width: "100%" }}
                            >
                              <option value="" disabled selected>
                                Request Type
                              </option>
                              {
                                uniquerequestType.map((item) => (
                                  <option>{item}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "24%",
                              marginRight: "10px",
                            }}
                          >
                            <label
                              style={{ fontSize: "13px", fontWeight: "500" }}
                            >
                              Request Date
                            </label>
                            <input
                              type="text"
                              onFocus={(e) => (e.currentTarget.type = "date")}
                              onBlur={(e) => (e.currentTarget.type = "text")}
                              name="requestDate"
                              value={formValue.requestDate}
                              onChange={handleChange}
                              placeholder="Request Date"
                              autoComplete="off"
                              style={{ marginTop: "-5px", width: "100%" }}
                            />
                          </div>
                          <div style={{ margin: "22px 0px 0px 806px" }}>
                            <button
                              type="submit"
                              id="apply"
                              style={{ width: "96px", height: "35px" }}
                              onClick={fetchReport}
                            >
                              Apply
                            </button>
                            <button
                              onClick={clearFilter}
                              id="clear"
                              style={{ width: "96px", height: "35px" }}
                            >
                              Clear Filters
                            </button>
                          </div>
                        </div>
                      </form>

                      {/* 
            <form
              id="abc"
              onSubmit={submitHandler}
              className="RA_block"
              style={{
                height: "115px",
                justifyContent: "flex-start",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                backgroundColor: "#F7F7F7",
                padding: "5px",
              }}
            >
              <div className="bomfilter_fields">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "19%",
                  }}
                >
                  <label style={{ fontSize: "13px", fontWeight: "500" }}>
                    WO ID
                  </label>
                  <select
                    // required={true}
                    name="orderID"
                    value={formValue.orderID}
                    onChange={handleChange}
                    style={{ marginTop: "-5px", width: "100%" }}
                  >
                    <option value="" disabled selected>
                      WO ID*
                    </option>
                    {UsersData.map((item) => (
                      <option>{item.orderID}</option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "19%",
                  }}
                >
                  <label style={{ fontSize: "13px", fontWeight: "500" }}>
                    WO Type
                  </label>
                  <select
                    // required={true}
                    name="workType"
                    value={formValue.workType}
                    onChange={handleChange}
                    style={{ marginTop: "-5px", width: "100%" }}
                  >
                    <option value="" disabled selected>
                      WO Type*
                    </option>
                    {UsersData.map((item) => (
                      <option>{item.workType}</option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "19%",
                  }}
                >
                  <label style={{ fontSize: "13px", fontWeight: "500" }}>
                    BOM ID
                  </label>
                  <select
                    name="BOMID"
                    value={formValue.BOMID}
                    onChange={handleChange}
                    style={{ marginTop: "-5px", width: "100%" }}
                  >
                    <option value="" disabled selected>
                      BOM ID*
                    </option>
                    {UsersData.map((item) => (
                      <option>{item.BOMID}</option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "19%",
                  }}
                >
                  <label style={{ fontSize: "13px", fontWeight: "500" }}>
                    BOM Location
                  </label>
                  <select
                    name="bomLocation"
                    value={formValue.bomLocation}
                    onChange={handleChange}
                    style={{ marginTop: "-5px", width: "100%" }}
                  >
                    <option value="" disabled selected>
                      BOM Location*
                    </option>
                    {UsersData.map((item) => (
                      <option>{item.bomLocation}</option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "19%",
                  }}
                >
                  <label style={{ fontSize: "13px", fontWeight: "500" }}>
                    BOM Name
                  </label>
                  <select
                    name="bomName"
                    value={formValue.bomName}
                    onChange={handleChange}
                    style={{ marginTop: "-5px", width: "100%" }}
                  >
                    <option value="" disabled selected>
                      BOM Name*
                    </option>
                    {UsersData.map((item) => (
                      <option>{item.bomName}</option>
                    ))}
                  </select>
                </div>
                <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "19%",
                }}
              >
                <label style={{ fontSize: "13px", fontWeight: "500" }}>
                  Request Type
                </label>
                <select
                  name="requestType"
                  value={formValue.requestType}
                  onChange={handleChange}
                  style={{ marginTop: "-5px", width: "100%" }}
                >
                  <option value="" disabled selected>
                    Request Type*
                  </option>
                  {UsersData.map((item) => (
                    <option>{item.requestType}</option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "19%",
                }}
              >
                <label style={{ fontSize: "13px", fontWeight: "500" }}>
                  Request Date
                </label>
                <input
                  type="text"
                  onFocus={(e) => (e.currentTarget.type = "date")}
                  onBlur={(e) => (e.currentTarget.type = "text")}
                  name="requestDate"
                  value={formValue.requestDate}
                  onChange={handleChange}
                  placeholder="Request Date"
                  autoComplete="off"
                  style={{ marginTop: "-5px", width: "100%" }}
                />
              </div>
                <div
                  style={{
                    margin: "10px 0px 0px 812px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <button
                    type="submit"
                    id="apply"
                    style={{ width: "96px", height: "35px" }}
                    onClick={fetchReport}
                  >
                    Apply
                  </button>
                  <button
                    onClick={clearFilter}
                    id="clear"
                    style={{ width: "96px", height: "35px" }}
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </form> */}
                    </>
                  )}
                  <br></br>
                </div>

                <BootstrapTable
                  wrapperClasses="table-responsive"
                  // rowClasses="text-nowrap"
                  rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                  pagination={paginationFactory({
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    hideSizePerPage: true,
                    paginationSize: 2,
                    sizePerPage: "10",
                  })}
                  noDataIndication="No Data Found"
                  {...props.baseProps}
                />
              </>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </>
  );
};
export default ManageAddonSF;
