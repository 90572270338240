import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification,errorNotification } from "../../../utils/notifications"

const ApproveVP = ({closeModal, data, updatedData,close}) => {
  

  const editDetails  = {
    requestID:data.requestID,
    status:"Approved By Management"
   };

   const refresh = () => {
    updatedData();
   }

  const submitHandler = async () => {
    const resp = await trackPromise(API.put(`management/api/v1/vehiclenewrequest/status`, editDetails));
    const { success,message} = resp;
    
    if (success) {
      closeModal(false);
      refresh()
      close(false)
      successNotification(message, { autoClose: 3000 });
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
 
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{width:"410px",height:"210px"}}>
        <div className="body" style={{flexDirection:"column",marginTop:"58px",fontSize:"18px",textAlign:"center"}}>
          <span>Are you sure to approve</span>
          <span>this vehicle MRP?</span>
        </div>
        <div className="footer" style={{justifyContent:"center",marginBottom:"16px"}}>
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApproveVP;
