import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import FinishedGoods from "./finishedgoods";
// import GRN from "./grn";
// import MROInventory from "./mroinventory"
// import RawInventory from "./rawinventory";
// import WipInventory from "./WipInventory";

const GRN = React.lazy(()=>import("./grn"))
const FinishedGoods = React.lazy(()=>import("./finishedgoods"))
const MROInventory = React.lazy(()=>import("./mroinventory"))
const RawInventory = React.lazy(()=>import("./rawinventory"))
const WipInventory = React.lazy(()=>import("./WipInventory"))



const FinanceInventory = () => {
  const tabs = [
    {
      id: "FIM4T1",
      label: TabLabels.GRN,
      Component: GRN
    },
    {
      id: "FIM4T2",
      label: TabLabels.RAW_INVENTORY_INV,
      Component: RawInventory
    },
    {
      id: "FIM4T3",
      label: TabLabels.WIP_INVENTORY,
      Component: WipInventory
    },
    {
      id: "FIM4T4",
      label: TabLabels.FINISHEDGOODS,
      Component: FinishedGoods
    },
    {
      id: "FIM4T5",
      label: TabLabels.MRO_INVENTORY_INV,
      Component: MROInventory
    },
  ];

  const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
    return tabs.filter(tab => tabIds.includes(tab.id));
  };
  let tabsToShow = getTabs(tabs);

  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>

  )
}
export default FinanceInventory