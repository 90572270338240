import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import Vector from '../QcDashboard/assets/Vector.svg'
import Group17 from '../QcDashboard/assets/Group17.svg'
import Group16 from '../QcDashboard/assets/Group16.svg'
import Group9 from '../QcDashboard/assets/Group9.svg'
import '../ShopFloorDashboard/shopFloorDash.scss';
import Vector3 from "../QcDashboard/assets/Vector3.svg";
import moment from "moment";
import auth from "../../utils/auth";

const ManagementRawBox = ({ props, startDate, endDate, mrostart, mroend }) => {
  const [rawInventory, setRawInventory] = useState({ totalComponent: 0, totalUnits: 0 });
  const [mroInventory, setMroInventory] = useState({});

  // raw
  const fetchData = async () => {
    const result = await trackPromise(API.get(`inventory-module/api/v1/rawInventory`));
    let rawArray = [];
    result.map((item) => {
      rawArray.push({ ...item._id, availableQty: item.count });
    })
    let totalComp = rawArray.reduce((a, b) => a + b?.availableQty, 0);
    console.log(totalComp);
    setRawInventory({ ...rawInventory, totalComponent: totalComp, totalUnits: rawArray.length });
  };
  useEffect(() => {
    fetchData();
  }, [endDate]);

  //   mro
  const fetchReport = async () => {
    const result = await trackPromise(API.get(`inventory-module/api/v1/mroInventory`));
    let rawArray = [];
    result.map((item) => {
      rawArray.push({ ...item._id, availableQty: item.count });
    })
    let totalComp = rawArray.reduce((a, b) => a + b?.availableQty, 0);
    console.log(totalComp);
    setMroInventory({ ...mroInventory, totalComponent: totalComp, totalUnits: rawArray.length });
  };
  useEffect(() => {
    fetchReport();
  }, [mroend]);

  return (
    <div className='requestBody'>
      <div className='shopFloorRequest' style={{ width: "240px", height: "160px", marginTop: "15px" }}>
        <img src={Group9} style={{ marginTop: "20px", marginLeft: "auto", marginRight: "auto", display: "block" }} alt="group" />
        <div style={{ color: "#808080", fontSize: "14px", textAlign: "center", margin: "10px 6px 0px 6px", borderBottom: "2px solid #F2F2F2" }}>Total Components</div>
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
          <div style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px", display: "flex", justifyContent: "center" }}>{props == "raw" ? rawInventory.totalUnits : mroInventory.totalUnits}&nbsp;
            {/* {props == "raw" ?
              <>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#11781B",
                  }}
                >
                  {rawInventory.totalComponentChange > 0 ? (
                    <>
                      <img src={Vector} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "green" }}
                      >{`+${rawInventory.totalComponentChange}`}</span>
                    </>
                  ) : rawInventory.totalComponentChange === 0 ? (
                    <>
                      <img src={Vector} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "green" }}
                      >{`+${rawInventory.totalComponentChange}`}</span>
                    </>
                  ) : (
                    <>
                      <img src={Vector3} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "red" }}
                      >{`${rawInventory.totalComponentChange}`}</span>
                    </>
                  )}
                </span>
              </>
              :
              <>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#11781B",
                  }}
                >
                  {mroInventory.totalComponentChange > 0 ? (
                    <>
                      <img src={Vector} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "green" }}
                      >{`+${mroInventory.totalComponentChange}`}</span>
                    </>
                  ) : mroInventory.totalComponentChange === 0 ? (
                    <>
                      <img src={Vector} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "green" }}
                      >{`+${mroInventory.totalComponentChange}`}</span>
                    </>
                  ) : (
                    <>
                      <img src={Vector3} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "red" }}
                      >{`${mroInventory.totalComponentChange}`}</span>
                    </>
                  )}
                </span>
              </>
            } */}

          </div>
        </div>
      </div>

      <div className='shopFloorRequest' style={{ width: "240px", height: "160px", marginTop: "15px" }}>
        <img src={Group16} style={{ marginTop: "20px", marginLeft: "auto", marginRight: "auto", display: "block" }} alt="group" />
        <div style={{ color: "#808080", fontSize: "14px", textAlign: "center", margin: "10px 6px 0px 6px", borderBottom: "2px solid #F2F2F2" }}>Total Units</div>
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
          <div style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px", display: "flex", justifyContent: "center" }}>{props == "raw" ? rawInventory.totalComponent : mroInventory.totalComponent}&nbsp;
            {/* {props == "raw" ?
              <>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#11781B",
                  }}
                >
                  {rawInventory.totalUnitsChange > 0 ? (
                    <>
                      <img src={Vector} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "green" }}
                      >{`+${rawInventory.totalUnitsChange}`}</span>
                    </>
                  ) : rawInventory.totalUnitsChange === 0 ? (
                    <>
                      <img src={Vector} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "green" }}
                      >{`+${rawInventory.totalUnitsChange}`}</span>
                    </>
                  ) : (
                    <>
                      <img src={Vector3} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "red" }}
                      >{`${rawInventory.totalUnitsChange}`}</span>
                    </>
                  )}
                </span>
              </>
              :
              <>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#11781B",
                  }}
                >
                  {mroInventory.totalUnitsChange > 0 ? (
                    <>
                      <img src={Vector} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "green" }}
                      >{`+${mroInventory.totalUnitsChange}`}</span>
                    </>
                  ) : mroInventory.totalUnitsChange === 0 ? (
                    <>
                      <img src={Vector} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "green" }}
                      >{`+${mroInventory.totalUnitsChange}`}</span>
                    </>
                  ) : (
                    <>
                      <img src={Vector3} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "red" }}
                      >{`${mroInventory.totalUnitsChange}`}</span>
                    </>
                  )}
                </span>
              </>} */}

          </div>
        </div>
      </div>

      {/* <div className='shopFloorRequest' style={{ width: "150px", height: "160px", marginTop: "15px" }}>
        <img src={Group17} style={{ marginTop: "20px", marginLeft: "auto", marginRight: "auto", display: "block" }} alt="group" />
        <div style={{ color: "#808080", fontSize: "14px", textAlign: "center", margin: "10px 6px 0px 6px", borderBottom: "2px solid #F2F2F2" }}>{`Total Value (${auth.getUserInfo().currencySymbol})`}</div>
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
          <div style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}>{props == "raw" ? rawInventory.totalValue : mroInventory.totalValue}&nbsp;</div>
        </div>
      </div> */}
    </div>
  )
}

export default ManagementRawBox