import { ContactsOutlined } from "@material-ui/icons";
import React from "react";
import { KeyboardBackspace } from "@material-ui/icons";

const ActionViewDetails = ({
  close,
  rowData,
  type
}) => {

    console.log("rowData-->",rowData)

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "50%", height: "480px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Details</h3>
            {/* <div>
              <button className="action-btn" style={{ color: "blue" }}>
                Print
              </button>
              <button className="action-btn" style={{ color: "blue" }}>
                Export
              </button>
            </div> */}
          </div>
          <br />
          <section
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span>Executive Name: {rowData?.executiveName || 'N/A'}</span>
            </div>
          </section>
          <br />
          <div className="body" style={{ height: "340px" }}>
            <div style={{ width: "100%", overflow: "auto", height: "340px" }}>
              <table className="viewcomp_tbl">
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>S.No.</th>
                    <th style={{ width: "90%" }}>Created Date</th>
                    <th style={{ width: "90%" }}>Current Status</th>
                  </tr>
                </thead>
                <tbody>
                  {rowData?.executive?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ width: "20%" }}>{index + 1}</td>
                        <td style={{ width: "90%" }}>{item?.createdDate || 'N/A'}</td>
                        <td style={{ width: "90%" }}> 
                        <span className="deactivate-btn" 
                        style={{padding:"3px 6px", borderRadius:"50px", color:"orange"}}>
                        {item?.currentStatus || 'N/A'}
                        </span> </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}>
              <KeyboardBackspace /> Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionViewDetails;
