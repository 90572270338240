import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisH
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";


const qtyDetails = ({closeModal ,rowData}) => {
    console.log(rowData)
    const [openQuant, setOpenQuant] = useState(false);
    const [status, setStatus] = useState("");
    const [open, setOpen] = useState();
    const [actionShow,setActionShow] = useState()

    function on() {
        document.getElementById("overlay").style.display = "block";
      };
    
      function off() {
        document.getElementById("overlay").style.display = "none";
        setOpen(false);
      };

    
    
      const actionHandler = () => {
        setOpen(true);
        on();
      };

      const handleStatusChange = (e,id) => {
         
      };

    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "700px", height: "400px"}}
            >
                <div className="header">
                    <h3>Requested Details</h3>
                </div>


                <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            paddingTop:"10px"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>RPI No : {rowData.RPIno}</span>
                            <span>RPI Raise By : {rowData.RPIRaiseBy}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>RPI Date : {moment(rowData.RPIDate).format("DD-MMM-YYYY / HH:mm")}</span>
                            {/* <span>Request Type : {rowData.requestType}</span> */}
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Vehicle Segment : {moment(rowData.requestedDate).format("DD-MMM-YYYY / HH:mm")}</span>
                        </div>
                    </section>
                         <br/>
                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >

                    <section style={{ width: '100%' }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '25%' }}>S.No.</div>
                            <div style={{ width: '25%' }}>Category</div>
                            <div style={{ width: '25%' }}>Model</div>
                            <div style={{ width: '25%' }}>Variant</div>
                            <div style={{ width: '25%' }}>Type</div>
                            <div style={{ width: '25%' }}>Color</div>
                        </div>
                        {rowData.vehicles.map((item,index)=>{
                            return(
                                <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }}>
                                <div style={{ width: '25%', overflowY: "auto" }}>{index + 1}</div>
                                <div style={{ width: '25%', overflowY: "auto" }}>{item.vehicleCategory}</div>
                                <div style={{ width: '25%', overflowY: "auto" }}>{item.vehicleModel}</div>
                                <div style={{ width: '25%', overflowY: "auto" }}>{item.vehicleVariant}</div>
                                <div style={{ width: '25%', overflowY: "auto" }}>{item.vehicleType}</div>
                                <div style={{ width: '25%', overflowY: "auto" }}>{item.vehicleColor}</div>                   
                            </div>
                            )
                        })}
                        <div>
                        
                                 
                        </div>
                    </section>

                </div>

                <div className="footer">
                    <button
                        style={{
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                            padding: "5px 15px",
                            marginRight: "10px",
                            color: "#0A18A3",
                        }}
                        onClick={() => closeModal(false)}
                    >
                        <KeyboardBackspace style={{ fontSize: "17px" }} />
                        Back
                    </button>
                </div>
            </div>
        </div>
    );
};

export default qtyDetails;