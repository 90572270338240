import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../components/modal/modal.scss";
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ViewComponents from "./wo-actions/wo-viewcomponents";
import ViewLabour from "./wo-actions/wo-viewlabour";
import ViewAssembly from "./wo-actions/wo-viewassembly";
import ActionWO from "./wo-actions/wo-action";
import EditWO from "./wo-actions/wo-edit";
import AppConstants from "../../utils/app-constants";
import { errorNotification } from "../../utils/notifications";
import handleExport from "../../utils/exportExcel";


const { ExportCSVButton } = CSVExport;


const ManageWorkorder = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [viewLabour, setViewLabour] = useState();
  const [viewAssm, setViewAssm] = useState();
  const [open, setOpen] = useState();
  const [ordersData, setOrdersData] = useState([]);
  const [viewComp, setViewComp] = useState();
  const [componentShow, setComponentShow] = useState();
  const [status, setStatus] = useState("");
  const [row, setRow] = useState();
  const [showedit, setShowedit] = useState(true);
  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    BOMID: "",
    bomLocation: "",
    bomName: "",
    vehicleModel: "",
    vehicleVariant: "",
    orderID: "",
    workType: "",
    shopFloorEmailID: ""
  });

  const fetchReport = async () => {
    const resp = await trackPromise(API.get(`production-module/api/v1/workOrder?BOMID=${formValue.BOMID}&BOMLocation=${formValue.bomLocation}&BOMName=${formValue.bomName}&vehicleModel=${formValue.vehicleModel}&vehicleVariant=${formValue.vehicleVariant}&orderID=${formValue.orderID}&workType=${formValue.workType}&shopFloorEmailID=${formValue.shopFloorEmailID}`));
    console.log(resp);
    resp.success == false ? errorNotification(resp.message, { autoClose: 3000 }) : setOrdersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const statusFormatter = (cell, row, rowIndex) => {
    if (cell == "Assigned&New Work Order") {
      let text = "Assigned&New Work Order";
      const myArray = text.split("&");
      setStatus(myArray[0]);
    }
    return (
      cell == "Accepted_WO in Progress" || cell == "Accepted_Work Resumed" || cell == "Accepted_WO Completed" || cell == "Accepted_Work Halted" || cell == "Accepted_Accepted" ?
        <button className="active-btn"><span className="active-btn-font">Accepted</span></button> :
        cell = "Assigned" || "Assigned_New Work Order" ?
          <button className="reactive-btn"> <span className="reactive-btn-font">Assigned</span></button> :
          cell == AppConstants.REASSIGNED ?
            <button className="reactive-btn"> <span className="reactive-btn-font">Reassigned</span></button> :
            cell == "Rejected_Not Accepted" ?
              <button className="delete-btn"> <span className="delete-btn-font">Rejected</span></button> : cell

    );
  };
  const reasonFormatter = (cell, row) => {
    return (
      row.status === AppConstants.ACCEPTED ?
        <span className="active-btn-font">-</span> :
        row.status === AppConstants.ASSIGNED ?
          <span className="">-</span> :
          row.status === AppConstants.REJECTED ?
            <span>{row.reason}</span> : cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  };

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  };

  const actionHandler = (row, rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    setRow(row);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => actionHandler(row, rowIndex)}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && open ? (
          <ActionWO rowData={row} status={true} updatedData={fetchReport} showedit={setShowedit} key="1" rowIndex={rowIndex}/>
        ) : null}
      </div>
    );
  };

  const ComponentsHandler = (rowIndex) => {
    setComponentShow(rowIndex);
    setViewComp(true);
  };

  const componentFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => ComponentsHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          View Components
        </button>
        {componentShow === rowIndex && viewComp ?
          <ViewComponents rowdata={row} close={setViewComp} /> : null
        }
      </div>
    );
  };
  const labourHandler = (rowIndex) => {
    setComponentShow(rowIndex);
    setViewLabour(true);
  };

  const labourFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => labourHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          View Labour
        </button>
        {componentShow === rowIndex && viewLabour ?
          <ViewLabour rowdata={row} close={setViewLabour} /> : null
        }
      </div>
    );
  };

  const assemblyHandler = (rowIndex) => {
    setComponentShow(rowIndex);
    setViewAssm(true);
  };

  const assemblyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => assemblyHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          View Assembly
        </button>
        {componentShow === rowIndex && viewAssm ?
          <ViewAssembly rowdata={row} close={setViewAssm} /> : null
        }
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  };

  function timelineFormatter(cell, row, rowIndex, formatExtraData) {
    console.log(row)
    let startDate = row.startDate;
    let endDate = row.endDate;
    return `${moment(startDate).format("DD-MMM-YYYY")} to ${moment(endDate).format("DD-MMM-YYYY")}`;
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return ordersData.findIndex(function (value, idx, arr) { return value == row }) + 1;
      },
      sort: true,
    },
    {
      text: "WO ID",
      dataField: "orderID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "WO Type",
      dataField: "workType",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "BOM ID",
      dataField: "BOMID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: " Location",
      dataField: "bomLocation",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "BOM Name",
      dataField: "bomName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Shop Floor Manager",
      dataField: "shopFloorManager",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Shop Floor E-mail ID",
      dataField: "shopFloorEmailID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Production Quantity",
      dataField: "productionQuantity",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "170px" },
    },
    {
      text: "Work Timeline",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "250px" },
      formatter: timelineFormatter,
    },
    {
      text: "Created By",
      dataField: "createdBy",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Creation Date & Time",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      dataField: "createdDate",
      headerAttrs: { width: "170px" },
      formatter: dateFormatter,
    },
    {
      text: "Component",
      dataField: "bomComponents",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "160px" },
      formatExtraData: { viewComp, componentShow },
      formatter: componentFormatter,
    },
    {
      text: "Labour",
      dataField: "bomLabour",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatExtraData: { viewLabour, componentShow },
      formatter: labourFormatter,
    },
    {
      text: "Assembly",
      dataField: "bomAssembly",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { viewAssm, componentShow },
      formatter: assemblyFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "140px" },
      formatExtraData: { status },
      formatter: statusFormatter,
    },
    {
      text: "Non Acceptation Reason",
      dataField: "reason",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: reasonFormatter,
    },
    {
      text: "Action",
      // dataField: "title",
      headerAttrs: { width: "70px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];


  const handleChange = (e) => {
    console.log(e)
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      BOMID: "",
      bomLocation: "",
      bomName: "",
      vehicleModel: "",
      vehicleVariant: "",
      orderID: "",
      workType: "",
      shopFloorEmailID: ""
    });

    const resp = await trackPromise(API.get(`production-module/api/v1/workOrder?`));
    resp.success == false ? errorNotification(resp.message, { autoClose: 3000 }) : setOrdersData(resp);
  };

  console.log(formValue)
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };
  const handleClick = (e) => {
    setShow(!show);
  };

  let uniqueBomIdArray = [...new Map(ordersData.map((item) => [item["BOMID"], item])).values()];
  //let uniqueBomLocationArray = [...new Map(ordersData.map((item) => [item["bomLocation"], item])).values()];
  const uniqueBomLocationArray = [...new Set(ordersData.map((item) => item.bomLocation))];
  let uniqueBomNameArray = [...new Map(ordersData.map((item) => [item["bomName"], item])).values()];
  let uniqueVehicleModelArray = [...new Map(ordersData.map((item) => [item["vehicleModel"], item])).values()];
  let uniqueVehicleVariantArray = [...new Map(ordersData.map((item) => [item["vehicleVariant"], item])).values()];
  let uniqueShopFloorMailArray = [...new Map(ordersData.map((item) => [item["shopFloorEmailID"], item])).values()];
  let uniqueWorkTypeArray = [...new Map(ordersData.map((item) => [item["workType"], item])).values()];
  let uniqueOrderIdArray = [...new Map(ordersData.map((item) => [item["orderID"], item])).values()];

  console.log(showedit)

  return (
    <>
      {showedit ?
        <div>

          <div id="overlay" onClick={(e) => off(e)}></div>
          <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
            <span>Manage Work Order</span>
          </div>

          <ToolkitProvider keyField="id" data={ordersData} columns={columns} search >

            {(props) => (
              <>
                <div>
                  <div style={{ float: "right", marginTop: "-39px" }}>
                    <div style={{ display: "flex", marginRight: "-15px", height: "26px", marginTop: "10px", }}>
                      <div style={{ width: "220px" }}>

                        <SearchBar
                          {...props.searchProps}
                          placeholder='Search'
                          style={{
                            height: "25px",
                            // border: "1px solid #C6C6C6",
                            border: "none",
                            background: "white",
                            borderRadius: "3px",
                            marginRight: "-50px",
                          }}
                        />

                      </div>
                      <div onClick={clearFilter} className="filter-refresh">
                        <FontAwesomeIcon icon={faRedoAlt} />
                        &nbsp; Refresh
                      </div>
                      <div className="filter-refresh" onClick={handleClick}>
                        {" "}
                        <FontAwesomeIcon icon={faFilter} />
                        &nbsp; Filters
                      </div>
                      <div
                        className="filter-refresh"
                        onClick={() => handleExport(ordersData, "Manage Work Order")}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp; Export
                      </div>
                      {inDashboard ? (
                        <div
                          className="filter-refresh"
                          onClick={() => inDashboard(false)}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>


                {show && (
                  <form id="abc" onSubmit={submitHandler} className="RA_block" style={{ height: "130px", justifyContent: "flex-start", display: 'flex', flexWrap: 'wrap', flexDirection: 'row', backgroundColor: '#F7F7F7', padding: '5px' }}>
                    <div className="bomfilter_fields">
                      <div style={{ display: 'flex', flexDirection: 'column', width: '19%' }}>
                        <label style={{ fontSize: "13px", fontWeight: "500", }}>BOM ID<span style={{ color: "red" }}>*</span></label>
                        <select
                          name="BOMID"
                          value={formValue.BOMID}
                          onChange={handleChange}
                          style={{ marginTop: '-5px', width: '100%' }}
                        >
                          <option value="" disabled selected>
                            BOM ID
                          </option>
                          {uniqueBomIdArray.map((item) => {
                            return (
                              <option>{item.BOMID}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '19%' }}>
                        <label style={{ fontSize: "13px", fontWeight: "500", }}>BOM Location<span style={{ color: "red" }}>*</span></label>
                        <select
                          name="bomLocation"
                          value={formValue.bomLocation}
                          onChange={handleChange}
                          style={{ marginTop: '-5px', width: '100%' }}
                        >
                          <option value="" disabled selected>
                            BOM Location
                          </option>
                          {uniqueBomLocationArray.map((item) => {
                            return (
                              <option>{item}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '19%' }}>
                        <label style={{ fontSize: "13px", fontWeight: "500", }}>BOM Name <span style={{ color: "red" }}>*</span></label>
                        <select
                          name="bomName"
                          value={formValue.bomName}
                          onChange={handleChange}
                          style={{ marginTop: '-5px', width: '100%' }}
                        >
                          <option value="" disabled selected>
                            BOM Name
                          </option>
                          {uniqueBomNameArray.map((item) => {
                            return (
                              <option>{item.bomName}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '19%' }}>
                        <label style={{ fontSize: "13px", fontWeight: "500", }}>Vehicle Model </label>
                        <select name="vehicleModel"
                          value={formValue.vehicleModel}
                          onChange={handleChange}
                          style={{ marginTop: '-5px', width: '100%' }}
                        >
                          <option value="" disabled selected>Vehicle Model</option>
                          {uniqueVehicleModelArray.map((item) => {
                            return (
                              <option>{item.vehicleModel}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '19%' }}>
                        <label style={{ fontSize: "13px", fontWeight: "500", }}>Vehicle Variant</label>
                        <select name="vehicleVariant"
                          value={formValue.vehicleVariant}
                          onChange={handleChange}
                          style={{ marginTop: '-5px', width: '100%' }}
                        >
                          <option value="" disabled selected>Vehicle Variant</option>
                          {uniqueVehicleVariantArray.map((item) => {
                            return (
                              <option>{item.vehicleVariant}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '19%' }}>
                        <label style={{ fontSize: "13px", fontWeight: "500", }}>WO ID <span style={{ color: "red" }}>*</span></label>
                        <select
                          // required={true}
                          name="orderID"
                          value={formValue.orderID}
                          onChange={handleChange}
                          style={{ marginTop: '-5px', width: '100%' }}
                        >
                          <option value="" disabled selected>
                            WO ID
                          </option>
                          {uniqueOrderIdArray.map((item) => {
                            return (
                              <option>{item.orderID}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '19%', marginLeft: '-10px' }}>
                        <label style={{ fontSize: "13px", fontWeight: "500", }}>WO Type <span style={{ color: "red" }}>*</span></label>
                        <select
                          // required={true}
                          name="workType"
                          value={formValue.workType}
                          onChange={handleChange}
                          style={{ marginTop: '-5px', width: '100%' }}
                        >
                          <option value="" disabled selected>
                            WO Type
                          </option>
                          {uniqueWorkTypeArray.map((item) => {
                            return (
                              <option>{item.workType}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '19%' }}>
                        <label style={{ fontSize: "13px", fontWeight: "500", marginLeft: '-10px' }}> Shop Floor Mail ID<span style={{ color: "red" }}>*</span></label>
                        <select
                          // required={true}
                          name="shopFloorEmailID"
                          value={formValue.shopFloorEmailID}
                          onChange={handleChange}
                          style={{ marginTop: '-5px', width: '100%', marginLeft: '-10px' }}
                        >
                          <option value="" disabled selected>
                            Shop Floor Mail ID
                          </option>
                          {uniqueShopFloorMailArray.map((item) => {
                            return (
                              <option>{item.shopFloorEmailID}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div style={{ margin: "24px 0 0 190px" }}>
                        <button type="submit" id="apply" style={{ width: "96px", height: "35px" }} onClick={fetchReport}>
                          Apply
                        </button>
                        <button onClick={clearFilter} id="clear" style={{ width: "96px", height: "35px" }}>
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                <br></br>


                <BootstrapTable
                  wrapperClasses="table-responsive"
                  // rowClasses="text-nowrap"
                  rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                  pagination={paginationFactory({
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    hideSizePerPage: true,
                    paginationSize: 2,
                    sizePerPage: "10",
                  })}
                  noDataIndication="No Data Found"
                  {...props.baseProps}
                />
              </>
            )}
          </ToolkitProvider>

        </div> : <EditWO rowdata={row} updatedData={fetchReport} closeModal={setShowedit} key="2" />}
    </>
  );
}
export default ManageWorkorder