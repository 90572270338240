import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import AddBom from "./add-bom";
// import ManageBom from "./manage-bom";

const AddBom = React.lazy(()=>import("./add-bom"))
const ManageBom = React.lazy(()=>import("./manage-bom"))



const Bom = () => {
  const tabs = [
    {
      id:"PDM3T1",
      label: TabLabels.ADD_BOM,
      Component: AddBom
    },
    {
      id:"PDM3T2",
      label: TabLabels.MANAGE_BOM,
      Component: ManageBom
    },
  ];

  const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
    return tabs.filter(tab => tabIds.includes(tab.id));
  };

  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>

  )
}
export default Bom