import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import ReturnRequest from "./returnRequest";
// import ReturnHistory from "./returnHistory";

const ReturnRequest = React.lazy(()=>import("./returnRequest"))
const ReturnHistory = React.lazy(()=>import("./returnHistory"))



const tabs = [
    {
        id: "SVCM6T1",
        label: TabLabels.RETURN_REQUEST,
        Component: ReturnRequest
    },
    {
        id: "SVCM6T2",
        label: TabLabels.RETURN_HISTORY,
        Component: ReturnHistory
    }
];

const ServiceCenterReturns = () => {
    return (
        <div className="app-layout__card">
            <Tabs tabs={tabs} type="pills" />
        </div>
    );
};

export default ServiceCenterReturns;
