import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification,errorNotification } from "../../../utils/notifications";

const DeleteBom = ({closeModal,updatedData,editData , setActionShow}) => {
  const [formValue, setFormValue] = useState({
    DeleteReason: "",
    description: "",
  });

  const editDetails = {
    "BOMID":editData.BOMID,
    "reason":formValue.DeleteReason,
    "reasonComment": formValue.description
   };

   const refresh = () => {
    updatedData()
};

  const submitHandler = async () => {
    if(formValue?.DeleteReason == ""){
      return errorNotification("Please select Delete Reason",{autoclose:2000});
    }
    else{
      const resp = await trackPromise(API.post(`production-module/api/v1/bom/delete`, editDetails));
    
      const { success,message} = resp;
      
      if (success) {
          closeModal(false);
          setActionShow(false)
          successNotification(message, { autoClose: 3000 });
          refresh()
      } else {
          errorNotification(message, { autoclose: 2000 });
      }
    }
 
  };


  const closeHandler = ()=>{
    setActionShow(false)
    closeModal(false)
  }




  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Delete BOM</h3>
         
        </div>

        <div className="body">
          <label style={{display:"flex", justifyContent:"flex-start", flexDirection:"column"}}>
          <label style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign:"start"
                  }}>Delete Reason <span style={{color:"red"}}>*</span></label>
            <select
              name="deleteReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{marginTop:"-5px"}}
            >
              <option disabled selected hidden>
                Delete Reason
              </option>
              <option value="Misconduct">
              Misconduct
              </option>
              <option value="Not an Active User">
              Not an Active User
              </option>
              <option value="Change of Role">
              Change of Role
              </option>
              <option value="Others">Others</option>
            </select>
          </label>

          {formValue.DeleteReason === "Others" ? 
          <label className="lgInput">
            Description
            <input
              type="text"
              style= {{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Delete Reason*"
              autoComplete="off"
            />
          </label> : ""}
        </div>
        <div className="footer">
          <button onClick={closeHandler}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteBom;
