import React, { useState } from "react";
import Edit from "./ChargesEdit";
import Reactivate from "./Reactivate";
import Deactivate from "./Deactivate";
import ChargesHistory from "./ChargesHistory";
import {
  faEdit,
  faBan,
  faTrash,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../components/modal/modal.scss";

const Action = ({ updatedData, rowData, setIsOpen, rowIndex }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      reactivate: false,
      deactivate: false,
      deleted: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {rowData.status === "Active" || rowData.status === "Reactivated"
        ? activeModal.edit &&
          showModal && (
            <Edit
              updatedData={updatedData}
              editData={rowData}
              closeModal={setIsOpen}
            />
          )
        : ""}

      {rowData.status === "Active" || rowData.status === "Reactivated"
        ? activeModal.deactivate &&
          showModal && (
            <Deactivate
              updatedData={updatedData}
              editData={rowData}
              closeModal={setIsOpen}
            />
          )
        : rowData.status === "Active" || rowData.status === "Deactivated"
        ? activeModal.reactivate &&
          showModal && (
            <Reactivate
              updatedData={updatedData}
              editData={rowData}
              closeModal={setIsOpen}
            />
          )
        : ""}

      {rowData.status === "Active" ||
      rowData.status === "Deactivated" ||
      rowData.status === "Reactivated"
        ? activeModal.deleted &&
          showModal && (
            <ChargesHistory
              updatedData={updatedData}
              editData={rowData}
              closeModal={setIsOpen}
            />
          )
        : ""}

      <div
        className="action"
        style={{
          position: "relative",
          marginTop: rowIndex <= 4 ? "0px" : "-145px",
        }}
        onBlur={() => setIsOpen(false)}
      >
        <div
          className="action vendoraction"
          style={{ marginLeft: "-30px", marginTop: "0px", width: "200px" }}
        >
          {rowData.status === "Active" || rowData.status === "Reactivated" ? (
            <div>
              {" "}
              <span id="edit" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status === "Active" ||
          rowData.status === "Reactivated" ||
          rowData.status === "Deactivated" ? (
            <div>
              {rowData.status === "Active" ||
              rowData.status === "Reactivated" ? (
                <span id="deactivate" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faBan} /> Deactivate
                </span>
              ) : (
                <span id="reactivate" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faDotCircle} /> Reactivate
                </span>
              )}
            </div>
          ) : (
            ""
          )}

          {rowData.status === "Active" ||
          rowData.status === "Deactivated" ||
          rowData.status === "Reactivated" ? (
            <div>
              <span id="deleted" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Updated History
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Action;
