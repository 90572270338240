import React from "react";
import {Tabs} from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import ConfigureStockLevels from "./configure-stocklevels";
// import ConfiguredStockLevels from "./configured-stocklevels";

const ConfigureStockLevels = React.lazy(()=>import("./configure-stocklevels"))
const ConfiguredStockLevels = React.lazy(()=>import("./configured-stocklevels"))

const StockManagement=()=>{
    const tabs = [
        {
          id:"INM3T1",
          label: TabLabels.CONFIGURE_STOCKLEVELS,
          Component: ConfigureStockLevels
        },
        {
          id:"INM3T2",
          label: TabLabels.CONFIGURED_STOCKLEVELS,
          Component: ConfiguredStockLevels
        },
    ];

    const getTabs = (tabs) => {
      let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
      return tabs.filter(tab => tabIds.includes(tab.id));
    };

    let tabsToShow = getTabs(tabs);
    return (
      <div className="app-layout__card">
        <Tabs tabs={tabsToShow} type="pills" />
      </div>
    );
}
export default StockManagement