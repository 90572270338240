import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import "../QcDashboard/styles/main.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { BsArrowUpRight, BsTable } from "react-icons/bs";
import { FaExpandAlt } from "react-icons/fa";
import { FaRedoAlt } from "react-icons/fa";
import { DatePicker, Space } from "antd";
import { useHistory } from "react-router-dom";
//Routing Components
import Vehicles from "../AreaSales/AreaSalesVehicle&Spares/components/Vehicles";
import Spares from "../AreaSales/AreaSalesVehicle&Spares/components/Spares";
import VehiclesOrders from "../AreaSalesOrder/VehicleOrders/NewRequest";
import SparesOrders from "../AreaSalesOrder/SparesOrders/NewRequest";
import VehiclesReturns from "../AreaSalesReturn/VehicleReturns/Component/NewRequest";
import SpareReturns from "../AreaSalesReturn/SpareReturns/NewRequest";
// Dashboard Components
import VehicleNSpares from "./VehicleNSpares";
import Orders from "./Orders";
import Returns from "./Returns";

const AreaSalesDashboard = () => {
  const [openVehicleTable, setOpenVehicleTable] = useState(false);
  const [openSparesTable, setOpenSparesTable] = useState(false);
  const [openVehiclesOrderTable, setOpenVehiclesOrderTable] = useState(false);
  const [openSparesOrderTable, setOpenSparesOrderTable] = useState(false);
  const [openVehicleReturnTable, setOpenVehicleReturnTable] = useState(false);
  const [openSparesReturnTable, setOpenSparesReturnTable] = useState(false);

  const { RangePicker } = DatePicker;
  const [calenderOpen, setCalenderOpen] = useState(false);

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    setCalenderOpen(false);
    document.getElementById("overlay").style.display = "none";
    // refresh();
    // vpaRefresh();
    // spareRefresh();
    // vehicleRefresh();
    // verification();
    // reVerificationRefresh();
  }

  const history = useHistory();

  return (
    <>
      {openVehicleTable == true ? (
        <Vehicles inDashboard={setOpenVehicleTable} />
      ) : openSparesTable == true ? (
        <Spares inDashboard={setOpenSparesTable} />
      ) : openVehiclesOrderTable == true ? (
        <VehiclesOrders inDashboard={setOpenVehiclesOrderTable} />
      ) : openSparesOrderTable == true ? (
        <SparesOrders inDashboard={setOpenSparesOrderTable} />
      ) : openVehicleReturnTable == true ? (
        <VehiclesReturns inDashboard={setOpenVehicleReturnTable} />
      ) : openSparesReturnTable == true ? (
        <SpareReturns inDashboard={setOpenSparesReturnTable} />
      ) : (
        <>
          <div id="overlay" onClick={(e) => off(e)}></div>
          <div
            className="thirdBox"
            style={{
              height: "auto",
              marginTop: "10px",
              padding: "23px 0px",
            }}
          >
            {/* First Row */}
            <>
              <div
                className="container-new"
                style={{ width: "98%", height: "auto", marginBottom: "5%", marginLeft:"10px" }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "98%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Vehicles</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "35%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => {
                          setOpenVehicleTable(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <VehicleNSpares prop="vehicle" />
              </div>
            </>
            {/* Second Row */}
            <>
              <div
                className="container-new"
                style={{ width: "98%", height: "auto", marginBottom: "5%", marginLeft:"10px" }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "98%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Spares</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "35%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => {
                          setOpenSparesTable(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <VehicleNSpares prop="Spares" />
              </div>
            </>
            {/* Third Row */}
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "inherit",
                }}
              >
                <div
                  className="pricing"
                  style={{ height: "265px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Vehicle Orders</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenVehiclesOrderTable(true)}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <Orders prop="vehicle" />
                  </div>
                </div>
                <div
                  className="pricing"
                  style={{ height: "265px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Spare Orders</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenSparesOrderTable(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <Orders prop="spares" />
                  </div>
                </div>
              </div>
            </>
            {/* Fourth Row */}
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "inherit",
                }}>
                <div
                  className="pricing"
                  style={{ height: "265px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Vehicle Returns</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenVehicleReturnTable(true)}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <Returns prop="vehicle" />
                  </div>
                </div>
                <div
                  className="pricing"
                  style={{ height: "265px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Spare Returns</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenSparesReturnTable(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <Returns prop="spares" />
                  </div>
                </div>
              </div>
            </>
          </div>
        </>
      )}
    </>
  );
};

export default AreaSalesDashboard;
