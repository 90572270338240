import React from 'react';
import { Tabs } from 'react-simple-tabs-component';
import { TabLabels } from '../../utils/app-constants';
import "../settings-tabs/tabs.scss";
// import Vehicles from './Vehicles';
// import Spares from './Spares'

const Vehicles = React.lazy(()=>import('./Vehicles'))
const Spares = React.lazy(()=>import('./Spares'))


const VehicleSpares = () =>{

    const tabs = [
        {
        id:"DEM2T1",
        label:TabLabels.VEHICLE,
        Component:Vehicles
        },
        {
        id:"DEM2T2",
        label:TabLabels.SPARES,
        Component:Spares
        }
    ]

    return(
        <div className='app-layout__card'>
            <Tabs type="pills" tabs={tabs} />
        </div>
    )
}

export default VehicleSpares