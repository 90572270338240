import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { display, flexbox } from "@mui/system";
import ViewCustomerDetails from "../Components/ViewCustomerDetails";
import SpareDetails from "./SpareDetails";

const ResumeJobcard = ({ updatedData, rowData, closeModal , setIsOpen}) => {
  const [openSpare, setOpenSpare] = useState();
  const [spareData, setSpareData] = useState();
  const [formValue, setFormValue] = useState({
    reason: "",
    description: "",
  });

  const editDetails = {
    jobID: rowData.jobID,

    status: "Job Resumed",

    reason: formValue.reason,

    description: formValue.description,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    console.log(formValue);
    const resp = await trackPromise(
      API.post(
        `serviceAdvisor-module/api/v1/serviceJobs/updateStatus`,
        editDetails
      )
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      setIsOpen(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const spareDetailsFormatter = (rowData) => {
    setOpenSpare(true);
    setSpareData(rowData);
  };



  const closeHandler = ()=>{
    setIsOpen(false)
    closeModal(false)
  }

  


  return (
    <div className="container1">
      {openSpare ? (
        <SpareDetails rowData={spareData} closeModal={setOpenSpare} />
      ) : (
        ""
      )}
      <div className="modalContainer">
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Resumed Job Card</h3>
          <div>
            {/* <button className="action-btn" style={{ color: "blue" }}>
              <PrintIcon />
              &nbsp;Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              <IosShareIcon />
              &nbsp;Export
            </button> */}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 8,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h7 style={{textAlign:"start"}}>Job Card ID : {rowData.jobID}</h7>
            <h7 style={{textAlign:"start"}}>Vehicle Reg. No. {rowData.vehicleRegistrationNo}</h7>
            <h7 onClick={() => spareDetailsFormatter(rowData)}
              style={{ color: "#0A18A3" ,textAlign:"start" }}>View Spare Details</h7>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h7 style={{textAlign:"end"}}>Job Date : {moment(rowData.jobDate).format("DD-MMM-YYYY/HH:mm")}</h7>
            <h7 style={{textAlign:"end"}}>
              Delivery Date : {moment(rowData.jobDate).format("DD-MMM-YYYY/HH:mm")}
            </h7>
            <h7 style={{ color: "#e65c00", textAlign:"end" }}>{rowData.status}</h7>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "start",
            margin: 7,
            backgroundColor: "#e6e6ff",
            padding: 5,
            borderRadius: 5,
          }}
        >
          <h7>Halt Date { }</h7>
          <h7>Halt Reason { }</h7>
        </div>

        <div className="body">
          <label
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label
              style={{ fontSize: "13px", fontWeight: "500", width: "120px" }}
            >
              Resumed Job Card<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="reason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: "100%", marginTop: "-5px" }}
            >
              <option disabled selected hidden>
                Select Reason
              </option>
              <option value="Misconduct">Remaining Spare Received</option>
              <option value="Not an Active User">
                Electricity Issue Resolved
              </option>
              <option value="Change of Role">
                Pump Unit Function Unit restored
              </option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.reason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Put On Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button id="submit-button" onClick={submitHandler}
           disabled={formValue.reason == "" ? true : false}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResumeJobcard;
