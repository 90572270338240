import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import Vehicles from "./VehicleOrders/VehicleOrder";
// import Spares from "./SparesOrders/SpareOrder";

const Vehicles = React.lazy(()=>import("./VehicleOrders/VehicleOrder"))
const Spares = React.lazy(()=>import("./SparesOrders/SpareOrder"))


const NationalSalesOrders = () => {
  const tabs = [
    {
      id: "NSMM3T1",
      label: TabLabels.VEHICLE_ORDER,
      Component: Vehicles,
    },
    {
      id: "NSMM3T2",
      label: TabLabels.SPARE_ORDERS,
      Component: Spares,
    }
  ];

  return (
    <div className="app-layout__card">
      <Tabs type="pills" tabs={tabs} />
    </div>
  );
};

export default NationalSalesOrders;
