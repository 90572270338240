import React, { useState } from "react";
import Edit from "./EditComponents";
import Reactivate from "./ReactivateComponents";
import Discontinue from "./DiscontinueComponents";
import Delete from "./DeleteComponents";
import "../../../components/modal/modal.scss";
import AppConstants from "../../../utils/app-constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const ComponentsActions = ({ updatedData, rowData, status, setIsOpen, rowIndex }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      reactivate: false,
      discontinue: false,
      delete: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && <Edit updatedData={updatedData} editData={rowData} closeModal={setShowModal} setIsOpen={setIsOpen}/>}

      {rowData.status === AppConstants.ACTIVE || rowData.status === AppConstants.REACTIVATED ?
        activeModal.discontinue && showModal &&
        <Discontinue updatedData={updatedData} editData={rowData} closeModal={setShowModal} setIsOpen={setIsOpen}/>
        : activeModal.reactivate && showModal &&
        <Reactivate updatedData={updatedData} editData={rowData} closeModal={setShowModal} setIsOpen={setIsOpen}/>
      }

      {activeModal.delete && showModal && <Delete updatedData={updatedData} editData={rowData} closeModal={setShowModal} setIsOpen={setIsOpen} />}

      <div style={ rowIndex === 0 || rowIndex === 1 ? { position: "relative" } : {position:"relative", marginTop:"-125px"}}>
        <div className="action vendorAction" style={{width:"125px", marginLeft:"-71px"}}>
          <div>
            <span id="edit" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </span>
          </div>
          <div>
            {rowData.status === AppConstants.ACTIVE || rowData.status === AppConstants.REACTIVATED ? (
              <span id="discontinue" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faDotCircle} /> Deactivate
              </span>
            ) : (
              <span id="reactivate" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faDotCircle} /> Reactivate
              </span>
            )}
          </div>
          <div>
            <span id="delete" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faTrash} />  Delete
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentsActions;
