import React, { useState } from "react";
import "../../../../components/modal/modal.scss";
import { faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditEnquirySales from "./EditEnquirySales";
import EditBookingSales from "./EditBookingSales";
import ConfirmationPopup from "../../ShowroomCustomerEnquiry/ConfirmationPopup";

const VehicleSalesAction = ({ updatedData, rowData, status, rowIndex }) => {
  console.log(rowData);
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      remove: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && rowData.saleType == "Booking Sale" && (
        <EditBookingSales
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.edit && showModal && rowData.saleType == "Enquiry Sale" && (
        <EditEnquirySales
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.remove && showModal && (
        <ConfirmationPopup
          updatedData={updatedData}
          formData={rowData}
          closeModal={setShowModal}
          tabName="vehicleSalesDelete"
        />
      )}
      <div
        style={
          rowIndex === 0 || rowIndex === 1
            ? { position: "relative" }
            : { position: "relative", marginTop: "-148px" }
        }
      >
        <div
          className="vehicleaction"
          style={{
            width: "205px",
            marginLeft: "-180px",
            background: "#E5E5E5",
          }}
        >
          <div>
            <span id="edit" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faEdit} /> View & Edit
            </span>
          </div>
          <div>
            <span id="remove" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faTimesCircle} /> Move to Sales History
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleSalesAction;
