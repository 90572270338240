import React, { useEffect, useState } from 'react';
import Group from '../QcDashboard/assets/Group.png';
import Group21 from '../QcDashboard/assets/Group21.png';
import Group22 from '../QcDashboard/assets/Group22.png';
import Group2 from '../QcDashboard/assets/Group2.png';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';


function SalesAreasDash() {
    const [UsersData, setUsersData] = useState([]);

    const fetchReport = async () => {
        const resp = await trackPromise(
            API.get(
                `nationalSales-module/api/v1/users/manageUsers`
            )
        );
        let filterData = resp.filter((item) => {
            return item.role == "Area Sales" || item.role == "Zonal Sales"
        });
        setUsersData(filterData);
    };
    useEffect(() => {
        fetchReport();
    }, []);

    let areaSales = UsersData.filter((item) => {
        return item.role == "Area Sales"
    });

    let zonalSales = UsersData.filter((item) => {
        return item.role == "Zonal Sales"
    });
    let activeUser = UsersData.filter((item) => {
        return item.status == "Active"
    });

    return (
        <>
            <div className='grid-container'>
                <div className='shopFloorRequest' style={{ width: "230px", height: "70px", padding: "10px", display: "flex", justifyContent: "space-around" }}>
                    <img src={Group} style={{ marginLeft: "8px" }} alt="group" />
                    <div>
                        <span style={{ fontSize: "16px", fontWeight: "500", color: "#3442CF" }}>Total Users</span>
                        <div style={{ fontSize: "26px", fontWeight: "500" }}>{UsersData.length}</div>
                    </div>
                </div>

                <div className='shopFloorRequest' style={{ width: "230px", height: "70px", padding: "10px", display: "flex", justifyContent: "space-around" }}>
                    <img src={Group22} style={{ marginLeft: "8px" }} alt="group" />
                    <div>
                        <span style={{ fontSize: "16px", fontWeight: "500", color: "#3442CF" }}>Zonal Sales</span>
                        <div style={{ fontSize: "26px", fontWeight: "500" }}>{zonalSales.length}</div>
                    </div>
                </div>

                <div className='shopFloorRequest' style={{ width: "230px", height: "70px", padding: "10px", display: "flex", justifyContent: "space-around" }}>
                    <img src={Group21} style={{ marginLeft: "8px" }} alt="group" />
                    <div>
                        <span style={{ fontSize: "16px", fontWeight: "500", color: "#FDAA48" }}>Area Sales</span>
                        <div style={{ fontSize: "26px", fontWeight: "500" }}>{areaSales.length}</div>
                    </div>
                </div>

                <div className='shopFloorRequest' style={{ width: "230px", height: "70px", padding: "10px", display: "flex", justifyContent: "space-around" }}>
                    <img src={Group2} style={{ marginLeft: "8px" }} alt="group" />
                    <div>
                        <span style={{ fontSize: "16px", fontWeight: "500", color: "#21A82E" }}>Active Users</span>
                        <div style={{ fontSize: "26px", fontWeight: "500" }}>{activeUser.length}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalesAreasDash;