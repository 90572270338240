import React from 'react'
import {
  CancelOutlined,
  CheckCircle,
} from "@material-ui/icons";
import { trackPromise } from 'react-promise-tracker';
import API from '../../../utils/api';
import { errorNotification, successNotification } from '../../../utils/notifications';

const MROInventory = ({ close, closeModal, rowData, requestID, componentID, skuCode, tableRefresh, partSnoTableRefresh, tabName }) => {

  const compSubmitHandler = async () => {
    const rejectionData = {
      "requestID": requestID,
      "componentID": componentID,
      "componentSno": rowData
    }
    const resp = await trackPromise(API.post(`inventory-module/api/v1/inventorygoodsverification/moveToMROInventory`, rejectionData));
    console.log(resp);
    resp.success == true ? successNotification(resp.message) : errorNotification(resp.message);
    closeModal(false);
    tableRefresh();
    partSnoTableRefresh();
  };

  const SpareSubmitHandler = async () => {
    const rejectionData = {
      "requestID": requestID,
      "skuCode": skuCode,
      "partSno": rowData.partSno
    }
    const resp = await trackPromise(API.post(`inventory-module/api/v1/inventoryGoodsVerification/moveToMROInvSpares`, rejectionData));
    console.log(resp);
    resp.success == true ? successNotification(resp.message) : errorNotification(resp.message);
    if (resp.modalClose) {
      close(false)
    }
    closeModal(false)
    tableRefresh();
    partSnoTableRefresh();
  };

  return (
    <div className='container1'>
      <div className="modalContainer" style={
        { width: '430px', height: '200px' }
      }>

        <div className="body" style={{ justifyContent: 'center', fontSize: '16px', padding: "0px" }}>
          Are you Sure to move this Component to MRO Inventory  ?

        </div>
        <div className="footer" style={{ justifyContent: "center" }}>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: 'white',
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            onClick={tabName == 'components' ? () => compSubmitHandler() : () => SpareSubmitHandler()}
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>

        </div>
      </div>
    </div>
  )
}

export default MROInventory;