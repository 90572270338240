import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
// import "./modal.scss";
import { successNotification, errorNotification } from "../../../../utils/notifications";
import { IoMdRefresh } from "react-icons/io";
import { KeyboardBackspace } from "@material-ui/icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConfirmRelease = ({ editData, closeModal }) => {
  // console.log(editData);
  console.log("hi")
  const editDetails = {
    userID: editData.userID,
  };

  const submitHandler = async () => {
    console.log(editData);
    // const resp = await trackPromise(
    //   API.post(`admin-module/api/v1/users/approve`, editDetails)
    // );
    // console.log(resp);
    // const { success, message } = resp;

    // // const notifyBody = 
    // // {
    // //   "role":["Sub Admin"],
    // //   "title":"Inventory request approval",
    // //   "body":`Request for ${editData.action} is approved by Super Admin.`,
    // //   "requestID":editData.requesterID
    // // }
    // if (success) {
    //   successNotification(message, { autoClose: 3000 });
    //   closeModal(false)
    //   // const resp = await trackPromise(
    //   //   API.post(`inventory-module/api/v1/grn/notification`, notifyBody)
    //   // );
    // } else {
    //   errorNotification(message, { autoclose: 2000 });
    // }
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "377px", height: "214px" }}
      >
        <div className="header">
          <h3>Approve Deactivation Request</h3>
        </div>

        <div className="body" style={{ justifyContent: "center" }}>
          Are You sure to send release request to
          <br />
          Admin
        </div>
        <div className="footer" style={{ justifyContent: "center" }}>
          <button onClick={() => closeModal(false)}>
            {" "}
            <KeyboardBackspace /> Back
          </button>
          <button id="submit-button" onClick={submitHandler}>
            <FontAwesomeIcon icon={faCheckCircle} /> Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRelease;
