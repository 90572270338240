import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import AddReturns from "./component/AddReturns/AddReturns";
// import ManageReturns from "./component/ManageReturns/ManageReturns";

const AddReturns = React.lazy(()=>import("./component/AddReturns/AddReturns"))
const ManageReturns = React.lazy(()=>import("./component/ManageReturns/ManageReturns"))


const StoreManagerReturns = () => {
  const tabs = [
    {
      id: "STMM5T1",
      label: TabLabels.ADD_RETURNS,
      Component: AddReturns,
    },
    {
      id: "STMM5T2",
      label: TabLabels.MANAGE_RETURNS,
      Component: ManageReturns,
    },
  ];

  return (
    <div className="app-layout__card">
      <Tabs type="pills" tabs={tabs} />
    </div>
  );
};

export default StoreManagerReturns;
