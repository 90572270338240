import React from 'react';
import moment from "moment";


const UsedViewQuantity = ({editData ,closeModal}) => {

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "615px", height: "450px", padding: "15px" }}
      >
        <div className="header">
          <h3>Component Usage</h3>
        </div>

        <br />
        <div style={{height:'400px', overflow:'scroll'}}>
          <ul className='OH_Ul'>
                <li className='OH_list grnReceived_list'>
                  <div className='OH_list_div'>
                    <span>10 Used </span>
                    <span>Date: </span>
                    <span></span>
                  </div>
                </li>
                <li className='OH_list grnReceived_list'>
                  <div className='OH_list_div'>
                    <span>10 Used </span>
                    <span>Date: </span>
                    <span></span>
                  </div>
                </li>
                <li className='OH_list grnReceived_list'>
                  <div className='OH_list_div'>
                    <span>10 Used </span>
                    <span>Date: </span>
                    <span></span>
                  </div>
                </li>
                <li className='OH_list grnReceived_list'>
                  <div className='OH_list_div'>
                    <span>10 Used </span>
                    <span>Date: </span>
                    <span></span>
                  </div>
                </li>
              
                <li className='OH_list grnReceived_list'>
                    <div className='OH_list_div'>
                    <span>12 Used </span>
                      <span>Date: </span>
                    <span></span>
                    </div>
                </li> 
                <li className='OH_list grnReceived_list'>
                    <div className='OH_list_div'>
                    <span>14 Used </span>
                      <span>Date: </span>
                    <span></span>
                    </div>
                </li> 
              
                  
          
          </ul>
        </div>

        <div className="footer">
          <button onClick={() => closeModal(false)}>Back</button>
        </div>
      </div>
    </div>
  )
}

export default UsedViewQuantity