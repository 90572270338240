import React, { useState } from "react";
import AppConstants from "../../../utils/app-constants";
import "../../work-order/workorder.scss";
import moment from "moment";

const ReceivedQtyWithStatus = ({ close, rowdata, returnedQty }) => {
  console.log(rowdata)

    const statusFormatter = (cell) => {
        return (
          cell == AppConstants.PASSED? 
          <button className="active-btn"><span className="active-btn-font">Verification Passed</span></button> : 
          cell == AppConstants.FAILED ? 
          <button className="delete-btn"><span className="delete-btn-font">Verification Failed</span></button>:
          cell == AppConstants.PENDING ? 
          <button className="deactive-btn"><span className="deactive-btn-font">Verification Pending</span></button> :cell  
        );
      };
 
  return (
    <>
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "900px", height:"506px", padding: "15px" }}
      >
        <div className="header">
          <h3>Received Qty.</h3>
        </div>

    <div className="body" style={{height:"315px"}}> 

    <div
        style={{
            width:"100%",
          display: "flex",
          flexDirection: "column",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Component Name: {rowdata.componentName}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Sent for Verification Date: {moment(rowdata.partCheckDate).format("DD-MMM-YYYY,HH:mm")}</span>
        </div>
      </div>

      
         <div style={{width:"100%",overflow:"auto",height:"265px"}}>     
          <table className="simple_tbl" width="100%"> 
          <thead>
            <tr>
                <th style={{width:"10%"}}>S.No.</th>
                <th>Part Serial No.</th>
                <th>Status</th>
                <th>Reason</th>
            </tr>
            </thead>
      {rowdata.componentSno.map((item, index)=> {
         return(
            <tr>
                <td>{++index}</td>
                <td>{item.partSno}</td>
                <td>{statusFormatter(item.status)}</td>
                <td>{item.description}</td>
            </tr>
         )})}
            </table>        
            </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => close(false)}>Back</button>
        </div>
      </div>
    </div>
    </>
  );
};

export default ReceivedQtyWithStatus;