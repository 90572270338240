import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";

const AddedQty = ({ closeModal, rowdata, data }) => {
    const [values, setValues] = useState({});

    // console.log(data)
    // data?.map((item) => {
    //     return setValues(item)
    // })

    // console.log(values)

    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "780px", height: "450px", padding: '15px' }}
            >
                <div className="header">
                    <h3>Sold Qty</h3>
                </div>

                <section
                    style={{
                        width: '100%',
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "14px",
                        fontWeight: "500",
                        margin: "10px 0"
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                        <span>Vehicle Category : {data.vehicleCategory}</span>
                        <span>Vehicle Model  : {data.vehicleModel}</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                        <span>Vehicle Segment : {data.vehicleSegment}</span>
                        <span>Vehicle Variant  : {data.vehicleVariant}</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                        <span>Vehicle Type : {data.vehicleType}</span>
                        <span>Vehicle Color  : {data.vehicleColor}</span>
                    </div>
                </section>

                <div
                    className="body"
                    style={{ display: "flex", height: '500px', fontSize: "14px", overflow: 'scroll', marginTop: '20px' }}
                >

                    <section style={{ width: '100%' }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '10%' }}>S.No.</div>
                            <div style={{ width: '30%' }}>Vehicle S.No.</div>
                            <div style={{ width: '20%' }}>VIN</div>
                            <div style={{ width: '20%' }}>BIN</div>
                            <div style={{ width: '20%' }}>Addition Date</div>
                        </div>


                        {rowdata.map((part, i) => {
                            return (
                                <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }}>
                                    <div style={{ width: '10%', overflowY: "auto" }}>{i + 1}</div>
                                    <div style={{ width: '30%', overflowY: "auto" }}>{part.SNO}</div>
                                    <div style={{ width: '20%', overflowY: "auto" }}>{part.VIN}</div>
                                    <div style={{ width: '20%', overflowY: "auto" }}>{part.BIN}</div>
                                    <div style={{ width: '20%', overflowY: "auto" }}>{part.requestDate}</div>
                                </div>
                            )
                        })}
                    </section>

                </div>
                <div className="footer">
                    <button
                        style={{
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                            padding: "5px 15px",
                            marginLeft: "500px",
                            color: "#0A18A3",
                            cursor: 'pointer'
                        }}
                        onClick={() => closeModal(false)}
                    >
                        <KeyboardBackspace style={{ fontSize: "17px" }} />
                        Back
                    </button>
                </div>


            </div>
        </div>
    );
};

export default AddedQty;