import React, { useState } from "react";
import Edit from "./EditComponents";
import Reactivate from "./ReactivateComponents";
import Deactivate from "./DeactivateComponents";
import Delete from "./DeleteComponents";
import "../../../../components/modal/modal.scss";
import AppConstants from "../../../../utils/app-constants"
import ViewHistory from './ViewHistory'
import { faBan, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Actions = ({rowData, status, updatedData , rowIndex , setOpen}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      reactivated: false,
      deactivate: false,
      delete: false,
      viewHistory:false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && <Edit updatedData={updatedData} editData={rowData} closeModal={setShowModal} setOpen={setOpen}/>}
      
      { rowData.status === AppConstants.ACTIVE || rowData.status === AppConstants.REACTIVATED ?
       activeModal.deactivate &&showModal && 
       <Deactivate updatedData={updatedData}  editData={rowData} closeModal={setShowModal} setOpen={setOpen} />
        : activeModal.reactivated && showModal && 
       <Reactivate updatedData={updatedData} editData={rowData} closeModal={setShowModal} setOpen={setOpen} />
      }

      {activeModal.delete && showModal && <Delete updatedData={updatedData} editData={rowData} closeModal={setShowModal} setOpen={setOpen} />}
      {activeModal.viewHistory && showModal && <ViewHistory editData={rowData} closeModal={setActiveModal} />}

      <div style={{ position: "relative" ,marginTop: rowIndex <= 4 ? "0px" : "-145px",}}>
        <div className="action vendorAction">
            {rowData.status === AppConstants.ACTIVE || rowData.status === AppConstants.REACTIVATED || rowData.status === AppConstants.DEACTIVATED ? (
               <>
               {rowData.status === AppConstants.ACTIVE || rowData.status === AppConstants.REACTIVATED ? (
                    <div>
                    <span id="edit" onClick={(e) => clickHandler(e)}>
                    <FontAwesomeIcon icon={faEdit} /> Edit
                    </span>
                  </div>

               ):""}
              
          <div>
            {rowData.status === AppConstants.ACTIVE || rowData.status === AppConstants.REACTIVATED ? (
              <span id="deactivate" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faBan} /> Deactivate
              </span>
            ) : (
              <span id="reactivated" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faBan} /> Reactivate
              </span>
            )}
          </div>
          <div>
            <span id="delete" onClick={(e) => clickHandler(e)}>
            <FontAwesomeIcon icon={faTrash} /> Delete
            </span>
          </div>
               </>
            ):
            <div>
            <span id="viewHistory" onClick={(e) => clickHandler(e)}>
            <FontAwesomeIcon icon={faEdit} />  View History
            </span>
          </div>
          }
          
          
        </div>
      </div>
    </div>
  );
};

export default Actions;
