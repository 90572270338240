import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';
import moment from 'moment';

function InwardsGraph({startDate, endDate}) {
    const [vendorGraphData, setVendorGraphData] = useState({});

    const fetchVendorGraphData = async () => {
        const resp = await trackPromise(API.get(startDate==null && endDate==null ? `inventory-module/api/v1/dashboard/getInwardData` : `inventory-module/api/v1/dashboard/getInwardData?startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}`))
        setVendorGraphData(resp);
    };
    useEffect(() => {
        fetchVendorGraphData();
    }, [endDate]);

    
    return (
        <div style={{ width: "53%", marginLeft: "10px" }}>
            <Doughnut
                height={53}
                data={{
                    datasets: [{
                        data: [vendorGraphData.totalComponentReturns, vendorGraphData.totalVehicleReturns],
                        backgroundColor: [
                            'rgba(33, 168, 46, 1)',
                            'rgba(253, 170, 72, 1)'
                        ],
                        options: {
                            cutoutPercentage: 80
                        },
                        hoverOffset: 4,
                    }
                    ],
                    labels: ["Total Return Components", "Total Return Vehicles"]
                }} />
            <div style={{ margin: "-70% 0 0 100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(33, 168, 46, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "160px", fontSize: "14px", fontWeight: "500", marginTop: "3px" }}>Total Return Components</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "700", marginTop: "3px" }}>
                        {vendorGraphData.totalComponentReturns}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(253, 170, 72, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "160px", fontSize: "14px", fontWeight: "500", marginTop: "3px" }}>Total Return Vehicle</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "700", marginTop: "3px" }}>
                        {vendorGraphData.totalVehicleReturns}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default InwardsGraph