import { Close, KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import auth from "../../utils/auth";
import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";
import TermsView from "../TermsView/TermsView";

const TaxInvoiceView = ({ rowData, closeModal }) => {
  console.log("rowData", rowData);

  let componentRef = null;

  const totalSum = parseInt(
    parseInt(rowData.exShowroomPrice) +
      (parseInt(rowData.exShowroomPrice) * parseInt(rowData.cgst)) / 100 +
      (parseInt(rowData.exShowroomPrice) * parseInt(rowData.sgst)) / 100
  );

  function numberToWords(num) {
    if (num === 0) return "Zero";

    const belowTwenty = [
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    const tens = [
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const thousands = ["", "Thousand", "Lakh", "Crore"];

    function helper(n) {
      if (n < 20) return belowTwenty[n - 1];
      if (n < 100)
        return (
          tens[Math.floor(n / 10) - 2] +
          (n % 10 ? " " + belowTwenty[(n % 10) - 1] : "")
        );
      if (n < 1000)
        return (
          belowTwenty[Math.floor(n / 100) - 1] +
          " Hundred" +
          (n % 100 ? " " + helper(n % 100) : "")
        );

      for (let i = 0, unit = 1; i < thousands.length; i++) {
        const divisor = i === 0 ? 1 : i === 1 ? 1000 : 100000;
        if (n < divisor * 100)
          return (
            helper(Math.floor(n / divisor)) +
            " " +
            thousands[i] +
            (n % divisor ? " " + helper(n % divisor) : "")
          );
      }
    }

    function capitalizeFirstLetter(string) {
      return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    }

    return capitalizeFirstLetter(helper(num));
  }

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          width: "70%",
          height: "600px",
          padding: "15px",
          marginTop: "95px",
          marginBottom: "100px",
          borderRadius: "0px",
          borderTop: "none",
        }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Tax Invoice</h3>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <button
                    style={{
                      color: "#0A18A3",
                      margin: "1px",
                      height: "27px",
                      width: "90px",
                      border: "none",
                    }}
                  >
                    Download
                  </button>
                );
              }}
              content={() => componentRef}
              documentTitle="Tax Invoice"
              pageStyle="Print"
            />
          </div>
        </div>

        <div
          style={{ overflowY: "scroll", overflowX: "hidden", width: "100%" }}
        >
          <div
            // className="body"
            style={{
              width: " 99%",
              height: "97%",
              display: "flex",
              margin: "5px",
              flexDirection: "column",
              // justifyContent: "space-between",
              // pageBreakBefore: "always",
            }}
            ref={(el) => (componentRef = el)}
          >
            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <span>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Tata_logo.svg/1200px-Tata_logo.svg.png"
                      style={{
                        width: "60px",
                        height: "60px",
                        marginBottom: "30px",
                      }}
                      alt="Car"
                    />
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span style={{ fontWeight: "500", fontSize: "14px" }}>
                    {auth.getUserInfo()?.businessName}
                  </span>
                  <span style={{ fontWeight: "100", fontSize: "14px" }}>
                    Ph. : {auth.getUserInfo()?.contactPersonNumber}
                  </span>
                  <span style={{ fontWeight: "100", fontSize: "14px" }}>{`${
                    auth.getUserInfo()?.location.address1
                  } , ${auth.getUserInfo()?.location.address2} , ${
                    auth.getUserInfo()?.location.city
                  } , ${auth.getUserInfo()?.location.state} , ${
                    auth.getUserInfo()?.location.zipCode
                  }`}</span>
                  <span style={{ fontWeight: "100", fontSize: "14px" }}>
                    GSTIN : {auth.getUserInfo()?.gst}
                  </span>
                </div>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <section
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "20px",
                  fontWeight: "500",
                  border: "1px solid black", // Combined border for the entire table
                }}
              >
                Tax Invoice
              </section>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    border: "1px solid black", // Combined border for the entire table
                    overflow: "hidden", // Optional: Hiding overflowing content
                    width: "40%",
                    padding: "12px",
                    borderTop: "none",
                    // height: "100%",
                  }}
                >
                  <span style={{ fontWeight: "600", fontSize: "14px" }}>
                    Invoice to :{" "}
                  </span>
                  <span style={{ fontWeight: "400", fontSize: "14px" }}>
                    Customer Name: <span>{rowData.customerName}</span>
                  </span>
                  <span style={{ fontWeight: "400", fontSize: "14px" }}>
                    Address : {rowData.customerAddress}
                  </span>
                  <span style={{ fontWeight: "400", fontSize: "14px" }}>
                    Ph. : {rowData.customerPhone}
                  </span>
                  <span style={{ fontWeight: "400", fontSize: "14px" }}>
                    Email : {rowData.customerEmail}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    border: "1px solid black",
                    borderLeft: "none",
                    borderTop: "none",
                    overflow: "hidden",
                    width: "60%",
                    // height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        borderRight: "1px solid black",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        Invoice No : {rowData.invoiceNo}
                      </span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        Invoice Date :{" "}
                        {moment(rowData.invoiceDate).format("DD-MMM-YYYY")}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        borderRight: "1px solid black",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        Order No{" "}
                        {
                          (rowData.saleType = "Enquiry Sale"
                            ? rowData.enquiryID
                            : rowData.bookingID)
                        }
                      </span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        Order Date{" "}
                        {
                          (rowData.saleType = "Enquiry Sale"
                            ? moment(rowData.enquiryDate).format("DD-MMM-YYYY")
                            : rowData
                                .moment(rowData.bookingDate)
                                .format("DD-MMM-YYYY"))
                        }
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "none",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        borderRight: "1px solid black",
                        borderBottom: "1px solid black",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        Mode/Terms of Payment : {rowData.paymentType}
                      </span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        padding: "5px 10px",
                        display: "flex",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        H.P From : {rowData?.financerName}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "none",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        borderRight: "1px solid black",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        Disp. From : {auth.getUserInfo()?.businessName}
                      </span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span
                        style={{ fontWeight: "100", fontSize: "14px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                fontSize: "14px",
                // border: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "170%",
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <div
                  style={{
                    width: "101%",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                    fontWeight: "600",
                    borderRight: "none",
                  }}
                >
                  Description
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "110px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    fontSize: "14px",
                    borderLeft: "1px solid black",
                    paddingLeft: "3px",
                    fontWeight: "100",
                  }}
                >
                  <span>Model : {rowData.vehicleModel}</span>
                  <span>Variant : {rowData.vehicleVariant}</span>
                  <span>Type : {rowData.vehicleType}</span>
                  <span>Color : {rowData.vehicleColor}</span>
                  <span>CSGT % </span>
                  <span>SGST %</span>
                </div>
                <div
                  style={{
                    width: "101%",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                    borderRight: "none",
                  }}
                >
                  Total
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                    fontWeight: "600",
                    borderRight: "none",
                  }}
                >
                  HSN/SAC
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "110px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    fontSize: "14px",
                    borderLeft: "1px solid black",
                    fontWeight: "100",
                  }}
                >
                  <span>{rowData.HSNSACcode}</span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span> </span>
                  <span> </span>
                </div>
                <div
                  style={{
                    width: "101%",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                  }}
                ></div>
              </div>

              <div
                style={{
                  width: "50%",
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                    fontWeight: "600",
                    borderRight: "none",
                  }}
                >
                  Quantity
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "110px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    fontSize: "14px",
                    borderLeft: "1px solid black",
                    alignItems: "center",
                    fontWeight: "100",
                  }}
                >
                  <span style={{ fontWeight: "100" }}>{"1"}</span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span> </span>
                  <span> </span>
                </div>
                <div
                  style={{
                    width: "101%",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                  }}
                ></div>
              </div>

              <div
                style={{
                  width: "80%",
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                    fontWeight: "600",
                    borderRight: "none",
                  }}
                >
                  {`Rate(${auth.getUserInfo().currencySymbol})`}
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "110px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    fontSize: "14px",
                    borderLeft: "1px solid black",
                    paddingLeft: "3px",
                  }}
                >
                  <div style={{ fontWeight: "100", textAlign:"end" }}>
                    {parseInt(rowData.exShowroomPrice).toLocaleString("en-IN")}
                  </div>
                  <div>{}</div>
                  <div>{}</div>
                  <div>{}</div>
                  <div style={{ marginTop: "27%", fontWeight: "100" }}>
                    {rowData.cgst}
                  </div>
                  <div style={{ fontWeight: "100" }}>{rowData.sgst}</div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                    borderLeft: "none",
                  }}
                ></div>
              </div>

              <div
                style={{
                  width: "80%",
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  {`Amount(${auth.getUserInfo().currencySymbol})`}
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "110px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    fontSize: "14px",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    paddingLeft: "3px",
                  }}
                >
                  <span style={{ fontWeight: "100" }}>
                  {parseInt(rowData.exShowroomPrice).toLocaleString("en-IN")}
                  </span>
                  <span>{}</span>
                  <span>{}</span>
                  <span>{}</span>
                  <span style={{ marginTop: "27%", fontWeight: "100" }}>
                    {((parseInt(rowData.exShowroomPrice) *
                      parseInt(rowData.cgst)) /
                      100).toLocaleString("en-IN")}
                  </span>
                  <span style={{ fontWeight: "100" }}>
                    {((parseInt(rowData.exShowroomPrice) *
                      parseInt(rowData.sgst)) /
                      100).toLocaleString("en-IN")}
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                    fontWeight: "100",
                  }}
                >
                  {parseInt(
                    parseInt(rowData.exShowroomPrice) +
                      (parseInt(rowData.exShowroomPrice) *
                        parseInt(rowData.cgst)) /
                        100 +
                      (parseInt(rowData.exShowroomPrice) *
                        parseInt(rowData.sgst)) /
                        100
                  ).toLocaleString("en-IN")}
                </div>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                margin: "30px 0px 10px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <span>
                  Amount Chargeable (in words) : {numberToWords(totalSum)}
                </span>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                fontSize: "14px",
                // border: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <span
                  style={{
                    width: "100%",
                    height: "135px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  HSN/SAC
                </span>
                <span
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "start",
                    fontSize: "14px",
                    borderLeft: "1px solid black",
                    fontWeight: "100",
                  }}
                >
                  {rowData.HSNSACcode}
                </span>
                <span
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "start",
                    fontSize: "14px",
                    border: "1px solid black",
                  }}
                ></span>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <span
                  style={{
                    width: "100%",
                    height: "135px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                    borderBottom: "none",
                    borderLeft: "none",
                    fontWeight: "600",
                  }}
                >
                  Taxable Value
                </span>
                <span
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "start",
                    fontSize: "14px",
                    border: "1px solid black",
                    borderRight: "none",
                    paddingLeft: "3px",
                    fontWeight: "100",
                  }}
                >
                  {parseInt(rowData.exShowroomPrice).toLocaleString("en-IN")}
                </span>
                <span
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "start",
                    fontSize: "14px",
                    borderBottom: "1px solid black",
                    paddingLeft: "3px",
                    fontWeight: "600",
                  }}
                >
                  {parseInt(rowData.exShowroomPrice).toLocaleString("en-IN")}
                </span>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <span
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                    fontSize: "14px",
                    borderTop: "1px solid black",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "600",
                      display: "flex",
                    }}
                  >
                    {" "}
                    Central GST
                  </span>
                  <span style={{ display: "flex", width: "100%" }}>
                    <span
                      style={{ borderRight: "1px solid black", width: "50%" }}
                    >
                      <span>Rate</span>
                    </span>
                    <span
                      style={{ width: "50%", borderRight: "1px solid black" }}
                    >
                      Amount
                    </span>
                  </span>
                </span>
                <span
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "start",
                    fontSize: "14px",
                    border: "1px solid black",
                    borderBottom: "none",
                  }}
                >
                  <span
                    style={{
                      borderRight: "1px solid black",
                      width: "50%",
                      height: "100%",
                      fontWeight: "100",
                    }}
                  >
                    {rowData.cgst}
                    {"%"}
                  </span>
                  <span style={{ width: "50%", fontWeight: "100" }}>
                    {((parseInt(rowData.exShowroomPrice) *
                      parseInt(rowData.cgst)) /
                      100).toLocaleString("en-IN")}
                  </span>
                </span>

                <span
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "start",
                    fontSize: "14px",
                    border: "1px solid black",
                  }}
                >
                  <span
                    style={{
                      borderRight: "1px solid black",
                      width: "50%",
                      height: "100%",
                    }}
                  ></span>
                  <span style={{ width: "50%", fontWeight: "600" }}>
                    {((parseInt(rowData.exShowroomPrice) *
                      parseInt(rowData.cgst)) /
                      100).toLocaleString("en-IN")}
                  </span>
                </span>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <span
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                    fontSize: "14px",
                    borderTop: "1px solid black",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    State GST
                  </span>
                  <span style={{ display: "flex", width: "100%" }}>
                    <span
                      style={{ borderRight: "1px solid black", width: "50%" }}
                    >
                      <span>Rate</span>
                    </span>
                    <span style={{ width: "50%" }}>Amount</span>
                  </span>
                </span>
                <span
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "start",
                    fontSize: "14px",
                    borderTop: "1px solid black",
                    borderRight: "1px solid black",
                  }}
                >
                  <span
                    style={{
                      borderRight: "1px solid black",
                      width: "50%",
                      height: "100%",
                      fontWeight: "100",
                    }}
                  >
                    {rowData.sgst}
                    {"%"}
                  </span>
                  <span style={{ width: "50%", fontWeight: "100" }}>
                    {((parseInt(rowData.exShowroomPrice) *
                      parseInt(rowData.sgst)) /
                      100).toLocaleString("en-IN")}
                  </span>
                </span>

                <span
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "start",
                    fontSize: "14px",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <span
                    style={{
                      borderRight: "1px solid black",
                      width: "50%",
                      height: "100%",
                    }}
                  ></span>
                  <span style={{ width: "50%", fontWeight: "600" }}>
                    {((parseInt(rowData.exShowroomPrice) *
                      parseInt(rowData.sgst)) /
                      100).toLocaleString("en-IN")}
                  </span>
                </span>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <span
                  style={{
                    width: "100%",
                    height: "135px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    fontSize: "14px",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Total Tax Amount
                </span>
                <span
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "start",
                    fontSize: "14px",
                    borderRight: "1px solid black",
                    paddingLeft: "3px",
                    fontWeight: "100",
                  }}
                >
                  {parseInt(
                    (parseInt(rowData.exShowroomPrice) *
                      parseInt(rowData.cgst)) /
                      100 +
                      (parseInt(rowData.exShowroomPrice) *
                        parseInt(rowData.sgst)) /
                        100
                  ).toLocaleString("en-IN")}
                </span>
                <span
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "start",
                    fontSize: "14px",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  {parseInt(
                    (parseInt(rowData.exShowroomPrice) *
                      parseInt(rowData.cgst)) /
                      100 +
                      (parseInt(rowData.exShowroomPrice) *
                        parseInt(rowData.sgst)) /
                        100
                  ).toLocaleString("en-IN")}
                </span>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontSize: "14px",
                margin: "10px 0px 10px 0px",
                // border: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <span
                  style={{
                    width: "100%",
                    height: "150px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    fontSize: "14px",
                    border: "1px solid black",
                    borderBottom: "none",
                  }}
                >
                  <span
                    style={{
                      paddingLeft: "3px",
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                  >
                    Terms and Conditions
                  </span>
                  <span
                    style={{
                      paddingLeft: "3px",
                      fontWeight: "100",
                      display: "flex",
                      alignItems: "start",
                    }}
                  >
                    <TermsView data={"Vehicle Sales Terms"} />
                  </span>
                </span>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <span
                  style={{
                    width: "100%",
                    height: "70px",
                    display: "flex",
                    alignItems: "start",
                    fontSize: "14px",
                    border: "1px solid black",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      paddingLeft: "3px",
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                  >
                    Declaration
                  </span>
                  <span style={{ paddingLeft: "3px", fontWeight: "100" }}>
                    This is to declare that this invoice shows the actual price
                    of the goods described and that all particulars are true and
                    verified.
                  </span>
                </span>
                <span
                  style={{
                    width: "100%",
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    fontSize: "14px",
                    border: "1px solid black",
                    borderLeft: "none",
                    paddingLeft: "3px",
                  }}
                >
                  <span>For : {auth.getUserInfo()?.businessName}</span>
                  <span style={{ marginTop: "30px" }}>
                    Authorized Signatory
                  </span>
                </span>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                margin: "50px 0px 10px 0px",
              }}
            >
              <div></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                }}
              >
                E. & O. E.
              </div>
            </section>
          </div>
        </div>

        <div
          className="footer"
          style={{ width: "100%", justifyContent: "end" }}
        >
          <button
            style={{
              width: "104px",
              height: "35px",
              background: "#fff",
              cursor: "pointer",
            }}
            onClick={() => closeModal(false)}
          >
            <Close style={{ fontSize: "25px" }} />
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaxInvoiceView;
