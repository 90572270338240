import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import {
 errorNotification,
 successNotification,
} from "../../../../utils/notifications";
import ManageServiceManuals from "./ManageServiceManuals";

const ServiceManual = () => {
  const [manualContent, setManualContent] = useState([]);

  console.log("manualContent-->", manualContent);

  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createEmptyValue()
  );

  const [editorData, setEditorData] = useState({
    serviceManualType: "",
    serviceManualTerms: "",
  });

  console.log("editorData-->", editorData);

  const onEditorStateChange = (value) => {
    console.log("newValue-->", value.toString("html"));
    setEditorValue(value);
    var data = value.toString("html");
    setEditorData((prevState) => ({
      ...prevState,
      serviceManualTerms: data,
    }));
  };

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(
        `/operations-module/api/v1/warrantyAndService/serviceMaster/serviceManual/manageServiceManual`
      )
    );
    setManualContent(res);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(
        `/operations-module/api/v1/warrantyAndService/serviceMaster/serviceManual/addServiceManual`,
        {
          ...editorData,
          serviceManualTerms: editorData.serviceManualTerms,
        }
      )
    );
    if (resp.success) {
      successNotification(resp.message, { autoClose: 10000 });
      fetchData();
      setEditorData((prevState) => ({
        ...prevState,
        serviceManualTerms: "",
        serviceManualType: "",
      }));
    } else {
      return errorNotification(resp.message, { autoClose: 10000 });
    }
  };

  return (
    <>
      <div className="bussiness">
        {/* <h4 className="heading">Add Service Manuals</h4> */}
        {/* <span>Add Templates</span> */}
      </div>

      <div>
        <div
          style={{
            width: "23%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              width: "auto",
              marginLeft: "6px",
            }}
          >
            Select Type<span style={{ color: "red" }}>*</span>
          </label>
          <select
            required={true}
            name="serviceManualType"
            className="input-config"
            value={editorData.serviceManualType}
            style={{ width: "100%", outline: "none", marginTop: "-8px" }}
            onChange={(e) =>
              setEditorData({ ...editorData, [e.target.name]: e.target.value })
            }
          >
            <option value="" disabled>
              Select Type
            </option>
            <option value="Vehicle Warranty Terms">Vehicle Warranty Terms</option>
            <option value="Spare Warranty Terms">Spare Warranty Terms</option>
            <option value="Vehicle Service Terms">Vehicle Service Terms</option>
            <option value="PROC. PO Terms">PROC. PO Terms</option>
            <option value="Vehicle PO Terms">Vehicle PO Terms</option>
            <option value="Spares PO Terms">Spares PO Terms</option>
            <option value="Vehicle Invoice Terms">Vehicle Invoice Terms</option>
            <option value="Spares Invoice Terms">Spares Invoice Terms</option>
            <option value="Vehicle Booking Terms">Vehicle Booking Terms</option>
            <option value="Vehicle Sales Terms">Vehicle Sales Terms</option>
            <option value="Delivery Challan Terms">Delivery Challan Terms</option>
            <option value="Service Invoice Terms">Service Invoice Terms</option>
            <option value="Vehicle Warranty Details">Vehicle Warranty Details</option>
            <option value="Credit Note Terms">Credit Note Terms</option>
          </select>
        </div>
      </div>

      {editorData.serviceManualType && (
        <div>
          <div
            style={{
              border: "1px solid grey",
              marginTop: "10px",
              marginBottom: "50px",
              maxHeight: "250px",
              overflowY: "scroll",
            }}
          >
            <RichTextEditor
              value={editorValue}
              onChange={onEditorStateChange}
              style={{ maxHeight: "250px", overflowY: "scroll" }}
            />
          </div>

          <div className="save-cancel">
            <div className="cust-reset cancel-button">
              <button
                type="button"
                className="btn btn-icon-label"
                onClick={(e) =>
                  setEditorData(editorData?.serviceManualType == "0")
                }
              >
                <div
                  style={{
                    fontSize: "12px",
                    color: "#0A18A3",
                    fontWeight: "500",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} /> Cancel
                </div>
              </button>
            </div>
            <div className=" cancel-button">
              <button className="btn btn-icon-label" type="submit">
                <div
                  onClick={() => submitHandler()}
                  style={{
                    fontSize: "12px",
                    color: "#0A18A3",
                    fontWeight: "500",
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} /> Submit
                </div>
              </button>
            </div>
          </div>
        </div>
      )}

      <div style={{ marginTop: "155px" }}>
        <div className="bussiness">
          <h4 className="heading">Manage Templates</h4>
        </div>

        <ManageServiceManuals tableData={manualContent} fetchData={fetchData} />
      </div>
    </>
  );
};

export default ServiceManual;
