import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { errorNotification, successNotification } from "../../utils/notifications";
import '../work-order/workorder.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import auth from "../../utils/auth";

const VerifyModal = ({ closeModal, closeBussModal, location, verifyDetails, updatedData }) => {
    console.log(verifyDetails);

    const refresh = () => {
        updatedData();
    };

    const businessLocationHandler = async () => {
        const resp = await trackPromise(API.post(`admin-module/api/v1/users/addLocation`, verifyDetails));
        if (resp.success) {
            successNotification(resp.message, { autoClose: 3000 });
            refresh();
            const respWithDetails = await trackPromise(API.get(`admin-module/api/v1/login/user`));
            auth.setUserInfo(respWithDetails.data);
        } else {
            errorNotification(resp.message, { autoClose: 3000 });
        }
        closeModal(false);
        closeBussModal(false);
    };


    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "577px", height: "428px", padding: "15px", position: "relative" }}
            >
                <div className="header">
                    <h3>Verify Location Details</h3>
                </div>
                <small style={{ color: "#0A18A3" }}>Please verify the added Details</small>
                <div className="body">
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "16px",
                            fontWeight: "500",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Address : {verifyDetails.address1}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Address (optional) : {verifyDetails.address2}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Country : {verifyDetails.country}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>State/Province : {verifyDetails.state}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>City : {verifyDetails.city}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Contact No. : {verifyDetails.businessContactNo}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Mobile No. : {verifyDetails.mobileNumber}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>TimeZone : {verifyDetails.timezone}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Currency : {verifyDetails.currency}</span>
                        </div>

                    </div>
                </div>

                <br />
                <div className="footer">
                    <button onClick={() => closeModal(false)}><FontAwesomeIcon icon={faTimesCircle} /> Back</button>
                    <button id="submit-button" onClick={businessLocationHandler}><FontAwesomeIcon icon={faCheckCircle} /> Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default VerifyModal;