import React, { useState } from "react";
import {
    KeyboardBackspace,
    CheckCircle,
    HighlightOffOutlined,
} from "@material-ui/icons";
import moment from "moment";
import ViewSpecs from "../../bom/bom-viewspecs";
import AppConstants from "../../../utils/app-constants";
import ReceivedQuantity from "./receivedQty";
import ReceivedQtyWithStatus from "./receivedQtyWithStatus";
import GRNdetails from "./grnDetails";
import GINdetails from "./ginDetails"; 

const NG_CompViewDetails = ({ closeModal, rowdata }) => {
    const [openQty, setOpenQty] = useState();
    const [viewSpecs, setViewSpecs] = useState();
    const [showGRN, setShowGRN] = useState();
    const [showGIN, setShowGIN] = useState();

    console.log(rowdata);

    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "1040px", height: "550px", padding: "15px" }}
            >
                <div
                    className="header"
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <h3>View Details</h3>
                    <div>
                        <button className="action-btn" style={{ color: "blue" }}>
                            Print
                        </button>
                        <button className="action-btn" style={{ color: "blue" }}>
                            Export
                        </button>
                    </div>
                </div>

                <section
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "14px",
                        fontWeight: "500",
                        margin: "10px 0",
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>PO No: {rowdata.POID}</span>
                        {rowdata.GINno ? (
                            <span>
                                GIN No:
                                <span
                                    style={{
                                        color: "#0a18a3",
                                        fontWeight: "500",
                                        cursor: "pointer",
                                    }}
                                    onClick={(e) => setShowGIN(true)}
                                >
                                    {" "}
                                    {rowdata.GIN}
                                </span>
                            </span>
                        ) : (
                            <span>
                                GRN No:
                                <span
                                    style={{
                                        color: "#0a18a3",
                                        fontWeight: "500",
                                        cursor: "pointer",
                                    }}
                                    onClick={(e) => setShowGRN(true)}
                                >
                                    {" "}
                                    {rowdata.GRNID}
                                </span>
                            </span>
                        )}
                        {showGIN && <GINdetails rowdata={rowdata} close={setShowGIN} />}
                        {showGRN && <GRNdetails rowdata={rowdata} close={setShowGRN} />}
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>
                            PO Date:{" "}
                            {moment(rowdata.PODate).format("DD-MMM-YYYY,HH:mm")}
                        </span>
                        {rowdata.GINDate ? (
                            <span>
                                GIN Date :{" "}
                                {moment(rowdata.GINCreatedDate).format(
                                    "DD-MMM-YYYY,HH:mm"
                                )}
                            </span>
                        ) : (
                            <span>
                                GRN Date :{" "}
                                {moment(rowdata.GRNCreatedDate).format(
                                    "DD-MMM-YYYY,HH:mm"
                                )}
                            </span>
                        )}
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>PO By: {rowdata.poBy}</span>
                    </div>

                    {rowdata.status == AppConstants.ONHOLD ? (
                        <div className="onhold_div">
                            <span className="holdDate">Hold Date: {moment(rowdata.holdDate).format(
                                    "DD-MMM-YYYY,HH:mm"
                                )} </span>
                            <span
                                style={{ fontSize: "25px", color: "#CDCDCD", marginTop: "9px" }}
                            >
                                |
                            </span>
                            <span className="holdReason" style={{ right: "150px" }}>
                                Hold Reason: {rowdata.holdReason}
                            </span>
                        </div>
                    ) : rowdata.status == AppConstants.COMPLETED ? (
                        <div className="onhold_div" style={{ backgroundColor: "#C0FCC6" }}>
                            <span className="holdDate" style={{ color: "#11781B" }}>
                                Completion Date: {moment(rowdata.completeDate).format(
                                    "DD-MMM-YYYY,HH:mm"
                                )}
                            </span>
                        </div>
                    ) : rowdata.status == AppConstants.REJECTED ? (
                        <div className="onhold_div" style={{ backgroundColor: "#FFE1E1" }}>
                            <span className="holdDate" style={{ color: "#E51111" }}>
                                Rejection Date: {moment(rowdata.rejectDate).format(
                                    "DD-MMM-YYYY,HH:mm"
                                )}
                            </span>
                            <span
                                style={{ fontSize: "25px", color: "#CDCDCD", marginTop: "9px" }}
                            >
                                |
                            </span>
                            <span
                                className="holdReason"
                                style={{ right: "150px", color: "red" }}
                            >
                                Rejection Reason: {rowdata.rejectReason}
                            </span>
                        </div>
                    ) : null}
                </section>

                <section>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "12px",
                        }}
                    >
                        <span>Vendor Name: {rowdata.vendorName}</span>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "12px",
                        }}
                    >
                        <span>Vendor Location: {rowdata.vendorLocation}</span>
                    </div>
                </section>

                <div
                    className="body"
                    style={{
                        display: "flex",
                        height: "300px",
                        fontSize: "14px",
                        overflow: "scroll",
                    }}
                >
                    <section style={{ width: "100%" }}>
                        <div id="POVendorHeading">
                            <div style={{ width: "6%" }}>S.No.</div>
                            <div style={{ width: "15%" }}>Component Name</div>
                            <div style={{ width: "10%" }}>SKU Code</div>
                            <div style={{ width: "15%" }}>Category</div>
                            <div style={{ width: "10%" }}>Sub Category</div>
                            <div style={{ width: "10%" }}>Specification</div>
                            <div style={{ width: "12%" }}>Vendor Part Name</div>
                            <div style={{ width: "12%" }}>Vendor Part Code</div>
                            <div style={{ width: "10%" }}>Received Qty.</div>
                        </div>

                        {rowdata.components.map((item, index) => {
                            return (
                                <div>
                                    <div
                                        id="POVendorContent"
                                        style={{
                                            borderBottom: "1px solid #999999",
                                            boxShadow: "none",
                                            padding: "0px",
                                        }}
                                    >
                                        <div style={{ width: "6%", overflowY: "auto" }}>
                                            {++index}
                                        </div>
                                        <div style={{ width: "15%", overflowY: "auto" }}>
                                            {item.componentName}
                                        </div>
                                        <div style={{ width: "10%", overflowY: "auto" }}>
                                            {item.skuCode}
                                        </div>
                                        <div style={{ width: "15%", overflowY: "auto" }}>
                                            {item.category}
                                        </div>
                                        <div style={{ width: "10%", overflowY: "auto" }}>
                                            {item.subCategory}
                                        </div>
                                        <div
                                            onClick={() => setViewSpecs(index)}
                                            style={{
                                                width: "10%",
                                                overflowY: "scroll",
                                                color: "blue",
                                                cursor: "pointer",
                                            }}
                                        >
                                            View Specs
                                        </div>
                                        <div style={{ width: "12%", overflowY: "auto" }}>
                                            {item.vendorPartName}
                                        </div>
                                        <div style={{ width: "12%", overflowY: "auto" }}>
                                            {item.vendorPartCode}
                                        </div>
                                        <div
                                            onClick={() => setOpenQty(index)}
                                            style={{
                                                width: "10%",
                                                overflowY: "scroll",
                                                color: "blue",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {item.recivedQty}
                                        </div>
                                    </div>
                                    {viewSpecs === index && (
                                        <ViewSpecs rowData={item} close={setViewSpecs} />
                                    )}
                                    {openQty == index &&
                                        (rowdata.status == AppConstants.NEWREQUEST ||
                                            rowdata.status == AppConstants.ONHOLD) ? (
                                        <ReceivedQuantity rowdata={item} close={setOpenQty} />
                                    ) : openQty == index &&
                                        (rowdata.status == AppConstants.IN_PROGRESS ||
                                            rowdata.status == AppConstants.COMPLETED ||
                                            rowdata.status == AppConstants.REJECTED) ? (
                                        <ReceivedQtyWithStatus rowdata={item} close={setOpenQty} />
                                    ) : null}
                                </div>
                            );
                        })}
                    </section>
                </div>

                <div className="footer">
                    <div>
                        <button
                            style={{ width: "104px", height: "42px", background: "#fff" }}
                            onClick={() => closeModal(false)}
                        >
                            <KeyboardBackspace style={{ fontSize: "25px" }} />
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NG_CompViewDetails;
