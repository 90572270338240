import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import Vector from "../QcDashboard/assets/Vector.svg";
import Vector3 from "../QcDashboard/assets/Vector3.svg";
import Group7 from "../QcDashboard/assets/Group7.svg";
import Group8 from "../QcDashboard/assets/Group8.svg";
import Group9 from "../QcDashboard/assets/Group9.svg";
import Group10 from "../QcDashboard/assets/Group10.svg";
import Group11 from "../QcDashboard/assets/Group11.svg";
import Group12 from "../QcDashboard/assets/Group12.svg";
import Group13 from "../QcDashboard/assets/Group13.svg";
import "./shopFloorDash.scss";
import { Chart as ChartJS, registerables } from 'chart.js'
ChartJS.register(...registerables)
ChartJS.defaults.plugins.legend.display = false;
import ProgressBar from "react-bootstrap/esm/ProgressBar";
import moment from "moment";

const WorkOrderDashboard = ({ startDate, endDate, woData, soStartDate, soEndDate }) => {
  const [data, setData] = useState({});
  const [managementData, setManagementData] = useState({});
  // ShopFloor API Data
  const fetchData = async () => {
    const res = await trackPromise(
      API.get(
        soStartDate == null && soEndDate == null
          ? `shop-floor-module/api/v1/dashBoard/workorder`
          : `shop-floor-module/api/v1/dashBoard/workorder?startDate=${moment(
            soStartDate
          ).format("DD-MMM-YYYY")}& endDate=${moment(soEndDate).format(
            "DD-MMM-YYYY"
          )}`
      )
    );
    setData(res);
  };

  //    Management API Data
  const fetchReport = async () => {
    const res = await trackPromise(API.get(startDate == null && endDate == null ? `management/api/v1/workorders` : `management/api/v1/workorders?startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}`));
    setManagementData(res);
  };

  useEffect(() => {
    woData == "management" ?
      fetchReport() : fetchData()
  }, []);

  return (
    <div className="requestBody">
      {woData == "management" ?
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group9}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              Total WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >

              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "450",
                  marginTop: "15px",
                }}
              >
                {managementData.totalWorkorder}&nbsp;
                {/* <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#11781B",
                  }}
                >
                  {managementData.totalWorkorderChange > 0 ? (
                    <>
                      <img src={Vector} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "green" }}
                      >{`+${managementData.totalWorkorderChange}`}</span>
                    </>
                  ) : managementData.totalWorkorderChange === 0 ? (
                    <>
                      <img src={Vector} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "green" }}
                      >{`+${managementData.totalWorkorderChange}`}</span>
                    </>
                  ) : (
                    <>
                      <img src={Vector3} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "red" }}
                      >{`${managementData.totalWorkorderChange}`}</span>
                    </>
                  )}
                </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={managementData.totalWorkorder * 100} />
          </ProgressBar>
        </div> */}
          </div>
        </>
        :
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group9}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              Total WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >

              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "450",
                  marginTop: "15px",
                }}
              >
                {data.totalWorkOrder}&nbsp;
                {/* <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#11781B",
                  }}
                >
                  {data.totalWorkOrderChange > 0 ? (
                    <>
                      <img src={Vector} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "green" }}
                      >{`+${data.totalWorkOrderChange}`}</span>
                    </>
                  ) : data.totalWorkOrderChange === 0 ? (
                    <>
                      <img src={Vector} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "green" }}
                      >{`+${data.totalWorkOrderChange}`}</span>
                    </>
                  ) : (
                    <>
                      <img src={Vector3} alt="vector" />
                      <span
                        style={{ fontSize: "14px", color: "red" }}
                      >{`${data.totalWorkOrderChange}`}</span>
                    </>
                  )}
                </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={data.totalWorkOrder * 100} />
          </ProgressBar>
        </div> */}
          </div>
        </>
      }


      {woData == "management" ?
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group7}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              Assign WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}
              >
                {managementData.assignedWorkOrder}&nbsp;
                {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {managementData.assignedWorkOrderChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${managementData.assignedWorkOrderChange}`}</span>
                </>
              ) : managementData.assignedWorkOrderChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${managementData.assignedWorkOrderChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${managementData.assignedWorkOrderChange}`}</span>
                </>
              )}
            </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={managementData.assignedWorkOrder * 100} />
            <ProgressBar
              variant="info"
              now={managementData.assignedWorkOrderChange * 100}
            />
          </ProgressBar>
        </div> */}
          </div>
        </>
        :
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group7}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              Assign WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}
              >
                {data.assignWorkOrder}&nbsp;
                {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {data.assignWorkOrderChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.assignWorkOrderChange}`}</span>
                </>
              ) : data.assignWorkOrderChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.assignWorkOrderChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${data.assignWorkOrderChange}`}</span>
                </>
              )}
            </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={data.assignWorkOrder * 100} />
            <ProgressBar
              variant="info"
              now={data.assignWorkOrderChange * 100}
            />
          </ProgressBar>
        </div> */}
          </div>
        </>
      }


      {woData == "management" ?
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group8}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              Accepted WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}
              >
                {managementData.acceptedWorkOrder}&nbsp;
                {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {managementData.acceptedWorkOrderChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${managementData.acceptedWorkOrderChange}`}</span>
                </>
              ) : managementData.acceptedWorkOrderChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${managementData.acceptedWorkOrderChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${managementData.acceptedWorkOrderChange}`}</span>
                </>
              )}
            </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={managementData.acceptedWorkOrder * 100} />
            <ProgressBar
              variant="warning"
              now={managementData.acceptedWorkOrderChange * 100}
            />
          </ProgressBar>
        </div> */}
          </div>
        </>
        :
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group8}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              Accepted WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}
              >
                {data.acceptedWorkOrder}&nbsp;
                {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {data.acceptedWorkOrderChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.acceptedWorkOrderChange}`}</span>
                </>
              ) : data.acceptedWorkOrderChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.acceptedWorkOrderChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${data.acceptedWorkOrderChange}`}</span>
                </>
              )}
            </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={data.acceptedWorkOrder * 100} />
            <ProgressBar
              variant="warning"
              now={data.acceptedWorkOrderChange * 100}
            />
          </ProgressBar>
        </div> */}
          </div>
        </>
      }


      {woData == "management" ?
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group10}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              Halted WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}
              >
                {managementData.haltedWorkOrder}&nbsp;
                {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {managementData.haltedWorkOrderChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${managementData.haltedWorkOrderChange}`}</span>
                </>
              ) : managementData.haltedWorkOrderChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${managementData.haltedWorkOrderChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${managementData.haltedWorkOrderChange}`}</span>
                </>
              )}
            </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={managementData.haltedWorkOrder * 100} />
            <ProgressBar
              variant="info"
              now={managementData.haltedWorkOrderChange * 100}
            />
          </ProgressBar>
        </div> */}
          </div>
        </>
        :
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group10}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              Halted WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}
              >
                {data.haltedWorkOrder}&nbsp;
                {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {data.haltedWorkOrderChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.haltedWorkOrderChange}`}</span>
                </>
              ) : data.haltedWorkOrderChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.haltedWorkOrderChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${data.haltedWorkOrderChange}`}</span>
                </>
              )}
            </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={data.haltedWorkOrder * 100} />
            <ProgressBar
              variant="info"
              now={data.haltedWorkOrderChange * 100}
            />
          </ProgressBar>
        </div> */}
          </div>
        </>
      }


      {woData == "management" ?
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group11}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              In-Progress WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}
              >
                {managementData.inprogressWorkOrder}&nbsp;
                {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {managementData.inprogressWorkOrderChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${managementData.inprogressWorkOrderChange}`}</span>
                </>
              ) : managementData.inprogressWorkOrderChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${managementData.inprogressWorkOrderChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${managementData.inprogressWorkOrderChange}`}</span>
                </>
              )}
            </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={managementData.inprogressWorkOrder * 100} />
            <ProgressBar
              variant="danger"
              now={managementData.inprogressWorkOrderChange * 100}
            />
          </ProgressBar>
        </div> */}
          </div>
        </>
        :
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group11}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              In-Progress WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}
              >
                {data.inprogressWorkOrder}&nbsp;
                {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {data.inprogressWorkOrderChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.inprogressWorkOrderChange}`}</span>
                </>
              ) : data.inprogressWorkOrderChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.inprogressWorkOrderChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${data.inprogressWorkOrderChange}`}</span>
                </>
              )}
            </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={data.inprogressWorkOrder * 100} />
            <ProgressBar
              variant="danger"
              now={data.inprogressWorkOrderChange * 100}
            />
          </ProgressBar>
        </div> */}
          </div>
        </>
      }


      {woData == "management" ?
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group12}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              Completed WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}
              >
                {managementData.completedWorkOrder}&nbsp;
                {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {managementData.completedWorkOrderChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${managementData.completedWorkOrderChange}`}</span>
                </>
              ) : managementData.completedWorkOrderChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${managementData.completedWorkOrderChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${managementData.completedWorkOrderChange}`}</span>
                </>
              )}
            </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={managementData.completedWorkOrder * 100} />
            <ProgressBar
              variant="success"
              now={managementData.completedWorkOrderChange * 100}
            />
          </ProgressBar>
        </div> */}
          </div>
        </>
        :
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group12}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              Completed WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}
              >
                {data.completedWorkOrder}&nbsp;
                {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {data.completedWorkOrderChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.completedWorkOrderChange}`}</span>
                </>
              ) : data.completedWorkOrderChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.completedWorkOrderChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${data.completedWorkOrderChange}`}</span>
                </>
              )}
            </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={data.completedWorkOrder * 100} />
            <ProgressBar
              variant="success"
              now={data.completedWorkOrderChange * 100}
            />
          </ProgressBar>
        </div> */}
          </div>
        </>
      }


      {woData == "management" ?
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group13}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              Rejected WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}
              >
                {managementData.rejectedWorkOrder}&nbsp;
                {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {managementData.rejectedWorkOrderChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${managementData.rejectedWorkOrderChange}`}</span>
                </>
              ) : managementData.rejectedWorkOrderChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${managementData.rejectedWorkOrderChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${managementData.rejectedWorkOrderChange}`}</span>
                </>
              )}
            </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={managementData.rejectedWorkOrder * 100} />
            <ProgressBar
              variant="danger"
              now={managementData.rejectedWorkOrderChange * 100}
            />
          </ProgressBar>
        </div> */}
          </div>
        </>
        :
        <>
          <div className="shopFloorRequest" style={{ height: "150px" }}>
            <img
              src={Group13}
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              alt="group"
            />
            <div
              style={{
                color: "#808080",
                fontSize: "14px",
                textAlign: "center",
                margin: "10px 6px 0px 6px",
                borderBottom: "2px solid #F2F2F2",
              }}
            >
              Rejected WO
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px" }}
              >
                {data.rejectedWorkOrder}&nbsp;
                {/* <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#11781B" }}
            >
              {data.rejectedWorkOrderChange > 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.rejectedWorkOrderChange}`}</span>
                </>
              ) : data.rejectedWorkOrderChange === 0 ? (
                <>
                  <img src={Vector} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "green" }}
                  >{`+${data.rejectedWorkOrderChange}`}</span>
                </>
              ) : (
                <>
                  <img src={Vector3} alt="vector" />
                  <span
                    style={{ fontSize: "14px", color: "red" }}
                  >{`${data.rejectedWorkOrderChange}`}</span>
                </>
              )}
            </span> */}
              </div>
            </div>
            {/* <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <ProgressBar>
            <ProgressBar now={data.rejectedWorkOrder * 100} />
            <ProgressBar
              variant="danger"
              now={data.rejectedWorkOrderChange * 100}
            />
          </ProgressBar>
        </div> */}
          </div>
        </>
      }
    </div>
  );
}

export default WorkOrderDashboard;
