import React, { useState } from "react";

import "../../../../components/modal/modal.scss";

import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import { errorNotification, successNotification } from "../../../../utils/notifications";

const Deactivate = ({ updatedData, closeModal, props, rowData }) => {


  console.log("rowData-->",rowData)

  const [formValue, setFormValue] = useState({
    reason: "",
    description: "",
    status: "Deactivated",
    _id:rowData?._id
  });

  console.log("formValue-->",formValue)

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.put(`systemadmin/api/v1/features`, formValue)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Deactivate Charges</h3>
        </div>

        <div className="body">
          <label style={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <label style={{ fontSize: "13px", fontWeight: "500", textAlign: "left" }}>
              Deactivation Feature<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="reason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: "100%", marginTop: "-5px" }}
            >
              <option disabled selected hidden>
                Deactivation Reason
              </option>
              <option value="Plan Discontinued">Plan Discontinued</option>
              <option value="Not an Active User">Not approved by management</option>
              <option value="Plan not required">Plan not required</option>
              <option value="Others">Others</option>
            </select>
          </label>

          {formValue.reason === "Others" && (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Deactivation Reason"
                autoComplete="off"
              />
            </label>
          )}
        </div>

        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>

          {props === "subscription" ? (
            <button
              id="submit-button"
              onClick={submitHandler} // Replace with submitSubsHandler if needed
              disabled={formValue?.reason === "" ? true : false}
            >
              <FontAwesomeIcon icon={faCheckCircle} /> Submit
            </button>
          ) : (
            <button
              id="submit-button"
              onClick={submitHandler}
              disabled={formValue?.reason === "" ? true : false}
            >
              <FontAwesomeIcon icon={faCheckCircle} /> Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Deactivate;