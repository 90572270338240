import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import ProgressBar from "react-bootstrap/ProgressBar";
import API from "../../utils/api";
import moment from "moment";

const ApprovalProgressBar = ({ props, startDate, endDate  }) => {
  const [approvalData, setApprovalData] = useState({});
  const [vehicleData, setVehicleData] = useState({});
  const [spareData, setSpareData] = useState({});

  //BOM Costing
  const fetchReport = async () => {
    const result = await trackPromise(API.get(startDate == null && endDate == null ? `management/api/v1/bomRequest` : `management/api/v1/bomRequest?startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}`));
    setApprovalData(result);
  };
  useEffect(() => {
    fetchReport();
  }, [endDate]);

  //Vehicle Pricing

  const fetchData = async () => {
    const result = await trackPromise(API.get((startDate == null && endDate == null ? `management/api/v1/vehiclePricing` : `management/api/v1/vehiclePricing?startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}`)));
    setVehicleData(result);
  };
  useEffect(() => {
    fetchData();
  }, [endDate]);

  //Spare Pricing

  const fetchRequest = async () => {
    const result = await trackPromise(API.get(startDate == null && endDate == null ? `management/api/v1/sparePricing` : `management/api/v1/sparePricing?startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}` ));
    setSpareData(result);
  };
  useEffect(() => {
    fetchRequest();
  }, [endDate]);


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "95%",
        marginLeft: "10px",
        fontWeight: "430",
      }}
    >
      <ProgressBar>
        <ProgressBar now={(props == "bom"
            ? approvalData.totalBomRequest
            : props == "vehicle"
            ? vehicleData.totalVehicleRequest
            : spareData.totalSpareRequest)*100} />
        <ProgressBar variant="success" now={(props == "bom"
            ? approvalData.pendingRequset
            : props == "vehicle"
            ? vehicleData.pendingRequset
            : spareData.pendingRequset)*100} />
        <ProgressBar variant="warning" now={(props == "bom"
            ? approvalData.holdRequest
            : props == "vehicle"
            ? vehicleData.holdRequest
            : spareData.holdRequest)*100} />
        <ProgressBar variant="danger" now={(props == "bom"
            ? approvalData.approvedRequest
            : props == "vehicle"
            ? vehicleData.approvedRequest
            : spareData.approvedRequest)*100} />
            <ProgressBar variant="danger" now= {(props == "bom"
            ? approvalData.rejectedRequest
            : props == "vehicle"
            ? vehicleData.rejectedRequest
            : spareData.rejectedRequest)*100}/>
      </ProgressBar>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#1e90ff",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            {props == "bom"
              ? `Total BOM Requests`
              : props == "vehicle"
              ? `Total Vehicle Requests`
              : `Total Spare Requests`}
          </span>
        </div>
        <div>
          {props == "bom"
            ? approvalData.totalBomRequest
            : props == "vehicle"
            ? vehicleData.totalVehicleRequest
            : spareData.totalSpareRequest}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#28a745",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            {props == "bom"
              ? `Pending Requests`
              : props == "vehicle"
              ? `Pending Vehicle Requests`
              : `Pending Spare Requests`}
          </span>
        </div>
        <div>
          {props == "bom"
            ? approvalData.pendingRequset
            : props == "vehicle"
            ? vehicleData.pendingRequset
            : spareData.pendingRequset}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#ffc107",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            {props == "bom"
              ? `On Hold Requests`
              : props == "vehicle"
              ? `On Hold Vehicle Requests`
              : `On Hold Spare Requests`}
          </span>
        </div>
        <div>
          {props == "bom"
            ? approvalData.holdRequest
            : props == "vehicle"
            ? vehicleData.holdRequest
            : spareData.holdRequest}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#28a745",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            {props == "bom"
              ? `Approved Requests`
              : props == "vehicle"
              ? `Approved Vehicle Requests`
              : `Approved Spare Requests`}
          </span>
        </div>
        <div>
          {props == "bom"
            ? approvalData.approvedRequest
            : props == "vehicle"
            ? vehicleData.approvedRequest
            : spareData.approvedRequest}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#dc3545",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            {props == "bom"
              ? `Rejected Requests`
              : props == "vehicle"
              ? `Rejected Vehicle Requests`
              : `Rejected Spare Requests`}
          </span>
        </div>
        <div>
          {props == "bom"
            ? approvalData.rejectedRequest
            : props == "vehicle"
            ? vehicleData.rejectedRequest
            : spareData.rejectedRequest}
        </div>
      </div>
    </div>
  );
}

export default ApprovalProgressBar;
