import React, { useState, useEffect, useRef } from "react";
import "../QcDashboard/styles/main.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faTable,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { FaRedoAlt } from "react-icons/fa";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { DatePicker, Space } from "antd";
import { BsArrowUpRight } from "react-icons/bs";
import Business from "./Business";
import Subscription from "./Subscription";
import InvoiceNDPayment from "./InvoiceNDPayment"

const SysAdminDash = () => {
  const [startDateBusiness, setstartDateBusiness] = useState(null);
  const [endDateBusiness, setendDateBusiness] = useState(null);
  const { RangePicker } = DatePicker;
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [businessData, setbusinessData] = useState({});
  const [openBusinessEntity, setOpenBusinessEntity] = useState(false);
  const [subscriptionStartDate, setsubscriptionStartDate] = useState(null);
  const [subscriptionEndDate, setsubscriptionEndDate] = useState(null);
  const [invoiceStartDate, setinvoiceStartDate] = useState(null);
  const [invoiceEndDate, setinvoiceEndDate] = useState(null);

  const fetchData = async () => {
    const resp = await trackPromise(API.get(`---API---`));
    setbusinessData(resp);
  };
  useEffect(() => {
    fetchData();
  }, []);

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  // Business
  const onChangeBusiness = (dates) => {
    const [start, end] = dates;
    setstartDateBusiness(start);
    setendDateBusiness(end);
  };

  const refreshBusiness = () => {
    setstartDateBusiness(null);
    setendDateBusiness(null);
    document.getElementById("businessCalender").style.display = "none";
    document.getElementById("businessCalenderIcon").style.display = "block";
    document.getElementById("businessCalenderIcon").style.justifyContent =
      "center";
    document.getElementById("businessCalenderIcon").style.textAlign = "center";
  };

  const showBusiness = () => {
    document.getElementById("businessCalender").style.display = "block";
    document.getElementById("businessCalenderIcon").style.display = "none";
    setCalenderOpen(true);
    on();
  };

  // Subscription
  const blank = () => {
    setsubscriptionStartDate(null);
    setsubscriptionEndDate(null);
    document.getElementById("subscriptionCalender").style.display = "none";
    document.getElementById("subscriptionCalendericon").style.display = "block";
    document.getElementById("subscriptionCalendericon").style.justifyContent = "center";
    document.getElementById("subscriptionCalendericon").style.textAlign = "center";
  };

  const shows = () => {
    document.getElementById("subscriptionCalender").style.display = "block";
    document.getElementById("subscriptionCalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  const subscriptionTypeChange = (dates) => {
    const [start, end] = dates;
    setsubscriptionStartDate(start);
    setsubscriptionEndDate(end);
  };


  // Invoices and Payments

  const invoiceRefresh = () => {
    setinvoiceStartDate(null);
    setinvoiceEndDate(null);
    document.getElementById("invoiceCalendar").style.display = "none";
    document.getElementById("invoiceCalendaricon").style.display = "block";
    document.getElementById("invoiceCalendaricon").style.justifyContent =
      "center";
    document.getElementById("invoiceCalendaricon").style.textAlign = "center";
  };

  const onInvoiceChange = (dates) => {
    const [start, end] = dates;
    setinvoiceStartDate(start);
    setinvoiceEndDate(end);
  };

  const showInvoice = () => {
    document.getElementById("invoiceCalendar").style.display = "block";
    document.getElementById("invoiceCalendaricon").style.display = "none";
    setCalenderOpen(true);
    on();
  };

  function off() {
    setCalenderOpen(false);
    document.getElementById("overlay").style.display = "none";
    refreshBusiness();
    blank();
    invoiceRefresh();
  }

  return (
    <>
      {
        // openBusinessEntity == true ? <ManageBomCosting inDashboard={setOpenBomCostTable} /> :
        <div className="dashboardBody">
          <div id="overlay" onClick={(e) => off(e)}></div>
          <div className="firstRow">
            <div
              className="container-new"
              style={{ height: "auto", width: "100%" }}
            >
              <div
                className="header"
                style={{ marginTop: "10px", marginLeft: "10px", width: "98%" }}
              >
                <div>
                  <h5 style={{ fontSize: "18px" }}>Business</h5>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    onClick={refreshBusiness}
                  >
                    <FaRedoAlt />
                  </div>
                  <span
                    id="businessCalenderIcon"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    onClick={showBusiness}
                  >
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ color: "#808080" }}
                    />
                  </span>
                  <label
                    id="businessCalender"
                    style={{
                      width: "auto",
                      marginRight: "5px",
                      display: "none",
                    }}
                  >
                    <Space
                      style={{ width: "235px" }}
                      direction="vertical"
                      size={10}
                    >
                      <RangePicker
                        startDate={startDateBusiness}
                        endDate={endDateBusiness}
                        value={[startDateBusiness, endDateBusiness]}
                        onChange={onChangeBusiness}
                        format="DD-MM-YYYY"
                        size="small"
                      />
                    </Space>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <BsArrowUpRight
                      onClick={() => setOpenBusinessEntity(true)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <Business businessData={businessData} />
              </div>
            </div>
          </div>
          <div className="secondRow" style={{display:"flex", justifyContent:"space-between"}}>
            <div
              className="container-box"
              style={{ width: "49%", height: "369px", padding: "10px" }}
            >
              <div className="header">
                <div>
                  <h5 style={{ fontSize: "18px" }}>Subscription</h5>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    onClick={blank}
                  >
                    <FaRedoAlt />
                  </div>
                  <span
                    id="subscriptionCalendericon"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    onClick={shows}
                  >
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ color: "#808080" }}
                    />
                  </span>
                  <label
                    id="subscriptionCalender"
                    style={{
                      width: "auto",
                      marginRight: "5px",
                      display: "none",
                    }}
                  >
                    <Space
                      style={{ width: "235px" }}
                      direction="vertical"
                      size={10}
                    >
                      <RangePicker
                        startDate={subscriptionStartDate}
                        endDate={subscriptionEndDate}
                        value={[subscriptionStartDate, subscriptionEndDate]}
                        onChange={subscriptionTypeChange}
                        format="DD-MM-YYYY"
                        size="small"
                      />
                    </Space>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {/* <BsArrowUpRight onClick={() => routeComp()} /> */}
                    <BsArrowUpRight/>
                  </div>
                </div>
              </div>
              <br />
              <div>
                <Subscription subscriptionData={""} />
              </div>
            </div>
            <div
              className="container-box"
              style={{ width: "49%", height: "369px", padding: "10px" }}
            >
              <div className="header">
                <div>
                <h5 style={{ fontSize: "18px" }}>Invoices & Payments</h5>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    onClick={invoiceRefresh}
                  >
                    <FaRedoAlt />
                  </div>
                  <span
                    id="invoiceCalendaricon"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    onClick={showInvoice}
                  >
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ color: "#808080" }}
                    />
                  </span>
                  <label
                    id="invoiceCalendar"
                    style={{
                      width: "auto",
                      marginRight: "5px",
                      display: "none",
                    }}
                  >
                    <Space
                      style={{ width: "235px" }}
                      direction="vertical"
                      size={10}
                    >
                  <RangePicker
                          startDate={invoiceStartDate}
                          endDate={invoiceEndDate}
                          value={[invoiceStartDate,invoiceEndDate]}
                          onChange={onInvoiceChange}
                          format="DD-MM-YYYY"
                          size="small"
                        />
                    </Space>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {/* <BsArrowUpRight onClick={() => routeComp()} /> */}
                    <BsArrowUpRight />
                  </div>
                </div>
              </div>
              <div>
                <br />
              <InvoiceNDPayment
                  InvoiceData={""}
                  />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default SysAdminDash;
