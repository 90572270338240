import React, { useEffect } from "react";
import HomePage from "../Homepage/homepage";
import Sidebar  from "../../components/shared/sidebar";

const HomeDashboard = () => {

    useEffect(() => {
    }, []);

    return (
    <div className="">
        <h2>Dashboard</h2>
    </div>

    );
};

export default HomeDashboard;
