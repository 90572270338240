import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';

export default function LinearProgressCard({ props }) {

    const [mapCompData, setMapCompData] = useState({});
    const [mapLaborData, setMapLaborData] = useState({});
    const [mapAssemblyData, setMapAssemblyData] = useState({});


    const fetchMapCompData = async () => {
        const compResp = await trackPromise(API.get(`procurement-module/api/v1/dashboard/getMappedUnmappedComponents`))
        const laborResp = await trackPromise(API.get(`procurement-module/api/v1/dashboard/getMappedUnmappedLabors`))
        const assemblyResp = await trackPromise(API.get(`procurement-module/api/v1/dashboard/getMappedUnmappedAssemblies`))
        setMapCompData(compResp);
        setMapLaborData(laborResp);
        setMapAssemblyData(assemblyResp);
    };
    useEffect(() => {
        fetchMapCompData();
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "95%", marginLeft: "10px", fontWeight: "430" }}>

            <ProgressBar>
                <ProgressBar now={props == "component" ? mapCompData.totalComponents * 100 : props == "labour" ? mapLaborData.totalLabors * 100 : mapAssemblyData.totalAssemblies * 100} />
                <ProgressBar variant="success" now={props == "component" ? mapCompData.mappedComponents * 100 : props == "labour" ? mapLaborData.mappedLabors * 100 : mapAssemblyData.mappedAssemblies * 100} />
                <ProgressBar variant="warning" now={props == "component" ? mapCompData.unmappedComponents * 100 : props == "labour" ? mapLaborData.unmappedLabors * 100 : mapAssemblyData.unmappedAssemblies * 100} />
            </ProgressBar>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#1e90ff", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>{props == "component" ? `Total Components` : props == "labour" ? `Total Labours` : `Total Assembly`}</span>
                </div>
                <div>
                    {props == "component" ? mapCompData.totalComponents : props == "labour" ? mapLaborData.totalLabors : mapAssemblyData.totalAssemblies}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#28a745", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>{props == "component" ? `Mapped Components` : props == "labour" ? "Mapped Labours" : "Mapped Assembly"}</span>
                </div>
                <div>
                    {props == "component" ? mapCompData.mappedComponents : props == "labour" ? mapLaborData.mappedLabors : mapAssemblyData.mappedAssemblies}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#ffc107", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>{props == "component" ? `Un-mapped Components` : props == "labour" ? `Un-mapped Labours` : `Un-mapped Assembly`}</span>
                </div>
                <div>
                    {props == "component" ? mapCompData.unmappedComponents : props == "labour" ? mapLaborData.unmappedLabors : mapAssemblyData.unmappedAssemblies}
                </div>
            </div>
        </div>
    )
}
