import React, { useState } from "react";
import auth from "../../../utils/auth";
import ReactToPrint from "react-to-print";
import { Close } from "@material-ui/icons";
import moment from "moment";


const TaxInvoice = ({ closeTaxInvoice, rowData }) => {


  console.log("rowData",rowData)
   
  let componentRef = null;



  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          width: "63%",
          height: "625px",
          padding: "15px",
          borderRadius: "0px",
          borderTop: "none",
        }}
      >
         <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Tax Invoice</h3>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <button
                    style={{
                      color: "blue",
                      margin: "1px",
                      height: "27px",
                      width: "90px",
                      border: "none",
                    }}
                  >
                    Download
                  </button>
                );
              }}
              content={() => componentRef}
              documentTitle="Tax Invoice"
              pageStyle="Print"
            />
          </div>
        </div>


        <div className="body">
          <div 
             style={{
            width: " 100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            pageBreakBefore:"always",
          }}
          ref={(el) => (componentRef = el)}
          >
         

        <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{
                backgroundColor:"#0A18A3",
                color:"white",
                width:"400px",
                height:"10px",
                paddingRight:"12px",
                display:"flex",
                margin:"20px 20px 20px 20px",
                marginTop:"10px",
                flexGrow:"1",
                }}>

                </div>

              <div style={{display:"flex",flexDirection:"column"}}>
              <div>
                <h4>Tax Invoice</h4>
              </div>
              </div>

              <div style={{
                backgroundColor:"#0A18A3",
                color:"white",
                width:"20px",
                height:"10px",
                paddingRight:"12px",
                display:"flex",
                marginLeft:"10px",
                marginTop:"10px",
                }}>

                </div>
            </div>     

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{
                width:"200px",
                fontSize:"15px",
                marginTop:"5px",
              }}>Jacob Honda :</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                fontWeight: "400",
              }}
            >
              <span>Address</span>
              <span>ST No. : {}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                fontWeight: "400",
              }}
            >
              <span>Tel :</span>
              <span>GSTIN No. : {}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                fontWeight: "400",
              }}
            >
              <span>Email :</span>
              <span>CIN No. : {}</span>
            </div>
            
          </section>


          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{
                backgroundColor:"#0A18A3",
                color:"white",
                width:"2100%",
                height:"2px",
                paddingLeft:"12px",
                }}></span>
            </div>



            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>Customer Name : {}</span>
              <span style={{fontSize:"12px",fontWeight:"bold"}}>Invoice No : {}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>Customer ID : </span>
              <span>Veh Reg. No: </span>
              <span>Date : {moment(rowData?.jobDate).format("DD-MMM-YYYY/HH:mm")} </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>Address : </span>
              <span>VIN : </span>
              <span>Job Card : </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                fontWeight: "400",
              }}
            >
              <span>Service Advisor : {}</span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection:"row",
                fontWeight: "400",
              }}
            >
              <span>Phone No : {}</span>
              <span>Email : {}</span>
            </div>

          </section>


          <section style={{ width: "100%",flexGrow: 1, }}>

            <div id="POVendorHeading">
              <div style={{ width: "13%", fontSize: "10px", backgroundColor:"#0A18A3",color:"white" }}>
               S.No
              </div>
              <div style={{ width: "10%", fontSize: "10px", backgroundColor:"#0A18A3",color:"white" }}>SKU Code</div>
              <div style={{ width: "20%", fontSize: "10px", backgroundColor:"#0A18A3",color:"white" }}>Spare Name & Description</div>
              <div style={{ width: "10%", fontSize: "10px", backgroundColor:"#0A18A3",color:"white" }}>Qty.</div>
              <div style={{ width: "13%", fontSize: "10px", backgroundColor:"#0A18A3",color:"white" }}>{`Unit Price(${
                auth.getUserInfo().currencySymbol
              })`}</div>
              <div style={{ width: "13%", fontSize: "10px", backgroundColor:"#0A18A3",color:"white"}}>Req. Qty.</div>
              <div style={{ width: "13%", fontSize: "10px", backgroundColor:"#0A18A3",color:"white"}}>{`Qty. Price(${
                auth.getUserInfo().currencySymbol
              })`}</div>
              <div style={{ width: "13%", fontSize: "10px", backgroundColor:"#0A18A3",color:"white"}}>{`Labour(${
                auth.getUserInfo().currencySymbol
              })`}</div>
             
            </div>
            <div>
              {  (
                <div
                  id="POVendorContent"
                  style={{
                    borderBottom: "1px solid #999999",
                    boxShadow: "none",
                  }}
                //   key={index}
                >
                  <div style={{ width: "13%", fontSize: "10px" }}>
                  {/* {index+1} */}
                  </div>
                  <div style={{ width: "13%", fontSize: "10px" }}>
                  {}
                  </div>
                  <div style={{ width: "13%", fontSize: "10px" }}>
                  {}
                  </div>
                  <div style={{ width: "13%", fontSize: "10px" }}>
                  {}
                  </div>
                  <div style={{ width: "8%", fontSize: "10px" }}>
                  {}
                  </div>
                  <div style={{ width: "9%", fontSize: "10px" }}>
                  {}
                  </div>
                 
                  {/* <div style={{ width: "8%", fontSize: "10px" }}>{`${
                    (item.unitPrice * item.reqComponentQty * item.taxRate) / 100
                  } (${item.taxRate}%)`}</div>
                  <div style={{ width: "10%", fontSize: "10px" }}>
                    {item.unitPrice * item.reqComponentQty +
                      (item.unitPrice * item.reqComponentQty * item.taxRate) /
                        100}
                  </div> */}
                </div>
              )}
            </div>
          </section>

          <section className="POterms" style={{backgroundColor:"#0A18A3",display:"flex",flexDirection:"row", justifyContent:"space-between" }}>
                <span style={{fontSize:'12px',color:"white"}}>Total Payable Amount </span>
                <span style={{fontSize:'12px',color:"white"}}>120500</span>
          </section>

          <section className="POterms" style={{padding:"3px 5px",marginBottom:"20px",backgroundColor:"white",display:"flex",flexDirection:"column"}}>
            <div>
              <div style={{ fontWeight: "500", textAlign: "left",marginTop:"12px"}}>
               For Jacob Honda Services :-
              </div>
            </div>
            <div>
              <div style={{ fontWeight: "500", display:"flex",flexDirection:"row", justifyContent:"space-between",marginTop:"12px"}}>
               <span>Authorised Signature :-</span>
               <span>E. &. O. E.</span>
              </div>
              
            </div>

          
          </section>
          </div>
        </div>


        <div className="footer">
          <button
            style={{ width: "104px", height: "35px", background: "#fff" }}
            onClick={()=>closeTaxInvoice(false)}
          >
            <Close style={{ fontSize: "25px" }} />
            Close
          </button>
        </div>

      </div>
    </div>
  );
};

export default TaxInvoice;

