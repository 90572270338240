import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import moment from "moment";
import "../../../pages/vendors/Vendors.scss";
import AppConstants from "../../../utils/app-constants";
import SearchBox from "../../../components/search/SearchBox";
import ServiceCenterUsersAction from "./actions/ServiceCenterUsersAction";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ServiceCenterManageUsers = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState();
  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    role: "",
    fullName: "",
    email: "",
    address: "",
    status: "",
    createdDate: "",
  });

  console.log(formValue.address);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `serviceCenter-module/api/v1/user/getUser?role=${formValue.role}&&fullName=${formValue.fullName}&&email=${formValue.email}&&address=${formValue.address}&&status=${formValue.status}&&createdDate=${formValue.createdDate}`
      )
    );
    let filteredData = resp.filter((item) => {
      return (
        item.status == "Active" ||
        item.status == "Reactivated" ||
        item.status == "Deactivated"
      );
    });
    console.log(filteredData);
    setUsersData(filteredData);
  };
  useEffect(() => {
    fetchReport();
  }, []);



  const uniquerole = [...new Set(UsersData.map((item)=>item.role))]
  const uniquefullName = [...new Set(UsersData.map((item)=>item.fullName))]
  const uniqueemail = [...new Set(UsersData.map((item)=>item.email))]
  const uniquestatus = [...new Set(UsersData.map((item)=>item.status))]




  const statusFormatter = (cell, row, rowIndex) => {
    return cell === AppConstants.ACTIVE ? (
      <button className="active-btn">
        <span className="active-btn-font">Active</span>
      </button>
    ) : cell === AppConstants.DEACTIVATED ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">Deactivated</span>
      </button>
    ) : cell === AppConstants.REACTIVATED ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">Reactivated</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.status === AppConstants.DELETED ? (
      ""
    ) : (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          <ServiceCenterUsersAction
            updatedData={fetchReport}
            rowData={row}
            setIsOpen={setIsOpen}
            tabName="serviceCenter"
            rowIndex={rowIndex}
          />
        ) : null}
      </div>
    );
  };

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      role: "",
      fullName: "",
      email: "",
      address: "",
      status: "",
      createdDate: "",
    });
    const resp = await trackPromise(
      API.get(`serviceCenter-module/api/v1/user/getUser`)
    );
    let filteredData = resp.filter((item) => {
      return (
        item.status == "Active" ||
        item.status == "Reactivated" ||
        item.status == "Deactivated"
      );
    });
    console.log(filteredData);
    setUsersData(filteredData);
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  } 

  const exportData = UsersData.map((row, index) => ({
    "S.No.": index + 1, // Serial number
    "User Type": row?.role || "-", 
    Name: row?.fullName || "-",
    "Email ID": row?.email || "-",
    "Created Date": row?.createdDate
      ? moment(row.createdDate).format("DD-MMM-YYYY")
      : "-",
    Status: row?.status || "-",
    "Last Updated": row?.actionDate
      ? moment(row.actionDate).format("DD-MMM-YYYY")
      : "-",
  }));
  

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "User Type",
      dataField: "role",
      headerAttrs: { width: "170px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Name",
      dataField: "fullName",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Email ID",
      classes: "cellsOverflow",
      dataField: "email",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Created Date",
      dataField: "createdDate",
      headerAttrs: { width: "180px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "130px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "Last Updated",
      dataField: "actionDate",
      headerAttrs: { width: "180px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "60px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Users</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(exportData, "Service Center Manage User")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div
                    id="abc"
                    className="RA_block"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      height: "125px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="bomfilter_fields"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          User Type
                        </label>
                        <select
                          name="role"
                          value={formValue.role}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            User Type
                          </option>
                          {
                            uniquerole.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Name
                        </label>
                        <select
                          name="fullName"
                          value={formValue.fullName}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Name
                          </option>
                          {
                            uniquefullName.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Email ID
                        </label>
                        <select
                          name="email"
                          value={formValue.email}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Email ID
                          </option>
                          {
                            uniqueemail.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {
                            uniquestatus.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          width: "20%",
                          textAlign: "end",
                          marginTop: "21px",
                        }}
                      >
                        <button
                          type="submit"
                          id="apply"
                          style={{ width: "96px", height: "35px" }}
                          onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ width: "96px", height: "35px" }}
                        >
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ServiceCenterManageUsers;
