import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import AddHsnSac from "./add-hsn-sac";
// import PlaceOrders from "./place-orders";
// import ManageOrders from "./manage-orders";
// import GoodReceivedNote from "./good-received-note";
// import OrderHistory from "./order-history";
// import GoodReturnManagement from "./good-return-management";

const AddHsnSac = React.lazy(()=>import("./add-hsn-sac"))
const PlaceOrders = React.lazy(()=>import("./place-orders"))
const ManageOrders = React.lazy(()=>import("./manage-orders"))
const GoodReceivedNote = React.lazy(()=>import("./good-received-note"))
const OrderHistory = React.lazy(()=>import("./order-history"))
const GoodReturnManagement = React.lazy(()=>import("./good-return-management"))



const tabs = [
  {
    id: "PRM5T1",
    label: TabLabels.ADD_HSN_SAC,
    Component: AddHsnSac
  },
  {
    id: "PRM5T2",
    label: TabLabels.PLACE_ORDERS,
    Component: PlaceOrders
  },
  {
    id: "PRM5T3",
    label: TabLabels.MANAGE_ORDERS,
    Component: ManageOrders
  },
  {
    id: "PRM5T4",
    label: TabLabels.GOOD_RECEIVED_NOTE,
    Component: GoodReceivedNote
  },
  {
    id: "PRM5T5",
    label: TabLabels.ORDER_HISTORY,
    Component: OrderHistory
  },
  {
    id: "PRM5T6",
    label: TabLabels.Good_RETURN_MANAGEMENT,
    Component: GoodReturnManagement
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};


const Orders = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default Orders;
