import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import { CheckCircle, KeyboardBackspace } from "@material-ui/icons";

const OnHold = ({ closeModal, rowData, updatedData, extraData }) => {
  const [formValue, setFormValue] = useState({
    onholdReason: "",
    description: "",
  });

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    if(!formValue.onholdReason){
      errorNotification("Please select a reason");
      return;
    }
    const body = {
      status: "On Hold",
      requisitionID: rowData.requisitionID,
      reason: formValue.onholdReason,
      reasonDescription: formValue.description,
    };
    if (formValue.onholdReason) {
      const resp = await trackPromise(
        API.put(`dealer-module/api/v1/requisitionAndOrder/vehicles`, body)
      );
      {
        resp.success
          ? successNotification(resp.message)
          : errorNotification(resp.message);
      }
      closeModal(false);
      refresh();
    } else {
      errorNotification("Please enter on hold reason", { autoClose: 10000 });
    }
  };

  const spareSubmitHandler = async () => {
    if(!formValue.onholdReason){
      errorNotification("Please select a reason");
      return;
    }
    const body = {
      status: "On Hold",
      requisitionID: rowData.requisitionID,
      reason: formValue.onholdReason,
      reasonDescription: formValue.description,
    };
    if (formValue.onholdReason) {
      const resp = await trackPromise(
        API.put(`dealer-module/api/v1/requisitionAndOrder/spares`, body)
      );
      {
        resp.success
          ? successNotification(resp.message)
          : errorNotification(resp.message);
      }
      closeModal(false);
      refresh();
    } else {
      errorNotification("Please enter on hold reason", { autoClose: 10000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Put on Hold</h3>
        </div>
        <div className="body">
          <label
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label
              style={{ fontSize: "13px", fontWeight: "500", width: "fit-content" }}
            >
             On Hold Reason<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="onholdReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: "100%", marginTop: "-5px" }}
            >
              <option disabled selected hidden>
                Select Reason
              </option>
              <option value="Slow Inventory Liquidation">
                Slow Inventory Liquidation
              </option>
              <option value="Internal Audit">Internal Audit</option>
              <option value="Payment Dispute Not Resolved">
                Payment Dispute Not Resolved
              </option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.onholdReason === "Others" ? (
            <>
              <label
                className="lgInput"
                style={{ fontSize: "13px", fontWeight: "500" }}
              >
                Description
                <input
                  type="text"
                  style={{ width: "508px" }}
                  name="description"
                  value={formValue.description}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      [e.target.name]: e.target.value,
                    })
                  }
                  placeholder="On Hold Reason"
                  autoComplete="off"
                />
              </label>
            </>
          ) : null}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}><KeyboardBackspace/>{" "}Back</button>
          <button
            id="submit-button"
            onClick={extraData == "spare" ? spareSubmitHandler : submitHandler}
            disabled={formValue.onholdReason == "" ? true : false}
          >
            <CheckCircle/>{" "}Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnHold;
