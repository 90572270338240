import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
  ConfirmationNumber,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import {
  faEdit,
  faBan,
  faShare,
  faPauseCircle,
} from "@fortawesome/free-solid-svg-icons";
import UpdateProgress from "./UpdateProgress";
import VehicleReturnConfirmation from "./VehicleReturnConfirmation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VehicleReturnViewUpdate = ({ closeModal, rowData, updatedData }) => {
  console.log(rowData);
  const [receivedBy, setReceivedBy] = useState();
  const [receivedDate, setReceivedDate] = useState(new Date(parseInt("5467")));
  const [viewSpecs, setViewSpecs] = useState();
  const [showReject, setShowReject] = useState(false);
  const [showHold, setShowHold] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [receivedQuantity, setReceivedQty] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [showHybrid, setShowHybrid] = useState(false)

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(`qc-module/api/v1/vehicle/managerequest/status`, {
        requestID: rowData.requestID,
        status: "Completed",
      })
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const refresh = () => {
    updatedData();
  };

  const StandardVehicles = rowData?.returnedData.filter((item) => {
    return item.vehicleCategory == "Standard";
  });

  const HybridVehicles = rowData?.returnedData.filter((item) => {
    return item.vehicleCategory == "Hybrid";
  });

  console.log(StandardVehicles);

  console.log(HybridVehicles);

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1000px", height: "500px", padding: "15px" }}
      >
        <div className="header">
          <h3>Request Details</h3>
        </div>
        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            paddingTop: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Request ID : {rowData.requestID}</span>
            <span>GIN No.: {rowData.ginNo}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Request Category : {rowData.requestCategory}</span>
            <span>
              GIN Date : {moment(rowData.ginDate).format("DD-MMM-YYYY/HH:mm")}
            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Request Type : {rowData.requestType}</span>
            <span>GIN Qty. : {rowData?.returnedData.length}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Segment: {rowData?.returnedData[0].vehicleSegment}</span>
          </div>
        </section>
        <br />


        <div
          className="body"
          style={{ display: "flex", height: "50%", fontSize: "14px" }}
        >
          <section style={{ width: "100%" }}>
            <div
              id="POVendorHeading"
              style={{ overflow: "auto", width: "150%" }}
            >
              <div style={{ width: "5%" }}>S. No.</div>
              <div style={{ width: "10%" }}>Vehicle Model</div>
              <div style={{ width: "10%" }}>Vehicle Variant</div>
              <div style={{ width: "10%" }}>Vehicle Type</div>
              <div style={{ width: "10%" }}>Category</div>
              <div style={{ width: "10%" }}>Color</div>
              <div style={{ width: "15%" }}>VIN</div>
              <div style={{ width: "15%" }}>BIN</div>
              <div style={{ width: "20%" }}>Status</div>
              <div style={{ width: "15%" }}>Verified On</div>
              <div style={{ width: "20%" }}>Failure Reason</div>
              <div style={{ width: "20%" }}>Action</div>
            </div>
            <div>
              {rowData?.returnedData?.map((item, index) => {
                return (
                  <div
                    id="POVendorContent"
                    style={{
                      borderBottom: "1px solid #999999",
                      boxShadow: "none",
                      padding: "0px",
                      overflow: "auto",
                      width: "150%",
                    }}
                    key={1}
                  >
                    <div style={{ width: "5%" }}>{index + 1}</div>
                    <div style={{ width: "10%" }}>{item.vehicleModel}</div>
                    <div style={{ width: "10%" }}>{item.vehicleVariant}</div>
                    <div style={{ width: "10%" }}>{item.vehicleType}</div>
                    <div style={{ width: "10%" }}>{item.vehicleCategory}</div>
                    <div style={{ width: "10%" }}>{item.vehicleColor}</div>
                    <div style={{ width: "15%" }}>{item.VIN}</div>
                    <div style={{ width: "15%" }}>{item.BIN}</div>
                    <div style={{ width: "20%" }}>{item.status == "Failed" ? (
                      <button style={{ width: "170px" }} className="delete-btn">
                        <span className="delete-btn-font">
                          {item.status}
                        </span>
                      </button>
                    ) : item.status == "Pending Verification" ? (
                      <button style={{ width: "170px" }} className="deactive-btn">
                        <span className="deactive-btn-font">
                          {item.status}
                        </span>
                      </button>
                    ) : (
                      <button style={{ width: "170px" }} className="active-btn">
                        <span className="active-btn-font">
                          {item.status}
                        </span>
                      </button>
                    )}</div>
                    <div style={{ width: "15%" }}>
                      {moment(item.actionDate).format("DD-MMM-YYYY, HH:mm")}
                    </div>
                    <div style={{ width: "20%" }}>{item.reason}</div>
                    <div style={{ width: "20%" }}>
                      {item.status == "Pending Verification" ? (
                        <button
                          style={{
                            fontSize: "12px",
                            color: "#0a18a3",
                            cursor: "pointer",
                            padding: "3px 9px",
                            border: "1px solid #0a18a3",
                            width: "115px",
                            height: "25px",
                            borderRadius: "4px",
                            background: "white",
                          }}
                          onClick={() => {
                            setShowHold(true);
                            setSelectedIndex(index);
                          }}
                        >
                          Update progress
                        </button>
                      ) : (
                        "-"
                      )}
                    </div>
                    {selectedIndex == index && showHold && (
                      <UpdateProgress
                        extraData="extra"
                        updatedData={updatedData}
                        item={item}
                        rowData={rowData}
                        closeModal={setShowHold}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </section>
        </div>


        {/* {HybridVehicles.map((item, index) => {
          return (
            <div
              className="body"
              style={{ display: "flex", height: "50%", fontSize: "14px" }}
            >
              <section style={{ width: "100%" }}>
                <div
                  id="POVendorHeading"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    background: "#E5E5E5",
                    borderRadius: "3px",
                    width: "130px",
                    height: "27px",
                  }}
                >
                  <span style={{ marginLeft: "5px", marginTop: "5px" }}>
                    Category: {item.vehicleCategory}
                  </span>
                </div>
                <div
                  id="POVendorHeading"
                  style={{ overflow: "auto", width: "150%" }}
                >
                  <div style={{ width: "5%" }}>S. No.</div>
                  <div style={{ width: "10%" }}>Vehicle Model</div>
                  <div style={{ width: "10%" }}>Vehicle Variant</div>
                  <div style={{ width: "10%" }}>Vehicle Type</div>
                  <div style={{ width: "10%" }}>Color</div>
                  <div style={{ width: "15%" }}>VIN</div>
                  <div style={{ width: "15%" }}>BIN</div>
                  <div style={{ width: "20%" }}>Status</div>
                  <div style={{ width: "15%" }}>Verified On</div>
                  <div style={{ width: "20%" }}>Failure Reason</div>
                  <div style={{ width: "20%" }}>Action</div>
                </div>
                <div>
                  <div
                    id="POVendorContent"
                    style={{
                      borderBottom: "1px solid #999999",
                      boxShadow: "none",
                      padding: "0px",
                      overflow: "auto",
                      width: "150%",
                    }}
                    key={1}
                  >
                    <div style={{ width: "5%" }}>{index + 1}</div>
                    <div style={{ width: "10%" }}>{item.vehicleModel}</div>
                    <div style={{ width: "10%" }}>{item.vehicleVariant}</div>
                    <div style={{ width: "10%" }}>{item.vehicleType}</div>
                    <div style={{ width: "10%" }}>{item.vehicleColor}</div>
                    <div style={{ width: "15%" }}>{item.VIN}</div>
                    <div style={{ width: "15%" }}>{"-"}</div>
                    <div style={{ width: "20%" }}> {item.status == "Failed" ? (
                          <button style={{width:"170px"}} className="delete-btn">
                            <span className="delete-btn-font">
                              {item.status}
                            </span>
                          </button>
                        ) : item.status == "Pending Verification" ? (
                          <button style={{width:"170px"}} className="deactive-btn">
                            <span className="deactive-btn-font">
                              {item.status}
                            </span>
                          </button>
                        ) : (
                          <button style={{width:"170px"}} className="active-btn">
                            <span className="active-btn-font">
                              {item.status}
                            </span>
                          </button>
                        )}</div>
                    <div style={{ width: "15%" }}>
                      {moment(item.actiondate).format("DD-MMM-YYYY, HH:mm")}
                    </div>
                    <div style={{ width: "20%" }}>{item.reason}</div>
                    <div style={{ width: "20%" }}>
                      {item.status == "Pending Verification" ? (
                        <button
                          style={{
                            fontSize: "12px",
                            color: "#0a18a3",
                            cursor: "pointer",
                            padding: "3px 9px",
                            border: "1px solid #0a18a3",
                            width: "115px",
                            height: "25px",
                            borderRadius: "4px",
                            background: "white",
                          }}
                          onClick={() => {
                            setShowHybrid(true);
                            setSelectedIndex(index);
                          }}
                        >
                          Update progress
                        </button>
                      ) : (
                        "-"
                      )}
                    </div>
                    {selectedIndex == index && showHybrid && (
                      <UpdateProgress
                        extraData="extra"
                        updatedData={updatedData}
                        item={item}
                        rowData={rowData}
                        closeModal={setShowHybrid}
                      />
                    )}
                  </div>
                </div>
              </section>
            </div>
          );
        })} */}

        <div className="footer">
          <div>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "25px" }} />
              Back
            </button>
          </div>

          <div>
            <button
              onClick={() => setOpenModal(true)}
              style={{ marginRight: "0", width: "200px", height: "42px" }}
              id="submit-button"
            >
              <CheckCircle style={{ fontSize: "17px" }} /> Verification Complete{" "}
            </button>
            {openModal && (
              <VehicleReturnConfirmation
                submitHandler={submitHandler}
                closeModal={setOpenModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleReturnViewUpdate;
