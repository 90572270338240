import React, { useState } from "react";
import Edit from "./Edit";
import "../../../../components/modal/modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const Action = ({updatedData,rowData, setIsOpen, rowIndex}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && <Edit updatedData={updatedData} editData={rowData} closeModal={setShowModal} setIsOpen={setIsOpen}/>}

      <div style={{ position: "relative",marginTop: rowIndex <= 4 ? "0px" : "-45px" }}>
        <div style={{  marginLeft: "-7px",width:"73px"  }} className="action">
          <div>
            <span id="edit" onClick={(e) => clickHandler(e)}>
            <FontAwesomeIcon icon={faEdit} /> Edit
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Action;
