import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import Hsn from "./components/HSN";
// import VehiclePricing from "./components/VehiclePricing";
// import SparePricing from "./components/SparePricing";

const Hsn = React.lazy(()=>import("./components/HSN"))
const VehiclePricing = React.lazy(()=>import("./components/VehiclePricing"))
const SparePricing = React.lazy(()=>import("./components/SparePricing"))


const tabs = [
  {
    id: "FIM1T1",
    label: TabLabels.HSN,
    Component: Hsn,
  },
  {
    id: "FIM1T2",
    label: TabLabels.VEHICLE_PRICING,
    Component: VehiclePricing,
  },
  {
    id: "FIM1T3",
    label: TabLabels.SPARE_PRICING,
    Component: SparePricing
  }
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};


const Pricing = () => {

  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  )
};

export default Pricing;
