import React, { useState } from "react";
import "../QcDashboard/styles/main.scss";
import { BsArrowUpRight, BsTable } from "react-icons/bs";
import SalesAreasDash from "./SalesAreasDash";
import OprVehiclesCard from "../OperationsDashboard/OprVehiclesCard";
import OprSparesCard from "../OperationsDashboard/oprSparesCard";
import NationalSpareOrderCard from "./NationalSpareOrderCard";
import NationalVehicleReturnCard from "./NationalVehicleReturnCard";
import NationalSpareReturnCard from "./NationalSpareReturnCard";
import NationalVehicleOrderCard from "./NationalVehicleOrderCard";

//Routing Components
import Users from "../NationalSalesUsers/Tabs/ManageUsers";
import Vehicles from "../NationalSalesVehicleAndSpares/Tabs/Vehicles";
import Spares from "../NationalSalesVehicleAndSpares/Tabs/Spares";
import VehiclesOrders from "../NationalSalesOrders/VehicleOrders/NewRequest";
import SparesOrders from "../NationalSalesOrders/SparesOrders/NewRequest";
import VehiclesReturns from "../NationalSalesReturns/VehicleReturns/Component/NewRequest";
import SpareReturns from "../NationalSalesReturns/SpareReturns/NewRequest";

function NationalSalesDashboard() {
  const [openUsersTable, setOpenUsersTable] = useState(false);
  const [openVehicleTable, setOpenVehicleTable] = useState(false);
  const [openSparesTable, setOpenSparesTable] = useState(false);
  const [openVehiclesOrderTable, setOpenVehiclesOrderTable] = useState(false);
  const [openSparesOrderTable, setOpenSparesOrderTable] = useState(false);
  const [openVehicleReturnTable, setOpenVehicleReturnTable] = useState(false);
  const [openSparesReturnTable, setOpenSparesReturnTable] = useState(false);
  return (
    <>
      {openUsersTable == true ? (
        <Users inDashboard={setOpenUsersTable} />
      ) : openVehicleTable == true ? (
        <Vehicles inDashboard={setOpenVehicleTable} />
      ) : openSparesTable == true ? (
        <Spares inDashboard={setOpenSparesTable} />
      ) : openVehiclesOrderTable == true ? (
        <VehiclesOrders inDashboard={setOpenVehiclesOrderTable} />
      ) : openSparesOrderTable == true ? (
        <SparesOrders inDashboard={setOpenSparesOrderTable} />
      ) : openVehicleReturnTable == true ? (
        <VehiclesReturns inDashboard={setOpenVehicleReturnTable} />
      ) : openSparesReturnTable == true ? (
        <SpareReturns inDashboard={setOpenSparesReturnTable} />
      ) : (
        <>
          <div id="overlay" onClick={(e) => off(e)}></div>
          <div className="dashboardBody">
            <div className="firstRow">
              <div
                className="container-box"
                style={{ width: "100%", padding: "10px" }}
              >
                <div className="header">
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Users</h5>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => {
                          setOpenUsersTable(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <SalesAreasDash />
                </div>
              </div>
            </div>

            {/* second row */}

            <div className="secondRow">
              <div
                className="container-box"
                style={{ width: "50%", padding: "10px" }}
              >
                <div className="header">
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Vehicles</h5>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => {
                          setOpenVehicleTable(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ overflow: "auto" }}>
                  <OprVehiclesCard prop="vehicle" />
                </div>
              </div>

              <div
                className="container-box"
                style={{
                  width: "50%",
                  padding: "10px",
                  marginLeft: "15px"
                }}
              >
                <div className="header">
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Spares</h5>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => {
                          setOpenSparesTable(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ overflow: "auto" }}>
                  <OprVehiclesCard prop="spare" />
                </div>
              </div>
            </div>

            {/* third row*/}
            <div className="secondRow">
              <div
                className="container-box"
                style={{ width: "50%", padding: "10px" }}
              >
                <div className="header">
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Vehicle Orders</h5>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => {
                          setOpenVehiclesOrderTable(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ overflow: "auto" }}>
                  <NationalVehicleOrderCard prop="vehicle" />
                </div>
              </div>

              <div
                className="container-box"
                style={{ width: "50%", padding: "10px", marginLeft: "15px" }}
              >
                <div className="header">
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Spare Orders</h5>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => {
                          setOpenSparesOrderTable(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ overflow: "auto" }}>
                  <NationalVehicleOrderCard prop="spare" />
                </div>
              </div>
            </div>

            {/* Fourth Row */}
            <div className="secondRow">
              <div
                className="container-box"
                style={{ width: "50%", height: "380px", padding: "10px" }}
              >
                <div className="header">
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Vehicle Returns</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => {
                          setOpenVehicleReturnTable(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <NationalSpareReturnCard prop="vehicle" />
              </div>
              <div
                className="container-box"
                style={{
                  width: "50%",
                  height: "380px",
                  padding: "10px",
                  marginLeft: "10px",
                }}
              >
                <div className="header">
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Spare Returns</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => {
                          setOpenSparesReturnTable(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <NationalSpareReturnCard prop="spare" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NationalSalesDashboard;
