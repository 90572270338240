import React, { useEffect, useState } from 'react';
import Group from '../QcDashboard/assets/Group.png';
import Group2 from '../QcDashboard/assets/Group2.png';
import Group11 from '../QcDashboard/assets/Group11.svg';
import Vector from '../QcDashboard/assets/Vector.svg';
import Vector3 from '../QcDashboard/assets/Vector3.svg';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';

function VehicleOutwardBox() {

    const [outwardData, setOutwardData] = useState({});

    const fetchOutwardData = async () => {
        const resp = await trackPromise(API.get(`inventory-module/api/v1/dashboard/getVehicleOutwardData`))
        setOutwardData(resp);
    };
    useEffect(() => {
        fetchOutwardData();
    }, []);

    return (
        <>
            <div style={{ width: "94%", height: "70px", boxShadow: "0px 0px 12px rgb(0 0 0 / 15%)", borderRadius: "10px", marginTop: "30px", marginLeft: "12px", display: "flex", justifyContent: "space-around" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "50%" }}>
                    <img style={{ margin: "12px 0px 0px 10px", height: "46px" }} src={Group} />&nbsp;&nbsp;
                    <div style={{ fontSize: "16px", fontWeight: "500", marginTop: "24px" }}>New Requests</div>
                </div>
                <div style={{ fontSize: "26px", fontWeight: "500", margin: "18px 0px 0px 123px" }}>{outwardData.totalPOReqs}
                    {/* &nbsp;{outwardData?.totalPOReqs > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${outwardData?.totalPOReqs}`}</span></> : outwardData?.totalPOReqs === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${outwardData?.totalPOReqs}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${outwardData?.totalPOReqs}`}</span></>} */}
                </div>
            </div>

            <div style={{ width: "94%", height: "70px", boxShadow: "0px 0px 12px rgb(0 0 0 / 15%)", borderRadius: "10px", marginTop: "10px", marginLeft: "12px", display: "flex", justifyContent: "space-around" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "50%" }}>
                    <img style={{ margin: "12px 0px 0px 10px", height: "46px" }} src={Group2} />&nbsp;&nbsp;
                    <div style={{ fontSize: "16px", fontWeight: "500", marginTop: "24px" }}>Managed Requests</div>
                </div>
                <div style={{ fontSize: "26px", fontWeight: "500", margin: "18px 0px 0px 123px" }}>{outwardData.managedPOReqs}
                    {/* &nbsp;{outwardData.managedPOReqs > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${outwardData.managedPOReqs}`}</span></> : outwardData.managedPOReqs === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${outwardData.managedPOReqs}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${outwardData.managedPOReqs}`}</span></>} */}
                </div>
            </div>

            <div style={{ width: "94%", height: "70px", boxShadow: "0px 0px 12px rgb(0 0 0 / 15%)", borderRadius: "10px", marginTop: "10px", marginLeft: "12px", display: "flex", justifyContent: "space-around" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "50%" }}>
                    <img style={{ margin: "12px 0px 0px 10px", height: "46px" }} src={Group11} />&nbsp;&nbsp;
                    <div style={{ fontSize: "16px", fontWeight: "500", marginTop: "24px" }}>Order History</div>
                </div>
                <div style={{ fontSize: "26px", fontWeight: "500", margin: "18px 0px 0px 123px" }}>{outwardData.orderHistory}
                    {/* &nbsp;{outwardData.orderHistory > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${outwardData.orderHistory}`}</span></> : outwardData.orderHistory === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${outwardData.orderHistory}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${outwardData.orderHistory}`}</span></>} */}
                </div>
            </div>
        </>
    )
}

export default VehicleOutwardBox