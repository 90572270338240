import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../../utils/api";
import { errorNotification, successNotification } from "../../../../../utils/notifications";
import moment from "moment";
import {
  CheckCircle
} from "@material-ui/icons";
import DealerSparePO from "../../../../RequisitionOrders/DealerSparePO";
import auth from "../../../../../utils/auth";

const PlaceToFinance = ({ rowData, closeModal, updatedData }) => {
  const [financeData, setFinanceData] = useState([]);
  const [userData, setUserData] = useState({});
  const [openPO,setPO] = useState(false);

  const handleInputPlanTypeChange = (event) => {
    let finalUserData = financeData.filter((item) => {
      return item.userID == event.target.value;
    });
    setUserData(finalUserData[0]);
  };

  const refresh = () => {
    updatedData();
  };


  const submitHandler = async () => {
    const body = {
      "requisitionID": rowData.requisitionID,
      "status": "Pending at Finance",
      "financeUserName": userData.fullName,
      "financeUserEmail": userData.email,
      "financeUserID": userData.userID
    }
    const resp = await trackPromise(API.put(`/salesUser-module/api/v1/orders/spareUpdateStatus`, body));
    if (resp.success) {
      successNotification(resp.message, { autoClose: 10000 });
      closeModal(false);
      refresh();
    } else {
      errorNotification(resp.message, { autoClose: 10000 });
    }
  };

  const fetchReport = async () => {
    const resp = await trackPromise(API.get(`/salesUser-module/api/v1/orders/getFinanceUser`));
    console.log(resp);
    setFinanceData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);


  return (
    <div className="container1">
    {
      openPO ? <DealerSparePO rowData={rowData} closeModal={setPO} type="requisitionDetails"/>:""
    }
      <div
        className="modalContainer"
        style={{ width: "500px", height: "315px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Place To Finance</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "13px" }}
        >
          <section
            style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: "2%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <span>Request ID: {rowData.requisitionID}</span>
              <span>
              <span>PO No.: </span>
              <span style={{color:"blue",cursor:"pointer"}} onClick={()=>setPO(true)}>{rowData.poNo}</span>
            </span>
              <span>PO Date: {moment(rowData?.poDate).format("DD-MM-YYYY")}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <span>PO By: {rowData.poBy}</span>
              <span>PO Value: {`${auth.getUserInfo().currencySymbol}${rowData.poValue}`}</span>
            </div>
          </section>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100px",
              justifyContent: "start",
              textAlign: "start",
            }}
          >
            <label
              style={{
                float: "left",
                marginLeft: "5px",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              Select Finance User<span style={{ color: "red" }}>*</span>
            </label>
            <select
              required={true}
              name="businessPlanType"
              id="type"
              className="input-config"
              onChange={handleInputPlanTypeChange}
              style={{ marginTop: "-8px", width: "100%" }}
            >
              <option value="" disabled selected>
                Select Finance User
              </option>
              {financeData.map((item) => {
                return <option value={item.userID}>{item.fullName}</option>;
              })}
            </select>
          </section>
        </div>
        <br />

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
          <button className="btn btn-icon-label" type="submit" onClick={submitHandler} style={{ backgroundColor: "blue", color: "white" }}>
            <div style={{ fontSize: "14px", color: "white", fontWeight: "500" }}><CheckCircle style={{ fontSize: "17px" }} />&nbsp;Submit</div>
          </button>
        </div>

      </div>
    </div>
  );
};

export default PlaceToFinance;
