import React, { useState } from "react";
import Switch from "react-switch";
import { trackPromise } from "react-promise-tracker";
// import API from "utils/api";

const NotificationSwitch = id => {
  const [checked, setChecked] = useState(!id.enable);
  const handleChange = nextChecked => {
    setChecked(nextChecked);
    // callApi(nextChecked);
  };

  // const callApi = async nextChecked => {
  //   console.log(id.id);
  //   const resp = await trackPromise(
  //     API.put(`/auth/disableuser?enable=${!nextChecked}&id=${id.id}`)
  //   );
  //   console.log(resp);
  // };
  return (
    <div className="example">
      <label>
        <Switch
          onChange={handleChange}
          checked={checked}
          onColor="#0A18A3"
          offColor="#D3D3D3"
          height={22}
          width={40}
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 9,
                fontWeight: "bold",
                color: "white",
                // marginLeft: 9
              }}
            >
            </div>
          }
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 9,
                fontWeight: "bold",
                color: "white",
                // marginRight: 9
              }}
            >
            </div>
          }
        />
      </label>
    </div>
  );
};

export default NotificationSwitch;
