import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { errorNotification, successNotification } from "../../../utils/notifications";
import "../../settings-tabs/homesettings.scss";
import "../../user/users.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckCircle, CancelOutlined } from "@material-ui/icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ManageFeaturesTable from "./ManageFeaturesTable";
import { useForm } from "react-hook-form";

const AddFeatures = () => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [blankForm, setBlankForm] = useState([{ addFeatures: "", monthlyCharges: "", yearlyCharges: "" }]);
  const [selectedOption, setSelectedOption] = useState("Monthly");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const addFormFields = () => {
    setBlankForm([...blankForm, { addFeatures: "", monthlyCharges: "", yearlyCharges: "" }]);
  };

  const removeFields = (index) => {
    const updatedFields = blankForm.filter((_, i) => i !== index);
    setBlankForm(updatedFields);
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const updatedFields = [...blankForm];
    updatedFields[index][name] = value;
    setBlankForm(updatedFields);
  };

  const validateInputs = () => {
    const numericRegex = /^\d+$/;
    return blankForm.every(({ monthlyCharges, yearlyCharges }) => {
      if (!monthlyCharges && selectedOption === "Monthly" || !yearlyCharges && selectedOption === "Yearly") {
        errorNotification("All fields are required.");
        return false;
      }
      if (monthlyCharges && !numericRegex.test(monthlyCharges)) {
        errorNotification("Monthly charges must be a numeric value.");
        return false;
      }
      if (yearlyCharges && !numericRegex.test(yearlyCharges)) {
        errorNotification("Yearly charges must be a numeric value.");
        return false;
      }
      return true;
    });
  };

  const submitHandler = async () => {
    if (!validateInputs()) return;

    const result = await trackPromise(API.post(`systemadmin/api/v1/addUserCharges`, blankForm));
    if (result.success) {
      successNotification(result.message, { autoClose: 3000 });
      setBlankForm([{ addFeatures: "", monthlyCharges: "", yearlyCharges: "" }]); // Reset form
    } else {
      errorNotification(result.message, { autoClose: 3000 });
    }
  };

  const cancel = () => {
    setBlankForm([{ addFeatures: "", monthlyCharges: "", yearlyCharges: "" }]);
  };

  return (
    <>
      <div className="bussiness">
        <h4 className="heading">Add User Charges</h4>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input type="radio" value="Monthly" checked={selectedOption === "Monthly"} onChange={handleOptionChange} />
          <span style={{ width: "90px", padding: "6px" }}>Monthly</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input type="radio" value="Yearly" checked={selectedOption === "Yearly"} onChange={handleOptionChange} />
          <span style={{ width: "90px", padding: "6px" }}>Yearly</span>
        </div>
      </div>

      <form className="setting-form" onSubmit={handleSubmit(submitHandler)}  style={{marginBottom:"60px"}}>
        {blankForm.map((item, index) => (
          <div key={index} style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <div style={{ margin: "0 5px", width: "30%", display: "flex", flexDirection: "column" }}>
              <label style={{ fontSize: "13px", fontWeight: "500", marginLeft: "6px" }}>
                Add Features <span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="input-config"
                type="text"
                name={selectedOption === "Monthly" ? "monthlyCharges" : "yearlyCharges"}
                value={selectedOption === "Monthly" ? item.monthlyCharges : item.yearlyCharges}
                onChange={(event) => handleInputChange(event, index)}
                placeholder={`Enter ${selectedOption} Charges`}
                style={{ marginTop: "-8px", width: "100%" }}
                onKeyPress={(e) => {
                  const isNumeric = /^[0-9]*$/.test(e.key);
                  if (!isNumeric) e.preventDefault();
                }}
              />
            </div>           
          </div>
        ))}
        <br />
        <div className="save-cancel">
          <div className="cust-reset  cancel-button">
            <button type="button" className="btn btn-icon-label">
              <div
                style={{
                  fontSize: "14px",
                  color: "#0A18A3",
                  fontWeight: "500",
                }}
                onClick={(e) => {
                  cancel(e);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
                Cancel
              </div>
            </button>
          </div>
          <div className=" cancel-button">
            <button className="btn btn-icon-label" type="submit">
              <div
                style={{
                  fontSize: "14px",
                  color: "#0A18A3",
                  fontWeight: "500",
                }}
                onClick={() => {
                  submitHandler();
                }}
              >
                <CheckCircle style={{ fontSize: "17px" }} />
                Submit
              </div>
            </button>
          </div>
        </div>



      </form>

      <ManageFeaturesTable />
    </>
  );
};

export default AddFeatures;
