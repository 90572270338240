import React, { useEffect, useState } from 'react';
import './superAdminDashboard.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import "../QcDashboard/styles/main.scss";
import { FaExpandAlt } from 'react-icons/fa';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';
import { Bar } from 'react-chartjs-2';
import API from '../../utils/api';
import { trackPromise } from 'react-promise-tracker';
import auth from '../../utils/auth';

const ExpandSubscription = ({ closeModal }) => {

    const [currentPlan, setCurrentPlan] = useState([]);

    console.log("currentPlan -->",currentPlan)

    const fetchPlanHistoryWithUserIDData = async () => {
        const resp = await trackPromise(
            API.get(`admin-module/api/v1/subscription/planHistoryWithUserId?userID=${auth.getUserInfo().userID}`)
        );
        console.log(resp);
        setPlanHistoryData(resp.planHistory);

        const currentPlan = resp.planHistory.filter((item) => {
            return item.planID == resp.planID
        });
        setCurrentPlan(currentPlan);
    };
    useEffect(() => {
        fetchPlanHistoryWithUserIDData();
    }, []);

    return (
        <div className="container1">
            <div className="modalContainer" style={{ width: '667px', height: "317px" }}>
                <div className='container-new' style={{ height: "200px", width: "100%", marginRight: "10px", padding: "5px" }}>
                    <div className='header' style={{ justifyContent: "space-between" }} >
                        <div><h5 style={{ fontSize: "18px" }}>Subscription</h5></div>
                        <div style={{ display: "flex" }}>
                            <div onClick={() => closeModal(false)} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "25px", height: "25px", border: "1px Solid #808080", color: "#808080", borderRadius: "5px", cursor: "pointer" }}>
                                <FaExpandAlt />
                            </div>
                            <button className="action-btn" style={{ height: "25px", color: "#808080" }}>
                                <FontAwesomeIcon className='fa-lg' icon={faEllipsisV} />
                            </button>
                        </div>

                    </div>
                    <table style={{ width: "100%", marginTop: "10px" }}>
                        <tr>
                            <td className='grey-heading'>Plan Name</td>
                            <td className='grey-heading'>Subscription Type</td>
                            <td className='grey-heading'>Last Renewed Date</td>
                            <td className='grey-heading'>Subscription Expiry</td>
                        </tr>
                        <tr>
                            <td className='black-heading'>{currentPlan[0]?.planName}</td>
                            <td className='black-heading'>{auth.getUserInfo()?.planType}</td>
                            <td className='black-heading'>-</td>
                            <td className='red-heading'>{currentPlan[0]?.validTill}</td>
                        </tr>
                    </table>

                    <div id="wrapper">
                        {/* <div style={{ width: "48%" }}>
                            <div className='grey-heading'>10 of 20 User</div>
                            <div style={{ marginTop: "10px" }}>
                                <ProgressBar>
                                    <ProgressBar now={100} style={{ background: "#3442CF" }} />
                                    <ProgressBar now={60} style={{ background: "#CDCDCD" }} />
                                </ProgressBar>
                            </div>
                        </div> */}
                        <div style={{ display: "flex", marginTop: "11px" }}>
                            <button className='whiteBgButton'>Renewed Plan</button>
                            <button className='colorBgButton'>Renewed Plan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpandSubscription