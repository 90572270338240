import React, { useState } from "react";
import {
  faEdit,
  faBan,
  faTrash,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../components/modal/modal.scss";
import EditShowroom from "./editShowroom";
import ShowroomDeactivate from "./showroomDeactivate";
import ShowroomReactivate from "./showroomReactivate";
import ShowroomDelete from "./showroomDelete";
import Resetpswd from "../../../components/modal/Resetpswd";
import ViewHistory from "./ViewHistory";

const ShowroomAction = ({ updatedData, rowData, setIsOpen, tabName , rowIndex }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      resetpswd: false,
      reactivate: false,
      deactivate: false,
      delete: false,
      history: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && (
        <EditShowroom
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
          tabName={tabName}
        />
      )}
      {activeModal.resetpswd && showModal && (
        <Resetpswd closeModal={setShowModal} />
      )}
      {rowData.status === "Active" || rowData.status === "Reactivate"
        ? activeModal.deactivate &&
          showModal && (
            <ShowroomDeactivate
              updatedData={updatedData}
              editData={rowData}
              closeModal={setShowModal}
              tabName={tabName}
            />
          )
        : activeModal.reactivate &&
          showModal && (
            <ShowroomReactivate
              updatedData={updatedData}
              editData={rowData}
              closeModal={setShowModal}
              tabName={tabName}
            />
          )}

      {activeModal.delete && showModal && (
        <ShowroomDelete
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
          tabName={tabName}
        />
      )}

      {activeModal.history && showModal && (
        <ViewHistory
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
          tabName={tabName}
        />
      )}

      <div style={{ position: "relative", marginTop: rowIndex <= 4 ? "0px" : "-145px", }}>
        <div className="action" onBlur={() => setIsOpen(false)}>
          {
            rowData.status === "Active" || rowData.status === "Reactivate" ? <div> <span id="edit" onClick={(e) => clickHandler(e)}>
            <FontAwesomeIcon icon={faEdit} /> Edit
          </span> </div>:""
            }
           
          {
            rowData.status === "Active" || rowData.status === "Reactivate" ?  <div><span id="resetpswd" onClick={(e) => clickHandler(e)}>
            <FontAwesomeIcon icon={faEdit} /> Reset Password
          </span></div>:""
            }
            {rowData.status === "Active" || rowData.status === "Reactivate" ? (
              <div><span id="deactivate" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faBan} /> Deactivate
              </span></div>
            ) : rowData.status === "Deactivate" ?(
              <div><span id="reactivate" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faDotCircle} /> Reactivate
              </span></div>
            ):""}
            {
              rowData.status === "Active" || rowData.status === "Reactive" || rowData.status ==="Deactivate" ? <div><span id="delete" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faTrash} /> Delete
            </span></div>:""
            }

            {rowData.status === "Delete" ? (
              <div><span id="history" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faBan} /> View History
              </span></div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
  );
};

export default ShowroomAction;
