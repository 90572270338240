import React from 'react'
import {
    CancelOutlined,
    CheckCircle,
  } from "@material-ui/icons";

const CompleteConfirm = ({closeModal, submitHandler}) => {

  return (
    <div className='container1'>
            <div className="modalContainer" style={
                {width:'400px',height:'200px'}
            }>

            <div className="body" style={{justifyContent:'center',fontSize:'16px',padding:"0px"}}>
                    Are you sure to mark this request complete?
                
            </div>
            <div className="footer" style={{justifyContent:"center"}}>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: 'white',
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
            onClick={()=>{submitHandler()}}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>

        </div>
        </div>
        </div>
  )
}

export default CompleteConfirm;