import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import '../styles/main.scss'
import Group from '../assets/Group.png'
import Group2 from '../assets/Group2.png'
import Vector from '../assets/Vector.svg'
import Vector3 from '../../QcDashboard/assets/Vector3.svg'
import Group3 from '../assets/Group3.png'
import Group4 from '../assets/Group4.png'
import Group5 from '../assets/Group5.png'
import Group6 from '../assets/Group6.png'

function ComponentVerification() {
  const [data, setData] = useState({})

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `qc-module/api/v1/component`
      )
    );
    console.log(resp);
    setData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <div className='requestBody'>
      <div className='request'>
        <img src={Group} style={{ marginLeft: "8px" }} alt="group" />
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
          <div style={{ fontSize: "24px", fontWeight: "450" }}>{data.components}
            {/* &nbsp;{data.componentsChange > 0 ? <><img src={Vector} alt="vector" /><span style={{fontSize:"14px",color:"green"}}>{`+${data.componentsChange}`}</span></>:data.componentsChange === 0 ? <><img src={Vector} alt="vector" /><span style={{fontSize:"14px",color:"green"}}>{`+${data.componentsChange}`}</span></>:<><img src={Vector3} alt="vector" /><span style={{fontSize:"14px",color:"red"}}>{`${data.componentsChange}`}</span></>} */}
          </div>
          <span style={{ color: "#808080", fontSize: "14px" }}>Total Requests</span>
        </div>
      </div>


      <div className='request'>
        <img src={Group} style={{ marginLeft: "8px" }} alt="group" />
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
          <div style={{ fontSize: "24px", fontWeight: "450" }}>{data.newComp}
            {/* &nbsp;{data.newCompChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data.newCompChange}`}</span></> : data.newCompChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data.newCompChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data.newCompChange}`}</span></>} */}
          </div>
          <span style={{ color: "#808080", fontSize: "14px" }}>New Requests</span>
        </div>
      </div>



      <div className='request'>
        <img src={Group} style={{ marginLeft: "8px" }} alt="group" />
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
          <div style={{ fontSize: "24px", fontWeight: "450" }}>{data.inprogressComp}
            {/* &nbsp;{data.inprogressCompChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data.inprogressCompChange}`}</span></> : data.inprogressCompChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data.inprogressCompChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data.inprogressCompChange}`}</span></>} */}
          </div>
          <span style={{ color: "#808080", fontSize: "14px" }}>In-Progress Requests</span>
        </div>
      </div>


      <div className='request'>
        <img src={Group} style={{ marginLeft: "8px" }} alt="group" />
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
          <div style={{ fontSize: "24px", fontWeight: "450" }}>{data.holdComp}
            {/* &nbsp;{data.holdCompChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data.holdCompChange}`}</span></> : data.holdCompChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data.holdCompChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data.holdCompChange}`}</span></>} */}
          </div>
          <span style={{ color: "#808080", fontSize: "14px" }}>Hold Requests</span>
        </div>
      </div>


      <div className='request'>
        <img src={Group} style={{ marginLeft: "8px" }} alt="group" />
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
          <div style={{ fontSize: "24px", fontWeight: "450" }}>{data.completedComp}
            {/* &nbsp;{data.completedCompChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data.completedCompChange}`}</span></> : data.completedCompChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data.completedCompChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data.completedCompChange}`}</span></>} */}
          </div>
          <span style={{ color: "#808080", fontSize: "14px" }}>Completed Requests</span>
        </div>
      </div>


      <div className='request'>
        <img src={Group} style={{ marginLeft: "8px" }} alt="group" />
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
          <div style={{ fontSize: "24px", fontWeight: "450" }}>{data.rejectedComp}
            {/* &nbsp;{data.rejectedCompChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data.rejectedCompChange}`}</span></> : data.rejectedCompChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data.rejectedCompChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data.rejectedCompChange}`}</span></>} */}
          </div>
          <span style={{ color: "#808080", fontSize: "14px" }}>Rejected Requests</span>
        </div>
      </div>

    </div>
  )
}

export default ComponentVerification