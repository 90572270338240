import React, { useState } from "react";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
} from "@material-ui/icons";
import moment from "moment";
import AppConstants from "../../../utils/app-constants";
import auth from "../../../utils/auth";

const OnHoldDetails = ({ closeModal, rowData }) => {
  const [openQty, setOpenQty] = useState();
  const [viewSpecs, setViewSpecs] = useState(false);
  const [showGRN, setShowGRN] = useState();
  const [showGIN, setShowGIN] = useState();

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "800px", height: "400px", padding: "15px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>View and Update</h3>
          <div></div>
        </div>

        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
            margin: "10px 0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Requested ID: {rowData?.requestID}</span>
            <span>RPI No.: {rowData?.rpiNo}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Requested By: {rowData?.requestedBy}</span>
            <span>
              RPI Date: {moment(rowData?.rpiDate).format("DD-MMM-YYYY,HH:mm")}
            </span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              Requested Date :{" "}
              {moment(rowData?.requestDate).format("DD-MMM-YYYY,HH:mm")}
            </span>
            <span>RPI Raise By : {rowData?.rpiRaisedBy}</span>
          </div>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Segment : {rowData?.}</span>
            </div> */}
        </section>

        <section>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <span
              style={{
                display: "flex",
                background: "blue",
                alignItems: "center",
                overflow: "auto",
                height: "30px",
                justifyContent: "center",
                borderRadius: "5px",
                color: "white",
                width: "300px",
                fontSize: "14px",
                fontWeight: "800",
              }}
            >
              Total Returned Qty. :{" "}
              {rowData?.returnedData?.map((item) => item?.returnQty.length)}
            </span>
            <span
              style={{
                display: "flex",
                background: "blue",
                alignItems: "center",
                overflow: "auto",
                height: "30px",
                justifyContent: "center",
                borderRadius: "5px",
                color: "white",
                width: "300px",
                fontSize: "14px",
                fontWeight: "800",
                marginLeft: "10px",
              }}
            >
              {`Total Value(${auth.getUserInfo().currencySymbol}) : `}{" "}
              {rowData?.returnedData?.map((item) => item?.priceToserviceCenter)}
            </span>
          </div>
        </section>
        <br />

        <div
          className="body"
          style={{
            display: "flex",
            height: "300px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section
            style={{
              display: "flex",
              width: "100%",
              background: "#FFEAD2",
              height: "110px",
              width: "100%",
              borderRadius: "8px",
            }}
          >
            <div>
              <p
                style={{
                  display: "flex",
                  marginLeft: "10px",
                  color: "#E37D04",
                  alignItems: "flex-start",
                  marginTop: "20px",
                }}
              >
                <label>Hold Date : </label>&nbsp;{" "}
                {rowData?.action?.map((item) =>
                  moment(item?.date).format("DD-MMM-YYYY,HH:mm")
                )}
              </p>
              <p
                style={{
                  display: "flex",
                  marginLeft: "10px",
                  color: "#E37D04",
                  alignItems: "flex-start",
                }}
              >
                <label>Hold Reason : </label>&nbsp;
                {rowData?.action?.map((item) => item?.status)}
              </p>
            </div>
          </section>
        </div>

        <div className="footer">
          <div>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "25px" }} />
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnHoldDetails;
