import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';


const UserCountBarChart = ({ prop }) => {
    const [data, setData] = useState({});

    const fetchData = async () => {
        const res = await trackPromise(
            API.get(`dealer-module/api/v1/dashBoard/vehicleOrders`)
        );
        setData(res);
    };
    const fetchSpareData = async () => {
        const res = await trackPromise(
            API.get(`dealer-module/api/v1/dashBoard/spareOrders`)
        );
        setData(res);
    };
    useEffect(() => {
        prop == "vehicle" ? fetchData() : fetchSpareData();
    }, []);

    return <Bar
        height={120}
        data={prop == "vehicle" ? {
            datasets: [{
                type: 'bar',
                backgroundColor: '#2D3DD9',
                barThickness: 25,
                data: [data.totalVehiclePO, data.totalHoldPO, data.totalCompletedPO, data.totalRejectedPO],
                options: {
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    responsive: true
                }
            }
            ],
            labels: ["Total Vehicle PO", "PO On Hold ", "PO Completed", "PO Rejected"]
        } :
            {
                datasets: [{
                    type: 'bar',
                    backgroundColor: '#2D3DD9',
                    barThickness: 25,
                    data: [data.totalVehiclePO, data.totalHoldPO, data.totalCompletedPO, data.totalRejectedPO],
                    options: {
                        plugins: {
                            legend: {
                                display: false
                            },
                        },
                        responsive: true
                    }
                }
                ],
                labels: ["Total Spare PO", "PO On Hold ", "PO Completed", "PO Rejected"]
            }
        }
    />
}

export default UserCountBarChart