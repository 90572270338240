import React, { useState } from "react";
import { faEdit, faBan, faTrash, faDotCircle, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../../../components/modal/modal.scss';
import EditServiceCenterUsers from "./EditServiceCenterUsers";
import Resetpswd from "../../../../components/modal/Resetpswd";
import DeactivateScUser from "./DeactivateScUser";
import ReactivateScUser from "./ReactivateScUser";
import DeleteScUser from "./DeleteScUser";
import ViewDetails from "./ViewDetails";

const ServiceCenterUsersAction = ({ updatedData, rowData, setIsOpen, tabName , rowIndex }) => {
    const [showModal, setShowModal] = useState(true);
    const [activeModal, setActiveModal] = useState({});

    const clickHandler = (e) => {
        setActiveModal({
            edit: false,
            resetpswd: false,
            reactivate: false,
            deactivate: false,
            delete: false,
            viewHistory: false,
            [e.target.id]: true,
        });
        setShowModal(true);
    };

    return (
        <div>
            {activeModal.edit && showModal && (
                <EditServiceCenterUsers updatedData={updatedData} editData={rowData} closeModal={setShowModal} tabName={tabName} setIsOpen={setIsOpen}/>
            )}
            {activeModal.resetpswd && showModal && (
                <Resetpswd closeModal={setShowModal} tabName={tabName} setIsOpen={setIsOpen}/>
            )}
            {
                rowData.status === "Active" || rowData.status === "Reactivated" ?
                    activeModal.deactivate && showModal && (
                        <DeactivateScUser updatedData={updatedData} editData={rowData} closeModal={setShowModal} tabName={tabName} setIsOpen={setIsOpen}/>
                    )
                    :
                    activeModal.reactivate && showModal && (
                        <ReactivateScUser updatedData={updatedData} editData={rowData} closeModal={setShowModal} tabName={tabName} setIsOpen={setIsOpen} />
                    )
            }

            {activeModal.delete && showModal && <DeleteScUser updatedData={updatedData} editData={rowData} closeModal={setShowModal} tabName={tabName} setIsOpen={setIsOpen} />}

            {activeModal.viewHistory && showModal && <ViewDetails updatedData={updatedData} rowData={rowData} closeModal={setShowModal} tabName={tabName} />}

            <div style={{ position: "relative" , marginTop: rowIndex <= 4 ? "0px" : "-135px", }}>
                <div className="action" onBlur={() => setIsOpen(false)}>
                    {(() => {
                        if (rowData.status === "Active" || rowData.status === "Reactivated") {
                            return (
                                <>
                                    <div>
                                        <span id="edit" onClick={(e) => clickHandler(e)}>
                                            <FontAwesomeIcon icon={faEdit} />  Edit
                                        </span>
                                    </div>
                                    <div>
                                        <span id="resetpswd" onClick={(e) => clickHandler(e)}>
                                            <FontAwesomeIcon icon={faEdit} />  Reset Password
                                        </span>
                                    </div>
                                </>
                            )
                        }
                    })()}
                    <div>
                        {
                            rowData.status === "Active" || rowData.status === "Reactivated" ?
                                <span id="deactivate" onClick={(e) => clickHandler(e)}>
                                    <FontAwesomeIcon icon={faBan} /> Deactivate
                                </span>
                                :
                                <span id="reactivate" onClick={(e) => clickHandler(e)}>
                                    <FontAwesomeIcon icon={faDotCircle} /> Reactivate
                                </span>
                        }
                    </div>
                    <div>
                        <span id="delete" onClick={(e) => clickHandler(e)}>
                            <FontAwesomeIcon icon={faTrash} />  Delete
                        </span>
                    </div>
                    {(() => {
                        if (rowData.status === "Deactivated" || rowData.status === "Reactivated") {
                            return (
                                    <div>
                                        <span id="viewHistory" onClick={(e) => clickHandler(e)}>
                                            <FontAwesomeIcon icon={faEye} />  View History
                                        </span>
                                    </div>
                            )
                        }
                    })()}
                </div>
            </div>
        </div>
    );
};

export default ServiceCenterUsersAction;
