import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";

const OnHoldVP = ({ closeModal, data, updatedData, close }) => {
  const [formValue, setFormValue] = useState({
    onHoldReason: "",
    description: "",
  });

  const editDetails = {
    reason: formValue.onHoldReason,
    ...(formValue.onHoldReason === "Other" && {
      reasonDescription: formValue.description,
    }),
    status: "On Hold By Management",
    requestID: data.requestID,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.put(`management/api/v1/vehiclenewrequest/status`, editDetails)
    );
    const { success, message } = resp;

    if (success) {
      closeModal(false);
      refresh();
      close(false);
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };


  console.log("formValue-->",formValue)




  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Hold Vehicle MRP Request</h3>
        </div>
        <div className="body">
        <label style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "170px",display:"flex" }}>On Hold Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="onHoldReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{marginTop: '-5px'}}
            >
              <option disabled selected hidden>
                On Hold Reason
              </option>
              <option value="Misconduct">Misconduct</option>
              <option value="Not an Active User">Not an Active User</option>
              <option value="Change of Role">Change of Role</option>
              <option value="Others">Others</option>
            </select>
          </label>

          {formValue.rejectReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="On Hold Reason*"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button 
          id="submit-button" 
          onClick={submitHandler}
          disabled={formValue.onHoldReason == "" ? true : false}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnHoldVP;
