import React, { useEffect, useState } from "react";
import {
  faDownload,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import { trackPromise } from "react-promise-tracker";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import API from "../../../utils/api";
import auth from "../../../utils/auth";
import handleExport from "../../../utils/exportExcel";
import { errorNotification } from "../../../utils/notifications";
import TaxInvoiceView from "../../Invoice/TaxInvoiceView";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ShowroomSalesReport = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [formValue, setFormValue] = useState({
    vehicleCategory: "",
    vehicleSkuCode: "",
    vehicleModel: "",
    vehicleVariant: "",
    invoiceNo: "",
    fromDate: "",
    toDate: "",
  });

  const currentDate = new Date();
  //first Day of the month
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const year = startDate.getFullYear();
  const month = String(startDate.getMonth() + 1).padStart(2, '0');
  const day = String(startDate.getDate()).padStart(2, '0');
  const formattedStartDate = `${year}-${month}-${day}`;

  //Last day of the month
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const year2 = endDate.getFullYear();
  const month2 = String(endDate.getMonth() + 1).padStart(2, '0');
  const day2 = String(endDate.getDate()).padStart(2, '0');
  const formattedEndDate = `${year2}-${month2}-${day2}`;


  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `showroom-module/api/v1/customerAndSales/getAllVehicleSales?vehicleCategory=${formValue.vehicleCategory}&vehicleModel=${formValue.vehicleModel}&vehicleVariant=${formValue.vehicleVariant}&vehicleSkuCode=${formValue.vehicleSkuCode}&invoiceNo=${formValue.invoiceNo}&fromDate=${formValue.fromDate ? formValue.fromDate : formattedStartDate}&toDate=${formValue.toDate ? formValue.toDate : formattedEndDate}`
      )
    );
    console.log(resp);
    if (resp.success == false) {
      return errorNotification(resp.message);
    } else {
      const filterData = resp.filter((item) => {
        return item.invoiceAmount !== undefined && item.invoiceAmount !== null;
      });
      setUsersData(filterData);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  let dataToExport = UsersData.map((item) => {
    return {
      "Vehicle SKU Code": item.vehicleSkuCode,
      "Vehicle Segment": item.vehicleSegment,
      "Vehicle Category": item.vehicleCategory,
      "Vehicle Model": item.vehicleModel,
      "Vehicle Variant": item.vehicleVariant,
      "Vehicle Color": item.vehicleColor,
      "Invoice No.": item.invoiceNo,
      "Invoice Date": moment(item.invoiceDate).format("DD-MM-YYYY"),
      "Invoice Amount": item.invoiceAmount.toLocaleString("en-IN"),
    };
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const [invoiceStartingDate, setInvoiceStartingDate] = useState(null);
  const [invoiceEndingDate, setInvoiceEndingDate] = useState(null);
  const onInvoiceChangeDate = (dates) => {
    const [start, end] = dates;
    setInvoiceStartingDate(start);
    setInvoiceEndingDate(end);
    const data = { ...formValue, fromDate: start, toDate: end };
    setFormValue(data);
  };

  const clearFilter = async (e) => {
    setFormValue({
      vehicleCategory: "",
      vehicleSkuCode: "",
      vehicleModel: "",
      vehicleVariant: "",
      invoiceNo: "",
      fromDate: "",
      toDate: "",
    });
    setInvoiceStartingDate(null);
    setInvoiceEndingDate(null);

    const resp = await trackPromise(
      API.get(`showroom-module/api/v1/customerAndSales/getAllVehicleSales?fromDate=${formattedStartDate}&toDate=${formattedEndDate}`)
    );
    if (resp.success == false) {
      return errorNotification(resp.message);
    } else {
      const filterData = resp.filter((item) => {
        return item.invoiceAmount !== undefined && item.invoiceAmount !== null;
      });
      setUsersData(filterData);
    }
  };
  const handleClick = (e) => {
    setShow(!show);
  };

  const uniqueinvoiceNo = [...new Set(UsersData.map((item) => item.invoiceNo))];
  const uniquevehicleCategory = [
    ...new Set(UsersData.map((item) => item.vehicleCategory)),
  ];
  const uniquevehicleModel = [
    ...new Set(UsersData.map((item) => item.vehicleModel)),
  ];
  const uniquevehicleVariant = [
    ...new Set(UsersData.map((item) => item.vehicleVariant)),
  ];
  const uniquevehicleSkuCode = [
    ...new Set(UsersData.map((item) => item.vehicleSkuCode)),
  ];

  const sumInvoiceAmounts = (data) => {
    return data.reduce((sum, item) => {
      return sum + (item.invoiceAmount ? parseFloat(item.invoiceAmount) : 0);
    }, 0);
  };

  const totalInvoiceAmount = sumInvoiceAmounts(UsersData);
  const formattedTotalInvoiceAmount =
    totalInvoiceAmount.toLocaleString("en-IN");

  const [actionShow, setActionShow] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const invoiceHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setViewSpecs(true);
  };

  const invoiceFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => invoiceHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          {cell}
        </button>
        {actionShow === rowIndex && viewSpecs ? (
          <TaxInvoiceView rowData={row} closeModal={setViewSpecs} />
        ) : null}
      </div>
    );
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Vehicle SKU",
      dataField: "vehicleSkuCode",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Segment",
      dataField: "vehicleSegment",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Category",
      dataField: "vehicleCategory",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Model",
      dataField: "vehicleModel",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Variant",
      dataField: "vehicleVariant",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Color",
      dataField: "vehicleColor",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Invoice No.",
      dataField: "invoiceNo",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { viewSpecs, actionShow },
      formatter: invoiceFormatter
    },
    {
      text: "Invoice Date",
      dataField: "invoiceDate",
      headerAttrs: { width: "180px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row) => {
        if (!cell) {
          return "-";
        } else {
          return moment(cell).format("DD-MMM-YYYY/HH:mm");
        }
      },
    },
    {
      text: `Invoice Amount (${auth.getUserInfo().currencySymbol})`,
      dataField: "invoiceAmount",
      headerAttrs: { width: "180px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row) => {
        if (!cell) {
          return "-";
        } else {
          return cell.toLocaleString("en-IN");
        }
      },
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Sales Report</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(dataToExport, "salesReport")}
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <>
                    <div
                      id="abc"
                      className="RA_block"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        height: "125px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="bomfilter_fields"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "23%",
                          }}
                        >
                          <label style={{ fontSize: "13px", fontWeight: "500" }}>
                            SKU Code
                          </label>
                          <select
                            name="vehicleSkuCode"
                            value={formValue.vehicleSkuCode}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" selected>
                              SKU Code
                            </option>
                            {uniquevehicleSkuCode.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "23%",
                          }}
                        >
                          <label style={{ fontSize: "13px", fontWeight: "500" }}>
                            Category
                          </label>
                          <select
                            name="vehicleCategory"
                            value={formValue.vehicleCategory}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Category
                            </option>
                            {uniquevehicleCategory.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "23%",
                          }}
                        >
                          <label style={{ fontSize: "13px", fontWeight: "500" }}>
                            Invoice No.
                          </label>
                          <select
                            name="invoiceNo"
                            value={formValue.invoiceNo}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Invoice No.
                            </option>
                            {uniqueinvoiceNo.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "10px"
                          }}
                        >
                          <label style={{ fontSize: "13px", fontWeight: "500" }}>
                            Invoice Date
                          </label>
                          <DatePicker
                            className="daterangeReportsDealer"
                            placeholderText="Invoice Date"
                            selectsRange={true}
                            startDate={invoiceStartingDate}
                            endDate={invoiceEndingDate}
                            onChange={onInvoiceChangeDate}
                            dateFormat="dd-MM-yyyy"
                          >
                          </DatePicker>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "23%",
                            marginRight: "20px"
                          }}
                        >
                          <label style={{ fontSize: "13px", fontWeight: "500" }}>
                            Model
                          </label>
                          <select
                            name="vehicleModel"
                            value={formValue.vehicleModel}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Model
                            </option>
                            {uniquevehicleModel.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "23%",
                            marginRight: "31%"
                          }}
                        >
                          <label style={{ fontSize: "13px", fontWeight: "500" }}>
                            Variant
                          </label>
                          <select
                            name="vehicleVariant"
                            value={formValue.vehicleVariant}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Variant
                            </option>
                            {uniquevehicleVariant.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>

                        <div
                          style={{
                            height: "35px",
                            backgroundColor: "#F7F7F7",
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "2%",
                            width: "21%"
                          }}
                        >
                          <button
                            onClick={fetchReport}
                            id="apply"
                            className="btn"
                          >
                            Apply
                          </button>
                          <button
                            onClick={clearFilter}
                            id="clear"
                            className="btn"
                            style={{ width: "50%" }}
                          >
                            Clear Filter
                          </button>
                        </div>
                      </div>

                    </div>
                  </>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  width: "30%",
                  justifyContent: "space-between",
                  background: "#F3F3F3",
                  marginTop: "10px"
                }}
              >
                <h6 style={{ margin: "5px", color: "rgb(54, 54, 54)" }}>
                  Total Units : <span style={{ fontSize: "18px", fontWeight: "600" }}>{UsersData?.length}</span>
                </h6>
                <h6 style={{ margin: "5px", color: "rgb(54, 54, 54)" }}>
                  {`Total Value (${auth.getUserInfo().currencySymbol})`} : <span style={{ fontSize: "18px", fontWeight: "600" }}>{formattedTotalInvoiceAmount}</span>
                </h6>
              </div>
              <div style={{ marginTop: "10px" }}>

                <BootstrapTable
                  wrapperClasses="table-responsive"
                  // rowClasses="text-nowrap"
                  rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                  pagination={paginationFactory({
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    hideSizePerPage: true,
                    paginationSize: 2,
                    sizePerPage: "10",
                  })}
                  noDataIndication="No Data Found"
                  {...props.baseProps}
                />
              </div>
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

export default ShowroomSalesReport;
