import React, { useState } from 'react'
import AppConstants from '../../../../utils/app-constants';
import ViewAssemblyBom from './viewassembly-bom';
import ViewComponentsBom from './viewcomponents-bom';
import ViewComponentsBattery from "./viewcomponents-bom-battery";
import ViewLabourBom from './viewlabour-bom';
import moment from 'moment'
import auth from '../../../../utils/auth';


const BomViewandUpdate = ({ close, rowdata }) => {
 const [viewComp, setViewComp] = useState();
 const [viewLbr, setViewLbr] = useState();
 const [viewAssm, setViewAssm] = useState();
 const [viewBatt, setViewBatt] = useState();    


 const calculateProdQty = (data)=>{
  let totalQty =0 
  data?.map((item)=>{
   if(item?.primary){
     totalQty+= Number(item?.componentQuantity ) || 0
   }
  })
  return totalQty
 }

 return (
   <>
     {viewComp && <ViewComponentsBom location={rowdata?.bomLocation} compdata={rowdata?.bomComponents} close={setViewComp} />}
     {viewLbr && <ViewLabourBom labourdata={rowdata?.bomLabor} close={setViewLbr} />}
     {viewAssm && <ViewAssemblyBom assemblydata={rowdata?.bomAssembly} close={setViewAssm} />}
     {viewBatt && (
        <ViewComponentsBattery
          location={rowdata?.bomLocation}
          compdata={rowdata?.bomComponents}
          close={setViewBatt}
        />
      )}
     <div className="bussiness">
       <h4 className="heading">BOM</h4>
       <button
         type="button"
         className="btn btn-md"
         style={{ float: "right", marginTop: "-49px" }}
       >
         <div
           style={{ display: "flex", marginRight: "-18px", height: "26px" }}
         >
           <div className="filter-refresh" onClick={() => close(false)}>
             {/* <FontAwesomeIcon icon={faRedoAlt} /> */}
             Back
           </div>
         </div>
       </button>
     </div>

     <div
       style={{
         width: "100%",
         display: "flex",
         flexDirection: "column",
         fontSize: "16px",
         fontWeight: "500",
       }}
     >
       <div style={{ display: "flex", justifyContent: "space-between" }}>
         <span>Request ID:{rowdata?.BOMID}</span>
         <span>Requested By:{rowdata?.bomApprovalReqBy}</span>
       </div>
       <div style={{ display: "flex", justifyContent: "space-between" }}>
         <span>Request Type:{rowdata?.bomTypes}</span>
         <span>Request Date:{moment(rowdata?.bomApprovalReqDate).format("DD-MMM-YYYY,HH:mm")}</span>
       </div>
     </div>
     {rowdata?.status == AppConstants.HOLD_MANAGEMENT ?
       <div className="onhold_div">
         <span className="holdDate">Hold Date: {moment(rowdata?.actiondate).format("DD-MMM-YYYY,HH:mm")} </span>
         <span style={{ fontSize: "25px", color: "#CDCDCD" }}>|</span>
         <span className="holdReason" style={{ right: "150px" }}>Hold Reason:{rowdata?.reason == "Other" ? rowdata?.reasonDescription : rowdata?.reason}</span>
       </div> :
       rowdata?.status == AppConstants.APPROVEDBYMANAGEMENT ?
         <div className="approve_div">
           <span className="approveDate">Approved By Management Date: {moment(rowdata?.approveDate).format("DD-MMM-YYYY,HH:mm")} </span>
         </div> :
         rowdata?.status == AppConstants.REJECTED ?
           <div className="rejected_div">
             <span className="rejectedDate">Rejected Date: {moment(rowdata?.rejectDate).format("DD-MMM-YYYY,HH:mm")} </span>
             <span style={{ fontSize: "25px", color: "#CDCDCD" }}>|</span>
             <span className="rejectedReason" style={{ right: "150px" }}>Rejected Reason:{rowdata?.reason == "Other" ? rowdata?.reasonDescription : rowdata?.reason}</span>
           </div> : null
     }
     <br />
     <div>
       <h6>Vehicles Details</h6>
       <div className="shopfloortbl">
         <table className="sfaction_tbl" style={{ width: "100%" }} >
           <thead>
             <tr>
               <th>Segment</th>
               <th>Category</th>
               <th>Model</th>
               <th>Variant</th>
               <th>Type</th>
             </tr>
           </thead>
           {/* <hr className='line'/> */}
           <tbody>
             <tr>
               <td>{rowdata?.vehicleSegment}</td>
               <td>{rowdata?.vehicleCategory}</td>
               <td>{rowdata?.vehicleModel}</td>
               <td>{rowdata?.vehicleVariant}</td>
               <td>{rowdata?.vehicleType}</td>
             </tr>
           </tbody>
         </table>
       </div>
       <br />

       <h6>Bom Details</h6>
       <div className="shopfloortbl">
         <div
           style={{
             width: "100%",
             display: "flex",
             flexDirection: "column",
             fontSize: "16px",
             fontWeight: "500",
             padding: "14px"
           }}
         >
           <div style={{ display: "flex", justifyContent: "space-between" }}>
             <span>BOM ID: {rowdata?.BOMID}</span>
             <span>BOM Location: {rowdata?.bomLocation}</span>
           </div>
           <div style={{ display: "flex", justifyContent: "space-between" }}>
             <span>BOM Name: {rowdata?.bomName}</span>
             {/* <span>Production Qty:{calculateProdQty(rowdata?.bomComponents)}</span> */}
             <span>BOM Qty:{calculateProdQty(rowdata?.bomComponents)}</span>
           </div>
         </div>
         <table className="sfaction_tbl" style={{ width: "100%" }} >
           <thead>
             <tr>
               <th>Total Component Details</th>
               <th>Total Labour Details</th>
               <th>Total Assembly Details</th>
               <th>Total Battery Details</th>
               <th>{`Total Cost(${auth.getUserInfo().currencySymbol})`}</th>
             </tr>
           </thead>
           <tbody>
             <tr>
               <td>
                 <button
                   onClick={() => setViewComp(true)}
                   className="action-btn"
                   style={{ color: "blue" }}>
                   View Components
                 </button>
               </td>
               <td>
                 <button
                   onClick={() => setViewLbr(true)}
                   className="action-btn"
                   style={{ color: "blue" }}>
                   View Labours
                 </button>
               </td>
               <td>
                 <button
                   onClick={() => setViewAssm(true)}
                   className="action-btn"
                   style={{ color: "blue" }}>
                   View Assembly
                 </button>
               </td>
               <td>
                  <button
                    onClick={() => setViewBatt(true)}
                    className="action-btn"
                    style={{ color: "blue" }}
                  >
                    View Battery
                  </button>
                </td>
               <td>{rowdata?.totalBomCost}</td>
             </tr>
           </tbody>
         </table>
       </div>

       <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>

       </div>
     </div>
   </>
 )
}

export default BomViewandUpdate