import React from "react";
import {Tabs} from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from '../../utils/auth'
// import Dealers from "./dealers";
// import Inventory from "./inventory/inventory";
// import Orders from "./orders/orders";
// import Returns from "./returns/returns";
// import Sales from "./sales/sales";
// import ServiceCenters from "./servicecenters";
// import Showrooms from "./showrooms";

const Dealers = React.lazy(()=>import("./dealers"))
const Inventory = React.lazy(()=>import("./inventory/inventory"))
const Orders = React.lazy(()=>import("./orders/orders"))
const Returns = React.lazy(()=>import("./returns/returns"))
const Sales = React.lazy(()=>import("./sales/sales"))
const ServiceCenters = React.lazy(()=>import("./servicecenters"))
const Showrooms = React.lazy(()=>import("./showrooms"))


const BusinessInsights=()=>{
    const tabs = [
        {
          id:"MGM5T1",
          label: TabLabels.DEALERS,
          Component: Dealers
        },
        {
          id:"MGM5T2",
          label: TabLabels.SHOWROOMS,
          Component: Showrooms
        },
        {
          id:"MGM5T3",
          label: TabLabels.SERVICECENTERS,
          Component: ServiceCenters
        },
        {
          id:"MGM5T4",
          label: TabLabels.ORDERS,
          Component: Orders
        },
        {
          id:"MGM5T5",
          label: TabLabels.INVENTORY,
          Component: Inventory
        },
        {
          id:"MGM5T6",
          label: TabLabels.RETURNS,
          Component: Returns
        },
        {
          id:"MGM5T7",
          label: TabLabels.SALES,
          Component: Sales
        },
    ];

    const getTabs = (tabs) => {
      let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
      return tabs.filter(tab => tabIds.includes(tab.id));
    };

    let tabsToShow = getTabs(tabs);
    return (
      <div className="app-layout__card">
        <Tabs tabs={tabsToShow} type="pills" />
      </div>
    );
}
export default BusinessInsights