import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";

const SpareViewSpecs = ({ rowData, closeModal }) => {
  console.log(rowData)
  return (
    <div>
      <div className="container1">
        <div className="modalContainer" style={{ height: "350px" }}>
          <div className="header">
            <h3> Spare Part Specifications</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <h6>Spare Part Name: {rowData?.sparePartName}</h6>
            <h6>Spare Part Sku code: {rowData?.skuCode}</h6>
          </div>
          <div className="body" style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap" }}>
            <ul type="none" className="viewspeclist" style={{ paddingLeft: "0px", textAlign: "left", fontSize: "14px" }}>
              {rowData?.specifications?.map((item) => {
                return (
                  <li>{`Spare Part ${item?.specsType} : ${item?.specsValue}${item?.specsUnit[0]}`}</li>
                )
              })}
            </ul>
          </div>
          <div className="footer">
            <button onClick={() => closeModal(false)}>
              <KeyboardBackspace style={{ fontSize: "25px" }} />
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpareViewSpecs;
