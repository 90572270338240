import React, { useState } from "react";
import {
  KeyboardBackspace,
} from "@material-ui/icons";
import FullAssignment from "../../inventory-inv/action/FullAssignment";
import RequestUpdate from "./requestUpdate";
import VR_MovedToFinishedGoods from "./VR_MovedToFinishedGoods";
import VR_SendToProduction from "./VR_SendToProduction";
import moment from "moment";
import RequestToStartVerification from "./requestToStartVerification";

const VehicleViewAct = ({ closeModal, rowData }) => {
  const [openFA, setOpenFA] = useState(false);
  const [openReqUpdate, setOpenReqUpdate] = useState(false);

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1050px", height: "475px", padding: '15px' }}
      >
        <div className="header">
          <h3>View Details</h3>
        </div>

        <div
          className="body"
          style={{ width: '100%', display: "flex", fontSize: "14px", marginTop: "-36px" }}
        >

          <section
            style={{
              width: '100%',
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0"
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested ID: {rowData.requestID}</span>
              <span>GIN : {rowData.ginNo}<span style={{ color: "#0a18a3" }}></span></span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested Category: {rowData.requestCategory}</span>
              <span>GIN Date : {moment(rowData.ginDate).format("DD-MMM-YYYY/HH:mm")}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Request Type: {rowData.requestType}</span>
              <span>GIN Qty : {rowData?.vehicles?.length}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested By: {rowData.requestedBy}<span style={{ color: "#0a18a3" }}></span></span>
            </div>



          </section>
          {rowData.status == "On Hold" ?
            <div id="hold" style={{ display: 'flex', width: "100%", height: "38px" }}>
              <span id="holdDate">Hold Date: {rowData.onHoldDate}</span>
              <view id="line"></view>
              <span id="holdReason">Hold Reason: {rowData.holdReason}</span>
            </div> : rowData.status == "Failed" ?
              <div id="rejected" style={{ display: 'flex', width: "100%", height: "38px" }}>
                <span id="reject">Rejected Date: {rowData.rejectDate}</span>
                <view id="line"></view>
                <span id="rejectedReason">Rejected Reason: {rowData.failureReason}</span>
              </div> : ""}
          <section style={{ width: '100%' }}>
            <div id='POVendorHeading'>
              <div style={{ width: '6%' }}>S.No.</div>
              <div style={{ width: '12%' }}>Category</div>
              <div style={{ width: '12%' }}>Model</div>
              <div style={{ width: '12%' }}>Variant</div>
              <div style={{ width: '12%' }}>Type</div>
              <div style={{ width: '12%' }}>Color</div>
              <div style={{ width: '12%' }}>VIN</div>
              <div style={{ width: '12%' }}>BIN</div>
              <div style={{ width: '10%' }}>Status</div>
            </div>
            <div>
              {rowData.vehicles.map((item, index) => (
                <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }}>
                  <div style={{ width: '6%' }}>{index + 1}</div>
                  <div style={{ width: '12%' }}>{item.vehicleCategory}</div>
                  <div style={{ width: '12%' }}>{item.vehicleModel}</div>
                  <div style={{ width: '12%' }}>{item.vehicleVariant}</div>
                  <div style={{ width: '12%' }}>{item.vehicleType}</div>
                  <div style={{ width: '12%' }}>{item.vehicleColor}</div>
                  <div style={{ width: '12%' }}>{item.VIN}</div>
                  <div style={{ width: '12%' }}>{item.BIN}</div>
                  <div className="deactive-btn" style={{ width: '10%', height: "20px", marginBottom: "5px" }}><span className="deactive-btn-font">Pending</span></div>
                </div>
              ))}
            </div>
          </section>

        </div>

        <div className="footer">
          <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
          {rowData.status == "On Hold" || rowData.status == "Failed" ?
            <button
              style={{
                background: "#0A18A3",
                border: "1px solid #0A18A3",
                borderRadius: "3px",
                padding: "5px 15px",
                color: "white",
                width: '200px'
              }}
              onClick={() => setOpenReqUpdate(true)}
            >
              Request For Update
            </button>
            :
            <button
              style={{
                background: "#0A18A3",
                border: "1px solid #0A18A3",
                borderRadius: "3px",
                padding: "5px 15px",
                color: "white",
                width: '200px'
              }}
              onClick={() => setOpenFA(true)}
            >
              Request to Start
            </button>
          }
          {openReqUpdate && <RequestUpdate closeModal={setOpenReqUpdate} rowData={rowData} />}
          {openFA && <RequestToStartVerification closeModal={setOpenFA} rowData={rowData} status={rowData.status} />}
        </div>
      </div>
    </div>
  );
};

export default VehicleViewAct;