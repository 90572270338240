import { TabTwoTone } from "@material-ui/icons";
import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import AddVehicles from "./add-vehicles";
// import ManageVehicles from "./manage-vehicles";
// import ProdManageVehicles from "./ProcManageVehicles";
// import VehicleMaster from "./vehicleMaster";

const AddVehicles = React.lazy(()=>import("./add-vehicles"))
const ManageVehicles = React.lazy(()=>import("./manage-vehicles"))
const ProdManageVehicles = React.lazy(()=>import("./ProcManageVehicles"))
const VehicleMaster = React.lazy(()=>import("./vehicleMaster"))


const Vehicles = () => {

  const tabs1 = [
    {
      id: "PDM1T1",
      label: TabLabels.VEHICLE,
      Component: ProdManageVehicles
    },
  ];

  const tabs2 = [
    {
      label: TabLabels.VEHICLE_MASTER,
      Component: VehicleMaster
    },
    {
      label: TabLabels.ADD_VEHICLES,
      Component: AddVehicles
    },
    {
      label: TabLabels.MANAGE_VEHICLES,
      Component: ManageVehicles
    },
  ];

  const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
    return tabs.filter(tab => tabIds.includes(tab.id));
  };

  let tabsToShow = getTabs(tabs1);
  return (
    <div className="app-layout__card">
      {auth.isProduction() ? <Tabs tabs={tabsToShow} type="pills" /> : <Tabs tabs={tabs2} type="pills" />}
    </div>
  )
}
export default Vehicles
