import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import AddSpecs from "./AddSpecs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import "../../../components/modal/modal.scss";

const EditComponents = ({ updatedData, editData, closeModal, setIsOpen}) => {
  const [openSpecs, setOpenSpecs] = useState(false);
  const [formValue, setFormValue] = useState({
    category: editData.category,
    subCategory: editData.subCategory,
    skuCode: editData.skuCode,
    componentName: editData.componentName,
    specifications: editData.specifications,
  });

  const validateInputs = () => {

      if (
        !formValue.category.trim() ||
        !formValue.subCategory.trim() ||
        !formValue.skuCode.trim() ||
        !formValue.componentName.trim() ||
        formValue.specifications.length == 0 
      ) {
        errorNotification("All fields are required.");
        return false;
      }
  
      if (
        /^[0-9]+$/.test(formValue.category.trim()) ||
        /^[0-9]+$/.test(formValue.subCategory.trim()) ||
        /^[0-9]+$/.test(formValue.componentName.trim())
      ) {
        errorNotification("Only numeric values are not allowed.");
        return false;
      
    }
    return true;
  };

  const [UnitOfMeasure ,setUnitOfMeasure] = useState({})

  useEffect(()=>{
    setUnitOfMeasure(editData.specifications.map((item)=>(item.specsUnit)))
  },[editData])

  console.log("editData--->",editData.specifications.map((item)=>(item.specsUnit)))


  const specsStyle = {
    border: "none",
    margin: "10px 10px 10px 0px",
    width: "240px",
    height: "50px",
    background: "#ebebeb",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop:"-5px"
  };

  const submitData = (index, data) => {
    formValue.specifications = data;
  };

  const editDetails = {
    componentID: editData.componentID,

    newSkuCode: formValue.skuCode,

    category: formValue.category,

    subCategory: formValue.subCategory,

    componentName: formValue.componentName,

    specifications: formValue.specifications,
  };

  const refresh = () => {
    updatedData();
  };


  console.log("editDetails-->",editDetails)




  const submitHandler = async () => {
    if (!validateInputs()) {
      return;
    }
    console.log(editDetails);
    const resp = await trackPromise(
      API.put(`procurement-module/api/v1/components`, editDetails)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      updatedData();
      setIsOpen(false)
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  // let uniqueCategoryArray = [...new Map(UsersData.map((item) => [item["category"], item])).values()];
console.log(formValue)



const closeHandler = ()=>{
  setIsOpen(false)
  closeModal(false)
}





  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "860px", height: "auto" }}
      >
        <div className="header">
          <h3>Edit Component Details</h3>
        </div>

        <div className="body">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              margin: "12px 0px",
              justifyContent: "start",
            }}
          >
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  textAlign:"start"
                }}
              >
                Category
              </label>
              <input
              disabled
                defaultValue={editData.category}
                name="category"
                style={{marginTop:'-5px', width:'auto'}}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  textAlign:"start"
                }}
              >
                Sub Category
              </label>
              <input
              disabled
                type="text"
                name="subCategory"
                defaultValue={editData.subCategory}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Sub Category"
                autoComplete="off"
                style={{marginTop:'-5px', width:'auto'}}
              />
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  textAlign:"start"
                }}
              >
                SKU Code<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="skuCode"
                defaultValue={editData.skuCode}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="SKU Code"
                autoComplete="off"
                style={{marginTop:'-5px', width:'auto'}}
              />
            </div>
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  textAlign:"start"
                }}
              >
                Component Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="componentName"
                defaultValue={editData.componentName}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Component Name"
                autoComplete="off"
                style={{marginTop:'-5px', width:'auto'}}
              />
            </div>
          </div>
          <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  textAlign:"start"
                }}
              >
                Add Specifications<span style={{ color: "red" }}>*</span>
              </label>

          <div style={specsStyle} name="specifications">
            <span>Add Specifications</span>
            <button
              type="button"
              style={{
                color: "blue",
                borderRadius: "100%",
                border: "2px solid blue",
              }}
              onClick={() => setOpenSpecs(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
            {openSpecs && (
              <AddSpecs
                addSpecsData={editData.specifications}
                handler={submitData}
                closeModal={setOpenSpecs}
                UnitOfMeasure={UnitOfMeasure}
              />
            )}
          </div>
          </div>
         
        </div>
        <div className="footer">
          <button onClick={closeHandler}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditComponents;
