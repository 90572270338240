import React, { useState } from "react";
import AppConstants from "../../../utils/app-constants";
import "../../settings-tabs/homesettings.scss";
import "../approval.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ApproveSPP from "./approveSPP";
import OnHoldSPP from "./onholdSPP";
import RejectSPP from "./rejectSPP";
import { KeyboardBackspace } from "@material-ui/icons";
import PrintIcon from "@mui/icons-material/Print";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import auth from "../../../utils/auth";

const SppViewandUpdatePending = ({ close, rowdata, updatedData }) => {
  console.log(rowdata);
  let data = rowdata.spareDetails;
  console.log(data);

  const [formValue, setFormValue] = useState([]);
  const [approvePopup, setApprovePopup] = useState();
  const [rejectPopup, setRejectPopup] = useState();
  const [onholdPopup, setOnholdPopup] = useState();

  const statusFormatter = (cell) => {
    return cell === AppConstants.PENDING ? (
      <button className="delete-btn">
        <span className="delete-btn-font">Pending</span>
      </button>
    ) : cell === AppConstants.REJECTED ? (
      <button className="reactive-btn">
        <span className="reactive-btn-font">Rejected</span>
      </button>
    ) : (
      cell
    );
  };

  const checkboxAllFormatter = (e) => {
    if (e.target.checked) {
      const el = document.querySelectorAll(".selector");
      el.forEach((item) => (item.checked = true));
      setFormValue(data);
    } else {
      const el = document.querySelectorAll(".selector");
      el.forEach((item) => (item.checked = false));
      setFormValue([]);
    }
  };

  const checkBoxHandler = (e, row, rowIndex) => {
    if (e.target.checked) {
      console.log(row);
      console.log(rowIndex);
      const field = [...formValue];
      field.push(row);
      setFormValue(field);
    } else {
      const field = [...formValue];
      const finalData = field.filter((item) => {
        return item.skuCode != row.skuCode;
      });
      console.log(finalData);
      setFormValue(finalData);
    }
  };

  console.log(formValue);

  const checkBoxFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row);
    return (
      <>
        <input
          type="checkbox"
          className="selector"
          style={{ width: "18px" }}
          onClick={(e) => checkBoxHandler(e, row, rowIndex)}
        ></input>
      </>
    );
  };

  const printbtn = () => {
    console.log(formValue);
  };

  const columns = [
    {
      text: (
        <input
          style={{ width: "18px" }}
          onClick={(e) => {
            checkboxAllFormatter(e);
          }}
          type="checkbox"
        ></input>
      ),
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatExtraData: { formValue, setFormValue },
      formatter: checkBoxFormatter,
    },
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Component Name",
      dataField: "sparePartName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "180px" },
    },
    {
      text: "SKU Code",
      dataField: "skuCode",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: `Unit Price (${auth.getUserInfo().currencySymbol})`,
      dataField: "unitPrice",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "120px" },
    },
    {
      text: "Margin(%)",
      dataField: "manufactureMargin",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "120px" },
    },
    {
      text: `Other Costs (${auth.getUserInfo().currencySymbol})`,
      dataField: "otherCharges",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: `Price to Dealer/Service Center (${auth.getUserInfo().currencySymbol
        })`,
      dataField: "priceToserviceCenter",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "260px" },
    },
    {
      text: "Dealer Margin(%)",
      dataField: "serviceCenterMargin",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: `MRP (${auth.getUserInfo().currencySymbol})`,
      dataField: "sparePartMrp",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "100px" }
    },
    {
      text: "Status",
      dataField: "status",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: statusFormatter,
    },
  ];

  return (
    <>
      {approvePopup && (
        <ApproveSPP
          close={close}
          updatedData={updatedData}
          closeModal={setApprovePopup}
          selectedrows={formValue}
          requestCategory={rowdata.requestCategory}
          requestID={rowdata.requestID}
        />
      )}

      {rejectPopup && (
        <RejectSPP
          close={close}
          updatedData={updatedData}
          closeModal={setRejectPopup}
          selectedrows={formValue}
          requestCategory={rowdata.requestCategory}
          requestID={rowdata.requestID}
        />
      )}

      {onholdPopup && (
        <OnHoldSPP
          close={close}
          updatedData={updatedData}
          closeModal={setOnholdPopup}
          selectedrows={formValue}
          requestCategory={rowdata.requestCategory}
          requestID={rowdata.requestID}
        />
      )}

      <div className="bussiness">
        <h4 className="heading">New Requests</h4>
        <button
          type="button"
          className="btn btn-md"
          style={{ float: "right", marginTop: "-49px" }}
        >
          <div
            style={{ display: "flex", marginRight: "-18px", height: "26px" }}
          >
            <div className="filter-refresh" onClick={() => close(false)}>
              <KeyboardBackspace />
              &nbsp; Back
            </div>
          </div>
        </button>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request ID:&nbsp;{rowdata.requestID}</span>
          <span>Requested By:&nbsp;{rowdata.requestedBy}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request Type:&nbsp;{rowdata.requestType}</span>
          <span>
            Request Date: &nbsp;{" "}
            {moment(rowdata.requestDate).format("DD-MMM-YYYY, HH:mm")}
          </span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request Category: &nbsp;{rowdata.requestCategory}</span>
        </div>
      </div>
      {rowdata.status == AppConstants.ONHOLD ? (
        <div className="onhold_div">
          <span className="holdDate">
            Hold Date:&nbsp; {moment().format("DD-MMM-YYYY, HH:mm")}
          </span>
          <span style={{ fontSize: "25px", color: "#CDCDCD" }}>|</span>
          <span className="holdReason" style={{ right: "150px" }}>
            Hold Reason: &nbsp; { }
          </span>
        </div>
      ) : null}

      <br />

      <div>
        <ToolkitProvider
          keyField="id"
          // data={UsersData}
          data={data}
          columns={columns}
          exportCSV
        >
          {(props) => (
            <div className="bootstraptbl">
              <BootstrapTable
                wrapperClasses="table-responsive"
                rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid #CDCDCD" }}
                keyField="id"
                data={data}
                columns={columns}
                // selectRow={}
                // rowEvents={rowEvents}
                // {...props.baseProps}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: 10,
                })}
                // bordered={false}
                condensed
                // filter={filterFactory()}
                filterPosition="top"
              />
              {/*    
                 <ExportCSVButton {...props.csvProps} id="exportbtn">
                   Export CSV
                 </ExportCSVButton> */}
            </div>
          )}
        </ToolkitProvider>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <div>
            {rowdata.status == AppConstants.PENDING_AT_MANAGEMENT ? (
              <button
                className="approvalfooter_btn"
                onClick={() => setOnholdPopup(true)}
              >
                <PauseCircleOutlineIcon />
                &nbsp;On Hold
              </button>
            ) : null}
          </div>
          <div>
            <button
              className="approvalfooter_btn"
              onClick={() => setRejectPopup(true)}
            >
              <CloseIcon />
              &nbsp;Reject
            </button>
            <button
              className="approvalfooter_btn"
              style={{ color: "white", backgroundColor: "#0a18a3" }}
              onClick={() => setApprovePopup(true)}
            >
              <DoneIcon />
              &nbsp;Approve
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SppViewandUpdatePending;
