import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import Group8 from "../QcDashboard/assets/Group8.svg";
import Group9 from "../QcDashboard/assets/Group9.svg";
import Group13 from "../QcDashboard/assets/Group13.svg";
import Group29 from "../QcDashboard/assets/Group29.svg";
import Group30 from "../QcDashboard/assets/Group30.svg";
import Group22 from "../QcDashboard/assets/Group22.png";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);
ChartJS.defaults.plugins.legend.display = false;

const BusinessUser = ({ prop }) => {
  const [data, setData] = useState({});

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(`dealer-module/api/v1/dashBoard/businessUser`)
    );
    setData(res);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="requestBody" style={{ justifyContent: "start" }}>
      <div
        className="shopFloorRequest"
        style={{
          height: "auto",
          width: "24%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <div style={{ width: "35%", padding: "4%" }}>
          <img
            src={Group9}
            style={{
              height: "50px",
              display: "block",
            }}
            alt="group"
          />
        </div>

        <div style={{ width: "65%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginLeft: "15px",
            }}
          >
            <div
              style={{
                fontSize: "24px",
                fontWeight: "450",
                textAlign: "start",
              }}
            >
              {data.totalBusinessUser}
            </div>
          </div>
          <div
            style={{
              color: "#808080",
              fontSize: "14px",
              textAlign: "start",
              margin: "10px 6px 0px 6px",
              borderBottom: "2px solid #F2F2F2",
            }}
          >
            Total Business User
          </div>
        </div>
      </div>
      <div
        className="shopFloorRequest"
        style={{
          height: "auto",
          width: "24%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <div style={{ width: "35%", padding: "4%" }}>
          <img
            src={Group22}
            style={{
              height: "50px",
              display: "block",
            }}
            alt="group"
          />
        </div>
        <div style={{ width: "65%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginLeft: "15px",
            }}
          >
            <div
              style={{
                fontSize: "24px",
                fontWeight: "450",
                textAlign: "start",
              }}
            >
              {data.totalShowroom}
            </div>
          </div>
          <div
            style={{
              color: "#808080",
              fontSize: "14px",
              textAlign: "start",
              margin: "10px 6px 0px 6px",
              borderBottom: "2px solid #F2F2F2",
            }}
          >
            Total Showrooms
          </div>
        </div>
      </div>
      <div
        className="shopFloorRequest"
        style={{
          height: "auto",
          width: "24%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <div style={{ width: "35%", padding: "4%" }}>
          <img
            src={Group9}
            style={{
              height: "50px",
              display: "block",
            }}
            alt="group"
          />
        </div>
        <div style={{ width: "65%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginLeft: "15px",
            }}
          >
            <div
              style={{
                fontSize: "24px",
                fontWeight: "450",
                textAlign: "start",
              }}
            >
              {data.totalServiceCenter}
            </div>
          </div>
          <div
            style={{
              color: "#808080",
              fontSize: "14px",
              textAlign: "start",
              margin: "10px 6px 0px 6px",
              borderBottom: "2px solid #F2F2F2",
            }}
          >
            Total Service Centers
          </div>
        </div>
      </div>
      <div
        className="shopFloorRequest"
        style={{
          height: "auto",
          width: "24%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <div style={{ width: "35%", padding: "4%" }}>
          <img
            src={Group8}
            style={{
              height: "50px",
              display: "block",
            }}
            alt="group"
          />
        </div>
        <div style={{ width: "65%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginLeft: "15px",
            }}
          >
            <div
              style={{
                fontSize: "24px",
                fontWeight: "450",
                textAlign: "start",
              }}
            >
              {data.totalActiveUser}
            </div>
          </div>
          <div
            style={{
              color: "#808080",
              fontSize: "14px",
              textAlign: "start",
              margin: "10px 6px 0px 6px",
              borderBottom: "2px solid #F2F2F2",
            }}
          >
            Active Users
          </div>
        </div>
      </div>
      <div
        className="shopFloorRequest"
        style={{
          height: "auto",
          width: "24%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <div style={{ width: "35%", padding: "4%" }}>
          <img
            src={Group30}
            style={{
              height: "50px",
              display: "block",
            }}
            alt="group"
          />
        </div>
        <div style={{ width: "65%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginLeft: "15px",
            }}
          >
            <div
              style={{
                fontSize: "24px",
                fontWeight: "450",
                textAlign: "start",
              }}
            >
              {data.totalDeactivateUser}
            </div>
          </div>
          <div
            style={{
              color: "#808080",
              fontSize: "14px",
              textAlign: "start",
              margin: "10px 6px 0px 6px",
              borderBottom: "2px solid #F2F2F2",
            }}
          >
            Deactivated Users
          </div>
        </div>
      </div>
      <div
        className="shopFloorRequest"
        style={{
          height: "auto",
          width: "24%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <div style={{ width: "35%", padding: "4%" }}>
          <img
            src={Group29}
            style={{
              height: "50px",
              display: "block",
            }}
            alt="group"
          />
        </div>
        <div style={{ width: "65%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginLeft: "15px",
            }}
          >
            <div
              style={{
                fontSize: "24px",
                fontWeight: "450",
                textAlign: "start",
              }}
            >
              {data.totalReactivateUser}
            </div>
          </div>
          <div
            style={{
              color: "#808080",
              fontSize: "14px",
              textAlign: "start",
              margin: "10px 6px 0px 6px",
              borderBottom: "2px solid #F2F2F2",
            }}
          >
            Re-activated Users
          </div>
        </div>
      </div>
      <div
        className="shopFloorRequest"
        style={{
          height: "auto",
          width: "24%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <div style={{ width: "35%", padding: "4%" }}>
          <img
            src={Group13}
            style={{
              height: "50px",
              display: "block",
            }}
            alt="group"
          />
        </div>
        <div style={{ width: "65%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginLeft: "15px",
            }}
          >
            <div
              style={{
                fontSize: "24px",
                fontWeight: "450",
                textAlign: "start",
              }}
            >
              {data.totalDeleteUser}
            </div>
          </div>
          <div
            style={{
              color: "#808080",
              fontSize: "14px",
              textAlign: "start",
              margin: "10px 6px 0px 6px",
              borderBottom: "2px solid #F2F2F2",
            }}
          >
            Deleted Users
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessUser;
