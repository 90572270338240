import React, { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker';
import API from '../../../utils/api';
import '../styles/main.scss'
import Group4 from '../../QcDashboard/assets/Group4.png'
import Group5 from '../../QcDashboard/assets/Group5.png'
import Group6 from '../../QcDashboard/assets/Group6.png'
import Group from '../../QcDashboard/assets/Group.png'
import Group2 from '../../QcDashboard/assets/Group2.png'
import { BsArrowUpRight } from 'react-icons/bs';

function SparesReturns() {
    const [data, setData] = useState({});

    const fetchData = async () => {
        const result = await trackPromise(API.get("finance/api/v1/spareReturn"));
        setData(result);
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <hr />
            <div style={{ display: "flex", width: "95%", marginLeft: "10px", flexDirection: "column" }}>

                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <img style={{ marginTop: "-10px" }} src={Group} />&nbsp;&nbsp;
                        <span >Total Requests</span>
                    </div>
                    <div style={{ fontSize: "18px" }}>
                        {data?.totalReqs}
                    </div>
                </div>
                <hr />
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <img style={{ marginTop: "-10px" }} src={Group4} />&nbsp;&nbsp;
                        <span>Pending at Inventory</span>
                    </div>
                    <div>
                        {data?.pendingReqs}
                    </div>
                </div>
                <hr />
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <img style={{ marginTop: "-10px" }} src={Group5} />&nbsp;&nbsp;
                        <span>QC Verification</span>
                    </div>
                    <div>
                        {data?.qcReqs}
                    </div>
                </div>
                <hr />
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <img style={{ marginTop: "-10px" }} src={Group2} />&nbsp;&nbsp;
                        <span>Verification Completed</span>
                    </div>
                    <div>
                        {data?.completedReqs}
                    </div>
                </div>
                {/* <hr />
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <img style={{ marginTop: "-10px" }} src={Group6} />&nbsp;&nbsp;
                        <span>Rejected</span>
                    </div>
                    <div>
                        582
                    </div>
                </div>
                <hr /> */}
            </div>
        </div>
    )
}

export default SparesReturns