import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Action from "../../components/modal/Action";
import "../../components/modal/modal.scss";
import auth from "../../utils/auth";
import moment from "moment";
import "../../pages/vendors/Vendors.scss";
import AppConstants from "../../utils/app-constants";
import SearchBox from "./SearchBox/SearchBox";
import handleExport from "../../utils/exportExcel";
import { useHistory } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ManageUsers = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState();
  const [filterData, setFilterData] = useState();
    const [formValue, setFormValue] = useState({
    role: "",
    name: "",
    email: "",
    status: "",
  });
  let history = useHistory();


  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `admin-module/api/v1/users?role=${formValue.role}&status=${formValue.status}&name=${formValue.name}&email=${formValue.email}&&userType=${"Super Admin"}`
      )
    );
    console.log(resp);


    //  if(auth.getUserInfo().role === "Sub Admin"){
    //   let filterData = resp.data.filter((item) => {
    //     return ( item.role != "Sub Admin");});
    //   console.log("filterData",filterData)
    //   resp.success == true
    //   ? setUsersData(filterData)
    //   : errorNotification(resp.message);
    // }


    if(auth.getUserInfo().role === "Sub Admin"){
      let filterData = resp.filter((item) => item.role != "Sub Admin")
      console.log("filterData",filterData)
      
    setUsersData(filterData.reverse());
    }else{
      let filteredUserData = resp.filter(
        (item) => item.status != "Deleted" && item.role != "Super Admin" && item.role != "System Admin"
      );
      console.log(filteredUserData);
      setUsersData(filteredUserData.reverse());
    }

    
   
  };



  useEffect(() => {
    fetchReport();
  }, []);


  const uniqueRole = [...new Set(UsersData.map((item) => item.role))];
  const uniqueRoleSub = [...new Set(UsersData
    .filter(item => item.role !== "Sub Admin") // Filter out "Sub Admin" roles
    .map(item => item.role) // Extract the role from each item
  )];
  
  const uniqueStatus = [...new Set(UsersData.map((item) => item.status))];

  console.log("Sub Admin",uniqueRoleSub)
  console.log("uniqueRole",uniqueRole)


  const statusFormatter = (cell, row, rowIndex) => {
    return cell === AppConstants.APPROVE ? (
      <button className="active-btn">
        <span className="active-btn-font">Approve</span>
      </button>
    ) : cell === AppConstants.ACTIVE ? (
      <button className="active-btn">
        <span className="active-btn-font">Active</span>
      </button>
    ) : cell === AppConstants.DEACTIVATED ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">Deactivated</span>
      </button>
    ) : cell === AppConstants.REACTIVATED ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">Reactivated</span>
      </button>
    ) : cell === AppConstants.DELETED || cell === "Delete" ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Deleted</span>
      </button>
    ) : cell === AppConstants.REJECT || cell === "Reject" ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Rejected</span>
      </button>
    ): (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.status === AppConstants.DELETED ? (
      ""
    ) : (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          <Action
            updatedData={fetchReport}
            rowData={row}
            rowIndex={rowIndex}
            setIsOpen={setIsOpen}
          />
        ) : null}
      </div>
    );
  };

  const [show, setShow] = useState(false);


  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  

  const clearFilter = async (e) => {
    setFormValue({
      name: "",
      email: "",
      role: "",
      status: "",
    });
    const resp = await trackPromise(
      API.get(
        `admin-module/api/v1/users?role=${""}&&status=${""}&&name=${""}&&email=${""}&&userType=${"Super Admin"}`
      )
    );
    console.log(resp);
    let filteredUserData = resp.filter(
      (item) => item.status != "Deleted" && item.role != "Super Admin" && item.role != "System Admin"
    );
    console.log(filteredUserData);
    setUsersData(filteredUserData.reverse());
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "User Type",
      dataField: "role",
      classes: "cellsOverflow",
      headerAttrs: { width: "170px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Name",
      dataField: "fullName",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Email ID",
      dataField: "email",
      classes: "cellsOverflow",
      headerAttrs: { width: "220px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Location",
      dataField: "city",
      classes: "cellsOverflow",
      headerAttrs: { width: "180px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell) => {
        if (!cell) {
          return "-";
        }
        return cell;
      },
    },
    auth.isSubAdmin()
      ? {
        text: "Created Date",
        dataField: "createdDate",
        classes: "cellsOverflow",
        headerAttrs: { width: "150px" },
        headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
        formatter: dateFormatter,
      }
      : {
        headerStyle: { display: "none" },
        style: { display: "none" },
      },
    {
      text: "Status",
      dataField: "status",
      classes: "cellsOverflow",
      headerAttrs: { width: "130px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "60px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div style={{ position: "relative", zIndex: "1" }}>

        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Users</span>
        </div>

        <ToolkitProvider keyField="id" data={UsersData} columns={columns} search >

          {(props) => (
            <>
              <div style={{ float: "right", marginTop: "-39px" }}>
                <div style={{ display: "flex", marginRight: "-15px", height: "26px", marginTop: "10px", }}>
                  <div style={{ width: "220px" }}>

                    <SearchBar
                      {...props.searchProps}
                      placeholder='Search'
                      style={{
                        height: "25px",
                        // border: "1px solid #C6C6C6",
                        border: "none",
                        background: "white",
                        borderRadius: "3px",
                        marginRight: "-50px",
                      }}
                    />

                  </div>
                  <div onClick={clearFilter} className="filter-refresh">
                    <FontAwesomeIcon icon={faRedoAlt} />
                    &nbsp; Refresh
                  </div>
                  <div className="filter-refresh" onClick={handleClick}>
                    {" "}
                    <FontAwesomeIcon icon={faFilter} />
                    &nbsp; Filters
                  </div>
                  <div
                    className="filter-refresh"
                    onClick={() => handleExport(UsersData, "manageUsers")}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                    &nbsp; Export
                  </div>
                  {inDashboard ? (
                    <div
                      className="filter-refresh"
                      onClick={() => inDashboard(false)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {show && (
                <div
                  className="UHinputfields"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "85px",
                    backgroundColor: "#F7F7F7",
                    padding: "5px",
                    marginRight: "655px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "20%",
                    }}
                  >
                    <label style={{ fontSize: "13px", fontWeight: "500" }}>
                      User Type
                    </label>
                    <select
                      name="role"
                      value={formValue.role}
                      onChange={handleChange}
                      style={{ marginTop: "-5px", width: "100%" }}
                    >
                      <option value="" disabled selected>
                        User Type
                      </option>
                      {
                      auth.getUserInfo().role === "Sub Admin" ? (
                        uniqueRoleSub.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ) )
                      ): (
                        uniqueRole.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ) )
                      )
                      }

                      {/* {uniqueRole.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))} */}
                      {/* {auth.getUserInfo().role === "Sub Admin" ? (
                        ""
                      ) : (
                        <option value="Sub Admin">Sub Admin</option>
                      )}
                      <option value="Procurement">Procurement</option>
                      <option value="Production">Production</option>
                      <option value="Inventory">Inventory</option>
                      <option value="Shop Floor">Shop floor</option>
                      <option value="Management">Management</option>
                      <option value="QC">Quality Control</option>
                      <option value="Finance">Finance</option>
                      <option value="Operations">Operations</option> */}
                    </select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "20%",
                    }}
                  >
                    <label style={{ fontSize: "13px", fontWeight: "500" }}>
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formValue.name}
                      onChange={handleChange}
                      placeholder="Name"
                      autoComplete="off"
                      style={{ marginTop: "-5px", width: "100%" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "20%",
                    }}
                  >
                    <label style={{ fontSize: "13px", fontWeight: "500" }}>
                      Email
                    </label>
                    <input
                      type="text"
                      name="email"
                      value={formValue.email}
                      onChange={handleChange}
                      placeholder="Email"
                      autoComplete="off"
                      style={{ marginTop: "-5px", width: "100%" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "20%",
                    }}
                  >
                    <label style={{ fontSize: "13px", fontWeight: "500" }}>
                      Status
                    </label>
                    <select
                      name="status"
                      value={formValue.status}
                      onChange={handleChange}
                      style={{ marginTop: "-5px", width: "100%" }}
                    >
                      <option value="" disabled selected>
                        Status
                      </option>
                    {
                       uniqueStatus.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ) )
                    }
                     
                    </select>
                  </div>
                  <button
                    onClick={fetchReport}
                    id="apply"
                    style={{ marginTop: "22px", marginLeft: "10px" }}
                  >
                    Apply
                  </button>
                  <button
                    onClick={clearFilter}
                    id="clear"
                    style={{ marginTop: "22px" }}
                  >
                    Clear Filter
                  </button>
                </div>
              )}

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>

      </div>
    </>
  );
};

export default ManageUsers;
