import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";

const ViewDetails = ({ closeModal, data }) => {
  console.log(data);
  const [receivedQuantity, setReceivedQty] = useState();
  const [showReject, setShowReject] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);

  const submitHandler = async () => {
    console.log("hahahha");
    closeModal(false);
  };

  let count = 0;
  data.map((item, index) => {
    count++;
  });

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1050px", height: "600px", padding: "15px" }}
      >
        <div className="header">
          <h3>View Details</h3>
        </div>

        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            margin: "10px 0",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Vehicle Category : {data[0].vehicleCategory}</span>
            {/* <span>Battery Type : {data[0].batteryType}</span> */}
            <span>Battery Type :  Li-Ion</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Vehicle Segment : {data[0].vehicleSegment}</span>
            {/* <span>Battery Make : {data[0].batteryMake}</span> */}
            <span>Battery Make : Exxide</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Vehicle Model : {data[0].vehicleModel}</span>
            {/* <span>Battery Capacity : {data[0].batteryCapacity}</span> */}
            <span>Battery Capacity : 15 Kwh</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Vehicle Variant : {data[0].vehicleVariant}</span>
            {/* <span>Nominal Battery volts : {data[0].nominalBatteryVolt}</span> */}
            <span>Nominal Battery volts : 24 Volts</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Vehicle Type : {data[0].vehicleType}</span>
          </div>
          <br />
          <div
            className="active-btn"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "15%",
              height: "25px",
              borderRadius: "4px",
              alignItems: "center",
            }}
          >
            <span className="active-btn-font">Total Qty - {count}</span>
          </div>
        </section>
        <div
          className="body"
          style={{
            display: "flex",
            height: "300px",
            fontSize: "14px",
            overflow: "auto",
          }}
        >
          <section style={{ width: "100%" }}>
            <div id="POVendorHeading">
              <div style={{ width: "12%" }}>Color</div>
              <div style={{ width: "12%" }}>VIN</div>
              <div style={{ width: "12%" }}>Socket Type</div>
              <div style={{ width: "12%" }}>BIN</div>
              <div style={{ width: "12%" }}>Battery Model No.</div>
              <div style={{ width: "16%", overflow: "auto" }}>
                Avg. Full Charge(hrs)
              </div>
              <div style={{ width: "25%", overflow: "auto" }}>
                Power Consum./Charging(kw)
              </div>
              <div style={{ width: "16%", overflow: "auto" }}>
                Distance to Full Charge
              </div>
            </div>
            <div>
              {data.map((item, index) => {
                return (
                  <div
                    id="POVendorContent"
                    style={{
                      borderBottom: "1px solid #999999",
                      boxShadow: "none",
                      padding: "0px",
                    }}
                    key={1}
                  >
                    <div style={{ width: "12%" }}>{item.color}</div>
                    <div style={{ width: "12%" }}>{item.VIN}</div>
                    {/* <div style={{ width: "12%" }}>{item.socketType}</div> */}
                    <div style={{ width: "12%" }}>CHADeMo</div>
                    <div style={{ width: "12%" }}>{item.BIN}</div>
                    {/* <div style={{ width: "12%" }}>{item.batteryModelNo}</div> */}
                    <div style={{ width: "12%" }}>#78347</div>
                    {/* <div style={{ width: "16%" }}>{item.avgFullCharge}</div> */}
                    <div style={{ width: "16%" }}>12</div>
                    {/* <div style={{ width: "25%" }}>
                      {item.powerConsumptionCharging}
                    </div> */}
                    <div style={{ width: "25%" }}>
                   40
                    </div>
                    {/* <div style={{ width: "16%" }}>
                      {item.distaceToFullCharge}
                    </div> */}
                    <div style={{ width: "16%" }}>
                      135
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>

        <div
          className="footer"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
