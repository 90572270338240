import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import {
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import SearchBox from "../../../../components/search/SearchBox";
import moment from "moment";
import SparesAction from "./component/SparesAssignmentActions";
import AppConstants from "../../../../utils/app-constants";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

function ManageRequest({ inDashboard }) {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [openQty, setOpenQty] = useState();
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [actionShow, setActionShow] = useState();

  const [formValue, setFormValue] = useState({
    jobCardId: "",
    jobType: "",
    vehicleRegistrationNo: "",
    serviceAdvisorName: "",
    status: "",
    from: "",
    to: "",
  });

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `storeManager-module/api/v1/spareRequests/getManageRequests?jobID=${formValue.jobCardId}&&jobType=${formValue.jobType}&&vehicleRegistrationNo=${formValue.vehicleRegistrationNo}&&serviceAdvisorName=${formValue.serviceAdvisorName}&&status=${formValue.status}&&from=${formValue.from}&&to=${formValue.to}`
      )
    );
    console.log(resp);
    const filteredData = resp.filter((item) => {
      return item.status == "Full Spares Assigned" ||  item.status == "Partial Spares Assigned";
    });
    setUsersData(filteredData);
  };
  useEffect(() => {
    fetchReport();
  }, []);



  const uniquejobID = [...new Set(UsersData.map((item) => item.jobID))];
  const uniquejobType = [...new Set(UsersData.map((item) => item.jobType))];
  const uniquevehicleRegistrationNo = [...new Set(UsersData.map((item) => item.vehicleRegistrationNo))];
  const uniqueserviceAdvisorName = [...new Set(UsersData.map((item) => item.serviceAdvisorName))];




  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const statusFormatter = (cell, row, rowIndex) => {
    return cell === AppConstants.FULL_SPARES_ASSIGNED ? (
      <button className="active-btn">
        <span className="active-btn-font">Full Spares Assigned</span>
      </button>
    ) : (
      cell
    );
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setActionShow(rowIndex);
    setOpen(true);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow == rowIndex && open ? <SparesAction rowData={row} rowIndex={rowIndex} /> : null}
      </div>
    );
  };
  const categoryFormatter = (cell, row, rowIndex) => {
    return <span>{row.serviceDetails.serviceCategory}</span>;
  };

  function dateFormatter(cell, row) {
    return `${moment(row?.serviceDetails?.jobDate).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Job Card ID",
      dataField: "jobID",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Job Type",
      // dataField: "jobType",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter:(cell,row)=>{
        return row?.serviceDetails?.serviceType
      }
    },
    {
      text: "Category",
      dataField: "serviceCategory",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: categoryFormatter,
    },
    {
      text: "Job Date",
      dataField: "jobDate",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Service Advisor Name",
      dataField: "serviceAdvisorName",
      classes: "cellsOverflow",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Vehicle Registration No",
      dataField: "vehicleRegNo",
      classes: "cellsOverflow",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Status",
      dataField: "status",
      classes: "cellsOverflow",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { open, actionShow },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Spares Inventory</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={""} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div
                    className="UHinputfields VMform"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "135px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Job Card ID
                        </label>
                        <select
                          name="jobCardId"
                          value={formValue.jobCardId}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Job Card ID
                          </option>
                          {
                            uniquejobID.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Job Type
                        </label>
                        <select
                          name="jobType"
                          value={formValue.jobType}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Job Type
                          </option>
                          {
                            uniquejobType.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Reg. No.
                        </label>
                        <select
                          name="vehicleRegistrationNo"
                          value={formValue.vehicleRegistrationNo}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Reg. No.
                          </option>
                          {
                            uniquevehicleRegistrationNo.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Service Advisor Name
                        </label>
                        <select
                          name="serviceAdvisorName"
                          value={formValue.serviceAdvisorName}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Service Advisor Name
                          </option>
                          {
                            uniqueserviceAdvisorName.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          From
                        </label>
                        <input
                          type="date"
                          name="from"
                          defaultValue={formValue.from}
                          onChange={handleChange}
                          placeholder="From"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          To
                        </label>
                        <input
                          type="date"
                          name="to"
                          defaultValue={formValue.to}
                          onChange={handleChange}
                          placeholder="To"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "end",
                          width: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <button
                          id="apply"
                          style={{ marginTop: "22px", marginLeft: "10px" }}
                          onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          //  onClick={clearFilter}
                          id="clear"
                          style={{ marginTop: "22px", width: "auto" }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

export default ManageRequest;
