function importAll(r) {
  let images = {};
  r.keys().forEach(item => {
  images[item.replace("./", "")] = r(item);
  });
  return images;
  }
  const images = importAll(
  require.context("../assets/img", true, /\.(png|jpe?g|svg)$/)
);

const sidebarIcons = importAll(
  require.context("../assets/img/sidebarIcons", true, /\.(png|jpe?g|svg)$/)
);

export default images;
export {sidebarIcons};
