import React, { useEffect, useState } from 'react'
import '../styles/main.scss'
import Group7 from '../assets/Group7.png';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import { trackPromise } from 'react-promise-tracker';
import API from '../../../utils/api';
import moment from 'moment';

const VehiclesView = ({ vehicleStartDate, vehicleEndDate }) => {

  const [vehicleData, setVehicleData] = useState({});

  const fetchVehicleData = async () => {
    const resp = await trackPromise(API.get(vehicleStartDate == null && vehicleEndDate == null ? `production-module/api/v1/dashboard/getAllVehicles` : `production-module/api/v1/dashboard/getAllVehicles?startDate=${moment(vehicleStartDate).format("DD-MMM-YYYY")}&endDate=${moment(vehicleEndDate).format("DD-MMM-YYYY")}`))
    setVehicleData(resp);
  };
  useEffect(() => {
    fetchVehicleData();
  }, [vehicleEndDate]);

  let percentage = 60;
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "10px", height: "100%" }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", fontWeight: "430", fontSize: "16px", height: "5%" }}>
        <div>
          <img src={Group7} style={{ marginRight: "10px" }} alt="image" />
          <span>Total Vehicles</span>
        </div>
        <span style={{ fontSize: "18px" }}>{vehicleData.total}</span>
      </div>
      <br />
      <hr />
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", height: "95%" }}>
        <div className='productionBox' style={{ height: "35%", width: "45%", margin: "10px", display: "flex" }}>
          <div style={{ width: 60, height: 60, alignItems: "center", margin: "10px" }} >
            <CircularProgressbar
              strokeWidth={12}
              styles={{
                path: {
                  stroke: `green`,
                },
                text: {
                  // Text color
                  fill: 'gray',
                  // Text size
                  fontSize: '30px',
                  fontWeight: "430"
                },
                trail: {
                  // Trail color
                  stroke: 'lavender',
                },
              }}
              value={vehicleData.activeVehicles} text={`${vehicleData.activeVehicles}%`} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", margin: "5px" }}>
            <span style={{ fontWeight: "450", fontSize: "20px" }}>{vehicleData.activeVehicles}</span>
            <span style={{ color: "#808080", fontWeight: "420", fontSize: "14px" }}>Active Vehicles</span>
          </div>
        </div>
        <div className='productionBox' style={{ height: "35%", width: "45%", margin: "10px", display: "flex" }}>
          <div style={{ width: 60, height: 60, alignItems: "center", margin: "10px" }} >
            <CircularProgressbar
              strokeWidth={12}
              styles={{
                path: {
                  stroke: `#FDAA48`,
                },
                text: {
                  // Text color
                  fill: 'gray',
                  // Text size
                  fontSize: '30px',
                  fontWeight: "430"
                },
                trail: {
                  // Trail color
                  stroke: 'lavender',
                },
              }}
              value={vehicleData.deactivatedVehicles} text={`${vehicleData.deactivatedVehicles}%`} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", margin: "5px" }}>
            <span style={{ fontWeight: "450", fontSize: "20px" }}>{vehicleData.deactivatedVehicles}</span>
            <span style={{ color: "#808080", fontWeight: "420", fontSize: "14px" }}>Discontinued Vehicles</span>
          </div>

        </div>
        <div className='productionBox' style={{ height: "35%", width: "45%", margin: "10px", display: "flex" }}>
          <div style={{ width: 60, height: 60, alignItems: "center", margin: "10px" }} >
            <CircularProgressbar
              strokeWidth={12}
              styles={{
                path: {
                  stroke: `#FFD74A`,
                },
                text: {
                  // Text color
                  fill: 'gray',
                  // Text size
                  fontSize: '30px',
                  fontWeight: "430"
                },
                trail: {
                  // Trail color
                  stroke: 'lavender',
                },
              }}
              value={vehicleData.reactivatedVehicles} text={`${vehicleData.reactivatedVehicles}%`} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", margin: "5px" }}>
            <span style={{ fontWeight: "450", fontSize: "20px" }}>{vehicleData.reactivatedVehicles}</span>
            <span style={{ color: "#808080", fontWeight: "420", fontSize: "14px" }}>Reactivated Vehicles</span>
          </div>
        </div>
        <div className='productionBox' style={{ height: "35%", width: "45%", margin: "10px", display: "flex" }}>
          <div style={{ width: 60, height: 60, alignItems: "center", margin: "10px" }} >
            <CircularProgressbar
              strokeWidth={12}
              styles={{
                path: {
                  stroke: `#ED344B`,
                },
                text: {
                  // Text color
                  fill: 'gray',
                  // Text size
                  fontSize: '30px',
                  fontWeight: "430"
                },
                trail: {
                  // Trail color
                  stroke: 'lavender',
                },
              }}
              value={vehicleData.deletedVehicles} text={`${vehicleData.deletedVehicles}%`} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", margin: "5px" }}>
            <span style={{ fontWeight: "450", fontSize: "20px" }}>{vehicleData.deletedVehicles}</span>
            <span style={{ color: "#808080", fontWeight: "420", fontSize: "14px" }}>Deleted Vehicles</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VehiclesView;