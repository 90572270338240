import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import WoViewDetails from "./actions/wo-viewdetails";
import WoInProgressViewDetails from "./actions/wo-inprogress-viewdetails";
import WoRejectedViewDetails from "./actions/wo-rejected-viewdetails";
import AppConstants from "../../utils/app-constants";
import WoProductionQty from "./actions/wo-productionqty";
import handleExport from "../../utils/exportExcel";
// import BomViewandUpdate from "./approval-actions/bom-viewandupdate";

const { ExportCSVButton } = CSVExport;

const WorkOrders = () => {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [openDetails, setOpenDetails] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [formValue, setFormValue] = useState({
    orderID: "",
    woType: "",
    woCreatedBy: "",
    woCreationDate: "",
    status: "",
  });

  const [row, setRow] = useState();

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `management/api/v1/bom/workorder?orderID=${formValue.orderID}&createdBy=${formValue.woCreatedBy}&createdDate=${formValue.woCreationDate}&workType=${formValue.woType}&status=${formValue.status}`
      )
    );
    setUsersData(resp);
  };

  useEffect(() => {
    fetchReport();
  }, []);


  const uniqueorderID = [...new Set(UsersData.map(item => item.orderID))];
  const uniqueworkType = [...new Set(UsersData.map(item => item.workType))];
  const uniquecreatedBy = [...new Set(UsersData.map(item => item.createdBy))];
  const uniquestatus = [...new Set(UsersData.map(item => item.status))];




  const statusFormatter = (cell) => {
    return cell === "Assigned_New Work Order" ? (
      <button className="delete-btn" style={{width:"100%"}}>
        <span className="delete-btn-font">Pending at Shop Floor</span>
      </button>
    ) : cell === "Accepted_Accepted" ? (
      <button className="reactive-btn" style={{width:"100%"}}>
        <span className="reactive-btn-font">Accepted by Shop Floor</span>
      </button>
    ) : cell === "Rejected_Not Accepted" ? (
      <button className="delete-btn" style={{width:"100%"}}>
        <span className="delete-btn-font">Rejected by Shop Floor</span>
      </button>
    ) : cell === "Full Assignment" || cell == "Full Assignment_WO in Progress" ? (
      <button className="deactive-btn" style={{width:"100%"}}>
        <span className="deactive-btn-font">Full Assignment</span>
      </button>
    ) : cell === "Partial Assignment" || cell == "Partial Assignment_WO in Progress" ? (
      <button className="deactive-btn" style={{width:"100%"}}>
        <span className="deactive-btn-font">Partial Assignment</span>
      </button>
    ) : cell === "Accepted_WO in Progress" ? (
      <button className="deactive-btn" style={{width:"100%"}}>
        <span className="deactive-btn-font">WO in Progress</span>
      </button>
    ) : cell === "Accepted_Work Halted" || cell === "Accepted_On Hold" ? (
      <button className="delete-btn" style={{width:"100%"}}>
        <span className="delete-btn-font">WO Halted</span>
      </button>
    ) : cell === "Accepted_Work Resumed" ? (
      <button className="deactive-btn" style={{width:"100%"}}>
        <span className="deactive-btn-font">WO Resumed</span>
      </button>
    ) : cell === "Accepted_WO Completed" ? (
      <button className="active-btn" style={{width:"100%"}}>
        <span className="active-btn-font">WO Completed</span>
      </button>
    ) : (
      cell
    );
  };

  const actionHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setOpenDetails(true);
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          onClick={() => actionHandler(row, rowIndex)}
          style={{
            color: "#0A18A3",
            backgroundColor: "white",
            border: "1px solid #0A18A3",
            borderRadius: "3px",
            fontSize:"smaller"
          }}
        >
          View Details
        </button>
        {actionShow === rowIndex &&
        (row?.status == "Assigned_New Work Order" ||
          row?.status == "Accepted_Accepted") &&
        openDetails ? (
          <WoViewDetails
            productionQuantity={row?.productionQuantity}
            rowdata={row}
            closeModal={setOpenDetails}
          />
        ) : actionShow === rowIndex &&
          row?.status == "Rejected_Not Accepted" &&
          openDetails ? (
          <WoRejectedViewDetails rowdata={row} closeModal={setOpenDetails} />
        ) : actionShow === rowIndex && openDetails ? (
          <WoInProgressViewDetails rowdata={row} closeModal={setOpenDetails} />
        ) : null}
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY")}`;
  }

  function timelineFormatter(cell, row) {
    let startDate = row?.startDate;
    let endDate = row?.endDate;
    if (!cell) {
      return "N/A";
    }
    return `${moment(startDate).format("DD-MMM-YYYY")} - ${moment(
      endDate
    ).format("DD-MM-YYYY, HH:mm")}`;
  }

  function ProductionQty(cell, row) {
    return (
      <>
        <span>{parseInt(row?.productionQuantity)}</span>
      </>
    );
  }

  const handleClick = (e) => {
    setShow(!show);
  };

  const exportData = UsersData.map((row, index) => ({
    "S.No.": index + 1,
    "WO ID": row?.orderID ?? "",
    "WO Type": row?.workType ?? "",
    "BOM ID": row?.BOMID ?? "",
    "BOM Location": row?.bomLocation ?? "",
    "BOM Name": row?.bomName ?? "",
    "Production Qty": row?.productionQuantity ?? "", // Assuming the raw value without formatter
    "WO Timeline": row?.startDate ?? "", // Assuming the raw value without formatter
    "WO Created By": row?.createdBy ?? "",
    "WO Creation Date": row?.createdDate ? moment(row.createdDate).format("DD-MM-YYYY") : "",
    "Status": row?.status ?? "", // Assuming the raw value without formatter
    "Reason": row?.reason ?? "",
  }));
  

  const columns = [ 
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "WO ID",
      dataField: "orderID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "180px" },
    },
    {
      text: "WO Type",
      dataField: "workType",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "BOM ID",
      dataField: "BOMID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "BOM Location",
      dataField: "bomLocation",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "BOM Name",
      dataField: "bomName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Production Qty",
      dataField: "productionQuantity",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatter: ProductionQty,
    },
    {
      text: "WO Timeline",
      dataField: "startDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "300px" },
      formatter: timelineFormatter,
    },
    {
      text: "WO Created By",
      dataField: "createdBy",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "250px" },
    },
    {
      text: "WO Creation Date",
      dataField: "createdDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatter: dateFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: statusFormatter,
    },
    {
      text: "Reason",
      dataField: "reason",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Action",
      // dataField: "title",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, openDetails },
      formatter: actionFormatter,
    },
  ];

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      orderID: "",
      woType: "",
      woCreatedBy: "",
      woCreationDate: "",
      status: "",
    });
    const resp = await trackPromise(API.get(`management/api/v1/bom/workorder`));
    setUsersData(resp);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    fetchReport();
  };

  return (
    //<span>Work Orders</span>
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Work Orders</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(exportData, "Work Order")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <>
                    <form
                      onSubmit={submitHandler}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: "135px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                      }}
                      className="UHinputfields VMform"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          WO ID
                        </label>
                        <select
                          name="orderID"
                          value={formValue.orderID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            WO ID
                          </option>
                          {
                            uniqueorderID?.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          WO Type
                        </label>
                        <select
                          name="woType"
                          value={formValue.woType}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            WO Type
                          </option>
                          {
                            uniqueworkType?.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          WO Created By
                        </label>
                        <select
                          name="woCreatedBy"
                          value={formValue.woCreatedBy}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            WO Created By
                          </option>
                          {
                            uniquecreatedBy?.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          WO Creation Date
                        </label>
                        <input
                          type="text"
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "text")}
                          name="woCreationDate"
                          value={formValue.woCreationDate}
                          onChange={handleChange}
                          placeholder="WO Creation Date"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {
                            uniquestatus?.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          width: "22%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <button
                          onClick={fetchReport}
                          style={{ width: "100px", marginTop: "24px" }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{ width: "100px", marginTop: "24px" }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </form>
                  </>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};
export default WorkOrders;
