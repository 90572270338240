import { KeyboardBackspace } from '@material-ui/icons';
import React from 'react'

const ViewBatterySpec = ({ closeModal, specs }) => {
    console.log(specs)
    return (
        <div className="container1">
            <div className="modalContainer" style={{ height: '350px' }}>
                <div className="header">
                    <h3>Battery Specification</h3>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h6>Battery Name: {specs?.batteryName}</h6>
                    <h6>Battery SKU Code: {specs?.batterySkuCode}</h6>
                </div>

                <div className="body" style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap" }}>
                    <ul type="none" className="viewspeclist" style={{ paddingLeft: "0px", textAlign: "left", fontSize: "14px" }}>
                        {specs?.batterySpecifications?.map((item) => {
                            return (
                                <li>{`Battery ${item?.specsType} : ${item?.specsValue} ${item?.specsUnit}`}</li>
                            )
                        })}
                    </ul>
                   
                </div>
                <div className="footer">
                    <button onClick={() => closeModal(false)}><KeyboardBackspace /> Back</button>
                </div>
            </div>
        </div>
    );
};

export default ViewBatterySpec