import { Close, KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import auth from "../../utils/auth";
import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";
import TermsView from "../TermsView/TermsView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const ServiceInvoiceView = ({ closeModal, rowData , setIsOpen }) => {
  console.log("rowData", rowData);

  const data = rowData?.serviceDetails.additionalSpares.map(
    (item) => item.skuCode
  );
  console.log("data-->", data);

  let componentRef = null;



  const calculateSubtotal = (item) => {
    const unitPrice = parseInt(item?.sparePartMrp);
    const requiredQty = item?.requiredUnits;
    const taxPercentage = (Math.ceil(
      (item?.requiredUnits *
        item?.sparePartMrp *
        rowData?.serviceDetails?.cgst) /
      100
    ) +
      Math.ceil(
        (item?.requiredUnits *
          item?.sparePartMrp *
          rowData?.serviceDetails?.sgst) /
        100
      ));

    const subtotal = (unitPrice * requiredQty) + taxPercentage;

    return subtotal;
  };

  const totalSumAdditionalSpares = rowData?.serviceDetails?.additionalSpares?.reduce((acc, item) => {
    return acc + calculateSubtotal(item);
  }, 0);

  const totalSumMandatoryReplacements = rowData?.serviceDetails?.mandatoryReplacements?.reduce((acc, item) => {
    return acc + calculateSubtotal(item);
  }, 0);


  const totalSum = totalSumAdditionalSpares + totalSumMandatoryReplacements




  const calculateCheckSubtotals = (item) => {
    const unitPrice = parseInt(item?.sparePartMrp);
    const taxPercentage = (Math.ceil(
      (
        item?.sparePartMrp *
        rowData?.serviceDetails?.cgst) /
      100
    ) +
      Math.ceil(
        (
          item?.sparePartMrp *
          rowData?.serviceDetails?.sgst) /
        100
      ));

    const subtotal = unitPrice + taxPercentage;

    return subtotal;
  };

  const totalSumAdditionalChecks = rowData?.serviceDetails?.additionalChecks?.reduce((acc, item) => {
    return acc + calculateCheckSubtotals(item);
  }, 0);

  const totalSumMandatoryInspectionCheck = rowData?.serviceDetails?.mandatoryInspectionCheck?.reduce((acc, item) => {
    return acc + calculateCheckSubtotals(item);
  }, 0);


  const totalCheckSum = totalSumAdditionalChecks + totalSumMandatoryInspectionCheck



  const currentDate = new Date();
  console.log(currentDate);







  function numberToWords(num) {
    if (num === 0) return "Zero";

    const belowTwenty = [
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    const tens = [
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const thousands = ["", "Thousand", "Lakh", "Crore"];

    function helper(n) {
      if (n < 20) return belowTwenty[n - 1];
      if (n < 100)
        return (
          tens[Math.floor(n / 10) - 2] +
          (n % 10 ? " " + belowTwenty[(n % 10) - 1] : "")
        );
      if (n < 1000)
        return (
          belowTwenty[Math.floor(n / 100) - 1] +
          " Hundred" +
          (n % 100 ? " " + helper(n % 100) : "")
        );

      for (let i = 0, unit = 1; i < thousands.length; i++) {
        const divisor = i === 0 ? 1 : i === 1 ? 1000 : 100000;
        if (n < divisor * 100)
          return (
            helper(Math.floor(n / divisor)) +
            " " +
            thousands[i] +
            (n % divisor ? " " + helper(n % divisor) : "")
          );
      }
    }

    function capitalizeFirstLetter(string) {
      return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    }

    return capitalizeFirstLetter(helper(num));
  }




  const closeHandler = ()=>{
    setIsOpen(false)
    closeModal(false)
  }





  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          width: "70%",
          height: "600px",
          padding: "15px",
          marginTop: "95px",
          marginBottom: "100px",
          borderRadius: "0px",
          borderTop: "none",
        }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Service Invoice</h3>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <button
                    style={{
                      color: "#0A18A3",
                      margin: "1px",
                      height: "27px",
                      width: "90px",
                      border: "none",
                    }}
                  >
                    Download
                  </button>
                );
              }}
              content={() => componentRef}
              documentTitle="Tax Invoice"
              pageStyle="Print"
            />
          </div>
        </div>

        <div
          style={{ overflowY: "scroll", overflowX: "hidden", width: "100%" }}
        >
          <div
            // className="body"
            style={{
              width: " 99%",
              height: "97%",
              display: "flex",
              margin: "5px",
              flexDirection: "column",
              // justifyContent: "space-between",
              // pageBreakBefore: "always",
            }}
            ref={(el) => (componentRef = el)}
          >
            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <span>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Tata_logo.svg/1200px-Tata_logo.svg.png"
                      style={{
                        width: "60px",
                        height: "60px",
                        marginBottom: "30px",
                      }}
                      alt="Car"
                    />
                  </span>
                </div>

                <div style={{ marginTop: "35px", fontSize: "23px" }}>
                  Service Invoice
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span style={{ fontWeight: "500", fontSize: "14px" }}>
                    {rowData?.serviceCenterName}
                  </span>
                  <span style={{ fontWeight: "100", fontSize: "14px" }}>
                    Ph. : {rowData?.serviceCenterPhone}
                  </span>
                  <span style={{ fontWeight: "100", fontSize: "14px" }}>
                    {rowData?.serviceCenterLocation}
                  </span>

                </div>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              {/* <section
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderBottom: "1px solid black", 
                  height:"1px"
                }}
              >
                
              </section> */}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    border: "1px solid black", // Combined border for the entire table
                    overflow: "hidden", // Optional: Hiding overflowing content
                    width: "40%",
                    padding: "12px",
                    // borderTop: "none",
                    // height: "100%",
                  }}
                >
                  <span style={{ fontWeight: "400", fontSize: "14px" }}>
                    Customer Name: {rowData?.customerDetails?.customerName}
                  </span>
                  <span style={{ fontWeight: "400", fontSize: "14px" }}>
                    Address : {rowData?.customerDetails?.customerAddress}
                  </span>
                  <span style={{ fontWeight: "400", fontSize: "14px" }}>
                    Registraion No. : {rowData?.vehicleRegNo}
                  </span>
                  <span style={{ fontWeight: "400", fontSize: "14px" }}>
                    Model Name. : {rowData?.vehicleModel}
                  </span>
                  <span style={{ fontWeight: "400", fontSize: "14px" }}>
                    VIN : {rowData?.engineNo}
                  </span>

                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    border: "1px solid black",
                    borderLeft: "none",
                    // borderTop: "none",
                    overflow: "hidden",
                    width: "60%",
                    // height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        borderRight: "1px solid black",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        Service Advisor
                      </span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        {rowData?.serviceAdvisorName}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        borderRight: "1px solid black",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        Job Card No.
                      </span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        {rowData?.jobID}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "none",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        borderRight: "1px solid black",
                        borderBottom: "1px solid black",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        Job Card Date
                      </span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        padding: "5px 10px",
                        display: "flex",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        {moment(rowData?.jobDate).format("DD-MM-YYYY-Hm")}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        borderRight: "1px solid black",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        Invoice Date :
                      </span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        {new Date().toLocaleString('en-IN')}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        borderRight: "1px solid black",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        Service Center :
                      </span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        {rowData?.serviceCenterName}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        borderRight: "1px solid black",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        GSTIN :
                      </span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        padding: "5px 10px",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "100", fontSize: "14px" }}>
                        {rowData?.serviceCenterGstin}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                margin: "50px 0px 10px 0px",
              }}
            >
              Part Description
            </div>

            <section
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                fontSize: "14px",
                // border: "1px solid black",
              }}
            >
              <div style={{ width: "100%", border: "1px solid black" }}>
                <div
                  style={{ display: "flex", borderBottom: "1px solid black" }}
                >
                  <span style={{ width: "10%" }}>SKU Code</span>
                  <span style={{ borderLeft: "1px solid black", width: "30%" }}>
                    Description
                  </span>
                  <span style={{ borderLeft: "1px solid black", width: "8%" }}>
                    Qty.
                  </span>
                  <span style={{ borderLeft: "1px solid black", width: "10%" }}>
                    Price
                  </span>
                  <span style={{ borderLeft: "1px solid black", width: "10%" }}>
                    CGST/SGST %
                  </span>
                  <span
                    style={{ borderLeft: "1px solid black", width: "10%" }}
                  >{`CGST(${auth.getUserInfo().currencySymbol})`}</span>
                  <span
                    style={{ borderLeft: "1px solid black", width: "10%" }}
                  >{`SGST(${auth.getUserInfo().currencySymbol})`}</span>
                  <span
                    style={{ borderLeft: "1px solid black", width: "12%" }}
                  >{`Amount(${auth.getUserInfo().currencySymbol})`}</span>
                </div>

                <div>
                  {rowData?.serviceDetails?.additionalSpares?.map((item) => (
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <span style={{ width: "10%" }}>{item?.skuCode}</span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "30%" }}
                      >{`${item?.category} - ${item?.subCategory}`}</span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "8%" }}
                      >
                        {item?.requiredUnits}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >
                        {item?.sparePartMrp}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >{`${parseInt(rowData?.serviceDetails?.cgst) + parseInt(rowData?.serviceDetails?.sgst)}%`}</span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >
                        {Math.ceil(
                          (item?.requiredUnits *
                            item?.sparePartMrp *
                            rowData?.serviceDetails?.cgst) /
                          100
                        )}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >
                        {Math.ceil(
                          (item?.requiredUnits *
                            item?.sparePartMrp *
                            rowData?.serviceDetails?.cgst) /
                          100
                        )}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "12%" }}
                      >
                        {Math.ceil(
                          item?.requiredUnits * item?.sparePartMrp +
                          Math.ceil((item?.requiredUnits *
                            item?.sparePartMrp *
                            rowData?.serviceDetails?.cgst) /
                            100) +
                          Math.ceil((item?.requiredUnits *
                            item?.sparePartMrp *
                            rowData?.serviceDetails?.sgst) /
                            100)
                        )}
                      </span>
                    </div>
                  ))}
                </div>
                <div>
                  {rowData?.serviceDetails?.mandatoryReplacements?.map((item) => (
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <span style={{ width: "10%" }}>{item?.skuCode}</span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "30%" }}
                      >{`${item?.category} - ${item?.subCategory}`}</span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "8%" }}
                      >
                        {item?.requiredUnits}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >
                        {item?.sparePartMrp}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >{`${parseInt(rowData?.serviceDetails?.cgst) + parseInt(rowData?.serviceDetails?.sgst)}%`}</span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >
                        {Math.ceil(
                          (item?.requiredUnits *
                            item?.sparePartMrp *
                            rowData?.serviceDetails?.cgst) /
                          100
                        )}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >
                        {Math.ceil(
                          (item?.requiredUnits *
                            item?.sparePartMrp *
                            rowData?.serviceDetails?.sgst) /
                          100
                        )}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "12%" }}
                      >
                        {Math.ceil(
                          item?.requiredUnits * item?.sparePartMrp +
                          Math.ceil((item?.requiredUnits *
                            item?.sparePartMrp *
                            rowData?.serviceDetails?.cgst) /
                            100) +
                          Math.ceil((item?.requiredUnits *
                            item?.sparePartMrp *
                            rowData?.serviceDetails?.sgst) /
                            100)
                        )}
                      </span>
                    </div>
                  ))}

                  <div style={{ display: "flex" }}>
                    <span style={{ width: "10%" }}></span>
                    <span style={{ width: "30%" }}>{ }</span>
                    <span style={{ width: "8%" }}>{ }</span>
                    <span style={{ width: "10%" }}>{ }</span>
                    <span style={{ width: "10%" }}>{ }</span>
                    <span style={{ width: "10%" }}>{ }</span>
                    <span style={{ width: "10%" }}>{"Total"}</span>
                    <span
                      style={{ borderLeft: "1px solid black", width: "12%" }}
                    >
                      {Math.ceil(totalSum)}
                    </span>
                  </div>
                </div>

              </div>
            </section>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                margin: "50px 0px 10px 0px",
              }}
            >
              Labour Description
            </div>

            <section
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                fontSize: "14px",
                // border: "1px solid black",
              }}
            >
              <div style={{ width: "100%", border: "1px solid black" }}>
                <div
                  style={{ display: "flex", borderBottom: "1px solid black" }}
                >
                  <span style={{ width: "48%" }}>
                    Description
                  </span>
                  <span style={{ borderLeft: "1px solid black", width: "10%" }}>
                    Price
                  </span>
                  <span style={{ borderLeft: "1px solid black", width: "10%" }}>
                    CGST/SGST %
                  </span>
                  <span
                    style={{ borderLeft: "1px solid black", width: "10%" }}
                  >{`CGST(${auth.getUserInfo().currencySymbol})`}</span>
                  <span
                    style={{ borderLeft: "1px solid black", width: "10%" }}
                  >{`SGST(${auth.getUserInfo().currencySymbol})`}</span>
                  <span
                    style={{ borderLeft: "1px solid black", width: "12%" }}
                  >{`Amount(${auth.getUserInfo().currencySymbol})`}</span>
                </div>

                <div>
                  {rowData?.serviceDetails?.additionalChecks?.map((item) => (
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <span
                        style={{ width: "48%" }}
                      >{`${item?.check}`}</span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >
                        {item?.sparePartMrp}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >{`${parseInt(rowData?.serviceDetails?.cgst) + parseInt(rowData?.serviceDetails?.sgst)}%`}</span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >
                        {Math.ceil(
                          (
                            item?.sparePartMrp *
                            rowData?.serviceDetails?.cgst) /
                          100
                        )}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >
                        {Math.ceil(
                          (
                            item?.sparePartMrp *
                            rowData?.serviceDetails?.cgst) /
                          100
                        )}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "12%" }}
                      >
                        {Math.ceil(
                          parseInt(item?.sparePartMrp) +
                          Math.ceil((
                            parseInt(item?.sparePartMrp) *
                            rowData?.serviceDetails?.cgst) /
                            100) +
                          Math.ceil((
                            parseInt(item?.sparePartMrp) *
                            rowData?.serviceDetails?.sgst) /
                            100)
                        )}
                      </span>
                    </div>
                  ))}
                </div>

                <div>
                  {rowData?.serviceDetails?.mandatoryInspectionCheck?.map((item) => (
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <span
                        style={{ width: "48%" }}
                      >{`${item?.check}`}</span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >
                        {item?.sparePartMrp}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >{`${parseInt(rowData?.serviceDetails?.cgst) + parseInt(rowData?.serviceDetails?.sgst)}%`}</span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >
                        {Math.ceil(
                          (
                            item?.sparePartMrp *
                            rowData?.serviceDetails?.cgst) /
                          100
                        )}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "10%" }}
                      >
                        {Math.ceil(
                          (
                            item?.sparePartMrp *
                            rowData?.serviceDetails?.cgst) /
                          100
                        )}
                      </span>
                      <span
                        style={{ borderLeft: "1px solid black", width: "12%" }}
                      >
                        {Math.ceil(
                          parseInt(item?.sparePartMrp) +
                          Math.ceil((
                            parseInt(item?.sparePartMrp) *
                            rowData?.serviceDetails?.cgst) /
                            100) +
                          Math.ceil((
                            parseInt(item?.sparePartMrp) *
                            rowData?.serviceDetails?.sgst) /
                            100)
                        )}
                      </span>
                    </div>
                  ))}
                </div>

                <div>
                  <div style={{ display: "flex" }}>
                    <span style={{ width: "48%" }}>{ }</span>
                    <span style={{ width: "10%" }}>{ }</span>
                    <span style={{ width: "10%" }}>{ }</span>
                    <span style={{ width: "10%" }}>{ }</span>
                    <span style={{ width: "10%" }}>{"Total"}</span>
                    <span
                      style={{ borderLeft: "1px solid black", width: "12%" }}
                    >
                      {Math.ceil(totalCheckSum)}
                    </span>
                  </div>
                </div>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                margin: "30px 0px 10px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "13px"
                }}
              >
                <span>
                  {` Amount Chargeable (in Number) : (${auth.getUserInfo().currencySymbol
                    }) ${totalSum + totalCheckSum}`}
                </span>
                <span>
                  Amount Chargeable (in words) : {numberToWords(totalSum + totalCheckSum)}
                </span>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontSize: "14px",
                margin: "10px 0px 10px 0px",
                // border: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                <span
                  style={{
                    width: "100%",
                    height: "150px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    fontSize: "14px",
                    // border: "1px solid black",
                    // borderBottom: "none",
                  }}
                >
                  <span
                    style={{
                      paddingLeft: "3px",
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                  >
                    Terms and Conditions
                  </span>
                  <span
                    style={{
                      paddingLeft: "3px",
                      fontWeight: "100",
                      display: "flex",
                      alignItems: "start",
                    }}
                  >
                    <TermsView data={"Service Invoice Terms"} />
                  </span>
                </span>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              ></div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                margin: "50px 0px 10px 0px",
              }}
            >
              <div></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  width: "100%",
                  height: "70px",
                  flexDirection: "column",
                  alignItems: "end",
                  fontSize: "14px",
                  // border: "1px solid black",
                  borderLeft: "none",
                  paddingLeft: "3px",
                }}
              >
                <span>For : {rowData?.serviceAdvisorName}</span>
                <span style={{ marginTop: "30px" }}>Authorized Signatory</span>
              </div>
            </section>
          </div>
        </div>

        <div
          className="footer"
          style={{ width: "100%", justifyContent: "end" }}
        >
          <button
            style={{
              width: "104px",
              height: "35px",
              background: "#fff",
              cursor: "pointer",
            }}
            onClick={closeHandler}
          >
            <Close style={{ fontSize: "25px" }} />
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceInvoiceView;
