import React, { useState } from "react";
import auth from "../../../utils/auth";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification,errorNotification } from "../../../utils/notifications";

const DeleteVendor = ({ updatedData,editData,closeModal, setIsOpen}) => {
  const [formValue, setFormValue] = useState({
    DeleteReason: "",
    description: "",
  });

  const editDetails = {
    "vendorID": editData.vendorID,
    "reason": formValue.DeleteReason,
    "reasonComment": formValue.description
};

const refresh = () => {
  updatedData()
};

const submitHandler = async () => {
 if(formValue.DeleteReason){
  console.log(formValue);
  const resp = await trackPromise(API.post(`procurement-module/api/v1/vendors/delete`, editDetails));

  const { success, message } = resp;

  if (success) {
    closeModal(false);
    successNotification(message, { autoClose: 3000 });
    refresh()
    setIsOpen(false)
  } else {
    errorNotification(message, { autoclose: 2000 });
  }
 }
 else{
  return errorNotification("Please select Delete Reason", { autoClose: 10000 });
 }
};


return (
  <div className="container1">
    <div className="modalContainer">
      <div className="header">
        <h3>Delete Vendor</h3>
      </div>

      <div className="body">
        <label style={{display:'flex', flexDirection:'column'}}>
        <label style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign:"start"
                  }}>Delete Reason <span style={{color:"red"}}>*</span></label>
          <select
            name="DeleteReason"
            onChange={(e) =>
              setFormValue({ ...formValue, [e.target.name]: e.target.value })
            }
            style={{ width: '100%', outline: 'none', marginTop:'-8px'  }}
          >
            <option disabled selected hidden>
              Delete Reason
            </option>
            <option value="Continuous violation of Terms">
            Continuous violation of Terms
            </option>
            <option value="Business closed">
            Business closed
            </option>
            <option value=" Production related issues">
            Production related issues
            </option>
            <option value="Others">Others</option>
          </select>
        </label>

        {formValue.DeleteReason === "Others" ? 
        <label className="lgInput">
          Description
          <input
            type="text"
            style={{ width: "508px" }}
            name="description"
            value={formValue.description}
            onChange={(e) =>
              setFormValue({ ...formValue, [e.target.name]: e.target.value })
            }
            placeholder="Delete Reason"
            autoComplete="off"
          />
        </label> : ""}
      </div>

      <div className="footer">
        <button onClick={() => closeModal(false)}>Cancel</button>
        <button id="submit-button" onClick={formValue.DeleteReason==""?"" : submitHandler}>
          Submit
        </button>
      </div>
    </div>
  </div>
);
};

export default DeleteVendor;
