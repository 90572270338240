import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import {
    KeyboardBackspace,
    CheckCircle,
    HighlightOffOutlined,
    ConfirmationNumber,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { errorNotification, successNotification } from "../../utils/notifications";
import { faEdit, faBan, faShare, faPauseCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewSpecs from "../storeManagerOrders/RequisitionProgress/Action/ViewSpecs";
import auth from "../../utils/auth";

const SpareRequisitionDetails = ({ closeModal, rowData }) => {
    const [openModal, setOpenModal] = useState(false)
    const [unitPrice, setUnitPrice] = useState();

    const sumWithInitial = rowData.requisitionDetails.reduce(
        (accumulator, currentValue) => accumulator + parseInt(currentValue?.requiredQty),
        0
    );

    const [viewSpecs, setViewSpecs] = useState(false);
    const [specsData, setSpecsData] = useState({});

    const handleViewSpecs = (item, index) => {
        setViewSpecs(true);
        setSpecsData(item);
    };

    return (
        <div className="container1">
            {viewSpecs && <ViewSpecs specs={specsData} closeModal={setViewSpecs} />}
            <div
                className="modalContainer"
                style={{ width: "900px", height: "400px", padding: '15px' }}
            >
                <div className="header">
                    <h3>Spares Requisition</h3>
                </div>
                <br />
                <div
                    className="body"
                    style={{ display: "flex", height: "450px", fontSize: "14px", overflow: 'scroll' }}
                >

                    <section style={{ width: '100%', }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requisition Number : {rowData.requisitionID}</span>
                            <span>Total Spare Requested : {rowData.requisitionDetails.length} </span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Total Units Requested : {sumWithInitial} </span>
                        </div>

                        <br />

                        <br />
                        <div style={{ overflow: "scroll" }}>
                            <div id='POVendorHeading'>
                                <div style={{ width: '5%' }}>S.No.</div>
                                <div style={{ width: '15%' }}>Spare Part Name</div>
                                <div style={{ width: '10%' }}>SKU Code</div>
                                <div style={{ width: '10%' }}>Category</div>
                                <div style={{ width: '15%' }}>Sub Category</div>
                                <div style={{ width: '10%' }}>Specifications</div>
                                <div style={{ width: '15%' }}>{`Unit Price (${auth.getUserInfo().currencySymbol})`}</div>
                                <div style={{ width: '10%' }}>Requested Qty.</div>
                                <div style={{ width: '10%' }}>{`Sub Total (${auth.getUserInfo().currencySymbol})`}</div>

                            </div>
                            {
                                rowData.requisitionDetails.map((item, index) => {
                                    return (
                                        <div>
                                            <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }} key={1}>
                                                <div style={{ width: '5%' }}>{index + 1}</div>
                                                <div style={{ width: '15%' }}>{item.sparePartname}</div>
                                                <div style={{ width: '10%', }}>{item.skuCode}</div>
                                                <div style={{ width: '10%' }}>{item.category}</div>
                                                <div style={{ width: '15%' }}>{item.subCategory}</div>
                                                <div style={{ width: '10%' }}>
                                                    <span
                                                        onClick={() => handleViewSpecs(item, index)}
                                                        style={{
                                                            color: "#0a18a3",
                                                            fontWeight: "500",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        View Specs
                                                    </span>
                                                </div>
                                                <div style={{ width: '15%' }}>{item.priceToserviceCenter}</div>
                                                <div style={{ width: '10%', }}>{item.requiredQty}</div>
                                                <div style={{ width: '10%' }}>{item?.requiredQty * item?.priceToserviceCenter}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>

                    </section>

                </div>

                <div className="footer">
                    <div>
                        <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SpareRequisitionDetails