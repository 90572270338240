import React, { useState } from "react";
import {
  CancelOutlined,
  CheckCircle,
} from "@material-ui/icons";
const ShareBom = ({ closeModal, editData }) => {
  console.log("ed", editData);

  const [formValue, setFormValue] = useState({
    userType: "",
    emailId: "",
  });

  const submitHandler = () => {
    console.log(formValue);
    setActionShow(false)
    console.log("submitted succesfully!");
  };


  const closeHandler = ()=>{
    setActionShow(false)
    closeModal(false)
  }



  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Share BOM</h3>
        </div>

        <div
          className="body"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label
            style={{ display: "flex", flexDirection: "column", width: "45%" }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                textAlign: "start",
              }}
            >
              User Type <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="userType"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: "100%", outline: "none", marginTop: "-5px" }}
            >
              <option disabled selected hidden>
                User Type
              </option>
              <option value="Procurement">Procurement</option>
              <option value="Inventory">Inventory</option>
              <option value="Production">Production</option>
            </select>
          </label>
          <label
            style={{ display: "flex", flexDirection: "column", width: "45%" }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                textAlign: "start",
              }}
            >
              {" "}
              Email ID<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="emailId"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: "100%", outline: "none", marginTop: "-5px" }}
            >
              <option disabled selected hidden>
                Email ID
              </option>
              <option>abc@gmail.com</option>
              <option>xyz@gmail.com</option>
              <option>pqr@gmail.com</option>
            </select>
          </label>
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}><CancelOutlined/>  Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
           <CheckCircle/>  Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareBom;
