import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../../utils/app-constants";
import "../../../pages/settings-tabs/tabs.scss";
import AddFeatures from "./AddFeatures";
import ManageFeatures from "./ManageFeatures";



const tabs = [
  {
    label: TabLabels.ADD_FEATURES,
    Component: AddFeatures,
  },
  {
    label: TabLabels.MANAGE_FEATURES,
    Component: ManageFeatures,
  }
];

const SubscriptionFeatures = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default SubscriptionFeatures;