import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ViewMappedStates from "./viewMappedStates";
import AreaAction from "./Actions/areaActions";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import EditArea from "./Actions/editArea";

function ManageAreas() {
  const { SearchBar } = Search;
  const [statesShow, setStatesShow] = useState();
  const [viewMappedStates, setMappedStates] = useState();
  const [actionShow, setActionShow] = useState();
  const [isOpen, setIsOpen] = useState(true);
  const [areaData, setAreaData] = useState([]);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`operations-module/api/v1/zoneManagement/getAreas`)
    );
    console.log(resp);
    setAreaData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const uniquecomponentName = [...new Set(areaData?.map((item) => item.componentName))];
  const uniqueskuCode = [...new Set(areaData?.map((item) => item.skuCode))];
  const uniquevendorPartName = [...new Set(areaData?.map((item) => item.vendorPartName))];
  const uniqueverifiedBy = [...new Set(areaData?.map((item) => item.verifiedBy))];

  const stateHandler = (rowIndex) => {
    setStatesShow(rowIndex);
    setMappedStates(true);
  };

  const mappedStatesFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => stateHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          {cell.length}
        </button>
        {statesShow === rowIndex && viewMappedStates ? (
          <ViewMappedStates rowData={row} close={setMappedStates} />
        ) : null}
      </div>
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => actionHandler(rowIndex)}
          style={{ color: "blue", border: "1px solid blue", borderRadius: "5px" }}
        >
          Edit
        </button>
        {actionShow === rowIndex && isOpen ? (
          <EditArea updatedData={fetchReport} editData={row} closeModal={setIsOpen} />
        ) : null}
      </div>
    );
  };

  const exportData = areaData.map((row, index) => ({
    "S.No.": index + 1,
    "Country": row?.countryName ?? "",
    "Zone": row?.zone ?? "",
    "Area": row?.area ?? "",
    "Mapped Areas": row?.states ?? "",
  }));
  

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "40px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Country",
      dataField: "countryName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "100px" },
    },
    {
      text: "Zone",
      dataField: "zone",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "100px" },
    },
    {
      text: "Area",
      dataField: "area",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "100px" },
    },
    {
      text: "Mapped Areas",
      dataField: "states",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "100px" },
      classes: "cellsOverflow",
      formatExtraData: { viewMappedStates, statesShow },
      formatter: mappedStatesFormatter,
    },
    {
      text: "Action",
      headerAttrs: { width: "40px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    componentName: "",
    skuCode: "",
    vendorPartName: "",
    verifiedBy: "",
    createdDate: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = (e) => {
    setFormValue({
      componentName: "",
      skuCode: "",
      vendorPartName: "",
      verifiedBy: "",
      createdDate: "",
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Areas</span>
        </div>

        <ToolkitProvider keyField="id" data={areaData} columns={columns} search>
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(exportData, "Manage Area")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    id="abc"
                    onSubmit={submitHandler}
                    className="RA_block"
                    style={{ height: "120px", justifyContent: "flex-start" }}
                  >
                    <div className="bomfilter_fields">
                      <select
                        name="componentName"
                        value={formValue.componentName}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Component Name
                        </option>
                        {
                          uniquecomponentName.map((item) => (
                            <option>{item}</option>
                          ))
                        }
                      </select>

                      <select
                        name="skuCode"
                        value={formValue.skuCode}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          SKU Code
                        </option>
                        {
                          uniqueskuCode.map((item) => (
                            <option>{item}</option>
                          ))
                        }
                      </select>

                      <select
                        name="vendorPartName"
                        value={formValue.vendorPartName}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Vendor Part Name
                        </option>
                        {
                          uniquevendorPartName.map((item) => (
                            <option>{item}</option>
                          ))
                        }
                      </select>

                      <select
                        name="verifiedBy"
                        value={formValue.verifiedBy}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Verified By
                        </option>
                        {
                          uniqueverifiedBy.map((item) => (
                            <option>{item}</option>
                          ))
                        }
                      </select>

                      <input
                        type="text"
                        onFocus={(e) => (e.currentTarget.type = "date")}
                        onBlur={(e) => (e.currentTarget.type = "text")}
                        name="createdDate"
                        value={formValue.createdDate}
                        onChange={handleChange}
                        placeholder="Inventory Date"
                        autoComplete="off"
                      />

                      <div style={{ margin: "10px 0 0 770px" }}>
                        <button
                          type="submit"
                          id="apply"
                          style={{ width: "96px", height: "35px" }}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ width: "96px", height: "35px" }}
                        >
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

export default ManageAreas;
