import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import moment from "moment";

function InvoiceHistory({ rowData, closeModal }) {
  const [tableData, setTableData] = useState([]);
  const [formValue, setFormValue] = useState({
    from: "",
    to: "",
  });

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `dealer-module/api/v1/serviceCenters/sales/invoiceHistory?skuCode=${rowData.skuCode}&&from=${formValue.from}&&to=${formValue.to}`
      )
    );
    setTableData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);
  console.log(tableData);

  const handleInputChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      from: "",
      to: "",
    });
    const resp = await trackPromise(
      API.get(
        `dealer-module/api/v1/serviceCenters/sales/invoiceHistory?skuCode=${rowData.skuCode}`
      )
    );
    setTableData(resp);
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "850px", height: "400px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Invoice History</h3>
        </div>
        <br />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontFamily: "sans-serif",
            fontSize: "14px",
            fontWeight: "420",
          }}
        >
          <span>Service Center ID: {rowData.serviceCenterID}</span>
          <span>Service Center Name: {rowData.serviceCenterName}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontFamily: "sans-serif",
            fontSize: "14px",
            fontWeight: "420",
          }}
        >
          <span>Sold Qty.: {rowData.soldUnit.length}</span>
        </div>
        <br />

        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <div
            style={{
              display: "flex",
              marginTop: "6px",
              justifyContent: "space-between",
            }}
          >
            <label style={{ marginLeft: "14px" }}>Date (From)</label>
            <input
              type="date"
              name="from"
              placeholder="Invoice Date"
              className="input-config"
              value={formValue.from}
              style={{ margin: "18px 0px 0px -78px" }}
              onChange={(event) => handleInputChange(event)}
            />
            <label style={{ marginLeft: "14px" }}>Date (To)</label>
            <input
              type="date"
              name="to"
              placeholder="Invoice Date"
              className="input-config"
              value={formValue.to}
              style={{ margin: "18px 0px 0px -55px" }}
              onChange={(event) => handleInputChange(event)}
            />
            <div style={{ margin: "20px 0px 0px 160px" }}>
              <button
                style={{ height: "40px", width: "70px" }}
                onClick={fetchReport}
                id="apply"
              >
                Apply
              </button>
              <button
                style={{ height: "40px", width: "70px" }}
                onClick={clearFilter}
                id="clear"
              >
                Reset
              </button>
            </div>
          </div>
          <br />

          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
            }}
          >
            <div style={{ padding: "0px", border: "1px solid #cdcdcd" }}>
              <span style={{ width: "10%", padding: "10px" }}>S.No.</span>
              <span style={{ width: "45%", padding: "10px" }}>Invoice No.</span>
              <span style={{ width: "45%", padding: "10px" }}>
                Invoice Date
              </span>
            </div>

            <section
              style={{ width: "100%", height: "180px", overflow: "scroll" }}
            >
              {tableData.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      padding: "0px",
                      border: "1px solid #cdcdcd",
                      borderTop: "none",
                    }}
                  >
                    <span style={{ width: "10%", padding: "10px" }}>
                      {index + 1}
                    </span>
                    <span style={{ width: "45%", padding: "10px" }}>
                      {item.invoiceNo}
                    </span>
                    <span style={{ width: "45%", padding: "10px" }}>
                      {moment(item.invoiceDate).format("DD-MM-YYYY")}
                    </span>
                  </div>
                );
              })}
            </section>
          </section>
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default InvoiceHistory;
