import React, { useState } from "react";
import {
  faEdit,
  faBan,
  faTrash,
  faDotCircle,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RequestForProceeding from "./RequestForProceeding";
import ViewDetails from "./ViewDetails";
import ViewAndRequest from "./ViewAndRequest";
import PoViewDetails from "./PoViewDetails";

const Action = ({ updatedData, rowData, setIsOpen }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      requestForProceeding: "false",
      viewDetails: "false",
      viewAndRequest: "false",
      poViewDetails: "false",
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {rowData.status === "New Request"
        ? activeModal.requestForProceeding &&
        showModal && (
          <RequestForProceeding
            updatedData={updatedData}
            editData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}
      {rowData.status === "On Hold"
        ? activeModal.viewAndRequest &&
        showModal && (
          <ViewAndRequest
            updatedData={updatedData}
            editData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}
      {rowData.status === "PO Generated"
        ? activeModal.poViewDetails &&
        showModal && (
          <PoViewDetails
            updatedData={updatedData}
            editData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}
      {rowData.status === "Requisition Rejected"
        ? activeModal.viewDetails &&
        showModal && (
          <ViewDetails
            updatedData={updatedData}
            editData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}

      <div
        className="action"
        style={{ position: "relative" }}
        onBlur={() => setIsOpen(false)}
      >
        <div
          className="action"
          style={{ marginLeft: "-23px", marginTop: "0px", width: "235px" }}
        >
          {rowData.status === "New Request" ? (
            <div>
              {" "}
              <span id="requestForProceeding" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Request for Proceeding
              </span>
            </div>
          ) : (
            ""
          )}
          {rowData.status === "On Hold" ? (
            <div>
              {" "}
              <span id="viewAndRequest" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> View & Request
              </span>
            </div>
          ) : (
            ""
          )}
          {rowData.status === "Requisition Rejected" ? (
            <div>
              {" "}
              <span id="viewDetails" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> View Details
              </span>
            </div>
          ) : (
            ""
          )}
          {rowData.status === "PO Generated" ? (
            <div>
              {" "}
              <span id="poViewDetails" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> View Details
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Action;
