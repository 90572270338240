import React, { useEffect, useState } from "react";
import {
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import auth from "../../utils/auth";
import moment from "moment";
import AvailabilityQty from "../storeManagerInventory/component/AvailabilityQty";
// import SpareViewSpecs from "../../sales/actions/spareviewspecs";
import SpareViewSpecs from "../sales/actions/spareviewspecs";



function SkuWiseInventory({ inDashboard }) {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [openQty, setOpenQty] = useState();
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState();
  const [actionShow, setActionShow] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const [viewQty, setViewQty] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [formValue, setFormValue] = useState({
    skuCode: "",
    category: "",
    subCategory: "",
    sparePartname: "",
  });

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`serviceCenter-module/api/v1/inventory/spareInventory?skuCode=${formValue.skuCode}&
      category=${formValue.category}&subCategory=${formValue.subCategory}&sparePartname=${formValue.sparePartname}`)
    );
    console.log(resp);
    const extractedData = resp.map((item) => ({
      ...item._id,
      availableQty: item.availableQty,
      unitPrice: item._id.unitPrice,
      subTotal: item.subTotal,
      poDetails: item.poDetails,
    }));
    console.log(extractedData);
    setUsersData(extractedData);
  };
  useEffect(() => {
    fetchReport();
  }, []);


  const uniqueskuCode = [...new Set(UsersData.map((item) => item?.skuCode))];
  const uniquecategory = [...new Set(UsersData.map((item) => item?.category))];
  const uniquesubCategory = [
    ...new Set(UsersData.map((item) => item?.subCategory)),
  ];
  const uniquesparePartName = [
    ...new Set(UsersData.map((item) => item?.sparePartname)),
  ];
  const uniquepoNos = [
    ...new Set(
      UsersData.map((item) => item?.poDetails.map((item) => item?.poNo))
    ),
  ];
  console.log("------>", uniquepoNos);

  let finalArray = [];
  UsersData.map((item) => {
    finalArray.push({ ...item._id, ...item });
  });
  console.log(finalArray);



  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setActionShow(rowIndex);
    setOpen(true);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow == rowIndex && open ? (
          <SparesAction rowData={row} closeModal={setViewSpecs} />
        ) : null}
      </div>
    );
  };

  const specsHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setViewSpecs(true);
  };

  const availabilityHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setViewQty(true);
  };



  const specsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => specsHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          View Specs
        </button>
        {actionShow === rowIndex && viewSpecs ? (
          <SpareViewSpecs
            rowData={row}
            status={true}
            closeModal={setViewSpecs}
          />
        ) : null}
      </div>
    );
  };

  const availabilityFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => availabilityHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          <span>{cell}</span>
        </button>
        {actionShow === rowIndex && viewQty ? (
          <AvailabilityQty
            rowData={row}
            status={true}
            closeModal={setViewQty}
          />
        ) : null}
      </div>
    );
  };



  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      skuCode: "",
      category: "",
      subCategory: "",
      sparePartname: "",
    });
    const resp = await trackPromise(
      API.get(`storeManager-module/api/v1/inventory/sparesInventory`)
    );
    setUsersData(resp);
  };


  const handleClick = (e) => {
    setShow(!show);
  };


  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return moment(date).format("DD-MMM-YYYY");
  }

  const priceFormatter = (cell, row) => {
    // const currencySymbol = auth.getUserInfo().currencySymbol; // Get currency symbol dynamically
    const formattedPrice = (cell || 0).toLocaleString('en-IN', {
      minimumFractionDigits: 2, // Ensures two decimal places
      maximumFractionDigits: 2,
    });
    
    return `${formattedPrice}`;
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "SKU Code",
      dataField: "skuCode",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Spare Part Name",
      dataField: "sparePartname",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Category",
      dataField: "category",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Sub Category",
      dataField: "subCategory",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Specifications",
      dataField: "specifications",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatExtraData: { viewSpecs, actionShow },
      formatter: specsFormatter,
    },
    {
      text: "Available Qty.",
      dataField: "availableQty",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { viewQty, actionShow },
      formatter: availabilityFormatter,
    },
    {
      text: "Last Inventory Date",
      dataField: "lastInventoryDate",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: `Unit Price (${auth.getUserInfo().currencySymbol})`,
      dataField: "unitPrice",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter:priceFormatter
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Detailed Spares Inventory</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={""} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div
                    className="UHinputfields"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "85px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      marginRight: "655px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        SKU Code
                      </label>
                      <select
                        name="skuCode"
                        style={{ width: "100%" }}
                        value={formValue.skuCode}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          SKU Code
                        </option>
                        {uniqueskuCode.map((item) => (
                          <option>{item}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Category
                      </label>
                      <select
                        name="category"
                        style={{ width: "100%" }}
                        value={formValue.category}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Category
                        </option>
                        {uniquecategory.map((item) => (
                          <option>{item}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Sub Category
                      </label>
                      <select
                        name="subCategory"
                        style={{ width: "100%" }}
                        value={formValue.subCategory}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Sub-Category
                        </option>
                        {uniquesubCategory.map((item) => (
                          <option>{item}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Spare Part Name
                      </label>
                      <select
                        name="sparePartname"
                        style={{ width: "100%" }}
                        value={formValue.sparePartname}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Spare Part Name
                        </option>
                        {uniquesparePartName.map((item) => (
                          <option>{item}</option>
                        ))}
                      </select>
                    </div>
                    <button
                      onClick={fetchReport}
                      id="apply"
                      style={{ marginTop: "22px", marginLeft: "10px" }}
                    >
                      Apply
                    </button>
                    <button
                      onClick={clearFilter}
                      id="clear"
                      style={{ marginTop: "22px" }}
                    >
                      Clear Filter
                    </button>
                  </div>
                )}
                <br />
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>

      </div>
    </>
  );
}

export default SkuWiseInventory;
