import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../../utils/app-constants";
import "../../settings-tabs/tabs.scss";
import OnDemandhybridVehicle from "./OnDemandhybridVehicle";
import OnDemandstandardVehicle from "./OnDemandstandardVehicle";


const tabs = [
  {
    label: TabLabels.STANDARD_VEHICLE,
    Component: OnDemandstandardVehicle
  },
  {
    label: TabLabels.HYBRID_VEHICLE,
    Component: OnDemandhybridVehicle
  },
];

const OnDemandFinishedGoods = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default OnDemandFinishedGoods;