import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ROLES_MANAGEMENT_ROUTE_URL, SUBSCRIPTION_ROUTE_URL, USER_ROUTE_URL, OPERATIONS_SETTING_ROUTE_URL, ORGANIZATION_ROUTE_URL, VEHICLES_ROUTE_URL, VENDORS_ROUTE_URL, COMPONENTS_ROUTE_URL, OUTWARD_ROUTE_URL, ASSEMBLY_ROUTE_URL, BOM_ROUTE_URL, MAPPING_ROUTE_URL, BOM_COSTING_ROUTE_URL, ORDERS_ROUTE_URL, WORKORDER_ROUTE_URL, INVENTORY_ROUTE_URL, INWARD_ROUTE_URL, WO_SHOPFLOOR_ROUTE_URL, INVENTORY_SHOPFLOOR_ROUTE_URL, INVENTORY_INV_ROUTE_URL, APPROVAL_ROUTE_URL, STOCK_MANAGEMENT_ROUTE_URL, QUALITY_ROUTE_URL, WORKORDER_QC_ROUTE_URL, PRICING_ROUTE_URL, PO_REQUEST_ROUTE_URL, SALE_REQUEST_ROUTE_URL, INVENTORY_MANAGEMENT_ROUTE_URL, SALES_ROUTE_URL, BUSINESS_INSIGHTS_ROUTE_URL, FINANCE_INVENTORY_ROUTE_URL, FINANCE_RETURNS_ROUTE_URL, MASTER_CONFIGURATION_ROUTE_URL, SUPER_ADMIN_DASHBOARD_ROUTE_URL, QC_DASHBOARD, FINANCE_DASHBOARD_ROUTE_URL, PROCUREMENT_DASHBOARD_ROUTE_URL, PRODUCTION_DASHBOARD_ROUTE_URL, BUSINESS_USER_ROUTE_URL, VEHICLES_SPARES_ROUTE_URL, REQUISITION_ROUTE_URL, SHOWROOMS_ROUTE_URL, INVOICE_PAYMEMT_ROUTE_URL, DEALER_DASHBOARD_USER_ROUTE_URL, SERVICE_CENTER_ROUTE_URL, SERVICE_CENTER_DASHBOARD_ROUTE_URL, SERVICE_CENTER_SPARES_ROUTE_URL, SERVICE_CENTER_USER_ROUTE_URL, SERVICE_CENTER_ORDERS_ROUTE_URL, SERVICE_CENTER_INVENTORY_ROUTE_URL, SHOWROOM_ROUTE_URL, SHOWROOM_VEHICLE_BATTERIES_ROUTE_URL, SHOWROOM_ORDERS_ROUTE_URL, SHOWROOM_RETURNS_ROUTE_URL, SHOWROOM_INVENTORY_ROUTE_URL, SHOWROOM_CUSTOMER_SALES_ROUTE_URL,SHOWROOM_VEHICLE_WARRANTY, STORE_MANAGER_HOME_ROUTE_URL, STORE_MANAGER_SPARES_ROUTE_URL, STORE_MANAGER_ORDER_ROUTE_URL, STORE_MANAGER_INVENTORY_ROUTE_URL, STORE_MANAGER_SPARE_REQUEST_ROUTE_URL, STORE_MANAGER_RETURNS_ROUTE_URL, SERVICE_CENTER_JOBS_ROUTE_URL, SERVICE_CENTER_RETURNS_ROUTE_URL, INVENTORY_DASHBOARD_ROUTE_URL, SHOPFLOOR_DASHBOARD_ROUTE_URL, MANAGEMENT_DASHBOARD_ROUTE_URL, SYSTEM_ADMIN_HOME_ROUTE_URL,
  SYSTEM_ADMIN_SUBSCRIPTIONS_ROUTE_URL,
  SYSTEM_ADMIN_BUSINESS_ENTITY_ROUTE_URL,
  OPERATION_BUSINESS_ENTITIES,
  SYSTEM_ADMIN_INVOICE_AND_PAYMENT_ROUTE_URL,SYSTEM_ADMIN_CONFIGURATIONS, OPERATIONS_VEHICLE_AND_SPARES_URL, OPERATIONS_SALES_USER, OPERATIONS_DASHBOARD_ROUTE_URL, OPERATIONS_ZONE_MANAGEMENT_ROUTE_URL, OPERATIONS_BUSINESS_INVENTORY_AND_SALES_URL, OERATION_WARRANTY_AND_SERVICES, SERVICE_ADVISOR_HOME_ROUTE_URL,
  SERVICE_ADVISOR_SPARE_PARTS_URL,
  SERVICE_ADVISOR_SERVICE_JOBS,
  SERVICE_ADVISOR_SPARE_RETURNS, AREA_VEHICLE_SPARES_URL,
  AREA_SALES_DASHBOARD_ROUTE_URL, AREA_SALES_RETURNS_URL, AREA_SALES_ORDERS_ROUTE_URL, ZONAL_SALES_HOME_ROUTE_URL,
  ZONAL_SALES_USER_ROUTE_URL,
  ZONAL_SALES_VEHICLE_AND_SPARES_ROUTE_URL,
  ZONAL_SALES_ORDERS_ROUTE_URL,
  ZONAL_SALES_RETURNS_ROUTE_URL,
  ZONAL_SALES_BUSINESS_INVENTOR_ROUTE_URL,
  ZONAL_SALES_BUSINESS_SALES_ROUTE_URL,
  NATIONAL_SALES_HOME_ROUTE_URL,
  NATIONAL_SALES_USER_ROUTE_URL,
  NATIONAL_SALES_VEHICLE_AND_SPARES_ROUTE_URL,
  NATIONAL_SALES_ORDERS_ROUTE_URL,
  NATIONAL_SALES_RETURNS_ROUTE_URL,
  AREA_SALES_BUSINESS_INVENTOR_ROUTE_URL,
  AREA_SALES_BUSINESS_SALES_ROUTE_URL,
  DEALER_VEHICLE_WARRANTY,
  OPERATIONS_TEMPLATES,
  SUPPORT_ROUTE_URL
} from "../../routes/route-urls";
import images, { sidebarIcons } from "../../../utils/get-images";
import { SidebarLabels } from "../../../utils/app-constants";
import 'react-pro-sidebar/dist/css/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import "./sidebar.scss";
import auth from "../../../utils/auth";


const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleTrigger = () => setIsOpen(!isOpen);

  const location = useLocation();

  const superAdminOnly = navItem => (auth.isSuperAdmin() ? [navItem] : []);
  const superAdminSubAdminOnly = navItem => (auth.isSuperAdmin() || auth.isSubAdmin() ? [navItem] : []);
  const productionOnly = navItem => (auth.isProduction() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const procurementOnly = navItem => (auth.isProcurement() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const inventoryOnly = navItem => (auth.isInventory() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const shopfloorOnly = navItem => (auth.isShopfloor() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const managementOnly = navItem => (auth.isManagement() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const qcOnly = navItem => (auth.isQc() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const financeOnly = navItem => (auth.isFinance() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const dealerOnly = navItem => (auth.isDealer() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const serviceCenterOnly = navItem => (auth.isServiceCenter() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const showroomOnly = navItem => (auth.isShowroom() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const storeManagerOnly = navItem => (auth.isStoreManager() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const systemAdminOnly = navItem => (auth.isSystemAdmin() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const operationsOnly = navItem => (auth.isOperations() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const serviceAdvisorOnly = navItem => (auth.isServiceAdvisor() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const areaSalesOnly = navItem => (auth.isAreaSales() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const zonalSalesOnly = navItem => (auth.isZonalSales() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);
  const nationalSalesOnly = navItem => (auth.isNationalsales() && !auth.isSuperAdmin() && !auth.isSubAdmin() ? [navItem] : []);



  let structure = [
    // {
    //   label: SidebarLabels.HOME,
    //   link: HOMEDASHBOARD_ROUTE_URL,
    //   icon: <img src={sidebarIcons["Home.svg"]}/>
    // },
    ...superAdminSubAdminOnly({
      id: "SAD",
      label: SidebarLabels.HOME,
      link: SUPER_ADMIN_DASHBOARD_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...superAdminOnly({
      id: "SAM1",
      label: SidebarLabels.SUBSCRIPTION,
      link: SUBSCRIPTION_ROUTE_URL,
      icon: <img src={sidebarIcons["Subscription.svg"]} />
    }),
    ...superAdminSubAdminOnly({
      id: "SAM2",
      label: SidebarLabels.USER,
      link: USER_ROUTE_URL,
      icon: <img src={sidebarIcons["User.svg"]} />
    }),
    ...superAdminSubAdminOnly({
      id: "SAM3",
      label: SidebarLabels.ROLE_MANAGEMENT,
      link: ROLES_MANAGEMENT_ROUTE_URL,
      icon: <img src={sidebarIcons["RolesM.svg"]} />
    }),
    ...superAdminSubAdminOnly({
      id: "SAM4",
      label: SidebarLabels.MASTER_CONFIGURATION,
      link: MASTER_CONFIGURATION_ROUTE_URL,
      icon: <img src={sidebarIcons["masterConfigSetting.svg"]} />
    }),
    ...productionOnly({
      id: "PDD",
      label: SidebarLabels.HOME,
      link: PRODUCTION_DASHBOARD_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...productionOnly({
      id: "PDM1",
      label: SidebarLabels.VEHICLES,
      link: VEHICLES_ROUTE_URL,
      icon: <img src={sidebarIcons["Vehicles.svg"]} />
    }),
    // ...productionOnly({
    //   label: SidebarLabels.LABOUR,
    //   link: LABOUR_ROUTE_URL,
    //   icon: <img src={sidebarIcons["Vehicles.svg"]}/>
    // }),
    ...productionOnly({
      id: "PDM2",
      label: SidebarLabels.ASSEMBLY,
      link: ASSEMBLY_ROUTE_URL,
      icon: <img src={sidebarIcons["Assembly.svg"]} />
    }),
    ...productionOnly({
      id: "PDM3",
      label: SidebarLabels.BOM,
      link: BOM_ROUTE_URL,
      icon: <img src={sidebarIcons["Bom.svg"]} />
    }),
    ...productionOnly({
      id: "PDM4",
      label: SidebarLabels.WORKORDER,
      link: WORKORDER_ROUTE_URL,
      icon: <img src={sidebarIcons["Workorder.svg"]} />
    }),
    ...productionOnly({
      id: "PDM5",
      label: SidebarLabels.INVENTORY,
      link: INVENTORY_ROUTE_URL,
      icon: <img src={sidebarIcons["Inventory.svg"]} />
    }),
    ...procurementOnly({
      id: "PRD",
      label: SidebarLabels.HOME,
      link: PROCUREMENT_DASHBOARD_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...procurementOnly({
      id: "PRM1",
      label: SidebarLabels.VENDORS,
      link: VENDORS_ROUTE_URL,
      icon: <img src={sidebarIcons["Vendors.svg"]} />
    }),
    ...procurementOnly({
      id: "PRM2",
      label: SidebarLabels.COMPONENTS,
      link: COMPONENTS_ROUTE_URL,
      icon: <img src={sidebarIcons["Components.svg"]} />
    }),
    ...procurementOnly({
      id: "PRM3",
      label: SidebarLabels.MAPPING,
      link: MAPPING_ROUTE_URL,
      icon: <img src={sidebarIcons["Mapping.svg"]} />
    }),
    ...procurementOnly({
      id: "PRM4",
      label: SidebarLabels.BOM_COSTING,
      link: BOM_COSTING_ROUTE_URL,
      icon: <img src={sidebarIcons["BomCosting.svg"]} />
    }),
    ...procurementOnly({
      id: "PRM5",
      label: SidebarLabels.ORDERS,
      link: ORDERS_ROUTE_URL,
      icon: <img src={sidebarIcons["Orders.svg"]} />
    }),
    ...inventoryOnly({
      id: "IND",
      label: SidebarLabels.HOME,
      link: INVENTORY_DASHBOARD_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...inventoryOnly({
      id: "INM1",
      label: SidebarLabels.INWARD,
      link: INWARD_ROUTE_URL,
      icon: <img src={sidebarIcons["Inward.svg"]} />
    }),
    ...inventoryOnly({
      id: "INM2",
      label: SidebarLabels.INVENTORY_INV,
      link: INVENTORY_INV_ROUTE_URL,
      icon: <img src={sidebarIcons["Inventory.svg"]} />
    }),
    ...inventoryOnly({
      id: "INM3",
      label: SidebarLabels.STOCKMANAGEMENT,
      link: STOCK_MANAGEMENT_ROUTE_URL,
      icon: <img src={sidebarIcons["StockManagement.svg"]} />
    }),
    ...inventoryOnly({
      id: "INM4",
      label: SidebarLabels.OUTWARD,
      link: OUTWARD_ROUTE_URL,
      icon: <img src={sidebarIcons["Outward.svg"]} />
    }),
    ...shopfloorOnly({
      id: "SFD",
      label: SidebarLabels.HOME,
      link: SHOPFLOOR_DASHBOARD_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...shopfloorOnly({
      id: "SFM1",
      label: SidebarLabels.WORKORDER,
      link: WO_SHOPFLOOR_ROUTE_URL,
      icon: <img src={sidebarIcons["Workorder.svg"]} />
    }),
    ...shopfloorOnly({
      id: "SFM2",
      label: SidebarLabels.INVENTORY,
      link: INVENTORY_SHOPFLOOR_ROUTE_URL,
      icon: <img src={sidebarIcons["Inventory.svg"]} />
    }),
    ...managementOnly({
      id: "MGD",
      label: SidebarLabels.HOME,
      link: MANAGEMENT_DASHBOARD_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...managementOnly({
      id: "MGM1",
      label: SidebarLabels.APPROVAL,
      link: APPROVAL_ROUTE_URL,
      icon: <img src={sidebarIcons["approval.svg"]} />
    }),
    ...managementOnly({
      id: "MGM2",
      label: SidebarLabels.WORKORDERQC,
      link: WORKORDER_QC_ROUTE_URL,
      icon: <img src={sidebarIcons["worder.svg"]} />
    }),
    ...managementOnly({
      id: "MGM3",
      label: SidebarLabels.INVENTORY,
      link: INVENTORY_MANAGEMENT_ROUTE_URL,
      icon: <img src={sidebarIcons["inventoryM.svg"]} />
    }),
    ...managementOnly({
      id: "MGM4",
      label: SidebarLabels.SALES,
      link: SALES_ROUTE_URL,
      icon: <img src={sidebarIcons["sales.svg"]} />
    }),
    ...managementOnly({
      id: "MGM5",
      label: SidebarLabels.BUSINESS_INSIGHTS,
      link: BUSINESS_INSIGHTS_ROUTE_URL,
      icon: <img src={sidebarIcons["bussiness.svg"]} />
    }),
    ...qcOnly({
      id: "QCD",
      label: SidebarLabels.HOME,
      link: QC_DASHBOARD,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...qcOnly({
      id: "QCM1",
      label: SidebarLabels.VERIFICATION,
      link: QUALITY_ROUTE_URL,
      icon: <img src={sidebarIcons["Inventory.svg"]} />
    }),
    ...financeOnly({
      id: "FID",
      label: SidebarLabels.HOME,
      link: FINANCE_DASHBOARD_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...financeOnly({
      id: "FIM1",
      label: SidebarLabels.PRICING,
      link: PRICING_ROUTE_URL,
      icon: <img src={sidebarIcons["financePricing.svg"]} />
    }),
    ...financeOnly({
      id: "FIM2",
      label: SidebarLabels.PO_REQUEST,
      link: PO_REQUEST_ROUTE_URL,
      icon: <img src={sidebarIcons["poReqs.svg"]} />
    }),
    ...financeOnly({
      id: "FIM3",
      label: SidebarLabels.SALE_REQUEST,
      link: SALE_REQUEST_ROUTE_URL,
      icon: <img src={sidebarIcons["saleReqs.svg"]} />
    }),
    ...financeOnly({
      id: "FIM4",
      label: SidebarLabels.INVENTORY,
      link: FINANCE_INVENTORY_ROUTE_URL,
      icon: <img src={sidebarIcons["Inventory.svg"]} />
    }),
    ...financeOnly({
      id: "FIM5",
      label: SidebarLabels.FINANCE_RETURNS,
      link: FINANCE_RETURNS_ROUTE_URL,
      icon: <img src={sidebarIcons["serviceReturns.svg"]} />
    }),
    ...dealerOnly({
      id: "DED",
      label: SidebarLabels.HOME,
      link: DEALER_DASHBOARD_USER_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...dealerOnly({
      id: "DEM1",
      label: SidebarLabels.BUSINESS_USERS,
      link: BUSINESS_USER_ROUTE_URL,
      icon: <img src={sidebarIcons["businessIcon.svg"]} />
    }),
    ...dealerOnly({
      id: "DEM2",
      label: SidebarLabels.VEHICLE_SPARES,
      link: VEHICLES_SPARES_ROUTE_URL,
      icon: <img src={sidebarIcons["vehicle&spares.svg"]} />
    }),
    ...dealerOnly({
      id: "DEM3",
      label: SidebarLabels.REQUISITION_ORDERS,
      link: REQUISITION_ROUTE_URL,
      icon: <img src={sidebarIcons["requisition.svg"]} />
    }),
    ...dealerOnly({
      id: "DEM4",
      label: SidebarLabels.SHOWROOMS,
      link: SHOWROOMS_ROUTE_URL,
      icon: <img src={sidebarIcons["showroom.svg"]} />
    }),
    ...dealerOnly({
      id: "DEM5",
      label: SidebarLabels.SERVICE_CENTER,
      link: SERVICE_CENTER_ROUTE_URL,
      icon: <img src={sidebarIcons["center.svg"]} />
    }),
    ...dealerOnly({
      id: "DEM6",
      label: SidebarLabels.VEHICLE_WARRANTY,
      link: DEALER_VEHICLE_WARRANTY,
      icon: <img src={sidebarIcons["invoices.svg"]} />
    }),
    ...serviceCenterOnly({
      id: "SVCD",
      label: SidebarLabels.HOME,
      link: SERVICE_CENTER_DASHBOARD_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...serviceCenterOnly({
      id: "SVCM1",
      label: SidebarLabels.USER,
      link: SERVICE_CENTER_USER_ROUTE_URL,
      icon: <img src={sidebarIcons["User.svg"]} />
    }),
    ...serviceCenterOnly({
      id: "SVCM2",
      label: SidebarLabels.SPARE_PARTS,
      link: SERVICE_CENTER_SPARES_ROUTE_URL,
      icon: <img src={sidebarIcons["center.svg"]} />
    }),
    ...serviceCenterOnly({
      id: "SVCM3",
      label: SidebarLabels.ORDERS,
      link: SERVICE_CENTER_ORDERS_ROUTE_URL,
      icon: <img src={sidebarIcons["serviceOrders.svg"]} />
    }),
    ...serviceCenterOnly({
      id: "SVCM4",
      label: SidebarLabels.INVENTORY,
      link: SERVICE_CENTER_INVENTORY_ROUTE_URL,
      icon: <img src={sidebarIcons["Inventory.svg"]} />
    }),
    ...serviceCenterOnly({
      id: "SVCM5",
      label: SidebarLabels.JOBS,
      link: SERVICE_CENTER_JOBS_ROUTE_URL,
      icon: <img src={sidebarIcons["Bom.svg"]} />
    }),
    ...serviceCenterOnly({
      id: "SVCM6",
      label: SidebarLabels.RETURNS,
      link: SERVICE_CENTER_RETURNS_ROUTE_URL,
      icon: <img src={sidebarIcons["serviceReturns.svg"]} />
    }),
    ...showroomOnly({
      id: "SHOD",
      label: SidebarLabels.HOME,
      link: SHOWROOM_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...showroomOnly({
      id: "SHOM1",
      label: SidebarLabels.SHOWROOM_VEHICLE_BATTERIES,
      link: SHOWROOM_VEHICLE_BATTERIES_ROUTE_URL,
      icon: <img src={sidebarIcons["vehicle&spares.svg"]} />
    }),
    ...showroomOnly({
      id: "SHOM2",
      label: SidebarLabels.ORDERS,
      link: SHOWROOM_ORDERS_ROUTE_URL,
      icon: <img src={sidebarIcons["requisition.svg"]} />
    }),
    ...showroomOnly({
      id: "SHOM3",
      label: SidebarLabels.INVENTORY,
      link: SHOWROOM_INVENTORY_ROUTE_URL,
      icon: <img src={sidebarIcons["Inventory.svg"]} />
    }),
    ...showroomOnly({
      id: "SHOM4",
      label: SidebarLabels.CUSTOMER_SALES,
      link: SHOWROOM_CUSTOMER_SALES_ROUTE_URL,
      icon: <img src={sidebarIcons["User.svg"]} />
    }),
    ...showroomOnly({
      id: "SHOM5",
      label: SidebarLabels.FINANCE_RETURNS,
      link: SHOWROOM_RETURNS_ROUTE_URL,
      icon: <img src={sidebarIcons["serviceReturns.svg"]} />
    }),
    ...showroomOnly({
      id: "SHOM6",
      label: SidebarLabels.VEHICLE_WARRANTY,
      link: SHOWROOM_VEHICLE_WARRANTY,
      icon: <img src={sidebarIcons["serviceReturns.svg"]} />
    }),
    ...storeManagerOnly({
      id: "STMD",
      label: SidebarLabels.HOME,
      link: STORE_MANAGER_HOME_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...storeManagerOnly({
      id: "STMM1",
      label: SidebarLabels.SPARE_PARTS,
      link: STORE_MANAGER_SPARES_ROUTE_URL,
      icon: <img src={sidebarIcons["center.svg"]} />
    }),
    ...storeManagerOnly({
      id: "STMM2",
      label: SidebarLabels.ORDERS,
      link: STORE_MANAGER_ORDER_ROUTE_URL,
      icon: <img src={sidebarIcons["requisition.svg"]} />
    }),
    ...storeManagerOnly({
      id: "STMM3",
      label: SidebarLabels.INVENTORY,
      link: STORE_MANAGER_INVENTORY_ROUTE_URL,
      icon: <img src={sidebarIcons["Inventory.svg"]} />
    }),
    ...storeManagerOnly({
      id: "STMM4",
      label: SidebarLabels.SPARE_REQUEST,
      link: STORE_MANAGER_SPARE_REQUEST_ROUTE_URL,
      icon: <img src={sidebarIcons["Bom.svg"]} />
    }),
    ...storeManagerOnly({
      id: "STMM5",
      label: SidebarLabels.RETURNS,
      link: STORE_MANAGER_RETURNS_ROUTE_URL,
      icon: <img src={sidebarIcons["serviceReturns.svg"]} />
    }),
    ...systemAdminOnly({
      id: "SYD",
      label: SidebarLabels.HOME,
      link: SYSTEM_ADMIN_HOME_ROUTE_URL,
      icon: <img src={sidebarIcons["SystemAdminHome.svg"]} />
    }),
    ...systemAdminOnly({
      id: "SYM1",
      label: SidebarLabels.SUBSCRIPTIONS,
      link: SYSTEM_ADMIN_SUBSCRIPTIONS_ROUTE_URL,
      icon: <img src={sidebarIcons["SystemAdminSubscription.svg"]} />
    }),
    ...systemAdminOnly({
      id: "SYM2",
      label: SidebarLabels.BUSINESS_ENTITIES,
      link: SYSTEM_ADMIN_BUSINESS_ENTITY_ROUTE_URL,
      icon: <img src={sidebarIcons["sysAdminBusiness.svg"]} />
    }),
    ...systemAdminOnly({
      id: "SYM3",
      label: SidebarLabels.INVOICES_PAYMENTS,
      link: SYSTEM_ADMIN_INVOICE_AND_PAYMENT_ROUTE_URL,
      icon: <img src={sidebarIcons["sysInvoiceandPayment.svg"]} />
    }),
    ...systemAdminOnly({
      id: "SYM4",
      label: SidebarLabels.CONFIGURATION,
      link: SYSTEM_ADMIN_CONFIGURATIONS,
      icon: <img src={sidebarIcons["sysInvoiceandPayment.svg"]} />
    }),
    ...operationsOnly({
      id: "OPD",
      label: SidebarLabels.HOME,
      link: OPERATIONS_DASHBOARD_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...operationsOnly({
      id: "OPM1",
      label: SidebarLabels.ZONE_MANAGEMENT,
      link: OPERATIONS_ZONE_MANAGEMENT_ROUTE_URL,
      icon: <img src={sidebarIcons["Components.svg"]} />
    }),
    ...operationsOnly({
      id: "OPM2",
      label: SidebarLabels.SALES_USER,
      link: OPERATIONS_SALES_USER,
      icon: <img src={sidebarIcons["requisition.svg"]} />
    }),
    ...operationsOnly({
      id: "OPM3",
      label: SidebarLabels.BUSINESS_ENTITIES,
      link: OPERATION_BUSINESS_ENTITIES,
      icon: <img src={sidebarIcons["sysAdminBusiness.svg"]} />
    }),
    ...operationsOnly({
      id: "OPM4",
      label: SidebarLabels.VEHICLES_AND_SPARES,
      link: OPERATIONS_VEHICLE_AND_SPARES_URL,
      icon: <img src={sidebarIcons["Vehicles.svg"]} />
    }),
    ...operationsOnly({
      id: "OPM5",
      label: SidebarLabels.WARRANTY_AND_SERVICES,
      link: OERATION_WARRANTY_AND_SERVICES,
      icon: <img src={sidebarIcons["Bom.svg"]} />
    }),
    // ...operationsOnly({
    //   id: "OPM6",
    //   label: SidebarLabels.BUSINESS_INVENTORY_AND_SALES,
    //   link: OPERATIONS_BUSINESS_INVENTORY_AND_SALES_URL,
    //   icon: <img src={sidebarIcons["Home.svg"]} />
    // }),
    ...operationsOnly({
      id: "OPM7",
      label: SidebarLabels.TEMPLATES,
      link: OPERATIONS_TEMPLATES,
      icon: <img src={sidebarIcons["Bom.svg"]} />
    }),
    ...serviceAdvisorOnly({
      id: "SVAD",
      label: SidebarLabels.HOME,
      link: SERVICE_ADVISOR_HOME_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...serviceAdvisorOnly({
      id: "SVAM1",
      label: SidebarLabels.SPARE_PARTS,
      link: SERVICE_ADVISOR_SPARE_PARTS_URL,
      icon: <img src={sidebarIcons["masterConfigSetting.svg"]} />
    }),
    ...serviceAdvisorOnly({
      id: "SVAM2",
      label: SidebarLabels.SERVICE_JOBS,
      link: SERVICE_ADVISOR_SERVICE_JOBS,
      icon: <img src={sidebarIcons["StockManagement.svg"]} />
    }),
    ...serviceAdvisorOnly({
      id: "SVAM3",
      label: SidebarLabels.SERVICE_ADVISOR_SPARE_RETURNS,
      link: SERVICE_ADVISOR_SPARE_RETURNS,
      icon: <img src={sidebarIcons["serviceReturns.svg"]} />
    }),
    ...areaSalesOnly({
      id: "ASMD",
      label: SidebarLabels.HOME,
      link: AREA_SALES_DASHBOARD_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...areaSalesOnly({
      id: "ASM1",
      label: SidebarLabels.VEHICLE_AND_SPARES,
      link: AREA_VEHICLE_SPARES_URL,
      icon: <img src={sidebarIcons["Vehicles.svg"]} />
    }),
    ...areaSalesOnly({
      id: "ASM2",
      label: SidebarLabels.ORDERS,
      link: AREA_SALES_ORDERS_ROUTE_URL,
      icon: <img src={sidebarIcons["requisition.svg"]} />
    }),
    ...areaSalesOnly({
      id: "ASM3",
      label: SidebarLabels.RETURNS,
      link: AREA_SALES_RETURNS_URL,
      icon: <img src={sidebarIcons["StockManagement.svg"]} />
    }),
    ...areaSalesOnly({
      id: "ASM4",
      label: SidebarLabels.BUSINESS_INVENTORY,
      link: AREA_SALES_BUSINESS_INVENTOR_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...areaSalesOnly({
      id: "ASM5",
      label: SidebarLabels.BUSINESS_SALES,
      link: AREA_SALES_BUSINESS_SALES_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...zonalSalesOnly({
      id: "ZSMD",
      label: SidebarLabels.HOME,
      link: ZONAL_SALES_HOME_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...zonalSalesOnly({
      id: "ZSMM1",
      label: SidebarLabels.USER,
      link: ZONAL_SALES_USER_ROUTE_URL,
      icon: <img src={sidebarIcons["User.svg"]} />
    }),
    ...zonalSalesOnly({
      id: "ZSMM2",
      label: SidebarLabels.VEHICLES_AND_SPARES,
      link: ZONAL_SALES_VEHICLE_AND_SPARES_ROUTE_URL,
      icon: <img src={sidebarIcons["Vehicles.svg"]} />
    }),
    ...zonalSalesOnly({
      id: "ZSMM3",
      label: SidebarLabels.ORDERS,
      link: ZONAL_SALES_ORDERS_ROUTE_URL,
      icon: <img src={sidebarIcons["requisition.svg"]} />
    }),
    ...zonalSalesOnly({
      id: "ZSMM4",
      label: SidebarLabels.RETURNS,
      link: ZONAL_SALES_RETURNS_ROUTE_URL,
      icon: <img src={sidebarIcons["StockManagement.svg"]} />
    }),
    ...zonalSalesOnly({
      id: "ZSMM5",
      label: SidebarLabels.BUSINESS_INVENTORY,
      link: ZONAL_SALES_BUSINESS_INVENTOR_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...zonalSalesOnly({
      id: "ZSMM6",
      label: SidebarLabels.BUSINESS_SALES,
      link: ZONAL_SALES_BUSINESS_SALES_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),

    ...nationalSalesOnly({
      id: "NSMD",
      label: SidebarLabels.HOME,
      link: NATIONAL_SALES_HOME_ROUTE_URL,
      icon: <img src={sidebarIcons["Home.svg"]} />
    }),
    ...nationalSalesOnly({
      id: "NSMM1",
      label: SidebarLabels.USER,
      link: NATIONAL_SALES_USER_ROUTE_URL,
      icon: <img src={sidebarIcons["User.svg"]} />
    }),
    ...nationalSalesOnly({
      id: "NSMM2",
      label: SidebarLabels.VEHICLES_AND_SPARES,
      link: NATIONAL_SALES_VEHICLE_AND_SPARES_ROUTE_URL,
      icon: <img src={sidebarIcons["Vehicles.svg"]} />
    }),
    ...nationalSalesOnly({
      id: "NSMM3",
      label: SidebarLabels.ORDERS,
      link: NATIONAL_SALES_ORDERS_ROUTE_URL,
      icon: <img src={sidebarIcons["requisition.svg"]} />
    }),
    ...nationalSalesOnly({
      id: "NSMM4",
      label: SidebarLabels.RETURNS,
      link: NATIONAL_SALES_RETURNS_ROUTE_URL,
      icon: <img src={sidebarIcons["StockManagement.svg"]} />
    }),

  ];


  return (
    <>
      <div className="page">
        <div className={`sidebar${isOpen ? ' sidebar--open' : ''}`}>
          <div className="main-logo">
            <Link style={{ marginTop: "4%" }} to={`/app/${auth.getUserInfo().role}`}>
              <img src={isOpen ? images["electriseLogo.svg"] : images["sidebarLogo.svg"]} alt="logo" />
            </Link>
          </div>
          <div className="trigger " onClick={handleTrigger}>
            <div className="collapse-btn">
              <FontAwesomeIcon icon={isOpen ? faBars : faBars} />
            </div>
            <div className="supradmn"> {isOpen ? <h2>{auth.getUserInfo().role}</h2> : null}</div>
          </div>
          {structure.map((item, i) => {
            const isLinkActive = location.pathname === item.link;
            let menuIds = auth.getUserInfo().menuID.map(menu => menu.ID);
            if (!menuIds.includes(item.id)) {
            } else {
              return (
                <div key={i}>
                  <div id="up" style={isLinkActive ? { display: 'block' } : { display: 'none' }} className={isOpen ? '' : 'closeWidth'}></div>
                  <div className="sidebar-position">

                    <Link to={item.link} className={` ${isLinkActive ? "active-menu" : ""}`}>
                      <div className="sidebar-position">
                        <div style={{ width: '22px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item.icon} </div>
                        <span>{item.label}</span>
                      </div>
                    </Link>
                  </div>
                  <div id="down" style={isLinkActive ? { display: 'block' } : { display: 'none' }} className={isOpen ? '' : 'closeWidth'}></div>
                </div>
              )
            }
          })
          }
          {auth.isSuperAdmin() ?
            <footer className="sidebar-footer">
              <div className="support-sec">
                <Link style={{ display: "flex" }} to={ORGANIZATION_ROUTE_URL}><img src={sidebarIcons["MainSettingsIcon.svg"]} /><div style={{ fontSize: "15px", fontWeight: "600", color: "#fff", marginLeft: "60%" }}>Settings</div></Link>
                <br></br>
                <Link style={{ display: "flex" }} to={SUPPORT_ROUTE_URL}><img src={sidebarIcons["HeadphonesIcon.svg"]} /><div style={{ fontSize: "15px", fontWeight: "600", color: "#fff", marginLeft: "60%" }}>Support</div></Link>
              </div>
            </footer> : ""}
          {/* {auth.isOperations() ?
            <footer className="sidebar-footer">
              <div className="support-sec">
                <Link style={{ display: "flex" }} to={OPERATIONS_SETTING_ROUTE_URL}><img src={sidebarIcons["MainSettingsIcon.svg"]} /><div style={{ fontSize: "15px", fontWeight: "600", color: "#fff", marginLeft: "60%" }}>Settings</div></Link> <br />
                <Link style={{ display: "flex" }} ><img src={sidebarIcons["HeadphonesIcon.svg"]} /><div style={{ fontSize: "15px", fontWeight: "600", color: "#fff", marginLeft: "60%" }}>Support</div></Link>
              </div>
            </footer> : ""} */}
        </div>
      </div>
    </>
  );
};


export default Sidebar;

