import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SubscribedPlann from "./SubscribedPlann";
import SubscribePlann from "./SubscribePlann";

const Tab = ({ children, label, tabs}) => {

  const [showPlan, setShowPlan] = useState(0);
  const [activeTab, setActiveTab] = useState("Current Plan");


  const location = useLocation();
  const upPlan = location.state;

  useEffect(()=>{
    if(upPlan===true){
     setShowPlan(1);
     setActiveTab("Upgrade Plan")
    }
   },[upPlan])



  const tabClickHandler = (label) => {
    if (label === "Current Plan") {
      setShowPlan(0);
      setActiveTab(label);
    } else if (label === "Upgrade Plan") {
      setShowPlan(1);
      setActiveTab(label);
    }
  };




  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* {children}*/}

        {tabs.map((item) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  backgroundColor: activeTab === item.label ? "#0a18a3" : "#f5f5f0",
                  color: "#373737",
                  padding: "2px",
                  fontSize: "16px",
                  cursor: "pointer"
                }}
              >
              </div>
              <div
                style={{
                  //backgroundColor: activeTab === item.label ? "#f5f5f0" : "#f5f5f0",
                  backgroundImage: activeTab === item.label ? "linear-gradient(to bottom, rgb(184, 189, 255), rgb(245, 245, 240))" : "linear-gradient(to right, #f5f5f0, #f5f5f0)",
                  color: activeTab === item.label ? "#0a18a3" : "#373737",
                  padding: "6px",
                  cursor: "pointer",
                  marginBottom: "10px",
                  fontSize: "16px"
                }}
                onClick={() => tabClickHandler(item.label)}>
                {item.label}
              </div>
            </div>
          );
        })}


      </div>
      <div>
        {showPlan === 0 ? (
          <SubscribedPlann setPlanShow={setShowPlan} setActiveTab={setActiveTab} />
        ) : showPlan === 1 ? (
          <SubscribePlann setPlanShow={setShowPlan} />
        ) : null}
      </div>
    </div>
  );
};

export default Tab;
