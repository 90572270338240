import React, { Suspense } from "react";
import { Switch, Redirect, Route, BrowserRouter } from "react-router-dom";
import { NotFoundPage } from "../../../pages";
import routes from "../routes-config";
import RouteWithSubRoutes from "./route-with-subroutes";
// import {BeatLoader} from 'react-spinners'
import "./app-route.scss"

const AppRoutes = () => {
	const fallbackStyles = {
		// position:"absolute",
		// top:"50%",
		// left:"50%",
		// marginRight:"-50%",
		// transform : 'translate(-50%, -50%)',

		display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
	}

	return (
		<Suspense fallback={<div style={fallbackStyles}><div className="loading-indicator"></div></div>}>
			<Switch>
				<Redirect exact from='/' to='/login' />
				{routes.map((route, i) => (
					<RouteWithSubRoutes key={i} {...route} />
				))}
				<Route path="*" component={NotFoundPage} />
			</Switch>
		</Suspense>
	);
};

export default AppRoutes;
