import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBalanceScaleLeft,
  faCheckCircle,
  faSave,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import VerifyModal from "./verifyModal";
import auth from "../../utils/auth";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";

const BusinessLocation = ({ closeModal, locationType, updatedData }) => {
  const [showVerify, setVerify] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [selectedStdCode, setStdCode] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/users/getAllCountries`)
    );
    console.log(resp);
    setCountryData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const [blankForm, setBlankForm] = useState({
    locationType: locationType,
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    isd: "",
    std: "",
    businessContactNo: "",
    timezone: "",
    currency: "",
    mobileNumber: "",
  });

  const FinalBody = {
    locationType: locationType,
    address1: blankForm.address1,
    address2: blankForm.address2,
    country: blankForm.country,
    state: selectedState.stateName,
    city: blankForm.city,
    zipcode: blankForm.zipcode,
    isd: selectedCountry.phoneCode,
    std: selectedStdCode,
    businessContactNo: blankForm.businessContactNo,
    mobileNumber: blankForm.mobileNumber,
    timezone:
      selectedCountry.timezones &&
      selectedCountry.timezones.gmtOffsetName +
        " " +
        selectedCountry.timezones.abbreviation,
    currency: selectedCountry.currency,
  };

  console.log(blankForm);

  const handleInputChange = (event) => {
    if (event.target.name == "city") {
      let std = selectedCity.find(
        (std) => std.PostOfficeName == event.target.value
      );
      console.log(std.StdCode);
      setStdCode(std.StdCode);
    }
    setBlankForm({ ...blankForm, [event.target.name]: event.target.value });
  };

  const handleCountryChange = async (event) => {
    setBlankForm({ ...blankForm, [event.target.name]: event.target.value });
    let countryName = event.target.value;
    const resp = await trackPromise(
      API.get(
        `admin-module/api/v1/users/getCountryDataByID?countryName=${countryName}`
      )
    );
    setSelectedCountry(resp[0]);
  };

  const handleZipcodeChange = async (event) => {
    setBlankForm({ ...blankForm, [event.target.name]: event.target.value });
    let zipCode = event.target.value;
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/users/getDataByZipCode?zipcode=${zipCode}`)
    );
    setSelectedState(resp.state[0]);
    setSelectedCity(resp.city);
  };

  const validateInputs = () => {
    // Validate address1
    if (!blankForm.address1) {
      errorNotification("Address is required.");
      return false;
    }

    // Validate country
    if (!blankForm.country) {
      errorNotification("Country is required.");
      return false;
    }

    // Validate city
    if (!blankForm.city) {
      errorNotification("City is required.");
      return false;
    }

    // Validate state
    if (!selectedState.stateName) {
      errorNotification("State/Province is required.");
      return false;
    }

    // Validate zipcode
    if (!blankForm.zipcode) {
      errorNotification("Zipcode/Postal Code is required.");
      return false;
    }

    // Validate zipcode format (must be exactly 6 digits)
    if (!/^\d{6}$/.test(blankForm.zipcode)) {
      errorNotification("Zipcode/Postal Code must be exactly 6 digits.");
      return false;
    }

    // Validate business contact number or mobile number
    if (!blankForm.businessContactNo && !blankForm.mobileNumber) {
      errorNotification(
        "Please provide either Business Contact Number or Mobile Number."
      );
      return false;
    }

    // Validate address1 format
    if (!/^[a-zA-Z0-9\s,'-]*$/.test(blankForm.address1)) {
      errorNotification(
        "Address must contain only alphanumeric characters and special characters like , ' -"
      );
      return false;
    }

    // Validate address2 format if provided
    if (blankForm.address2 && !/^[a-zA-Z0-9\s,'-]*$/.test(blankForm.address2)) {
      errorNotification(
        "Address (Optional) must contain only alphanumeric characters and special characters like , ' -"
      );
      return false;
    }

    // Validate country format
    if (!/^[a-zA-Z\s]*$/.test(blankForm.country)) {
      errorNotification("Country must contain only alphabets and spaces.");
      return false;
    }

    // Validate city format
    if (!/^[a-zA-Z\s]*$/.test(blankForm.city)) {
      errorNotification("City must contain only alphabets and spaces.");
      return false;
    }

    // Validate state format
    if (!/^[a-zA-Z\s]*$/.test(selectedState.stateName)) {
      errorNotification(
        "State/Province must contain only alphabets and spaces."
      );
      return false;
    }

    // Validate business contact number format
    if (
      blankForm.businessContactNo &&
      !/^[0-9]*$/.test(blankForm.businessContactNo)
    ) {
      errorNotification("Business Contact Number must contain only digits.");
      return false;
    }

    // Validate mobile number format
    if (blankForm.mobileNumber && !/^[0-9]*$/.test(blankForm.mobileNumber)) {
      errorNotification("Mobile Number must contain only digits.");
      return false;
    }

    return true;
  };

  const submitHandler = () => {
    if (!validateInputs()) {
      return;
    }

    if (!blankForm.businessContactNo && !blankForm.mobileNumber) {
      errorNotification(
        "Please provide either Business Contact Number or Mobile Number."
      );
    } else {
      setVerify(true);
    }
  };
  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "765px", height: "450px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Add Business Location</h3>
          </div>

          <div className="body" style={{ height: "340px", marginTop: "25px" }}>
            <div style={{ display: "flex", width: "100%" }}>
              <label style={{ fontSize: "13px", fontWeight: "500" }}>
                Address<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                style={{ margin: "19px 0px 5px -57px" }}
                className="input-config"
                type="text"
                name="address1"
                value={blankForm.address1}
                placeholder="Address"
                onChange={(event) => handleInputChange(event)}
              />
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginLeft: "14px",
                  width: "18%",
                }}
              >
                Address <span>(Optional)</span>
              </label>
              <br />
              <input
                style={{ margin: "19px 0px 5px -136px" }}
                className="input-config"
                type="text"
                name="address2"
                value={blankForm.address2}
                placeholder="Address"
                onChange={(event) => handleInputChange(event)}
              />
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginLeft: "14px",
                  width: "18%",
                }}
              >
                Country <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                style={{ margin: "19px 0px 5px -136px" }}
                required={true}
                name="country"
                value={selectedCountry.countryName}
                className="input-config"
                onChange={(event) => handleCountryChange(event)}
              >
                <option value="" disabled selected>
                  Country
                </option>
                {countryData?.map((item) => (
                  <option>{item.countryName}</option>
                ))}
              </select>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <label
                style={{ fontSize: "13px", fontWeight: "500", width: "18%" }}
              >
                ZipCode/Postal Code<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                style={{ margin: "19px 0px 5px -136px" }}
                className="input-config"
                type="text"
                name="zipcode"
                defaultValue={blankForm.zipcode}
                placeholder="ZipCode"
                minLength={5}
                maxLength={6}
                onBlur={(event) => handleZipcodeChange(event)}
              />
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginLeft: "16px",
                }}
              >
                City<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                style={{ margin: "19px 0px 5px -30px" }}
                name="city"
                value={selectedCity.PostOfficeName}
                className="input-config"
                onChange={(event) => handleInputChange(event)}
              >
                <option value="" disabled selected>
                  City
                </option>
                {selectedCity?.map((item) => (
                  <option>{item.PostOfficeName}</option>
                ))}
              </select>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginLeft: "15px",
                }}
              >
                State/Province<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                style={{ margin: "19px 0px 5px -96px" }}
                name="state"
                value={selectedState.stateName}
                className="input-config"
                placeholder="State"
              />
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <label
                style={{ fontSize: "13px", fontWeight: "500", width: "23%" }}
              >
                Contact Number<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <div style={{ display: "flex" }}>
                <input
                  style={{ width: "11%", margin: "19px 86px 0px -171px" }}
                  className="input-config"
                  type="text"
                  name="isd"
                  placeholder="-"
                  defaultValue={selectedCountry.phoneCode}
                />
                <input
                  style={{ width: "14%", margin: "19px 86px 0px -83px" }}
                  className="input-config"
                  type="text"
                  name="std"
                  placeholder="-"
                  defaultValue={selectedStdCode}
                  onChange={(event) => handleInputChange(event)}
                />
                <input
                  style={{ margin: "19px 0px 5px -83px", width: "27%" }}
                  className="input-config"
                  type="text"
                  minLength={10}
                  maxLength={15}
                  name="businessContactNo"
                  defaultValue={blankForm.businessContactNo}
                  placeholder="Contact No."
                  onChange={(event) => handleInputChange(event)}
                />
              </div>
              <label className="sAMobCssBasedOnbrowser">Mobile Number</label>
              <br />
              <input
                style={{ margin: "19px 10px 5px -97px" }}
                className="input-config"
                type="text"
                name="mobileNumber"
                pattern="[0-9]*"
                defaultValue={blankForm.mobileNumber}
                placeholder="Mobile Number"
                minLength={10}
                maxLength={15}
                onChange={(event) => handleInputChange(event)}
              />
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  // marginLeft: "-359px",
                  width: "13%",
                }}
              >
                Time Zone<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                style={{ margin: "19px 0px 5px -99px" }}
                name="timezone"
                placeholder="Time Zone"
                value={
                  selectedCountry.timezones &&
                  selectedCountry.timezones.gmtOffsetName +
                    " " +
                    selectedCountry.timezones.abbreviation
                }
                className="input-config"
              />
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  // marginRight: "-22px",
                }}
              >
                {`Currency (${auth.getUserInfo().currencySymbol})`}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                disabled
                style={{ margin: "19px 0px 5px -81px" }}
                required={true}
                name="currency"
                defaultValue={selectedCountry.currency}
                className="input-config"
                onChange={(event) => handleInputChange(event)}
              />
            </div>
            {/* <div style={{ display: 'flex', width: "100%" }}>
                            <label style={{ fontSize: "13px", fontWeight: "500", width: "23%" }}>Contact Number<span style={{ color: "red" }}>*</span></label><br />
                            <div style={{ display: "flex" }}>
                                <input
                                    style={{ width: "11%", margin: "19px 86px 0px -171px" }}
                                    className="input-config"
                                    type="text"
                                    name="isd"
                                    placeholder="+91"
                                    defaultValue={selectedCountry.phoneCode}
                                // onChange={event => handleInputChange(event)}
                                />
                                <input
                                    style={{ width: "14%", margin: "19px 86px 0px -83px" }}
                                    className="input-config"
                                    type="text"
                                    name="std"
                                    placeholder="011"
                                    defaultValue={selectedStdCode}
                                    onChange={event => handleZipcodeChange(event)}
                                />
                                <input
                                    style={{ margin: "19px 0px 5px -83px", width: "26%" }}
                                    className="input-config"
                                    type="text"
                                    maxLength="8"
                                    name="businessContactNo"
                                    defaultValue={blankForm.businessContactNo}
                                    placeholder="Contact No."
                                    onChange={event => handleInputChange(event)}
                                />
                            </div>
                            <label style={{ fontSize: "13px", fontWeight: "500", marginLeft: "-370px", width: "13%" }}>Time Zone<span style={{ color: "red" }}>*</span></label><br />
 
                            <input
                                style={{ margin: "19px 10px 5px -97px" }}
                                name="timezone"
                                placeholder="Time Zone"
                                value={selectedCountry.timezones && selectedCountry.timezones.gmtOffsetName + " " + selectedCountry.timezones.abbreviation}
                                className="input-config"
                            />

                            <label style={{ fontSize: "13px", fontWeight: "500", marginRight: "-14px" }}>Currency {auth.getUserInfo().currencySymbol}<span style={{ color: "red" }}>*</span></label><br />
                            <input
                                style={{ margin: "19px 0px 5px -53px", width: "32%" }}
                                className="input-config"
                                type="text"
                                name="currency"
                                defaultValue={selectedCountry.currency}
                                placeholder="Currency"
                            // onChange={event => handleInputChange(event)}
                            />
                        </div> */}
          </div>

          <br />
          <div className="footer">
            <button onClick={() => closeModal(false)}>
              <FontAwesomeIcon icon={faTimesCircle} /> Cancel
            </button>
            <button id="submit-button" onClick={submitHandler}>
              <FontAwesomeIcon icon={faCheckCircle} /> Save
            </button>
            {showVerify && (
              <VerifyModal
                updatedData={updatedData}
                closeModal={setVerify}
                closeBussModal={closeModal}
                verifyDetails={FinalBody}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessLocation;
