import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import auth from "../../../utils/auth";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { successNotification, errorNotification } from "../../../utils/notifications";
import ReceivedQtyView from "./ReceivedQtyView";


const SpareDetails = ({ closeModal, rowData }) => {
  // const UsersData = row.requisitionDetails;

  const [openSpare, setOpenSpare] = useState();
  const [spareData, setSpareData] = useState();


  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(``)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };


  const receivedQtyFormatter = (rowData) => {
    setOpenSpare(true);
    setSpareData(rowData);
  };



  return (
    <div className="container1">
    {
        openSpare ? <ReceivedQtyView rowData={spareData} closeModal={setOpenSpare}/>:""
    }
      <div
        className="modalContainer"
        style={{ width: "70%", height: "400px", padding: "15px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Spares Details</h3>
          <div>
            <button className="action-btn" style={{ color: "blue" }}>
              <PrintIcon/>
              &nbsp;Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              <IosShareIcon/>
              &nbsp;Export
            </button>
          </div>
        </div>
   

        <div className="body">
          <div>
            <div>
              <Table>
                <tbody>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb"}}>S.No</td>
                    <td style={{backgroundColor:"#ebebeb"}}>SKU Code</td>
                    <td style={{backgroundColor:"#ebebeb",width:"150px"}}>Spare Part Name</td>
                    <td style={{backgroundColor:"#ebebeb"}}>Category</td>
                    <td style={{backgroundColor:"#ebebeb",width:"150px"}}>Sub-Category</td>
                    <td style={{backgroundColor:"#ebebeb",width:"150px"}}>Requested Qty.</td>
                    <td style={{backgroundColor:"#ebebeb",width:"150px"}}>Received Qty.</td>
                    <td style={{backgroundColor:"#ebebeb",width:"150px"}}>Pending Qty.</td>
                  </tr>
                  <tr>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td onClick={()=>receivedQtyFormatter(rowData)} style={{ color: "#0A18A3" }}>567{}</td>
                  <td>{}</td>
                  </tr>
                </tbody>
              </Table>
            </div>



        
          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={submitHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default SpareDetails;
