import moment from 'moment'
import React from 'react'

const VR_History = ({ closeModal, row }) => {
  console.log(row)
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "615px", height: "420px", padding: "15px" }}
      >
        <div className="header" style={{ width: "100%", display: 'flex', justifyContent: 'flex-start' }}>
          <h3>View Details</h3>
        </div>

        <div style={{ width: '100%', height: '400px', overflow: 'scroll', display: 'flex', justifyContent: 'flex-start' }}>
          <ul className='OH_Ul' style={{ width: '100%' }}>
            {row.action.map((item) => {
              if (item.action == "Move To Finished Goods") {
                return (
                  <li className='OH_list poGenerated_list'>
                    <div className='OH_list_div' style={{ marginTop: '-10px', justifyContent: 'flex-start' }}>
                      <span className='OH_list_btn poGenerated'>Move To Finished Goods</span>
                      <span>Date: {moment(item.Date).format("DD-MMM-YYYY/HH:mm")}</span>
                      <span>{moment(item.Date).format("h:mm")}</span>
                    </div>
                  </li>
                )
              }
              if (item.action == "Verification Passed") {
                return (
                  <li className='OH_list poGenerated_list'>
                    <div className='OH_list_div' style={{ marginTop: '-10px', justifyContent: 'flex-start' }}>
                      <span className='OH_list_btn poGenerated'>Verification Passed</span>
                      <span>Date: {moment(item.Date).format("DD-MMM-YYYY/HH:mm")}</span>
                      <span>{moment(item.Date).format("h:mm")}</span>
                    </div>
                  </li>
                )
              }
              if (item.action == "Send To Verification") {
                return (
                  <li className='OH_list partiallyClosure_list'>
                    <div className='OH_list_div' style={{ marginTop: '-10px', justifyContent: 'flex-start' }}>
                      <span className='OH_list_btn partiallyClosure'>Send To Verification</span>
                      <span>Date: {moment(item.Date).format("DD-MMM-YYYY/HH:mm")}</span>
                      <span>{moment(item.Date).format("h:mm")}</span>
                    </div>
                  </li>
                )
              }
              if (item.action == "Send To Verification") {
                return (
                  <li className='OH_list approved_list'>
                    <div className='OH_list_div' style={{ marginTop: '-10px', justifyContent: 'flex-start' }}>
                      <span className='OH_list_btn approved'>Request Received</span>
                      <span>Date: {moment(item.Date).format("DD-MMM-YYYY/HH:mm")}</span>
                      <span>{moment(item.Date).format("h:mm")}</span>
                    </div>
                  </li>
                )
              }
            })}
          </ul>
        </div>

        <div className="footer" style={{ width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
          <button onClick={() => closeModal(false)}>Back</button>
        </div>
      </div>
    </div>
  )
}

export default VR_History