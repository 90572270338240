import * as XLSX from "xlsx";

const handleExport = (excelFile, fileName ,page , rowsPerPage = 10) => {
 

  // Calculate the start and end indices for the slice
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // Slice the data array to get the data for the current page
  let data = excelFile.slice(startIndex, endIndex);
  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, "myExcelSheet");
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export default handleExport;
