import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import sparesInventory from './component/sparesInventory';
const sparesInventory = React.lazy(() => import("./component/sparesInventory"));
const SkuWiseInventory = React.lazy(() =>
  import("./component/SkuWiseInventory")
);

const StoreManagerInventory = () => {
  const tabs = [
    {
      id: "STMM3T1",
      label: TabLabels.SKU_WISE_INVENTORY,
      Component: SkuWiseInventory,
    },
    {
      id: "STMM3T2",
      label: TabLabels.DETAILED_SPARES_INVENTORY,
      Component: sparesInventory,
    },
  ];

  return (
    <div className="app-layout__card">
      <Tabs type="pills" tabs={tabs} />
    </div>
  );
};

export default StoreManagerInventory;
