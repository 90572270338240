import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import auth from "../../../utils/auth";
import EditShowroomLocationModal from "./editShowroomLocationModal";
import moment from "moment";

const EditShowroom = ({ updatedData, editData, closeModal, tabName }) => {
  const [openLocation, setOpenLocation] = useState(false);
  const [formValue, setFormValue] = useState({
    userType: "Showroom",
    businessID: editData.businessID,
    businessName: editData.businessName,
    contactPersonName: editData.contactPersonName,
    location: {
      address1: editData.location.address1,
      address2: editData.location.address2,
      country: editData.location.country,
      state: editData.location.state,
      city: editData.location.city,
      zipCode: editData.location.zipCode
    },
    isd: editData.isd,
    std: editData.std,
    businessContactNo: editData.businessContactNo,
    businessMobileNo: editData.businessMobileNo,
    businessEmail: editData.businessEmail,
    gstin: editData.gstin,
    authNo: editData.authNo,
    mappedCity: editData.mappedCity,
    salesManager: editData.salesManager,
    asmFullName: editData.salesManager,
    asmUserID: editData.asmUserID,
    asmEmail: editData.salesManagerEmail,
    gstDoc: "",
    authDoc: "",
  });

  const [salesManagerList, setSalesManagerList] = useState([]);
  const [mappedCities, setMappedCities] = useState({});

  const fetchReport = async () => {
    const resp2 = await trackPromise(
      API.get(`dealer-module/api/v1/businessusers/getMappedCities?userID=${auth.getUserInfo().userID}`)
    );
    console.log(resp2);
    setMappedCities(resp2);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const [citiesChange, setCitiesChange] = useState(false)
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormValue((prevBlankForm) => {
      const newBlankForm = { ...prevBlankForm, [name]: value };

      if (name === "mappedCity") {
        // setCitiesChange(!citiesChange);
        const salesManager = mappedCities.cities.filter((item) => item.City === value);
        console.log(salesManager);
        let filterAsm = salesManager.some(item => item.hasOwnProperty('asmUserID'));

        // Guard to prevent repeated error messages
        if (!filterAsm) {
          if (!errorNotification.hasBeenCalled) {
            errorNotification("No Asm Mapped");
            errorNotification.hasBeenCalled = true;
          }
          setSalesManagerList([]);
          newBlankForm.salesManager = "";
          newBlankForm.asmFullName = "";
          newBlankForm.asmUserID = "";
          newBlankForm.asmEmail = "";
        } else {
          // Reset the guard flag if a valid ASM is found
          errorNotification.hasBeenCalled = false;

          const uniqueData = Object.values(salesManager.reduce((acc, cur) => {
            acc[cur.City] = cur;
            return acc;
          }, {}));
          setSalesManagerList(uniqueData);
        }
      }

      if (name === "salesManager") {
        const salesManager = mappedCities.cities.filter((item) => item.asmUserID === value);
        if (salesManager.length > 0) {
          newBlankForm.salesManager = salesManager[0].asmFullName;
          newBlankForm.asmFullName = salesManager[0].asmFullName;
          newBlankForm.asmUserID = salesManager[0].asmUserID;
          newBlankForm.asmEmail = salesManager[0].asmEmail;
        }
      }
      return newBlankForm;
    })
  };




  const validateInputs = () => {
    // Check for not blank fields
    if (formValue.userType.trim() === "") {
      errorNotification("User Type must not be blank.");
      return false;
    }
  
    if (formValue.businessName.trim() === "") {
      errorNotification("Business Name must not be blank.");
      return false;
    }
  
    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    if (!alphanumericRegex.test(formValue.businessName)) {
      errorNotification("Business Name must be alphanumeric.");
      return false;
    }
  
    if (formValue.businessEmail.trim() === "") {
      errorNotification("Email must not be blank.");
      return false;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formValue.businessEmail)) {
      errorNotification("Email format is invalid.");
      return false;
    }
  
    if (formValue.contactPersonName.trim() === "") {
      errorNotification("Contact Person Name must not be blank.");
      return false;
    }
  
    if (formValue.location.country.trim() === "") {
      errorNotification("Country must not be blank.");
      return false;
    }
  
    if (formValue.location.state.trim() === "") {
      errorNotification("State must not be blank.");
      return false;
    }
  
    if (formValue.location.city.trim() === "") {
      errorNotification("City must not be blank.");
      return false;
    }
  
    if (formValue.location.zipCode.trim() === "") {
      errorNotification("Zip Code must not be blank.");
      return false;
    }
  
    if (formValue.location.address1.trim() === "") {
      errorNotification("Address Line 1 must not be blank.");
      return false;
    }
  
    if (formValue.businessContactNo.trim() === "" && formValue.businessMobileNo.trim() === "") {
      errorNotification("At least one contact number must not be blank (Business Contact No or Business Mobile No).");
      return false;
    }
  
    if (formValue.businessContactNo.trim() !== "" && isNaN(formValue.businessContactNo)) {
      errorNotification("Business Contact No must be a number.");
      return false;
    }
  
    if (formValue.businessMobileNo.trim() !== "" && isNaN(formValue.businessMobileNo)) {
      errorNotification("Business Mobile No must be a number.");
      return false;
    }
  
    if (formValue.gstin.trim() === "") {
      errorNotification("GSTIN must not be blank.");
      return false;
    }
  
    // Validate GSTIN format (example for India)
    const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][A-Z0-9]{1}[Z][0-9A-Z]{1}$/;
    if (!gstinRegex.test(formValue.gstin)) {
      errorNotification("GSTIN format is invalid.");
      return false;
    }
  
    if (formValue.authNo.trim() === "") {
      errorNotification("Authorization Number must not be blank.");
      return false;
    }
  
    if (formValue.authDoc.trim() === "") {
      errorNotification("Auth Doc must not be blank.");
      return false;
    }
  
    if (formValue.gstDoc.trim() === "") {
      errorNotification("GST Doc must not be blank.");
      return false;
    }
  
    if (formValue.asmFullName.trim() === "") {
      errorNotification("ASM Full Name must not be blank.");
      return false;
    }
  
    if (formValue.asmUserID.trim() === "") {
      errorNotification("ASM User ID must not be blank.");
      return false;
    }
  
    if (formValue.asmEmail.trim() === "") {
      errorNotification("ASM Email must not be blank.");
      return false;
    }
  
    if (!emailRegex.test(formValue.asmEmail)) {
      errorNotification("ASM Email format is invalid.");
      return false;
    }
  
    // If all validations pass
    return true;
  };



  const submitHandler = async () => {

    if(!validateInputs()){
      return
    }


    console.log(formValue);
    const resp = await trackPromise(
      API.post(`dealer-module/api/v1/businessusers/editUser`, formValue)
    );
    const { success, message } = resp;
    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      updatedData();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };


  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "804px", height: "450px" }}
      >
        <div className="header">
          <h3>Edit Showroom Details</h3>
        </div>

        <div
          className="body"
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Showroom ID
            </label>
            <input
              disabled
              name="businessID"
              placeholder="Showroom ID"
              className="input-config"
              value={formValue.businessID}
              onChange={(event) => handleInputChange(event)}
              style={{ marginTop: "-6px", color: "grey" }}
            />
          </div>
          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Showroom Name
            </label>
            <input
              name="businessName"
              placeholder="Service Center Name"
              className="input-config"
              defaultValue={formValue.businessName}
              onChange={(event) => handleInputChange(event)}
              style={{ marginTop: "-6px" }}
            />
          </div>
          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Contact Person Name
            </label>
            <input
              name="contactPersonName"
              placeholder="Contact Person"
              className="input-config"
              defaultValue={formValue.contactPersonName}
              onChange={(event) => handleInputChange(event)}
              style={{ marginTop: "-6px" }}
            />
          </div>
          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Email ID
            </label>
            <input
              name="businessEmail"
              placeholder="Email ID"
              className="input-config"
              defaultValue={formValue.businessEmail}
              onChange={(event) => handleInputChange(event)}
              style={{ marginTop: "-6px" }}
            />
          </div>

          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Cities
            </label>
            <select
              name="mappedCity"
              value={formValue.mappedCity}
              className="input-config"
              onChange={(event) => handleInputChange(event)}
              style={{ width: "100%", marginTop: "-5px", marginLeft: "0px" }}
            >
              <option value="" disabled selected>
                Cities
              </option>
              {mappedCities.cities?.sort((a, b) => a.City.localeCompare(b.City))?.map((item) => (
                <option value={item.City}>{item.City}</option>
              ))}
            </select>
          </div>

          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Sales Manager
            </label>
            <select
              name="salesManager"
              value={formValue.salesManager}
              className="input-config"
              onChange={(event) => handleInputChange(event)}
              style={{ width: "100%", marginTop: "-5px", marginLeft: "0px" }}
            >
              <option value={formValue.salesManager} disabled selected>
                {formValue.salesManager}
              </option>
              {salesManagerList?.map((item) => (
                <option key={item.asmUserID} value={item.asmUserID}>{item.asmFullName}</option>
              ))}
            </select>
          </div>

          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Edit Showroom Location
            </label>
            <input
              className="input-config"
              type="text"
              name="location"
              defaultValue={""}
              placeholder="Click to edit Address"
              onFocus={() => setOpenLocation(true)}
              style={{ marginTop: "-6px", color: "blue" }}
            />
            {openLocation && (
              <EditShowroomLocationModal
                editData={editData}
                closeModal={setOpenLocation}
                tabName={tabName}
                location={setFormValue}
                formValue={formValue.location}
              />
            )}
          </div>
          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Showroom Contact Number
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "-16px",
              }}
            >
              <input
                disabled
                style={{ width: "20%" }}
                className="input-config"
                type="text"
                name="isd"
                placeholder="-"
                defaultValue={formValue.isd}
              />
              <input
                disabled
                style={{ width: "20%" }}
                className="input-config"
                type="text"
                name="std"
                placeholder="-"
                defaultValue={formValue.std}
                onChange={(event) => handleInputChange(event)}
              />
              <input
                style={{ width: "60%" }}
                className="input-config"
                type="text"
                maxLength="8"
                name="businessContactNo"
                defaultValue={formValue.businessContactNo}
                placeholder="Contact No."
                onChange={(event) => handleInputChange(event)}
              />
            </div>
          </div>
          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Mobile Number
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "-16px",
              }}
            >
              <input
                disabled
                style={{ width: "20%" }}
                className="input-config"
                type="text"
                name="isd"
                placeholder="-"
                defaultValue={formValue.isd}
                onChange={(event) => handleInputChange(event)}
              />
              <input
                style={{ width: "80%" }}
                className="input-config"
                type="text"
                maxLength="10"
                name="businessMobileNo"
                defaultValue={formValue.businessMobileNo}
                placeholder="Mobile No."
                onChange={(event) => handleInputChange(event)}
              />
            </div>
          </div>
          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              On-Boarding Date
            </label>
            <input
              disabled
              className="input-config"
              type="text"
              name="createdDate"
              value={moment(formValue.createdDate).format("DD-MM-YYYY, HH:mm")}
              placeholder="On-Boarding Date"
              onChange={(event) => handleInputChange(event)}
              style={{ marginTop: "-6px", color: "grey" }}
            />
          </div>
          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>GSTIN</label>
            <input
              className="input-config"
              type="text"
              name="gstin"
              defaultValue={formValue.gstin}
              placeholder="GSTIN"
              onChange={(event) => handleInputChange(event)}
              style={{ marginTop: "-6px" }}
            />
          </div>
          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              GSTIN Doc
            </label>
            <input
              className="input-config"
              type="file"
              name="gstDoc"
              defaultValue={formValue.gstDoc}
              placeholder="GSTIN Document"
              onChange={(event) => handleInputChange(event)}
              style={{ marginTop: "-6px", padding: "14px" }}
            />
          </div>
          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Auth. No.
            </label>
            <input
              className="input-config"
              type="text"
              name="authNo"
              defaultValue={formValue.authNo}
              placeholder="Service Center Auth No."
              onChange={(event) => handleInputChange(event)}
              style={{ marginTop: "-6px" }}
            />
          </div>
          <div
            style={{
              width: "32%",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              Auth. Doc.
            </label>
            <input
              className="input-config"
              type="file"
              name="authDoc"
              defaultValue={formValue.authDoc}
              placeholder="Auth Document"
              onChange={(event) => handleInputChange(event)}
              style={{ marginTop: "-6px", padding: "14px" }}
            />
          </div>
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditShowroom;
