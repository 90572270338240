import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import ProgressBar  from 'react-bootstrap/ProgressBar'
import '../../QcDashboard/styles/main.scss'
import moment from "moment";

export default function VehiclePricing({startDate, endDate}) {

    const [data, setData] = useState({})

    const fetchData = async () => {
        const result = await trackPromise(API.get(startDate == null && endDate == null ? `finance/api/v1/spareDashboard` : `finance/api/v1/spareDashboard?startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}`));
        setData(result);
      };
      useEffect(() => {
        fetchData();
      }, [endDate]);



  return (
    <div style={{display:"flex",flexDirection:"column",width:"95%",marginLeft:"10px",fontWeight:"430"}}>
        <ProgressBar>
            <ProgressBar now={data.totalVehicle*100}/>
            <ProgressBar variant="inProgressRequest" now={data.totalPending*100} />
            <ProgressBar variant="warning" now={data.totalOnHold*100} />
            <ProgressBar variant="success" now={data.totalApproved*100} />
            <ProgressBar variant="danger" now={data.totalRejected*100} />
        </ProgressBar>
        <br/>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <div style={{display:"flex",flexDirection:"row"}}>
            <div style={{display:"flex", width:"15px",height:"15px",background:"#1e90ff", borderRadius:"3px",marginRight:"5px",marginTop:"4px"}}>
            </div>&nbsp;
            <span>Total Spare Pricing</span>
        </div>
        <div>
            {data.totalVehicle}
        </div>
        </div>
        <hr/>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <div style={{display:"flex",flexDirection:"row"}}>
            <div style={{display:"flex", width:"15px",height:"15px",background:"#FDAA48", borderRadius:"3px",marginRight:"5px",marginTop:"4px"}}>
            </div>&nbsp;
            <span>Pending Spare Pricing</span>
        </div>
        <div>
            {data.totalPending}
        </div>
        </div>
        <hr/>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <div style={{display:"flex",flexDirection:"row"}}>
            <div style={{display:"flex", width:"15px",height:"15px",background:"#FFD74A", borderRadius:"3px",marginRight:"5px",marginTop:"4px"}}>
            </div>&nbsp;
            <span>On Hold Spare Pricing</span>
        </div>
        <div>
            {data.totalOnHold}
        </div>
        </div>
        <hr/>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <div style={{display:"flex",flexDirection:"row"}}>
            <div style={{display:"flex", width:"15px",height:"15px",background:"#21A82E", borderRadius:"3px",marginRight:"5px",marginTop:"4px"}}>
            </div>&nbsp;
            <span>Approved Spare Pricing</span>
        </div>
        <div>
            {data.totalApproved}
        </div>
        </div>
        <hr/>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <div style={{display:"flex",flexDirection:"row"}}>
            <div style={{display:"flex", width:"15px",height:"15px",background:"#EF4D62", borderRadius:"3px",marginRight:"5px",marginTop:"4px"}}>
            </div>&nbsp;
            <span>Rejected Spare Pricing</span>
        </div>
        <div>
            {data.totalRejected}
        </div>
        </div>
        <hr/>
    </div>
  )
}
