import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
    KeyboardBackspace,
    CheckCircle
} from "@material-ui/icons";
import {
    errorNotification,
    successNotification,
} from "../../../utils/notifications";
import OpenConfirmation from "./OpenConfirmation";

const EditNationalSales = ({ closeModal, editData, userType, updatedData }) => {

    let fName = editData.nsmFname;
    let lName = editData.nsmLname;
    let email = editData.nsmEmail;

    const [formValue, setFormValue] = useState(
        {
            fName: fName,
            lName: lName,
            email: email,
        },
    );

    const [openConfirmation, setOpenConfirmartion] = useState(false);
    const [confirmFlag, setConfirmFlag] = useState(false);

    const handleInputChange = (event) => {
        setFormValue({ ...formValue, [event.target.name]: event.target.value });
    };

    const handleConfirm = () => {
        setOpenConfirmartion(true);
    }


    const submitHandler = async () => {
        // Body Data
        let nationalBodyData = {
            countryName: editData.countryName,
            nsmFname: formValue.fName,
            nsmLname: formValue.lName,
            nsmEmail: formValue.email
        }

        const resp = await trackPromise(API.post(`operations-module/api/v1/salesUser/editNationalUser`, nationalBodyData));
        if (resp.success) {
            updatedData("", "National Sales");
            closeModal(false)
            return successNotification(resp.message, { autoClose: 10000 });
        } else {
            return errorNotification(resp.message, { autoClose: 10000 });
        }
    };

    return (
        <div className="container1">
            {openConfirmation && !confirmFlag && <OpenConfirmation closeModal={setOpenConfirmartion} getConfirmFlag={setConfirmFlag} />}
            <div
                className="modalContainer"
                style={{ width: "835px", padding: "15px" }}
            >
                <div className="header">
                    <h3>Edit User Details </h3>
                </div>
                <section>
                    <form id="add-user-from" className="setting-form">
                        <div style={{ display: "flex", width: "100%" }}>
                            <div
                                style={{
                                    margin: "0px 5px 0px 5px",
                                    width: "23%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <label
                                    style={{
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        width: "100%",
                                        textAlign: "start",
                                        marginLeft: "5px"
                                    }}
                                >
                                    User Type
                                </label>
                                <input
                                    type="text"
                                    placeholder="User Type"
                                    name="fName"
                                    value={userType}
                                    style={{
                                        width: "100%",
                                        outline: "none",
                                        marginTop: "-8px",
                                    }}
                                    className="input-config"
                                />
                            </div>
                            <div
                                style={{
                                    margin: "0px 5px 0px 5px",
                                    width: "23%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <label
                                    style={{
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        width: "100%",
                                        textAlign: "start",
                                        marginLeft: "5px"
                                    }}
                                >
                                    Country
                                </label>
                                <input
                                    type="text"
                                    placeholder="Country"
                                    name="fName"
                                    value={editData.countryName}
                                    style={{
                                        width: "100%",
                                        outline: "none",
                                        marginTop: "-8px",
                                    }}
                                    className="input-config"
                                />
                            </div>
                            <div
                                style={{
                                    margin: "0px 5px 0px 5px",
                                    width: "23%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <label
                                    style={{
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        width: "100%",
                                        textAlign: "start",
                                    }}
                                >
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    name="fName"
                                    defaultValue={fName}
                                    style={{
                                        width: "100%",
                                        outline: "none",
                                        marginTop: "-8px",
                                    }}
                                    className="input-config"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                            <div
                                style={{
                                    margin: "0px 5px 0px 5px",
                                    width: "23%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <label
                                    style={{
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        width: "100%",
                                        textAlign: "start"
                                    }}
                                >
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    name="lName"
                                    defaultValue={lName}
                                    style={{
                                        width: "100%",
                                        outline: "none",
                                        marginTop: "-8px",
                                    }}
                                    className="input-config"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                            <div
                                style={{
                                    margin: "0px 5px 0px 5px",
                                    width: "23%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <label
                                    style={{
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        width: "100%",
                                        textAlign: "start"
                                    }}
                                >
                                    Email ID
                                </label>
                                <input
                                    // disabled={confirmFlag == true ? false : true}
                                    type="text"
                                    placeholder="Email ID"
                                    name="email"
                                    defaultValue={email}
                                    style={{
                                        width: "100%",
                                        outline: "none",
                                        marginTop: "-8px",
                                    }}
                                    className="input-config"
                                    onClick={(e) => handleConfirm(e)}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                    </form>
                </section>
                <div
                    className="footer"
                    style={{ width: "100%", display: "flex", flexDirection: "row" }}
                >
                    <button
                        style={{ width: "100px", height: "42px", background: "#fff" }}
                        onClick={() => closeModal(false)}
                    >
                        <KeyboardBackspace style={{ fontSize: "25px" }} />
                        &nbsp; Back
                    </button>
                    <button
                        onClick={submitHandler}
                        style={{
                            marginRight: "0",
                            width: "120px",
                            height: "42px",
                            backgroundColor: "#0A18A3",
                        }}
                        id="submit-button"
                    >
                        <CheckCircle style={{ fontSize: "17px" }} />
                        &nbsp; Update
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditNationalSales;
