import React, { useState } from "react";
import Edit from "./Edit";
import Reactivate from "./Reativate";
import Deactivate from "./Deactivate";
import Delete from "./Delete";
import {
  faEdit,
  faBan,
  faTrash,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../components/modal/modal.scss";

const ManageAction = ({ updatedData, rowData, setIsOpen, userType, usersData , rowIndex}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});


  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      reactivate: false,
      deactivate: false,
      delete: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };


  return (
    <div>
      {rowData.asmStatus === "Active" || rowData.asmStatus === "Reactivate"
        ? activeModal.edit &&
        showModal && (
          <Edit
            updatedData={updatedData}
            editData={rowData}
            closeModal={setShowModal}
            userType={userType}
            usersData={usersData}
          />
        )
        : ""}
      {rowData.asmStatus === "Active" || rowData.asmStatus === "Reactivate"
        ? activeModal.deactivate &&
        showModal && (
          <Deactivate
            updatedData={updatedData}
            editData={rowData}
            closeModal={setShowModal}
            userType={userType}
          />
        )
        : rowData.asmStatus === "Deactivate"
          ? activeModal.reactivate &&
          showModal && (
            <Reactivate
              updatedData={updatedData}
              editData={rowData}
              closeModal={setShowModal}
              userType={userType}
            />
          )
          : ""}
      {rowData.asmStatus === "Active" ||
        rowData.asmStatus === "Deactivate" ||
        rowData.asmStatus === "Reactivate"
        ? activeModal.delete &&
        showModal && (
          <Delete
            updatedData={updatedData}
            editData={rowData}
            closeModal={setShowModal}
            userType={userType}
          />
        )
        : ""}

      <div
        className="action"
        style={{ position: "relative" , marginTop: rowIndex <= 4 ? "0px" : "-50px",}}
        onBlur={() => setIsOpen(false)}
      >
        <div
          className="action vendoraction"
          style={{ marginLeft: "-20px", marginTop: "-13px", width: "202px" }}
        >
          {rowData.asmStatus === "Active" || rowData.asmStatus === "Reactivate" ? (
            <div>
              {" "}
              <span id="edit" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Edit Details Request
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.asmStatus === "Active" ||
            rowData.asmStatus === "Reactivate" ||
            rowData.asmStatus === "Deactivate" ? (
            <div>
              {rowData.asmStatus === "Active" ||
                rowData.asmStatus === "Reactivate" ? (
                <span id="deactivate" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faBan} /> Request Deactivation
                </span>
              ) : (
                <span id="reactivate" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faDotCircle} /> Request Reactivation
                </span>
              )}
            </div>
          ) : (
            ""
          )}

          {rowData.asmStatus === "Active" ||
            rowData.asmStatus === "Deactivate" ||
            rowData.asmStatus === "Reactivate" ? (
            <div>
              <span id="delete" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faTrash} /> Delete Request
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageAction;
