import { KeyboardBackspace } from '@material-ui/icons';
import React from 'react';

const ShowMappedCities = ({ citiesData, rowData, closeModal }) => {
    console.log(citiesData, rowData);

    const filteredCities = rowData.cities.filter(item => item.stateId === citiesData.stateId);

    return (
        <div className="container1">
            <div className="modalContainer" style={{ width: "501px", height: "450px" }}>
                <div className="header">
                    <h3>Mapped Cities</h3>
                </div>
                <label style={{ textAlign: "left", marginTop: "-45px" }}>
                    Area Name: <b style={{ color: "#0A18A3" }}>{rowData.area}</b>
                </label>
                <label style={{ textAlign: "left", marginTop: "-65px" }}>
                    State Name: <b style={{ color: "#0A18A3" }}>{citiesData?.stateName}</b>
                </label>
                <div className="body" style={{ marginTop: "-30px", height: "100px", overflowY: "auto" }}>
                    <table style={{ width: "100%", border: "1px solid grey" }}>
                        <thead>
                            <tr style={{ backgroundColor: "#E5E5E5" }}>
                                <th style={{ display: "flex", justifyContent: "center", borderRight: "1px solid grey" }}>S.No</th>
                                <th style={{ paddingLeft: "50px" }}>Cities</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCities.map((el, index) => (
                                <tr key={el.id} style={{ border: "1px solid grey" }}>
                                    <td style={{ paddingLeft: "20px", borderRight: "1px solid grey" }}>{index + 1}.</td>
                                    <td style={{ paddingLeft: "50px" }}>{el.City}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="footer">
                    <button onClick={() => closeModal(false)}>
                        <KeyboardBackspace /> Back
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShowMappedCities;
