import React, { useEffect, useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import GINno from "./GINno";
import CR_POno from "./CR_POno";
import CR_MR_ReturnQty from "./CR_MR_ReturnQty";
import moment from "moment";
import ViewSpecs from "../../inventory-inv/action/ViewSpecs";
import FullAssignment from "../../inventory-inv/action/FullAssignment";
import SpareReturnQty from "./spareReturnQty";
import { errorNotification } from "../../../utils/notifications";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import RequestToStartVerification from "./requestToStartVerification";
import CreditNoteSparePreview from "../../ReturnTemplates/CreditNoteSparePreview ";
import OnHoldReturnNote from "../../financeReturn/Components/OnHoldReturnNote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause } from "@fortawesome/free-solid-svg-icons";

const SpareViewAct = ({ closeModal, rowData, tabName, updatedData }) => {
  console.log(rowData);
  const [compData, setCompData] = useState({});
  const [showReturnItem, setItem] = useState();
  const [openFA, setOpenFA] = useState(false);
  const [openVerification, setOpenVerification] = useState(false);
  const [openRelease, setOpenRelease] = useState(false);
  const [openSpecs, setOpenSpecs] = useState();
  const [viewQty, setViewQty] = useState();
  const [showNote, setShowNote] = useState();
  const [showModal, setShowModal] = useState();


  const fetchCompDetails = async () => {
    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/inventorygoodsverification/getGRNComponentDetails?GRNID=${rowData.GRNID}`
      )
    );
    console.log(resp);
    resp.success == false ? errorNotification(resp.message) : setCompData(resp);
  };
  const fetchSparesDetails = async () => {
    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/inventorygoodsverification/getComponentsAction?requestID=${rowData.requestID}`
      )
    );
    console.log(resp);
    resp.success == false ? errorNotification(resp.message) : setCompData(resp);
  };
  useEffect(() => {
    {
      tabName == "components" ? fetchCompDetails() : fetchSparesDetails();
    }
  }, []);

  const returnQty = (componentSno) => {
    let qty = componentSno?.length;
    return qty;
  };

  const returnQuantity = (item, index) => {
    setViewQty(true);
    setItem(item);
  };

  const totalAvailableQuantity = compData?.returnedData?.reduce((acc, item) => {
    return (acc += item?.returnedQty);
  }, 0);

  return (
    <div className="container1">
      {showNote && (
        <CreditNoteSparePreview
          closeModal={setShowNote}
          closeOne={closeModal}
          rowData={rowData}
          updatedData={updatedData}
        />
      )}

      {showModal && (
        <OnHoldReturnNote
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}
      <div
        className="modalContainer"
        style={{ width: "1000px", padding: "15px" }}
      >
        <div className="header">
          <h3>View Details</h3>
        </div>

        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {tabName == "components" ? (
                <span>GRN: {compData?.GRNID}</span>
              ) : (
                <span>GIN: {compData?.ginNo}</span>
              )}
              <span>
                Requested By:{" "}
                <span style={{ color: "#0a18a3" }}>{compData?.requestedBy}</span>
              </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* {tabName == "components" ? <span>PO No. : {compData.POID}</span> : <span>PO No. : {compData.poNo}</span>} */}
              <span>
                Requested Date:{" "}
                {moment(compData?.requestedDate).format("DD-MMM-YYYY")}
              </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested Category: {compData?.requestCategory}</span>
              {tabName == "components" ? (
                <span>
                  Received Components : {compData?.receivedComponents}
                </span>
              ) : (
                <span>Returned Spares : {compData?.returnedUnits}</span>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Request Type: {compData?.requestType}</span>
              {tabName == "components" ? (
                <span>Received Units : {compData?.receivedUints}</span>
              ) : (
                <span>Returned Units : {totalAvailableQuantity}</span>
              )}
            </div>
          </section>
          {rowData?.status == "On Hold_On Hold" ? (
            <div
              id="hold"
              style={{ display: "flex", width: "100%", height: "38px" }}
            >
              <span id="holdDate" style={{ width: "auto" }}>
                Hold Date:{" "}
                {moment(rowData?.onholdDate).format("DD-MMM-YYYY/HH:mm")}
              </span>
              <span id="line"></span>
              <span id="holdReason">Hold Reason: {rowData?.onholdReasson}</span>
            </div>
          ) : (
            ""
          )}
          <section style={{ width: "100%" }}>
            <div id="POVendorHeading">
              <div style={{ width: "10%" }}>S.No.</div>
              <div style={{ width: "15%" }}>Spares Name</div>
              <div style={{ width: "15%" }}>SKU Code</div>
              <div style={{ width: "15%" }}>Category</div>
              <div style={{ width: "30%" }}>Sub Category</div>
              {/* <div style={{ width: '15%' }}>Specification</div> */}
              <div style={{ width: "15%" }}>Received Qty.</div>
            </div>
            {tabName == "components" ? (
              <div>
                {compData?.components?.map((item, index) => (
                  <div
                    id="POVendorContent"
                    style={{
                      borderBottom: "1px solid #999999",
                      boxShadow: "none",
                      padding: "0px",
                    }}
                    key={index}
                  >
                    <div style={{ width: "10%" }}>{index + 1}</div>
                    <div style={{ width: "15%" }}>{item.componentName}</div>
                    <div style={{ width: "15%" }}>{item.skuCode}</div>
                    <div style={{ width: "15%" }}>{item.category}</div>
                    <div style={{ width: "30%" }}>{item.subCategory}</div>
                    {/* <div style={{ width: '15%' }}>
                                        <span onClick={() => setOpenSpecs(index)} style={{ color: "#0a18a3", fontWeight: "500", cursor: 'pointer' }}>View Specs</span>
                                        {openSpecs === index && <ViewSpecs specs={item} closeModal={setOpenSpecs} />}
                                    </div> */}
                    <div style={{ width: "15%" }}>
                      <span
                        onClick={() => returnQuantity(item)}
                        style={{
                          color: "#0a18a3",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        {returnQty(item?.componentSno)}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {compData?.returnedData?.map((item, index) => (
                  <div
                    id="POVendorContent"
                    style={{
                      borderBottom: "1px solid #999999",
                      boxShadow: "none",
                      padding: "0px",
                    }}
                    key={index}
                  >
                    <div style={{ width: "10%" }}>{index + 1}</div>
                    <div style={{ width: "15%" }}>{item.sparePartname}</div>
                    <div style={{ width: "15%" }}>{item.skuCode}</div>
                    <div style={{ width: "15%" }}>{item.category}</div>
                    <div style={{ width: "30%" }}>{item.subCategory}</div>
                    {/* <div style={{ width: '15%' }}>
                                        <span onClick={() => setOpenSpecs(index)} style={{ color: "#0a18a3", fontWeight: "500", cursor: 'pointer' }}>View Specs</span>
                                        {openSpecs === index && <ViewSpecs specs={item} closeModal={setOpenSpecs} />}
                                    </div> */}
                    <div style={{ width: "15%" }}>
                      <span
                        onClick={() => returnQuantity(item)}
                        style={{
                          color: "#0a18a3",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        {item?.returnQty?.length}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {viewQty && (
              <SpareReturnQty
                tableRefresh={
                  tabName == "components"
                    ? fetchCompDetails
                    : fetchSparesDetails
                }
                tabName={tabName}
                GRNID={compData.GRNID}
                GIN={compData.ginNo}
                row={showReturnItem}
                status={rowData.status}
                requestID={rowData.requestID}
                close={setViewQty}
              />
            )}
          </section>
        </div>

        <div className="footer" style={{ display: "flex", flexDirection:"row" ,justifyContent: "end" }}>
          <div>
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
          </div>

         
          {/* {tabName == "components" || tabName == "spares" ? "" : */}
          {/* {rowData.status == "Pending At QC_New Request" ?
                        <button
                            style={{
                                background: "#0A18A3",
                                border: "1px solid #0A18A3",
                                borderRadius: "3px",
                                padding: "5px 15px",
                                color: "white",
                                width: '200px'
                            }}
                            onClick={() => setOpenVerification(true)}
                        >
                            Request For Verification
                        </button> :
                        <button
                            style={{
                                background: "#0A18A3",
                                border: "1px solid #0A18A3",
                                borderRadius: "3px",
                                padding: "5px 15px",
                                color: "white",
                                width: '200px'
                            }}
                            onClick={() => setOpenRelease(true)}
                        >
                            Request For Release
                        </button>
                    } */}
          {/* } */}
          {/* {openVerification && <RequestToStartVerification closeModal={setOpenVerification} rowData={rowData} tabName={tabName}/> }
                    {openRelease && <RequestToStartVerification closeModal={setOpenVerification} rowData={rowData} tabName={tabName}/>}
                */}
        </div>
      </div>
    </div>
  );
};

export default SpareViewAct;
