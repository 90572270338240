import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";

const ViewSpecs = ({ rowData, closeModal }) => {
  console.log(rowData)
  return (
    <div>
      <div className="container1">
        <div className="modalContainer" style={{ height: "350px" }}>
          <div className="header">
            <h3> Component Specification</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <h6>Component Name: {rowData?.sparePartname}</h6>
            <h6>Component Sku code: {rowData?.skuCode}</h6>
          </div>
          <div className="body" style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap" }}>
            <ul type="none" className="viewspeclist" style={{ paddingLeft: "0px", textAlign: "left", fontSize: "14px" }}>
              {rowData?.specifications?.map((item) => {
                return (
                  <li>{`Component ${item?.specsType} : ${item?.specsValue} ${item?.specsUnit[0]}`}</li>
                )
              })}
            </ul>
          </div>
          <div className="footer">
            <button onClick={() => closeModal(false)}><KeyboardBackspace/> Back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSpecs;
