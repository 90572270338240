import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import Assembly from "../assembly/assembly";
// import Labour from "../labour/labour";
// import Vehicles from "../vehicles/vehicles";

const Vehicles = React.lazy(()=>import("../vehicles/vehicles"))
const Assembly = React.lazy(()=>import("../assembly/assembly"))
const Labour = React.lazy(()=>import("../labour/labour"))
const componentConfiguration = React.lazy(()=>import("../componentConfiguration/componentConfiguration"))
const BatteryConfiguration = React.lazy(()=>import("../BatteryConfiguration/BatteryConfiguration"))



const tabs = [
    {
        id: "SAM4T1",
        label: TabLabels.VEHICLE,
        Component: Vehicles
    },
    {
        id: "SAM4T2",
        label: TabLabels.LABOR,
        Component: Labour
    },
    {
        id: "SAM4T3",
        label: TabLabels.ASSEMBLY,
        Component: Assembly
    },
    {
        id: "SAM4T4",
        label: TabLabels.COMPONENTS_CONFIGURATION,
        Component: componentConfiguration
    },
    {
        id: "SAM4T5",
        label: TabLabels.BATTERY_CONFIGURATION,
        Component: BatteryConfiguration
    }
];

const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
    return tabs.filter(tab => tabIds.includes(tab.id));
};

const MasterConfiguration = () => {
    let tabsToShow = getTabs(tabs);
    return (
        <div className="app-layout__card">
            <Tabs tabs={tabsToShow} type="pills" />
        </div>
    );
};

export default MasterConfiguration;