import React, { useState } from "react";

const RequestRPI = ({closeModal}) => {

  const submitHandler =() => {
    console.log("requested")
    closeModal(false);
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{width:"477px",height:"237px"}}>
        <div className="body" style={{flexDirection:"column",marginTop:"58px",fontSize:"18px",textAlign:"center"}}>
          <span>Are you sure to send request</span>
          <span>for RPI?</span>
        </div>
        <div className="footer" style={{justifyContent:"center",marginBottom:"16px"}}>
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestRPI;
