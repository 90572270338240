import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import ViewSpecs from "../../bom-costing/action/ViewSpecs";

const ViewComponents = ({ componentData, close }) => {
  const [viewSpecs, setViewSpecs] = useState(false);
  const [specsData, setSpecsData] = useState({});

  const handleViewSpecs = (item, index) => {
    setViewSpecs(true)
    setSpecsData(item)
  }

  return (
    <div>
      <div className="container1">
        {viewSpecs && <ViewSpecs specs={specsData} closeModal={setViewSpecs} />}
        <div className="modalContainer" style={{ width: "72%" }}>
          <div className="header">
            <h3> BOM Components Details</h3>
          </div>

          <div className="body">
            <table className="viewcomp_tbl" >

              <tr>
                <th>Component Name</th>
                <th>Component SKU Code</th>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Specification</th>
                <th>Quantity</th>
              </tr>
              {componentData === undefined ?

                <div style={{ marginLeft: "170%", color: "red" }}>No Data Found!</div> :

                componentData?.map((item) => {
                  return (
                    <tr>
                      <td className="leftcell">{item.componentName}</td>
                      <td>{item.skuCode}</td>
                      <td>{item.category}</td>
                      <td>{item.subCategory}</td>
                      <td><span onClick={() => handleViewSpecs(item)} style={{ color: "#0a18a3", fontWeight: "500", cursor: 'pointer' }}> View Specs</span></td>
                      <td className="rightcell">{item.componentQuantity}</td>
                    </tr>
                  )
                })}
            </table>
          </div>
          <div className="footer">
            <button onClick={() => close(false)}> <KeyboardBackspace /> Back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewComponents;
