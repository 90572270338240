import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";

const SpareWarrantyDetails = ({ closeModal, rowData }) => {
  // const UsersData = row.requisitionDetails;
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "50%", height: "500px", padding: "10px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Spare Warranty Details</h3>
          <div>
            <button className="action-btn" style={{ color: "blue" }}>
              <PrintIcon />
              &nbsp;Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              <IosShareIcon />
              &nbsp;Export
            </button>
          </div>
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding:7,
          }}
        >
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            SKU Code: {rowData?.skuCode}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Category : {rowData?.category}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
             Spare Part Name: {rowData?.sparePartName}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Sub Category: {rowData?.subCategory}
          </span>
        </section>
        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ color: "blue" }}>Warranty Details</h6>
            </div>

            <div>
              <Table>
                <tbody>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Warranty Duration</td>
                    <td style={{textAlign:"start"}}>{rowData?.warrantyDuration+rowData?.warrantyDurationUnit}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Warranty Inclusions & Terms</td>
                    <td style={{color:"blue", textAlign:"start"}}>tata altroz.pdf</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <KeyboardBackspace /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default SpareWarrantyDetails;
