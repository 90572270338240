import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { errorNotification, successNotification } from "../../utils/notifications";

const RemoveLocation = ({closeModal,locationObj,updatedData,locationType}) => {
  console.log(locationObj)
    const refresh = () => {
        updatedData();
      };

    const submitHandler = async() => {
        let id = {
            "locationID": locationObj.locationID
          };
        const resp = await trackPromise(API.put(`admin-module/api/v1/users/deleteLocation`, id));
        if (resp.success) {
            successNotification(resp.message, { autoClose: 3000 });
            refresh();
        } else {
            errorNotification(resp.message, { autoClose: 3000 });
        }
        closeModal(false);
    };

  return (
    <div className="container1">
      <div className="modalContainer" style={{width:"410px",height:"224px"}}>
        <div className="body" style={{flexDirection:"column",marginTop:"58px",fontSize:"18px",textAlign:"center"}}>
          <span>Are you sure to remove this {locationType} Location?</span>
        </div>
        <div className="footer" style={{justifyContent:"center",marginBottom:"16px"}}>
          <button onClick={() => closeModal(false)}>Back</button>
          <button id="submit-button" onClick={submitHandler}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveLocation;
