import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { successNotification, errorNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Deactivate = ({ updatedData, editData, closeModal, props }) => {
  const [formValue, setFormValue] = useState({
    deactivateReason: "",
    description: "",
  });

  const editDetails = {
    user: editData.user,
    status:"Deactivated",
    reason:formValue.deactivateReason,
    description:formValue.description
  };

  const editSubDetails = {
    planId: editData.planId,
    status:"Deactivated",
    reason:formValue.deactivateReason,
    description:formValue.description
  };

  const refresh = () => {
    updatedData();
  };

  

  const submitHandler = async () => {
    if(!formValue.deactivateReason){
      errorNotification("Please select Deactivation Reason",{autoclose:2000});
      return;
    }
    console.log(formValue);
    const resp = await trackPromise(
      API.put(`systemadmin/api/v1/toggleChargeStatus`, editDetails)
    );

    
    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const submitSubsHandler = async () => {
    if(formValue?.deactivateReason == ""){
      errorNotification("Please select Deactivation Reason",{autoclose:2000});
      return;
    }
    console.log(formValue);
    const resp = await trackPromise(
      API.post(`systemadmin/api/v1/subscriptions/update/status`, editSubDetails)
    );
    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Deactivate Charges</h3>
        </div>

        <div className="body">
          <label style={{display:'flex', flexDirection:'column', width:'50%'}}>
          <label style={{ fontSize: "13px", fontWeight: "500", textAlign:"left" }}>Deactivation Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="deactivateReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{width:'100%', marginTop:'-5px'}}
            >
              <option disabled selected hidden>
                Deactivation Reason
              </option>
              <option value="Plan Discontinued">Plan Discontinued</option>
              <option value="Not an Active User">Not approved by management</option>
              <option value="Plan not required">Plan not required</option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.deactivateReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Deactivation Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          {props === "subscription" ? <>
          <button
            id="submit-button"
            onClick={submitSubsHandler}
            disabled={formValue?.deactivateReason == "" ? true : false}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
          </> : <>
          <button
            id="submit-button"
            onClick={submitHandler}
            disabled={formValue?.deactivateReason == "" ? true : false}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
          </>}
          
        </div>
      </div>
    </div>
  );
};

export default Deactivate;
