import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import auth from "../../../utils/auth";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { successNotification, errorNotification } from "../../../utils/notifications";


const ReceivedQtyView = ({ closeModal, rowData }) => {
  // const UsersData = row.requisitionDetails;


  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(``)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };



  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "70%", height: "400px", padding: "15px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Request for Spares</h3>
          <div>
            <button className="action-btn" style={{ color: "blue" }}>
              <PrintIcon/>
              &nbsp;Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              <IosShareIcon/>
              &nbsp;Export
            </button>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h7>SKU Code : {rowData.skuCode}</h7>
          </div>
        </div>
   

        <div className="body">
          <div>
            <div>
              <Table>
                <tbody>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb"}}>S.No</td>
                    <td style={{backgroundColor:"#ebebeb"}}>Part Serial No.</td>
                    <td style={{backgroundColor:"#ebebeb"}}>PO NO</td>
                  </tr>
                  <tr>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  </tr>
                </tbody>
              </Table>
            </div>



        
          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={submitHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReceivedQtyView;
