import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";

const ManageJobCards = React.lazy(() => import("./Components/ManageJobCards"))
const JobsHistory = React.lazy(() => import("./Components/JobsHistory"))
const CreateJobCards = React.lazy(() => import("./Components/CreateJobCards"))
const JobsSalesReport = React.lazy(() => import("./Components/jobsSalesReport"))

const tabs = [
  {
    id: "SVAM2T1",
    label: TabLabels.CREATE_JOB_CARD,
    Component: CreateJobCards,
  },
  {
    id: "SVAM2T2",
    label: TabLabels.MANAGE_JOB_CARD,
    Component: ManageJobCards,
  },
  {
    id: "SVAM2T3",
    label: TabLabels.JOBS_HISTORY,
    Component: JobsHistory,
  },
  {
    id: "SVAM2T4",
    label: TabLabels.SALES_REPORT,
    Component: JobsSalesReport,
  }
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};


const ServiceAdvisorServiceJobs = () => {

  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  )
};

export default ServiceAdvisorServiceJobs;
