import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../../utils/api";
import { errorNotification, successNotification } from "../../../../../utils/notifications";
import moment from "moment";
import {
  CheckCircle
} from "@material-ui/icons";

const PutOnHold = ({ rowData, closeModal }) => {
  const [formValue, setFormValue] = useState({
    onHoldReason: "",
    description: "",
  });

  const editDetails = {
    status: "On Hold",
    reason: formValue.onHoldReason,
    description: formValue.description,
    requestID:rowData?.requestID
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    const resp = await trackPromise(API.put(`salesUser-module/api/v1/spareReturns/action`,editDetails ));
    if (resp.success) {
      return successNotification(resp.message, { autoClose: 10000 });
    } else {
      return errorNotification(resp.message, { autoClose: 10000 });
    }
  };


  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "500px", height: "400px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Put On Hold</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "13px" }}
        >
          <section style={{display:'flex', flexDirection:'column', width:'50%'}}>
          <label style={{ fontSize: "13px", fontWeight: "500", width: "83px" }}>On Hold Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="onHoldReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{width:'100%', marginTop:'-5px'}}
            >
              <option disabled selected hidden>
                On Hold Reason
              </option>
              <option value="Payment Issue">Payment Issue</option>
              <option value="Too many request">Too many request</option>
              <option value="Others">Others</option>
            </select>
       
          {formValue.onHoldReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="On Hold Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </section>
        </div>
        <br />

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
          <button className="btn btn-icon-label"  type="submit" onClick={submitHandler} style={{backgroundColor:"blue", color:"white"}}>
              <div style={{ fontSize: "14px", color: "white", fontWeight: "500" }}><CheckCircle style={{ fontSize: "17px" }} />&nbsp;Submit</div>
            </button>
        </div>
        
      </div>
    </div>
  );
};

export default PutOnHold;
