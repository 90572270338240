import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import {
  faCheckCircle,
  faPlus,
  faTimesCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  errorNotification,
  infoNotification,
  successNotification,
} from "../../../utils/notifications";
import ViewCustomerDetails from "../Components/ViewCustomerDetails";
import moment from "moment";

const JobCardDetails = ({ rowData, closeModal }) => {
  console.log("rowData-->", rowData);

  const [openSpare, setOpenSpare] = useState();
  const [spareData, setSpareData] = useState();

  const [blankForm, setBlankForm] = useState([
    {
      countryName: "",
      zone: "",
      states: [],
    },
  ]);

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(`operations-module/api/v1/zoneManagement/addZones`, blankForm)
    );
    console.log(resp);
    if (resp.success == true) {
      successNotification(resp.message);
    } else {
      errorNotification(resp.message);
    }
  };

  const viewCustomerFormatter = (rowData) => {
    setOpenSpare(true);
    setSpareData(rowData);
  };

  const cancelHandler = () => {
    infoNotification("No Zone details are Added");
    setBlankForm([
      {
        countryName: "",
        zone: "",
        states: "",
      },
    ]);
  };

  return (
    <div>
      <div className="container1">
        {openSpare ? (
          <ViewCustomerDetails
            rowData={rowData?.customerDetails}
            closeModal={setOpenSpare}
          />
        ) : (
          ""
        )}
        <div className="modalContainer" style={{ height: "550px" }}>
          <div className="header">
            <h3>Job Card Details</h3>
          </div>

          <div style={{ overflowY: "scroll", overflowX: "hidden" }}>
            <div>
              <Table>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>Job Type</td>
                    <td>{rowData?.jobType ? rowData?.jobType : "N/A"}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>Job Card ID</td>
                    <td>{rowData?.jobID ? rowData?.jobID : "N/A"}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>Category</td>
                    <td>
                      {rowData?.additionalSpares?.map((item) => item?.category)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Job Card Date
                    </td>
                    <td>
                      {rowData.jobDate
                        ? moment(rowData.jobDate).format("DD-MMM-YYYY/HH:mm")
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Vehicle Reg. No.
                    </td>
                    <td>
                      {rowData?.vehicleRegNo ? rowData?.vehicleRegNo : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Customer Details
                    </td>
                    <td
                      onClick={() => viewCustomerFormatter(rowData)}
                      style={{ color: "blue", cursor:"pointer" }}
                    >
                      View Customer Details
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div>
              <h6 style={{ color: "blue" }}>Vehicle & Battery Details</h6>
              <Table>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>Vehicle SKU</td>
                    <td>
                      {rowData?.vehicleSkuCode
                        ? rowData?.vehicleSkuCode
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>Vehicle Make</td>
                    <td>
                      {rowData?.vehicleMake ? rowData?.vehicleMake : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Vehicle Model
                    </td>
                    <td>
                      {rowData?.vehicleModel ? rowData?.vehicleModel : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Vehicle Variant
                    </td>
                    <td>
                      {rowData?.vehicleVariant
                        ? rowData?.vehicleVariant
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>Vehicle Type</td>
                    <td>
                      {rowData?.vehicleType ? rowData?.vehicleType : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>Color</td>
                    <td>
                      {rowData?.vehicleColor ? rowData?.vehicleColor : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Engine Number
                    </td>
                    <td>{rowData?.engineNo ? rowData?.engineNo : "N/A"}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Chassis Number
                    </td>
                    <td>{rowData?.chassisNo ? rowData?.chassisNo : "N/A"}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Battery Number
                    </td>
                    <td>{"N/A"}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Date Of Purchase
                    </td>
                    <td>
                      {moment(rowData.dateOfPurchase).format(
                        "DD-MMM-YYYY/HH:mm"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Warranty Duration
                    </td>
                    <td>{rowData?.warrantyDuration}</td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div>
              <h6 style={{ color: "blue" }}>Service Details</h6>
              <Table>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Service Category
                    </td>
                    <td>
                      {rowData?.serviceDetails?.serviceCategory
                        ? rowData?.serviceDetails?.serviceCategory
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>Service Type</td>
                    <td>
                      {rowData?.serviceDetails?.serviceType
                        ? rowData?.serviceDetails?.serviceType
                        : "N/A"}
                    </td>
                  </tr>
                  {/* <tr>
                    <td style={{backgroundColor:"#ebebeb"}}>Service Number</td>
                    <td>{rowData?.serviceDetails?.serviceNo ? (rowData?.serviceDetails?.serviceNo) : "N/A"}</td>
                  </tr> */}
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Mandatory Inspection Checks
                    </td>
                    <td>
                      {rowData?.inspectionsChecks?.mandatoryInspectionChecks?.map(
                        (item, index) =>
                          item.inspectionCheck.map((check, subIndex) => (
                            <div key={subIndex}>
                              {check?.inspectionCheck
                                ? check?.inspectionCheck
                                : "N/A"}
                              <br />
                            </div>
                          ))
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Additional Checks
                    </td>
                    <td>
                      {rowData?.inspectionsChecks?.optionalInspectionChecks?.map(
                        (item, index) =>
                          item.inspectionCheck.map((check, subIndex) => (
                            <div key={subIndex}>
                              {check?.inspectionCheck
                                ? check?.inspectionCheck
                                : "N/A"}
                              <br />
                            </div>
                          ))
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Mandatory Replacements
                    </td>
                    <td>
                      {rowData?.inspectionsChecks?.spares
                        ?.filter((spare) => spare?.spareType === "Mandatory")
                        .map((spare, index) => (
                          <div key={`mandatory-spare-${index}`}>
                            {spare?.componentName
                              ? spare?.componentName
                              : "N/A"}
                            <br />
                          </div>
                        ))}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Additional Spares
                    </td>
                    <td>
                      {rowData?.inspectionsChecks?.spares
                        ?.filter((spare) => spare?.spareType === "Optional")
                        .map((spare, index) => (
                          <div key={`optional-spare-${index}`}>
                            {spare?.componentName
                              ? spare?.componentName
                              : "N/A"}
                            <br />
                          </div>
                        ))}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Technician / Mechanic
                    </td>
                    <td>
                      {" "}
                      {rowData?.serviceDetails?.technicianName
                        ? rowData?.serviceDetails?.technicianName
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>Job Date</td>
                    <td>
                      {moment(rowData.jobDate).format("DD-MMM-YYYY/HH:mm")}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Service Charges
                    </td>
                    <td>
                      {rowData?.serviceDetails?.serviceCharge ? rowData?.serviceDetails?.serviceCharge : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <div
            className="body"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
            }}
          >
            <ul
              type="none"
              className="viewspeclist"
              style={{
                paddingLeft: "0px",
                textAlign: "left",
                fontSize: "14px",
              }}
            >
              {/* {rowData.specifications.map((item) => {
                  return(
                   <li>{`Component ${item.specsType} : ${item.specsValue}${item.specsUnit}`}</li>
                  )
                  })} */}
            </ul>
          </div>
          <div className="save-cancel" style={{ justifyContent: "flex-end" }}>
            <div className="cust-reset  cancel-button">
              <button
                type="button"
                className="btn btn-icon-label"
                onClick={() => closeModal(false)}
                style={{ height: "40px" }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    color: "#0A18A3",
                    fontWeight: "500",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={{ fontSize: "16px" }}
                  />{" "}
                  Cancel
                </div>
              </button>
            </div>
            <div className=" cancel-button">
              <button
                className="btn btn-icon-label"
                onClick={submitHandler}
                style={{ background: "#0A18A3", height: "40px" }}
              >
                <div
                  style={{ fontSize: "14px", color: "#fff", fontWeight: "500" }}
                >
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ fontSize: "16px" }}
                  />{" "}
                  Submit
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCardDetails;
