import { set } from 'lodash';
import React,{useState} from 'react'
import AppConstants from '../../../utils/app-constants';
import "../../settings-tabs/homesettings.scss";
import "../../wo-shopfloor/shopfloor.scss";
import moment from "moment";
import WoProductionQty from './wo-productionqty';
import { KeyboardArrowLeft, KeyboardBackspace } from '@material-ui/icons';

const WoRejectedViewDetails = ({closeModal,rowdata}) => {
 
  const [viewProdQty, setViewProdQty] = useState(false);
    console.log(rowdata)

  return (
    <div className="container1">
      <div className="modalContainer" style={{width:"712px",height:"395px"}}>
      <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>View Details</h3>
          {/* <div>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Print 
          </button>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Export
          </button>
          </div> */}
        </div>

 <div className="" style={{height:"402px",overflow:"auto", marginTop:"2%"}}>
        
      <div
        style={{
            width:"100%",
          display: "flex",
          flexDirection: "column",
          fontSize: "14px",
          fontWeight: "100",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>WO ID: {rowdata?.orderID}</span>
          <span>BOM ID: {rowdata?.BOMID}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>WO Type: {rowdata?.workType}</span>
          <span>BOM Name: {rowdata?.bomName}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>WO Timeline: {moment(rowdata?.startDate).format("DD-MMM-YYYY, HH:mm")} to {moment(rowdata?.endDate).format("DD-MM-YYYY, HH:mm")}</span>
          <span>BOM Location: {rowdata?.bomLocation}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        {viewProdQty && <WoProductionQty rowdata={rowdata} closeModal={setViewProdQty} />}
        <span>Production Qty: <span style={{color:"blue",cursor:"pointer"}} onClick={()=>setViewProdQty(true)}>{rowdata?.productionQuantity}</span></span>
          <span>BOM Created By: {rowdata?.createdBy}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span></span>
          <span>BOM Creation Date: {moment(rowdata?.createdDate).format("DD-MM-YYYY, HH:mm")}</span>
        </div>
      </div>

      <br/>
      <div>
          <h6>WO Rejection Details</h6>
       <div className="shopfloortbl">
          <table className="sfaction_tbl" style={{width:"100%"}} > 
            <thead>
            <tr>
                <th style={{fontSize:"small", fontWeight:"500"}}>WO Rejection Date</th>
                <th style={{fontSize:"small", fontWeight:"500"}}>WO Rejection Reason</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td style={{fontSize:"small"}}>{moment(rowdata?.rejectionDate).format("DD-MM-YYYY")}</td>
            <td style={{fontSize:"small"}}>{rowdata?.reason}</td>
            </tr>
            </tbody>
            </table>
          </div>
      </div>

</div>
        <div className="footer">
          <button onClick={() => closeModal(false)}><KeyboardBackspace/> Back</button>
        </div>
      </div>
    </div>
  );
};

export default WoRejectedViewDetails;
