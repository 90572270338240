import * as XLSX from "xlsx";

const handleExport = (excelFile, fileName) => {
  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(excelFile);
  XLSX.utils.book_append_sheet(wb, ws, "myExcelSheet");
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export default handleExport;
