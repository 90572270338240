import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

// import JobCardDetails from "./JobCardDetails";
import moment from "moment";
import ReturnUnits from "./ReturnUnits";
import Specification from "../../../../AreaSalesOrder/SparesOrders/Component/Specification";

const ViewDetails = ({ closeModal, rowData }) => {
  // const UsersData = row.requisitionDetails;

  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);

  //   const Data = [...rowData.serviceDetails.additionalSpares, ...rowData.serviceDetails.mandatoryReplacements]
  //   console.log(Data)

  return (
    <div className="container1">
      {open && <ReturnUnits rowData={rowData} closeModal={setOpen} />}
      {view && <Specification rowData={rowData} closeModal={setOpen} />}

      <div
        className="modalContainer"
        style={{ width: "65%", height: "400px", padding: "15px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Spares Return</h3>
          <div>
            <button className="action-btn" style={{ color: "blue" }}>
              <PrintIcon />
              &nbsp;Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              <IosShareIcon />
              &nbsp;Export
            </button>
          </div>
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding: 7,
          }}
        >
          <section
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              textAlign: "start",
            }}
          >
            <div>Request ID: {rowData?.requestID}</div>
            <div>
              Request Date: {moment(rowData?.requestDate).format("DD-MM-YYYY")}
            </div>
            <div>Request By.: {rowData?.rpi}</div>
            <div>GIN No: {rowData?.ginNo}</div>
          </section>
          <section
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              textAlign: "end",
            }}
          >
            <div>RPI No: {rowData?.rpiNo}</div>
            <div>RPI Date: {moment(rowData?.rpiDate).format("DD-MM-YYYY")}</div>
            <div>RPI Raised By: {rowData?.rpiRaisedBy}</div>
          </section>
        </section>
        <br />

        <section
          id="nr_POno"
          style={{
            width: "100%",
            fontSize: "14px",
            margin: "10px 0",
            border: "none",
            overflow: "scroll",
          }}
        >
          <div
            style={{
              padding: "0px",
              border: "1px solid #cdcdcd",
              width: "fit-content",
              fontSize: "13px",
            }}
          >
            <div style={{ width: "50px", padding: "10px" }}>S.No</div>
            <div style={{ width: "200px", padding: "10px" }}>SKU Code</div>
            <div style={{ width: "200px", padding: "10px" }}>Category</div>
            <div style={{ width: "200px", padding: "10px" }}>Sub Category</div>
            <div style={{ width: "200px", padding: "10px" }}>Specification</div>
            <div style={{ width: "200px", padding: "10px" }}>Returned Qty.</div>
            <div style={{ width: "200px", padding: "10px" }}>Status</div>
          </div>
          <section style={{ width: "fit-content", height: "auto" }}>
            {rowData?.returnedData?.map((item, index) => (
              <div
                style={{
                  padding: "0px",
                  border: "1px solid #cdcdcd",
                  width: "",
                  fontSize: "13px",
                  display: "flex",
                }}
              >
                <div style={{ width: "50px", padding: "10px" }}>
                  {index + 1}
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  {item?.skuCode}
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  {item?.category}
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  {item?.subCategory}
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                  onClick={() => setView(true)}
                >
                  Specification
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                    color: "blue",
                  }}
                  onClick={() => setOpen(true)}
                >
                  {rowData?.returnQty?.length}
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                    color: "blue",
                  }}
                >
                  {item?.status == "Verification Passed" ? (
                    <button className="active-btn">
                      <span className="active-btn-font">
                        Verification Passed
                      </span>
                    </button>
                  ) : (
                    <button className="delete-btn">
                      <span className="delete-btn-font">
                        Verification Failed
                      </span>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </section>
        </section>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
