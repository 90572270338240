import React, { useState } from "react";
import ViewAssignedHistory from "./ViewAssignedHistory";

const SparesAction = ({ rowData , rowIndex }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  console.log(rowData);

  const clickHandler = (e) => {
    setActiveModal({
      fullSparesAssigned: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.fullSparesAssigned && showModal && (
        <ViewAssignedHistory rowData={rowData} closeModal={setShowModal} />
      )}

      <div style={{ position: "relative", marginTop: rowIndex <= 4 ? "0px" : "-40px", }}>
        <div className="action vendoraction" style={{width:"auto"}}>
          {rowData.status == "Full Spares Assigned" 
          || rowData.status == "Partial Spares Assigned"
            ? (
            <div>
              <span id="fullSparesAssigned" onClick={(e) => clickHandler(e)}>
              View Assignment History
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default SparesAction;
