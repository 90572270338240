import { KeyboardBackspace } from "@material-ui/icons";
import React from "react";

function DealerShowroomAvailableQty({ row, closeModal, tabName }) {
    console.log(tabName)
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "700px", height: "400px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>
            {tabName == "returns" ? "Returned Quantity" : "Available Quantity"}
          </h3>
        </div>

        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px",
              }}
            >
              <span>Showroom Name : {row.showroomName}</span>
              <span>Vehicle Color : {row.vehicleColor}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px",
              }}
            >
              <span>Vehicle Model : {row.vehicleModel}</span>
              <span>Vehicle Variant : {row.vehicleVariant}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px",
              }}
            >
              <span>Available Quantity : {tabName = "returns" ? row.returnedQty.length : row.availableQty.length}</span>
            </div>
          </section>

          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
            }}
          >
            <div style={{ padding: "0px", border: "1px solid #cdcdcd" }}>
              <span style={{ width: "10%", padding: "10px" }}>S.No.</span>
              <span style={{ width: "18%", padding: "10px" }}>
                Vehicle Type
              </span>
              <span style={{ width: "18%", padding: "10px" }}>PO No.</span>
              <span style={{ width: "18%", padding: "10px" }}>
                Vehicle Serial No.
              </span>
              <span style={{ width: "18%", padding: "10px" }}>VIN</span>
              <span style={{ width: "18%", padding: "10px" }}>BIN</span>
            </div>
            <section
              style={{ width: "100%", height: "180px", overflow: "scroll" }}
            >
              {row.availableQty.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    padding: "0px",
                    border: "1px solid #cdcdcd",
                    borderTop: "none",
                  }}
                >
                  <span
                    style={{
                      width: "10%",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {index + 1}
                  </span>
                  <span
                    style={{
                      width: "18%",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.vehicleType}
                  </span>
                  <span
                    style={{
                      width: "18%",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.PONo}
                  </span>
                  <span
                    style={{
                      width: "18%",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.vehicleSerialNo}
                  </span>
                  <span
                    style={{
                      width: "18%",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.VINNo}
                  </span>
                  <span
                    style={{
                      width: "18%",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item.BINNo}
                  </span>
                </div>
              ))}
            </section>
          </section>
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default DealerShowroomAvailableQty;
