import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import Table from "react-bootstrap/Table";
import {
  faCheckCircle,
  faPlus,
  faTimesCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PreviousJobCard from "./previousJobCard";

const ServiceHistoryDetails = ({ closeModal, UsersData }) => {
  const [open, setOpen] = useState(false);
  const [serviceHistoryData, setServiceHistoryData] = useState([]);
  const [jobData, setJobData] = useState({});
  const [prevService, setPrevService] = useState({
    serviceType:"",
    serviceCategory:"",
    jobDate:""
  })

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `serviceAdvisor-module/api/v1/serviceJobs/getAllServiceHistory?vehicleRegNo=${UsersData?.vehicleRegNo}`
      )
    );
   
    setServiceHistoryData(resp.data);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const clickHandler = (item) => {
    console.log(item);
    setOpen(true);
    setJobData(item);
  
    // Find the previous service
    const selectedJobDate = new Date(item?.serviceDetails?.jobDate);
    const previousService = serviceHistoryData
      .filter(
        (service) =>
          new Date(service?.serviceDetails?.jobDate) < selectedJobDate
      )
      .sort(
        (a, b) =>
          new Date(b?.serviceDetails?.jobDate) -
          new Date(a?.serviceDetails?.jobDate)
      )[0]; // Get the most recent previous service
  
    if (previousService) {
      setPrevService({
        serviceType: previousService?.serviceDetails?.serviceType,
        serviceCategory: previousService?.serviceDetails?.serviceCategory,
        jobDate: previousService?.serviceDetails?.jobDate,
      });
    } else {
      console.log("No previous service found");
    }
  };
  

  return (
    <div className="container1">
      {open && (
        <PreviousJobCard closeModal={setOpen} UsersData={UsersData} serviceHistory={jobData} prevService={prevService}/>
      )}
      <div
        className="modalContainer"
        style={{
          width: "80%",
          height: "fit-content",
          padding: "15px",
          overflow: "scroll",
        }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Services History</h3>
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding: 7,
          }}
        >
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
              fontSize: "14px",
            }}
          >
            Vehicle SKU Code: {UsersData?.vehicleSkuCode}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "end",
              justifyContent: "end",
              fontSize: "14px",
            }}
          >
            Vehicle Type: {UsersData?.vehicleType}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
              fontSize: "14px",
            }}
          >
            Vehicle Model: {UsersData?.vehicleModel}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "end",
              justifyContent: "end",
              fontSize: "14px",
            }}
          >
            Vehicle Color: {UsersData?.vehicleColor}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
              fontSize: "14px",
            }}
          >
            Vehicle Variant: {UsersData?.vehicleVariant}
          </span>
        </section>
        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div>
              <Table>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>S.No</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>Service Type</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Service Category
                    </td>
                    <td style={{ backgroundColor: "#ebebeb" }}>
                      Last Service Date
                    </td>
                    <td style={{ backgroundColor: "#ebebeb" }}>Job Card</td>
                  </tr>
                  {serviceHistoryData?.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item?.serviceDetails?.serviceType}</td>
                        <td>{item?.serviceDetails?.serviceCategory}</td>
                        <td>{moment(item?.serviceDetails?.jobDate).format("DD-MM-yyyy")}</td>
                        <td
                          style={{ color: "blue", cursor:"Pointer" }}
                          onClick={()=>clickHandler(item)}
                        >
                          {item?.jobID}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div
          className="save-cancel"
          style={{ margin: "22px 0px 0px 20px", justifyContent: "right" }}
        >
          <div className="cust-reset  cancel-button">
            <button
              type="button"
              className="btn btn-icon-label"
              onClick={() => closeModal(false)}
            >
              <div
                style={{
                  fontSize: "14px",
                  color: "#0A18A3",
                  fontWeight: "500",
                }}
              >
                <faTimesCircle style={{ fontSize: "17px" }} />
                &nbsp;Back
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceHistoryDetails;
 