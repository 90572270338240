import { faClock, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "../../components/modal/modal.scss";
import AppConstants from "../../utils/app-constants";
import Confirmation from "./Confirmation";
import ViewData from "./ViewData";

const RequisitionActions = ({ rowData }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      approve: false,
      onHoldApprove: false,
      onHold: false,
      reject: false,
      release: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.approve && showModal && (
        <Confirmation closeModal={setShowModal} />
      )}

      {activeModal.onHold && showModal && (
        <ViewData rowData={rowData} closeModal={setShowModal} />
      )}

      {activeModal.reject && showModal && (
        <ViewData rowData={rowData} extraData="rejected" closeModal={setShowModal} />
      )}

      {activeModal.release && showModal && (
        <ViewData rowData={rowData} extraData="completed" closeModal={setShowModal} />
      )}

      <div style={{ position: "relative" }}>
        <div className="action vendoraction" style={{ width: "210px", marginLeft: "-155px" }}>
          {rowData.status == AppConstants.NEWREQUEST || rowData.status == "New Request" ? (
            <div>
              <span id="approve" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faClock} /> Request for Proceeding
              </span>
            </div>
          ) : rowData.status == AppConstants.ONHOLD ? (
            <>
              <div>
                <span id="onHold" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faEye} /> View & Request
                </span>
              </div>
            </>
          ) : rowData.status == AppConstants.REJECTED || rowData.status == "Requisition Rejected" ? (
            <div>
              <span id="reject" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEye} /> View Details
              </span>
            </div>
          ) : rowData.status === AppConstants.PO_GENERATED ? (
            <div>
              <span id="release" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEye} /> View Details
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RequisitionActions;
