import React, { useState } from "react";
import moment from "moment";
import { KeyboardBackspace } from "@material-ui/icons";

const ViewDetails = ({ closeModal, rowdata }) => {
  console.log(rowdata);

  return (
    <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "700px", height: "420px", padding: '15px' }}
            >
          <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
               <h3>View Details</h3>
         </div>

               
               

                <div className="footer">
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
  );
};

export default ViewDetails;
