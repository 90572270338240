import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { NavItem } from "reactstrap";
import Action from "./components-actions/ComponentsActions";
import "../../components/modal/modal.scss";
import AppConstants from "../../utils/app-constants";
import SearchBox from "../../components/search/SearchBox";
import { errorNotification } from "../../utils/notifications";
import handleExport from '../../utils/exportExcel';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";



const ManageComponents = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchReport = async () => {
    const resp = await trackPromise(API.get(`procurement-module/api/v1/components?category=${formValue.category}&subCategory=${formValue.subCategory}&componentName=${formValue.componentName}&skuCode=${formValue.skuCode}`));
    console.log(resp);
    resp.success == false ? errorNotification(resp.message, { autoClose: 3000 }) : setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  console.log("users data-->", UsersData)

  const statusFormatter = (cell) => {
    return (
      cell === AppConstants.ACTIVE ?
        <button className="active-btn"><span className="active-btn-font">Active</span></button> :
        cell === AppConstants.DISCONTINUED ?
          <button className="deactive-btn"> <span className="deactive-btn-font">Discontinued</span></button> :
          cell === AppConstants.REACTIVATED ?
            <button className="reactive-btn"> <span className="reactive-btn-font">Reactivated</span></button> :
            cell === AppConstants.DELETED ?
              <button className="delete-btn"> <span className="delete-btn-font">Deleted</span></button> : cell
    );
  };

  const specsFormatter = (cell) => {
    return (
      cell.map((item) => {
        return (
          <span style={{ marginRight: "5px" }}>{item.specsType} : {item.specsValue}{item.specsUnit}</span>
        )
      })
    )
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  };

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const handleClick = (e) => {
    setShow(!show);
  };


  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row);
    return (
      row.status === AppConstants.DELETED ? "" :
        <div>
          <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
            <FontAwesomeIcon icon={faEllipsisH} />
          </button>
          {(actionShow === rowIndex) && open ? (
            <Action updatedData={fetchReport} rowData={row} rowIndex={rowIndex} status={true} setIsOpen={setOpen} />
          ) : null}
        </div>
    );
  };

  const [formValue, setFormValue] = useState({
    category: "",
    subCategory: "",
    skuCode: "",
    componentName: ""
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };


  const clearFilter = async (e) => {
    setFormValue({
      category: "",
      subCategory: "",
      skuCode: "",
      componentName: ""
    });
    const resp = await trackPromise(API.get(`procurement-module/api/v1/components`));
    resp.success == false ? errorNotification(resp.message, { autoClose: 3000 }) : setUsersData(resp);
  };


  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return UsersData.findIndex(function (value, idx, arr) { return value == row }) + 1;
      },
      sort: true,
    },
    {
      text: "Category",
      dataField: "category",
      headerAttrs: { width: "300px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' }
      // classes: "alignment"
    },
    {
      text: "Sub Category",
      dataField: "subCategory",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
      classes: "cellsOverflow"
    },
    {
      text: "SKU Code",
      dataField: "skuCode",
      headerAttrs: { width: "120px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' }
      // classes: "alignment",
      // formatter: noCloudId
    },
    {
      text: "Component Name",
      dataField: "componentName",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' }
      // classes: "alignment",
      // formatter: noCloudId
    },
    {
      text: "Specification",
      dataField: "specifications",
      headerAttrs: { width: "450px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
      formatter: specsFormatter,
      classes: "cellsOverflow",
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "140px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
      // classes: "alignment"
      formatter: statusFormatter,
    },
    {
      text: "Action",
      // dataField: "title",
      headerAttrs: { width: "120px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  let uniqueCategoryArray = [...new Map(UsersData.map((item) => [item["category"], item])).values()];

  return (

    <>
      <div >
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Components</span>
        </div>

        <ToolkitProvider keyField="id" data={UsersData} columns={columns} search >

          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div style={{ display: "flex", marginRight: "-15px", height: "26px", marginTop: "10px", }}>
                    <div style={{ width: "220px" }}>

                      <SearchBar
                        {...props.searchProps}
                        placeholder='Search'
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />

                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "Manage Components")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>

                {show && (
                  <form onSubmit={submitHandler} className="UHinputfields VMform" style={{ display: 'flex', flexDirection: 'row', height: '85px', backgroundColor: '#F7F7F7', padding: '5px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                      <label style={{ fontSize: "13px", fontWeight: "500", }}> Category</label>
                      <select name="category" value={formValue.category} onChange={handleChange} style={{ marginTop: '-5px', width: '100%' }}>
                        <option value='' disabled selected>
                          Category
                        </option>
                        {uniqueCategoryArray.map((item) => {
                          return (<option>{item.category}</option>)
                        })}
                      </select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                      <label style={{ fontSize: "13px", fontWeight: "500", }}>Sub Category</label>
                      <input
                        type="text"
                        name="subCategory"
                        value={formValue.subCategory}
                        onChange={handleChange}
                        placeholder="Sub Category"
                        autoComplete="off"
                        style={{ marginTop: '-5px', width: '100%' }}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                      <label style={{ fontSize: "13px", fontWeight: "500", }}>SKU Code</label>
                      <input
                        type="text"
                        name="skuCode"
                        value={formValue.skuCode}
                        onChange={handleChange}
                        placeholder="SKU Code"
                        autoComplete="off"
                        style={{ marginTop: '-5px', width: '100%' }}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                      <label style={{ fontSize: "13px", fontWeight: "500", }}>Component Name</label>
                      <input
                        type="text"
                        name="componentName"
                        value={formValue.componentName}
                        onChange={handleChange}
                        placeholder="Component Name"
                        autoComplete="off"
                        style={{ marginTop: '-5px', width: '100%' }}
                      />
                    </div>
                    <button onClick={fetchReport} id="apply" style={{ marginTop: '22px', marginLeft: '10px' }}>
                      Apply
                    </button>
                    <button onClick={clearFilter} id="clear" style={{ marginTop: '22px' }}>
                      Clear Filter
                    </button>
                  </form>
                )}
                <br></br>

              </div>


              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManageComponents;