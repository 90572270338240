import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import CreateGRN from "./create-grn";
import ManageGRN from "./manage-grn";
import GoodsVerification from "./goods-verifications";
import VehiclesReturns from "./vehicles-returns";
import ComponentsReturns from "./components-returns";
import auth from '../../utils/auth'

const tabs = [
  {
    id:"INM1T1",
    label: TabLabels.CREATE_GRN,
    Component: CreateGRN
  },
  {
    id:"INM1T2",
    label: TabLabels.MANAGE_GRN,
    Component: ManageGRN
  },
  {
    id:"INM1T3",
    label: TabLabels.GOODS_VERIFICATION,
    Component: GoodsVerification
  },
  {
    id:"INM1T4",
    label: TabLabels.VEHICLES_RETURNS,
    Component: VehiclesReturns
  },
  {
    id:"INM1T5",
    label: TabLabels.COMPONENTS_RETURNS,
    Component: ComponentsReturns
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};


const Vendor = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default Vendor;
