import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import RequestedBatteryQty from "./RequestedBatteryQty";
import moment from "moment";
import DealerVehiclePO from "../../../RequisitionOrders/DealerVehiclePO";

const PODetails = ({ rowData, closeModal }) => {

  console.log("rowData",rowData);

  const [open, setOpen] = useState(false);
  const [batteryDetails, setBatteryDetails] = useState({});
  const [openPO,setPO] = useState(false);

  const SubTotal = (item) => {
    if (item?.vehicleCategory == "Standard") {
      return (item?.unitPrice * item?.requiredQty)
    } else {
      return (item?.unitPrice * item?.requiredQty) + (item?.batteryDetails?.batteryUnitPrice * item?.batteryQty)
    }
  };

  const openBatteryDetails = (item) => {
    setOpen(true);
    setBatteryDetails(item)
  }

  return (
    <div className="container1">
    {
      openPO ? <DealerVehiclePO rowData={rowData} closeModal={setPO}/>:""
    }
      <div
        className="modalContainer"
        style={{ width: "1280px", height: "500px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>PO Details</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderBottom: "0.1px solid grey",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div style={{ fontWeight: "600" }}>Po To : </div>
              <div style={{ fontWeight: "600" }}>{rowData?.poTo}</div>
              <div style={{ fontWeight: "600" }}>{rowData?.poAddress}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div> 
              <span>
              <span>PO No.: </span>
              <span style={{color:"blue",cursor:"pointer"}} onClick={()=>setPO(true)}>{rowData.poNo}</span>
            </span>
            </div>
              <div style={{ fontWeight: "600" }}>PO Date : {moment(rowData?.poDate).format("DD-MMM-YYYY/HH:mm")}</div>
              <div style={{ fontWeight: "600" }}>PO By : {rowData?.poBy}</div>
              <div style={{ fontWeight: "600" }}>PO Value : {rowData?.poValue}</div>
            </section>
          </section>
          <br />

          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderBottom: "0.1px solid grey",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div style={{ fontWeight: "600" }}>Bill To : </div>
              <div style={{ fontWeight: "600" }}>{rowData?.dealerBusinessName}</div>
              <div>{rowData?.dealerAddress}</div>
              <div>Contact Person: {rowData?.dealerContactPerson}</div>
              <div>Contact Number: {rowData?.dealerContact}</div>
              <div>GSTIN: {rowData?.gstin}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div style={{ fontWeight: "600" }}>Ship To : </div>
              <div style={{ fontWeight: "600" }}>{rowData?.showroomName}</div>
              <div>{rowData?.showroomAddress}</div>
              <div>Contact Person: {rowData?.showroomContactPerson}</div>
              <div>Contact Number: {rowData?.showroomContact}</div>
            </section>
          </section>

          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "fit-content",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "50px", padding: "10px" }}>S.No.</div>
              <div style={{ width: "150px", padding: "10px" }}>Vehicle Segment</div>
              <div style={{ width: "150px", padding: "10px" }}>Vehicle Category</div>
              <div style={{ width: "100px", padding: "10px" }}>Vehicle SKU</div>
              <div style={{ width: "100px", padding: "10px" }}>
                Vehicle Make
              </div>
              <div style={{ width: "100px", padding: "10px" }}>
                Vehicle Model
              </div>
              <div style={{ width: "100px", padding: "10px" }}>
                Vehicle Variant
              </div>
              <div style={{ width: "100px", padding: "10px" }}>
                Vehicle Type
              </div>
              <div style={{ width: "100px", padding: "10px" }}>
                Vehicle Color
              </div>
              <div style={{ width: "150px", padding: "10px" }}>
                Requested Vehicle Qty.
              </div>
              <div
                style={{ width: "150px", padding: "10px", overflowX: "scroll" }}
              >
                Vehicle Price/Unit
              </div>
              <div
                style={{ width: "150px", padding: "10px", overflowX: "scroll" }}
              >
                Requested Battery Qty.
              </div>
              <div
                style={{ width: "150px", padding: "10px", overflowX: "scroll" }}
              >
                Battery Price/Unit
              </div>
              <div
                style={{ width: "100px", padding: "10px", overflowX: "scroll" }}
              >
                Sub Total
              </div>
            </div>
            <section style={{ width: "fit-content", height: "auto" }}>
              {rowData?.vehicleData?.map((item, index) => (
                <div
                  style={{
                    padding: "0px",
                    border: "1px solid #cdcdcd",
                    width: "",
                    fontSize: "13px",
                    display: "flex",
                  }}
                >
                  <div style={{ width: "50px", padding: "10px" }}>{index + 1}</div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleSegment}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleCategory}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleSkuCode}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleMake}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleModel}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleVariant}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleType}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleColor}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.requiredQty}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.unitPrice}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "14px",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => openBatteryDetails(item)}
                    >
                      {item?.vehicleCategory == "Standard" ? "-" : item?.batteryQty}
                    </p>

                  </div>
                  <div
                    style={{
                      width: "150px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleCategory == "Standard" ? "-" : item?.batteryDetails?.batteryUnitPrice}
                  </div>
                  <div
                    style={{
                      width: "100px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {SubTotal(item)}
                  </div>
                </div>
              ))}
            </section>
          </section>
          {open && <RequestedBatteryQty rowData={batteryDetails} closeModal={setOpen} />}
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default PODetails;
