import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import Vector from "../QcDashboard/assets/Vector.svg";
import Vector3 from "../QcDashboard/assets/Vector3.svg";
import Group7 from "../QcDashboard/assets/Group7.svg";
import Group8 from "../QcDashboard/assets/Group8.svg";
import Group9 from "../QcDashboard/assets/Group9.svg";
import Group10 from "../QcDashboard/assets/Group10.svg";
import Group11 from "../QcDashboard/assets/Group11.svg";
import Group12 from "../QcDashboard/assets/Group12.svg";
import Group13 from "../QcDashboard/assets/Group13.svg";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);
ChartJS.defaults.plugins.legend.display = false;
import ProgressBar from "react-bootstrap/esm/ProgressBar";
import moment from "moment";
import auth from "../../utils/auth";

const VehicleCard = ({ prop }) => {
  const [data, setData] = useState({});
  const [showroomData, setShowroomSalesData] = useState([]);

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(
        prop == "inventory"
          ? `/showroom-module/api/v1/dashboard/getVehicleInventoryCard`
          : `/showroom-module/api/v1/dashboard/getVehicleSalesCard`
      )
    );
    setData(res);

    const resp = await trackPromise(
      API.get(`showroom-module/api/v1/customerAndSales/getAllVehicleSales`)
    );
    const filteredData = resp.filter((item) => {
      return item.invoiceAmount != undefined || item.invoiceAmount != null
    })
    console.log(filteredData);
    setShowroomSalesData(filteredData);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const filterData = (data, filterType) => {
    const now = new Date();
    const filteredData = data?.filter(item => {
      const invoiceDate = new Date(item.invoiceDate);
      switch (filterType) {
        case 'weekly':
          const weekAgo = new Date();
          weekAgo.setDate(now.getDate() - 7);
          return invoiceDate >= weekAgo && invoiceDate <= now;
        case 'monthly':
          const monthAgo = new Date();
          monthAgo.setMonth(now.getMonth() - 1);
          return invoiceDate >= monthAgo && invoiceDate <= now;
        case 'yearly':
          const yearAgo = new Date();
          yearAgo.setFullYear(now.getFullYear() - 1);
          return invoiceDate >= yearAgo && invoiceDate <= now;
        default:
          return false;
      }
    });

    const totalSoldValue = filteredData?.reduce((sum, item) => sum + parseFloat(item.invoiceAmount), 0);
    const totalSoldUnits = filteredData?.length;

    return { totalSoldValue, totalSoldUnits };
  };

  const weeklyResult = filterData(showroomData, 'weekly');
  const monthlyResult = filterData(showroomData, 'monthly');
  const yearlyResult = filterData(showroomData, 'yearly');

  console.log('Weekly Result:', weeklyResult);
  console.log('Monthly Result:', monthlyResult);
  console.log('Yearly Result:', yearlyResult);


  return (
    <div className="requestBody">
      {prop == "showroom" && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <span style={{ fontSize: "11px", fontWeight: "600", marginLeft: "1%" }}>This Week</span>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <>
                <div
                  className="shopFloorRequest"
                  style={{ height: "auto", width: "115px", margin: "8px 5px" }}
                >
                  <img
                    src={Group9}
                    style={{
                      marginTop: "7px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    alt="group"
                  />
                  <div
                    style={{
                      color: "#808080",
                      fontSize: "11px",
                      textAlign: "center",
                      margin: "2px 6px 0px 6px",
                      borderBottom: "2px solid #F2F2F2",
                    }}
                  >
                    Total Sold Units
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      marginLeft: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "450",
                        textAlign: "center",
                      }}
                    >
                      {weeklyResult.totalSoldUnits}
                    </div>
                  </div>
                </div>
              </>
              <>
                <div
                  className="shopFloorRequest"
                  style={{ height: "auto", width: "115px", margin: "8px 5px" }}
                >
                  <img
                    src={Group12}
                    style={{
                      marginTop: "7px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    alt="group"
                  />
                  <div
                    style={{
                      color: "#808080",
                      fontSize: "11px",
                      textAlign: "center",
                      margin: "2px 6px 0px 6px",
                      borderBottom: "2px solid #F2F2F2",
                    }}
                  >
                    {`Total Sales Value (${auth.getUserInfo().currencySymbol})`}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      marginLeft: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "450",
                        textAlign: "center",
                      }}
                    >
                      {weeklyResult.totalSoldValue?.toLocaleString('en-IN')}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <span style={{ fontSize: "11px", fontWeight: "600", marginLeft: "1%" }}>This Month</span>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <>
                <div
                  className="shopFloorRequest"
                  style={{ height: "auto", width: "115px", margin: "8px 5px" }}
                >
                  <img
                    src={Group9}
                    style={{
                      marginTop: "7px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    alt="group"
                  />
                  <div
                    style={{
                      color: "#808080",
                      fontSize: "11px",
                      textAlign: "center",
                      margin: "2px 6px 0px 6px",
                      borderBottom: "2px solid #F2F2F2",
                    }}
                  >
                    Total Sold Units
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      marginLeft: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "450",
                        textAlign: "center",
                      }}
                    >
                      {monthlyResult.totalSoldUnits}
                    </div>
                  </div>
                </div>
              </>
              <>
                <div
                  className="shopFloorRequest"
                  style={{ height: "auto", width: "115px", margin: "8px 5px" }}
                >
                  <img
                    src={Group12}
                    style={{
                      marginTop: "7px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    alt="group"
                  />
                  <div
                    style={{
                      color: "#808080",
                      fontSize: "11px",
                      textAlign: "center",
                      margin: "2px 6px 0px 6px",
                      borderBottom: "2px solid #F2F2F2",
                    }}
                  >
                    {`Total Sales Value (${auth.getUserInfo().currencySymbol})`}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      marginLeft: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "450",
                        textAlign: "center",
                      }}
                    >
                      {monthlyResult.totalSoldValue?.toLocaleString('en-IN')}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>


        </div>
      )}
      {prop == "inventory" ? (
        <>
          <>
            <div
              className="shopFloorRequest"
              style={{ height: "auto", width: "239px", padding: "5%" }}
            >
              <img
                src={Group9}
                style={{
                  marginTop: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
                alt="group"
              />
              <div
                style={{
                  color: "#808080",
                  fontSize: "14px",
                  textAlign: "center",
                  margin: "10px 6px 0px 6px",
                  borderBottom: "2px solid #F2F2F2",
                }}
              >
                Total Available Units
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  marginLeft: "15px",
                }}
              >
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "450",
                    textAlign: "center",
                  }}
                >
                  {data.totalAvailableUnits}
                </div>
              </div>
            </div>
          </>
          <>
            <div
              className="shopFloorRequest"
              style={{ height: "auto", width: "239px", padding: "5%" }}
            >
              <img
                src={Group12}
                style={{
                  marginTop: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
                alt="group"
              />
              <div
                style={{
                  color: "#808080",
                  fontSize: "14px",
                  textAlign: "center",
                  margin: "10px 6px 0px 6px",
                  borderBottom: "2px solid #F2F2F2",
                }}
              >
                Total Inventory Value
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  marginLeft: "15px",
                }}
              >
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "450",
                    textAlign: "center",
                  }}
                >
                  {data.totalInventoryValue}
                </div>
              </div>
            </div>
          </>
        </>

      ) : (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }} >
          <span style={{ fontSize: "11px", fontWeight: "600", marginLeft: "1%" }}>This Year</span>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="shopFloorRequest"
              style={{ height: "auto", width: "239px" }}
            >
              <img
                src={Group9}
                style={{
                  marginTop: "7px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
                alt="group"
              />
              <div
                style={{
                  color: "#808080",
                  fontSize: "11px",
                  textAlign: "center",
                  margin: "2px 6px 0px 6px",
                  borderBottom: "2px solid #F2F2F2",
                }}
              >
                Total Sold Units
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  marginLeft: "15px",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "450",
                    textAlign: "center",
                  }}
                >
                  {yearlyResult.totalSoldUnits}
                </div>
              </div>
            </div>
            <div
              className="shopFloorRequest"
              style={{ height: "auto", width: "239px" }}
            >
              <img
                src={Group12}
                style={{
                  marginTop: "7px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
                alt="group"
              />
              <div
                style={{
                  color: "#808080",
                  fontSize: "11px",
                  textAlign: "center",
                  margin: "2px 6px 0px 6px",
                  borderBottom: "2px solid #F2F2F2",
                }}
              >
                {`Total Sales Value (${auth.getUserInfo().currencySymbol})`}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  marginLeft: "15px",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "450",
                    textAlign: "center",
                  }}
                >
                  {yearlyResult.totalSoldValue?.toLocaleString('en-IN')}
                </div>
              </div>
            </div>
          </div>
        </div>

      )}


    </div>
  );
};

export default VehicleCard;
