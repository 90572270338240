import React, { useState } from "react";
import "../../../../components/modal/modal.scss";
import { faEdit, faBan, faTrash, faDotCircle, faShare, faEye, faShip, faPrint, faTruckPickup, faClock, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppConstants from "../../../../utils/app-constants";
import RequestRPI from "./requestRPI";
import PrepareForShipment from "./PrepareForShipment";
import ProceedToShipment from "./ProceedToShipment";
import TrackOrder from "./TrackOrder";
import ViewDetails from "./ViewDetails";
import ExportDetails from "./ExportDetails";


const ManageReturnAction = ({ rowData, tableRefresh , rowIndex}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});


  const clickHandler = (e) => {
    setActiveModal({
      requestrpi: false,
      exportdetails: false,
      prepareshipment: false,
      printchallan: false,
      printinvoice: false,
      proceedshipment: false,
      trackorder: false,
      viewdetails: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.requestrpi && showModal &&
        <RequestRPI closeModal={setShowModal} />
      }
      {activeModal.prepareshipment && showModal &&
        <PrepareForShipment rowData={rowData} closeModal={setShowModal} tableRefresh={tableRefresh} />
      }
      {activeModal.proceedshipment && showModal &&
        <ProceedToShipment rowData={rowData} closeModal={setShowModal} tableRefresh={tableRefresh}/>
      }
      {activeModal.trackorder && showModal &&
        <TrackOrder rowData={rowData} closeModal={setShowModal} />
      }
      {activeModal.viewdetails && showModal &&
        <ViewDetails rowData={rowData} closeModal={setShowModal} /> // need to integrated after the vendor flow completion
      }
      {activeModal.exportdetails && showModal &&
        <ExportDetails rowData={rowData} closeModal={setShowModal} />
      }


      <div style={{ position: "relative",  marginTop: rowIndex <= 4 ? "0px" : "-120px", }}>
        <div className="vehicleaction" style={{width:"200px"}}>
          {
            rowData.status === AppConstants.RPI_PENDING ?
              <div>
                <span id="requestrpi" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={fa} />  Request for RPI
                </span>
              </div>
              :
              rowData.status === AppConstants.PENDINGATPROCUREMENT ?
                <div>
                  <span id="exportdetails" onClick={(e) => clickHandler(e)}>
                    <FontAwesomeIcon icon={faPrint} />  Export Details
                  </span>
                </div>
                :
                rowData.status === AppConstants.RPI_GENERATED ?
                  <div>
                    <span id="prepareshipment" onClick={(e) => clickHandler(e)}>
                      <FontAwesomeIcon icon={faArrowUp} />  Prepare for Shipment
                    </span>
                  </div>
                  :
                  rowData.status === AppConstants.READYTOSHIP ?
                    <>
                      <div>
                        <span id="printchallan" onClick={(e) => clickHandler(e)}>
                          <FontAwesomeIcon icon={faPrint} />  Print Delivery Challan
                        </span>
                      </div>
                      <div>
                        <span id="proceedshipment" onClick={(e) => clickHandler(e)}>
                          <FontAwesomeIcon icon={faShare} />  Proceed to Shipment
                        </span>
                      </div>
                    </>
                    :
                    rowData.status === AppConstants.SHIPPEDTOVENDOR ?
                      <div>
                        <span id="trackorder" onClick={(e) => clickHandler(e)}>
                          <FontAwesomeIcon icon={faClock} />  Track Shipment
                        </span>
                      </div>
                      :
                      rowData.status === AppConstants.DELIVEREDTOVENDOR ?
                        <div>
                          <span id="viewdetails" onClick={(e) => clickHandler(e)}>
                            <FontAwesomeIcon icon={faEye} />  View Details
                          </span>
                        </div>
                        : null}

        </div>
      </div>
    </div>
  );
};

export default ManageReturnAction;
