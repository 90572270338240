import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import AddLabour from "./add-labour";
// import ManageLabour from "./manage-labour";

const AddLabour = React.lazy(()=>import("./add-labour"))
const ManageLabour = React.lazy(()=>import("./manage-labour"))


const tabs = [
  {
    label: "Master Labor",
    Component: AddLabour
  },
  // {
  //   label: TabLabels.MANAGE_LABOUR,
  //   Component: ManageLabour
  // }
];

const Labour = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default Labour;