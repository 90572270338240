import React from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import moment from "moment"

const RPIno = ({ closeModal, rowData }) => {
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "980px", height: "425px", padding: '15px' }}
            >
                <div className="header">
                    <h3>RPI Details</h3>
                </div>

                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>RPI No. : {rowData.rpiNo}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>RPI Date: {moment(rowData.rpiDate).format("DD-MMM-YYYY/HH:mm")}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>RPI Raise By : {rowData.showroomName}</span>
                        </div>
                    </section>

                    <section style={{ width: '100%' }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '50px' }}>S.No.</div>
                            <div style={{ width: '100px' }}>Segment</div>
                            <div style={{ width: '80px' }}>Category</div>
                            <div style={{ width: '70px' }}>Model</div>
                            <div style={{ width: '70px' }}>Variant</div>
                            <div style={{ width: '70px' }}>Type</div>
                            <div style={{ width: '70px' }}>Color</div>
                            <div style={{ width: '160px' }}>VIN</div>
                            <div style={{ width: '150px' }}>BIN</div>
                            <div style={{ width: '150px' }}>Return Reason</div>
                        </div>
                        <div>
                            {
                                rowData.returnedData.map((item, index) => (<div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }} key={index}>
                                    <div style={{ width: '50px' }}>{index + 1}</div>
                                    <div style={{ width: '100px' }}>{item.vehicleSegment}</div>
                                    <div style={{ width: '80px' }}>{item.vehicleCategory}</div>
                                    <div style={{ width: '70px' }}>{item.vehicleModel}</div>
                                    <div style={{ width: '70px' }}>{item.vehicleVariant}</div>
                                    <div style={{ width: '70px' }}>{item.vehicleType}</div>
                                    <div style={{ width: '70px' }}>{item.vehicleColor}</div>
                                    <div style={{ width: '160px' }}>{item.VIN}</div>
                                    <div style={{ width: '150px' }}>{item.BIN}</div>
                                    <div style={{ width: '150px' }}>{item.returnReason}</div>
                                </div>))
                            }
                        </div>
                    </section>

                </div>

                <div className="footer">
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
    );
};

export default RPIno; 