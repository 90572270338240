import React, { useState } from "react";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
} from "@material-ui/icons";
import moment from "moment";
import ViewSpecs from "../../bom/bom-viewspecs";
import AppConstants from "../../../utils/app-constants";
import auth from "../../../utils/auth";
import ReactToPrint from "react-to-print";

const POViewDetailsClosed = ({ closeModal, data }) => {
  const [openQty, setOpenQty] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const [showGRN, setShowGRN] = useState();
  const [showGIN, setShowGIN] = useState();

  console.log(data)

  let sum = 0;
  const overAllSum = (item) => {
    return (sum +=
      item.requiredQuantity * item.unitPrice +
      (item.requiredQuantity * item.unitPrice * item.taxRate) / 100);
  };

  // Total Order Qty
  const totalOrders = data.components.map((item) => {
    return item.requiredQuantity;
  });
  const initialValue = 0;
  const totalOrderQty = totalOrders.reduce((a, b) => a + b, initialValue);

  // Total Received Qty
  const totalReceived = data.components.map((item) => {
    return item.receivedQuantity;
  });
  const initialVal = 0;
  const totalReceivedQty = totalReceived.reduce((a, b) => a + b, initialVal);

  // Total Remaining Qty
  const totalremainig = data.components.map((item) => {
    return item.requiredQuantity - item.receivedQuantity;
  });
  const initValue = 0;
  const totalRemainingQty = totalremainig.reduce((a, b) => a + b, initValue);

  let componentRef = null;

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          width: "80%",
          height: "625px",
          padding: "15px",
          borderRadius: "0px",
          borderTop: "none",
        }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Purchase Order</h3>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <button
                    style={{
                      color: "#0A18A3",
                      margin: "1px",
                      height: "27px",
                      width: "90px",
                      border: "none",
                    }}
                  >
                    Download
                  </button>
                );
              }}
              content={() => componentRef}
              documentTitle="Purchase Order Invoice"
              pageStyle="Print"
            />
          </div>
        </div>

        <div
          className="body"
           style={{
            width: " 100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            pageBreakBefore:"always",
          }}
          ref={(el) => (componentRef = el)}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
           <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                      <span>Buyer Name : {data?.buyerName}</span>
                      <span>PO ID : {data?.POID}</span>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <span>Buyer Address : {data?.buyerDetails}</span>
                      <span>Requested Date : {moment(data?.requestDate).format("DD-MMM-YYYY , HH:mm")}</span>
                  </div>
                  
                  <div>
                  {data.status == AppConstants.PARTIALLYCLOSED ? (
            <div className="onhold_div" style={{ backgroundColor: "#C6C9E9" }}>
              <span className="holdDate" style={{ color: "#0A18A3" }}>
                Order Placed Date:
                {moment(data.actionDate).format(
                  "DD-MMM-YYYY, HH:mm"
                )}
              </span>
              <span
                style={{ fontSize: "25px", color: "gray", marginTop: "9px" }}
              >
                |
              </span>
              <span
                className="holdReason"
                style={{ right: "150px", color: "#0A18A3" }}
              >
                Order Status:lorem ipsum{" "}
              </span>
            </div>
          ) : data.status == AppConstants.FULLYCLOSED ? (
            <div className="onhold_div" style={{ backgroundColor: "#C0FCC6" }}>
              <span className="holdDate" style={{ color: "#11781B" }}>
                Order Placed Date:{" "}
                {moment(data.actionDate).format(
                  "DD-MMM-YYYY, HH:mm"
                )}
              </span>
              <span
                style={{ fontSize: "25px", color: "gray", marginTop: "9px" }}
              >
                |
              </span>
              <span
                className="holdReason"
                style={{ right: "150px", color: "#11781B" }}
              >
                Order Status:lorem ipsum{" "}
              </span>
            </div>
          ) : null}
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                      <span>Bill to : {data?.billTo}{data?.buyerDetails}</span>
                      <span>Ship to : {data?.deliveryLocation}{data?.buyerDetails}</span>
                  </div>
          </section>


          <section style={{ width: "76%",flexGrow: 1,}}>
                      <div  id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px', width: "130%" }}>
                      <div style={{ width: "4%" , backgroundColor:"#0A18A3",color:"white"  }}>S.No.</div>
              <div style={{ width: "10%", backgroundColor:"#0A18A3",color:"white"  }}>Component Name</div>
              <div style={{ width: "29%", backgroundColor:"#0A18A3",color:"white"  }}>SKU Code</div>
              <div style={{ width: "29%", backgroundColor:"#0A18A3",color:"white"  }}>Category</div>
              <div style={{ width: "29%" , backgroundColor:"#0A18A3",color:"white" }}>Sub Category</div>
              <div style={{ width: "22%", backgroundColor:"#0A18A3",color:"white" }}>Specification</div>
              <div style={{ width: "22%" , backgroundColor:"#0A18A3",color:"white" }}>Ordered Qty.</div>
              <div style={{ width: "22%", backgroundColor:"#0A18A3",color:"white" }}>{`Unit Price(${auth.getUserInfo().currencySymbol})`}</div>
              <div style={{ width: "22%" , backgroundColor:"#0A18A3",color:"white" }}>{`Sub Order Qty.(${auth.getUserInfo().currencySymbol})`}</div>
              <div style={{ width: "22%", backgroundColor:"#0A18A3",color:"white"  }}>HSN/SAC(%)</div>
              <div style={{ width: "22%" , backgroundColor:"#0A18A3",color:"white" }}>{`Tax(${auth.getUserInfo().currencySymbol})`}</div>
              <div style={{ width: "22%", backgroundColor:"#0A18A3",color:"white"  }}>Received Qty</div>
              <div style={{ width: "22%" , backgroundColor:"#0A18A3",color:"white" }}>Remaining Qty</div>
              <div style={{ width: "22%" , backgroundColor:"#0A18A3",color:"white" }}>{`Total Amount(${auth.getUserInfo().currencySymbol})`}</div>
                      </div>


                      <div>

                      {data.components.map((item, index) => {
              overAllSum(item);
              return (
                <div>
                  <div
                   id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px', width: "130%" }}
                  >
                    <div style={{ width: "10%", overflowY: "auto" }}>
                      {++index}
                    </div>
                    <div style={{ width: "22%", overflowY: "auto" }}>
                      {item.componentName}
                    </div>
                    <div style={{ width: '34%', overflowY: "auto" }}>
                      {item.skuCode}
                    </div>
                    <div style={{ width: '25%', overflowY: "auto" }}>
                      {item.category}
                    </div>
                    <div style={{ width: '22%', overflowY: "auto" }}>
                      {item.subCategory}
                    </div>
                    <div
                      onClick={() => setViewSpecs(index)}
                      style={{
                        width: "32%",
                        overflowY: "scroll",
                        color: "blue",
                        cursor: "pointer",
                      }}
                    >
                      View Specs
                    </div>
                    <div style={{ width: "22%", overflowY: "auto" }}>
                      {item.requiredQuantity}
                    </div>
                    <div style={{ width: "20%", overflowY: "auto" }}>
                      {item.unitPrice.toLocaleString('en-IN')}
                    </div>
                    <div style={{ width: "20%", overflowY: "auto" }}>
                      {(item.requiredQuantity * item.unitPrice).toLocaleString('en-IN')}
                    </div>
                    <div style={{ width: "30%", overflowY: "auto" }}>
                      {item.hsnSacCode}({item.taxRate}%)
                    </div>
                    <div style={{ width: "22%", overflowY: "auto" }}>

                      {((item.requiredQuantity * item.unitPrice * item.taxRate) /
                        100).toLocaleString('en-IN')}
                    </div>
                    <div style={{ width: "22%", overflowY: "auto" }}>
                      {item.receivedQuantity}
                    </div>
                    <div style={{ width: "22%", overflowY: "auto" }}>
                      {item.requiredQuantity - item.receivedQuantity}
                    </div>
                    <div style={{ width: "22%", overflowY: "auto" }}>

                      {(item.requiredQuantity * item.unitPrice +
                        (item.requiredQuantity *
                          item.unitPrice *
                          item.taxRate) /
                        100).toLocaleString('en-IN')}
                    </div>
                  </div>

                  {viewSpecs === index && (
                    <ViewSpecs data={item} close={setViewSpecs} />
                  )}
                </div>
              );
            })}


                      </div>

                  </section>

                  <section className="POterms" style={{backgroundColor:"#0A18A3",justifyContent:"space-between"}}>
          <div>
            <div style={{ fontWeight: "500", textAlign: "left" ,color:"white"}}>
              Terms & Conditions :-
            </div>
            <div
              style={{ fontSize: "11px", textAlign: "left", color: "white" , display:"flex",flex:"wrap" }}
            >
              <ul>
              <li>Price should be inclusive of all charges, Taxes etc.</li>
              <li>Delivery Period should be 7 days from our Purchase Order.</li>
              <li>Supply should be made door delivery</li>
              <li>Supplier should be responsible for any damage or loss of Material</li>
             </ul>
            </div>
          </div>
          
        </section>
      


        </div>

        <div className="footer">
                  <button
                      style={{
                          border: "1px solid #0A18A3",
                          borderRadius: "3px",
                          padding: "5px 15px",
                          marginRight: "10px",
                          color: "#0A18A3",
                      }}
                      onClick={() => closeModal(false)}
                  >
                      <KeyboardBackspace style={{ fontSize: "17px" }} />
                      Back
                  </button>
              </div>
      </div>
    </div>
  );
};

export default POViewDetailsClosed;
