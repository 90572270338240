import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { BsTable } from 'react-icons/bs'
import { FaExpandAlt } from 'react-icons/fa'
import UserCountBarChart from './userCountBarChart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserPieChart from './userPieChart'
import { KeyboardBackspace } from '@material-ui/icons'

const ExpandUserPieChart = ({ closeModal }) => {
    return (
        <div className="container1">
            <div className="modalContainer" style={{ width: '500px', height: "430px" }}>
                <div className='container-box' style={{height: "100%",marginTop:"15px" }}>
                    <div className='header' style={{ justifyContent: "space-between",padding:"5px" }}>
                        <div><h5 style={{ fontSize: "18px" }}>Users</h5></div>
                        <div style={{ display: "flex" }}>
                            <select style={{ height: "25px", borderRadius: "5px", background: "#fff", outline: "none" }}>
                                <option selected value="Month">Month</option>
                                <option value="week">Week</option>
                                <option value="Day">Day</option>
                            </select>
                            <div onClick={() => console.log("hi this is table")} style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#808080", cursor: "pointer", width: "25px", height: "25px", border: "1px Solid #808080", borderRadius: "5px", margin: "0px 2px" }}>
                                <BsTable />
                            </div>
                            <div onClick={() => closeModal(false)} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "25px", height: "25px", border: "1px Solid #808080", color: "#808080", borderRadius: "5px",cursor:"pointer" }}>
                                <FaExpandAlt />
                            </div>
                            <button className="action-btn" style={{ height: "25px", color: "#808080" }}>
                                <FontAwesomeIcon className='fa-lg' icon={faEllipsisV} />
                            </button>
                        </div>
                    </div>
                    <br />
                    <div>
                        <UserPieChart />
                    </div>
                </div>
                <div className="footer">
                    <button onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
    )
}

export default ExpandUserPieChart;