import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import auth from "../../../../utils/auth";
import ViewSpecs from "../../../bom/bom-viewspecs";

const ViewComponentsBom = ({ close, compdata, location }) => {

  console.log("compdata-->",compdata)

  const [viewSpecs, setViewSpecs] = useState(false);

  //   const totalPrice = () => {
  //     let total = 0;
  //     data.map(item => (
  //         total += ((item?.unitPrice * item?.releasedQty + item?.taxAmount))
  //     ))
  //     return total;
  // }

  const vendorDetails = [];
  compdata?.map((item, index) => {
    item?.vendorDetails?.map((data, i) => {
      if (data?.selected == true) {
        vendorDetails?.push(data)
      }
    })
  })


  const unitPrice = []

  compdata?.map((item, index) => {
    item?.vendorDetails?.map((value, i) => {
      if (value?.selected == true) {
        value?.deliveryDetails?.map((data, ind) => {
          if (data?.deliveryLocation == location) {
            unitPrice?.push(data?.unitPrice)
          }
        })
      }
    })
  })

  const calculateTotalPrice = compdata?.map((item, index) => {
    return ((parseInt(item?.componentQuantity) * parseInt(item?.vendorDetails[0]?.deliveryDetails[0]?.unitPrice)))
  })

  const totalPrice = calculateTotalPrice?.reduce((acc, value) => {
    return acc + value
  }, 0)



  const totalValue = compdata?.reduce((accumulator, item, index) => {
    const unitPriceValue = parseInt(item?.vendorDetails[0]?.deliveryDetails[0]?.unitPrice) || 0; // Ensure unitPrice is a number
    const quantity = parseInt(item?.componentQuantity) || 0; // Ensure quantity is a number
    return accumulator + (unitPriceValue * quantity);
  }, 0);
  



  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "1170px", height: "521px", padding: "15px" }}
        >

          <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Bom Components Details</h3>
            <div>
              {/* <button
                className="action-btn"
                style={{ color: "blue" }}>
                Print
              </button>
              <button
                className="action-btn"
                style={{ color: "blue" }}>
                Export
              </button> */}
            </div>
          </div>

          <div className="body" style={{ height: "340px" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column", fontSize: "12px", fontWeight: "450" }}>
                <span>Total Component : {compdata?.length}</span>
                <span>{`Total Cost(${auth?.getUserInfo()?.currencySymbol}) :`} {totalValue}</span>
              </div>
            </div>

            <div style={{ width: "100%", overflow: "auto", height: "325px" }}>
              <table style={{ width: "130%" }} className="viewcomp_tbl">
                <thead >
                  <tr >
                    <th>S.No.</th>
                    <th>Component Name</th>
                    <th>SKU Code</th>
                    <th>Category</th>
                    <th>Sub Category</th>
                    <th>Specification</th>
                    <th>Vendor Part Name</th>
                    <th>Vendor Part Code</th>
                    <th>{`Unit Price(${auth.getUserInfo().currencySymbol})`}</th>
                    <th>Qty.</th>
                    <th>{`Sub Total(${auth.getUserInfo().currencySymbol})`}</th>
                  </tr>
                </thead>
                <>
                  <tbody >
                    {compdata?.map((item, index) => {
                      return (
                        <>
                          {viewSpecs === index && <ViewSpecs rowData={item} close={setViewSpecs} />}
                          <tr >
                            <td className="leftcell">{index + 1}</td>
                            <td>{item?.componentName}</td>
                            <td>{item?.skuCode} </td>
                            <td>{item?.category}</td>
                            <td>{item?.subCategory}</td>
                            <td>{<span onClick={() => setViewSpecs(index)}
                              style={{ color: "#0a18a3", fontWeight: "500", cursor: 'pointer' }}>
                              View Specs</span>}
                            </td>
                            <td>{item?.vendorDetails[0]?.vendorPartName}</td>
                            <td>{item?.vendorDetails[0]?.vendorPartCode}</td>
                            <td>{item?.vendorDetails[0]?.deliveryDetails[0]?.unitPrice}</td>
                            <td>{item?.componentQuantity}</td>
                            <td className="rightcell">{parseInt(item?.vendorDetails[0]?.deliveryDetails[0]?.unitPrice) * parseInt(item?.componentQuantity)}</td>
                          </tr>
                        </>
                      )

                    })}

                  </tbody>

                </>

              </table>


            </div>
          </div>
          <section style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', borderRadius: '5px', margin: '5px 0', height: "35px" }}>
            <div style={{ width: '200px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', fontSize: '14px', fontWeight: '500' }}>
              {`Total Price(${auth.getUserInfo().currencySymbol}) :`}
              <span style={{ width: '100px', background: '#ebebeb', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                {/* ₹{totalPrice()} */}
                {totalPrice}
              </span>
            </div>
          </section>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}><KeyboardBackspace/> Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewComponentsBom;