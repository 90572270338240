import React, { useState } from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import DelegateRights from "./delegateRights";
// import ManageRoles from "./manageRoles";

const DelegateRights = React.lazy(()=>import("./delegateRights"))
const ManageRoles = React.lazy(()=>import("./manageRoles"))

const tabs1 = [
  {
    id: "SAM3T1",
    label: TabLabels.DELEGATE_RIGHTS,
    Component: DelegateRights
  },
  {
    id: "SAM3T2",
    label: TabLabels.MANAGE_ROLES,
    Component: ManageRoles
  }
]
const tabs2 = [
  {
    id: "SAM3T2",
    label: TabLabels.MANAGE_ROLES,
    Component: ManageRoles
  },
]

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};


const RolesManagementTabs = () => {
  let tabsToShow = getTabs(auth.isSubAdmin() ? tabs2 : tabs1);
  return (
    <>
      <div className="app-layout__card">
        {auth.isSubAdmin() ? <Tabs tabs={tabsToShow} type="pills" /> : <Tabs tabs={tabsToShow} type="pills" />}
      </div>
    </>

  )

};  

export default RolesManagementTabs;
