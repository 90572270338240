import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";

const RequisitionProgress = React.lazy(()=>import("./RequisitionProgress"))
const POHistory = React.lazy(()=>import('./POHistory'))


const tabs = [
  {
    id:"SVCM3T1",
    label: TabLabels.REQUISITION,
    Component: RequisitionProgress
  },
  {
    id:"SVCM3T2",
    label: TabLabels.PO_HISTORY,
    Component: POHistory
  }
];

const ServiceCenterOrders = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default ServiceCenterOrders;
