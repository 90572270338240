import React, { useState } from "react";
import "../../../../components/modal/modal.scss";
import { faEdit, faBan, faShare, faPauseCircle, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppConstants from "../../../../utils/app-constants";
import NewRequestOnHold from "../../vehicleOutward/actions/onHold";

const NewRequestCompAction = ({ updatedData, rowData, display , rowIndex}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      releaseInventory: false,
      onHold: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  console.log("hi")

  return (
    <div>
      {
        rowData.status == AppConstants.PENDING_AT_INVENTORY || rowData.status == "Pending At Inventory" ?
          activeModal.onHold && showModal && (
            <NewRequestOnHold updatedData={updatedData} editData={rowData} closeModal={setShowModal} />) : ""
      }

      <div style={{ position: "relative",  marginTop: rowIndex <= 4 ? "0px" : "-70px",}}>
        <div className="vehicleaction">

          {/* {rowData.status == AppConstants.PARTIALRELEASE || rowData.status == "Partial In Progress" || rowData.status == "Partial Order Completed" ?
            <div>
              <span id="releaseInventory" onClick={(e) => display(false)}>
                <FontAwesomeIcon icon={faPrint} /> Print Release Note
              </span>
            </div> : null} */}

          {rowData.status == AppConstants.PENDING_AT_INVENTORY || rowData.status == AppConstants.ONHOLD || rowData.status == AppConstants.PARTIALASSIGNMENT || rowData.status == "Pending At Inventory" || rowData.status == "Partial Release" || rowData.status == "Partial In Progress" || rowData.status == "Partial Order Completed" || rowData.status == "Partial Invoice Generated" || rowData.status == "Ready To Ship" ?
            <div>
              <span id="releaseInventory" onClick={(e) => display(false)}>
                <FontAwesomeIcon icon={faShare} />  Release Inventory
              </span>
            </div> : null}


          {rowData.status === AppConstants.PENDING_AT_INVENTORY || rowData.status == "Pending At Inventory" ?
            <div>
              <span id="onHold" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faPauseCircle} />  On Hold
              </span>
            </div> : null}

        </div>
      </div>
    </div>
  );
};

export default NewRequestCompAction;