import { KeyboardBackspace } from "@material-ui/icons";
import React from "react";
import moment from "moment";

const ReturnUnit = ({ rowData, closeModal }) => {
  console.log(rowData)
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "540px", height: "310px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Returned Units</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <br />
          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "auto",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "10%", padding: "10px" }}>S No</div>
              <div style={{ width: "150px", padding: "10px" }}>Part S.No</div>
              <div style={{ width: "150px", padding: "10px"}}>Return Reason</div>
              <div style={{ width: "150px", padding: "10px" }}>Invoice No</div>
              <div style={{ width: "150px", padding: "10px" }}>Invoice Date</div>
            </div>
            <section
              style={{ width: "auto", height: "auto" }}
            >
              {rowData?.map((item, index) => (
              <div
               style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                fontSize: "13px",
                display:"flex"
              }}
            >
              <div style={{ width: "10%", padding: "10px", overflowX:"scroll" }}>{index+1}</div>
              <div style={{ width: "150px", padding: "10px", overflowX:"scroll" }}>{item?.partSno}</div>
              <div style={{ width: "150px", padding: "10px", overflowX:"scroll" }}>{item?.returnReason}</div>
              <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    {item?.invoiceNo}
                  </p>
                  
                </div>
                <div
                  style={{
                    width: "200px",
                    padding: "10px",
                    overflowX: "scroll",
                  }}
                >
                 {moment(item?.invoiceDate).format("DD-MM-YYYY")}
                </div>
            </div>
             ))}
            </section>
          </section>
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReturnUnit;
