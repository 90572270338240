import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import CreateWorkorder from "./create-workorder";
// import ManageWorkorder from "./manage-workorder";
// import ManageAddon from "./manage-addon";
// import ProductionRequest from "./ProductionRequest/ProductionRequest";

const CreateWorkorder = React.lazy(()=>import("./create-workorder"))
const ManageWorkorder = React.lazy(()=>import("./manage-workorder"))
const ManageAddon = React.lazy(()=>import("./manage-addon"))
const ProductionRequest = React.lazy(()=>import("./ProductionRequest/ProductionRequest"))



const Workorder = () => {
  const tabs = [
    {
      id: "PDM4T1",
      label: TabLabels.CREATE_WORKORDER,
      Component: CreateWorkorder
    },
    {
      id: "PDM4T2",
      label: TabLabels.MANAGE_WORKORDER,
      Component: ManageWorkorder
    },
    {
      id: "PDM4T3",
      label: TabLabels.MANAGE_ADDON,
      Component: ManageAddon
    },
    {
      id: "PDM4T4",
      label: TabLabels.PRODUCTION_REQUEST,
      Component: ProductionRequest
    },
  ];


  const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
    return tabs.filter(tab => tabIds.includes(tab.id));
  };

  let tabsToShow = getTabs(tabs);

  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>

  )
}
export default Workorder