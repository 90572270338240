import React, { useState, useEffect } from "react";
import "../../work-order/workorder.scss";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import ViewSpecs from "../../bom/bom-viewspecs";
import { KeyboardBackspace } from "@material-ui/icons";
import ReceivedQtySP from "../../wo-shopfloor/wo-sf-actions/wo-sf-sp-receivedqty"

const WoInProgressViewComp = ({ close, compdata }) => {
  const [viewSpecs, setViewSpecs] = useState(false);
  const [showQty, setShowQty] = useState();
  const [open, setOpen] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [addedQty, setAddedQty] = useState();
  const [added, setAdded] = useState([]);
  const [receivedQuant, setReceivedQuant] = useState([]);

  console.log(compdata)

  const fetchReport = async () => {
    let body = {
      orderID: compdata?.orderID,
      status: "Accepted_Accepted",
      requiredItemsType: "components",
    };
    const resp = await trackPromise(
      API.post(
        `shop-floor-module/api/v1/shopFloor/getRequirementItemType`,
        body
      )
    );
    console.log(resp);
    setUsersData(resp);

    const receiveQuant = await trackPromise(
      API.get(
        `shop-floor-module/api/v1/shopFloor/recivedQty?orderID=${compdata?.orderID}`
      )
    );
    console.log(receiveQuant);
    setReceivedQuant(receiveQuant);

    const result = await trackPromise(
      API.get(
        `shop-floor-module/api/v1/shopFloor/addedQty?orderID=${compdata?.orderID}`
      )
    );
    setAdded(result);
  };

  useEffect(() => {
    fetchReport();
  }, []);
  console.log(UsersData);
  console.log(receivedQuant);
  console.log(added);

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "1170px", height: "521px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Required Components</h3>
            {/* <div>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Print 
          </button>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Export
          </button>
          </div> */}
          </div>

          <div className="body" style={{ height: "340px", flexWrap: "nowrap" }}>
            <div style={{ width: "100%", overflow: "auto", height: "325px" }}>
              <table className="viewcomp_tbl">
                <thead>
                  <tr>
                    <th style={{ fontSize: "11px", fontWeight: "900" }}>
                      S.No.
                    </th>
                    <th style={{ fontSize: "11px", fontWeight: "900" }}>
                      SKU Code
                    </th>
                    <th style={{ fontSize: "11px", fontWeight: "900" }}>
                      Component Name
                    </th>

                    <th style={{ fontSize: "11px", fontWeight: "900" }}>
                      Required Qty
                    </th>
                    <th style={{ fontSize: "11px", fontWeight: "900" }}>
                      Received Qty
                    </th>
                    <th style={{ fontSize: "11px", fontWeight: "900" }}>
                      Added Qty
                    </th>
                    <th style={{ fontSize: "11px", fontWeight: "900" }}>
                      Remaining Receivable Qty
                    </th>
                  </tr>
                </thead>
                {UsersData?.map((item, index) => {
                  console.log(item?.componentQuantity)
                  console.log(compdata?.productionQuantity)
                  return (
                    <tr>
                      <td style={{ fontSize: "11px" }} className="leftcell">
                        {index + 1}
                      </td>
                      <td style={{ fontSize: "11px" }}>{item?.skuCode}</td>
                      <td style={{ fontSize: "11px" }}>
                        {item?.componentName}
                      </td>

                      <td style={{ fontSize: "11px" }}>
                        {parseInt(item?.componentQuantity) *
                          parseInt(compdata?.productionQuantity)}
                      </td>
                      <td style={{ fontSize: "11px" }}>
                        <button
                          onClick={() => {setShowQty(index); setOpen(true);}}
                          className="action-btn"
                          style={{ color: "blue" }}
                        >
                          {receivedQuant?.map((data) => {
                            if (data?.componentName == item?.componentName) {
                              if (data?.partsSno) {
                                return data?.partsSno.length;
                              } else {
                                return 0;
                              }
                            }
                          })}
                        </button>
                        {showQty == index && open && (
                          <ReceivedQtySP
                            orderID={compdata?.orderID}
                            updatedData={fetchReport}
                            compdata={item}
                            rowdata={item}
                            newArray={receivedQuant?.map((data)=>{
                              if(data?.componentName == item?.componentName){
                                return data?.partsSno
                              }else{
                                return null
                              }
                            })}
                            close={setOpen}
                          />
                        )}
                      </td>
                      <td style={{ fontSize: "11px" }}><button
                          onClick={() => {setAddedQty(index);setViewSpecs(true);}}
                          className="action-btn"
                          style={{ color: "blue" }}
                        >
                          {added?.map((data)=>{
                            if(data?.componentName == item?.componentName){
                              if(!data?.partsSno){
                                return 0
                              }else{
                                return data?.partsSno.length
                              }
                            }else{
                              return null
                            }
                          })}
                        </button>
                        {addedQty == index && viewSpecs && (
                          <ReceivedQtySP
                            orderID={compdata?.orderID}
                            updatedData={fetchReport}
                            rowdata={item}
                            close={setViewSpecs}
                            newArray={added?.map((data)=>{
                              if(data?.componentName == item?.componentName){
                                return data?.partsSno
                              }else{
                                return null
                              }
                            })}
                          />
                        )}</td>
                      <td style={{ fontSize: "11px" }} className="rightcell">
                      {receivedQuant?.map((data)=>{
                            if(data?.componentName == item?.componentName){
                              if(data?.partsSno){
                                return (parseInt(item?.componentQuantity) * parseInt(compdata?.productionQuantity)) - parseInt(data?.partsSno.length)
                              }else{
                                return 0
                              }
                            }
                          })}
                      </td>
                    </tr>
                  );
                })}
              </table>

              {/* <section style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', borderRadius: '5px', margin: '5px 0', height: "35px" }}>
                        <div style={{ width: '200px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', fontSize: '14px', fontWeight: '500' }}>
                            Total Price :
                            <span style={{ width: '100px', background: '#ebebeb', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                ₹{totalPrice()}
                                ₹......
                            </span>
                        </div>
         </section> */}
            </div>
          </div>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}>
              {" "}
              <KeyboardBackspace />
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WoInProgressViewComp;
