import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { display, flexbox } from "@mui/system";
import ViewCustomerDetails from "../Components/ViewCustomerDetails";
import SpareDetails from "./SpareDetails";

const ReOpenJob = ({ updatedData, rowData, closeModal, setIsOpen}) => {
  const [formValue, setFormValue] = useState({
    reason: "",
    description: "",
  });

  const editDetails = {
    jobID: rowData.jobID,

    status: "Job Re-Opened",

    reason: formValue.reason,

    description: formValue.description,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    console.log(formValue);
    const resp = await trackPromise(
      API.post(
        `serviceAdvisor-module/api/v1/serviceJobs/updateStatus`,
        editDetails
      )
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      setIsOpen(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };



  const closeHandler = ()=>{
    setIsOpen(false)
    closeModal(false)
  }






  return (
    <div className="container1">

      <div className="modalContainer">
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Re-Opened Job Card</h3>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 8,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h7>Job Card ID : {rowData.jobID}</h7>
            <h7>Vehicle Reg. No. {rowData.vehicleRegNo}</h7>
            <h7>Invoice Date : {moment(rowData?.invoiceDate).format("DD-MMM-YYYY")}</h7>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h7>Job Date : {moment(rowData.jobDate).format("DD-MMM-YYYY")}</h7>
            <h7>
              Delivery Date : {moment(rowData?.serviceDetails?.deliveryDateAndTime).format("DD-MMM-YYYY")}
            </h7>
            <h7 style={{ color: "red" }}>{rowData.status}</h7>

          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "start",
            margin: 7,
            backgroundColor: "#e6e6ff",
            padding: 5,
            borderRadius: 5,
          }}
        >
          <h7>Closed Date {moment(rowData?.invoiceDate).format("DD-MMM-YYYY")}</h7>
          <h7>Closed Reason {rowData?.reason}</h7>
        </div>

        <div className="body">
          <label
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label
              style={{ fontSize: "13px", fontWeight: "500", width: "83px" }}
            >
              Re-Opened Job Card<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="reason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: "100%", marginTop: "-5px" }}
            >
              <option disabled selected hidden>
                Select Reason
              </option>
              <option value="wiringIssue">Wiring Issue Persists</option>
              <option value="BatteryIssue">
                Battery Issue Persists
              </option>
              <option value="engineIssue">
                Engine Sound Issue
              </option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.reason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Put On Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button id="submit-button" onClick={submitHandler}>
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReOpenJob;


