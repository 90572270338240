import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import NotificationSwitch from "./iam-Switch";
import IamSwitch from "./iam-Switch";
import {
    faSave,
    faTimesCircle,
  } from "@fortawesome/free-solid-svg-icons";
import ProceedModal from "./ProceedModal";

const Notifications = () => {

    const [openModal , isOpenModal] = useState(false)

    useEffect(() => {
    }, []);

    let NotificationList = [
        {
            title: "New User Registration",
            component: < NotificationSwitch />
        },
        {
            title: "Disable / Deactivation Request",
            component: < NotificationSwitch />
        },
        {
            title: "Plan Expiry Notification",
            component: < NotificationSwitch />
        },
        {
            title: "Plan Re-newel Notification",
            component: < NotificationSwitch />
        },
        {
            title: "Procurement Notification",
            component: < NotificationSwitch />
        },
        {
            title: "Production Notification",
            component: < NotificationSwitch />
        },
        {
            title: "Approval Notification",
            component: < NotificationSwitch />
        },
    ];

    let notificationList = [];
    NotificationList.forEach((val) => {
        notificationList.push(
            <tr>
                <td>{val.title}</td>
                <td></td>
                <td></td>
                <td className="list"><IamSwitch /></td>
            </tr>
        )
    })
    return (
        <>
            <div className="bussiness">
            {
                openModal ? <ProceedModal closeModal={isOpenModal}/> :""
            }
                <h4 className="heading">Notification Prefrence</h4>
            </div>
            <br />
            <div>
                <table className="notification-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{notificationList}</tbody>
                </table>
            </div>

            <div
          style={{
            display: "flex",
            marginLeft: "5px",
            flexDirection: "row",
            width: "100%",
            justifyContent:"end"
          }}
        >
          <div style={{ margin: "22px 0px 0px 0px" }} className="save-cancel">
           
            <div className=" cancel-button">
              <button
                className="btn btn-icon-label"
              >
                <div
                  style={{
                    fontSize: "14px",
                    color: "#0A18A3",
                    fontWeight: "500",
                  }}
                onClick={()=>isOpenModal(true)}
                >
                  <FontAwesomeIcon icon={faSave} />
                  Update
                </div>
              </button>
            </div>
           
            <div className="cust-reset  cancel-button">
              <button
                type="button"
                className="btn btn-icon-label"
              >
                <div
                  style={{
                    fontSize: "14px",
                    color: "#0A18A3",
                    fontWeight: "500",
                  }}
                  onClick={()=>isOpenModal(true)}
                >
                  <FontAwesomeIcon icon={faTimesCircle} /> Cancel
                </div>
              </button>
            </div>
          </div>
          
        </div>
        </>

    );
};

export default Notifications;