import { Close, KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import auth from "../../utils/auth";
import moment from "moment";
import TermsView from "../TermsView/TermsView";
import { successNotification } from "../../utils/notifications";

const GenerateDeliveryChallanInv = ({
  rowData,
  closeModal,
  type,
  Qty,
  Data,
  actionData,
  tableRefresh,
  close
}) => {
  console.log("rowData", rowData);
  console.log("Data", Data);
  console.log("actionData", actionData);

  console.log(auth.getUserInfo().tenantID);
  const [UserData, setUserData] = useState([]);

  const getCompanyDetails = async () => {
    const resp = await trackPromise(
      API.get(
        `admin-module/api/v1/users/getDataWithTenantID?tenantID=${
          auth.getUserInfo().tenantID
        }`
      )
    );
    console.log("resp-->", resp);
    setUserData(resp);
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  let componentRef = null;

  const submitChallan = async () => {
    const obj = {
      requestID: rowData.requestID,
      requestCategory: rowData.requestCategory,
      orderID: rowData.orderID,
      vendorID: rowData.vendorID,
      vendorName: rowData.vendorName,
      vendorContact: rowData.vendorContact,
      vendorLocation: rowData.vendorLocation,
      components: rowData.components,
      RPI: rowData.RPI,
      RPIDate: rowData.RPIDate,
      RPICreatedBy: rowData.RPICreatedBy,
    };
    const resp = await trackPromise(
      API.post(`inventory-module/api/v1/spareOutward/prepareForShipment`, obj)
    );
    resp.success
      ? successNotification(resp.message)
      : errorNotification(resp.message);
      close(false);
      closeModal(false)
    tableRefresh();
  };

  // rand number
  function generateRandomCode() {
    const randomNumber = Math.floor(Math.random() * 90000) + 10000;
    return `DC-${randomNumber}`;
  }

  // for total
  const total = rowData.components.reduce((acc, component) => {
    return acc + rowData.components.length * component.returnPartsArr.length;
  }, 0);







  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          width: "70%",
          height: "600px",
          padding: "15px",
          marginTop: "95px",
          marginBottom: "100px",
          borderRadius: "0px",
          borderTop: "none",
        }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Delivery Challan</h3>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <button
                    style={{
                      color: "#0A18A3",
                      margin: "1px",
                      height: "27px",
                      width: "90px",
                      border: "none",
                    }}
                  >
                    Download
                  </button>
                );
              }}
              content={() => componentRef}
              documentTitle="Delivery Challan"
              pageStyle="Print"
            />
          </div>
        </div>

        <div style={{ overflow: "scroll" }}>
          <div
            // className="body"
            style={{
              width: " 99%",
              height: "97%",
              display: "flex",
              margin: "5px",
              flexDirection: "column",
              justifyContent: "space-between",
              // pageBreakBefore: "always",
            }}
            ref={(el) => (componentRef = el)}
          >
            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Tata_logo.svg/1200px-Tata_logo.svg.png"
                      style={{
                        width: "60px",
                        height: "60px",
                        marginBottom: "30px",
                      }}
                      alt="Car"
                    />
                  </span>
                  <span>{UserData?.businessName}</span>
                  <span>
                    Phone : {UserData?.mobileNo || rowData?.contactPersonNumber}
                  </span>
                  <span>
                    {UserData?.locations?.map(
                      (item) =>
                        item.locationType === "Business" && (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <span style={{ fontWeight: "100" }}>
                              Phone: {item?.businessContactNo}
                            </span>
                            <span style={{ fontWeight: "100" }}>
                              {item?.address1 + " , " + item?.address2}
                            </span>
                            <span style={{ fontWeight: "100" }}>
                              {item?.city +
                                " , " +
                                item?.state +
                                " , " +
                                item?.country}
                            </span>
                          </span>
                        )
                    )}
                  </span>
                  <span style={{ fontWeight: "100" }}>
                    PAN : {UserData?.pan}
                  </span>
                  <span style={{ fontWeight: "100" }}>
                    GSTIN : {UserData?.gstin}
                  </span>
                  {/* <span>GSTIN : {UserData?.gstin || rowData?.showroomGstin}</span> */}
                  {/* <span>
                    Website :
                    <span
                      style={{ color: "#0A18A3", textDecoration: "underline" }}
                    >
                      {"www.tatamotors.com"}
                    </span>
                  </span> */}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: "45px",
                  }}
                >
                  <span
                    style={{
                      marginTop: "40px",
                      fontSize: "25px",
                      marginBottom: "12px",
                    }}
                  >
                    Delivery Challan
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: "85px",
                  }}
                >
                  <span>Challan No. : {generateRandomCode()}</span>
                  <span>
                    Challan Date :
                    {moment(rowData?.challanDate).format("DD-MM-YYYY,HH:mm")}
                  </span>
                  {/* <span>Challan Amount :{rowData?.poValue}</span> */}
                </div>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#0A18A3",
                  height: "25px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      paddingLeft: "3px",
                      fontSize: "15px",
                    }}
                  >
                    Delivery Challan for :
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "15px",
                      alignItems: "end",
                    }}
                  >
                    Ship To :
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span style={{ fontWeight: "400" }}>
                    {UserData?.businessName}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    {UserData?.locations?.map(
                      (item) =>
                        item.locationType === "Business" && (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <span style={{ fontWeight: "100" }}>
                              Phone: {item?.businessContactNo}
                            </span>
                            <span style={{ fontWeight: "100" }}>
                              {item?.address1 + " , " + item?.address2}
                            </span>
                            <span style={{ fontWeight: "100" }}>
                              {item?.city +
                                " , " +
                                item?.state +
                                " , " +
                                item?.country}
                            </span>
                          </span>
                        )
                    )}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    PAN: {UserData?.pan}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    {`GSTIN :${UserData?.gstin}`}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    // marginRight: "100px",
                  }}
                >
                  <span style={{ fontWeight: "400" }}>
                    <span>Vendor Name: {rowData.vendorName}</span>
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    <span>Vendor Phone: {}</span>
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    <span>Vendor Location : {rowData.deliveryLocation}</span>
                  </span>
                  <span style={{ fontWeight: "400" }}>{`GSTIN :${rowData?.components[0]?.gstin}`}</span>
                </div>
              </div>
            </section>

            <section style={{ width: "100%", flexGrow: 1 }}>
              {/* <span style={{ display: "flex", alignItems: "start" }}>
                Delivery Challan Items :{" "}
              </span> */}
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#0A18A3",
                  height: "25px",
                }}
              >
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                      width: "100px",
                      marginLeft: "5px",
                      padding: "2px",
                    }}
                  >
                    S.No
                  </span>
                </div>

                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                      width: "400px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    Item Description
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                      width: "150px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    Qty.
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                      width: "150px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    HSN/SAC
                  </span>
                </div>
              </div>

              <div>
                {rowData?.components?.map((item, index) => (
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          width: "100px",
                          marginLeft: "5px",
                          padding: "2px",
                        }}
                      >
                        {index + 1}
                      </span>
                    </div>

                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          width: "400px",
                          marginLeft: "2px",
                          marginRight: "2px",
                          padding: "2px",
                        }}
                      >
                        {item.skuCode} {item.componentName} {item.category}{" "}
                        {item.subCategory}
                      </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          width: "150px",
                          marginLeft: "2px",
                          marginRight: "2px",
                          padding: "2px",
                        }}
                      >
                        {rowData.components.length}
                      </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span
                        style={{
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          width: "150px",
                          marginLeft: "2px",
                          marginRight: "2px",
                          padding: "2px",
                        }}
                      >
                        {item?.hsnSacCode}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  backgroundColor: "#0A18A3",
                  height: "25px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      paddingLeft: "3px",
                      marginRight: "6px",
                      fontSize: "15px",
                    }}
                  >
                    Total {total}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    marginRight: "20px",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      marginRight: "190px",
                      fontSize: "15px",
                    }}
                  >
                    {}
                  </span>
                </div>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  Terms & Conditions :-
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    textAlign: "left",
                    display: "flex",
                    flex: "wrap",
                  }}
                >
                  <TermsView data="Delivery Challan Terms" />
                </div>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    fontWeight: "500",
                    textAlign: "left",
                    textDecoration: "underline",
                  }}
                >
                  Received By :-
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span>Name :</span>
                  <span>Date :</span>
                  <span>Sigature :</span>
                </div>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                  marginTop: "23px",
                }}
              >
                <div
                  style={{
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  Authorized Signatory
                </div>

                <div
                  style={{
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  {UserData?.businessName}
                </div>
              </div>
            </section>
          </div>
        </div>

        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "12px",
            fontWeight: "500",
            margin: "10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <div className="footer">
              <button
                style={{
                  width: "104px",
                  background: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => close(false)}
              >
                <Close style={{ fontSize: "25px" }} />
                Close
              </button>

              <button
                type="submit"
                id="apply"
                style={{ width: "fit-content" }}
                onClick={() => submitChallan()}
              >
                Generate Challan
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default GenerateDeliveryChallanInv;
