import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification,errorNotification } from "../../../utils/notifications";

const ReactivateBom = ({closeModal,updatedData,editData , setActionShow}) => {
    const [formValue, setFormValue] = useState({
        reactivationReason: "",
        description: ""
    });

    const editDetails = {
      "BOMID":editData.BOMID,
      "reason":formValue.reactivationReason,
      "reasonComment": formValue.description
     };
  
     const refresh = () => {
      updatedData()
  };
  
    const submitHandler = async () => {
      if(formValue?.reactivationReason){
        const resp = await trackPromise(API.post(`production-module/api/v1/bom/reactivate`, editDetails));
      
        const { success,message} = resp;
        
        if (success) {
            closeModal(false);
            successNotification(message, { autoClose: 3000 });
            refresh()
        } else {
            errorNotification(message, { autoclose: 2000 });
        }
      }
      else{
        errorNotification("Please select reason", { autoclose: 2000 }); 
      }
    };


    const closeHandler = ()=>{
      setActionShow(false)
      closeModal(false)
    }




    return (
        <div className="container1">
            <div className='modalContainer'>
            <div className="header">
                <h3>Reactivate BOM</h3>
            </div>

            <div className="body">
            <label style={{display:'flex', flexDirection:'column', width:'50%'}}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "126px" }}>Reactivation Reason<span style={{color:'red'}}>*</span></label>
            <select
              name="reactivationReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{marginTop:"-7px"}}
            >
              <option disabled selected hidden>
                Reactivation Reason
              </option>
              <option value="Activity Resumed">
              Activity Resumed
              </option>
              <option value="Technical Issues resolved">
              Technical Issues resolved
              </option>
              <option value="Returned from leaves">
              Returned from leaves
              </option>
              <option value="Others">Others</option>
            </select>
          </label>

          {formValue.reactivationReason === "Others" ? 
          <label className="lgInput">
            Description
            <input
              type="text"
              style={{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Reactivation Reason"
              autoComplete="off"
            />
          </label> : ""}
        </div>
            <div className="footer">
                <button onClick={closeHandler}>Cancel</button>
                <button id='submit-button' onClick={submitHandler}>Submit</button>
            </div>
        </div>
        </div>
        
    );
};

export default ReactivateBom;
