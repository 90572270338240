import React, { useState, useEffect } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";

const CompletedDetails = ({ closeModal, rowData }) => {
  const [openQuant, setOpenQuant] = useState(false);
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState();
  const [actionShow, setActionShow] = useState();
  const [data, setData] = useState([]);

  const fetchBom = async () => {
    const resp = await trackPromise(
      API.get(
        `qc-module/api/v1/vehicle/finishedgoods/action?requestID=${rowData.requestID}`
      )
    );
    console.log(resp);
    const dataArray = resp.map((item, i) =>
      item.data.map((obj, i) => {
        return obj;
      })
    );
    const manageData = [];
    dataArray.map((item, i) => {
      return item.map((object, i) => manageData.push(object));
    });
    setData(manageData);
  };

  console.log(data);

  useEffect(() => {
    fetchBom();
  }, []);

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = () => {
    setOpen(true);
    on();
  };

  const handleStatusChange = (e, id) => {};

  return (
    <div className="container1">
      <div id="overlay" onClick={(e) => off(e)}></div>
      <div
        className="modalContainer"
        style={{ width: "900px", height: "450px", padding: "15px" }}
      >
        <div className="header">
          <h3>Requested Details</h3>
        </div>

        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
            margin: "10px 0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Request ID : {rowData.requestID}</span>
            <span>Request Category : {rowData.requestCategory}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Request By : {rowData.requestedBy}</span>
            <span>Request Type : {rowData.requestType}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              Request Date : {moment(rowData.requestDate).format("DD-MMM-YYYY/HH:mm")}
            </span>
          </div>
        </section>

        <div
          className="body"
          style={{
            display: "flex",
            height: "300px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div id="POVendorHeading" style={{ width: "130%" }}>
              <div style={{ width: "10%" }}>S. No.</div>
              <div style={{ width: "13%" }}>Vehicle Model</div>
              <div style={{ width: "15%" }}>Vehicle Variant</div>
              <div style={{ width: "13%" }}>Vehicle Type</div>
              <div style={{ width: "10%" }}>Color</div>
              <div style={{ width: "10%" }}>VIN</div>
              <div style={{ width: "10%" }}>BIN</div>
              <div style={{ width: "35%" }}>Status</div>
              <div style={{ width: "18%" }}>Verified On</div>
              <div style={{ width: "20%" }}>Failure Reason</div>
            </div>
            {data.map((item, index) => {
              return (
                <div>
                  <div
                    id="POVendorContent"
                    style={{
                      borderBottom: "1px solid #999999",
                      boxShadow: "none",
                      width: "130%",
                      padding: "0px",
                    }}
                    key={1}
                  >
                    <div style={{ width: "10%" }}>{++index}</div>
                    <div style={{ width: "13%" }}>{item.vehicleModel}</div>
                    <div style={{ width: "13%" }}>{item.vehicleVariant}</div>
                    <div style={{ width: "13%" }}>{item.vehicleType}</div>
                    <div style={{ width: "10%" }}>{item.vehicleColor}</div>
                    <div style={{ width: "10%" }}>{item.VIN}</div>
                    <div style={{ width: "10%" }}>{item.BIN}</div>
                    <div style={{ width: "35%" }}>
                      {item.status == "Failed" ? (
                        <button
                          style={{ width: "200px" }}
                          className="delete-btn"
                        >
                          <span className="delete-btn-font">{item.status}</span>
                        </button>
                      ) : item.status == "Pending Verification" ? (
                        <button
                          style={{ width: "200px" }}
                          className="deactive-btn"
                        >
                          <span className="deactive-btn-font">
                            {item.status}
                          </span>
                        </button>
                      ) : (
                        <button
                          style={{ width: "200px" }}
                          className="active-btn"
                        >
                          <span className="active-btn-font">{item.status}</span>
                        </button>
                      )}
                    </div>
                    <div style={{ width: "18%" }}>
                      {moment(item.actiondate).format("DD-MMM-YYYY/HH:mm")}
                    </div>
                    <div style={{ width: "20%" }}>
                      {item.status == "Failed" ? item.reason : "-"}
                    </div>
                  </div>
                </div>
              );
            })}
          </section>
        </div>

        <div className="footer">
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 15px",
              marginRight: "10px",
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "17px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompletedDetails;
