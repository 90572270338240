import React, { useState } from "react";
import { CancelOutlined, CheckCircle } from "@material-ui/icons";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import SalesInvoice from "../ShowroomVehicleSales/SalesInvoice";

const ConfirmationPopup = ({
  enquiryData,
  closeModal,
  vehicleData,
  formData,
  updatedData,
  tabName,
  rowData,
  receivedBy,
  receivedDate,
  poNumber,
  poData,
  clearFields
}) => {
  const refresh = async () => {
    const resp = await trackPromise(
      API.get(
        `showroom-module/api/v1/showroomOrders/getAllVehicleDataByPo?poNo=${poNumber}`
      )
    );
    poData(resp);
  };

  const refreshBattery = async () => {
    const resp = await trackPromise(
      API.get(
        `showroom-module/api/v1/showroomOrders/getAllBatteryDataByPo?poNo=${poNumber}`
      )
    );
    poData(resp);
  };

  const refreshVehicleInv = async () => {
    const resp = await trackPromise(
      API.get(
        `showroom-module/api/v1/showroomOrders/getAllVehicleArrivedData?poNo=${poNumber}`
      )
    );
    poData(resp);
  };

  const refreshBatteryInv = async () => {
    const resp = await trackPromise(
      API.get(
        `showroom-module/api/v1/showroomOrders/getAllBatteryArrivedData?poNo=${poNumber}`
      )
    );
    poData(resp);
  };

  const submitHandler = async () => {
    let body = {
      poNo: rowData?.poNo,

      requestID: rowData?.requestID,

      vehicleSkuCode: rowData?.vehicleSkuCode,

      receivedBy: receivedBy,

      receivingDate: receivedDate,

      requisitionID: rowData?.requisitionID
    };
    const resp = await trackPromise(
      API.put(
        `showroom-module/api/v1/showroomOrders/markedVehicleAsArrived`,
        body
      )
    );
    closeModal(false);
    refresh();
    {
      resp.success
        ? successNotification(resp.message)
        : errorNotification(resp.message);
    }
  };

  const submitBatteryHandler = async () => {
    let body = {
      poNo: rowData?.poNo,

      requestID: rowData?.requestID,

      batterySkuCode: rowData?.batterySkuCode,

      receivedBy: receivedBy,

      receivingDate: receivedDate,
    };
    const resp = await trackPromise(
      API.put(
        `showroom-module/api/v1/showroomOrders/markedBatteryAsArrived`,
        body
      )
    );
    closeModal(false);
    refreshBattery();
    {
      resp.success
        ? successNotification(resp.message)
        : errorNotification(resp.message);
    }
  };

  const submitVehicleInvVerifyHandler = async () => {
    let body = {
      poNo: rowData.poNo,

      requestID: rowData.requestID,

      vehicleSkuCode: rowData.vehicleSkuCode,

      action: "Added to Inventory",

      issue: rowData.issue,

      issueDescription: rowData.description,
    };
    const resp = await trackPromise(
      API.put(`showroom-module/api/v1/showroomOrders/verifyVehicle`, body)
    );
    closeModal(false);
    refreshVehicleInv();
    if (resp.success == true) {
      successNotification(resp.message);
    } else {
      errorNotification(resp.message);
    }
  };

  let submitCustomerEnquiry = async () => {
    console.log(formData);
    // let extraData = vehicleData.filter((el) => {
    //   return (
    //     el.vehicleSegment == formData.vehicleSegment &&
    //     el.vehicleCategory == formData.vehicleCategory &&
    //     el.vehicleModel == formData.vehicleModel &&
    //     el.vehicleVariant == formData.vehicleVariant &&
    //     el.vehicleType == formData.vehicleType &&
    //     el.vehicleSkuCode == formData.vehicleSkuCode
    //   );
    // });
    // let finalData = {
    //   ...formData,
    //   // vehicleSkuCode: extraData[0].vehicleSkuCode,
    //   vehicleColor: extraData[0].vehicleColor,
    //   // exShowroomPrice: extraData[0].exShowroomPrice,
    // };
    const resp = await trackPromise(
      API.post(
        `/showroom-module/api/v1/customerAndSales/createEnquiry`,
        formData
      )
    );
    closeModal(false);
    if (resp.success == true) {
      clearFields()
      successNotification(resp.message);
    } else {
      errorNotification(resp.message);
    }
  };

  let submitCustomerBooking = async () => {
    const newFormData = new FormData();

    for (const key in formData) {
      newFormData.append(key, formData[key]);
    }

    const resp = await trackPromise(
      API.post(
        `/showroom-module/api/v1/customerAndSales/createBooking`,
        newFormData
      )
    );
    closeModal(false);
    if (resp.success == true) {
      successNotification(resp.message);
    } else {
      errorNotification(resp.message);
    }
  };


  const [salesInvoice, setSalesInvoice] = useState(false)

  let submitVehicleSales = async () => {
    const newFormData = new FormData();

    for (const key in formData) {
      newFormData.append(key, formData[key]);
    }

    const resp = await trackPromise(
      API.post(
        `/showroom-module/api/v1/customerAndSales/vehicleSale`,
        newFormData
      )
    );

    setSalesInvoice(true);

    // closeModal(false);
    // if (resp.success == true) {
    //   successNotification(resp.message);
    // } else {
    //   errorNotification(resp.message);
    // }
  };

  let deleteEnquiry = async () => {
    const resp = await trackPromise(
      API.delete(
        `/showroom-module/api/v1/customerAndSales//deleteEnquiry/${formData.enquiryID}`
      )
    );
    closeModal(false);
    if (resp.success == true) {
      successNotification(resp.message);
      updatedData();
    } else {
      errorNotification(resp.message);
    }
  };

  let deleteSale = async () => {
    const resp = await trackPromise(
      API.put(
        `/showroom-module/api/v1/customerAndSales/moveToHistory/`, {
        saleID: formData.saleID
      }
      )
    );
    closeModal(false);
    if (resp.success == true) {
      successNotification(resp.message);
      updatedData();
    } else {
      errorNotification(resp.message);
    }
  };

  const submitBatteryInvVerifyHandler = async () => {
    let body = {
      poNo: rowData.poNo,

      requestID: rowData.requestID,

      batterySkuCode: rowData.batterySkuCode,

      action: "Added to Inventory",

      issue: rowData.issue,

      issueDescription: rowData.description,
    };
    const resp = await trackPromise(
      API.put(`showroom-module/api/v1/showroomOrders/verifyBattery`, body)
    );
    closeModal(false);
    refreshBatteryInv();
    if (resp.success == true) {
      successNotification(resp.message);
    } else {
      errorNotification(resp.message);
    }
  };

  return (
    <div className="container1">
      {
        salesInvoice ? <SalesInvoice rowData={rowData} closeSalesInvoice={setSalesInvoice} /> : " "
      }
      <div
        className="modalContainer"
        style={{ width: "400px", height: "200px" }}
      >
        {tabName == "vehicleSalesDelete" ? (
          <div
            className="body"
            style={{
              justifyContent: "center",
              fontSize: "16px",
              padding: "0px",
              marginTop: "40px",
            }}
          >
            Are you sure to delete this Vehicle Sale Data ?
          </div>
        ) : tabName == "customerEnquiryDelete" ? (
          <div
            className="body"
            style={{
              justifyContent: "center",
              fontSize: "16px",
              padding: "0px",
              marginTop: "40px",
            }}
          >
            Are you sure to delete this Customer Enquiry ?
          </div>
        ) : tabName == "customerEnquiry" ? (
          <div
            className="body"
            style={{
              justifyContent: "center",
              fontSize: "16px",
              padding: "0px",
              marginTop: "40px",
            }}
          >
            Are you sure to submit this Customer Enquiry ?
          </div>
        ) : tabName == "customerBooking" ? (
          <div
            className="body"
            style={{
              justifyContent: "center",
              fontSize: "16px",
              padding: "0px",
              marginTop: "40px",
            }}
          >
            Are you sure to submit this Customer Booking ?
          </div>
        ) : tabName == "vehicleSales" ? (
          <div
            className="body"
            style={{
              justifyContent: "center",
              fontSize: "16px",
              padding: "0px",
              marginTop: "40px",
            }}
          >
            Are you sure to submit this Vehicle Sale ?
          </div>
        ) : tabName == "showroomVehicleArrival" ? (
          <div
            className="body"
            style={{
              justifyContent: "center",
              fontSize: "16px",
              padding: "0px",
              marginTop: "40px",
            }}
          >
            Are you sure to Mark this vehicle as Arrived ?
          </div>
        ) : tabName == "showroomBatteryArrival" ? (
          <div
            className="body"
            style={{
              justifyContent: "center",
              fontSize: "16px",
              padding: "0px",
              marginTop: "40px",
            }}
          >
            Are you sure to Mark this Battery as Arrived ?
          </div>
        ) : tabName == "showroomVehicleInvVerification" ? (
          <div
            className="body"
            style={{
              justifyContent: "center",
              fontSize: "16px",
              padding: "0px",
              marginTop: "40px",
            }}
          >
            Are you sure to Add this Vehicle to Inventory ?
          </div>
        ) : tabName == "showroomBatteryInvVerification" ? (
          <div
            className="body"
            style={{
              justifyContent: "center",
              fontSize: "16px",
              padding: "0px",
              marginTop: "40px",
            }}
          >
            Are you sure to Add this Battery to Inventory ?
          </div>
        ) : (
          ""
        )}
        <div className="footer" style={{ justifyContent: "center" }}>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: "white",
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
            onClick={
              tabName == "vehicleSalesDelete"
                ? deleteSale
                : tabName == "customerEnquiryDelete"
                  ? deleteEnquiry
                  : tabName == "vehicleSales"
                    ? submitVehicleSales
                    : tabName == "customerBooking"
                      ? submitCustomerBooking
                      : tabName == "showroomVehicleArrival"
                        ? submitHandler
                        : tabName == "showroomBatteryArrival"
                          ? submitBatteryHandler
                          : tabName == "showroomVehicleInvVerification"
                            ? submitVehicleInvVerifyHandler
                            : tabName == "showroomBatteryInvVerification"
                              ? submitBatteryInvVerifyHandler
                              : tabName == "customerEnquiry"
                                ? submitCustomerEnquiry
                                : ""
            }
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
