import React from "react";
import { Tabs } from "react-simple-tabs-component";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
import { TabLabels } from "../../utils/app-constants";
// import ComponentVendorMapping from "./cv-mapping/ComponentVendorMapping";
// import LaborCosting from "./labor-costing/LaborCosting";
// import AssemblyCosting from "./assembly-costing/AssemblyCosting";

const ComponentVendorMapping = React.lazy(()=>import("./cv-mapping/ComponentVendorMapping"))
const BatteryVendorMapping = React.lazy(()=>import("./cv-mapping/BatteryVendorMapping"))
const LaborCosting = React.lazy(()=>import("./labor-costing/LaborCosting"))
const AssemblyCosting = React.lazy(()=>import("./assembly-costing/AssemblyCosting"))


const tabs = [
  {
    id: "PRM4T1",
    label: TabLabels.COMPONENT_VENDORS_MAPPING,
    Component: ComponentVendorMapping
  },
  {
    id: "PRM4T2",
    label: TabLabels.LABOR_COSTING,
    Component: LaborCosting
  },
  {
    id: "PRM4T3",
    label: TabLabels.ASSEMBLY_COSTING,
    Component: AssemblyCosting
  },
  {
    id: "PRM4T4",
    label: TabLabels.BATTERY_VENDORS_MAPPING,
    Component: BatteryVendorMapping
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};

const Mapping = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default Mapping;
