import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../../utils/api";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
  ConfirmationNumber,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// import { notifyError, notifySuccess } from "../../../../../utils/notify";
import {
  faEdit,
  faBan,
  faShare,
  faPauseCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../components/modal/modal.scss";

const SparesInventoryHistory = ({ rowData, closeModal, extraData }) => {
  const [actionShow, setActionShow] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState();
  
  let data = [
    {
      id: "1",
      skuCode: "234234",
      sparePartName: "4-Wheeler",
      category: "Standard",
      subCategory: "Tata Altroz",
      vehicleVariant: "XZ+1 Turbo",
      vehicleType: "HatchBook",
      color: "Black",
      requestedQty: "10",
      producedQty: "5",
      pendingQty: "5",
      dealerPrice: "2500",
      status: "New Request",
      vendorContact: "+91-9977441187",
      vendorLocation: "Gautam Budh Nagar Revised",
      vendorName: "Vendor Business 4",
      componentID: "96F137EBE0",
      componentName: "Front Head Lamp",
      tenantID: "dc870fcd-cc51-4632-8d90-98c524c6118a",
      skuCode: "SKU-9879943",
      vendorPartName: "Johnsons Suspensions Part 1 Revised",
      vendorPartCode: "BSX-1001",
      category: "Front Part",
      subCategory: "HeadLight",
      assignedQty:[
        {
          partSNo:"57657657",
          poNo:"67866"
        }
      ],
      specifications: [
        {
          specsType: "Length",
          specsValue: "81",
          specsUnit: "cm",
        },
        {
          specsType: "Weight",
          specsValue: "100",
          specsUnit: "kg",
        },
        {
          specsType: "Height",
          specsValue: "90",
          specsUnit: "cm",
        },
      ],
      componentQuantity: 4,
      vendorID: "091-8D965",
      verifiedBy: "Anil",
      id: "164863212147448",
      createdDate: "1648632121475",
      action: [
        {
          id: 1,
          action: "Create",
          createdDate: "1648632121475",
        },
      ],
      receivedQuantity: 300,
      requiredQuantity: 500,
      availableQuantity: 1200,
      dealerPrice: 600,
      partMRP: 20000,
      poDate: "00-00-0000",
      invoiceNo: "34534545",
      addedQty: "34",
      invoiceDate: "00-00-0000",
    },
    {
      id: "2",
      skuCode: "2342345654",
      sparePartName: "4-Wheeler",
      category: "Standard",
      subCategory: "Tata Altroz",
      vehicleVariant: "XZ+1 Turbo",
      vehicleType: "HatchBook",
      color: "Black",
      requestedQty: "10",
      producedQty: "5",
      pendingQty: "5",
      dealerPrice: "2500",
      status: "New Request",
      vendorContact: "+91-9977441187",
      vendorLocation: "Gautam Budh Nagar Revised",
      vendorName: "Vendor Business 4",
      componentID: "96F137EBE0",
      componentName: "Front Head Lamp",
      tenantID: "dc870fcd-cc51-4632-8d90-98c524c6118a",
      skuCode: "SKU-9879943",
      vendorPartName: "Johnsons Suspensions Part 1 Revised",
      vendorPartCode: "BSX-1001",
      category: "Front Part",
      subCategory: "HeadLight",
      assignedQty:[
        {
          partSNo:"0000000",
          poNo:"000000"
        },
        {
          partSNo:"50000",
          poNo:"67800000"
        }
      ],
      specifications: [
        {
          specsType: "Length",
          specsValue: "81",
          specsUnit: "cm",
        },
        {
          specsType: "Weight",
          specsValue: "100",
          specsUnit: "kg",
        },
        {
          specsType: "Height",
          specsValue: "90",
          specsUnit: "cm",
        },
      ],
      componentQuantity: 4,
      vendorID: "091-8D965",
      verifiedBy: "Anil",
      id: "164863212147448",
      createdDate: "1648632121475",
      action: [
        {
          id: 1,
          action: "Create",
          createdDate: "1648632121475",
        },
      ],
      receivedQuantity: 300,
      requiredQuantity: 500,
      availableQuantity: 1200,
      dealerPrice: 600,
      partMRP: 20000,
      poDate: "00-00-0000",
      invoiceNo: "34534545",
      addedQty: "34",
      invoiceDate: "00-00-0000",
    },
  ];

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Part S.No.",
      dataField: "partSNo",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "PO No.",
      dataField: "poNO",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD"},
      headerAttrs: { width: "150px" },
    },
  ];

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "500px", height: "350px", padding: "15px" }}
      >
        <div className="header" style={{ marginTop: "1px" }}>
          <h3>Assigned Qty.</h3>
        </div>
        <br />
        <div
          className="body"
          style={{
            display: "flex",
            height: "450px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Assigned Part S.No.: {}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Spare Part Name: {}</span>
            </div>
            <br />
          </section>
          <br />
          <section style={{ width: "100%" }}>
            <div style={{ overflow: "scroll" }}>
              <div>
                <ToolkitProvider
                  keyField="id"
                  data={data} // change this to rowData for final
                  columns={columns}
                  exportCSV
                >
                  {(props) => (
                    <div className="bootstraptbl">
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        rowClasses="text-nowrap"
                        rowStyle={{ border: "1px solid #CDCDCD" }}
                        keyField="id"
                        data={data} // change this to rowData for final
                        columns={columns}
                        {...props.baseProps}
                        condensed
                        filterPosition="top"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </section>
        </div>
        <div className="footer">
          <div>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "25px" }} />
              &nbsp; Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SparesInventoryHistory;
