import React from 'react';
import { Tabs } from 'react-simple-tabs-component';
import { TabLabels } from '../../utils/app-constants';
import "../settings-tabs/tabs.scss";
// import VehicleOrders from './components/VehicleOrders';
// import SpareOrders from './components/SpareOrders';

const VehicleOrders = React.lazy(()=>import('./components/VehicleOrders'))
const SpareOrders = React.lazy(()=>import('./components/SpareOrders'))


const RequisitionOrders = () =>{

    const tabs = [
        {
        id:"DEM3T1",
        label:TabLabels.VEHICLE_ORDERS,
        Component:VehicleOrders
        },
        {
        id:"DEM3T2",
        label:TabLabels.SPARE_ORDERS,
        Component:SpareOrders
        }
    ]

    return(
        <div className='app-layout__card'>
            <Tabs type="pills" tabs={tabs} />
        </div>
    )
}

export default RequisitionOrders