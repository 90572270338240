import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { KeyboardBackspace } from "@material-ui/icons";
import "../../work-order/workorder.scss";
import ViewSpecs from "../../wo-shopfloor/wo-sf-actions/wo-sf-viewspecs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faEdit,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import "../BomCosting.scss";
import auth from "../../../utils/auth";

const ViewDetailsAssembly = ({ close, rowdata }) => {
  // const [UsersData, setUsersData] = useState([]);

  // const fetchReport = async () => {
  //     let body = {
  //         "orderID": rowdata?.orderID,
  //         "status": rowdata?.status,
  //         "requiredItemsType": "components"
  //     }
  //     const resp = await trackPromise(API.post(`shop-floor-module/api/v1/shopFloor/getRequirementItemType`, body));
  //     console.log(resp);
  //     setUsersData(resp);
  // };
  // useEffect(() => {
  //     fetchReport();
  // }, []);

  // let assemblyData = {
  //     "assemblyType": "Main Assembly",
  //     "assemblyName": "service-1",
  //     "assemblyServices": [{
  //         "id": "1",
  //         "serviceName": "Converyer Belt",
  //         "reqTime": "2",
  //         "duration": "hrs",
  //         "cost": "1500"
  //     }, {
  //         "id": "2",
  //         "serviceName": "Crane",
  //         "reqTime": "4",
  //         "duration": "day",
  //         "cost": "2000"
  //     }]
  // };

  // const [blankForm, setBlankForm] = useState(assemblyData.assemblyServices);

  const convertToDays = (requiredDuration, laborDuration) => {
    const durationInDays = {
      day: 1,
      week: 7,
      month: 30,
      days:1,
      weeks:7,
      months:30
    };
    return requiredDuration * (durationInDays[laborDuration.toLowerCase()] || 1);
  };


  const countPrice = (assemblies) => {
    let totalCost = 0;
  
    assemblies.forEach((assembly) => {
      assembly.assemblyServices.forEach((service) => {
        const days = convertToDays(parseInt(service.timeDuration), service.unit);
        totalCost += days * parseInt(service.serviceCost);
      });
    });
  
    return totalCost;
  };
  




  const sum = (item) => {
    
    return item.assemblyServices.map((list) => {
      return (
        <>
          <ul>
            <li style={{ listStyle: "none" }}>{calculateDailyCost(list.serviceCost,list?.time,list?.timeDuration)}</li>
          </ul>
        </>
      );
    })
  }

 


  const calculateDailyCost = (serviceCost, time, timeDuration) => {
    console.log("check ther data",serviceCost, time, timeDuration,"-------------")
    switch (time) {
      case "Week":
        return serviceCost * 7 * Number(timeDuration)   // Convert weekly cost to daily cost
      case "Month":
        return serviceCost * 30 *  Number(timeDuration)  // Convert monthly cost to daily cost
      case "Year":
        return serviceCost * 365 * Number(timeDuration) ; // Convert yearly cost to daily cost
      default:
        return serviceCost * Number(timeDuration)  // If "Day", return the cost as is
    }
  };
  

  const totalPrice = (elem) => {
    console.log("testing console",elem)
    let total = 0;
    elem.map((item)=>{
      item?.assemblyServices?.map((list) => {
        total+= calculateDailyCost(Number(list?.serviceCost), list?.time, list?.timeDuration )
    })
    })
    
    return total
  }

  const calculateTotalPrice = ()=>{
    let total = 0
    assemblydata?.map((item)=>{
      total+= calculateDailyCost((Number(item?.serviceCost), item?.time, item?.timeDuration) )
      
    })
    return total
  }







  

console.log(rowdata?.bomAssembly)

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{
            width: "1280px",
            height: "400px",
            padding: "15px",
            fontSize: "small",
          }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Required Assembly</h3>
            {/* <div>
                            <button
                                className="action-btn"
                                style={{ color: "blue" }}>
                                Print
                            </button>
                            <button
                                className="action-btn"
                                style={{ color: "blue" }}>
                                Export
                            </button>
                        </div> */}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                Total Services:{" "}
                {rowdata?.bomAssembly?.reduce(
                  (acc, item) => acc + item?.assemblyServices.length,
                  0
                )}
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Total Cost ({auth.getUserInfo().currencySymbol}): {totalPrice(rowdata?.bomAssembly)}</span>
            </div>
          </div>
          <div className="body" style={{ height: "300px" }}>
            <div style={{ width: "100%", overflow: "auto", height: "210px" }}>
              <table className="viewcomp_tbl">
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>Assembly Type</th>
                    <th style={{ width: "15%" }}>Assembly Name</th>
                    <th style={{ width: "20%" }}>Required Services</th>
                    <th style={{ width: "20%" }}>Required Time</th>
                    <th style={{ width: "10%" }}>
                      Cost ({auth.getUserInfo().currencySymbol})
                    </th>
                    <th style={{ width: "15%" }}>
                      Sub Total ({auth.getUserInfo().currencySymbol})
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rowdata?.bomAssembly?.map((item) => {
                    return (
                      <tr>
                        <td
                          className="leftcell"
                          style={{ width: "20%", height: "auto" }}
                        >
                          {item?.assemblyType}
                        </td>
                        <td style={{ width: "15%", height: "auto" }}>
                          {item?.assemblyName}
                        </td>
                        <td style={{ width: "20%", height: "auto" }}>
                          {item?.assemblyServices?.map((item, index) => (
                            <ul style={{ padding: "0", margin: "0" }}>
                              <li>{item?.serviceName}</li>
                            </ul>
                          ))}
                        </td>
                        <td style={{ width: "20%", height: "auto" }}>
                          {item?.assemblyServices?.map((item, index) => (
                            <ul style={{ padding: "0", margin: "0" }}>
                              <li>{`${item?.timeDuration} ${item?.time}`}</li>
                            </ul>
                          ))}
                        </td>
                        <td style={{ width: "10%", height: "auto" }}>
                          {item?.assemblyServices?.map((item, index) => (
                            <ul style={{ padding: "0", margin: "0" }}>
                              <li>{`${item?.serviceCost} Per Day`}</li>
                            </ul>
                          ))}
                        </td>
                        <td
                          className="rightcell"
                          style={{ width: "6%", height: "auto" }}
                        >
                          {item?.assemblyServices?.map((list) => (
                            <ul style={{ padding: "0", margin: "0" }}>
                              <li>
                                {/* {parseInt(item?.serviceCost) *
                                  parseInt(item?.timeDuration)} */}
                                  {calculateDailyCost(list.serviceCost,list?.time,list?.timeDuration)}
                              </li>
                            </ul>
                          ))}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <br />

          <div className="footer">
            <button className="btn-footer" onClick={() => close(false)}>
              <KeyboardBackspace style={{ fontSize: "25px" }} /> Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDetailsAssembly;
