import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, registerables } from 'chart.js'
ChartJS.register(...registerables)
ChartJS.defaults.plugins.legend.display = false;
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import moment from 'moment';


function VehicleBarChart({ props, bomStartDate, bomEndDate }) {
  const [ordersData, setOrdersData] = useState({});
  const [bomData, setBomData] = useState({});
  const [qcData, setQcData] = useState({});
  const [bom, setbom] = useState({})
  const [po, setpo] = useState({})
  const [vehicleSaleReq, setVehicleSaleReq] = useState({})
  const [spareSaleReq, setSpareSaleReq] = useState({})

  const fetchOrdersData = async () => {
    const resp = await trackPromise(API.get(bomStartDate == null && bomEndDate == null ? `procurement-module/api/v1/dashboard/getAllReqsOrders` : `procurement-module/api/v1/dashboard/getAllReqsOrders?startDate=${moment(bomStartDate).format("DD-MMM-YYYY")}&endDate=${moment(bomEndDate).format("DD-MMM-YYYY")}`))
    setOrdersData(resp);
  };

  const fetchBomData = async () => {
    const result = await trackPromise(API.get(bomStartDate == null && bomEndDate == null ? `production-module/api/v1/dashboard/getAllBOMS` : `production-module/api/v1/dashboard/getAllBOMS?startDate=${moment(bomStartDate).format("DD-MMM-YYYY")}&endDate=${moment(bomEndDate).format("DD-MMM-YYYY")}`));
    setBomData(result);
  };

  const fetchQcData = async () => {
    const result = await trackPromise(API.get(bomStartDate == null && bomEndDate == null ? `qc-module/api/v1/vehicleVerification` : `qc-module/api/v1/vehicleVerification?startDate=${moment(bomStartDate).format("DD-MMM-YYYY")}&endDate=${moment(bomEndDate).format("DD-MMM-YYYY")}`));
    setQcData(result);
  };

  const fetchbomData = async () => {
    const result = await trackPromise(API.get(bomStartDate == null && bomEndDate == null ? `finance/api/v1/BOM` : `finance/api/v1/BOM?startDate=${moment(bomStartDate).format("DD-MMM-YYYY")}&endDate=${moment(bomEndDate).format("DD-MMM-YYYY")}`));
    setbom(result);
  };

  const fetchPoData = async () => {
    const result = await trackPromise(API.get(bomStartDate == null && bomEndDate == null ? `finance/api/v1/PO` : `finance/api/v1/PO?startDate=${moment(bomStartDate).format("DD-MMM-YYYY")}&endDate=${moment(bomEndDate).format("DD-MMM-YYYY")}`));
    setpo(result);
  };

  const fetchVehicleReqData = async () => {
    const result = await trackPromise(API.get(bomStartDate == null && bomEndDate == null ? `finance/api/v1/vehicleOrderSaleReqs` : `finance/api/v1/vehicleOrderSaleReqs?startDate=${moment(bomStartDate).format("DD-MMM-YYYY")}&endDate=${moment(bomEndDate).format("DD-MMM-YYYY")}`));
    setVehicleSaleReq(result);
  };

  const fetchSpareReqData = async () => {
    const result = await trackPromise(API.get(bomStartDate == null && bomEndDate == null ? `finance/api/v1/spareOrderSaleReqs` : `finance/api/v1/spareOrderSaleReqs?startDate=${moment(bomStartDate).format("DD-MMM-YYYY")}&endDate=${moment(bomEndDate).format("DD-MMM-YYYY")}`));
    setSpareSaleReq(result);
  };

  useEffect(() => {
    if (props == "procurement") {
      fetchOrdersData();
    }
    else if (props == "production") {
      fetchBomData();
    }
    else if (props == "qc") {
      fetchQcData();
    }
    else if (props == "bom") {
      fetchbomData()
    }
    else if (props == "po") {
      fetchPoData()
    }
    else if (props == "financeVehicleOrder") {
      fetchVehicleReqData()
    }
    else if (props == "financeSpareOrder") {
      fetchSpareReqData()
    }
  }, [bomEndDate]);

  return (
    <Bar
      height={props == "production" || props == "procurement" || props == "qc" || props == "bom" || props == "po" ? 195 : 230}
      data={{
        datasets: [{
          type: 'bar',
          backgroundColor: '#2D3DD9',
          barThickness: 25,
          data: props == "production" ? [bomData.totalBOMS, 0, bomData.pendingBOMS, bomData.approvedBOMS, bomData.reactivatedBOMS, bomData.deactivatedBOMS, bomData.deletedBOMS] : props == "procurement" ? [ordersData?.totalReqs, ordersData?.approvedReqs, ordersData?.pendingReqs, ordersData?.onHoldReqs, ordersData?.rejectedReqs, ordersData?.partiallyClosedReqs, ordersData?.fullyClosedReqs] : props == "qc" ? [qcData.completedVehicle, qcData.holdVehicle, qcData.inprogressVehicle, qcData.newVehicle, qcData.rejectedVehicle, qcData.totalVehicle] : props == "bom" ? [bom.totalBOM, bom.totalPending, bom.totalOnHold, bom.totalApproved, bom.totalRejected] : props == "po" ? [po.totalBOM, po.totalPending, po.totalOnHold, po.totalApproved, po.totalRejected] : props == "financeVehicleOrder" ? [vehicleSaleReq.totalReq, vehicleSaleReq.pendingReq, vehicleSaleReq.onHoldReq, vehicleSaleReq.inProgressReq, vehicleSaleReq.rejectedReq] : props == "financeSpareOrder" ? [spareSaleReq.totalReq, spareSaleReq.pendingReq, spareSaleReq.onHoldReq, spareSaleReq.inProgressReq, spareSaleReq.rejectedReq] : [256, 180, 240, 150, 100, 120],
          options: {
            responsive: true,
          }
        }
        ],
        labels: props == "production" ? ["Total BOM", "Saved as Draft", "Pending", "Approved", "Re-activated", "De-activated", "Deleted"] :
          props == "procurement" ? ["Total Orders", "Approved", "Pending", "Hold", "Rejected", "Partially Closed", "Fully Closed"] :
            props == "qc" ? ["Completed Vehicle", "Hold Vehicle", "In-Progress Vehicle", "New Vehicle", "Rejected Vehicle", "Total Vehicle"] : props == "bom" ? ["Total BOM", "Pending", "On Hold", "Approved", "Rejected"] : props == "po" ? ["Total Requests", "Pending", "On-Hold", "Approved", "Rejected"] : props == "financeSpareOrder" ? ["Total", "Pending", "On-Hold", "Approved", "Rejected"] : props == "financeVehicleOrder" ? ["Total", "Pending", "On-Hold", "Approved", "Rejected"] : ["Total", "New", "Hold", "In - Progress", "Completed", "Rejected"]
      }}
    />
  )
}

export default VehicleBarChart