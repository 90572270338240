import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import auth from "../../../utils/auth";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { successNotification, errorNotification } from "../../../utils/notifications";
import JobCardDetails from "./JobCardDetails";
import MarkInProgress from "./MarkInProgress";
import RequestRelease from "./RequestRelease";
import ReceivedQtyView from "./ReceivedQtyView";
import ViewReceivedQty from "./ViewReceivedQty";
import RequestSpare from "./RequestSpare";
import RequiredSpareAndUnit from "./RequiredSpareAndUnit";
import moment from "moment";


const ViewUpdateReOpen = ({ closeModal, rowData, updatedData, setIsOpen }) => {
  // const UsersData = row.requisitionDetails;

  console.log("rowData-->",rowData)

  const [openSpare, setOpenSpare] = useState();
  const [spareData, setSpareData] = useState();

  const [openProgress, setOpenProgress] = useState();
  const [data, setData] = useState();

  const [openSpareRequest, setOpenSpareRequest] = useState();
  const [SpareDataRequest, setSpareDataRequest] = useState();


  const [viewCharges, setViewCharges] = useState();
  const [dataCharge, setDataCharge] = useState();



  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(``)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      setIsOpen(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const jobCardDetailsFormatter = (rowData) => {
    setOpenSpare(true);
    setSpareData(rowData);
  };




  const requestSpare = (rowData) => {
    setOpenSpareRequest(true);
    setSpareDataRequest(rowData);
  };


  const requestProgress = (rowData) => {
    setOpenProgress(true);
    setData(rowData);
  };


  const handleInputChange = (event) => {
    setBlankForm({ ...blankForm, [event.target.name]: event.target.value });
  };



  
  const closeHandler = ()=>{
    setIsOpen(false)
    closeModal(false)
  }





  return (
    <div className="container1">
      {openSpare ? (
        <JobCardDetails rowData={spareData} closeModal={setOpenSpare} />
      ) : (
        ""
      )}


      {openProgress ? (
        <MarkInProgress rowData={data} closeModal={setOpenProgress} updatedData={updatedData} />
      ) : (
        ""
      )}

      {openSpareRequest ? (
        <RequestSpare rowData={SpareDataRequest} closeModal={setOpenSpareRequest} />
      ) : (
        ""
      )}

      {viewCharges && (
        <RequiredSpareAndUnit rowData={dataCharge} closeModal={setViewCharges} />
      )
      }

      <div
        className="modalContainer"
        style={{ width: "65%", height: "400px", padding: "15px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>View and Update</h3>
          <div>
            <button className="action-btn" style={{ color: "blue" }}>
              <PrintIcon />
              &nbsp;Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              <IosShareIcon />
              &nbsp;Export
            </button>
          </div>
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding: 7,
          }}
        >
          <span style={{ width: "50%", textAlign: "start", justifyContent: "start", }}> Vehicle Reg. No : {rowData.vehicleRegistrationNo}</span>
          <span style={{ width: "50%", textAlign: "end", justifyContent: "end" }}>Job Close Date : {moment(rowData.actionDate).format("DD-MMM-YYYY/HH:mm")}</span>
          <span style={{ width: "50%", textAlign: "start", justifyContent: "start", }}> Job Type : {rowData.jobType}</span>
          <span style={{ width: "50%", textAlign: "end", justifyContent: "end" }}>Job Close Reason : {rowData.reason}</span>
          <span style={{ width: "50%", textAlign: "start", justifyContent: "start", }}> Job Card ID : {rowData.jobID}</span>
          <span style={{ width: "50%", textAlign: "end", justifyContent: "end" }}>Re-Open Reason : { }</span>
          <span style={{ width: "50%", textAlign: "start", justifyContent: "start", }}> Job Card Date : {moment(rowData.jobDate).format("DD-MMM-YYYY/HH:mm")}</span>
          <span style={{ width: "50%", textAlign: "end", justifyContent: "end" }}>Re-Open  Date : {moment().format("DD-MMM-YYYY/HH:mm")}</span>
          <span style={{ width: "50%", textAlign: "start", justifyContent: "start", }}> Delivery Date : {moment().format("DD-MMM-YYYY/HH:mm")}</span>
          <span style={{ width: "50%", textAlign: "end", justifyContent: "end" }}>Category : { }</span>
          <span style={{ width: "50%", textAlign: "start", justifyContent: "start", }}>Invoice No. : {rowData.invoiceNo}</span>
          <span style={{ width: "50%", textAlign: "end", color: "blue" }}> {rowData.status}</span>
          <span
            onClick={() => jobCardDetailsFormatter(rowData)}
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
              color: "blue"
            }}
          >
            Job Details: { }
          </span>


        </section>
        <br />
        <div className="body">
          <label style={{ display: 'flex', flexDirection: 'column', width: '39%' }}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "83px" }}>Inspection Checks<span style={{ color: "red" }}>*</span></label>
            <select
              name="reason"
              style={{ width: '70%', marginTop: '5px' }}
            >
              <option disabled selected hidden>
                Select
              </option>
              <option value="one">one</option>
              <option value="two">two</option>
            </select>
          </label>
          <label
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                marginLeft: "-107px",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              Required Spare & Units<span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="input-config"
              id="chargesPerUser"
              type="text"
              backgroundColor="blue"
              placeholder="Select"
              autoComplete="off"
              onChange={handleInputChange}
              style={{ marginTop: "4px" }}
              onFocus={() => setViewCharges(true)}

            />
          </label>
        </div>

        <br />
        <div className="footer">
          <button onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={() => requestSpare(rowData)}
            style={{ backgroundColor: "#ffffff", color: "blue", width: "200px" }}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Request For Spare
          </button>
          <button
            id="submit-button"
            onClick={() => requestProgress(rowData)}
            style={{ width: "170px" }}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Mark In-Progress
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewUpdateReOpen;
