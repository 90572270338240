import React, { useEffect, useState } from "react";
import "./subscription.scss";
import { IoIosCheckmarkCircle } from "react-icons/io";
import PurchaseSummary from "./PurchaseSummary";
import ComparisonPlan from "./ComparisonPlan";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const SubscribePlann = () => {
  const [purchaseSummary, setPurchaseSummary] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [checkedPlans, setCheckedPlans] = useState([]);
  const [UsersData, setUsersData] = useState([]);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/subscription/plans`)
    );
    const filterData = resp.filter(
      (item) => item.status !== "Deleted" && item.status !== "Deactivated"
    );
    setUsersData(filterData);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (newState) => {
    setIsChecked(newState);
  };

  // const markHandleChange = (item) => {

  //   handleCheckboxChange();

  //   if (!selectedPlan) {
  //     setSelectedPlan([item]);
  //   } else {
  //     const isAlreadySelected = selectedPlan.some(
  //       (selectedItem) => selectedItem.planId === item.planId
  //     );

  //     if (isAlreadySelected) {
  //       const updatedSelectedPlan = selectedPlan.filter(
  //         (selectedItem) => selectedItem.planId !== item.planId
  //       );
  //       setSelectedPlan(
  //         updatedSelectedPlan.length ? updatedSelectedPlan : null
  //       );
  //     } else {
  //       setSelectedPlan([...selectedPlan, item]);
  //     }
  //   }
  // };

  const markHandleChange = (item) => {
    handleCheckboxChange();

    // Check if the selected item is already in the selectedPlan array
    const isAlreadySelected = selectedPlan.some(
      (selectedItem) => selectedItem.planId === item.planId
    );

    if (!isAlreadySelected) {
      // Check if the maximum number of checkboxes is already selected
      if (selectedPlan.length < 3) {
        // Add the selected item to the selectedPlan array
        setSelectedPlan([...selectedPlan, item]);
      } else {
        // If maximum checkboxes are already selected, alert the user
        console.log(
          "You can only select a maximum of three items for comparison."
        );
      }
    } else {
      // If the selected item is already in the selectedPlan array, remove it
      const updatedSelectedPlan = selectedPlan.filter(
        (selectedItem) => selectedItem.planId !== item.planId
      );
      setSelectedPlan(updatedSelectedPlan);
    }
  };

  console.log("selectedPlan-->", selectedPlan);

  const handleModalClose = (updatedSelectedPlan) => {
    setCheckedPlans(updatedSelectedPlan);
    setSelectedPlan([]);
  };

  const [selectedOption, setSelectedOption] = useState("Monthly");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  console.log("selectedOption-->", selectedOption);
  console.log("UsersData-->", UsersData);

  return (
    <>
      <div id="addUser" className="bussiness">
        <h4 className="heading">Subscription Plans</h4>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            type="radio"
            value="Monthly"
            checked={selectedOption === "Monthly"}
            onChange={handleOptionChange}
          />
          <span style={{ width: "90px", padding: "6px" }}>Monthly</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            type="radio"
            value="Yearly"
            checked={selectedOption === "Yearly"}
            onChange={handleOptionChange}
          />
          <span style={{ width: "90px", padding: "6px" }}>Yearly</span>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          height: "530px",
          overflowX: "scroll", // Always show vertical scrollbar
          scrollbarWidth: "thin", // For Firefox
          scrollbarColor: "#4a4a9a #e0e0e0", // For Firefox
        }}
      >
        {selectedOption === "Monthly"
          ? UsersData.map((item, index) => (
              <div key={index} className="subscription-Container">
                <div className="bg-container">
                  <div className="alR-center">
                    <div className="alC-center">
                      <span style={{ fontWeight: "500", fontSize: "large" }}>
                        {item.planName}
                      </span>
                    </div>

                    <label
                      htmlFor={item.planId}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        type="checkbox"
                        id={item.planId}
                        name={item.planId}
                        checked={selectedPlan?.some(
                          (ele) => ele.planId === item.planId
                        )}
                        onChange={() => markHandleChange(item)}
                        style={{
                          backgroundColor: "#00b33c",
                          margin: "3px",
                          accentColor: "#00b33c",
                        }}
                      />
                      Compare
                    </label>
                  </div>

                  <div className="wh-container">
                    <span style={{ fontWeight: "bold" }}>
                      ₹ {item.monthlyCharges}
                    </span>
                    <span>Excl. GST</span>
                  </div>

                  <div>
                    <div style={{ color: "black", marginTop: "15%" }}>
                      <span style={{ marginBottom: "15px" }}>
                        {item.planDescrition}
                      </span>

                      <ul>
                        {item?.features?.map((el) => (
                          <li style={{ display: "flex", alignItems: "center" }}>
                            {/* {el.checked && "- "} */}
                            {el.checked}
                            {el.checked && el.time == "Monthly" &&
                              el.name.replace(/([a-z])([A-Z])/g, "$1 $2")}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <button
                      className="buyButton"
                      style={{
                        position: "fixed",
                        bottom: "-280%",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                      onClick={() => setPurchaseSummary({ item })}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            ))
          : UsersData.map((item, index) => (
              <div key={index} className="subscription-Container">
                <div className="bg-container">
                  <div className="alR-center">
                    <div className="alC-center">
                      <span style={{ fontWeight: "500", fontSize: "large" }}>
                        {item.planName}
                      </span>
                    </div>

                    <label
                      htmlFor={item.planId}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        type="checkbox"
                        id={item.planId}
                        name={item.planId}
                        checked={selectedPlan?.some(
                          (ele) => ele.planId === item.planId
                        )}
                        onChange={() => markHandleChange(item)}
                        style={{
                          backgroundColor: "#00b33c",
                          margin: "3px",
                          accentColor: "#00b33c",
                        }}
                      />
                      Compare
                    </label>
                  </div>

                  <div className="wh-container">
                    <span style={{ fontWeight: "bold" }}>
                      ₹ {item.yearlyCharges}
                    </span>
                    <span>Excl. GST</span>
                  </div>

                  <div>
                    <div style={{ color: "black", marginTop: "15%" }}>
                      <span style={{ marginBottom: "15px" }}>
                        {item.planDescrition}
                      </span>

                      <ul>
                        {item?.features?.map((el) => (
                          <li style={{ display: "flex", alignItems: "center" }}>
                            {/* {el.checked && "- "} */}
                            {el.checked}
                            {el.checked && el.time == "Yearly" &&
                              el.name.replace(/([a-z])([A-Z])/g, "$1 $2")}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <button
                      className="buyButton"
                      style={{
                        position: "fixed",
                        bottom: "-280%",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                      onClick={() => setPurchaseSummary({ item })}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            ))}

        {purchaseSummary && (
          <PurchaseSummary
            Data={purchaseSummary}
            closeModal={setPurchaseSummary}
            selectedOption={selectedOption}
          />
        )}
      </div>
      {selectedPlan?.length ? (
        <ComparisonPlan
          selectedPlan={selectedPlan}
          checkedPlans={checkedPlans}
          isChecked={isChecked}
          markHandleChange={markHandleChange}
          handleCheckboxChange={handleCheckboxChange}
          closeModal={(updatedSelectedPlan) =>
            handleModalClose(updatedSelectedPlan)
          }
          setPurchaseSummary={setPurchaseSummary}
          selectedOption={selectedOption}
        />
      ) : null}
    </>
  );
};

export default SubscribePlann;
