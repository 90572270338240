import React from 'react'
import Vector from '../QcDashboard/assets/Vector.svg'
import Vector3 from '../QcDashboard/assets/Vector3.svg'
import { trackPromise } from "react-promise-tracker";
import API from '../../utils/api'
import { useEffect, useState } from 'react'

function InventoryDashboardComp() {

    const [data, setData] = useState({});

    const fetchOutwardData = async () => {
        const resp = await trackPromise(API.get(`inventory-module/api/v1/dashboard/getAllInventory`))
        setData(resp);
        console.log(resp)
    };
    useEffect(() => {
        fetchOutwardData();
    }, []);

    return (
        <div className='requestBody'>
            <div className='shopFloorRequest' style={{ width: "185px", height: "70px", padding: "10px" }}>
                <span style={{ fontSize: "16px", fontWeight: "500", color: "#3442CF" }}>Raw Inventory</span>
                <div style={{ fontSize: "26px", fontWeight: "500" }}>{data?.rawInventory}
                    {/* &nbsp;{data?.rawInventoryChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.rawInventoryChange}`}</span></> : data?.rawInventoryChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.rawInventoryChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data?.rawInventoryChange}`}</span></>} */}
                </div>
            </div>

            <div className='shopFloorRequest' style={{ width: "185px", height: "70px", padding: "10px" }}>
                <span style={{ fontSize: "16px", fontWeight: "500", color: "#FFD74A" }}>MRO Inventory</span>
                <div style={{ fontSize: "26px", fontWeight: "500" }}>{data?.mroInventory}
                    {/* &nbsp;{data?.mroInventoryChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.mroInventoryChange}`}</span></> : data?.mroInventoryChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.mroInventoryChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data?.mroInventoryChange}`}</span></>} */}
                </div>
            </div>

            <div className='shopFloorRequest' style={{ width: "185px", height: "70px", padding: "10px" }}>
                <span style={{ fontSize: "16px", fontWeight: "500", color: "#FDAA48" }}>WIP Inventory</span>
                <div style={{ fontSize: "26px", fontWeight: "500" }}>{data?.wipInventory}
                    {/* &nbsp;{data?.wipInventoryChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.wipInventoryChange}`}</span></> : data?.wipInventoryChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.wipInventoryChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data?.wipInventoryChange}`}</span></>} */}
                </div>
            </div>

            <div className='shopFloorRequest' style={{ width: "185px", height: "70px", padding: "10px" }}>
                <span style={{ fontSize: "16px", fontWeight: "500", color: "#21A82E" }}>Finished Goods</span>
                <div style={{ fontSize: "26px", fontWeight: "500" }}>{data?.finishedGoods}
                    {/* &nbsp;{data?.finishedGoodsChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.finishedGoodsChange}`}</span></> : data?.finishedGoodsChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.finishedGoodsChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data?.finishedGoodsChange}`}</span></>} */}
                </div>
            </div>

            <div className='shopFloorRequest' style={{ width: "185px", height: "70px", padding: "10px" }}>
                <span style={{ fontSize: "16px", fontWeight: "500", color: "#EF4D62" }}>Rejection Inventory</span>
                <div style={{ fontSize: "26px", fontWeight: "500" }}>{data?.rejectionInventory}
                    {/* &nbsp;{data?.rejectionInventoryChange > 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.rejectionInventoryChange}`}</span></> : data?.rejectionInventoryChange === 0 ? <><img src={Vector} alt="vector" /><span style={{ fontSize: "14px", color: "green" }}>{`+${data?.rejectionInventoryChange}`}</span></> : <><img src={Vector3} alt="vector" /><span style={{ fontSize: "14px", color: "red" }}>{`${data?.rejectionInventoryChange}`}</span></>} */}
                </div>
            </div>
        </div>
    )
}

export default InventoryDashboardComp