import React from "react";
import QueryTable from "./QueryTable";


const ManageQuery = ({ props }) => {
 
  return (
    <>
    <div style={{ position: "relative", zIndex: "1" }}>
    
      <div id="overlay" onClick={(e) => off(e)}></div>
      {/* <div className="heading bussiness" style={{marginBottom:"-5px"}}>
        <span>Manage Query</span>
      </div> */}


      <QueryTable/>
   

    </div>
  </>
  );
};

export default ManageQuery;
