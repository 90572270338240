import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
    KeyboardBackspace,
    CheckCircle,
    HighlightOffOutlined,
    ConfirmationNumber,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { errorNotification, successNotification } from "../../../utils/notifications";
import { faEdit, faBan, faShare, faPauseCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationBox from "../ConfirmationBox";
import SparePurchaseOrderSummary from "./SparePurchaseOrderSummary";
import POPreview from "../../POPreview/POPreview";
import ViewSpecs from "../../storeManagerOrders/RequisitionProgress/Action/ViewSpecs";
import auth from "../../../utils/auth";

const SpareGeneratePO = ({ rowData, closeModal, updatedData, OpenOne, actionShow }) => {
    const [openModal, setOpenModal] = useState(false)
    const [billingAddress, setBillingAddress] = useState([]);
    const [shippingAddress, setShippingAddress] = useState([]);
    const [selectedBillingAddress, setSelectedBillingAddress] = useState({});
    const [selectedShippingAddress, setSelectedShippingAddress] = useState({});


    
  console.log("selectedBillingAddress-->", selectedBillingAddress);
  console.log("selectedShippingAddress-->", selectedShippingAddress);



    const sumWithInitial = rowData?.requisitionDetails?.reduce(
        (accumulator, currentValue) => accumulator + parseInt(currentValue?.requiredQty),
        0
    );

    const fetchReport = async () => {
        const resp = await trackPromise(API.get(`dealer-module/api/v1/requisitionAndOrder/getServiceCenterAddress`));
        console.log(resp);
        setShippingAddress(resp);
        const resp2 = await trackPromise(API.get(`dealer-module/api/v1/requisitionAndOrder/getDealerAddress`));
        console.log(resp2);
        setBillingAddress(resp2);
    };
    useEffect(() => {
        fetchReport();
    }, []);

    const handleInputChange = (e) => {
        // console.log("-->", e.target);
        // console.log(e.target.value);

        if (e.target.name == "billingAddress") {
            const filtData = billingAddress.filter((item) => item.DealerAddress == e.target.value)
            setSelectedBillingAddress(filtData[0]);
        }
        if (e.target.name == "shippingAddress") {
            let shippingObject = shippingAddress.filter((item) => {
                return item?.serviceCenterID == e.target.value;
            });
            console.log(shippingObject[0]);
            setSelectedShippingAddress(shippingObject[0]);
        }
    }

    const [viewSpecs, setViewSpecs] = useState(false);
    const [specsData, setSpecsData] = useState({});

    const handleViewSpecs = (item, index) => {
        setViewSpecs(true);
        setSpecsData(item);
    };

    return (
        <div className="container1">
            {viewSpecs && <ViewSpecs specs={specsData} closeModal={setViewSpecs} />}
            <div
                className="modalContainer"
                style={{ width: "1000px", height: "500px", padding: '15px' }}
            >
                <div className="header">
                    <h3>Generate Purchase Order</h3>
                </div>
                <br />
                <div
                    className="body"
                    style={{ display: "flex", height: "450px", fontSize: "14px", overflow: 'scroll' }}
                >

                    <section style={{ width: '100%', }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requisition Number : {rowData.requisitionID}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Service Center Name : {rowData.serviceCenterName}</span>
                            <span>Total Spare Requested : {rowData.requisitionDetails.length} </span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Address : {rowData.serviceCenterLocation}</span>
                            <span>Total Units Requested : {sumWithInitial} </span>
                        </div>

                        <br />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: 'flex', flexDirection: "column", alignItems: "flex-start" }}>
                                <div>
                                    Select Billing Address
                                </div>
                                <select
                                    required={true}
                                    name="billingAddress"
                                    style={{ width: "100%" }}
                                    className="input-config"
                                    onChange={event => handleInputChange(event)}
                                >
                                    <option className="locationOption" value="" disabled selected>
                                        Select Billing Address
                                    </option>
                                    {billingAddress?.map((item) => (
                                        <option value={item.DealerAddress}>{item.DealerAddress}</option>
                                    ))}
                                </select>

                            </div>
                            <div style={{ display: 'flex', flexDirection: "column", alignItems: "flex-start" }}>
                                <div>
                                    Select Shipping Address
                                </div>
                                <select
                                    required={true}
                                    name="shippingAddress"
                                    style={{ width: "100%" }}
                                    className="input-config"
                                    onChange={event => handleInputChange(event)}
                                >
                                    <option className="locationOption" value="" disabled selected>
                                        Select Shipping Address
                                    </option>
                                    {shippingAddress.map((item) => (
                                        <option value={item.serviceCenterID}>{item.serviceCenterAddress}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <br />
                        <div style={{ overflow: "scroll" }}>
                            <div id='POVendorHeading'>
                                <div style={{ width: '5%' }}>S. No.</div>
                                <div style={{ width: '15%' }}>Spare Part Name</div>
                                <div style={{ width: '10%' }}>SKU Code</div>
                                <div style={{ width: '10%' }}>Category</div>
                                <div style={{ width: '15%' }}>Sub Category</div>
                                <div style={{ width: '10%' }}>Specifications</div>
                                <div style={{ width: '15%' }}>{`Unit Price (${auth.getUserInfo().currencySymbol})`}</div>
                                <div style={{ width: '11%' }}>Requested Qty.</div>
                                <div style={{ width: '10%' }}>{`Sub Total (${auth.getUserInfo().currencySymbol})`}</div>

                            </div>
                            <div>
                                {
                                    rowData.requisitionDetails.map((item, index) => {
                                        return (
                                            <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }} key={1}>
                                                <div style={{ width: '5%' }}>{index + 1}</div>
                                                <div style={{ width: '15%' }}>{item.sparePartname}</div>
                                                <div style={{ width: '10%', }}>{item.skuCode}</div>
                                                <div style={{ width: '10%' }}>{item.category}</div>
                                                <div style={{ width: '15%' }}>{item.subCategory}</div>
                                                <div style={{ width: '10%' }}>
                                                    <span
                                                        onClick={() => handleViewSpecs(item, index)}
                                                        style={{
                                                            color: "#0a18a3",
                                                            fontWeight: "500",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        View Specs
                                                    </span>
                                                </div>
                                                <div style={{ width: '15%' }}>
                                                {item?.priceToserviceCenter?.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                                                </div>
                                                <div style={{ width: '11%', }}>{item.requiredQty}</div>
                                                <div style={{ width: '10%' }}>
                                                {(parseInt(item?.requiredQty || 0) * parseInt(item?.priceToserviceCenter || 0)).toLocaleString('en-IN', { minimumFractionDigits: 2 })}
                                                   </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>

                    </section>

                </div>

                <div className="footer">
                    <div>
                        <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>

                        {/* <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => setOpenModal(true)}><CheckCircle style={{ fontSize: "25px" }} />Proceed</button> */}

                        <button
              style={{
                width: "104px",
                height: "42px",
                background: "#fff",
                opacity:
                  !selectedBillingAddress?.DealerAddress ||
                  !selectedShippingAddress?.serviceCenterID
                    ? 0.5
                    : 1,
                cursor:
                  !selectedBillingAddress?.DealerAddress ||
                  !selectedShippingAddress?.serviceCenterID
                    ? "not-allowed"
                    : "pointer",
              }}
              onClick={() => {
                if (
                  selectedBillingAddress?.DealerAddress &&
                  selectedShippingAddress?.serviceCenterID
                ) {
                    setOpenModal(true);
                }
              }}
              disabled={
                !selectedBillingAddress?.DealerAddress ||
                !selectedShippingAddress?.serviceCenterID
              }
            >
              <CheckCircle style={{ fontSize: "25px" }} />
              Proceed
            </button>

                    </div>
                </div>
            </div>

            {
                openModal && <POPreview type="requisitionDetails" actionShow={actionShow}
                    verify="SpareGeneratePO" closeModal={setOpenModal} rowData={rowData} selectedShippingAddress={selectedShippingAddress} selectedBillingAddress={selectedBillingAddress} updatedData={updatedData} OpenOne={OpenOne} data="Spares PO Terms"/>
            }
        </div>
    );
};

export default SpareGeneratePO