import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import ProgressBar from "react-bootstrap/ProgressBar";
import API from "../../utils/api";
import moment from "moment";

const Orders = ({ prop }) => {
  const [data, setData] = useState({});
  const [spareData, setSpareData] = useState({});

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(`salesUser-module/api/v1/dashboard/vehicleOrders`)
    );
    setData(res);
  };
  const fetchSpareData = async () => {
    const res = await trackPromise(
      API.get(`salesUser-module/api/v1/dashboard/spareOrders`)
    );
    setSpareData(res);
  };
  useEffect(() => {
    { prop == "vehicle" ? fetchData() : fetchSpareData() }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "95%",
        marginLeft: "10px",
        fontWeight: "430",
      }}
    >
      <ProgressBar>
        <ProgressBar style={{ backgroundColor: "#3B61CF" }} now={(prop == "vehicle" ? data.totalVehicles : spareData.totalVehicles) * 100} />
        <ProgressBar style={{ backgroundColor: "#FDAA48" }} now={(prop == "vehicle" ? data.newRequest : spareData.newRequest) * 100} />
        <ProgressBar style={{ backgroundColor: "#FFD74A" }} now={(prop == "vehicle" ? data.inProgressAtFinance : spareData.inProgressAtFinance) * 100} />
        <ProgressBar style={{ backgroundColor: "#2B7A9C" }} now={(prop == "vehicle" ? data.approvedByFinanceVehicle : spareData.approvedByFinanceVehicle) * 100} />
        <ProgressBar style={{ backgroundColor: "#43A830" }} now={(prop == "vehicle" ? data.PartialShipment : spareData.PartialShipment) * 100} />
        <ProgressBar style={{ backgroundColor: "#EF4D62" }} now={(prop == "vehicle" ? data.FullShipment : spareData.FullShipment) * 100} />
      </ProgressBar>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#3B61CF",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            {prop == "vehicle" ? "Total Vehicle PO" : "Total Spare PO"}
          </span>
        </div>
        <div>
          {prop == "vehicle" ? data.totalVehicles : spareData.totalVehicles}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#FDAA48",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            New PO Requests
          </span>
        </div>
        <div>
          {prop == "vehicle" ? data.newRequest : spareData.newRequest}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#FFD74A",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            Pending at Finance
          </span>
        </div>
        <div>
          {prop == "vehicle" ? data.pendingAtFinance : spareData.pendingAtFinance}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#2B7A9C",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            Approved by Finance
          </span>
        </div>
        <div>
          {prop == "vehicle" ? data.approvedByFinanceVehicle : spareData.approvedByFinance}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#43A830",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            Partial Shipment
          </span>
        </div>
        <div>
          {prop == "vehicle" ? data.PartialShipment : spareData.PartialShipment}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#EF4D62",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>
            Full Shipment
          </span>
        </div>
        <div>
          {prop == "vehicle" ? data.FullShipment : spareData.FullShipment}
        </div>
      </div>
    </div>
  );
}

export default Orders;
