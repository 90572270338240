import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";
import moment from "moment";

const GINDetails = ({ closeModal, rowData }) => {
  // const UsersData = row.requisitionDetails;
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "45%", height: "450px", padding: "10px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>GIN Details</h3>
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding: 7,
          }}
        >
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            GIN No: {rowData?.ginNo}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            GIN Date: {moment(rowData?.ginDate).format("DD-MM-YYYY")}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            GIN Raised By: {rowData?.showroomEmail}
          </span>

        </section>
        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ color: "blue" }}>Shipment Details</h6>
            </div>
            <div style={{ display: "flex", flexDirection: "column", borderWidth: "3px", alignItems: "start", margin: "3px", border: "1px solid #E6E6E6", borderRadius: "5px", padding: "2%" }}>
              <span style={{fontWeight:"100"}}>Shipped From : {rowData?.shipmentBy}</span>
              <span style={{fontWeight:"100"}}>Shipped No. : {rowData?.shipmentNo}</span>
              <span style={{fontWeight:"100"}}>Shipped Date : {moment(rowData?.shipmentDate).format("DD-MM-YYYY")}</span>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ color: "blue" }}>Delivery Details</h6>
            </div>
            <div style={{ display: "flex", flexDirection: "column", borderWidth: "3px", alignItems: "start", margin: "3px", border: "1px solid #E6E6E6", borderRadius: "5px", padding: "2%" }}>
              <span style={{fontWeight:"100"}}>Delivery Vehicle Number : {rowData?.deliveryVehicleNo}</span>
              <span style={{fontWeight:"100"}}>Delivery Person : {rowData?.deliveryPersonName}</span>
              <span style={{fontWeight:"100"}}>Delivery Person Contact No : {rowData?.deliveryPersonPhone}</span>
            </div>
          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <KeyboardBackspace /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default GINDetails;
