  import React, { useEffect, useState } from "react";
import "./subscription.scss";
import images from "../../../utils/get-images";
import { useHistory } from "react-router-dom";
import { KeyboardBackspace } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FaCircle } from "react-icons/fa6";
import { BsThreeDots } from "react-icons/bs";
import handleExport from "../../../utils/exportExcel";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import BootstrapTable from "react-bootstrap-table-next";
import ActionsModal from "../Actions/ActionsModal";
import auth from "../../../utils/auth";
import moment from "moment";

const SubscribedPlann = ({ setPlanShow, setActiveTab }) => {
  const { SearchBar } = Search;
  const [UsersData, setUsersData] = useState([]);
  const [hisData, setHisData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [planHistoryData, setPlanHistoryData] = useState([]);
  const [currentPlan, setCurrentPlan] = useState([]);


  console.log("planHistoryData-->",planHistoryData)
  console.log("currentPlan-->",currentPlan)


  const fetchPlanHistoryWithUserIDData = async () => {
    const resp = await trackPromise(
      API.get(
        `admin-module/api/v1/subscription/planHistoryWithUserId?userID=${auth.getUserInfo().userID
        }`
      )
    );
    console.log(resp);

    // Get today's date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0

    // Get the date one year ago
    const lastYear = new Date();
    lastYear.setFullYear(lastYear.getFullYear() - 1);
    lastYear.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0

    // Filter the plans
    const filteredPlans = resp.planHistory?.filter(plan => {
      const validFromDate = new Date(plan.validFrom);
      validFromDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
      return validFromDate >= lastYear && validFromDate <= today;
    });

    console.log(filteredPlans);
      setPlanHistoryData(filteredPlans.reverse());

    const currentPlan = resp.planHistory.filter((item) => {
      return item.planID == resp.planID;
    });
    setCurrentPlan(currentPlan);

    const activePlan = await trackPromise(
      API.get(`admin-module/api/v1/subscription/plan?planID=${resp.planID}`)
    );
    setUsersData(activePlan);
  };
  useEffect(() => {
    fetchPlanHistoryWithUserIDData();
  }, []);

  const fetchData = async () => {
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/subscription/plans`)
    );
    setHisData(resp);
  };
  useEffect(() => {
    fetchData();
  }, []);

  // // To filter data based on matching planID
  const planID = new Set(planHistoryData?.map((item) => item.planID));
  const filteredData = hisData.filter((item) => planID.has(item.planId));

  console.log(filteredData);
  const subscribedPlan = true;
  const activePlan = true;

  useEffect(() => { }, []);

  let history = useHistory();

  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    planId: "",
    planName: "",
    planValidity: "",
    fromdate: "",
    todate: "",
    validFrom: "",
    validTill: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      planId: "",
      planName: "",
      planValidity: "",
      fromdate: "",
      todate: "",
      validFrom: "",
      validTill: "",
    });
    const resp = await trackPromise(API.get(``));
    setUsersData(resp);
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const ExportData = planHistoryData?.map((item)=>{
    return {    
      "Plan Name":item?.planName,
      "Plan Status":item?.status,
      "Valid From":moment(item?.validFrom).format("DD-MM-YYYY"),
      "Valid Till":moment(item?.validTill).format("DD-MM-YYYY")
    }
  })
    const columns = [
      {
        text: "S.No.",
        dataField: "id",
        headerAttrs: { width: "50px" },
        headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            planHistoryData.findIndex(function (value, idx, arr) {
              return value == row;
            }) + 1
          );
        },
        sort: true,
      },
      {
        text: "Plan Name",
        dataField: "planName",
        headerAttrs: { width: "150px" },
        headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      },
      // {
      //   text: "Plan Description",
      //   dataField: "description",
      //   headerAttrs: { width: "150px" },
      //   headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      // },
      {
        text: "Plan Status",
        dataField: "planStatus",
        headerAttrs: { width: "150px" },
        headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      },
      {
        text: "Valid From",
        dataField: "validFrom",
        headerAttrs: { width: "150px" },
        headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
        formatter: (cell, row, rowIndex) => {
          if (!cell) {
            return "-";
          }
          return moment(row.validFrom).format("DD-MMM-YYYY/HH:mm");
        },
      },
      {
        text: "Valid Till",
        dataField: "validTill",
        headerAttrs: { width: "150px" },
        headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
        formatter: (cell, row, rowIndex) => {
          if (!cell) {
            return "-";
          }
          return moment(row.validTill).format("DD-MMM-YYYY/HH:mm");
        },
      },
    ];



  const expirationDate = moment(currentPlan[0]?.validTill);
  const currentDate = new Date();
  const daysDifference = expirationDate.diff(currentDate, "days");




  return (
    <>
      {isOpen ? (
        <ActionsModal
          setIsOpen={setIsOpen}
          Data={UsersData}
          setPlanShow={setPlanShow}
          setActiveTab={setActiveTab}
        />
      ) : (
        ""
      )}
      {!subscribedPlan ? (
        <div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="subsBackBtn"
              onClick={() => history.push("/app/Super Admin")}
            >
              <KeyboardBackspace /> Back to Dashboard
            </button>
          </div>
          <div className="">
            <img
              className="noSubsPic"
              src={images["subscription.svg"]}
              alt="logo"
            />
          </div>
          <br></br>
          <h3 style={{ textAlign: "center", color: "rgba(55, 55, 55, 1)" }}>
            No Subscription Yet
          </h3>
          <span style={{ textAlign: "center", color: "rgba(55, 55, 55, 1)" }}>
            Let's get Started
          </span>
          <button className="buyNowSubs">Buy Now</button>
        </div>
      ) : (
        <div>
          {/* subscribed plan */}
          <div style={{ marginBottom: "20px" }}>
            <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
              <span>Current Plan</span>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "9px",
                }}
              >
                <div>Active Plan</div>

                {daysDifference <= 30 ? (
                  <div
                    style={{
                      background: "#ffe6e6",
                      color: "#ff0000",
                      padding: "2px 5px 2px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    {`Expire on ${moment(currentPlan[0]?.validTill).format(
                      "DD-MMM-YYYY/HH:mm"
                    )}`}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div
                style={{
                  border: "0px solid #333",
                  boxShadow: "0 0 5px #333",
                  padding: "9px",
                  margin: "3px",
                  borderRadius: "6px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "2px solid #ccccb3",
                  }}
                >
                  <span style={{ width: "20%",fontWeight: "500" }}>Plan Name</span>
                  {/* <span style={{width:"15%"}}>Plan ID</span> */}
                  <span style={{ width: "20%",fontWeight: "500" }}>Valid From</span>
                  <span style={{ width: "20%",fontWeight: "500" }}>Valid Till</span>
                  {/* <span style={{ width: "31%" }}>Plan Description</span> */}
                  <span style={{ width: "20%",fontWeight: "500", paddingLeft: "80px" }}>
                    Status
                  </span>
                  <span style={{ width: "20%",fontWeight:"500", paddingLeft: "37px" }}>
                    Action
                  </span>
                </div>

                {activePlan && currentPlan[0]?.planStatus == "Active" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ width: "20%" }}>
                      {currentPlan[0]?.planName}
                    </span>
                    {/* <span style={{width:"15%"}}>{item.planId}</span> */}
                    <span style={{ width: "20%" }}>
                      {moment(planHistoryData[0]?.validFrom).format("DD-MMM-YYYY/HH:mm")}
                    </span>
                    <span style={{ width: "20%" }}>
                      {moment(planHistoryData[0]?.validTill).format("DD-MMM-YYYY/HH:mm")}
                    </span>
                    {/* <span style={{ width: "31%" }}>
                      {currentPlan[0]?.description}
                    </span> */}
                    <span style={{ width: "20%" }}>
                      {planHistoryData[0]?.planStatus === "Active" ? (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "3px",
                          }}
                        >
                          <FaCircle
                            style={{ color: "#006600", marginRight: "2px" }}
                          />{" "} {planHistoryData[0]?.planStatus}
                        </span>
                      ) : (
                        <span>{planHistoryData[0]?.planStatus}</span>
                      )}
                    </span>

                    <span
                      style={{
                        width: "20%",
                        paddingLeft: "37px",
                        paddingTop: "10px",
                      }}
                    >
                      {currentPlan[0]?.planStatus === "Active" ? (
                        <button
                          className="action-btn"
                          onClick={() => setIsOpen(true)}
                        >
                          {/* <FontAwesomeIcon icon={faEllipsisH} /> */}
                          <BiDotsVerticalRounded style={{fontSize:"x-large"}}/>
                        </button>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Plan History */}
          <div>
            <div id="overlay" onClick={(e) => off(e)}></div>
            <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
              <span>Plan History</span>
            </div>

            <ToolkitProvider
              keyField="id"
              data={planHistoryData}
              columns={columns}
              search
            >
              {(props) => (
                <>
                  <div style={{ float: "right", marginTop: "-39px" }}>
                    <div
                      style={{
                        display: "flex",
                        marginRight: "-15px",
                        height: "26px",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "220px" }}>
                        <SearchBar
                          {...props.searchProps}
                          placeholder="Search"
                          style={{
                            height: "25px",
                            // border: "1px solid #C6C6C6",
                            border: "none",
                            background: "white",
                            borderRadius: "3px",
                            marginRight: "-50px",
                          }}
                        />
                      </div>
                      {/* <div className="filter-refresh" onClick={handleClick}>
                        {" "}
                        <FontAwesomeIcon icon={faFilter} />
                        &nbsp; Filters
                      </div> */}
                      <div
                        className="filter-refresh"
                        onClick={() => handleExport(ExportData, "planHistory")}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp; Export
                      </div>
                    </div>
                  </div>

                  <div>
                    {show && (
                      <div
                        className="UHinputfields"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: "85px",
                          backgroundColor: "#F7F7F7",
                          padding: "5px",
                          marginRight: "655px",
                        }}
                      >
                        {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Plan Id
                      </label>
                      <select
                        name="planId"
                        value={formValue.planId}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected>
                          Plan Id
                        </option>
                        {
                            Data.map((item)=>(
                                <option>{item.planId}</option>
                            ))
                        }
                      </select>
                    </div> */}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "20%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Plan Name
                          </label>
                          <select
                            name="planName"
                            value={formValue.planName}
                            style={{ marginTop: "-5px", width: "100%" }}
                            onChange={handleChange}
                          >
                            <option value="" disabled selected>
                              Plan Name
                            </option>
                            {hisData.map((item) => (
                              <option>{item?.planName}</option>
                            ))}
                          </select>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "15%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Valid From
                          </label>
                          <input
                            type="date"
                            onFocus={(e) => (e.currentTarget.type = "date")}
                            onBlur={(e) => (e.currentTarget.type = "text")}
                            placeholder="From"
                            name="fromdate"
                            // onfocus={type='date'}
                            value={formValue.fromdate}
                            onChange={handleChange}
                            id="from"
                            autoComplete="off"
                            style={{ marginTop: "-5px", width: "100%" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "15%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Valid till
                          </label>
                          <input
                            type="date"
                            onFocus={(e) => (e.currentTarget.type = "date")}
                            onBlur={(e) => (e.currentTarget.type = "text")}
                            placeholder="To"
                            name="todate"
                            value={formValue.todate}
                            onChange={handleChange}
                            id="to"
                            autoComplete="off"
                            style={{ marginTop: "-5px", width: "100%" }}
                          />
                        </div>

                        {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Plan Validity
                      </label>
                      <select
                        name="planValidity"
                        value={formValue.planValidity}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected>
                        Plan Validity
                        </option>
                        {
                            Data.map((item)=>(
                                <option>{item.planValidity}</option>
                            ))
                        }
                      </select>
                    </div> */}

                        <button
                          onClick={hisData}
                          id="apply"
                          style={{ marginTop: "22px", marginLeft: "10px" }}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ marginTop: "22px" }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    )}
                  </div>

                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    // rowClasses="text-nowrap"
                    rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                    pagination={paginationFactory({
                      hidePageListOnlyOnePage: true,
                      showTotal: true,
                      hideSizePerPage: true,
                      paginationSize: 2,
                      sizePerPage: "10",
                    })}
                    noDataIndication="No Data Found"
                    {...props.baseProps}
                  />
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscribedPlann;
