import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import { ArrowRightAlt } from "@material-ui/icons";
import "../../settings-tabs/homesettings.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import BomDetails from "./bomdetails.jsx";
import "../workorder.scss";
import moment from "moment";
import { parseInt } from "lodash";
import { parseISO } from "date-fns";
import ViewSpecs from "../wo-viewspecs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { KeyboardBackspace, CheckCircle } from "@material-ui/icons";
import EditColorQty from "./editColorQty";

const EditWO = ({ rowdata, closeModal, updatedData }) => {
  console.log(rowdata);
  const [selectedBomId, setSelectedBomId] = useState();
  const [selectedBom, setSelectedBom] = useState(rowdata);
  const [specsShow, setSpecsShow] = useState();
  const [display, setDisplay] = useState(false);
  const [showColorQty, setShowAddColorQty] = useState(false);

  const shopManagers = ["Jatin", "Sandeep", "Ayush", "Jay"];
  const shopId = ["abc@gmail.com", "xyz@gmail.com"];

  // const handleInputChange = (event) => {
  //   setSelectedBomId(event.target.value);
  //   const bom = blankForm.filter(item => item?.bomId == event.target.value);
  //   setSelectedBom(bom[0]);
  // }

  console.log("cehk row dara",rowdata)
  const handleChange = (e) => {
    setSelectedBom({ ...selectedBom, [e.target.name]: e.target.value });
  };

  // const handleDates=(e)=>{
  //   setDateRange(e);

  // };

  const [startDate, setStartDate] = useState(new Date(rowdata.startDate));
  const [endDate, setEndDate] = useState(new Date(rowdata.endDate));

  const handleDates = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const editDetails = {
    orderID: rowdata.orderID,
    workType: selectedBom.workType,
    startDate: selectedBom.startDate,
    endDate: selectedBom.endDate,
    productionQuantity: selectedBom.productionQuantity,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.put(`production-module/api/v1/workOrder`, editDetails)
    );
    const { success, message } = resp;
    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <>
      <div>
        <div className="bussiness">
          <h4 className="heading">Edit Work Order</h4>
          <button
            type="button"
            className="btn btn-md"
            style={{ float: "right", marginTop: "-49px" }}
          >
            <div
              style={{ display: "flex", marginRight: "-18px", height: "26px" }}
            >
              <div className="filter-refresh" onClick={() => closeModal(false)}>
                <FontAwesomeIcon icon={faRedoAlt} />
                &nbsp; Back
              </div>
              <div className="filter-refresh" onClick={submitHandler}>
                <CheckCircle style={{ fontSize: "17px" }} />
                &nbsp; Update
              </div>
            </div>
          </button>
        </div>

        <div>
          <small>Edit Work Order Details</small>
          <hr />

          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "11px",
                }}
              >
                BOM ID
              </label>
              <input
                className="input-config"
                style={{ width: "98%", outline: "none", marginTop: "-8px" }}
                type="text"
                name="BOMID"
                value={rowdata.BOMID}
                placeholder="Bom ID"
                disabled
              />
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "3px",
                }}
              >
                Location
              </label>
              <input
                className="input-config"
                style={{ width: "98%", outline: "none", marginTop: "-8px", marginLeft:"-1px" }}
                type="text"
                name="location"
                value={rowdata.bomLocation}
                placeholder="Location"
                disabled
              />
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "0px",
                }}
              >
                BOM Name
              </label>

              <input
                className="input-config"
                style={{ width: "101%", outline: "none", marginTop: "-8px",marginLeft:"-3px" }}
                type="text"
                name="bomName"
                value={rowdata.bomName}
                placeholder="BOM Name"
                disabled
              />
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "6px",
                }}
              >
                Vehicle Model
              </label>
              <input
                className="input-config"
                style={{ width: "98%", outline: "none", marginTop: "-8px", marginLeft:"2px" }}
                type="text"
                name="vehicleModel"
                // value={rowdata.vehicleModel + " & " + rowdata.vehicleVariant}
                value={rowdata.vehicleModel}
                placeholder="Vehicle Model & Variant"
                disabled
              />
            </div>
          </div>

          

          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "7px",
                }}
              >
                Shop Floor Manager
              </label>

              <input
                className="input-config"
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                type="text"
                name=" shopFloorManager"
                value={rowdata.shopFloorManager}
                placeholder="manager"
                disabled
              />
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "6px",
                }}
              >
                Shop Floor Email ID
              </label>
              <input
                className="input-config"
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                type="text"
                name="shopFloorEmailID"
                value={rowdata.shopFloorEmailID}
                placeholder="Email ID "
                disabled
              />
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "6px",
                }}
              >
                Work Type<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="input-config"
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
                type="text"
                name="workType"
                value={rowdata.workType}
                placeholder="Work Type "
                disabled
              />
              {/* <select
                required={true}
                name="workType"
                className="input-config"
                style={{ width: "103%", outline: "none", marginTop: "-8px" }}
                defaultValue={rowdata.workType}
                onChange={(event) => handleChange(event)}
              >
                <option>Production</option>
                <option>Customized</option>
              </select> */}
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "17px",
                }}
              >
                Work Timeline<span style={{ color: "red" }}>*</span>
              </label>
              <DatePicker
                className="daterange woeditDateRange"
                placeholderText="Work Timeline"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                disabled
                onChange={handleDates}
                // isClearable={true}
                style={{
                  marginTop: "0",
                }}
              />
            </div>
          </div>


          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            {/* <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "24%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "6px",
                }}
              >
                Edit Color & Quantity<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="input-config icon-rtl"
                style={{ width: "98%", outline: "none", marginTop: "-8px" }}
                type="text"
                name="colorQty"
                placeholder="Edit Color & Quantity"
                value={""}
                onClick={() => setShowAddColorQty(true)}
              />
              {showColorQty && <EditColorQty closeModal={setShowAddColorQty} />}
            </div> */}
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "24%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "6px",
                }}
              >
                Production Quantity<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="input-config"
                style={{ width: "99%", outline: "none", marginTop: "-8px", marginLeft:"1px" }}
                type="text"
                name="productionQuantity"
                defaultValue={rowdata.productionQuantity}
                placeholder="Production Quantity"
                autoComplete="off"
                onChange={(event) => handleChange(event)}
                disabled
              />
            </div>
          </div>
        </div>

        <div>
          <br />
          <div>
            <h5>Component Details</h5>
            <table className="viewcomp_tbl" style={{ width: "100%" }}>
              <tr>
                <th>Component Name</th>
                <th>Component SKU Code</th>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Specification</th>
                <th>BOM Quantity</th>
                <th>Required Quantity</th>
              </tr>

              {rowdata.bomComponents.map((item) => {
                var requiredQuantity =
                  item?.componentQuantity * rowdata.productionQuantity;
                return (
                  <tr>
                    <td className="leftcell">{item?.componentName}</td>
                    <td>{item?.skuCode}</td>
                    <td>{item?.category}</td>
                    <td>{item?.subCategory}</td>
                    <td>
                      <button
                        className="action-btn"
                        onClick={() => setSpecsShow(item?.skuCode)}
                        style={{ color: "blue" }}
                      >
                        View Specs
                      </button>
                      {specsShow === item?.skuCode && (
                        <ViewSpecs selectedBom={item} close={setSpecsShow} />
                      )}
                    </td>
                    <td>{item.componentQuantity}</td>
                    <td className="rightcell">{requiredQuantity}</td>
                  </tr>
                );
              })}
            </table>
          </div>
          <br />
          <div>
            <div>
              <h5>Labour Details</h5>
              <div style={{ display: "flex" }}>
                <span
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    style={{ width: "15px", height: "15px" }}
                    type="radio"
                    name="labor"
                    id="lbrcount"
                    checked={display == false}
                    onClick={(e) => setDisplay(false)}
                  />
                  <label htmlFor="lbrcount" style={{ marginTop: "8px" }}>
                    View By Labour Count
                  </label>
                </span>

                <span
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    style={{
                      width: "15px",
                      height: "15px",
                      marginLeft: "10px",
                    }}
                    type="radio"
                    name="labor"
                    id="lbrtime"
                    onClick={(e) => setDisplay(true)}
                  />
                  <label htmlFor="lbrtime" style={{ marginTop: "8px" }}>
                    View By Labour Time
                  </label>
                </span>
              </div>
            </div>

            <table
              className="viewcomp_tbl"
              style={{ width: "100%", fontSize: "15px" }}
            >
              <tr>
                <th>Labour Type</th>
                <th>Labour Level</th>
                <th>BOM Labour Count</th>
                <th>BOM Labour Count Time</th>
                <th>Required Labour Count</th>
                <th>Required Labour Time</th>
              </tr>
              {rowdata?.bomLabor?.map((item) => {
                var x = item?.requiredWorkers;
                var y = item?.requiredWorkers * rowdata.productionQuantity;
                var m = item?.requiredDuration;
                var n = item?.requiredDuration * rowdata.productionQuantity;
                return (
                  <tr>
                    <td className="leftcell">{item?.laborCategory}</td>
                    <td>{item?.laborLevel}</td>
                    <td>{item?.requiredWorkers} nos.</td>
                    <td>
                      {item?.requiredDuration} {item?.laborDuration}
                    </td>
                    <td>{display ? x : y}</td>
                    <td className="rightcell">
                      {display ? n : m} {item?.laborDuration}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
          <br />
          <div>
            <h5>Assembly Details</h5>
            <table
              className="viewcomp_tbl"
              style={{ width: "100%", fontSize: "15px" }}
            >
              <tr>
                <th>Assembly Type</th>
                <th>Assembly Name</th>
                <th>Service Name</th>
                <th>BOM Quantity</th>
                <th>Required Duration</th>
                <th>Required Quantity</th>
                <th>Required Time</th>
              </tr>
              {rowdata?.bomAssembly?.map((item) => {
                return (
                  <tr>
                    <td className="leftcell">{item?.assemblyType}</td>
                    <td>{item?.assemblyName}</td>
                    <td>
                      <ol style={{ textAlign: "left" }}>
                        {item?.assemblyServices?.map((item) => (
                          <li>{item?.serviceName}</li>
                        ))}
                      </ol>
                    </td>
                    <td>{item?.assemblyServices?.length}</td>
                    <td>
                    {item?.assemblyServices?.map((laborItem, index) => (
                        <div key={index}>{laborItem?.timeDuration} {laborItem?.time}</div>
                      ))}
                    </td>
                    <td>
                    {item?.assemblyServices?.length * rowdata.productionQuantity} 
                    </td>

                    <td>
                    {item?.assemblyServices?.map((laborItem, index) => (
                        <div key={index}>{laborItem?.timeDuration} {laborItem?.time}</div>
                      ))}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditWO;
