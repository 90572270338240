import React, { useState } from 'react'
import { CancelOutlined, CheckCircle } from "@material-ui/icons";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../../../../utils/notifications";

const PlaceGoodReleaseRequest = ({
  closeModal,
  formData,
  rowData,
}) => {

  const refresh = () => {
    updatedData();
  }

  const submitHandler = async () => {
    let editDetails = {

      "status": "Pending At Inventory",

      "requisitionID": rowData.requisitionID

    }
    const resp = await trackPromise(API.put(`/salesUser-module/api/v1/orders/sparesApprovedByFinance`, editDetails));
    if (resp.success) {
      successNotification("Goods Release request placed to inventory");
      closeModal(false);
      refresh();
    } else {
      errorNotification(resp.message, { autoClose: 10000 });
    }
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "400px", height: "200px" }}
      >
        <div
          className="body"
          style={{
            justifyContent: "center",
            fontSize: "16px",
            padding: "0px",
            marginTop: "40px",
          }}
        >
          Are you sure to Place this PO Request
          <br />
          to inventory user for goods release ?
        </div>
        <div className="footer" style={{ justifyContent: "center" }}>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: "white",
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
            onClick={submitHandler}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlaceGoodReleaseRequest;
