import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import AddOnRequestsSF from "./addonrequests-sf";
// import FinishedGoodsSF from "./finishedgoods-sf";
// import WipSF from "./wip-sf";

const WipSF = React.lazy(()=>import("./wip-sf"))
const AddOnRequestsSF = React.lazy(()=>import("./addonrequests-sf"))
const FinishedGoodsSF = React.lazy(()=>import("./finishedgoods-sf"))



const InventoryShopfloor = () => {
  const tabs = [
    {
      id: "SFM2T1",
      label: TabLabels.WIP_INVENTORY,
      Component: WipSF
    },
    {
      id: "SFM2T2",
      label: TabLabels.ADDONREQUESTS,
      Component: AddOnRequestsSF
    },
    {
      id: "SFM2T3",
      label: TabLabels.FINISHEDGOODS,
      Component: FinishedGoodsSF
    },
  ];

  const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
    return tabs.filter(tab => tabIds.includes(tab.id));
  };
  let tabsToShow = getTabs(tabs);

  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>

  )
}
export default InventoryShopfloor