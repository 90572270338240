import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import AddZones from "./addZones";
// import ManageZones from "./manageZonesTab";
const AddCities = React.lazy(() => import("./AddCities"))
const ManageCities = React.lazy(() => import("./ManageCities"))
const AddZones = React.lazy(() => import("./addZones"))
const ManageZones = React.lazy(() => import("./manageZonesTab"))


const tabs = [
  {
    id: "OPM1T1",
    label: TabLabels.ADD_CITIES,
    Component: AddCities
  },
  {
    id: "OPM1T2",
    label: TabLabels.MANAGE_CITIES,
    Component: ManageCities
  },
  {
    id: "OPM1T3",
    label: TabLabels.ADD_ZONES,
    Component: AddZones
  },
  {
    id: "OPM1T4",
    label: TabLabels.MANAGE_ZONES,
    Component: ManageZones
  }
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};

const ZoneManagement = () => {
  let tabsToShow = getTabs(tabs);

  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};



export default ZoneManagement;
