import React, { useState } from "react";
import {
    KeyboardBackspace,
    CheckCircle,
    HighlightOffOutlined,
} from "@material-ui/icons";
import moment from 'moment'
import AppConstants from "../../../utils/app-constants";
import ReturnedQty from "./returnedQty";

const RG_ViewDetails = ({closeModal, rowdata }) => {
    console.log(rowdata)
    const [openQty, setOpenQty] = useState(false);
    const [status, setStatus] = useState("");
     

    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "980px", height: "550px", padding: '15px' }}
            >
                <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>View Details</h3>
          {/* <div>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Print 
          </button>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Export
          </button>
          </div> */}
        </div>

     <section
            style={{
                width: '100%',
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                fontWeight: "500",
                margin: "10px 0"
            }}
        >
            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <span>Requested ID : {rowdata.requestID}</span>
                <span>GIN No : {rowdata.GIN}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Requested By: {rowdata.requestedBy}</span>
                <span>GIN Date : {moment(rowdata.createDate).format("DD-MMM-YYYY, HH:mm")}</span>
            </div>
                       
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Requested Date: {moment(rowdata.requestDate).format("DD-MMM-YYYY, HH:mm")}</span>
                {/* <span>Returned Date : {rowdata.segment}</span> */}
            </div>

         
            {rowdata.status == AppConstants.ONHOLD ?
                <div className="onhold_div">
                  <span className="holdDate">Hold Date: {moment(rowdata.actionDate).format("DD-MMM-YYYY, HH:mm")} </span>
                  <span style={{fontSize:"25px",color:"#CDCDCD",marginTop:"9px"}}>|</span>
                  <span className="holdReason" style={{right:"150px"}}>Hold Reason: {rowdata.reason}</span>
                </div>
                :
                rowdata.status == AppConstants.COMPLETED ?
                  <div className="onhold_div" style={{backgroundColor:"#C0FCC6"}}>
                    <span className="holdDate" style={{color:"#11781B"}}>Completion Date: {moment(rowdata.actionDate).format("DD-MMM-YYYY, HH:mm")} </span>
                  </div> 
                : 
                rowdata.status == AppConstants.REJECTED ?
                  <div className="onhold_div" style={{backgroundColor:"#FFE1E1"}}>
                    <span className="holdDate" style={{color:"#E51111"}}>Rejection Date: {moment(rowdata.actionDate).format("DD-MMM-YYYY, HH:mm")} </span>
                    <span style={{fontSize:"25px",color:"#CDCDCD",marginTop:"9px"}}>|</span>
                    <span className="holdReason" style={{right:"150px",color:"red"}}>Rejection Reason: {rowdata.reason}</span>
                  </div>: null}

         </section>

                 {
                     rowdata.requestType == "Verification" ? 
                     <section>
                    <div style={{ display: "flex", justifyContent: "space-between", fontSize:'12px' }}>
                            <span>BOM ID: {rowdata.BOMID}</span>
                     </div>
                     <div style={{ display: "flex", justifyContent: "space-between", fontSize:'12px'  }}>
                            <span>BOM Location: {rowdata.bomLocation}</span>
                    </div>
                    </section>:null

                 }
                    

                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >

                    <section style={{ width: '100%' }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '5%' }}>S.No.</div>
                            <div style={{ width: '20%' }}>Category</div>
                            <div style={{ width: '15%' }}>Model</div>
                            <div style={{ width: '15%' }}>Variant</div>
                            <div style={{ width: '15%' }}>Type</div>
                            <div style={{ width: '15%' }}>Color</div>
                            <div style={{ width: '15%' }}>Returned Qty.</div>
                        </div>

                        {rowdata?.vehicles?.map((item, index)=> {
                            return(
                                <div>
                                    <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }}>
                                        <div style={{ width: '5%', overflowY: "auto" }}>{index + 1}</div>
                                        <div style={{ width: '20%', overflowY: "auto" }}>{item?.vehicleCategory}</div>
                                        <div style={{ width: '15%', overflowY: "auto" }}>{item?.vehicleModel}</div>
                                        <div style={{ width: '15%', overflowY: "auto" }}>{item?.vehicleVariant}</div>
                                        <div style={{ width: '15%', overflowY: "auto" }}>{item?.vehicleType}</div>
                                        <div style={{ width: '15%', overflowY: "auto" }}>{item?.vehicleColor}</div>
                                        <div onClick={()=>setOpenQty(index)} style={{ width: '15%', overflowY: "scroll", color:'blue',cursor:'pointer'}}>{item?.returnedQty?.length}</div>
                                    </div>
                                    {openQty === index && <ReturnedQty rowdata={item} closeModal={setOpenQty} />}
                         </div>
                           )
                        })} 
                    </section>

                </div>

                <div className="footer">
                <div><button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={()=> closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button></div> 
                </div>
            </div>
        </div>
    );
};

export default RG_ViewDetails;