import { KeyboardBackspace } from '@material-ui/icons';
import React from 'react'

const ViewSpecs = ({ closeModal, specs }) => {
    console.log(specs)
    return (
        <div className="container1">
            <div className="modalContainer" style={{ height: '350px' }}>
                <div className="header">
                    <h3>Component Specification</h3>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h6>Component Name: {specs?.componentName}</h6>
                    <h6>Component SKU Code: {specs?.skuCode}</h6>
                </div>

                <div className="body" style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap" }}>
                    <ul type="none" className="viewspeclist" style={{ paddingLeft: "0px", textAlign: "left", fontSize: "14px" }}>
                        {specs?.specifications?.map((item) => {
                            return (
                                <li>{`Component ${item?.specsType} : ${item?.specsValue} ${item?.specsUnit}`}</li>
                            )
                        })}
                    </ul>
                    {/* <ul type="none" className="viewspeclist" style={{textAlign:"right",fontSize:"14px"}}>
                  <li>Component Width : {specs?.componentSpecifications.componentWidth}</li>
                  <li>Component Weight : {specs?.componentSpecifications.componentWeight}</li>
                </ul> */}
                </div>
                <div className="footer">
                    <button onClick={() => closeModal(false)}><KeyboardBackspace /> Back</button>
                </div>
            </div>
        </div>
    );
};

export default ViewSpecs