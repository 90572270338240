import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import "./modal.scss";
import { successNotification, errorNotification } from "../../utils/notifications";
import auth from "../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Delete = ({ updatedData, editData, closeModal , closeOn }) => {
  const [formValue, setFormValue] = useState({
    deleteReason: "",
    description: "",
  });

  const editDetails = {
    userID: editData.userID,
    action: "Delete",
    reason: formValue.deleteReason,
    reasonComment: formValue.description,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    console.log(formValue);
    const resp = await trackPromise(
      API.post(`admin-module/api/v1/users/delete`, editDetails)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      closeOn(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const submitSubAdminHandler = async () => {
    console.log(formValue);
    const resp = await trackPromise(
      API.post(
        `${process.env.REACT_APP_BASE_URL}/admin-module/api/v1/users/requests/subAdmin`,
        editDetails
      )
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      closeOn(false);
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const closeHandler = ()=>{
    closeModal(false)
    closeOn(false)
  }


  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Delete User</h3>
        </div>

        <div className="body">
          <label style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "120px",display:"flex" }}>Delete Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="deleteReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', marginTop: '-5px' }}
            >
              <option value="" disabled selected>
                Delete Reason
              </option>
              <option value="Misconduct">Misconduct</option>
              <option value="Not an Active User">Not an Active User</option>
              <option value="Change of Role">Change of Role</option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.deleteReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Deactivation Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={auth.isSubAdmin() ? submitSubAdminHandler : submitHandler}
            disabled={formValue.deleteReason == "" ? true : false}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Delete;
