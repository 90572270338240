import React, { useState } from "react";
import {
    KeyboardBackspace,
    CheckCircle,
    HighlightOffOutlined,
} from "@material-ui/icons";
import moment from 'moment'
import ViewSpecs from "./ViewSpecs";
import AppConstants from "../../../utils/app-constants";
import auth from "../../../utils/auth";

const ViewUpdate = ({ closeModal, rowData }) => {
    console.log(rowData)
    const [openQty, setOpenQty] = useState();
    const [viewSpecs, setViewSpecs] = useState(false);
    const [showGRN, setShowGRN] = useState();
    const [showGIN, setShowGIN] = useState();

    const totalValue = rowData.vehicles.map((item, index) => {
        return item.subTotal
    })

    const finalValue = totalValue.reduce((acc, item) => {
        return acc + parseInt(item)
    }, 0)

    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "90%", height: "500px", padding: '15px' }}
            >
                <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3>View and Update</h3>
                    {/* <div>
                        <button
                            className="action-btn"
                            style={{ color: "#0A18A3" }}>
                            Print
                        </button>
                        <button
                            className="action-btn"
                            style={{ color: "#0A18A3" }}>
                            Export
                        </button>
                    </div> */}
                </div>

                <section
                    style={{
                        width: '100%',
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "14px",
                        fontWeight: "500",
                        margin: "10px 0"
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Requested ID: {rowData.requestID}</span>
                        <span>RPI No.: {rowData.rpiNo}</span>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Requested By: {rowData.requestedBy}</span>
                        <span>RPI Date: {moment(rowData.rpiDate).format("DD-MMM-YYYY")}</span>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Requested Date : {moment(rowData.requestedDate).format("DD-MMM-YYYY")}</span>
                        <span>RPI Raise By : {rowData.RPIRaiseBy}</span>
                    </div>
                </section>


                <section>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "80%" }}>
                        <span style={{ display: "flex", background: "#0A18A3", alignItems: "center", height: "30px", justifyContent: "center", borderRadius: "5px", color: "white", width: "200px", fontSize: "14px", fontWeight: "800" }}>Total Returned Qty. : {rowData.vehicles.length}</span>
                        <span style={{ display: "flex", background: "#0A18A3", alignItems: "center", height: "30px", justifyContent: "center", borderRadius: "5px", color: "white", width: "200px", fontSize: "14px", fontWeight: "800", marginLeft: "10px" }}>{`Total Value(${auth.getUserInfo().currencySymbol}) : `} {finalValue}</span>
                    </div>
                </section>

                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >

                    <section style={{ width: '100%' }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '5%' }}>S.No.</div>
                            <div style={{ width: '22%' }}>Category</div>
                            <div style={{ width: '10%' }}>Model</div>
                            <div style={{ width: '10%' }}>Variant</div>
                            <div style={{ width: '10%' }}>Type</div>
                            <div style={{ width: '10%' }}>Color</div>
                            <div style={{ width: '15%' }}>VIN</div>
                            <div style={{ width: '15%' }}>BIN</div>
                            {rowData.status === AppConstants.NEWREQUEST && (
                                <div style={{ width: '10%' }}>Return Reason</div>
                            )}

                            <div style={{ width: '12%' }}>{`Vehicle Price(${auth.getUserInfo().currencySymbol})`}</div>
                            <div style={{ width: '12%' }}>{`Sub Total(${auth.getUserInfo().currencySymbol})`}</div>
                            {/* <div style={{ width: '12%' }}>status</div> */}
                            {rowData.status === AppConstants.QC_VERIFICATION || rowData.status === AppConstants.VERIFICATION_COMPLETED ? (
                                <div style={{ width: '12%' }}>Failed Reason</div>

                            ) : null}
                        </div>

                        <div>
                            {rowData.vehicles.map((item, index) => {
                                return (
                                    <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }}>
                                        <div style={{ width: '5%', overflowY: "auto" }}>{index + 1}</div>
                                        <div style={{ width: '22%', overflowY: "auto" }}>{item.vehicleCategory}</div>
                                        <div style={{ width: '10%', overflowY: "auto" }}>{item.vehicleModel}</div>
                                        <div style={{ width: '10%', overflowY: "auto" }}>{item.vehicleVariant}</div>
                                        <div style={{ width: '10%', overflowY: "auto" }}>{item.vehicleType}</div>
                                        <div style={{ width: '10%', overflowY: "auto" }}>{item.vehicleColor}</div>
                                        <div style={{ width: '15%', overflowY: "auto" }}>{item.VIN}</div>
                                        <div style={{ width: '15%', overflowY: "auto" }}>{item.BIN}</div>
                                        {rowData.status === AppConstants.NEWREQUEST && (
                                            <div style={{ width: '10%', overflowY: "auto" }}>{item.returnReason}</div>
                                        )}

                                        <div style={{ width: '12%', overflowY: "auto" }}>{item.vehiclePrice}</div>
                                        <div style={{ width: '12%', overflowY: "auto" }}>{item.subTotal}</div>
                                        {/* <div className={item.status=="Passed" ? ("active-btn"):item.status=="Failed" ? ("delete-btn") : item.status=="Pending Verification" ?("deactive-btn") :""}
                                         style={item.status === "Passed" ? { color: "#00994d", width: '12%', overflowY: "auto" } :
                                         item.status=="Failed" ? {color:"#e60000", width: '12%', overflowY: "auto"} :
                                         item.status=="Pending Verification" ? {color:"#cca300", width: '12%', overflowY: "auto"} :{} }>
                                        {item.status}</div> */}

                                        {rowData.status === AppConstants.QC_VERIFICATION || rowData.status === AppConstants.VERIFICATION_COMPLETED ? (
                                            <div style={{ width: '12%', overflowY: "auto" }}>{item.reason}</div>
                                        ) : null}
                                    </div>
                                )
                            })}



                        </div>


                    </section>
                </div>

                <div className="footer">
                    <div><button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button></div>
                </div>
            </div>
        </div>
    );
};

export default ViewUpdate;