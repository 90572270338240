import { faClock, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "../../components/modal/modal.scss";
import AppConstants from "../../utils/app-constants";
import CreditNotePreview from "../ReturnTemplates/CreditNoteView";
import GenerateShip from "./GenerateShip";
import ViewDetails from "./ViewDetails";
import ViewUpdates from "./ViewUpdates";


const ReturnActions = ({ rowData, fetchData , rowIndex}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      approve: false,
      onHoldApprove: false,
      onHold: false,
      reject: false,
      release: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>

      {activeModal.approve && showModal && <GenerateShip fetchData={fetchData} rowData={rowData} closeModal={setShowModal} />}

      {activeModal.onHold && showModal && <ViewUpdates rowData={rowData} closeModal={setShowModal} />}

      {activeModal.reject && showModal && <CreditNotePreview rowData={rowData} closeModal={setShowModal} />}


      <div style={ rowIndex === 0 || rowIndex === 1 ? { position: "relative" } : {position:"relative", marginTop:"-148px"}}>
        <div className="action vendoraction" style={{ width: "197px" }}>
          {rowData.status === AppConstants.RPI_GENERATED ?

            <div>
              <span id="approve" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faClock} /> Generate GIN & Ship
              </span>
            </div>

            :
            rowData.status === AppConstants.RETURN_REQUEST_PLACED ?

              <>
                <div>
                  <span id="onHold" onClick={(e) => clickHandler(e)}>
                    <FontAwesomeIcon icon={faEye} /> View Updates
                  </span>
                </div>
              </> :
              rowData.status === AppConstants.RETURN_SETTLED || rowData?.status == "Return Note Generated" ?
                <div>
                  <span id="reject" onClick={(e) => clickHandler(e)}>
                    <FontAwesomeIcon icon={faEye} /> View Details
                  </span>
                </div>
                :
                null
          }




        </div>
      </div>
    </div>
  );
};

export default ReturnActions;
