import React, { useEffect } from "react";
import NotificationSwitch from "./iam-Switch";
import IamSwitch from "./iam-Switch";

const OperationNotificationPreference = () => {

    useEffect(() => {
    }, []);

    let NotificationList = [
        {
            title: "New User Registration",
            component: < NotificationSwitch />
        },
        {
            title: "Disable / Deactivation Request",
            component: < NotificationSwitch />
        },
        {
            title: "Plan Expiry Notification",
            component: < NotificationSwitch />
        },
        {
            title: "Plan Re-newel Notification",
            component: < NotificationSwitch />
        },
        {
            title: "Procurement Notification",
            component: < NotificationSwitch />
        },
        {
            title: "Production Notification",
            component: < NotificationSwitch />
        },
        {
            title: "Approval Notification",
            component: < NotificationSwitch />
        },
    ];

    let notificationList = [];
    NotificationList.forEach((val) => {
        notificationList.push(
            <tr>
                <td>{val.title}</td>
                <td></td>
                <td></td>
                <td className="list"><IamSwitch /></td>
            </tr>
        )
    })
    return (
        <>
            <div className="bussiness">
                <h4 className="heading">Notification Preference</h4>
            </div>
            <br />
            <div>
                {/* {NotificationList.map((item) => {
                return(
              <>
              <ul style={{ listStyleType: "none" }}>
                        <li>{item.title}</li>
                    </ul>
              <ul>{item.component}</ul>
              </>
                )
            })} */}
                <table className="notification-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{notificationList}</tbody>
                </table>
            </div>
        </>

    );
};

export default OperationNotificationPreference;