import React, { useEffect, useState } from 'react';
import { BsArrowUpRight } from 'react-icons/bs';
import ServiceJobsPieChart from './ServiceJobsPieChart';
import SpareReturnsCard from './SpareReturnsCard';
import ManageJobCards from "../ServiceAdvisorServiceJobs/Components/ManageJobCards";
import ManageReturns from '../ServiceAdvisorRequestAndReturn/Components/ManageReturns';
import API from '../../utils/api';
import { trackPromise } from 'react-promise-tracker';

function ServiceAdvisorDashboard() {
  const [openServiceJobTable, setOpenServiceJobTable] = useState(false);
  const [openSpareReturnsTable, setOpenSpareReturnsTable] = useState(false);
  const [serviceJobsData, setServiceJobsData] = useState({});
  const [returnsData, setReturnsData] = useState({});

  const fetchData = async () => {
    const resp = await trackPromise(
      API.get(`serviceAdvisor-module/api/v1/dashboard/getServiceJobsCount`)
    );
    console.log(resp);
    setServiceJobsData(resp);

    const resp2 = await trackPromise(
      API.get(`serviceAdvisor-module/api/v1/dashboard/getServiceReturnsCount`)
    );
    console.log(resp2);
    setReturnsData(resp2);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {openServiceJobTable == true ? <ManageJobCards inDashboard={setOpenServiceJobTable} /> :
        openSpareReturnsTable == true ? <ManageReturns inDashboard={setOpenSpareReturnsTable} /> :
          <div className="body">
            <div id="overlay" onClick={(e) => off(e)}></div>
            <div className="firstRow">
              <div
                className="container-box"
                style={{ width: "50%", height: "370px", padding: "10px" }}
              >
                <div className="header">
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Service Jobs</h5>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer"
                      }}
                    >
                      <BsArrowUpRight onClick={() => setOpenServiceJobTable(true)} />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    padding: "10px"
                  }}
                >
                  <span className="grey-heading">Total Service Jobs</span>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {serviceJobsData.totalJobCard}
                  </div>
                </div>
                <div>
                  <ServiceJobsPieChart jobsData={serviceJobsData}/>
                </div>
              </div>
              <div className="container-box"
                style={{ width: "50%", height: "370px", padding: "10px", marginLeft: "15px" }}
              >
                <div
                  className="header"
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Spare Returns</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "60%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer"
                      }}
                    >
                      <BsArrowUpRight onClick={() => setOpenSpareReturnsTable(true)} />
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ overflow: "auto" }}>
                  <SpareReturnsCard returnsData={returnsData}
                  />
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default ServiceAdvisorDashboard