import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import VehicleInventory from "./VehicleInventory"
// import VehicleSales from "./VehicleSales"
// import SpareInventory from "./SpareInventory"
// import SpareSales from "./SpareSales"

const VehicleInventory = React.lazy(()=>import("./VehicleInventory"))
const VehicleSales = React.lazy(()=>import("./VehicleSales"))
const SpareInventory = React.lazy(()=>import("./SpareInventory"))
const SpareSales = React.lazy(()=>import("./SpareSales"))


const tabs = [
  {
    id: "OPM6T1",
    label: TabLabels.VEHICLE_INVENTORY,
    Component: VehicleInventory,
  },
  {
    id: "OPM6T2",
    label: TabLabels.VEHICLE_SALES,
    Component: VehicleSales,
  },
  {
    id: "OPM6T3",
    label: TabLabels.SPARES_INVENTORY,
    Component: SpareInventory,
  },
  {
    id: "OPM6T4",
    label: TabLabels.SPARE_SALES,
    Component: SpareSales,
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

const OperationBIS = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default OperationBIS;
