import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import "../QcDashboard/styles/main.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { BsArrowUpRight, BsTable } from "react-icons/bs";
import { FaRedoAlt } from "react-icons/fa";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import ApprovalProgressBar from "./approvalProgressBar";
import WorkOrderDashboard from "../ShopFloorDashboard/WorkOrderDashboard";
import Group18 from "../QcDashboard/assets/Group18.svg";
import Group9 from "../QcDashboard/assets/Group9.svg";
import Group from "../QcDashboard/assets/Group.png";
import Vector from "../QcDashboard/assets/Vector.svg";
import ProgressBar from "react-bootstrap/esm/ProgressBar";
import ManagementRawBox from "./ManagementRawBox";
import FinishedGoodsBox from "./finishedGoodsBox";
import Vector3 from "../QcDashboard/assets/Vector3.svg";
import ComponentVerification from "../QcDashboard/components/ComponentVerification";
import SpareReVerification from "../QcDashboard/components/SpareReVerification";
import VehicleBarChart from "../QcDashboard/components/VehicleBarChart";
import VehicleReBarChart from "../QcDashboard/components/VehicleReBarChart";
import { DatePicker, Space } from "antd";
import { useHistory } from "react-router-dom";
import BomNewRequests from "../approval/bom-newrequests";
import VP_NewRequests from "../approval/vp-newrequests";
import SPP_NewRequests from "../approval/spp-newrequests";
import ComponentQC from "../workorder-qc/componentQC";
import VehicleQC from "../workorder-qc/vehicleQC";
import PurchaseOrder from "../inventory-management/purchaseorder";
import GRN from "../inventory-management/grn";
import RawInventory from "../inventory-management/rawinventory";
import MROInventory from "../inventory-management/mroinventory";
import Standard from "../inventory-management/Standard";
import Hybrid from "../inventory-management/Hybrid";
import moment from "moment";

function ManagementDash() {
  const [purchaseData, setPurchaseData] = useState({});
  const [grn, setGrnData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [vehicleStartDate, setVehicleStartDate] = useState(null);
  const [vehicleEndDate, setVehicleEndDate] = useState(null);
  const [spareStartDate, setSpareStartDate] = useState(null);
  const [spareEndDate, setSpareEndDate] = useState(null);
  const [qcVehicleStartDate, setqcVehicleStartDate] = useState(null);
  const [qcVehicleEndDate, setqcVehicleEndDate] = useState(null);
  const [spareVerificationStartDate, setSpareVerificationStartDate] =
    useState(null);
  const [spareVerificationEndDate, setSpareVerificationEndDate] =
    useState(null);
  const [vehicleReStartDate, setVehicleReStartDate] = useState(null);
  const [vehicleReEndDate, setVehicleReEndDate] = useState(null);
  const [openBomCostTable, setOpenBomCostTable] = useState(false);
  const [openVehiclePriceTable, setOpenVehiclePriceTable] = useState(false);
  const [openSparePriceTable, setOpenSparePriceTable] = useState(false);
  const [openQcCompTable, setOpenQcCompTable] = useState(false);
  const [openQcVehicleTable, setOpenQcVehicleTable] = useState(false);
  const [openPoTable, setOpenPoTable] = useState(false);
  const [openGRNTable, setOpenGRNTable] = useState(false);
  const [openRAWTable, setOpenRAWTable] = useState(false);
  const [openMROTable, setOpenMROTable] = useState(false);
  const [openStandardTable, setOpenStandardTable] = useState(false);
  const [openHybridTable, setOpenHybridTable] = useState(false);

  const { RangePicker } = DatePicker;
  const [calenderOpen, setCalenderOpen] = useState(false);

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    setCalenderOpen(false);
    document.getElementById("overlay").style.display = "none";
    refresh();
    vpaRefresh();
    spareRefresh();
    vehicleRefresh();
    verification();
    reVerificationRefresh();
  }

  // BOM Costing (Approval)
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const refresh = () => {
    setStartDate(null);
    setEndDate(null);
    document.getElementById("calender").style.display = "none";
    document.getElementById("calendericon").style.display = "block";
    document.getElementById("calendericon").style.justifyContent = "center";
    document.getElementById("calendericon").style.textAlign = "center";
    document.getElementById("pallate").style.marginTop = "0px";
  };
  const show = () => {
    document.getElementById("calender").style.display = "block";
    document.getElementById("calendericon").style.display = "none";
    document.getElementById("pallate").style.display = "block";
    setCalenderOpen(true);
    on();
  };

  // Vehicle Pricing (Approval)
  const onVehicleChange = (dates) => {
    const [start, end] = dates;
    setVehicleStartDate(start);
    setVehicleEndDate(end);
  };

  const vpaRefresh = () => {
    setVehicleStartDate(null);
    setVehicleEndDate(null);
    document.getElementById("vpacalender").style.display = "none";
    document.getElementById("vpacalendericon").style.display = "block";
    document.getElementById("vpacalendericon").style.justifyContent = "center";
    document.getElementById("vpacalendericon").style.textAlign = "center";
  };
  const shows = () => {
    document.getElementById("vpacalender").style.display = "block";
    document.getElementById("vpacalendericon").style.display = "none";
    setCalenderOpen(true);
    on();
  };

  // Spare Pricing (Approval)
  const onSpareChange = (dates) => {
    const [start, end] = dates;
    setSpareStartDate(start);
    setSpareEndDate(end);
  };
  const spareRefresh = () => {
    setSpareStartDate(null);
    setSpareEndDate(null);
    document.getElementById("sparecalender").style.display = "none";
    document.getElementById("sparecalendericon").style.display = "block";
    document.getElementById("sparecalendericon").style.justifyContent =
      "center";
    document.getElementById("sparecalendericon").style.textAlign = "center";
  };
  const display = () => {
    document.getElementById("sparecalender").style.display = "block";
    document.getElementById("sparecalendericon").style.display = "none";
    setCalenderOpen(true);
    on();
  };

  // Vehicle Verification (QC)
  const onQcVehicleChange = (dates) => {
    const [start, end] = dates;
    setqcVehicleStartDate(start);
    setqcVehicleEndDate(end);
  };

  const vehicleRefresh = () => {
    setqcVehicleStartDate(null);
    setqcVehicleEndDate(null);
    document.getElementById("vehiclecalender").style.display = "none";
    document.getElementById("vehiclecalendericon").style.display = "block";
    document.getElementById("vehiclecalendericon").style.justifyContent =
      "center";
    document.getElementById("vehiclecalendericon").style.textAlign = "center";
  };
  const visible = () => {
    document.getElementById("vehiclecalender").style.display = "block";
    document.getElementById("vehiclecalendericon").style.display = "none";
    setCalenderOpen(true);
    on();
  };

  // Spare Verification (QC)
  const onSpareVerificationChange = (dates) => {
    const [start, end] = dates;
    setSpareVerificationStartDate(start);
    setSpareVerificationEndDate(end);
  };

  const verification = () => {
    setSpareVerificationStartDate(null);
    setSpareVerificationEndDate(null);
    document.getElementById("verificationcalender").style.display = "none";
    document.getElementById("verificationcalendericon").style.display = "block";
    document.getElementById("verificationcalendericon").style.justifyContent =
      "center";
    document.getElementById("verificationcalendericon").style.textAlign =
      "center";
  };
  const visibility = () => {
    document.getElementById("verificationcalender").style.display = "block";
    document.getElementById("verificationcalendericon").style.display = "none";
    setCalenderOpen(true);
    on();
  };

  // Vehicle Re-Verification (QC)
  const onVehicleReChange = (dates) => {
    const [start, end] = dates;
    setVehicleReStartDate(start);
    setVehicleReEndDate(end);
  };

  const reVerificationRefresh = () => {
    setVehicleReStartDate(null);
    setVehicleReEndDate(null);
    document.getElementById("reverificationcalender").style.display = "none";
    document.getElementById("reverificationcalendericon").style.display =
      "block";
    document.getElementById("reverificationcalendericon").style.justifyContent =
      "center";
    document.getElementById("reverificationcalendericon").style.textAlign =
      "center";
  };
  const appear = () => {
    document.getElementById("reverificationcalender").style.display = "block";
    document.getElementById("reverificationcalendericon").style.display =
      "none";
    setCalenderOpen(true);
    on();
  };

  // Purchase Order Data
  const fetchReport = async () => {
    const result = await trackPromise(API.get(`management/api/v1/poOrder`));
    setPurchaseData(result);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  //   GRN Data
  const fetchData = async () => {
    const result = await trackPromise(API.get(`management/api/v1/grn`));
    setGrnData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const history = useHistory();

  return (
    <>
      {openBomCostTable == true ? (
        <BomNewRequests inDashboard={setOpenBomCostTable} />
      ) : openVehiclePriceTable == true ? (
        <VP_NewRequests inDashboard={setOpenVehiclePriceTable} />
      ) : openSparePriceTable == true ? (
        <SPP_NewRequests inDashboard={setOpenSparePriceTable} />
      ) : openQcCompTable == true ? (
        <ComponentQC inDashboard={setOpenQcCompTable} />
      ) : openQcVehicleTable == true ? (
        <VehicleQC inDashboard={setOpenQcVehicleTable} />
      ) : openPoTable == true ? (
        <PurchaseOrder inDashboard={setOpenPoTable} />
      ) : openGRNTable == true ? (
        <GRN inDashboard={setOpenGRNTable} />
      ) : openRAWTable == true ? (
        <RawInventory inDashboard={setOpenRAWTable} />
      ) : openMROTable == true ? (
        <MROInventory inDashboard={setOpenMROTable} />
      ) : openStandardTable == true ? (
        <Standard inDashboard={setOpenStandardTable} />
      ) : openHybridTable == true ? (
        <Hybrid inDashboard={setOpenHybridTable} />
      ) : (
        <>
          <>
            <>
              <>
                <div id="overlay" onClick={(e) => off(e)}></div>
                <div
                  className="thirdBox"
                  style={{
                    height: "100%",
                    marginTop: "10px",
                    padding: "23px 0px",
                  }}
                >
                  <div
                    className="header"
                    style={{
                      width: "95%",
                      marginLeft: "20px",
                      marginBottom: "40px",
                    }}
                  >
                    <h5 style={{ fontSize: "18px" }}>Approval</h5>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      className="pricing"
                      style={{ height: "265px", marginTop: "-30px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          flexDirection: "row",
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginTop: "-100px",
                        }}
                      >
                        <div style={{ width: "30%" }}>
                          <h5 style={{ fontSize: "14px" }}>BOM Costing</h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            width: "70%",
                          }}
                        >
                          {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }}
                            onClick={refresh}
                          >
                            <FaRedoAlt />
                          </div> */}
                          {/* <span
                            id="calendericon"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }}
                            onClick={show}
                          >
                            <FontAwesomeIcon
                              icon={faCalendar}
                              style={{ color: "#808080" }}
                            />
                          </span> */}
                          
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <BsArrowUpRight
                              onClick={() => setOpenBomCostTable(true)}
                            />
                          </div>
                        </div>
                        <br />
                      </div>
                      <div
                        id="calender"
                        style={{
                          width: "auto",
                          display: "none",
                          width: "97%",
                          justifyContent: "end",
                          textAlign: "end",
                          marginTop: "7px",
                        }}
                      >
                        <Space
                          style={{ width: "235px"}}
                          direction="vertical"
                          size={10}
                        >
                          <RangePicker
                            startDate={startDate}
                            endDate={endDate}
                            value={[startDate, endDate]}
                            onChange={onChange}
                            format="DD-MM-YYYY"
                            size="small"
                            className="custom-range-picker"
                          />
                        </Space>
                      </div>
                      <br />
                      <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                        <ApprovalProgressBar
                          props="bom"
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                    </div>
                    <div
                      className="pricing"
                      style={{ height: "265px", marginTop: "-30px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginTop: "-100px",
                        }}
                      >
                        <div style={{ width: "30%" }}>
                          <h5 style={{ fontSize: "14px" }}>Vehicle Pricing</h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            width: "70%",
                          }}
                        >
                          {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }}
                            onClick={vpaRefresh}
                          >
                            <FaRedoAlt />
                          </div> */}
                          {/* <span
                            id="vpacalendericon"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }}
                            onClick={shows}
                          >
                            <FontAwesomeIcon
                              icon={faCalendar}
                              style={{ color: "#808080" }}
                            />
                          </span> */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <BsArrowUpRight
                              onClick={() => setOpenVehiclePriceTable(true)}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        id="vpacalender"
                        style={{
                          width: "auto",
                          display: "none",
                          width: "97%",
                          justifyContent: "end",
                          textAlign: "end",
                          marginTop: "7px",
                        }}
                      >
                        <Space
                          style={{ width: "235px" }}
                          direction="vertical"
                          size={10}
                        >
                          <RangePicker
                            startDate={vehicleStartDate}
                            endDate={vehicleEndDate}
                            value={[vehicleStartDate, vehicleEndDate]}
                            onChange={onVehicleChange}
                            format="DD-MM-YYYY"
                            size="small"
                            className="custom-range-picker"
                          />
                        </Space>
                      </div>
                      <br />
                      <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                        <ApprovalProgressBar
                          props="vehicle"
                          startDate={vehicleStartDate}
                          endDate={vehicleEndDate}
                        />
                      </div>
                    </div>
                    <div
                      className="pricing"
                      style={{ height: "265px", marginTop: "-30px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          flexDirection: "row",
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginTop: "-100px",
                        }}
                      >
                        <div style={{ width: "30%" }}>
                          <h5 style={{ fontSize: "14px" }}>Spare Pricing</h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            width: "70%",
                          }}
                        >
                          {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }}
                            onClick={spareRefresh}
                          >
                            <FaRedoAlt />
                          </div> */}
                          {/* <span
                            id="sparecalendericon"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }}
                            onClick={display}
                          >
                            <FontAwesomeIcon
                              icon={faCalendar}
                              style={{ color: "#808080" }}
                            />
                          </span> */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "25px",
                              height: "23.4px",
                              border: "1px Solid #808080",
                              color: "#808080",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <BsArrowUpRight
                              onClick={() => setOpenSparePriceTable(true)}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        id="sparecalender"
                        style={{
                          width: "auto",
                          display: "none",
                          width: "97%",
                          justifyContent: "end",
                          textAlign: "end",
                          marginTop: "7px",
                        }}
                      >
                        <Space
                          style={{ width: "235px" }}
                          direction="vertical"
                          size={10}
                        >
                          <RangePicker
                            startDate={spareStartDate}
                            endDate={spareEndDate}
                            value={[spareStartDate, spareEndDate]}
                            onChange={onSpareChange}
                            format="DD-MM-YYYY"
                            size="small"
                            className="custom-range-picker"
                          />
                        </Space>
                      </div>
                      <br />
                      <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                        <ApprovalProgressBar
                          props="spare"
                          startDate={spareStartDate}
                          endDate={spareEndDate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <div className="secondRow">
                <div
                  className="container-new"
                  style={{ width: "100%", height: "292px" }}
                >
                  <div
                    className="header"
                    style={{
                      marginTop: "10px",
                      marginLeft: "10px",
                      width: "98%",
                    }}
                  >
                    <div>
                      <h5 style={{ fontSize: "18px" }}>Work Orders</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "35%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => history.push("/app/workorder-qc")}
                        />
                      </div>
                    </div>
                  </div>
                  {/* //same component for shop Floor WO */}
                  <WorkOrderDashboard woData="management" />
                </div>
              </div>
            </>
            <div className="lastBox" style={{ height: "auto" }}>
              <div
                className="header"
                style={{
                  width: "95%",
                  marginTop: "10px",
                  marginLeft: "20px",
                }}
              >
                <h5 style={{ fontSize: "18px" }}>Quality Control</h5>
              </div>
              <div
                className="outerBox"
                style={{
                  flexDirection: "column",
                  margin: "0px,15px",
                  padding: "0px 23px 23px 23px",
                }}
              >
                <div className="firstRow">
                  <div className="container-new">
                    <div
                      className="header"
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        width: "95%",
                      }}
                    >
                      <div>
                        <h5 style={{ fontSize: "18px" }}>
                          Components Verification
                        </h5>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          width: "40%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <BsArrowUpRight
                            onClick={() => setOpenQcCompTable(true)}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <ComponentVerification />
                    </div>
                  </div>
                  <div className="container-new" style={{ marginLeft: "20px" }}>
                    <div
                      className="header"
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        width: "95%",
                      }}
                    >
                      <div>
                        <h5 style={{ fontSize: "18px" }}>
                          Vehicle Verification
                        </h5>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          width: "65%",
                          marginRight: "-16px",
                        }}
                      >
                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                          onClick={vehicleRefresh}
                        >
                          <FaRedoAlt />
                        </div> */}
                        {/* <span
                          id="vehiclecalendericon"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                          onClick={visible}
                        >
                          <FontAwesomeIcon
                            icon={faCalendar}
                            style={{ color: "#808080" }}
                          />
                        </span> */}
                        <label
                          id="vehiclecalender"
                          style={{
                            width: "auto",
                            marginRight: "5px",
                            display: "none",
                          }}
                        >
                          <Space
                            style={{ width: "235px" }}
                            direction="vertical"
                            size={10}
                          >
                            <RangePicker
                              startDate={qcVehicleStartDate}
                              endDate={qcVehicleEndDate}
                              value={[qcVehicleStartDate, qcVehicleEndDate]}
                              onChange={onQcVehicleChange}
                              format="DD-MM-YYYY"
                              size="small"
                              className="custom-range-picker"
                            />
                          </Space>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <BsArrowUpRight
                            onClick={() => setOpenQcVehicleTable(true)}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <VehicleBarChart
                        props="qc"
                        bomStartDate={qcVehicleStartDate}
                        bomEndDate={qcVehicleEndDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="secondRow">
                  <div className="container-new">
                    <div
                      className="header"
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        width: "95%",
                      }}
                    >
                      <div>
                        <h5 style={{ fontSize: "18px" }}>
                          Spare Re-verification
                        </h5>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          width: "65%",
                          marginRight: "-16px",
                        }}
                      >
                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                          onClick={verification}
                        >
                          <FaRedoAlt />
                        </div> */}
                        {/* <span
                          id="verificationcalendericon"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                          onClick={visibility}
                        >
                          <FontAwesomeIcon
                            icon={faCalendar}
                            style={{ color: "#808080" }}
                          />
                        </span> */}
                        <label
                          id="verificationcalender"
                          style={{
                            width: "auto",
                            marginRight: "5px",
                            display: "none",
                          }}
                        >
                          <Space
                            style={{ width: "235px" }}
                            direction="vertical"
                            size={10}
                          >
                            <RangePicker
                              startDate={spareVerificationStartDate}
                              endDate={spareVerificationEndDate}
                              value={[spareVerificationStartDate, spareVerificationEndDate]}
                              onChange={onSpareVerificationChange}
                              format="DD-MM-YYYY"
                              size="small"
                              className="custom-range-picker"
                            />
                          </Space>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <BsArrowUpRight
                            onClick={() => setOpenQcCompTable(true)}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div style={{ overflow: "auto" }}>
                      <SpareReVerification
                        startDate={spareVerificationStartDate}
                        endDate={spareVerificationEndDate}
                      />
                    </div>
                  </div>
                  <div className="container-new" style={{ marginLeft: "20px" }}>
                    <div
                      className="header"
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        width: "98%",
                      }}
                    >
                      <div>
                        <h5 style={{ fontSize: "18px" }}>
                          Vehicle Re-verification
                        </h5>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          width: "61%",
                        }}
                      >
                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                          onClick={reVerificationRefresh}
                        >
                          <FaRedoAlt />
                        </div> */}
                        {/* <span
                          id="reverificationcalendericon"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                          onClick={appear}
                        >
                          <FontAwesomeIcon
                            icon={faCalendar}
                            style={{ color: "#808080" }}
                          />
                        </span> */}
                        <label
                          id="reverificationcalender"
                          style={{
                            width: "auto",
                            marginRight: "5px",
                            display: "none",
                          }}
                        >
                          <Space
                            style={{ width: "234px" }}
                            direction="vertical"
                            size={10}
                          >
                            <RangePicker
                              startDate={vehicleReStartDate}
                              endDate={vehicleReEndDate}
                              value={[vehicleReStartDate, vehicleReEndDate]}
                              onChange={onVehicleReChange}
                              format="DD-MM-YYYY"
                              size="small"
                              className="custom-range-picker"
                            />
                          </Space>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <BsArrowUpRight
                            onClick={() => setOpenQcVehicleTable(true)}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <VehicleBarChart
                        props="qc"
                        bomStartDate={vehicleReStartDate}
                        bomEndDate={vehicleReEndDate}
                      />
                    </div>
                    {/* <div>
                      <VehicleReBarChart
                        props="qc"
                        startDate={vehicleReStartDate}
                        endDate={vehicleReEndDate}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </>
          <div
            className="lastBox"
            style={{ height: "auto", padding: "0px 0px 23px 0px" }}
          >
            <div
              className="header"
              style={{
                width: "95%",
                marginTop: "10px",
                marginLeft: "20px",
                marginBottom: "40px",
              }}
            >
              <h5 style={{ fontSize: "18px" }}>Inventory</h5>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                marginTop: "-25px",
              }}
            >
              {/* Purchase Order */}
              <div
                className="container-new"
                style={{ margin: " 0px 0px 0px 10px", width: "65%" }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Purchase Order</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "30%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight onClick={() => setOpenPoTable(true)} />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                  }}
                >
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>Total PO Requests</span>
                    <span style={{ fontWeight: "420" }}>
                      {purchaseData.totalPOrequest}
                    </span>
                  </div>
                  <div className="innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Pending at Finance
                    </span>
                    <span style={{ fontWeight: "420" }}>
                      {purchaseData.pendingRequest}
                    </span>
                  </div>
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>Hold by Finance</span>
                    <span style={{ fontWeight: "420" }}>
                      {purchaseData.holdRequest}
                    </span>
                  </div>
                  <div className="innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Approved by Finance
                    </span>
                    <span style={{ fontWeight: "420" }}>
                      {purchaseData.approveRequest}
                    </span>
                  </div>
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>Place to Vendor</span>
                    <span style={{ fontWeight: "420" }}>
                      {purchaseData.placeToVendor}
                    </span>
                  </div>
                  <div className="innerDiv">
                    <span style={{ fontWeight: "420" }}>Rejected PO</span>
                    <span style={{ fontWeight: "420" }}>
                      {purchaseData.rejectRequest}
                    </span>
                  </div>
                </div>
              </div>
              {/* GRN */}
              <div
                className="container-new"
                style={{ margin: " 0px 11px 0px 10px", width: "35%" }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>GRN</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "50%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight onClick={() => setOpenGRNTable(true)} />
                    </div>
                  </div>
                </div>
                <img
                  src={Group9}
                  style={{
                    marginTop: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                  alt="group"
                />
                <div
                  style={{
                    color: "#808080",
                    fontSize: "14px",
                    textAlign: "center",
                    margin: "10px 6px 0px 6px",
                    borderBottom: "2px solid #F2F2F2",
                  }}
                >
                  Total WO
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    marginLeft: "15px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "24px",
                      fontWeight: "450",
                      marginTop: "15px",
                    }}
                  >
                    {grn.totalRequest}&nbsp;{" "}
                    {/* <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#11781B",
                      }}
                    >
                      {grn.totalRequestChange > 0 ? (
                        <>
                          <img src={Vector} alt="vector" />
                          <span
                            style={{ fontSize: "14px", color: "green" }}
                          >{`+${grn.totalRequestChange}`}</span>
                        </>
                      ) : grn.totalRequestChange === 0 ? (
                        <>
                          <img src={Vector} alt="vector" />
                          <span
                            style={{ fontSize: "14px", color: "green" }}
                          >{`+${grn.totalRequestChange}`}</span>
                        </>
                      ) : (
                        <>
                          <img src={Vector3} alt="vector" />
                          <span
                            style={{ fontSize: "14px", color: "red" }}
                          >{`${grn.totalRequestChange}`}</span>
                        </>
                      )}
                    </span> */}
                  </div>
                </div>
                <div
                  style={{
                    width: "90%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <ProgressBar>
                    <ProgressBar now={grn.totalRequest * 100} />
                  </ProgressBar>
                </div>
              </div>
            </div>

            <div className="secondRow">
              {/* Raw Inventory */}
              <div
                className="container-new"
                style={{ width: "50%", height: "250px", marginLeft: "10px" }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Raw Inventory</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight onClick={() => setOpenRAWTable(true)} />
                    </div>
                  </div>
                </div>
                <div>
                  <ManagementRawBox props="raw" />
                </div>
              </div>
              {/* MRO Inventory */}
              <div
                className="container-new"
                style={{
                  margin: "0px 10px 0px 10px",
                  width: "50%",
                  height: "250px",
                }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "97%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>MRO Inventory</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight onClick={() => setOpenMROTable(true)} />
                    </div>
                  </div>
                </div>
                <div>
                  <ManagementRawBox props="mro" />
                </div>
              </div>
            </div>
            <div className="secondRow">
              {/* Finished Goods (Standard) */}
              <div
                className="container-new"
                style={{ width: "50%", height: "250px", marginLeft: "10px" }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>
                      Finished Goods (Standard)
                    </h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => setOpenStandardTable(true)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <FinishedGoodsBox props="Standard" />
                </div>
              </div>
              {/* Finished Goods (Hybrid) */}
              <div
                className="container-new"
                style={{
                  margin: "0px 10px 0px 10px",
                  width: "50%",
                  height: "250px",
                }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "97%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>
                      Finished Goods (Hybrid)
                    </h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => setOpenHybridTable(true)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <FinishedGoodsBox props="Hybrid" />
                </div>
              </div>
            </div>
          </div>
          <div className="thirdBox" style={{ height: "70%" }}>
            <div
              className="header"
              style={{
                width: "95%",
                marginTop: "10px",
                marginLeft: "20px",
                marginBottom: "40px",
              }}
            >
              <h5 style={{ fontSize: "18px" }}>Sales</h5>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                marginTop: "-25px",
              }}
            >
              <div
                className="container-new"
                style={{
                  margin: " 0px 0px 0px 10px",
                  width: "50%",
                  height: "290px",
                }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Vehicles</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "30%",
                    }}
                  >
                    {/* <select
                      style={{
                        height: "23.4px",
                        borderRadius: "5px",
                        background: "#fff",
                        outline: "none",
                      }}
                    >
                      <option selected value="Month">
                        Month
                      </option>
                      <option value="week">Week</option>
                      <option value="Day">Day</option>
                    </select> */}
                    {/* <div
                      onClick={() => console.log("hi this is table")}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#808080",
                        cursor: "pointer",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        borderRadius: "5px",
                        margin: "0px 2px",
                      }}
                    >
                      <BsTable />
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                      }}
                    >
                      <BsArrowUpRight />
                    </div>
                    {/* <button
                      className="action-btn"
                      style={{ height: "23.4px", color: "#808080" }}
                    >
                      <FontAwesomeIcon className="fa-lg" icon={faEllipsisV} />
                    </button> */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "430",
                    margin: "10px 0px 0px 12px",
                    padding: "10px",
                    borderBottom: "2px solid rgb(242, 242, 242)",
                    width: "95%",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img style={{ marginTop: "-10px" }} src={Group18} />
                    &nbsp;&nbsp;
                    <span style={{ width: "130px", marginTop: "4px" }}>
                      Total Sold Units
                    </span>
                  </div>
                  <div style={{ fontSize: "26px", marginTop: "-5px" }}>
                    1568
                  </div>
                </div>

                <div
                  style={{ display: "flex", marginTop: "20px", padding: "6px" }}
                >
                  <div
                    className="bussLoc-box"
                    style={{ width: "50%", marginRight: "5px", padding: "5px" }}
                  >
                    <div className="header">
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#3442CF",
                        }}
                      >
                        Standard Vehicle
                      </span>
                    </div>
                    <div style={{ display: "flex", padding: "6px" }}>
                      <div
                        style={{
                          width: "50%",
                          borderRight: "2px solid #F2F2F2",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          956
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Sold
                        </div>
                      </div>

                      <div style={{ width: "50%" }}>
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          18,27,455
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Value
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="bussLoc-box"
                    style={{ width: "50%", padding: "5px" }}
                  >
                    <div className="header">
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#3442CF",
                        }}
                      >
                        Hybrid Vehicle
                      </span>
                    </div>
                    <div style={{ display: "flex", padding: "6px" }}>
                      <div
                        style={{
                          width: "50%",
                          borderRight: "2px solid #F2F2F2",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          685
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Sold
                        </div>
                      </div>

                      <div style={{ width: "50%" }}>
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          18,27,455
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Value
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="container-new"
                style={{
                  margin: " 0px 11px 0px 10px",
                  width: "50%",
                  height: "290px",
                }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Spares</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "50%",
                    }}
                  >
                    {/* <select
                      style={{
                        height: "23.4px",
                        borderRadius: "5px",
                        background: "#fff",
                        outline: "none",
                      }}
                    >
                      <option selected value="Month">
                        Month
                      </option>
                      <option value="week">Week</option>
                      <option value="Day">Day</option>
                    </select> */}
                    {/* <div
                      onClick={() => console.log("hi this is table")}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#808080",
                        cursor: "pointer",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        borderRadius: "5px",
                        margin: "0px 2px",
                      }}
                    >
                      <BsTable />
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                      }}
                    >
                      <BsArrowUpRight />
                    </div>
                    {/* <button
                      className="action-btn"
                      style={{ height: "23.4px", color: "#808080" }}
                    >
                      <FontAwesomeIcon className="fa-lg" icon={faEllipsisV} />
                    </button> */}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "430",
                    margin: "10px 0px 0px 12px",
                    padding: "10px",
                    borderBottom: "2px solid rgb(242, 242, 242)",
                    width: "95%",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img style={{ marginTop: "-10px" }} src={Group} />
                    &nbsp;&nbsp;
                    <span style={{ width: "130px", marginTop: "4px" }}>
                      Total Sold Spares
                    </span>
                  </div>
                  <div style={{ fontSize: "26px", marginTop: "-5px" }}>
                    1568
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "430",
                    margin: "25px 0px 0px 12px",
                    padding: "10px",
                    borderLeft: "8px solid #3442CF",
                    width: "95%",
                    height: "50px",
                    boxShadow: "0px 0px 12px rgb(0 0 0 / 15%)",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ width: "130px", marginTop: "4px" }}>
                      Total Unit Sold
                    </span>
                  </div>
                  <div style={{ fontSize: "26px" }}>9587</div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "430",
                    margin: "10px 0px 0px 12px",
                    padding: "10px",
                    borderLeft: "8px solid #21A82E",
                    width: "95%",
                    height: "50px",
                    boxShadow: "0px 0px 12px rgb(0 0 0 / 15%)",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ width: "130px", marginTop: "4px" }}>
                      Total Value
                    </span>
                  </div>
                  <div style={{ fontSize: "26px" }}>18,74,999</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="lastBox" style={{ height: "1100px" }}>
            <div
              className="header"
              style={{
                width: "95%",
                marginTop: "10px",
                marginLeft: "20px",
                marginBottom: "40px",
              }}
            >
              <h5 style={{ fontSize: "18px" }}>Business Entity</h5>
            </div>
            <div className="secondRow">
              <div
                className="container-new"
                style={{ margin: "-58px 0px 0px 10px", height: "280px" }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Dealers</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <select
                      style={{
                        height: "23.4px",
                        borderRadius: "5px",
                        background: "#fff",
                        outline: "none",
                      }}
                    >
                      <option selected value="Month">
                        Month
                      </option>
                      <option value="week">Week</option>
                      <option value="Day">Day</option>
                    </select>
                    <div
                      onClick={() => console.log("hi this is table")}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#808080",
                        cursor: "pointer",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        borderRadius: "5px",
                        margin: "0px 2px",
                      }}
                    >
                      <BsTable />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                      }}
                    >
                      <BsArrowUpRight />
                    </div>
                    <button
                      className="action-btn"
                      style={{ height: "23.4px", color: "#808080" }}
                    >
                      <FontAwesomeIcon className="fa-lg" icon={faEllipsisV} />
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                  }}
                >
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>Total Dealers</span>
                    <span style={{ fontWeight: "420" }}>06</span>
                  </div>
                  <div className="innerDiv">
                    <span style={{ fontWeight: "420" }}>Active Dealers</span>
                    <span style={{ fontWeight: "420" }}>06</span>
                  </div>
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Deactivated Dealers
                    </span>
                    <span style={{ fontWeight: "420" }}>18</span>
                  </div>
                  <div className="innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Reactivated Dealers
                    </span>
                    <span style={{ fontWeight: "420" }}>02</span>
                  </div>
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>Deleted Dealers</span>
                    <span style={{ fontWeight: "420" }}>20</span>
                  </div>
                </div>
              </div>

              <div
                className="container-new"
                style={{ margin: "-58px 10px 0px 20px", height: "280px" }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Showrooms</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <select
                      style={{
                        height: "23.4px",
                        borderRadius: "5px",
                        background: "#fff",
                        outline: "none",
                      }}
                    >
                      <option selected value="Month">
                        Month
                      </option>
                      <option value="week">Week</option>
                      <option value="Day">Day</option>
                    </select>
                    <div
                      onClick={() => console.log("hi this is table")}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#808080",
                        cursor: "pointer",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        borderRadius: "5px",
                        margin: "0px 2px",
                      }}
                    >
                      <BsTable />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                      }}
                    >
                      <BsArrowUpRight />
                    </div>
                    <button
                      className="action-btn"
                      style={{ height: "23.4px", color: "#808080" }}
                    >
                      <FontAwesomeIcon className="fa-lg" icon={faEllipsisV} />
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                  }}
                >
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>Total Showrooms</span>
                    <span style={{ fontWeight: "420" }}>06</span>
                  </div>
                  <div className="innerDiv">
                    <span style={{ fontWeight: "420" }}>Active Showrooms</span>
                    <span style={{ fontWeight: "420" }}>06</span>
                  </div>
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Deactivated Showrooms
                    </span>
                    <span style={{ fontWeight: "420" }}>18</span>
                  </div>
                  <div className="innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Reactivated Showrooms
                    </span>
                    <span style={{ fontWeight: "420" }}>02</span>
                  </div>
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>Deleted Showrooms</span>
                    <span style={{ fontWeight: "420" }}>20</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="secondRow">
              <div
                className="container-new"
                style={{ margin: "0px 10px 0px 10px", height: "280px" }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Service Centers</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <select
                      style={{
                        height: "23.4px",
                        borderRadius: "5px",
                        background: "#fff",
                        outline: "none",
                      }}
                    >
                      <option selected value="Month">
                        Month
                      </option>
                      <option value="week">Week</option>
                      <option value="Day">Day</option>
                    </select>
                    <div
                      onClick={() => console.log("hi this is table")}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#808080",
                        cursor: "pointer",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        borderRadius: "5px",
                        margin: "0px 2px",
                      }}
                    >
                      <BsTable />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                      }}
                    >
                      <BsArrowUpRight />
                    </div>
                    <button
                      className="action-btn"
                      style={{ height: "23.4px", color: "#808080" }}
                    >
                      <FontAwesomeIcon className="fa-lg" icon={faEllipsisV} />
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                  }}
                >
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Total Service Centers
                    </span>
                    <span style={{ fontWeight: "420" }}>06</span>
                  </div>
                  <div className="innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Active Service Centers
                    </span>
                    <span style={{ fontWeight: "420" }}>06</span>
                  </div>
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Deactivated Service Centers
                    </span>
                    <span style={{ fontWeight: "420" }}>18</span>
                  </div>
                  <div className="innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Reactivated Service Centers
                    </span>
                    <span style={{ fontWeight: "420" }}>02</span>
                  </div>
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Deleted Service Centers
                    </span>
                    <span style={{ fontWeight: "420" }}>20</span>
                  </div>
                </div>
              </div>

              <div
                className="container-new"
                style={{ margin: "0px 10px 0px 0px", height: "280px" }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Orders</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <select
                      style={{
                        height: "23.4px",
                        borderRadius: "5px",
                        background: "#fff",
                        outline: "none",
                      }}
                    >
                      <option selected value="Month">
                        Month
                      </option>
                      <option value="week">Week</option>
                      <option value="Day">Day</option>
                    </select>
                    <div
                      onClick={() => console.log("hi this is table")}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#808080",
                        cursor: "pointer",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        borderRadius: "5px",
                        margin: "0px 2px",
                      }}
                    >
                      <BsTable />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                      }}
                    >
                      <BsArrowUpRight />
                    </div>
                    <button
                      className="action-btn"
                      style={{ height: "23.4px", color: "#808080" }}
                    >
                      <FontAwesomeIcon className="fa-lg" icon={faEllipsisV} />
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                  }}
                >
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>Total Orders</span>
                    <span style={{ fontWeight: "420" }}>06</span>
                  </div>
                  <div className="innerDiv">
                    <span style={{ fontWeight: "420" }}>Active Orders</span>
                    <span style={{ fontWeight: "420" }}>06</span>
                  </div>
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Deactivated Orders
                    </span>
                    <span style={{ fontWeight: "420" }}>18</span>
                  </div>
                  <div className="innerDiv">
                    <span style={{ fontWeight: "420" }}>
                      Reactivated Orders
                    </span>
                    <span style={{ fontWeight: "420" }}>02</span>
                  </div>
                  <div className="assemblyCard innerDiv">
                    <span style={{ fontWeight: "420" }}>Deleted Orders</span>
                    <span style={{ fontWeight: "420" }}>20</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                marginTop: "-25px",
              }}
            >
              <div
                className="container-new"
                style={{
                  margin: " 50px 0px 0px 10px",
                  width: "33%",
                  height: "340px",
                }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Inventory</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "50%",
                    }}
                  >
                    <select
                      style={{
                        height: "23.4px",
                        borderRadius: "5px",
                        background: "#fff",
                        outline: "none",
                      }}
                    >
                      <option selected value="Month">
                        Month
                      </option>
                      <option value="week">Week</option>
                      <option value="Day">Day</option>
                    </select>
                    <div
                      onClick={() => console.log("hi this is table")}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#808080",
                        cursor: "pointer",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        borderRadius: "5px",
                        margin: "0px 2px",
                      }}
                    >
                      <BsTable />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                      }}
                    >
                      <BsArrowUpRight />
                    </div>
                    <button
                      className="action-btn"
                      style={{ height: "23.4px", color: "#808080" }}
                    >
                      <FontAwesomeIcon className="fa-lg" icon={faEllipsisV} />
                    </button>
                  </div>
                </div>

                <div style={{ marginTop: "20px", padding: "10px" }}>
                  <div className="bussLoc-box" style={{ padding: "5px" }}>
                    <div className="header">
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#3442CF",
                        }}
                      >
                        Total Vehicle Inventory
                      </span>
                    </div>
                    <div style={{ display: "flex", padding: "6px" }}>
                      <div
                        style={{
                          width: "50%",
                          borderRight: "2px solid #F2F2F2",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          956
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Vehicles
                        </div>
                      </div>

                      <div style={{ width: "50%" }}>
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          18,27,455
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Value
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="bussLoc-box"
                    style={{ padding: "5px", marginTop: "10px" }}
                  >
                    <div className="header">
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#3442CF",
                        }}
                      >
                        Total Spare Inventory
                      </span>
                    </div>
                    <div style={{ display: "flex", padding: "6px" }}>
                      <div
                        style={{
                          width: "50%",
                          borderRight: "2px solid #F2F2F2",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          685
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Units
                        </div>
                      </div>

                      <div style={{ width: "50%" }}>
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          18,27,455
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Value
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="container-new"
                style={{
                  margin: " 50px 0px 0px 10px",
                  width: "33%",
                  height: "340px",
                }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Return</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "50%",
                    }}
                  >
                    <select
                      style={{
                        height: "23.4px",
                        borderRadius: "5px",
                        background: "#fff",
                        outline: "none",
                      }}
                    >
                      <option selected value="Month">
                        Month
                      </option>
                      <option value="week">Week</option>
                      <option value="Day">Day</option>
                    </select>
                    <div
                      onClick={() => console.log("hi this is table")}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#808080",
                        cursor: "pointer",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        borderRadius: "5px",
                        margin: "0px 2px",
                      }}
                    >
                      <BsTable />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                      }}
                    >
                      <BsArrowUpRight />
                    </div>
                    <button
                      className="action-btn"
                      style={{ height: "23.4px", color: "#808080" }}
                    >
                      <FontAwesomeIcon className="fa-lg" icon={faEllipsisV} />
                    </button>
                  </div>
                </div>

                <div style={{ marginTop: "20px", padding: "10px" }}>
                  <div className="bussLoc-box" style={{ padding: "5px" }}>
                    <div className="header">
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#3442CF",
                        }}
                      >
                        Total Return Vehicle
                      </span>
                    </div>
                    <div style={{ display: "flex", padding: "6px" }}>
                      <div
                        style={{
                          width: "50%",
                          borderRight: "2px solid #F2F2F2",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          956
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Vehicles
                        </div>
                      </div>

                      <div style={{ width: "50%" }}>
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          18,27,455
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Value
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="bussLoc-box"
                    style={{ padding: "5px", marginTop: "10px" }}
                  >
                    <div className="header">
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#3442CF",
                        }}
                      >
                        Total Return Spares
                      </span>
                    </div>
                    <div style={{ display: "flex", padding: "6px" }}>
                      <div
                        style={{
                          width: "50%",
                          borderRight: "2px solid #F2F2F2",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          685
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Units
                        </div>
                      </div>

                      <div style={{ width: "50%" }}>
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          18,27,455
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Value
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="container-new"
                style={{
                  margin: " 50px 0px 0px 10px",
                  width: "33%",
                  height: "340px",
                }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Sales</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "50%",
                    }}
                  >
                    <select
                      style={{
                        height: "23.4px",
                        borderRadius: "5px",
                        background: "#fff",
                        outline: "none",
                      }}
                    >
                      <option selected value="Month">
                        Month
                      </option>
                      <option value="week">Week</option>
                      <option value="Day">Day</option>
                    </select>
                    <div
                      onClick={() => console.log("hi this is table")}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#808080",
                        cursor: "pointer",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        borderRadius: "5px",
                        margin: "0px 2px",
                      }}
                    >
                      <BsTable />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                      }}
                    >
                      <BsArrowUpRight />
                    </div>
                    <button
                      className="action-btn"
                      style={{ height: "23.4px", color: "#808080" }}
                    >
                      <FontAwesomeIcon className="fa-lg" icon={faEllipsisV} />
                    </button>
                  </div>
                </div>

                <div style={{ marginTop: "20px", padding: "10px" }}>
                  <div className="bussLoc-box" style={{ padding: "5px" }}>
                    <div className="header">
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#3442CF",
                        }}
                      >
                        Total Sold Vehicle
                      </span>
                    </div>
                    <div style={{ display: "flex", padding: "6px" }}>
                      <div
                        style={{
                          width: "50%",
                          borderRight: "2px solid #F2F2F2",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          956
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Vehicles
                        </div>
                      </div>

                      <div style={{ width: "50%" }}>
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          18,27,455
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Value
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="bussLoc-box"
                    style={{ padding: "5px", marginTop: "10px" }}
                  >
                    <div className="header">
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#3442CF",
                        }}
                      >
                        Total Sold Spares
                      </span>
                    </div>
                    <div style={{ display: "flex", padding: "6px" }}>
                      <div
                        style={{
                          width: "50%",
                          borderRight: "2px solid #F2F2F2",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          685
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Units
                        </div>
                      </div>

                      <div style={{ width: "50%" }}>
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          18,27,455
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="grey-heading"
                        >
                          Total Value
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
    </>
  );
}

export default ManagementDash;
