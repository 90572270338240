import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import moment from "moment";

const GINno = ({ closeModal, rowData }) => {
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "580px", height: "425px", padding: '15px' }}
            >
                <div className="header">
                    <h3>GIN Details</h3>
                </div>

                <div
                    className="body"
                    style={{ display: "flex", fontSize: "14px" }}
                >


                    <section
                        style={{
                            width: '100%',
                            textAlign: "left",
                            fontSize: "14px",
                            margin: "10px 0",
                        }}
                    >
                        <div>GIN No. : {rowData.ginNo}</div>
                        <div>GIN Date: {moment(rowData.ginDate).format("DD-MMM-YYYY/HH:mm")}</div>
                        <div>GIN Issued By: <span style={{ fontWeight: '100', color: "#0a18a3" }}>{rowData.showroomName}</span></div>

                    </section>

                    <section
                        style={{
                            width: '100%',
                            textAlign: "left",
                            fontSize: "14px",
                            margin: "10px 0"
                        }}
                    >
                        <p>Shipment Details</p>
                        <div style={{
                            fontWeight: '100',
                            background: '#FFFFFF',
                            boxShadow: '0px 0px 4px rgb(0 0 0 / 25%)',
                            borderRadius: '5px',
                            padding: '10px',
                            width: '98%',
                            margin: '-5px 2px'
                        }}>
                            <div>Shipped From : {rowData?.shipmentBy}</div>
                            <div>Shipment Date: {moment(rowData?.shipmentDate).format("DD-MMM-YYYY")}</div>
                        </div>
                    </section>

                    <section
                        style={{
                            width: '100%',
                            textAlign: "left",
                            fontSize: "14px",
                            margin: "10px 0"
                        }}
                    >
                        <p>Delivery Details</p>
                        <div style={{
                            fontWeight: '100',
                            background: '#FFFFFF',
                            boxShadow: '0px 0px 4px rgb(0 0 0 / 25%)',
                            borderRadius: '5px',
                            padding: '10px',
                            width: '98%',
                            margin: '-5px 2px'
                        }}>
                            <div>Delivery Vehicle No. : {rowData?.deliveryVehicleNo}</div>
                            <div>Delivery Person : {rowData?.deliveryPersonName}</div>
                            <div>Delivery Person Contact No. : {rowData?.deliveryPersonPhone}</div>
                        </div>

                    </section>

                </div>

                <div className="footer">
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
    );
};

export default GINno;