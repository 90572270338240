import React from 'react';
import { CancelOutlined } from '@material-ui/icons';

const ViewDetails = ({ rowData, closeModal }) => {
    console.log(rowData)
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "650px", height: "550px", padding: '15px' }}
            >
                <div className="header" style={{ width: "100%" }}>
                    <h3 style={{fontSize:"18px", marginRight:"68%"}}>View Details</h3>
                </div>

                <section
                      style={{
                            width: '100%',
                            display: "flex",
                            padding:"10px",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between",padding:'0px' }}>
                            <span>RP Invoice No: {"-"}</span>
                            <span>Vendor Name: {"-"}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between",padding:'0px' }}>
                            <span>RP Invoice Date: {"-"}</span>
                            <span>Vendor Location: {"-"}</span>
                        </div>                       
               </section>
  
                <div className="body" style={{padding:"10px"}}>       
                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0",
                            padding: "5px",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
                        }}
                    >
                        {
                            rowData.shippedDetails.map(item => {
                                return (
                                    <>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Shipment From: {item.shippedFrom}</span>
                                        </div>
                                        <br/>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Shipment No.: {item.shipmentNO}</span>
                                        </div>
                                        <br/>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Shipment Date: {item.shipmentDate}</span>
                                        </div>
                                    </>

                                )
                            })
                        }
                    </section>

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0",
                            padding: "5px",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
                        }}
                    >
                        {
                            rowData.deliveryDetails.map(item => {
                                return (
                                    <>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Delivered To: {item.deliveredTO}</span>
                                        </div>
                                        <br/>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Delivery Location: {item.deliveryLocation}</span>
                                        </div>
                                        <br/>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Delivery Date: {item.deliveryDate}</span>
                                        </div>
                                        <br/>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Received By: {item.ReceivedBy}</span>
                                        </div>
                                        <br/>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Receiver's Contact No.: {item.contactNo}</span>
                                        </div>
                                    </>

                                )
                            })
                        }
                    </section>

                </div>

                <div className="footer" style={{ display: 'flex', justifyContent: 'flex-end', marginTop:"7%",marginLeft:"67%" }}>
                    <div>
                        <button
                            style={{
                                border: "1px solid #0A18A3",
                                borderRadius: "3px",
                                padding: "5px 15px",
                                marginRight: "10px",
                                color: "#0A18A3",
                                width: '152px'
                            }}
                            onClick={() => closeModal(false)}
                        >
                            <CancelOutlined style={{ color: "#0A18A3", fontSize: "28px" }} />
                            Back
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default ViewDetails;