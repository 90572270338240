import React, { useState } from "react";
import {
  KeyboardBackspace,
} from "@material-ui/icons";
import GINno from "./GINno";
import CR_POno from "./CR_POno";
import CR_MR_ReturnQty from "./CR_MR_ReturnQty";
import moment from "moment";
import ViewSpecs from "../../inventory-inv/action/ViewSpecs";
import FullAssignment from "../../inventory-inv/action/FullAssignment";
import SpareReturnQty from "./spareReturnQty";
import VR_MovedToFinishedGoods from "./VR_MovedToFinishedGoods";
import VR_SendToProduction from "./VR_SendToProduction";

const VehiclesUpdates = ({ closeModal, rowData, vehicleTableRefresh, requestCategory }) => {
  const [goodsShow, setFinishedGoodShow] = useState();
  const [prodShow, setProductionShow] = useState();

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1100px", height: "475px", padding: '15px' }}
      >
        <div className="header">
          <h3>View Details</h3>
        </div>

        <div
          className="body"
          style={{ width: '100%', display: "flex", fontSize: "14px", marginTop: "-36px" }}
        >

          <section
            style={{
              width: '100%',
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0"
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested ID: {rowData.requestID}</span>
              <span>GIN : {rowData.ginNo}<span style={{ color: "#0a18a3" }}></span></span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested Category: {rowData.requestCategory}</span>
              <span>GIN Date : {moment(rowData.ginDate).format("DD-MMM-YYYY/HH:mm")}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Request Type: {rowData.requestType}</span>
              <span>GIN Qty : {requestCategory == "Vehicle Returns" ? rowData?.returnedData?.length : rowData?.vehicles?.length}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested By: {rowData.requestedBy}<span style={{ color: "#0a18a3" }}></span></span>
            </div>

          </section>

          <section style={{ width: '100%' }}>
            <div id='POVendorHeading'>
              <div style={{ width: '5%' }}>S.No.</div>
              <div style={{ width: '8%' }}>Category</div>
              <div style={{ width: '8%' }}>Model</div>
              <div style={{ width: '10%' }}>Variant</div>
              <div style={{ width: '10%' }}>Type</div>
              <div style={{ width: '10%' }}>Color</div>
              <div style={{ width: '12%' }}>VIN</div>
              <div style={{ width: '12%' }}>BIN</div>
              <div style={{ width: '8%' }}>Status</div>
              <div style={{ width: '12%' }}>Verified On</div>
              <div style={{ width: '12%' }}>Failure Reason</div>
              {rowData.status == 'Completed' ? <div style={{ width: '20%' }}>Action</div> : ""}
            </div>
            <div>
              {
                requestCategory == "Vehicle Returns" ?
                  rowData?.returnedData.map((item, index) => (
                    <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }}>
                      <div style={{ width: '5%' }}>{index + 1}</div>
                      <div style={{ width: '8%' }}>{item.vehicleCategory}</div>
                      <div style={{ width: '8%' }}>{item.vehicleModel}</div>
                      <div style={{ width: '10%' }}>{item.vehicleVariant}</div>
                      <div style={{ width: '10%' }}>{item.vehicleType}</div>
                      <div style={{ width: '10%' }}>{item.vehicleColor}</div>
                      <div style={{ width: '12%' }}>{item.VIN}</div>
                      <div style={{ width: '12%' }}>{item.BIN}</div>
                      {item.status == "Passed" ?
                        <div className="active-btn" style={{ width: '8%', marginTop: "5px" }}><span className="active-btn-font">Passed</span></div>
                        :
                        <div className="delete-btn" style={{ width: '8%', marginTop: "5px" }}><span className="delete-btn-font">Failed</span></div>
                      }
                      <div style={{ width: '12%' }}>{item.verifiedOn}</div>
                      <div style={{ width: '12%' }}>{item.faliedReason}</div>
                      {rowData.status == "Completed" ?
                        <div style={{ padding: '5px', width: "19%" }}>
                          {item.status == 'Passed' ?
                            <button
                              style={{
                                width: "100%",
                                fontSize: '12px',
                                color: '#0a18a3',
                                cursor: 'pointer',
                                padding: '3px 9px',
                                border: '1px solid #0a18a3',
                                borderRadius: '4px',
                                background: 'white'
                              }}
                              disabled={item.lastAdded ? true : false}
                              onClick={() => setFinishedGoodShow(index)}
                            >
                              {item.lastAdded ? item.lastAction : "Move To Finished Goods"}
                            </button>
                            :
                            <button
                              style={{
                                width: "100%",
                                fontSize: '12px',
                                color: '#0a18a3',
                                cursor: 'pointer',
                                padding: '3px 9px',
                                border: '1px solid #0a18a3',
                                borderRadius: '4px',
                                background: 'white'
                              }}
                              disabled={item.lastAdded ? true : false}
                              onClick={() => setProductionShow(index)}
                            >
                              {item.lastAdded ? item.lastAction : "Move To Production"}
                            </button>}
                          {(goodsShow === index) && <VR_MovedToFinishedGoods requestID={rowData.requestID} vehicleSkuCode={item.vehicleSkuCode} rowData={item} closeModal={setFinishedGoodShow} vehicleTableRefresh={vehicleTableRefresh} />}
                          {(prodShow === index) && <VR_SendToProduction requestID={rowData.requestID} vehicleSkuCode={item.vehicleSkuCode} rowData={item} closeModal={setProductionShow} vehicleTableRefresh={vehicleTableRefresh} />}
                        </div> :
                        ""}
                    </div>
                  ))
                  :
                  rowData?.vehicles.map((item, index) => (
                    <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }}>
                      <div style={{ width: '5%' }}>{index + 1}</div>
                      <div style={{ width: '8%' }}>{item.vehicleCategory}</div>
                      <div style={{ width: '8%' }}>{item.vehicleModel}</div>
                      <div style={{ width: '10%' }}>{item.vehicleVariant}</div>
                      <div style={{ width: '10%' }}>{item.vehicleType}</div>
                      <div style={{ width: '10%' }}>{item.vehicleColor}</div>
                      <div style={{ width: '12%' }}>{item.VIN}</div>
                      <div style={{ width: '12%' }}>{item.BIN}</div>
                      {item.status == "Passed" ?
                        <div className="active-btn" style={{ width: '8%', marginTop: "5px" }}><span className="active-btn-font">Passed</span></div>
                        :
                        <div className="delete-btn" style={{ width: '8%', marginTop: "5px" }}><span className="delete-btn-font">Failed</span></div>
                      }
                      <div style={{ width: '12%' }}>{item.verifiedOn}</div>
                      <div style={{ width: '12%' }}>{item.faliedReason}</div>
                      {rowData.status == "Completed" ?
                        <div style={{ padding: '5px', width: "19%" }}>
                          {item.status == 'Passed' ?
                            <button
                              style={{
                                width: "100%",
                                fontSize: '12px',
                                color: '#0a18a3',
                                cursor: 'pointer',
                                padding: '3px 9px',
                                border: '1px solid #0a18a3',
                                borderRadius: '4px',
                                background: 'white'
                              }}
                              disabled={item.lastAdded ? true : false}
                              onClick={() => setFinishedGoodShow(index)}
                            >
                              {item.lastAdded ? item.lastAction : "Move To Finished Goods"}
                            </button>
                            :
                            <button
                              style={{
                                width: "100%",
                                fontSize: '12px',
                                color: '#0a18a3',
                                cursor: 'pointer',
                                padding: '3px 9px',
                                border: '1px solid #0a18a3',
                                borderRadius: '4px',
                                background: 'white'
                              }}
                              disabled={item.lastAdded ? true : false}
                              onClick={() => setProductionShow(index)}
                            >
                              {item.lastAdded ? item.lastAction : "Move To Production"}
                            </button>}
                          {(goodsShow === index) && <VR_MovedToFinishedGoods requestID={rowData.requestID} vehicleID={item.vehicleID} rowData={item} tabName="vehicles" closeModal={setFinishedGoodShow} vehicleTableRefresh={vehicleTableRefresh} />}
                          {(prodShow === index) && <VR_SendToProduction requestID={rowData.requestID} vehicleID={item.vehicleID} rowData={item} tabName="vehicles" closeModal={setProductionShow} vehicleTableRefresh={vehicleTableRefresh} />}
                        </div> :
                        ""}
                    </div>
                  ))
              }
            </div>
          </section>

        </div>

        <div className="footer">
          <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
        </div>
      </div>
    </div>
  );
};

export default VehiclesUpdates;