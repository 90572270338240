import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import ActionBom from "./bom-actions/bom-action";
import ViewComponents from "./bom-actions/bom-viewcomponent";
import "../../components/modal/modal.scss";

import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ViewLabours from "./bom-actions/bom-viewlabour";
import ViewAssembly from "./bom-actions/bom-viewassembly";
import SearchBox from "../../components/search/SearchBox";
import AppConstants from "../../utils/app-constants";
import { errorNotification } from "../../utils/notifications";
import handleExport from "../../utils/exportExcel";

const { ExportCSVButton } = CSVExport;

const ManageBom = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [viewLabour, setViewLabour] = useState();
  const [viewAssm, setViewAssm] = useState();
  const [open, setOpen] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [viewComp, setViewComp] = useState();
  const [componentShow, setComponentShow] = useState();
  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    bomLocation: "",
    bomTypes: "",
    bomVersion: "",
    bomLevel: "",
    vehicleSkuCode: "",
    vehicleColor: "",
    vehicleModel: "",
    vehicleVariant: "",
    status: "",
    creationDate: "",
    createdBy: "",
  });


  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `production-module/api/v1/bom?bomLocation=${formValue.bomLocation}&bomTypes=${formValue.bomTypes}&bomVersion=${formValue.bomVersion}&bomLevel=${formValue.bomLevel}&vehicleModel=${formValue.vehicleModel}&vehicleColor=${formValue.vehicleColor}&vehicleVariant=${formValue.vehicleVariant}&createdDate=${formValue.creationDate}&status=${formValue.status}&vehicleSkuCode=${formValue.vehicleSkuCode}&createdBy=${formValue.createdBy}`
      )
    );
    console.log(resp);
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setUsersData(resp);
    setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const statusFormatter = (cell) => {
    return cell == AppConstants.ACTIVE ? (
      <button className="active-btn">
        <span className="active-btn-font">Active</span>
      </button>
    ) : cell == AppConstants.DEACTIVATED ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">Deactivated</span>
      </button>
    ) : cell == AppConstants.REACTIVATED ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">Reactivated</span>
      </button>
    ) : cell == AppConstants.PENDINGBYPROC ? (
      <button className="pendingByProc-btn" style={{ width: "100%" }}>
        <span className="pendingByProc-btn-font">Pending At Procurement</span>
      </button>
    ) : cell == AppConstants.PENDINGBYMANAGEMENT ? (
      <button className="pendingByProc-btn" style={{ width: "100%" }}>
        <span className="pendingByProc-btn-font">Pending At Management</span>
      </button>
    ) : cell == "Rejected By Management" ? (
      <button className="delete-btn" style={{ width: "100%" }}>
        <span className="delete-btn-font">Rejected At Management</span>
      </button>
    ) : cell == "Approved By Management" ? (
      <button className="active-btn" style={{ width: "100%" }}>
        <span className="active-btn-font">Approved By Management</span>
      </button>
    ) : cell == AppConstants.DELETED ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Deleted</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const handleClick = (e) => {
    setShow(!show);
  };

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.status === AppConstants.DELETED ? (
      ""
    ) : (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && open ? (
          <ActionBom updatedData={fetchReport} rowData={row} status={true} rowIndex={rowIndex} setActionShow={setActionShow}/>
        ) : null}
      </div>
    );
  };

  const bomComponentsHandler = (rowIndex) => {
    setComponentShow(rowIndex);
    setViewComp(true);
  };

  const componentFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => bomComponentsHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          View Components
        </button>
        {componentShow === rowIndex && viewComp ? (
          <ViewComponents
            componentData={row.bomComponents}
            close={setViewComp}
          />
        ) : null}
      </div>
    );
  };

  const bomLabourHandler = (rowIndex) => {
    setComponentShow(rowIndex);
    setViewLabour(true);
  };

  const labourFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => bomLabourHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          View Labours
        </button>
        {componentShow === rowIndex && viewLabour ? (
          <ViewLabours labourData={row.bomLabor} close={setViewLabour} />
        ) : null}
      </div>
    );
  };

  const bomAssemblyHandler = (rowIndex) => {
    setComponentShow(rowIndex);
    setViewAssm(true);
  };

  const assemblyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => bomAssemblyHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          View Assembly
        </button>
        {componentShow === rowIndex && viewAssm ? (
          <ViewAssembly assemblyData={row.bomAssembly} close={setViewAssm} />
        ) : null}
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },

    {
      text: "BOM Name",
      dataField: "bomName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      classes:"cellsOverflow"
    },
    {
      text: " Location",
      dataField: "bomLocation",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      classes:"cellsOverflow"
    },
    {
      text: "BOM ID",
      dataField: "BOMID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      classes:"cellsOverflow"
    },
    {
      text: "BOM Type",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      dataField: "bomTypes",
      headerAttrs: { width: "200px" },
      classes:"cellsOverflow"
    },
    {
      text: "BOM Version",
      dataField: "bomVersion",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      classes:"cellsOverflow"
    },
    {
      text: "BOM Level",
      dataField: "bomLevel",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      classes:"cellsOverflow"
    },
    {
      text: "Model",
      dataField: "vehicleModel",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "100px" },
      classes:"cellsOverflow"
    },
    {
      text: "Variant",
      dataField: "vehicleVariant",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "100px" },
      classes:"cellsOverflow"
    },
    {
      text: "Component",
      dataField: "bomComponents",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatExtraData: { viewComp, componentShow },
      formatter: componentFormatter,
      classes:"cellsOverflow"
    },
    {
      text: "Labour",
      dataField: "bomLabour",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatExtraData: { viewLabour, componentShow },
      formatter: labourFormatter,
      classes:"cellsOverflow"
    },
    {
      text: "Assembly",
      dataField: "bomAssembly",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { viewAssm, componentShow },
      formatter: assemblyFormatter,
      classes:"cellsOverflow"
    },
    {
      text: "Created By",
      dataField: "createdBy",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      classes:"cellsOverflow"
    },
    {
      text: "Creation Date & Time",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      dataField: "createdDate",
      headerAttrs: { width: "170px" },
      formatter: dateFormatter,
      classes:"cellsOverflow"
    },

    {
      text: "Status",
      dataField: "status",
      classes: "cellsOverflow",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "250px" },
      formatter: statusFormatter,
      classes:"cellsOverflow"
    },
    // {
    //   text: "Assembly Services",
    //   dataField: "assemblyservices",
    //   headerAttrs: { width: "200px" },
    //   formatExtraData: {serviceShow,open},
    //   formatter: serviceFormatter,
    // },
    {
      text: "Action",
      // dataField: "title",
      headerAttrs: { width: "70px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      bomLocation: "",
      bomTypes: "",
      bomVersion: "",
      bomLevel: "",
      vehicleSkuCode: "",
      vehicleColor: "",
      vehicleModel: "",
      vehicleVariant: "",
      status: "",
      creationDate: "",
      createdBy: "",
    });

    const resp = await trackPromise(API.get(`production-module/api/v1/bom`));
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setUsersData(resp);
    setUsersData(resp);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  let uniqueBomTypeArray = [
    ...new Map(UsersData.map((item) => [item["bomTypes"], item])).values(),
  ];
  let uniqueBomLevelArray = [
    ...new Map(UsersData.map((item) => [item["bomLevel"], item])).values(),
  ];
  let uniqueBomLocationArray = [
    ...new Map(UsersData.map((item) => [item["bomLocation"], item])).values(),
  ];
  let uniqueVehicleModelArray = [
    ...new Map(UsersData.map((item) => [item["vehicleModel"], item])).values(),
  ];
  let uniqueVehicleVariantArray = [
    ...new Map(
      UsersData.map((item) => [item["vehicleVariant"], item])
    ).values(),
  ];
  let uniqueVehicleColorArray = [
    ...new Map(UsersData.map((item) => [item["vehicleColor"], item])).values(),
  ];

  const uniqueStatus = [...new Set(UsersData.map((item) => item.status))];
  const uniqueSKU = [...new Set(UsersData.map((item) => item.vehicleSkuCode))];
  const uniqueCreatedBy = [...new Set(UsersData.map((item) => item.createdBy))];

  return (

    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage BOM</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "Manage BOM")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    id="abc"
                    onSubmit={submitHandler}
                    className="RA_block"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      height: "185px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                    }}
                  >
                    <div
                      className="bomfilter_fields"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "left",
                        height: "auto",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "20px",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          BOM Location 
                        </label>
                        <select
                          name="bomLocation"
                          value={formValue.bomLocation}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            BOM Location
                          </option>
                          {uniqueBomLocationArray.map((item, id) => (
                            <option key={id}>{item.bomLocation}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "20px",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          BOM Type 
                        </label>
                        <select
                          name="bomTypes"
                          value={formValue.bomTypes}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            BOM Type
                          </option>
                          {uniqueBomTypeArray.map((item, id) => (
                            <option key={id}>{item.bomTypes}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "20px",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          BOM Version 
                        </label>
                        <input
                          type="text"
                          name="bomVersion"
                          value={formValue.bomVersion}
                          placeholder="BOM Version"
                          autoComplete="off"
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "20px",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          BOM Level 
                        </label>
                        <select
                          name="bomLevel"
                          value={formValue.bomLevel}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            BOM Level
                          </option>
                          {uniqueBomLevelArray.map((item, id) => (
                            <option key={id}>{item.bomLevel}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "20px",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          SKU Code
                        </label>
                        <select
                          name="vehicleSkuCode"
                          value={formValue.vehicleSkuCode}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            SKU Code
                          </option>
                          {uniqueSKU.map((item, id) => (
                            <option key={id}>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "20px",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          Color 
                        </label>
                        <select
                          name="vehicleColor"
                          value={formValue.vehicleColor}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Color
                          </option>
                          {uniqueVehicleColorArray.map((item, id) => (
                            <option key={id}>{item.vehicleColor}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "20px",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Model
                        </label>
                        <select
                          name="vehicleModel"
                          value={formValue.vehicleModel}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Model
                          </option>
                          {uniqueVehicleModelArray.map((item, id) => (
                            <option key={id}>{item.vehicleModel}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "20px",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Variant
                        </label>
                        <select
                          name="vehicleVariant"
                          value={formValue.vehicleVariant}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Variant
                          </option>
                          {uniqueVehicleVariantArray.map((item, id) => (
                            <option key={id}>{item.vehicleVariant}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "20px",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {uniqueStatus.map((item, id) => (
                            <option key={id}>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "20px",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Creation Date
                        </label>
                        <input
                          type="date"
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "text")}
                          placeholder="Creation Date"
                          name="creationDate"
                          // onfocus={type='date'}
                          value={formValue.creationDate}
                          onChange={handleChange}
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "20px",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Created By <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          // required={true}
                          name="createdBy"
                          value={formValue.createdBy}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Created By
                          </option>
                          {uniqueCreatedBy?.map((item, id) => (
                            <option key={id}>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        className="RA_btn"
                        style={{ justifyContent: "end", width: "80%" }}
                      >
                        <button
                          onClick={fetchReport}
                          id="apply"
                          style={{ width: "88px" }}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          style={{ width: "104px" }}
                          id="clear"
                        >
                          Clear Filters
                        </button>
                      </div>
                    </div>

                    {/* </div> */}
                  </form>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};
export default ManageBom;
