import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import SearchBox from "../../../../components/search/SearchBox";
import WarrantyDetails from "./WarrantyDetails";
import ServiceDetails from "./ServiceDetails";
import VehicleInsuranceDetails from "./VehicleInsuranceDetails";
import AvailableQty from "./AvailableQty";
import moment from "moment";
const { ExportCSVButton } = CSVExport;

const Vehicles = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const [warrantyShow, setWarrantyShow] = useState();
  const [viewWarranty, setViewWarranty] = useState();
  const [viewDetails, setViewDetails] = useState();
  const [viewInsurance, setViewInsurance] = useState();
  const [viewData, setViewData] = useState();
  const [viewAvailablQty, setViewAvailablQty] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState();
  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    vehicleSkuCode: "",
    vehicleSegment: "",
    vehicleCategory: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleVariant: "",
    vehicleType: "",
    vehicleColor: "",
    dateRange: "",
  });

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `salesUser-module/api/v1/vehicleAndSpare/getVehicles?vehicleSkuCode=${formValue.vehicleSkuCode}&vehicleSegment=${formValue.vehicleSegment}&vehicleCategory=${formValue.vehicleCategory}&
        vehicleMake=${formValue.vehicleMake}&vehicleModel=${formValue.vehicleModel}&vehicleVariant=${formValue.vehicleVariant}&
        vehicleType=${formValue.vehicleType}&vehicleColor=${formValue.vehicleColor}`
      )
    );
    console.log(resp);
    setUsersData(resp);
  };

  useEffect(() => {
    fetchReport();
  }, []);





  const  uniqueskuCode = [...new Set(UsersData.map((item)=>item.vehicleSkuCode))]
  const  uniquesegment = [...new Set(UsersData.map((item)=>item.vehicleSegment))]
  const  uniquecategory = [...new Set(UsersData.map((item)=>item.vehicleCategory))]
  const  uniquevehicleMake = [...new Set(UsersData.map((item)=>item.vehicleMake))]
  const  uniquevehicleModel = [...new Set(UsersData.map((item)=>item.vehicleModel))]
  const  uniquevehicleType = [...new Set(UsersData.map((item)=>item.vehicleType))]
  const  uniquevehicleVariant = [...new Set(UsersData.map((item)=>item.vehicleVariant))]
  const  uniquevehicleColor = [...new Set(UsersData.map((item)=>item.vehicleColor))]





  const specsHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setViewSpecs(true);
  };

  const specsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => specsHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          View Details
        </button>
        {actionShow === rowIndex && viewSpecs ? (
          <ServiceDetails
            rowData={row}
            status={true}
            closeModal={setViewSpecs}
          />
        ) : null}
      </div>
    );
  };

  const warrantyHandler = (row, rowIndex) => {
    setWarrantyShow(rowIndex);
    setRow(row);
    setViewWarranty(true);
  };

  const warrantyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => warrantyHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          View Details
        </button>
        {warrantyShow === rowIndex && viewWarranty ? (
          <WarrantyDetails
            rowData={row}
            status={true}
            closeModal={setViewWarranty}
          />
        ) : null}
      </div>
    );
  };

  const insuranceHandler = (row, rowIndex) => {
    setViewDetails(rowIndex);
    setRow(row);
    setViewInsurance(true);
  };

  const insuranceFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => insuranceHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          View Details
        </button>
        {viewDetails === rowIndex && viewInsurance ? (
          <VehicleInsuranceDetails
            rowData={row}
            status={true}
            closeModal={setViewInsurance}
          />
        ) : null}
      </div>
    );
  };

  const availableQtyHandler = (row, rowIndex) => {
    setViewData(rowIndex);
    setRow(row);
    setViewAvailablQty(true);
  };

  const availableQtyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => availableQtyHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          {row.availableQty.length}
        </button>
        {viewData === rowIndex && viewAvailablQty ? (
          <AvailableQty
            rowData={row}
            rowInde={rowIndex}
            status={true}
            closeModal={setViewAvailablQty}
          />
        ) : null}
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      vehicleSkuCode: "",
      vehicleSegment: "",
      vehicleCategory: "",
      vehicleMake: "",
      vehicleModel: "",
      vehicleVariant: "",
      vehicleType: "",
      vehicleColor: "",
      dateRange: "",
    });

    const resp = await trackPromise(
      API.get(
        `http://localhost:3019/salesUser-module/api/v1/vehicleAndSpare/getVehicles`
      )
    );
    setUsersData(resp);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "SKU Code",
      dataField: "vehicleSkuCode",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Segment",
      dataField: "vehicleSegment",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Category",
      dataField: "vehicleCategory",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    // {
    //   text: "Vehicle Make",
    //   dataField: "vehicleMake",
    //   headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    //   headerAttrs: { width: "200px" },
    // },
    {
      text: "Vehicle Model",
      dataField: "vehicleModel",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Vehicle Variant",
      dataField: "vehicleVariant",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Vehicle Type",
      dataField: "vehicleType",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Color",
      dataField: "vehicleColor",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Launch Date",
      dataField: "requestDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: dateFormatter,
    },
    {
      text: "Warranty",
      dataField: "dealerPrice",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "250px" },
      formatExtraData: { viewWarranty, warrantyShow },
      formatter: warrantyFormatter,
    },
    {
      text: "Service",
      dataField: "service",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatExtraData: { viewSpecs, actionShow },
      formatter: specsFormatter,
    },
    {
      text: "Insurance",
      dataField: "insurance",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatExtraData: { viewInsurance, viewDetails },
      formatter: insuranceFormatter,
    },
    {
      text: "Dealer Price",
      dataField: "pricetoDealer",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Dealer Margin",
      dataField: "dealerMargin",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "EX-Showroom Price",
      dataField: "exShowroomPrice",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Available Qty",
      dataField: "availableQty",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatExtraData: { viewAvailablQty, viewData },
      formatter: availableQtyFormatter,
    },
    {
      text: "Last Update",
      dataField: "lastUpdated",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: dateFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Vehicle</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div
                    className="UHinputfields"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "135px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          SKU Code
                        </label>
                        <select
                          name="vehicleSkuCode"
                          value={formValue.vehicleSkuCode}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            SKU Code
                          </option>
                          {
                            uniqueskuCode.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Segment
                        </label>
                        <select
                          name="vehicleSegment"
                          value={formValue.vehicleSegment}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Segment
                          </option>
                          {
                            uniquesegment.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Category
                        </label>
                        <select
                          name="vehicleCategory"
                          value={formValue.vehicleCategory}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Category
                          </option>
                          {
                            uniquecategory.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Make
                        </label>
                        <select
                          name="vehicleMake"
                          value={formValue.vehicleMake}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Make
                          </option>
                          {
                            uniquevehicleMake.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Model
                        </label>
                        <select
                          name="vehicleModel"
                          value={formValue.vehicleModel}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Model
                          </option>
                          {
                            uniquevehicleModel.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Variant
                        </label>
                        <select
                          name="vehicleVariant"
                          value={formValue.vehicleVariant}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Variant
                          </option>
                          {
                            uniquevehicleVariant.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Type
                        </label>
                        <select
                          name="date"
                          value={formValue.dateRange}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Type
                          </option>
                          {
                            uniquevehicleType.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Color
                        </label>
                        <select
                          name="date"
                          value={formValue.dateRange}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Color
                          </option>
                          {
                            uniquevehicleColor.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Date Range
                        </label>
                        <input
                          type="date"
                          name="fromDate"
                          defaultValue={formValue.fromDate}
                          style={{ marginTop: "-5px", width: "100%" }}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "end",
                          width: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <button
                          id="apply"
                          style={{ marginTop: "22px", marginLeft: "10px" }}
                          onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ marginTop: "22px", width: "auto" }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default Vehicles;
