import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, registerables } from 'chart.js'
import { trackPromise } from 'react-promise-tracker';
import API from '../../../utils/api';
import moment from 'moment';
ChartJS.register(...registerables)
ChartJS.defaults.plugins.legend.display = false;


const VehicleReBarChart=({ props, startDate, endDate, sfStartDate, sfEndDate })=> {
  const [compData, setCompData] = useState({});
  const [shopFloorData, setShopFloorData] = useState({});
  const [qcData, setQcData] = useState({})

  const fetchCompData = async () => {
    const resp = await trackPromise(API.get(startDate == null && endDate == null ? `procurement-module/api/v1/dashboard/getAllComponents` : `procurement-module/api/v1/dashboard/getAllComponents?startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}`))
    setCompData(resp);
  };
  const fetchShopFloorData = async () => {
    const resp = await trackPromise(API.get(sfStartDate == null && sfEndDate == null ? `production-module/api/v1/dashboard/getShopFloorUsers` : `production-module/api/v1/dashboard/getShopFloorUsers?startDate=${moment(sfStartDate).format("DD-MMM-YYYY")}&endDate=${moment(sfEndDate).format("DD-MMM-YYYY")}`))
    setShopFloorData(resp);
  };
  const fetchQCData = async () => {
    const resp = await trackPromise(API.get(startDate == null && endDate == null ? `qc-module/api/v1/returnVehicle` : `qc-module/api/v1/returnVehicle?startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}`))
    setQcData(resp);
    console.log(resp)
  };

  useEffect(() => {
    {
      if(props == "procurement"){
        fetchCompData() 
      }else if (props == "qc"){
        fetchQCData()
      }else{
        fetchShopFloorData()
      }
    }
  }, [props == "production" ? sfEndDate : endDate]);

  return <Bar
    height={props == "production" ? 180 : props == "procurement" ? 180 : props=="qc" ? 230 : 234}
    data={{
      datasets: [{
        type: 'bar',
        backgroundColor: '#2D3DD9',
        barThickness: 25,
        data: props == "production" ? [shopFloorData.total, shopFloorData.activeUsers, shopFloorData.deactivatedUsers, shopFloorData.reactivatedUsers, shopFloorData.deletedUsers] : props == "procurement" ? [compData.totalComponents, compData.activeComponents, compData.discontinuedComponents, compData.reactivatedComponents, compData.deletedComponents]: props == "qc" ? [qcData.completedVehicle, qcData.holdVehicle, qcData.inprogressVehicle, qcData.newVehicle, qcData.rejectedVehicle, qcData.totalVehicle] : [256, 180, 240, 150, 100, 120],
        indexAxis: 'y',
        options: {

          responsive: true,

        }
      }
      ],
      labels: props == "production" || props == "procurement" ? ["Total", "Active", "De-activated", "Re-activated", "Deleted"] : props == "qc" ? ["Completed Vehicle", "Hold Vehicle", "In-Progress Vehicle", "New Vehicle", "Rejected Vehicle", "Total Vehicle"]  : ["Total", "New", "Hold", "In-Progress", "Completed", "Rejected"] 
    }}
  />
}

export default VehicleReBarChart