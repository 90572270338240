import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { trackPromise } from 'react-promise-tracker';
import { Chart as ChartJS, registerables } from 'chart.js'
ChartJS.register(...registerables)
ChartJS.defaults.plugins.legend.display = false;
import API from '../../../utils/api';
import moment from 'moment';
import '../styles/main.scss'

export default function SpareReVerification({startDate, endDate}) {

    const [spareData, setSpareData] = useState({})
    
    const fetchReport = async () => {
        const result = await trackPromise(API.get(startDate == null && endDate == null ? `qc-module/api/v1/spare` : `qc-module/api/v1/spare?startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}`));
        console.log(result);
        setSpareData(result);
    };
    useEffect(() => {
        fetchReport();
    }, [endDate]);


    return (
        <div style={{ width: "95%", marginLeft: "10px" }}>
            <ProgressBar>
                <ProgressBar now={spareData.components * 100} style={{ background: "#3442CF" }} />
                <ProgressBar variant="newRequest" now={spareData.newComp * 100} />
                <ProgressBar variant="inProgressRequest" now={spareData.inprogressComp * 100} />
                <ProgressBar variant="warning" now={spareData.holdComp * 100} />
                <ProgressBar variant="success" now={spareData.completedComp * 100} />
                <ProgressBar variant="danger" now={spareData.rejectedComp * 100} />
            </ProgressBar>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#1e90ff", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Total Requests</span>
                </div>
                <div>
                    {spareData.components}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#267799", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>New Requests</span>
                </div>
                <div>
                    {spareData.newComp}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#FDAA48", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>In-Progress Requests</span>
                </div>
                <div>
                    {spareData.inprogressComp}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#FFD74A", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Hold Requests</span>
                </div>
                <div>
                    {spareData.holdComp}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#21A82E", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Completed Requests</span>
                </div>
                <div>
                    {spareData.completedComp}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#EF4D62", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Rejection Inventory</span>
                </div>
                <div>
                    {spareData.rejectedComp}
                </div>
            </div>
            <hr />
        </div>
    )
}
