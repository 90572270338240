import { KeyboardBackspace } from "@material-ui/icons";
import React from "react";
import moment from "moment";
import DealerSparePO from "../../../../RequisitionOrders/DealerSparePO";

const ViewUpdates = ({ rowData, closeModal }) => {

  const [openPO,setPO] = useState(false);

  return (
    <div className="container1">
    {
      openPO ? <DealerSparePO rowData={rowData} closeModal={setPO} type="requisitionDetails"/>:""
    }
      <div
        className="modalContainer"
        style={{ width: "1280px", height: "500px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>View Updates</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div>Request ID: {}</div>
              <div>
              <span>
              PO No. : 
              </span>

              <span  style={{ fontWeight: "600", color:"blue",cursor:"pointer"}} onClick={()=>setPO(true)}>
              {rowData.poNo}
              </span>

     </div>
              <div>PO Date: {moment().format("DD-MM-YYYY")}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div>PO By : {}</div>
              <div>PO Value : {}</div>
              <div><button className="reactive-btn" style={{color:"blue", width:"115px"}}>WO In-Progress<span className="reactive-btn-font"></span></button></div>
              
            </section>
          </section>
          <br />
          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "fit-content",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "10%", padding: "10px" }}>Index</div>
              <div style={{ width: "200px", padding: "10px" }}>Segment</div>
              <div style={{ width: "200px", padding: "10px"}}>Category</div>
              <div style={{ width: "200px", padding: "10px"}}>Vehicle SKU</div>
              <div style={{ width: "200px", padding: "10px"}}>
                Vehicle Make
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
                Vehicle Model
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
                Vehicle Variant
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
                Vehicle Type
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
                Vehicle Color
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
                Requested Vehicle Qty.
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
                Status
              </div>
            </div>
            <section
              style={{ width: "fit-content", height: "auto" }}
            >
              {/* {row.availableQty.map((item, index) => ( */}
              <div
               style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "",
                fontSize: "13px",
                display:"flex"
              }}
            >
              <div style={{ width: "10%", padding: "10px" }}>Index</div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>Segment</div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>Category</div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>Vehicle SKU</div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                Vehicle Make
              </div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                Vehicle Model
              </div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                Vehicle Variant
              </div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                Vehicle Type
              </div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                Vehicle Color
              </div>
              <div style={{ width: "200px", padding: "10px", overflowX:"scroll" }}>
                Requested Vehicle Qty.
              </div>
              <div style={{ width: "200px", padding: "10px"}}>
              <button className="reactive-btn" style={{color:"blue", width:"115px"}}>WO In-Progress<span className="reactive-btn-font"></span></button>
              </div>
            </div>
            {/* //   ))} */}
            </section>
          </section>
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewUpdates;
