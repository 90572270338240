import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import SearchBox from "../../../components/search/SearchBox";
import VehicleSalesAction from "./VehicleSalesAction/VehicleSalesAction";
import { trackPromise } from "react-promise-tracker";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import API from "../../../utils/api";
import moment from "moment";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import TaxInvoice from "../../Invoice/TaxInvoice";
import TaxInvoiceView from "../../Invoice/TaxInvoiceView";

function ManageVehicleSales({ inDashboard }) {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [open, setOpen] = useState();
  const [data, setData] = useState([]);
  const [openQty, setOpenQty] = useState();
  const [view, setView] = useState();

  const [formValue, setFormValue] = useState({
    vehicleSkuCode: "",
    bookingId: "",
    enquiryId: "",
    customerName: "",
  });

  const fetchReport = useMemo(() => {
    return async () => {
      const resp = await trackPromise(
        API.get(
          `/showroom-module/api/v1/customerAndSales/getAllVehicleSales?vehicleSkuCode=${formValue.vehicleSkuCode}&bookingId=${formValue.bookingId}&enquiryId=${formValue.enquiryId}&customerName=${formValue.customerName}`
        )
      );
      console.log(resp);
      setData(resp);
    };
  }, []);

  const uniqueEnquiryID = [...new Set(data.map((item) => item.enquiryID))];
  const uniquevehicleSkuCode = [
    ...new Set(data.map((item) => item.vehicleSkuCode)),
  ];
  const customerName = [...new Set(data.map((item) => item.customerName))];
  const uniquesbookingId = [
    ...new Set(
      data.map((item) => {
        item.bookingId;
      })
    ),
  ];

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  const handleClick = (e) => {
    setShow(!show);
  };

  // function dateFormatter(cell) {
  //   let date = cell
  //   if (!cell) {
  //     return "N/A";
  //   }
  //   return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const clearFilter = () => {
    setFormValue({
      vehicleSkuCode: "",
      bookingId: "",
      enquiryId: "",
      customerName: "",
    });
    document.getElementById("vehicleSkuCode").value = "";
    document.getElementById("bookingId").value = "";
    document.getElementById("enquiryId").value = "";
    document.getElementById("customerName").value = "";
    fetchReport();
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const openHandler = (rowIndex)=>{
    setOpenQty(rowIndex)
    setView(true)
  }

  const invoiceFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "14px",
            color: "blue",
            cursor: "pointer",
          }}
          onClick={() => openHandler(rowIndex)}
        >
          {cell}
        </p>
        {openQty == rowIndex && view ? (
          <TaxInvoiceView rowData={row} closeModal={setView} />
        ) :""
        }
      </div>
    );
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && open ? (
          <VehicleSalesAction
            updatedData={fetchReport}
            rowData={row}
            status={true}
            rowIndex={rowIndex}
          />
        ) : null}
      </div>
    );
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Enquiry ID",
      dataField: "enquiryID",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Booking ID",
      dataField: "bookingID",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        if (!cell) {
          return "-";
        } else {
          return cell;
        }
      },
    },
    {
      text: "Booking Date",
      dataField: "bookingDate",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        if (!cell) {
          return "-";
        } else {
          return <p>{moment(cell).format("DD-MMM-YYYY")}</p>;
        }
      },
    },
    {
      text: "Booking Amount",
      dataField: "bookingAmount",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        if (!cell) {
          return "-";
        } else {
          return cell;
        }
      },
    },
    {
      text: "Payment Mode",
      dataField: "paymentMode",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        if (!cell) {
          return "-";
        } else {
          return cell;
        }
      },
    },
    {
      text: "Transaction ID",
      dataField: "transactionID",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        if (!cell) {
          return "-";
        } else {
          return cell;
        }
      },
    },
    {
      text: "Customer Name",
      dataField: "customerName",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Vehicle Sku Code",
      dataField: "vehicleSkuCode",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Vehicle Model",
      dataField: "vehicleModel",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Registration Number",
      dataField: "vehicleRegNo",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "220px" },
      formatter: (cell, row, rowIndex) => {
        if (row.vehicleRegNo == undefined || row.vehicleRegNo == null) {
          return "-";
        } else {
          return cell;
        }
      },
    },
    {
      text: "Invoice No.",
      dataField: "invoiceNo",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { openQty , view},
      formatter: invoiceFormatter,
    },
    {
      text: "Invoice Date",
      dataField: "invoiceDate",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        if (!cell) {
          return "-";
        } else {
          return <p>{moment(cell).format("DD-MMM-YYYY")}</p>;
        }
      },
    },
    {
      text: "Invoice Amount",
      dataField: "invoiceAmount",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Payment Type",
      dataField: "paymentType",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Financed By",
      dataField: "financerName",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        if (!cell) {
          return "-";
        } else {
          return cell;
        }
      },
    },
    {
      text: "Action",
      headerAttrs: { width: "70px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Vehicle Sales</span>
        </div>

        <ToolkitProvider keyField="id" data={data} columns={columns} search>
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div
                style={
                  show
                    ? { background: "#E5E5E5", padding: "0% 1% 1% 1%" }
                    : { background: "white", padding: "0" }
                }
              >
                {show && (
                  <div
                    id="abc"
                    // onSubmit={fetchReport}
                    className="RA_block"
                    style={{ height: "120px", justifyContent: "flex-start" }}
                  >
                    <div className="bomfilter_fields">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                        }}
                      >
                        <label>Enquiry ID</label>
                        <select
                          name="enquiryId"
                          id="enquiryId"
                          defaultValue={formValue.enquiryId}
                          style={{ width: "100%", marginTop: "-9px" }}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="" disabled selected>
                            Enquiry ID
                          </option>
                          {uniqueEnquiryID?.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                        }}
                      >
                        <label>SKU Code</label>
                        <select
                          name="vehicleSkuCode"
                          id="vehicleSkuCode"
                          style={{ width: "100%", marginTop: "-9px" }}
                          defaultValue={formValue.vehicleSkuCode}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="" disabled selected>
                            SKU Code
                          </option>
                          {uniquevehicleSkuCode?.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                        }}
                      >
                        <label>Customer Name</label>
                        <select
                          name="customerName"
                          id="customerName"
                          style={{ width: "100%", marginTop: "-9px" }}
                          defaultValue={formValue.customerName}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="" disabled selected>
                            Customer Name
                          </option>
                          {customerName?.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                        }}
                      >
                        <label>Booking ID</label>
                        <select
                          name="bookingId"
                          id="bookingId"
                          style={{ width: "100%", marginTop: "-9px" }}
                          defaultValue={formValue.bookingId}
                          onChange={(e) => handleChange(e)}
                          type="date"
                          placeholder="Booking ID"
                        >
                          <option value="" disabled selected>
                            Booking ID
                          </option>
                          {uniquesbookingId?.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>

                      <div style={{ margin: "9px 0 0 80%" }}>
                        <button
                          type="submit"
                          id="apply"
                          style={{ width: "96px", height: "35px" }}
                          onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ width: "96px", height: "35px" }}
                        >
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <br />
              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

export default ManageVehicleSales;
