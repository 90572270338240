import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import Vector from "../QcDashboard/assets/Vector.svg";
import Vector3 from "../QcDashboard/assets/Vector3.svg";
import Group7 from "../QcDashboard/assets/Group7.svg";
import Group8 from "../QcDashboard/assets/Group8.svg";
import Group9 from "../QcDashboard/assets/Group9.svg";
import Group10 from "../QcDashboard/assets/Group10.svg";
import Group11 from "../QcDashboard/assets/Group11.svg";
import Group12 from "../QcDashboard/assets/Group12.svg";
import Group13 from "../QcDashboard/assets/Group13.svg";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);
ChartJS.defaults.plugins.legend.display = false;

const SpareInventory = ({ prop }) => {

  const [data, setData] = useState({});
  const [totalInventory, setTotalInventory] = useState(0);
  const [totalInventoryPrice, setTotalInventoryPrice] = useState(0);
  const [dataSpare, setDataSpare] = useState({});

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(`dealer-module/api/v1/serviceCenters/inventory/serviceCenterWise`)
    );

    const { totalInventory, totalInventoryPrice } = res?.data?.reduce(
      (acc, curr) => {
        const count = parseInt(curr.count);
        const price = parseInt(curr.priceToserviceCenter);
        acc.totalInventory += count;
        acc.totalInventoryPrice += count * price;
        return acc;
      },
      { totalInventory: 0, totalInventoryPrice: 0 }
    );
    setTotalInventory(totalInventory);
    setTotalInventoryPrice(totalInventoryPrice)
    setData(res.data);

    const resp = await trackPromise(
      API.get(`dealer-module/api/v1/dashBoard/sparesSales`)
    );
    setDataSpare(resp);
  };
  useEffect(() => {
    fetchData()
  }, []);


return (
  <div className="requestBody" style={{ justifyContent: "start" }}>
    <div
      className="shopFloorRequest"
      style={{ height: "auto", width: "47%", display: "flex", flexDirection: "row", justifyContent: "start" }}
    >
      <div style={{ width: "35%", padding: "4%" }}>
        <img
          src={Group9}
          style={{
            height: "50px",
            display: "block",
          }}
          alt="group"
        />
      </div>
      <div style={{ width: "65%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginLeft: "15px",
          }}
        >
          <div
            style={{
              fontSize: "24px",
              fontWeight: "450",
              textAlign: "start",
            }}
          >
            {prop == "inventory" ? data.length : dataSpare.totalSpare}
          </div>
        </div>
        <div
          style={{
            color: "#808080",
            fontSize: "14px",
            textAlign: "start",
            margin: "10px 6px 0px 6px",
            borderBottom: "2px solid #F2F2F2",
          }}
        >
          {prop == "inventory" ? "Total Spare Parts" : "Total Spares Sold "}
        </div>
      </div>
    </div>
    <div
      className="shopFloorRequest"
      style={{ height: "auto", width: "47%", display: "flex", flexDirection: "row", justifyContent: "start" }}
    >
      <div style={{ width: "35%", padding: "4%" }}>
        <img
          src={Group8}
          style={{
            height: "50px",
            display: "block",
          }}
          alt="group"
        />
      </div>
      <div style={{ width: "65%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginLeft: "15px",
          }}
        >
          <div
            style={{
              fontSize: "24px",
              fontWeight: "450",
              textAlign: "start",
            }}
          >
            {prop == "inventory" ? totalInventory : dataSpare.totalSpareUnit}
          </div>
        </div>
        <div
          style={{
            color: "#808080",
            fontSize: "14px",
            textAlign: "start",
            margin: "10px 6px 0px 6px",
            borderBottom: "2px solid #F2F2F2",
          }}
        >
          {prop == "inventory" ? "Total Spare Units" : "Total Sold Units"}
        </div>
      </div>
    </div>
    <div
      className="shopFloorRequest"
      style={{ height: "auto", width: "47%", display: "flex", flexDirection: "row", justifyContent: "start" }}
    >
      <div style={{ width: "35%", padding: "4%" }}>
        <img
          src={Group12}
          style={{
            height: "50px",
            display: "block",
          }}
          alt="group"
        />
      </div>
      <div style={{ width: "65%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginLeft: "15px",
          }}
        >
          <div
            style={{
              fontSize: "24px",
              fontWeight: "450",
              textAlign: "start",
            }}
          >
            {prop == "inventory" ? totalInventoryPrice.toLocaleString("en-IN") : dataSpare.totalSparePrice}
          </div>
        </div>
        <div
          style={{
            color: "#808080",
            fontSize: "14px",
            textAlign: "start",
            margin: "10px 6px 0px 6px",
            borderBottom: "2px solid #F2F2F2",
          }}
        >
          {prop == "inventory" ? "Total Stock Value" : "Total Sales Value"}
        </div>
      </div>
    </div>

  </div>
);
};

export default SpareInventory;
