import React, { useState } from "react";
import"../../work-order/workorder.scss"
import moment from "moment";

const RejectionDetails = ({ close, rowdata }) => {  
console.log(rowdata)
  return (
    <>
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "600px", height: "375px", padding: "15px" }}
      >
        <div className="header">
          <h3>View Details</h3>
        </div>

        <div className="body" style={{height:"300px",marginTop:"20px",flexDirection:"column"}}>
       <div
        style={{
            width:"100%",
          display: "flex",
          flexDirection: "column",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request ID:{rowdata.requestID}</span>
          <span>WO ID:{rowdata.orderID}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request Date:{rowdata.requestDate}</span>
          <span>WO Type:{rowdata.workType}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request Type:{rowdata.requestType}</span>
          <span>Total Requested Component:{}</span>
        </div>
      </div>
      <br/>
      </div>
      <div style={{ width:'100%', textAlign:'left'}}> 
      <span>Request Rejection Details</span>
      <br />
      <div style={{width:'98%',margin:'2px', textAlign:'left', background:'white',boxShadow:'0px 0px 1px grey', padding:'10px', borderRadius:'5px', display:'flex', flexDirection:'column'}}>
     <span>Rejection Date: {moment(rowdata.rejectionDate).format("DD-MMM-YYYY HH:mm")}</span>
     <span>Rejected By: {rowdata.rejectedBy}</span>
     <span>Rejection Reason: {rowdata.rejectionReason}</span>
      </div>
      </div>
       
        
        <br />
        <div className="footer">
          <button onClick={() => close(false)}>Back</button>
        </div>
      </div>
    </div>
    </>
  );
};

export default RejectionDetails;