import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";

const ViewLabours = ({ labourData, close }) => {
  console.log(labourData)
  return (
    <div>
      <div className="container1">
        <div className="modalContainer" style={{ width: "72%" }}>
          <div className="header">
            <h3> BOM Labour Details</h3>
          </div>

          <div className="body">
            <table className="viewcomp_tbl" width="100%">
              <tr>
                <th>Labour</th>
                <th>Labour Level</th>
                <th>Required Time</th>
                <th>Required Labour</th>
              </tr>
              {
                labourData === undefined ?
                  <div style={{ marginLeft: "170%", color: "red" }}>No Data Found!</div>
                  :
                  labourData.map((item) => {
                    return (
                      <tr>
                        <td className="leftcell">{item.laborCategory}</td>
                        <td>{item.laborLevel}</td>
                        <td>{`${item.requiredDuration}  ${item.laborDuration}`}</td>
                        <td className="rightcell">{item.requiredWorkers}</td>
                      </tr>
                    )
                  })}
            </table>
          </div>
          <div className="footer">
            <button onClick={() => close(false)}> <KeyboardBackspace /> Back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLabours;
