import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";

const ServiceJobs = React.lazy(() => import("./ServiceJobs"))
const ServiceCenterSalesReport = React.lazy(() => import("./ServiceCenterSalesReport"))


const tabs = [
  {
    id: "SVCM5T1",
    label: TabLabels.SERVICE_JOBS,
    Component: ServiceJobs
  },
  {
    id: "SVCM5T2",
    label: TabLabels.SALES_REPORT,
    Component: ServiceCenterSalesReport
  }
];

const Jobs = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  )
};

export default Jobs;
