import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import AddReturns from './AddReturns';
// import PlaceReturns from "./PlaceReturns/PlaceReturns";
// import ReturnProgress from "./ReturnProgress";

const AddReturns = React.lazy(()=>import('./AddReturns'))
const PlaceReturns = React.lazy(()=>import("./PlaceReturns/PlaceReturns"))
const ReturnProgress = React.lazy(()=>import("./ReturnProgress"))


const tabs = [
  {
    id:"SHO5T1",
    label: TabLabels.ADD_RETURNS,
    Component: AddReturns
  },
  {
    id:"SHO5T2",
    label: TabLabels.PLACE_RETURNS,
    Component: PlaceReturns
  },
  {
    id:"SHO5T3",
    label: TabLabels.RETURN_PROGRESS,
    Component: ReturnProgress
  }
];

const Returns = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default Returns;
