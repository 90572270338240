import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import "../../../../../components/modal/modal.scss";

import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import API from "../../../../../utils/api";
import { errorNotification, successNotification } from "../../../../../utils/notifications";


const GenerateGNI = ({ updatedData, rowData, closeModal, data, closeMain }) => {

  console.log("data", data)


  const submitHandler = async () => {

    const obj = {
      "rpiNo": rowData.rpiNo,
      "shipmentBy": data.shipmentBy,
      "shipmentNo": data.shipmentNo,
      "shipmentDate": data.shipmentDate,
      "deliveryVehicleNo": data.deliveryVehicleNo,
      "deliveryPersonName": data.deliveryPersonName,
      "deliveryPersonPhone": data.deliveryPersonPhone,
    }

    const resp = await trackPromise(API.put(`storeManager-module/api/v1/returns/generateGIN`, obj));
    resp.success ? successNotification(resp.message) : errorNotification(resp.message);
    closeModal(false);
    closeMain(false);
  };


  const refresh = () => {
    updatedData();
  };



  return (
    <div className="container1">
      <div className="modalContainer" style={{ height: "200px", width: "400px" }}>

        <div className="body">

          <h5>Are you sure want to generate the GIN with required details ? </h5>


        </div>
        <div className="footer" style={{ justifyContent: "center" }}>
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={(e) => submitHandler(e)}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenerateGNI