import React, { useState } from "react";
import {
  faEdit,
  faBan,
  faTrash,
  faDotCircle,
  faEye,
  faCircle,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../components/modal/modal.scss";
import PutOnHold from "./Actions/PutOnHold";
import Reject from "./Actions/Reject";
import RequestForProcess from "./Actions/RequestForProcess";
import ViewUpdates from "./Actions/ViewUpdates";
import ViewDetails from "./Actions/ViewDetails";
import RequestApproval from "./Actions/RequestApproval";
import PlaceGoodReleaseRequest from "./Actions/PlaceGoodReleaseRequest";
import PlaceToInventory from "./Actions/PlaceToInventory";
import ReleasePlacetoInventory from "./Actions/PlaceToFinance";
import PlaceToFinance from "./Actions/PlaceToFinance";
import RequestCompletedByInventory from "./Actions/RequestCompletedByInventory";
import RequestForReleaseAndProcess from "./Actions/RequestForReleaseAndProcess";
import ReturnRequestViewDetails from "./Actions/ReturnRequestViewDetails";
import CNGeneratedDetails from "./Actions/CNGeneratedDetails";
import RequestForProcessDetails from "./Actions/RequestForProcessDetails";

const Action = ({ updatedData, rowData, setIsOpen , rowIndex}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      placeToInventory: false,
      putOnHold: false,
      reject: false,
      releasePlaceToInventory: false,
      requestForProcess: false,
      viewUpdate: false,
      viewDetails: false,
      requestApproval: false,
      placeGoodReleaseRequest: false,
      placeToFinance: false,
      requestForReleaseAndProcess: false,
      returnRequestViewDetails: false,
      viewAndShare: false,
      requestCompltedViewDetails: false,
      verificationCompltedViewDetails: false,
      requestForProcessDetails: false,
      returnSettleViewDetails:false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.placeToInventory && showModal && (
        <PlaceToInventory
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.putOnHold && showModal && (
        <PutOnHold
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.reject && showModal && (
        <Reject
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.releasePlaceToInventory && showModal && (
        <ReleasePlacetoInventory
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.requestForReleaseAndProcess && showModal && (
        <RequestForReleaseAndProcess
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.viewDetails && showModal && (
        <ReturnRequestViewDetails
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.requestForProcess && showModal && (
        <RequestForProcess
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.viewUpdate && showModal && (
        <ViewUpdates
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.verificationCompltedViewDetails && showModal && (
        <ViewDetails
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}
      {activeModal.requestCompltedViewDetails && showModal && (
        <RequestCompletedByInventory
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.requestApproval && showModal && (
        <RequestApproval
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.placeGoodReleaseRequest && showModal && (
        <PlaceGoodReleaseRequest
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.placeToFinance && showModal && (
        <PlaceToFinance
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.viewAndShare && showModal && (
        <CNGeneratedDetails
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.returnSettleViewDetails && showModal && (
        <CNGeneratedDetails
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      {activeModal.requestForProcessDetails && showModal && (
        <RequestForProcessDetails
          updatedData={updatedData}
          rowData={rowData}
          closeModal={setShowModal}
        />
      )}

      <div
        className="action"
        style={{ position: "relative" , marginTop: rowIndex <= 4 ? "0px" : "-145px",}}
        onBlur={() => setIsOpen(false)}
      >
        <div
          className="action vendoraction"
          style={{ marginLeft: "-90px", marginTop: "0px", width: "270px" }}
        >
          {rowData?.status === "Return Request Placed" ? (
            <div>
              {" "}
              <span id="placeToInventory" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Place to Inventory
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData?.status === "Return Request Placed" ? (
            <div>
              <span id="putOnHold" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Put On Hold
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData?.status === "On Hold" ? (
            <div>
              <span
                id="releasePlaceToInventory"
                onClick={(e) => clickHandler(e)}
              >
                <FontAwesomeIcon icon={faEdit} /> Release & Place to Inventory
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData?.status === "Return Request Placed" ||
          rowData?.status === "On Hold" ? (
            <div>
              <span id="reject" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Reject Request
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData?.status === "Pending At Inventory" ? (
            <div>
              <span id="requestForProcess" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faCircleNotch} /> Request For Process
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData?.status === "In-Progress at Inventory" ? (
            <div>
              <span id="viewUpdate" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> View Update
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData?.status === "Verification Completed" ? (
            <div>
              <span
                id="verificationCompltedViewDetails"
                onClick={(e) => clickHandler(e)}
              >
                <FontAwesomeIcon icon={faEye} /> View Details
              </span>
            </div>
          ) : (
            ""
          )}

          {
          rowData?.status === "Hold at Finance" ? (
            <div>
              <span id="viewDetails" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEye} /> View Details
              </span>
            </div>
          ) : (
            ""
          )}
          {
          rowData?.status === "Return Settled" ? (
            <div>
              <span id="returnSettleViewDetails" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEye} /> View Details
              </span>
            </div>
          ) : (
            ""
          )}


          {rowData?.status === "Request Completed" ? (
            <div>
              <span
                id="requestCompltedViewDetails"
                onClick={(e) => clickHandler(e)}
              >
                <FontAwesomeIcon icon={faEye} /> View Details
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData?.status === "CN Generated" ? (
            <div>
              <span id="viewAndShare" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEye} /> View & Share
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData?.status === "Held by Finance" ? (
            <div>
              <span id="requestApproval" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Request Approval
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData?.status === "Approved By Finance" ? (
            <div>
              <span
                id="placeGoodReleaseRequest"
                onClick={(e) => clickHandler(e)}
              >
                <FontAwesomeIcon icon={faEdit} /> Place Good Release Request
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData?.status === "Request Completed" ? (
            <div>
              <span id="placeToFinance" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Place To Finance
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData?.status === "Hold at Finance" ? (
            <div>
              <span
                id="requestForReleaseAndProcess"
                onClick={(e) => clickHandler(e)}
              >
                <FontAwesomeIcon icon={faCircle} /> Request For Release &
                Process
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData?.status === "Pending at Finance" ? (
            <div>
              <span
                id="requestForProcessDetails"
                onClick={(e) => clickHandler(e)}
              >
                <FontAwesomeIcon icon={faCircle} /> Request for Process
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Action;
