import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';
import moment from 'moment';


function ComponentReturnGraph({startDate, endDate}) {
    const [compGraphData, setCompGraphData] = useState({});

    const fetchCompGraphData = async () => {
        const resp = await trackPromise(API.get(startDate==null && endDate==null ?`inventory-module/api/v1/dashboard/getComponentReturns` : `inventory-module/api/v1/dashboard/getComponentReturns?startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}`))
        setCompGraphData(resp);
    };
    useEffect(() => {
        fetchCompGraphData();
    }, [endDate]);

    return (
        <div style={{ width: "53%", marginLeft: "10px" }}>
            <Pie
                data={{
                    datasets: [{
                        data: [compGraphData.totalReqs, compGraphData.managedReqs],
                        backgroundColor: [
                            'rgba(77, 87, 188, 1)',
                            'rgba(74, 175, 84, 1)'
                        ],
                        options: {
                            cutoutPercentage: 80
                        },
                        hoverOffset: 4,
                    }
                    ],
                    labels: ["Total Requests", "Managed Requests"]
                }} />
            <div style={{ margin: "-70% 0 0 100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(77, 87, 188, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "160px", fontSize: "14px", fontWeight: "500", marginTop: "3px" }}>Total Requests</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "700", marginTop: "3px" }}>
                        {compGraphData.totalReqs}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(74, 175, 84, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "160px", fontSize: "14px", fontWeight: "500", marginTop: "3px" }}>Managed Requests</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "700", marginTop: "3px" }}>
                        {compGraphData.managedReqs}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ComponentReturnGraph