import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Chart as ChartJS, registerables } from 'chart.js'
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';
ChartJS.register(...registerables)
ChartJS.defaults.plugins.legend.display = false;

export default function StoreOrdersCard() {

    const [ordersData, setOrdersData] = useState({})

    const fetchReport = async () => {
        const result = await trackPromise(API.get(`/storeManager-module/api/v1/dashboard/getServiceJobsCount`));
        console.log(result);
        setOrdersData(result);
    };
    useEffect(() => {
        fetchReport();
    }, []);


    return (
        <div style={{ width: "95%", marginLeft: "10px" }}>
            <ProgressBar>
                <ProgressBar now={ordersData.totalReqs * 100} style={{ background: "#0A18A3" }} />
                <ProgressBar now={ordersData.newReqs * 100} style={{ background: "rgba(253, 170, 72, 1)" }} />
                <ProgressBar now={ordersData.onHoldReqs * 100} style={{ background: "rgba(255, 215, 74, 1)" }} />
                <ProgressBar now={ordersData.POGenerated * 100} style={{ background: "rgba(33, 168, 46, 1)" }} />
                <ProgressBar now={ordersData.rejectedReqs * 100} style={{ background: "rgba(237, 52, 75, 1)" }} />
            </ProgressBar>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#0A18A3", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Total Requisitions</span>
                </div>
                <div>
                    {ordersData.totalReqs}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(253, 170, 72, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>New Requisitions</span>
                </div>
                <div>
                    {ordersData.newReqs}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(255, 215, 74, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Requisitions On Hold</span>
                </div>
                <div>
                    {ordersData.onHoldReqs}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(33, 168, 46, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>PO Generated</span>
                </div>
                <div>
                    {ordersData.POGenerated}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(237, 52, 75, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Requisitions Rejected</span>
                </div>
                <div>
                    {ordersData.rejectedReqs}
                </div>
            </div>
        </div>
    )
}
