import AppConstants from "./app-constants";

const auth = {
  setToken: authToken => localStorage.setItem("AUTH_TOKEN", authToken),
  
  getToken: () => localStorage.getItem("AUTH_TOKEN"),
  
  setRefreshToken : authRefreshToken => localStorage.setItem("REFRESH_TOKEN", authRefreshToken),
  getRefreshToken: () => localStorage.getItem("REFRESH_TOKEN"),

  setUserInfo: user =>
    localStorage.setItem("USER_INFO", JSON.stringify(user)),
  getUserInfo: () => {
    const user = localStorage.getItem("USER_INFO");
    return JSON.parse(user || "{}");
  },
  isSuperAdmin: function() {
    return (
      (this.getUserInfo()).role === "Super Admin"
    )
  },
  isSubAdmin: function() {
    return (
      (this.getUserInfo()).role === "Sub Admin"
    );
  },
  isProcurement: function() {
    return (
      (this.getUserInfo()).role === "Procurement"
    );
  },
  isProduction: function() {
    return (
      (this.getUserInfo()).role === "Production"
    );
  },
  isInventory: function() {
    return (
      (this.getUserInfo()).role === "Inventory"
    );
  },
  isShopfloor: function() {
    return (
      (this.getUserInfo()).role === "Shop Floor"
    );
  },
  isManagement: function() {
    return (
      (this.getUserInfo()).role === "Management"
    );
  },
  isQc: function() {
    return (
      (this.getUserInfo()).role === "Quality Control"
    );
  },
  isFinance: function() {
    return (
      (this.getUserInfo()).role === "Finance"
    );
  },
  isDealer: function() {
    return (
      (this.getUserInfo()).role === "Dealer"
    );
  },
  isServiceCenter: function() {
    return (
      (this.getUserInfo()).role === "Service Center"
    );
  },
  isShowroom: function() {
    return (
      (this.getUserInfo()).role === "Showroom"
    );
  },
  isStoreManager: function() {
    return (
      (this.getUserInfo()).role === "Store Manager"
    );
  },
  isSystemAdmin: function() {
    return (
      (this.getUserInfo()).role === "System Admin"
    );
  },
  isOperations: function() {
    return (
      (this.getUserInfo()).role === "Operations"
    );
  },
  isServiceAdvisor: function() {
    return (
      (this.getUserInfo()).role === "Service Advisor"
    );
  },
  isAreaSales: function() {
    return (
      (this.getUserInfo()).role === "Area Sales"
    );
  },
  isZonalSales: function() {
    return (
      (this.getUserInfo()).role === "Zonal Sales"
    );
  },
  isNationalsales: function() {
    return (
      (this.getUserInfo()).role === "National Sales"
    );
  },
  isAuthenticated: function() {
    return this.getToken();
  },
  setConfig: data =>
    localStorage.setItem(AppConstants.CONFIG_KEY, JSON.stringify(data)),
  getConfig: () =>
    JSON.parse(localStorage.getItem(AppConstants.CONFIG_KEY) || "{}"),
  clearAll: () => localStorage.clear()
};


export default auth;



