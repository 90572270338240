import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import auth from "../../../utils/auth";
import ViewSpecs from "./ViewSpecs";

const ViewComponentsCost = ({ closeModal, row }) => {

  const UsersData = row.bomComponents;
  const [viewSpecs, setViewSpecs] = useState(false);
  const [specsData, setSpecsData] = useState({});

  const handleViewSpecs = (item, index) => {
    setViewSpecs(true)
    setSpecsData(item)
  }

  return (
    <div className="container1">
      {viewSpecs && <ViewSpecs specs={specsData} closeModal={setViewSpecs} />}
      <div
        className="modalContainer"
        style={{ width: "90%", height: "400px", padding: "15px" }}
      >
        <div className="header">
          <h3>BOM Components Details</h3>
        </div>

        <br />
        <div className="body">

          <div style={{ width: "100%", }}>
            <div id="costingTableHeading" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '12%', wordWrap: 'break-word', wordBreak: 'break-all' }}>Component Name</div>
              <div style={{ width: '12%' }}>SKU Code</div>
              <div style={{ width: '12%' }}>Category</div>
              <div style={{ width: '12%' }}>Sub Category</div>
              <div style={{ width: '12%' }}>Specification</div>
              <div style={{ width: '12%' }}>Quantity</div>
              <div style={{ width: '12%' }}>{`Unit Price (${auth.getUserInfo().currencySymbol})`}</div>
              <div style={{ width: '12%' }}>{`Total Price (${auth.getUserInfo().currencySymbol})`}</div>
            </div>
            <div style={{ height: '230px', overflowY: "scroll" }}>
              {UsersData?.map((item, index) => (
                <div id="costingTableContent" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ overflowY: "auto", width: '12%' }}>{item?.componentName}</div>
                  <div style={{ overflowY: "auto", width: '12%' }}>{item?.skuCode}</div>
                  <div style={{ overflowY: "auto", width: '12%' }}>{item?.category}</div>
                  <div style={{ overflowY: "auto", width: '12%' }}>{item?.subCategory}</div>
                  <div style={{ overflowY: "auto", width: '12%' }}><span onClick={() => handleViewSpecs(item, index)} style={{ color: "#0a18a3", fontWeight: "500", cursor: 'pointer' }}> View Specs</span></div>
                  <div style={{ overflowY: "auto", width: '12%' }}>{item?.componentQuantity}</div>
                  <div style={{ overflowY: "auto", width: '12%' }}>{5}</div>
                  <div style={{ overflowY: "auto", width: '12%' }}>{item?.componentQuantity * 5}</div>
                </div>
              ))}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "8px" }}>{`Total Cost (${auth.getUserInfo().currencySymbol})`}</span>
                <span
                  style={{
                    padding: "7px",
                    width: "141px",
                    backgroundColor: "#e5e5e5",
                  }}
                >
                  {row.totalComponentsCost}
                </span>
              </div>
            </div>
          </div>


        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}><KeyboardBackspace /> Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewComponentsCost;