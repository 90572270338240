// const SalesInvoice = ({ close, data }) 

import React, { useState } from "react";
import auth from "../../../../utils/auth";
import ReactToPrint from "react-to-print";
import { Close } from "@material-ui/icons";


const SalesInvoice = ({ closeModal, rowData }) => {
   
  let componentRef = null;



  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          width: "570px",
          height: "625px",
          padding: "15px",
          borderRadius: "0px",
          borderTop: "none",
        }}
      >
         <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Sales Invoice</h3>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <button
                    style={{
                      color: "blue",
                      margin: "1px",
                      height: "27px",
                      width: "78px",
                      border: "none",
                    }}
                  >
                    Download
                  </button>
                );
              }}
              content={() => componentRef}
              documentTitle="Tax Invoice"
              pageStyle="Print"
            />
          </div>
        </div>


        <div className="body">
          <div 
             style={{
            width: " 100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            // pageBreakBefore:"always",
          }}
          ref={(el) => (componentRef = el)}
          >
         

        <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{
                backgroundColor:"#185CC2",
                color:"white",
                width:"400px",
                height:"10px",
                paddingRight:"12px",
                display:"flex",
                margin:"20px 20px 20px 20px",
                marginTop:"10px",
                flexGrow:"1",
                }}>

                </div>

              <div style={{display:"flex",flexDirection:"column"}}>
              <div>
                <h4>Sales Invoice</h4>
              </div>
              </div>

              <div style={{
                backgroundColor:"#185CC2",
                color:"white",
                width:"20px",
                height:"10px",
                paddingRight:"12px",
                display:"flex",
                marginLeft:"10px",
                marginTop:"10px",
                }}>

                </div>
            </div>     

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>Showroom Business Name</span>
              <span>Invoice Date : {}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>Showroom Address :</span>
              <span>Customer Name : {}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>Showroom Business Email :</span>
              <span>Customer Email : {}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>GSTIN :</span>
              <span>Customer Phone : {}</span>
            </div>
            
          </section>

          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{
                backgroundColor:"#185CC2",
                color:"white",
                width:"2100%",
                height:"2px",
                paddingLeft:"12px",
                }}></span>
            </div>



            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "400",
                marginTop:"7px"
              }}
            >
              <span style={{fontSize:"15px",fontWeight:"bold"}}>Vehicle Information</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>Vehicle Segment : </span>
              <span>Vehicle Category: </span>
              <span>Vehicle Model : </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>Vehicle Variant : </span>
              <span>Vehicle Type : </span>
              <span>Vehicle Color : </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{
                backgroundColor:"#185CC2",
                color:"white",
                width:"2100%",
                height:"2px",
                paddingLeft:"12px",
                }}></span>
            </div>
          </section>


          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent:"space-between",
              fontSize: "12px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >


            <div
              style={{
                display: "flex",
                flexDirection:"column",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span style={{ fontSize:"15px",fontWeight:"bold"}}>Description : </span>
              <span>Model : </span>
              <span>RTO Charges : </span>
              <span>Other Charges : </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection:"column",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span style={{ fontSize:"15px",fontWeight:"bold"}}>Qty. : {} </span>
              <span> - : </span>
              <span> - : </span>
              <span> - : </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection:"column",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span style={{ fontSize:"15px",fontWeight:"bold"}}>Unit Price (INR) : </span>
              <span>{"500"}</span>
              <span>{"500"}</span>
              <span>{"500"}</span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection:"column",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span style={{ fontSize:"15px",fontWeight:"bold"}}>Total (INR) : </span>
              <span>{"1500"}</span>
              <span>{"1500"}</span>
              <span>{"1500"}</span>
            </div>
          </section>

          <section
          style={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between"
          }}
          >
            <div
             style={{
            display:"flex",
            flexDirection:"column",
            justifyContent:"center", 
          }}
          >
              <p>Terms & condition</p>
            </div>
            <div
             style={{
                display: "flex",
                flexDirection:"row",
                justifyContent: "space-between",
                marginRight:"70px",
              }}>
            <div
             style={{
                display: "flex",
                flexDirection:"column",
              }}>
              <span>Sub Total : </span>
              <span>SGST : </span>
              <span>CGST : </span>
              <span>Total : </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection:"column",
                marginRight:"-20px"
              }}>
              <span>{"500"}</span>
              <span>{"500"}</span>
              <span>{"500"}</span>
              <span>{"500"}</span>
            </div>

            </div>
          </section>
          <div
          style={{
            display:"flex",
            flexDirection:"column",
            justifyContent:"end",
          }}>
            <p>This is computer generated invoice</p>
          </div>
          </div>
        </div>


        <div className="footer">
          <button
            style={{ width: "104px", height: "35px", background: "#fff" }}
            onClick={()=>closeModal(false)}
          >
            <Close style={{ fontSize: "25px" }} />
            Close
          </button>
        </div>

      </div>
    </div>
  );
};

export default SalesInvoice;

