import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";

const CR_ReturnQty = ({ row, closeModal }) => {
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "580px", height: "480px", padding: '15px' }}
            >
                <div className="header" style={{ width: '100%', display: "flex", justifyContent: "flex-start" }}>
                    <h3>Return Qty.</h3>
                </div>

                <div
                    className="body"
                    style={{ width: '100%', display: "flex", fontSize: "14px" }}
                >


                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                            <span>Component Name: {row.sparePartname}</span>
                            <span>Category: {row.category}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                            <span>Component SKU Code: {row.skuCode}</span>
                            <span>Sub Category: {row.subCategory}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                            <span>Return Qty.: {row.returnQty?.length}</span>
                        </div>

                    </section>

                    <section
                        id="nr_POno"
                        style={{
                            width: '100%',
                            fontSize: "14px",
                            margin: "10px 0",
                            border: 'none'
                        }}
                    >
                        <div style={{ padding: '0px', border: '1px solid #cdcdcd' }}>
                            <span style={{ width: '10%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>S.No.</span>
                            <span style={{ width: '45%', padding: '10px' }}>Part Serial No.</span>
                            <span style={{ width: '45%', padding: '10px' }}>PO No.</span>
                        </div>
                        <section style={{ width: "100%", height: '180px', overflow: 'scroll' }}>
                            {row.returnQty.map((item, index) => (
                                <div style={{ padding: '0px', border: '1px solid #cdcdcd', borderTop: 'none' }}>
                                    <span style={{ width: '10%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>{index + 1}</span>
                                    <span style={{ width: '45%', padding: '10px' }}>{item.partSno}</span>
                                    <span style={{ width: '45%', padding: '10px' }}>{item.poNo}</span>
                                </div>
                            ))}
                        </section>

                    </section>

                </div>

                <div className="footer" style={{ width: '100%', display: "flex", justifyContent: "flex-end" }}>
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
    );
};

export default CR_ReturnQty;