import React, { useEffect, useState } from "react";
import {
  faDownload,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import SearchBox from "../../components/search/SearchBox";
import moment from "moment";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import ViewWarrantyShowroom from "./viewWarrantyShowroom";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DealerVehiclePO from "../RequisitionOrders/DealerVehiclePO";
import auth from "../../utils/auth";

function SKUWiseInventory({ inDashboard }) {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [warrantyShow, setWarrantyShow] = useState();
  const [viewWarranty, setViewWarranty] = useState(false);
  const [formValue, setFormValue] = useState({
    vehicleSegment: "",
    vehicleCategory: "",
    vehicleColor: "",
    vehicleSkuCode: "",
    vehicleModel: "",
    vehicleVariant: "",
    poNo: "",
    invoiceNo: "",
  });
  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      vehicleSegment: "",
      vehicleCategory: "",
      vehicleColor: "",
      vehicleSkuCode: "",
      vehicleModel: "",
      vehicleVariant: "",
      poNo: "",
      invoiceNo: "",
    });

    const resp = await trackPromise(
      API.get(`showroom-module/api/v1/inventory/vehicleInvenrtory`)
    );
    {
      resp.success == false
        ? errorNotification(resp.message, { autoClose: 3000 })
        : setUsersData(resp);
    }
  };
  const handleClick = (e) => {
    setShow(!show);
  };

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `showroom-module/api/v1/inventory/vehicleInvenrtory?vehicleSegment=${formValue.vehicleSegment}&vehicleCategory=${formValue.vehicleCategory}&vehicleColor=${formValue.vehicleColor}&poNo=${formValue.poNo}&vehicleModel=${formValue.vehicleModel}&vehicleVariant=${formValue.vehicleVariant}&vehicleSkuCode=${formValue.vehicleSkuCode}&invoiceNo=${formValue.invoiceNo}`
      )
    );
    console.log(resp);
    setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const uniquevehicleColor = [
    ...new Set(UsersData.map((item) => item.vehicleColor)),
  ];
  const uniquevehicleModel = [
    ...new Set(UsersData.map((item) => item.vehicleModel)),
  ];
  const uniquevehicleVariant = [
    ...new Set(UsersData.map((item) => item.vehicleVariant)),
  ];
  const uniquevehicleSkuCode = [
    ...new Set(UsersData.map((item) => item.vehicleSkuCode)),
  ];

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return moment(date).format("DD-MMM-YYYY");
  }

  const stateHandler = (rowIndex) => {
    setWarrantyShow(rowIndex);
    setViewWarranty(true);
  };

  const warrantyFormatter = (cell, row, rowIndex) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => stateHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          View Details
        </button>
        {warrantyShow === rowIndex && viewWarranty ? (
          <ViewWarrantyShowroom
            vehicleSkuCode={row.vehicleSkuCode}
            close={setViewWarranty}
          />
        ) : null}
      </div>
    );
  };

  const sum = UsersData.reduce((a, b) => {
    return a + parseInt(b.unitPrice);
  }, 0);

  let hybridCount = 0;
  let standardCount = 0;
  UsersData.map((item) => {
    if (item.vehicleCategory == "Hybrid") {
      return hybridCount++;
    } else {
      return standardCount++;
    }
  });

  const [openQty, setOpenQty] = useState();

  const qtyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "14px",
            color: "blue",
            cursor: "pointer",
            margin: "0px",
          }}
          onClick={() => setOpenQty(rowIndex)}
        >
          {row.poNo}
        </p>
        {openQty === rowIndex && (
          <DealerVehiclePO
            rowData={row}
            closeModal={setOpenQty}
            type="showroom"
          />
        )}
      </div>
    );
  };
  const totalValue = UsersData?.reduce((accumulator, item) => {
    let priceToDealer = item?.pricetoDealer;
    let currentInvQty = item?.currentInvQty;
    return accumulator + priceToDealer * currentInvQty;
  }, 0);

  console.log(UsersData);
  const totalInventoryFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row);
    const formattedPrice = ((cell||0) * (row?.currentInvQty || 0)).toLocaleString('en-IN', {
      minimumFractionDigits: 2, // Ensures two decimal places
      maximumFractionDigits: 2,
    });
    return `${formattedPrice}`;
  };
  const priceFormatter = (cell, row) => {
    // const currencySymbol = auth.getUserInfo().currencySymbol; // Get currency symbol dynamically
    const formattedPrice = (cell || 0).toLocaleString('en-IN', {
      minimumFractionDigits: 2, // Ensures two decimal places
      maximumFractionDigits: 2,
    });
    
    return `${formattedPrice}`;
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "SKU Code",
      dataField: "vehicleSkuCode",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },

    {
      text: "Model",
      dataField: "vehicleModel",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Variant",
      dataField: "vehicleVariant",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Color",
      dataField: "vehicleColor",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: `Unit Price (${auth.getUserInfo().currencySymbol})`,
      dataField: "pricetoDealer",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: priceFormatter,
    },
    {
      text: "Current Inventory",
      dataField: "currentInvQty",
      headerAttrs: { width: "180px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: `Inventory Value (${auth.getUserInfo().currencySymbol})`,
      dataField: "pricetoDealer",
      headerAttrs: { width: "180px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: totalInventoryFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>SKU Wise Inventory</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "manageUsers")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div
                    className="UHinputfields"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "135px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          SKU Code
                        </label>
                        <select
                          name="vehicleSkuCode"
                          value={formValue.vehicleSkuCode}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" selected>
                            SKU Code
                          </option>
                          {uniquevehicleSkuCode.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Model
                        </label>
                        <select
                          name="vehicleModel"
                          value={formValue.vehicleModel}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Model
                          </option>
                          {uniquevehicleModel.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Variant
                        </label>
                        <select
                          name="vehicleVariant"
                          value={formValue.vehicleVariant}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Variant
                          </option>
                          {uniquevehicleVariant.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Color
                        </label>
                        <select
                          name="vehicleColor"
                          value={formValue.vehicleColor}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Color
                          </option>
                          {uniquevehicleColor.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>

                      <div
                        style={{
                          justifyContent: "end",
                          width: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <button
                          id="apply"
                          style={{ marginTop: "22px", marginLeft: "10px" }}
                          onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ marginTop: "22px", width: "auto" }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  width: "30%",
                  justifyContent: "space-between",
                  background: "#F3F3F3",
                  marginTop: "10px",
                }}
              >
                <h6 style={{ margin: "5px", color: "rgb(54, 54, 54)" }}>
                  Total Inventory Value ({auth.getUserInfo().currencySymbol}) :{" "}
                  <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  {Math.ceil(totalValue).toLocaleString('en-IN', { minimumFractionDigits: 2 })}
                  </span>
                </h6>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

export default SKUWiseInventory;
