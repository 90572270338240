import React, { useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import UsedViewQuantity from "./UsedViewQuantity";

const ViewHybridDetails = ({ closeModal, data }) => {
  console.log(data);
  const [openQuant, setOpenQuant] = useState(false);
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState();
  const [actionShow, setActionShow] = useState();

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = () => {
    setOpen(true);
    on();
  };

  let count = 0;
  data.map((item, index) => {
    count++;
  });

  const handleStatusChange = (e, id) => {};

  return (
    <div className="container1">
      <div id="overlay" onClick={(e) => off(e)}></div>
      <div
        className="modalContainer"
        style={{ width: "550px", height: "400px", padding: "15px" }}
      >
        <div className="header">
          <h3>View Details</h3>
        </div>

        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
            margin: "10px 0",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Vehicle Category : {data[0].vehicleCategory}</span>
              <span>Vehicle Model : {data[0].vehicleModel}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Vehicle Segment : {data[0].vehicleSegment}</span>
              <span>Vehicle Variant : {data[0].vehicleVariant}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Vehicle Type : {data[0].vehicleType}</span>
            </div>
          </div>
          <br />
          <div
            className="active-btn"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "25%",
              height: "30px",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span className="active-btn-font">Total Qty - {count}</span>
          </div>
        </section>

        <div
          className="body"
          style={{
            display: "flex",
            height: "300px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div id="POVendorHeading">
              <div style={{ width: "30%" }}>Color</div>
              <div style={{ width: "70%" }}>VIN</div>
            </div>

            <div>
              {data.map((item, index) => {
                return (
                  <>
                    <div
                      id="POVendorContent"
                      style={{
                        borderBottom: "1px solid #999999",
                        boxShadow: "none",
                        padding: "0px",
                      }}
                    >
                      <div style={{ width: "30%", overflowY: "auto" }}>
                        {item.color}
                      </div>
                      <div style={{ width: "70%", overflowY: "auto" }}>
                        {item.VIN}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </section>
        </div>

        <div className="footer">
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 15px",
              marginRight: "10px",
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "17px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewHybridDetails;
