import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";


const SparesInventory = React.lazy(()=>import('./sparesInventory'))
const SkuWiseInventory = React.lazy(()=>import('./SkuWiseInventory'))


const tabs = [
  {
    id:"SVCM4T1",
    label: TabLabels.SKU_WISE_INVENTORY,
    Component: SkuWiseInventory
  },
  {
    id:"SVCM4T2",
    label: TabLabels.DETAILED_SPARES_INVENTORY,
    Component: SparesInventory
  }
];

const ServiceCenterInventory = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  )
};

export default ServiceCenterInventory;
