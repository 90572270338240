import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import "../settings-tabs/homesettings.scss";
import "../../pages/orders/Orders.scss";
import { CheckCircle, CancelOutlined } from "@material-ui/icons";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";
import ManageCompRequest from "./manageCompRequests";

const Components = ({ inDashboard }) => {
  const [showManage, setShowManage] = useState(true);
  const [selectedComponent, setSelectedComponent] = useState({});
  const [updatetable, setUpdateTable] = useState();
  const [calculatedReceivedUnits, setReceivedUnits] = useState();
  const [blankForm, setBlankForm] = useState([]);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`inventory-module/api/v1/inventoryGoodsVerification/getAllGRNID`)
    );
    console.log(resp);
    resp.success === false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setBlankForm(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const handleInputChange = async (event) => {
    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/inventorygoodsverification/onGRNIDSelect?GRNID=${event.target.value}`
      )
    );
    resp.success == false
      ? errorNotification(resp.message)
      : setSelectedComponent(resp);
    let finalUnits = resp.receivedUnits.reduce((item1, item2) => {
      return item1 + parseInt(item2.receivedQuantity);
    }, 0);
    console.log(finalUnits);
    setReceivedUnits(finalUnits);
    setShowManage(true);
    console.log(resp);
  };

  const submitHandler = async () => {
    let requestData = {
      receivedComponents: selectedComponent.receivedComponents,
      requestCategory: "PO Components",
      receivedUnits: calculatedReceivedUnits,
      requestType: "Verification",
      POID: selectedComponent.purchaseID,
      GRNID: selectedComponent.GRNID,
    };
    console.log(requestData);
    const resp = await trackPromise(
      API.post(
        `inventory-module/api/v1/inventorygoodsverification/placeNewRequest`,
        requestData
      )
    );
    console.log(resp);
    if (resp.success) {
      setUpdateTable(true);
      successNotification(resp.message, { autoClose: 3000 });
      fetchReport();
    } else {
      errorNotification(resp.message, { autoClose: 3000 });
    }
  };

  return (
    <>
      <div>
        {/* {inDashboard == true ? (
          <>
            <div className="bussiness">
              <h4 className="heading">New Request</h4>
            </div>
            <br />
          </>
        ) : (
          ""
        )} */}
        {/* {inDashboard == true ? ( */}
        <div>
          <div
            style={{
              margin: "0px 5px 0px 5px",
              width: "30%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "11px",
              }}
            >
              GRN No.<span style={{ color: "red" }}>*</span>
            </label>
            <select
              required={true}
              disabled={blankForm.length == 0 ? true : false}
              name="GRNID"
              className="input-config"
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              onChange={(event) => handleInputChange(event)}
            >
              <option selected disabled>
                GRN Number
              </option>
              {blankForm.map((item) => {
                return <option key={item.GRNID}>{item.GRNID}</option>;
              })}
            </select>
          </div>

          {Object.keys(selectedComponent).length !== 0 && (
            <>
              <div>GRN Details</div>
              <div>
                <div id="hsnBody">
                  <div style={{ padding: "5px" }}>
                    <section
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "14px",
                        fontWeight: "500",
                        margin: "10px 0",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>PO No.: {selectedComponent.POID}</span>
                        <span>
                          Received Components:{" "}
                          {selectedComponent.receivedComponents}
                        </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Request Category: PO Components</span>
                        <span>Received Units: {calculatedReceivedUnits}</span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Request Type: Verification</span>
                      </div>
                    </section>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "15px 5px",
                  }}
                >
                  <button
                    style={{
                      border: "1px solid #0A18A3",
                      borderRadius: "3px",
                      padding: "5px 15px",
                      marginRight: "10px",
                      color: "#0A18A3",
                    }}
                  >
                    <CancelOutlined style={{ fontSize: "17px" }} />
                    Cancel
                  </button>
                  <button
                    id="componentData"
                    style={{
                      background: "#0A18A3",
                      border: "1px solid #0A18A3",
                      borderRadius: "3px",
                      padding: "5px 15px",
                      color: "white",
                    }}
                    onClick={submitHandler}
                  >
                    <CheckCircle style={{ fontSize: "17px" }} />
                    Submit
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        {/* ) : ( */}
        <ManageCompRequest
          updatedTable={updatetable}
          tabName="components"
          inDashboard={inDashboard}
        />
        {/* )} */}
      </div>
      <br></br>
      <br></br>
    </>
  );
};

export default Components;
