import React, { useState } from "react";
import AppConstants from "../../../utils/app-constants";
import ViewDetails from "./ViewDetails";
import ViewInvHistory from "./ViewInvHistory";
import ViewHybridDetails from "./ViewHybridDetails";
import VisibilityIcon from '@mui/icons-material/Visibility';
import HistoryIcon from '@mui/icons-material/History';


const Actions = ({ rowData , rowIndex }) => {
  console.log(rowData);
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      viewDetails: false,
      viewHybridDetails: false,
      invHistory: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  console.log(rowData);
  return (
    <div>
      {activeModal.viewDetails && showModal && (
        <ViewDetails data={rowData} closeModal={setShowModal} />
      )}
      {activeModal.invHistory && showModal && (
        <ViewInvHistory data={rowData} closeModal={setShowModal} />
      )}
      {activeModal.viewHybridDetails && showModal && (
        <ViewHybridDetails data={rowData} closeModal={setShowModal} />
      )}

      <div style={{ position: "relative" , marginTop: rowIndex <= 4 ? "0px" : "-40px", marginLeft: "-30px", }}>
        <div className="action vendoraction" style={{width:"200px"}}>
          <div>
            <span id="viewHybridDetails" onClick={(e) => clickHandler(e)}>
            <VisibilityIcon/>&nbsp;View Details
            </span>
          </div>
          <div>
            <span id="invHistory" onClick={(e) => clickHandler(e)}>
            <HistoryIcon/>&nbsp; Inventory History
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Actions;
