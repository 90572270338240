import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import ComponentsReturns from "./Components/ComponentsReturns";
import auth from "../../utils/auth";
// import VehicleReturns from "./Components/VehicleReturns";
// import SpareReturns from "./Components/SpareReturns";
// import ReturnNotes from "./Components/ReturnNotes";

const VehicleReturns = React.lazy(()=>import("./Components/VehicleReturns"))
const SpareReturns = React.lazy(()=>import("./Components/SpareReturns"))
const ReturnNotes = React.lazy(()=>import("./Components/ReturnNotes"))
const BatteryReturns = React.lazy(()=>import("./Components/BatteryReturns"))


const FinanceReturn = () => {
  const tabs = [
    {
      id: "FIM5T1",
      label: TabLabels.VEHICLES_RETURNS,
      Component: VehicleReturns
    },
    {
      id: "FIM5T2",
      label: TabLabels.SPARES_RETURN,
      Component: SpareReturns
    },
    {
      id: "FIM5T3",
      label: TabLabels.BATTERY_RETURN,
      Component: BatteryReturns
    },
    {
      id: "FIM5T4",
      label: TabLabels.RETURN_NOTES,  
      Component: ReturnNotes
    },
  ];


  const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
    return tabs.filter(tab => tabIds.includes(tab.id));
  };
  let tabsToShow = getTabs(tabs);

  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>

  )
}
export default FinanceReturn