import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import "../../../components/modal/modal.scss";

const Delete = ({ updatedData, editData, closeModal }) => {
  const [formValue, setFormValue] = useState({
    deleteReason: "",
    description: "",
  });

  const editDetails = {
    componentID: editData.componentID,
    reason: formValue.deleteReason,
    reasonComment: formValue.description,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    if(formValue.deleteReason){

      const resp = await trackPromise(
        API.put(
          `${process.env.REACT_APP_BASE_URL}/procurement-module/api/v1/hsnsac/delete`,
          editDetails
        )
      );
  
      const { success, message } = resp;
  
      if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        refresh();
      } else {
        errorNotification(message, { autoclose: 2000 });
      }
    }
    else{
      return errorNotification("Please select delete reason", { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Delete</h3>
        </div>

        <div className="body">
          <label style={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                textAlign: "start",
              }}
            >
              Deletion Reason<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="deleteReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ marginTop: "-5px" }}
            >
              <option disabled selected hidden>
                Delete Reason
              </option>
              <option value="Misconduct">Misconduct</option>
              <option value="Not an Active User">Not an Active User</option>
              <option value="Change of Role">Change of Role</option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.deleteReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Deactivation Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={formValue.deleteReason==""?"": submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Delete;
