import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faUpload } from "@fortawesome/free-solid-svg-icons";

const EditZone = ({ updatedData, editData, closeModal }) => {
  console.log(editData);
  const [statesArray, setStatesArray] = useState(editData.states);
  const [zoneName, setZoneName] = useState(editData.zone);
  const [zoneData, setZoneData] = useState([]);

  console.log("statesArray-->", statesArray);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`operations-module/api/v1/zoneManagement/getStates`)
    );
    console.log(resp);
    setZoneData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  let finalStateArray = [];

  console.log("finalStateArray yoo-->", finalStateArray);

  editData.states.map((el) => {
    finalStateArray.push({ ...el });
  });

  zoneData.filter((item) => {
    if (item.countryName == editData.countryName) {
      finalStateArray.push(...item.states);
    }
  });

  const onCheckboxChange = (e, item) => {
    console.log(statesArray);
    let tempInfo = [...statesArray];
    if (e.target.checked == true) {
      if (e.target.checked == false) {
        tempInfo = tempInfo.filter((info) => info.stateId != item.stateId);
        e.target.checked == false;
      } else {
        tempInfo.push(item);
      }
    } else {
      if (e.target.checked == false) {
        tempInfo = tempInfo.filter((info) => info.stateId != item.stateId);
      } else {
        tempInfo.push(item);
      }
    }
    setStatesArray(tempInfo);
  };

  var expanded = false;
  const showCheckboxes = () => {
    var checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
      checkboxes.style.display = "block";
      expanded = true;
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  };

  document.body.addEventListener("click", (event) => {
    const multiselect = document.querySelector(".multiselect");
    if (!multiselect.contains(event.target)) {
      // Click occurred outside the multiselect component
      if (expanded) {
        // Close the checkboxes if they are currently expanded
        var checkboxes = document.getElementById("checkboxes");
        checkboxes.style.display = "none";
        expanded = false;
      }
    }
  });

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    let editZonesBody = {
      zoneId: editData.zoneId,
      states: statesArray,
      zone: zoneName,
    };
    const resp = await trackPromise(
      API.put(
        `operations-module/api/v1/zoneManagement/editZones`,
        editZonesBody
      )
    );
    if (resp.success == true) {
      successNotification(resp.message);
      closeModal(false);
      refresh();
    } else {
      errorNotification(resp.message);
    }
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "800px", height: "220px" }}
      >
        <div className="header">
          <h3>Edit Zone Details</h3>
        </div>

        <div className="body" style={{ height: "350px" }}>
          <label style={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                float: "left",
                marginLeft: "-192px",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              Country
            </label>
            <input
              disabled
              name="countryName"
              value={editData.countryName}
              className="input-config"
              style={{ marginTop: "-5px" }}
            />
          </label>

          <label style={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                float: "left",
                marginLeft: "-170px",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              Zone Name
            </label>
            <input
              name="zone"
              defaultValue={editData.zone}
              className="input-config"
              onChange={(e) => {
                setZoneName(e.target.value);
              }}
              style={{ marginTop: "-5px" }}
            />
          </label>
          <div class="multiselect" style={{ width: "33%" }}>
            <div class="selectBox" onClick={() => showCheckboxes()}>
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "9px",
                  width: "100%",
                }}
              >
                <label
                  style={{
                    float: "left",
                    marginLeft: "-200px",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  States
                </label>
                <select
                  name="editStates"
                  className="input-config"
                  id="stateId"
                  style={{ marginTop: "-5px" }}
                >
                  <option value="">Select/Unselect States</option>
                </select>
              </label>
              <div class="overSelect"></div>
            </div>
            
              <div
                id="checkboxes"
                style={{ padding: "5px", textAlign: "left", position:"absolute", width:"230px", height:"200px", marginLeft:"12px" , overflow:"scroll"}}
              >
                {[...new Set(finalStateArray.map((item) => item.stateName))]
                  .map((stateName) =>
                    finalStateArray.find((item) => item.stateName === stateName)
                  )
                  .map((item, index) => {
                    return (
                      <label key={item.stateId}>
                        <input
                          className="zoneCheckbox"
                          type="checkbox"
                          id={item.stateId}
                          onChange={(e) => {
                            onCheckboxChange(e, item);
                          }}
                          checked={statesArray.some(
                            (el) => el.stateId === item.stateId
                          )}
                        />
                        <span style={{ marginLeft: "5px" }}>
                          {item.stateName}
                        </span>
                      </label>
                    );
                  })}
              </div>
            
          </div>
        </div>
        <div className="footer" style={{justifyContent:'start'}}>
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button id="submit-button" onClick={submitHandler}>
            <FontAwesomeIcon icon={faUpload} /> Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditZone;
