import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ViewSpecs from "../bom/bom-viewspecs";
import SearchBox from "../../components/search/SearchBox";
import handleExport from "../../utils/exportExcel";
import auth from "../../utils/auth";

// const { ExportCSVButton } = CSVExport;

const RawInventory = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const [UsersData, setUsersData] = useState([]);

  const [row, setRow] = useState();

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `management/api/v1/inventory/rawinventory?componentName=&skuCode=&vendorPartName&verifiedBy`
      )
    );
    setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  

  const uniquecomponentName = [...new Set(UsersData.map(item => item.componentName))];
  const uniqueskuCode = [...new Set(UsersData.map(item => item.skuCode))];
  const uniquevendorPartName = [...new Set(UsersData.map(item => item.vendorPartName))];
  const uniqueverifiedBy = [...new Set(UsersData.map(item => item.verifiedBy))];



  console.log(UsersData);

  const specsHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setViewSpecs(true);
  };

  const specsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => specsHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          View Specs
        </button>
        {actionShow === rowIndex && viewSpecs ? (
          <ViewSpecs rowData={row} status={true} close={setViewSpecs} />
        ) : null}
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const subTotal = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <span>
          {(row?.availableQty || 0 * row?.unitPrice || 0).toLocaleString("en-IN")}
        </span>
      </>
    );
  };

  const unitPrice = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <span>₹{row?.unitPrice?.toLocaleString("en-IN")}</span>
      </>
    );
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Component Name",
      dataField: "componentName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "SKU Code",
      dataField: "skuCode",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Category",
      dataField: "category",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "300px" },
      classes: "cellsOverflow",
    },
    {
      text: "Sub Category",
      dataField: "subCategory",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Specification",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatExtraData: { actionShow, viewSpecs },
      formatter: specsFormatter,
    },
    {
      text: "Vendor Part Name",
      dataField: "vendorPartName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "300px" },
      classes: "cellsOverflow",
    },
    {
      text: "Vendor Part Code",
      dataField: "vendorPartCode",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Verified By",
      dataField: "verifiedBy",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "300px" },
    },
    {
      text: "Inventory Added Date",
      dataField: "addedDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: dateFormatter,
    },
    {
      text: "Available Quantity",
      dataField: "availableQty",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: `Unit Price(${auth.getUserInfo().currencySymbol})`,
      dataField: "unitPrice",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatter: unitPrice,
    },
    {
      text: `Sub Total(${auth.getUserInfo().currencySymbol})`,
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: subTotal,
    },
  ];

  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    componentName: "",
    skuCode: "",
    vendorPartName: "",
    verifiedBy: "",
    createdDate: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      componentName: "",
      skuCode: "",
      vendorPartName: "",
      verifiedBy: "",
      createdDate: "",
    });
    const resp = await trackPromise(
      API.get(
        `management/api/v1/inventory/rawinventory?componentName=&skuCode=&vendorPartName&verifiedBy`
      )
    );
    setUsersData(resp);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Raw Inventory</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "raw inventory")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <>
                    <form
                      onSubmit={submitHandler}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: "135px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                      }}
                      className="UHinputfields VMform"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Component Name
                        </label>
                        <select
                          name="componentName"
                          value={formValue.componentName}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Component Name
                          </option>
                         {
                          uniquecomponentName.map((item)=>(
                            <option>{item}</option>
                          ))
                         }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          SKU Code
                        </label>
                        <select
                          name="skuCode"
                          value={formValue.skuCode}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            SKU Code
                          </option>
                          {
                          uniqueskuCode.map((item)=>(
                            <option>{item}</option>
                          ))
                         }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vendor Part Name
                        </label>
                        <select
                          name="vendorPartName"
                          value={formValue.vendorPartName}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vendor Part Name
                          </option>
                          {
                          uniquevendorPartName.map((item)=>(
                            <option>{item}</option>
                          ))
                         }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Verified By
                        </label>
                        <select
                          name="verifiedBy"
                          value={formValue.verifiedBy}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Verified By
                          </option>
                          {
                          uniqueverifiedBy.map((item)=>(
                            <option>{item}</option>
                          ))
                         }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Inventory Date
                        </label>
                        <input
                          type="date"
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "text")}
                          name="createdDate"
                          value={formValue.createdDate}
                          onChange={handleChange}
                          placeholder="Inventory Date"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          width: "22%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <button
                          onClick={fetchReport}
                          style={{ width: "100px", marginTop: "24px" }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{ width: "100px", marginTop: "24px" }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </form>
                  </>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default RawInventory;
