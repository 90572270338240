import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { errorNotification, successNotification } from "../../../../../utils/notifications";
import JobCardDetails from "../../../../ServiceAdvisorServiceJobs/Modals/JobCardDetails";
import RequestedBatteryQty from "../RequestedBatteryQty";
import SubmitPop from "./SubmitPop";
import moment from "moment";



const PlaceToInventory = ({ rowData, closeModal, updatedData }) => {
  const [open, setOpen] = useState(false);
  const [openSpare, setOpenSpare] = useState(false);
  const [spareData, setSpareData] = useState({});

  const submitHandler = (rowData) => {
    setOpenSpare(true);
    setSpareData(rowData);
  };

  console.log(rowData)

  return (
    <div className="container1">
      {openSpare ? (
        <SubmitPop rowData={rowData} closeModal={setOpenSpare} closeMain={closeModal} updatedData={updatedData} />
      ) : (
        ""
      )}
      <div
        className="modalContainer"
        style={{ width: "1000px", height: "450px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Vehicle Returns</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >

          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderBottom: "0.1px solid grey",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div>Request ID:{rowData.requestID}</div>
              <div>Request Date: {moment(rowData.requestDate).format("DD-MM-YYYY")}</div>
              <div>Requested By: {rowData.requestedBy}</div>
              <div>GIN No:: {rowData.ginNo}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div>RPI No : {rowData.rpiNo}</div>
              <div>RPI Date: {moment(rowData.rpiDate).format("DD-MM-YYYY")}</div>
              <div>RPI Raised By: {rowData.dealerName}</div>
            </section>
          </section>

          <section
            id="nr_POno"
            style={{
              width: "100%",
              fontSize: "14px",
              margin: "10px 0",
              border: "none",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                padding: "0px",
                border: "1px solid #cdcdcd",
                width: "fit-content",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "50px", padding: "10px" }}>S.No</div>
              <div style={{ width: "200px", padding: "10px" }}>Vehicle SKU</div>
              <div style={{ width: "200px", padding: "10px" }}>Segment</div>
              <div style={{ width: "200px", padding: "10px" }}>Category</div>
              <div style={{ width: "200px", padding: "10px" }}>Vehicle Model</div>
              <div style={{ width: "200px", padding: "10px" }}>Vehicle Variant</div>
              <div style={{ width: "200px", padding: "10px" }}>Vehicle Type</div>
              <div style={{ width: "200px", padding: "10px" }}>Color</div>
              <div style={{ width: "200px", padding: "10px" }}>Vehicle S.No</div>
              <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>BIN</div>
              <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>VIN</div>
              <div style={{ width: "200px", padding: "10px", overflowX: "scroll" }}>Return Reason</div>

            </div>
            <section style={{ width: "fit-content", height: "auto" }}>
              {rowData.returnedData.map((item, index) => (
                <div
                  style={{
                    padding: "0px",
                    border: "1px solid #cdcdcd",
                    width: "",
                    fontSize: "13px",
                    display: "flex",
                  }}
                >
                  <div style={{ width: "50px", padding: "10px" }}>{index + 1}</div>

                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleSkuCode}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleSegment}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleCategory}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleModel}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleVariant}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleType}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.vehicleColor}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.SNO}
                  </div>

                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.BIN}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.VIN}
                  </div>
                  <div
                    style={{
                      width: "200px",
                      padding: "10px",
                      overflowX: "scroll",
                    }}
                  >
                    {item?.returnReason}
                  </div>
                </div>
              ))}
            </section>
          </section>
          {open && <RequestedBatteryQty rowData={rowData} closeModal={setOpen} />}
        </div>

        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={submitHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlaceToInventory;
