import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';

function UserPieChart({ userTypeData }) {
    return (
        <div style={{ width: "50%", marginLeft: "10px" }}>
            <Doughnut
                data={{
                    datasets: [{
                        data: [userTypeData.activeUsers, userTypeData.deactivatedUsers, userTypeData.reactivatedUsers, userTypeData.deletedUsers],
                        backgroundColor: [
                            'rgba(33, 168, 46, 1)',
                            'rgba(253, 170, 72, 1)',
                            'rgba(255, 215, 74, 1)',
                            'rgba(237, 52, 75, 1)',
                        ],
                        options: {
                            radius: "10%"
                        },
                        hoverOffset: 4,
                    }
                    ],
                    labels: ["Active Users", "De-Activated Users", "Re-Activated Users", "Deleted Users"]
                }} />
            <div style={{ margin: "-70% 0 0 100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" , width:"184px"}}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(33, 168, 46, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "114px", fontSize: "13px", fontWeight: "500", marginTop: "3px" }}>Active Users</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "13px", fontWeight: "700", marginTop: "3px" }}>
                        {userTypeData.activeUsers}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" , width:"184px"}}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(253, 170, 72, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "114px", fontSize: "13px", fontWeight: "500", marginTop: "3px" }}>Deactivated Users</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "13px", fontWeight: "700", marginTop: "3px" }}>
                        {userTypeData.deactivatedUsers}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" , width:"184px"}}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(255, 215, 74, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "114px", fontSize: "13px", fontWeight: "500", marginTop: "3px" }}>Reactivated Users</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "13px", fontWeight: "700", marginTop: "3px" }}>
                        {userTypeData.reactivatedUsers}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" , width:"184px"}}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(237, 52, 75, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "114px", fontSize: "13px", fontWeight: "500", marginTop: "3px" }}>Deleted Users</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "13px", fontWeight: "700", marginTop: "3px" }}>
                        {userTypeData.deletedUsers}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default UserPieChart