import {
  faEnvelope,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useParams } from "react-router-dom";
import API from "../../utils/api";
import images from "../../utils/get-images";
import {
  errorNotification,
  infoNotification,
  successNotification,
} from "../../utils/notifications";
import "./dealerOnboardform.scss";
import ViewSalesManager from "../OperationBusinessEntities/Components/ViewSalesManager";

function DealerOnboardForm() {
  let { dealerID } = useParams();
  const [formData, setFormData] = useState({});
  const [panfile, setPanFile] = useState();
  const [gstfile, setGstFile] = useState();
  const [dealerCertfile, setDealerCertFile] = useState();
  const [selectedState, setSelectedState] = useState({});
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedStdCode, setStdCode] = useState("");
  const [openMapping, setOpenMappings] = useState(false);
  const [displayDone, setDisplayDone] = useState(false);
  const getDataWithDealerID = async () => {
    const resp = await trackPromise(
      API.get(
        `operations-module/api/v1/businessEntities/getEntityDetails?entityID=${dealerID}`
      )
    );
    setFormData({
      businessType: resp.businessType,
      country: resp.country,
      cities: resp.cities,
      businessName: resp.businessName,
      businessEmailID: resp.businessEmailID,
      contactPersonName: resp.contactPersonName,
      entityID: resp.entityID,
      address: "",
      address1: "",
      zipCode: "",
      state: "",
      city: "",
      isd: resp.phoneCode,
      std: selectedStdCode,
      businessContactNo: "",
      contactPersonNo: "",
      panNo: "",
      gstin: "",
      dealerCertificateNo: "",
      panDoc: panfile,
      gstDoc: gstfile,
      certDoc: dealerCertfile,
    });
  };
  useEffect(() => {
    getDataWithDealerID();
  }, []);

  const cancelHandler = () => {
    document.getElementById("address1").value = "";
    document.getElementById("address2").value = "";
    document.getElementById("zipCode").value = "";
    document.getElementById("state").value = "";
    document.getElementById("city").value = "";
    document.getElementById("isd").value = "";
    document.getElementById("std").value = "";
    document.getElementById("panNo").value = "";
    document.getElementById("gstin").value = "";
  };

  const submitHandler = async () => {
    console.log(formData);
    const ZonalDealerFormData = new FormData();
    ZonalDealerFormData.append("entityID", formData.entityID);
    ZonalDealerFormData.append("bussinessType", formData.businessType);
    ZonalDealerFormData.append("bussinessName", formData.businessName);
    ZonalDealerFormData.append("businessEmailID", formData.businessEmailID);
    ZonalDealerFormData.append("contactPersonName", formData.contactPersonName);
    ZonalDealerFormData.append("country", formData.country);
    ZonalDealerFormData.append("address", formData.address);
    ZonalDealerFormData.append("address1", formData.address1);
    ZonalDealerFormData.append("zipCode", formData.zipCode);
    ZonalDealerFormData.append("state", formData.state);
    ZonalDealerFormData.append("city", formData.city);
    ZonalDealerFormData.append("isd", formData.isd);
    ZonalDealerFormData.append("std", selectedStdCode);
    ZonalDealerFormData.append("businessContactNo", formData.businessContactNo);
    ZonalDealerFormData.append("contactPersonNo", formData.contactPersonNo);
    ZonalDealerFormData.append("panNo", formData.panNo);
    ZonalDealerFormData.append("gstin", formData.gstin);
    ZonalDealerFormData.append(
      "dealerCertificateNo",
      formData.dealerCertificateNo
    );
    ZonalDealerFormData.append("panDoc", panfile);
    ZonalDealerFormData.append("gstDoc", gstfile);
    ZonalDealerFormData.append("certDoc", dealerCertfile);

    const resp = await trackPromise(
      API.put(
        `operations-module/api/v1/businessEntities/updateEntityDetails`,
        ZonalDealerFormData
      )
    );
    console.log(resp);
    if (resp.success == true) {
      setDisplayDone(true);
      successNotification(resp.message);
    } else {
      errorNotification(resp.message);
    }
  };

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleZipcodeChange = async (event) => {
    let zipCode = event.target.value;
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/users/getDataByZipCode?zipcode=${zipCode}`)
    );
    console.log(resp);
    // let std = resp.city.find((std) => std.PostOfficeName == event.target.value);
    setStdCode(resp.city[0].StdCode);
    setSelectedState(resp.state[0]);
    setSelectedCity(resp.city);
    setFormData({
      ...formData,
      zipCode: resp.city[0].Pincode,
      state: resp.state[0].stateName,
      std: resp.city[0].StdCode,
    });
  };

  const handleStdChange = (event) => {
    let std = selectedCity.find(
      (std) => std.PostOfficeName == event.target.value
    );
    setStdCode(std.StdCode);
    setFormData({ ...formData, std: std.StdCode });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileSize = selectedFile.size;
      const allowedFileSize = 5 * 1024 * 1024; // 5MB in bytes
      console.log(event.target.name, selectedFile);
      if (fileSize <= allowedFileSize) {
        if (event.target.name == "panDoc") {
          setPanFile(selectedFile);
        }
        if (event.target.name == "gstDoc") {
          setGstFile(selectedFile);
        }
        if (event.target.name == "certDoc") {
          setDealerCertFile(selectedFile);
        }
      } else {
        infoNotification(
          "File size exceeds 5MB limit. Please select a smaller file."
        );
      }
    }
  };

  return (
    <>
      {openMapping && (
        <ViewSalesManager
          blankForm={formData}
          close={setOpenMappings}
          userType="zonal"
        />
      )}

      <div
        style={{
          width: "100%",
          background: "#efefef",
          padding: "20px",
          height: "100vh",
        }}
      >
        <div className="dealerFormHeader">
          <img
            style={{ marginTop: "1%" }}
            src={images["electriseLogo.svg"]}
            alt="logo"
          />
        </div>
        {displayDone ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
            }}
          >
            <p
              style={{
                background: "white",
                height: "17%",
                borderRadius: "5px",
                padding: "2%",
                fontWeight: "600",
              }}
            >
              Form Submitted Successfully <br />
              <span
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ fontSize: "24px", color: "green" }}
                />
              </span>
            </p>
          </div>
        ) : (
          <div className="dealerForm">
            <span
              style={{
                color: "#0A18A3",
                fontSize: "20px",
                fontWeight: "500",
                marginLeft: "10px",
              }}
            >
              Dealer Enrollment Form
            </span>

            <div className="ContentScroll">
              <div
                style={{ display: "flex", flexWrap: "wrap", margin: "12px" }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "13%",
                    marginLeft: "15px",
                  }}
                >
                  Business Type
                </label>
                <br />
                <input
                  disabled
                  style={{ margin: "19px 0px 5px -136px", width: "32%" }}
                  name="businessType"
                  value={formData.businessType}
                  className="input-config"
                  onChange={(event) => handleCountryChange(event)}
                />

                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "13%",
                    marginLeft: "16px",
                  }}
                >
                  Business Name
                </label>
                <br />
                <input
                  style={{ margin: "19px 0px 5px -136px", width: "32%" }}
                  name="businessName"
                  defaultValue={formData.businessName}
                  className="input-config"
                  placeholder="Business Name"
                  onChange={(event) => handleInputChange(event)}
                />

                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    marginLeft: "12px",
                  }}
                >
                  Contact Person Name
                </label>
                <br />
                <input
                  style={{ margin: "19px 0px 5px -136px", width: "32%" }}
                  name="contactPersonName"
                  defaultValue={formData.contactPersonName}
                  placeholder="Contact Person Name"
                  className="input-config"
                  onChange={(event) => handleInputChange(event)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginLeft: "2.8%",
                }}
              >
                <label
                  style={{ fontSize: "13px", fontWeight: "500", width: "13%" }}
                >
                  Business Email ID
                </label>
                <br />
                <input
                  disabled
                  style={{ margin: "19px 0px 5px -136px", width: "32%" }}
                  name="businessEmailID"
                  value={formData.businessEmailID}
                  className="input-config"
                  placeholder="Business Email ID"
                  onChange={(event) => handleInputChange(event)}
                />
                <div
                  className="viewMappings"
                  onClick={() => setOpenMappings(true)}
                  style={{ margin: "3% 0px 0px 3%" }}
                >
                  View Mappings
                </div>
              </div>
              <hr style={{ background: "black" }} />
              <div
                style={{ display: "flex", flexWrap: "wrap", margin: "12px" }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    marginLeft: "2px",
                    width: "18%",
                  }}
                >
                  Address 1<span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  style={{ margin: "19px 0px 5px -187px", width: "32%" }}
                  className="input-config"
                  id="address1"
                  type="text"
                  name="address"
                  defaultValue={formData.address}
                  placeholder="Enter Address"
                  onChange={(event) => handleInputChange(event)}
                />
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    marginLeft: "16px",
                    width: "18%",
                  }}
                >
                  Address 2
                </label>
                <br />
                <input
                  style={{ margin: "19px 0px 5px -187px", width: "32%" }}
                  className="input-config"
                  type="text"
                  id="address2"
                  name="address1"
                  defaultValue={formData.address1}
                  placeholder="Enter Address"
                  onChange={(event) => handleInputChange(event)}
                />

                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "18%",
                    marginLeft: "12px",
                  }}
                >
                  Zip Code/Postal Code<span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  style={{ margin: "19px 0px 5px -184px", width: "32%" }}
                  className="input-config"
                  type="text"
                  id="zipCode"
                  name="zipcode"
                  defaultValue={formData.zipcode}
                  placeholder="Zip Code"
                  maxLength={6}
                  onKeyPress={(e) => {
                    const isNumeric = /^[0-9]*$/.test(e.key);
                    if (!isNumeric) {
                      e.preventDefault();
                    }
                  }}
                  onBlur={(event) => handleZipcodeChange(event)}
                />
              </div>

              <div
                style={{ display: "flex", flexWrap: "wrap", margin: "12px" }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "18%",
                    marginLeft: "0px",
                  }}
                >
                  State
                </label>
                <br />
                <input
                  style={{ margin: "19px 0px 5px -184px", width: "32%" }}
                  className="input-config"
                  type="text"
                  disabled
                  id="state"
                  name="state"
                  defaultValue={formData.state}
                  placeholder="State"
                //  onBlur={(event) => handleZipcodeChange(event)}
                />
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    marginLeft: "18px",
                  }}
                >
                  City<span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <select
                  style={{ margin: "19px 0px 5px -34px", width: "32%" }}
                  name="city"
                  id="city"
                  defaultValue={formData.city}
                  className="input-config"
                  onChange={(event) => handleInputChange(event)}
                >
                  <option value="" disabled selected>
                    City
                  </option>
                  {selectedCity.map((item) => (
                    <option>{item.PostOfficeName}</option>
                  ))}
                </select>

                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    marginLeft: "12px",
                  }}
                >
                  Business Contact Number<span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <div
                  style={{ display: "flex", width: "22%", marginLeft: "-6%" }}
                >
                  <input
                    disabled
                    style={{
                      width: "40%",
                      margin: "19px 86px 0px -45%",
                      textAlign: "center",
                    }}
                    className="input-config"
                    type="text"
                    name="isd"
                    pattern="[0-9]*"
                    placeholder="-"
                    defaultValue={formData.isd}
                  />
                  <input
                    disabled
                    style={{
                      width: "43%",
                      margin: "19px 86px 0px -80px",
                      textAlign: "center",
                    }}
                    className="input-config"
                    type="text"
                    name="std"
                    maxLength="3"
                    id="std"
                    onKeyPress={(e) => {
                      const isNumeric = /^[0-9]*$/.test(e.key);
                      if (!isNumeric) {
                        e.preventDefault();
                      }
                    }}
                    placeholder="-"
                    defaultValue={selectedStdCode}
                  />
                  <input
                    style={{ margin: "19px 0px 5px -83px", width: "100%" }}
                    className="input-config"
                    type="text"
                    maxLength="8"
                    id="businessContactNo"
                    name="businessContactNo"
                    defaultValue={formData.businessContactNo}
                    placeholder="Contact No."
                    onKeyPress={(e) => {
                      const isNumeric = /^[0-9]*$/.test(e.key);
                      if (!isNumeric) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(event) => handleInputChange(event)}
                  />
                </div>
              </div>

              <div
                style={{ display: "flex", flexWrap: "wrap", margin: "12px" }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    marginLeft: "0px",
                  }}
                >
                  Contact Person Number<span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <div
                  style={{ display: "flex", width: "22%", marginLeft: "-46px" }}
                >
                  <input
                    disabled
                    style={{
                      width: "43%",
                      margin: "19px 86px 0px -45%",
                      textAlign: "center",
                    }}
                    className="input-config"
                    type="text"
                    pattern="[0-9]*"
                    name="isd"
                    placeholder="-"
                    defaultValue={formData.isd}
                  />

                  <input
                    style={{ margin: "19px 0px 5px -83px", width: "100%" }}
                    className="input-config"
                    type="text"
                    maxLength={10}
                    onKeyPress={(e) => {
                      const isNumeric = /^[0-9]*$/.test(e.key);
                      if (!isNumeric) {
                        e.preventDefault();
                      }
                    }}
                    id="contactPersonNo"
                    name="contactPersonNo"
                    defaultValue={formData.contactPersonNo}
                    placeholder="Contact No."
                    onChange={(event) => handleInputChange(event)}
                  />
                </div>
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    marginLeft: "17px",
                    width: "18%",
                  }}
                >
                  PAN<span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  style={{ margin: "19px 0px 5px -187px", width: "32%" }}
                  className="input-config"
                  type="text"
                  id="panNo"
                  name="panNo"
                  defaultValue={formData.panNo}
                  placeholder="Enter Company PAN"
                  onChange={(event) => handleInputChange(event)}
                />

                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "18%",
                    marginLeft: "12px",
                  }}
                >
                  Upload PAN<span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  style={{
                    margin: "19px 0px 5px -184px",
                    width: "32%",
                    padding: "14px",
                  }}
                  className="input-config"
                  type="file"
                  name="panDoc"
                  id="panDoc"
                  defaultValue={formData.panDoc}
                  placeholder="panDoc"
                  accept=".doc,.docx,.xls,.xlsx,.ppt,.pdf,.jpg,.jpeg,.png,.gif"
                  onChange={handleFileChange}
                // onChange={(e) => setPanFile(e.target.files[0])}
                />
              </div>

              <div style={{ display: "flex", flexWrap: "wrap", margin: "0px" }}>
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "18%",
                    marginLeft: "15px",
                  }}
                >
                  GSTIN<span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  style={{ margin: "19px 0px 5px -187px", width: "30.5%" }}
                  className="input-config"
                  type="text"
                  name="gstin"
                  id="gstin"
                  defaultValue={formData.gstin}
                  placeholder="Enter GSTIN"
                  onChange={(event) => handleInputChange(event)}
                />
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "18%",
                    marginLeft: "12px",
                  }}
                >
                  Upload GSTIN<span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  style={{
                    margin: "19px 0px 5px -188px",
                    width: "31%",
                    padding: "14px",
                  }}
                  className="input-config"
                  type="file"
                  name="gstDoc"
                  id="gstDoc"
                  defaultValue={formData.gstDoc}
                  placeholder="gstDoc"
                  accept=".doc,.docx,.xls,.xlsx,.ppt,.pdf,.jpg,.jpeg,.png,.gif"
                  onChange={handleFileChange}
                // onChange={(e) => setGstFile(e.target.files[0])}
                />
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "18%",
                    marginLeft: "15px",
                  }}
                >
                  Dealer Certificate
                </label>
                <br />
                <input
                  style={{ margin: "19px 0px 5px -186px", width: "31%" }}
                  className="input-config"
                  type="text"
                  name="dealerCertificateNo"
                  id="dealerCertificateNo"
                  defaultValue={formData.dealerCertificateNo}
                  placeholder="Enter Dealer Certificate"
                  onChange={(event) => handleInputChange(event)}
                />
              </div>
              <div style={{ display: "flex", flexWrap: "wrap", margin: "0px" }}>
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "18%",
                    marginLeft: "14px",
                  }}
                >
                  Upload Dealer Certificate
                </label>
                <br />
                <input
                  style={{
                    margin: "19px 0px 5px -184px",
                    width: "30.5%",
                    padding: "14px",
                  }}
                  className="input-config"
                  type="file"
                  name="certDoc"
                  id="certDoc"
                  defaultValue={formData.certDoc}
                  placeholder="Upload Dealer Certificate"
                  accept=".doc,.docx,.xls,.xlsx,.ppt,.pdf,.jpg,.jpeg,.png,.gif"
                  onChange={handleFileChange}
                // onChange={(e) => setDealerCertFile(e.target.files[0])}
                />
              </div>
              <div className="save-cancel" style={{ marginTop: "0px" }}>
                <div className="cust-reset  cancel-button">
                  <button
                    type="button"
                    className="btn btn-icon-label"
                    onClick={cancelHandler}
                    style={{ height: "40px" }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#0A18A3",
                        fontWeight: "500",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        style={{ fontSize: "16px" }}
                      />{" "}
                      Cancel
                    </div>
                  </button>
                </div>
                <div className=" cancel-button">
                  <button
                    className="btn btn-icon-label"
                    onClick={submitHandler}
                    style={{ background: "#0A18A3", height: "40px" }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#fff",
                        fontWeight: "500",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ fontSize: "16px" }}
                      />{" "}
                      Submit
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <br></br>
      </div>
    </>
  );
}

export default DealerOnboardForm;
