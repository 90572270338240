import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import ProgressBar from "react-bootstrap/ProgressBar";
import API from "../../utils/api";
import moment from "moment";

const ApprovalProgressBar = ({ props }) => {
  const [orderData, setOrderData] = useState({});

  const fetchRequest = async () => {
    const result = await trackPromise(
      API.get(`/showroom-module/api/v1/dashboard/getOrders`)
    );
    setOrderData(result);
  };
  useEffect(() => {
    fetchRequest();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "95%",
        marginLeft: "10px",
        fontWeight: "430",
      }}
    >
      <ProgressBar>
        <ProgressBar
          style={{ backgroundColor: "#FDAA48" }}
          now={orderData.totalRequisitions * 100}
        />
        <ProgressBar
          style={{ backgroundColor: "#FDAA48" }}
          now={orderData.newRequest * 100}
        />
        <ProgressBar
          style={{ backgroundColor: "#267799" }}
          now={orderData.onHold * 100}
        />
        <ProgressBar
          style={{ backgroundColor: "#267799" }}
          now={orderData.poGenerated * 100}
        />
        <ProgressBar
          style={{ backgroundColor: "#267799" }}
          now={orderData.requisitionRejected * 100}
        />
      </ProgressBar>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#3B61CF",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>Total Requisitions</span>
        </div>
        <div>{orderData.totalRequisitions}</div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#FDAA48",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>New Request</span>
        </div>
        <div>{orderData.newRequest}</div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#FFD74A",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>Requisition On Hold</span>
        </div>
        <div>{orderData.onHold}</div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#2B7A9C",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>PO Generated</span>
        </div>
        <div>{orderData.poGenerated}</div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              width: "15px",
              height: "15px",
              background: "#43A830",
              borderRadius: "3px",
              marginRight: "5px",
              marginTop: "4px",
            }}
          ></div>
          &nbsp;
          <span>Requisition Rejected</span>
        </div>
        <div>{orderData.requisitionRejected}</div>
      </div>
    </div>
  );
};

export default ApprovalProgressBar;
