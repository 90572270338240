import React, { useState } from "react";
import { trackPromise } from 'react-promise-tracker';
import API from '../../../utils/api';
import { errorNotification, successNotification } from '../../../utils/notifications';

const OnHoldReturnNote = ({ closeModal, rowData, updatedData }) => {
  const [formValue, setFormValue] = useState({
    onholdReason: "",
    description: "",
  });

  const refresh = () => {
    updatedData();
   }

  const editDetails = {
    "requestID": rowData.requestID,
    "status": "On Hold",
    "reason": formValue.onholdReason,
    "reasonDescription": formValue.description
  };

  const submitHandler = async () => {
    const resp = await trackPromise(API.put(`finance/api/v1/returns/createnote/status`, editDetails));

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      refresh()
      close(false)
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>On Hold</h3>
        </div>
        <div className="body">
          <label>
            <select
              name="onholdReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                On Hold Reason*
              </option>
              <option value="Misconduct">
                Misconduct
              </option>
              <option value="Not an Active User">
                Not an Active User
              </option>
              <option value="Change of Role">
                Change of Role
              </option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.onholdReason === "Others" ? (
            <>
              <label className="lgInput">
                Description
                <input
                  type="text"
                  style={{ width: "508px" }}
                  name="description"
                  value={formValue.description}
                  onChange={(e) =>
                    setFormValue({ ...formValue, [e.target.name]: e.target.value })
                  }
                  placeholder="On Hold Reason*"
                  autoComplete="off"
                />
              </label>
            </>

          ) : null}

        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button 
          id="submit-button"
          onClick={submitHandler}
          disabled={formValue.onholdReason == "" ? true : false}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnHoldReturnNote;
