import React, { useState } from "react";
import {
    KeyboardBackspace,
    CheckCircle,
    HighlightOffOutlined,
} from "@material-ui/icons";
import moment from 'moment'
import AppConstants from "../../../utils/app-constants";

const ViewSpecs = ({closeModal, rowData }) => {
    console.log(rowData)
    const [openQty, setOpenQty] = useState();
    const [viewSpecs,setViewSpecs]=useState(false);
    const [showGRN,setShowGRN]=useState();
    const [showGIN,setShowGIN]=useState();


    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "500px", height: "500px", padding: '15px' }}
            >
                <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>Component S.No.</h3>
          <div>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Print 
          </button>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Export
          </button>
          </div>
        </div>

     <section
            style={{
                width: '100%',
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                fontWeight: "500",
                margin: "10px 0"
            }}
        >
            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <span>Part Name: {rowData.componentName}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>SKU Code: {rowData.skuCode}</span>
            </div>
                       
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Unit Price: {rowData?.componentSno[0]?.unitPrice}</span>
            </div>

         </section>

                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >

                    <section style={{ width: '100%' }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '20%' }}>S.No.</div>
                            <div style={{ width: '30%' }}>Part S.No.</div>
                            <div style={{ width: '50%' }}>Return Reason</div>
                        </div>

                                <div>
                                    {
                                        rowData?.componentSno?.map((item,index)=>{
                                            return(
                                                <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }}>
                                                <div style={{ width: '20%', overflowY: "auto" }}>{index + 1}</div>
                                                <div style={{ width: '30%', overflowY: "auto" }}>{item?.partSno}</div>
                                                <div style={{ width: '50%', overflowY: "auto" }}>{item?.returnedReason}</div>
                                            </div>
                                            )
                                           
                                        })
                                    }
                        
                                   
                                    
                         </div>
                           
                     
                    </section>
                </div>

                <div className="footer">
                <div><button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={()=> closeModal()}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button></div> 
                </div>
            </div>
        </div>
    );
};

export default ViewSpecs;