import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import "../assembly.scss";

const EditAssm = ({ updatedData, editData, closeModal , showModal}) => {
  const [formValue, setFormValue] = useState({
    assemblyType: editData.assemblyType,
    assemblyName: editData.assemblyName,
    assemblyServices: editData.assemblyServices,
    id:editData.id
  });


  console.log("formValue-->",formValue)

  const [showCheckboxes, setShowCheckboxes] = useState(false);

  const validateInputs = () => {
    // if (
    //   !formValue.assemblyType ||
    //   !formValue.assemblyName ||
    //   formValue.assemblyServices.length === 0
    // ) {
    //   errorNotification("All fields are required.");
    //   return false;
    // }

    if (
      /^[0-9]+$/.test(formValue.assemblyType) ||
      /^[0-9]+$/.test(formValue.assemblyName)
    ) {
      errorNotification("Only numeric values are not allowed.");
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    if (!validateInputs()) {
      return;
    }
    const resp = await trackPromise(
      API.put(`admin-module/api/v1/assembly`, formValue)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      showModal(false)
      successNotification(message, { autoClose: 2000 });
      refresh();
    } else {
      errorNotification(message, { autoClose: 2000 });
    }
  };

  const onCheckboxChange = (item) => {
    let updatedServices;
    if (formValue.assemblyServices.some((service) => service.serviceName === item.serviceName)) {
      updatedServices = formValue.assemblyServices.filter(
        (service) => service.serviceName !== item.serviceName
      );
    } else {
      updatedServices = [...formValue.assemblyServices, item];
    }

    setFormValue({ ...formValue, assemblyServices: updatedServices });
  };

  const isChecked = (item) => {
    return formValue.assemblyServices.some(
      (service) => service.serviceName === item.serviceName
    );
  };


  const closeHandler = ()=>{
    showModal(false)
    closeModal(false)
  }


  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "810px" }}>
        <div className="header">
          <h3>Edit Assembly Details</h3>
        </div>

        <div className="body">
          <label style={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                float: "left",
                marginLeft: "-145px",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              Assembly Types<span style={{ color: "red" }}>*</span>
            </label>
            <select
              disabled
              name="assemblyType"
              value={formValue.assemblyType}
              style={{ marginTop: "-8px" }}
            >
              <option disabled value="">
                Assembly Types*
              </option>
              <option>Main Assembly</option>
              <option>Sub Assembly</option>
            </select>
          </label>
          <label style={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                float: "left",
                marginLeft: "-145px",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              Assembly Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="assemblyname"
              type="text"
              name="assemblyName"
              value={formValue.assemblyName}
              placeholder="Assembly Name*"
              onChange={handleChange}
              style={{ marginTop: "-8px" }}
            />
          </label>
          <div className="multiselect" style={{ width: "236px" }}>
            <div className="selectBox" onClick={() => setShowCheckboxes(!showCheckboxes)}>
              <label style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    float: "left",
                    marginLeft: "-120px",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Assembly Services<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  name="assemblyServices"
                  className="input-config"
                  style={{ marginTop: "-8px" }}
                >
                  <option>Select Services</option>
                </select>
              </label>
              <div className="overSelect"></div>
            </div>
            {showCheckboxes && (
              <div
                id="checkboxes"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                {editData.assemblyServices.map((item) => (
                  <label key={item.id}>
                    <input
                      style={{ height: "15px", width: "20px" }}
                      className="service-list"
                      type="checkbox"
                      checked={isChecked(item)}
                      id={item.id}
                      onChange={() => onCheckboxChange(item)}
                    />
                    {item.serviceName}
                  </label>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <button onClick={closeHandler}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAssm;
