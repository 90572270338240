import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";

const ConfigurationAssembly = React.lazy(() =>
  import("./ConfigurationAssembly/ConfigurationAssembly")
);
const ConfigurationsLabor = React.lazy(() =>
  import("./ConfigurationLabor/ConfigurationLabor")
);
const ConfigurationComponent = React.lazy(() =>
  import("./ConfigurationComponents/ComponentConfiguration")
);

const ConfigurationBattery = React.lazy(() =>
  import("./ConfigurationComponents/BatteryConfiguration")
);



const tabs = [
  {
    id: "SYM4T1",
    label: TabLabels.COMPONENTS_CONFIGURATION,
    Component: ConfigurationComponent,
  },
  {
    id: "SYM4T2",
    label: TabLabels.LABOR,
    Component: ConfigurationsLabor,
  },
  {
    id: "SYM4T3",
    label: TabLabels.ASSEMBLY_SERVICES,
    Component: ConfigurationAssembly,
  },
  {
    id: "SYM4T4",
    label: TabLabels.BATTERY_CONFIGURATION,
    Component: ConfigurationBattery,
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

function SystemAdminConfiguration() {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
}

export default SystemAdminConfiguration;
