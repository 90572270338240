import React, { useState } from "react";
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import "../../outward.scss"
import id from "date-fns/esm/locale/id/index.js";

const ExportDetails = ({closeModal}) => {

    const [selection,setSelection]=useState(-2);
    const [radioValue,setRadioValue]=useState("");

  const submitHandler = () => {
    if (selection==0 && radioValue=="pdf"){
        console.log("PDF file");
    }else if (selection==0 && radioValue=="xls"){
        console.log("Excel file");
    }else if(selection==1){
        console.log("Send Mail");
    }else{
        null
    }
    //   closeModal(false);
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{width:"620px",height:"340px",justifyContent:"flex-start"}}>

          <div className="header" style={{ display: "flex", justifyContent: "space-between", paddingRight: "0px" }}>
              <h3>Export</h3>
           </div>
        <div className="body" style={{flexDirection:"column",marginTop:"15px",fontSize:"18px",textAlign:"center"}}>
          <span style={{textAlign:"left" }}>Your document is ready for sharing.</span>
        <div style={{display:"flex",height:"160px",marginTop:"20px",justifyContent:"space-around"}}>
        
            <div 
             className="exportdetailsdiv"
             onClick={()=>setSelection(0)}
             style={ selection==0 ? { boxShadow: "0px 0px 8px rgb(0 0 0 / 65%)"} : {} }>
              <div className="exportdetailsbtn" style={{marginTop:"8%"}}>
              <CloudDownloadIcon style={selection==0 ?{fontSize:"45px",marginLeft:"41%",color:"#0A18A3"}:{fontSize:"45px",marginLeft:"41%"}}/>
               Download
              </div>
              

    <div style={{display:"flex",justifyContent:"space-around"}}> 
      <label  htmlFor="pdf" className="wo_labels"              
      style={{fontSize:"14px"}}>
      <input type="radio" 
         name="download" 
         id="pdf"
         value="pdf"
         disabled={selection==0 ? false:true}
         style={{width:"13px",height:"13px"}}
        //  checked={selection==0}
         onClick={e=>setRadioValue(e.target.value)}
        /> 
        Download PDF</label>

       <label htmlFor="xls" className="wo_labels"
       style={{fontSize:"14px"}} >  
       <input type="radio"
        name="download" 
        id="xls"    
        value="xls"
        disabled={selection==0 ? false:true}                             
        style={{width:"13px",height:"13px"}}
        // checked={display == true}
         onClick={e=>setRadioValue(e.target.value)}
        /> 
          Download XLS</label>
     </div>

            </div>
            <div className="exportdetailsdiv"
            onClick={()=>setSelection(1)}
            style={ selection==1 ? { boxShadow: "0px 0px 8px rgb(0 0 0 / 65%)"} :{} }>
            <div className="exportdetailsbtn">
            <EmailOutlinedIcon style={selection==1 ?{fontSize:"45px",marginLeft:"41%",color:"#0A18A3"}:{fontSize:"45px",marginLeft:"41%"}}/>
             Send Email</div>
            </div>
        </div>
    </div>

        <div className="footer" style={{justifyContent:"flex-end",marginBottom:"16px"}}>
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Confirm
          </button>
        </div>

      </div>
    </div>
  );
};

export default ExportDetails;
