import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";

const ReturnedQty = ({ closeModal ,rowdata}) => {

    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "780px", height: "450px", padding: '15px' }}
            >
                <div className="header">
                    <h3>Sold Qty</h3>
                </div>

                <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between",padding:'0px' }}>
                            <span>Vehicle Category : {rowdata.vehicleCategory}</span>
                            <span>Vehicle Model  : {rowdata.vehicleModel}</span>
                        </div>
                        <div style={{ display: "flex",justifyContent: "space-between",padding:'0px' }}>
                            <span>Vehicle Segment : {rowdata.vehicleSegment}</span>
                            <span>Vehicle Variant  : {rowdata.vehicleVariant}</span>
                        </div>
                        <div style={{ display: "flex",justifyContent: "space-between",padding:'0px' }}>
                            <span>Vehicle Type : {rowdata.vehicleType}</span>
                            <span>Vehicle Color  : {rowdata.vehicleColor}</span>
                        </div>
                 </section>

                <div
                    className="body"
                    style={{ display: "flex", height: '500px', fontSize: "14px", overflow: 'scroll',marginTop:'20px' }}
                >

                    <section  style={{ width: '100%'}}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '10%'}}>S.No.</div>
                            <div style={{ width: '30%'}}>Vehicle S.No.</div>
                            <div style={{ width: '30%'}}>VIN</div>
                            <div style={{ width: '30%'}}>BIN</div>                    
                        </div>
              
                    
                            {rowdata.returnedQty.map((item,index)=>{
                                return(
                                    <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }}>
                                    <div style={{ width: '10%', overflowY: "auto" }}>{index + 1}</div>
                                    <div style={{ width: '30%', overflowY: "auto" }}>{item.vehicleSno}</div>
                                    <div style={{ width: '30%', overflowY: "auto" }}>{item.vinNo}</div>
                                    <div style={{ width: '30%', overflowY: "auto" }}>{item.binNo}</div>
                               </div>
                                )
                            })}
                              
                                {/* )
                            })} */}
                    </section>

                </div>
                <div className="footer">
                    <button
                        style={{
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                            padding: "5px 15px",
                            marginLeft: "500px",
                            color: "#0A18A3",
                            cursor:'pointer'
                        }}
                        onClick={() => closeModal(false)}
                    >
                        <KeyboardBackspace style={{ fontSize: "17px" }} />
                        Back
                    </button>
                </div>

               
            </div>
        </div>
    );
};

export default ReturnedQty;