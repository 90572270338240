import React, { Component, useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import "../bom.scss";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";

function EditBomLabor({ LaborData, idx, bomLabData }) {
  const [final, setFinal] = useState(bomLabData);
  const [laborSubCategoryData, setSubcategoryData] = useState([]);
  const [laborCategoryValue, setLaborCategoryValue] = useState();

  const handleSubmit = () => {
    console.log("submit pressed");
    console.log(final);
    LaborData(final, idx);
  };

  let addFormFields = () => {
    setFinal([
      ...final,
      {
        laborCategory: "",
        laborSubCategory: "",
        laborLevel: "",
        laborDuration: "",
        requiredDuration: "",
        requiredWorkers: "",
      },
    ]);
  };

  const removeFields = (index) => {
    const field = [...final];
    field.splice(index, 1);
    setFinal(field);
  };

  const handleLaborData = async (event, index) => {
    const { name, value } = event.target;
    const field = [...final];
    field[index][name] = value;

    if (name == "laborCategory") {
      setLaborCategoryValue(value);
    }
    if (name == "laborLevel") {
      const resp = await trackPromise(
        API.get(
          `production-module/api/v1/bom/fetchLaborSubCategory?laborCategory=${laborCategoryValue}&laborLevel=${value}`
        )
      );
      setSubcategoryData(resp);
    }
    if (name == "laborSubCategory") {
      const selectedData = laborSubCategoryData.filter(
        (item) => item.laborSubCategory == value
      );
      console.log(selectedData);
      field[index]["laborID"] = selectedData[0].laborID;
    }
    setFinal(field);
  };

  return (
    <>
      <Accordion style={{ width: "100%" }}>
        <div style={{ backgroundColor: "#ebebeb" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Labor</Typography>
          </AccordionSummary>
        </div>
        <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
          {final.map((item, index) => {
            return (
              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <div
                  style={{
                    margin: "0px 5px 0px 5px",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                      marginLeft: "-53px",
                    }}
                  >
                    Labor Category<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    name="laborCategory"
                    className="drpdwnbox"
                    defaultValue={item.laborCategory}
                    style={{
                      width: "100%",
                      outline: "none",
                      marginTop: "-8px",
                    }}
                    onChange={(event) => handleLaborData(event, index)}
                  >
                    <option value="" selected disabled>
                      Labor Category
                    </option>
                    <option>Unskilled</option>
                    <option>Skilled</option>
                    <option>Professional</option>
                  </select>
                </div>
                <div
                  style={{
                    margin: "0px 5px 0px 5px",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                      marginLeft: "-77px",
                    }}
                  >
                    Labor Level<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    name="laborLevel"
                    className="drpdwnbox"
                    defaultValue={item.laborLevel}
                    style={{
                      width: "100%",
                      outline: "none",
                      marginTop: "-8px",
                    }}
                    onChange={(event) => handleLaborData(event, index)}
                  >
                    <option value="" disabled selected>
                      Labor Level
                    </option>
                    <option>Fresher</option>
                    <option>Intermediate</option>
                    <option>Experienced</option>
                  </select>
                </div>
                <div
                  style={{
                    margin: "0px 5px 0px 5px",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                      marginLeft: "-28px",
                    }}
                  >
                    Labor Sub Category<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    name="laborSubCategory"
                    className="drpdwnbox"
                    value={item.laborSubCategory}
                    style={{
                      width: "100%",
                      outline: "none",
                      marginTop: "-8px",
                    }}
                    onChange={(event) => handleLaborData(event, index)}
                  >
                    <option selected>{item.laborSubCategory}</option>
                    {laborSubCategoryData.map((item, index) => (
                      <option key={index}>{item.laborSubCategory}</option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    margin: "0px 5px 0px 5px",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                      marginLeft: "-53px",
                    }}
                  >
                    Required Duration<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="requiredDuration"
                    className="drpdwnbox"
                    placeholder="required Duration"
                    style={{
                      width: "100%",
                      outline: "none",
                      marginTop: "-8px",
                    }}
                    autoComplete="off"
                    defaultValue={item.requiredDuration}
                    onChange={(event) => handleLaborData(event, index)}
                  />
                </div>
                <div
                  style={{
                    margin: "0px 5px 0px 5px",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                      marginLeft: "-125px",
                    }}
                  >
                    Unit<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    name="laborDuration"
                    className="drpdwnbox"
                    style={{
                      width: "100%",
                      outline: "none",
                      marginTop: "-8px",
                    }}
                    defaultValue={item.laborDuration}
                    onChange={(event) => handleLaborData(event, index)}
                  >
                    <option value="" disabled selected>
                      Duration
                    </option>
                    <option>Hour</option>
                    <option>Day</option>
                    <option>Week</option>
                    <option>Month</option>
                  </select>
                </div>
                <div
                  style={{
                    margin: "0px 5px 0px 5px",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                      marginLeft: "-46px",
                    }}
                  >
                    Required Workers<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    name="requiredWorkers"
                    className="drpdwnbox"
                    style={{
                      width: "100%",
                      outline: "none",
                      marginTop: "-8px",
                    }}
                    placeholder="required Workers"
                    autoComplete="off"
                    value={item.requiredWorkers}
                    onChange={(event) => handleLaborData(event, index)}
                  />
                </div>
                {index === 0 ? (
                  <button
                    type="button"
                    className="btn btn-md"
                    onClick={() => addFormFields()}
                  >
                    <div style={{ color: "green" }}>
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-md"
                    onClick={() => removeFields(index)}
                  >
                    <div style={{ color: "red" }}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  </button>
                )}
              </div>
            );
          })}
          <br />
          {/* <div className="bomfooter">
            <button id="bomcancel">Cancel</button>
            <button id="bomsubmit" onClick={handleSubmit}>
              {" "}
              Submit
            </button>
          </div> */}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default EditBomLabor;
