import { KeyboardBackspace } from "@material-ui/icons";
import React from "react";
import moment from "moment";

const ReturnRequestViewDetails = ({ rowData, closeModal }) => {
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "520px", height: "320px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Return Request</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginBottom: "2%",
            }}
          >
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div>Request ID: {rowData?.requestID}</div>
              <div>Request Date: {moment(rowData?.requestDate).format("DD-MM-YYYY")}</div>
              <div>Request By: {rowData?.showroomEmail}</div>
              <div>GIN No.: {rowData?.ginNo}</div>
            </section>
            <section
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div>RPI No.: {rowData?.rpiNo}</div>
              <div>RPI Date: {moment(rowData.rpiDate).format("DD-MM-YYYY")}</div>
              <div>RPI Raised By: {rowData?.showroomName}</div>
              <div>Return Qty: {}</div>
            </section>
          </section>
          <br />
          <div><button className="deactive-btn" style={{color:"#A45A22", width:"148px"}}>On Hold By Finance<span className="deactive-btn-font"></span></button></div>
          <br />
         <section style={{width:"99%",display:"flex", flexDirection:"column", border:"1px solid #EEEEEE", textAlign:"start", justifyContent:"start", marginTop:"2%", padding:"2%"}}>
              <div>Hold Reason: {rowData?.reason}</div>
         </section>
        </div>

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReturnRequestViewDetails;
