import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import Vector from '../QcDashboard/assets/Vector.svg'
import Group18 from '../QcDashboard/assets/Group18.svg'
import Group19 from '../QcDashboard/assets/Group19.svg'
import Group20 from '../QcDashboard/assets/Group20.svg'
import '../ShopFloorDashboard/shopFloorDash.scss';
import Vector3 from "../QcDashboard/assets/Vector3.svg";
import moment from "moment";

function FinishedGoodsBox({ props, startDate, endDate }) {
    const [data, setData] = useState({})
    const fetchReport = async () => {
        const result = await trackPromise(API.get(startDate == null && endDate == null ? `management/api/v1/finishedGoods?vehicleCategory=${props}` : `management/api/v1/finishedGoods?vehicleCategory=${props}&startDate=${moment(startDate).format("DD-MMM-YYYY")}&endDate=${moment(endDate).format("DD-MMM-YYYY")}`));
        setData(result);
      };
      useEffect(() => {
        fetchReport();
      }, [endDate]);


    return (
        <div className='requestBody'>
            <div className='shopFloorRequest' style={{ width: "150px", height: "160px", marginTop: "15px" }}>
                <img src={Group18} style={{ marginTop: "20px", marginLeft: "auto", marginRight: "auto", display: "block" }} alt="group" />
                <div style={{ color: "#808080", fontSize: "14px", textAlign: "center", margin: "10px 6px 0px 6px", borderBottom: "2px solid #F2F2F2" }}>Total Vehicles</div>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
                    <div style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px",textAlign:"center" }}>{data.totalVehicle}</div>
                </div>
            </div>

            <div className='shopFloorRequest' style={{ width: "150px", height: "160px", marginTop: "15px" }}>
                <img src={Group19} style={{ marginTop: "20px", marginLeft: "auto", marginRight: "auto", display: "block" }} alt="group" />
                <div style={{ color: "#808080", fontSize: "14px", textAlign: "center", margin: "10px 6px 0px 6px", borderBottom: "2px solid #F2F2F2" }}>Total Models</div>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
                    <div style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px",textAlign:"center" }}>{data.totalModel}</div>
                </div>
            </div>

            <div className='shopFloorRequest' style={{ width: "150px", height: "160px", marginTop: "15px" }}>
                <img src={Group20} style={{ marginTop: "20px", marginLeft: "auto", marginRight: "auto", display: "block" }} alt="group" />
                <div style={{ color: "#808080", fontSize: "14px", textAlign: "center", margin: "10px 6px 0px 6px", borderBottom: "2px solid #F2F2F2" }}>Total Variants</div>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", marginLeft: "15px" }}>
                    <div style={{ fontSize: "24px", fontWeight: "450", marginTop: "15px",textAlign:"center" }}>{data.totalVariant}</div>
                </div>
            </div>
        </div>
    )
}

export default FinishedGoodsBox