import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../../utils/api";
import { errorNotification, successNotification } from "../../../../../utils/notifications";
import moment from "moment";
import {
  CheckCircle
} from "@material-ui/icons";
import DealerVehiclePO from "../../../../RequisitionOrders/DealerVehiclePO";
import auth from "../../../../../utils/auth";

const PutOnHold = ({ rowData, closeModal, updatedData }) => {
  const [formValue, setFormValue] = useState({
    rejectReason: "",
    description: "",
  });

  const [openPO, setPO] = useState(false);

  const editDetails = {

    "requisitionID": rowData.requisitionID,

    "status": "PO Rejected",

    "reason": formValue.rejectReason,

    "description": formValue.description

  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    if(formValue.rejectReason){
      const resp = await trackPromise(API.put(`/salesUser-module/api/v1/orders/vehicleUpdateStatus`, editDetails));
      if (resp.success) {
        successNotification("Rejected", { autoClose: 10000 });
        refresh();
        closeModal(false)
      } else {
        errorNotification(resp.message, { autoClose: 10000 });
      }
    }else{
      errorNotification("Please enter reject reason", { autoClose: 10000 });
    }
 
  };


  return (
    <div className="container1">
      {
        openPO ? <DealerVehiclePO rowData={rowData} closeModal={setPO} type="vehicleData" /> : ""
      }
      <div
        className="modalContainer"
        style={{ width: "500px", height: "400px", padding: "15px" }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <h3>Reject</h3>
        </div>
        <br />
        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "13px" }}
        >
          <section
            style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: "2%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <span>Request ID: {rowData.requisitionID}</span>
              <span>
                <span>PO No.: </span>
                <span style={{ color: "blue", cursor: "pointer" }} onClick={() => setPO(true)}>{rowData.poNo}</span>
              </span>
              <span>PO Date: {moment(rowData.poDate).format("DD-MM-YYYY/HH:mm")}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <span>PO By: {rowData.poBy}</span>
              <span>{`PO Value (${auth.getUserInfo().currencySymbol})`} : {rowData.poValue}</span>
            </div>
          </section>
          <section style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "83px" }}>Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="rejectReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', marginTop: '-5px' }}
            >
              <option disabled selected hidden>
                Select Reason
              </option>
              <option value="Misconduct">Misconduct</option>
              <option value="Violation of Terms">Violation of Terms</option>
              <option value="Others">Others</option>
            </select>

            {formValue.rejectReason === "Others" ? (
              <label className="lgInput">
                Description
                <input
                  type="text"
                  style={{ width: "508px" }}
                  name="description"
                  value={formValue.description}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      [e.target.name]: e.target.value,
                    })
                  }
                  placeholder="Reject Reason"
                  autoComplete="off"
                />
              </label>
            ) : (
              ""
            )}
          </section>
        </div>
        <br />

        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
          <button className="btn btn-icon-label" type="submit" onClick={submitHandler} style={{ backgroundColor: "blue", color: "white" }}>
            <div style={{ fontSize: "14px", color: "white", fontWeight: "500" }}><CheckCircle style={{ fontSize: "17px" }} />&nbsp;Submit</div>
          </button>
        </div>

      </div>
    </div>
  );
};

export default PutOnHold;
