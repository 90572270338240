import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import moment from "moment";

const CR_MR_ReturnQty = ({ closeModal, rowData }) => {
    console.log(rowData)
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "980px", height: "480px", padding: '15px' }}
            >
                <div className="header" style={{ width: '100%', display: "flex", justifyContent: "flex-start" }}>
                    <h3>View Details</h3>
                </div>

                <div
                    className="body"
                    style={{ width: '100%', display: "flex", fontSize: "14px" }}
                >


                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                            <span>Component Name: {rowData.sparePartname}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                            <span>Sent For Verification Date: {rowData.partCheckDate}</span>
                        </div>

                    </section>

                    <section
                        id="nr_POno"
                        style={{
                            width: '100%',
                            fontSize: "14px",
                            margin: "10px 0",
                            border: 'none'
                        }}
                    >
                        <div style={{ padding: '0px', border: '1px solid #cdcdcd' }}>
                            <span style={{ width: '5%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>S.No.</span>
                            <span style={{ width: '15%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>Part Serial No.</span>
                            <span style={{ width: '30%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>Status</span>
                            <span style={{ width: '30%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>Reason</span>
                            <span style={{ width: '20%', padding: '10px' }}>Action Date</span>
                        </div>
                        <section style={{ width: "100%", height: '180px', overflow: 'scroll' }}>
                            {rowData.returnQty?.map((item, index) => (
                                <div style={{ padding: '0px', border: '1px solid #cdcdcd', borderTop: 'none' }}>
                                    <span style={{ width: '5%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>{index + 1}</span>
                                    <span style={{ width: '15%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>{item.partSno}</span>
                                    <span style={{ width: '30%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>
                                        {
                                            item.status == 'Passed' ?
                                                <button className="active-btn">
                                                    <span className="active-btn-font">Moved to MRO Inventory</span>
                                                </button> :
                                                <button className="delete-btn">
                                                    <span className="delete-btn-font">Moved to Rejection Inventory</span>
                                                </button>
                                        }
                                    </span>
                                    <span style={{ width: '30%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>
                                        {
                                            item.issue
                                        }
                                    </span>
                                    <span style={{ width: '20%', padding: '10px' }}>{moment(rowData.lastAddedDate).format("DD-MMM-YYYY")}</span>

                                </div>
                            ))}
                        </section>

                    </section>

                </div>

                <div className="footer" style={{ width: '100%', display: "flex", justifyContent: "flex-end" }}>
                    <button style={{ width: '104px', height: '40px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
    );
};

export default CR_MR_ReturnQty;   