import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { errorNotification, successNotification } from "../../../utils/notifications";
import {
    CancelOutlined,
    CheckCircle,
  } from "@material-ui/icons";

const FullClosure = ({closeModal,editData,releaseType}) => {
  const submitHandler = async() => {
    let fullClosureData = {
      POID: editData.POID
     };

    const resp = await trackPromise(API.post(`procurement-module/api/v1/orders/markFullClosure`, fullClosureData));
    console.log(resp);
    if(resp.success){
     closeModal(false);
     successNotification(resp.message,{autoClose:3000})
     refresh()
    }else{
     errorNotification(resp.message,{autoClose:3000})
    }
  };

  return (
    <div className='container1'>
            <div className="modalContainer" style={
                {width:'400px',height:'200px'}
            }>

            <div className="body" style={{justifyContent:'center',fontSize:'16px'}}>
                    Are you sure to mark this <br /> order as fully closed?
                
            </div>
            <div className="footer">
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: 'white',
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
            onClick={submitHandler}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>

        </div>
        </div>
        </div>
  )
}

export default FullClosure