import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import ComponentOutward from "./componentOutward/componentOutward";
// import VehicleOutward from "./vehicleOutward/vehicleOutward";

const VehicleOutward = React.lazy(() =>
  import("./vehicleOutward/vehicleOutward")
);
const ComponentOutward = React.lazy(() =>
  import("./componentOutward/componentOutward")
);
const BatteryOutward = React.lazy(() =>
  import("./componentOutward/BatteryOutward")
);

const tabs = [
  {
    id: "INM5T1",
    label: TabLabels.VEHICLE_OUTWARD,
    Component: VehicleOutward,
  },
  {
    id: "INM5T2",
    label: TabLabels.COMPONENT_OUTWARD,
    Component: ComponentOutward,
  },
  {
    id: "INM5T3",
    label: TabLabels.BATTERY_OUTWARD,
    Component: BatteryOutward,
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

const Outward = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default Outward;
