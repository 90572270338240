import React, { useState } from "react";
import moment from "moment";
import { KeyboardBackspace } from "@material-ui/icons";

const ViewComponentsFG = ({ close, rowdata }) => {
  console.log(rowdata);
  console.log(rowdata.requiredComponents.componentName);

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "722px", height: "554px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Required Components</h3>
            <div>
              {/* <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Print 
          </button>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Export
          </button> */}
            </div>
          </div>

          <div className="body" style={{ height: "340px" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>WO ID:{rowdata.orderID}</span>
                <span>BOM ID:{rowdata.BOMID}</span>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  WO Creation Date:
                  {moment(rowdata.requestDate).format("DD-MMM-YYYY,HH:mm")}
                </span>
                <span>BOM Name:{rowdata.BOMName}</span>
              </div>
            </div>
            <div style={{ width: "100%", overflow: "auto", height: "340px" }}>
              <table className="viewcomp_tbl">
                <thead>
                  <tr>
                    <th style={{ width: "15%" }}>S.No.</th>
                    <th>Component Name</th>
                    <th>SKU Code</th>
                    <th>Required Qty.</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                {rowdata?.requiredComponents?.map((value, index) => {
                  return (
                    <>
                      <tr>
                        <td className="leftcell">{index + 1}</td>
                        <td>{value?.componentName}</td>
                        <td>{value?.skuCode}</td>
                        <td>{value?.requiredQuantity}</td>
                        <td className="rightcell">{value?.description}</td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </div>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}> <KeyboardBackspace/> Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewComponentsFG;
