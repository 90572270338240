import React, { useEffect, useState } from "react";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
  ConfirmationNumber,
} from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AssignedSpares from "./AssignedSpares";

const ViewAssignedHistory = ({ closeModal, rowData }) => {
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState({});
  const [array, setArray] = useState([
    ...rowData?.inspectionsChecks?.spares,
    ...rowData?.additionalSpares,
  ]);


console.log("rowData-->",rowData)
console.log("array-->",array)

const handler = (item) =>{
  setOpen(true);
  setRow(item)
}
 
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1200px", height: "420px", padding: "15px" }}
      >
        <div className="header" style={{ marginTop: "-10px" }}>
          <h3>View & Assign Pending</h3>
        </div>
        <br />
        <div
          className="body"
          style={{
            display: "flex",
            height: "450px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span
                style={{fontSize: "13px", fontWeight: "500" }}
              >
                Job Card ID: {rowData.jobID}
              </span>
              <span style={{ fontSize: "13px", fontWeight: "500" }}>
                Service Advisor: {rowData.serviceAdvisorName}
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span
                style={{ fontSize: "13px", fontWeight: "500" }}
              >
                Job Card Type: {rowData?.serviceDetails?.serviceType}
              </span>
              <span style={{ fontSize: "13px", fontWeight: "500" }}>
                Technician Name: {rowData.serviceDetails.technicianName}
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontSize: "13px", fontWeight: "500" }}>
                Job Card Date: {moment().format("DD-MM-YYYY")}
              </span>
            </div>
            <br />
            <div style={{ overflow: "scroll" }}>
              <div id="POVendorHeading">
                <div style={{ width: "10%" }}>S. No.</div>
                <div style={{ width: "20%" }}>SKU Code</div>
                <div style={{ width: "20%" }}>Spare Part Name</div>
                <div style={{ width: "20%" }}>Category</div>
                <div style={{ width: "20%" }}>Sub-Category</div>
                <div style={{ width: "20%" }}>Required Qty.</div>
                <div style={{ width: "20%" }}>Assigned Qty.</div>
                <div style={{ width: "20%" }}>Pending Qty.</div>
              </div>
              <div>
                  {array?.map((item,index) => {
                    return (
                      <div  id="POVendorContent"
                      style={{
                        borderBottom: "1px solid #999999",
                        boxShadow: "none",
                        padding: "0px",
                      }}>
                        <div style={{ width: "10%", overflow:"scroll" }}>{index+1}</div>
                        <div style={{ width: "20%", overflow:"scroll" }}>{item?.skuCode}</div>
                        <div style={{ width: "20%", overflow:"scroll" }}>{item?.componentName}</div>
                        <div style={{ width: "20%", overflow:"scroll" }}>{item?.category}</div>
                        <div style={{ width: "20%", overflow:"scroll" }}>{item?.subCategory}</div>
                        <div style={{ width: "20%", overflow:"scroll" }}>{item?.requiredQty}</div>
                        <div style={{ width: "20%", overflow:"scroll", cursor:"pointer" }} 
                        // onClick={()=>{handler(item)}}
                        >{item?.assignedQty}</div>
                        <div style={{ width: "20%", overflow:"scroll" }}>{item?.requiredQty-item?.assignedQty}</div>
                      </div>
                    );
                    
                  })}
                  {
                    open && <AssignedSpares array={setArray} rowData={row} closeModal={setOpen}/>
                  }
              </div>
            </div>
          </section>
        </div>

        <div className="footer" style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
        <div style={{width:"100%", textAlign:"end"}}>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "20px" }} />
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAssignedHistory;
