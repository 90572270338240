import axios from "axios";
import auth from "./auth";
import AppConstants from "./app-constants";
import { trackPromise } from "react-promise-tracker";
import { errorNotification } from "./notifications";


const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};
// const requestHandler = (request) => {
//   document.body.classList.add("loading-indicator");
//   if (isHandlerEnabled(request)) {
//     const authToken = auth.getToken();
//     request.headers[AppConstants.AUTH_TOKEN_KEY] = authToken
//       ? "Bearer " + authToken
//       : null;
//   }
//   return request;
// };

const requestHandler = (request) => {
  document.body.classList.add("loading-indicator");
 
  // Add the ngrok header to bypass the browser warning
  request.headers['ngrok-skip-browser-warning'] = 'true';
 
  if (isHandlerEnabled(request)) {
const authToken = auth.getToken();
    request.headers[AppConstants.AUTH_TOKEN_KEY] = authToken
      ? "Bearer " + authToken
      : null;
  }
return request;
};



const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",
});

API.interceptors.request.use((request) => requestHandler(request));

API.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

const successHandler = (response) => {
  document.body.classList.remove("loading-indicator");
  return response.data;
};

const errorHandler = async (error) => {
  document.body.classList.remove("loading-indicator");
  const statusCode = error.response ? error.response.status : null;
  const url = error.response.request.responseURL;
  const originalRequest = error.config;
  if (statusCode === 403) {
    auth.clearAll();
    window.location.href = process.env.FRONTEND_URL
  }
  if (
    statusCode === 401 &&
    url !== `${process.env.REACT_APP_BASE_URL}/admin-module/api/v1/login` &&
    !originalRequest._retry
  ) {
    // errorNotification("Please login to continue.");
    const refreshToken = {
      refreshToken: auth.getRefreshToken(),
    };
    const resp = await trackPromise(
      API.post(
        `${process.env.REACT_APP_BASE_URL}/admin-module/api/v1/login/reissueToken`,
        refreshToken
      )
    );
    auth.setToken(resp.accessToken);
    //api retry
    originalRequest._retry = true;
    originalRequest.headers["Authorization"] = "Bearer " + resp.accessToken;
    return API.request(originalRequest);
  }

  if (error.response) {
    console.log(error.response)
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { data } = error.response;
    const errMsg = (data || {}).message || "";
    errorNotification(data.message, { autoClose: 2000 });
    console.error(errMsg);
  } else if (error.request) {
    console.log(error.request);
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the
    // browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
    errorNotification(error.message);
  }

  return Promise.reject({ ...error });
};

export default API;
