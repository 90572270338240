import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";

const ShowroomVehicleWarrantyContent = React.lazy(()=>import("./ShowroomBatteryWarrantyContent"))


const tabs = [
  {
    id: "SHOM6T1",
    label: TabLabels.BATTERY_WARRANTY,
    Component: ShowroomVehicleWarrantyContent,
  },
  // {
  //   id: "SHOM6T2",
  //   label: TabLabels.BATTERY_WARRANTY,
  //   Component: ShowroomVehicleWarrantyContent,
  // }
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};


const ShowroomBatteryWarranty= () => {

  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  )
};

export default ShowroomBatteryWarranty;
