import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import Components from "./components";
import Spares from "./Spares";
import FinishedGoods from "./finishedGoods";
import Vehicles from "./vehicles";
import VerificationHistory from "./verificationHistory";

const tabs = [
  {
    label: TabLabels.COMPONENTS,
    Component: Components
  },
  {
    label: TabLabels.SPARES,
    Component: Spares
  },
  {
    label: TabLabels.FINISHEDGOODS,
    Component: FinishedGoods
  },
  {
    label: TabLabels.VEHICLE,
    Component: Vehicles
  },
  {
    label: TabLabels.VERIFICATIONHISTORY,
    Component: VerificationHistory
  },
];

const Vendor = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default Vendor;
