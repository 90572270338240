import React, { useState } from "react";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
} from "@material-ui/icons";
import moment from "moment";
import ViewSpecs from "../../bom/bom-viewspecs";
import AppConstants from "../../../utils/app-constants";

const GRNViewDetails = ({ closeModal, rowdata }) => {
  const [openQty, setOpenQty] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const [showGRN, setShowGRN] = useState();
  const [showGIN, setShowGIN] = useState();

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1130px", height: "450px", padding: "15px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>View Details</h3>
          {/* <div>
            <button className="action-btn" style={{ color: "blue" }}>
              Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              Export
            </button>
          </div> */}
        </div>

        <section
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: "500",
            margin: "10px 0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>GRN No: {rowdata?.GRNID}</span>
            <span>PO No: {rowdata?.POID}</span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>GRN Created By: {rowdata?.GRNCreatedBy}</span>
            <span>
              PO Date: {moment(rowdata?.PODate).format("DD-MMM-YYYY, HH:mm")}
            </span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              GRN Creation Date:{" "}
              {moment(rowdata?.GRNCreatedDate).format("DD-MMM-YYYY, HH:mm")}
            </span>
            <span>BOM Location: {rowdata?.bomLocation}</span>
          </div>
        </section>

        <section>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
            }}
          >
            <span>Vendor Name: {rowdata?.vendorName}</span>
            <span style={{ marginRight: "-140px" }}>
              Recieved By: {rowdata?.receivedBy}
            </span>
            <span></span>
            <span></span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
            }}
          >
            <span>Vendor Location: {rowdata?.vendorCity}</span>
            <span>
              Receiving Date:{" "}
              {moment(rowdata?.receivedDate).format("DD-MMM-YYYY, HH:mm")}
            </span>
            <span>Delivery Location: {rowdata?.deliveryLocation}</span>
          </div>
        </section>

        <div
  className="body"
  style={{
    display: "flex",
    height: "300px", // Fixed height to make the container scrollable
    fontSize: "14px",
    overflowY: "auto", // Enable vertical scrolling
    width: "100%",
  }}
>
  <section style={{ width: "100%" }}>
    {/* Sticky header */}
    <div
      id="POVendorHeading"
      style={{
        display: "flex",
        position: "sticky",
        top: 0, // Stick the header to the top while scrolling
        backgroundColor: "#f9f9f9", // Background color to make the sticky header more visible
        zIndex: 1,
      }}
    >
      <div style={{ width: "5%" }}>S.No.</div>
      <div style={{ width: "11%" }}>Component Name</div>
      <div style={{ width: "9%" }}>SKU Code</div>
      <div style={{ width: "11%" }}>Vendor Part Name</div>
      <div style={{ width: "11%" }}>Vendor Part Code</div>
      <div style={{ width: "9%" }}>Category</div>
      <div style={{ width: "9%" }}>Sub Category</div>
      <div style={{ width: "9%" }}>Specification</div>
      <div style={{ width: "9%" }}>Ordered Qty.</div>
      <div style={{ width: "9%" }}>Received Qty</div>
      <div style={{ width: "9%" }}>Remaining Qty</div>
    </div>

    {rowdata?.components?.map((item, index) => {
      return (
        <div key={index}>
          <div
            id="POVendorContent"
            style={{
              display: "flex",
              borderBottom: "1px solid #999999",
              padding: "5px 0",
            }}
          >
            <div style={{ width: "5%", overflowY: "auto", padding:"0" }}>{index + 1}</div>
            <div style={{ width: "11%", overflowY: "auto" , padding:"0" }}>
              {item?.componentName}
            </div>
            <div style={{ width: "9%", overflowY: "auto" , padding:"0" }}>{item?.skuCode}</div>
            <div style={{ width: "11%", overflowY: "auto" , padding:"0" }}>
              {item?.vendorPartName}
            </div>
            <div style={{ width: "11%", overflowY: "auto" , padding:"0" }}>
              {item?.vendorPartCode}
            </div>
            <div style={{ width: "9%", overflowY: "auto" , padding:"0" }}>{item?.category}</div>
            <div style={{ width: "9%", overflowY: "auto" , padding:"0" }}>
              {item?.subCategory}
            </div>
            <div
              onClick={() => setViewSpecs(index)}
              style={{
                width: "9%",
                color: "blue",
                cursor: "pointer"
                , padding:"0"
                
              }}
            >
              View Specs
            </div>
            <div style={{ width: "9%", overflowY: "auto", padding:"0" }}>
              {item?.requiredQuantity}
            </div>
            <div style={{ width: "9%", overflowY: "auto", padding:"0" }}>
              {item?.receivedQuantity}
            </div>
            <div style={{ width: "9%", overflowY: "auto" , padding:"0"}}>
              {item?.requiredQuantity - item?.receivedQuantity}
            </div>
          </div>

          {viewSpecs === index && (
            <ViewSpecs rowData={item} close={setViewSpecs} />
          )}
        </div>
      );
    })}
  </section>
</div>


        <div className="footer">
          <div>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "25px" }} />
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GRNViewDetails;
