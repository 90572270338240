import React, { useState } from "react";
import ReassignWO from "./wo-reassign";
import ChangeAssign from "./wo-changeassign";
import "../../../components/modal/modal.scss";
import { faEdit,faBan,faTrash,faDotCircle,faShare} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppConstants from "../../../utils/app-constants";


const ActionWO = ({updatedData,rowData,status,showedit , rowIndex}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});


  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      reassign: false,
      changeassign:false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>

      { rowData.status === AppConstants.REJECTED ?
      activeModal.reassign && showModal && 
        <ReassignWO rowData={rowData} updatedData={updatedData} closeModal={setShowModal} />
         : null
      }
    {rowData.status === AppConstants.ASSIGNED || rowData.status === AppConstants.ACCEPTED ? 
      activeModal.changeassign && showModal && 
        <ChangeAssign rowData={rowData} updatedData={updatedData} closeModal={setShowModal} />
        :null
    }
     
       <div style={{ position: "relative", marginTop: rowIndex <= 4 ? "0px" : "-45px"}}>
      <div className="vehicleaction">
        <div>
          <span id="edit" onClick={(e) => showedit(false)}>
          <FontAwesomeIcon icon={faEdit}/>  Edit
          </span>
        </div>
        {rowData.status === AppConstants.REJECTED ? 
        <div>
          <span id="reassign" onClick={(e) => clickHandler(e)}>
          <FontAwesomeIcon icon={faShare}/>  Reassign
          </span>
        </div> : null}

    {rowData.status === AppConstants.ASSIGNED || rowData.status === AppConstants.ACCEPTED ? 
         <div>
          <span id="changeassign" onClick={(e) => clickHandler(e)}>
          <FontAwesomeIcon icon={faShare}/>  Change Assignment
          </span>
        </div>
        :null
    }   
      </div>
      </div>
    </div>
  );
};

export default ActionWO;
