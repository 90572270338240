import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import Requisition from "./components/Requisitions";
// import GoodsArrival from "./components/GoodsArrival";
// import GoodsVerification from "./components/GoodsVerification";
// import POHistory from "./components/POHistory";

const Requisition = React.lazy(()=>import("./components/Requisitions"))
const GoodsArrival = React.lazy(()=>import("./components/GoodsArrival"))
const GoodsVerification = React.lazy(()=>import("./components/GoodsVerification"))
const POHistory = React.lazy(()=>import("./components/POHistory"))


const tabs = [
  {
    id: "STMM2T1",
    label: TabLabels.REQUISITION,
    Component: Requisition,
  },
  {
    id: "STMM2T2",
    label: TabLabels.GOODS_ARRIVAL,
    Component: GoodsArrival,
  },
  {
    id: "STMM2T3",
    label: TabLabels.GOODS_VERIFICATION,
    Component: GoodsVerification,
  },
  {
    id: "STMM2T4",
    label: TabLabels.PO_HISTORY,
    Component: POHistory,
  }
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};


const StoreManagerOrders= () => {

  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  )
};

export default StoreManagerOrders;
