import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import Group8 from "../QcDashboard/assets/Group8.svg";
import Group9 from "../QcDashboard/assets/Group9.svg";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);
ChartJS.defaults.plugins.legend.display = false;


function OprVehiclesCard({ prop }) {
    const [data, setData] = useState({});
    const [spareData, setSpareData] = useState({});

    const fetchData = async () => {
        const res = await trackPromise(
            API.get(`salesUser-module/api/v1/dashboard/vehicles`)
        );
        setData(res);
    };
    const fetchSpareData = async () => {
        const res = await trackPromise(
            API.get(`salesUser-module/api/v1/dashboard/spare`)
        );
        setSpareData(res);
    };
    useEffect(() => {
        { prop == "vehicle" ? fetchData() : fetchSpareData() }
    }, []);

    return (
        <div className="requestBody" style={{ justifyContent: "start" }}>
            <div
                className="shopFloorRequest"
                style={{
                    height: "auto",
                    width: "48%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                }}
            >
                <div style={{ width: "35%", padding: "4%" }}>
                    <img
                        src={Group9}
                        style={{
                            height: "50px",
                            display: "block",
                        }}
                        alt="group"
                    />
                </div>

                <div style={{ width: "65%" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            marginLeft: "15px",
                        }}
                    >
                        <div
                            style={{
                                fontSize: "24px",
                                fontWeight: "450",
                                textAlign: "start",
                            }}
                        >
                            {prop == "vehicle" ? data.totalVehicles : spareData.totalVehicles}
                        </div>
                    </div>
                    <div
                        style={{
                            color: "#808080",
                            fontSize: "14px",
                            textAlign: "start",
                            margin: "10px 6px 0px 6px",
                            borderBottom: "2px solid #F2F2F2",
                        }}
                    >
                        {prop == "vehicle" ? "Total Vehicles" : "Total Spares"}
                    </div>
                </div>
            </div>
            <div
                className="shopFloorRequest"
                style={{
                    height: "auto",
                    width: "48%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                }}
            >
                <div style={{ width: "35%", padding: "4%" }}>
                    <img
                        src={Group8}
                        style={{
                            height: "50px",
                            display: "block",
                        }}
                        alt="group"
                    />
                </div>
                <div style={{ width: "65%" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            marginLeft: "15px",
                        }}
                    >
                        <div
                            style={{
                                fontSize: "24px",
                                fontWeight: "450",
                                textAlign: "start",
                            }}
                        >
                            {prop == "vehicle" ? data.availableQty : spareData.availableQty}
                        </div>
                    </div>
                    <div
                        style={{
                            color: "#808080",
                            fontSize: "14px",
                            textAlign: "start",
                            margin: "10px 6px 0px 6px",
                            borderBottom: "2px solid #F2F2F2",
                        }}
                    >
                        {prop == "vehicle" ? "Available Vehicles" : "Available Spares"}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OprVehiclesCard