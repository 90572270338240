import React from "react";
import { Tabs } from "react-simple-tabs-component";
// import "./tabs.scss";
// import auth from "../../utils/auth";

import ManageQuery from "./ManageQuery";
import ViewQuery from "./ViewQuery";

const superAdminTabs = [
  {
    label: "View Query",
    Component: ViewQuery
  },
  {
    label: "Manage Query",
    Component: ManageQuery
  },
  
];



const SupportTabs = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={superAdminTabs} type="pills" className="tabs-line"/>
    </div>
  );
};

export default SupportTabs;
