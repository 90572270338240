import React, { useState } from "react";
import auth from "../../../utils/auth";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification,errorNotification } from "../../../utils/notifications";

const BlacklistVendor = ({ updatedData,editData,closeModal, setIsOpen}) => {
  const [formValue, setFormValue] = useState({
    blacklistReason: "",
    description: "",
  });

  const editDetails  = {
    "vendorID": editData.vendorID,
    "reason": formValue.blacklistReason,
    "reasonComment": formValue.description
    };

    const refresh = () => {
      updatedData()
  };

  const submitHandler = async () => {
    const resp = await trackPromise(API.post(`procurement-module/api/v1/vendors/blacklist`, editDetails));
    
    const { success,message} = resp;
    
    if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        refresh()
        setIsOpen(false)
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
  };

  
  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Blacklist Vendor</h3>
        </div>

        <div className="body">
          <label style={{display:'flex', flexDirection:'column'}}>
          <label style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    textAlign:"start"
                  }}>BlackList Reason <span style={{color:"red"}}>*</span></label>
            <select
              name="blacklistReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', outline: 'none', marginTop:'-8px'  }}
            >
              <option disabled selected hidden>
              BlackList Reason
              </option>
              <option value="Misleading Activities">
              Misleading Activities
              </option>
              <option value="Continuous Abuse of Business terms">
              Continuous Abuse of Business terms
              </option>
              <option value="Inappropriate Behavior">
              Inappropriate Behavior
              </option>
              <option value="Others">Others</option>
            </select>
          </label>

          {formValue.blacklistReason === "Others" ? 
          <label className="lgInput">
            Description
            <input
              type="text"
              style={{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Blacklist Reason"
              autoComplete="off"
            />
          </label> : "" }
        </div>
        
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={formValue.blacklistReason==""?"" : submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlacklistVendor;