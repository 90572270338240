import React, { useEffect, useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";

const ViewSparesRequestUnit = ({ 
  closeModal, 
}) => {
  

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "400px", height: "300px", padding: "15px" }}>
        <div className="header" style={{ marginTop: "-10px", width: "100%", justifyContent: "start" }}>
          <h3>View Spare Unit</h3>
        </div>
        <div className="body" style={{ display: "flex", height: "450px", fontSize: "14px", overflow: "scroll", width: "100%" }}>
          <section style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "start" }}>
            <label style={{ fontSize: "14px", fontWeight: "500", width: "100%", textAlign: "start" }}>
              Part S.No. <span style={{ color: "red" }}>*</span>
            </label>

            <div
              style={{
                marginTop: "5px",
                width: "100%",
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                backgroundColor: "#f9f9f9",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                minHeight: "40px",
                cursor: "pointer"
              }}
            >
               <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 8px",
                      border: "1px solid #ccc",
                      borderRadius: "12px",
                      backgroundColor: "#e0e0e0",
                      marginRight: "5px",
                      marginBottom: "5px",
                      fontSize: "13px"
                    }}
                  >
                    <span>{""}</span>
                </div>
            </div>
          </section>
        </div>

        <div className="footer" style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <div>
            <button
              style={{ width: "104px", height: "42px", background: "#fff", border: "1px solid #ccc", borderRadius: "5px" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "20px" }} /> Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSparesRequestUnit;
