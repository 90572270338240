import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
// import VehicleOrders from "./components/VehicleOrders";
// import SpareOrders from "./components/SpareOrders";
// import ProductionRequest from "./components/ProductionRequest";

const VehicleOrders = React.lazy(() => import("./components/VehicleOrders"));
const SpareOrders = React.lazy(() => import("./components/SpareOrders"));
const BatteryOrders = React.lazy(() => import("./components/BatteryOrders"));
const ProductionRequest = React.lazy(() =>
  import("./components/ProductionRequest")
);

const tabs = [
  {
    id: "FIM3T1",
    label: TabLabels.VEHICLE_ORDERS,
    Component: VehicleOrders,
  },
  {
    id: "FIM3T2",
    label: TabLabels.SPARE_ORDERS,
    Component: SpareOrders,
  },
  {
    id: "FIM3T3",
    label: TabLabels.BATTERY_ORDERS,
    Component: BatteryOrders,
  },
  {
    id: "FIM3T4",
    label: TabLabels.PRODUCTION_REQUEST,
    Component: ProductionRequest,
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

const SaleRequest = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default SaleRequest;
