import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Chart as ChartJS, registerables } from 'chart.js'
ChartJS.register(...registerables)
ChartJS.defaults.plugins.legend.display = false;

export default function SpareReturnsCard({ returnsData }) {


    return (
        <div style={{ width: "95%", marginLeft: "10px" }}>
            <ProgressBar>
                <ProgressBar now={returnsData.totalReturnPlaced * 100} style={{ background: "#0A18A3" }} />
                <ProgressBar now={returnsData.returnsPending * 100} style={{ background: "rgba(253, 170, 72, 1)" }} />
                <ProgressBar now={returnsData.returnsProcessed * 100} style={{ background: "rgba(255, 215, 74, 1)" }} />
            </ProgressBar>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#0A18A3", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Total Returns Placed</span>
                </div>
                <div>
                    {returnsData.totalReturnPlaced}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(253, 170, 72, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Total Returns Pending</span>
                </div>
                <div>
                    {returnsData.returnsPending}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(255, 215, 74, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Total Returns Processed</span>
                </div>
                <div>
                    {returnsData.returnsProcessed}
                </div>
            </div>
        </div>
    )
}
