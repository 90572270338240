import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import { successNotification, errorNotification } from "../../../../utils/notifications";
import '../../../../components/modal/modal.scss';
import moment from 'moment';


const EditBookingSales = ({ editData, closeModal, updatedData }) => {
    const [addVRNumber, setVRNumber] = useState(false)

    const [formValue, setFormValue] = useState({
        bookingID: editData.bookingID,
        bookingDate: editData.bookingDate,
        bookingAmount: editData.bookingAmount,
        vehicleSegment: editData.vehicleSegment,
        vehicleCategory: editData.vehicleCategory,
        vehicleType: editData.vehicleType,
        vehicleModel: editData.vehicleModel,
        vehicleVariant: editData.vehicleVariant,
        customerName: editData.customerName,
        customerEmail: editData.customerEmail,
        customerPhone: editData.customerPhone,
        customerAddress: editData.customerAddress,
        addressDoc: editData.addressDoc,
        paymentType: editData.paymentType,
        paymentMode: editData.paymentMode,
        transactionID: editData.transactionID,
        exShowroomPrice: editData.exShowroomPrice,
        vehicleRegNo: editData.vehicleRegNo
    });


    const handleInputChange = async (e) => {
        if (e.target.name == 'addressDoc') {
            setFormValue({ ...formValue, [e.target.name]: e.target.files[0] })
        } else {
            const { name, value } = e.target;
            setFormValue({ ...formValue, [name]: value });
        }

    };

    const refresh = () => {
        updatedData();
    };

    const submitHandler = async () => {
        const editDetails = {
            // bookingID: formValue.bookingID,
            saleID: editData.saleID,
            vehicleRegNo: formValue.vehicleRegNo,
            // bookingDate: formValue.bookingDate,
            // bookingAmount: formValue.bookingAmount,
            // vehicleSegment: formValue.vehicleSegment,
            // vehicleCategory: formValue.vehicleCategory,
            // vehicleType: formValue.vehicleType,
            // vehicleModel: formValue.vehicleModel,
            // vehicleVariant: formValue.vehicleVariant,
            // customerName: formValue.customerName,
            // customerEmail: formValue.customerEmail,
            // customerPhone: formValue.customerPhone,
            // customerAddress: formValue.customerAddress,
            // addressDoc: formValue.addressDoc,
            // paymentType: formValue.paymentType,
            // paymentMode: formValue.paymentMode,
            // transactionID: formValue.transactionID,
            // exShowroomPrice: formValue.exShowroomPrice
        };
        console.log(editDetails)
        let formData = new FormData();
        for (let key in editDetails) {
            formData.append(key, editDetails[key])
        }

        const resp = await trackPromise(API.put(`/showroom-module/api/v1/customerAndSales/editSale`, formData));

        const { success, message } = resp;

        if (success) {
            closeModal(false);
            successNotification(message, { autoClose: 3000 });
            refresh();
        } else {
            errorNotification(message, { autoclose: 2000 });
        }
    };

    console.log(formValue)

    return (
        <div className="container1">
            <div className="modalContainer" style={{ width: '810px', height: "600px" }}>
                <div className="header">
                    <h3>Booking Sales</h3>
                </div>
                <div className="body">

                    <input
                        disabled
                        name="bookingID"
                        defaultValue={editData.bookingID}
                    />

                    <input
                        disabled
                        name="bookingDate"
                        type="text"
                        placeholder={`${editData?.bookingDate}`}
                    />

                    <input
                        disabled
                        name="bookingAmount"
                        type="text"
                        defaultValue={editData.bookingAmount}
                        onChange={event => handleInputChange(event)}
                        placeholder="Booking Amount"
                    />

                    <select
                        disabled
                        name="paymentMode"
                        defaultValue={editData.paymentMode}
                        onChange={event => handleInputChange(event)}
                    >
                        <option value="" disabled selected>Payment Mode</option>
                        <option>Cash</option>
                        <option>Cheque/DD</option>
                        <option>Net Banking</option>
                        <option>Credit/Debit Card</option>
                        <option>E-Wallet</option>
                    </select>

                    <input
                        disabled
                        name="transactionID"
                        type="text"
                        defaultValue={editData.transactionID}
                        onChange={event => handleInputChange(event)}
                        placeholder="Transaction ID"
                    />

                    <input
                        type="text"
                        name="customerName"
                        defaultValue={editData.customerName}
                        placeholder="Customer Name"
                        onChange={event => handleInputChange(event)}
                    />

                    <input
                        disabled
                        type="text"
                        name="customerEmail"
                        defaultValue={editData.customerEmail}
                        placeholder="Customer Email"
                        onChange={event => handleInputChange(event)}
                    />

                    <input
                        disabled
                        type="text"
                        name="customerPhone"
                        defaultValue={editData.customerPhone}
                        placeholder="Customer Phone"
                        onChange={event => handleInputChange(event)}
                    />


                    <input
                        disabled
                        name="vehicleSegment"
                        defaultValue={editData.vehicleSegment}
                        onChange={event => handleInputChange(event)}
                    />

                    <input
                        disabled
                        name="vehicleCategory"
                        defaultValue={editData.vehicleCategory}
                        onChange={event => handleInputChange(event)}
                    />

                    <input
                        disabled
                        name="vehicleModel"
                        defaultValue={editData.vehicleModel}
                        onChange={event => handleInputChange(event)}
                    />

                    <input
                        disabled
                        name="vehicleVariant"
                        defaultValue={editData.vehicleVariant}
                        onChange={event => handleInputChange(event)}
                    />

                    <input
                        disabled
                        name="vehicleType"
                        defaultValue={editData.vehicleType}
                        onChange={event => handleInputChange(event)}
                    />

                    <input
                        disabled
                        type="text"
                        name="customerAddress"
                        defaultValue={editData?.customerAddress}
                        placeholder="Customer Address"
                        onChange={event => handleInputChange(event)}
                    />

                    <input
                        disabled
                        name="addressDoc"
                        type="file"
                        // defaultValue={editData.addressDoc}
                        onChange={event => handleInputChange(event)}
                        placeholder="Upload Address"
                    />

                    <input
                        disabled
                        name="exShowroomPrice"
                        type="text"
                        defaultValue={editData.exShowroomPrice}
                        onChange={event => handleInputChange(event)}
                        placeholder="Ex-Showroom Price"
                    />

                    <select
                        disabled
                        name="paymentType"
                        defaultValue={formValue.paymentType}
                        onChange={event => handleInputChange(event)}
                    >
                        <option value="" disabled selected>Payment Type</option>
                        <option>Full Payment</option>
                        <option>Finance</option>
                    </select>


                </div>
                <div className="footer">
                    <button onClick={() => closeModal(false)}>Cancel</button>
                    <button style={{ width: "23%" }} onClick={() => { setVRNumber(!addVRNumber) }}>Add Vehicle Reg. No</button>
                    {/* <button
            id="submit-button"
            //onClick={submitHandler}
            onClick={()=>{setConfirmGUI(true)}}
            style={{ width: "23%" }}
          >
            Generate Updated Invoice
          </button> */}
                    {/* {
            confirmGUI ? <ConfirmGUI closeModal={setConfirmGUI} proceed={submitHandler} data={data} /> : ""
          } */}

                </div>

                {addVRNumber ?
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <label style={{ display: "flex", flexDirection: "column" }}>
                            <label
                                style={{
                                    float: "left",
                                    marginLeft: "-105px",
                                    fontSize: "13px",
                                    fontWeight: "500",
                                }}
                            >
                                Add Vehicle Reg. No
                            </label>
                            <input
                                style={{ marginTop: "-8px", width: "96%" }}
                                type="text"
                                name="vehicleRegNo"
                                defaultValue={formValue.vehicleRegNo}
                                placeholder="Add Vehicle Reg. No"
                                onChange={(event) => handleInputChange(event)}
                            />
                        </label>

                        <div className="footer">
                            <button onClick={() => { setVRNumber(!addVRNumber) }}>Cancel</button>
                            <button
                                id="submit-button"
                                onClick={submitHandler}
                            >
                                Submit
                            </button>
                        </div>

                    </div> : ""
                }
            </div>
        </div>
    );
};

export default EditBookingSales;
