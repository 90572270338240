import React, { useState } from "react";
import {
    KeyboardBackspace,
    CheckCircle,
} from "@material-ui/icons";
import PurchaseOrder from "../PurchaseOrder";
import moment from "moment";
import auth from "../../../utils/auth";


const ViewStatus = ({ closeModal,viewData }) => {
    console.log(viewData)
    const [openPO, setOpenPO] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState({});
    const [productionQty, setProductionQty] = useState();

    const blankForm = viewData.components;

    const handleComponentChange = (event) => {

        // todo:: filter components from actual data

        // const component = blankForm.filter(
        //   (item) => item.componentName == event.target.value
        // );
        // setSelectedComponent(component[0]);
    };

    const totalPrice = () => {
        let total = 0;
        blankForm.map(item => (
            total += (item.unitPrice * item.requiredQuantity) + ((item.unitPrice * item.requiredQuantity * item.taxRate) / 100)
        ))
        return total;
    }
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "1180px", height: "525px", padding: '15px' }}
            >
                <div className="header">
                    <h3>View Details</h3>
                </div>

                <div
                    className="body"
                    style={{ display: "flex", height: '400px', fontSize: "14px", overflow: 'scroll' }}
                >

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Icon</span>
                            <span style={{ color: '#0A18A3' }}>PO: {viewData.POID}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Buyer Name : Honda Ltd.</span>
                            <span>PO Date : {moment(viewData.PODate).format("DD-MMM-YYYY/HH:mm")}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Buyer Address : Plot no. 20, IMT Manesar, Gurugram (Haryana)</span>
                            <span>Requested By : {viewData.requestedBy}</span>
                        </div>

                    </section>


                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Bill to :</span>
                            <span>Ship to :</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Honda Ltd.</span>
                            <span>Honda Ltd.</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", fontWeight: '400' }}>
                            <span>Plot no. 20, IMT Manesar, Gurugram (Haryana)</span>
                            <span>Plot no. 20, IMT Manesar, Gurugram (Haryana)</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", fontWeight: '400' }}>
                            <span>+91 236548921</span>
                            <span>+91 326945789</span>
                        </div>
                    </section>


                    <section style={{ width: '100%', }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '12%' }}>Component Name</div>
                            <div style={{ width: '10%' }}>SKU Code</div>
                            <div style={{ width: '12%' }}>Vendor Part Name</div>
                            <div style={{ width: '12%' }}>Vendor Part Code</div>
                            <div style={{ width: '10%' }}>#HSN/SAC</div>
                            <div style={{ width: '8%' }}>{`Unit Price (${auth.getUserInfo().currencySymbol})`}</div>
                            <div style={{ width: '8%' }}>Required Qty.</div>
                            <div style={{ width: '8%' }}>{`Qty. Price (${auth.getUserInfo().currencySymbol})`}</div>
                            <div style={{ width: '12%' }}>{`Tax (${auth.getUserInfo().currencySymbol})`}</div>
                            <div style={{ width: '8%' }}>{`Total Price (${auth.getUserInfo().currencySymbol})`}</div>
                        </div>
                        <div>
                            {
                                blankForm.map((item, index) => (
                                <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', }} key={index}>
                                    <div style={{ width: '12%' }}>{item.componentName}</div>
                                    <div style={{ width: '10%' }}>{item.skuCode}</div>
                                    <div style={{ width: '12%' }}>{item.vendorPartName}</div>
                                    <div style={{ width: '12%' }}>{item.vendorPartCode}</div>
                                    <div style={{ width: '10%' }}>{item.hsnSacCode}</div>
                                    <div style={{ width: '8%' }}>{item.unitPrice}</div>
                                    <div style={{ width: '8%' }}>{item.requiredQuantity}</div>
                                    <div style={{ width: '8%' }}>{item.unitPrice * item.requiredQuantity}</div>
                                    <div style={{ width: '12%' }}>{`₹${(item.unitPrice * item.requiredQuantity * item.taxRate) / 100} (${item.taxRate}%)`}</div>
                                    <div style={{ width: '8%' }}>₹{(item.unitPrice * item.requiredQuantity) + ((item.unitPrice * item.requiredQuantity * item.taxRate) / 100)}</div>
                                </div>))
                            }
                        </div>
                    </section>


                    <section className="POterms" style={{width:"100%"}}>
                        <div style={{ width: '900px', }}>
                            <div style={{ fontWeight: '500', textAlign: 'left' }}>Terms & Conditions :-</div>
                            <div style={{ width: '700px', fontSize: '10px', textAlign: 'left' }}>Sed rem suscipit beatae, fugiat dolor modi alias natus doloremque ab libero possimus reiciendis neque doloribus temporibus quos praesentium aliquam <br /> quia consequatur ad, a, explicabo saepe. Ipsam unde aliquid officia.</div>
                        </div>
                        <div style={{ width: '250px' }}>
                            <div style={{ flexDirection: 'row', justifyContent: 'space-around', fontSize: '14px', fontWeight: '500' }}>Sub {`Total (${auth.getUserInfo().currencySymbol})`} : <span>₹{totalPrice()}</span></div>
                            <div style={{ flexDirection: 'row', justifyContent: 'space-around' }}>{`Shipping (${auth.getUserInfo().currencySymbol})`}: <span>{500}</span></div>
                        </div>
                    </section>

                    <section style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', background: '#0A18A3', borderRadius: '5px', margin: '10px 0', height: "35px" }}>
                        <div style={{ width: '230px', color: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', fontSize: '14px', fontWeight: '500' }}>{`Grand Total (${auth.getUserInfo().currencySymbol})`}: <span>{totalPrice() + 500}</span></div>
                    </section>

                </div>

                <div className="footer">
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                    {/* <button
                        style={{
                            background: "#0A18A3",
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                            padding: "6px 20px",
                            marginRight: '0px',
                            width: '135px',
                            color: "white",
                        }}
                        onClick={() => setOpenPO(true)}
                    >
                        <CheckCircle style={{ fontSize: "17px" }} />
                        Generate PO
                    </button>

                    {openPO && (
                        <PurchaseOrder
                            purchaseData={blankForm}
                            closeModal={setOpenPO}
                        />
                    )} */}

                </div>
            </div>
        </div>
    );
};

export default ViewStatus; 