import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import AddComponents from "./add-components";
// import ManageComponents from "./manage-components";

const AddComponents = React.lazy(()=>import("./add-components"))
const ManageComponents = React.lazy(()=>import("./manage-components"))


const tabs = [
  {
    id: "PRM2T1",
    label: TabLabels.ADD_COMPONENTS,
    Component: AddComponents
  },
  {
    id: "PRM2T2",
    label: TabLabels.MANAGE_COMPONENTS,
    Component: ManageComponents
  }
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};

const Components = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default Components;
