import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { NavItem } from "reactstrap";
import "../../../components/modal/modal.scss";
import AppConstants from "../../../utils/app-constants";
import SearchBox from "../../../components/search/SearchBox";
import { errorNotification } from "../../../utils/notifications";
import handleExport from "../../../utils/exportExcel";
import Actions from "./actions/Actions";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ManageHsn = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [open, setOpen] = useState();

  const fetchBom = async () => {
    const resp = await trackPromise(
      API.get(`finance/api/v1/pricing/managehsnsac`)
    );
    setUsersData(resp.reverse());
  };

  useEffect(() => {
    fetchBom();
  }, []);


  const uniqueCategory = [...new Set(UsersData.map((item)=>item.vehicleCategory))]


  const statusFormatter = (cell) => {
    return cell === AppConstants.ACTIVE ? (
      <button className="active-btn">
        <span className="active-btn-font">Active</span>
      </button>
    ) : cell === AppConstants.DEACTIVATED ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">Deactivated</span>
      </button>
    ) : cell === AppConstants.REACTIVATED ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">Reactivated</span>
      </button>
    ) : cell === AppConstants.DELETED ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Deleted</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && open ? (
          <Actions updatedData={fetchBom} rowData={row} status={true} rowIndex={rowIndex} setOpen={setOpen}/>
        ) : null}
      </div>
    );
  };

  const [formValue, setFormValue] = useState({
    category: "",
    subCategory: "",
    skuCode: "",
    componentName: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      category: "",
      subCategory: "",
      skuCode: "",
      componentName: "",
    });
    const resp = await trackPromise(
      API.get(`finance/api/v1/pricing/managehsnsac`)
    );
    setUsersData(resp.reverse());
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const exportData = UsersData?.map((item, index) => {
    return {
        "S.No.": index + 1, // S.No.
        "Vehicle Segment": item?.vehicleSegment, // Vehicle Segment
        "Vehicle Category": item?.vehicleCategory, // Vehicle Category
        "Vehicle Model": item?.vehicleModel, // Vehicle Model
        "Vehicle Type": item?.vehicleType, // Vehicle Type
        "Vehicle Color": item?.vehicleColor, // Vehicle Color
        "HSN/SAC Code": item?.HSNSACcode, // HSN/SAC Code
        "GST (%)": item?.taxPercentage, // GST (%)
        "Status": statusFormatter(item?.status), // Status formatted with custom formatter
    };
});


    const columns = [
      {
        text: "S.No.",
        // dataField: "id",
        headerAttrs: { width: "50px" },
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            UsersData.findIndex(function (value, idx, arr) {
              return value == row;
            }) + 1
          );
        },
        sort: true,
      },
      {
        text: "Vehicle Segment",
        dataField: "vehicleSegment",
        headerAttrs: { width: "150px" },
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        classes: "cellsOverflow"
      },
      {
        text: "Vehicle Category",
        dataField: "vehicleCategory",
        headerAttrs: { width: "150px" },
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        classes: "cellsOverflow",
      },
      {
        text: "Vehicle Model",
        dataField: "vehicleModel",
        headerAttrs: { width: "150px" },
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        classes: "cellsOverflow",
        // formatter: noCloudId
      },
      {
        text: "Vehicle Type",
        dataField: "vehicleType",
        headerAttrs: { width: "150px" },
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        classes: "cellsOverflow",
        // formatter: noCloudId
      },
      {
        text: "Vehicle Color",
        dataField: "vehicleColor",
        headerAttrs: { width: "150px" },
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        classes: "cellsOverflow",
        // formatter: noCloudId
      },
      {
        text: "HSN/SAC Code",
        dataField: "HSNSACcode",
        headerAttrs: { width: "150px" },
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        classes: "cellsOverflow",
        // formatter: noCloudId
      },
      {
        text: "GST (%)",
        dataField: "taxPercentage",
        headerAttrs: { width: "80px" },
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        classes: "cellsOverflow",
        // formatter: noCloudId
      },
      {
        text: "Status",
        dataField: "status",
        headerAttrs: { width: "140px" },
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        // classes: "alignment"
        formatter: statusFormatter,
      },
      {
        text: "Action",
        // dataField: "title",
        headerAttrs: { width: "120px" },
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        formatExtraData: { actionShow, open },
        formatter: actionFormatter,
      },
    ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage HSN/SAC</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(exportData, "Manage HSN/SAC")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <>
                    <form
                      onSubmit={submitHandler}
                      className="UHinputfields VMform"
                    >
                      <select
                        name="category"
                        value={formValue.category}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Category
                        </option>
                        {uniqueCategory.map((item) => {
                          return <option>{item}</option>;
                        })}
                      </select>
                      <input
                        type="text"
                        name="subCategory"
                        value={formValue.subCategory}
                        onChange={handleChange}
                        placeholder="Sub Category"
                        autoComplete="off"
                      />
                      <input
                        type="text"
                        name="skuCode"
                        value={formValue.skuCode}
                        onChange={handleChange}
                        placeholder="SKU Code"
                        autoComplete="off"
                      />
                      <input
                        type="text"
                        name="componentName"
                        value={formValue.componentName}
                        onChange={handleChange}
                        placeholder="Component Name"
                        autoComplete="off"
                      />
                      <button id="apply">Apply</button>
                      <button onClick={clearFilter} id="clear">
                        Clear Filter
                      </button>
                    </form>
                  </>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManageHsn;
