import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../work-order/workorder.scss";
import { isArray } from "lodash";
import { KeyboardBackspace } from "@material-ui/icons";
import auth from "../../../utils/auth";

const ServicesView = ({ close, rowData }) => {
  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "636px", height: "350px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Assembly Services</h3>
            {/* <div>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Print 
          </button>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Export
          </button>
          </div> */}
          </div>

          {/* <div className="body" style={{height:"340px"}}>  */}
          {/* <div style={{width:"100%",overflow:"auto",height:"325px"}}> */}

          <div className="shopfloortbl" style={{ width: "100%" }}>
            <table className="sfaction_tbl" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      width: "8%",
                      fontSize: "small",
                      fontWeight: "bold",
                    }}
                  >
                    S.No.
                  </th>
                  <th style={{ fontSize: "small", fontWeight: "bold" }}>
                    Service Name
                  </th>
                  <th style={{ fontSize: "small", fontWeight: "bold" }}>
                    Duration
                  </th>
                  <th style={{ fontSize: "small", fontWeight: "bold" }}>
                    Service Cost ({auth.getUserInfo().currencySymbol})
                  </th>
                </tr>
              </thead>
              {rowData?.assemblyServices?.map((item, index) => {
                return (
                  <tr>
                    <td
                      style={{ width: "8%", fontSize: "small" }}
                      className="leftcell"
                    >
                      {index + 1}
                    </td>
                    <td style={{ fontSize: "small" }}>{item?.serviceName}</td>
                    <td style={{ fontSize: "small" }}>
                      {item?.timeDuration} {item?.time}
                    </td>
                    <td style={{ fontSize: "small" }} className="rightcell">
                      {item?.serviceCost}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>

          {/* </div> */}
          {/* </div> */}

          <br />
          <div className="footer">
            <button onClick={() => close(false)}>
              <KeyboardBackspace />
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesView;
