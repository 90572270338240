import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import auth from "../../../utils/auth";

const DeliveryDetails = ({ deliveryDetailsData, index, deliveryHandler }) => {
  const [delivery, setDelivery] = useState([]);
  const [businessUserInfo, setBusinessUserInfo] = useState([]);

  const deliveryData = deliveryDetailsData === undefined ? [] : deliveryDetailsData;

  const [formValue, setFormValue] = useState(deliveryData);


  const handleChange = (e) => {
    setDelivery([...delivery, { ...formValue, [e.target.name]: e.target.value }]);
    deliveryHandler(index, [...delivery, { ...formValue, [e.target.name]: e.target.value }]);
    console.log("submitted delivery data succesfully!");
    setFormValue({
      deliveryLocation: "",
      unitPrice: "",
      estdTime: "",
      duration: "",
    });
  };

  const removeFields = (index) => {
    const field = [...delivery];
    field.splice(index, 1);
    setDelivery(field);
    deliveryHandler(index, delivery);
    console.log("submitted delivery data succesfully!");
  };

  const fetchReport = async () => {
    const response = await trackPromise(
      API.get(`procurement-module/api/v1/mappings/getStorageLocations`)
    );
    setBusinessUserInfo(response);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap", width: '200%' }}>
        <div
          style={{
            margin: "0px 5px 0px 5px",
            width: "188px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              width: "auto",
              marginLeft: "1px",
            }}
          >
            Delivery Location<span style={{ color: "red" }}>*</span>
          </label>
          <select
            style={{ width: "auto", marginTop: '-8px' }}
            name="deliveryLocation"
            defaultValue={formValue.deliveryLocation}
            onChange={(e) =>
              setFormValue({ ...formValue, [e.target.name]: e.target.value })
            }
          >
            <option value="" disabled selected>
              Delivery Location
            </option>
            {businessUserInfo.map((item) => (
              <option>{item.city}</option>
            ))}
          </select>
        </div>

        <div
          style={{
            margin: "0px 5px 0px 5px",
            width: "188px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              width: "auto",
              marginLeft: "1px",
            }}
          >
            {`Unit Price (${auth.getUserInfo().currencySymbol})`}<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            style={{ width: "auto", marginTop: '-8px' }}
            name="unitPrice"
            defaultValue={formValue.unitPrice}
            placeholder="Unit Price"
            onChange={(e) =>
              setFormValue({ ...formValue, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div
          style={{
            margin: "0px 5px 0px 5px",
            width: "188px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              width: "auto",
              marginLeft: "1px",
            }}
          >
            Estd. Delivery Time<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            style={{ width: "auto", marginTop: '-8px' }}
            name="estdTime"
            defaultValue={formValue.estdTime}
            placeholder="Estd. Time"
            onChange={(e) =>
              setFormValue({ ...formValue, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div
          style={{
            margin: "0px 5px 0px 5px",
            width: "188px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label
            style={{
              fontSize: "13px",
              fontWeight: "500",
              width: "auto",
              marginLeft: "1px",
            }}
          >
            Duration<span style={{ color: "red" }}>*</span>
          </label>
          <select
            style={{ width: "auto", marginTop: '-8px' }}
            name="duration"
            defaultValue={formValue.duration}
            onChange={
              formValue.deliveryLocation &&
                formValue.unitPrice &&
                formValue.estdTime
                ? (e) => handleChange(e)
                : null
            }
          >
            <option>Duration</option>
            <option value="Day">Day</option>
            <option value="Week">Week</option>
            <option value="Month">Month</option>
          </select>
        </div>


      </div>

      <div
        style={{
          width: "71.5%",
          borderRadius: "5px",
          backgroundColor: "#ebebeb",
        }}
      >
        {delivery.map((item, index) => (
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "93%",
                margin: "5px",
                padding: "5px",
                borderRadius: "5px",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "36%",
                  textAlign: "left",
                }}
              >
                {item.deliveryLocation}
              </span>
              <span
                style={{
                  display: "inline-block",
                  width: "36%",
                  textAlign: "left",
                }}
              >
                {item.unitPrice}
              </span>
              <span>{`${item.estdTime} ${item.duration}`}</span>
            </div>
            <div>
              <button
                type="button"
                style={{
                  borderRadius: "188px",
                  border: "1px solid red",
                  marginTop: "10px",
                }}
                onClick={() => removeFields(index)}
              >
                <div style={{ color: "red" }}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
              </button>
            </div>
          </div>
        ))}
      </div>
    </>


  );
};

export default DeliveryDetails;
