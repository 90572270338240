import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';

const OprSalesUserCard = () => {
    const [salesData, setData] = useState({});

    const fetchData = async () => {
        const resp = await trackPromise(
            API.get(`operations-module/api/v1/dashboard/salesUser`)
        );
        setData(resp);
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div style={{ width: "95%", marginLeft: "10px" }}>
            <ProgressBar>
                <ProgressBar now={salesData.totalSalesUser * 100} style={{ background: "#0A18A3" }} />
                <ProgressBar now={salesData.totalActiveSalesUser * 100} style={{ background: "rgba(33, 168, 46, 1)" }} />
                <ProgressBar now={salesData.totalDectivatedSalesUser * 100} style={{ background: "rgba(253, 170, 72, 1)" }} />
                <ProgressBar now={salesData.totalRectivatedSalesUser * 100} style={{ background: "rgba(255, 215, 74, 1)" }} />
                <ProgressBar now={salesData.totalDeleteSalesUser * 100} style={{ background: "rgba(237, 52, 75, 1)" }} />
            </ProgressBar>
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430", marginTop: "20px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#0A18A3", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Total Users</span>
                </div>
                <div>
                    {salesData.totalSalesUser}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(33, 168, 46, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Active Users</span>
                </div>
                <div>
                    {salesData.totalActiveSalesUser}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(253, 170, 72, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Deactivated Users</span>
                </div>
                <div>
                    {salesData.totalDectivatedSalesUser}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(255, 215, 74, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Reactivated Users</span>
                </div>
                <div>
                    {salesData.totalRectivatedSalesUser}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(237, 52, 75, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Deleted Users</span>
                </div>
                <div>
                    {salesData.totalDeleteSalesUser}
                </div>
            </div>
        </div>
    )
}

export default OprSalesUserCard