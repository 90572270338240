import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AppConstants from "../../../utils/app-constants";
import Actions from "./actions/Actions";
import POViewDetails from "./actions/POViewDetails";
import handleExport from "../../../utils/exportExcel";
import SearchBox from "../../../components/search/SearchBox";
import DealerSparePO from "../../RequisitionOrders/DealerSparePO";

const { ExportCSVButton } = CSVExport;

const PONewRequest = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [poActionShow, setPoActionShow] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [poOpen, setPoOpen] = useState(false);

  const [row, setRow] = useState();

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `finance/api/v1/porequest/newrequest?BOMID=${formValue.BOMID}&bomLocation=${formValue.bomLocation}&POID=${formValue.POID}&PODate=${formValue.PODate}&status=${formValue.status}`
      )
    );

    let finalData = resp.filter((item) => {
      return (
        item.status == "Pending At Finance" ||
        item.status == "On Hold By Finance"
      );
    });

    setUsersData(finalData);
    console.log(finalData);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const dummydata = [
    {
      BOMID:"1234"
    }
  ]


  const uniqueBOMID = [...new Set(UsersData.map((item)=>item.BOMID))]
  const uniquebomLocation = [...new Set(UsersData.map((item)=>item.bomLocation))]
  const uniquePOID = [...new Set(UsersData.map((item)=>item.POID))]
  const uniquestatus = [...new Set(UsersData.map((item)=>item.status))]



  const statusFormatter = (cell) => {
    return cell === "Pending At Finance" ? (
      <button className="pendingByProc-btn">
        <span className="pendingByProc-btn-font">Pending</span>
      </button>
    ) : cell === AppConstants.ONHOLDBYFINANCE ? (
      <button className="deactive-btn">
        <span className="deactive-btn-font">On Hold</span>
      </button>
    ) : (
      cell
    );
  };
  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setActionShow(rowIndex);
    setOpen(true);
    on();
  };


  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow == rowIndex && open ? (
          <Actions updatedData={fetchReport} rowData={row} rowIndex={rowIndex}/>
        ) : null}
      </div>
    );
  };

  const poHandler = (rowIndex) => {
    setPoActionShow(rowIndex);
    setPoOpen(true);
  };

  const poFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{ color: "blue", cursor: "pointer", marginBottom:"0px"}}
          onClick={() => poHandler(rowIndex)}
        >
          {cell}
        </p>
        {/* {poActionShow === rowIndex && poOpen ? (
          <POViewDetails data={row} closeModal={setPoOpen} />
        ) : null} */}
        {poActionShow === rowIndex && poOpen ? (
          <DealerSparePO rowData={row} closeModal={setPoOpen} type="components" />
        ) : null}
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const reasonFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.status == "On Hold By Finance" && row.onholdReason == "Other") {
      return <span>{row.onholdDescription}</span>;
    } else {
      return <span>{row.onholdReason}</span>;
    }
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const exportData = data?.map((item, index) => {
    return {
        "S.No.": index + 1 + ".", // S.No.
        "BOM ID": item?.BOMID, // BOM ID
        "BOM Location": item?.bomLocation, // BOM Location
        "PO No.": item?.POID, // PO No.
        "Vendor Name": item?.vendorName, // Vendor Name
        "PO Date": moment(item?.PODate).format("DD-MM-YYYY"), // PO Date formatted
        "PO Value": item?.totalPrice, // PO Value
        "Status": statusFormatter(item?.status), // Status formatted
        "Reason": item?.reason, // Reason formatted
        "Action Date": moment(item?.actionDate).format("DD-MM-YYYY"), // Action Date formatted
    };
});


  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "BOM ID",
      dataField: "BOMID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "180px" },
      classes:"cellsOverflow"
    },
    {
      text: "BOM Location",
      dataField: "bomLocation",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      classes:"cellsOverflow"
    },
    {
      text: "PO No.",
      dataField: "POID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatExtraData: { poOpen, poActionShow },
      formatter: poFormatter,
      classes:"cellsOverflow"
    },
    {
      text: "Vendor Name",
      dataField: "vendorName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      classes:"cellsOverflow"
    },
    // {
    //   text: "PO Details",
    //   headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    //   headerAttrs: { width: "150px" },
    //   formatExtraData: { poOpen, poActionShow },
    //   formatter: poFormatter,
    // },
    {
      text: "PO Date",
      dataField: "PODate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "180px" },
      formatter: dateFormatter,
    },
    {
      text: "PO Value",
      dataField: "totalPrice",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "120px" },
      classes:"cellsOverflow"
    },
    {
      text: "Status",
      dataField: "status",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "140px" },
      formatter: statusFormatter,
    },
    {
      text: "Reason",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "220px" },
      classes:"cellsOverflow",
      formatter: reasonFormatter,
    },
    {
      text: "Action Date",
      dataField: "actionDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatter: dateFormatter,
    },
    {
      text: "Action",
      // dataField: "title",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  const [formValue, setFormValue] = useState({
    BOMID: "",
    bomLocation: "",
    POID: "",
    requestCategory: "",
    PODate: "",
    status: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      BOMID: "",
      bomLocation: "",
      POID: "",
      requestCategory: "",
      PODate: "",
      status: "",
    });
    const resp = await trackPromise(
      API.get(`finance/api/v1/porequest/newrequest`)
    );

    let finalData = resp.filter((item, index) => {
      return (
        item.status == "Pending At Finance" ||
        item.status == "On Hold By Finance"
      );
    });

    setUsersData(finalData);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  return (
    <>
 
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>New Requests</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          // data={dummydata}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(exportData, "New Request")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <>
                    <form
                      onSubmit={submitHandler}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: "135px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                      }}
                      className="UHinputfields VMform"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          BOM ID
                        </label>
                        <select
                          name="BOMID"
                          value={formValue.BOMID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            BOM ID*
                          </option>
                          {uniqueBOMID.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          BOM Location
                        </label>
                        <select
                          name="bomLocation"
                          value={formValue.bomLocation}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            BOM Location*
                          </option>
                          {uniquebomLocation.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          PO ID
                        </label>
                        <select
                          name="POID"
                          value={formValue.POID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            PO ID*
                          </option>
                          {uniquePOID.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          PO Date
                        </label>
                        <input
                          type="date"
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "text")}
                          name="PODate"
                          value={formValue.PODate}
                          onChange={handleChange}
                          placeholder="PO Date"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status*
                          </option>
                          {uniquestatus.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          width: "46%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <button
                          onClick={fetchReport}
                          style={{ width: "100px", marginTop: "24px" }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{ width: "100px", marginTop: "24px" }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </form>
                  </>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};
export default PONewRequest;
