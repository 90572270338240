import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../../utils/notifications";
import "../../../../components/modal/modal.scss";
import ConfirmGUI from "./ConfirmGUI";
import moment from 'moment';

const EditEnquirySales = ({ editData, closeModal, updatedData }) => {

  const [addVRNumber, setVRNumber] = useState(false)
  const [confirmGUI, setConfirmGUI] = useState(false)
  const [data, setData] = useState([])

  const [formValue, setFormValue] = useState({
    enquiryID: editData.enquiryID,
    enquiryType: editData.enquiryType,
    enquiryDate: editData.enquiryDate,
    vehicleSegment: editData.vehicleSegment,
    vehicleCategory: editData.vehicleCategory,
    vehicleType: editData.vehicleType,
    vehicleModel: editData.vehicleModel,
    vehicleVariant: editData.vehicleVariant,
    customerName: editData.customerName,
    customerEmail: editData.customerEmail,
    customerPhone: editData.customerPhone,
    customerAddress: editData.customerAddress,
    addressDoc: editData.addressDoc,
    paymentType: editData.paymentType,
    financerName: editData.financerName,
    financedAmount: editData.financedAmount,
    otherCharges: editData.otherCharges,
    rtoCharges: editData.rtoCharges,
    cgst: editData.cgst,
    sgst: editData.sgst,
    onRoadPrices: editData.onRoadPrices,
    exShowroomPrice: editData.exShowroomPrice,
    vehicleRegNo: editData.vehicleRegNo,
  });

  const handleInputChange = async (e) => {
    let totalCharges =
      parseInt(formValue.exShowroomPrice) +
      parseInt(formValue.rtoCharges) +
      parseInt(formValue.otherCharges);
    let totalgst = parseInt(formValue.cgst) + parseInt(formValue.sgst);
    let gstAmount = (totalCharges * totalgst) / 100;
    let roadPrice = parseInt(totalCharges) + parseInt(gstAmount);
    console.log("totalgst", totalgst)
    console.log("gstAmount", gstAmount)
    console.log("roadPrice", roadPrice)
    setFormValue({ ...formValue, ["onRoadPrices"]: roadPrice });
    const { name, value } = e.target;
    if (name == "addressDoc") {
      setFormValue({ ...formValue, [name]: e.target.files[0] });
    } else {
      setFormValue({ ...formValue, [name]: value });
    }
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    const editDetails = {
      // enquiryID: formValue.enquiryID,
      // enquiryType: formValue.enquiryType,
      // enquiryDate: formValue.enquiryDate,
      // vehicleSegment: formValue.vehicleSegment,
      saleID: editData.saleID,
      // vehicleCategory: formValue.vehicleCategory,
      // vehicleType: formValue.vehicleType,
      // vehicleModel: formValue.vehicleModel,
      // vehicleVariant: formValue.vehicleVariant,
      // customerName: formValue.customerName,
      // customerEmail: formValue.customerEmail,
      // customerPhone: formValue.customerPhone,
      // customerAddress: formValue.customerAddress,
      // addressDoc: formValue.addressDoc,
      // paymentType: formValue.paymentType,
      // financerName: formValue.financerName,
      // financedAmount: formValue.financedAmount,
      // otherCharges: formValue.otherCharges,
      // rtoCharges: formValue.rtoCharges,
      // cgst: formValue.cgst,
      // sgst: formValue.sgst,
      // onRoadPrices: formValue.onRoadPrices,
      // exShowroomPrice: formValue.exShowroomPrice,
      vehicleRegNo: formValue.vehicleRegNo,
    };
    console.log(editDetails);
    let formData = new FormData();
    for (let key in editDetails) {
      formData.append(key, editDetails[key]);
    }

    const resp = await trackPromise(
      API.put(`/showroom-module/api/v1/customerAndSales/editSale`, formData)
    );

    setData(resp)

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };


  // const vehicleRegistration = ()=>{
  //   setVehicleReg(!addVRNumber);
  // }

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "810px", height: "600px" }}
      >
        <div className="header">
          <h3>Enquiry Sales</h3>
        </div>
        <div className="body">
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-190px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Enquiry ID
              </label>
              <input
                className="input-config"
                style={{ marginTop: "-8px", width: "90%", marginRight: "52px" }}
                disabled
                name="enquiryID"
                defaultValue={editData.enquiryID}
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-175px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Enquiry Type
              </label>
              <input
                className="input-config"
                style={{ marginTop: "-8px", width: "95%", marginRight: "52px" }}
                disabled
                name="enquiryType"
                defaultValue={editData.enquiryType}
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-130px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Enquiry Date
              </label>
              <input
                className="input-config"
                style={{ marginTop: "-8px", width: "160%" }}
                disabled
                name="enquiryDate"
                type="text"
                // defaultValue={new Date(editData.enquiryDate)}
                placeholder={`${editData?.enquiryDate}`}
              />
            </label>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-135px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Customer Name
              </label>
              <input
                style={{ marginTop: "-8px", width: "96%" }}
                type="text"
                name="customerName"
                defaultValue={editData.customerName}
                placeholder="Customer Name"
                onChange={(event) => handleInputChange(event)}
                disabled
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-120px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Customer Email
              </label>
              <input
                style={{ marginTop: "-8px", width: "96%", marginLeft: "20px" }}
                disabled
                type="text"
                name="customerEmail"
                defaultValue={editData.customerEmail}
                placeholder="Customer Email"
                onChange={(event) => handleInputChange(event)}
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-115px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Customer Phone
              </label>
              <input
                style={{ marginTop: "-8px", width: "96%", marginLeft: "20px" }}
                disabled
                type="text"
                name="customerPhone"
                defaultValue={editData.customerPhone}
                placeholder="Customer Phone"
                onChange={(event) => handleInputChange(event)}
              />
            </label>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-130px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Vehicle Segment
              </label>
              <input
                style={{ marginTop: "-8px", width: "96%" }}
                disabled
                name="vehicleSegment"
                defaultValue={editData.vehicleSegment}
                onChange={(event) => handleInputChange(event)}
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-110px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Vehicle Category
              </label>
              <input
                style={{ marginTop: "-8px", width: "96%", marginLeft: "20px" }}
                disabled
                name="vehicleCategory"
                defaultValue={editData.vehicleCategory}
                onChange={(event) => handleInputChange(event)}
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-100px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Vehicle Model
              </label>
              <input
                style={{ marginTop: "-8px", width: "96%", marginLeft: "20px" }}
                disabled
                name="vehicleModel"
                defaultValue={editData.vehicleModel}
                onChange={(event) => handleInputChange(event)}
              />
            </label>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-150px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Vehicle Variant
              </label>
              <input
                style={{ marginTop: "-8px", width: "96%" }}
                disabled
                name="vehicleVariant"
                defaultValue={editData.vehicleVariant}
                onChange={(event) => handleInputChange(event)}
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-130px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Vehicle Type
              </label>
              <input
                style={{ marginTop: "-8px", width: "96%", marginLeft: "20px" }}
                disabled
                name="vehicleType"
                defaultValue={editData.vehicleType}
                onChange={(event) => handleInputChange(event)}
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-82px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Customer Address
              </label>
              <input
                style={{ marginTop: "-8px", width: "96%", marginLeft: "20px" }}
                type="text"
                disabled
                name="customerAddress"
                defaultValue={editData.customerAddress}
                placeholder="Customer Address"
                onChange={(event) => handleInputChange(event)}
              />
            </label>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-240px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Upload Address
              </label>
              <input
                style={{ marginTop: "-8px", width: "67%" }}
                name="addressDoc"
                type="file"
                disabled
                // defaultValue={editData.addressDoc}
                onChange={(event) => handleInputChange(event)}
                placeholder="Upload Address"
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-200px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Ex Showroom Price
              </label>
              <input
                style={{
                  marginTop: "-8px",
                  width: "155%",
                  marginLeft: "-82px",
                }}
                name="exShowroomPrice"
                type="upload"
                disabled
                defaultValue={editData.exShowroomPrice}
                onChange={(event) => handleInputChange(event)}
                placeholder="Ex-Showroom Price"
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-40px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Payment Type
              </label>
              <select
                style={{ marginTop: "-8px", width: "155%", marginLeft: "20px" }}
                name="paymentType"
                defaultValue={editData.paymentType}
                onChange={(event) => handleInputChange(event)}
                disabled
              >
                <option value="" disabled selected>
                  Payment Mode
                </option>
                <option>Full Payment</option>
                <option>Finance</option>
              </select>
            </label>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-50px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Financier Name
              </label>
              <select
                disabled
                style={{ marginTop: "-8px", width: "150%", marginLeft: "0px" }}
                name="financerName"
                defaultValue={editData.financerName}
                onChange={(event) => handleInputChange(event)}
              >
                <option value="" disabled selected>
                  Financier Name
                </option>
                <option>HDFC</option>
                <option>ICICI</option>
              </select>
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-22px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Financed Amount
              </label>
              <input
                style={{ marginTop: "-8px", width: "70%", marginLeft: "105px" }}
                name="financedAmount"
                type="number"
                disabled
                defaultValue={editData.financedAmount}
                onChange={(event) => handleInputChange(event)}
                placeholder=""
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-130px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                RTO Charges
              </label>
              <input
                style={{ marginTop: "-8px", width: "100%", marginLeft: "22px" }}
                name="rtoCharges"
                type="number"
                disabled
                defaultValue={editData.rtoCharges}
                onChange={(event) => handleInputChange(event)}
                placeholder="rtoCharges"
              />
            </label>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-145px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Other Charges
              </label>
              <input
                style={{ marginTop: "-8px", width: "100%" }}
                name="otherCharges"
                type="number"
                disabled
                defaultValue={editData.otherCharges}
                onChange={(event) => handleInputChange(event)}
                placeholder="otherCharges"
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-175px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                CGST
              </label>
              <input
                style={{ marginTop: "-8px", width: "92%", marginLeft: "22px" }}
                name="cgst"
                disabled
                type="number"
                defaultValue={formValue.cgst}
                onChange={(event) => handleInputChange(event)}
                placeholder="cgst"
              />
            </label>

            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-175px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                SGST
              </label>
              <input
                style={{ marginTop: "-8px", width: "92%", marginLeft: "22px" }}
                name="sgst"
                type="number"
                disabled
                defaultValue={formValue.sgst}
                onChange={(event) => handleInputChange(event)}
                placeholder="sgst"
              />
            </label>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-145px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                On Road Price
              </label>
              <input
                style={{ marginTop: "-8px", width: "92%" }}
                name="onRoadPrices"
                type="text"
                disabled
                defaultValue={formValue.onRoadPrices}
                onChange={(event) => handleInputChange(event)}
                placeholder="onRoadPrice"
              />
            </label>
          </div>
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button style={{ width: "23%" }} onClick={() => { setVRNumber(!addVRNumber) }}>Add Vehicle Reg. No</button>
        </div>

        {addVRNumber ?
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <label style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  float: "left",
                  marginLeft: "-105px",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Add Vehicle Reg. No
              </label>
              <input
                style={{ marginTop: "-8px", width: "96%" }}
                type="text"
                name="vehicleRegNo"
                defaultValue={formValue.vehicleRegNo}
                placeholder="Add Vehicle Reg. No"
                onChange={(event) => handleInputChange(event)}
              />
            </label>

            <div className="footer">
              <button onClick={() => { setVRNumber(!addVRNumber) }}>Cancel</button>
              <button
                id="submit-button"
                onClick={submitHandler}
              >
                Submit
              </button>
            </div>

          </div> : ""
        }

      </div>
    </div>
  );
};

export default EditEnquirySales;
