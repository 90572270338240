import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import Group7 from "../QcDashboard/assets/Group7.svg";
import Group10 from "../QcDashboard/assets/Group10.svg";
import Group11 from "../QcDashboard/assets/Group11.svg";
import Group12 from "../QcDashboard/assets/Group12.svg";
import Group15 from "../QcDashboard/assets/Group15.svg";

const Returns = ({ prop }) => {
  const [data, setData] = useState({});
  const [spareData, setSpareData] = useState({});

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(`salesUser-module/api/v1/dashboard/vehicleReturns`)
    );
    setData(res);
  };
  const fetchSpareData = async () => {
    const res = await trackPromise(
      API.get(`salesUser-module/api/v1/dashboard/spareReturns`)
    );
    setSpareData(res);
  };
  useEffect(() => {
    { prop == "vehicle" ? fetchData() : fetchSpareData() }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "95%",
        marginLeft: "10px",
        fontWeight: "430",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            src={Group7}
            style={{
              height: "25px",
              display: "block",
            }}
            alt="group"
          />
          &nbsp;
          <span>
            {prop == "vehicle" ? "Total Vehicle Returns" : "Total Spare Returns"}
          </span>
        </div>
        <div>
          {prop == "vehicle" ? data.totalVehicles : spareData.totalSpare}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            src={Group12}
            style={{
              height: "25px",
              display: "block",
            }}
            alt="group"
          />
          &nbsp;
          <span>
            New Requests
          </span>
        </div>
        <div>
          {prop == "vehicle" ? data.newRequest : spareData.newRequest}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            src={Group11}
            style={{
              height: "25px",
              display: "block",
            }}
            alt="group"
          />
          &nbsp;
          <span>
            In-Progress at Inventory
          </span>
        </div>
        <div>
          {prop == "vehicle" ? data.inProgressAtInventory : spareData.inProgressAtInventory}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            src={Group15}
            style={{
              height: "25px",
              display: "block",
            }}
            alt="group"
          />
          &nbsp;
          <span>
            Verification Completed
          </span>
        </div>
        <div>
          {prop == "vehicle" ? data.verificationCompleted : spareData.verificationCompleted}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "2px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            src={Group10}
            style={{
              height: "25px",
              display: "block",
            }}
            alt="group"
          />
          &nbsp;
          <span>
            Pending at Finance
          </span>
        </div>
        <div>
          {prop == "vehicle" ? data.pendingAtInventory : spareData.pendingAtInventory}
        </div>
      </div>
    </div>
  );
}

export default Returns;
