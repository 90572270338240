import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import Group9 from "../QcDashboard/assets/Group9.svg";
import Group12 from "../QcDashboard/assets/Group12.svg";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);
ChartJS.defaults.plugins.legend.display = false;

const VehicleCard = () => {

  const [data, setData] = useState([]);

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(`serviceCenter-module/api/v1/inventory/spareInventory`)
    );
    setData(res);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const availSpares = data?.reduce(
    (accumulator, currentValue) => accumulator + parseInt(currentValue?.availableQty),
    0
  );

  const totalValueOfSpares = data?.reduce(
    (accumulator, currentValue) => accumulator + parseInt(currentValue?.subTotal),
    0
  );

  return (
    <div className="requestBody" style={{ justifyContent: "start" }}>
      <div
        className="shopFloorRequest"
        style={{ height: "auto", width: "47%", display: "flex", flexDirection: "row", justifyContent: "start" }}
      >
        <div style={{ width: "35%", padding: "4%" }}>
          <img
            src={Group9}
            style={{
              height: "50px",
              display: "block",
            }}
            alt="group"
          />
        </div>
        <div style={{ width: "65%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginLeft: "15px",
            }}
          >
            <div
              style={{
                fontSize: "24px",
                fontWeight: "450",
                textAlign: "start",
              }}
            >
              {availSpares}
            </div>
          </div>
          <div
            style={{
              color: "#808080",
              fontSize: "14px",
              textAlign: "center",
              margin: "10px 6px 0px 6px",
              borderBottom: "2px solid #F2F2F2",
            }}
          >
            Total Available Spares
          </div>
        </div>
      </div>
      <div
        className="shopFloorRequest"
        style={{ height: "auto", width: "47%", display: "flex", flexDirection: "row", justifyContent: "start" }}
      >
        <div style={{ width: "35%", padding: "4%" }}>
          <img
            src={Group12}
            style={{
              height: "50px",
              display: "block",
            }}
            alt="group"
          />
        </div>
        <div style={{ width: "65%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginLeft: "15px",
            }}
          >
            <div
              style={{
                fontSize: "24px",
                fontWeight: "450",
                textAlign: "start",
              }}
            >
              {totalValueOfSpares.toLocaleString("en-IN")}
            </div>
          </div>
          <div
            style={{
              color: "#808080",
              fontSize: "14px",
              textAlign: "center",
              margin: "10px 6px 0px 6px",
              borderBottom: "2px solid #F2F2F2",
            }}
          >
            Total Inventory Value
          </div>
        </div>
      </div>

    </div>
  );
};

export default VehicleCard;
