import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';

const OprBusinessEntityCard = () => {

    const [entityData, setData] = useState({});

    const fetchData = async () => {
        const resp = await trackPromise(
            API.get(`operations-module/api/v1/dashboard/getEntitise`)
        );
        setData(resp);
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div style={{ width: "95%", marginLeft: "10px" }}>
            <ProgressBar>
                <ProgressBar now={entityData.totalEntitise * 100} style={{ background: "#0A18A3" }} />
                <ProgressBar now={entityData.totalActivatedEntitise * 100} style={{ background: "rgba(33, 168, 46, 1)" }} />
                <ProgressBar now={entityData.totalDeactivatedEntitise * 100} style={{ background: "rgba(253, 170, 72, 1)" }} />
                <ProgressBar now={entityData.totalReactivatedEntitise * 100} style={{ background: "rgba(255, 215, 74, 1)" }} />
                <ProgressBar now={entityData.totalDeletedEntitise * 100} style={{ background: "rgba(237, 52, 75, 1)" }} />
            </ProgressBar>
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430", marginTop: "20px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "#0A18A3", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Total Entities</span>
                </div>
                <div>
                    {entityData.totalEntitise}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(33, 168, 46, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Active Entities</span>
                </div>
                <div>
                    {entityData.totalActivatedEntitise}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(253, 170, 72, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Deactivated Entities</span>
                </div>
                <div>
                    {entityData.totalDeactivatedEntitise}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(255, 215, 74, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Reactivated Entities</span>
                </div>
                <div>
                    {entityData.totalReactivatedEntitise}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", width: "15px", height: "15px", background: "rgba(237, 52, 75, 1)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                    </div>&nbsp;
                    <span>Deleted Entities</span>
                </div>
                <div>
                    {entityData.totalDeletedEntitise}
                </div>
            </div>
        </div>
    )
}

export default OprBusinessEntityCard