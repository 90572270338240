import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from "react-bootstrap-table-next";
import ViewSpecs from '../bom-viewspecs';
import "../bom.scss"


const EditBomComponents = ({ componentsData, idx, bomComdata }) => {
  console.log(bomComdata)
  const [arry, setArry] = useState(bomComdata);
  const [specsShow, setSpecsShow] = useState();
  const [actionShow, setActionShow] = useState();
  const [components, setComponents] = useState([]);

  const actionHandler = (rowIndex) => {
    setSpecsShow(true);
    setActionShow(rowIndex);
  };

  const getComponent = async () => {
    const resp = await trackPromise(
      API.get(`procurement-module/api/v1/components`)
    );
    setComponents(resp);
  };
  useEffect(() => {
    getComponent();
  }, []);

  const specsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
           onClick={() => actionHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          View Specification
        </button>
        {actionShow === rowIndex && specsShow ? 
          <ViewSpecs rowData={row} close={setSpecsShow} /> : null
        }
      </div>
    );
  };

  const removeHandler = (rowIndex) => {
    const field = [...arry];
    field.splice(rowIndex, 1);
    setArry(field);
  }

  const removeFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={(e) => removeHandler(row, rowIndex)}
          style={{ color: "red" }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    )
  }

  const inputChange = (e, row) => {
    row.componentQuantity = e.target.value
  };

  const quantityFormatter = (cell, row, rowIndex) => {
    return (
      <div>
        <input type="text"
          // name="Quantity"
          defaultValue={row.componentQuantity}
          placeholder="Add Qty."
          id="qty"
          autoComplete='off'
          onChange={(e) => inputChange(e, row)}
          // onChange={event => handleInputChange(event, index)} 
          style={{ width: "70px", height: "25px", padding: "0px", backgroundColor:"#ebebeb", textAlign:"center" }}
        />
      </div>
    )
  }
  const cancel = (e) => {
    document.getElementById('qty').value="";
  }

  const columns = [
    {
      text: "Component Name",
      dataField: "componentName",
      headerStyle: {fontWeight :"500",borderTop:"1px solid #CDCDCD",borderRight:"2px solid white"},
      headerAttrs: { width: "155px" },
      classes: "cellsOverflow"
    },
    {
      text: "Component SKU Code",
      dataField: "skuCode",
      headerStyle: {fontWeight :"500",borderTop:"1px solid #CDCDCD",borderRight:"2px solid white"},
      headerAttrs: { width: "180px" },
    },
    {
      text: "Category",
      dataField: "category",
      headerStyle: {fontWeight :"500",borderTop:"1px solid #CDCDCD",borderRight:"2px solid white"},
      headerAttrs: { width: "180px" },
      classes: "cellsOverflow"
    },
    {
      text: "Sub-Category",
      dataField: "subCategory",
      headerStyle: {fontWeight :"500",borderTop:"1px solid #CDCDCD",borderRight:"2px solid white"},
      headerAttrs: { width: "180px" },
    },
    {
      text: "Specification",
      dataField: "specifications",
      headerStyle: {fontWeight :"500",borderTop:"1px solid #CDCDCD",borderRight:"2px solid white"},
      headerAttrs: { width: "160px" },
      formatExtraData: {specsShow,actionShow},
      formatter: specsFormatter,
    },
    {
      text: "Quantity",
      dataField: "componentQuantity",
      headerStyle: {fontWeight :"500",borderTop:"1px solid #CDCDCD"},
      headerAttrs: { width: "90px" },
      formatter: quantityFormatter,
    },
    {
      formatExtraData: { arry },
      headerStyle: {fontWeight :"500",borderTop:"1px solid #CDCDCD"},
      headerAttrs: { width: "40px" },
      formatter: removeFormatter,
    }
  ];

  const handleChange = (e) => {
    const rowdata = components.filter(item => item.componentName === e.target.value)
    setArry(obj => [...obj,...rowdata]);
  };

  const handleSubmit = () => {
    console.log(arry);
    componentsData(arry, idx);
  };

  return (
    <>
      <Accordion style={{ width: "100%" }} >
        <div style={{ backgroundColor: "#ebebeb" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography  >
              Components
            </Typography>
          </AccordionSummary>
        </div>
        <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ width: "fit",display:"flex", flexDirection:'column'}}>
          <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "-980px",
              }}
            > Component<span style={{ color: "red" }}>*</span>
            </label>
            <select
              required={true}
              name="components"
              className="drpdwnbox"
              // value={components}
              onChange={handleChange}
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
            >
              <option disabled selected>
                Components
              </option>
              {components.map(item =>
                <option key={item.id} value={item.componentName}>{item.componentName}</option>
              )};

            </select>
            </div>
            <br/>
            <div>
                <BootstrapTable
                wrapperClasses="table-responsive"
                rowClasses="text-nowrap" 
                keyField="id"
                data={arry}
                columns={columns}
                />
            </div>
            {/* <div className='bomfooter'>
                <button id='bomcancel' onClick={cancel} >Cancel</button> 
                <button id='bomsubmit' onClick={handleSubmit}> Submit</button>
            </div> */}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default EditBomComponents