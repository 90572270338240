import React, { useState } from "react";
import ViewDetails from './ViewDetails'


const Actions = ({ rowData, extraData, status , rowIndex }) => {
  const [showModal, setShowModal] = useState(false);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      approve: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };


  return (
    <div>

      {showModal && activeModal.approve && <ViewDetails rowData={rowData} closeModal={setShowModal} />}

      <div style={{ position: "relative" , marginTop: rowIndex <= 4 ? "0px" : "-20px",}}>
        <div className="action vendoraction">
          <div>
            <span id="approve" onClick={(e) => clickHandler(e)}>
              View Details
            </span>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Actions;
