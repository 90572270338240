import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { errorNotification, successNotification } from "../../../utils/notifications";

const PartiallyClosure = ({closeModal,editData}) => {
  console.log(editData);
  const [formValue, setFormValue] = useState({
    reason: "",
    description: "",
  });

  const submitHandler = async() => {
    console.log(formValue);
    let partialData = {
      "POID": editData.POID,
      "reason": formValue.reason,
      "reasonComment": formValue.description
      };

    const resp = await trackPromise(API.post(`procurement-module/api/v1/orders/markPartialClosure`, partialData));
    console.log(resp);
    if(resp.success){
     closeModal(false);
     successNotification(resp.message,{autoClose:3000})
     refresh()
    }else{
     errorNotification(resp.message,{autoClose:3000})
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{padding:'15px'}}>
        <div className="header" style={{width:'100%',display:"flex",justifyContent:'flex-start'}}>
          <h3>Partially Closure</h3>
         
        </div>

        <div className="body" style={{display:"flex",justifyContent:'flex-start'}}>
          <label>
            <select
              name="reason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                Reason
              </option>
              <option value="One component is pending ">
              One component is pending 
              </option>
              <option value="More than one component is pending ">
              More than one component is pending 
              </option>
              <option value="Others">Others</option>
            </select>
          </label>
          
          {formValue.reason === "Others" ? 
          <label className="lgInput">
            Description
            <input
              type="text"
              style={{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Reason"
              autoComplete="off"
            />
          </label> : ""}

        </div>
        <div className="footer" style={{width:'100%',display:"flex",justifyContent:'flex-end'}}>
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PartiallyClosure;