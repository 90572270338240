import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import "../QcDashboard/styles/main.scss";
import { BsArrowUpRight, BsTable } from "react-icons/bs";
import Users from "../ServiceCenter/serviceCenterUser/ServiceCenterManageUsers";
import Orders from "../ServiceCenterOrders/Requisition";
import Returns from "../serviceCenterReturns/returnRequest";
import Inventory from "../serviceCenterInventory/sparesInventory";
import Jobs from "../serviceCenterJobs/ServiceJobs";
import AvailableProgress from "./AvailableProgress";
import UserCountBarChart from "./userCountBarChart";
import VehicleCard from "./VehicleCard";
import UserPieChart from "./UserPieChart"

function ServiceCenterDashboard() {
  const [openOrdersTable, setOpenOrdersTable] = useState(false);
  const [openInventoryTable, setOpenInventoryTable] = useState(false);
  const [openJobsTable, setOpenJobsTable] = useState(false);
  const [openReturnTable, setOpenReturnTable] = useState(false);
  const [openUsersTable, setOpenUsersTable] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(`serviceCenter-module/api/v1/dashboard/getReturns`)
    );
    setData(res);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {openOrdersTable == true ? (
        <Orders inDashboard={setOpenOrdersTable} />
      ) : openInventoryTable == true ? (
        <Inventory inDashboard={setOpenInventoryTable} />
      ) : openJobsTable == true ? (
        <Jobs inDashboard={setOpenJobsTable} />
      ) : openReturnTable == true ? (
        <Returns inDashboard={setOpenReturnTable} />
      ) : openUsersTable == true ? (
        <Users inDashboard={setOpenUsersTable} />
      ) : (
        <>
          <>
            <div
              className="thirdBox"
              style={{
                height: "auto",
                marginTop: "10px",
                padding: "23px 0px",
              }}
            >
              {/* First Row */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "inherit",
                }}
              >
                <div
                  className="pricing"
                  style={{ height: "265px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Users</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenUsersTable(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <UserCountBarChart />
                  </div>
                </div>
                <div
                  className="pricing"
                  style={{ height: "265px", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Orders</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenOrdersTable(true)}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <AvailableProgress />
                  </div>
                </div>
              </div>

              {/* Second Row */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "inherit",
                }}
              >
                <div
                  className="pricing"
                  style={{ height: "auto", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Spares Inventory</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenInventoryTable(true)}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <VehicleCard />
                  </div>
                </div>
                <div
                  className="pricing"
                  style={{ height: "auto", marginTop: "-30px", width: "50%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Service Jobs</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenJobsTable(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                    <UserPieChart userTypeData={""} />
                  </div>
                </div>
              </div>

              {/* Third Row */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "inherit",
                }}
              >
                <div
                  className="pricing"
                  style={{ height: "256px", marginTop: "-30px", width: "48%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "-100px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <h5 style={{ fontSize: "14px" }}>Spares Returns </h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight
                          onClick={() => setOpenReturnTable(true)}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                    }}
                  >
                    <div className="assemblyCard innerDiv">
                      <span style={{ fontWeight: "420" }}>Total Placed Returns</span>
                      <span style={{ fontWeight: "420" }}>
                        {data.totalReturns}
                      </span>
                    </div>
                    <div className="innerDiv">
                      <span style={{ fontWeight: "420" }}>
                        Total Returns In-Progress
                      </span>
                      <span style={{ fontWeight: "420" }}>
                        {data.totalRetunInprogress}
                      </span>
                    </div>
                    <div className="assemblyCard innerDiv">
                      <span style={{ fontWeight: "420" }}>Total Returns On Halt</span>
                      <span style={{ fontWeight: "420" }}>
                        {data.totalOnHalt}
                      </span>
                    </div>
                    <div className="innerDiv">
                      <span style={{ fontWeight: "420" }}>
                        Total Returns Settled
                      </span>
                      <span style={{ fontWeight: "420" }}>
                        {data.totalReturnSettled}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </>
        </>
      )}
    </>
  );
}

export default ServiceCenterDashboard;
