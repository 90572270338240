import React, { useEffect, useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import "../finishGoods.scss";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";

const HybridVehicleDetails = ({ rowData, closeModal }) => {
    console.log(rowData)
    const blankForm = [rowData];
    const [hybridVehicleDetailsData, setHybridVehicleDetailsData] = useState([]);

    const getHybridVehicleViewDetails = async () => {
        const resp = await trackPromise(API.get(`inventory-module/api/v1/finishedGoods/getHybridVehicles?vehicleSegment=${rowData.vehicleSegment}&vehicleColor=${rowData.vehicleColor}&vehicleModel=${rowData.vehicleModel}&vehicleVariant=${rowData.vehicleVariant}&vehicleType=${rowData.vehicleType}`));
        setHybridVehicleDetailsData(resp);
    };
    useEffect(() => {
        getHybridVehicleViewDetails();
    }, []);
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "580px", height: "480px", padding: '15px' }}
            >
                <div className="header" style={{ width: '100%', display: "flex", justifyContent: "flex-start" }}>
                    <h3>Vehicle Quantity</h3>
                </div>

                <div
                    className="body"
                    style={{ width: '100%', display: "flex", fontSize: "14px" }}
                >


                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "10px 0",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                            <span>Vehicle Category : Hybrid</span>
                            <span>Vehicle Model : {rowData.vehicleModel}</span>

                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                            <span>Vehicle Segment : {rowData.vehicleSegment}</span>
                            <span>Vehicle Variant : {rowData.vehicleVariant}</span>
                        </div>
                    </section>
                    <div className="availVehicle">Total Qty. : {hybridVehicleDetailsData.length}</div>

                    <section
                        id="nr_POno"
                        style={{
                            width: '100%',
                            fontSize: "14px",
                            margin: "10px 0",
                            border: 'none'
                        }}
                    >
                        <div style={{ padding: '0px', border: '1px solid #cdcdcd' }}>
                            <span style={{ width: '20%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>Color</span>
                            <span style={{ width: '40%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>VIN</span>
                            <span style={{ width: '40%', padding: '10px' }}>SNO</span>
                        </div>
                        <section style={{ width: "100%", height: '180px', overflow: 'scroll' }}>
                            {hybridVehicleDetailsData.map((item, index) => (
                                <div style={{ display: 'flex', padding: '0px', border: '1px solid #cdcdcd', borderTop: 'none' }}>
                                    <span style={{ width: '20%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>{item.vehicleColor}</span>
                                    <span style={{ width: '40%', padding: '10px', borderRight: '1px solid #cdcdcd' }}>{item.VIN}</span>
                                    <span style={{ width: '40%', padding: '10px' }}>{item.SNO}</span>
                                </div>
                            ))}
                        </section>

                    </section>

                </div>

                <div className="footer" style={{ width: '100%', display: "flex", justifyContent: "flex-end" }}>
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
    );
};

export default HybridVehicleDetails;