import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";

const VehicleInsuranceDetails = ({ closeModal, rowData }) => {

  console.log("rowData-->",rowData)


  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "50%", height: "500px", padding: "10px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Vehicle Insurance Details</h3>
          <div>
            {/* <button className="action-btn" style={{ color: "blue" }}>
              <PrintIcon />
              &nbsp;Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              <IosShareIcon />
              &nbsp;Export
            </button> */}
          </div>
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding:7,
          }}
        >
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            SKU Code: {rowData?.vehicleSkuCode}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Vehicle Model: {rowData?.vehicleModel}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            Vehicle Variant: {rowData?.vehicleVariant}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Vehicle Color: {rowData?.vehicleColor}
          </span>
          
        </section>
        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ color: "blue" }}>Standard Insurance</h6>
            </div>

            <div>
                <Table>
                <tbody>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Insurance Type</td>
                    <td style={{textAlign:"start"}}>{rowData?.standardInsurance?.insuranceType}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Duration</td>
                    <td style={{textAlign:"start"}}>{rowData?.standardInsurance?.insuranceDuration}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Insurance Inclusions & Terms</td>
                    <td style={{color:"blue", textAlign:"start"}}>altroz_free_service.pdf</td>
                  </tr>
                </tbody>
              </Table>
            </div>

          </div>
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ color: "blue" }}>Extended Insurance</h6>
            </div>

            <div>
            {
              rowData?.extendedInsurance?.map((item)=>{
                return (
                  <Table>
                <tbody>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Insurance Type</td>
                    <td style={{textAlign:"start"}}>{item?.insuranceType}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Duration</td>
                    <td style={{textAlign:"start"}}>{item?.insuranceDuration}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb", textAlign:"start"}}>Insurance Inclusions & Terms</td>
                    <td style={{color:"blue", textAlign:"start"}}>altroz_free_service.pdf</td>
                  </tr>
                </tbody>
              </Table>
                )
              })
             }
            </div>

          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <KeyboardBackspace /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default VehicleInsuranceDetails;
