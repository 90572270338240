import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Delete = ({ updatedData, editData, closeModal}) => {
  const [formValue, setFormValue] = useState({
    deleteReason: "",
    description: "",
  });

  const editBody = { ...editData, requestAction: "Delete Requested", requestedReason: formValue.deleteReason, requestedDescription: formValue.description };

  const refresh = () => {
    updatedData();
  }

  const submitHandler = async () => {
    if(formValue.deleteReason){
      const resp = await trackPromise(
        API.post(`zonalSales-module/api/v1/users/requestAction`, editBody)
      );
      const { success, message } = resp;
      if (success) {
        closeModal(false);
        successNotification(message);
        refresh();
      } else {
        errorNotification(message);
      }
    }
    else{
      return errorNotification("Please fill all the required fields")
    }
   
  }

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Delete User</h3>
        </div>

        <div className="body">
          <label
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label
              style={{ fontSize: "13px", fontWeight: "500", width: "fit-content" }}
            >
              Delete Reason<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="deleteReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: "100%", marginTop: "-5px" }}
            >
              <option disabled selected hidden>
                Delete Reason
              </option>
              <option value="Misconduct">Misconduct</option>
              <option value="Not an Active User">Not an Active User</option>
              <option value="Change of Role">Change of Role</option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.deleteReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Deactivation Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button id="submit-button" onClick={submitHandler}>
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Delete;
