import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';

function Subscription({ subscriptionData }) {
    return (
        <div style={{ width: "50%", marginLeft: "10px" }}>
            <Doughnut
                data={{
                    datasets: [{
                        // data: [subscriptionData?.activeUsers, subscriptionData?.deactivatedUsers],
                        data:[34,45],
                        backgroundColor: [
                        'rgb(77,87,189)',
                            'rgb(74,175,84)',
                        ],
                        options: {
                            radius: "10%"
                        },
                        hoverOffset: 4,
                    }
                    ],
                    labels: ["Total Licenses", "Subscribed Licenses"]
                }} />
            <div style={{ margin: "-70% 0 0 100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" , width:"184px"}}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgb(77,87,189)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "114px", fontSize: "13px", fontWeight: "500", marginTop: "3px" }}>Total Licenses</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "13px", fontWeight: "700", marginTop: "3px" }}>
                        {/* {subscriptionData?.activeUsers} */}33423423
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" , width:"184px"}}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                        <div style={{ display: "flex", width: "15px", height: "15px", background: "rgb(74,175,84)", borderRadius: "3px", marginRight: "5px", marginTop: "4px" }}>
                        </div>&nbsp;
                        <span style={{ width: "114px", fontSize: "13px", fontWeight: "500", marginTop: "3px" }}>Subs. Licenses</span>
                    </div>
                    <div style={{ marginLeft: "7px", fontSize: "13px", fontWeight: "700", marginTop: "3px" }}>
                        {/* {subscriptionData?.deactivatedUsers} */}45787734
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscription