import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFileDownload,
  faFilter,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";


import AppConstants from "../../utils/app-constants";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ActionViewDetails from "./ActionViewDetails";




const QueryTable = ({ props }) => {

  const UsersData = [
    {
      priority: "High",
      category: "kitty ",
      subcategory: "one",
      description: "perfect...",
      executiveName:"mansi",
      executive:[
        {
          createdDate:"29/11/24",
          currentStatus:"Pending"
        }
      ]
    }
  ]

  const [actionShow, setActionShow] = useState();
  // const [UsersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState();
  const [filterData, setFilterData] = useState();
  const [showFilter, setShowFilter] = useState(false);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        ` ` 
      )
    );
    console.log(resp);
    let filteredUserData = resp.filter((item) => item.status != "Deleted");
    setUsersData(filteredUserData);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const statusFormatter = (cell, row, rowIndex) => {
    return cell === AppConstants.ACTIVE ? (
      <button className="active-btn">
        <span className="active-btn-font">Active</span>
      </button>
    ) : cell === AppConstants.REACTIVATED ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">Reactivated</span>
      </button>
    ) : cell === AppConstants.DEACTIVATED ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Deactivated</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)} 
        style={{
          border:"1px solid blue",
          borderRadius:"2px",
          color:"blue"
        }}>
         View Details
        </button>
        {actionShow === rowIndex && isOpen ? (
          <ActionViewDetails
            updatedData={fetchReport}
            rowData={row}
            close={setIsOpen}
            rowIndex={rowIndex}
          />
        ) : null}
      </div>
    );
  };

  const [show, setShow] = useState(false);

  const [formValue, setFormValue] = useState({
    priority: "",
      category: "",
      subcategory: "",
      description: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      priority: "",
      category: "",
      subcategory: "",
      description: "",
    });
    const resp = await trackPromise(
      API.get(` `)
    );
    setUsersData(resp);
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  function dateFormatter(cell, row) {
    let date = cell;
    let updatedDate;
    if (!cell) {
      return "N/A";
    }
    row.actionHistory.map((item) => {
      updatedDate = item.date;
    });
    return (
      <>
        {row.status === "Active" ? (
          <span>{moment(date).format("DD-MMM-YYYY/HH:mm")}</span>
        ) : (
          <span>{moment(updatedDate).format("DD-MMM-YYYY/HH:mm")}</span>
        )}
      </>
    );
  }

  const reasonFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{row.reason}</>;
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Priority",
      dataField: "features",
      headerAttrs: { width: "300px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Category",
      dataField: "category",
      headerAttrs: { width: "300px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Sub Category",
      dataField: "subcategory",
      headerAttrs: { width: "300px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Description",
      dataField: "description",
      headerAttrs: { width: "300px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "160px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];
  console.log(formValue);
  return (
    <>
    <div style={{ position: "relative", zIndex: "1" }}>
    
      <div id="overlay" onClick={(e) => off(e)}></div>
      <div className="heading bussiness" style={{marginBottom:"-5px"}}>
        <span>Query</span>
      </div>

      <ToolkitProvider keyField="id" data={UsersData} columns={columns}  >
       
        {(props) => (
          <>
          <div style={{ float: "right", marginTop: "-42px"}}>
              <div style={{ display: "flex", marginRight: "-15px", height: "26px", marginTop:"10px", }}>
                <div style={{ width: "220px" }}>
                
                </div>
              </div>
              </div>

           
    
             
    <br></br>
    
        <BootstrapTable
              wrapperClasses="table-responsive"
              // rowClasses="text-nowrap"
              rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
              pagination={paginationFactory({
                hidePageListOnlyOnePage: true,
                showTotal: true,
                hideSizePerPage: true,
                paginationSize: 2,
                sizePerPage: "10",
              })}
              noDataIndication="No Data Found"
              {...props.baseProps}
            />
          </>
        )}
      </ToolkitProvider>

    </div>
  </>
  );
};

export default QueryTable;
