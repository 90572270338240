import React, { useState } from "react";
import {
  KeyboardBackspace
} from "@material-ui/icons";

const ViewSpecs = ({ rowData, close }) => {
  console.log("rowData")
  console.log(rowData)
  return (

    <div>
      <div className="container1">
        <div className="modalContainer" style={{ height: "350px" }}>
          <div className="header">
            <h3> Component Specification</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <h6>Component Name: {rowData?.componentName || rowData?.sparePartName}</h6>
            <h6>Component Sku code: {rowData?.skuCode || rowData?.SKUCode}</h6>
          </div>
          <div className="body" style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap" }}>
            <ul type="none" className="viewspeclist" style={{ paddingLeft: "0px", textAlign: "left", fontSize: "14px" }}>
              {rowData?.specifications?.map((item) => {
                return (
                  <li>{`Component ${item?.specsType} : ${item?.specsValue}${item?.specsUnit}`}</li>
                )
              })}
            </ul>
          </div>
          <div className="footer">
            <button onClick={() => close(false)}><KeyboardBackspace /> &nbsp;Back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSpecs;
