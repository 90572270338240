import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileUpload,
  faPlus,
  faTrashAlt,
  faTimes,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

const AddSpecs = ({ addSpecsData,closeModal, handler, index, UnitOfMeasure }) => {

  const specsData = addSpecsData === undefined ?  [{
    specsType: "",
    specsValue: "",
    specsUnit: UnitOfMeasure,
  }] : addSpecsData

  const [formValue, setFormValue] = useState(specsData);

  let addFields = () => {
    setFormValue([
      ...formValue,
      {
        specsType: "",
        specsValue: "",
        specsUnit: "",
      },
    ]);
  };

  const removeFields = (index) => {
    const field = [...formValue];
    field.splice(index, 1);
    setFormValue(field);
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const field = [...formValue];
    field[index][name] = value;
    setFormValue(field);
  };
  const submitHandler = () => {
    // console.log(formValue);
    handler(index, formValue);
    console.log("submitted succesfully!");
    closeModal(false);
  };

  console.log(formValue)
  console.log(addSpecsData)
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "860px", height: "350px" }}
      >
        <div className="header">
          <h3>Component Specifications</h3>
        </div>

        <div className="body" style={{ height: "150px", overflowY: "scroll" }}>
          {formValue.map((value, index) => {
            return (
              <div
                style={{
                  display: "flex", flexWrap: "wrap", margin: "15px 0px" 
                }}
              >
                  <div
                  style={{
                    margin: "0px 5px 0px 5px",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                   <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                      justifyContent:"start",
                      textAlign:"start"
                    }}
                  >
                    Specification Type<span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                  type="text"
                  name="specsType"
                  value={value.specsType}
                  placeholder="Enter Specification Type"
                  onChange={(event) => handleInputChange(event, index)}
                  style={{marginTop:'-5px'}}
                />

                </div>
                <div
                  style={{
                    margin: "0px 5px 0px 5px",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                   <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                      justifyContent:"start",
                      textAlign:"start"
                    }}
                  >
                    Specification Value<span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                  type="text"
                  name="specsValue"
                  value={value.specsValue}
                  placeholder="Enter Specification Value"
                  onChange={(event) => handleInputChange(event, index)}
                  style={{marginTop:'-5px'}}
                />
                </div>
                <div
                  style={{
                    margin: "0px 5px 0px 5px",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                   <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                      justifyContent:"start",
                      textAlign:"start"
                    }}
                  >
                     Units of Measure<span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                  disabled
                  type="none"
                  name="specsValue"
                  value={UnitOfMeasure}
                  placeholder="Enter Specification Value"
                  style={{marginTop:'-5px'}}
                />
                </div>
               
              

                {index === 0 ? (
                  <button
                    type="button"
                    style={{
                      borderRadius: "100%",
                      border: "1px solid green",
                      height: "30px",
                      marginTop:'28px',
                      marginLeft:'15px'
                    }}
                    onClick={() => addFields()}
                  >
                    <div style={{ color: "green" }}>
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                  </button>
                ) : (
                  <button
                    type="button"
                    style={{
                      borderRadius: "100%",
                      border: "1px solid red",
                      height: "30px",
                      marginTop:'28px',
                      marginLeft:'15px'
                    }}
                    onClick={() => removeFields(index)}
                  >
                    <div style={{ color: "red" }}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  </button>
                )}
              </div>
            );
          })}

          <br />
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSpecs;
