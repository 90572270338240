import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import VehicleReturns from "./VehicleReturns/Component/VehicleReturns";
// import SpareReturns from "./SpareReturns/component/SpareReturns";

const VehicleReturns = React.lazy(()=>import("./VehicleReturns/Component/VehicleReturns"))
const SpareReturns = React.lazy(()=>import("./SpareReturns/component/SpareReturns"))


const AreaSalesReturn = () => {
  const tabs = [
    {
      id: "ASM3T1",
      label: TabLabels.VEHICLE_RETURNS,
      Component: VehicleReturns,
    },
    {
      id: "ASM3T2",
      label: TabLabels.SPARE_RETURNS,
      Component: SpareReturns,
    }
  ];

  const getTabs = (tabs) => {
    let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
    return tabs.filter((tab) => tabIds.includes(tab.id));
  };

  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default AreaSalesReturn;
