import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
import OnDemandFinishedGoods from "./OnDemand finished_goods/OnDemand finished-goods";
// import RawInventory from "./raw-inventory";
// import WIP_Inventory from "./wip-inventory";
// import FinishedGoods from "./finished_goods/finished-goods";
// import MRO_Inventory from "./mro-inventory";
// import RejectionInventory from "./rejection-inventory";

const RawBattery = React.lazy(()=>import("./RawBattery"))
const WIP_Battery = React.lazy(()=>import("./wip-battery"))
const MRO_Battery = React.lazy(()=>import("./mro-Battery"))
const RejectionBattery = React.lazy(()=>import("./rejection-Battery"))



const tabs = [
  {
    id:"INM2T1",
    label: TabLabels.RAW_BATTERY_INV,
    Component: RawBattery
  },
  {
    id:"INM2T2",
    label: TabLabels.WIP_BATTERY_INV,
    Component: WIP_Battery
  },
 
  {
    id:"INM2T3",
    label: TabLabels.MRO_BATTERY_INV,
    Component: MRO_Battery
  },
  {
    id:"INM2T4",
    label: TabLabels.REJECTION_BATTERY_INV,
    Component: RejectionBattery
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};

const Battery_INV = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default Battery_INV;