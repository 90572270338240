import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { errorNotification, successNotification } from "../../../utils/notifications";

import {
  KeyboardBackspace,
  CheckCircle,
} from "@material-ui/icons";
import PurchaseOrder from "../PurchaseOrder";
import auth from "../../../utils/auth";

const EditManageOrder = ({ closeModal, editData }) => {
  console.log(editData)
  const [openPO, setOpenPO] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState({});
  const [productionQty, setProductionQty] = useState();
  const [receivedQuantity, setReceivedQuantity] = useState();

  const editDetails = { ...editData, components: receivedQuantity };
  console.log(editDetails)

  const submitHandler = async () => {
    setOpenPO(true);
    const resp = await trackPromise(API.put(`procurement-module/api/v1/orders`, editDetails));

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const receivedQtyChange = (e, id) => {
    editData.components.forEach(item => {
      if (item.componentID == id) {
        item.requiredQuantity = e.target.value
      }
    })
    setReceivedQuantity(editData.components);
  };

  const blankForm = editData.components;

  const handleComponentChange = (event) => {

    // todo:: filter components from actual data

    // const component = blankForm.filter(
    //   (item) => item.componentName == event.target.value
    // );
    // setSelectedComponent(component[0]);
  };

  const totalPrice = () => {
    let total = 0;
    blankForm.map(item => (
      total += (item.unitPrice * item.requiredQuantity) + ((item.unitPrice * item.requiredQuantity * item.taxRate) / 100)
    ))
    return total;
  }
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1180px", height: "525px", padding: '15px' }}
      >
        <div className="header">
          <h3>Edit Orders</h3>
        </div>

        <div
          className="body"
          style={{ display: "flex", height: '400px', fontSize: "14px", overflow: 'scroll' }}
        >

          <section
            style={{
              width: '100%',
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0"
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>BOM ID : EL03326</span>
              <span>Creation Date : 15-Feb-2022 </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>BOM Level : Multilevel</span>
              <span>Approve Date : 15-Feb-2022 </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>BOM Name : Shine125.02</span>
              <span>Created By : production@gmail.com </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Delivery Location : Sector 15, Faridabad</span>
              <span>Approved By : management@mail.com </span>
            </div>

          </section>

          <section
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: '100%'
            }}
          >
            <select
              required={true}
              defaultValue={'DEFAULT'}
              name="componentName"
              className="input-config"
              style={{ width: "21%", outline: "none", cursor: 'pointer' }}
              onChange={(event) => handleComponentChange(event)}
            >
              <option value={'DEFAULT'} disabled hidden>
                Components
              </option>
              {/* {selectedComponent.map((item, index) => (
                <option key={index}>{item.componentName}</option>
              ))} */}
            </select>

            <input
              className="input-config"
              disabled={Object.keys(selectedComponent).length !== 0 ? false : true}
              type="text"
              placeholder="Production Quantity"
              defaultValue={productionQty}
              onChange={(e) => setProductionQty(e.target.value)}
            />
          </section>

          <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div>Vendor Name : Alpha Industries</div>
            <div>Vendor Location : Sector 15, Gurugram</div>
          </section>


          <section style={{ width: '100%', }}>
            <div id='POVendorHeading'>
              <div style={{ width: '13%' }}>Component Name</div>
              <div style={{ width: '9%' }}>SKU Code</div>
              <div style={{ width: '13%' }}>Vendor Part Name</div>
              <div style={{ width: '13%' }}>Vendor Part Code</div>
              <div style={{ width: '9%' }}>Category</div>
              <div style={{ width: '9%' }}>Sub Category</div>
              <div style={{ width: '8%' }}>BOM Qty.</div>
              <div style={{ width: '8%' }}>#HSN/SAC</div>
              <div style={{ width: '8%' }}>{`Unit Price (${auth.getUserInfo().currencySymbol})`}</div>
              <div style={{ width: '10%' }}>Required Qty.</div>
              <div style={{ width: '8%' }}>{`Qty. Price (${auth.getUserInfo().currencySymbol})`}</div>
              <div style={{ width: '8%' }}>{`Tax (${auth.getUserInfo().currencySymbol})`}</div>
              <div style={{ width: '8%' }}>{`Total Price (${auth.getUserInfo().currencySymbol})`}</div>
            </div>
            <div>
              {
                blankForm.map((item, index) => (<div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', }} key={index}>
                  <div style={{ width: '13%' }}>{item.componentName}</div>
                  <div style={{ width: '9%' }}>{item.skuCode}</div>
                  <div style={{ width: '13%' }}>{item.vendorPartName}</div>
                  <div style={{ width: '13%' }}>{item.vendorPartCode}</div>
                  <div style={{ width: '9%' }}>{item.category}</div>
                  <div style={{ width: '9%' }}>{item.subCategory}</div>
                  <div style={{ width: '8%' }}>2 nos.</div>
                  <div style={{ width: '8%' }}>{item.hsnSacCode}</div>
                  <div style={{ width: '8%' }}>{item.unitPrice}</div>
                  <div style={{ width: '10%' }}>
                    <input
                      type="text"
                      placeholder="Enter Qty."
                      style={{ width: '80px', margin: '0 -7px 0 0', textAlign: 'center', height: '20px' }}
                      defaultValue={item.requiredQuantity}
                      onChange={(e) => receivedQtyChange(e, item.componentID)}
                    />
                  </div>
                  <div style={{ width: '8%' }}>{item.unitPrice * item.requiredQuantity}</div>
                  <div style={{ width: '8%' }}>{`${(item.unitPrice * item.requiredQuantity * item.taxRate) / 100} (${item.taxRate}%)`}</div>
                  <div style={{ width: '8%' }}>{(item.unitPrice * item.requiredQuantity) + ((item.unitPrice * item.requiredQuantity * item.taxRate) / 100)}</div>
                </div>))
              }
            </div>
          </section>


          <section style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', borderRadius: '5px', margin: '5px 0', height: "35px" }}>
            <div style={{ width: '200px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', fontSize: '14px', fontWeight: '500' }}>
            {`Total Price (${auth.getUserInfo().currencySymbol})`} :
              <span style={{ width: '100px', background: '#ebebeb', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                {totalPrice()}
              </span>
            </div>
          </section>

        </div>

        <div className="footer">
          <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
          <button
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "6px 20px",
              marginRight: '0px',
              width: '135px',
              color: "white",
            }}
            onClick={submitHandler}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Generate PO
          </button>

          {openPO && (
            <PurchaseOrder
              purchaseData={blankForm}
              closeModal={setOpenPO}
            />
          )}

        </div>
      </div>
    </div>
  );
};

export default EditManageOrder; 