import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import OperationVerifyBusinessDetails from "./Components/OperationVerifyBusinessDetails";
// import InviteBusinessEntities from "./Components/InviteBusinessEntities";
// import ManagingBusinessEntities from "./Components/ManagingBusinessEntities";
// import BusinessEntitiesHistory from "./Components/BusinessEntitiesHistory";

const InviteBusinessEntities = React.lazy(()=>import("./Components/InviteBusinessEntities"))
const OperationVerifyBusinessDetails = React.lazy(()=>import("./Components/OperationVerifyBusinessDetails"))
const ManagingBusinessEntities = React.lazy(()=>import("./Components/ManagingBusinessEntities"))
const BusinessEntitiesHistory = React.lazy(()=>import("./Components/BusinessEntitiesHistory"))


const tabs = [
  {
    id: "OPM3T1",
    label: TabLabels.INVITE_BUSINESS_ENTITIES,
    Component: InviteBusinessEntities,
  },
  {
    id: "OPM3T2",
    label: TabLabels.VERIFY_BUSINESS_DETAILS,
    Component: OperationVerifyBusinessDetails,
  },
  {
    id: "OPM3T3",
    label: TabLabels.MANAGE_BUSINESS_ENTITIES,
    Component: ManagingBusinessEntities,
  },
  {
    id: "OPM3T4",
    label: TabLabels.BUSINESS_ENTITIES_HISTORY,
    Component: BusinessEntitiesHistory,
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

const OperationBusinessEntities = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default OperationBusinessEntities;
