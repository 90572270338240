import React, { useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";

const ViewServiceDetails = ({ closeModal,row,serviceData }) => {
  console.log(serviceData);
  return (
    <div className="container1">
      <div className="modalContainer" style={{width:'558px',height:'400px'}}>
        <div className="header">
          <h3>Assembly Service</h3>
        </div>
        <div style={{display:'flex', flexDirection:'column' ,alignItems:'flex-start'}}>
            <h6>Assembly Type: {row.assemblyType}</h6>
            <h6>Assembly Name: {row.assemblyName}</h6>
        </div>
        <div className="body" style={{display:'flex', flexDirection:'column' ,alignItems:'flex-start',}}>
        {serviceData.map((item)=> (
            <div style={{
                    display:'flex', 
                    justifyContent:'space-between', 
                    width:'100%',
                    margin:'5px 0',
                    padding:'7px',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.12)',
                    borderRadius: '5px'
                    }}
            >
                <span>Service Name: {item.serviceName}</span>
                {/* <span style={{marginRight:'60px'}}>Service Cost: {item.serviceCost}</span> */}
            </div>
        ))}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}><KeyboardBackspace/>Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewServiceDetails;