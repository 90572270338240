import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";
import ViewTerms from "../../../OperationsWarrantyServices/Component/serviceMaster/ViewTerms";
import ViewTermsDetails from "../../../OperationsWarrantyServices/Component/ViewTermsDetails";

const SpareWarrantyDetails = ({ closeModal, rowData }) => {
  // const UsersData = row.requisitionDetails;
  const [openView , setOpenView] = useState()
  return (
    <div className="container1">
    {
      openView && <ViewTermsDetails rowdata={rowData} closeModal={setOpenView} data={"Spare Warranty Terms"} />
    }
      <div
        className="modalContainer"
        style={{ width: "50%", padding: "10px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Spare Warranty Details</h3>
        <button
          onClick={()=>setOpenView(true)}
          style={{
            color: "#0A18A3",
            backgroundColor: "white",
            border: "1px solid #0A18A3",
            borderRadius: "3px",
            marginBottom:"6px"
          }}
        >
          View Terms
        </button>
        
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding: 7,
          }}
        >
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            SKU Code: {rowData.skuCode}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Category : {rowData.category}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            Spare Part Name: {rowData.sparePartname}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Sub Category: {rowData.subCategory}
          </span>
        </section>
        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ color: "blue" }}>Warranty Details</h6>
            </div>

            <div>
              <Table>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>Warranty Duration</td>
                    <td>{`${rowData.warrantyDuration} ${rowData.warrantyDurationUnit}`}</td>
                  </tr>
                  {/* <tr>
                    <td style={{backgroundColor:"#ebebeb"}}>Warranty Inclusions & Terms</td>
                    <td style={{color:"blue"}}>tata altroz.pdf</td>
                  </tr> */}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <KeyboardBackspace /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default SpareWarrantyDetails;
