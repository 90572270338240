import React, { useEffect, useState } from "react";
import { CancelOutlined, CheckCircle } from "@material-ui/icons";
import { errorNotification, successNotification } from "../../../../utils/notifications";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import RichTextEditor from "react-rte";

const ServiceManualEdit = ({ rowdata, closeModal , fetchData }) => {

  // Initialize editorValue with rowdata's serviceManualTerms
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString(rowdata?.serviceManualTerms, 'html')
  );

  const [editorData, setEditorData] = useState({
    serviceManualType: rowdata?.serviceManualType,
    serviceManualTerms: rowdata?.serviceManualTerms,
    serviceManualId: rowdata?.serviceManualId,
  });

  console.log("editorData-->", editorData);

  const onEditorStateChange = (value) => {
    console.log("newValue-->", value.toString("html"));
    setEditorValue(value);
    var data = value.toString("html")
    setEditorData((prevState) => ({
      ...prevState,
      serviceManualTerms: data,
    }));
  };

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.put(`/operations-module/api/v1/warrantyAndService/serviceMaster/serviceManual/editServiceManual`, editorData)
    );
    if (resp.success) {
      successNotification(resp.message, { autoClose: 10000 });
      fetchData()
      closeModal(false);
    } else {
      errorNotification(resp.message, { autoClose: 10000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "900px", height: "600px" }}>
        <div className="header">
          <h3>Edit Service Manuals</h3>
        </div>
        <div
          style={{
            border: "1px solid grey",
            marginTop: "10px",
            marginBottom: "50px",
            maxHeight: "400px", 
            overflowY: "auto",
          }}
        >
          <RichTextEditor value={editorValue} onChange={onEditorStateChange} />
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <CancelOutlined /> Cancel
          </button>
          <button
            className="btn btn-icon-label"
            type="submit"
            onClick={submitHandler}
            style={{ backgroundColor: "#0a18a3", color: "white" }}
          >
            <div style={{ fontSize: "14px", color: "white", fontWeight: "500" }}>
              <CheckCircle style={{ fontSize: "17px" }} />
              &nbsp;Submit
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceManualEdit;
