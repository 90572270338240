import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faPlusCircle,
  faTimesCircle,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";
import ViewDetails from "./ViewDetails";
import auth from "../../utils/auth";

const OperationLocation = () => {
  const [isOpen, setIsOpen] = useState(false)


  // const fetchReport = async () => {
  //   const response = await trackPromise(
  //     API.get(`admin-module/api/v1/users/getBusinessUserInfo`)
  //   );
  //   console.log(response)
  //   setBusinessUserInfo(response);
  //   setFormValue({
  //     businessName: response?.businessName,
  //     businessEmail: response?.email,
  //     pan: response?.pan,
  //     gstin: response?.gstin,
  //     panDoc: panfile,
  //     gstinDoc: gstfile,
  //   })
  //   let prodLocationArray = response.locations.filter(
  //     (item) => item.locationType == "Production"
  //   );
  //   setProdArray(prodLocationArray);
  //   let bussLocationArray = response.locations.filter(
  //     (item) => item.locationType == "Business"
  //   );
  //   setBussArray(bussLocationArray);
  //   let storageLocationArray = response.locations.filter(
  //     (item) => item.locationType == "Storage"
  //   );
  //   setStorageArray(storageLocationArray);
  // };
  // useEffect(() => {
  //   fetchReport();
  // }, []);


  return (
    <>
      <div className="bussiness">
        <h4 className="heading">Operation Location</h4>
      </div>
      <br />
      <div>
        <h6>Location ID: <span style={{color:"#0A18A3"}}>4324344</span></h6>
      </div>
      <br />
      <section style={{display:"flex", flexDirection:"column", boxShadow:"0px 0px 5px grey",padding:"20px", borderRadius:"5px"}}>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%", fontWeight:"500"}}>
        <span style={{width:"40%"}}>Location Name</span>
        <span style={{width:"25%"}}>Country</span>
        <span style={{width:"25%"}}>State</span>
        <span style={{width:"10%"}}></span>
        </div>
        <hr />
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%", fontSize:"14px", fontWeight:'500'}}>
        <span style={{width:"40%"}}>C-171, Sec-63, Noida</span>
        <span style={{width:"25%"}}>India</span>
        <span style={{width:"25%"}}>Uttar Pradesh</span>
        <button style={{backgroundColor:"transparent", border:"none", color:"#0A18A3", width:"10%"}} onClick={()=>setIsOpen(true)}>View Details</button>
        </div>
        {isOpen && <ViewDetails closeModal={setIsOpen}/>}
      </section>
    </>
  );
};

export default OperationLocation;
