import React from "react";
import { MdOutlineCancel } from "react-icons/md";
import { IoIosCheckmarkCircle } from "react-icons/io";

const ViewDetails = ({ closeModal, Data }) => {

  return (
    <div className="container1">
      <div className="mainCont" style={{width:"35%", height:"85%"}}>
        <div className="bg-grdnt">
          <div className="vD">
            <span className="planSec">
              <span>Plan Name:</span>
              <span>{Data?.planName}</span>
            </span>
            <span className="planSec">
              <span>Plan ID:</span>
              <span>{Data?.planId}</span>
            </span>
          </div>
        </div>

        <div style={{margin:"5px"}}>
            <span style={{marginLeft:"32px"}}>This Plan offers the below mentioned features to the user</span>
        </div>
    

        <div className="body" style={{ padding: "6px", height:"90%", overflow:"auto" }}>
  <section style={{ padding: "6px" }}>
    <ul style={{ listStyle: "none", padding: 0, display: "flex", flexDirection: "column" }}>
      {Data?.features?.map((item) => (
        <li style={{ display: "flex", alignItems: "center", marginBottom: "6px" }}>
          {item?.checked && <IoIosCheckmarkCircle style={{ color: "#33cc33", marginRight: "6px" }} />}
          <span style={{width:"100%"}}>{item?.checked && item?.name}</span>
        </li>
      ))}
    </ul>
  </section>
</div>

        <div
          style={{
            position: "sticky",
            bottom: "0px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop:"28px"
          }}
        >
          <button
            className="footerPurchase-btn"
            onClick={() => closeModal(false)}
          >
            <MdOutlineCancel /> Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
