import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
    KeyboardBackspace,
    CheckCircle,
    HighlightOffOutlined,
    ConfirmationNumber,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { errorNotification, successNotification } from "../../../utils/notifications";
import { faEdit, faBan, faShare, faPauseCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import auth from "../../../utils/auth";

const RequisitionDetails = ({ closeModal, rowData }) => {
    const [openModal, setOpenModal] = useState(false)
    const [unitPrice, setUnitPrice] = useState();
    const [battery, setBattery] = useState()

    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "1100px", height: "420px", padding: '15px' }}
            >
                <div className="header" tyle={{ marginTop: "-30px" }}>
                    <h3>Vehicle Requisition</h3>
                </div>
                <br />
                <div
                    className="body"
                    style={{ display: "flex", height: "450px", fontSize: "14px", overflow: 'scroll' }}
                >

                    <section style={{ width: '100%', }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requisition Number : {rowData?.requisitionID}</span>
                            <span>Total Vehicle Requested : {rowData?.vehicleData?.reduce(
                                (x, b) => x + parseInt(b?.requiredQty),
                                0
                            )}</span>
                        </div>
                        <br />
                        <div style={{ overflow: "scroll" }}>
                            <div id='POVendorHeading' style={{ width: "130%" }}>
                                <div style={{ width: '5%' }}>S. No.</div>
                                <div style={{ width: '9%' }}>SKU Code</div>
                                <div style={{ width: '9%' }}>Segment</div>
                                <div style={{ width: '9%' }}>Category</div>
                                <div style={{ width: '10%' }}>Vehicle Model</div>
                                <div style={{ width: '10%' }}>Vehicle Variant</div>
                                <div style={{ width: '9%' }}>Vehicle Type</div>
                                <div style={{ width: '9%' }}>Vehicle SKU</div>
                                <div style={{ width: '9%' }}>Color</div>
                                <div style={{ width: '11%' }}>Requested Units</div>
                                <div style={{ width: '15%' }}>Vehicle Unit Price {`(${auth.getUserInfo().currencySymbol})`}</div>
                                <div style={{ width: '10%' }}>Battery Quant.</div>
                                <div style={{ width: '15%' }}>Battery Unit Price {`(${auth.getUserInfo().currencySymbol})`}</div>
                                <div style={{ width: '9%' }}>Sub Total {`(${auth.getUserInfo().currencySymbol})`}</div>
                            </div>
                            {
                                rowData?.vehicleData.map((item, index) => {
                                    return (
                                        <div>
                                            <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', width: "130%", boxShadow: 'none', padding: '0px' }} key={1}>
                                                <div style={{ width: '5%' }}>{index + 1}</div>
                                                <div style={{ width: "9%" }}>{item.vehicleSkuCode}</div>
                                                <div style={{ width: '9%' }}>{item?.vehicleSegment}</div>
                                                <div style={{ width: '9%' }}>{item?.vehicleCategory}</div>
                                                <div style={{ width: '10%', }}>{item?.vehicleModel}</div>
                                                <div style={{ width: '10%' }}>{item?.vehicleVariant}</div>
                                                <div style={{ width: '9%' }}>{item?.vehicleType}</div>
                                                <div style={{ width: '9%' }}>{item?.vehicleSkuCode}</div>
                                                <div style={{ width: '9%' }}>{item?.vehicleColor}</div>
                                                <div style={{ width: '11%', }}>{item?.requiredQty}</div>
                                                <div style={{ width: '15%' }}>{item?.unitPrice}</div>
                                                <div style={{ width: '10%' }}>{item?.batteryQty}</div>
                                                <div style={{ width: '15%' }}>-</div>
                                                <div style={{ width: '9%' }}>
                                                    {parseInt(item?.unitPrice) * parseInt(item?.requiredQty)}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </section>

                </div>

                <div className="footer">
                    <div><button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button></div>
                </div>

            </div>
        </div>
    );
};

export default RequisitionDetails