import React, { useState } from "react";
import {
  CancelOutlined,
  CheckCircle,
} from "@material-ui/icons";
import PurchaseOrder from "../PurchaseOrder";
import FullClosure from "./FullClosure";
import PartiallyClosure from "./PartiallyClosure";
import { MdCancel } from "react-icons/md";

const MarkClosureManageOrder = ({ closeModal, editData }) => {
  console.log(editData)
  const [fullClosure, setFullClosure] = useState(false);
  const [partiallyClosure, setPartiallyClosure] = useState(false);

  const FullClosureHandler = () => {
    setPartiallyClosure(false);
    setFullClosure(true);
  }

  const PartiallyClosureHandler = () => {
    setFullClosure(false);
    setPartiallyClosure(true);
  }

  const blankForm = editData.components;

  return (
    <div className="container1">
      
      <div
        className="modalContainer"
        style={{ width: "980px", height: "400px", padding: '15px', position:"relative" }}
      >
        <div className="header">
          <h3>Mark Closure</h3>
        </div>

        <div
          className="body"
          style={{ display: "flex", height: '400px', fontSize: "14px", overflow: 'scroll' }}
        >

          <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <br />
            <div style={{ color: '#0A18A3' }}>PO: {editData.POID}</div>
            <div>Vendor Name : {editData?.vendorName}</div>
            <div>Vendor Location : {editData?.vendorCity}</div>
          </section>


          <section style={{ width: '100%', }}>
            <div id='POVendorHeading'>
              <div style={{ width: '14%' }}>Component Name</div>
              <div style={{ width: '14%' }}>SKU Code</div>
              <div style={{ width: '14%' }}>Vendor Part Name</div>
              <div style={{ width: '14%' }}>Vendor Part Code</div>
              <div style={{ width: '14%' }}>Ordered Qty.</div>
              <div style={{ width: '14%' }}>Received Qty.</div>
              <div style={{ width: '14%' }}>Pending Qty.</div>
            </div>
            <div>
              {
                blankForm?.map((item, index) => (
                  <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', }} key={index}>
                    <div style={{ width: '14%' }}>{item?.componentName}</div>
                    <div style={{ width: '14%' }}>{item?.skuCode}</div>
                    <div style={{ width: '14%' }}>{item?.vendorPartName}</div>
                    <div style={{ width: '14%' }}>{item?.vendorPartCode}</div>
                    <div style={{ width: '14%' }}>{item?.requiredQuantity}</div>
                    <div style={{ width: '14%' }}>{item?.receivedQuantity}</div>
                    <div style={{ width: '14%' }}>{item?.requiredQuantity - item?.receivedQuantity}</div>
                  </div>))
              }
            </div>
          </section>

        </div>

        <div className="footer" style={{ display: 'flex' }}>
          {/* <div>
            <button
              style={{
                border: "1px solid #0A18A3",
                borderRadius: "3px",
                // padding: "5px 15px",
                marginRight: "10px",
                color: "#0A18A3",
                width: '90px'
              }}
              onClick={() => closeModal(false)}
            >
              <CancelOutlined style={{ fontSize: "17px" }} />
              Cancel
            </button>
          </div> */}

          <div>
            {editData?.status === "Partially Closed" ? "" :
              <button
              style={{
                border: "1px solid #0A18A3",
                borderRadius: "3px",
                // padding: "5px 15px",
                marginRight: "10px",
                color: "#0A18A3",
                width: '150px'
            }}
                onClick={PartiallyClosureHandler}
              >
                <CancelOutlined style={{ fontSize: "17px" }} />
                Partially Closure
              </button>}
            {partiallyClosure && (
              <PartiallyClosure
                editData={editData}
                closeModal={setPartiallyClosure}
              />
            )}
            <button
             style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              // padding: "5px 15px",
              color: "white",
              width: '140px'
          }}
              onClick={FullClosureHandler}
            >
              <CheckCircle style={{ fontSize: "17px" }} />
              Full Closure
            </button>
            {fullClosure && (
              <FullClosure
                editData={editData}
                closeModal={setFullClosure}
              />
            )}
          </div>
        </div>

        <div 
        style={{
          position:"absolute",
          top:"-3px",
          right:"0px",
          cursor: "pointer"
          }}
        onClick={() => closeModal(false)}
        
        >
      <MdCancel style={{height:"40px", width:"35px", color:"rgb(10, 24, 163)"}}/>
      </div>

      </div>

    </div>
  );
};

export default MarkClosureManageOrder;  