import React, { useEffect, useState } from "react";
import "./superAdminDashboard.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import "../QcDashboard/styles/main.scss";
import { BsArrowUpRight } from "react-icons/bs";
import { FaRedoAlt } from "react-icons/fa";
import UserCountBarChart from "./userCountBarChart";
import UserPieChart from "./userPieChart";
import ExpandSubscription from "./expandSubscription";
import ExpandBusLocation from "./expandBusLocation";
import ExpandUserCountGraph from "./expandUserCountGraph";
import ExpandUserPieChart from "./expandUserPieChart";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import moment from "moment";
import ManageUsers from "../user/manage-users";
import { useHistory } from "react-router-dom";
import { DatePicker, Space } from "antd";
import auth from "../../utils/auth";
import Tab from "../subscription/subscriptionTabs/Tab";
import ConfirmationModal from "../subscription/Actions/ConfirmationModal";

function SuperAdminDashboard() {
  const [expandSubscription, setExpandSubscription] = useState(false);
  const [expandBusLocation, setExpandBusLocation] = useState(false);
  const [expandUserCountGraph, setExpandUserCountGraph] = useState(false);
  const [expandUserPieChart, setExpandUserPieChart] = useState(false);
  const [locationData, setLocationData] = useState({});
  const [userTypeData, setUserTypeData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userTypeStartDate, setUserTypeStartDate] = useState(null);
  const [userTypeEndDate, setUserTypeEndDate] = useState(null);
  const { RangePicker } = DatePicker;
  const [setCalenderOpen] = useState(false);
  const [openManageUserTable, setOpenManageUserTable] = useState(false);
  const [currentPlan, setCurrentPlan] = useState([]);
  const [renew, setRenew] = useState(false);

  const fetchPlanHistoryWithUserIDData = async () => {
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/subscription/planHistoryWithUserId?userID=${auth.getUserInfo().userID}`)
    );
    console.log(resp);
    // setPlanHistoryData(resp.planHistory);

    const currentPlan = resp.planHistory.filter((item) => {
      return item.planID == resp.planID
    });
    setCurrentPlan(currentPlan);
  };
  useEffect(() => {
    fetchPlanHistoryWithUserIDData();
  }, []);

  let history = useHistory();

  const fetchLocationData = async () => {
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/dashboard/getLocations`)
    );
    console.log(resp);
    setLocationData(resp);
  };
  useEffect(() => {
    fetchLocationData();
  }, []);

  const fetchUserTypeData = async () => {
    const resp = await trackPromise(
      API.get(
        userTypeStartDate == null && userTypeEndDate == null
          ? `admin-module/api/v1/dashboard/getAllUsers`
          : `admin-module/api/v1/dashboard/getAllUsers?startDate=${moment(
            userTypeStartDate
          ).format("DD-MMM-YYYY")}&endDate=${moment(userTypeEndDate).format(
            "DD-MMM-YYYY"
          )}`
      )
    );
    console.log(resp);
    setUserTypeData(resp);
  };
  useEffect(() => {
    fetchUserTypeData();
  }, [userTypeEndDate]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const refresh = () => {
    setStartDate(null);
    setEndDate(null);
    document.getElementById("calender").style.display = "none";
    document.getElementById("calendericon").style.display = "block";
    document.getElementById("calendericon").style.justifyContent = "center";
    document.getElementById("calendericon").style.textAlign = "center";
  };

  const onUserTypeChange = (dates) => {
    const [start, end] = dates;
    setUserTypeStartDate(start);
    setUserTypeEndDate(end);
  };

  const blank = () => {
    setUserTypeStartDate(null);
    setUserTypeEndDate(null);
    document.getElementById("usercalender").style.display = "none";
    document.getElementById("usercalendericon").style.display = "block";
    document.getElementById("usercalendericon").style.justifyContent = "center";
    document.getElementById("usercalendericon").style.textAlign = "center";
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  };

  const show = () => {
    document.getElementById("calender").style.display = "block";
    document.getElementById("calendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }
  const shows = () => {
    document.getElementById("usercalender").style.display = "block";
    document.getElementById("usercalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  function off() {
    setCalenderOpen(false);
    document.getElementById("overlay").style.display = "none";
    refresh();
    blank();
  }

  const routeComp = () => {
    setOpenManageUserTable(true)
  }


  const [upPlan, setUpPlan] = useState(false)

  const dataVal = true;

  const planHandler = () => {
    setUpPlan(true);
    history.push({
      pathname: "/app/subscription",
      state: dataVal
    });
  }

  return (
    <>
      {openManageUserTable == true ? <ManageUsers inDashboard={setOpenManageUserTable} /> :
        <div className="body">
          <div id="overlay" onClick={(e) => off(e)}></div>
          {expandSubscription && (
            <ExpandSubscription closeModal={setExpandSubscription} />
          )}
          {expandBusLocation && (
            <ExpandBusLocation closeModal={setExpandBusLocation} />
          )}
          {expandUserCountGraph && (
            <ExpandUserCountGraph closeModal={setExpandUserCountGraph} />
          )}
          {expandUserPieChart && (
            <ExpandUserPieChart closeModal={setExpandUserPieChart} />
          )}
          
          {
            auth.getUserInfo().role == "Sub Admin" ? (
              <>
              <div className="firstRow">
            
            <div
              className="container-box"
              style={{ height: "330px", width: "55%", padding: "10px" , marginRight:"10px"}}
            >
              <div className="header">
                <div>
                  <h5 style={{ fontSize: "18px" }}>Locations</h5>
                </div>
                {
                  auth.getUserInfo().role == "Sub Admin" ? "" :
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => history.push("/app/organization")}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight />
                      </div>
                    </div>
                }
              </div>
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div
                  className="bussLoc-box"
                  style={{ width: "50%", marginRight: "5px", padding: "5px" ,height:"160px"}}
                >
                  <div className="header">
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      Production Location(s)
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {locationData.productionLocations}
                  </div>
                  {/* <div style={{ textAlign: "center" }} className="grey-heading">
                  Total Production Location{" "}
                </div> */}
                </div>
                <div
                  className="bussLoc-box"
                  style={{ width: "50%", padding: "5px", height:"160px"}}
                >
                  <div className="header">
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      Storage Location(s)
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {locationData.storageLocations}
                  </div>
                  {/* <div style={{ textAlign: "center" }} className="grey-heading">
                  Total Storage Location{" "}
                </div> */}
                </div>
              </div>
            </div>

            <div
              className="container-box"
              style={{ width: "43%", height: "330px", padding: "10px" }}
            >
              <div className="header">
                <div>
                  <h5 style={{ fontSize: "18px" }}>Users</h5>
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    onClick={blank}
                  >
                    <FaRedoAlt />
                  </div>
                  <span id="usercalendericon" style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "25px",
                    height: "23.4px",
                    border: "1px Solid #808080",
                    color: "#808080",
                    borderRadius: "5px",
                    marginRight: "5px",
                  }} onClick={shows}>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ color: "#808080" }}
                    />
                  </span>
                  <label id="usercalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                    <Space style={{ width: "235px" }} direction="vertical" size={10}>
                      <RangePicker
                        startDate={userTypeStartDate}
                        endDate={userTypeEndDate}
                        value={[userTypeStartDate, userTypeEndDate]}
                        onChange={onUserTypeChange}
                        format="DD-MM-YYYY"
                        size="small"
                      />
                    </Space>

                  </label> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      cursor: "pointer"
                    }}
                  >
                    <BsArrowUpRight onClick={() => routeComp()} />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <span className="grey-heading">Total Users</span>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {userTypeData.totalUsers}
                </div>
              </div>
              <div>
                <UserPieChart userTypeData={userTypeData} />
              </div>
            </div>


          </div>

          <div className="secondRow">
            <div
              className="container-box"
              style={{
                width: "100%",
                height: "fit-content",
                marginRight: "10px",
                padding: "10px",
              }}
            >
              <div className="header">
                <div>
                  <h5 style={{ fontSize: "18px" }}>User Counts</h5>
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    onClick={refresh}
                  >
                    <FaRedoAlt />
                  </div>
                  <span id="calendericon" style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "25px",
                    height: "23.4px",
                    border: "1px Solid #808080",
                    color: "#808080",
                    borderRadius: "5px",
                    marginRight: "5px",
                  }} onClick={show}>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ color: "#808080" }}
                    />
                  </span>
                  <label id="calender" style={{ width: "235px", height: " 2px", marginRight: "5px", display: "none" }}>
                    <DatePicker
                    className="dashboardDaterange"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onChange}
                  />

                    <Space style={{ width: "235px" }} direction="vertical" size={10}>
                      <RangePicker
                        startDate={startDate}
                        endDate={endDate}
                        value={[startDate, endDate]}
                        onChange={onChange}
                        format="DD-MM-YYYY"
                        size="small"
                      />
                    </Space>

                    <span style={{ position: "relative", marginLeft: "-9px" }}>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ color: "#808080" }}
                    />
                  </span>
                  </label> */}
                  {/* {startDate == null && endDate == null ? "" : <span style={{ fontSize: '13px', fontWeight: 'bold', margin: '3px 5px' }} >{" "}{moment(startDate).format('DD-MM-YYYY/HH:mm') + " - " + moment(endDate).format('DD-MM-YYYY/HH:mm')}{" "}</span>} */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <BsArrowUpRight onClick={() => routeComp()} />
                  </div>
                </div>
              </div>
              <br />
              <div style={{ overflow: "auto" }}>
                <UserCountBarChart startDate={startDate} endDate={endDate} />
              </div>
            </div>

          </div>
              </>
            )
            :(
              <>
              <div className="firstRow">
            <div
              className="container-box"
              style={{
                height: "190px",
                width: "75%",
                marginRight: "10px",
                padding: "10px",
              }}
            >
              <div className="header">
                <div>
                  <h5 style={{ fontSize: "18px" }}>Subscription</h5>
                </div>
                {
                  auth.getUserInfo().role == "Sub Admin" ? "" :
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => history.push("/app/subscription")}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight />
                      </div>
                    </div>
                }
              </div>
              <table style={{ width: "100%", marginTop: "10px" }}>
                <tr>
                  <td className="grey-heading">Plan Name</td>
                  <td className="grey-heading">Subscription Type</td>
                  <td className="grey-heading">Last Renew Date</td>
                  <td className="grey-heading">Subscription Expiry</td>
                </tr>
                <tr>
                  <td className='black-heading'>{currentPlan[0]?.planName}</td>
                  <td className='black-heading'>{auth.getUserInfo()?.planType}</td>
                  <td className='black-heading'>-</td>
                  <td className='red-heading'>{moment(currentPlan[0]?.validTill).format("DD-MMM-YYYY/HH:mm")}</td>
                </tr>
              </table>

              <div id="wrapper">
                {/* <div style={{ width: "48%" }}>
                  <div className="grey-heading">10 of 20 User</div>
                  <div
                    className="dashboardProgressBar"
                    style={{ marginTop: "10px" }}
                  >
                    <ProgressBar>
                      <ProgressBar now={100} style={{ background: "#3442CF" }} />
                      <ProgressBar now={60} style={{ background: "#CDCDCD" }} />
                    </ProgressBar>
                  </div>
                </div> */}
                <div style={{ display: "flex", marginTop: "11px" }}>
                  <button className="whiteBgButton" onClick={() => setRenew(true)}>Renew Plan</button>
                  <button className="colorBgButton" onClick={planHandler}>Upgrade Plan</button>
                </div>
              </div>
            </div>
            <div
              className="container-box"
              style={{ height: "190px", width: "50%", padding: "10px" }}
            >
              <div className="header">
                <div>
                  <h5 style={{ fontSize: "18px" }}>Locations</h5>
                </div>
                {
                  auth.getUserInfo().role == "Sub Admin" ? "" :
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => history.push("/app/organization")}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "23.4px",
                          border: "1px Solid #808080",
                          color: "#808080",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <BsArrowUpRight />
                      </div>
                    </div>
                }
              </div>
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div
                  className="bussLoc-box"
                  style={{ width: "50%", marginRight: "5px", padding: "5px" }}
                >
                  <div className="header">
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      Production Location(s)
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {locationData.productionLocations}
                  </div>
                  {/* <div style={{ textAlign: "center" }} className="grey-heading">
                  Total Production Location{" "}
                </div> */}
                </div>
                <div
                  className="bussLoc-box"
                  style={{ width: "50%", padding: "5px" }}
                >
                  <div className="header">
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      Storage Location(s)
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {locationData.storageLocations}
                  </div>
                  {/* <div style={{ textAlign: "center" }} className="grey-heading">
                  Total Storage Location{" "}
                </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="secondRow">
            <div
              className="container-box"
              style={{
                width: "60%",
                height: "330px",
                marginRight: "10px",
                padding: "10px",
              }}
            >
              <div className="header">
                <div>
                  <h5 style={{ fontSize: "18px" }}>User Counts</h5>
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    onClick={refresh}
                  >
                    <FaRedoAlt />
                  </div>
                  <span id="calendericon" style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "25px",
                    height: "23.4px",
                    border: "1px Solid #808080",
                    color: "#808080",
                    borderRadius: "5px",
                    marginRight: "5px",
                  }} onClick={show}>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ color: "#808080" }}
                    />
                  </span>
                  <label id="calender" style={{ width: "235px", height: " 2px", marginRight: "5px", display: "none" }}>
                    <DatePicker
                    className="dashboardDaterange"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onChange}
                  />

                    <Space style={{ width: "235px" }} direction="vertical" size={10}>
                      <RangePicker
                        startDate={startDate}
                        endDate={endDate}
                        value={[startDate, endDate]}
                        onChange={onChange}
                        format="DD-MM-YYYY"
                        size="small"
                      />
                    </Space>

                    <span style={{ position: "relative", marginLeft: "-9px" }}>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ color: "#808080" }}
                    />
                  </span>
                  </label> */}
                  {/* {startDate == null && endDate == null ? "" : <span style={{ fontSize: '13px', fontWeight: 'bold', margin: '3px 5px' }} >{" "}{moment(startDate).format('DD-MM-YYYY/HH:mm') + " - " + moment(endDate).format('DD-MM-YYYY/HH:mm')}{" "}</span>} */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <BsArrowUpRight onClick={() => routeComp()} />
                  </div>
                </div>
              </div>
              <br />
              <div style={{ overflow: "auto" }}>
                <UserCountBarChart startDate={startDate} endDate={endDate} />
              </div>
            </div>
            <div
              className="container-box"
              style={{ width: "40%", height: "330px", padding: "10px" }}
            >
              <div className="header">
                <div>
                  <h5 style={{ fontSize: "18px" }}>Users</h5>
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    onClick={blank}
                  >
                    <FaRedoAlt />
                  </div>
                  <span id="usercalendericon" style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "25px",
                    height: "23.4px",
                    border: "1px Solid #808080",
                    color: "#808080",
                    borderRadius: "5px",
                    marginRight: "5px",
                  }} onClick={shows}>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ color: "#808080" }}
                    />
                  </span>
                  <label id="usercalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                    <Space style={{ width: "235px" }} direction="vertical" size={10}>
                      <RangePicker
                        startDate={userTypeStartDate}
                        endDate={userTypeEndDate}
                        value={[userTypeStartDate, userTypeEndDate]}
                        onChange={onUserTypeChange}
                        format="DD-MM-YYYY"
                        size="small"
                      />
                    </Space>

                  </label> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      cursor: "pointer"
                    }}
                  >
                    <BsArrowUpRight onClick={() => routeComp()} />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <span className="grey-heading">Total Users</span>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {userTypeData.totalUsers}
                </div>
              </div>
              <div>
                <UserPieChart userTypeData={userTypeData} />
              </div>
            </div>
          </div>
              </>
            )
          }
        </div>
      }

      {
        upPlan && <Tab upPlan="true" />
      }

      {
        renew && <ConfirmationModal closeModal={setRenew} />
      }
    </>
  );
}

export default SuperAdminDashboard;
