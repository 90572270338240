import React, { useState } from "react";
import"../../work-order/workorder.scss"
import moment from "moment";

const PendingInvDetails = ({ close, rowdata }) => {  
    console.log(rowdata);
  return (
    <>
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "521px", height: "337px", padding: "15px" }}
      >
        <div className="header">
          <h3>View Details</h3>
        </div>

        <div className="body" style={{height:"300px",marginTop:"20px",flexDirection:"column"}}>
            <span>Request ID:{rowdata.requestID}</span>
            <span>Request Date:{moment(rowdata.requestDate
).format("DD-MMM-YYYY,HH:mm")}</span>
            <div 
            style={{display:"flex",
                   flexDirection:"column",width:"100%",
                    border:"2px solid #EBEBEB",borderRadius:"5px",
                    padding:"10px",marginTop:"15px"
                    }}>
                <span style={{marginBottom:"3px"}}>Hold By Production</span>
                <span style={{marginBottom:"3px"}}>Hold Date: {moment(rowdata.holdDate
).format("DD-MMM-YYYY,HH:mm")}</span>
            </div>
       
        </div>
        <br />
        <div className="footer">
          <button onClick={() => close(false)}>Back</button>
        </div>
      </div>
    </div>
    </>
  );
};

export default PendingInvDetails;