import React, { useEffect, useState } from "react";
import { CancelOutlined } from "@material-ui/icons";
import ReactToPrint from "react-to-print";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import auth from "../../../../utils/auth";

const ViewTerms = ({ rowdata, closeModal }) => {
  console.log("rowdata-->", rowdata);

  let componentRef = null;



  const [showData, setShowData] = useState([]);
  console.log("showData-->",showData)

  const getCompanyDetails = async () => {
    const resp = await trackPromise(
      API.get(
        `admin-module/api/v1/users/getDataWithTenantID?tenantID=${auth.getUserInfo().tenantID}`
      )
    );
    console.log("resp-->", resp);
    setShowData(resp);
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);


  console.log("showData-->",showData)


  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "65%", height: "502px" }}>
        <div className="header" style={{ justifyContent: "space-between" }}>
          <h3>View Terms</h3>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <button
                    style={{
                      color: "#0A18A3",
                      margin: "1px",
                      height: "27px",
                      width: "90px",
                      border: "none",
                    }}
                  >
                    Download
                  </button>
                );
              }}
              content={() => componentRef}
              documentTitle="View Terms"
              pageStyle="Print"
            />
          </div>
        </div>
        <div style={{ width: "100%", height: "600px", overflowY: "auto" }}>
          <div
            className="body"
            style={{
              width: " 340%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              pageBreakBefore: "always",
            }}
            ref={(el) => (componentRef = el)}
          >
            <div style={{ width: "100%"}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "29%",
                  marginTop:"9px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span>{showData.businessName}</span>
                  <span>{showData?.locations?.map((item)=>item?.locationType=="Business"? item?.address1+", " +item?.city+", " +item?.state+", " +item?.country:"")}</span>
                  <span>{showData?.locations?.map((item)=>item?.locationType=="Business"?  `Ph. : ${item?.businessContactNo}`:"")}</span>
                 
                </div>
              </div>

             
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "29%",
                  color: "#0A18A3",
                  textDecoration: "underline",
                  fontSize:"20px",
                  marginBottom:"6px"
                }}
              >
              {
                rowdata?.serviceManualType=="Vehicle Warranty Terms" ? "Vehicle Warranty Terms & Conditions":
                rowdata?.serviceManualType=="Spare Warranty Terms" ? "Spare Warranty Terms & Conditions":
                rowdata?.serviceManualType=="Vehicle Service Terms" ? "Vehicle Service Terms & Conditions":
                rowdata?.serviceManualType=="PROC. PO Terms" ? "PROC. PO Terms":
                rowdata?.serviceManualType=="Vehicle PO Terms" ? "Vehicle PO Terms":
                rowdata?.serviceManualType=="Spares PO Terms" ? "Spares PO Terms":
                rowdata?.serviceManualType=="Vehicle Invoice Terms" ? "Vehicle Invoice Terms":
                rowdata?.serviceManualType=="Spares Invoice Terms" ? "Spares Invoice Terms":
                rowdata?.serviceManualType=="Vehicle Booking Terms" ? "Vehicle Booking Terms":
                rowdata?.serviceManualType=="Vehicle Sales Terms" ? "Vehicle Sales Terms":
                rowdata?.serviceManualType=="Credit Note Terms" ? "Credit Note Terms":""
              }
              
              </div>
              

              <div
                dangerouslySetInnerHTML={{ __html: rowdata?.serviceManualTerms }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  padding: "10px",
                  width: "29%",
                  border:"1px solid grey",
                  overflow:"scroll",
                  background:"white",
                }}
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            {" "}
            <CancelOutlined /> Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewTerms;
