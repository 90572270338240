import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import Users from './UserComponent/Users'
// import Inventory from './InventoryComponent/Inventory'
// import Sales from './Sales/Sales'
// import Returns from './Returns/Returns'
// import ServiceJobs from './ServiceJobs/ServiceJobs'

const Users = React.lazy(() => import('./UserComponent/Users'))
const Inventory = React.lazy(() => import('./InventoryComponent/Inventory'))
const Sales = React.lazy(() => import('./Sales/Sales'))
const Returns = React.lazy(() => import('./Returns/Returns'))
const ServiceJobs = React.lazy(() => import('./ServiceJobs/ServiceJobs'))
const ServiceCenterReport = React.lazy(() => import('./ServiceCenterReport'))


const tabs = [
  {
    id: "DEM5T1",
    label: TabLabels.USERS,
    Component: Users
  },
  {
    id: "DEM5T2",
    label: TabLabels.INVENTORY,
    Component: Inventory
  },
  {
    id: "DEM5T3",
    label: TabLabels.SALES,
    Component: Sales
  },
  {
    id: "DEM5T4",
    label: TabLabels.RETURNS,
    Component: Returns
  },
  {
    id: "DEM5T5",
    label: TabLabels.SERVICE_JOBS,
    Component: ServiceJobs
  },
  {
    id: "DEM5T6",
    label: TabLabels.SALES_REPORT,
    Component: ServiceCenterReport
  }
];

const ServiceCenter = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default ServiceCenter;
