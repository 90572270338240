import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import PaymentsTabs from "./Payments/PaymentsTabs";
// import DealerPurchaseInvoiceTabs from "./PurchaseInvoice/PurchaseInvoiceTabs";
// import DealerSalesInvoiceTabs from "./SalesInvoice/DealerSalesInvoiceTabs";

const DealerPurchaseInvoiceTabs = React.lazy(()=>import("./PurchaseInvoice/PurchaseInvoiceTabs"))
const DealerSalesInvoiceTabs = React.lazy(()=>import("./SalesInvoice/DealerSalesInvoiceTabs"))
const PaymentsTabs = React.lazy(()=>import("./Payments/PaymentsTabs"))


const tabs = [
  {
    id:"DEM6T1",
    label: TabLabels.PURCHASE_INVOICE,
    Component: DealerPurchaseInvoiceTabs
  },
  {
    id:"DEM6T2",
    label: TabLabels.SALES_INVOICE,
    Component: DealerSalesInvoiceTabs
  },
  {
    id:"DEM6T3",
    label: TabLabels.PAYMENTS,
    Component: PaymentsTabs
  }
];

const DealerInvoicePaymentTabs = () => {
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
  );
};

export default DealerInvoicePaymentTabs;
