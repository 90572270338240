import React, { useEffect, useState } from "react";
import "../QcDashboard/styles/main.scss";
import { BsArrowUpRight, BsTable } from "react-icons/bs";
import ZonesAreasDash from "./ZonesAreasDash";
import ManageZonesTab from "../zoneManagement/manageZonesTab";
import OprSalesUserCard from "./OprSalesUserCard";
import OprBusinessEntityCard from "./OprBusinessEntityCard";
import ManageSalesUser from "../SalesUser/Component/ManageSalesUser";
import OprVehiclesCard from "./OprVehiclesCard";
import { useHistory } from "react-router-dom";
import OperationSpares from "../VehicleAndSpares/Component/OperationsSpares";
import OprSparesCard from "./oprSparesCard";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import ManagingBusinessEntities from "../OperationBusinessEntities/Components/ManagingBusinessEntities";

function OperationsDashboard() {
  const [openZonesTable, setOpenZonesTable] = useState(false);
  const [openAreasTable, setOpenAreasTable] = useState(false);
  const [openSparesTable, setOpenSparesTable] = useState(false);

  const [showroomInvData, setShowroomInvData] = useState({});
  const [serviceInvData, setServiceInvData] = useState({});
  const [showroomSalesData, setShowroomSalesData] = useState({});
  const [serviceSalesData, setServiceSalesData] = useState({});

  const fetchData = async () => {
    const resp = await trackPromise(API.get(`operations-module/api/v1/dashboard/getShoroomInventory`));
    setShowroomInvData(resp);
    const resp2 = await trackPromise(API.get(`operations-module/api/v1/dashboard/getServiceCenterInventory`));
    setServiceInvData(resp2);
    const resp3 = await trackPromise(API.get(`operations-module/api/v1/dashboard/getShowroomSales`));
    setShowroomSalesData(resp3);
    // const resp4 = await trackPromise(API.get(`operations-module/api/v1/dashboard/getServiceCenterSales`));
    // setServiceSalesData(resp4);
  };
  useEffect(() => {
    fetchData();
  }, []);

  let history = useHistory();
  return (
    <>
      {openZonesTable == true ? (
        <ManageZonesTab inDashboard={setOpenZonesTable} />
      ) : openAreasTable == true ? (
        <ManageSalesUser inDashboard={setOpenAreasTable} />
      ) :
        openSparesTable == true ? (
          <ManagingBusinessEntities inDashboard={setOpenSparesTable} />
        ) :
          (
            <>
              <div id="overlay" onClick={(e) => off(e)}></div>
              <div className="dashboardBody">
                <div className="firstRow">
                  <div
                    className="container-box"
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <div className="header">
                      <div>
                        <h5 style={{ fontSize: "18px" }}>Zones & Areas</h5>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer"
                          }}
                        >
                          <BsArrowUpRight onClick={() => setOpenZonesTable(true)} />
                        </div>
                      </div>
                    </div>
                    <div>
                      <ZonesAreasDash />
                    </div>
                  </div>
                </div>

                <div className="secondRow">
                  <div className="container-box"
                    style={{ width: "50%", padding: "10px" }}
                  >
                    <div className="header">
                      <div>
                        <h5 style={{ fontSize: "18px" }}>Sales User</h5>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer"
                          }}
                        >
                          <BsArrowUpRight onClick={() => setOpenAreasTable(true)} />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div style={{ overflow: "auto" }}>
                      <OprSalesUserCard />
                    </div>
                  </div>

                  <div className="container-box"
                    style={{ width: "50%", padding: "10px", marginLeft: "15px" }}
                  >
                    <div className="header">
                      <div>
                        <h5 style={{ fontSize: "18px" }}>Buisiness Entities</h5>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer"
                          }}
                        >
                          <BsArrowUpRight onClick={() => setOpenSparesTable(true)} />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div style={{ overflow: "auto" }}>
                      <OprBusinessEntityCard />
                    </div>
                  </div>
                </div>


                {/* third row */}

                {/* <div className="secondRow">
                  <div className="container-box"
                    style={{ width: "50%", padding: "10px", height: "280px" }}
                  >
                    <div className="header">
                      <div>
                        <h5 style={{ fontSize: "18px" }}>Vehicles</h5>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer"
                          }}
                        >
                          <BsArrowUpRight onClick={() => history.push("/app/VehicleandSpares")} />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div style={{ overflow: "auto" }}>
                      <OprVehiclesCard user="opr" />
                    </div>
                  </div>

                  <div className="container-box"
                    style={{ width: "50%", padding: "10px", marginLeft: "15px", height: "280px" }}
                  >
                    <div className="header">
                      <div>
                        <h5 style={{ fontSize: "18px" }}>Spares</h5>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer"
                          }}
                        >
                          <BsArrowUpRight onClick={() => setOpenSparesTable(true)} />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div style={{ overflow: "auto" }}>
                      <OprSparesCard />
                    </div>
                  </div>
                </div> */}


                {/* fourth row */}
                <div className="secondRow">
                  <div className="container-box"
                    style={{ width: "50%", padding: "10px", height: "190px" }}
                  >
                    <div className="header">
                      <div>
                        <h5 style={{ fontSize: "18px" }}>Showroom Inventory</h5>
                      </div>
                      {/* <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer"
                          }}
                        >
                          <BsArrowUpRight />
                        </div>
                      </div> */}
                    </div>
                    <br />
                    <div style={{ marginTop: "-24px", padding: "10px", display: "flex" }}>
                      <div className="bussLoc-box" style={{ padding: "5px", width: "50%" }}>
                        <div className="header">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#3442CF",
                            }}
                          >
                            Total Available Units
                          </span>
                        </div>
                        <div style={{ display: "flex", padding: "6px" }}>
                          <div>
                            <div
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {showroomInvData.totalVehicles}
                            </div>
                            <div
                              style={{ textAlign: "center" }}
                              className="grey-heading"
                            >
                              Total Units
                            </div>
                          </div>


                        </div>
                      </div>

                      <div className="bussLoc-box" style={{ padding: "5px", width: "50%", marginLeft: "10px" }}>
                        <div className="header">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#3442CF",
                            }}
                          >
                            Total Stock Value
                          </span>
                        </div>
                        <div style={{ display: "flex", padding: "6px" }}>
                          <div>
                            <div
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {showroomInvData.totalUnitPrice == null ? 0 : showroomInvData.totalUnitPrice}
                            </div>
                            <div
                              style={{ textAlign: "center" }}
                              className="grey-heading"
                            >
                              Total Stock Value
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-box"
                    style={{ width: "50%", padding: "10px", height: "190px", marginLeft: "15px" }}
                  >
                    <div className="header">
                      <div>
                        <h5 style={{ fontSize: "18px" }}>Service Center Inventory</h5>
                      </div>
                      {/* <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer"
                          }}
                        >
                          <BsArrowUpRight />
                        </div>
                      </div> */}
                    </div>
                    <br />
                    <div style={{ marginTop: "-24px", padding: "10px", display: "flex" }}>
                      <div className="bussLoc-box" style={{ padding: "5px", width: "50%" }}>
                        <div className="header">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#3442CF",
                            }}
                          >
                            Total Available Units
                          </span>
                        </div>
                        <div style={{ display: "flex", padding: "6px" }}>
                          <div>
                            <div
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {serviceInvData.totalSpare}
                            </div>
                            <div
                              style={{ textAlign: "center" }}
                              className="grey-heading"
                            >
                              Total Units
                            </div>
                          </div>


                        </div>
                      </div>

                      <div className="bussLoc-box" style={{ padding: "5px", width: "50%", marginLeft: "10px" }}>
                        <div className="header">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#3442CF",
                            }}
                          >
                            Total Stock Value
                          </span>
                        </div>
                        <div style={{ display: "flex", padding: "6px" }}>
                          <div>
                            <div
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {serviceInvData.totalUnitPrice == null ? 0 : serviceInvData.totalUnitPrice}
                            </div>
                            <div
                              style={{ textAlign: "center" }}
                              className="grey-heading"
                            >
                              Total Stock Value
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* fifth row */}
                <div className="secondRow">
                  <div className="container-box"
                    style={{ width: "50%", padding: "10px", height: "190px" }}
                  >
                    <div className="header">
                      <div>
                        <h5 style={{ fontSize: "18px" }}>Showroom Sales</h5>
                      </div>
                      {/* <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer"
                          }}
                        >
                          <BsArrowUpRight />
                        </div>
                      </div> */}
                    </div>
                    <br />
                    <div style={{ marginTop: "-24px", padding: "10px", display: "flex" }}>
                      <div className="bussLoc-box" style={{ padding: "5px", width: "50%" }}>
                        <div className="header">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#3442CF",
                            }}
                          >
                            Total Sold Units
                          </span>
                        </div>
                        <div style={{ display: "flex", padding: "6px" }}>
                          <div>
                            <div
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {showroomSalesData.totalVehicle}
                            </div>
                            <div
                              style={{ textAlign: "center" }}
                              className="grey-heading"
                            >
                              Total Sold Units
                            </div>
                          </div>


                        </div>
                      </div>

                      <div className="bussLoc-box" style={{ padding: "5px", width: "50%", marginLeft: "10px" }}>
                        <div className="header">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#3442CF",
                            }}
                          >
                            Total Sale Value
                          </span>
                        </div>
                        <div style={{ display: "flex", padding: "6px" }}>
                          <div>
                            <div
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {showroomSalesData.totalUnitPrice == null ? 0 : showroomSalesData.totalUnitPrice}
                            </div>
                            <div
                              style={{ textAlign: "center" }}
                              className="grey-heading"
                            >
                              Total Sale Value
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-box"
                    style={{ width: "50%", padding: "10px", height: "190px", marginLeft: "15px" }}
                  >
                    <div className="header">
                      <div>
                        <h5 style={{ fontSize: "18px" }}>Service Center Sales</h5>
                      </div>
                      {/* <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "23.4px",
                            border: "1px Solid #808080",
                            color: "#808080",
                            borderRadius: "5px",
                            cursor: "pointer"
                          }}
                        >
                          <BsArrowUpRight />
                        </div>
                      </div> */}
                    </div>
                    <br />
                    <div style={{ marginTop: "-24px", padding: "10px", display: "flex" }}>
                      <div className="bussLoc-box" style={{ padding: "5px", width: "50%" }}>
                        <div className="header">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#3442CF",
                            }}
                          >
                            Total Spare Sold
                          </span>
                        </div>
                        <div style={{ display: "flex", padding: "6px" }}>
                          <div>
                            <div
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {serviceSalesData.totalVehicle}
                            </div>
                            <div
                              style={{ textAlign: "center" }}
                              className="grey-heading"
                            >
                              Total Spare Sold
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bussLoc-box" style={{ padding: "5px", width: "50%", marginLeft: "10px" }}>
                        <div className="header">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#3442CF",
                            }}
                          >
                            Total Sale Value
                          </span>
                        </div>
                        <div style={{ display: "flex", padding: "6px" }}>
                          <div>
                            <div
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {serviceSalesData.totalUnitPrice == null ? 0 : serviceSalesData.totalUnitPrice}
                            </div>
                            <div
                              style={{ textAlign: "center" }}
                              className="grey-heading"
                            >
                              Total Sale Value
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </>
          )
      }
    </>
  )
}

export default OperationsDashboard