import React, { useState } from 'react';
import './superAdminDashboard.scss';
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import "../QcDashboard/styles/main.scss";
import { FaBackspace, FaExpandAlt, FaExpandArrowsAlt } from 'react-icons/fa';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';

const ExpandBusLocation = ({ closeModal }) => {
    return (
        <div className="container1">
            <div className="modalContainer" style={{ width: '495px', height: "270px" }}>
                <div className='container-box' style={{marginTop:"35px",padding:"10px"}}>
                    <div className='header' style={{ justifyContent: "space-between" }}>
                        <div><h5 style={{ fontSize: "18px" }}>Business Location</h5></div>
                        <div style={{ display: "flex" }}>
                            <div onClick={() => closeModal(false)} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "25px", height: "25px", border: "1px Solid #808080", color: "#808080", borderRadius: "5px",cursor:"pointer" }}>
                                <FaExpandArrowsAlt />
                            </div>
                            <button className="action-btn" style={{ height: "25px", color: "#808080" }}>
                                <FontAwesomeIcon className='fa-lg' icon={faEllipsisV} />
                            </button>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <div className="bussLoc-box" style={{ width: "50%", marginRight: "5px", padding: "5px" }}>
                            <div className='header'>
                                <span style={{ fontSize: "14px", fontWeight: "500" }}>Production Location</span>
                            </div>
                            <div style={{ fontSize: "20px", fontWeight: "600", textAlign: "center" }}>05</div>
                            <div style={{ textAlign: "center" }} className='grey-heading'>Total Production Location </div>
                        </div>
                        <div className="bussLoc-box" style={{ width: "50%", padding: "5px" }}>
                            <div className='header'>
                                <span style={{ fontSize: "14px", fontWeight: "500" }}>Inventory Location</span>
                            </div>
                            <div style={{ fontSize: "20px", fontWeight: "600", textAlign: "center" }}>08</div>
                            <div style={{ textAlign: "center" }} className='grey-heading'>Total Inventory Location </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <button onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                </div>
            </div>
        </div>
    )
}

export default ExpandBusLocation