import React from "react";
import { Tabs } from "react-simple-tabs-component";

import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
import Template from "./Component/Template";
import { TabLabels } from "../../utils/app-constants";



const tabs = [
  {
    id: "OPM7T1",
    label: "Templates",
    Component: Template,
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

const Templates = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default Templates;
