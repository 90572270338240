import React from 'react';
import moment from "moment";


const ViewHistory = ({editData ,closeModal}) => {

  console.log(editData)
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "615px", height: "450px", padding: "15px" }}
      >
        <div className="header">
          <h3>View History</h3>
        </div>
        <br />
        <div style={{display:'flex', justifyContent:"space-between"}}>
          <h6 style={{fontSize:"14px" }}>Vehicle Segment : {editData.vehicleSegment}</h6>
          <h6 style={{fontSize:"14px" }}>Vehicle Variant : {editData.vehicleVariant}</h6>
        </div>
        <div style={{display:'flex', justifyContent:"space-between"}}>
          <h6 style={{fontSize:"14px" }}>Vehicle Model : {editData.vehicleModel}</h6>
          <h6 style={{fontSize:"14px" }}>HSN/SAC Code : {editData.HSNSACcode}</h6>
        </div>
        <br />
        <div style={{height:'400px', overflow:'scroll'}}>

        <ul className='OH_Ul'>
          {editData.action.map((item,index)=>{
            return(
              <>
              {item.status == "Deleted" ? 
               <li className='OH_list fullyClosure_list'>
               <div className='OH_list_div'>
                 <span className='OH_list_btn fullyClosure'>Deleted</span>
                 <span>Date: {item.status == "Deleted" ? moment(item.actionDate).format("DD-MMM-YYYY / HH:mm"):""} </span>
                 <span>Reason: {item.reason}</span>
               </div>
             </li>:
             item.status == "Deactivated" ?
             <li className='OH_list partiallyClosure_list'>
             <div className='OH_list_div'>
               <span className='OH_list_btn partiallyClosure'>Deactivated</span>
               <span>Date: {moment(item.actionDate).format("DD-MMM-YYYY / HH:mm")}</span>
             <span>Reason: {item.reason}</span>
             </div>
         </li> :
         item.status == "Reactivated" ?
         <li className='OH_list grnReceived_list'>
         <div className='OH_list_div'>
           <span className='OH_list_btn grnReceived'>Reactivated</span>
           <span>Date:  {item.status == "Reactivated" ? moment(item.actionDate).format("DD-MMM-YYYY / HH:mm"):""}</span>
         <span>Reason: {item.reason}</span>
         </div>
        </li> :
       null


            }
                <li className='OH_list poGenerated_list'>
           <div className='OH_list_div'>
             <span className='OH_list_btn poGenerated'>HSN/SAC Added</span>
             <span>Date:  {moment(editData.activeDate).format("DD-MMM-YYYY / HH:mm")}</span>
           <span></span>
           </div>
         </li> 
             
                </>
     
            )
          })}
          </ul>
        </div>

        <div className="footer">
          <button onClick={() => closeModal(false)}>Back</button>
        </div>
      </div>
    </div>
  )
}

export default ViewHistory