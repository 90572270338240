import React, { useEffect, useState } from "react";
import {
  KeyboardBackspace,
} from "@material-ui/icons";
import GINno from "./GINno";
import CR_POno from "./CR_POno";
import CR_MR_ReturnQty from "./CR_MR_ReturnQty";
import moment from "moment";
import ViewSpecs from "../../inventory-inv/action/ViewSpecs";
import FullAssignment from "../../inventory-inv/action/FullAssignment";
import SpareReturnQty from "./spareReturnQty";
import RequestUpdate from "./requestUpdate";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { errorNotification } from "../../../utils/notifications";
import RequestToStartVerification from "./requestToStartVerification";

const FinishedGoodsViewAct = ({ closeModal, rowData }) => {
  const [openFA, setOpenFA] = useState(false);
  const [openReqUpdate, setOpenReqUpdate] = useState(false);
  const [goodsData, setGoodsData] = useState([]);
  const [viewQty, setViewQty] = useState();

  const fetchGoodsDetails = async () => {
    const resp = await trackPromise(API.get(`inventory-module/api/v1/inventorygoodsverification/getFinishedGoods?requestID=${rowData.requestID}`));
    console.log(resp);
    resp.success == false ? errorNotification(resp.message) : setGoodsData(resp);
  };
  useEffect(() => {
    fetchGoodsDetails()
  }, []);

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "980px", height: "425px", padding: '15px' }}
      >
        <div className="header">
          <h3>View Details</h3>
        </div>

        <div
          className="body"
          style={{ width: '100%', display: "flex", fontSize: "14px" }}
        >

          <section
            style={{
              width: '100%',
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0"
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested ID: {rowData.requestID}</span>
              <span>WO ID: {rowData.orderID}<span style={{ color: "#0a18a3" }}></span></span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested Category: {rowData.requestCategory}</span>
              <span>Added Date : {moment(rowData.requestDate).format("DD-MMM-YYYY/HH:mm")}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Request Type: {rowData.requestType}</span>
              <span>Added Qty : {goodsData.length}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Requested By: {rowData.requestedBy}<span style={{ color: "#0a18a3" }}></span></span>
              <span>Segment: {goodsData[0]?.vehicleSegment}</span>
            </div>



          </section>
          <div style={{ background: "lightgrey", width: "15%", marginBottom: "5px", borderRadius: "5px" }}>Category: {goodsData[0]?.vehicleCategory}</div>
          {rowData.status == "On Hold" || rowData.status == "Rejected" ?
            <>
              <div id="hold" style={{ display: 'flex', width: "100%", height: "38px" }}>
                <span id="holdDate">Hold Date: {"24/09/3012"}</span>
                <view id="line"></view>
                <span id="holdReason">Hold Reason: </span>
              </div></> : ""}
          <section style={{ width: '100%' }}>
            <div id='POVendorHeading'>
              <div style={{ width: '5%' }}>S.No.</div>
              <div style={{ width: '12%' }}>Model</div>
              <div style={{ width: '12%' }}>Variant</div>
              <div style={{ width: '12%' }}>Type</div>
              <div style={{ width: '10%' }}>Color</div>
              <div style={{ width: '15%' }}>VIN</div>
              <div style={{ width: '15%' }}>BIN</div>
              <div style={{ width: '15%' }}>Status</div>
            </div>
            <div>
              {goodsData.map((item, index) => (
                <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }}>
                  <div style={{ width: '5%' }}>{index + 1}</div>
                  <div style={{ width: '12%' }}>{item.vehicleModel}</div>
                  <div style={{ width: '12%' }}>{item.vehicleVariant}</div>
                  <div style={{ width: '12%' }}>{item.vehicleType}</div>
                  <div style={{ width: '10%' }}>{item.vehicleColor}</div>
                  <div style={{ width: '15%' }}>{item.VIN}</div>
                  <div style={{ width: '15%' }}>{item.BIN}</div>
                  <div className="deactive-btn" style={{ width: '15%' }}><span className="deactive-btn-font">Pending</span></div>
                </div>
              ))}
            </div>
          </section>

        </div>

        <div className="footer">
          <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
          {rowData.status == "On Hold" || rowData.status == "Rejected" ?
            <button
              style={{
                background: "#0A18A3",
                border: "1px solid #0A18A3",
                borderRadius: "3px",
                padding: "5px 15px",
                color: "white",
                width: '200px'
              }}
              onClick={() => setOpenReqUpdate(true)}
            >
              Request For Update
            </button>
            :
            <button
              style={{
                background: "#0A18A3",
                border: "1px solid #0A18A3",
                borderRadius: "3px",
                padding: "5px 15px",
                color: "white",
                width: '200px'
              }}
              onClick={() => setOpenFA(true)}
            >
              Reminder For Verification
            </button>
          }
          {openReqUpdate && <RequestUpdate closeModal={setOpenReqUpdate} tabName="finishedGoods"/>}
          {openFA && <RequestToStartVerification closeModal={setOpenFA} tabName="finishedGoods"/>}
        </div>
      </div>
    </div>
  );
};

export default FinishedGoodsViewAct;