import { ContactsOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import ShowMappedCities from "../../zoneManagement/ShowMappedCities";


const MappedStates = ({ close, rowdata, states, zone, areas, userType }) => {
  console.log();
  const [showCities, setShowCities] = useState(false);
  const [citiesData, setCitiesData] = useState([]);
  const [rowsData, setRowsData] = useState();


  let showMappedCities = (item, index, el) => {
    setShowCities(true);
    setCitiesData(el);
    setRowsData(item);
  };


  return (
    <>
      <div className="container1">
        {showCities && (
          <ShowMappedCities
            citiesData={citiesData}
            rowData={rowsData}
            closeModal={setShowCities}
          />
        )}
        <div
          className="modalContainer"
          style={{ width: "492px", height: "550px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Mappings</h3>
          </div>
          <br />
          <section
            style={{ display: "flex", flexDirection: "column", width: "100" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            ></div>
          </section>
          <br />
          <div className="body" style={{ height: "340px", marginTop: "-20%" }}>
            <div style={{ width: "100%", overflow: "auto", height: "340px" }}>
              <table className="viewcomp_tbl">
                <thead>
                  {userType == "Zonal Sales" ? (
                    <tr>
                      {/* <th style={{ width: "15%" }}>S.No.</th> */}
                      <th style={{ width: "40%" }}>Zone</th>
                      <th style={{ width: "45%" }}>Mapped State</th>
                    </tr>
                  ) : (
                    <tr>
                      {/* <th style={{ width: "10%" }}>S.No.</th> */}
                      <th style={{ width: "35%" }}>Mapped Area</th>
                      <th style={{ width: "35%" }}>Mapped State</th>
                      <th style={{ width: "30%" }}>Mapped Cities</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {userType == "Zonal Sales"
                    ? rowdata?.zones?.map((item, index) => {
                      return (
                        <>
                          <tr>
                            {/* <td style={{ width: "15%" }}>{index + 1}.</td> */}
                            <td style={{ width: "40%" }}>{item.zone}</td>
                            <td style={{ width: "45%" }}>
                              {item?.states?.map((el) => {
                                return (
                                  <ul
                                    style={{
                                      listStyleType: "none",
                                      paddingLeft: "0px",
                                    }}
                                  >
                                    <li>{el.stateName}</li>
                                  </ul>
                                );
                              })}
                            </td>
                          </tr>
                        </>
                      );
                    })
                    : areas.map((item, index) => {
                      return item?.states?.map((el) => {
                        return (
                          <>
                            <tr>
                              {/* <td style={{ width: "10%" }}>{index + 1}</td> */}
                              <td style={{ width: "35%" }}>{item.area}</td>
                              <td
                                style={{
                                  paddingLeft: "12px",
                                  width: "35%"
                                }}
                              >
                                {el?.stateName}
                              </td>
                              <td
                                onClick={() =>
                                  showMappedCities(item, index, el)
                                }
                                style={{
                                  color: "#0A18A3",
                                  cursor: "pointer",
                                  width: "30%",
                                }}
                              >
                                {" "}
                                {
                                  item?.cities?.filter((item1) => {
                                    return item1.stateId == el.stateId;
                                  })?.length
                                }
                              </td>
                            </tr>
                          </>
                        );
                      });
                    })}
                </tbody>
              </table>
            </div>
          </div>


          <br />
          <div className="footer">
            <button onClick={() => close(false)}>
              <KeyboardBackspace /> Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};


export default MappedStates;