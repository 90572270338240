import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import {
  successNotification,
  errorNotification,
} from "../../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import auth from "../../../../utils/auth";

const Edit = ({ updatedData, closeModal, editData, setIsOpen}) => {
  console.log(editData);
  const [formValue, setFormValue] = useState({
    assemblyType: editData.assemblyType,
    assemblyName: editData.assemblyName,
    serviceDetails: editData.assemblyServices,
    assemblyCost: editData.assemblyCost,
  });

  const [blankForm, setBlankForm] = useState(editData.assemblyServices);

  console.log(blankForm)
  let addFormFields = () => {
    setBlankForm([
      ...blankForm,
      {
        serviceName: "",
        serviceCost: "",
        time: Day,
      },
    ]);
  };

  const validateInputs = () => {
    for (const form of blankForm) {
      if (
        form?.serviceName ? !form?.serviceName.trim() :"" ||
        form?.serviceCost ? !form?.serviceCost.trim() :"" ||
        form?.time ? !form?.time.trim() :"" 
      ) {
        errorNotification("All fields are required.");
        return false;
      }
    }
    if (
      !formValue.assemblyType.trim() ||
      !formValue.assemblyName.trim() 
    ) {
      errorNotification("All fields are required.");
      return false;
    }

    return true;
  };
  const removeFields = (index) => {
    const field = [...blankForm];
    field.splice(index, 1);
    console.log(field);
    setBlankForm(field);

    formValue.serviceDetails = field;
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const field = [...editData.assemblyServices];
    field[index][name] = value;
    setBlankForm(field);
    formValue.serviceDetails = editData.assemblyServices;

    if (name === "time") {
      setFormValue({
              ...formValue,
              assemblyCost: `${calculateTotalCost(updatedForm)} ${updatedForm[0].time}`,
            });
    }
  };

  useEffect(() => {
    let total = 0;
    blankForm.map((item) => {
      if (item.serviceCost != "") {
        total += parseInt(item.serviceCost);
      } else {
        total += 0;
      }
    });
    setFormValue({
      ...formValue,
      assemblyCost: `${total} ${blankForm[0].time}`,
    });
  }, [blankForm]);

  console.log(blankForm.serviceCost);

  const editDetails = {
    id: editData.id,
    assemblyID: editData.assemblyID,
    assemblyType: editData.assemblyType,
    assemblyName: editData.assemblyName,
    assemblyCost: formValue.assemblyCost,
    assemblyServices: formValue.serviceDetails,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    if (!validateInputs()) {
      return;
    }
    console.log(editDetails);
    const resp = await trackPromise(API.post(`procurement-module/api/v1/costings/editAssemblyCosting`, editDetails));

    const { success,message} = resp;

    if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        refresh()
        setIsOpen(false)
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "521px", overflow: "scroll" }}
      >
        <div className="header">
          <h3>Edit Assembly Costing</h3>
        </div>

        <div className="body" style={{ overflow: "scroll" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              margin: "12px",
              justifyContent: "start",
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-125px",
                }}
              >
                Assembly Type<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="assemblyType"
                style={{marginTop:'-5px', width:'auto'}}
                value={editData.assemblyType}
                placeholder="Assembly Type"
                disabled
              />
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-125px",
                }}
              >
                Assemby Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="assemblyName"
                style={{marginTop:'-5px', width:'auto'}}
                value={editData.assemblyName}
                disabled
                placeholder="Assemby Name"
              />
            </div>
            {blankForm?.map((value, index) => {
              return (
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "start",
                    }}
                  >
                    <div
                      style={{
                        width: "41%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          width: "auto",
                          marginLeft: "-83px",
                        }}
                      >
                        Service Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        required={true}
                        name="serviceName"
                        className="input-config"
                        style={{marginTop:'-5px', width:'auto', outline:'none'}}
                        defaultValue={value.serviceName}
                        // disabled
                        onChange={(event) => handleInputChange(event, index)}
                      >
                        <option value="" disabled selected hidden>
                          Select Service
                        </option>
                        {editData.assemblyServices.map((item, id) => (
                          <option key={id}>{item.serviceName}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{
                        width: "29%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          width: "auto",
                          marginLeft: "-24px",
                        }}
                      >
                        {`Service Cost (${auth.getUserInfo().currencySymbol})`}<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="input-config"
                        style={{marginTop:'-5px', width:'auto', outline:'none'}}
                        type="text"
                        name="serviceCost"
                        pattern="[0-9]*"
                        defaultValue={value.serviceCost}
                        placeholder="Service Cost"
                        onChange={(event) => handleInputChange(event, index)}
                      />
                    </div>
                    <div
                      style={{
                        width: "28%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          width: "auto",
                          marginLeft: "-83px",
                        }}
                      >
                        Time
                      </label>
                      {/* <select
                        required={true}
                        // disabled={index > 0 ? true : false}
                        name="time"
                        className="input-config"
                        style={{marginTop:'-5px', width:'auto', outline:'none'}}
                        value={value.time}
                        onChange={(event) => handleInputChange(event, index)}
                      >
                        <option value="" disabled selected>
                          Time
                        </option>
                        <option>second</option>
                        <option>minute</option>
                        <option>hour</option>
                        <option>day</option>
                        <option>week</option>
                        <option>month</option>
                      </select> */}
                       <input
                        className="input-config"
                        disabled
                        style={{marginTop:'-5px', width:'auto', outline:'none'}}
                        type="text"
                        placeholder="Per Day"
                      />
                    </div>
                  </div>

                  {/* <div style={{ marginTop: "34px" }}>
                    {index === 0 ? (
                      <button
                        type="button"
                        style={{
                          borderRadius: "100%",
                          border: "1px solid green",
                        }}
                        onClick={() => addFormFields()}
                      >
                        <div style={{ color: "green" }}>
                          <FontAwesomeIcon icon={faPlus} />
                        </div>
                      </button>
                    ) : (
                      <button
                        type="button"
                        style={{
                          borderRadius: "100%",
                          border: "1px solid red",
                        }}
                        onClick={() => removeFields(index)}
                      >
                        <div style={{ color: "red" }}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </div>
                      </button>
                    )}
                  </div> */}
                </div>
              );
            })}
          </div>
          <br />
          {/* {blankForm[0].time ? (
            <div style={{ marginLeft: "45%", width: "auto" }}>
              <span
                style={{
                  display: "inline-block",
                  width: "auto",
                  textAlign: "center",
                }}
              >
                {`Total Cost (${auth.getUserInfo().currencySymbol})`}
              </span>
              <span
                className="input-config"
                style={{
                  display: "inline-block",
                  width: "auto",
                  padding: "10px",
                  textAlign: "left",
                  height: "35px",
                }}
              >
                {formValue.assemblyCost}
              </span>
            </div>
          ) : null} */}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default Edit;
