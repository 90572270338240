import React, { useState, useEffect } from "react";
import auth from "../../utils/auth";
// import './modal.scss'
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";
import { AiOutlineUser } from "react-icons/ai";
import './modal.scss';
import { faAmbulance } from "@fortawesome/free-solid-svg-icons";
import { LocalAirportOutlined } from "@material-ui/icons";

const PersonalProfile = ({ closeModal, getUserImage }) => {

  const [formValue, setFormValue] = useState({
    fullName:auth.getUserInfo().fullName,
    mobileNo: auth.getUserInfo().mobileNo,
  });

  const [displayPic, setDisplayPic] = useState();
  const [uploadedPic, setUploadedPic] = useState();


  const submitHandler = async () => {
    const formData = new FormData();
    formData.append("userID", auth.getUserInfo().userID);
    formData.append("fullName", formValue.fullName);
    formData.append("mobileNo", formValue.mobileNo);
    formData.append("profilePic", uploadedPic);

    const resp = await trackPromise(
      API.put("admin-module/api/v1/users/editProfile/", formData)
    );
    const { success, message, data } = resp;
  console.log(data);
    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      getUserImage(displayPic);
      localStorage.setItem("USER_INFO",JSON.stringify(data)); 
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
    console.log(auth.getUserInfo())
  };

  // const submitSubAdminHandler = async () => {
  //   const resp = await trackPromise(
  //     API.post("http://localhost:3001/updateSubAdminUser", editDetails)
  //   );
  //   const { success, message } = resp;

  //   if (success) {
  //     closeModal(false);
  //     successNotification(message, { autoClose: 3000 });
  //   } else {
  //     errorNotification(message, { autoclose: 2000 });
  //   }
  // };

  const handleImageChange = (event) => {
    setDisplayPic(URL.createObjectURL(event.target.files[0]));
    setUploadedPic(event.target.files[0]);
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "555px", height: "330px" }}
      >
        <div className="header">
          <h3>Personal Profile</h3>
        </div>
        <div className="title">
          <div className="title-photo">
            <img
              src={displayPic}
              className="uploadedPicStyle"
            />
          </div>
          <div>
            <p className="title-para">
              Suported format should be jpg, jpeg and png. <br />
              Image size should not be more than 150 x 150 pixels.
            </p>
            <input
              type="file"
              className="chooseFileBtn"
              accept="/image/*"
              onChange={handleImageChange}
            />
          </div>
        </div>
        <div className="body">
          <div style={{ display: "flex", flexDirection: "row", width: "100%", flexWrap:"wrap", justifyContent:"space-between" }}>
            <div style={{ display: "flex", flexDirection: "column", width: "47%" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "7px",
                }}
              >
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="fullName"
                defaultValue={
                  formValue.fullName 
                    ? formValue.fullName.replace("null", "").trim() // Remove "null" and trim spaces
                    : "" // Fallback to an empty string if fullName is falsy
                } 
                  onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="First Name"
                autoComplete="off"
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              />
            </div>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                marginLeft: "20px",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "7px",
                }}
              >
                Last Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="lName"
                defaultValue={formValue.lName}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Last Name"
                autoComplete="off"
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              />
            </div> */}
              <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "47%",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
              }}
            >
              Mobile Number<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="mobileNo"
              defaultValue={formValue.mobileNo}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Mobile Number"
              autoComplete="off"
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
            />
          </div>
          </div>
        
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button
            id="submit-button"
            // onClick={auth.isSubAdmin() ? submitSubAdminHandler : submitHandler}
            onClick={submitHandler}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalProfile;
