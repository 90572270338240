import React from "react";

const MoveToHistory = ({ closeModal, rowData }) => {

  const submitHandler = async () => {
    closeModal(false);
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "410px", height: "210px" }}>
        <div className="body" style={{ flexDirection: "column", marginTop: "58px", fontSize: "18px", textAlign: "center" }}>
          <span>Are you sure to move this record to <br /> History ?</span>
        </div>
        <div className="footer" style={{ justifyContent: "center", marginBottom: "16px" }}>
          <button onClick={() => closeModal(false)}>Back</button>
          <button id="submit-button" onClick={submitHandler}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default MoveToHistory;
