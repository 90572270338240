import React, { useState } from "react";
import { KeyboardBackspace } from '@material-ui/icons';
// import"../../work-order/workorder.scss"

const WoProductionQty = ({ closeModal, rowdata }) => {
  console.log(rowdata);
  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "400px", height: "261px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Production Quantity</h3>
            {/* <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Export
          </button> */}
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "100",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <span>Vehicle Color: &nbsp;{rowdata?.vehicleColor}</span>
              <span>Production Qty: &nbsp;{rowdata?.productionQuantity}</span>
              <span>Vehicle Model: &nbsp;{rowdata?.vehicleModel}</span>
              <span>Segment: &nbsp;{rowdata?.vehicleSegment}</span>
              <span>Vehicle Variant: &nbsp;{rowdata?.vehicleVariant}</span>
            </div>
          </div>
          <div className="footer">
            <button onClick={() => closeModal(false)}><KeyboardBackspace />Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WoProductionQty;
