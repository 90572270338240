import React, { useEffect, useState } from "react";
import "../QcDashboard/styles/main.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { BsArrowUpRight, BsTable } from "react-icons/bs";
import { FaExpandAlt } from "react-icons/fa";
import { FaRedoAlt } from "react-icons/fa";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import InventoryDashboardComp from "./inventoryDashboardComp";
import InwardsGraph from "./InwardsGraph";
import VehicleOutwardBox from "./VehicleOutwardBox";
import SpareOutwardBox from "./SpareOutwardBox";
import ComponentReturnGraph from "./ComponentReturnGraph";
import { DatePicker, Space } from "antd";
import { useHistory } from "react-router-dom";
import Components from "../inward/components";
import Spares from "../inward/Spares";
import CompNewRequest from "../outward/componentOutward/compNewRequest";
import ManagedReturns from "../outward/componentOutward/managedReturns";
import ManageRequests from "../inward/ManageRequests";

const InventoryDashboard = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [compStartDate, setCompStartDate] = useState(null);
  const [compEndDate, setCompEndDate] = useState(null);
  const { RangePicker } = DatePicker;
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [openInwardsTable, setOpenInwardsTable] = useState(false);
  const [openCompReturnTable, setOpenCompReturnTable] = useState(false);
  const [openSpareOutwardTable, setOpenSpareOutwardTable] = useState(false);

  let history = useHistory();

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    setCalenderOpen(false);
    document.getElementById("overlay").style.display = "none";
    refresh();
    compRefresh();
  }

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const refresh = () => {
    setStartDate(null);
    setEndDate(null);
    document.getElementById("inwardcalender").style.display = "none";
    document.getElementById("inwardcalendericon").style.display = "block";
    document.getElementById("inwardcalendericon").style.justifyContent =
      "center";
    document.getElementById("inwardcalendericon").style.textAlign = "center";
  };

  const show = () => {
    document.getElementById("inwardcalender").style.display = "block";
    document.getElementById("inwardcalendericon").style.display = "none";
    setCalenderOpen(true);
    on();
  };

  const onCompChange = (dates) => {
    const [start, end] = dates;
    setCompStartDate(start);
    setCompEndDate(end);
  };
  const compRefresh = () => {
    setCompStartDate(null);
    setCompEndDate(null);
    document.getElementById("componentcalender").style.display = "none";
    document.getElementById("componentcalendericon").style.display = "block";
    document.getElementById("componentcalendericon").style.justifyContent =
      "center";
    document.getElementById("componentcalendericon").style.textAlign = "center";
  };
  const shows = () => {
    document.getElementById("componentcalender").style.display = "block";
    document.getElementById("componentcalendericon").style.display = "none";
    setCalenderOpen(true);
    on();
  };

  return (
    <>
      {openInwardsTable == true ? (
        <ManageRequests inDashboard={setOpenInwardsTable} />
      ) : openCompReturnTable == true ? (
        <ManagedReturns inDashboard={setOpenCompReturnTable} />
      ) : openSpareOutwardTable == true ? (
        <CompNewRequest inDashboard={setOpenSpareOutwardTable} />
      ) : (
        <>
          <div id="overlay" onClick={(e) => off(e)}></div>
          <div className="dashboardBody">
            <div className="firstRow">
              <div
                className="container-new"
                style={{ width: "100%", height: "171px" }}
              >
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "98%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Inventory</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "20%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => history.push("/app/inventory-inv")}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <InventoryDashboardComp />
                </div>
              </div>
            </div>

            <div className="secondRow">
              <div className="container-new" style={{ height: "350px" }}>
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Inwards</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "60%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => setOpenInwardsTable(true)}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ overflow: "auto", height: "100%" }}>
                  <InwardsGraph startDate={startDate} endDate={endDate} />
                </div>
              </div>

              <div className="container-new" style={{ marginLeft: "20px" }}>
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Vehicle Outward</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => history.push("/app/outward")}
                      />
                    </div>
                  </div>
                </div>
                <VehicleOutwardBox />
              </div>
            </div>

            <div className="secondRow">
              <div className="container-new" style={{ height: "350px" }}>
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Component Returns</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "60%",
                    }}
                  >
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        marginRight: "5px",
                      }}
                      onClick={compRefresh}
                    >
                      <FaRedoAlt />
                    </div>

                    <span
                      id="componentcalendericon"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        marginRight: "5px",
                      }}
                      onClick={shows}
                    >
                      <FontAwesomeIcon
                        icon={faCalendar}
                        style={{ color: "#808080" }}
                      />
                    </span>
                    <label
                      id="componentcalender"
                      style={{
                        width: "auto",
                        marginRight: "5px",
                        display: "none",
                      }}
                    >
                      <Space
                        style={{ width: "235px" }}
                        direction="vertical"
                        size={10}
                      >
                        <RangePicker
                          startDate={compStartDate}
                          endDate={compEndDate}
                          value={[compStartDate,compEndDate]}
                          onChange={onCompChange}
                          format="DD-MM-YYYY"
                          size="small"
                        />
                      </Space>
                    </label> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => setOpenCompReturnTable(true)}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ overflow: "auto", height: "100%" }}>
                  <ComponentReturnGraph
                    startDate={compStartDate}
                    endDate={compEndDate}
                  />
                </div>
              </div>

              <div className="container-new" style={{ marginLeft: "20px" }}>
                <div
                  className="header"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    width: "95%",
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Spare Outward</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <BsArrowUpRight
                        onClick={() => setOpenSpareOutwardTable(true)}
                      />
                    </div>
                  </div>
                </div>
                <SpareOutwardBox />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InventoryDashboard;
