import React, { useEffect, useState } from 'react';
import Group from '../QcDashboard/assets/Group.png';
import Group2 from '../QcDashboard/assets/Group2.png';
import Group4 from '../QcDashboard/assets/Group4.png';
import Group5 from '../QcDashboard/assets/Group5.png';
import Vector from '../QcDashboard/assets/Vector.svg';
import Vector3 from '../QcDashboard/assets/Vector3.svg';
import { trackPromise } from 'react-promise-tracker';
import API from '../../utils/api';

function StoreSpareReturnCard() {

    const [data, setData] = useState({ assignedWO: "2", assignedWOChange: "3", acceptedWO: "5", acceptedWOChange: "-1", inProgressWO: "2", inProgressWOChange: "0", completedWO: "8", completedWOChange: "-2" })


    // const fetchOutwardData = async () => {
    //     const resp = await trackPromise(API.get(`inventory-module/api/v1/dashboard/getVehicleOutwardData`))
    //     setData(resp);
    // };
    // useEffect(() => {
    //     fetchOutwardData();
    // }, []);

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430", marginTop: "30px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <img style={{ marginTop: "-10px", height: "40px" }} src={Group} />&nbsp;&nbsp;
                    <span >Assigned Work Order</span>
                </div>
                <div style={{ fontSize: "18px" }}>
                    {data?.assignedWO}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <img style={{ marginTop: "-10px", height: "40px" }} src={Group2} />&nbsp;&nbsp;
                    <span>Accepted Work Order</span>
                </div>
                <div>
                    {data?.acceptedWO}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <img style={{ marginTop: "-10px", height: "40px" }} src={Group4} />&nbsp;&nbsp;
                    <span>In-Progress Work Order</span>
                </div>
                <div>
                    {data?.inProgressWO}
                </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "430" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <img style={{ marginTop: "-10px", height: "40px" }} src={Group5} />&nbsp;&nbsp;
                    <span>Completed Work Order</span>
                </div>
                <div>
                    {data?.completedWO}
                </div>
            </div>
            <hr />
        </>
    )
}

export default StoreSpareReturnCard