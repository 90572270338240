import React from "react";
import "./styles/main.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar
} from "@fortawesome/free-solid-svg-icons";
import { FaRedoAlt } from "react-icons/fa";
import { BsArrowUpRight, BsTable } from "react-icons/bs";
import { useState, useEffect } from "react";
import { FaExpandAlt } from "react-icons/fa";
import ComponentVerification from "./components/ComponentVerification";
import SpareReVerification from "./components/SpareReVerification";
import VehicleBarChart from "./components/VehicleBarChart";
import VehicleReBarChart from "./components/VehicleReBarChart";
import TableView from "./components/TableView";
import { DatePicker, Space } from "antd";
import { useHistory } from "react-router-dom";
import VehicleNewRequest from "../quality/vehicleComponents/VehicleNewRequest";

function QcDashboard() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [spareStartDate, setSpareStartDate] = useState(null);
  const [spareEndDate, setSpareEndDate] = useState(null);
  const [vehicleStartDate, setVehicleStartDate] = useState(null);
  const [vehicleEndDate, setVehicleEndDate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { RangePicker } = DatePicker;
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [openVehicleverificationTable, setOpenVehicleverificationTable] = useState(false);

  const history = useHistory();

  function on() {
    document.getElementById("overlay").style.display = "block";
  };

  function off() {
    setCalenderOpen(false);
    document.getElementById("overlay").style.display = "none";
    refresh();
    blank();
    empty();
  }

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const refresh = () => {
    setStartDate(null);
    setEndDate(null);
    document.getElementById("calender").style.display = "none";
    document.getElementById("calendericon").style.display = "block";
    document.getElementById("calendericon").style.justifyContent = "center";
    document.getElementById("calendericon").style.textAlign = "center";
  }
  const show = () => {
    document.getElementById("calender").style.display = "block";
    document.getElementById("calendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }


  const onSpareChange = (dates) => {
    const [start, end] = dates;
    setSpareStartDate(start);
    setSpareEndDate(end);
  };
  const blank = () => {
    setSpareStartDate(null)
    setSpareEndDate(null)
    document.getElementById("sparecalender").style.display = "none";
    document.getElementById("sparecalendericon").style.display = "block";
    document.getElementById("sparecalendericon").style.justifyContent = "center";
    document.getElementById("sparecalendericon").style.textAlign = "center";
  }
  const shows = () => {
    document.getElementById("sparecalender").style.display = "block";
    document.getElementById("sparecalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }


  const onVehicleChange = (dates) => {
    const [start, end] = dates;
    setVehicleStartDate(start);
    setVehicleEndDate(end);
  };
  const empty = () => {
    setVehicleStartDate(null)
    setVehicleEndDate(null)
    document.getElementById("vehiclecalender").style.display = "none";
    document.getElementById("vehiclecalendericon").style.display = "block";
    document.getElementById("vehiclecalendericon").style.justifyContent = "center";
    document.getElementById("vehiclecalendericon").style.textAlign = "center";
  }
  const display = () => {
    document.getElementById("vehiclecalender").style.display = "block";
    document.getElementById("vehiclecalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }



  return (
    <>
      {openVehicleverificationTable == true ? <VehicleNewRequest inDashboard={setOpenVehicleverificationTable} /> :
        <>
          <div id="overlay" onClick={(e) => off(e)}></div><div className="dashboardBody">
            {showModal && <TableView closeModal={setShowModal} />}
            <div className="firstRow">
              <div className="container-new">
                <div
                  className="header"
                  style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Components Verification</h5>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "end", width: "40%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer"
                      }}
                    >
                      <BsArrowUpRight onClick={() => history.push("/app/quality")} />
                    </div>
                  </div>
                </div>
                <div>
                  <ComponentVerification />
                </div>
              </div>
              <div className="container-new" style={{ marginLeft: "20px" }}>
                <div
                  className="header"
                  style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Vehicle Verification</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "60%",
                    }}
                  >
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        marginRight: "5px"
                      }}
                      onClick={refresh}
                    >
                      <FaRedoAlt />
                    </div>
                    <span id="calendericon" style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }} onClick={show}>
                      <FontAwesomeIcon
                        icon={faCalendar}
                        style={{ color: "#808080" }} />
                    </span>
                    <label id="calender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                      <Space style={{ width: "235px" }} direction="vertical" size={10}>
                        <RangePicker
                          startDate={startDate}
                          endDate={endDate}
                          value={[startDate,endDate]}
                          onChange={onChange}
                          format="DD-MM-YYYY"
                          size="small" />
                      </Space>
                    </label> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer"
                      }}
                    >
                      <BsArrowUpRight onClick={() => setOpenVehicleverificationTable(true)} />
                    </div>
                  </div>
                </div>
                <div>
                  <VehicleBarChart
                    props="qc"
                    bomStartDate={startDate}
                    bomEndDate={endDate} />
                </div>
              </div>
            </div>
            <div className="secondRow">
              <div className="container-new">
                <div
                  className="header"
                  style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Spare Re-verification</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "60%",
                    }}
                  >
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        marginRight: "5px"
                      }}
                      onClick={blank}
                    >
                      <FaRedoAlt />
                    </div>
                    <span id="sparecalendericon" style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }} onClick={shows}>
                      <FontAwesomeIcon
                        icon={faCalendar}
                        style={{ color: "#808080" }} />
                    </span>
                    <label id="sparecalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                      <Space style={{ width: "235px" }} direction="vertical" size={10}>
                        <RangePicker
                          startDate={spareStartDate}
                          endDate={spareEndDate}
                          value={[spareStartDate,spareEndDate]}
                          onChange={onSpareChange}
                          format="DD-MM-YYYY"
                          size="small" />
                      </Space>
                    </label> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer"
                      }}
                    >
                      <BsArrowUpRight onClick={() => history.push("/app/quality")} />
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ overflow: "auto" }}>
                  <SpareReVerification
                    startDate={spareStartDate}
                    endDate={spareEndDate} />
                </div>
              </div>
              <div className="container-new" style={{ marginLeft: "20px" }}>
                <div
                  className="header"
                  style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                >
                  <div>
                    <h5 style={{ fontSize: "18px" }}>Vehicle Re-verification</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "60%",
                    }}
                  >
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        marginRight: "5px"
                      }}
                      onClick={empty}
                    >
                      <FaRedoAlt />
                    </div>
                    <span id="vehiclecalendericon" style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "25px",
                      height: "23.4px",
                      border: "1px Solid #808080",
                      color: "#808080",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }} onClick={display}>
                      <FontAwesomeIcon
                        icon={faCalendar}
                        style={{ color: "#808080" }} />
                    </span>
                    <label id="vehiclecalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                      <Space style={{ width: "235px" }} direction="vertical" size={10}>
                        <RangePicker
                          startDate={vehicleStartDate}
                          endDate={vehicleEndDate}
                          value={[vehicleStartDate,vehicleEndDate]}
                          onChange={onVehicleChange}
                          format="DD-MM-YYYY"
                          size="small" />
                      </Space>
                    </label> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "23.4px",
                        border: "1px Solid #808080",
                        color: "#808080",
                        borderRadius: "5px",
                        cursor: "pointer"
                      }}
                    >
                      <BsArrowUpRight onClick={() => setOpenVehicleverificationTable(true)} />
                    </div>
                  </div>
                </div>
                <div>
                  <VehicleReBarChart
                    props="qc"
                    startDate={vehicleStartDate}
                    endDate={vehicleEndDate} />
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default QcDashboard;
