import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faRedoAlt,
  faEllipsisH,
  faDownload,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import "../../components/modal/modal.scss";
import SearchBox from "../../../components/search/SearchBox";
import moment from "moment";
import AppConstants from "../../../utils/app-constants";
import ManageReturnAction from "./actions/ManageReturnAction";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import handleExport from "../../../utils/exportExcel";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ManagedReturns = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [open, setOpen] = useState();
  const [openQty, setOpenQty] = useState();
  const [manageReturnData, setManageReturnData] = useState([]);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/spareOutward/getAllRPIReqs?requestID=${formValue.returnID}&vendorName=${formValue.vendorName}&vendorLocation=${formValue.vendorLocation}&status=${formValue.status}`
      )
    );
    console.log(resp);
    setManageReturnData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY / HH:mm")}`;
  }

  const statusFormatter = (cell) => {
    return cell === AppConstants.RPI_GENERATED ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">RPI Generated</span>
      </button>
    ) : cell === AppConstants.READYTOSHIP ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">Ready To Ship</span>
      </button>
    ) : cell === AppConstants.SHIPPEDTOVENDOR ? (
      <button className="shipToVendor-btn">
        {" "}
        <span className="shipToVendor-btn-font">Shipped To Vendor</span>
      </button>
    ) : cell === AppConstants.DELIVEREDTOVENDOR ? (
      <button className="active-btn">
        {" "}
        <span className="active-btn-font">Delivered To Vendor</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }
  const actionHandler = (row, rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    // setRow(row);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => actionHandler(row, rowIndex)}
          style={{marginBottom:"0px"}}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && open ? (
          <ManageReturnAction rowData={row} tableRefresh={fetchReport} rowIndex={rowIndex}/>
        ) : null}
      </div>
    );
  };

  const [formValue, setFormValue] = useState({
    returnID: "",
    vendorName: "",
    vendorLocation: "",
    status: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      returnID: "",
      vendorName: "",
      vendorLocation: "",
      status: "",
    });
    const resp = await trackPromise(
      API.get(`inventory-module/api/v1/spareOutward/getAllRPIReqs`)
    );
    setManageReturnData(resp);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const handleClick = (e) => {
    setShow(!show);
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      classes: "cellsOverflow",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          manageReturnData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requestID",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request Date",
      dataField: "requestedDate",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "Request Category",
      dataField: "requestCategory",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { openQty },
    },
    {
      text: "Vendor ID",
      dataField: "vendorID",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vendor Name",
      dataField: "vendorName",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vendor Location",
      dataField: "vendorLocation",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { openQty },
      formatter:(cell,row)=>{
        return row?.deliveryLocation
      }
    },
    {
      text: "Components Qty.",
      dataField: "components",
      classes: "cellsOverflow",
      headerAttrs: { width: "160px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex) => {
        return cell.length;
      },
    },
    {
      text: "Status",
      dataField: "status",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      headerAttrs: { width: "80px" },
      classes: "cellsOverflow",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Managed Returns</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={manageReturnData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(manageReturnData, "Managed Returns")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <>
                    <form
                      onSubmit={submitHandler}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: "85px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                      }}
                      className="UHinputfields VMform"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "19%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          Return ID
                        </label>
                        <select
                          style={{ marginTop: "-5px", width: "100%" }}
                          name="returnID"
                          value={formValue.returnID}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Return ID
                          </option>
                          {manageReturnData.map((item) => (
                            <option>{item.requestID}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "19%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          Vendor Name
                        </label>
                        <select
                          style={{ marginTop: "-5px", width: "100%" }}
                          name="vendorName"
                          value={formValue.vendorName}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Vendor Name
                          </option>
                          {manageReturnData.map((item) => (
                            <option>{item.vendorName}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "19%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          Vendor Location
                        </label>
                        <select
                          style={{ marginTop: "-5px", width: "100%" }}
                          name="vendorLocation"
                          value={formValue.vendorLocation}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Vendor Location
                          </option>
                          {manageReturnData.map((item) => (
                            <option>{item.vendorLocation}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "19%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          Status
                        </label>
                        <select
                          style={{ marginTop: "-5px", width: "100%" }}
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Status
                          </option>
                          {manageReturnData.map((item) => (
                            <option>{item.status}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                        }}
                      >
                        <button
                          onClick={fetchReport}
                          style={{ width: "100px", marginTop: "24px" }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{ width: "100px", marginTop: "24px" }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </form>
                  </>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManagedReturns;
