import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../../utils/app-constants";
import "../../settings-tabs/tabs.scss";
import auth from "../../../utils/auth";
// import Vehicles from "./components/Vehicles";
// import Spares from "./components/Spares";

const Vehicles = React.lazy(()=>import("./components/Vehicles"))
const Spares = React.lazy(()=>import("./components/Spares"))



const tabs = [
    {
        id: "ASM1T1",
        label: TabLabels.VEHICLE,
        Component:Vehicles
    },
    {
        id: "ASM1T2",
        label: TabLabels.SPARES,
        Component:Spares
    }
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

const AreaVehicleSpares = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default AreaVehicleSpares;
