import React, { useState } from "react";
import "../../../../../components/modal/modal.scss";
import AppConstants from "../../../../../utils/app-constants";
import CreditNoteSpareView from "../../../../ReturnTemplates/CreditNoteSpareView";
import GenerateShip from "./GenerateShip";
import ViewDetails from "./ViewDetails";
import ViewUpdates from "./ViewUpdates";


const ReturnActions = ({ rowData , rowIndex }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      approve: false,
      onHoldApprove: false,
      onHold: false,
      reject: false,
      release: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>

      {activeModal.approve && showModal && <GenerateShip closeModal={setShowModal} rowData={rowData} />}

      {activeModal.onHold && showModal && <ViewUpdates closeModal={setShowModal} rowData={rowData} />}

      {/* {activeModal.reject && showModal && <ViewDetails closeModal={setShowModal} rowData={rowData} />} */}

      {activeModal.reject && showModal && <CreditNoteSpareView closeModal={setShowModal} rowData={rowData} />}


      <div style={{ position: "relative" , marginTop: rowIndex <= 4 ? "0px" : "-70px", }}>
        <div className="action vendoraction" style={{ width: "180px", marginLeft: "-125px" }}>
          {rowData.status === AppConstants.RPI_GENERATED ?

            <div>
              <span id="approve" onClick={(e) => clickHandler(e)}>
                Generate GIN & Ship
              </span>
            </div>

            :
            rowData.status === AppConstants.RETURN_REQUEST_PLACED  || rowData.status === "Returns Request Placed" ?

              <>
                <div>
                  <span id="onHold" onClick={(e) => clickHandler(e)}>
                    View Updates
                  </span>
                </div>
              </> :
              rowData.status === AppConstants.RETURN_SETTLED || rowData.status == "Return Note Generated" ?
                <div>
                  <span id="reject" onClick={(e) => clickHandler(e)}>
                    View Details
                  </span>
                </div>
                :
                null
          }
        </div>
      </div>
    </div>
  );
};

export default ReturnActions;
