import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import SearchBox from "../../../components/search/SearchBox";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import handleExport from "../../../utils/exportExcel";
import AppConstants from "../../../utils/app-constants";
import Action from "../Actions/ManageAction";
import MappedStates from "../Actions/MappedStates";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";


const ManageSalesUserTable = ({inDashboard}) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [statesShow, setStateShow] = useState();
  const [stateOpen, setStateOpen] = useState(true);
  const [UsersData, setUsersData] = useState([]);

  const fetchReport = async () => {
    const { SearchBar } = Search;
    const resp = await trackPromise(
      API.get(
        `zonalSales-module/api/v1/users/manageUsers?country=${formValue.country}&zone=${formValue.zones}&name=${formValue.fullName}&emailID=${formValue.email}&area=${formValue.area}&status=${formValue.status}&from=${formValue.from}&to=${formValue.to}`
      )
    );
    let filteredData = resp.filter((item) => { return item.asmStatus == "Active" || item.asmStatus == "Deactivate" || item.asmStatus == "Reactivate" })
    setUsersData(filteredData);
  };
  useEffect(() => {
    fetchReport();
  }, []);





  const  uniquercountryName = [...new Set(UsersData.map((item)=>item.countryName))]
  const  uniquezone = [...new Set(UsersData.map((item)=>item.zone))]
  const  uniquearea = [...new Set(UsersData.map((item)=>item.area))]
  const  uniqueemail = [...new Set(UsersData.map((item)=>item.email))]
  const  uniquestatus = [...new Set(UsersData.map((item)=>item.status))]






  const stateHandler = (rowIndex) => {
    setStateOpen(true);
    setStateShow(rowIndex);
  };

  const statesFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row);
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => stateHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          {/* {row?.states?.length} */}
          View
        </button>
        {statesShow === rowIndex && stateOpen ? (
          <MappedStates
            rowData={row}
            close={setStateOpen}
          />
        ) : null}
      </div>
    );
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.asmStatus === AppConstants.DEACTIVATE ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">Deactivated</span>
      </button>
    ) : row.asmStatus === AppConstants.REACTIVATE ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">Reactivated</span>
      </button>
    ) : row.asmStatus === AppConstants.DELETE ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Deleted</span>
      </button>
    ) : (
      <button className="active-btn">
        <span className="active-btn-font">Active</span>
      </button>
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          <Action
            rowData={row}
            setIsOpen={setIsOpen}
            usersData={UsersData}
            updatedData={fetchReport}
            rowIndex={rowIndex}
          />
        ) : null}
      </div>
    );
  };

  function dateFormatter(cell, row, rowIndex, formatExtraData) {
    return `${moment(cell).format("DD-MMM-YYYY / HH:mm")}`;
  }

  const [formValue, setFormValue] = useState({
    country: "",
    zones: "",
    area: "",
    fullName: "",
    email: "",
    status: "",
    from: "",
    to: ""
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      country: "",
      zones: "",
      area: "",
      fullName: "",
      email: "",
      status: "",
      from: "",
      to: ""
    });

    const resp = await trackPromise(
      API.get(`inventory-module/api/v1/vehicleReturns`)
    );
    setUsersData(
      resp.data.filter((item) => item.status == "Request Completed")
    );
  };


  const [show, setShow] = useState(false);

  const handleClick = (e) => {
    setShow(!show);
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },

      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Country",
      dataField: "countryName",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Zone",
      dataField: "zone",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Mapped States",
      dataField: "states",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { statesShow, stateOpen },
      formatter: statesFormatter,
    },
    {
      text: "Name",
      dataField: "asmFname",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Email ID",
      dataField: "asmEmail",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Status",
      // dataField: "status",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Last Updated",
      dataField: "asmUserAddedDate",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "Reason",
      dataField: "reason",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "100px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
 <div >
       <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{marginBottom:"-5px"}}>
          <span>Manage Users</span>
        </div>

         <ToolkitProvider keyField="id" data={UsersData} columns={columns} search >
         
          {(props) => (
            <>
            <div>
              <div style={{ float: "right", marginTop: "-42px"}}>
                <div style={{ display: "flex", marginRight: "-15px", height: "26px", marginTop:"10px", }}>
                  <div style={{ width: "220px" }}>
                    
                     <SearchBar
                      {...props.searchProps}
                      placeholder='Search'
                      style={{
                        height: "25px",
                       // border: "1px solid #C6C6C6",
                        border:"none",
                        background: "white",
                        borderRadius: "3px",
                        marginRight: "-50px",
                      }}
                    />
                    
                  </div>
                  <div onClick={clearFilter} className="filter-refresh">
                    <FontAwesomeIcon icon={faRedoAlt} />
                    &nbsp; Refresh
                  </div>
                  <div className="filter-refresh" onClick={handleClick}>
                    {" "}
                    <FontAwesomeIcon icon={faFilter} />
                    &nbsp; Filters
                  </div>
                  <div
              className="filter-refresh"
              onClick={() => handleExport(UsersData, "manageReturns")}
            >
              <FontAwesomeIcon icon={faDownload} />
              &nbsp; Export
            </div>
                  {inDashboard ? (
                <div
                  className="filter-refresh"
                  onClick={() => inDashboard(false)}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </div>
              ) : (
                ""
              )}
                </div>
               </div>
              </div>
               
     <div>


      {show && (
        <>
          <div
            className="UHinputfields VMform"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "135px",
              backgroundColor: "#F7F7F7",
              padding: "5px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "18%",
                  marginRight: "2%",
                }}
              >
                <label style={{ fontSize: "13px", fontWeight: "500" }}>
                  Country <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  name="country"
                  value={formValue.country}
                  onChange={handleChange}
                  style={{ marginTop: "-5px", width: "100%" }}
                >
                  <option value="" disabled selected>
                    Country
                  </option>
                  {
                    uniquercountryName.map((item)=>(
                      <option>{item}</option>
                    ))
                  }
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "18%",
                  marginRight: "2%",
                }}
              >
                <label style={{ fontSize: "13px", fontWeight: "500" }}>
                  Zone <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  name="zone"
                  value={formValue.zones}
                  onChange={handleChange}
                  style={{ marginTop: "-5px", width: "100%" }}
                >
                  <option value="" disabled selected>
                    Zone
                  </option>
                  {
                    uniquezone.map((item)=>(
                      <option>{item}</option>
                    ))
                  }
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "18%",
                  marginRight: "2%",
                }}
              >
                <label style={{ fontSize: "13px", fontWeight: "500" }}>
                  Area
                </label>
                <select
                  name="area"
                  value={formValue.area}
                  onChange={handleChange}
                  style={{ marginTop: "-5px", width: "100%" }}
                >
                  <option value="" disabled selected>
                    Area
                  </option>
                  {
                    uniquearea.map((item)=>(
                      <option>{item}</option>
                    ))
                  }
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "18%",
                  marginRight: "2%",
                }}
              >
                <label style={{ fontSize: "13px", fontWeight: "500" }}>
                  Status
                </label>
                <select
                  name="status"
                  value={formValue.status}
                  onChange={handleChange}
                  style={{ marginTop: "-5px", width: "100%" }}
                >
                  <option value="" disabled selected>
                    Status
                  </option>
                  {
                    uniquestatus.map((item)=>(
                      <option>{item}</option>
                    ))
                  }
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "18%",
                  marginRight: "2%",
                }}
              >
                <label style={{ fontSize: "13px", fontWeight: "500" }}>
                  Email
                </label>
                <select
                  name="status"
                  value={formValue.email}
                  onChange={handleChange}
                  style={{ marginTop: "-5px", width: "100%" }}
                >
                  <option value="" disabled selected>
                    Email
                  </option>
                  {
                    uniqueemail.map((item)=>(
                      <option>{item}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "18%",
                  marginRight: "2%",
                }}
              >
                <label style={{ fontSize: "13px", fontWeight: "500" }}>
                  From
                </label>
                <input
                  type="date"
                  placeholder="From"
                  style={{ marginTop: "-5px", width: "100%" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "18%",
                  marginRight: "2%",
                }}
              >
                <label style={{ fontSize: "13px", fontWeight: "500" }}>
                  To
                </label>
                <input
                  type="date"
                  placeholder="To"
                  style={{ marginTop: "-5px", width: "100%" }}
                />
              </div>
              <div
                style={{
                  justifyContent: "end",
                  width: "auto",
                  marginLeft: "auto",
                }}
              >
                <button
                  id="apply"
                  style={{ marginTop: "22px", marginLeft: "10px" }}
                  onClick={fetchReport}
                >
                  Apply
                </button>
                <button
                  onClick={clearFilter}
                  id="clear"
                  style={{ marginTop: "22px", width: "auto" }}
                >
                  Clear Filter
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <br></br>
      </div>

      
<BootstrapTable
  wrapperClasses="table-responsive"
  // rowClasses="text-nowrap"
  rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
  pagination={paginationFactory({
    hidePageListOnlyOnePage: true,
    showTotal: true,
    hideSizePerPage: true,
    paginationSize: 2,
    sizePerPage: "10",
  })}
  noDataIndication="No Data Found"
  {...props.baseProps}
/>
</>
)}
</ToolkitProvider>
</div>
</>
  );
};

export default ManageSalesUserTable;
