import React from "react";
import {Tabs} from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from '../../utils/auth'
// import Bom from "./bom";
// import SparePartsPrice from "./sparepartsprice";
// import VehiclePrice from "./vehicleprice";

const Bom = React.lazy(()=>import("./bom"))
const VehiclePrice = React.lazy(()=>import("./vehicleprice"))
const SparePartsPrice = React.lazy(()=>import("./sparepartsprice"))

const Approval=()=>{
    const tabs = [
        {
          id:"MGM1T1",
          label: TabLabels.BOM,
          Component: Bom
        },
        {
          id:"MGM1T2",
          label: TabLabels.VEHICLEPRICE,
          Component: VehiclePrice
        },
        {
          id:"MGM1T3",
          label: TabLabels.SPAREPARTSPRICE,
          Component: SparePartsPrice
        },
    ];

    const getTabs = (tabs) => {
      let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
      return tabs.filter(tab => tabIds.includes(tab.id));
    };

    let tabsToShow = getTabs(tabs);
    return (
      <div className="app-layout__card" >
        <Tabs tabs={tabsToShow} type="pills" />
      </div>
    );
}
export default Approval