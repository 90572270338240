import React from 'react'
import {
  CancelOutlined,
  CheckCircle,
} from "@material-ui/icons";
import auth from '../../../utils/auth';
import { trackPromise } from 'react-promise-tracker';
import API from '../../../utils/api';
import { errorNotification, successNotification } from '../../../utils/notifications';

const RequestUpdate = ({ closeModal, rowData,tabName }) => {

  const submitHandler = async () => {
    let finalBody = {
      "role": ["Quality Control"],
      "title": tabName == 'finishedGoods' ? "finished Goods updation request" : "vehicle updation request",
      "body": tabName == 'finishedGoods' ? `${auth.getUserInfo().fullName} have requested for Release and Update of Finished Goods  Verification request ${rowData.requestID}` : `${auth.getUserInfo().fullName} have requested to release and update the Vehicle Re-verification Request ${rowData.requestID}.`,
      "requestID": rowData.requestID
    }
    console.log(finalBody)
    const resp = await trackPromise(API.post(`inventory-module/api/v1/grn/notification`, finalBody));
    const { success, message } = resp;
    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 3000 });
    }
  };


  return (
    <div className='container1'>
      <div className="modalContainer" style={
        { width: '400px', height: '200px' }
      }>

        <div className="body" style={{ justifyContent: 'center', fontSize: '16px' }}>
          Are you sure to sent Request <br /> for Update?

        </div>
        <div className="footer" style={{ justifyContent: 'center' }}>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: 'white',
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            onClick={submitHandler}
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>

        </div>
      </div>
    </div>
  )
}

export default RequestUpdate;