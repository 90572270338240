import React, { useState } from "react";
import Edit from "./Edit";
import Reactivate from "./Reactivated";
import Deactivate from "./Deactivated";
import Delete from "./Deleted";
import {
  faEdit,
  faBan,
  faTrash,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../components/modal/modal.scss";
import EditNationalSales from "./EditNationalSales";
import EditZonalSales from "./EditZonalSales";

const Action = ({ updatedData, rowData, setIsOpen, userType, usersData , rowIndex}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});


  const clickHandler = (e) => {
    console.log("inclickHandler");
    setActiveModal({
      edit: false,
      reactivate: false,
      deactivate: false,
      delete: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  let status;
  if (userType == "National Sales") {
    status = rowData.nsmStatus ? rowData.nsmStatus : "Active"
  }
  else if (userType == "Zonal Sales") {
    status = rowData.zsmStatus ? rowData.zsmStatus : "Active"
  }
  else {
    status = rowData.asmStatus ? rowData.asmStatus : "Active"
  }

  console.log(userType)


  return (
    <div>
      {status === "Active" || status === "Reactivate"
        ? activeModal.edit &&
        showModal && (
          userType === "Area Sales" ?
            // Edit Component for Area Sales User
            <Edit
              updatedData={updatedData}
              editData={rowData}
              closeModal={setShowModal}
              userType={userType}
              usersData={usersData}
            /> : userType === "National Sales" ?
              // Edit Component for National Sales User
              <EditNationalSales
                updatedData={updatedData}
                editData={rowData}
                closeModal={setShowModal}
                userType={userType}
                usersData={usersData}
              /> : userType === "Zonal Sales" ?
                // Edit Component for Zonal Sales User
                <EditZonalSales
                  updatedData={updatedData}
                  editData={rowData}
                  closeModal={setShowModal}
                  userType={userType}
                  usersData={usersData}
                /> : ""
        )
        : ""}
      {status === "Active" || status === "Reactivate"
        ? activeModal.deactivate &&
        showModal && (
          <Deactivate
            updatedData={updatedData}
            editData={rowData}
            closeModal={setShowModal}
            userType={userType}
          />
        )
        : status === "Deactivate"
          ? activeModal.reactivate &&
          showModal && (
            <Reactivate
              updatedData={updatedData}
              editData={rowData}
              closeModal={setShowModal}
              userType={userType}
            />
          )
          : ""}
      {status === "Active" ||
        status === "Deactivate" ||
        status === "Reactivate"
        ? activeModal.delete &&
        showModal && (
          <Delete
            updatedData={updatedData}
            editData={rowData}
            closeModal={setShowModal}
            userType={userType}
          />
        )
        : ""}

      <div
        className="action"
        style={{ position: "relative",  marginTop: rowIndex <= 4 ? "0px" : "-145px", }}
        onBlur={() => setIsOpen(false)}
      >
        <div
          className="action vendoraction"
          style={{ marginLeft: "0px", marginTop: "0px" }}
        >
          {status === "Active" || status === "Reactivate" ? (
            <div>
              <span id="edit" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </span>
            </div>
          ) : (
            ""
          )}

          {status === "Active" ||
            status === "Reactivate" ||
            status === "Deactivate" ? (
            <div>
              {status === "Active" ||
                status === "Reactivate" ? (
                <span id="deactivate" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faBan} /> Deactivate
                </span>
              ) : (
                <span id="reactivate" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faDotCircle} /> Reactivate
                </span>
              )}
            </div>
          ) : (
            ""
          )}

          {status === "Active" ||
            status === "Deactivate" ||
            status === "Reactivate" ? (
            <div>
              <span id="delete" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faTrash} /> Delete
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Action;
