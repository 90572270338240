import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
import Tab from "./subscriptionTabs/Tab"

import SubscribePlann from "./subscriptionTabs/SubscribePlann"
import SubscribedPlann from "./subscriptionTabs/SubscribedPlann"


const tabs = [
  {
    id: "SAM1T1",
    label: TabLabels.SUBSCRIBED_PLAN,
    Component: SubscribedPlann
  },
  {
    id: "SAM1T2",
    label: TabLabels.SUBSCRIBE_PLAN,
    Component: SubscribePlann
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};

const UsersTabs = () => {
  let tabsToShow = getTabs(tabs);

  return (
    <div className="app-layout__card">
      <Tab tabs={tabsToShow} type="pills" />
    </div>
  );
};



export default UsersTabs;
