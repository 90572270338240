import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import SearchBox from "../../../components/search/SearchBox";
import moment from "moment";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppConstants from "../../../utils/app-constants";
import RequestForApproval from "./actions/RequestForApproval";
import ViewAndRequest from "./actions/ViewAndRequest";
import ViewDetails from "./actions/ViewDetails";
import ViewRejectedDetails from "./actions/ViewRejectedDetails";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import handleExport from "../../../utils/exportExcel";
import auth from "../../../utils/auth";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ManageVehiclePricing = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [data, setData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [show, setShow] = useState(false);

  const fetchBom = async () => {
    const resp = await trackPromise(
      API.get(
        `finance/api/v1/vehiclePricing?requestID=${formValue.requestID}&requestType=${formValue.requestType}&requestCategory=${formValue.requestCategory}&vehicleModel=${formValue.vehicleModel}&vehicleVariant=${formValue.vehicleVariant}&actionDate=${formValue.actionDate}&status=${formValue.status}`
      )
    );
    setData(resp);
  };
  useEffect(() => {
    fetchBom();
  }, []);


  const uniquerequestID  = [...new Set(data.map((item)=>item.requestID))]
  const uniquevehicleModel = [...new Set(data.map((item)=>item.vehicleModel))]
  const uniquevehicleVariant = [...new Set(data.map((item)=>item.vehicleVariant))]
  const uniquestatus = [...new Set(data.map((item)=>item.status))]



  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY / HH:mm")}`;
  }

  function lastFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY / HH:mm")}`;
  }

  const statusFormatter = (cell) => {
    return cell === AppConstants.PENDING_MANAGEMENT ? (
      <button className="deactive-btn" style={{width:"100%"}}>
        <span className="deactive-btn-font">Pending at Management</span>
      </button>
    ) : cell === AppConstants.HOLD_MANAGEMENT ? (
      <button className="deactive-btn" style={{width:"100%"}}>
        {" "}
        <span className="deactive-btn-font">Hold by Management</span>
      </button>
    ) : cell === AppConstants.APPROVEDBYMANAGEMENT ? (
      <button className="active-btn">
        {" "}
        <span className="active-btn-font">Approved</span>
      </button>
    ) : cell === AppConstants.REJECTED_BY_MANAGEMENT ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Rejected</span>
      </button>
    ) : (
      cell
    );
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.status === AppConstants.PENDING_MANAGEMENT)
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              // padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
            }}
            onClick={() => setActionShow(rowIndex)}
          >
            Request for Approval
          </button>
          {actionShow === rowIndex && (
            <RequestForApproval rowData={row} closeModal={setActionShow} />
          )}
        </div>
      );
    else if (row.status === AppConstants.HOLD_MANAGEMENT) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              // padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
              width: "130px",
            }}
            onClick={() => setActionShow(rowIndex)}
          >
            View & Request
          </button>
          {actionShow === rowIndex && (
            <ViewAndRequest rowData={row} closeModal={setActionShow} />
          )}
        </div>
      );
    } else if (row.status === AppConstants.APPROVEDBYMANAGEMENT) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              // padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
              width: "130px",
            }}
            onClick={() => setActionShow(rowIndex)}
          >
            View & Update
          </button>
          {actionShow === rowIndex && (
            <ViewDetails
              updatedData={fetchBom}
              rowData={row}
              closeModal={setActionShow}
            />
          )}
        </div>
      );
    } else if (row.status === AppConstants.REJECTED_BY_MANAGEMENT) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              // padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
              width: "130px",
            }}
            onClick={() => setActionShow(rowIndex)}
          >
            View Details
          </button>
          {actionShow === rowIndex && (
            <ViewRejectedDetails rowData={row} closeModal={setActionShow} />
          )}
        </div>
      );
    }
  };

  const [formValue, setFormValue] = useState({
    requestID: "",
    vehicleModel: "",
    vehicleVariant: "",
    actionDate: "",
    requestCategory: "",
    requestType: "",
    status: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      requestID: "",
      vehicleModel: "",
      vehicleVariant: "",
      actionDate: "",
      requestCategory: "",
      requestType: "",
      Status: "",
    });

    const resp = await trackPromise(API.get(`finance/api/v1/vehiclePricing`));
    setData(resp.reverse());
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const exportData = data?.map((item, index) => {
    return {
        "S.No.": index + 1, // S.No.
        "Request ID": item?.requestID, // Request ID
        "Request Category": item?.requestCategory, // Request Category
        "Request Type": item?.requestType, // Request Type
        "Segment": item?.vehicleSegment, // Segment
        "Category": item?.vehicleCategory, // Category
        "Model": item?.vehicleModel, // Model
        "Variant": item?.vehicleVariant, // Variant
        "Type": item?.vehicleType, // Type
        "Price to Dealer(Unit Price)": item?.pricetoDealer, // Price to Dealer
        "Ex-showroom Price": item?.exShowroomPrice, // Ex-showroom Price
        "Added By": auth.getUserInfo().email, // Added By (based on auth)
        "Addition Date": moment(item?.actionDate).format("DD-MM-YYYY"), // Addition Date formatted
        "Last Update": moment(item?.actionDate).format("DD-MM-YYYY"), // Last Update formatted
        "Status": statusFormatter(item?.status), // Status formatted
    };
});


  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          data.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requestID",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: "Request Category",
      dataField: "requestCategory",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: "Request Type",
      dataField: "requestType",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: "Segment",
      dataField: "vehicleSegment",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: "Category",
      dataField: "vehicleCategory",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: "Model",
      dataField: "vehicleModel",
      headerAttrs: { width: "100px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: "Variant",
      dataField: "vehicleVariant",
      headerAttrs: { width: "100px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: "Type",
      dataField: "vehicleType",
      headerAttrs: { width: "100px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: `Price to Dealer(Unit Price) (${
        auth.getUserInfo().currencySymbol
      })`,
      dataField: "pricetoDealer",
      headerAttrs: { width: "300px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: `Ex-showroom Price (${auth.getUserInfo().currencySymbol})`,
      dataField: "exShowroomPrice",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow"
    },
    {
      text: "Added By",
      // dataField: "addedBy",
      headerAttrs: { width: "170px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes:"cellsOverflow",
      formatter:()=>{
        return auth.getUserInfo().email
      }
    },
    {
      text: "Addition Date",
      dataField: "actionDate",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
      classes:"cellsOverflow"
    },
    {
      text: "Last Update",
      dataField: "actionDate",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: lastFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "230px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Requests</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(exportData, "Manage Vehicle Pricing")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <>
                    <form
                      onSubmit={submitHandler}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: "135px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                      }}
                      className="UHinputfields VMform"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request ID
                        </label>
                        <select
                          style={{ marginTop: "-5px", width: "100%" }}
                          name="requestId"
                          value={formValue.requestID}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Request ID
                          </option>
                          {uniquerequestID?.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Category
                        </label>
                        <input
                          type="text"
                          style={{ marginTop: "-5px", width: "100%" }}
                          name="requestCategory"
                          value={formValue.requestCategory}
                          onChange={handleChange}
                          placeholder="Request Category"
                          autoComplete="off"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Type
                        </label>
                        <input
                          type="text"
                          style={{ marginTop: "-5px", width: "100%" }}
                          name="requestType"
                          value={formValue.requestType}
                          onChange={handleChange}
                          placeholder="Request Type"
                          autoComplete="off"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Model
                        </label>
                        <select
                          style={{ marginTop: "-5px", width: "100%" }}
                          name="vehicleModel"
                          value={formValue.vehicleModel}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Vehicle Model
                          </option>
                          {uniquevehicleModel.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Variant
                        </label>
                        <select
                          style={{ marginTop: "-5px", width: "100%" }}
                          name="vehicleVariant"
                          value={formValue.vehicleVariant}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Vehicle Variant
                          </option>
                          {uniquevehicleVariant.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          Status
                        </label>
                        <input
                          type={"date"}
                          name="actionDate"
                          className="requestdate"
                          selected={formValue.actionDate}
                          onChange={(date) =>
                            setFormValue({ ...formValue, actionDate: date })
                          }
                          isClearable
                          placeholderText="Addition Date"
                          dateFormat="DD-MM-YYYY/HH:mm"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          Status
                        </label>
                        <select
                          style={{ marginTop: "-5px", width: "100%" }}
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Status
                          </option>
                          {uniquestatus.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          width: "46%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          onClick={fetchBom}
                          style={{ width: "100px", marginTop: "24px" }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{ width: "100px", marginTop: "24px" }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </form>
                  </>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManageVehiclePricing;
