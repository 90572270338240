import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";

const ReturnedQtyModal = ({ closeModal, rowData }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <div className="container1">
            {
                openModal && <ReturnedQtyModal rowData={rowData} closeModal={setOpenModal} />
            }
            <div
                className="modalContainer"
                style={{ width: "600px", padding: '15px' }}
            >
                <div className="header">
                    <h3>Returned Units</h3>
                </div>
                <br />
                <div
                    className="body"
                    style={{ display: "flex", height: "450px", fontSize: "14px", overflow: 'scroll' }}
                >

                    <section style={{ width: '100%', }}>
                        <div style={{ overflow: "scroll" }}>
                            <div id='POVendorHeading'>
                                <div style={{ width: '10%' }}>S. No.</div>
                                <div style={{ width: '30%' }}>Part S.No.</div>
                                <div style={{ width: '30%' }}>PO No.</div>
                                <div style={{ width: '30%' }}>Return Reason</div>

                            </div>
                            <div>

                               {
                                rowData.returnedData.map((item,index)=>{
                                    return (
                                        <>
                                        <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }} key={1}>
                                        <div   div style={{ width: '10%' }}>{index+1}</div>
                                        <div style={{ width: '30%' }}>{item.returnQty.map((data=>{return data.partSno}))}</div>
                                        <div style={{ width: '30%', color: "#0A18A3" }}>{item.returnQty.map((data)=>{return data.poNo})}</div>
                                        <div style={{ width: '30%' }}>{item.returnQty.map((data)=>{return data.returnReason})}</div>
                                        </div>
                                        </>
                                    )
                                })
                               }

                            </div>
                        </div>
                    </section>

                </div>

                <div className="footer">
                    <div><button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button></div>

                </div>

            </div>
        </div>
    );
};

export default ReturnedQtyModal