import React, { useState } from "react";
import auth from "../../../../utils/auth";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
// import "./modal.scss";
import { successNotification, errorNotification } from "../../../../utils/notifications";
import { IoMdRefresh } from "react-icons/io";
import { KeyboardBackspace } from "@material-ui/icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RequestForProceeding = ({ editData, closeModal }) => {
  console.log(editData);

  const editDetails = {
    userID: editData.userID,
  };

  const notificationBody = {
    "target": "bellnotification",
    "receivers": {
      "type": "users",
      "to": editData.dealerID
    },
    "message": {
      "notification": {
        "title": "Deactivated",
        "body": "User 164913412080887 is Deactivated."
      }
    }
  }

  const submitHandler = async () => {
    console.log(editData);
    // const resp = await trackPromise(API.post(`messages/api/v1/sendwebnotification`, notificationBody)
    // );
    // const { success, message } = resp;

    // // const notifyBody = 
    // // {
    // //   "role":["Sub Admin"],
    // //   "title":"Inventory request approval",
    // //   "body":`Request for ${editData.action} is approved by Super Admin.`,
    // //   "requestID":editData.requesterID
    // // }
    // if (success) {
    //   successNotification(message, { autoClose: 3000 });
    //   closeModal(false)
    //   // const resp = await trackPromise(
    //   //   API.post(`inventory-module/api/v1/grn/notification`, notifyBody)
    //   // );
    // } else {
    //   errorNotification(message, { autoclose: 2000 });
    // }
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "377px", height: "214px" }}
      >
        <div className="header">
          <h3>Requisition Reminder</h3>
        </div>

        <div className="body" style={{ justifyContent: "center" }}>
          Are You sure to send reminder for the
          <br />
          Current request proceeding
        </div>
        <div className="footer" style={{ justifyContent: "center" }}>
          <button onClick={() => closeModal(false)}>
            {" "}
            <KeyboardBackspace /> Back
          </button>
          <button id="submit-button" onClick={submitHandler}>
            <FontAwesomeIcon icon={faCheckCircle} /> Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestForProceeding;
