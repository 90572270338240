import React, { useState } from "react";
import AppConstants from "../../../utils/app-constants";
import "../../settings-tabs/homesettings.scss";
import "../approval.scss";
import ApproveBom from "./approvebom";
import OnHoldBom from "./onholdbom";
import RejectBom from "./rejectbom";
import ViewAssemblyBom from "./viewassembly-bom";
import ViewComponentsBom from "./viewcomponents-bom";
import ViewBattery from "./viewBattery";
import ViewLabourBom from "./viewlabour-bom";
import moment from "moment";
import { KeyboardBackspace } from "@material-ui/icons";
import PrintIcon from '@mui/icons-material/Print';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import auth from "../../../utils/auth";

const   BomViewandUpdate = ({ close, rowdata, updatedData }) => {
  console.log(rowdata);
  const [viewComp, setViewComp] = useState();
  const [viewLbr, setViewLbr] = useState();
  const [viewAssm, setViewAssm] = useState();
  const [viewBatt, setViewBatt] = useState();
  const [approvePopup, setApprovePopup] = useState();
  const [rejectPopup, setRejectPopup] = useState();
  const [onholdPopup, setOnholdPopup] = useState();

  let viewComponentTotal = 0;
  let viewTotalLabour = 0;
  let ViewTotalAssembly = 0;
  const componentTotalPrice = rowdata.bomComponents.map((item) => {
    return item.vendorDetails.map((vendorItem) => {
      if (vendorItem.selected == true) {
        return vendorItem.deliveryDetails.map((deliveryItem) => {
          if (deliveryItem.deliveryLocation == rowdata.bomLocation) {
            viewComponentTotal +=
              deliveryItem.unitPrice * item.componentQuantity;
          }
        });
      }
    });
  });
  const laborTotalPrice = rowdata.bomLabor.map((item) => {
    return (viewTotalLabour +=
      item.requiredWorkers * item.requiredDuration * item.avgLaborCost);
  });
  const assemblyTotalPrice = rowdata.bomAssembly.map((item) => {
    item.assemblyServices.map((value) => {
      return (ViewTotalAssembly += value.requiredDuration * value.serviceCost);
    });
  });

  let overAllCost = rowdata.totalBomCost;

  return (
    <>
      {viewComp && (
        <ViewComponentsBom
          compdata={rowdata.bomComponents}
          bomLocation={rowdata.bomLocation}
          totalComponentsCost={rowdata.totalComponentsCost}
          viewComponentTotal={viewComponentTotal}
          close={setViewComp}
        />
      )}
      {viewLbr && (
        <ViewLabourBom labourdata={rowdata.bomLabor} close={setViewLbr} />
      )}
      {viewAssm && (
        <ViewAssemblyBom
          assemblydata={rowdata.bomAssembly}
          close={setViewAssm}
        />
      )}
      {
        viewBatt && (
          <ViewBattery 
          compdata={rowdata.bomComponents}
          bomLocation={rowdata.bomLocation}
          totalComponentsCost={rowdata.totalComponentsCost}
          viewComponentTotal={viewComponentTotal}
          close={setViewBatt}
          />
        )
      }
      {approvePopup && (
        <ApproveBom
          close={close}
          updatedData={updatedData}
          data={rowdata}
          closeModal={setApprovePopup}
        />
      )}
      {rejectPopup && (
        <RejectBom
          close={close}
          updatedData={updatedData}
          data={rowdata}
          closeModal={setRejectPopup}
        />
      )}
      {onholdPopup && (
        <OnHoldBom
          close={close}
          updatedData={updatedData}
          data={rowdata}
          closeModal={setOnholdPopup}
        />
      )}
      <div className="bussiness">
        <h4 className="heading">New Requests</h4>
        <button
          type="button"
          className="btn "
          style={{ float: "right", marginTop: "-52px" }}
        >
          <div
            style={{ display: "flex", marginRight: "-18px", height: "31px" }}
          >
            <div className="filter-refresh" onClick={() => close(false)}>
              <KeyboardBackspace />
              &nbsp; Back
            </div>
          </div>
        </button>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request ID: &nbsp;{rowdata.BOMID}</span>
          <span>Requested By: &nbsp; Procurement</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Request Type: &nbsp;{rowdata.bomTypes}</span>
          <span>
            Request Date: &nbsp;{" "}
            {moment(rowdata.createdDate).format("DD-MMM-YYYY, HH:mm")}
          </span>
        </div>
      </div>
      {rowdata.status == AppConstants.ON_HOLD_BY_MANAGEMENT ? (
        <div className="onhold_div">
          <span className="holdDate">
            Hold Date: &nbsp;{moment(rowdata.actiondate).format("DD-MMM-YYYY, HH:mm")}
          </span>
          <span style={{ fontSize: "25px", color: "#CDCDCD" }}>|</span>
          <span className="holdReason" style={{ right: "150px" }}>
            Hold Reason: &nbsp; {rowdata.reason}
          </span>
        </div>
      ) : null}
      <br />
      <div>
        <h6>Vehicles Details</h6>
        <div className="shopfloortbl">
          <table className="sfaction_tbl" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Segment</th>
                <th>Category</th>
                <th>Model</th>
                <th>Variant</th>
                <th>Type</th>
              </tr>
            </thead>
            {/* <hr className='line'/> */}
            <tbody>
              <tr>
                <td>{rowdata.vehicleSegment}</td>
                <td>{rowdata.vehicleCategory}</td>
                <td>{rowdata.vehicleModel}</td>
                <td>{rowdata.vehicleVariant}</td>
                <td>{rowdata.vehicleType}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />

        <h6>Bom Details</h6>
        <div className="shopfloortbl">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "16px",
              fontWeight: "500",
              padding: "14px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>BOM ID: &nbsp;{rowdata.BOMID}</span>
              <span>BOM Location: &nbsp;{rowdata.bomLocation}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>BOM Name: &nbsp;{rowdata.bomName}</span>
              <span>Production Qty: &nbsp;{"01"}</span>
            </div>
          </div>
          <table className="sfaction_tbl" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Total Component Details</th>
                <th>Total Labour Details</th>
                <th>Total Assembly Details</th>
                <th>Total Battery Details</th>
                <th>Total Cost(component,labour,assembly,battery)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <button
                    onClick={() => setViewComp(true)}
                    className="action-btn"
                    style={{ color: "blue" }}
                  >
                    View Components
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => setViewLbr(true)}
                    className="action-btn"
                    style={{ color: "blue" }}
                  >
                    View Labours
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => setViewAssm(true)}
                    className="action-btn"
                    style={{ color: "blue" }}
                  >
                    View Assembly
                  </button>
                </td>       
                <td>
                  <button
                    onClick={() => setViewBatt(true)}
                    className="action-btn"
                    style={{ color: "blue" }}
                  >
                    View Battery
                  </button>
                </td>
                <td>{`${auth.getUserInfo().currencySymbol}`} {overAllCost}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <div>
            {/* <button className="approvalfooter_btn"><PrintIcon/> &nbsp;Print</button> */}
            {rowdata.status == AppConstants.PENDING_AT_MANAGEMENT ? (
              <button
                className="approvalfooter_btn"
                onClick={() => setOnholdPopup(true)}
              ><PauseCircleOutlineIcon/>
                On Hold
              </button>
            ) : null}
          </div>
          <div>
            <button
              className="approvalfooter_btn"
              onClick={() => setRejectPopup(true)}  
            ><CloseIcon/>&nbsp;
              Reject  
            </button>
            <button
              className="approvalfooter_btn"
              style={{ color: "white", backgroundColor: "#0a18a3" }}
              onClick={() => setApprovePopup(true)}
            ><DoneIcon/>&nbsp;
              Approve
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BomViewandUpdate;
