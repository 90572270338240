import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import "./styles/main.scss";
import { BsArrowUpRight } from "react-icons/bs";
import Hsn from "./components/Hsn";
import VehiclePricing from "./components/VehiclePricing";
import SpareReturns from "../financeReturn/Components/SpareReturns";
import SparesReturns from "./components/SpareReturns";
import SparePricing from "./components/SparePricing";
import VehicleBarChart from "../QcDashboard/components/VehicleBarChart";
import ReturnNotes from "./components/ReturnNotes";
import InventoryView from "./components/InventoryView";
import { useHistory } from "react-router-dom";
import { DatePicker, Space } from "antd";
import ManageHsn from "../pricing/components/ManageHsn";
import ManageVehiclePricing from "../pricing/components/ManageVehiclePricing";
import SpareManageRequest from "../pricing/components/SpareManageRequest";
import Bom from "../po-request/components/Bom";
import PONewRequest from "../po-request/components/PONewRequest";
import ReturnNote from "../financeReturn/Components/ReturnNote";
import VehicleReturns from "../financeReturn/Components/VehicleReturns";
import VehicleReturnsDash from "./components/VehicleReturnsDash";


function FinanceDashboard() {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [finishedGoods, setFinishedGoods] = useState({});
  const [mro, setMro] = useState({});
  const [raw, setRaw] = useState({});
  const [wip, setWip] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [vehicleStartDate, setVehicleStartDate] = useState(null);
  const [vehicleEndDate, setVehicleEndDate] = useState(null);
  const [spareStartDate, setSpareStartDate] = useState(null);
  const [spareEndDate, setSpareEndDate] = useState(null);
  const [vehicleOrderStartDate, setVehicleOrderStartDate] = useState(null);
  const [vehicleOrderEndDate, setVehicleOrderEndDate] = useState(null);
  const [spareOrderStartDate, setSpareOrderStartDate] = useState(null);
  const [spareOrderEndDate, setSpareOrderEndDate] = useState(null);
  const [bomStartDate, setBomStartDate] = useState(null);
  const [bomEndDate, setBomEndDate] = useState(null);
  const [poStartDate, setPoStartDate] = useState(null);
  const [poEndDate, setPoEndDate] = useState(null);
  const { RangePicker } = DatePicker;
  const [calenderOpen, setCalenderOpen] = useState(false);

  const [openHsnTable, setOpenHsnTable] = useState(false);
  const [openVehiclePriceTable, setOpenVehiclePriceTable] = useState(false);
  const [openSparePriceTable, setOpenSparePriceTable] = useState(false);
  const [openBomTable, setOpenBomTable] = useState(false);
  const [openPoReqsTable, setOpenPoReqsTable] = useState(false);
  const [openSpareReturnTable, setOpenSpareReturnTable] = useState(false);
  const [openVehicleReturnTable, setOpenVehicleReturnTable] = useState(false);
  const [openReturnNoteTable, setOpenReturnNoteTable] = useState(false);

  function on() {
    document.getElementById("overlay").style.display = "block";
  };

  function off() {
    setCalenderOpen(false);
    document.getElementById("overlay").style.display = "none";
    refresh();
    blank();
    empty();
    emptyBom();
    emptyPo();
    emptyVehicle();
    emptySpare();
  }



  // Inventory (finishedGoods)
  const fetchData = async () => {
    const result = await trackPromise(API.get("finance/api/v1/finishedGoods"));
    setFinishedGoods(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Inventory (finishedGoods)
  const fetchReport = async () => {
    const result = await trackPromise(API.get("finance/api/v1/mro"));
    setMro(result);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  // Inventory (finishedGoods)
  const fetchinfo = async () => {
    const result = await trackPromise(API.get("finance/api/v1/raw"));
    setRaw(result);
  };
  useEffect(() => {
    fetchinfo();
  }, []);

  // WIP 
  const fetchWip = async () => {
    const result = await trackPromise(API.get("finance/api/v1/wip"));
    setWip(result);
  };
  useEffect(() => {
    fetchWip();
  }, []);


  // HSN/SAC (Pricing)
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const refresh = () => {
    setStartDate(null);
    setEndDate(null);
    document.getElementById("calender").style.display = "none";
    document.getElementById("calendericon").style.display = "block";
    document.getElementById("calendericon").style.justifyContent = "center";
    document.getElementById("calendericon").style.textAlign = "center";
  };

  const show = () => {
    document.getElementById("calender").style.display = "block";
    document.getElementById("calendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  // Vehicle (Pricing)
  const onVehicleChange = (dates) => {
    const [start, end] = dates;
    setVehicleStartDate(start);
    setVehicleEndDate(end);
  };
  const blank = () => {
    setVehicleStartDate(null);
    setVehicleEndDate(null);
    document.getElementById("vehiclePricingcalender").style.display = "none";
    document.getElementById("vehiclePricingcalendericon").style.display = "block";
    document.getElementById("vehiclePricingcalendericon").style.justifyContent = "center";
    document.getElementById("vehiclePricingcalendericon").style.textAlign = "center";
  };
  const shows = () => {
    document.getElementById("vehiclePricingcalender").style.display = "block";
    document.getElementById("vehiclePricingcalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  // Spare (Pricing)
  const onSpareeChange = (dates) => {
    const [start, end] = dates;
    setSpareStartDate(start);
    setSpareEndDate(end);
  };
  const empty = () => {
    setSpareStartDate(null);
    setSpareEndDate(null);
    document.getElementById("sparecalender").style.display = "none";
    document.getElementById("sparecalendericon").style.display = "block";
    document.getElementById("sparecalendericon").style.justifyContent = "center";
    document.getElementById("sparecalendericon").style.textAlign = "center";
  };
  const display = () => {
    document.getElementById("sparecalender").style.display = "block";
    document.getElementById("sparecalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  // BOM (PO Request)
  const onBomChange = (dates) => {
    const [start, end] = dates;
    setBomStartDate(start);
    setBomEndDate(end);
  };
  const emptyBom = () => {
    setBomStartDate(null);
    setBomEndDate(null);
    document.getElementById("bomcalender").style.display = "none";
    document.getElementById("bomcalendericon").style.display = "block";
    document.getElementById("bomcalendericon").style.justifyContent = "center";
    document.getElementById("bomcalendericon").style.textAlign = "center";
  };
  const visibility = () => {
    document.getElementById("bomcalender").style.display = "block";
    document.getElementById("bomcalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  // PO Request (PO Request)
  const onPoChange = (dates) => {
    const [start, end] = dates;
    setPoStartDate(start);
    setPoEndDate(end);
  };
  const emptyPo = () => {
    setPoStartDate(null);
    setPoEndDate(null);
    document.getElementById("pocalender").style.display = "none";
    document.getElementById("pocalendericon").style.display = "block";
    document.getElementById("pocalendericon").style.justifyContent = "center";
    document.getElementById("pocalendericon").style.textAlign = "center";
  };
  const appear = () => {
    document.getElementById("pocalender").style.display = "block";
    document.getElementById("pocalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  // Vehicle Orders (Sales Request)
  const onVehicleOrderChange = (dates) => {
    const [start, end] = dates;
    setVehicleOrderStartDate(start);
    setVehicleOrderEndDate(end);
  };
  const emptyVehicle = () => {
    setVehicleOrderStartDate(null);
    setVehicleOrderEndDate(null);
    document.getElementById("vehicleOrdercalender").style.display = "none";
    document.getElementById("vehicleOrdercalendericon").style.display = "block";
    document.getElementById("vehicleOrdercalendericon").style.justifyContent = "center";
    document.getElementById("vehicleOrdercalendericon").style.textAlign = "center";
  };
  const appeared = () => {
    document.getElementById("vehicleOrdercalender").style.display = "block";
    document.getElementById("vehicleOrdercalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  // Spare Orders (Sales Request)
  const onSpareChange = (dates) => {
    const [start, end] = dates;
    setSpareOrderStartDate(start);
    setSpareOrderEndDate(end);
  };
  const emptySpare = () => {
    setSpareOrderStartDate(null);
    setSpareOrderEndDate(null);
    document.getElementById("spareChangecalender").style.display = "none";
    document.getElementById("spareChangecalendericon").style.display = "block";
    document.getElementById("spareChangecalendericon").style.justifyContent = "center";
    document.getElementById("spareChangecalendericon").style.textAlign = "center";
  };
  const open = () => {
    document.getElementById("spareChangecalender").style.display = "block";
    document.getElementById("spareChangecalendericon").style.display = "none";
    setCalenderOpen(true);
    on()
  }

  return (
    <>
      {openHsnTable == true ? <ManageHsn inDashboard={setOpenHsnTable} /> :
        openVehiclePriceTable == true ? <ManageVehiclePricing inDashboard={setOpenVehiclePriceTable} /> :
          openSparePriceTable == true ? <SpareManageRequest inDashboard={setOpenSparePriceTable} /> :
            openBomTable == true ? <Bom inDashboard={setOpenBomTable} /> :
              openPoReqsTable == true ? <PONewRequest inDashboard={setOpenPoReqsTable} /> :
                openSpareReturnTable == true ? <SpareReturns inDashboard={setOpenSpareReturnTable} /> :
                  openVehicleReturnTable == true ? <VehicleReturns inDashboard={setOpenVehicleReturnTable} /> :
                    openReturnNoteTable == true ? <ReturnNote inDashboard={setOpenReturnNoteTable} /> :
                      <>
                        <div id="overlay" onClick={(e) => off(e)}></div><div className="financeBody">
                          {showModal && <InventoryView closeModal={setShowModal} />}
                          <div className="fi_header">
                            <div
                              className="header"
                              style={{
                                width: "95%",
                                marginTop: "10px",
                                marginLeft: "20px",
                              }}
                            >
                              <h5 style={{ fontSize: "18px" }}>Inventory</h5>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  width: "20%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "25px",
                                    height: "23.4px",
                                    border: "1px Solid #808080",
                                    color: "#808080",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <BsArrowUpRight onClick={() => history.push("/app/financeInventory")} />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div className="box">
                                <h4
                                  style={{
                                    color: "#3442CF",
                                    fontSize: "18px",
                                    fontWeight: "450",
                                    marginLeft: "47px",
                                    marginBottom: "-5px",
                                    marginTop: "10px",
                                  }}
                                >
                                  RAW Inventory
                                </h4>
                                <hr style={{ color: "gray", width: "90%", marginLeft: "10px" }} />
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontWeight: "480",
                                    }}
                                  >
                                    {raw.totalInventory}
                                    <span
                                      style={{
                                        color: "#808080",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      Total Inventory
                                    </span>
                                  </div>
                                  &nbsp;
                                  <view
                                    style={{
                                      height: "100%",
                                      backgroundColor: "#F2F2F2",
                                      width: "2px",
                                      marginLeft: "5px",
                                    }}
                                  ></view>
                                  {/* <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "480",
                                  marginLeft: "5px",
                                }}
                              >
                                {raw.totalValue}
                                <span
                                  style={{
                                    color: "#808080",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {`Total Value (${auth.getUserInfo().currencySymbol})`}
                                </span>
                              </div> */}
                                  &nbsp;
                                </div>
                              </div>
                              <div className="box">
                                <h4
                                  style={{
                                    color: "#FDAA48",
                                    fontSize: "18px",
                                    fontWeight: "450",
                                    marginLeft: "50px",
                                    marginBottom: "-5px",
                                    marginTop: "10px",
                                  }}
                                >
                                  WIP Inventory
                                </h4>
                                <hr style={{ color: "gray", width: "90%", marginLeft: "10px" }} />
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontWeight: "480",
                                    }}
                                  >
                                    {wip.totalInventory}
                                    <span
                                      style={{
                                        color: "#808080",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      Total Inventory
                                    </span>
                                  </div>
                                  &nbsp;
                                  <view
                                    style={{
                                      height: "100%",
                                      backgroundColor: "#F2F2F2",
                                      width: "2px",
                                      marginLeft: "5px",
                                    }}
                                  ></view>
                                  {/* <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "480",
                                  marginLeft: "5px",
                                }}
                              >
                                {wip.totalValue}
                                <span
                                  style={{
                                    color: "#808080",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {`Total Value (${auth.getUserInfo().currencySymbol})`}
                                </span>
                              </div> */}
                                  &nbsp;
                                </div>
                              </div>
                              <div className="box">
                                <h4
                                  style={{
                                    color: "#FFD74A",
                                    fontSize: "18px",
                                    fontWeight: "450",
                                    marginLeft: "50px",
                                    marginBottom: "-5px",
                                    marginTop: "10px",
                                  }}
                                >
                                  MRO Inventory
                                </h4>
                                <hr style={{ color: "gray", width: "90%", marginLeft: "10px" }} />
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontWeight: "480",
                                    }}
                                  >
                                    {mro.totalInventory}
                                    <span
                                      style={{
                                        color: "#808080",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      Total Inventory
                                    </span>
                                  </div>
                                  &nbsp;
                                  <view
                                    style={{
                                      height: "100%",
                                      backgroundColor: "#F2F2F2",
                                      width: "2px",
                                      marginLeft: "5px",
                                    }}
                                  ></view>
                                  {/* <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "480",
                                  marginLeft: "5px",
                                }}
                              >
                                {mro.totalValue}
                                <span
                                  style={{
                                    color: "#808080",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {`Total Value (${auth.getUserInfo().currencySymbol})`}
                                </span>
                              </div> */}
                                  &nbsp;
                                </div>
                              </div>
                              <div className="box">
                                <h4
                                  style={{
                                    color: "#21A82E",
                                    fontSize: "18px",
                                    fontWeight: "450",
                                    marginLeft: "10px",
                                    marginBottom: "-5px",
                                    marginTop: "10px",
                                  }}
                                >
                                  Finished Goods Inventory
                                </h4>
                                <hr style={{ color: "gray", width: "90%", marginLeft: "10px" }} />
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontWeight: "480",
                                    }}
                                  >
                                    {finishedGoods.totalInventory}
                                    <span
                                      style={{
                                        color: "#808080",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      Total Inventory
                                    </span>
                                  </div>
                                  &nbsp;
                                  <view
                                    style={{
                                      height: "100%",
                                      backgroundColor: "#F2F2F2",
                                      width: "2px",
                                      marginLeft: "5px",
                                    }}
                                  ></view>
                                  {/* <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "480",
                                  marginLeft: "5px",
                                }}
                              >
                                {finishedGoods.totalValue}
                                <span
                                  style={{
                                    color: "#808080",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {`Total Value (${auth.getUserInfo().currencySymbol})`}
                                </span>
                              </div> */}
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="thirdBox" style={{ height: "28%" }}>
                            <div
                              className="header"
                              style={{
                                width: "95%",
                                marginTop: "10px",
                                marginLeft: "20px",
                                marginBottom: "40px",
                              }}
                            >
                              <h5 style={{ fontSize: "18px" }}>Pricing</h5>
                            </div>
                            <div style={{ display: "flex", justifyContent: "row" }}>
                              <div className="pricing">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    marginTop: "-100px",
                                  }}
                                >
                                  <div style={{ width: "20%" }}>
                                    <h5 style={{ fontSize: "14px" }}>HSN/SAC</h5>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      width: "80%",
                                    }}
                                  >
                                    {/* <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "25px",
                                    height: "23.4px",
                                    border: "1px Solid #808080",
                                    color: "#808080",
                                    borderRadius: "5px",
                                    marginRight: "5px",
                                  }}
                                  onClick={refresh}
                                >
                                  <FaRedoAlt />
                                </div>
                                <span id="calendericon" style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                }} onClick={show}>
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    style={{ color: "#808080" }} />
                                </span> */}

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "25px",
                                        height: "23.4px",
                                        border: "1px Solid #808080",
                                        color: "#808080",
                                        borderRadius: "5px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <BsArrowUpRight onClick={() => setOpenHsnTable(true)} />
                                    </div>
                                  </div>
                                </div>
                                <div id="calender" style={{ width: "auto", display: "none", width: '97%', justifyContent: "end", textAlign: "end", marginTop: "7px" }}>
                                  <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                    <RangePicker
                                      startDate={startDate}
                                      endDate={endDate}
                                      value={[startDate, endDate]}
                                      onChange={onChange}
                                      format="DD-MM-YYYY"
                                      size="small" />
                                  </Space>
                                </div>
                                <br />
                                <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                                  <Hsn startDate={startDate} endDate={endDate} />
                                </div>
                              </div>
                              <div className="pricing">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    marginTop: "-100px",
                                  }}
                                >
                                  <div style={{ width: "30%" }}>
                                    <h5 style={{ fontSize: "14px" }}>Vehicle Pricing</h5>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      width: "70%",
                                    }}
                                  >
                                    {/* <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "25px",
                                    height: "23.4px",
                                    border: "1px Solid #808080",
                                    color: "#808080",
                                    borderRadius: "5px",
                                    marginRight: "5px",
                                  }}
                                  onClick={blank}
                                >
                                  <FaRedoAlt />
                                </div>
                                <span id="vehiclePricingcalendericon" style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                }} onClick={shows}>
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    style={{ color: "#808080" }} />
                                </span> */}

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "25px",
                                        height: "23.4px",
                                        border: "1px Solid #808080",
                                        color: "#808080",
                                        borderRadius: "5px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <BsArrowUpRight onClick={() => setOpenVehiclePriceTable(true)} />
                                    </div>
                                  </div>
                                </div>
                                <div id="vehiclePricingcalender" style={{ width: "auto", display: "none", width: '97%', justifyContent: "end", textAlign: "end", marginTop: "7px" }}>
                                  <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                    <RangePicker
                                      startDate={vehicleStartDate}
                                      endDate={vehicleEndDate}
                                      value={[vehicleStartDate, vehicleEndDate]}
                                      onChange={onVehicleChange}
                                      format="DD-MM-YYYY"
                                      size="small" />
                                  </Space>
                                </div>
                                <br />
                                <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                                  <VehiclePricing startDate={vehicleStartDate} endDate={vehicleEndDate} />
                                </div>
                              </div>
                              <div className="pricing">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    marginTop: "-100px",
                                  }}
                                >
                                  <div style={{ width: '30%' }}>
                                    <h5 style={{ fontSize: "14px" }}>
                                      Spare Pricing
                                    </h5>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      width: "70%",
                                    }}
                                  >
                                    {/* <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "25px",
                                    height: "23.4px",
                                    border: "1px Solid #808080",
                                    color: "#808080",
                                    borderRadius: "5px",
                                    marginRight: "5px",
                                  }}
                                  onClick={empty}
                                >
                                  <FaRedoAlt />
                                </div>
                                <span id="sparecalendericon" style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                }} onClick={display}>
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    style={{ color: "#808080" }} />
                                </span> */}

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "25px",
                                        height: "23.4px",
                                        border: "1px Solid #808080",
                                        color: "#808080",
                                        borderRadius: "5px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <BsArrowUpRight onClick={() => setOpenSparePriceTable(true)} />
                                    </div>
                                  </div>
                                </div>
                                <div id="sparecalender" style={{ width: "auto", display: "none", width: '97%', justifyContent: "end", textAlign: "end", marginTop: "7px" }}>
                                  <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                    <RangePicker
                                      startDate={spareStartDate}
                                      endDate={spareEndDate}
                                      value={[spareStartDate, spareEndDate]}
                                      onChange={onSpareeChange}
                                      format="DD-MM-YYYY"
                                      size="small" />
                                  </Space>
                                </div>
                                <br />
                                <div style={{ overflow: "auto", marginBottom: "-121px" }}>
                                  <SparePricing startDate={spareStartDate} endDate={spareEndDate} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="secondBox" style={{ height: "27%" }}>
                            <div
                              className="header"
                              style={{
                                width: "95%",
                                marginTop: "10px",
                                marginLeft: "20px",
                                marginBottom: "40px",
                              }}
                            >
                              <h5 style={{ fontSize: "18px" }}>PO Request</h5>
                            </div>
                            <div className="outerBox">
                              <div
                                className="poBox"
                                style={{ display: "flex", flexDirection: "column" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    margin: "15px 15px 0 15px",
                                  }}
                                >
                                  <div>
                                    <h5 style={{ fontSize: "16px", width: "150px" }}>BOM</h5>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      width: "60%",
                                    }}
                                  >
                                    {/* <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "25px",
                                    height: "23.4px",
                                    border: "1px Solid #808080",
                                    color: "#808080",
                                    borderRadius: "5px",
                                    marginRight: "5px",
                                  }}
                                  onClick={emptyBom}
                                >
                                  <FaRedoAlt />
                                </div>
                                <span id="bomcalendericon" style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                }} onClick={visibility}>
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    style={{ color: "#808080" }} />
                                </span>
                                <label id="bomcalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                                  <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                    <RangePicker
                                      startDate={bomStartDate}
                                      endDate={bomEndDate}
                                      value={[bomStartDate, bomEndDate]}
                                      onChange={onBomChange}
                                      format="DD-MM-YYYY"
                                      size="small" />
                                  </Space>
                                </label> */}
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "25px",
                                        height: "23.4px",
                                        border: "1px Solid #808080",
                                        color: "#808080",
                                        borderRadius: "5px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <BsArrowUpRight onClick={() => setOpenBomTable(true)} />
                                    </div>
                                  </div>
                                </div>

                                <VehicleBarChart bomStartDate={bomStartDate} bomEndDate={bomEndDate} props="bom" />
                              </div>
                              <div
                                className="poBox"
                                style={{ display: "flex", flexDirection: "column" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    margin: "15px 15px 0 15px",
                                  }}
                                >
                                  <div>
                                    <h5 style={{ fontSize: "16px", width: "150px" }}>PO Request</h5>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      width: "60%",
                                    }}
                                  >
                                    {/* <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "25px",
                                    height: "23.4px",
                                    border: "1px Solid #808080",
                                    color: "#808080",
                                    borderRadius: "5px",
                                    marginRight: "5px",
                                  }}
                                  onClick={emptyPo}
                                >
                                  <FaRedoAlt />
                                </div>
                                <span id="pocalendericon" style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                }} onClick={appear}>
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    style={{ color: "#808080" }} />
                                </span>
                                <label id="pocalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                                  <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                    <RangePicker
                                      startDate={poStartDate}
                                      endDate={poEndDate}
                                      value={[poStartDate, poEndDate]}
                                      onChange={onPoChange}
                                      format="DD-MM-YYYY"
                                      size="small" />
                                  </Space>
                                </label> */}
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "25px",
                                        height: "23.4px",
                                        border: "1px Solid #808080",
                                        color: "#808080",
                                        borderRadius: "5px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <BsArrowUpRight onClick={() => setOpenPoReqsTable(true)} />
                                    </div>
                                  </div>
                                </div>
                                <VehicleBarChart bomStartDate={poStartDate} bomEndDate={poEndDate} props="po" />
                              </div>
                            </div>
                          </div>

                          <div className="secondBox" style={{ height: "28%" }}>
                            <div
                              className="header"
                              style={{
                                width: "95%",
                                marginTop: "10px",
                                marginLeft: "20px",
                                marginBottom: "40px",
                              }}
                            >
                              <h5 style={{ fontSize: "18px" }}>Sales Request</h5>
                            </div>
                            <div className="outerBox">
                              <div
                                className="poBox"
                                style={{ display: "flex", flexDirection: "column" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    margin: "15px 15px 0 15px",
                                  }}
                                >
                                  <div>
                                    <h5 style={{ fontSize: "16px", width: "150px" }}>
                                      Vehicle Orders
                                    </h5>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      width: "60%",
                                    }}
                                  >
                                    {/* <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "25px",
                                    height: "23.4px",
                                    border: "1px Solid #808080",
                                    color: "#808080",
                                    borderRadius: "5px",
                                    marginRight: "5px",
                                  }}
                                  onClick={emptyVehicle}
                                >
                                  <FaRedoAlt />
                                </div>
                                <span id="vehicleOrdercalendericon" style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                }} onClick={appeared}>
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    style={{ color: "#808080" }} />
                                </span>
                                <label id="vehicleOrdercalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                                  <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                    <RangePicker
                                      startDate={vehicleOrderStartDate}
                                      endDate={vehicleOrderEndDate}
                                      value={[vehicleOrderStartDate, vehicleOrderEndDate]}
                                      onChange={onVehicleOrderChange}
                                      format="DD-MM-YYYY"
                                      size="small" />
                                  </Space>
                                </label> */}
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "25px",
                                        height: "23.4px",
                                        border: "1px Solid #808080",
                                        color: "#808080",
                                        borderRadius: "5px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <BsArrowUpRight onClick={() => history.push("/app/salerequest")} />
                                    </div>
                                  </div>
                                </div>
                                <VehicleBarChart props={"financeVehicleOrder"} />
                              </div>
                              <div
                                className="poBox"
                                style={{ display: "flex", flexDirection: "column" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    margin: "15px 15px 0 15px",
                                  }}
                                >
                                  <div>
                                    <h5 style={{ fontSize: "16px", width: "150px" }}>
                                      Spare Orders
                                    </h5>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      width: "60%",
                                    }}
                                  >
                                    {/* <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "25px",
                                    height: "23.4px",
                                    border: "1px Solid #808080",
                                    color: "#808080",
                                    borderRadius: "5px",
                                    marginRight: "5px",
                                  }}
                                  onClick={emptySpare}
                                >
                                  <FaRedoAlt />
                                </div>
                                <span id="spareChangecalendericon" style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "25px",
                                  height: "23.4px",
                                  border: "1px Solid #808080",
                                  color: "#808080",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                }} onClick={open}>
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    style={{ color: "#808080" }} />
                                </span>
                                <label id="spareChangecalender" style={{ width: "auto", marginRight: "5px", display: "none" }}>
                                  <Space style={{ width: "235px" }} direction="vertical" size={10}>
                                    <RangePicker
                                      startDate={spareOrderStartDate}
                                      endDate={spareOrderEndDate}
                                      value={[spareOrderStartDate, spareOrderEndDate]}
                                      onChange={onSpareChange}
                                      format="DD-MM-YYYY"
                                      size="small" />
                                  </Space>
                                </label> */}
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "25px",
                                        height: "23.4px",
                                        border: "1px Solid #808080",
                                        color: "#808080",
                                        borderRadius: "5px",
                                        cursor: "pointer"
                                      }}
                                    >
                                      <BsArrowUpRight onClick={() => setOpenSparePriceTable(true)} />
                                    </div>
                                  </div>
                                </div>
                                <VehicleBarChart props={"financeSpareOrder"} />
                              </div>
                            </div>
                          </div>

                          <div className="lastBox" style={{ height: "33%" }}>
                            <div
                              className="header"
                              style={{
                                width: "95%",
                                marginTop: "10px",
                                marginLeft: "20px",
                                marginBottom: "40px",
                                height: "auto"
                              }}
                            >
                              <h5 style={{ fontSize: "18px" }}>Return</h5>
                            </div>
                            <div className="outerBox" style={{ flexDirection: "column", height: "750px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                }}
                              >
                                <div className="poBox" style={{ height: "340px" }}>
                                  <div style={{ width: "100%", overflow: "auto" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "15px", marginLeft: "15px" }}>
                                      <div><h5 style={{ fontSize: "16px", width: "150px" }}>Vehicle Returns</h5></div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: "25px",
                                          height: "23.4px",
                                          border: "1px Solid #808080",
                                          color: "#808080",
                                          borderRadius: "5px",
                                          cursor: "pointer",
                                          marginRight: "5%"
                                        }}
                                      >
                                        <BsArrowUpRight onClick={() => setOpenVehicleReturnTable(true)} />
                                      </div>
                                    </div>
                                    <VehicleReturnsDash />
                                  </div>
                                </div>
                                <div className="poBox" style={{ height: "340px" }}>
                                  <div style={{ width: "100%", overflow: "auto" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "15px", marginLeft: "15px" }}>
                                      <div><h5 style={{ fontSize: "16px", width: "150px" }}>Spare Returns</h5></div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: "25px",
                                          height: "23.4px",
                                          border: "1px Solid #808080",
                                          color: "#808080",
                                          borderRadius: "5px",
                                          cursor: "pointer",
                                          marginRight: "5%"
                                        }}
                                      >
                                        <BsArrowUpRight onClick={() => setOpenSpareReturnTable(true)} />
                                      </div>
                                    </div>
                                    <SparesReturns />
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "left",
                                  flexDirection: "row",
                                  marginTop: "40px",
                                }}
                              >
                                <div className="poBox" style={{ height: "340px" }}>
                                  <div style={{ width: "100%", overflow: "auto" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "15px", marginLeft: "15px" }}>
                                      <div><h5 style={{ fontSize: "16px", width: "150px" }}>Return Notes</h5></div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: "25px",
                                          height: "23.4px",
                                          border: "1px Solid #808080",
                                          color: "#808080",
                                          borderRadius: "5px",
                                          cursor: "pointer",
                                          marginRight: "5%"
                                        }}
                                      >
                                        <BsArrowUpRight onClick={() => setOpenReturnNoteTable(true)} />
                                      </div>
                                    </div>
                                    <ReturnNotes />
                                  </div>
                                </div>
                                <div>

                                </div>
                                {/* <div className="poBox" style={{ height: "420px" }}>
                              <div style={{ width: "100%", overflow: "auto" }}>
                                <ReturnComponent returnState={setOpenSpareReturnTable} />
                              </div>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
      }
    </>
  );
}

export default FinanceDashboard;
