import React, { useState } from "react";
import "../../work-order/workorder.scss";
import ViewSpecs from "../../bom/bom-viewspecs";
import { KeyboardBackspace } from "@material-ui/icons";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import auth from "../../../utils/auth";

const ViewComponentsBom = ({
  close,
  compdata,
  bomLocation,
  viewComponentTotal,
}) => {
  const [viewSpecs, setViewSpecs] = useState();

  console.log("adssdasas",compdata);

  // Total Component Qty
  const totalComp = compdata
  .filter((item) => item.primary) // Only include items where primary is true
  .map((item) => Number(item.componentQuantity));
  // const initialValue = 0;
  // const totalCompsum = totalComp.reduce((a, b) => a + b, initialValue);
  const totalCompsum = compdata.filter((item) => item?.primary).length;

  // Vendor Part Name
  const vendorPartNameData = (item) => {
    return item.vendorDetails.map((vendorItem) => {
      if (vendorItem.selected == true) {
        return vendorItem.vendorPartName;
      }
    });
  };

  // Vendor Part Code
  const vendorPartCodeData = (item) => {
    return item.vendorDetails.map((vendorItem) => {
      if (vendorItem.selected == true) {
        return vendorItem.vendorPartCode;
      }
    });
  };

  // Vendor Unit Price
  const vendorUnitPriceData = (item) => {
    return item.vendorDetails.map((vendorItem) => {
      if (vendorItem.selected == true) {
        return vendorItem.deliveryDetails.map((deliveryItem) => {
          if (deliveryItem.deliveryLocation == bomLocation) {
            return deliveryItem.unitPrice.toLocaleString("en-IN");
          }
        });
      }
    });
  };

  // Component Qty
  const componentQty = (item) => {
    return item.vendorDetails.map((vendorItem) => {
      if (vendorItem.selected == true) {
        return vendorItem.deliveryDetails.map((deliveryItem) => {
          if (deliveryItem.deliveryLocation == bomLocation) {
            return (
              deliveryItem.unitPrice * item.componentQuantity
            ).toLocaleString("en-IN");
          }
        });
      }
    });
  };

  // Total Cost
  let sum = 0;
  const totalPrice = (item) => {
    return item.vendorDetails.map((vendorItem) => {
      if (vendorItem.selected == true) {
        return vendorItem.deliveryDetails.map((deliveryItem) => {
          if (deliveryItem.deliveryLocation == bomLocation) {
            sum += deliveryItem.unitPrice * item.componentQuantity;
          }
        });
      }
    });
  };

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "1170px", height: "521px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Required Components</h3>
            <div>
              {/* <button className="action-btn" style={{ color: "blue" }}>
               <PrintIcon/>&nbsp; Print
              </button>
              <button className="action-btn" style={{ color: "blue" }}>
                <IosShareIcon/>&nbsp;Export
              </button> */}
            </div>
          </div>

          <div className="body" style={{ height: "340px", flexWrap: "nowrap" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Total Component : &nbsp; {totalCompsum}</span>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  {`Total Component Price (${
                    auth.getUserInfo().currencySymbol
                  })`}{" "}
                  : &nbsp;{viewComponentTotal.toLocaleString("en-IN")}
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              overflowY: "auto",
              height: "350px",
              marginTop: "10px",
              fontSize: "12px",
              fontWeight: "500",
              marginTop:"-210px"
            }}
          >
            <div style={{ width: "100%", overflowX: "auto" }}>
              <table
                className="viewcomp_tbl"
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "80px" }}>S.No.</th>
                    <th style={{ width: "150px" }}>Component Name</th>
                    <th style={{ width: "150px" }}>SKU Code</th>
                    <th style={{ width: "150px" }}>Category</th>
                    <th style={{ width: "150px" }}>Sub Category</th>
                    <th style={{ width: "150px" }}>Specification</th>
                    <th style={{ width: "155px" }}>Vendor Part Name</th>
                    <th style={{ width: "155px" }}>Vendor Part Code</th>
                    <th style={{ width: "123px" }}>{`Unit Price (${
                      auth.getUserInfo().currencySymbol
                    })`}</th>
                    <th style={{ width: "100px" }}>Qty.</th>
                    <th style={{ width: "123px" }}>{`Sub Total (${
                      auth.getUserInfo().currencySymbol
                    })`}</th>
                  </tr>
                </thead>
                <tbody>
                  {compdata.map((item, index) => {
                    totalPrice(item); // Calculate total price for each item

                    const vendorPartNames = vendorPartNameData(item).join(", ");
                    const vendorPartCodes = vendorPartCodeData(item).join(", ");
                    const unitPrice = vendorUnitPriceData(item);
                    const qty = item.componentQuantity;
                    const subTotal = componentQty(item);

                   if(item.primary){
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="leftcell" style={{ width: "50px" }}>
                            {index + 1}
                          </td>
                          <td style={{ width: "150px" }}>
                            {item.componentName}
                          </td>
                          <td style={{ width: "150px" }}>{item.skuCode}</td>
                          <td style={{ width: "150px" }}>{item.category}</td>
                          <td style={{ width: "150px" }}>{item.subCategory}</td>
                          <td style={{ width: "150px" }}>
                            <span
                              onClick={() => setViewSpecs(index)}
                              style={{
                                color: "#0a18a3",
                                fontWeight: "500",
                                cursor: "pointer",
                              }}
                            >
                              View Specs
                            </span>
                          </td>
                          <td style={{ width: "150px" }}>{vendorPartNames}</td>
                          <td style={{ width: "150px" }}>{vendorPartCodes}</td>
                          <td style={{ width: "100px" }}>{unitPrice}</td>
                          <td style={{ width: "100px" }}>{qty}</td>
                          <td className="rightcell" style={{ width: "100px" }}>
                            {subTotal}
                          </td>
                        </tr>
                        {viewSpecs === index && (
                          <ViewSpecs rowData={item} close={setViewSpecs} />
                        )}
                      </React.Fragment>
                    );
                   } else{
                    return null
                   }

                   
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}>
              <KeyboardBackspace /> Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewComponentsBom;
