import React, { useEffect, useState } from "react";
import AppConstants from "../../../utils/app-constants"
import VehicleViewAct from "./VehicleViewAct";
import OnHoldDetails from "./OnHoldDetails";
import MoveToRN from "./MoveToRN";
import ViewReturnDetails from "./ViewReturnDetails";
import { faEye, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreditNoteView from "../../ReturnTemplates/CreditNoteView";
import CreditNoteSpareView from "../../ReturnTemplates/CreditNoteSpareView";
import SpareViewAct from "./spareViewAct";

const ReturnActions = ({ rowData, updatedData , rowIndex }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});
  console.log(rowData)



  const clickHandler = (e) => {
    setActiveModal({
      vehicleViewAct: false,
      moveToRN: false,
      viewRelease: false,
      viewDetails: false,
      spareViewAct: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>

      {activeModal.vehicleViewAct && showModal && <VehicleViewAct rowData={rowData} closeModal={setShowModal} updatedData={updatedData} />}

      {activeModal.viewRelease && showModal && <OnHoldDetails data={rowData} closeModal={setShowModal} />}

      {/* {activeModal.moveToRN && showModal && <MoveToRN data={rowData} closeModal={setShowModal} />} */}

      {activeModal.viewDetails && showModal && (  rowData?.requestCategory == "Spare Returns" ? <CreditNoteSpareView rowData={rowData} closeModal={setShowModal} /> : <CreditNoteView rowData={rowData} closeModal={setShowModal} />)}

      {activeModal.spareViewAct && showModal && <SpareViewAct rowData={rowData} tabName={"spareReturn"} closeModal={setShowModal} updatedData={updatedData}/>}


      <div style={{ position: "relative" , marginTop: rowIndex <= 4 ? "0px" : "-110px", }}>
        <div className="action vendoraction" style={{ marginLeft: "-78px", width: "200px" }}>

          {rowData.status == "New Request" && rowData.requestCategory === "Vehicle Returns" && (
            <>
              <div>
                <span id="vehicleViewAct" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faEye} /> View & Act
                </span>
              </div>
            </>
          )}

          {rowData.status === AppConstants.ONHOLD && (
            <div>
              <span id="viewRelease" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEye} /> View & Release
              </span>
            </div>
          )}

          {rowData.status === AppConstants.RETURN_NOTE_GENERATED && (
            <>
              <div>
                <span id="viewDetails" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faEye} /> View Details
                </span>
              </div>
              {/* <div>
                <span id="moveToRN" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faShare} /> Move to RN History
                </span>
              </div> */}
            </>
          )}

          {(rowData?.status === AppConstants.NEWREQUEST || rowData?.status == "Pending at Finance") && rowData.requestCategory === "Spare Returns" && (
            <>
              <div>
                <span id="spareViewAct" onClick={(e) => clickHandler(e)}>
                  <FontAwesomeIcon icon={faEye} /> View & Act
                </span>
              </div>
            </>
          )}


        </div>
      </div>
    </div>
  );
};

export default ReturnActions;
