import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { successNotification, errorNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BusinessEntitiesDelete = ({ updatedData, editData, closeModal,entityID}) => {
  const [formValue, setFormValue] = useState({
    deletedReason: "",
    description: "",
    status:""
  });

  const editDetails = {
    entityID:entityID,
    businessEmailID: editData.businessEmailID,
    status: "Deleted",
    deletedReason: formValue.deletedReason,
    deletedDescription: formValue.description,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    console.log(formValue);
    const resp = await trackPromise(
      API.put("operations-module/api/v1/businessEntities", editDetails)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const submitOperationsHandler = async () => {
    if(formValue.deletedReason){
      console.log(formValue);
      const resp = await trackPromise(
        // API.post(
        //   `${process.env.REACT_APP_BASE_URL}/operations-module/api/v1/businessEntities`,editDetails
        // )
        API.put(`operations-module/api/v1/businessEntities/${editDetails.entityID}`, editDetails)
      );
      const { success, message } = resp;
      if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
      } else {
        errorNotification(message, { autoclose: 2000 });
      }
    }
    else{
      return errorNotification("Please select Delete Reason",{autoclose:2000 })
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Delete User</h3>
        </div>

        <div className="body">
          <label style={{display:'flex', flexDirection:'column', width:'50%'}}>
          <label style={{ fontSize: "13px", fontWeight: "500", width: "92px" }}>Delete Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="deletedReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{width:'100%', marginTop:'-5px'}}
            >
              <option disabled selected hidden>
                Delete Reason
              </option>
              <option value="Misconduct">Misconduct</option>
              <option value="Declined terms of Business">Declined terms of Business</option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.deletedReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Deactivation Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={auth.isOperations() ? submitOperationsHandler : ""}
            disabled={formValue.deletedReason == "" ? true : false}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessEntitiesDelete;
