import React from "react";
import moment from "moment";
import "../../../components/modal/modal.scss";

const History = ({ editData, closeModal, setIsOpen}) => {
  console.log(editData)


  const handler = ()=>{
    setIsOpen(false)
    closeModal(false)
  }


  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "650px", height: "500px", padding: "15px" }}
      >
        <div className="header">
          <h3>HSN/SAC History</h3>
        </div>

        <div>
          <h6 style={{ textAlign: "left" }}>Component Name: {editData.componentName}</h6>
          <h6 style={{ textAlign: "left" }}>Component SKU Code: {editData.skuCode}</h6>
        </div>

       
          {/* <ul className='hsn_Ul'>
            <li className='hsn_list updateOn_list'>
              <div className="hsn_list_div">
                <span className='hsn_list_btn updateOn'>Update On</span>
                <span>Date: 15-Dec-2021</span>
                <span>20:15</span>
                <span>Tax (%) : 8%</span>
              </div>

            </li>
            <li className='hsn_list addOn_list'>
              <div className="hsn_list_div">
                <span className='OH_list_btn addOn'>Added On</span>
                <span>Date: 18-Nov-2021</span>
                <span>20:15</span>
                <span>Tax (%) : 5%</span>
              </div>
            </li>
          </ul> */}
           <div>
          {editData.history.reverse().map((item) => {
            return (
              <>
                <ul className='hsn_Ul'>
                  <li className={item.action == "Added On" ? "hsn_list addOn_list" : "hsn_list updateOn_list"}>
                    <div className="hsn_list_div">
                      <span className={item.action == "Added On" ? "OH_list_btn addOn" : "hsn_list_btn updateOn"}>{item.action === "Added On" ? "Added On" : "Updated On"}</span>
                      <span>Date: {item.action == "Added On" ? moment(item.addedOn).format("DD-MMM-YYYY") : moment(item.updatedOn).format("DD-MMM-YYYY")}</span>
                      <span>{item.action == "Added On" ? moment(item.addedOn).format("HH:mm a") : moment(item.updatedOn).format("HH:mm a")}</span>
                      <span>Tax (%) : {item.taxRate}%</span>
                    </div>
                  </li>
                </ul>
              </>
            )
          })}
        </div>

        <div className="footer">
          <button onClick={handler}>Back</button>
        </div>
      </div>
    </div>
  );
};

export default History;
