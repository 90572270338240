import React from "react";
import {Tabs} from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
// import VehicleQC from "./vehicleQC";
// import ComponentQC from "./componentQC";

const VehicleQC = React.lazy(()=>import("./vehicleQC"))
const ComponentQC = React.lazy(()=>import("./componentQC"))


const QualityControl=()=>{
  
  const tabs = [
    {
      label: TabLabels.VEHICLE_QC,
      Component: VehicleQC
    },
    {
      label: TabLabels.COMPONENT_QC,
      Component: ComponentQC
    },
  ];

    return(
    <div className="app-layout__card">
      <Tabs tabs={tabs} type="pills" />
    </div>
    
    )
}
export default QualityControl